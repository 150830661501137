/* eslint-disable max-len */
import styled from 'styled-components';
import { Typography } from '../../../../global/theme';
const Group = styled.div`
  width: 100%;
  max-width: 1022px;
  background-color: var(--background-secondary);
  margin: auto;
  height: 100vh;
  box-orient: vertical;
  flex-direction: column;
  display: flex;
`;
const Headerblock = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 14px 0 25px;
  @media (max-width: 449px) {
    gap: 24px;
    background-color: var(--background-primary);
    border-bottom: 0.5px solid var(--border-primary);
    padding: 12px 16px;
    margin: 0 -16px 16px;
    position: sticky;
    top: 0;
    z-index: 9;
  }
`;

const Icon = styled.div`
  display: inline-flex;
  .invoice-icon {
    path {
      fill: var(--brand-primary);
    }
  }
  @media (max-width: 449px) {
    display: none;
  }
`;
const ResponsiveNavbarIcon = styled.div`
  display: none;
  @media (max-width: 449px) {
    display: block;
  }
`;
const HeaderTitle = styled.h1`
  ${Typography.heading_lg}
  color: var(--text-primary);
  margin: 0;
`;
const Group1 = styled.div`
  background: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 8px;
  overflow: hidden;
  @media (max-width: 449px) {
    overflow: auto;
    scrollbar-width: none;
  }
`;
const MainGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const Gheader = styled.div`
  padding: 11px 165px 12px 12px;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
`;
const Rightheader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Quantity = styled.p`
  ${Typography.body_md_medium}
  text-transform: uppercase;
  color: var(--text-secondary);
  white-space: nowrap;
  padding-left: 12px;
  border-left: 0.5px solid var(--border-primary);
  margin: 0;
  text-align: right;
`;

const Fields = styled.div`
  border-top: 0.5px solid var(--border-primary);
  padding: 13px 31px 12px 12px;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
`;

const Label = styled.p`
  ${Typography.body_md_medium}
  color: var(--text-primary);
  margin: 0px;
`;
const Price = styled.p`
  ${Typography.body_md_medium}
  color: var(--text-secondary);
  border-left: 0.5px solid var(--border-primary);
  padding-left: 12px;
  margin: 0px;
  text-transform: uppercase;
  white-space: nowrap;
`;

const Check = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  white-space: nowrap;

  .checkbox-round {
    width: 7px;
    height: 7px;
    border-radius: 8px;
    vertical-align: middle;
    border: 1px solid var(--border-secondary);
    appearance: none;
    outline: none;
    cursor: pointer;
    margin: 0;
    padding: 7px;
  }
  input[type='checkbox'] {
    position: relative;
    cursor: pointer;
    opacity: 0.5;
  }
  input[type='checkbox']:checked {
    background-color: var(--brand-primary);
    opacity: 1;
    border: 1px solid var(--brand-primary);
  }
  input[type='checkbox']:checked::before {
    content: '';
    position: absolute;
    right: 50%;
    top: 50%;
    width: 4px;
    height: 10px;
    border: solid var(--text-white);
    border-width: 0 2px 2px 0;
    margin: -1px -1px 0 -1px;
    transform: rotate(45deg) translate(-50%, -50%) scale(0.7);
    z-index: 2;
  }
`;
const Text = styled.p`
  ${Typography.body_md_medium}
  text-transform: uppercase;
  color: var(--text-secondary);
  margin: 0px;
`;
const Status = styled.p`
  padding-left: 31px;
  ${Typography.body_md_medium}
  color: var(--text-secondary);
  width: 220px;
  margin: 0px;
`;
const Inputvalue = styled.input``;
const Group2 = styled.h1`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 0px;
`;
const Title = styled.h1`
  ${Typography.heading_md}
  color: var(--text-primary);
  margin: 0px;
`;
const Desc = styled.div`
  padding: 12px;
  background: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 8px;
  gap: 20px;
  display: flex;
  flex-direction: column;
`;
const Billingtime = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  .ant-space-gap-row-small {
    row-gap: 5px !important;
  }
`;
const Billinghour = styled.p`
  ${Typography.heading_md}
  color: var(--text-primary);
  margin: 0px;
`;

const Leftbilling = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  .ant-radio-wrapper {
    color: var(--text-primary);
    ${Typography.body_md_medium}
  }
  .ant-radio-wrapper span.ant-radio + * {
    padding-inline-start: 10px;
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .ant-radio-wrapper .ant-radio-inner:not(.ant-radio-checked) {
    border: 0.5px solid var(--border-secondary);
    background-color: var(--background-primary);
  }
  .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    background-color: var(--brand-primary);
    border: var(--brand-primary);
  }
  .ant-space-gap-row-small {
    row-gap: 15px;
  }
  @media (max-width: 449px) {
    .ant-radio-wrapper span.ant-radio + * {
      flex-direction: column;
      gap: 5px;
      align-items: flex-start;
    }
    .ant-radio-wrapper .ant-radio {
      align-self: flex-start;
      margin-top: 2px;
    }
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  margin-bottom: 10px;
`;

const Formview = styled.div`
  position: relative;
  z-index: 9999;
  @media (max-width: 449px) {
    padding-left: 24px;
  }
  .angledown-icon {
    path {
      stroke: var(--text-secondary);
    }
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: var(--background-primary);
    border: 0.5px solid var(--border-primary);
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    color: var(--text-primary);
  }
  .ant-select-focused.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
      .ant-pagination-size-changer
    )
    .ant-select-selector {
    border: 0.5px solid var(--brand-primary);
  }
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover
    .ant-select-selector {
    border: 0.5px solid var(--brand-primary);
  }
  .ant-select .ant-select-arrow .anticon > svg {
    fill: var(--text-secondary);
    vertical-align: middle;
  }
  .ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: var(--brand-primary);
  }
  .ant-select-single {
    height: 36px;
  }
`;
const Timeentry = styled.div`
  ${Typography.body_md_medium}
  color: var(--text-secondary);
`;
const Timedetail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: var(--background-primary);
  thead tr {
    background-color: var(--background-tertiary);
    border-bottom: 0.5px solid var(--border-primary) !important;
    td {
      :last-child {
        padding-left: 0;
      }
    }
  }
  tr {
    border-bottom: 0.5px solid var(--border-primary);
    :last-child {
      border-bottom: none;
    }
  }
`;

const TableRow = styled.tr``;

const TableCell = styled.td`
  padding: 10px 12px;
  ${Typography.body_md_medium}
  color: var(--text-primary);
  white-space: nowrap;
  :nth-child(2) {
    text-align: right;
  }
  @media (max-width: 449px) {
    :nth-child(2) {
      text-align: left;
      padding-left: 24px;
    }
  }
`;
const TableheaderCell = styled.td`
  ${Typography.body_md_medium}
  text-transform: uppercase;
  color: var(--text-secondary);
  padding: 10px 12px;
  .lineicon {
    stroke: var(--background-muted);
  }
`;
const DropdownDiv = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 449px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }
`;

const SelectDiv = styled.div`
  position: relative;
`;

const ItemDiv = styled.div`
  max-height: 115px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: 4px;
  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--text-placeholder);
    border-radius: 12px;
    min-height: 28px;
  }
`;

const Input = styled.input`
  min-width: 161px;
  max-width: 161px;
  padding: 5px 12px;
  color: var(--text-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 6px;
  ${Typography.body_lg}
  ::placeholder {
    color: var(--text-secondary);
  }
  :focus-visible {
    border-color: var(--brand-primary);
    outline: unset;
  }
`;

const IconDiv = styled.div`
  position: absolute;
  top: 8px;
  right: 10px;
  .angledown-icon {
    transition: transform 0.3s ease;
  }

  .angledown-icon.rotate {
    transform: rotate(180deg);
  }
`;
export {
  Group,
  Headerblock,
  Icon,
  HeaderTitle,
  Group1,
  MainGroup,
  Gheader,
  Rightheader,
  Quantity,
  Fields,
  Price,
  Label,
  Text,
  Inputvalue,
  Check,
  Status,
  Title,
  Group2,
  Desc,
  Billingtime,
  Billinghour,
  Leftbilling,
  Buttons,
  Formview,
  Timeentry,
  Timedetail,
  ResponsiveNavbarIcon,
  StyledTable,
  TableCell,
  TableRow,
  TableheaderCell,
  DropdownDiv,
  SelectDiv,
  ItemDiv,
  Input,
  IconDiv
};
