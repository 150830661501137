import UserPreferenceSingleton from '../helpers/userPreferenceSingleton';
import LocalDbService, { PROJECT_NAME, UPDATE_ON_ID } from './localDbService';

let singleton: any;
let organizationId: any;

export default class ProjectCacheService extends LocalDbService {
  constructor(props: any) {
    const db_name = `${props.organizationId}`;
    props.dbName = db_name;
    props.tableName = PROJECT_NAME;
    super(props);
  }

  static getInstance() {
    const company = UserPreferenceSingleton.getInstance().getWorkspace();
    if (company) {
      if (!singleton || organizationId !== company?.id) {
        organizationId = company?.id;
        singleton = new ProjectCacheService({ organizationId: company?.id });
      }
    }
    return singleton;
  }

  static removeInstance() {
    if (singleton) singleton.closeDb();
    organizationId = undefined;
    singleton = undefined;
  }

  async getProjectByWorkspace(tableId: string) {
    if (tableId && this.db) {
      const rows = await this.getDb()?.where('tableId').equals(tableId).toArray();
      const result = rows?.map((x: any) => x.value);
      return result?.length > 0 ? result[0]?.value : [];
    }
    return [];
  }

  async setProjectData(value: any, companyId: string) {
    if (!value || companyId !== organizationId) return false;
    const projectData = [
      {
        id: companyId,
        value
      }
    ];
    const result = await this.addBulk(projectData);
    return result;
  }

  async updateProjectList(data: any, archived: any, workspace_id: string) {
    if (!workspace_id) return;
    const projects = await this.getItem(workspace_id);
    let fieldValueOnly: any = projects?.value;

    if (data?.length > 0) {
      data?.forEach((item: any) => {
        let status = false;
        fieldValueOnly = fieldValueOnly?.map((x: any) => {
          if (x?.id === item?.id) {
            status = true;
            return item;
          } else return x;
        });
        if (!status) {
          fieldValueOnly = [...fieldValueOnly, item];
        }
      });
    }
    if (archived?.length > 0) {
      archived?.forEach((item: any) => {
        const dataIndex = fieldValueOnly?.findIndex((x: any) => x?.id === item?.id);
        if (dataIndex !== -1) {
          fieldValueOnly?.splice(dataIndex, 1);
        }
      });
    }
    const updatedValue: any[] = fieldValueOnly.toSorted((a: any, b: any): number => {
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    });
    const tasksFields = [
      {
        id: workspace_id,
        value: updatedValue
      }
    ];
    const result = await this.addBulk(tasksFields);
    return result;
  }

  async updateProject(newList: any, archivedList: any, companyId: any) {
    if (companyId !== organizationId) return false;
    await this.syncList(newList, archivedList);
  }

  async getLastUpdatedTime(workspace_id?: string) {
    const result = await super.getLastUpdatedTime(`${UPDATE_ON_ID.PROJECT}_${workspace_id}`);
    return result;
  }

  async setLastUpdatedTime(value: any, companyId: string) {
    if (companyId !== organizationId) return false;
    const result = await super.setLastUpdatedTime(`${UPDATE_ON_ID.PROJECT}_${companyId}`, value);
    return result;
  }
}
