import styled, { css } from 'styled-components';
import { Typography } from '../../../global/theme';

interface Props {
  isActive?: boolean;
}

const ModalMain = styled.div`
  padding: 0px;
  width: 632px;
  @media (max-width: 449px) {
    width: 100%;
  }
`;

const Modalhead = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 28px 28px 16px 28px;
  border-bottom: 0.5px solid var(--border-primary);
`;

const Headline = styled.h1`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--text-primary);
  text-overflow: ellipsis;
  ${Typography.heading_md}
  margin: 0;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
  padding: 16px 2px;
  svg {
    cursor: pointer;
  }
  .arrows-icon {
    path {
      stroke: var(--text-primary);
    }
  }
`;
const Icon = styled.div`
  display: inline-flex;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 12px;
  margin-right: 16px;
  z-index: 11;
  .fillColor {
    stroke: var(--text-primary);
  }
  :hover {
    background-color: var(--neutral-secondary);
    border-radius: 4px;
  }
`;

const Bottom = styled.div`
  max-width: 100rem;
  margin: 0px 28px 28px 28px;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const Bottomline = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
  p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: var(--text-secondary);
    text-overflow: ellipsis;
    ${Typography.body_md}
    margin: 0;
  }
`;
const List = styled.div`
  white-space: nowrap;
  display: flex;
  position: relative;
  gap: 4px;
`;

const Figure = styled.div<Props>`
  height: 44px;
  padding: 2px;
  border: 2px solid transparent;
  &:focus {
    border: 2px solid var(--brand-primary);
  }
  &:active {
    border: 2px solid var(--brand-primary);
  }
  ${(props: Props) =>
    props.isActive &&
    css`
      border: 2px solid var(--brand-primary);
    `}
`;

const Datetime = styled.div`
  display: flex;
  gap: 4px;
  padding: 4px 10px;
  border-radius: 4px;
  background: #2a2d2e;
  position: absolute;
  bottom: 106px;
  left: 38px;
  p {
    margin: 0;
    overflow: hidden;
    color: var(--text-white);
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    ${Typography.body_sm_medium}
  }
`;
export { Modalhead, Headline, ModalContent, Icon, ModalMain, Bottomline, Bottom, List, Figure, Datetime };
