import { setCurrentWorkspace } from '../actions/workspaceActions';
import { APP_INIT_RESPONSE_TYPE, CUSTOMER_MEMBER_ROLE, ONESIGNLE_APP_ID } from '../global/constants';
import { setWorksapceIdInAxios, setupToken } from '../helpers/authTokenHelpers';
import UserPreferenceSingleton from '../helpers/userPreferenceSingleton';
import { ROUTES } from '../routes/urls';
import { loadInboxData } from './inboxServices';
import { getInvoiceList } from './invoiceService';
import { getProjectList } from './projectServices';
import { getWorkspaceDetails, getWorkspaceList } from './workspaceService';
import { getColorWithOpacity, getCurrentDomain, isDev, isEmpty, isReactNative } from '../helpers/common';
import TagManager from 'react-gtm-module';
import { setAnlayticUserAndCompany } from './analyticsService';
import { captureException, setLogUser } from './logService';
import { CustomWindow } from '../interfaces/OneSignalInterface';
import { loadMyTaskData, getTotalTaskCount } from './taskServices';
import { getUserPreferenceFieldData } from '../helpers/firebaseHelper';
import { fetchGraphQL } from '../lib/contentful';
import { setAppInitLoading, setCustomBranding } from '../actions/appActions';
import { auth } from '../utils/firebase';
import { signInWithCustomToken } from 'firebase/auth';
import { getFirebaseToken } from './authServices';
import { getCompanySettingIntegration, getProfileSettingData, getSubscriptionList } from './settingServices';
import _ from 'lodash';
import { AnyAction, Dispatch } from 'redux';
import { connectToWebsocket } from './realtimeServices';
import { rootStore } from '../mobx/rootStore';

declare const window: CustomWindow;

export const getBrandDataBasedOnHostname: any = () => async (dispatch: Dispatch<AnyAction>) => {
  try {
    const hostName = getCurrentDomain();
    const query = `query{
        clientConfigurationCollection(where:{domain: "${hostName}"}){
          items{
            primaryColor
            primaryDarkColor
            domain
            faviconIcon{
              url
            }
            metaTitle
            logo{
              url
            }
          }
        }
      }`;
    const response = await fetchGraphQL(query);
    const customBrandingData = response?.data?.clientConfigurationCollection?.items?.[0];
    if (customBrandingData) {
      dispatch(setCustomBranding(customBrandingData));
      document.title = !isEmpty(customBrandingData?.metaTitle) ? customBrandingData?.metaTitle : 'Teamcamp';
      const favicon: HTMLLinkElement | null = document.querySelector('link[rel="icon"]');
      if (favicon?.href && customBrandingData?.faviconIcon?.url) {
        favicon.href = customBrandingData?.faviconIcon?.url;
      } else if (favicon) {
        favicon.href =
          'https://images.ctfassets.net/6v3c95narsix/4meyMh26aF5gp7IGa3WUmp/028b802e6183801e3b809eca10644fdb/Union.svg';
      }
      // Set LIGHT mode primary color
      if (customBrandingData?.primaryColor) {
        const hoverColor = getColorWithOpacity(customBrandingData?.primaryColor);
        const root = document.querySelector('.light') as HTMLElement;
        if (root) {
          root.style.setProperty('--brand-primary', customBrandingData?.primaryColor);
          root.style.setProperty(
            '--brand-primary-hover',
            !isEmpty(hoverColor) ? hoverColor : customBrandingData?.primaryColor
          );
        }
      } else {
        const root = document.querySelector('.light') as HTMLElement;
        root.style.setProperty('--brand-primary', '#00A16F');
        root.style.setProperty('--brand-primary-hover', '#03724F');
      }
      // Set DARK mode primary color
      const darkRoot = document.querySelector('.dark') as HTMLElement;
      if (darkRoot) {
        if (customBrandingData?.primaryDarkColor) {
          const hoverColor = getColorWithOpacity(customBrandingData?.primaryDarkColor);
          darkRoot.style.setProperty('--brand-primary', customBrandingData?.primaryDarkColor);
          darkRoot.style.setProperty(
            '--brand-primary-hover',
            !isEmpty(hoverColor) ? hoverColor : customBrandingData?.primaryDarkColor
          );
        } else {
          darkRoot.style.setProperty('--brand-primary', '#00A16F');
          darkRoot.style.setProperty('--brand-primary-hover', '#03724F');
        }
      }
    } else {
      const favicon: HTMLLinkElement | null = document.querySelector('link[rel="icon"]');
      if (favicon?.href)
        favicon.href =
          'https://images.ctfassets.net/6v3c95narsix/4meyMh26aF5gp7IGa3WUmp/028b802e6183801e3b809eca10644fdb/Union.svg';
      document.title = 'Teamcamp';
      dispatch(setCustomBranding({}));
      const root = document.querySelector('.light');
      if (root && root instanceof HTMLElement) {
        root.style.setProperty('--brand-primary', '#00A16F');
        root.style.setProperty('--brand-primary-hover', '#03724F');
      }
      const darkRoot = document.querySelector('.dark');
      if (darkRoot && darkRoot instanceof HTMLElement) {
        darkRoot.style.setProperty('--brand-primary', '#00A16F');
        darkRoot.style.setProperty('--brand-primary-hover', '#03724F');
      }
    }
  } catch (e) {
    captureException(e);
  }
};

export const getBrandDataBasedOnWorkspaceId: any = (workspaceId: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    if (isEmpty(workspaceId)) return;
    const query = `query{
        clientConfigurationCollection(where:{workspaceId: "${workspaceId}"}){
          items{
            primaryColor
            primaryDarkColor
            domain
            faviconIcon{
              url
            }
            metaTitle
            logo{
              url
            }
          }
        }
      }`;
    const response = await fetchGraphQL(query);
    const customBrandingData = response?.data?.clientConfigurationCollection?.items?.[0];
    if (customBrandingData) {
      dispatch(setCustomBranding(customBrandingData));
      document.title = !isEmpty(customBrandingData?.metaTitle) ? customBrandingData?.metaTitle : 'Teamcamp';
      const favicon: HTMLLinkElement | null = document.querySelector('link[rel="icon"]');
      if (favicon?.href && customBrandingData?.faviconIcon?.url) favicon.href = customBrandingData?.faviconIcon?.url;
      // Set LIGHT mode primary color
      if (customBrandingData?.primaryColor) {
        const hoverColor = getColorWithOpacity(customBrandingData?.primaryColor);
        const root = document.querySelector('.light');
        if (root && root instanceof HTMLElement) {
          root.style.setProperty('--brand-primary', customBrandingData?.primaryColor);
          root.style.setProperty(
            '--brand-primary-hover',
            !isEmpty(hoverColor) ? hoverColor : customBrandingData?.primaryColor
          );
        }
      } else {
        const root = document.querySelector('.light');
        if (root && root instanceof HTMLElement) {
          root.style.setProperty('--brand-primary', '#00A16F');
          root.style.setProperty('--brand-primary-hover', '#03724F');
        }
      }
      // Set DARK mode primary color
      if (customBrandingData?.primaryDarkColor) {
        const darkRoot = document.querySelector('.dark');
        if (darkRoot && darkRoot instanceof HTMLElement) {
          const hoverColor = getColorWithOpacity(customBrandingData?.primaryDarkColor);
          darkRoot.style.setProperty('--brand-primary', customBrandingData?.primaryDarkColor);
          darkRoot.style.setProperty(
            '--brand-primary-hover',
            !isEmpty(hoverColor) ? hoverColor : customBrandingData?.primaryDarkColor
          );
        }
      } else {
        const darkRoot = document.querySelector('.light');
        if (darkRoot && darkRoot instanceof HTMLElement) {
          darkRoot.style.setProperty('--brand-primary', '#00A16F');
          darkRoot.style.setProperty('--brand-primary-hover', '#03724F');
        }
      }
    } else {
      const hostName = getCurrentDomain();
      if (hostName === 'dash.teamcamp.app') {
        const favicon: HTMLLinkElement | null = document.querySelector('link[rel="icon"]');
        if (favicon?.href)
          favicon.href =
            // eslint-disable-next-line max-len
            'https://images.ctfassets.net/6v3c95narsix/4meyMh26aF5gp7IGa3WUmp/028b802e6183801e3b809eca10644fdb/Union.svg';
        document.title = 'Teamcamp';
        dispatch(setCustomBranding({}));
        const root = document.querySelector('.light');
        if (root && root instanceof HTMLElement) {
          root.style.setProperty('--brand-primary', '#00A16F');
          root.style.setProperty('--brand-primary-hover', '#03724F');
        }
        const darkRoot = document.querySelector('.dark');
        if (darkRoot && darkRoot instanceof HTMLElement) {
          darkRoot.style.setProperty('--brand-primary', '#00A16F');
          darkRoot.style.setProperty('--brand-primary-hover', '#03724F');
        }
      } else {
        dispatch(getBrandDataBasedOnHostname());
      }
    }
  } catch (e) {
    captureException(e);
  }
};

const loadWorkspaceFirebaseToken = (workspaceId: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    const firebaseToken = await dispatch(getFirebaseToken(workspaceId));
    const firebaseResult: any = await signInWithCustomToken(auth, firebaseToken);
    UserPreferenceSingleton.getInstance().setFirebaseToken({
      token: firebaseResult.user?.accessToken,
      workspaceId: workspaceId
    });
    auth.onIdTokenChanged(async (userDetails) => {
      if (userDetails && !_.isNull(userDetails)) {
        const user = auth.currentUser;
        const token = await user?.getIdToken();
        UserPreferenceSingleton.getInstance().setFirebaseToken({ token, workspaceId: workspaceId });
      }
    });
    auth.onAuthStateChanged(async (userDetails) => {
      if (userDetails && !_.isNull(userDetails)) {
        const user = auth.currentUser;
        const token = await user?.getIdToken();
        UserPreferenceSingleton.getInstance().setFirebaseToken({ token, workspaceId: workspaceId });
      }
    });
  } catch (e) {
    console.log('Error ', e);
    captureException(e);
  }
};

const loadProjectAndTasks = async (dispatch: Dispatch<AnyAction>) => {
  const projectResponse = await dispatch(getProjectList(true));
  dispatch(loadMyTaskData(true));
  return projectResponse;
};

export const loadInitWorkspaceDetails: any = (workspaceId: string) => async (dispatch: Dispatch<any>) => {
  try {
    dispatch(setAppInitLoading(true));
    if (workspaceId) {
      dispatch(loadWorkspaceFirebaseToken(workspaceId));
      const response = await loadProjectAndTasks(dispatch);
      return response;
    }
  } catch (e) {
    console.log('Error from load workspace details');
  } finally {
    dispatch(setAppInitLoading(false));
  }
};

const initializeWorkspace = async (dispatch: Dispatch<any>, checkForLastVisitedWorkspace: boolean) => {
  if (checkForLastVisitedWorkspace && !isDev()) {
    const lastVisitedWorkspace = await getUserPreferenceFieldData('current_workspace_id');
    if (!isEmpty(lastVisitedWorkspace)) {
      return await dispatch(getWorkspaceDetails(lastVisitedWorkspace));
    }
  }

  if (isDev()) {
    const devWorkspace = UserPreferenceSingleton.getInstance().getWorkspace();
    if (devWorkspace) return await dispatch(getWorkspaceDetails(devWorkspace?.id));
  }

  await dispatch(getWorkspaceList());
  return UserPreferenceSingleton.getInstance().getWorkspace();
};

export const appInit: any =
  (isInitLoad = false, checkForLastVisitedWorkspace = true) =>
  async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setAppInitLoading(true));
      const tagManagerArgs = {
        gtmId: 'GTM-N8SMZXPX'
      };
      TagManager.initialize(tagManagerArgs);
      const token = setupToken();
      if (!token) return;
      const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
      if (userDetails) {
        setLogUser(userDetails);
        if (isInitLoad) dispatch(getProfileSettingData(userDetails));
        if (window.electronApi?.subscribeFcm) {
          window.electronApi.subscribeFcm();
        }
        if (isReactNative()) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({ type: 'subscribe-fcm', data: { accessToken: userDetails.access_token } })
          );
        }
      }

      const workspaceDetails = await initializeWorkspace(dispatch, checkForLastVisitedWorkspace);
      if (!workspaceDetails) return { type: APP_INIT_RESPONSE_TYPE.REDIRECT, path: ROUTES.WORKSPACE };

      setWorksapceIdInAxios(workspaceDetails?.id);
      dispatch(loadWorkspaceFirebaseToken(workspaceDetails?.id));
      const [projectResponse] = await Promise.all([
        loadProjectAndTasks(dispatch),
        dispatch(getBrandDataBasedOnWorkspaceId(workspaceDetails?.id)),
        dispatch(setCurrentWorkspace(workspaceDetails)),
        setAnlayticUserAndCompany(userDetails, workspaceDetails)
      ]);
      rootStore.workspaceStore.setCurrentWorkspace(workspaceDetails);

      // Other require API calls
      if (window.location.pathname !== '/') {
        dispatch(loadInboxData());
      }
      dispatch(getCompanySettingIntegration(workspaceDetails?.id));
      dispatch(getTotalTaskCount());
      dispatch(connectToWebsocket(workspaceDetails?.id, userDetails?.id));
      dispatch(getSubscriptionList(workspaceDetails?.id));

      if (workspaceDetails?.isAdmin === true) dispatch(getInvoiceList(workspaceDetails));

      if (
        projectResponse?.length === 0 &&
        workspaceDetails?.customerRole !== CUSTOMER_MEMBER_ROLE.PROJECT_ONLY &&
        workspaceDetails?.customerRole !== CUSTOMER_MEMBER_ROLE.FULL_ACCESS &&
        (workspaceDetails?.isAdmin || workspaceDetails?.isOwner)
      ) {
        return { type: APP_INIT_RESPONSE_TYPE.REDIRECT, path: '/welcome' };
      } else {
        return { type: APP_INIT_RESPONSE_TYPE.SUCCESS };
      }
    } catch (e) {
      console.log('Error from load workspace details');
    } finally {
      dispatch(setAppInitLoading(false));
    }
  };

export const initPushNotification = async () => {
  try {
    const OneSignal = window.OneSignal || [];
    if (!OneSignal) return;

    if (!ONESIGNLE_APP_ID) return;
    if (!window.location.origin.startsWith('https://dash.teamcamp.app')) return;
    const onesignalInit = UserPreferenceSingleton.getInstance().getOneSignalInit();
    const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();

    if (!onesignalInit) {
      await OneSignal.push(async function () {
        await OneSignal.init({
          appId: ONESIGNLE_APP_ID,
          autoRegister: true,
          notifyButton: {
            enable: false
          }
        })
          .then(async () => {
            UserPreferenceSingleton.getInstance().setOneSignalInit(true);
            OneSignal.showSlidedownPrompt();
          })
          .catch((error: any) => {
            captureException(error);
            console.log('OneSignal Error', error);
          });
      });
    }

    if (!userDetails) return;
    const userTagPayload = {
      email: userDetails.email,
      userid: userDetails.id
    };

    try {
      OneSignal.push(function () {
        OneSignal.sendTags(userTagPayload)
          .then(function (tagsSent: any) {})
          .catch(function (error: any) {
            console.error('Error sending tags:', error);
          });
      });
    } catch (e) {
      captureException(e);
      console.log('Error in OneSignle-sendTags', e);
    }
    try {
      let notificationUrl = 'https://dash.teamcamp.app';
      if (isDev()) {
        notificationUrl = 'http://localhost:8080';
      }
      OneSignal.push(function () {
        window.OneSignal.setDefaultNotificationUrl(notificationUrl);
      });
    } catch (e) {
      captureException(e);
      console.log('Error in OneSignle-GetPushNotificationPermission', e);
    }
    OneSignal.push(function () {
      OneSignal.isPushNotificationsEnabled(function (isEnabled: boolean) {});
    });
    OneSignal.push(function () {
      OneSignal.on('notificationDisplay', function (event: any) {});
    });
    OneSignal.push(function () {
      OneSignal.on('notificationClicked', function (event: any) {
        console.log('Notification clicked:', event);
      });
    });
  } catch (e) {
    captureException(e);
    console.log('ERROR', e);
  }
};
