import styled from 'styled-components';
import { Typography } from '../../global/theme';

interface Props {
  isLast?: boolean;
}
const Box = styled.div<Props>`
  padding: 9px 16px;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${({ isLast }) => (isLast ? 'none' : `0.5px solid var(--border-primary)`)};
`;

const Leftcontent = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const Logo = styled.div`
  width: 28px;
  height: 28px;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  max-width: 479px;
  width: 100%;
`;

const Title = styled.div`
  color: var(--text-primary);
  ${Typography.body_lg_medium}
`;

const Desc = styled.div`
  color: var(--text-secondary);
  ${Typography.body_md}
  max-width: 479px;
  width: 100%;
`;

const Rightcontent = styled.div``;

const Tag = styled.div`
  border-radius: 6px;
  border: 1px solid var(--brand-secondary);
  background: var(--brand-secondary);
  color: var(--brand-primary);
  ${Typography.body_sm}
  padding: 2px 5px;
`;
const Tagbtn = styled.div`
  border-radius: 6px;
  border: 1px solid var(--neutral-secondary);
  background: var(--neutral-secondary);
  color: var(--text-secondary);
  ${Typography.body_sm}
  padding: 2px 5px;
`;

const Btn = styled.div`
  color: var(--brand-primary);
  ${Typography.body_md_medium}
  cursor: pointer;
  text-decoration: none;
`;

const View = styled.p`
  /* width: 76px; */
  ${Typography.body_md_medium}
  color: var(--text-primary);
  margin: 0px;
  cursor: pointer;
  span {
    color: var(--accent-error);
  }
  :hover {
    color: var(--border-secondary);
  }
`;

const ConnectedDiv = styled.div`
  .disconnect-button {
    display: none;
  }
  &:hover {
    .disconnect-button {
      display: flex;
    }
    .connect-button {
      display: none;
    }
  }
`;

export { Leftcontent, Rightcontent, Box, Logo, Description, Title, Desc, Tag, Tagbtn, Btn, View, ConnectedDiv };
