/* eslint-disable max-len */
import React, { useCallback, useEffect } from 'react';
import {
  convertBase64ToFile,
  convertBlobUrlToDataUrl,
  getFirebaseUrlsFromFiles,
  isEmpty
} from '../../../helpers/common';
import { Record, Stop } from '../styles';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { setScreenRecordingUrl } from '../../../actions/appActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaRecorder } from '../../useMediaRecorder/useMediaRecorder';
import { captureException } from '../../../services/logService';
import { setErrorMessage } from '../../../actions/messageActions';
import { addShareData } from '../../../services/projectServices';
import { SHARE_TYPE } from '../../../global/constants';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';

function RecordingComment({
  setLoading,
  setValueContent,
  iscommentModel,
  setButtonDisable,
  handleOpenModal,
  uploadSnapEmbed
}: {
  setLoading: Function;
  fieldValue: string;
  setValueContent: Function;
  iscommentModel: boolean;
  setButtonDisable: Function;
  handleOpenModal: () => void;
  uploadSnapEmbed?: (url: string, fileName: string, videoId: string) => void;
}) {
  const { status, stopRecording, mediaBlobUrl, clearBlobUrl } = useMediaRecorder();
  //use selector state variables
  const projectSelector = useSelector((state: RootReducerInterface) => state.project);
  const { currentProject } = projectSelector;
  //Other variable
  const dispatch = useDispatch();

  const onScreenCaptureComplete = useCallback(
    async (mediaBlobUrl: any) => {
      try {
        setLoading(true);
        const baseFile = await convertBlobUrlToDataUrl(mediaBlobUrl)
          .then((regularUrl) => {
            return regularUrl;
          })
          .catch((error) => {
            captureException(error);
            console.error('Error:', error.message);
          });
        const fileData: any = await convertBase64ToFile(baseFile, true);
        if (fileData?.size > 2.5e8) {
          dispatch(setErrorMessage('Please upload a file smaller than 250 MB.'));
          return false;
        }
        const firebaseUrl = await getFirebaseUrlsFromFiles([{ file: fileData }], 'screen_recordings/', dispatch);
        if (firebaseUrl) {
          const sharePayload = {
            ref: firebaseUrl,
            type: SHARE_TYPE.snap,
            projectId: currentProject?.id
          };
          const shareResponse = await dispatch(addShareData(sharePayload));
          const snapUrl = `https://share.teamcamp.app/${shareResponse?.id}`;
          dispatch(setScreenRecordingUrl(snapUrl || ''));
          const fileName = fileData?.name;

          if (uploadSnapEmbed) uploadSnapEmbed(firebaseUrl[0] || '', fileName, shareResponse?.id);
        }
      } catch (e) {
        captureException(e);
        console.log('error : ', e);
      } finally {
        clearBlobUrl();
        setButtonDisable(false);
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentProject?.id, dispatch, setButtonDisable, setLoading, setValueContent]
  );

  useEffect(() => {
    if (!isEmpty(mediaBlobUrl) && iscommentModel) onScreenCaptureComplete(mediaBlobUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaBlobUrl, iscommentModel]);

  useEffect(() => {
    if (status !== 'idle') {
      setButtonDisable(true);
    } else {
      setButtonDisable(false);
    }
  }, [setButtonDisable, status]);

  return (
    <>
      <div>
        <>
          {status !== 'recording' ? (
            <Record
              onClick={() => {
                handleOpenModal();
              }}>
              <SVGIcon name='record-start-icon' width='16' height='16' viewBox='0 0 16 16' className='svg-icon' />
            </Record>
          ) : (
            <Stop
              onClick={() => {
                stopRecording();
              }}>
              <SVGIcon name='record-stop-icon' width='16' height='16' viewBox='0 0 18 18' className='stop-icon' />
            </Stop>
          )}
        </>
      </div>
    </>
  );
}

export default RecordingComment;
