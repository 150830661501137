/* eslint-disable max-len */
import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import router from './routes/routes';
import './App.css';
import './Desktop.scss';
import './Mobile.scss';
import store from './store/store';
import PrivateRoute from './component/PrivateRoutes';
import { Route, Router, Switch } from 'react-router-dom';
import history from './history';
import { APP_INIT_RESPONSE_TYPE, COLOR_THEME, COLOR_THEME_TYPE } from './global/constants';
import { appInit, getBrandDataBasedOnHostname } from './services/appService';
import { useSelector, useDispatch } from 'react-redux';
import { setThemeType, updateThemeMode } from './actions/settingActions';
import {
  getColorWithOpacity,
  getIsDarkMode,
  isAndroid,
  isEmpty,
  isIOS,
  isIpad,
  isIphone,
  isMAC,
  isMediaRecordingSupported,
  isReactNative,
  isWindows,
  isElectronApp,
  getFirebaseUniqueUserId
} from './helpers/common';
import { captureException } from './services/logService';
import { getUserPreferenceFieldData } from './helpers/firebaseHelper';
import AppLoading from './component/loading/appLoading';
import useRudderStackAnalytics from './useRudderAnalytics';
import WindowHeader from './component/windowHeader/windowheader';
import { APP_ANALYTICS } from './global/analyticsConstants';
import TabsBar from './component/tabsBar';
import WorkSpaceLoading from './component/loading/workSpaceLoading';
import ReactScanMonitoring from './component/ReactScanMonitoring';
import { onValue, ref } from 'firebase/database';
import { getActiveTime } from './services/timeTrackingServices';
import { database, db } from './utils/firebase';
import { selectAppState } from './store/selectors';
import { AppMain, Group } from './styles/AppStyles';
import { doc, onSnapshot } from 'firebase/firestore';
import { rootStore } from './mobx/rootStore';

const MediaRecorderProvider = isMediaRecordingSupported()
  ? React.lazy(() => import('./component/useMediaRecorder/useMediaRecorder'))
  : null;

function App() {
  const analytics = useRudderStackAnalytics();
  useEffect(() => {
    if (analytics) {
      const params = {
        platform: isElectronApp() ? 'electron' : isReactNative() ? 'reactnative' : 'web'
      };
      analytics.track(APP_ANALYTICS.APP_LOADED, params);
    }
  }, [analytics]);
  // reducer selector variables
  const { themeMode, themeType, customBranding, appInitLoading, userDetails, workspace } = useSelector(selectAppState);

  const dispatch = useDispatch();
  const [isCustomBrandingLoad, setIsCustomBrandingLoad] = useState(false);
  const { location } = history;

  const loadThemeData = useCallback(async () => {
    const theme = await getUserPreferenceFieldData('theme_mode');
    if (isEmpty(theme)) {
      dispatch(setThemeType(COLOR_THEME.LIGHT));
    } else {
      dispatch(setThemeType(theme));
    }
  }, [dispatch]);

  // Set class and style to body based on theme mode
  const addClassAndStyleBasedOnTheme = useCallback(
    (theme: string) => {
      const body = document.body;
      if (theme === 'dark') {
        body.classList.add('dark');
        body.classList.remove('light');
        if (customBranding?.primaryDarkColor) {
          const hoverColor = getColorWithOpacity(customBranding?.primaryDarkColor);
          body.style.setProperty('--brand-primary', customBranding?.primaryDarkColor);
          body.style.setProperty('--brand-primary-hover', hoverColor ? hoverColor : customBranding?.primaryDarkColor);
        } else {
          body.style.setProperty('--brand-primary', '#00A16F');
          body.style.setProperty('--brand-primary-hover', '#03724F');
        }
      } else {
        body.classList.add('light');
        body.classList.remove('dark');
        if (customBranding?.primaryColor) {
          const hoverColor = getColorWithOpacity(customBranding?.primaryColor);
          body.style.setProperty('--brand-primary', customBranding?.primaryColor);
          body.style.setProperty('--brand-primary-hover', hoverColor ? hoverColor : customBranding?.primaryColor);
        } else {
          body.style.setProperty('--brand-primary', '#00A16F');
          body.style.setProperty('--brand-primary-hover', '#03724F');
        }
      }
    },
    [customBranding?.primaryColor, customBranding?.primaryDarkColor]
  );

  useEffect(() => {
    if ((window as any).electronApi) {
      (window as any).electronApi.setHistory(history);
    }

    if (isReactNative()) {
      window.addEventListener('message', (message) => {
        let dataPayload;
        try {
          dataPayload = JSON.parse(message.data);
        } catch {
          return;
        }

        if (!dataPayload) return;

        const { type, data } = dataPayload;

        if (type === 'redirect') {
          const url = new URL(data);
          history.push(url.pathname + url.search);
        }
      });
    }

    // Access the body tag
    const body = document.body;
    if (isElectronApp()) {
      body.classList.add('electron');

      if (isWindows()) {
        body.classList.add('windows');
      }

      if (isMAC()) {
        body.classList.add('mac');
      }
    }

    if (isReactNative()) {
      body.classList.add('react-native');

      if (isIOS()) {
        body.classList.add('ios');
      }

      if (isIphone()) {
        body.classList.add('iphone');
      }

      if (isIpad()) {
        body.classList.add('ipad');
      }

      if (isAndroid()) {
        body.classList.add('android');
      }
    }

    // Apply class based on theme
    addClassAndStyleBasedOnTheme(themeMode?.theme);

    if ((window as any).electronApi && themeType) {
      if (themeType === COLOR_THEME.DARK) {
        (window as any).electronApi.changeTheme('dark');
      } else if (themeType === COLOR_THEME.SYSTEM_DEFAULT) {
        (window as any).electronApi.changeTheme('system');
      } else {
        (window as any).electronApi.changeTheme('light');
      }
    }
    if (isReactNative()) {
      if (themeType === COLOR_THEME.DARK) {
        (window as any).ReactNativeWebView.postMessage(JSON.stringify({ type: 'update-theme', data: 'dark' }));
      } else if (themeType === COLOR_THEME.SYSTEM_DEFAULT) {
        (window as any).ReactNativeWebView.postMessage(JSON.stringify({ type: 'update-theme', data: 'system' }));
      } else {
        (window as any).ReactNativeWebView.postMessage(JSON.stringify({ type: 'update-theme', data: 'light' }));
      }
    }
  }, [addClassAndStyleBasedOnTheme, themeMode.theme, themeType]);

  const loadBrandingDetails = useCallback(async () => {
    try {
      await store.dispatch(getBrandDataBasedOnHostname());
    } catch (e) {
      captureException(e);
    } finally {
      setIsCustomBrandingLoad(true);
    }
  }, []);

  useEffect(() => {
    loadBrandingDetails();
    async function init() {
      try {
        const result = await store.dispatch(appInit(true));
        if (result && result?.type === APP_INIT_RESPONSE_TYPE.REDIRECT) {
          history.push(result.path);
        }
      } catch (e) {
        captureException(e);
        console.log('ERROR', e);
      }
    }
    init();
  }, [dispatch, loadBrandingDetails]);

  useEffect(() => {
    loadThemeData();
  }, [loadThemeData]);

  const loadFirebaseTimerData = useCallback(() => {
    try {
      if (!isEmpty(workspace?.id)) {
        const uniqueId = getFirebaseUniqueUserId();
        const docRef1 = ref(db, `timer/${uniqueId?.uniqueUserId}`);
        onValue(docRef1, async (snap) => {
          snap.val();
          await dispatch(getActiveTime());
        });
      }
    } catch (e: any) {
      captureException(e);
    }
  }, [dispatch, workspace?.id]);

  useEffect(() => {
    loadFirebaseTimerData();
  }, [loadFirebaseTimerData]);

  useEffect(() => {
    if (!userDetails?.id) return;

    const unsubscribe = onSnapshot(doc(database, 'user_preferences', userDetails?.id), () => {
      rootStore.projectStore.updateProjectIndexes();
    });

    return unsubscribe;
  }, [userDetails?.id]);

  useEffect(() => {
    const isDarkMode: boolean = getIsDarkMode();
    // Listen for changes in the color scheme preference
    const colorSchemeListener = (e: MediaQueryListEvent) => {
      if (e.matches && themeType === COLOR_THEME.SYSTEM_DEFAULT) {
        dispatch(updateThemeMode({ propsName: 'theme', value: COLOR_THEME_TYPE.DARK }));
      } else if (!e.matches && themeType === COLOR_THEME.SYSTEM_DEFAULT) {
        dispatch(updateThemeMode({ propsName: 'theme', value: COLOR_THEME_TYPE.LIGHT }));
      }
    };

    // Add a listener for changes in the color scheme preference
    let colorSchemeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    colorSchemeMediaQuery.addEventListener('change', colorSchemeListener);
    if (themeType === COLOR_THEME.SYSTEM_DEFAULT) {
      dispatch(
        updateThemeMode({
          propsName: 'theme',
          value: isDarkMode ? COLOR_THEME_TYPE.DARK : COLOR_THEME_TYPE.LIGHT
        })
      );
    } else {
      dispatch(
        updateThemeMode({
          propsName: 'theme',
          value: themeType === COLOR_THEME.DARK ? COLOR_THEME_TYPE.DARK : COLOR_THEME_TYPE.LIGHT
        })
      );
    }

    // Clean up the listener when the component unmounts
    return () => {
      colorSchemeMediaQuery?.removeEventListener('change', colorSchemeListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, themeType]);

  const excludeTabsRoutes = useMemo(
    () => [
      ...router.filter((route) => route && !route.private).map((route) => route!.path),
      '/select-workspace',
      '/onboarding',
      '/membership'
    ],
    []
  );
  const excludeTabs = useMemo(
    () => !userDetails?.id || excludeTabsRoutes.some((x) => location.pathname?.includes(x)),
    [userDetails?.id, excludeTabsRoutes, location.pathname]
  );

  const routeComponents = router?.map((item: any, i) => {
    if (item?.private) {
      return <PrivateRoute exact key={i} {...item} />;
    } else {
      return <Route exact key={i} {...item} />;
    }
  });

  return (
    <>
      <div
        style={{ height: 30, background: 'transparent', position: 'absolute', width: '100%' }}
        className='drag-header'></div>
      {appInitLoading ? (
        <Group>
          <WorkSpaceLoading />
        </Group>
      ) : (
        <AppMain>
          {isMediaRecordingSupported() && MediaRecorderProvider ? (
            <Suspense
              fallback={
                <div>
                  <AppLoading />
                </div>
              }>
              <MediaRecorderProvider>
                {isCustomBrandingLoad && (
                  <Router history={history}>
                    <ReactScanMonitoring />
                    {/* <WindowHeader /> */}
                    {excludeTabs || <TabsBar />}
                    <Switch>{routeComponents}</Switch>
                  </Router>
                )}
              </MediaRecorderProvider>
            </Suspense>
          ) : (
            <>
              {isCustomBrandingLoad && (
                <Router history={history}>
                  <ReactScanMonitoring />
                  <WindowHeader />
                  {excludeTabs || <TabsBar />}
                  <Switch>{routeComponents}</Switch>
                </Router>
              )}
            </>
          )}
        </AppMain>
      )}
    </>
  );
}

export default App;
