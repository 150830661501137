import { useCallback } from 'react';
import { UPLOAD_FILES_MENU_OPTION } from '../../../global/constants';
import DropdownItem from '../../Dropdown/DropdownItem';

interface Props {
  onClickUploadFile?: () => void;
  onClickSelectFile?: () => void;
  isSelectFileEnable?: boolean;
  isTemplateProject?: boolean;
}

export default function CommentDropdown(props: Props) {
  const { onClickUploadFile, onClickSelectFile, isSelectFileEnable = true, isTemplateProject } = props;
  //Other variable
  const MenuItems = [
    { key: UPLOAD_FILES_MENU_OPTION.UPLOAD_FILE, label: 'Upload file' },
    ...(isSelectFileEnable && !isTemplateProject
      ? [{ key: UPLOAD_FILES_MENU_OPTION.SELECT_FILE, label: 'Select file' }]
      : [])
  ];
  const handleMenuClick = useCallback(
    (key: string | number) => {
      if (Number(key) === UPLOAD_FILES_MENU_OPTION.UPLOAD_FILE) {
        onClickUploadFile?.();
      } else if (Number(key) === UPLOAD_FILES_MENU_OPTION.SELECT_FILE) {
        onClickSelectFile?.();
      }
    },
    [onClickSelectFile, onClickUploadFile]
  );

  return (
    <>
      {MenuItems.map((item) => (
        <DropdownItem key={item.key} label={item.label} onClick={() => handleMenuClick(item.key)} />
      ))}
    </>
  );
}
