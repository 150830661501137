import PropTypes from 'prop-types';
import { DatePicker as AntdDatePicker, ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import { getFormattedDate, isEmpty } from '../../helpers/common';
import SVGIcon from '../../assets/images/svg/SVGIcon';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import { useSelector } from 'react-redux';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

interface Props {
  label: any;
  value: any;
  inputFormat: any;
  onChange: any;
}

const DatePicker = ({ label, value, inputFormat = 'dd/MM/yyyy', onChange, ...props }: Props) => {
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { settings: settingsSelector } = stateSelector || {};
  const { profileData } = settingsSelector;
  const { dateFormat } = profileData;

  const defaultData: any = getFormattedDate(new Date(value));
  const primaryColour = document.documentElement.style.getPropertyValue('--brand-primary');
  const config = {
    token: {
      colorBgElevated: 'var(--background-primary)',
      colorText: 'var(--text-primary)',
      colorPrimary: primaryColour,
      colorPrimaryHover: 'var(--brand-primary-hover)',
      colorLink: primaryColour,
      colorLinkHover: primaryColour,
      colorLinkActive: primaryColour,
      colorSplit: 'var(--border-primary)',
      colorTextDisabled: 'var(--text-secondary)',
      colorFillTertiary: 'var(--neutral-secondary)',
      colorIcon: 'var(--text-primary)'
    }
  };
  const format = dateFormat || inputFormat;

  if (!isEmpty(value) && !isEmpty(defaultData)) {
    return (
      <>
        <ConfigProvider theme={config}>
          <AntdDatePicker
            getPopupContainer={(trigger: HTMLElement) => trigger.parentNode as HTMLElement}
            suffixIcon={
              <SVGIcon name='datepicker-icon' width='18' height='18' viewBox='0 0 18 18' fill='var(--text-primary)' />
            }
            placeholder={label}
            onChange={onChange}
            value={!isEmpty(defaultData) ? dayjs(defaultData, 'DD/MM/YYYY') : undefined}
            format={format}
            allowClear={true}
          />
        </ConfigProvider>
      </>
    );
  } else {
    return (
      <>
        <ConfigProvider theme={config}>
          <AntdDatePicker
            getPopupContainer={(trigger: HTMLElement) => trigger.parentNode as HTMLElement}
            suffixIcon={
              <SVGIcon name='datepicker-icon' width='18' height='18' viewBox='0 0 18 18' fill='var(--text-primary)' />
            }
            placeholder={label}
            onChange={onChange}
            format={format}
            allowClear={true}
          />
        </ConfigProvider>
      </>
    );
  }
};

export default DatePicker;
DatePicker.propTypes = {
  label: PropTypes.string,
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  inputFormat: PropTypes.string
};
