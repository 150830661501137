import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GroupModalhead, Groupbody, Groupmodal, Groupmodel, Icon, Inputvalues, Modeltitle, Todotext } from './styles';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import Button from '../../button';
import { clearStatusInput, setStatusInput, updateStatusInput } from '../../../actions/projectActions';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import { addStatusData, updateStatusData } from '../../../services/projectServices';
import { updateProjectTemplateDetails } from '../../../actions/settingActions';
import { nanoid } from 'nanoid';
interface Props {
  onCancel: () => void;
  statusType: number;
  projectId: string;
  loadData: () => void;
  isTemplateProject: boolean;
}

const AddStatusModal: React.FC<Props> = (props) => {
  const { onCancel, statusType, projectId, loadData, isTemplateProject } = props;
  //States
  const [loading, setLoading] = useState(false);
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { settings: settingsSelector, project: projectSelector } = stateSelector || {};
  const { projectTemplateDetails } = settingsSelector;
  const { statusInput } = projectSelector;
  //Other variable
  const dispatch = useDispatch();

  const onCloseModal = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const handleChangeDetails = useCallback(
    (propsName: string, value: string) => {
      dispatch(updateStatusInput({ propsName, value }));
    },
    [dispatch]
  );

  const updateTemplateProjectData = useCallback(() => {
    if (statusInput?.id) {
      const updatedStatusList = projectTemplateDetails?.statusData?.map((status) => {
        if (status?.['_id'] === statusInput?.id) {
          return { ...(status || {}), Name: statusInput?.name };
        } else return status;
      });
      dispatch(
        updateProjectTemplateDetails({
          propsName: 'statusData',
          value: updatedStatusList
        })
      );
    } else {
      const newStatus = {
        _id: nanoid(),
        Type: statusType,
        Name: statusInput?.name,
        Index: new Date().getTime()
      };

      dispatch(
        updateProjectTemplateDetails({
          propsName: 'statusData',
          value: [...(projectTemplateDetails?.statusData || []), newStatus]
        })
      );
    }
  }, [statusInput?.id, statusInput?.name, projectTemplateDetails?.statusData, dispatch, statusType]);

  const onSubmit = useCallback(
    async (e: React.SyntheticEvent) => {
      setLoading(true);
      try {
        e.preventDefault();
        if (isTemplateProject) {
          updateTemplateProjectData();
        } else {
          if (statusInput?.id) {
            const payload = {
              name: statusInput?.name
            };
            await dispatch(updateStatusData(projectId, statusInput?.id, payload));
          } else {
            const payload = {
              index: new Date().getTime(),
              name: statusInput?.name,
              type: statusType
            };
            dispatch(setStatusInput(payload));
            await dispatch(addStatusData(projectId, payload));
          }
          loadData();
        }
        onCancel();
      } catch (error) {
        console.log('error', error);
      } finally {
        setLoading(false);
        dispatch(clearStatusInput());
      }
    },
    [
      dispatch,
      isTemplateProject,
      loadData,
      onCancel,
      projectId,
      statusInput?.id,
      statusInput?.name,
      statusType,
      updateTemplateProjectData
    ]
  );

  return (
    <div>
      <Groupmodel onSubmit={onSubmit}>
        <GroupModalhead>
          <Todotext>{statusInput?.id ? 'Edit Status' : 'Add status'} </Todotext>
          <Icon onClick={onCloseModal}>
            <SVGIcon name='close-icon' width='18' height='18' viewBox='0 0 18 18' className='closeicon' />
          </Icon>
        </GroupModalhead>
        <Groupbody>
          <Modeltitle>Status name</Modeltitle>
          <Inputvalues
            placeholder={statusInput?.id ? 'Enter new status name' : 'Enter your status name'}
            value={statusInput?.name}
            onChange={(e) => handleChangeDetails('name', e.target.value)}
          />
        </Groupbody>
        <Groupmodal>
          <Button
            type={'submit'}
            title={statusInput?.id ? 'Edit Status' : 'Add Status'}
            isLoading={loading}
            modelbtn={true}
          />
        </Groupmodal>
      </Groupmodel>
    </div>
  );
};
export default AddStatusModal;
