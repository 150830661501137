import styled from 'styled-components';
import { Typography } from '../../../global/theme';

const Form = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const Inputvalues = styled.input`
  width: 100%;
  padding: 8px 12px;
  ${Typography.body_lg}
  color: var(--text-primary);
  background-color: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 6px;
  outline: none;

  :focus {
    border: 0.5px solid var(--brand-primary);
    z-index: 999;
  }
  ::placeholder {
    ${Typography.body_lg}
    color: var(--text-secondary);
  }
  ::-webkit-inner-spin-button {
    display: none;
  }
`;

const Iconview = styled.div`
  display: flex;
`;

const Formbutton = styled.div`
  display: flex;
  gap: 12px;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0 16px 16px 16px;
`;
const Icon = styled.div`
  display: inline-flex;
  .close-icon {
    cursor: pointer;
    path {
      stroke: var(--text-primary);
    }
    :hover {
      background-color: var(--neutral-secondary);
      border-radius: 4px;
    }
  }
`;
const MainModalDiv = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 632px;
  background-color: var(--background-primary);
  border-radius: 12px;
  box-shadow: var(--shadow-modal);
  @media (max-width: 449px) {
    width: 100%;
  }
`;
const Modalblock = styled.form`
  display: flex;
  flex-direction: column;
`;

const Modalhead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 0.5px solid var(--border-primary);
`;

const Headline = styled.h6`
  ${Typography.heading_md}
  color: var(--text-primary);
  margin: 0;
`;

const Formation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 16px 24px;
`;

const Label = styled.label`
  ${Typography.body_md_medium}
  color: var(--text-secondary);
  span {
    color: var(--accent-error);
  }
`;

const Textarea = styled.textarea`
  padding: 8px 12px;
  ${Typography.body_lg}
  color: var(--text-primary);
  border: none;
  background-color: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 6px;
  resize: none;
  outline: none;
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
  }
  :focus {
    border: 0.5px solid var(--brand-primary);
    z-index: 999;
  }

  ::placeholder {
    ${Typography.body_lg}
    color: var(--text-secondary);
  }
`;
export {
  MainModalDiv,
  Form,
  Formbutton,
  Icon,
  Iconview,
  Inputvalues,
  Formation,
  Modalblock,
  Headline,
  Modalhead,
  Label,
  Textarea
};
