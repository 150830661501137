import { combineReducers } from 'redux';
import { createReducer } from '../helpers/reduxHelpers';
import { createReducer as createReducerOrig, PayloadAction, current } from '@reduxjs/toolkit';
import * as Actions from '../actions/types';
import { ReducerInterface } from '../interfaces/ReducerInterface';

const setCustomerLoaderReducer = createReducer({
  initialState: false,
  actionType: Actions.SET_CUSTOMER_LOADER
});

const setCustomerListReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_CUSTOMER_LIST
});

const filteredCustomerListReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_FILTERED_CUSTOMER_LIST
});

const initialState = {
  ProfileImage: '',
  firstName: '',
  lastName: '',
  companyName: '',
  email: '',
  phone: '',
  address: ''
};

const createCustomerReducer = createReducerOrig(initialState, (builder) => {
  builder
    .addCase(Actions.SET_CUSTOMER_DETAIL, (state = initialState, action: PayloadAction<any, never>) => {
      return { ...(action.payload || {}) };
    })
    .addCase(Actions.UPDATE_CUSTOMER_DETAIL, (state, action: PayloadAction<ReducerInterface, never>) => {
      const customer = JSON.parse(JSON.stringify(current(state)));
      customer[action.payload.propsName] = action.payload.value;
      return customer;
    })
    .addCase(Actions.CLEAR_CUSTOMER_DETAIL, () => {
      const initialData = JSON.parse(JSON.stringify(initialState));
      return initialData;
    });
});

const setIndividualCustomerDetailReducer = createReducer({
  initialState: {},
  actionType: Actions.SET_INDIVIDUAL_CUSTOMER_DETAIL
});

const setCustomerProjectDetailReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_CUSTOMER_PROJECT_DETAIL
});

const setCustomerInvoiceListReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_CUSTOMERS_INVOICE_LIST
});

const setCustomerProjectListReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_CUSTOMERS_PROJECT_LIST
});

const initialMemberInputState = {
  name: '',
  role: undefined,
  title: '',
  email: ''
};
const memberInputReducer = createReducerOrig(initialMemberInputState, (builder) => {
  builder
    .addCase(Actions.SET_MEMBER_INPUT, (state = initialMemberInputState, action: PayloadAction<any, any>) => {
      return { ...(action.payload || {}) };
    })
    .addCase(Actions.UPDATE_MEMBER_INPUT, (state, action: PayloadAction<any, any>) => {
      const member = JSON.parse(JSON.stringify(current(state)));
      member[action.payload.propsName] = action.payload.value;
      return member;
    })
    .addCase(Actions.CLEAR_MEMBER_INPUT, () => {
      const initialData = JSON.parse(JSON.stringify(initialMemberInputState));
      return initialData;
    });
});

const customerMembersReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_CUSTOMER_MEMBERS
});

const customerReducer = combineReducers({
  loading: setCustomerLoaderReducer,
  customers: setCustomerListReducer,
  filteredCustomerList: filteredCustomerListReducer,
  newCustomer: createCustomerReducer,
  individualCustomer: setIndividualCustomerDetailReducer,
  customerProjectDetail: setCustomerProjectDetailReducer,
  customerInvoiceList: setCustomerInvoiceListReducer,
  customerProjectList: setCustomerProjectListReducer,
  memberInput: memberInputReducer,
  customerMembers: customerMembersReducer
});

export default customerReducer;
