import styled from 'styled-components';
import { Typography } from '../../../global/theme';

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 0 16px;
  width: 100%;
  max-width: 662px;
  margin: 0 auto;
  align-items: center;
`;
const Group = styled.div`
  width: 100%;
  max-width: 662px;
  margin: 0 auto;
  margin-bottom: 24px;
`;
const Personaltext = styled.div`
  ${Typography.heading_lg}
  color: var(--text-primary);
`;
const BottomContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const SessionContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  h2 {
    margin: 0;
    color: var(--text-primary);
    ${Typography.heading_md};
  }
  p {
    margin: 0;
    color: var(--text-secondary);
    ${Typography.body_md};
  }
`;
const Card = styled.div`
  background: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  padding: 8px 15px;
`;
const LeftContent = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;
const ActiveTag = styled.div`
  background: var(--brand-secondary);
  color: var(--brand-primary);
  border-radius: 6px;
  border: 1px solid var(--brand-secondary);
  padding: 2px 5px;
  ${Typography.body_sm_medium};
`;
const Icon = styled.div`
  width: 32px;
  height: 32px;
  border: 0.5px solid var(--neutral-secondary);
  background-color: var(--background-tertiary);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  .svg-icon {
    path {
      fill: var(--text-secondary);
    }
  }
  .svg-stroke-color {
    path {
      stroke: var(--text-secondary);
    }
  }
  .arc-icon {
    path {
      fill: var(--text-secondary);
      stroke: var(--background-tertiary);
    }
  }
`;

const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  h2 {
    margin: 0;
    color: var(--text-primary);
    ${Typography.body_lg_medium};
  }
  p {
    margin: 0;
    color: var(--text-secondary);
    ${Typography.body_sm};
  }
`;
const SubHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  h2 {
    margin: 0;
    color: var(--text-primary);
    ${Typography.heading_md};
  }
`;
const CardSection = styled.div`
  background: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 8px;
`;
const Cards = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  padding: 9px 15px 8px 15px;
  border-bottom: 0.5px solid var(--border-primary);
  cursor: pointer;
  &:hover {
    background-color: var(--neutral-secondary);
  }
  :last-child {
    border-bottom: none;
    border-radius: 0 0 8px 8px;
  }
  :first-child {
    border-radius: 8px 8px 0 0;
  }
`;
const Details = styled.div`
  display: flex;
  gap: 2px;
  flex-wrap: wrap;
  p {
    margin: 0;
    color: var(--text-secondary);
    ${Typography.body_sm};
    white-space: nowrap;
  }
`;
const Doticon = styled.div`
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Dot = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: var(--text-placeholder);
`;
export {
  Header,
  Group,
  Personaltext,
  BottomContent,
  SessionContent,
  Content,
  Card,
  LeftContent,
  ActiveTag,
  Icon,
  TitleSection,
  SubHeader,
  Cards,
  CardSection,
  Details,
  Doticon,
  Dot
};
