import styled from 'styled-components';

const Main = styled.div`
  max-width: 662px;
  width: 100%;
  margin: 26px auto 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (max-width: 449px) {
    margin: 0 auto;
  }
  .loading-animation {
    overflow: hidden;
  }

  .loading-animation::before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    animation: loading 1s infinite;
    background: linear-gradient(to right, transparent, var(--background-tertiary), transparent);
  }

  @keyframes loading {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  @media (max-width: 449px) {
    .fourth-block {
      display: none;
    }
    .fifth-block {
      display: none;
    }
  }
`;
const Firstblock = styled.div`
  padding: 12px 15px 12px 18px;
  background-color: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 12px;
  display: flex;
  gap: 12px;
  z-index: 1111;
  @media (max-width: 449px) {
    z-index: 0;
    padding: 12px 12px 12px 18px;
    gap: 16px;
  }
`;
const Avtar = styled.div`
  width: 28px;
  height: 28px;
  &.avtar {
    width: 20px;
    height: 20px;
  }
`;
const Profile = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: var(--background-muted);
  &.avtar {
    width: 20px;
    height: 20px;
  }
`;
const Rightside = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  &.right-side {
    flex-direction: row;
    gap: 15px;
    justify-content: space-between;
    width: 100%;
  }
  @media (max-width: 449px) {
    gap: 8px;
    width: 100%;
    &.right-side {
      flex-direction: column;
      gap: 8px;
    }
  }
`;
const Subdiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding-top: 5px;
  &.right-side {
    padding-bottom: 5px;
  }
  @media (max-width: 449px) {
    &.third-block {
      flex-direction: column-reverse;
    }
    &.right-side {
      padding-bottom: 0px;
    }
  }
`;
const Top = styled.div`
  width: 162px;
  height: 10px;
  border-radius: 6px;
  background-color: var(--background-muted);
  &.bottom {
    width: 463px;
  }
  &.right {
    width: 200px;
  }
  &.top {
    width: 481px;
  }
  &.bottomline {
    width: 463px;
  }
  @media (max-width: 768px) {
    &.bottomline {
      width: 342px;
    }
    &.top {
      width: 318px;
    }
    &.bottom {
      width: 344px;
    }
  }
  @media (max-width: 449px) {
    &.bottom {
      width: -webkit-fill-available;
    }
    &.top {
      width: 210px;
    }
    &.top-bottom {
      width: 116px;
    }
    &.right {
      width: 100%;
    }
    &.bottomline {
      width: 100%;
    }
  }
`;

const Bottom = styled.div`
  width: 112px;
  height: 26px;
  border-radius: 16px;
  background-color: var(--background-muted);
  &.first {
    width: 99px;
  }
  &.second {
    width: 90px;
  }
  @media (max-width: 449px) {
    &.second {
      display: none;
    }
  }
`;
const Leftside = styled.div`
  width: 62px;
  height: 16px;
  border-radius: 16px;
  background-color: var(--background-muted);
  @media (max-width: 449px) {
    display: none;
  }
`;

const Subbox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Subblock = styled.div`
  padding: 11px;
  border-radius: 8px;
  width: 483px;
  background-color: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (max-width: 768px) {
    width: 100%;
    max-width: -webkit-fill-available;
  }
`;

const Bottomdiv = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const Leftdiv = styled.div`
  width: 88px;
  height: 74px;
  @media (max-width: 320px) {
    width: 68px;
    height: 68px;
  }
`;

const Square = styled.div`
  width: 88px;
  height: 74px;
  border-radius: 4px;
  background-color: var(--background-muted);
  @media (max-width: 320px) {
    width: 68px;
    height: 68px;
  }
`;
const Rightdiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  @media (max-width: 449px) {
    width: 100%;
  }
`;
const Detail = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;
const Buttondiv = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;
const Box = styled.div`
  padding: 9px 11px;
  border-radius: 8px;
  width: 481px;
  background-color: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  display: flex;
  flex-direction: column;
  gap: 14px;
  @media (max-width: 768px) {
    width: 100%;
    max-width: -webkit-fill-available;
  }
`;
const Responsivebottomdiv = styled.div`
  display: none;
  width: 62px;
  height: 16px;
  border-radius: 16px;
  background-color: var(--background-muted);
  @media (max-width: 449px) {
    display: flex;
  }
`;
export {
  Main,
  Firstblock,
  Profile,
  Rightside,
  Top,
  Bottom,
  Subdiv,
  Leftside,
  Avtar,
  Subbox,
  Subblock,
  Bottomdiv,
  Leftdiv,
  Rightdiv,
  Square,
  Detail,
  Buttondiv,
  Box,
  Responsivebottomdiv
};
