import styled, { css } from 'styled-components';
import { Typography } from '../../global/theme';

interface Props {
  isActive?: boolean;
}

const Main = styled.div`
  margin-bottom: 20px;
  border: 0.5px solid var(--border-primary);
  border-radius: 8px;
  overflow: hidden;
  margin-top: 20px;
`;

const Table = styled.table`
  background-color: var(--background-primary);
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px;
  border-style: hidden;
  tr {
    border-top: 0.5px solid var(--border-primary);
    &:first-child {
      border-top: none;
      background: var(--background-tertiary);
      border-radius: 8px 8px 0 0;
    }
    &:last-child {
      border-radius: 0 0 8px 8px;
    }
    @media (max-width: 449px) {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      align-items: center;
      &:first-child {
        border-top: none;
        background-color: var(--background-primary);
        border-radius: 8px 8px 0 0;
      }
    }
  }
  tr:not(:first-child):hover {
    cursor: pointer;
    background-color: var(--neutral-secondary);
    border-radius: 0;
    width: 100%;
    tr:last-child {
      border-radius: 0 0 8px 8px;
    }
    @media (max-width: 449px) {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      align-items: center;
      background-color: transparent;
    }
  }
  th {
    ${Typography.body_md_medium}
    color: var(--text-primary);
    padding: 10px 16px 10px 0;
    margin: 0;
    text-align: left;
    &:first-child {
      padding: 10px 16px;
    }
    &:last-child {
      padding: 10px 16px 10px 0;
    }
  }
  p {
    margin: 0;
  }

  .last {
    width: 24px;
    height: 24px;
  }
  td {
    ${Typography.body_md_medium}
    color: var(--text-primary);
    padding: 8px 16px 8px 0;
    margin: 0;
    text-align: left;
    &:first-child {
      padding: 8px 20px 8px 16px;
    }
    &:last-child {
      padding: 8px 16px 8px 0;
    }
    @media (max-width: 449px) {
      max-width: 312px;
    }
    &:first-child {
      padding: 8px 0 8px 16px;
    }
    &:last-child {
      padding: 8px 16px 8px 0;
    }
    &.second-column {
      color: var(--text-secondary);
    }
    &.third-column {
      color: var(--text-secondary);
    }
  }
`;

const Icon = styled.div`
  width: 28px;
  height: 28px;
  .file-list-icon {
    path {
      stroke: var(--text-primary);
    }
  }
`;

const ResponsiveDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const DocumtentTitle = styled.p`
  ${Typography.body_md_medium}
  color: var(--text-primary);
  margin: 0;
  text-align: left;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
`;

const DocCreated = styled.p`
  ${Typography.body_sm_medium}
  color: var(--text-secondary);
  margin: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
`;

const First = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const Moreicon = styled.div<Props>`
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .fillColor {
    fill: var(--text-secondary);
    stroke: var(--text-secondary);
  }
  :hover {
    background-color: var(--background-muted);
    border-radius: 4px;
  }
  ${(props) =>
    props.isActive &&
    css`
      background-color: var(--neutral-secondary);
      border-radius: 4px;
    `}
`;

const FileIconDiv = styled.div`
  position: relative;
  .file-text {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 35%;
    left: 25%;
    width: 50%;
    font-weight: 700;
    color: var(--text-secondary);
  }
`;
export { Main, Table, Icon, First, Moreicon, FileIconDiv, ResponsiveDiv, DocumtentTitle, DocCreated };
