import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setProjectTemplateDetails,
  setTemplateFilteredTasksList,
  setTemplateOtherTasks,
  setTemplateTaskGroups,
  updateProjectTemplateDetails
} from '../../../actions/settingActions';
import { getUrlParam, isEmpty } from '../../../helpers/common';
import AppLayout from '../../../component/appLayout';
import TemplateHeader from '../../../component/templateHeader';
import TasksPage from '../../project/details/tasks/taskPage';
import { useLocation, useParams } from 'react-router-dom';
import { Group, TitleSetting } from './styles';
import SettingCards from '../../../component/projectSetting';
import { getProjectTemplateDetails } from '../../../services/settingServices';
import { captureException } from '../../../services/logService';
import { USER_ROLE } from '../../../global/constants';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';

const TemplateProjectDetails: React.FC = () => {
  // States
  const [loading, setLoading] = useState(false);
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { settings: settingsSelector, workspace: workspaceSelector } = stateSelector || {};
  const { projectTemplateDetails, templateTaskGroups, templateFilteredTasksList, templateOtherTasks, themeMode } =
    settingsSelector;
  const { workspace } = workspaceSelector;
  // Other variables
  const params: { id: string } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const tab = getUrlParam(location.search, 'tab');
  const filteredWorkspaceUsers = workspace?.users?.filter((item) => item?.role !== USER_ROLE.CLIENT) || [];

  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      if (projectTemplateDetails && workspace) {
        if (projectTemplateDetails?.statusEnable && projectTemplateDetails?.statusData?.length > 0) {
          const updatedGroups = projectTemplateDetails?.statusData?.map((x) => {
            return {
              id: x?.['_id'],
              index: x?.Index,
              name: x?.Name,
              projectId: projectTemplateDetails?.id,
              isDefault: x?.Default,
              isCollapse: false
            };
          });
          const updatedTasks = projectTemplateDetails?.tasks?.map((x) => {
            const users = x?.taskUsers?.map((x: any) => {
              const user = workspace?.users?.find((y: any) => x?.['_id'] === y?.id);
              return user;
            });
            return {
              ...x,
              users,
              priority:
                projectTemplateDetails?.priority?.enabled && isEmpty(x?.priority)
                  ? projectTemplateDetails?.priority?.default
                  : x?.priority
            };
          });
          // dispatch(setTemplateTaskGroups(updatedGroups));
          const groupList = updatedGroups?.map((x) => x?.id);
          const taskOpen = updatedGroups?.map((item) => {
            if (item?.isDefault) {
              const otherTasks = updatedTasks?.filter((ele) => !ele?.statusId || !groupList?.includes(ele?.statusId));
              let filteredTasks = updatedTasks?.filter((ele) => ele?.statusId === item?.id);
              return { ...item, tasks: [...filteredTasks, ...otherTasks], id: item?.id };
            }
            let filteredTasks = updatedTasks?.filter((ele) => ele?.statusId === item?.id);
            return { ...item, tasks: filteredTasks, id: item?.id };
          });
          dispatch(setTemplateFilteredTasksList(taskOpen));
          dispatch(setTemplateOtherTasks({ tasks: [] }));
          return;
        }
        const updatedGroups = projectTemplateDetails?.groups?.map((x) => {
          return { id: x?.id, index: x?.index, name: x?.name, projectId: x?.projectId, isCollapse: false };
        });
        const updatedTasks = projectTemplateDetails?.tasks?.map((x) => {
          const users = x?.taskUsers?.map((x: any) => {
            const user = workspace?.users?.find((y: any) => x?.['_id'] === y?.id);
            return user;
          });
          return {
            ...x,
            users,
            priority:
              projectTemplateDetails?.priority?.enabled && isEmpty(x?.priority)
                ? projectTemplateDetails?.priority?.default
                : x?.priority
          };
        });
        dispatch(setTemplateTaskGroups(updatedGroups));
        const groupList = updatedGroups?.map((x) => x?.id);
        const otherOpen = updatedTasks?.filter((item) => isEmpty(item.groupId) && !groupList?.includes(item?.groupId));
        const taskOpen = updatedGroups?.map((item) => {
          let filteredTasks = updatedTasks?.filter((ele) => ele?.groupId === item?.id);
          return { ...item, tasks: filteredTasks, id: item?.id };
        });
        dispatch(setTemplateFilteredTasksList(taskOpen));
        dispatch(setTemplateOtherTasks({ tasks: otherOpen }));
      }
    } catch (e) {
      captureException(e);
    } finally {
      setLoading(false);
    }
  }, [dispatch, projectTemplateDetails, workspace]);

  const loadTemplateDetails = useCallback(async () => {
    if (!isEmpty(workspace?.id) && !isEmpty(params?.id)) {
      await dispatch(getProjectTemplateDetails(workspace, params?.id));
    }
  }, [dispatch, params?.id, workspace]);

  useEffect(() => {
    loadData();
  }, [loadData, projectTemplateDetails?.tasks]);

  useEffect(() => {
    loadTemplateDetails();
  }, [loadTemplateDetails]);

  useEffect(() => {
    return () => {
      dispatch(setTemplateFilteredTasksList([]));
      dispatch(setTemplateOtherTasks([]));
      dispatch(setProjectTemplateDetails({}));
    };
  }, [dispatch]);

  const onUpdateMileStone = useCallback(
    async (value: boolean) => {
      dispatch(updateProjectTemplateDetails({ propsName: 'isMilestone', value }));
    },
    [dispatch]
  );

  const onUpdateMultiAssignee = useCallback(
    async (value: boolean) => {
      dispatch(updateProjectTemplateDetails({ propsName: 'multiAssignee', value }));
    },
    [dispatch]
  );

  const onUpdateStatus = useCallback(
    (value: boolean) => {
      if (!projectTemplateDetails?.statusData || projectTemplateDetails?.statusData?.length === 0) {
        let defaultStatus = [
          {
            _id: 'xi8KbkF-NXQ5bMPZ0efQx1',
            Type: 1,
            Name: 'Backlog',
            Default: true,
            Index: 1720862851642
          },
          {
            _id: 'xi8GbkF-NXQ5bMPZ0efQx2',
            Type: 2,
            Name: 'Todo',
            Index: 1720862851642
          },
          {
            _id: 'xi8DbkF-NXQ5bMPZ0efQx3',
            Type: 3,
            Name: 'In Progress',
            Index: 1720862851642
          },
          {
            _id: 'xi8BbkF-NXQ5bMPZ0efQx4',
            Type: 4,
            Name: 'In Review',
            Index: 1720862851642
          },
          {
            _id: 'xi8RbkF-NXQ5bMPZ0efQx5',
            Type: 5,
            Name: 'Done',
            Index: 1720862851642
          }
        ];
        dispatch(updateProjectTemplateDetails({ propsName: 'statusData', value: defaultStatus }));
        dispatch(updateProjectTemplateDetails({ propsName: 'defaultStatus', value: defaultStatus[0] }));
      }
      dispatch(updateProjectTemplateDetails({ propsName: 'statusEnable', value }));
    },
    [dispatch, projectTemplateDetails]
  );

  const onUpdateEstimate = useCallback(
    async (value: boolean) => {
      dispatch(updateProjectTemplateDetails({ propsName: 'isEstimate', value }));
    },
    [dispatch]
  );

  const onChangeDefault = useCallback(
    async (key: any) => {
      const priorityClone = JSON.parse(JSON.stringify(projectTemplateDetails?.priority || {}));
      priorityClone.default = Number(key);
      dispatch(updateProjectTemplateDetails({ propsName: 'priority', value: priorityClone }));
    },
    [dispatch, projectTemplateDetails?.priority]
  );

  const onUpdatePriority = useCallback(
    async (value: boolean) => {
      const priorityClone = JSON.parse(JSON.stringify(projectTemplateDetails?.priority || {}));
      priorityClone.enabled = value;
      dispatch(updateProjectTemplateDetails({ propsName: 'priority', value: priorityClone }));
    },
    [dispatch, projectTemplateDetails?.priority]
  );

  const onUpdateGroup = useCallback(
    async (value: boolean) => {
      dispatch(updateProjectTemplateDetails({ propsName: 'isGroupEnabled', value }));
    },
    [dispatch]
  );

  const onUpdateLabels = useCallback(
    async (value: boolean) => {
      dispatch(updateProjectTemplateDetails({ propsName: 'labelsEnabled', value }));
    },
    [dispatch]
  );

  return (
    <>
      <AppLayout>
        <TemplateHeader isSettingpage={tab === 'setting'} />
        {tab === 'setting' ? (
          <>
            <Group>
              <TitleSetting>Project Settings</TitleSetting>
              <SettingCards
                isTemplateProject={true}
                currentProject={projectTemplateDetails}
                themeMode={themeMode}
                onUpdateMileStone={(value) => onUpdateMileStone(value)}
                onUpdateEstimate={(value) => onUpdateEstimate(value)}
                onChangeDefault={(key) => onChangeDefault(key)}
                onUpdatePriority={(value) => onUpdatePriority(value)}
                onUpdateLabels={(value) => onUpdateLabels(value)}
                taskGroups={projectTemplateDetails?.groups}
                loading={loading}
                paramsId={params?.id}
                setLoading={(value: boolean) => setLoading(value)}
                loadData={loadData}
                workspaceUsers={filteredWorkspaceUsers}
                customersUsers={[]}
                workspaceDetails={workspace}
                onUpdateStatus={onUpdateStatus}
                onUpdateGroup={onUpdateGroup}
                onUpdateMultiAssignee={(value) => onUpdateMultiAssignee(value)}
                loadingButton={{
                  group: false,
                  status: false,
                  priority: false,
                  label: false,
                  multiAssignee: false,
                  estimation: false,
                  milestone: false
                }}
              />
            </Group>
          </>
        ) : (
          <TasksPage
            propsLoading={false}
            loadData={() => loadData()}
            otherTask={templateOtherTasks}
            setOtherTask={(items) => dispatch(setTemplateOtherTasks(items))}
            isPadding={true}
            taskGroups={templateTaskGroups}
            groupingStatus={1}
            filteredTasksList={templateFilteredTasksList}
            currentProject={projectTemplateDetails}
            isTemplateProject={true}
          />
        )}
      </AppLayout>
    </>
  );
};

export default TemplateProjectDetails;
