import styled from 'styled-components';

const Main = styled.div`
  display: flex;
  flex-direction: column;
  .loading-animation {
    overflow: hidden;
  }
  .loading-animation::before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    animation: loading 1s infinite;
    background: linear-gradient(to right, transparent, var(--background-tertiary), transparent);
  }

  @keyframes loading {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;

const Loadingbox = styled.div`
  padding: 14px 12px 13px;
  border-bottom: 0.5px solid var(--border-primary);
`;

const Loaddata = styled.div`
  display: flex;
  gap: 12px;
  justify-content: space-between;
`;
const Leftloading = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const Rightloading = styled.div`
  width: 38px;
  height: 8px;
  background-color: var(--background-muted);
  border-radius: 7px;
`;
const Left = styled.div`
  width: 30px;
  height: 30px;
  background-color: var(--background-muted);
  border-radius: 50%;
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Top = styled.div`
  width: 100px;
  height: 9px;
  background-color: var(--background-muted);
  border-radius: 7px;
`;

const Bottom = styled.div`
  width: 160px;
  height: 6px;
  background-color: var(--background-muted);
  border-radius: 7px;
`;

export { Loadingbox, Loaddata, Leftloading, Rightloading, Left, Right, Top, Bottom, Main };
