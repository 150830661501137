import {
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  DropdownMenu,
  DropdownWrapper,
  Icon,
  ModalCrossIcon,
  ModalHeader,
  ModalTitle,
  ResponsiveDropdownMenu,
  ResponsiveModalMainDiv,
  ResponsiveSearchInput,
  TriggerWrapper
} from '../styles';
import { createPortal } from 'react-dom';
import DropdownDivider from '../DropdownDivider';
import DropdownSearch from '../DropdownSearch';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { isEmpty } from '../../../helpers/common';
import { useMobileDevice } from '../../../global/useMobile';

interface DropdownProps {
  content: ReactNode;
  children: ReactNode;
  trigger?: 'click' | 'hover' | 'contextmenu';
  customStyle?: React.CSSProperties;
  isMinWidth?: number;
  searchResult?: any[]; // Add searchResult prop
  onOutsideClick?: () => void;
  onChangeSearch?: (value: string) => void;
  activeClassName?: string; // Optional class for active state
  setCurrentProject?: () => void;
  updateDropdownOpen?: (value: boolean) => void;
  modalTitle?: string;
}

const Dropdown = forwardRef(
  (
    {
      content,
      children,
      trigger = 'click',
      customStyle = {},
      isMinWidth = 120,
      onOutsideClick,
      onChangeSearch,
      searchResult = [],
      activeClassName = 'active', // Default active class
      setCurrentProject,
      updateDropdownOpen,
      modalTitle
    }: DropdownProps,
    ref
  ) => {
    // State to manage the dropdown's open/close status
    const [isOpen, setIsOpen] = useState(false);

    // State to variable
    const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
    const [calculateMenuPosition, setCalculateMenuPosition] = useState({ top: 0, left: 0 });

    // References variable
    const triggerRef = useRef<HTMLDivElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);

    // Reference for managing timeout (used for hover trigger)
    const timeoutRef = useRef<NodeJS.Timeout>();

    // Check if the screen size is mobile
    const isMobile = useMobileDevice();

    // Handle mouse enter for hover trigger
    const handleMouseEnter = useCallback(() => {
      if (trigger === 'hover') {
        // Clear any existing timeouts (both enter and leave)
        clearTimeout(timeoutRef.current);
        // Set a delay before opening the dropdown
        timeoutRef.current = setTimeout(() => {
          updateDropdownOpen?.(true);
          setIsOpen(true);
        }, 100); // 300ms delay before showing
      }
    }, [trigger, updateDropdownOpen]);

    // Handle mouse leave for hover trigger
    const handleMouseLeave = useCallback(() => {
      if (trigger === 'hover') {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
          updateDropdownOpen?.(false);
          setIsOpen(false);
        }, 100); // 300ms delay before hiding
      }
    }, [trigger, updateDropdownOpen]);

    // Handle click for contextmenu trigger
    const handleContextMenu = useCallback(
      (event: React.MouseEvent) => {
        if (trigger === 'contextmenu') {
          event.preventDefault(); // Prevent default context menu

          setCurrentProject?.();
          updateDropdownOpen?.(true);

          const { clientX, clientY } = event;
          const viewportWidth = window.innerWidth;
          const viewportHeight = window.innerHeight;
          const scrollX = window.scrollX;
          const scrollY = window.scrollY;

          // Initial position before opening
          let top = clientY + scrollY;
          let left = clientX + scrollX;

          // Open dropdown at initial position
          setCalculateMenuPosition({ top, left });
          setIsOpen(true);

          requestAnimationFrame(() => {
            if (!dropdownRef.current) return;
            const dropdownRect = dropdownRef.current.getBoundingClientRect();

            let newTop = top;
            let newLeft = left;

            // Adjust if dropdown overflows viewport
            if (clientX + dropdownRect.width > viewportWidth) {
              newLeft = viewportWidth - dropdownRect.width - 10 + scrollX;
            }
            if (clientY + dropdownRect.height > viewportHeight) {
              newTop = viewportHeight - dropdownRect.height - 10 + scrollY;
            }

            // Ensure position remains unchanged if no movement is needed
            if (newLeft !== top || newTop !== left) {
              setCalculateMenuPosition((prev) => {
                if (prev.top !== newTop || prev.left !== newLeft) {
                  return { top: newTop, left: newLeft };
                }
                return prev;
              });
            }
          });
        }
      },
      [setCurrentProject, trigger, updateDropdownOpen] // Removed calculateMenuPosition to prevent stale updates
    );

    // Function to calculate the dropdown's position
    const calculatePosition = useCallback(() => {
      if (!triggerRef.current || !dropdownRef.current) return;

      const triggerRect = triggerRef.current.getBoundingClientRect();
      const dropdownRect = dropdownRef.current.getBoundingClientRect();
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;
      const scrollY = window.scrollY;
      const scrollX = window.scrollX;

      let top = triggerRect.bottom + scrollY; // Default dropdown below trigger
      let left = triggerRect.left + scrollX;

      const offset = 2;
      const parentDropdownItem = triggerRef.current.closest('.dropdown-item');

      if (parentDropdownItem) {
        // Adjust for submenu positioning
        const itemRect = parentDropdownItem.getBoundingClientRect();
        top = triggerRect.top + scrollY;
        left = triggerRect.right + scrollX + offset;

        // Ensure submenu doesn't overflow the viewport width
        if (left + dropdownRect.width > viewportWidth) {
          left = itemRect.left - dropdownRect.width + scrollX - offset;
        }

        // Ensure submenu doesn't overflow the viewport height
        if (top + dropdownRect.height > viewportHeight) {
          top = viewportHeight - dropdownRect.height + scrollY;
        }
      }

      // Ensure dropdown fits within the viewport
      if (left + dropdownRect.width > viewportWidth) {
        left = viewportWidth - dropdownRect.width - 10 + scrollX;
      }
      if (top + dropdownRect.height > viewportHeight) {
        top = triggerRect.top - dropdownRect.height - 5 + scrollY;
      }

      setDropdownPosition((prev) => {
        if (prev.top !== top || prev.left !== left) {
          return { top, left };
        }
        return prev;
      });
    }, []);

    useEffect(() => {
      if (isOpen) {
        calculatePosition();
      }
    }, [calculatePosition, isOpen, searchResult]);

    // Handle click for click trigger
    const handleClick = useCallback(
      (event: React.MouseEvent) => {
        if (trigger === 'click') {
          setIsOpen((prev) => {
            if (!prev) calculatePosition();
            updateDropdownOpen?.(!prev);
            return !prev;
          }); // Toggle dropdown
          event.stopPropagation();
        } else {
          setIsOpen(false);
          updateDropdownOpen?.(false);
        }
      },
      [calculatePosition, trigger, updateDropdownOpen]
    );

    useEffect(() => {
      if (!isOpen) return;

      // Store the current scroll position
      const scrollPosition = window.scrollY;

      // Create a scroll lock class
      const style = document.createElement('style');
      style.innerHTML = `
        .scroll-lock {
          position: fixed;
          top: -${scrollPosition}px;
          left: 0;
          width: 100%;
          overflow-y: scroll;
        }
      `;
      document.head.appendChild(style);

      // Apply the scroll lock
      document.body.classList.add('scroll-lock');

      // Cleanup function
      return () => {
        // Remove the scroll lock
        document.body.classList.remove('scroll-lock');

        // Restore scroll position
        window.scrollTo(0, scrollPosition);

        // Remove the style element
        document.head.removeChild(style);
      };
    }, [isOpen]);

    // Close dropdown when clicking outside
    const handleClickOutside = useCallback(
      (event: MouseEvent) => {
        const targetElement = event.target as HTMLElement;
        if (targetElement.closest('.search-div')) {
          return;
        }
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(targetElement) &&
          triggerRef.current &&
          !triggerRef.current.contains(targetElement) &&
          !targetElement.closest('.reference-item')
        ) {
          setIsOpen(false);
          updateDropdownOpen?.(false);
          onOutsideClick?.();
        }
      },
      [onOutsideClick, updateDropdownOpen]
    );

    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside, true);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside, true);
      };
    }, [handleClickOutside]);
    // ------------------------------------------Page Scroll----------------------------------------------------------

    useEffect(() => {
      if (!isOpen) return;

      // Store original body overflow style
      const originalOverflow = document.body.style.overflow;

      // Disable body scrolling
      document.body.style.overflow = 'hidden';

      // Additional prevention for touch devices and edge cases
      const preventDefault = (e: Event) => {
        const targetElement = e.target as HTMLElement;
        // Allow scrolling if it's inside the dropdown or submenu
        if (dropdownRef.current?.contains(e.target as Node) || targetElement.closest('.dropdown-item')) {
          return;
        }
        // Prevent scrolling everywhere else
        e.preventDefault();
      };

      document.addEventListener('wheel', preventDefault, { passive: false });
      document.addEventListener('touchmove', preventDefault, { passive: false });

      return () => {
        // Restore original overflow style
        document.body.style.overflow = originalOverflow;
        document.removeEventListener('wheel', preventDefault);
        document.removeEventListener('touchmove', preventDefault);
      };
    }, [isOpen]);

    // Using useLayoutEffect to ensure the dropdown position is calculated before rendering
    useLayoutEffect(() => {
      if (isOpen) {
        calculatePosition();
      }
    }, [isOpen, calculatePosition]);

    // Functions call when we call it from parent component
    useImperativeHandle(ref, () => ({
      closeDropdown: () => {
        setIsOpen(false);
        updateDropdownOpen?.(false);
      }
    }));

    // Render search input
    const renderSearchInput = useMemo(() => {
      return (
        <>
          <DropdownSearch onChangeSearch={onChangeSearch} />
          <DropdownDivider />
        </>
      );
    }, [onChangeSearch]);

    const renderResponsiveSearchInput = useMemo(() => {
      return (
        <ResponsiveSearchInput>
          <Icon>
            <SVGIcon name='dropdown-search-icon' width='18' height='18' viewBox='0 0 18 18' />
          </Icon>
          <DropdownSearch onChangeSearch={onChangeSearch} />
        </ResponsiveSearchInput>
      );
    }, [onChangeSearch]);

    return (
      <DropdownWrapper>
        {/* Trigger element */}
        <TriggerWrapper
          ref={triggerRef}
          onClick={handleClick}
          onContextMenu={handleContextMenu}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className={isOpen ? activeClassName : ''}
          trigger={trigger}>
          {content}
          {/* Dropdown menu (rendered using React Portal) */}
          {isOpen &&
            createPortal(
              isMobile ? (
                // Modal-style dropdown on mobile
                <ResponsiveModalMainDiv>
                  <ResponsiveDropdownMenu ref={dropdownRef}>
                    {!isEmpty(modalTitle) && (
                      <>
                        <ModalHeader>
                          <ModalTitle>{modalTitle}</ModalTitle>
                        </ModalHeader>
                        <ModalCrossIcon>
                          <SVGIcon name='modal-cross-icon' width='18' height='18' viewBox='0 0 18 18' />
                        </ModalCrossIcon>
                      </>
                    )}
                    {onChangeSearch && renderResponsiveSearchInput}
                    {children}
                  </ResponsiveDropdownMenu>
                </ResponsiveModalMainDiv>
              ) : (
                <DropdownMenu
                  ref={dropdownRef}
                  style={{
                    top: trigger === 'contextmenu' ? calculateMenuPosition.top : dropdownPosition.top,
                    left: trigger === 'contextmenu' ? calculateMenuPosition.left : dropdownPosition.left,
                    ...customStyle
                  }}
                  className='dropdown-item'
                  isMinWidth={isMinWidth}>
                  {onChangeSearch && renderSearchInput}
                  {children}
                </DropdownMenu>
              ),
              document.body
            )}
        </TriggerWrapper>
      </DropdownWrapper>
    );
  }
);

export default Dropdown;
