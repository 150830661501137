import styled, { css } from 'styled-components';
interface Props {
  isOpen?: boolean;
  isTracker?: boolean;
  isWidth?: number;
  isZindex?: boolean;
  isMaxWidth?: boolean;
  isFreePlanModel?: boolean;
  isSubtaskpopup?: boolean;
  isHelpSupportModal?: boolean;
  hasNotBoxShadow?: boolean;
  isGifModal?: boolean;
  isContextMenu?: boolean;
}

const ModalOverlay = styled.div<Props>`
  display: ${(isOpen: Props) => (isOpen ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--background-overlay);
  align-items: center;
  justify-content: center;
  z-index: 999;
  ${(props: Props) =>
    props.isZindex &&
    css`
      z-index: 9999;
    `}
`;

const ModalContent = styled.div<Props>`
  background-color: var(--background-primary);
  border-radius: 12px;
  box-shadow: var(--shadow-modal);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  ${(props: Props) =>
    !props.isTracker &&
    css`
      width: 100%;
      max-width: 693px;
    `}
  ${(props: Props) =>
    props.isContextMenu &&
    css`
      width: unset;
      max-width: unset;
      transform: unset;
    `}
  ${(props: Props) =>
    props.isWidth &&
    css`
      width: ${props.isWidth}px;
    `}
      ${(props: Props) =>
    props.isZindex &&
    css`
      z-index: 9999;
    `}
      ${(props: Props) =>
    props.isMaxWidth &&
    css`
      max-width: unset !important;
      @media (max-width: 768px) {
        width: calc(100% - 32px);
        max-width: 90%;
      }
      @media (max-width: 449px) {
        width: calc(100% - 32px) !important;
      }
    `}
  @media (max-width: 449px) {
    ${(props: Props) =>
      !props.isTracker &&
      css`
        max-width: calc(100vw - 32px);
        width: 100%;
      `};
  }
  ${(props: Props) =>
    props.isFreePlanModel &&
    css`
      max-width: 692px;
      width: 100%;
      position: fixed;
      @media (max-width: 768px) {
        max-width: calc(100% - 32px);
      }
    `}
  ${(props: Props) =>
    props.isSubtaskpopup &&
    css`
      max-width: 598px;
      width: 100%;
    `};
  ${(props: Props) =>
    props.isHelpSupportModal &&
    css`
      @media (max-width: 449px) {
        position: fixed;
      }
    `}
  ${(props: Props) =>
    props.hasNotBoxShadow &&
    css`
      box-shadow: none;
    `}
    ${(props: Props) =>
    props.isGifModal &&
    css`
      max-width: 686px;
      width: 100%;
    `}
`;

export { ModalOverlay, ModalContent };
