import React, { useCallback, useState } from 'react';
import { Bottom, Form, Formbutton, Inputvalue, Label, Left, Main, Right, Top } from './styles';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import Button from '../../button';
import { useDispatch } from 'react-redux';
import { createApiKey } from '../../../services/settingServices';
import { isEmpty } from '../../../helpers/common';
import { setErrorMessage } from '../../../actions/messageActions';
import { captureException } from '../../../services/logService';

interface Props {
  onCancel: () => void;
  loadData: () => void;
  workspace_id: string;
}
const Addapimodal: React.FC<Props> = (props) => {
  const { onCancel, loadData, workspace_id } = props;
  //States
  const [apiName, setApiName] = useState('');
  const [loading, setLoading] = useState(false);
  //Other variable
  const dispatch = useDispatch();

  const onChangeInput = useCallback((name: string) => {
    setApiName(name);
  }, []);

  const onClickCancel = useCallback(() => {
    setApiName('');
    onCancel();
  }, [onCancel]);

  const onSubmit = useCallback(
    async (e: React.SyntheticEvent) => {
      try {
        e.preventDefault();
        setLoading(true);
        if (isEmpty(apiName)) {
          dispatch(setErrorMessage('Please enter name.'));
          return;
        }
        const result = await dispatch(createApiKey(workspace_id, apiName));
        if (result) {
          onClickCancel();
          loadData();
        }
      } catch (error) {
        captureException(error);
        console.log('error', error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    },
    [apiName, dispatch, loadData, onClickCancel, workspace_id]
  );

  return (
    <Main onSubmit={onSubmit}>
      <Top>
        <Left>Create API key</Left>
        <Right onClick={onClickCancel}>
          <SVGIcon name='close-icon' width='18' height='18' viewBox='0 0 18 18' className='close-icon' />
        </Right>
      </Top>
      <Bottom>
        <Form>
          <Label>Name (This is just for your referrance)</Label>
          <Inputvalue
            type='text'
            placeholder='Enter your key name '
            onChange={(e) => onChangeInput(e.target.value)}
            value={apiName}
          />
        </Form>
        <Formbutton>
          <Button
            title='Cancel'
            secondary={true}
            type='button'
            onClick={onClickCancel}
            modelbtn={true}
            hasNotBoxshadow
          />
          <Button title='Create' type='submit' isLoading={loading} disabled={loading} modelbtn={true} />
        </Formbutton>
      </Bottom>
    </Main>
  );
};

export default Addapimodal;
