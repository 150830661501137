import styled from 'styled-components';
import { Typography } from '../../../global/theme';

const Group = styled.div`
  width: 100%;
  margin: auto;

  .loading-animation {
    overflow: hidden;
  }

  .loading-animation::before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    animation: loading 1s infinite;
    background: linear-gradient(to right, transparent, var(--background-tertiary), transparent);
  }

  @keyframes loading {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: 1022px;
  background-color: var(--background-tertiary);
  border: 0.5px solid var(--border-primary);
  border-radius: 8px;
  margin: 26px auto;
`;

const Tableheader = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 12px;
  gap: 40px;
`;

const Titlewrap = styled.h2`
  width: 100%;
  max-width: 582px;
  ${Typography.body_md_medium}
  text-transform: uppercase;
  color: var(--text-secondary);
  margin: 0;
`;

const Titlewrapper = styled.h3`
  width: 100%;
  max-width: 165px;
  ${Typography.body_md_medium}
  text-transform: uppercase;
  color: var(--text-secondary);
  margin: 0;
  border-left: 0.5px solid var(--border-primary);
  padding: 2px 0 2px 14px;
`;

const Titlewrappers = styled.h4`
  width: 100%;
  max-width: 141px;
  ${Typography.body_md_medium}
  text-transform: uppercase;
  color: var(--text-secondary);
  margin: 0;
  border-left: 0.5px solid var(--border-primary);
  padding: 2px 0 2px 14px;
`;

const Tabledata = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 12px;
  background-color: var(--background-primary);
  border-top: 0.5px solid var(--border-primary);
  :last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .more-icon {
    path {
      fill: var(--background-muted);
      stroke: var(--background-muted);
    }
  }
`;

const Userdetails = styled.div`
  width: 100%;
  max-width: 582px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Username = styled.div`
  width: 100px;
  height: 9px;
  background-color: var(--background-muted);
  border-radius: 7px;
`;

const Userdescription = styled.div`
  width: 120px;
  height: 6px;
  background-color: var(--background-muted);
  border-radius: 7px;
`;

const Usermail = styled.div`
  width: 80px;
  height: 6px;
  background-color: var(--background-muted);
  border-radius: 7px;
`;

const Usernumber = styled.div`
  width: 70px;
  height: 6px;
  background-color: var(--background-muted);
  border-radius: 7px;
`;

const Privatedata = styled.div`
  width: 100%;
  max-width: 290px;
  display: flex;
  justify-content: space-between;
`;

export {
  Group,
  Container,
  Tableheader,
  Titlewrap,
  Titlewrapper,
  Titlewrappers,
  Tabledata,
  Userdetails,
  Username,
  Userdescription,
  Usermail,
  Usernumber,
  Privatedata
};
