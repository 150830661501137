import { useCallback } from 'react';
import { Item, Submenu } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { updateCreateTaskInput } from '../../actions/taskActions';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';
import { updateRecurringTaskDetails } from '../../services/taskServices';

const daysInMonth = Array.from({ length: 31 }, (_, i) => i + 1);

interface Props {
  className?: string;
  data?: any;
  loadData?: any;
}
export default function SubMenuMonthly(props: Props) {
  //use selector state variables
  const taskSelector = useSelector((state: RootReducerInterface) => state.task);
  const { createTaskInput } = taskSelector;
  //Other variables
  const dispatch = useDispatch();

  const setMonthlyRecurringTask = useCallback(
    async (day: string) => {
      try {
        const payloadTask = {
          projectId: props?.data?.projectId,
          start: day.toLowerCase(),
          recurringInterval: 'monthly'
        };
        const response = await dispatch(updateRecurringTaskDetails(props?.data?.id, payloadTask));
        if (response) {
          props?.loadData();
        }
        dispatch(updateCreateTaskInput({ propsName: 'start', value: day }));
        dispatch(updateCreateTaskInput({ propsName: 'recurringInterval', value: 'monthly' }));
      } catch (error) {
        console.log('error', error);
      }
    },
    [dispatch, props]
  );
  return (
    <Submenu className={props?.className} isSubmenuMonthly>
      {daysInMonth.map((day) => (
        <Item
          className='monthly'
          key={day}
          isSelected={createTaskInput?.start === day.toString()}
          onClick={() => setMonthlyRecurringTask(day.toString())}>
          {day}
        </Item>
      ))}
    </Submenu>
  );
}
