import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearPasswordDetail, updatePasswordDetail } from '../../actions/authActions';
import AppLayout from '../../component/appLayout';
import { changePasswordDetail } from '../../services/authServices';
import {
  Group,
  Details,
  Form,
  Formblock,
  Inputvalue,
  Label,
  Personaltext,
  PersonalHeader,
  Formmain,
  Header
} from './styles';
import Button from '../../component/button';
import { isEmpty } from '../../helpers/common';
import { setErrorMessage } from '../../actions/messageActions';
import { captureException } from '../../services/logService';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';
import { ResponsiveHeader } from './SettingHeaderResponsive';
import { useMobileDevice } from '../../global/useMobile';

const Password: React.FC = () => {
  // States
  const [loading, setLoading] = useState(false);
  //use selector state variables
  const auth = useSelector((state: RootReducerInterface) => state.auth);
  const { passwordDetail } = auth;
  const { oldPassword, newPassword, confirmPassword } = passwordDetail;
  // Other variables
  const dispatch = useDispatch();
  const isMobile = useMobileDevice();

  const onChangePassword = useCallback(
    (propsName: string, value: any) => {
      dispatch(updatePasswordDetail({ propsName, value }));
    },
    [dispatch]
  );

  const onSubmit = useCallback(
    async (e: React.SyntheticEvent) => {
      try {
        e.preventDefault();
        setLoading(true);
        if (isEmpty(oldPassword)) {
          dispatch(setErrorMessage('Please enter current password.'));
          return;
        } else if (isEmpty(newPassword)) {
          dispatch(setErrorMessage('Please enter new password.'));
          return;
        } else if (isEmpty(confirmPassword)) {
          dispatch(setErrorMessage('Please enter confirm password.'));
          return;
        } else if (newPassword !== confirmPassword) {
          dispatch(setErrorMessage('Please enter new password and confirm password correctly.'));
          return;
        }
        const payload = {
          oldPassword: oldPassword,
          newPassword: newPassword,
          confirmPassword: confirmPassword
        };
        const result = await dispatch(changePasswordDetail(payload));
        if (result) {
          dispatch(clearPasswordDetail());
        }
      } catch (error) {
        captureException(error);
        console.log('error', error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    },
    [dispatch, oldPassword, newPassword, confirmPassword]
  );

  return (
    <>
      <AppLayout>
        <Group onSubmit={onSubmit} isMiddlecontainer={true}>
          <PersonalHeader>
            <Header>
              {isMobile && <ResponsiveHeader />}
              <Personaltext>Change Password</Personaltext>
            </Header>
          </PersonalHeader>
          <Details>
            <Formmain>
              <Formblock isNewRow={true}>
                <Form>
                  <Label>Current Password</Label>
                  <Inputvalue
                    type='password'
                    value={oldPassword}
                    onChange={(e) => {
                      onChangePassword('oldPassword', e.target.value);
                    }}
                  />
                </Form>
                <Form>
                  <Label>New Password</Label>
                  <Inputvalue
                    type='password'
                    value={newPassword}
                    onChange={(e) => {
                      onChangePassword('newPassword', e.target.value);
                    }}
                  />
                </Form>
                <Form>
                  <Label>Confirm Password</Label>
                  <Inputvalue
                    type='password'
                    value={confirmPassword}
                    onChange={(e) => {
                      onChangePassword('confirmPassword', e.target.value);
                    }}
                  />
                </Form>
              </Formblock>
              <Button title='Save' type='submit' isLoading={loading} disabled={loading} modelbtn={true} />
            </Formmain>
          </Details>
        </Group>
      </AppLayout>
    </>
  );
};
export default Password;
