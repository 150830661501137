import styled from 'styled-components';
import { Typography } from '../../global/theme';

const Main = styled.div`
  border-radius: 8px;
  border: 0.5px solid var(--border-primary);
  background: var(--background-primary);
  padding: 11px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 186px;
  @media (max-width: 449px) {
    width: 164px;
  }
  @media (max-width: 375px) {
    width: 139px;
  }
`;
const Title = styled.div`
  ${Typography.body_md_medium}
  color: var(--text-primary);
`;
const Price = styled.div`
  ${Typography.heading_md}
  color: var(--text-primary);
`;
export { Main, Title, Price };
