import { createReducer } from '../helpers/reduxHelpers';
import * as Actions from '../actions/types';
import { combineReducers } from 'redux';

const setHelpCenterLoader = createReducer({
  initialState: false,
  actionType: Actions.SET_HELP_CENTER_LOADER
});

const helpCenterReducer = combineReducers({
  loading: setHelpCenterLoader
});

export default helpCenterReducer;
