/* eslint-disable max-len */
import {
  ActionSection,
  ExpandCloseSection,
  Icon,
  LogoIcon,
  MacHeader,
  NavigateArea,
  PlusIcon,
  TabCloseIcon,
  Tabs,
  TabsBarSection,
  TabsName,
  WindowsHeaderIcon
} from './styles';
import SVGIcon from '../../assets/images/svg/SVGIcon';
import BackForwardIcon from '../windowHeader/backForwardIcon';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import history from '../../history';
import { useSelector } from 'react-redux';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';
import { STATUS_TYPE } from '../../global/constants';
import { useDispatch } from 'react-redux';
import { setIsNavbarShow } from '../../actions/appActions';
import { setCurrentChatGroup } from '../../actions/chatMessageActions';

type Tab = {
  id: number;
  label: string;
  icon: string;
  path: string;
  iconProps: {
    width?: string;
    height?: string;
    className?: string;
    stroke?: string;
    viewBox?: string;
  };
};

const DEFAULT_PATH = '/';
const DEFAULT_TAB_ID = 1;
const MAX_TABS = 10;

export default function TabsBar() {
  const { location } = history;
  const dispatch = useDispatch();

  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const {
    project: projectSelector,
    task: tasksSelector,
    chatMessage: chatSelector,
    workspace: workspaceSelector
  } = stateSelector || {};
  const { currentProject } = projectSelector || {};
  const { taskDetails } = tasksSelector || {};
  const { currentChatGroup, chatGroups } = chatSelector || {};
  const { workspace } = workspaceSelector || {};

  const getIconAndLabel = useCallback(
    (path: string) => {
      const paths: Record<string, string> = {
        '/my-tasks': 'my-task',
        '/invoices': 'invoice',
        '/timeTracking': 'time-tracking',
        '/report': 'report',
        '/projects': 'project',
        '/messages': 'messages',
        '/customer': 'customer',
        '/settings': 'settings',
        '/members': 'members',
        '/': 'inbox'
      };

      const matchedKey = Object.keys(paths).find((key) => path.startsWith(key));

      const defaultIconAndLabel = { icon: '', label: 'Unknown', iconProps: {} };
      if (!matchedKey) {
        return defaultIconAndLabel;
      }

      switch (paths[matchedKey]) {
        case 'my-task':
          return { icon: 'navbar-MyTask-icon', label: 'My Tasks', iconProps: {} };
        case 'invoice':
          return { icon: 'navbar-Invoice-icon', label: 'Invoices', iconProps: {} };
        case 'time-tracking':
          return { icon: 'navbar-TimeTracking-icon', label: 'Time Tracking', iconProps: {} };
        case 'report':
          return { icon: 'navbar-Report-icon', label: 'Reports', iconProps: {} };
        case 'project': {
          if (path.includes('task=')) {
            if (currentProject.statusEnable) {
              const defaultStatus = currentProject.statusData.find((status) => status.Default);
              const status =
                currentProject.statusData.find((status) => status['_id'] === taskDetails.statusId) || defaultStatus;
              switch (status?.Type ?? STATUS_TYPE.UNSTARTED) {
                case STATUS_TYPE.BACKLOG:
                  return {
                    icon: 'task-status-icon',
                    label: taskDetails?.name || 'Task',
                    iconProps: { className: 'task-status-icon', viewBox: '0 0 16 16' }
                  };
                case STATUS_TYPE.CANCELLED:
                  return {
                    icon: 'task-cancelled-icon',
                    label: taskDetails?.name || 'Task',
                    iconProps: { className: 'task-cancelled-icon', viewBox: '0 0 16 16' }
                  };
                case STATUS_TYPE.COMPLETED:
                  return {
                    icon: 'task-completed-icon',
                    label: taskDetails?.name || 'Task',
                    iconProps: { className: 'task-completed-icon', viewBox: '0 0 16 16' }
                  };
                case STATUS_TYPE.IN_PROGRESS:
                  return {
                    icon: 'task-inprogress-icon',
                    label: taskDetails?.name || 'Task',
                    iconProps: { className: 'task-inprogress-icon', viewBox: '0 0 16 16' }
                  };
                case STATUS_TYPE.IN_REVIEW:
                  return {
                    icon: 'task-inreview-icon',
                    label: taskDetails?.name || 'Task',
                    iconProps: { className: 'task-inreview-icon', viewBox: '0 0 16 16' }
                  };
                case STATUS_TYPE.UNSTARTED: // White background
                  return {
                    icon: 'task-todo-icon',
                    label: taskDetails?.name || 'Task',
                    iconProps: { viewBox: '0 0 16 16' }
                  };
                default:
                  return {
                    icon: 'task-todo-icon',
                    label: taskDetails?.name || 'Task',
                    iconProps: { viewBox: '0 0 16 16' }
                  };
              }
            } else {
              if (taskDetails.status) {
                return {
                  icon: 'task-completed-icon',
                  label: taskDetails?.name || 'Task',
                  iconProps: { className: 'task-completed-icon', viewBox: '0 0 16 16' }
                };
              } else {
                // Check mark white
                return {
                  icon: 'open-icon',
                  label: taskDetails?.name || 'Task',
                  iconProps: { className: 'open-icon', viewBox: '0 0 20 20' }
                };
              }
            }
          }
          return {
            icon: 'navbar-project-icon',
            label: currentProject?.name || 'Project',
            iconProps: { className: 'navbar-project-icon' }
          };
        }
        case 'messages':
          return { icon: 'navbar-Messages-icon', label: currentChatGroup?.name || 'Messages', iconProps: {} };
        case 'customer':
          return { icon: 'navbar-Customer-icon', label: 'Customers', iconProps: {} };
        case 'settings':
          return { icon: 'settings-icon', label: 'Settings', iconProps: {} };
        case 'inbox':
          return { icon: 'navbar-inbox-icon', label: 'Inbox', iconProps: { className: 'navbar-inbox-icon' } };
        case 'members':
          const match = path.match(/^\/members\/([^/]+)(?:\/|$)/);
          const memberId = match ? match[1] : null;
          const memberDetails = workspace.users.find((user) => user.id === memberId);
          return { icon: 'user-icon', label: memberDetails?.name || 'Unkown', iconProps: { className: 'user-icon' } };
        default:
          return defaultIconAndLabel;
      }
    },
    [
      currentChatGroup?.name,
      currentProject?.name,
      currentProject.statusData,
      currentProject.statusEnable,
      taskDetails?.name,
      taskDetails.status,
      taskDetails.statusId,
      workspace.users
    ]
  );

  // States
  const [nextTabId, setNextTabId] = useState<number>(DEFAULT_TAB_ID + 1);
  const [selectedTabId, setSelectedTabId] = useState<number>(DEFAULT_TAB_ID);
  const path = location.pathname + location.search;
  const { icon, label, iconProps } = useMemo(() => getIconAndLabel(path), [getIconAndLabel, path]);
  const [tabs, setTabs] = useState<Tab[]>([
    {
      id: DEFAULT_TAB_ID,
      label,
      icon,
      path,
      iconProps
    }
  ]);
  const shouldUpdateTab = useRef(true);
  const { app: appSelector } = stateSelector || {};

  const { isNavbarShow } = appSelector || {};

  // Others
  const isReportPage = location.pathname.startsWith('/report');
  const isSettingsPage = location.pathname.startsWith('/settings');

  const addTab = useCallback(() => {
    if (tabs.length >= MAX_TABS) return;

    const { icon, label, iconProps } = getIconAndLabel(DEFAULT_PATH);

    const newTab: Tab = {
      id: nextTabId,
      label,
      icon,
      iconProps,
      path: DEFAULT_PATH
    };

    const currentTab = tabs.find((tab) => tab.id === selectedTabId);
    if (currentTab) {
      history.replace({ state: currentTab });
    }

    setTabs((prev) => [...prev, newTab]);
    setNextTabId((prev) => prev + 1);
    setSelectedTabId(nextTabId);

    history.push(DEFAULT_PATH, newTab);
  }, [getIconAndLabel, nextTabId, selectedTabId, tabs]);

  const removeTab = useCallback(
    (tabId: number) => {
      if (tabs.length <= 1) return;

      const newTabs = tabs.filter((tab) => tab.id !== tabId);
      setTabs(newTabs);

      if (tabId === selectedTabId) {
        // If current tab was closed then determine the next active tab
        let newSelectedTab: Tab;

        const deletedTabIndex = tabs.findIndex((tab) => tab.id === tabId);
        if (deletedTabIndex === 0) {
          // if first tab was closed then select the first tab in new tabs list
          newSelectedTab = newTabs[0];
        } else if (deletedTabIndex === tabs.length - 1) {
          // if last tab was closed then select the last tab in new tabs list
          newSelectedTab = newTabs[newTabs.length - 1];
        } else {
          // select the next tab
          newSelectedTab = newTabs[deletedTabIndex];
        }

        setSelectedTabId(newSelectedTab.id);
        history.push(newSelectedTab.path, newSelectedTab);
      }
    },
    [selectedTabId, tabs]
  );

  const onChangeMessageGroup = useCallback(
    async (groupId: string | null) => {
      const currentGroup = chatGroups?.find((x: any) => x?.id === groupId) || { id: '', name: '', type: 0 };
      dispatch(setCurrentChatGroup(groupId ? currentGroup : chatGroups[0]));
    },
    [chatGroups, dispatch]
  );

  const handleTabClick = useCallback(
    (tab: Tab) => {
      if (tab.id === selectedTabId) return;

      const currentTab = tabs.find((tab) => tab.id === selectedTabId);
      if (currentTab) {
        history.replace({ state: currentTab });
      }

      if (currentTab?.path.startsWith('/messages')) {
        const params = new URLSearchParams(tab.path?.split('?')[1]);
        const chatGroupId = params.get('chatGroup');
        onChangeMessageGroup(chatGroupId);
      }
      setSelectedTabId(tab.id);
      shouldUpdateTab.current = false;
      history.push(tab.path, tab);
    },
    [onChangeMessageGroup, selectedTabId, tabs]
  );

  useEffect(() => {
    if (!shouldUpdateTab.current) {
      shouldUpdateTab.current = true;
      return;
    }

    let selectedTab = selectedTabId;

    const tab = location.state as Tab | null;
    if (tab && tab.id !== selectedTabId && tabs.some((x) => x.id === tab.id)) {
      setSelectedTabId(tab.id);
      selectedTab = tab.id;
    }

    const path = location.pathname + location.search;

    const selectedTabIndex = tabs.findIndex((tab) => tab.id === selectedTab);
    if (selectedTabIndex !== -1) {
      setTabs((prev) => {
        const { icon, label, iconProps } = getIconAndLabel(path);

        prev[selectedTabIndex].icon = icon;
        prev[selectedTabIndex].label = label;
        prev[selectedTabIndex].path = path;
        prev[selectedTabIndex].iconProps = iconProps;

        return prev;
      });
    }
  }, [getIconAndLabel, location, selectedTabId, tabs]);

  const onClickMinimize = useCallback(() => {
    if (window && (window as any).electronApi) (window as any).electronApi.windowAction('minimize'); // close window
  }, []);

  const onClickFullScreen = useCallback(() => {
    if (window && (window as any).electronApi) (window as any).electronApi.windowAction('fullscreen'); // close window
    // window.electronApi.windowAction(‘exit-fullscreen‘) // exit fullscreen
  }, []);

  const onClickClose = useCallback(() => {
    if (window && (window as any).electronApi) (window as any).electronApi.windowAction('close'); // close window
  }, []);

  const onClickLogo = useCallback(() => {
    history.push('/');
  }, []);

  const onClickSideBarToddle = useCallback(
    (value: boolean) => {
      dispatch(setIsNavbarShow(value));
    },
    [dispatch]
  );

  return (
    <MacHeader className='app-header'>
      <ActionSection
        className={`back-forward-button ${
          isReportPage ? 'report-page' : isSettingsPage ? 'settings-page' : !isNavbarShow ? 'ipad-header' : ''
        }`}>
        <NavigateArea className='navigate-area'>
          <LogoIcon onClick={onClickLogo} className='logo-icon'>
            <SVGIcon name='teamcamp-logo' width='24' height='24' viewBox='24' />
          </LogoIcon>
          <WindowsHeaderIcon onClick={() => onClickSideBarToddle(!isNavbarShow)}>
            <SVGIcon name='expand-sidebar-icon' width='16' height='16' viewBox='16' />
          </WindowsHeaderIcon>
        </NavigateArea>
        <BackForwardIcon />
      </ActionSection>
      <TabsBarSection
        className={`tabsbar-section ${isReportPage ? 'report-page' : isSettingsPage ? 'settings-page' : ''}`}>
        {tabs.map((tab) => (
          <Tabs
            key={tab.id}
            isActive={selectedTabId === tab.id}
            onClick={() => handleTabClick(tab)}
            isWithoutbg={tabs.length <= 1}>
            <TabsName>
              <Icon>
                <SVGIcon
                  name={tab.icon}
                  width='18'
                  height='18'
                  viewBox='0 0 18 18'
                  stroke='var(--text-primary)'
                  {...tab.iconProps}
                />
              </Icon>
              <p>{tab.label}</p>
            </TabsName>
            {tabs.length > 1 && (
              <TabCloseIcon
                onClick={(event) => {
                  event.stopPropagation();
                  removeTab(tab.id);
                }}
                className='tabclose-icon'>
                <SVGIcon
                  name='app-close-icon'
                  width='14'
                  height='14'
                  viewBox='0 0 16 16'
                  stroke='var(--text-secondary)'
                />
              </TabCloseIcon>
            )}
          </Tabs>
        ))}
        <PlusIcon onClick={addTab} isDisabled={tabs.length >= MAX_TABS}>
          <SVGIcon name='plus-icon' width='18' height='18' viewBox='0 0 18 18' fill='var(--text-secondary)' />
        </PlusIcon>
        <ExpandCloseSection className='expandclose-section'>
          <WindowsHeaderIcon onClick={onClickMinimize}>
            <SVGIcon name='minimize-icon' width='16' height='16' viewBox='16' fill='none' className='minimize-icon' />
          </WindowsHeaderIcon>
          <WindowsHeaderIcon onClick={onClickFullScreen}>
            <SVGIcon name='expand-icon' width='16' height='16' viewBox='16' fill='none' className='expand-icon' />
          </WindowsHeaderIcon>
          <WindowsHeaderIcon onClick={onClickClose}>
            <SVGIcon name='app-close-icon' width='16' height='16' viewBox='16' fill='none' className='close-icon' />
          </WindowsHeaderIcon>
        </ExpandCloseSection>
      </TabsBarSection>
    </MacHeader>
  );
}
