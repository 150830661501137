import React from 'react';
import { Main, Price, Title } from './styles';

interface Props {
  title: string;
  price: string | number;
}
export default function Referbox(props: Props) {
  return (
    <div>
      <Main>
        <Title>{props.title}</Title>
        <Price>{props.price}</Price>
      </Main>
    </div>
  );
}
