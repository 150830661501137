import styled from 'styled-components';

interface Prop {
  width: string;
}

const Buttons = styled.button`
  display: none;
  @media (max-width: 449px) {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 0;
    background: none;
    .fill-color {
      fill: var(--brand-primary);
    }
  }
`;

const ButtonWrap = styled.div<Prop>``;

export { Buttons, ButtonWrap };
