import styled, { css } from 'styled-components';
import { Typography } from '../../../global/theme';

const Main = styled.form`
  max-width: 480px;
  width: 100%;
  background-color: var(--background-primary);
  border-radius: 12px;
  box-shadow: var(--shadow-modal);
`;

const Top = styled.div`
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  border-bottom: 0.5px solid var(--border-primary);
  align-items: center;
`;

const Left = styled.div`
  color: var(--text-primary);
  ${Typography.heading_md}
`;

const Right = styled.div`
  display: inline-flex;
  .close-icon {
    cursor: pointer;
    path {
      stroke: var(--text-primary);
    }
  }
  :hover {
    background-color: var(--neutral-secondary);
    border-radius: 4px;
  }
`;

const Bottom = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Formbutton = styled.div`
  display: flex;
  gap: 12px;
  justify-content: space-between;
`;
const Form = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const Field = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Label = styled.label`
  ${Typography.body_md_medium}
  color: var(--text-secondary);
`;
const Inputvalue = styled.input`
  background-color: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 6px;
  padding: 8px 12px;
  ${Typography.body_lg}
  color: var(--text-primary);
  outline: none;
  :focus {
    border: 0.5px solid var(--brand-primary);
    z-index: 999;
  }
  ::placeholder {
    ${Typography.body_lg}
    color: var(--text-secondary);
  }
  ${(props) =>
    props.disabled &&
    css`
      background-color: var(--background-secondary);
      color: var(--border-secondary);
    `}
`;

const Dropbox = styled.div`
  width: 300px;
  .menuStyle {
    display: flex;
    flex-direction: column;
    padding: 4px;
    background: var(--background-primary);
    border: 0.5px solid var(--border-primary);
    box-shadow: var(--shadow-modal);
    border-radius: 8px;
    margin: 0px;

    .menuitem {
      margin: 0;
      :hover {
        background-color: var(--neutral-secondary);
        border-radius: 4px;
      }
    }
  }
`;
const Dropitem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  padding: 4px 6px;
`;
const Icon = styled.div`
  height: 16px;
  .fill-colors {
    fill: var(--text-secondary);
  }
  .fill-stroke {
    stroke: var(--text-secondary);
  }
`;
const Droptab = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  padding: 6px 8px;
  border: 0.5px solid var(--border-primary);
  border-radius: 5px;
  cursor: pointer;
  .fill-color {
    fill: var(--text-secondary);
  }
  .fill-stroke {
    stroke: var(--text-secondary);
  }
  :hover {
    background-color: var(--neutral-secondary);
  }
`;
const Titles = styled.div`
  color: var(--text-secondary);
  ${Typography.body_md_medium}
`;
const Buttons = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;
const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  h2 {
    margin: 0;
    ${Typography.body_md}
    color: var(--text-primary);
  }
  p {
    ${Typography.body_sm}
    color: var(--text-secondary);
    margin: 0;
  }
`;
export {
  Main,
  Top,
  Left,
  Right,
  Bottom,
  Label,
  Inputvalue,
  Formbutton,
  Form,
  Field,
  Dropbox,
  Dropitem,
  Icon,
  Droptab,
  Titles,
  Buttons,
  Description
};
