import React, { useCallback, useEffect } from 'react';
import { Recoredtime, Showtime, Stopbutton, Time, Recordtext } from '../styles';
import SVGIcon from '../../../../assets/images/svg/SVGIcon';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  convertBase64ToFile,
  convertBlobUrlToDataUrl,
  formatTime,
  getFirebaseUrlFromFile,
  isEmpty
} from '../../../../helpers/common';
import { SHARE_TYPE } from '../../../../global/constants';
import { useMediaRecorder } from '../../../useMediaRecorder/useMediaRecorder';
import { setErrorMessage, setSuccessMessage } from '../../../../actions/messageActions';
import copy from 'clipboard-copy';
import { setScreenRecordingLoading, setScreenRecordingUrl } from '../../../../actions/appActions';
import { captureException } from '../../../../services/logService';
import { addShareData } from '../../../../services/projectServices';
import { RootReducerInterface } from '../../../../interfaces/RootReducerInterface';
import { getSnapListByUser } from '../../../../services/snapService';

const NavbarRecording: React.FC = () => {
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { app: appSelector, project: projectSelector } = stateSelector || {};
  const { screenRecordingLoading, screenRecordingUrl } = appSelector;
  const { uploadFilePercent } = projectSelector;
  //Other variable
  const location = useLocation();
  const dispatch = useDispatch();
  const { status, stopRecording, mediaBlobUrl, clearBlobUrl, duration } = useMediaRecorder();

  const onScreenCaptureComplete = useCallback(
    async (mediaBlobUrl: string) => {
      try {
        dispatch(setScreenRecordingLoading(true));
        if (!isEmpty(mediaBlobUrl)) {
          const baseFile = await convertBlobUrlToDataUrl(mediaBlobUrl || '')
            .then((regularUrl) => {
              return regularUrl;
            })
            .catch((error) => {
              captureException(error);
              console.error('Error:', error.message);
            });
          const fileData: any = await convertBase64ToFile(baseFile, true);
          if (fileData?.size > 2.5e8) {
            dispatch(setErrorMessage('Please upload a file smaller than 250 MB.'));
            return false;
          }
          const firebaseUrl = await getFirebaseUrlFromFile({ file: fileData }, 'screen_recordings/', dispatch);
          const sharePayload = {
            ref: firebaseUrl,
            type: SHARE_TYPE.snap
          };
          const shareResponse = await dispatch(addShareData(sharePayload));
          if (location?.pathname === '/snap') {
            dispatch(getSnapListByUser());
          }
          dispatch(setScreenRecordingUrl(shareResponse?.id ? `https://share.teamcamp.app/${shareResponse.id}` : ''));
        }
      } catch (e) {
        captureException(e);
        console.log('error : ', e);
      } finally {
        clearBlobUrl();
        dispatch(setScreenRecordingLoading(false));
      }
    },
    [clearBlobUrl, dispatch, location?.pathname]
  );

  useEffect(() => {
    if (!isEmpty(mediaBlobUrl) && !location.search?.includes('task=')) onScreenCaptureComplete(mediaBlobUrl || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaBlobUrl]);

  const onCopyLink = useCallback(
    async (url: string) => {
      try {
        await copy(url);
        dispatch(setSuccessMessage('Screen recording url copied successfully!'));
      } catch (error) {
        captureException(error);
        console.log('Failed to copy url: ', error);
      }
    },
    [dispatch]
  );

  const onCloseUrlBox = useCallback(() => {
    dispatch(setScreenRecordingUrl(''));
  }, [dispatch]);

  return (
    <>
      {status === 'recording' && (
        <Recoredtime>
          <Showtime>
            <Recordtext>
              <SVGIcon name='record-start-icon' width='18' height='18' viewBox='0 0 18 18' className='record-icon' />
              <p style={{ maxWidth: formatTime(duration)?.includes('h') ? '50px' : '76px' }}>Screen Recording</p>
            </Recordtext>
            <Time>{formatTime(duration)}</Time>
          </Showtime>
          <Stopbutton onClick={stopRecording}>Stop</Stopbutton>
        </Recoredtime>
      )}
      {(!isEmpty(screenRecordingUrl) || screenRecordingLoading) && (
        <Recoredtime className='copyclip'>
          <Showtime>
            <Recordtext>
              <SVGIcon
                name='copy-clip-icon'
                width='18'
                height='18'
                viewBox='0 0 18 18'
                className='clip-icon'
                fill='none'
              />
              <p>Snap Recorded</p>
            </Recordtext>
            <div onClick={onCloseUrlBox}>
              <SVGIcon
                name='close-icon'
                width='18'
                height='18'
                viewBox='0 0 18 18'
                fill='none'
                className='close-icon'
              />
            </div>
          </Showtime>
          {!screenRecordingLoading ? (
            <Stopbutton onClick={() => onCopyLink(screenRecordingUrl)}>Copy link</Stopbutton>
          ) : (
            <Stopbutton>Uploading({Math.floor(uploadFilePercent)}%)</Stopbutton>
          )}
        </Recoredtime>
      )}
    </>
  );
};

export default NavbarRecording;
