import styled, { css } from 'styled-components';
import { Typography } from '../../global/theme';

interface Props {
  size?: number;
  email?: string;
  isShowBorder?: boolean;
  isThinBorder?: boolean;
  isMediumBorder?: boolean;
  isNotBorder?: boolean;
  isMargin?: boolean;
  isMilestoneBorder?: boolean;
  isZindex?: boolean;
  randColor?: string;
}
const UserLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const UserLogo = styled.img<Props>`
  width: 38px;
  height: 38px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  background-color: var(--background-muted);

  ${(props: Props) =>
    props.size &&
    css`
      width: ${props.size}px;
      height: ${props.size}px;
      border-radius: ${props.size}px;
      border: 2px solid var(--background-secondary);
      object-fit: cover;
    `}
  ${(props: Props) =>
    props.isShowBorder &&
    css`
      border: 5px solid var(--background-secondary);
      border-radius: 20px;
    `}
  ${(props: Props) =>
    props.isThinBorder &&
    css`
      border: 1px solid var(--background-secondary);
      border-radius: 20px;
    `}
    ${(props: Props) =>
    props.isMediumBorder &&
    css`
      border: 3px solid var(--background-primary);
      border-radius: 20px;
    `}
    ${(props: Props) =>
    props.isNotBorder &&
    css`
      border: none;
    `}
      ${(props: Props) =>
    props.isMargin &&
    css`
      margin-left: 5px;
    `}
     ${(props: Props) =>
    props.isMilestoneBorder &&
    css`
      border: 2px solid var(--background-primary);
    `}
       ${(props: Props) =>
    props.isZindex &&
    css`
      z-index: 1;
    `}
`;
const TextWrapper = styled.div<Props>`
  width: 38px;
  height: 38px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  color: var(--text-white);
  display: flex;
  background-color: ${(props: Props) => props.randColor};
  ${(props: Props) =>
    props.size &&
    css`
      width: ${props.size}px;
      height: ${props.size}px;
      background: ${props.randColor};
      border: 2px solid var(--background-secondary);
      border-radius: ${props.size}px;
      span {
        ${Typography.body_xs}
      }
    `}
  ${(props: Props) =>
    props.isShowBorder &&
    css`
      border: 5px solid var(--background-secondary);
      border-radius: 20px;
    `}
  ${(props: Props) =>
    props.isThinBorder &&
    css`
      border: 1px solid var(--background-secondary);
      border-radius: 20px;
    `}
    ${(props: Props) =>
    props.isMediumBorder &&
    css`
      border: 3px solid var(--background-primary);
      border-radius: 20px;
    `}
     ${(props: Props) =>
    props.isMilestoneBorder &&
    css`
      border: 2px solid var(--background-primary);
    `}

    ${(props: Props) =>
    props.isNotBorder &&
    css`
      border: none;
    `}
       ${(props: Props) =>
    props.isMargin &&
    css`
      margin-left: 5px;
    `}
    ${(props: Props) =>
    props.isZindex &&
    css`
      z-index: 1;
    `}
`;

export { UserLogoWrapper, UserLogo, TextWrapper };
