import React, { useCallback, useState } from 'react';
import { Icon, NavigationItem, NavigationWrapper } from './styles';
import SVGIcon from '../../assets/images/svg/SVGIcon';
import { useHistory, useLocation } from 'react-router-dom';
import ModalCustom from '../models/modal';
import CreateTaskModal from '../models/createTaskModal';

export const BottomNavigation = () => {
  //state variable
  const [isOpenCreateTaskModal, setIsOpenCreateTaskModal] = useState(false);

  //other variables
  const history = useHistory();
  const location = useLocation();

  //click search icon
  const handleSearchbarClick = (e: React.SyntheticEvent) => {
    history.push('/search');
  };

  //click inbox icon
  const handleinboxClick = (e: React.SyntheticEvent) => {
    history.push('/');
  };

  //click Home icon
  const handleHomeClick = (e: React.SyntheticEvent) => {
    history.push('/home');
  };

  //open create task modal
  const onClickCreateTaskModal = useCallback(() => {
    setIsOpenCreateTaskModal(true);
  }, []);

  //close create task modal
  const onCloseCreateTaskModal = useCallback(() => {
    setIsOpenCreateTaskModal(false);
  }, []);

  //click setting icon
  const handleSettingIconClick = (e: React.SyntheticEvent) => {
    history.push('/setting');
  };

  return (
    <>
      <NavigationWrapper className={isOpenCreateTaskModal ? 'apply-zindex' : ''}>
        <NavigationItem onClick={handleHomeClick}>
          <Icon>
            <SVGIcon
              name='navigation-home-icon'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              className={location.pathname === '/home' && !isOpenCreateTaskModal ? 'active' : ''}
            />
          </Icon>
        </NavigationItem>
        <NavigationItem onClick={handleinboxClick}>
          <Icon>
            <SVGIcon
              name='navigation-inbox-icon'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              className={location.pathname === '/' && !isOpenCreateTaskModal ? 'active' : ''}
            />
          </Icon>
        </NavigationItem>
        <NavigationItem onClick={onClickCreateTaskModal}>
          <Icon>
            <SVGIcon
              name='mobile-home-plus-icon'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              className={isOpenCreateTaskModal ? 'active-stroke' : 'plus-icon'}
            />
          </Icon>
        </NavigationItem>
        <NavigationItem onClick={handleSearchbarClick}>
          <Icon>
            <SVGIcon
              name='navigation-search-icon'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              className={location.pathname === '/search' && !isOpenCreateTaskModal ? 'active' : ''}
            />
          </Icon>
        </NavigationItem>
        <NavigationItem onClick={handleSettingIconClick}>
          <Icon>
            <SVGIcon
              name='navigation-setting-icon'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              className={location.pathname === '/setting' && !isOpenCreateTaskModal ? 'active' : ''}
            />
          </Icon>
        </NavigationItem>
      </NavigationWrapper>
      <ModalCustom open={isOpenCreateTaskModal} onClose={onCloseCreateTaskModal} isCreateTask={true}>
        <CreateTaskModal onCancel={onCloseCreateTaskModal} />
      </ModalCustom>
    </>
  );
};
