import React, { useCallback, useState } from 'react';
import {
  DropboxModal,
  IconBlock,
  ModalBlock,
  ModalButton,
  ModalContent,
  ModalForm,
  ModalHeader,
  ModalTitle
} from '../../../../../component/filedata/styles';
import { Dropdown, DropdownItem } from '../../../../../component/Dropdown';
import { IconDiv, Input, InputDiv, SelectedText } from '../styles';
import SVGIcon from '../../../../../assets/images/svg/SVGIcon';
import Button from '../../../../../component/button';
import { MoveFilesInterface, ProjectFileData, ProjectFilesInterface } from '../../../../../interfaces/ProjectInterface';
import { useSelector } from 'react-redux';
import { RootReducerInterface } from '../../../../../interfaces/RootReducerInterface';
import { useDispatch } from 'react-redux';
import { updateFileData } from '../../../../../services/projectServices';
import { isEmpty } from '../../../../../helpers/common';

interface Props {
  onModalCancel: () => void;
  loadData: () => void;
  currentItem: ProjectFileData | null;
}

function MoveFolderModal(props: Props) {
  const { onModalCancel, loadData, currentItem } = props;
  // State to store the selected options
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [moveTo, setMoveTo] = useState<MoveFilesInterface | null>(null);
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { project: projectSelector } = stateSelector || {};
  const { moveProjectFiles } = projectSelector;
  // Other Variables
  const dispatch = useDispatch();

  const onUpdateInputValue = useCallback((obj: MoveFilesInterface) => {
    setInputValue('');
    setMoveTo(obj); // Update your moveTo state if needed
    setIsDropdownOpen(false); // Close the dropdown when an item is selected
  }, []);

  // Toggle dropdown when clicking on InputDiv
  const handleToggleDropdown = useCallback(() => {
    setIsDropdownOpen((prev) => !prev);
  }, []);

  // for move file
  const moveToDirectoryFile = useCallback(
    async (file: ProjectFileData) => {
      const payload = {
        ParentId: moveTo?.id === 'Files' ? null : moveTo?.id
      };
      const response = await dispatch(updateFileData(file?.id, payload));
      if (response) {
        loadData();
        onModalCancel();
      }
    },
    [dispatch, loadData, moveTo?.id, onModalCancel]
  );

  return (
    <DropboxModal>
      <ModalBlock>
        <ModalHeader>
          <ModalTitle>Select Folder</ModalTitle>
        </ModalHeader>
        <IconBlock onClick={onModalCancel}>
          <SVGIcon name='modal-cross-sign-icon' width='18' height='18' viewBox='0 0 18 18' className='fillColor' />
        </IconBlock>
      </ModalBlock>
      <ModalContent>
        <ModalForm>
          <Dropdown
            isMinWidth={428}
            content={
              <InputDiv onClick={handleToggleDropdown}>
                <Input
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  placeholder={isEmpty(moveTo?.name) ? 'Select folder' : ''}
                />
                {isEmpty(inputValue) && moveTo?.name && <SelectedText>{moveTo?.name}</SelectedText>}
                <IconDiv>
                  <SVGIcon
                    name='angledown-icon'
                    width='18'
                    height='18'
                    viewBox='0 0 18 18'
                    className={`angledown-icon ${isDropdownOpen ? 'rotate' : ''}`}
                  />
                </IconDiv>
              </InputDiv>
            }
            trigger='click'
            onOutsideClick={() => setIsDropdownOpen(false)}>
            {moveProjectFiles
              .filter((option) => option.name && option.name.toLowerCase().includes(inputValue.toLowerCase()))
              .map((option) => {
                const isSelected = moveTo && moveTo.key === option.key;
                return (
                  <DropdownItem
                    key={option.key}
                    label={option.name}
                    onClick={() => onUpdateInputValue(option)}
                    isSuffix={
                      isSelected ? <SVGIcon name='checkbox-icon' width='16' height='16' viewBox='0 0 16 16' /> : null
                    }
                  />
                );
              })}
          </Dropdown>
        </ModalForm>
        <ModalButton>
          <Button
            title='Move'
            onClick={() => moveToDirectoryFile(currentItem || ({} as ProjectFilesInterface))}
            modelbtn={true}
          />
        </ModalButton>
      </ModalContent>
    </DropboxModal>
  );
}

export default MoveFolderModal;
