import styled from 'styled-components';

const Main = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }
  @media (max-width: 425px) {
    gap: 15px;
  }
  .loading-animation {
    overflow: hidden;
  }
  .loading-animation::before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    animation: loading 1s infinite;
    background: linear-gradient(to right, transparent, var(--background-tertiary), transparent);
  }

  @keyframes loading {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;
const First = styled.div`
  border: 0.5px solid var(--border-primary);
  border-radius: 12px;
  max-width: 240px;
  width: 100%;
  background-color: var(--background-primary);
  @media (max-width: 449px) {
    max-width: -webkit-fill-available;
  }
`;
const Top = styled.div`
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Bottom = styled.div`
  padding: 16px 28px;
  display: flex;
`;

const Circle = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 3px solid var(--background-primary);
  background: var(--background-muted);
  margin-left: -8px;
`;

const Line = styled.div`
  background-color: var(--background-muted);
  height: 1px;
  width: 240px;
  @media (max-width: 449px) {
    width: auto;
  }
`;

const FirstLine = styled.div`
  width: 140px;
  height: 10px;
  background: var(--background-muted);
  border-radius: 5px;
`;

const SecondLine = styled.div`
  width: 180px;
  height: 8px;
  background: var(--background-muted);
  border-radius: 4px;
`;

const ThirdLine = styled.div`
  width: 180px;
  height: 6px;
  background: var(--background-muted);
  border-radius: 3px;
  margin-top: 20px;
`;

export { Main, Top, Bottom, Line, Circle, First, FirstLine, SecondLine, ThirdLine };
