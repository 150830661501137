import { isEmpty } from 'lodash';
import { Dropdown, DropdownItem } from '../../Dropdown';
import { Modalheader, Headline, MenuItem } from './style';
import { useSelector, useDispatch } from 'react-redux';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import { useCallback, useMemo, useState } from 'react';
import { ProjectDetailInterface } from '../../../interfaces/ProjectInterface';
import { setProjectTaskGroupsList, updateCreateTaskInput } from '../../../actions/taskActions';
import UserPreferenceSingleton from '../../../helpers/userPreferenceSingleton';
import { getTaskGroupList } from '../../../services/taskServices';
import { getMilestoneList, getProjectFiles } from '../../../services/projectServices';
import { captureException } from '../../../services/logService';

interface Props {
  isFromMyTask?: boolean;
}

const RenderProjectList = (props: Props) => {
  const { isFromMyTask } = props;

  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { project: projectSelector, task: taskSelector, workspace: workspaceSelector } = stateSelector || {};
  const { list } = projectSelector;
  const { workspace } = workspaceSelector;
  const { createTaskInput } = taskSelector;
  const { projectId } = createTaskInput;

  // other variables
  const menuItems = useMemo(() => {
    return list?.map((item) => ({
      label: item?.name,
      value: item?.id,
      name: item?.name,
      key: item?.id
    }));
  }, [list]);
  const dispatch = useDispatch();

  // State for project search query
  const [query, setQuery] = useState('');

  // Apply search filter dynamically
  const searchResult = useMemo(() => {
    return menuItems?.filter((item) => item.name.toLowerCase().includes(query.toLowerCase()));
  }, [query, menuItems]);

  // Handle search input change
  const onChangeSearch = useCallback((value: string) => {
    setQuery(value);
  }, []);

  //   for update project value
  const onUpdateProjectValue = useCallback(
    async (project: ProjectDetailInterface) => {
      if (project?.id !== projectId?.id) {
        try {
          dispatch(setProjectTaskGroupsList([]));
          if (isFromMyTask) {
            const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
            const user = workspace?.users?.find((user) => user?.id === userDetails?.id);
            const updatedUsers = createTaskInput?.users?.find((user) => user?.id === userDetails?.id) ? [user] : [];
            dispatch(updateCreateTaskInput({ propsName: 'users', value: updatedUsers }));
          } else {
            dispatch(updateCreateTaskInput({ propsName: 'users', value: [] }));
          }
          dispatch(updateCreateTaskInput({ propsName: 'labels', value: [] }));
          dispatch(updateCreateTaskInput({ propsName: 'projectId', value: project }));
          dispatch(updateCreateTaskInput({ propsName: 'groupId', value: null }));
          dispatch(updateCreateTaskInput({ propsName: 'milestoneId', value: null }));
          if (project?.statusEnable) {
            dispatch(updateCreateTaskInput({ propsName: 'statusId', value: project?.defaultStatus?.['_id'] }));
          }
          await dispatch(getTaskGroupList(project?.id, true));
          await dispatch(getProjectFiles(project?.id, project?.users));
          if (project?.isMilestone) {
            await dispatch(getMilestoneList(project?.id, true));
          }
        } catch (e) {
          captureException(e);
          console.log('error', e);
        }
      }
    },
    [createTaskInput?.users, dispatch, isFromMyTask, projectId?.id, workspace?.users]
  );

  // handle dropdown click
  const handleDropdownClick = useCallback(
    (key: string | number) => {
      const selectedProject = list?.find((item) => item?.id === String(key));
      onUpdateProjectValue(selectedProject || projectId);
    },
    [list, onUpdateProjectValue, projectId]
  );

  //   render search data
  const renderSearchData = useMemo(() => {
    return searchResult?.map((projectList) => (
      <DropdownItem
        key={projectList?.key}
        label={projectList?.label}
        onClick={() => handleDropdownClick(projectList?.key)}
        isSelected={projectList?.key === projectId?.id} // Mark selected project
      />
    ));
  }, [handleDropdownClick, searchResult, projectId?.id]);

  return (
    <Modalheader>
      <Dropdown
        isMinWidth={148}
        onChangeSearch={onChangeSearch}
        content={
          <Headline>
            {!isEmpty(projectId?.name)
              ? projectId?.name
              : !isEmpty(createTaskInput?.projectName)
              ? createTaskInput?.projectName
              : 'Select Project'}
          </Headline>
        }
        trigger='click'>
        <MenuItem>{renderSearchData}</MenuItem>
      </Dropdown>
    </Modalheader>
  );
};

export default RenderProjectList;
