import React from 'react'
import { Bottom, Circle, First, FirstLine, Line, Main, SecondLine, ThirdLine, Top } from './styles'

export default function Projectloading() {
  return (
    <div>
      <Main>
        <First>
        <Top>
            <FirstLine className='loading-animation'></FirstLine>
            <SecondLine className='loading-animation'></SecondLine>
            <ThirdLine className='loading-animation'></ThirdLine>
        </Top>
        <Line></Line>
        <Bottom>
            <Circle></Circle>
            <Circle></Circle>
            <Circle></Circle>
            <Circle></Circle>
            <Circle></Circle>
        </Bottom>
        </First>
        <First>
        <Top>
            <FirstLine className='loading-animation'></FirstLine>
            <SecondLine className='loading-animation'></SecondLine>
            <ThirdLine className='loading-animation'></ThirdLine>
        </Top>
        <Line></Line>
        <Bottom>
            <Circle></Circle>
            <Circle></Circle>
            <Circle></Circle>
            <Circle></Circle>
            <Circle></Circle>
        </Bottom>
        </First>
        <First>
        <Top>
            <FirstLine className='loading-animation'></FirstLine>
            <SecondLine className='loading-animation'></SecondLine>
            <ThirdLine className='loading-animation'></ThirdLine>
        </Top>
        <Line></Line>
        <Bottom>
            <Circle></Circle>
            <Circle></Circle>
            <Circle></Circle>
            <Circle></Circle>
            <Circle></Circle>
        </Bottom>
        </First>
        <First>
        <Top>
            <FirstLine className='loading-animation'></FirstLine>
            <SecondLine className='loading-animation'></SecondLine>
            <ThirdLine className='loading-animation'></ThirdLine>
        </Top>
        <Line></Line>
        <Bottom>
            <Circle></Circle>
            <Circle></Circle>
            <Circle></Circle>
            <Circle></Circle>
            <Circle></Circle>
        </Bottom>
        </First>
      </Main>
    </div>
  )
}
