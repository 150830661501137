import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  Bottomdata,
  Button,
  Check,
  Createitem,
  Datedropbox,
  Dropbox,
  Dropheader,
  Header,
  Headertext,
  Icon,
  Inputarea,
  ItemDiv,
  Leftdiv,
  Monthpicker,
  Rightcontent,
  Rightdiv,
  Text1
} from './styles';
import SVGIcon from '../../assets/images/svg/SVGIcon';
import moment from 'moment';
import { DateRange } from 'react-date-range';
import ReportDurationDropdown from '../../component/dropdowns/reportdurationdropdown/reportdurationdropdown';
import { TIME_DATE_SELECTED_TEXT } from '../../global/constants';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SelectionRangeInterface, TimeLogInterface } from '../../interfaces/TimeReportInterface';
import { ReportTimeFilterInterface, SelectRangeInterface } from '../../interfaces/ReportInterface';
import { getDayFromDate, getMonthRange, isEmpty } from '../../helpers/common';
import { loadMyTimeLog } from '../../services/timeTrackingServices';
import { ProjectDetailInterface } from '../../interfaces/ProjectInterface';
import { updateUserPreference } from '../../helpers/firebaseHelper';
import { setMyTimeLogFilter } from '../../actions/timeTrackingActions';
import { Dropdown } from '../../component/Dropdown';

interface Props {
  loading: boolean;
  setLoading: (value: boolean) => void;
  setTotalTime: (time: string) => void;
  setFilterLogList: (logData: TimeLogInterface[]) => void;
}

const MyTimelogHeader = ({ loading, setLoading, setTotalTime, setFilterLogList }: Props) => {
  // Refs
  const buttonRef = useRef<HTMLDivElement | null>(null);

  // States
  const [isDateDropdownOpen, setIsDateDropdownOpen] = useState(false);
  const [isStatusDropdownOpen, setIsStatusDropdownOpen] = useState(false);
  const [isProjectDropdownOpen, setIsProjectDropdownOpen] = useState(false);
  const [searchProject, setSearchProject] = useState('');
  const [filterProjectList, setFilterProjectList] = useState<ProjectDetailInterface[]>([]);

  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const {
    project: projectSelector,
    workspace: workspaceSelector,
    timeTracking: timeTrackingSelector
  } = stateSelector || {};
  const { workspace } = workspaceSelector;
  const { list } = projectSelector;
  const { myTimeLog, myTimeLogFilter } = timeTrackingSelector;

  // Other variables
  const dispatch = useDispatch();
  const history = useHistory();

  // redirect to previous page
  const handleBackClick = () => {
    history.goBack();
  };

  // open project dropdown
  const openProjectDropdown = useCallback((value: boolean) => {
    setIsProjectDropdownOpen(value);
    setIsStatusDropdownOpen(false);
    setIsDateDropdownOpen(false);
  }, []);

  // open status dropdown
  const openStatusDropdown = useCallback((value: boolean) => {
    setIsStatusDropdownOpen(value);
    setIsDateDropdownOpen(false);
  }, []);

  // open date dropdown
  const openDateDropdown = useCallback((value: boolean) => {
    setIsDateDropdownOpen(value);
  }, []);

  // convert decimal to hours and minutes
  const convertDecimalToHoursMinutes = (decimalHours: number, isFullString: boolean, withoutInitials?: boolean) => {
    const hours = Math.floor(decimalHours); // Get the integer part (hours)
    const minutes = Math.round((decimalHours - hours) * 60); // Convert decimal to minutes
    return isFullString
      ? `${hours} hours ${minutes} minutes`
      : withoutInitials
      ? `${hours} : ${minutes}`
      : `${hours}h ${minutes}m`;
  };

  // for calculating total time
  const calculateTotalTime = useCallback(
    (list: TimeLogInterface[]) => {
      const total = list?.reduce((acc: number, item: TimeLogInterface) => {
        return acc + (item?.hours || 0);
      }, 0);
      const timeString = convertDecimalToHoursMinutes(total, true);
      setTotalTime(timeString);
    },
    [setTotalTime]
  );

  // implement filter logic
  const applyFilter = useCallback(
    async (
      projects: ProjectDetailInterface[],
      selectionRange: SelectRangeInterface,
      myTimeReportData?: TimeLogInterface[],
      projectList?: ProjectDetailInterface[]
    ) => {
      const selectedProjects = projects?.filter((x) => x?.checked);
      if (!projectList) projectList = list;
      const selectedProjectIds = selectedProjects?.map((x) => x?.id);
      if (!myTimeReportData) myTimeReportData = myTimeLog;
      let timeLogData: TimeLogInterface[] = JSON.parse(JSON.stringify(myTimeReportData));
      const startDate = moment(selectionRange.startDate);
      const endDate = moment(selectionRange.endDate).add(1, 'day');
      timeLogData = timeLogData?.filter(
        (item) =>
          selectedProjectIds?.includes(item?.projectId) &&
          moment(item?.date) >= startDate &&
          moment(item?.date) <= endDate
      );
      const formattedTimeLogData = timeLogData?.map((item) => ({
        ...(item || {}),
        day: getDayFromDate(item?.date)
      }));
      setFilterLogList(formattedTimeLogData || []);
      calculateTotalTime(timeLogData || []);
    },
    [calculateTotalTime, list, myTimeLog, setFilterLogList]
  );

  // update date range
  const updateDateRangeChange = useCallback(
    async (startDate: Date, endDate: Date, selected_text: string) => {
      try {
        const selecteDates: SelectionRangeInterface = {
          startDate: startDate,
          endDate: endDate,
          key: 'selection'
        };
        setIsStatusDropdownOpen(false);
        const data: ReportTimeFilterInterface = {
          ...(myTimeLogFilter || {}),
          selected_range: {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
            key: 'selection'
          },
          selected_text: selected_text
        };
        dispatch(setMyTimeLogFilter(data));
        if (myTimeLogFilter?.project_list) {
          const monthRange = getMonthRange(startDate.toISOString(), endDate.toISOString());
          await dispatch(loadMyTimeLog(workspace?.id, monthRange));
          applyFilter(myTimeLogFilter?.project_list, selecteDates);
        }
        // check this
        await updateUserPreference({ [`my_timelog_filter_${workspace?.id}`]: data });
      } catch (e) {}
    },
    [applyFilter, dispatch, myTimeLogFilter, workspace?.id]
  );

  // handle select date range
  const handleSelectDateRange = useCallback(
    async (ranges: { selection: { startDate: Date; endDate: Date; key: string } }) => {
      try {
        setLoading(true);
        const data = {
          ...(myTimeLogFilter || {}),
          selected_range: {
            startDate: new Date(ranges.selection.startDate).toISOString(),
            endDate: new Date(ranges.selection.endDate).toISOString(),
            key: ranges.selection.key
          },
          selected_text: TIME_DATE_SELECTED_TEXT.CUSTOM
        };
        dispatch(setMyTimeLogFilter(data));
        if (myTimeLogFilter?.project_list) {
          const monthRange = getMonthRange(data?.selected_range?.startDate, data?.selected_range?.endDate);
          await dispatch(loadMyTimeLog(workspace?.id, monthRange));
          applyFilter(myTimeLogFilter?.project_list, ranges.selection);
        }
        await updateUserPreference({ [`my_timelog_filter_${workspace?.id}`]: data });
      } catch (e) {
      } finally {
        setLoading(false);
      }
    },
    [applyFilter, dispatch, myTimeLogFilter, setLoading, workspace?.id]
  );

  // get project info based on search
  const onChangeSearchProject = useCallback(
    async (e: { target: { value: string } }) => {
      const value = e.target.value;
      setSearchProject(value);
      let timeout;
      if (!isEmpty(value)) {
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
          const newList =
            myTimeLogFilter?.project_list?.filter((item: { id: string }) =>
              list
                ?.find((project) => project?.id === item?.id)
                ?.name?.toLowerCase()
                .includes(value?.toLowerCase())
            ) || [];
          setFilterProjectList(newList);
        }, 300);
      } else {
        if (myTimeLogFilter?.project_list) setFilterProjectList(myTimeLogFilter?.project_list);
      }
    },
    [list, myTimeLogFilter]
  );

  // get project info based on selected
  const onSelectAllProjectClick = useCallback(
    async (projectList: ProjectDetailInterface[], value: boolean) => {
      let projectListClone: ProjectDetailInterface[] = JSON.parse(JSON.stringify(projectList));
      projectListClone = projectListClone?.map((item) => {
        return { ...item, checked: value };
      });
      const data = {
        ...(myTimeLogFilter || {}),
        project_list: projectListClone,
        selected_all_project: value
      };
      setFilterProjectList(projectListClone);
      if (data) {
        dispatch(setMyTimeLogFilter(data));
      }
      if (myTimeLogFilter?.selected_range) applyFilter(projectListClone, myTimeLogFilter?.selected_range);
      await updateUserPreference({ [`my_timelog_filter_${workspace?.id}`]: data });
    },
    [applyFilter, dispatch, myTimeLogFilter, workspace?.id]
  );

  // get project info based in selected(check box)
  const onCheckProject = useCallback(
    async (
      allProjectList: ProjectDetailInterface[],
      filteredList: ProjectDetailInterface[],
      projectId: string,
      value: boolean
    ) => {
      const updatedAllList = allProjectList?.map((item) => {
        return item?.id === projectId ? { ...item, checked: value } : item;
      }, []);

      const updatedFilteredList = filteredList?.map((item) => {
        return item?.id === projectId ? { ...item, checked: value } : item;
      }, []);

      const uncheckedTasks = updatedAllList?.find((x) => !x?.checked);
      let newValue;
      if (uncheckedTasks || updatedFilteredList?.length === 0) {
        newValue = false;
      } else {
        newValue = true;
      }

      const data = {
        ...(myTimeLogFilter || {}),
        project_list: updatedAllList,
        selected_all_project: newValue
      };
      setFilterProjectList(updatedFilteredList);
      dispatch(setMyTimeLogFilter(data));
      if (myTimeLogFilter?.selected_range) applyFilter(updatedAllList, myTimeLogFilter?.selected_range);
      await updateUserPreference({ [`time_report_filter_${workspace?.id}`]: data });
    },
    [applyFilter, dispatch, myTimeLogFilter, workspace?.id]
  );

  // render project data
  const renderProjectData = useMemo(() => {
    return (
      // eslint-disable-next-line react/jsx-filename-extension
      <div className='dropdown-content'>
        <Dropbox>
          <div className='menuStyle'>
            <Dropheader>
              <Inputarea type='text' placeholder='Search...' value={searchProject} onChange={onChangeSearchProject} />
            </Dropheader>
            <Bottomdata isLoading={loading}>
              {isEmpty(searchProject) && (
                <Createitem
                  onClick={() =>
                    onSelectAllProjectClick(myTimeLogFilter?.project_list || [], !myTimeLogFilter?.selected_all_project)
                  }>
                  <Check>
                    <input type='checkbox' className='checkbox-round' checked={myTimeLogFilter?.selected_all_project} />
                  </Check>
                  <p>Select All</p>
                </Createitem>
              )}
              {(isEmpty(searchProject) ? myTimeLogFilter?.project_list : filterProjectList)?.map(
                (item: ProjectDetailInterface) => {
                  const project = list?.find((project) => project?.id === item?.id);
                  return (
                    <Createitem
                      key={item?.id}
                      onClick={(e) =>
                        onCheckProject(myTimeLogFilter?.project_list || [], filterProjectList, item?.id, !item?.checked)
                      }>
                      <Check>
                        <input type='checkbox' className='checkbox-round' checked={item?.checked} />
                      </Check>
                      <p>{project?.name}</p>
                    </Createitem>
                  );
                }
              )}
            </Bottomdata>
          </div>
        </Dropbox>
      </div>
    );
  }, [
    filterProjectList,
    list,
    loading,
    myTimeLogFilter?.project_list,
    myTimeLogFilter?.selected_all_project,
    onChangeSearchProject,
    onCheckProject,
    onSelectAllProjectClick,
    searchProject
  ]);

  return (
    <Header>
      <Leftdiv>
        <Icon onClick={handleBackClick}>
          <SVGIcon name='left-arrow-icon' width='16' height='16' viewBox='0 0 16 16' className='headerarrow' />
        </Icon>
        <Headertext>Your Timelog</Headertext>
      </Leftdiv>
      <Rightdiv>
        <div className='custom-dropdown'>
          <div onClick={() => openProjectDropdown(!isProjectDropdownOpen)}>
            <Button placeholder='By Project'>
              <p>All Projects</p>
              <SVGIcon
                name='dropdown-icon'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                className={isProjectDropdownOpen ? 'dropdown-icon stroke-color' : 'stroke-color'}
              />
            </Button>
          </div>
          {isProjectDropdownOpen && renderProjectData}
        </div>
        <Rightcontent>
          <Dropdown
            isMinWidth={152}
            content={
              <div className='custom-dropdown'>
                <div ref={buttonRef} onClick={() => openStatusDropdown(!isStatusDropdownOpen)}>
                  <Button iscustom>
                    <p>
                      {myTimeLogFilter?.selected_text ? myTimeLogFilter?.selected_text : TIME_DATE_SELECTED_TEXT.TODAY}
                    </p>
                    <SVGIcon
                      name='dropdown-icon'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      className={isStatusDropdownOpen ? 'dropdown-icon stroke-color' : 'stroke-color'}
                    />
                  </Button>
                </div>
              </div>
            }
            trigger='click'
            onOutsideClick={() => openStatusDropdown(!isStatusDropdownOpen)}>
            <ItemDiv>
              <div className='dropdown-content'>
                <ReportDurationDropdown
                  buttonref={buttonRef}
                  setIsStatusDropdownOpen={(value) => setIsStatusDropdownOpen(value)}
                  updateDateRangeChange={(startDate: Date, endDate: Date, selected_text: string) =>
                    updateDateRangeChange(startDate, endDate, selected_text)
                  }
                />
              </div>
            </ItemDiv>
          </Dropdown>

          <div className='custom-dropdown'>
            <div onClick={() => openDateDropdown(!isDateDropdownOpen)}>
              <Monthpicker>
                <SVGIcon name='calendar-icon' width='18' height='18' viewBox='0 0 18 18' className='fill-color' />
                <Text1>{`${moment(myTimeLogFilter?.selected_range?.startDate).format('MMM DD')} - ${moment(
                  myTimeLogFilter?.selected_range?.endDate
                ).format('MMM DD')}`}</Text1>
                <SVGIcon
                  name='dropdown-icon'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  className={isDateDropdownOpen ? 'dropdown-icon stroke-color' : 'stroke-color'}
                />
              </Monthpicker>
            </div>
            {isDateDropdownOpen && (
              <div className='dropdown-content'>
                <Datedropbox>
                  <DateRange
                    editableDateInputs={true}
                    onChange={handleSelectDateRange}
                    ranges={[
                      {
                        startDate: new Date(myTimeLogFilter?.selected_range?.startDate || new Date()),
                        endDate: new Date(myTimeLogFilter?.selected_range?.endDate || new Date()),
                        key: myTimeLogFilter?.selected_range?.key
                      }
                    ]}
                    moveRangeOnFirstSelection={false}
                    maxDate={new Date()}
                  />
                </Datedropbox>
              </div>
            )}
          </div>
        </Rightcontent>
      </Rightdiv>
    </Header>
  );
};

export default MyTimelogHeader;
