import styled from 'styled-components';
import { Typography } from '../../../global/theme';

const DropboxModal = styled.div``;

const ModalBlock = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid var(--border-primary);
  padding: 12px 16px;
`;

const ModalHeader = styled.div`
  display: flex;
  gap: 10px;
`;

const ModalTitle = styled.h2`
  ${Typography.heading_md}
  color: var(--text-primary);
  margin: 0;
`;

const ModalContent = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;
`;

const ModalForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ModalInput = styled.input`
  padding: 12px 16px;
  background-color: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 6px;
  ${Typography.body_lg}
  color: var(--text-primary);
  outline: none;
  :focus {
    border: 0.5px solid var(--border-primary);
    z-index: 999;
  }
  ::placeholder {
    color: var(--text-secondary);
  }
  @media (max-width: 449px) {
    border-radius: 6px;
  }
`;

const ModalButton = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
`;

export { DropboxModal, ModalBlock, ModalHeader, ModalTitle, ModalContent, ModalForm, ModalInput, ModalButton };
