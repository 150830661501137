import axios from 'axios';
import { AnyAction, Dispatch } from 'redux';
import { clearResponseMessage, setErrorMessage, setSuccessMessage } from '../actions/messageActions';
import {
  setCurrentProject,
  setFavouriteProjectList,
  setProjectList,
  setProjectLoader,
  setProjectStatus,
  setDiscussionData,
  setProjectAllFiles,
  setMoveProjectFiles,
  setProjectActivity,
  setProjectArchiveData,
  setDocData,
  setUserActivity,
  setFileViewData,
  setMilestoneList,
  setArchiveMilestoneList
} from '../actions/projectActions';
import { ACTIVITY_ACTION, ACTIVITY_TYPE, PROJECT_STATUS, PROJECT_STATUS_TEXT } from '../global/constants';
import { REACT_APP_APIURL1, REACT_APP_TEAMCAMP_APIURL } from '../global/environment';
import { getAPIErrorReason, isDev, isEmpty, mathRound } from '../helpers/common';
import UserPreferenceSingleton from '../helpers/userPreferenceSingleton';
import {
  AddMessageReactionInterface,
  AddNewFilePayloadInterface,
  CreateMilestonePayloadInterface,
  CreateNewDocInterface,
  DocDataInterface,
  MilestoneListInterface,
  MilestoneTaskInterface,
  ProjectDetailInterface,
  ProjectFilesInterface,
  ProjectResourceInterface,
  StatusInputInterface,
  StatusListInterface,
  UpdateProjectMemberInterface
} from '../interfaces/ProjectInterface';
import FilesCacheService from './filesCatchServices';
import TasksCacheService from './tasksCatchServices';
import { trackAnalyticActivity } from './analyticsService';
import { setProjectTaskMilestoneList } from '../actions/taskActions';
import moment from 'moment';
import ProjectCacheService from './projectCacheService';
import { getAllMessages } from './chatMessageService';
import { MessageDetailsInterface } from '../interfaces/chatMessageInterface';
import ChatMessageCacheService from './chatMessageCacheService';
import { captureException } from './logService';
import { getUserPreferenceFieldData } from '../helpers/firebaseHelper';
import { RootReducerInterface } from '../interfaces/RootReducerInterface';
import { TaskDetailsInterface } from '../interfaces/TaskInterface';
import { UsersWorkspaceInterface } from '../interfaces/WorkspaceInterface';
import { PROJECT_ANALYTICS } from '../global/analyticsConstants';
import { ProjectProgress } from '../mobx/interfaces/project';
import { rootStore } from '../mobx/rootStore';

export const updateLocalProjectList: any = async (updatedList: ProjectDetailInterface[], workspace_id: string) => {
  await ProjectCacheService.getInstance()?.setProjectData(updatedList, workspace_id);
};

export const getDefaultProgress = (): ProjectProgress => {
  const status = PROJECT_STATUS.NOT_STARTED;
  return {
    progress: 0,
    completedTasks: 0,
    totalTasks: 0,
    status,
    statusText: PROJECT_STATUS_TEXT[status]
  };
};

const determineProjectStatus = (projectTask: any[]) => {
  const completedTasks = projectTask?.filter((x: { status: boolean }) => x?.status)?.length || 0;
  const openTasks = projectTask?.filter((x: { status: boolean }) => !x?.status)?.length || 0;

  // If there are no open tasks
  if (openTasks === 0) {
    if (completedTasks === 0) {
      return PROJECT_STATUS.NOT_STARTED;
    }
    return PROJECT_STATUS.ON_SCHEDULE;
  }

  // If there are open tasks
  const overdueTasks =
    projectTask?.filter(
      (x: { dueDate: Date; status: boolean }) =>
        !x?.status && !isEmpty(x?.dueDate) && new Date(x?.dueDate).getTime() < new Date().getTime()
    )?.length || 0;

  const overduePercentage = (overdueTasks / openTasks) * 100;

  if (overduePercentage > 50) {
    return PROJECT_STATUS.BEHIND_SCHEDULE;
  }

  if (overduePercentage > 20) {
    return PROJECT_STATUS.AT_RISK;
  }

  return PROJECT_STATUS.ON_SCHEDULE;
};

/**
 * @desc Project - Calculate project progress
 * @param {*}
 */
export const calculateTaskProgress = async (project_id: string): Promise<ProjectProgress> => {
  try {
    const projectTask = await TasksCacheService.getInstance()?.getTasksByProject(project_id);

    if (!projectTask || projectTask.length === 0) {
      return getDefaultProgress();
    }

    const completedTasks = projectTask?.filter((x: { status: boolean }) => x?.status)?.length || 0;
    const progress = projectTask?.length === 0 ? 0 : Number(mathRound((completedTasks / projectTask?.length) * 100, 0));
    const status = determineProjectStatus(projectTask);

    return {
      progress,
      completedTasks,
      totalTasks: projectTask.length,
      status,
      statusText: PROJECT_STATUS_TEXT[status]
    };
  } catch (e) {
    captureException(e);
    return getDefaultProgress();
  }
};

export const getFavoriteSortedList = async (workspace_id: string, favouriteProjects: ProjectDetailInterface[]) => {
  const userFilter = await getUserPreferenceFieldData(`favorite_projects_${workspace_id}`);
  if (!userFilter) {
    return favouriteProjects.map((project) => {
      return {
        ...project,
        index: new Date(project?.createdOn).getTime()
      };
    });
  }
  const updatedFavouriteProjects = favouriteProjects.map((project) => {
    const index = userFilter[project.id] ?? new Date(project?.createdOn).getTime();
    return {
      ...project,
      index: index
    };
  });
  const sortedList = updatedFavouriteProjects.toSorted((a, b) => a.index - b.index);
  return sortedList;
};

const shouldUseExistingList = (list: ProjectDetailInterface[], forceLoad?: boolean) => {
  return list?.length > 0 && !forceLoad;
};

const handleLocalProjects = async (dispatch: Dispatch<AnyAction>, workspace_id: string) => {
  const local_data = await ProjectCacheService.getInstance()?.getProjectByWorkspace(workspace_id);
  if (local_data?.length > 0) {
    const favouriteProjects = local_data.filter((x: any) => x.isFavorite);
    const sortedFavouriteList = await getFavoriteSortedList(workspace_id, favouriteProjects);
    dispatch(setFavouriteProjectList(sortedFavouriteList));
    dispatch(setProjectList(local_data));
  }
};

const constructProjectObject = (x: any, progressData: any) => {
  const defaultStatus = x?.status?.find((status: StatusListInterface) => status?.Default);
  return {
    ...x,
    statusData: x?.status,
    statusEnable: x?.statusEnable,
    defaultStatus: defaultStatus || x?.status?.[0] || {},
    isGroupEnabled: x?.isGroupEnabled !== false,
    ...(progressData || {})
  };
};
/**
 * @desc Project - Get project list
 * @param {*}
 */
export const getProjectList: any =
  (forceLoad?: boolean) => async (dispatch: Dispatch<AnyAction>, getState: () => RootReducerInterface) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setProjectLoader(true));

      const state = getState();
      const { workspace } = state.workspace;
      const { id: workspace_id } = workspace;

      let { list } = state.project;
      if (shouldUseExistingList(list, forceLoad)) return list;

      await handleLocalProjects(dispatch, workspace_id);

      const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/project/getProjectList`);
      const { data } = response;

      rootStore.projectStore.upsertProjects(data);
      rootStore.projectStore.updateProjectIndexes();

      if (data.length > 0) {
        const updatedData = await Promise.all(
          data?.map(async (x: any) => {
            const progressData = await calculateTaskProgress(x?.id);
            return constructProjectObject(x, progressData);
          })
        );

        const updatedList = updatedData?.map((item) => {
          let updatedUsers = item?.users;
          if (typeof item?.users?.[0] === 'string') {
            updatedUsers = item?.users
              ?.map((x: string) => {
                const user = workspace?.users.find((a: any) => a?.id === x);
                return user;
              })
              .filter(Boolean); // This removes undefined or null values
          }
          return { ...item, users: updatedUsers };
        });
        const favouriteProjects = updatedList?.filter((x: any) => x.isFavorite);
        await ProjectCacheService.getInstance()?.setProjectData(updatedList, workspace_id);
        const sortedFavouriteList = await getFavoriteSortedList(workspace_id, favouriteProjects);
        dispatch(setFavouriteProjectList(sortedFavouriteList));
        dispatch(setProjectList(updatedList));
        return updatedList;
      } else {
        rootStore.projectStore.setProjects([]);
        await ProjectCacheService.getInstance()?.setProjectData([], workspace_id);
        dispatch(setFavouriteProjectList([]));
        dispatch(setProjectList([]));
      }
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to get project list please try again';
      captureException(error);
      dispatchProjectError(error, dispatch);
      return false;
    } finally {
      dispatch(setProjectLoader(false));
    }
  };

/**
 * @desc Project - Update project list from server
 * @param {*}
 */
export const updateProjectListDataFromServer: any =
  () => async (dispatch: Dispatch<AnyAction>, getState: () => RootReducerInterface) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setProjectLoader(true));
      const state = getState();
      const { workspace } = state.workspace;
      const { id: workspace_id } = workspace;
      let { list } = state.project;
      const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/project/getProjectList`);
      const { data } = response;
      if (data.length > 0) {
        const updatedData = await Promise.all(
          data?.map(async (x: any) => {
            const oldData = list?.find((project) => project?.id === x?.id);
            const { status, ...other } = x || {};
            const defaultStatus = status?.find((status: StatusListInterface) => status?.Default);
            return {
              ...(oldData || {}),
              ...(other || {}),
              statusData: status,
              statusEnable: x?.statusEnable,
              defaultStatus: defaultStatus || status?.[0] || {}
            };
          })
        );
        const favouriteProjects = updatedData?.filter((x: any) => x.isFavorite);
        await ProjectCacheService.getInstance()?.setProjectData(updatedData, workspace_id);
        const sortedFavouriteList = await getFavoriteSortedList(workspace_id, favouriteProjects);
        dispatch(setFavouriteProjectList(sortedFavouriteList));
        dispatch(setProjectList(updatedData));
        return updatedData;
      }
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to get project list please try again';
      captureException(error);
      dispatchProjectError(error, dispatch);
      return false;
    } finally {
      dispatch(setProjectLoader(false));
    }
  };

/**
 * @desc Project - create new project
 * @param {*}
 */
export const createNewProject: any = (payload: ProjectDetailInterface) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setProjectLoader(true));
    const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/project/postProject`, payload);
    const { data } = response;
    if (data) {
      return data;
    }
    return undefined;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to create project please try again';
    captureException(error);
    dispatchProjectError(error, dispatch);
    return false;
  } finally {
    dispatch(setProjectLoader(false));
  }
};

/**
 * @desc Project - create new label
 * @param {*}
 */
export const createNewLabel: any =
  (payload: { name: string; color: string }, project_Id: string) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/project/addProjectLabels/${project_Id}`, payload);
      const { data } = response;
      if (data) {
        return data;
      }
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to create label please try again';
      captureException(error);
      dispatchProjectError(error, dispatch);
      return false;
    }
  };

/**
 * @desc Project - update label
 * @param {*}
 */
export const updateLabel: any =
  (payload: { name: string; color: string }, project_id: string, label_id: string) =>
  async (dispatch: Dispatch<AnyAction>) => {
    try {
      const params = { labelId: label_id };
      const response = await axios.put(
        `${REACT_APP_TEAMCAMP_APIURL}/project/updateRemoveProjectLabels/${project_id}`,
        payload,
        { params }
      );
      if (response) {
        dispatchProjectSucess('label Updated Successfully', dispatch);
        return true;
      }
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to update label please try again';
      captureException(error);
      dispatchProjectError(error, dispatch);
      return false;
    }
  };

/**
 * @desc Project - delete invited member
 * @param {*}
 */
export const deletelabel: any = (label_id: string, project_id: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    const params = {
      labelId: label_id
    };
    const response = await axios.delete(
      `${REACT_APP_TEAMCAMP_APIURL}/project/updateRemoveProjectLabels/${project_id}`,
      {
        params
      }
    );
    if (response) {
      dispatchProjectSucess('Label deleted sucessfully.', dispatch);
      return true;
    }
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to delete label please try again';
    captureException(error);
    dispatchProjectError(error, dispatch);
    return false;
  } finally {
    dispatch(setProjectLoader(false));
  }
};

/**
 * @desc Project - Favorite/UnFavorite Project
 * @param {*}
 */
export const favoriteProject: any =
  (workspace_id: string, project_id: string, value: boolean) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setProjectLoader(true));
      const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
      const payload = {
        project_id: project_id,
        workspace_id: workspace_id,
        user_id: userDetails?.id,
        value: value
      };
      const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/project/putFavorite`, payload);
      const { data } = response;
      if (data) {
        if (value) trackAnalyticActivity(PROJECT_ANALYTICS.MARKED_FAVORITE);
        return data;
      }
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to favorite project please try again';
      captureException(error);
      dispatchProjectError(error, dispatch);
      return false;
    } finally {
      dispatch(setProjectLoader(false));
    }
  };

const fetchLocalFiles = async (project_id: string) => {
  const local_data = await FilesCacheService.getInstance()?.getFilesByTable(project_id);
  return local_data?.[0]?.value;
};

/**
 * @desc Project - get project files
 * @param {*}
 */
export const getProjectFiles: any =
  (project_id: string, users?: UsersWorkspaceInterface[]) =>
  async (dispatch: Dispatch<AnyAction>, getState: () => RootReducerInterface) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setProjectLoader(true));

      const { workspace } = getState().workspace;
      const { id: workspace_id, users: workspaceUsers } = workspace;
      users = users || workspaceUsers;

      const result = await fetchLocalFiles(project_id);
      if (result?.length > 0) {
        dispatch(setProjectAllFiles(result));
      } else {
        dispatch(setProjectAllFiles([]));
      }

      const lastUpdatedTime = await FilesCacheService.getInstance()?.getLastUpdatedTime(project_id);

      const params = { lastUpdatedTime };
      const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/files/getFilesList/${project_id}`, { params });

      const { data } = response;

      if (data?.length > 0) {
        const updatedData = data?.map((item: any) => {
          let user = users?.find((x: any) => x.id === item?.CreatedBy);
          const extension = (name: any) => {
            let fileExt;
            const ext = name.split('.');
            if (ext && ext.length >= 2) {
              fileExt = '.' + ext[1];
            }
            return fileExt;
          };
          return {
            createdBy: item?.CreatedBy,
            createdOn: item?.CreatedTime,
            fileType: item?.FileType,
            fileExt: extension(item?.Name),
            isArchived: item?.IsArchived,
            name: item?.Name,
            parentId: item?.ParentId,
            projectId: item?.ProjectId,
            size: Number(item?.Size),
            uploadFileType: item?.UploadFileType,
            url: item?.Url,
            id: item['_id'],
            user
          };
        });

        const activeFiles = updatedData.filter((item: any) => !item.isArchived);
        const archivedFiles = updatedData.filter((item: any) => item.isArchived);

        const adjustedTime = moment().subtract(1, 'hours').toISOString();

        let hasUpdatedData = false;
        if (lastUpdatedTime === 0 || isEmpty(lastUpdatedTime)) {
          await FilesCacheService.getInstance()?.setFiles(project_id, activeFiles, workspace_id);
          hasUpdatedData = true;
        } else {
          await FilesCacheService.getInstance()?.updateProjectFiles(activeFiles, archivedFiles, project_id);
          hasUpdatedData = !!(activeFiles.length > 0 || archivedFiles.length > 0);
        }
        await FilesCacheService.getInstance()?.setLastUpdatedTime(adjustedTime, workspace_id, project_id);

        if (hasUpdatedData) {
          const updatedLocalData = await fetchLocalFiles(project_id);
          if (updatedLocalData) {
            dispatch(setProjectAllFiles(updatedLocalData));
            return updatedLocalData;
          }
        }
      } else {
        return result;
      }
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to get project files please try again';
      captureException(error);
      dispatchProjectError(error, dispatch);
      return undefined;
    } finally {
      dispatch(setProjectLoader(false));
    }
  };

/**
 * @desc Project - get move project files
 * @param {*}
 */
export const getMoveProjectFiles: any =
  (project_id: string, document_id: string) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setProjectLoader(true));
      const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
      const params = {
        projectId: project_id,
        userId: userDetails?.id,
        fileId: document_id
      };
      const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/files/getMoveFileDirectory`, {
        params
      });
      const { data } = response;
      if (data?.length > 0) {
        const updateFile = data?.map((item: any) => {
          return { ...item, value: item?.name, key: item?.id };
        });
        dispatch(setMoveProjectFiles(updateFile));
        return updateFile;
      }
      return undefined;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to get move file list please try again';
      captureException(error);
      dispatchProjectError(error, dispatch);
      return undefined;
    } finally {
      dispatch(setProjectLoader(false));
    }
  };

/**
 * @desc Project - getCurrentProjectDetails
 * @param {*}
 */
export const getCurrentProjectDetails: any =
  (workspaceId: string, projectId: string) =>
  async (dispatch: Dispatch<AnyAction>, getState: () => RootReducerInterface) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setProjectLoader(true));
      const state = getState();
      const { workspace } = state.workspace;
      const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/project/getCurrentProjectDetails/${projectId}`);
      const { data } = response;
      if (data) {
        const projectUsers = data?.ProjectUsers?.map((user: any) => {
          let userData;
          if (typeof user === 'string') {
            userData = workspace?.users.find((ele) => ele?.id === user);
          } else {
            userData = workspace?.users.find((ele) => ele?.id === user['_id']);
          }
          return userData;
        }).filter(Boolean);

        const currentUser = await UserPreferenceSingleton.getInstance().getCurrentUser();
        let favouriteProject = data?.FavoriteUsers?.findIndex((item: string) => currentUser?.id === item);
        const updatedLabels = data?.LabelsList?.map((x: any) => {
          return {
            id: x['_id'],
            name: x?.Name,
            color: x?.Color,
            createdBy: x?.CreatedBy,
            createdOn: x?.CreatedTime,
            updatedBy: x?.UpdatedBy,
            updatedOn: x?.UpdatedTime
          };
        });
        const defaultStatus = data?.Status?.find((status: StatusListInterface) => status?.Default);
        const projectDetails = {
          companyId: data?.CompanyId,
          createdBy: data?.CreatedBy,
          createdOn: data?.CreatedTime,
          customerId: !isEmpty(data?.CustomerId) ? data?.CustomerId : undefined,
          description: data?.Description,
          dueDate: data?.DueDate,
          id: data['_id'],
          isEstimate: data?.IsEstimate,
          isFavorite: favouriteProject !== -1,
          name: data?.Name,
          startDate: data?.StartDate,
          updatedOn: data?.UpdatedTime,
          users: projectUsers,
          priority: { default: data?.Priority?.Default, enabled: data?.Priority?.Enabled },
          isMilestone: data?.IsMilestone,
          labelsEnabled: data?.LabelsEnabled,
          labelsList: updatedLabels,
          statusEnable: data?.StatusEnable,
          statusData: data?.Status?.filter((x: StatusListInterface) => x),
          defaultStatus: defaultStatus || data?.Status?.[0] || {},
          isGroupEnabled: data?.IsGroupEnabled === false ? false : true,
          multiAssignee: data?.MultiAsignee,
          githubRepository: data?.GithubRepository,
          resources: data?.Resources || []
        };
        dispatch(setCurrentProject(projectDetails));
        return projectDetails;
      }
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to get Current Project Details please try again';
      captureException(error);
      dispatchProjectError(error, dispatch);
      return false;
    } finally {
      dispatch(setProjectLoader(false));
    }
  };

/**
 * @desc Project - getProjectStatus
 * @param {*}
 */
export const getProjectStatus: any =
  (workspace_Id: string, project_Id: string) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setProjectLoader(true));
      const response = await axios.get(`${REACT_APP_APIURL1}/Projects/${workspace_Id}/details/${project_Id}`);
      if (response) {
        dispatch(setProjectStatus(response?.data));
        return true;
      }
      return false;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to get Project Status please try again';
      captureException(error);
      dispatchProjectError(error, dispatch);
      return false;
    } finally {
      dispatch(setProjectLoader(false));
    }
  };

/**
 * @desc Project - get discussionData
 * @param {*}
 */
export const getDiscussionData: any = (project_id: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setProjectLoader(true));
    const response: MessageDetailsInterface[] = await dispatch(getAllMessages());
    if (response) {
      const projectMessages = await ChatMessageCacheService.getInstance()?.getProjectMessages(project_id);
      dispatch(setDiscussionData(projectMessages));
      return projectMessages;
    } else {
      dispatch(setDiscussionData([]));
      return [];
    }
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to get discussion data please try again';
    captureException(error);
    dispatchProjectError(error, dispatch);
    return undefined;
  } finally {
    dispatch(setProjectLoader(false));
  }
};

/**
 * @desc Project - update file data
 * @param {*}
 */
export const updateFileData: any =
  (folder_id: string, payload: ProjectFilesInterface) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setProjectLoader(true));
      const response = await axios.put(`${REACT_APP_TEAMCAMP_APIURL}/files/updateFileData/${folder_id}`, payload);
      if (response) {
        return true;
      }
      return false;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to update file data please try again';
      captureException(error);
      dispatchProjectError(error, dispatch);
      return false;
    } finally {
      dispatch(setProjectLoader(false));
    }
  };

/**
 * @desc Project - archiveData
 * @param {*}
 */
export const updateProjectData: any =
  (project_id: string, payload: boolean) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setProjectLoader(true));
      const response = await axios.put(`${REACT_APP_TEAMCAMP_APIURL}/project/putProject/${project_id}`, payload);
      const { data } = response;
      if (data) {
        await dispatch(getProjectList(true));
        return true;
      }
      return false;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to update project data please try again';
      captureException(error);
      dispatchProjectError(error, dispatch);
      return false;
    } finally {
      dispatch(setProjectLoader(false));
    }
  };

/**
 * @desc Project - create new doc
 * @param {*}
 */
export const createNewDoc: any = (payload: CreateNewDocInterface) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setProjectLoader(true));
    const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/documents/postDocFile`, payload);
    if (response) {
      return response.data;
    }
    return false;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to create document please try again';
    captureException(error);
    dispatchProjectError(error, dispatch);
    return false;
  } finally {
    dispatch(setProjectLoader(false));
  }
};

/**
 * @desc Project - getPRojectActivity
 * @param {*}
 */
export const getProjectActivity: any =
  (project_Id: string) => async (dispatch: Dispatch<AnyAction>, getState: () => RootReducerInterface) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setProjectLoader(true));
      const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/activity/getActivityList/${project_Id}`);
      const { data } = response;
      if (data) {
        const state = getState();
        const { workspace } = state.workspace;
        const updatedData = data?.map((item: any) => {
          const user = workspace?.users?.find((x) => x?.id === item?.UserInfo?.id);
          return {
            user: user?.name,
            profile_photo: user?.profile_photo,
            actionId: item?.action,
            action: ACTIVITY_ACTION[item?.action],
            createdTime: item?.createdTime,
            // ProjectId,
            desc: item?.referenceName,
            typeId: item?.type,
            type: ACTIVITY_TYPE[item?.type],
            id: item?.id
          };
        });
        dispatch(setProjectActivity(updatedData));
        return true;
      }
      return false;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to get Project Overview activity please try again';
      captureException(error);
      dispatchProjectError(error, dispatch);
      return false;
    } finally {
      dispatch(setProjectLoader(false));
    }
  };

/**
 * @desc Member - get User Activity
 * @param {*}
 */
export const getUserActivity: any =
  (userId: string) => async (dispatch: Dispatch<AnyAction>, getState: () => RootReducerInterface) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setProjectLoader(true));
      const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/activity/getUserWiseActivityList/${userId}`);
      const { data } = response;
      if (data) {
        const state = getState();
        const { workspace } = state.workspace;
        const updatedData = data?.map((item: any) => {
          const user = workspace?.users?.find((x) => x?.id === userId);
          return {
            user: user?.name,
            profile_photo: user?.profile_photo,
            actionId: item?.action,
            action: ACTIVITY_ACTION[item?.action],
            createdTime: item?.createdTime,
            desc: item?.referenceName,
            typeId: item?.type,
            type: ACTIVITY_TYPE[item?.type],
            id: item?.id
          };
        });
        dispatch(setUserActivity(updatedData));
        return true;
      }
      return false;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to get user activity please try again';
      captureException(error);
      dispatchProjectError(error, dispatch);
      return false;
    } finally {
      dispatch(setProjectLoader(false));
    }
  };

/**
 * @desc Project - getProjectArchiveData
 * @param {*}
 */
export const getProjectArchiveData: any =
  (workspace_Id: string) => async (dispatch: Dispatch<AnyAction>, getState: () => RootReducerInterface) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setProjectLoader(true));
      const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
      const params = {
        UserId: userDetails?.id,
        CompanyId: workspace_Id,
        archived: true
      };
      const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/project/getProjectList`, { params });
      const { data } = response;

      const state = getState();
      const { workspace } = state.workspace;
      const updatedData = data?.map((item: any) => {
        let updatedUsers = item?.users;
        let createdBy = item?.createdBy;
        if (item?.users.every((x: any) => typeof x === 'string')) {
          createdBy = workspace?.users?.find((x) => x.id === item?.createdBy);
          updatedUsers = item?.users
            ?.map((x: string) => {
              const user = workspace?.users.find((a) => a?.id === x);
              return user;
            })
            .filter(Boolean); // This removes undefined or null values
        }
        return { ...item, createdBy, users: updatedUsers };
      });
      if (data) {
        dispatch(setProjectArchiveData(updatedData));
        return true;
      }
      return false;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to get Project Archive Data please try again';
      captureException(error);
      dispatchProjectError(error, dispatch);
      return false;
    } finally {
      dispatch(setProjectLoader(false));
    }
  };

/**
 * @desc Project - delete invited member
 * @param {*}
 */
export const removeInvitedMember: any =
  (id: string, project_id: string, invitedUser_id: string, isAllowAccess?: boolean, isReport = false) =>
  async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setProjectLoader(true));
      const params = {
        projectId: project_id,
        userId: id,
        Id: invitedUser_id,
        is_allow_access: isAllowAccess || false
      };
      const response = await axios.delete(`${REACT_APP_TEAMCAMP_APIURL}/project/removeTaskUser`, {
        params
      });
      if (response) {
        if (isReport) {
          dispatchProjectSucess('You leaved the project', dispatch);
        } else {
          dispatchProjectSucess(response.data.message, dispatch);
        }
        return true;
      }
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to remove invited member please try again';
      captureException(error);
      dispatchProjectError(error, dispatch);
      return false;
    } finally {
      dispatch(setProjectLoader(false));
    }
  };

/**
 * @desc Project - add invited member
 * @param {*}
 */
export const addInvitedMember: any =
  (payload: UpdateProjectMemberInterface, isAllowAccess?: boolean, isReport = false) =>
  async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setProjectLoader(true));
      const params = {
        is_allow_access: isAllowAccess,
        isDev: isDev()
      };
      const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/project/InviteUsersInProject`, payload, {
        params
      });
      if (response) {
        if (isReport) {
          dispatchProjectSucess('You joined successfully', dispatch);
        } else {
          dispatchProjectSucess(response.data.message, dispatch);
        }
        return true;
      }
      return false;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to add invited member please try again';
      captureException(error);
      dispatchProjectError(error, dispatch);
      return false;
    } finally {
      dispatch(setProjectLoader(false));
    }
  };

/**
 * @desc Project - get doc data
 * @param {*}
 */
export const getDocumentData: any = (fileId: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setProjectLoader(true));
    const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/documents/getDocFile/${fileId}`);
    const { data } = response;
    if (data) {
      const payload = {
        content: data?.Content,
        createdBy: data?.CreatedBy,
        createdOn: data?.CreatedTime,
        name: data?.Name,
        projectId: data?.ProjectId,
        updatedBy: data?.UpdatedBy,
        updatedOn: data?.UpdatedTime,
        id: data['_id'],
        isBlocksuite: data?.IsBlocksuite,
        isPublic: data?.IsPublic,
        isEdge: data?.IsEdge,
        expiryDate: data?.ExpiryTime
      };
      dispatch(setDocData(payload));
      return true;
    }
    return false;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to get doc data please try again';
    captureException(error);
    dispatchProjectError(error, dispatch);
    return false;
  } finally {
    dispatch(setProjectLoader(false));
  }
};

/**
 * @desc Project - get file details by ID
 * @param {*}
 */
export const getFileDetailsById: any =
  (itemId: string) => async (dispatch: Dispatch<AnyAction>, getState: () => RootReducerInterface) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setProjectLoader(true));
      const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/files/getFileDetails/${itemId}`);
      const { data } = response;
      if (data) {
        const state = getState();
        const { workspace } = state.workspace;
        const { users: workspaceUsers } = workspace;
        const extension = (name: string) => {
          let fileExt;
          const ext = name.split('.');
          if (ext && ext.length >= 2) {
            fileExt = '.' + ext[1];
          }
          return fileExt;
        };
        let user = workspaceUsers?.find((x: any) => x.id === data?.CreatedBy);
        const fileData = {
          createdBy: data?.CreatedBy,
          createdOn: data?.CreatedTime,
          fileType: data?.FileType,
          isArchived: data?.IsArchived,
          name: data?.Name,
          fileExt: extension(data?.Name),
          parentId: data?.ParentId,
          projectId: data?.ProjectId,
          size: data?.Size,
          uploadFileType: data?.UploadFileType,
          url: data?.Url,
          id: data['_id'],
          user
        };
        dispatch(setFileViewData(fileData));
        return fileData;
      }

      return undefined;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to get file details please try again';
      captureException(error);
      dispatchProjectError(error, dispatch);
      return false;
    } finally {
      dispatch(setProjectLoader(false));
    }
  };

/**
 *  @desc messages - add reaction
 * @param {*}
 */
export const addReactionOnMessage: any =
  (payload: AddMessageReactionInterface) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setProjectLoader(true));
      const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/discussions/reactionsOnMessage`, payload);
      if (response) {
        return response?.data;
      }
      return undefined;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to add reaction please try again';
      captureException(error);
      dispatchProjectError(error, dispatch);
      return undefined;
    } finally {
      dispatch(setProjectLoader(false));
    }
  };

/**
 *  @desc Milestone - create New Milestone
 * @param {*}
 */
export const createNewMilestone: any =
  (payload: CreateMilestonePayloadInterface) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setProjectLoader(true));
      const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/milestone/createMilestone`, payload);
      if (response) {
        dispatchProjectSucess(response.data.message, dispatch);
        return true;
      }
      return false;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to create New Milestone please try again';
      captureException(error);
      dispatchProjectError(error, dispatch);
      return false;
    } finally {
      dispatch(setProjectLoader(false));
    }
  };

/**
 * @desc Project - get Milestone List
 * @param {*}
 */
export const getMilestoneList: any =
  (projectId: string, isSelect = false, isForceLoading = true) =>
  async (dispatch: Dispatch<AnyAction>, getState: () => RootReducerInterface) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setProjectLoader(true));
      const state = getState();
      const { projectTaskMilestoneList, currentProject, milestoneList } = state.project;
      if (!isForceLoading && milestoneList?.length > 0 && milestoneList[0]?.projectId === projectId) {
        return milestoneList;
      }
      if (isSelect && projectTaskMilestoneList?.length > 0 && projectTaskMilestoneList[0]?.projectId === projectId) {
        return projectTaskMilestoneList;
      }
      const params = {
        projectId
      };
      const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/milestone/getMilestoneList`, {
        params
      });
      const { data } = response;
      if (data) {
        const updatedData: MilestoneListInterface[] = data?.map((item: MilestoneListInterface) => {
          const completedTasks = item?.tasks?.filter((x) => x?.status)?.length || 0;
          const dueTasks =
            item?.tasks?.filter(
              (x) => !x?.status && !isEmpty(x?.dueDate) && new Date(x?.dueDate).getTime() < new Date().getTime()
            ) || 0;
          const dueTasksPercentage =
            item?.tasks?.length === 0 ? 0 : Number(mathRound((dueTasks?.length / item?.tasks?.length) * 100, 0));
          let progress, status;
          if (item?.tasks?.length === 0) progress = 0;
          else progress = mathRound((completedTasks / item?.tasks?.length) * 100, 0);
          if (item?.tasks?.length > 0) {
            if (dueTasksPercentage < 10) status = PROJECT_STATUS.ON_SCHEDULE;
            else if (dueTasksPercentage >= 10 && dueTasksPercentage <= 30) status = PROJECT_STATUS.AT_RISK;
            else status = PROJECT_STATUS.BEHIND_SCHEDULE;
          } else {
            status = PROJECT_STATUS.NOT_STARTED;
          }
          return {
            ...item,
            index: !item?.index || item?.index === 0 ? new Date(item?.createdOn).getTime() : item?.index,
            status,
            progress,
            tasks: item?.tasks?.map((task) => ({
              ...task,
              priority: task?.priority === null ? currentProject?.priority?.default : task?.priority
            }))
          };
        });
        const sortedMilestone = updatedData?.toSorted((a, b) => {
          return a.index - b.index;
        });
        if (isSelect) {
          dispatch(setProjectTaskMilestoneList(sortedMilestone));
        } else if (isForceLoading) {
          dispatch(setMilestoneList(sortedMilestone));
          dispatch(setProjectTaskMilestoneList(sortedMilestone));
        } else {
          dispatch(setMilestoneList(sortedMilestone));
        }
        return data;
      }
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to get milestone list please try again';
      captureException(error);
      dispatchProjectError(error, dispatch);
      return false;
    } finally {
      dispatch(setProjectLoader(false));
    }
  };

/**
 * @desc Project - get Milestone List
 * @param {*}
 */

const calculateMilestoneStatus = (tasks: TaskDetailsInterface[]) => {
  const completedTasks = tasks?.filter((x) => x?.status)?.length || 0;
  const dueTasks =
    tasks?.filter((x) => !x?.status && !isEmpty(x?.dueDate) && new Date(x?.dueDate).getTime() < new Date().getTime()) ||
    0;
  const dueTasksPercentage = tasks?.length === 0 ? 0 : Number(mathRound((dueTasks?.length / tasks?.length) * 100, 0));
  let progress, status;
  if (tasks?.length === 0) progress = 0;
  else progress = mathRound((completedTasks / tasks?.length) * 100, 0);
  if (tasks?.length > 0) {
    if (dueTasksPercentage < 10) status = PROJECT_STATUS.ON_SCHEDULE;
    else if (dueTasksPercentage >= 10 && dueTasksPercentage <= 30) status = PROJECT_STATUS.AT_RISK;
    else status = PROJECT_STATUS.BEHIND_SCHEDULE;
  } else {
    status = PROJECT_STATUS.NOT_STARTED;
  }
  return { progress, status };
};

const mileStoneData = (
  item: MilestoneListInterface,
  progress: string | number,
  status: number,
  currentProject: ProjectDetailInterface
) => {
  return {
    ...item,
    index: !item?.index || item?.index === 0 ? new Date(item?.createdOn).getTime() : item?.index,
    status,
    progress,
    tasks: item?.tasks?.map((task) => ({
      ...task,
      priority: task?.priority === null ? currentProject?.priority?.default : task?.priority
    }))
  };
};

export const getArchiveMilestoneList: any =
  (projectId: string) => async (dispatch: Dispatch<AnyAction>, getState: () => RootReducerInterface) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setProjectLoader(true));
      const state = getState();
      const { currentProject } = state.project;
      const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/milestone/getMilestoneArchiveList/${projectId}`);
      const { data } = response;
      if (data) {
        const updatedData: MilestoneListInterface[] = data?.map((item: MilestoneListInterface) => {
          const { progress, status } = calculateMilestoneStatus(item?.tasks || []);
          return mileStoneData(item, progress, status, currentProject);
        });
        const sortedMilestone = updatedData?.toSorted((a, b) => {
          return a.index - b.index;
        });
        dispatch(setArchiveMilestoneList(sortedMilestone));
        return data;
      }
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to get archive milestone list please try again';
      captureException(error);
      dispatchProjectError(error, dispatch);
      return false;
    } finally {
      dispatch(setProjectLoader(false));
    }
  };

/**
 * @desc Project - delete Milestone
 * @param {*}
 */
export const deleteMilestone: any = (milestoneId: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setProjectLoader(true));
    const response = await axios.delete(`${REACT_APP_TEAMCAMP_APIURL}/milestone/deleteMilestone/${milestoneId}`);
    if (response) {
      dispatchProjectSucess(response.data.message, dispatch);
      return true;
    }
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to delete milestone please try again';
    captureException(error);
    dispatchProjectError(error, dispatch);
    return false;
  } finally {
    dispatch(setProjectLoader(false));
  }
};

/**
 * @desc Project - update Milestone
 * @param {*}
 */
export const updateMilestone: any =
  (payload: MilestoneTaskInterface, showMessage = true) =>
  async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setProjectLoader(true));
      const response = await axios.put(`${REACT_APP_TEAMCAMP_APIURL}/milestone/putMilestone`, payload);
      if (response) {
        if (showMessage) dispatchProjectSucess(response.data.message, dispatch);
        return true;
      }
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to update milestone please try again';
      captureException(error);
      dispatchProjectError(error, dispatch);
      return false;
    } finally {
      dispatch(setProjectLoader(false));
    }
  };

/**
 * @desc Project - add share data
 * @param {*}
 */
export const addShareData: any = (payload: any) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setProjectLoader(true));
    const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
    const updatedPayload = { ...payload, userId: userDetails?.id };
    const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/share/postShare`, updatedPayload);
    if (response) {
      return response?.data?.data;
    }
    return undefined;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to add share data please try again';
    captureException(error);
    dispatchProjectError(error, dispatch);
    return undefined;
  } finally {
    dispatch(setProjectLoader(false));
  }
};

/**
 * @desc Project - get share data
 * @param {*}
 */
export const getShareData: any = (share_id: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setProjectLoader(true));
    const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/share/getShareDetails/${share_id}`);
    if (response) {
      return response?.data?.data;
    }
    return undefined;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to get share data please try again';
    captureException(error);
    dispatchProjectError(error, dispatch);
    return undefined;
  } finally {
    dispatch(setProjectLoader(false));
  }
};

/**
 * @desc Project - add new file
 * @param {*}
 */
export const addFileData: any = (payload: AddNewFilePayloadInterface) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setProjectLoader(true));
    const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/files/addFileData`, payload);
    const { data } = response;
    if (data) {
      return data;
    }
    return undefined;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to add file please try again';
    captureException(error);
    dispatchProjectError(error, dispatch);
    return undefined;
  } finally {
    dispatch(setProjectLoader(false));
  }
};

/**
 * @desc update ducument data
 * @param {*}
 */
export const updateDocumentData: any =
  (document_id: string, payload: DocDataInterface) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setProjectLoader(true));
      const response = await axios.put(`${REACT_APP_TEAMCAMP_APIURL}/documents/putDocFile/${document_id}`, payload);
      const { data } = response;
      if (data) {
        return true;
      }
      return false;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to update document data please try again';
      captureException(error);
      dispatchProjectError(error, dispatch);
      return false;
    } finally {
      dispatch(setProjectLoader(false));
    }
  };

/**
 * @desc Create new demo project
 * @param {*}
 */

export const createDemoProject: any = () => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setProjectLoader(true));
    const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/project/postDemoProject`);
    const { data } = response;
    if (data) {
      return data;
    }
    return undefined;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to create demo project please try again';
    captureException(error);
    dispatchProjectError(error, dispatch);
    return false;
  } finally {
    dispatch(setProjectLoader(false));
  }
};

/**
 * @desc Project - add new status
 * @param {*}
 */
export const addStatusData: any =
  (projectId: string, payload: StatusInputInterface) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setProjectLoader(true));
      const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/project/${projectId}/status`, payload);
      const { data } = response;
      if (data) {
        return data;
      }
      return undefined;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'unable to add status please try again';
      captureException(error);
      dispatchProjectError(error, dispatch);
      return undefined;
    } finally {
      dispatch(setProjectLoader(false));
    }
  };

/**
 * @desc update status
 * @param {*}
 */
export const updateStatusData: any =
  (projectId: string, statusId: string, payload: { name?: string; type?: number; index?: number }) =>
  async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setProjectLoader(true));
      const response = await axios.put(`${REACT_APP_TEAMCAMP_APIURL}/project/${projectId}/status/${statusId}`, payload);
      const { data } = response;
      if (data) {
        return true;
      }
      return false;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to update status data please try again';
      captureException(error);
      dispatchProjectError(error, dispatch);
      return false;
    } finally {
      dispatch(setProjectLoader(false));
    }
  };

/**
 * @desc Project - delete Milestone
 * @param {*}
 */
export const deleteStatus: any = (projectId: string, statusId: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setProjectLoader(true));
    const response = await axios.delete(`${REACT_APP_TEAMCAMP_APIURL}/project/${projectId}/status/${statusId}`);
    if (response) {
      dispatchProjectSucess(response.data.message, dispatch);
      return true;
    }
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to delete status please try again';
    captureException(error);
    dispatchProjectError(error, dispatch);
    return false;
  } finally {
    dispatch(setProjectLoader(false));
  }
};
/**
 * @desc Project - getProjectListUserNotIn
 * @param {*}
 */
export const getProjectListUserNotIn: any = () => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setProjectLoader(true));
    const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/project/getProjectsUserNotIn`);
    const { data } = response;
    if (data) {
      rootStore.projectStore.setProjectUserNotIn(data);
      // const updatedData = data?.map((item: any) => {
      //   let updatedUsers = item?.users;
      //   if (typeof item?.users?.[0] === 'string') {
      //     updatedUsers = item?.users
      //       ?.map((x: string) => {
      //         const user = workspace?.users.find((a: any) => a?.id === x);
      //         return user;
      //       })
      //       .filter(Boolean); // This removes undefined or null values
      //   }
      //   return { ...item, users: updatedUsers };
      // });
      // dispatch(setProjectListUserNotIn(updatedData));
      return response;
    }
    return false;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to get project list user not in please try again';
    captureException(error);
    dispatchProjectError(error, dispatch);
    return false;
  } finally {
    dispatch(setProjectLoader(false));
  }
};

/**
 * @desc Project - add resource
 * @param {*}
 */
export const addProjectResource: any =
  (projectId: string, payload: ProjectResourceInterface) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setProjectLoader(true));
      const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/project/${projectId}/resource`, payload);
      const { data } = response;
      if (data) {
        return data;
      }
      return undefined;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'unable to add resource please try again';
      captureException(error);
      dispatchProjectError(error, dispatch);
      return undefined;
    } finally {
      dispatch(setProjectLoader(false));
    }
  };

/**
 * @desc Project - update resource
 * @param {*}
 */
export const updateProjectResource: any =
  (projectId: string, fileId: string, payload: { label?: string; reference?: string }) =>
  async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setProjectLoader(true));
      const response = await axios.put(`${REACT_APP_TEAMCAMP_APIURL}/project/${projectId}/resource/${fileId}`, payload);
      const { data } = response;
      if (data) {
        return data;
      }
      return undefined;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'unable to add resource please try again';
      captureException(error);
      dispatchProjectError(error, dispatch);
      return undefined;
    } finally {
      dispatch(setProjectLoader(false));
    }
  };

/**
 * @desc Project - delete resource
 * @param {*}
 */
export const deleteProjectResource: any =
  (projectId: string, fileId: string) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setProjectLoader(true));
      const response = await axios.delete(`${REACT_APP_TEAMCAMP_APIURL}/project/${projectId}/resource/${fileId}`);
      if (response) {
        return true;
      }
      return undefined;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'unable to add resource please try again';
      captureException(error);
      dispatchProjectError(error, dispatch);
      return undefined;
    } finally {
      dispatch(setProjectLoader(false));
    }
  };

function dispatchProjectError(msg: string, dispatch: Dispatch<AnyAction>) {
  dispatch(setErrorMessage(msg));
}

function dispatchProjectSucess(msg: string, dispatch: Dispatch<AnyAction>) {
  dispatch(setSuccessMessage(msg));
}
