import styled from 'styled-components';

const DescriptionInputSection = styled.div`
  .tiptap {
    max-height: 240px;
    min-height: 90px;
    overflow-y: auto;
    ::-webkit-scrollbar {
      display: block;
      background-color: var(--background-primary);
      width: 6px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: var(--text-tertiary);
      width: 6px;
      margin-bottom: 4px;
      border-radius: 7px;
      min-height: 20px;
    }
  }
`;
const FileBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 0;
  padding: 0;
  margin-top: 8px;
`;

export { DescriptionInputSection, FileBox };
