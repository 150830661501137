import styled, { css } from 'styled-components';

interface Props {
  isPadding?: boolean;
  isHover?: boolean;
  isHide?: boolean;
  isIcon?: boolean;
  isCreateTask?: boolean;
  isTaskItem?: boolean;
  isFeature?: boolean;
  isBorder?: boolean;
  isSubTask?: boolean;
  isSelectTask?: boolean;
  isCustomcontextmenu?: boolean;
  isCalendarviewcard?: boolean;
  isCalendarviewtasklist?: boolean;
  isHoverIcon?: boolean;
}

const Prioritybox = styled.div<Props>`
  ${(props: Props) =>
    props.isCustomcontextmenu &&
    css`
      top: 34px;
      left: 151px;
      .ant-menu-vertical .ant-menu-item {
        margin: 0;
        line-height: unset;
        height: unset;
        width: 100%;
        border-radius: 0;
      }
      .ant-menu-light.ant-menu-root.ant-menu-vertical {
        border-inline-end: none;
        padding: 5px 0;
      }
    `}
`;

const Icon = styled.div`
  width: 15px;
  height: 15px;
`;
const ButtonIcon = styled.div<Props>`
  ${(props: Props) =>
    props.isHoverIcon &&
    css`
      padding: 4px;
      border-radius: 12px;
      :hover {
        background-color: var(--neutral-secondary);
        svg {
          filter: brightness(var(--brightness-value));
        }
      }
    `}
`;
export { Prioritybox, Icon, ButtonIcon };
