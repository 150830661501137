import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { APP_INIT_RESPONSE_TYPE } from '../../../global/constants';
import { SignupInterface } from '../../../interfaces/SignUpInterface';
import { ROUTES } from '../../../routes/urls';
import { signUp, validateIsLoggedIn } from '../../../services/authServices';
import Button from '../../../component/button';
import { trackAnalyticActivity, trackPage } from '../../../services/analyticsService';
import AppLogo from '../../../component/appLogo';
import { getCookie, isEmpty } from '../../../helpers/common';
import { SignUpPayloadInterface } from '../../../interfaces/AuthInterface';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import {
  Content,
  Form,
  Formblock,
  Formcontent,
  GoogleButton,
  Group,
  Hiddennow,
  Icon,
  Inputvalue,
  Overtext,
  Text,
  Textline,
  Title,
  TopView,
  Viewer
} from '../styles';
import { VIEW_ANALYTICS } from '../../../global/analyticsConstants';

const Signup: React.FC = () => {
  //State
  const [inputDetails, setInputDetails] = useState<SignupInterface>({
    name: '',
    email: '',
    password: ''
  });
  const [referCodeValue, setReferCodeValue] = useState('');
  //use selector state variables
  const auth = useSelector((state: RootReducerInterface) => state.auth);
  const { loading } = auth;
  //Other variable
  const dispatch = useDispatch();
  const history = useHistory();

  // for name change
  const onChangeName = (value: string) => {
    setInputDetails({ ...(inputDetails || {}), name: value });
  };

  // for email change
  const onChangeEmail = (value: string) => {
    setInputDetails({ ...(inputDetails || {}), email: value });
  };

  // for password change
  const onChangePassword = (value: string) => {
    setInputDetails({ ...(inputDetails || {}), password: value });
  };

  useEffect(() => {
    trackPage({ visit_url: 'signup' });
    trackAnalyticActivity(VIEW_ANALYTICS.SIGN_UP_PAGE);
    if (validateIsLoggedIn()) {
      history.push(ROUTES.INBOX);
    }
    const cookieValue = getCookie('cross_domain_referral');
    if (cookieValue) setReferCodeValue(cookieValue);
  }, [history]);

  // for signup form submit
  const onClickSignUp = useCallback(
    async (e: React.SyntheticEvent) => {
      e.preventDefault();
      const payload: SignUpPayloadInterface = {
        ...(inputDetails || {}),
        confirmPassword: inputDetails.password,
        appName: 'WebAppProject'
      };
      if (!isEmpty(referCodeValue)) payload.referCode = referCodeValue;
      const result = await dispatch(signUp(payload));
      if (result && result.type === APP_INIT_RESPONSE_TYPE.REDIRECT) {
        history.push(result.path);
      } else if (result && result.type === APP_INIT_RESPONSE_TYPE.SUCCESS) {
        history.push('/');
      }
    },
    [inputDetails, referCodeValue, dispatch, history]
  );

  return (
    <>
      <Group isSignupPage={true} className='windows-onboarding-flow'>
        <Content isSignupPage={true}>
          <TopView>
            <Icon>
              <AppLogo height='60' />
            </Icon>
            <Title>Create New Account</Title>
            <Hiddennow>
              <GoogleButton
                title='Sign Up with Google'
                iconName='google-icon'
                type='button'
                iconSize={16}
                iconViewBox='0 0 16 16'
                secondary={true}
              />
              <Overtext>
                <Text>or</Text>
              </Overtext>
            </Hiddennow>
            <Formcontent>
              <Form onSubmit={onClickSignUp}>
                <Formblock>
                  <Inputvalue
                    type='text'
                    placeholder='Name'
                    onChange={(e) => onChangeName(e.target.value)}
                    autoFocus={true}
                    isSignupPage={true}
                  />
                  <Inputvalue
                    type='email'
                    placeholder='Email Address'
                    onChange={(e) => onChangeEmail(e.target.value)}
                    className='password'
                    isSignupPage={true}
                  />
                  <Inputvalue
                    type='password'
                    placeholder='Password'
                    onChange={(e) => onChangePassword(e.target.value)}
                    className='password-input'
                    isSignupPage={true}
                  />
                </Formblock>
                <Button type='submit' title='Sign Up' isLoading={loading} resWidth={100} />
              </Form>
              <Button
                type='button'
                title=' Go back to login'
                onClick={() => history.push(ROUTES.LOGIN)}
                disabled={loading}
                secondary={true}
                resWidth={100}
                hasNotBoxshadow={true}
              />
            </Formcontent>
          </TopView>

          <Textline>
            By signing up, you are indicating that you have read and agree to the{' '}
            <Viewer isSignupPage={true} href='https://www.teamcamp.app/terms-of-use'>
              Terms of Use
            </Viewer>{' '}
            and{' '}
            <Viewer isSignupPage={true} href='https://www.teamcamp.app/privacy-policy'>
              {' '}
              Privacy Policy
            </Viewer>
            .
          </Textline>
        </Content>
      </Group>
    </>
  );
};

export default Signup;
