import { ActionCreatorWithPayload, createAction } from '@reduxjs/toolkit';
import * as Actions from './types';
import { UsersWorkspaceInterface } from '../interfaces/WorkspaceInterface';

/**
 * @desc Set is app loader
 */
export const setIsAppLoader: ActionCreatorWithPayload<boolean, string> = createAction(Actions.SET_APP_LOADER);

/**
 * @desc Set is app loader
 */
export const setScreenRecordingLoading: ActionCreatorWithPayload<boolean, string> = createAction(
  Actions.SET_SCREEN_RECORDING_LOADER
);

/**
 * @desc Set is app loader
 */
export const setScreenRecordingUrl: ActionCreatorWithPayload<string, string> = createAction(
  Actions.SET_SCREEN_RECORDING_URL
);

/**
 * @desc Set is vercel modal open
 */
export const setVercelModalOpen: ActionCreatorWithPayload<boolean, string> = createAction(
  Actions.SET_VERCEL_MODAL_OPEN
);

/**
 * @desc Set is navbar show
 */
export const setIsNavbarShow: ActionCreatorWithPayload<boolean, string> = createAction(Actions.SET_IS_NAVBAR_SHOW);

/**
 * @desc Set is app loader
 */
export const setCustomBranding: ActionCreatorWithPayload<object, string> = createAction(Actions.SET_CUSTOM_BRANDING);

/**
 * @desc Set searched gif
 */
export const setSearchedGif: ActionCreatorWithPayload<string[], string> = createAction(Actions.SET_SEARCHED_GIF);

/**
 * @desc Set is bubble menu open
 */
export const setIsBubbleMenuOpen: ActionCreatorWithPayload<boolean, string> = createAction(
  Actions.SET_IS_BUBBLE_MENU_OPEN
);

/**
 * @desc Set is app init loading
 */
export const setAppInitLoading: ActionCreatorWithPayload<boolean, string> = createAction(Actions.SET_APP_INIT_LOADING);

/**
 * @desc Set user modal detail
 */
export const setUserModalDetail: ActionCreatorWithPayload<UsersWorkspaceInterface, string> = createAction(
  Actions.SET_USER_MODAL_DETAIL
);
