import styled, { css } from 'styled-components';
import { Typography } from '../../global/theme';

const DropBox = styled.div`
  .menuStyle {
    display: flex;
    flex-direction: column;
    padding: 3px 0;
    background: var(--background-primary);
    border: 0.5px solid var(--border-primary);
    box-shadow: var(--shadow-modal);
    border-radius: 8px;
    margin: 0px;
    width: 100px;

    .menuitem {
      margin: 0;
      :hover {
        background-color: var(--neutral-secondary);
        border-radius: 0;
      }
    }
  }
`;

const DropText = styled.div`
  ${Typography.body_md_medium}
  color: var(--text-primary);
  margin: 0;
  padding: 6px 12px;
  cursor: pointer;
`;

const ScreenRecordMain = styled.div<{ isStartFromVideo?: boolean }>`
  margin-top: 12px;
  @media (max-width: 449px) {
    width: calc(100% - 20px);
    margin-top: 8px;
  }
  .screen-recording-header {
    border-bottom: 0.5px solid var(--border-primary);
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 10px 8px;
  }
  .e-rte-video .e-video-inline {
    width: 355px !important;
  }
  .screen-recording-name {
    color: var(--text-primary);
    ${Typography.body_sm_medium}
    max-width: 300px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    word-break: break-all;
    margin-right: 8px;
  }
  .screen-recording-right-div {
    display: flex;
    gap: 12px;
  }
  .open-video-icon {
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
    :hover {
      background-color: var(--neutral-secondary);
      border-radius: 4px;
    }
  }
  .video-more-icon {
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
    :hover {
      background-color: var(--neutral-secondary);
      border-radius: 4px;
    }
  }
  .inbox-video {
    width: 352px;
    height: auto;
    border-radius: 0 0 6px 6px;
    margin: 0;
    @media (max-width: 449px) {
      width: 100%;
    }
  }
  .screen-recording-text {
    margin: 0;
  }
  .screen-recording-animation {
    width: 16px;
    height: 16px;
  }
  .screen-recording-more {
    width: 16px;
    height: 16px;
  }
  .inbox-image {
    position: relative;
    display: flex;
  }
  .video-icon {
    position: absolute;
    top: 45%;
    left: 45%;
    cursor: pointer;
  }
  .video-preview {
    border: 0.5px solid var(--border-primary);
    border-radius: 8px;
    max-width: 353px;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--background-primary);
    overflow: hidden;
    ${(props) =>
      props.isStartFromVideo &&
      css`
        /* margin-top: 0px !important; */
      `}
  }
  .screen-recording-desc-div {
    display: flex;
    align-items: center;
    gap: 6px;
  }
  .youtube-preview-image {
    width: 100%;
    height: auto;
    border-radius: 6px;
    border: 0.5px solid var(--border-primary);
    max-width: 353px;
  }
  .react-player__play-icon {
    display: none;
  }
  @media (max-width: 449px) {
    .screen-recording-header {
      gap: 10px;
    }
  }
`;

const YoutubePlayer = styled.div`
  height: 200px;
  width: 353px;
  .img-thumbnail {
    border: 0.5px solid var(--border-primary);
    max-width: unset;
  }
  @media (max-width: 449px) {
    width: 100%;
    height: auto;
    .img-thumbnail {
      width: 100% !important;
      height: auto !important;
      border: unset;
      border-radius: 0;
    }
  }
`;

export { DropBox, DropText, ScreenRecordMain, YoutubePlayer };
