import React from 'react';
import { useTable } from 'react-table';
import { Productname, Tabledata, ProductItem } from './styles';

interface Maincolumns {
  Header: string;
  accessor: string;
}

interface DataItem {
  [key: string]: any;
}

interface TableComponentProps {
  columns: Maincolumns[];
  data: DataItem[];
  subData?: DataItem[];
  subColumns?: Maincolumns[];
  hideLink?: boolean;
  linkname?: string;
  hidecontracticon?: boolean;
}

const Tablesubrow: React.FC<TableComponentProps> = ({ columns, data }) => {
  const { rows, prepareRow } = useTable({ columns, data });

  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <>
      {rows.map((row) => {
        prepareRow(row);
        return (
          <React.Fragment key={row?.id}>
            <tr {...row.getRowProps()} key={row?.id}>
              {row.cells.map((cell, cellIndex) => (
                <Tabledata {...cell.getCellProps()} key={cell.column.id}>
                  {cellIndex === 0 ? (
                    <ProductItem>
                      <div className='plus-icon'></div>
                      <Productname>
                        <p>{cell.row.original[columns[0].accessor]}</p>
                      </Productname>
                    </ProductItem>
                  ) : (
                    cell.render('Cell')
                  )}
                </Tabledata>
              ))}
            </tr>
          </React.Fragment>
        );
      })}
    </>
  );
};

export default Tablesubrow;
