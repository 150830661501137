import { ActionCreatorWithOptionalPayload, ActionCreatorWithPayload, createAction, Dispatch } from '@reduxjs/toolkit';
import * as Actions from './types';
import { ReducerInterface } from '../interfaces/ReducerInterface';
import {
  CustomerDetailsInterface,
  CustomerInvoiceData,
  CustomerProjectListInterface,
  ProjectIdNameInterface,
  MemberInputInterface
} from '../interfaces/CustomerInterface';

/**
 * @desc Set Customer loader
 */
export const setCustomerLoader: ActionCreatorWithPayload<boolean, string> = createAction(Actions.SET_CUSTOMER_LOADER);

/**
 * @desc Set Customer list
 */
export const setCustomerList: ActionCreatorWithPayload<CustomerDetailsInterface[], string> = createAction(
  Actions.SET_CUSTOMER_LIST
);

/**
 * @desc Set Customer filtered list
 */
export const setFilteredCustomerList: ActionCreatorWithPayload<CustomerDetailsInterface[], string> = createAction(
  Actions.SET_FILTERED_CUSTOMER_LIST
);

/**
 * @desc Set Customer detail
 */
export const setCustomerDetail: ActionCreatorWithPayload<CustomerDetailsInterface, string> = createAction(
  Actions.SET_CUSTOMER_DETAIL
);

/**
 * @desc Set Customer detail
 */
export const updateCustomerDetail: ActionCreatorWithPayload<ReducerInterface, string> = createAction(
  Actions.UPDATE_CUSTOMER_DETAIL
);

/**
 * @desc Clear Customer detail
 */
export const clearCustomerDetail: ActionCreatorWithOptionalPayload<undefined, string> = createAction(
  Actions.CLEAR_CUSTOMER_DETAIL
);

/**
 * @desc set individual Customer detail
 */
export const setIndividualCustomerDetail: ActionCreatorWithPayload<any, string> = createAction(
  Actions.SET_INDIVIDUAL_CUSTOMER_DETAIL
);

/**
 * @desc set individual Customer project detail
 */
export const setCustomerProjectDetail: ActionCreatorWithPayload<ProjectIdNameInterface[], string> = createAction(
  Actions.SET_CUSTOMER_PROJECT_DETAIL
);

/**
 * @desc set individual Customers invoice list
 */
export const setCustomersInvoiceList: ActionCreatorWithPayload<CustomerInvoiceData[], string> = createAction(
  Actions.SET_CUSTOMERS_INVOICE_LIST
);

/**
 * @desc set individual Customers project list
 */
export const setCustomersProjectList: ActionCreatorWithPayload<CustomerProjectListInterface[], string> = createAction(
  Actions.SET_CUSTOMERS_PROJECT_LIST
);

/**
 * @desc set member input
 */
export const setMemberInput: ActionCreatorWithPayload<MemberInputInterface, string> = createAction(
  Actions.SET_MEMBER_INPUT
);

/**
 * @desc update member input
 */
export const updateMemberInput: ActionCreatorWithPayload<{ propsName: string; value: string | number }, string> =
  createAction(Actions.UPDATE_MEMBER_INPUT);

/**
 * @desc clear member input
 */
export const clearMemberInput: ActionCreatorWithOptionalPayload<any, string> = createAction(Actions.CLEAR_MEMBER_INPUT);

/**
 * @desc set members
 */
export const setCustomerMembers: ActionCreatorWithPayload<
  { propsName: string; value: MemberInputInterface[] },
  string
> = createAction(Actions.SET_CUSTOMER_MEMBERS);

/**
 * @desc Clear Customer Data
 */
export const clearCustomerData = () => (dispatch: Dispatch) => {
  dispatch(setCustomerLoader(false));
  dispatch(setCustomerList([]));
  dispatch(setFilteredCustomerList([]));
  dispatch(clearCustomerDetail());
  dispatch(setIndividualCustomerDetail({}));
  dispatch(setCustomerProjectDetail([]));
};
