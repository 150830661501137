import styled, { css } from 'styled-components';
import { Typography } from '../../../global/theme';

const Modalhead = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 0.5px solid var(--border-primary);
`;

const Headline = styled.h6`
  ${Typography.heading_md}
  color: var(--text-primary);
  margin: 0;
`;
const Icon1 = styled.div`
  display: inline-flex;
  cursor: pointer;
  .close-icon {
    cursor: pointer;
    path {
      stroke: var(--text-primary);
    }
    :hover {
      background-color: var(--neutral-secondary);
      border-radius: 4px;
    }
  }
`;

const Modalblock = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;
`;

const SecmodalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Form = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const Label = styled.label`
  ${Typography.body_md_medium}
  margin: 0;
  color: var(--text-secondary);
`;
const Inputvalues = styled.input`
  width: 100%;
  padding: 8px 12px;
  ${Typography.body_lg_medium}
  color: var(--text-primary);
  background-color: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 4px;
  outline: none;
  :focus {
    border: 0.5px solid var(--brand-primary);
    z-index: 999;
  }
  ::placeholder {
    ${Typography.body_lg_medium}
    color: var(--text-placeholder);
  }
`;
const Iconview = styled.div`
  display: flex;
  .angledown-icon {
    path {
      stroke: var(--text-primary);
    }
  }
`;
const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 12px;
  justify-content: flex-end;
  margin-top: 8px;
`;

const Dropdata = styled.div`
  max-height: 94px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: 4px;
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background: var(--text-placeholder);
    border-radius: 12px;
    min-height: 28px;
  }
  @media (max-width: 449px) {
    height: 170px;
  }
`;

const Icon = styled.div`
  width: 100%;
  height: 8px;
  border-radius: 20px;
  max-width: 8px;
  ${(props: { iconColor: string }) =>
    props.iconColor &&
    css`
      background-color: ${props.iconColor};
    `}
`;

const Dropdownbox = styled.div`
  min-width: 404px;
  padding: 8px 12px;
  color: var(--text-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 4px;
  outline: none;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  .svg {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .angledown-icon {
    path {
      stroke: var(--text-primary);
    }
  }
`;

const Inputbox = styled.input`
  width: 100%;
  padding: 0;
  max-width: 376px;
  ${Typography.body_lg}
  display: flex;
  color: var(--text-primary);
  background-color: var(--background-primary);

  border: none;
  :focus {
    border: none;
  }
  :focus-visible {
    outline: none;
  }
  ::placeholder {
    ${Typography.body_lg}
    color: var(--text-placeholder);
  }
`;
export {
  Buttons,
  Dropdata,
  Dropdownbox,
  Form,
  Headline,
  Icon,
  Icon1,
  Iconview,
  Inputbox,
  Inputvalues,
  Label,
  Modalblock,
  Modalhead,
  SecmodalContent
};
