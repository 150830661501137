import React, { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../navbar';
import ReportNavbar from '../navbar/ReportNavbar';
import SettingNavbar from '../navbar/SettingNavbar';
import { CMDKOverLayDiv, Content, Headline, Icon, MainSection, Modalhead } from './styles';
import { VercelCMDK } from '../vercel/vercel';
import { useHotkeys } from 'react-hotkeys-hook';
import { isEmpty } from 'lodash';
import Responsivnavbar from '../navbar/responsivenavbar';
import ModalCustom from '../models/modal';
import SVGIcon from '../../assets/images/svg/SVGIcon';
import CreateNewProjectModal from '../models/createNewProjectModal';
import CreateTaskModal from '../models/createTaskModal';
import { isIpad, isMediaRecordingSupported } from '../../helpers/common';
import SnapRecordModal from '../models/snapRecordModal';
import { useMobileDevice } from '../../global/useMobile';
import { ContactUsModal } from '../models/contactUsmodal';
import { SuccessfullModal } from '../models/contactUsmodal/successfullmessagemodal';
import { useSelector, useDispatch } from 'react-redux';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';
import { setVercelModalOpen } from '../../actions/appActions';
import UserModal from '../models/userModal';

interface Props {
  children?: any;
  isInbox?: boolean;
  Inbox?: boolean;
  isPosition?: boolean;
  isMessageBox?: boolean;
  isPadding?: boolean;
  isNewDocPage?: boolean;
  isTaskDetailPreivewOpen?: boolean;
  isOverflowHidden?: boolean;
}

const AppLayout: React.FC<Props> = (Props) => {
  const {
    isInbox = false,
    children,
    Inbox = false,
    isPosition = false,
    isMessageBox = false,
    isPadding = false,
    isNewDocPage = false,
    isTaskDetailPreivewOpen = false,
    isOverflowHidden = false
  } = Props;
  //States
  const [searchBox, setSearchBox] = useState(false);
  const [isOpenProjectModal, setIsOpenProjectModal] = useState(false);
  const [isOpenSnapModal, setIsOpenSnapModal] = useState(false);
  const [isOpenTaskModal, setIsOpenTaskModal] = useState(false);
  const [openContactUsModal, setOpenContactUsModal] = useState(false);
  const [isSuccessfulModalOpen, setIsSuccessfulModalOpen] = useState(false);
  const [isOpenUserModal, setIsOpenUserModal] = useState(false);
  // use selector state variable
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { app: appSelector } = stateSelector || {};
  const { responsiveVercelModal, isNavbarShow } = appSelector;
  //other variables
  const isMobile = useMobileDevice();
  const location = useLocation();
  const dispatch = useDispatch();

  useHotkeys(['ctrl+k', 'meta+k'], async (event) => {
    event.preventDefault();
    setSearchBox(true);
  });
  useHotkeys('esc', async (event) => {
    event.preventDefault();
    setSearchBox(false);
  });

  const closeCmdPopup = useCallback(() => {
    if (isMobile) {
      dispatch(setVercelModalOpen(false));
    } else {
      setSearchBox(false);
    }
  }, [dispatch, isMobile]);

  const handleOverlayClick = useCallback(
    (e: any) => {
      if (isMobile) {
        dispatch(setVercelModalOpen(false));
        return;
      }
      if (!isEmpty(e.target.className)) {
        setSearchBox(false);
      } else {
        setSearchBox(true);
      }
    },
    [dispatch, isMobile]
  );

  const onClickCreateProject = useCallback(() => {
    setIsOpenProjectModal(true);
  }, []);

  const closeProjectModal = useCallback(() => {
    setIsOpenProjectModal(false);
  }, []);

  const onClickCreateTask = useCallback(() => {
    setIsOpenTaskModal(true);
  }, []);

  const openSnapModal = useCallback(() => {
    setIsOpenSnapModal(true);
  }, []);

  const closeSnapModal = useCallback(() => {
    setIsOpenSnapModal(false);
  }, []);

  const closeTaskModal = useCallback(() => {
    setIsOpenTaskModal(false);
  }, []);

  const onCloseContactUsModal = useCallback(() => {
    setOpenContactUsModal(false);
  }, []);

  const onCloseSuccessfullModal = useCallback(() => {
    setIsSuccessfulModalOpen(false);
  }, []);

  const onClickUserPopup = useCallback(() => {
    setIsOpenUserModal(true);
  }, []);

  const onCloseUserModal = useCallback(() => {
    setIsOpenUserModal(false);
  }, []);

  const renderCmdBox = useMemo(() => {
    const isVercelModalOpen = searchBox || (isMobile && responsiveVercelModal);
    return (
      isVercelModalOpen && (
        <>
          <CMDKOverLayDiv onClick={handleOverlayClick} />
          <VercelCMDK
            openSnapModal={openSnapModal}
            closePopup={closeCmdPopup}
            openProjectModal={onClickCreateProject}
            openTaskModal={onClickCreateTask}
            openUserModal={onClickUserPopup}
            setOpenContactUsModal={() => setOpenContactUsModal(!openContactUsModal)}
          />
        </>
      )
    );
  }, [
    closeCmdPopup,
    handleOverlayClick,
    isMobile,
    onClickCreateProject,
    onClickCreateTask,
    onClickUserPopup,
    openContactUsModal,
    openSnapModal,
    responsiveVercelModal,
    searchBox
  ]);

  const renderNavbar = useCallback(() => {
    if (location.pathname.startsWith('/settings')) {
      if (!isMobile) {
        return <SettingNavbar />;
      }
    } else if (location.pathname.startsWith('/report')) {
      if (!isMobile) {
        return <ReportNavbar />;
      }
    } else {
      return (
        <>
          <div className='webview'>
            <Navbar setOpenContactUsModal={() => setOpenContactUsModal(!openContactUsModal)} />
          </div>
          <div className='mobileview'>
            {!location.pathname.startsWith('/projects') &&
            location.pathname !== '/' &&
            !location.pathname.startsWith('/messages') &&
            !location.pathname.startsWith('/timeTracking') &&
            !location.pathname.startsWith('/customer') &&
            !location.pathname.startsWith('/my-tasks') &&
            !location.pathname.startsWith('/search') &&
            !location.pathname.startsWith('/recurring-tasks') &&
            !location.pathname.startsWith('/invoices') &&
            !location.pathname.startsWith('/home') &&
            !location.pathname.startsWith('/snap') &&
            !location.pathname.startsWith('/members') ? (
              <Responsivnavbar />
            ) : (
              ''
            )}
          </div>
        </>
      );
    }
  }, [isMobile, location.pathname, openContactUsModal]);

  return (
    <MainSection
      isInbox={isInbox}
      isMessageBox={isMessageBox}
      isTaskDetailPreivewOpen={isTaskDetailPreivewOpen}
      className='main-section'>
      {renderCmdBox}
      {renderNavbar()}
      <Content
        Inbox={Inbox}
        isPosition={isPosition}
        isPadding={isPadding}
        isNewDocPage={isNewDocPage}
        isOverflowHidden={isOverflowHidden}
        className='right-content'
        style={
          isIpad()
            ? {
                borderRadius: isNavbarShow
                  ? '8px 8px var(--ipad-border-radius) 8px'
                  : '8px 8px var(--ipad-border-radius) var(--ipad-border-radius)'
              }
            : {}
        }>
        {children}
      </Content>
      <ModalCustom open={isOpenProjectModal} onClose={closeProjectModal} width={632}>
        <Modalhead>
          <Headline>Create New Project</Headline>
          <Icon onClick={closeProjectModal}>
            <SVGIcon name='close-icon' width='18' height='18' viewBox='0 0 18 18' fill='none' className='svgicon' />
          </Icon>
        </Modalhead>
        <CreateNewProjectModal handleCloseModal={closeProjectModal} onClickCancel={closeProjectModal} />
      </ModalCustom>
      <ModalCustom open={isOpenTaskModal} onClose={closeTaskModal} isCreateTask={true}>
        <CreateTaskModal onCancel={closeTaskModal} isFromShortcut={true} />
      </ModalCustom>
      {isMediaRecordingSupported() && (
        <ModalCustom open={isOpenSnapModal} onClose={closeSnapModal} width={258} isZindex={true}>
          <SnapRecordModal handleCloseModal={closeSnapModal} />
        </ModalCustom>
      )}
      {/* contact us Modal */}
      <ModalCustom open={openContactUsModal} onClose={onCloseContactUsModal} width={500}>
        <ContactUsModal
          openSuccessfullModal={() => setIsSuccessfulModalOpen(true)}
          closeContactUsModal={() => setOpenContactUsModal(false)}
        />
      </ModalCustom>
      <ModalCustom open={isSuccessfulModalOpen} onClose={onCloseSuccessfullModal} width={500}>
        <SuccessfullModal onClose={onCloseSuccessfullModal} />
      </ModalCustom>
      <ModalCustom open={isOpenUserModal} onClose={onCloseUserModal} width={280}>
        <UserModal onClose={onCloseUserModal} />
      </ModalCustom>
    </MainSection>
  );
};

export default AppLayout;
