import {
  Bottom,
  BottomTitle,
  Default,
  Div,
  Head,
  Heading,
  Iconhidden,
  LeftDiv,
  PlusIcon,
  Status,
  StatusGroup
} from './style';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { Modal as AntModal, ModalProps } from 'antd';
import styled from 'styled-components';
import { ProjectTemplateDetailsInterface } from '../../../interfaces/SettingsInterface';
import {
  GroupByStatusInterface,
  ProjectDetailInterface,
  StatusListInterface
} from '../../../interfaces/ProjectInterface';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { PROJECT_SETTINGS_MENU_OPTION, STATUS_TYPE } from '../../../global/constants';
import Deletemodal from '../../models/deleteModel';
import { groupByStatus } from '../../../helpers/common';
import { useDispatch } from 'react-redux';
import { setStatusInput, updateCurrentProject } from '../../../actions/projectActions';
import { deleteStatus, updateStatusData } from '../../../services/projectServices';
import { updateProjectTemplateDetails } from '../../../actions/settingActions';
import TasksCacheService from '../../../services/tasksCatchServices';
import { TaskDetailsInterface } from '../../../interfaces/TaskInterface';
import { setErrorMessage } from '../../../actions/messageActions';
import { Draggable, DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Autotext, Box, Leftrow, Row, Subtitle, Title } from '../style';
import { Icon2 } from '../priorities/styles';
import Button from '../../button';
import { Dropdown, DropdownItem } from '../../Dropdown';
declare type DraggableProvided = typeof import('react-beautiful-dnd');
declare type DraggableStateSnapshot = typeof import('react-beautiful-dnd');

const Modal: React.FC<ModalProps> = styled(AntModal)`
  .ant-modal-content {
    box-shadow: 'var(--shadow-modal)';
    padding: 0;
    border-radius: 12px;
    background: 'var(--background-primary)';
  }
  .ant-modal-header {
    margin: 0;
  }
  .ant-modal-footer {
    margin: 0;
  }
`;
interface Props {
  themeMode: { theme: string };
  loadingButton: boolean;
  setModalOpen: (value: boolean) => void;
  currentProject: ProjectDetailInterface | ProjectTemplateDetailsInterface;
  onUpdateStatus: (value: boolean) => void;
  setStatusType: (value: number) => void;
  loadProjectData: () => void;
  isTemplateProject: boolean;
}
const StatusSettingCard: React.FC<Props> = (props) => {
  const {
    setModalOpen,
    currentProject,
    onUpdateStatus,
    setStatusType,
    loadProjectData,
    isTemplateProject,
    loadingButton
  } = props;
  //Refs
  const buttonRef = useRef<Map<string, React.RefObject<HTMLDivElement>>>(new Map());
  //States
  const [deleteModelopen, setDeleteModalOpen] = useState(false);
  const [statusList, setStatusList] = useState<GroupByStatusInterface[]>([]);
  const [deleteItem, setDeleteItem] = useState<StatusListInterface>();
  const [deleteLoading, setDeleteLoading] = useState(false);
  //Other variable
  const dispatch = useDispatch();
  const backgroundColor = 'var(--background-primary)';
  const borderColor = '0.5px solid var(--border-primary)';

  const loadData = useCallback(async () => {
    if (currentProject?.statusData?.length > 0) {
      const sortList = groupByStatus(currentProject?.statusData, 'Type');
      setStatusList(sortList);
    } else {
      setStatusList([]);
    }
  }, [currentProject?.statusData]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const Opendeletemodel = (item: StatusListInterface) => {
    setDeleteModalOpen(true);
    setDeleteItem(item);
  };

  const onClose = () => {
    setDeleteModalOpen(false);
  };

  const onClickAddGroup = useCallback(
    (type: number) => {
      setModalOpen(true);
      setStatusType(type);
    },
    [setModalOpen, setStatusType]
  );

  const onClickEdit = useCallback(
    (item: StatusListInterface) => {
      try {
        dispatch(setStatusInput({ name: item?.Name, id: item?.['_id'] }));
        setModalOpen(true);
      } catch (error) {
        console.log('error', error);
      }
    },
    [dispatch, setModalOpen]
  );

  const handleDelete = useCallback(async () => {
    setDeleteLoading(true);
    try {
      if (isTemplateProject) {
        const statusClone: StatusListInterface[] = JSON.parse(JSON.stringify(currentProject?.statusData || []));
        const statusIndex = statusClone?.findIndex((x) => x['_id'] === deleteItem?.['_id']);
        statusClone.splice(statusIndex, 1);
        dispatch(
          updateProjectTemplateDetails({
            propsName: 'statusData',
            value: statusClone
          })
        );
      } else {
        const local_project_tasks: TaskDetailsInterface[] = await TasksCacheService.getInstance()?.getTasksByProject(
          currentProject?.id
        );
        const statusUseTasks = local_project_tasks?.filter((task) => task?.statusId === deleteItem?.['_id']);
        if (statusUseTasks?.length > 0) {
          dispatch(setErrorMessage(`Unable to delete status "${deleteItem?.Name}", as it is currently in use!`));
        } else {
          await dispatch(deleteStatus(currentProject?.id, deleteItem?.['_id']));
          loadProjectData();
        }
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setDeleteModalOpen(false);
      setDeleteLoading(false);
    }
  }, [currentProject?.statusData, currentProject?.id, deleteItem, dispatch, isTemplateProject, loadProjectData]);

  const renderStatusIcon = useCallback((type: number) => {
    switch (type) {
      case STATUS_TYPE.BACKLOG:
        return (
          <SVGIcon name='task-status-icon' width='16' height='16' viewBox='0 0 16 16' className='task-status-icon' />
        );
      case STATUS_TYPE.UNSTARTED:
        return <SVGIcon name='task-todo-icon' width='16' height='16' viewBox='0 0 16 16' className='task-todo-icon' />;
      case STATUS_TYPE.IN_PROGRESS:
        return (
          <SVGIcon
            name='task-inprogress-icon'
            width='16'
            height='16'
            viewBox='0 0 16 16'
            className='task-inprogress-icon'
          />
        );
      case STATUS_TYPE.IN_REVIEW:
        return (
          <SVGIcon
            name='task-inreview-icon'
            width='16'
            height='16'
            viewBox='0 0 16 16'
            className='task-inreview-icon'
          />
        );
      case STATUS_TYPE.COMPLETED:
        return (
          <SVGIcon
            name='task-completed-icon'
            width='16'
            height='16'
            viewBox='0 0 16 16'
            className='task-completed-icon'
          />
        );
      case STATUS_TYPE.CANCELLED:
        return (
          <SVGIcon name='task-cancelled-icon' width='16' height='16' viewBox='0 0 16 16' className='cancelled-icon' />
        );
      default:
        return <></>;
    }
  }, []);

  const getItemStyle = (isDragging: boolean, draggableStyle: React.CSSProperties) => ({
    userSelect: 'none',
    background: isDragging ? backgroundColor : 'none',
    borderRadius: isDragging ? 40 : 0,
    border: isDragging ? borderColor : 'none',
    boxShadow: isDragging ? 'var(--shadow-card-hover)' : 'none',
    ...draggableStyle
  });

  const onClickDefaultSet = useCallback(
    async (statusDetails: StatusListInterface) => {
      if (isTemplateProject) {
        const statusClone: StatusListInterface[] = JSON.parse(JSON.stringify(currentProject?.statusData || []));
        const updatedStatus = statusClone?.map((x) => {
          return { ...x, Default: x['_id'] === statusDetails['_id'] };
        });
        const DefaultStatus = statusClone.find((status) => status['_id'] === statusDetails['_id']);
        const updatedDefaultStatus = { ...DefaultStatus, Default: true };
        dispatch(
          updateProjectTemplateDetails({
            propsName: 'statusData',
            value: updatedStatus
          })
        );
        dispatch(
          updateProjectTemplateDetails({
            propsName: 'defaultStatus',
            value: updatedDefaultStatus
          })
        );
        loadProjectData();
      } else {
        try {
          const result = await dispatch(updateStatusData(currentProject?.id, statusDetails['_id'], { default: true }));
          if (result) loadProjectData();
        } catch (error) {
          console.log('error', error);
        }
      }
    },
    [currentProject?.statusData, currentProject?.id, dispatch, isTemplateProject, loadProjectData]
  );

  const handleMenuClick = useCallback(
    (key: number | string, item: any) => {
      if (key === PROJECT_SETTINGS_MENU_OPTION.EDIT) {
        onClickEdit(item);
      } else if (key === PROJECT_SETTINGS_MENU_OPTION.SET_AS_DEFAULT) {
        onClickDefaultSet(item);
      } else {
        Opendeletemodel(item);
      }
    },
    [onClickDefaultSet, onClickEdit]
  );
  const RenderMenuItems = useCallback(
    (item: any) => {
      const MenuItems = [
        { label: 'Edit', key: PROJECT_SETTINGS_MENU_OPTION.EDIT },
        { label: 'Set as Default', key: PROJECT_SETTINGS_MENU_OPTION.SET_AS_DEFAULT },
        { label: 'Delete', key: PROJECT_SETTINGS_MENU_OPTION.DELETE, tone: 'critical' }
      ];

      return MenuItems.map((menuItem) => (
        <DropdownItem
          label={menuItem.label}
          key={menuItem?.key}
          tone={menuItem.label === 'Delete' ? 'critical' : 'primary'}
          onClick={() => handleMenuClick(menuItem.key, item)}
        />
      ));
    },
    [handleMenuClick]
  );

  const RenderContentItem = useCallback(
    (item: any) => {
      return item?.['_id'] === currentProject?.defaultStatus?.['_id'] ? <Default>Default</Default> : null;
    },
    [currentProject?.defaultStatus]
  );

  const getStatusGroupItems = (type: number) => {
    const statusData = statusList.find((item) => item?.Type === type);
    return statusData?.data.map((item: StatusListInterface, index: string) => {
      if (!buttonRef.current.has(item['_id'])) {
        buttonRef.current.set(item['_id'], React.createRef<HTMLDivElement>());
      }

      return (
        <Draggable draggableId={item['_id']} index={index} key={item['_id']}>
          {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
            <Bottom
              key={item?.Name}
              ref={provided.innerRef}
              {...provided.draggableProps}
              style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
              <Iconhidden {...provided.dragHandleProps}>
                <SVGIcon
                  className='svgicon'
                  name='three-dots-vertical-icon'
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                />
              </Iconhidden>
              <LeftDiv>
                {renderStatusIcon(type)}
                <BottomTitle>{item?.Name}</BottomTitle>
              </LeftDiv>

              <Dropdown
                content={
                  <Div>
                    {RenderContentItem(item)}
                    <Icon2>
                      <SVGIcon
                        name='loading-more-icon'
                        width='18'
                        height='18'
                        viewBox='0 0 18 18'
                        className='moreicon'
                      />
                    </Icon2>
                  </Div>
                }
                trigger='click'>
                {RenderMenuItems(item)}
              </Dropdown>
            </Bottom>
          )}
        </Draggable>
      );
    });
  };

  const onDragEnd = useCallback(
    async (item: any) => {
      const { destination, draggableId, source } = item || {};
      const { droppableId: sourceType, index: sourceIndex } = source || {};
      const { droppableId: destinationType, index: destinationIndex } = destination;
      if (sourceType === destinationType && sourceIndex === destinationIndex) return;
      if (sourceType === destinationType) {
        const statusData = statusList.find((item) => item?.Type === destinationType);
        let updatedTaskIndex;
        if (destinationIndex === 0) {
          const nextTaskIndex = statusData?.data?.[0]?.Index;
          updatedTaskIndex = nextTaskIndex ? nextTaskIndex - 100 : new Date().getTime();
        } else if (destinationIndex === statusData?.data?.length - 1) {
          const previousTaskIndex = statusData?.data?.[statusData?.data?.length - 1]?.Index;
          updatedTaskIndex = previousTaskIndex ? previousTaskIndex + 100 : new Date().getTime();
        } else {
          if (destinationIndex > sourceIndex) {
            const previousTaskIndex: number = statusData?.data?.[destinationIndex]?.Index || 0;
            const nextTaskIndex: number = statusData?.data?.[destinationIndex + 1]?.Index || 0;
            updatedTaskIndex = (previousTaskIndex + nextTaskIndex) / 2;
          } else {
            const previousTaskIndex: number = statusData?.data?.[destinationIndex - 1]?.Index || 0;
            const nextTaskIndex: number = statusData?.data?.[destinationIndex]?.Index || 0;
            updatedTaskIndex = (previousTaskIndex + nextTaskIndex) / 2;
          }
        }
        const updatedStatus = currentProject?.statusData?.map((x) => {
          if (x['_id'] === draggableId) {
            return { ...x, Index: updatedTaskIndex, Type: destinationType };
          } else return x;
        });
        if (!isTemplateProject) {
          const payload = {
            index: updatedTaskIndex
          };
          dispatch(updateCurrentProject({ propsName: 'statusData', value: updatedStatus }));
          await dispatch(updateStatusData(currentProject?.id, draggableId, payload));
        } else {
          dispatch(updateProjectTemplateDetails({ propsName: 'statusData', value: updatedStatus }));
        }
      } else if (sourceType !== destinationType) {
        const statusData = statusList.find((item) => item?.Type === destinationType);
        let updatedTaskIndex;
        if (destinationIndex === 0) {
          const nextTaskIndex = statusData?.data?.[0]?.Index;
          updatedTaskIndex = nextTaskIndex ? nextTaskIndex - 100 : new Date().getTime();
        } else if (destinationIndex === statusData?.data?.length) {
          const previousTaskIndex = statusData?.data?.[statusData?.data?.length - 1]?.Index;
          updatedTaskIndex = previousTaskIndex ? previousTaskIndex + 100 : new Date().getTime();
        } else {
          const previousTaskIndex: number = statusData?.data?.[destinationIndex - 1]?.Index || 0;
          const nextTaskIndex: number = statusData?.data?.[destinationIndex]?.Index || 0;
          updatedTaskIndex = (previousTaskIndex + nextTaskIndex) / 2;
        }
        const updatedStatus = currentProject?.statusData?.map((x) => {
          if (x['_id'] === draggableId) {
            return { ...x, Index: updatedTaskIndex, Type: destinationType };
          } else return x;
        });
        if (!isTemplateProject) {
          dispatch(updateCurrentProject({ propsName: 'statusData', value: updatedStatus }));
          const payload = {
            index: updatedTaskIndex,
            type: destinationType
          };
          await dispatch(updateStatusData(currentProject?.id, draggableId, payload));
        } else {
          dispatch(updateProjectTemplateDetails({ propsName: 'statusData', value: updatedStatus }));
        }
      }
    },
    [currentProject?.statusData, currentProject?.id, dispatch, isTemplateProject, statusList]
  );

  return (
    <>
      <Box>
        <Row>
          <Leftrow>
            <Title>Status</Title>
            <Subtitle>Statuses define task progression and can be customized and reordered</Subtitle>
          </Leftrow>
          <Autotext>
            {loadingButton ? (
              <Button isLoading={loadingButton} />
            ) : (
              <label className='switch'>
                <input
                  type='checkbox'
                  checked={currentProject?.statusEnable || false}
                  onChange={() => onUpdateStatus(!currentProject?.statusEnable)}
                />
                <span className='slider round'></span>
              </label>
            )}
          </Autotext>
        </Row>
        {currentProject?.statusEnable && (
          <DragDropContext onDragEnd={onDragEnd}>
            <Status>
              <Droppable droppableId={STATUS_TYPE.BACKLOG} key={STATUS_TYPE.BACKLOG}>
                {(provided: DraggableProvided) => (
                  <StatusGroup key={STATUS_TYPE.BACKLOG} ref={provided.innerRef} {...provided.droppableProps}>
                    <Head>
                      <Heading>Backlog</Heading>
                      <PlusIcon onClick={() => onClickAddGroup(STATUS_TYPE.BACKLOG)}>
                        <SVGIcon
                          name='status-plusicon'
                          width='18'
                          height='18'
                          viewBox='0 0 18 18'
                          className='svg-icon'></SVGIcon>
                      </PlusIcon>
                    </Head>
                    {getStatusGroupItems(STATUS_TYPE.BACKLOG)}
                    {provided?.placeholder}
                  </StatusGroup>
                )}
              </Droppable>
              <Droppable droppableId={STATUS_TYPE.UNSTARTED} key={STATUS_TYPE.UNSTARTED}>
                {(provided: DraggableProvided) => (
                  <StatusGroup key={STATUS_TYPE.UNSTARTED} ref={provided.innerRef} {...provided.droppableProps}>
                    <Head>
                      <Heading>Unstarted</Heading>
                      <PlusIcon onClick={() => onClickAddGroup(STATUS_TYPE.UNSTARTED)}>
                        <SVGIcon
                          name='status-plusicon'
                          width='18'
                          height='18'
                          viewBox='0 0 18 18'
                          className='svg-icon'></SVGIcon>
                      </PlusIcon>
                    </Head>
                    {getStatusGroupItems(STATUS_TYPE.UNSTARTED)}
                    {provided?.placeholder}
                  </StatusGroup>
                )}
              </Droppable>
              <Droppable droppableId={STATUS_TYPE.IN_PROGRESS} key={STATUS_TYPE.IN_PROGRESS}>
                {(provided: DraggableProvided) => (
                  <StatusGroup key={STATUS_TYPE.IN_PROGRESS} ref={provided.innerRef} {...provided.droppableProps}>
                    <Head>
                      <Heading>In Progress</Heading>
                      <PlusIcon onClick={() => onClickAddGroup(STATUS_TYPE.IN_PROGRESS)}>
                        <SVGIcon
                          name='status-plusicon'
                          width='18'
                          height='18'
                          viewBox='0 0 18 18'
                          className='svg-icon'></SVGIcon>
                      </PlusIcon>
                    </Head>
                    {getStatusGroupItems(STATUS_TYPE.IN_PROGRESS)}
                    {provided?.placeholder}
                  </StatusGroup>
                )}
              </Droppable>
              <Droppable droppableId={STATUS_TYPE.IN_REVIEW} key={STATUS_TYPE.IN_REVIEW}>
                {(provided: DraggableProvided) => (
                  <StatusGroup key={STATUS_TYPE.IN_REVIEW} ref={provided.innerRef} {...provided.droppableProps}>
                    <Head>
                      <Heading>In Review</Heading>
                      <PlusIcon onClick={() => onClickAddGroup(STATUS_TYPE.IN_REVIEW)}>
                        <SVGIcon
                          name='status-plusicon'
                          width='18'
                          height='18'
                          viewBox='0 0 18 18'
                          className='svg-icon'></SVGIcon>
                      </PlusIcon>
                    </Head>
                    {getStatusGroupItems(STATUS_TYPE.IN_REVIEW)}
                    {provided?.placeholder}
                  </StatusGroup>
                )}
              </Droppable>
              <Droppable droppableId={STATUS_TYPE.COMPLETED} key={STATUS_TYPE.COMPLETED}>
                {(provided: DraggableProvided) => (
                  <StatusGroup key={STATUS_TYPE.COMPLETED} ref={provided.innerRef} {...provided.droppableProps}>
                    <Head>
                      <Heading>Completed</Heading>
                      <PlusIcon onClick={() => onClickAddGroup(STATUS_TYPE.COMPLETED)}>
                        <SVGIcon
                          name='status-plusicon'
                          width='18'
                          height='18'
                          viewBox='0 0 18 18'
                          className='svg-icon'></SVGIcon>
                      </PlusIcon>
                    </Head>
                    {getStatusGroupItems(STATUS_TYPE.COMPLETED)}
                    {provided?.placeholder}
                  </StatusGroup>
                )}
              </Droppable>
              <Droppable droppableId={STATUS_TYPE.CANCELLED} key={STATUS_TYPE.CANCELLED}>
                {(provided: DraggableProvided) => (
                  <StatusGroup key={STATUS_TYPE.CANCELLED} ref={provided.innerRef} {...provided.droppableProps}>
                    <Head>
                      <Heading>Cancelled</Heading>
                      <PlusIcon onClick={() => onClickAddGroup(STATUS_TYPE.CANCELLED)}>
                        <SVGIcon
                          name='status-plusicon'
                          width='18'
                          height='18'
                          viewBox='0 0 18 18'
                          className='svg-icon'></SVGIcon>
                      </PlusIcon>
                    </Head>
                    {getStatusGroupItems(STATUS_TYPE.CANCELLED)}
                    {provided?.placeholder}
                  </StatusGroup>
                )}
              </Droppable>
            </Status>
          </DragDropContext>
        )}
      </Box>
      <Modal
        open={deleteModelopen}
        closable={false}
        transitionName=''
        maskTransitionName=''
        centered
        bodyStyle={{}}
        width={334}
        title={[]}
        onCancel={onClose}
        footer={[]}>
        <Deletemodal
          onOk={handleDelete}
          onClose={onClose}
          loading={deleteLoading}
          modaltitle='Delete Status?'
          description='Are you sure you want to delete this status?'
        />
      </Modal>
    </>
  );
};
export default StatusSettingCard;
