import styled from 'styled-components';
import SVGIcon from '../../../assets/images/svg/SVGIcon';

const PageLoading = styled.span`
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

function AppLoading() {
  return (
    <PageLoading>
      <SVGIcon name={'app-loading'} width='36' height='36' viewBox='0 0 38 38' stroke='var(--primary-custom-light)' />
    </PageLoading>
  );
}

export default AppLoading;
