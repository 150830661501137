/* eslint-disable max-len */
import { useCallback } from 'react';
import CmdkItem from '../cmdkItem';

function RecordItem({
  setInputValue,
  closePopup,
  openSnapModal
}: {
  setInputValue: Function;
  closePopup: Function;
  openSnapModal: Function;
}) {
  const onRecordSnap = useCallback(() => {
    openSnapModal();
    setInputValue('');
    closePopup();
  }, [closePopup, openSnapModal, setInputValue]);

  return (
    <>
      <CmdkItem
        shortcut='⇧ R'
        onSelect={() => {
          onRecordSnap();
        }}>
        <RecordingIcon />
        Record Snap
      </CmdkItem>
    </>
  );
}

export default RecordItem;

function RecordingIcon() {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12 9C12 10.6569 10.6569 12 9 12C7.34315 12 6 10.6569 6 9C6 7.34315 7.34315 6 9 6C10.6569 6 12 7.34315 12 9Z'
        fill='currentColor'
      />
      <path
        d='M15.9 9C15.9 12.8108 12.8108 15.9 9 15.9C5.18923 15.9 2.1 12.8108 2.1 9C2.1 5.18923 5.18923 2.1 9 2.1C12.8108 2.1 15.9 5.18923 15.9 9Z'
        stroke='currentColor'
        stroke-width='1.2'
      />
    </svg>
  );
}
