import moment from 'moment';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { currencySymbols } from '../../../global/row';
import {
  Activitybottom,
  Activity,
  Totalcontents,
  Activitycontent,
  Activityrow,
  Activityleft,
  Infotext,
  Textdate,
  Activityright,
  Icon
} from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import { InvoiceActivityDetailsInterface } from '../../../interfaces/InvoiceInterface';
import { deleteActivityDetail } from '../../../services/invoiceService';
import { setSuccessMessage } from '../../../actions/messageActions';
import { captureException } from '../../../services/logService';
import { useParams } from 'react-router-dom';
import Deletemodal from '../../../component/models/deleteModel';
import ModalCustom from '../../../component/models/modal';
import { Dropdown, DropdownItem } from '../../../component/Dropdown';

interface InvoiceActivity {
  selectedItem: any;
  setSelectedItem: any;
  loadData: () => void;
  deleteInvoiceLoading: boolean;
  setDeleteInvoiceLoading: React.Dispatch<React.SetStateAction<boolean>>;
}
// eslint-disable-next-line @typescript-eslint/no-redeclare
const InvoiceActivity: React.FC<InvoiceActivity> = (props) => {
  const { selectedItem, setSelectedItem, loadData, deleteInvoiceLoading, setDeleteInvoiceLoading } = props;

  //Refs
  const buttonRef = useRef<React.RefObject<HTMLDivElement>[]>([]);

  //States
  const [deleteActivityModalOpen, setDeleteActivityModalOpen] = useState(false);
  const [openDropdownId, setOpenDropdownId] = useState<string | number | null>(null);

  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { invoice: invoiceSelector, auth: authSelector, workspace: workspaceSelector } = stateSelector || {};
  const { invoiceViewData, invoiceActivityDetail } = invoiceSelector;
  const { userDetails } = authSelector;
  const { workspace } = workspaceSelector;
  const { id: workspace_id } = workspace;

  //Other variable
  const dispatch = useDispatch();
  const params: { id: string } = useParams();

  // delete payment log
  const onClickDeleteActivity = useCallback(
    (activity: InvoiceActivityDetailsInterface) => {
      setSelectedItem(activity);
      setDeleteActivityModalOpen(true);
    },
    [setSelectedItem]
  );

  // handle dropdown toggle
  const handleDropdownToggle = useCallback(
    (id: string) => {
      if (openDropdownId === id) {
        setOpenDropdownId(null);
      } else {
        setOpenDropdownId(id);
      }
    },
    [openDropdownId]
  );

  // close delete activity modal
  const closeDeleteActivityModal = useCallback(() => {
    setDeleteActivityModalOpen(false);
  }, []);

  // for delete payment log
  const deletePaymentLog = useCallback(async () => {
    try {
      setDeleteInvoiceLoading(true);
      const result = await dispatch(deleteActivityDetail(params?.id, selectedItem?.id, workspace_id));
      if (result) {
        setDeleteActivityModalOpen(false);
        dispatch(setSuccessMessage('Invoice payment log deleted successfully!'));
        loadData();
      }
    } catch (error) {
      captureException(error);
      console.log('error', error);
    } finally {
      setDeleteInvoiceLoading(false);
    }
  }, [dispatch, loadData, params?.id, selectedItem?.id, setDeleteInvoiceLoading, workspace_id]);

  const getInfoText = useCallback(
    (invoiceItem: InvoiceActivityDetailsInterface, invoiceActivityDetail: InvoiceActivityDetailsInterface[]) => {
      if (invoiceItem?.typeId === 14) {
        const user = invoiceItem?.action === 'Update' ? invoiceItem?.user || '' : invoiceActivityDetail[0]?.user || '';

        return `${invoiceItem?.type} ${invoiceItem?.action} ${user ? 'by ' : ''} ${user}`;
      } else {
        const user = invoiceItem?.user || '';
        return `${invoiceItem?.desc} ${user ? 'by ' : ''}${user}`;
      }
    },
    []
  );

  // render invoice activity
  const renderInvoiceActivity = useMemo(() => {
    return invoiceActivityDetail?.map((invoiceItem, index) => {
      const ref = currencySymbols.find((x) => x.code === invoiceViewData?.currency);
      const symbol = ref?.symbol;
      if (!buttonRef?.current[index]) {
        buttonRef.current[index] = React.createRef<HTMLDivElement>();
      }
      return (
        <Activityrow key={invoiceItem?.id}>
          <Activityleft>
            <Infotext>{getInfoText(invoiceItem, invoiceActivityDetail)}</Infotext>
            {invoiceItem?.notes && <Textdate>{invoiceItem?.notes}</Textdate>}
            <Textdate>
              {moment(invoiceItem?.date).format(userDetails?.dateFormat)} . {invoiceItem?.amount ? symbol : ''}
              {invoiceItem?.amount}
            </Textdate>
          </Activityleft>
          <Activityright>
            {invoiceItem?.typeId === 14 ? (
              ''
            ) : (
              <Dropdown
                activeClassName='active'
                content={
                  <Icon
                    ref={buttonRef.current[index]}
                    onClick={() => {
                      handleDropdownToggle(invoiceItem?.id);
                    }}>
                    <SVGIcon name='more-icon' width='18' height='18' viewBox='0 0 18 18' className='more-icon' />
                  </Icon>
                }
                trigger='click'>
                <DropdownItem label='Delete' tone='critical' onClick={() => onClickDeleteActivity(invoiceItem)} />
              </Dropdown>
            )}
          </Activityright>
        </Activityrow>
      );
    });
  }, [
    getInfoText,
    handleDropdownToggle,
    invoiceActivityDetail,
    invoiceViewData?.currency,
    onClickDeleteActivity,
    userDetails?.dateFormat
  ]);

  return (
    <>
      <Activitybottom>
        <Activity>
          <Totalcontents>Activity</Totalcontents>
          <Activitycontent>{renderInvoiceActivity}</Activitycontent>
        </Activity>
      </Activitybottom>
      <ModalCustom open={deleteActivityModalOpen} onClose={closeDeleteActivityModal} width={334}>
        <Deletemodal
          onClose={closeDeleteActivityModal}
          onOk={deletePaymentLog}
          loading={deleteInvoiceLoading}
          modaltitle='Delete Payment Log?'
          description='Are you sure to want to delete this payment log?'
        />
      </ModalCustom>
    </>
  );
};

export default InvoiceActivity;
