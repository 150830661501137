import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  MainMenu,
  Menu,
  Left,
  Right,
  ProjectsName,
  Viewer,
  Timer,
  Record,
  Action,
  Icon,
  IconView,
  BottomSection,
  Check,
  Inputvalue,
  Box,
  Top,
  Bottom,
  Plandiv,
  ProjectTitle,
  ProjectItems,
  Text,
  Dropdownbutton,
  View,
  Subview,
  Content
} from './styles';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTES } from '../../../routes/urls';
import { ProjectItem } from '../ProjectItem';
import { useSelector, useDispatch } from 'react-redux';
import { formatTime, isEmpty, isMediaRecordingSupported } from '../../../helpers/common';
import TasksCacheService from '../../../services/tasksCatchServices';
import { TaskInterface } from '../../../interfaces/ProjectInterface';
import { setCurrentStartTime } from '../../../actions/timeTrackingActions';
import { CUSTOMER_MEMBER_ROLE } from '../../../global/constants';
import NavbarRecording from './recordingNavbar';
import { RootReducerInterface, UpdateDataInterface } from '../../../interfaces/RootReducerInterface';
import update from '../../../update.json';

import {
  DragDropContext,
  DraggableProvided,
  Draggable,
  Droppable,
  DraggableStateSnapshot,
  DropResult
} from 'react-beautiful-dnd';
import { updateUserPreference } from '../../../helpers/firebaseHelper';
import { trackAnalyticActivity } from '../../../services/analyticsService';
import Button from '../../button';
import { useMobileDevice } from '../../../global/useMobile';
import { Dropdown, DropdownItem } from '../../Dropdown';
import { rootStore } from '../../../mobx/rootStore';
import { ProjectModel } from '../../../mobx/models/project';
// eslint-disable-next-line @typescript-eslint/no-redeclare
declare type DraggableProvided = typeof import('react-beautiful-dnd');
// eslint-disable-next-line @typescript-eslint/no-redeclare
declare type DraggableStateSnapshot = typeof import('react-beautiful-dnd');

// eslint-disable-next-line @typescript-eslint/no-redeclare
declare type DropResult = typeof import('react-beautiful-dnd');

interface Props {
  isAdmin: any;
  countInvoices: any;
  favouriteProjects: ProjectModel[];
  trackingActivity: any;
  onClickStartStop: (itemId: string, time: string, item: any) => void;
  onCloseNavbar?: () => void;
  setOpenContactUsModal?: () => void;
  onClickOpenModel?: () => void;
}

interface MenuItemProps {
  path: string;
  iconName: string;
  label: string;
  count?: number; // Optional count for the right side
  className?: string;
  isActive: boolean;
}

export const MenuItem: React.FC<MenuItemProps> = ({ path, iconName, className, label, count, isActive }) => {
  //Other variable
  const history = useHistory();

  return (
    <Menu
      onClick={() => {
        history.push(path);
      }}
      className='menu-name'>
      <Left isActive={isActive}>
        <IconView className='pointer'>
          <SVGIcon name='navbar-active-point-icon' width='20' height='18' viewBox='0 0 20 18' fill='none' />
        </IconView>
        <SVGIcon name={iconName} width='18' height='18' viewBox='0 0 18 18' className={className} />
        <span>{label}</span>
      </Left>
      {count && count > 0 ? <Right className='count-number'>{count}</Right> : <></>}
    </Menu>
  );
};

const Navbarcontent: React.FC<Props> = (props) => {
  const {
    isAdmin,
    countInvoices = 0,
    favouriteProjects,
    trackingActivity,
    onClickStartStop,
    onCloseNavbar,
    setOpenContactUsModal,
    onClickOpenModel
  } = props;
  //States
  const [currentActiveTask, setCurrentActiveTask] = useState<TaskInterface | null>();
  const [inboxCount, setInboxCount] = useState<number>(0);
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const {
    workspace: workspaceSelector,
    timeTracking: timeTrackingSelector,
    settings: settingSelector
  } = stateSelector || {};
  const { workspace: workspaceDetail, workspaceTotalTask } = workspaceSelector;
  const { apps, customerRole } = workspaceDetail || {};
  const { currentStartTime } = timeTrackingSelector;
  const { subscriptionList } = settingSelector;

  //Mobx store
  const { notifications } = rootStore.notificationStore;

  //Other variable
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const mobile = useMobileDevice();

  const updateElapsedTime = useCallback(async () => {
    const startDate = new Date(trackingActivity[0]?.TimerStartedAt).getTime();
    const currentDate = new Date().getTime();
    const seconds = Math.ceil((currentDate - startDate) / 1000);
    dispatch(setCurrentStartTime({ task_id: trackingActivity[0]?.TaskId, time: seconds }));
    const activeTask = await TasksCacheService.getInstance()?.getItem(trackingActivity[0]?.TaskId);
    setCurrentActiveTask(activeTask);
  }, [dispatch, trackingActivity]);

  useEffect(() => {
    const unreadInbox = notifications?.filter((item) => !item?.isRead) || [];
    setInboxCount(unreadInbox?.length);
  }, [notifications]);

  useEffect(() => {
    let intervalId: any;
    if (trackingActivity?.length > 0) intervalId = setInterval(updateElapsedTime, 1000);
    else {
      if (intervalId) {
        clearInterval(intervalId);
        dispatch(setCurrentStartTime({ task_id: undefined, time: 0 }));
      }
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
        dispatch(setCurrentStartTime({ task_id: undefined, time: 0 }));
      }
    };
  }, [dispatch, trackingActivity, updateElapsedTime]);

  const onClickTaskDetails = useCallback(() => {
    history.push(`/projects/details/${currentActiveTask?.projectId}/tasks?task=${currentActiveTask?.id}`);
  }, [history, currentActiveTask]);

  const calculateUpdatedTaskIndex = useCallback(
    (destinationIndex: number, sourceIndex: number) => {
      let updatedTaskIndex;
      if (destinationIndex === 0) {
        const nextTaskIndex = favouriteProjects?.[0]?.index;
        updatedTaskIndex = nextTaskIndex ? nextTaskIndex - 500 : new Date().getTime();
      } else if (destinationIndex === favouriteProjects?.length - 1) {
        const previousTaskIndex = favouriteProjects?.[favouriteProjects?.length - 1]?.index;
        updatedTaskIndex = previousTaskIndex ? previousTaskIndex + 500 : new Date().getTime();
      } else {
        if (destinationIndex > sourceIndex) {
          const previousTaskIndex: number = favouriteProjects?.[destinationIndex]?.index || 0;
          const nextTaskIndex: number = favouriteProjects?.[destinationIndex + 1]?.index || 0;
          updatedTaskIndex = (previousTaskIndex + nextTaskIndex) / 2;
        } else {
          const previousTaskIndex: number = favouriteProjects?.[destinationIndex - 1]?.index || 0;
          const nextTaskIndex: number = favouriteProjects?.[destinationIndex]?.index || 0;
          updatedTaskIndex = (previousTaskIndex + nextTaskIndex) / 2;
        }
      }

      return updatedTaskIndex;
    },
    [favouriteProjects]
  );

  const handleDragEnd = async (item: DropResult) => {
    try {
      const { destination, draggableId, source } = item || {};
      const { index: sourceIndex } = source || {};
      const { index: destinationIndex } = destination;
      const updatedTaskIndex = calculateUpdatedTaskIndex(destinationIndex, sourceIndex);

      const updatedProject = favouriteProjects.find((project) => project.id === draggableId);
      if (updatedProject) {
        updatedProject.setIndex(updatedTaskIndex);
      }
      const payload = {
        [draggableId]: updatedTaskIndex
      };
      await updateUserPreference({ [`favorite_projects_${workspaceDetail?.id}`]: { ...payload } });
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleMenuItemClick = (url: string, event?: string) => {
    if (event) trackSupportActivity(event);
    if (url) window.open(url, '_blank', 'noopener,noreferrer');
  };

  const getPathName = useCallback(() => {
    const source: any = {
      '/my-tasks': 'mytask',
      '/invoices': 'invoice',
      '/timeTracking': 'time tracking',
      '/report': 'report',
      '/projects': 'project',
      '/messages': 'messages',
      '/customer': 'customer',
      '/settings': 'settings',
      '/': 'inbox'
    };
    const matchedKey = (Object.keys(source) as Array<keyof typeof source>).find((key) =>
      location.pathname.startsWith(key.toString())
    );
    return matchedKey ? source[matchedKey] : 'unknown';
  }, [location.pathname]);

  const trackSupportActivity = useCallback(
    (event: string) => {
      const path = getPathName();
      trackAnalyticActivity(`support: ${event}`, { source: `${path}` });
    },
    [getPathName]
  );

  const onContactUsClick = useCallback(() => {
    if (setOpenContactUsModal) setOpenContactUsModal();
    trackSupportActivity('contact us clicked');
  }, [setOpenContactUsModal, trackSupportActivity]);

  const renderActiveTimeTrackingBox = useMemo(() => {
    return (
      trackingActivity?.length > 0 && (
        <Timer className='timer'>
          {/* <div className='tooltip'>
      <div className='tooltiptext'> */}
          <Box className='box'>
            <Top onClick={onClickTaskDetails} className='top-section'>
              <Check>
                <Inputvalue type='checkbox' className='checkbox-round' disabled={true} />
              </Check>
              <p>{currentActiveTask?.name}</p>
            </Top>
            <Bottom>
              <Action>
                <SVGIcon
                  name='time-icon'
                  width='18'
                  height='18'
                  viewBox='0 0 18 18'
                  fill='none'
                  className='time-icon'
                />
                <span>{formatTime(currentStartTime?.time)}</span>{' '}
              </Action>
              <Record
                onClick={() =>
                  onClickStartStop(trackingActivity[0]['_id'], trackingActivity[0]?.TimerStartedAt, trackingActivity[0])
                }>
                <SVGIcon name='record-icon' width='8' height='8' viewBox='0 0 8 8' className='record-icon' />
              </Record>
            </Bottom>
          </Box>
        </Timer>
      )
    );
  }, [currentActiveTask?.name, currentStartTime?.time, onClickStartStop, onClickTaskDetails, trackingActivity]);

  return (
    <>
      <>
        <>
          <MainMenu>
            <MenuItem
              path='/'
              isActive={location?.pathname === '/'}
              iconName='navbar-inbox-icon'
              label='Inbox'
              count={inboxCount}
              className='fill-color'
            />
            <MenuItem
              path='/my-tasks'
              isActive={location?.pathname?.startsWith('/my-tasks')}
              label='My Tasks'
              iconName='navbar-MyTask-icon'
              className='fill-color'
            />
            {(isEmpty(apps?.messages) || apps?.messages === true) && (
              <MenuItem
                path='/messages'
                isActive={location?.pathname?.startsWith('/messages')}
                iconName='navbar-Messages-icon'
                label='Messages'
                className='fill-color'
              />
            )}
            {(isAdmin || customerRole === CUSTOMER_MEMBER_ROLE.FULL_ACCESS) &&
              (isEmpty(apps?.invoiceAndPayment) || apps?.invoiceAndPayment === true) && (
                <MenuItem
                  path='/invoices'
                  isActive={location?.pathname?.startsWith('/invoices')}
                  iconName='navbar-Invoice-icon'
                  className='stroke-color'
                  label='Invoices'
                  count={countInvoices}
                />
              )}
            {favouriteProjects?.length === 0 && (
              <MenuItem
                path='/projects'
                isActive={location?.pathname?.startsWith('/projects')}
                iconName='navbar-project-icon'
                className='fill-color'
                label='Projects'
              />
            )}
            {isAdmin && (isEmpty(apps?.customer) || apps?.customer === true) && (
              <MenuItem
                path='/customer'
                isActive={location?.pathname?.startsWith('/customer')}
                iconName='navbar-Customer-icon'
                className='stroke-color'
                label='Customers'
              />
            )}
            {(isEmpty(apps?.timeTracking) || apps?.timeTracking === true) &&
              customerRole !== CUSTOMER_MEMBER_ROLE.PROJECT_ONLY &&
              customerRole !== CUSTOMER_MEMBER_ROLE.FULL_ACCESS && (
                <MenuItem
                  path='/timeTracking'
                  isActive={location?.pathname?.startsWith('/timeTracking')}
                  iconName='navbar-TimeTracking-icon'
                  className='stroke-color'
                  label='Time Tracking'
                />
              )}
            {isAdmin && (
              <MenuItem
                path={mobile ? ROUTES.REPORT : ROUTES.REPORT_PAYMENTS}
                isActive={location?.pathname?.startsWith('/report')}
                iconName='navbar-Report-icon'
                className='stroke-color'
                label='Reports'
              />
            )}
          </MainMenu>
          <Content>
            <DragDropContext onDragEnd={(result: DropResult) => handleDragEnd(result)}>
              <Droppable droppableId='favrouite_project'>
                {(provided: DraggableProvided) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {favouriteProjects?.length > 0 && (
                      <ProjectsName istrackingActivity={trackingActivity?.length > 0}>
                        <ProjectTitle className='project'>Projects</ProjectTitle>
                        <ProjectItems>
                          {favouriteProjects?.map((item: any, index: number) => (
                            <Draggable key={item?.id} draggableId={item?.id} index={index}>
                              {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                  <ProjectItem
                                    key={item?.id}
                                    item={item}
                                    onCloseNavbar={onCloseNavbar ? onCloseNavbar : undefined}
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))}

                          {provided?.placeholder}
                          <Viewer onClick={() => history.push('/projects')}>
                            <Icon>
                              <SVGIcon
                                name='all-projects-icon'
                                width='18'
                                height='18'
                                viewBox='0 0 18 18'
                                fill='none'
                                className='dot-icon'
                              />
                            </Icon>
                            All Projects
                          </Viewer>
                        </ProjectItems>
                      </ProjectsName>
                    )}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <BottomSection className='navbar-bottom'>
              {isMediaRecordingSupported() && <NavbarRecording />}

              {/* {(workspaceDetail?.isOwner || workspaceDetail?.isAdmin)
      <InviteSection onClick={onClickOnInviteMember}>
        {themeMode?.theme === 'dark' ? (
          <SVGIcon name='light-invite-member-icon' width='18' height='18' viewBox='0 0 18 18' fill='none' />
        ) : (
          <SVGIcon name='dark-invite-member-icon' width='18' height='18' viewBox='0 0 18 18' fill='none' />
        )}
        <p>Invite Member</p>
      </InviteSection>
    } */}
              {renderActiveTimeTrackingBox}
              <Plandiv>
                <Dropdown
                  isMinWidth={210}
                  content={
                    <Dropdownbutton onClick={() => trackSupportActivity('opened')}>
                      <SVGIcon
                        name='question-mark-icon'
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        className='stroke-color'
                      />
                    </Dropdownbutton>
                  }
                  trigger='click'>
                  <DropdownItem
                    key={1}
                    label='Help Center'
                    iconName='help-center-icon'
                    iconSize={16}
                    iconViewBox='0 0 18 18'
                    iconTone='fill'
                    onClick={() =>
                      handleMenuItemClick(
                        'https://pixerhq.notion.site/Teamcamp-Help-Center-de92c457ecf442b7a0f3bf83ae116113',
                        'help center clicked'
                      )
                    }
                    isSuffix={
                      <SVGIcon
                        name='cross-arrow-icon'
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        className='stroke-color'
                      />
                    }
                  />
                  <DropdownItem
                    key={2}
                    label='API Docs'
                    iconName='html-code-icon'
                    iconSize={18}
                    iconTone='fill'
                    onClick={() =>
                      handleMenuItemClick('https://api.teamcamp.app/api-reference/introduction', 'api docs clicked')
                    }
                    isSuffix={
                      <SVGIcon
                        name='cross-arrow-icon'
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        className='stroke-color'
                      />
                    }
                  />
                  <DropdownItem
                    key={3}
                    label='Contact us'
                    iconName='navbar-Messages-icon'
                    iconSize={16}
                    iconViewBox='0 0 18 18'
                    onClick={onContactUsClick}
                    iconTone='fill'
                  />
                  <Text>What’s new</Text>
                  {update?.length > 0 &&
                    update?.map(
                      (item: UpdateDataInterface) =>
                        !isEmpty(item.title) && (
                          <DropdownItem
                            key={item?.redirect_url}
                            onClick={() => handleMenuItemClick(item?.redirect_url)}>
                            <View>
                              <Subview>
                                <img
                                  src={item?.icon_url}
                                  alt={item?.title}
                                  width='18'
                                  height='18'
                                  className='stroke-color'
                                />
                                <p>{item?.title}</p>
                              </Subview>
                            </View>
                          </DropdownItem>
                        )
                    )}
                  <DropdownItem
                    key={6}
                    label='Full Changelog'
                    iconName='calendar-icon'
                    iconSize={16}
                    iconTone='fill'
                    iconViewBox='0 0 18 18'
                    onClick={() => handleMenuItemClick('https://www.teamcamp.app/updates', 'full changelog clicked')}
                    isSuffix={
                      <SVGIcon
                        name='cross-arrow-icon'
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        stroke='var(--text-primary)'
                      />
                    }
                  />
                </Dropdown>

                {false &&
                  subscriptionList &&
                  subscriptionList?.activeSubscription?.length === 0 &&
                  workspaceTotalTask >= 50 && (
                    <Button
                      title='Free plan'
                      secondary={true}
                      modelbtn={true}
                      iconName={'round-uparrow-icon'}
                      iconSize={14}
                      iconViewBox={'0 0 16 16'}
                      className='button'
                      onClick={onClickOpenModel}
                      size='small'
                    />
                  )}
              </Plandiv>
            </BottomSection>
          </Content>
        </>
      </>
    </>
  );
};

export default Navbarcontent;
