import React, { useCallback, useMemo, useState } from 'react';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import {
  Assignbottom,
  Signmode,
  Assign,
  Div,
  HumanIcon,
  Assigngroup,
  AvatarImage,
  Rightrow1,
  RightText,
  Blankdiv
} from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import { UserInterface } from '../../../interfaces/chatMessageInterface';
import {
  FilteredCompletedTasksListInterface,
  FilteredTasksListInterface,
  TaskDetailsInterface,
  TaskUsersInterface
} from '../../../interfaces/TaskInterface';
import Dropdown from '../../Dropdown/MainDropdown';
import { updateProjectTemplateDetails, updateTaskTemplateData } from '../../../actions/settingActions';
import { isEmpty } from '../../../helpers/common';
import UserPreferenceSingleton from '../../../helpers/userPreferenceSingleton';
import {
  setMemberTaskData,
  setMyTaskData,
  updateCreateTaskInput,
  updateFilteredTasksList,
  updateMemberTaskField,
  updateMyTaskDetailsData,
  updateMyTaskField,
  updateTaskField,
  updatSubTaskList
} from '../../../actions/taskActions';
import {
  createNewComment,
  updateTaskLocalData,
  updateRecurringTaskDetails,
  updateTaskDetails,
  loadMyTaskData
} from '../../../services/taskServices';
import { nanoid } from 'nanoid';
import { COMMENT_TYPE } from '../../../global/constants';
import { trackAnalyticActivity } from '../../../services/analyticsService';
import { TASK_ANALYTICS } from '../../../global/analyticsConstants';
import { captureException } from '../../../services/logService';
import Avatar from '../../avatar/Avatar';
import DropdownItem from '../../Dropdown/DropdownItem';
import { UsersWorkspaceInterface } from '../../../interfaces/WorkspaceInterface';
import { ItemDiv } from '../../customContextMenu/styles';
import { useParams } from 'react-router-dom';

interface UserData {
  projectUsers: UserInterface[];
  isHumanIcon?: boolean;
  currentTask?: TaskDetailsInterface;
  loadData?: () => void;
  isUpdateReducer?: boolean;
  isShowUnAssign?: boolean;
  currentAssignBox?: string;
  setCurrentAssignBox?: (id: string) => void;
  isTaskdetail?: boolean;
  isTemplateProject?: boolean;
  isCalendarview?: boolean;
  isPadding?: boolean;
  isSelectedtask?: boolean;
  isCreateTaskModal?: boolean;
  setIsDropdownOpen?: Function;
  isDropdownOpen?: boolean;
  isAssignModal?: boolean;
  isMilestone?: boolean;
  isSubtask?: boolean;
  isRecurringTask?: boolean;
  className?: string;
  isMultiAssignee?: boolean;
  isTaskTemplate?: boolean;
  isMyTask?: boolean;
  loadUser?: () => void;
  group?: FilteredTasksListInterface | FilteredCompletedTasksListInterface;
  isContextMenu?: boolean;
  isMember?: boolean;
}

const AssignBox: React.FC<UserData> = (props) => {
  const {
    projectUsers,
    currentTask,
    loadData,
    isUpdateReducer = false,
    isShowUnAssign = false,
    setCurrentAssignBox,
    currentAssignBox,
    isTemplateProject = false,
    setIsDropdownOpen,
    isRecurringTask,
    isMultiAssignee,
    isTaskTemplate,
    isMyTask,
    isTaskdetail,
    isSubtask,
    isCreateTaskModal,
    isHumanIcon = false,
    isPadding,
    loadUser,
    group,
    isContextMenu,
    isMember
  } = props;
  // States
  const [curentIndex, setCurrentIndex] = useState<number>(0);
  const [isSearch, setIsSearch] = useState(false);
  const [searchResult, setSearchResult] = useState<UserInterface[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const {
    settings: settingsSelector,
    task: taskSelector,
    workspace: workspaceSelector,
    auth: userDetailsSelector
  } = stateSelector || {};
  const { createTaskInput, membersTask, myTask } = taskSelector;
  const { taskTemplateData, projectTemplateDetails } = settingsSelector;
  const { workspace } = workspaceSelector;
  const { userDetails: currentUserDetails } = userDetailsSelector;
  //Other variable
  const params: { id: string } = useParams();
  const memberDetail = workspace?.users?.find((x: UserInterface) => x?.id === params?.id);
  const dispatch = useDispatch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dataRef = isSearch ? searchResult : projectUsers || [];

  const usersData =
    createTaskInput?.users && createTaskInput?.users?.length > 0 ? createTaskInput?.users : taskTemplateData?.users;

  const filterMemberTasks = useCallback(
    (taskIdToRemove: string) => {
      const updatedMembersTask = membersTask
        .map((group) => ({
          ...group,
          tasks: group.tasks.filter((task) => task.id !== taskIdToRemove)
        }))
        .filter((group) => group.tasks.length > 0);

      dispatch(setMemberTaskData(updatedMembersTask));
    },
    [dispatch, membersTask]
  );

  const filterMyTasks = useCallback(
    (taskIdToRemove: string) => {
      const updatedMyTask = myTask
        .map((group) => ({
          ...group,
          tasks: group.tasks.filter((task) => task.id !== taskIdToRemove)
        }))
        .filter((group) => group.tasks.length > 0);
      dispatch(setMyTaskData(updatedMyTask));
    },
    [dispatch, myTask]
  );

  const updateProjectTemplateData = useCallback(
    (updatedTaskUsers: TaskUsersInterface[]) => {
      const updatedTasks = projectTemplateDetails?.tasks?.map((x) => {
        if (x?.id === currentTask?.id) {
          return { ...x, taskUsers: updatedTaskUsers };
        } else {
          return x;
        }
      });
      dispatch(updateProjectTemplateDetails({ propsName: 'tasks', value: updatedTasks }));
    },
    [currentTask, dispatch, projectTemplateDetails]
  );

  const searchQuery = useCallback(
    (value: string) => {
      const result = projectUsers?.filter((item) => item?.name?.toLowerCase().includes(value?.toLowerCase())) || [];
      if (isEmpty(value)) setSearchResult(projectUsers || []);
      else setSearchResult(result);
      return null;
    },
    [projectUsers]
  );

  const onChangeSearch = useCallback(
    (value: string) => {
      let timeout;
      setCurrentIndex(0);
      if (!isEmpty(value)) {
        if (!isSearch) setIsSearch(true);
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
          searchQuery(value);
        }, 300);
      } else {
        if (isSearch) setIsSearch(false);
        searchQuery(value);
      }
    },
    [isSearch, searchQuery]
  );

  const closeDropdown = useCallback(() => {
    if (setIsDropdownOpen) setIsDropdownOpen(false);
    setCurrentAssignBox?.('');
    onChangeSearch('');
    setCurrentIndex(0);
  }, [onChangeSearch, setCurrentAssignBox, setIsDropdownOpen]);

  const updateSubTaskAssign = useCallback(
    (user: UserInterface[]) => {
      let groupData = JSON.parse(JSON.stringify(group || {}));
      let parentTask = null;
      let isCompletedTask = false;

      // Search in tasks
      parentTask = groupData?.tasks?.find((task: TaskDetailsInterface) => task?.id === currentTask?.parentTaskId);
      // If not found in tasks, search in completedTasks
      if (!parentTask) {
        isCompletedTask = true;
        parentTask = groupData.completedTasks.find(
          (task: TaskDetailsInterface) => task?.id === currentTask?.parentTaskId
        );
      }
      const updatedTask = parentTask?.subTasks?.find((item: TaskDetailsInterface) => item?.id === currentTask?.id);
      const updatedTaskWithUsers = {
        ...updatedTask,
        users: user
      };
      const updatedSubtasks = parentTask?.subTasks?.map((item: TaskDetailsInterface) => {
        if (item?.id === currentTask?.id) {
          return updatedTaskWithUsers;
        }
        return item;
      });
      const parentTaskData = JSON.parse(JSON.stringify(parentTask));
      parentTaskData.subTasks = updatedSubtasks;
      let tasksList = [];
      if (!isCompletedTask) {
        tasksList = groupData?.tasks?.map((obj: TaskDetailsInterface) => {
          return obj.id === parentTaskData.id ? parentTaskData : obj;
        });
        groupData.tasks = tasksList;
      } else {
        tasksList = groupData?.completedTasks?.map((obj: TaskDetailsInterface) => {
          return obj.id === parentTaskData.id ? parentTaskData : obj;
        });
        groupData.completedTasks = tasksList;
      }
      dispatch(updateFilteredTasksList(groupData));
    },
    [currentTask?.id, currentTask?.parentTaskId, dispatch, group]
  );

  const updateTaskAndReload = useCallback(
    async (updatedData: object) => {
      dispatch(updateTaskLocalData(updatedData));
      await dispatch(loadMyTaskData(false));
    },
    [dispatch]
  );

  const onAssignUser = useCallback(
    async (user: UserInterface) => {
      // e.stopPropagation();
      try {
        if (!isLoading) {
          setIsLoading(true);
          if (isEmpty(user?.id)) return;
          if (!isMultiAssignee) closeDropdown();
          const userIndex = currentTask?.users?.findIndex((x) => x.id === user?.id);
          setCurrentIndex(userIndex || 0);
          setSearchResult([]);
          setIsSearch(false);
          const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
          // template project is true
          if (isTemplateProject) {
            let updatedTaskUsers;
            if (userIndex !== -1) {
              const usersClone = JSON.parse(JSON.stringify(currentTask?.users));
              usersClone?.splice(userIndex, 1);
              updatedTaskUsers = usersClone;
            } else {
              const newUserObj = {
                _id: user?.id,
                CreatedBy: userDetails?.id,
                CreatedTime: new Date().toISOString(),
                UpdatedBy: userDetails?.id,
                UpdatedTime: new Date().toISOString()
              };
              updatedTaskUsers = isMultiAssignee ? [...(currentTask?.users || []), newUserObj] : [newUserObj];
            }
            updateProjectTemplateData(updatedTaskUsers);
          }
          // setting --> taskTemplate and create new task updatereducer true
          else if (isUpdateReducer) {
            let users = createTaskInput?.users || [];
            let index = -1;
            if (createTaskInput?.users && createTaskInput?.users?.length > 0) {
              index = users?.findIndex((x: { id: string }) => x.id === user?.id);
            }
            if (index !== -1) {
              const ref = JSON.parse(JSON.stringify(users));
              ref.splice(index, 1);
              users = ref;
            } else {
              if (isMultiAssignee) users = [...users, user];
              else users = [user];
            }
            // setting -> taskTemplate create , update and new task
            if (isTaskTemplate) {
              await dispatch(updateTaskTemplateData({ propsName: 'users', value: users }));
            } else {
              await dispatch(updateCreateTaskInput({ propsName: 'users', value: users }));
            }
          } else {
            // assign the user
            if (userIndex === -1) {
              const taskUsers = isMultiAssignee
                ? [...(currentTask?.users?.map((x) => x?.id) || []), user?.id]
                : [user?.id];
              const payload = { taskUsers, projectId: currentTask?.projectId };
              if (isRecurringTask) {
                await dispatch(updateRecurringTaskDetails(currentTask?.id, payload));
              } else {
                if (!isMultiAssignee) {
                  const addUser = [user];
                  const updatedTaskData = {
                    taskId: currentTask?.id || '',
                    key: 'users',
                    value: addUser
                  };
                  if (isMyTask) {
                    dispatch(updateMyTaskField(updatedTaskData));
                    await updateTaskAndReload({ ...currentTask, users: addUser });
                    if (user.id !== currentUserDetails?.id) filterMyTasks(currentTask?.id || '');
                  }
                  if (!isMyTask && !isTaskdetail) {
                    if (!isSubtask) {
                      dispatch(updateTaskField(updatedTaskData));
                    } else {
                      updateSubTaskAssign([user]);
                    }
                  }
                  if (isSubtask) {
                    const taskDetailsClone = JSON.parse(JSON.stringify(currentTask));
                    taskDetailsClone.users = addUser;
                    dispatch(updatSubTaskList(taskDetailsClone));
                  }
                  if (isTaskdetail && !isSubtask) {
                    const assignUser = workspace?.users?.find((item) => item?.id === user?.id);
                    const updatedComments = [
                      ...(currentTask?.updatedComments || []),
                      {
                        id: nanoid(),
                        type: COMMENT_TYPE.ADD_ASSIGNEE,
                        updatedBy: userDetails?.id,
                        updatedTime: new Date().toISOString(),
                        createdOn: new Date().toISOString(),
                        user: { ...userDetails, name: userDetails?.given_name },
                        assignee: assignUser
                      }
                    ];
                    dispatch(updateMyTaskDetailsData({ propsName: 'updatedComments', value: updatedComments }));
                    dispatch(updateMyTaskDetailsData({ propsName: 'users', value: addUser }));
                  }
                  if (isMember) {
                    dispatch(updateMemberTaskField(updatedTaskData));
                    if (user.id !== memberDetail?.id) filterMemberTasks(currentTask?.id || '');
                  }
                } else {
                  const updatedTaskData = {
                    taskId: currentTask?.id || '',
                    key: 'users',
                    value: [...(currentTask?.users || []), user]
                  };
                  if (isMember) {
                    dispatch(updateMemberTaskField(updatedTaskData));
                  }
                  if (isMyTask) {
                    dispatch(updateMyTaskField(updatedTaskData));
                    await updateTaskAndReload({ ...currentTask, users: [...(currentTask?.users || []), user] });
                  }
                }
                await dispatch(updateTaskDetails(currentTask?.id, payload));
                await dispatch(
                  createNewComment(currentTask?.id, {
                    Type: COMMENT_TYPE.ADD_ASSIGNEE,
                    Assignee: user?.id
                  })
                );
              }
              trackAnalyticActivity(TASK_ANALYTICS.REASSIGNED);
              // e.stopPropagation();
              setIsLoading(false);
            } else {
              //particular user unassign
              let usersClone: UsersWorkspaceInterface[] = JSON.parse(JSON.stringify(currentTask?.users));
              const userIndex = usersClone?.findIndex((item: { id: string }) => item.id === user?.id);
              usersClone?.splice(userIndex, 1);
              const payload = { taskUsers: usersClone?.map((x) => x?.id), projectId: currentTask?.projectId };
              // remove user direct update reducer
              let usersRemove: UserInterface[] = JSON.parse(JSON.stringify(currentTask?.users));
              const Index = usersRemove?.findIndex((item: { id: string }) => item['id'] === user?.id);
              usersRemove?.splice(Index, 1);
              if (isRecurringTask) {
                await dispatch(updateRecurringTaskDetails(currentTask?.id, payload));
              } else {
                const updatedTaskData = {
                  taskId: currentTask?.id || '',
                  key: 'users',
                  value: usersRemove
                };
                if (!isMultiAssignee) {
                  if (isMyTask) {
                    dispatch(updateMyTaskField(updatedTaskData));
                    await updateTaskAndReload({ ...currentTask, users: usersRemove });
                    if (user.id === currentUserDetails?.id) filterMyTasks(currentTask?.id || '');
                  } else if (isMember) {
                    dispatch(updateMemberTaskField(updatedTaskData));
                    if (user.id === memberDetail?.id) filterMemberTasks(currentTask?.id || '');
                  } else {
                    if (!isSubtask) {
                      dispatch(updateTaskField(updatedTaskData));
                    } else {
                      updateSubTaskAssign(usersRemove);
                    }
                  }
                } else {
                  if (isMember) {
                    dispatch(updateMemberTaskField(updatedTaskData));
                    if (user.id === memberDetail?.id) filterMemberTasks(currentTask?.id || '');
                  }
                  if (isMyTask) {
                    dispatch(updateMyTaskField(updatedTaskData));
                    await updateTaskAndReload({ ...currentTask, users: usersRemove });
                    if (user.id === currentUserDetails?.id) filterMyTasks(currentTask?.id || '');
                  }
                }
                if (isTaskdetail) {
                  dispatch(updateMyTaskDetailsData({ propsName: 'users', value: usersRemove }));
                }
                await dispatch(updateTaskDetails(currentTask?.id, payload));
                await dispatch(
                  createNewComment(currentTask?.id, {
                    Type: COMMENT_TYPE.REMOVE_ASSIGNEE,
                    Assignee: user?.id
                  })
                );
              }
              setIsLoading(false);
              // e.stopPropagation();
            }
            if (!isMember && !isMyTask && (isMultiAssignee || props?.isMilestone)) {
              loadData?.();
            }
          }
        }
      } catch (error) {
        captureException(error);
        console.log('error', error);
      } finally {
        setIsLoading(false);
      }
    },
    [
      isLoading,
      isMultiAssignee,
      closeDropdown,
      currentTask,
      isTemplateProject,
      isUpdateReducer,
      updateProjectTemplateData,
      createTaskInput?.users,
      isTaskTemplate,
      dispatch,
      isMember,
      isMyTask,
      props?.isMilestone,
      isRecurringTask,
      isTaskdetail,
      isSubtask,
      updateTaskAndReload,
      currentUserDetails?.id,
      filterMyTasks,
      updateSubTaskAssign,
      workspace?.users,
      memberDetail?.id,
      filterMemberTasks,
      loadData
    ]
  );

  const onUnassignUser = useCallback(async () => {
    try {
      if (!isLoading) {
        setIsLoading(true);
        closeDropdown();
        setIsSearch(false);
        setSearchResult([]);
        if (isTemplateProject) {
          updateProjectTemplateData([]);
          return;
        } else if (isUpdateReducer) {
          if (isTaskTemplate) {
            await dispatch(updateTaskTemplateData({ propsName: 'users', value: [] }));
          } else {
            await dispatch(updateCreateTaskInput({ propsName: 'users', value: [] }));
          }
        } else if (isShowUnAssign) {
          dispatch(updateMyTaskDetailsData({ propsName: 'users', value: [] }));
        } else {
          const taskClone = JSON.parse(JSON.stringify(currentTask));
          taskClone.users = [];
        }
        if (!isUpdateReducer) {
          const payload = { taskUsers: [], projectId: currentTask?.projectId };
          if (isRecurringTask) {
            await dispatch(updateRecurringTaskDetails(currentTask?.id, payload));
          } else {
            const updatedTaskData = {
              taskId: currentTask?.id || '',
              key: 'users',
              value: []
            };
            if (isMyTask) {
              dispatch(updateMyTaskField(updatedTaskData));
              await updateTaskAndReload({ ...currentTask, users: [] });
              filterMyTasks(currentTask?.id || '');
            } else if (isMember) {
              dispatch(updateMemberTaskField(updatedTaskData));
              filterMemberTasks(currentTask?.id || '');
            } else {
              if (isSubtask && !isTaskdetail) {
                updateSubTaskAssign([]);
              } else {
                dispatch(updateTaskField(updatedTaskData));
                const taskDetailsClone = JSON.parse(JSON.stringify(currentTask));
                taskDetailsClone.users = [];
                dispatch(updatSubTaskList(taskDetailsClone));
              }
            }
            await dispatch(updateTaskDetails(currentTask?.id, payload));
            await dispatch(
              createNewComment(currentTask?.id, {
                Type: COMMENT_TYPE.UNASSIGN
              })
            );
          }
        }
        if (!isMember && !isMyTask && isMultiAssignee) loadData?.();
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoading(false);
    }
  }, [
    isLoading,
    closeDropdown,
    isTemplateProject,
    isUpdateReducer,
    isShowUnAssign,
    isMember,
    isMyTask,
    isMultiAssignee,
    loadData,
    updateProjectTemplateData,
    isTaskTemplate,
    dispatch,
    currentTask,
    isRecurringTask,
    updateTaskAndReload,
    filterMyTasks,
    filterMemberTasks,
    isSubtask,
    isTaskdetail,
    updateSubTaskAssign
  ]);

  const handlePlusIconClick = useCallback(() => {
    loadUser?.();
    setIsDropdownOpen?.(true);
    setCurrentAssignBox?.(currentTask?.id || '');
  }, [currentTask?.id, loadUser, setCurrentAssignBox, setIsDropdownOpen]);

  const renderSignModeContent = useCallback(() => {
    try {
      if (isHumanIcon && usersData) {
        if (usersData.length > 0) {
          return (
            <Assign isCreateTaskModal={props?.isCreateTaskModal}>
              {usersData
                .filter((item) => !isEmpty(item?.id))
                .map((user) => (
                  <Div key={user?.id}>
                    <Avatar imgSrc={user?.profile_photo || ''} name={user?.name} size={16} isNotBorder />
                    <p>{user?.name}</p>
                  </Div>
                ))}
            </Assign>
          );
        } else {
          return (
            <HumanIcon isPadding={isPadding} isCreateTaskModal={props?.isCreateTaskModal}>
              <SVGIcon name='task-human-icon' width='16' height='16' viewBox='0 0 16 16' className='humanicon' />
              <p>Assignee</p>
            </HumanIcon>
          );
        }
      } else {
        if (isShowUnAssign) {
          if (currentTask?.users && currentTask.users.length > 0) {
            return (
              <Assigngroup>
                <ul>
                  {currentTask.users
                    .filter((item) => !isEmpty(item?.id))
                    .map((item) => (
                      <li key={item?.id}>
                        <AvatarImage>
                          <Avatar
                            imgSrc={item?.profile_photo || ''}
                            name={item?.name ? item?.name : 'U N'}
                            size={22}
                            isThinBorder
                          />
                        </AvatarImage>
                      </li>
                    ))}
                  <li className='plus-icon'>
                    <SVGIcon name='assign-plus-icon' width='24' height='24' viewBox='0 0 26 26' className='plus-icon' />
                  </li>
                </ul>
              </Assigngroup>
            );
          } else {
            return (
              <Rightrow1>
                <SVGIcon
                  name='unassign-profile-icon'
                  width='18'
                  height='18'
                  viewBox='0 0 18 18'
                  fill='var(--background-tertiary)'
                />
                <RightText>Unassigned</RightText>
              </Rightrow1>
            );
          }
        } else {
          return (
            <li className={currentAssignBox === currentTask?.id ? '' : 'plus-icon'} onClick={handlePlusIconClick}>
              <SVGIcon name='li-plus-icon' width='24' height='24' viewBox='0 0 24 24' className='fill-color' />
            </li>
          );
        }
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoading(false);
    }
  }, [
    isHumanIcon,
    usersData,
    props?.isCreateTaskModal,
    isPadding,
    isShowUnAssign,
    currentTask?.users,
    currentTask?.id,
    currentAssignBox,
    handlePlusIconClick
  ]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<Element>) => {
      switch (e.key) {
        case 'ArrowUp':
          setCurrentIndex((prevIndex) => (prevIndex === 0 ? dataRef.length - 1 : prevIndex - 1));
          break;
        case 'ArrowDown':
          setCurrentIndex((prevIndex) => (prevIndex === dataRef.length - 1 ? 0 : prevIndex + 1));
          break;
        case 'Enter':
          onAssignUser(dataRef[curentIndex]);
          setCurrentIndex(0);
          break;
        case 'Escape':
          closeDropdown();
          break;
        default:
          break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [curentIndex, dataRef, closeDropdown, onAssignUser]
  );

  const renderData = useCallback(
    (data: UserInterface[]) => {
      return (
        <div>
          <Assignbottom>
            <DropdownItem
              label={'Unassigned'}
              onClick={onUnassignUser}
              iconName={'unassign-profile-icon'}
              iconSize={20}
              iconViewBox='0 0 18 18'>
              {isMultiAssignee && <Blankdiv />}
            </DropdownItem>
            {data?.map((item, index: number) => {
              const isAssigned = isCreateTaskModal
                ? createTaskInput?.users?.some((user: { id: string }) => user.id === item.id)
                : currentTask?.users?.some((user: { id: string }) => user.id === item.id);
              return (
                <DropdownItem
                  key={index}
                  label={item?.name}
                  onClick={() => !isLoading && onAssignUser(item)}
                  isCheckBox={isMultiAssignee}
                  isAvatar={true}
                  avtarSrc={item?.profile_photo}
                  isChecked={isAssigned}
                  isActive={curentIndex === index}
                  isSelected={isAssigned}
                />
              );
            })}
          </Assignbottom>
        </div>
      );
    },
    [
      onUnassignUser,
      isMultiAssignee,
      isCreateTaskModal,
      createTaskInput?.users,
      currentTask?.users,
      curentIndex,
      isLoading,
      onAssignUser
    ]
  );

  const renderContent = useMemo(() => {
    return isContextMenu ? (
      <ItemDiv>
        <DropdownItem label='Assignee' iconName='task-human-icon' iconSize={16} />
      </ItemDiv>
    ) : (
      <Signmode>{renderSignModeContent()}</Signmode>
    );
  }, [isContextMenu, renderSignModeContent]);

  return (
    <div onKeyDown={(e) => handleKeyDown(e)}>
      <Dropdown
        isMinWidth={148}
        content={renderContent}
        trigger={isContextMenu ? 'hover' : 'click'}
        modalTitle='Assigns'
        onChangeSearch={onChangeSearch}
        onOutsideClick={() => {
          setCurrentAssignBox?.('');
        }}>
        {isSearch ? renderData(searchResult) : renderData(projectUsers?.filter((user) => !isEmpty(user?.id)) || [])}
      </Dropdown>
    </div>
  );
};
export default AssignBox;
