import styled from 'styled-components';
import { Typography } from '../../../../../global/theme';

interface Props {
  isEdge?: boolean;
}
const DocumentBlock = styled.div<Props>`
  height: 100dvh;
  .editor {
    padding: 8px;
    overflow: auto;
    height: calc(100dvh - 53px);
    max-height: max-content;
    .editorjs {
      max-width: 752px;
      padding: 38px 96px;
      margin: 0 auto;
    }
    .ce-popover__items {
      &::-webkit-scrollbar {
        width: 6px !important;
      }
      &::-webkit-scrollbar-thumb {
        background-color: var(--text-tertiary) !important;
        width: 6px !important;
        margin-bottom: 4px !important;
        border-radius: 7px !important;
        min-height: 20px !important;
      }
    }
    .ce-inline-toolbar {
      background-color: var(--background-primary) !important;
      border: 0.5px solid var(--border-primary);
      color: var(--text-primary) !important;
    }
    .ce-inline-tool:hover {
      background-color: var(--neutral-secondary) !important;
    }
    .ce-inline-toolbar__dropdown:hover {
      background-color: var(--neutral-secondary) !important;
      border-radius: 6px 0 0 6px;
    }
    .ce-conversion-tool:hover {
      background: var(--neutral-secondary) !important;
    }
    .ce-conversion-tool--focused {
      background-color: var(--text-tertiary) !important;
    }
    .ce-conversion-toolbar--showed {
      background-color: var(--background-primary) !important;
      border: 0.5px solid var(--border-primary);
      color: var(--text-primary) !important;
    }
    .tc-toolbox .tc-popover {
      background-color: var(--background-primary) !important;
      border: 0.5px solid var(--border-primary);
      color: var(--text-primary) !important;
    }
    .ce-conversion-tool__icon {
      background-color: var(--background-primary) !important;
    }
    .ce-popover__item-icon {
      background: var(--background-primary) !important;
      border: 0.5px solid var(--border-primary);
      box-shadow: none;
    }
    .codex-editor path {
      stroke: var(--text-primary) !important;
    }
    .codex-editor rect {
      stroke: var(--text-primary) !important;
    }
    .codex-editor line {
      stroke: var(--text-primary) !important;
    }
    .cdx-search-field__icon circle {
      stroke: var(--text-primary) !important;
    }
    .tc-toolbox__toggler svg rect {
      fill: var(--background-primary) !important;
    }
    .ce-paragraph {
      color: var(--text-primary) !important;
      ::selection {
        background-color: var(--text-tertiary) !important;
      }
    }
    .ce-block b::selection {
      background-color: var(--text-tertiary) !important;
    }
    .ce-block i {
      background-color: var(--text-tertiary) !important;
      ::selection {
        background-color: var(--text-tertiary) !important;
      }
    }
    .inline-code {
      color: var(--text-primary) !important;
      background: transparent;
      ::selection {
        background: var(--background-primary) !important;
      }
    }
    .ce-paragraph {
      span {
        background-color: var(--text-tertiary) !important;
      }
      mark::selection {
        background-color: var(--text-tertiary) !important;
      }
    }
    .cdx-marker {
      background-color: var(--text-tertiary) !important;
    }
    .ce-toolbar__plus {
      color: var(--text-primary) !important;
      :hover {
        background-color: var(--neutral-secondary) !important;
      }
    }
    .ce-toolbar__settings-btn {
      color: var(--text-primary) !important;
    }
    .ce-toolbar__settings-btn:hover {
      background-color: var(--neutral-secondary) !important;
    }
    .ce-popover {
      border: 0.5px solid var(--border-primary);
      background-color: var(--background-secondary);
    }
    .ce-popover__item:hover:not(.ce-popover__item--no-visible-hover) {
      background-color: var(--neutral-secondary) !important;
    }
    .cdx-search-field {
      border: 0.5px solid var(--border-primary);
      background-color: var(--background-secondary);
    }
    .codex-editor__redactor {
      color: var(--text-primary) !important;
      padding-bottom: 0 !important ;
    }
    .ce-popover__item-label {
      color: var(--text-primary) !important;
    }
    .cdx-search-field__input {
      color: var(--text-primary) !important;
    }
    .tc-toolbox__toggler svg {
      fill: var(--text-primary) !important;
    }
    .tc-popover__item-icon {
      background: var(--background-primary) !important;
      border: 0.5px solid var(--border-primary);
    }
    .tc-popover__item:hover {
      background-color: var(--neutral-secondary) !important;
    }
    .tc-row--selected:after {
      background: var(--background-primary) !important;
    }
    .tc-add-row:hover:before {
      background: var(--background-primary) !important;
    }
    .tc-add-column:hover,
    .tc-add-row:hover {
      background-color: var(--background-primary) !important;
    }
    .cdx-input {
      border: 0.5px solid var(--border-primary);
      ::selection {
        background: var(--text-tertiary) !important;
      }
    }
    h2.ce-header::selection {
      background-color: var(--text-tertiary) !important;
    }
    .ce-block__content::selection {
      background-color: var(--text-tertiary) !important;
    }
    .ce-block::selection {
      background-color: var(--text-tertiary) !important;
    }
    .ce-block--selected .ce-block__content {
      background-color: var(--background-primary) !important;
    }
    .tc-row--selected {
      background-color: var(--background-primary) !important;
    }
    .ce-rawtool__textarea {
      background-color: var(--background-primary) !important;
      color: var(--text-primary);
      ${Typography.body_lg_medium}
    }
    .cdx-button {
      background-color: var(--background-primary) !important;
      border: 0.5px solid var(--border-primary);
    }
    .ce-code__textarea {
      background-color: var(--background-primary) !important;
      color: var(--text-primary);
      ${Typography.body_lg_medium}
    }
    .cdx-checklist__item-checkbox-check {
      background-color: var(--background-primary) !important;
    }
    .cdx-checklist__item--checked .cdx-checklist__item-checkbox-check {
      border-color: var(--brand-primary);
    }
    .tc-cell--selected {
      background-color: var(--background-primary) !important;
    }
  }
  @media (max-width: 449px) {
    .editor {
      padding: 0;
      margin: 0 -16px;
    }
  }

  .docs-section {
    border-width: 0;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const DocumentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 10px 24px 10px 12px;
  position: sticky;
  z-index: 9;
  top: 0;
  background-color: var(--background-secondary);
  border-bottom: 0.5px solid var(--border-primary);
  @media (max-width: 449px) {
    padding: 12px 16px;
    width: 100%;
    margin-left: -16px;
  }
`;

const DocumentForm = styled.form`
  color: var(--text-secondary);
  padding: 4px;
  ${Typography.body_lg}
  padding-right: 12px;
`;

const DocumentButton = styled.div`
  display: flex;
  gap: 10px;
`;

const IconPoint = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  background-size: cover;
  .dot-icon {
    fill: var(--text-secondary);
    :hover {
      background: var(--neutral-secondary);
      border-radius: 4px;
    }
  }
  .headerarrow {
    :hover {
      background: var(--neutral-secondary);
      border-radius: 3px;

      svg {
        path {
          stroke: var(--text-primary);
        }
      }
    }
  }
  .headerarrow {
    path {
      stroke: var(--text-primary);
    }
  }
  @media (max-width: 449px) {
    .headerarrow {
      width: 18px;
      height: 18px;
    }
  }
  .arrow-icon {
    stroke: var(--text-primary);
    :hover {
      background: var(--neutral-secondary);
      border-radius: 4px;
    }
  }
`;
const Leftdiv = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon {
    top: 0;
    right: 0;
    height: 16px;
  }
  .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title {
    padding-inline-end: 0;
    padding: 0;
  }
  .ant-dropdown
    .ant-dropdown-menu
    .ant-dropdown-menu-submenu-title
    .ant-dropdown-menu-submenu-expand-icon
    .ant-dropdown-menu-submenu-arrow-icon {
    display: none;
  }
  .ant-dropdown-menu-submenu .ant-dropdown-menu {
    padding: 0;
  }
  .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover {
    background-color: var(--neutral-secondary);
    border-radius: 0;
  }
`;

const AvatarDiv = styled.div`
  display: flex;
`;
const Buttondiv = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  @media (max-width: 449px) {
    .button {
      padding: 7px 20px;
    }
  }
`;
const Rightdiv = styled.div`
  display: flex;
  li {
    list-style: none;
    margin-right: -8px;
  }
`;
const Icon = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin: 4px;
  .svgicon {
    path {
      stroke: var(--text-secondary);
      fill: var(--text-secondary);
    }
  }
  :hover {
    background-color: var(--neutral-secondary);
    border-radius: 4px;
  }
`;
const ViewDropdown = styled.div`
  .menustyle {
    display: flex;
    flex-direction: column;
    padding: 4px 0;
    background: var(--background-primary);
    border: 0.5px solid var(--border-primary);
    box-shadow: var(--shadow-modal);
    border-radius: 8px;
    margin: 0px;
    max-width: 200px;

    .menuitem {
      margin: 0px 4px;
      :hover {
        background-color: var(--neutral-secondary);
        border-radius: 4px;
      }
    }
  }
`;
const ViewPopupbox = styled.div``;
const View = styled.div`
  /* width: 172px; */

  p {
    ${Typography.body_md}
    color: var(--text-primary);
    margin: 0px;
  }
  cursor: pointer;
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 4px 6px;
  span {
    ${Typography.body_md}
    margin: 0px;
    color: var(--accent-error);
  }
`;
const RemoveIcon = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  .delete-icon {
    path {
      fill: var(--accent-error);
    }
  }
`;
const Icons = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  .svg-icon {
    path {
      stroke: var(--text-primary);
    }
  }
  .history-icon {
    path {
      fill: var(--text-primary);
    }
  }
  .fill-color {
    stroke: var(--text-primary);
  }
`;
const Submenu = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6px 0;
  background: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  box-shadow: var(--shadow-modal);
  border-radius: 8px;
  margin: 0px;
`;
const Item = styled.div`
  width: 142px;
  ${Typography.body_md}
  color: var(--text-primary);
  margin: 0px;
  cursor: pointer;
  padding: 6px 12px;
  :hover {
    background-color: var(--neutral-secondary);
    border-radius: 0;
  }
`;
const Exportmenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 178px;
  padding: 4px 6px;
`;
const Views = styled.div`
  width: 172px;
  cursor: pointer;
  display: flex;
  gap: 10px;
  align-items: center;
  p {
    ${Typography.body_md}
    color: var(--text-primary);
    margin: 0px;
  }
`;
const Autotext = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 30px;

  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--border-primary);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 14px;
    width: 14px;
    top: 3px;
    left: 3px;
    background-color: var(--text-white);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background: var(--brand-primary);
  }

  input:checked + .slider:before {
    transform: translateX(20px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;
export {
  DocumentBlock,
  DocumentHeader,
  DocumentForm,
  DocumentButton,
  IconPoint,
  Leftdiv,
  Rightdiv,
  Icon,
  ViewDropdown,
  ViewPopupbox,
  View,
  RemoveIcon,
  Icons,
  Submenu,
  Item,
  Exportmenu,
  Views,
  AvatarDiv,
  Buttondiv,
  Autotext
};
