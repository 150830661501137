import axios from 'axios';
import { clearResponseMessage, setErrorMessage, setSuccessMessage } from '../actions/messageActions';
import {
  setCustomerInvoiceData,
  setCustomerInvoiceTimeframes,
  setInvoiceActivityDetail,
  setInvoiceList,
  setInvoiceLoader,
  setInvoiceViewData,
  updateInvoiceBillableHoursDetail,
  updateInvoiceDetail
} from '../actions/invoiceActions';
import { REACT_APP_TEAMCAMP_APIURL } from '../global/environment';
import { getAPIErrorReason } from '../helpers/common';
import UserPreferenceSingleton from '../helpers/userPreferenceSingleton';
import { CUSTOMER_MEMBER_ROLE } from '../global/constants';
import {
  CustomerBillablrHoursDetailInterface,
  CustomerInvoiceTimeframesInterface,
  InputInvoiceDetailInterface,
  RecordInterface,
  sendInvoiceInterface
} from '../interfaces/InvoiceInterface';
import { AnyAction, Dispatch } from 'redux';
import { captureException } from './logService';
import { WorkspaceInterfase } from '../interfaces/WorkspaceInterface';

/**
 * @desc Invoice - Get invoice list
 * @param {*}
 */
export const getInvoiceList: any = (workspace: WorkspaceInterfase) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setInvoiceLoader(true));
    let response;
    if (workspace?.customerRole === CUSTOMER_MEMBER_ROLE.FULL_ACCESS) {
      response = await axios.get(
        `${REACT_APP_TEAMCAMP_APIURL}/invoice/getInvoiceListByCustomer/${workspace?.customerId}`
      );
    } else if (workspace?.isAdmin || workspace?.isOwner) {
      response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/invoice/getInvoiceListByWorkspace/${workspace?.id}`);
    } else {
      dispatchInvoiceError("You don't have access for this page", dispatch);
    }
    if (response) {
      const { data } = response;
      dispatch(setInvoiceList(data));
      return data;
    }
    return undefined;
  } catch (e) {
    const error = getAPIErrorReason(e) || `Unable to get invoice details please try again`;
    captureException(error);
    dispatchInvoiceError(error, dispatch);
    return undefined;
  } finally {
    dispatch(setInvoiceLoader(false));
  }
};

/**
 * @desc Invoice - Get invoice View Data
 * @param {*}
 */
export const getInvoiceViewData: any = (item_id: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setInvoiceLoader(true));
    const params = {
      id: item_id
    };
    const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/invoice/getInvoiceDetails`, { params });
    const { data } = response;
    if (data) {
      dispatch(setInvoiceViewData(data));
    }
    return data;
  } catch (e) {
    const error = getAPIErrorReason(e) || `Unable to get invoice View Data please try again`;
    captureException(error);
    dispatchInvoiceError(error, dispatch);
    return undefined;
  } finally {
    dispatch(setInvoiceLoader(false));
  }
};

/**
 * @desc Invoice - Get invoice activity detail Data
 * @param {*}
 */
export const getInvoiceActivityDetailData: any =
  (item_id: string, workspace: WorkspaceInterfase) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setInvoiceLoader(true));
      const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
      const params = {
        companyId: workspace?.id,
        userId: userDetails?.id,
        invoiceId: item_id,
        isClient: workspace?.customerRole === CUSTOMER_MEMBER_ROLE.FULL_ACCESS
      };
      const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/invoice/getInvoiceActivityList`, {
        params
      });
      const { data } = response;
      if (data) {
        dispatch(setInvoiceActivityDetail(data));
      }
      return false;
    } catch (e) {
      const error = getAPIErrorReason(e) || `Unable to get invoice activity details Data please try again`;
      captureException(error);
      dispatchInvoiceError(error, dispatch);
      return false;
    } finally {
      dispatch(setInvoiceLoader(false));
    }
  };

/**
 * @desc Invoice - create new invoice
 * @param {*}
 */
export const createNewInvoice: any =
  (payload: InputInvoiceDetailInterface) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setInvoiceLoader(true));
      const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/invoice/postInvoice`, payload);
      if (response) {
        dispatchInvoiceSucess(response.data.message, dispatch);
        return response;
      }
      return undefined;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to create new invoice please try again';
      captureException(error);
      dispatchInvoiceError(error, dispatch);
      return undefined;
    } finally {
      dispatch(setInvoiceLoader(false));
    }
  };

/**
 * @desc Invoice - update invoice
 * @param {*}
 */
export const updateInvoice: any =
  (invoice_id: string, payload: InputInvoiceDetailInterface) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setInvoiceLoader(true));
      const response = await axios.put(`${REACT_APP_TEAMCAMP_APIURL}/invoice/putInvoice/${invoice_id}`, payload);
      const { data } = response;
      if (data) {
        dispatchInvoiceSucess(response.data.message, dispatch);
        return data;
      }
      return false;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to update invoice please try again';
      captureException(error);
      dispatchInvoiceError(error, dispatch);
      return false;
    } finally {
      dispatch(setInvoiceLoader(false));
    }
  };

/**
 * @desc Invoice - get invoice id
 * @param {*}
 */
export const getInvoiceId: any =
  (user_id: string, workspace_id: string, isCurrency: boolean) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setInvoiceLoader(true));
      const params = {
        companyId: workspace_id,
        userId: user_id
      };
      const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/invoice/getInvoiceId`, { params });
      const { data } = response;
      if (data) {
        dispatch(updateInvoiceDetail({ propsName: 'number', value: data?.newInvoiceNumber || '' }));
        if (!isCurrency) {
          dispatch(updateInvoiceDetail({ propsName: 'currency', value: data?.lastCurrency || 'USD' }));
        }
        return true;
      }
      return false;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to get invoice id please try again';
      captureException(error);
      dispatchInvoiceError(error, dispatch);
      return false;
    } finally {
      dispatch(setInvoiceLoader(false));
    }
  };

/**
 * @desc Invoice - Get Customer Invoice Type
 * @param {*}
 */
export const getProjectHours: any =
  (workSpace_Id: string, customer_id: string) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setInvoiceLoader(true));
      const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
      const params = {
        userId: userDetails?.id,
        companyId: workSpace_Id,
        customerId: customer_id
      };
      const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/invoice/getProjectHours`, { params });
      const { data } = response;
      if (data) {
        return data;
      }
      return undefined;
    } catch (e) {
      const error = getAPIErrorReason(e) || `Unable to get project hours`;
      captureException(error);
      dispatchInvoiceError(error, dispatch);
      return undefined;
    } finally {
      dispatch(setInvoiceLoader(false));
    }
  };

/** @desc Invoice - update record payment
 * @param {*}
 */
export const updateRecordPayment: any =
  (user_Id: string, workspace_Id: string, invoice_Id: string, payload: RecordInterface) =>
  async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setInvoiceLoader(true));
      const params = {
        userId: user_Id,
        invoiceId: invoice_Id,
        companyId: workspace_Id
      };
      const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/invoice/putRecordPayment?`, payload, {
        params
      });
      const { data } = response;
      if (data) {
        dispatchInvoiceSucess(response.data.message, dispatch);
        return true;
      }
      return false;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to update record payment please try again';
      captureException(error);
      dispatchInvoiceError(error, dispatch);
      return false;
    } finally {
      dispatch(setInvoiceLoader(false));
    }
  };

/**
 * @desc Invoice - Get Customer Invoice Data
 * @param {*}
 */
export const getCustomerInvoiceData: any =
  (workSpace_Id: string, customer_id: string) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setInvoiceLoader(true));
      const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
      const params = {
        userId: userDetails?.id,
        companyId: workSpace_Id,
        customerId: customer_id
      };
      const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/invoice/getCustomerWithInvoiceData`, {
        params
      });
      const { data } = response;
      if (data) {
        const updatedData = data?.map((item: any) => {
          return { ...item, checked: true };
        });
        const userIds = updatedData?.map((data: { id: string }) => {
          return data?.id;
        });
        dispatch(setCustomerInvoiceData(updatedData));
        dispatch(updateInvoiceBillableHoursDetail({ propsName: 'projects', value: userIds }));
        return data;
      }
      return undefined;
    } catch (e) {
      const error = getAPIErrorReason(e) || `Unable to get Customer Invoice Data please try again`;
      captureException(error);
      dispatchInvoiceError(error, dispatch);
      return false;
    } finally {
      dispatch(setInvoiceLoader(false));
    }
  };

/**
 * @desc Invoice - Get Customer Invoice Timeframes
 * @param {*}
 */
export const getCustomerInvoiceTimeframes: any = (workSpace_Id: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setInvoiceLoader(true));
    const params = {
      companyId: workSpace_Id
    };
    const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/invoice/getTimeFrames`, { params });
    const { data } = response;
    if (data) {
      const updatelist = data?.map((item: CustomerInvoiceTimeframesInterface) => {
        return { text: item?.value, value: item?.text };
      });
      dispatch(setCustomerInvoiceTimeframes(updatelist));
      return updatelist;
    }
    return false;
  } catch (e) {
    const error = getAPIErrorReason(e) || `Unable to get Customer Invoice Timeframes please try again`;
    captureException(error);
    dispatchInvoiceError(error, dispatch);
    return undefined;
  } finally {
    dispatch(setInvoiceLoader(false));
  }
};

/**
 * @desc Invoice - Update Track Time Invoice Details
 * @param {*}
 */
export const updateTrackTimeInvoiceDetails: any =
  (workspace_id: string, payload: CustomerBillablrHoursDetailInterface) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setInvoiceLoader(true));
      const params = {
        companyId: workspace_id
      };
      const response = await axios.put(`${REACT_APP_TEAMCAMP_APIURL}/invoice/putUninvoiced`, payload, { params });
      const { data } = response;
      if (data) {
        const defaultItem = {
          amount: 0,
          desc: '',
          isTax: true,
          isTax2: true,
          itemType: 1,
          projectId: '',
          qty: 1,
          unitPrice: 0
        };
        dispatch(updateInvoiceDetail({ propsName: 'items', value: data?.length > 0 ? data : [defaultItem] }));
        return data;
      }
      return false;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to Update track time invoice details please try again';
      captureException(error);
      dispatchInvoiceError(error, dispatch);
      return undefined;
    } finally {
      dispatch(setInvoiceLoader(false));
    }
  };

/**
 * @desc Invoice - deleteActivityDetail
 * @param {*}
 */
export const deleteActivityDetail: any =
  (invoice_Id: string, payment_Id: string, workspaceId: string) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setInvoiceLoader(true));
      const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
      const params = {
        userId: userDetails?.id,
        companyId: workspaceId,
        invoiceId: invoice_Id,
        deletePaymentId: payment_Id
      };
      const response = await axios.delete(`${REACT_APP_TEAMCAMP_APIURL}/invoice/deleteActivityDetails`, { params });
      if (response) {
        return true;
      }
      return false;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to delete record payment details please try again';
      captureException(error);
      dispatchInvoiceError(error, dispatch);
      return false;
    } finally {
      dispatch(setInvoiceLoader(false));
    }
  };

/**
 * @desc Invoice - send Invoice To Customers
 * @param {*}
 */
export const sendInvoiceToCustomers: any = (payload: sendInvoiceInterface) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setInvoiceLoader(true));
    const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/invoice/sendInvoiceToCustomers`, payload);
    const { data } = response;
    if (data) {
      dispatchInvoiceSucess(data.message, dispatch);
      return true;
    }
    return false;
  } catch (e) {
    const error = getAPIErrorReason(e) || `Unable to send invoice to customers please try again`;
    captureException(error);
    dispatchInvoiceError(error, dispatch);
    return undefined;
  } finally {
    dispatch(setInvoiceLoader(false));
  }
};

/**
 * @desc Invoice - Delete Invoice
 * @param {*}
 */
export const deleteInvoice: any = (invoice_Id: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setInvoiceLoader(true));
    const response = await axios.delete(`${REACT_APP_TEAMCAMP_APIURL}/invoice/deleteInvoice/${invoice_Id}`);
    if (response) {
      return true;
    }
    return false;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to delete invoice please try again';
    captureException(error);
    dispatchInvoiceError(error, dispatch);
    return false;
  } finally {
    dispatch(setInvoiceLoader(false));
  }
};

function dispatchInvoiceError(msg: string, dispatch: Dispatch<AnyAction>) {
  dispatch(setErrorMessage(msg));
}

function dispatchInvoiceSucess(msg: string, dispatch: Dispatch<AnyAction>) {
  dispatch(setSuccessMessage(msg));
}
