/* eslint-disable max-len */
import { useCallback, useEffect, useRef, useState } from 'react';
import AppLayout from '../../../component/appLayout';
import {
  Bottom,
  Bottomcontent,
  Bottomdiv,
  Box,
  BoxCommingSoon,
  Buttons,
  Card,
  Content,
  Contents,
  Deleteicon,
  Desc,
  Description,
  Detail,
  Domaincontent,
  Empty,
  Group,
  Heading,
  Icon,
  Icon1,
  Image,
  Input,
  InputDiv,
  Left,
  Logo,
  MetaInput,
  Mode,
  Overlay,
  Personaltext,
  Square,
  Table,
  Tag,
  Textblock,
  Title,
  TitleDiv,
  TitleText,
  Top,
  Topcontent
} from './styles';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { useSelector, useDispatch } from 'react-redux';
import Button from '../../../component/button';
import emptyfavicondark from '../../../assets/images/emptystates/emptylogodark.svg';
import emptyfaviconlight from '../../../assets/images/emptystates/emptylogolight.svg';
import ModalCustom from '../../../component/models/modal';
import Addaccountmodal from '../../../component/models/addAccountmodal';
import {
  clearBrandDomainDetails,
  setBrandDomainDetails,
  updateBrandDomainDetails
} from '../../../actions/settingActions';
import { setErrorMessage } from '../../../actions/messageActions';
import { getFirebaseUrlFromFile, isEmpty } from '../../../helpers/common';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import { ColorPicker } from 'antd';
import {
  addDomainInVercelProject,
  getBrandingDetails,
  removeVercelDomain,
  updateBrandingDetails
} from '../../../services/settingServices';
import { BrandDomainDetailsInterface, DNSResponseInterface } from '../../../interfaces/SettingsInterface';
import { captureException } from '../../../services/logService';
import Deletemodal from '../../../component/models/deleteModel';
import { getBrandDataBasedOnWorkspaceId } from '../../../services/appService';
import { Header } from '../styles';
import { ResponsiveHeader } from '../SettingHeaderResponsive';
import { useMobileDevice } from '../../../global/useMobile';

export default function BrandDomain() {
  // States
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [brandindStoredData, setBrandingStoredData] = useState<BrandDomainDetailsInterface>({});
  const [dnsDetails, setDnsDetails] = useState<DNSResponseInterface>({ records: [] });
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { settings: settingsSelector, workspace: workspaceSelector } = stateSelector || {};
  const { themeMode } = settingsSelector;
  const { workspace } = workspaceSelector;
  const { brandDomainDetails } = settingsSelector;
  // Other variables
  const dispatch = useDispatch();
  const isMobile = useMobileDevice();

  const loadData = useCallback(async () => {
    try {
      setInitialLoading(true);
      if (!isEmpty(workspace?.id)) {
        const brandingDetails = await dispatch(getBrandingDetails());
        if (brandingDetails) {
          setBrandingStoredData(brandingDetails);
          dispatch(setBrandDomainDetails(brandingDetails));
        }
      }
    } catch (e) {
      captureException(e);
    } finally {
      setInitialLoading(false);
    }
  }, [dispatch, workspace?.id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    return () => {
      dispatch(clearBrandDomainDetails());
    };
  }, [dispatch]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const inputRef = useRef<HTMLInputElement | null>(null);
  const inputLogoRef = useRef<HTMLInputElement | null>(null);

  const convertBase64 = (file: File, propsName: string) => {
    return new Promise<void>((resolve, reject) => {
      setInitialLoading(true);

      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = async () => {
        try {
          const item = {
            profile: fileReader.result,
            file: file
          };
          const imageUrl = await getFirebaseUrlFromFile(item, 'branding/', dispatch);
          dispatch(
            updateBrandDomainDetails({
              propsName,
              value: {
                type: file?.type,
                name: file?.name,
                url: imageUrl
              }
            })
          );
          resolve();
        } catch (error) {
          reject(error);
        } finally {
          setInitialLoading(false);
        }
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleFileRead = async (event: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
    try {
      if (event.target.files) {
        const file = event.target.files?.[0];
        if (file?.size > 2.5e8) {
          dispatch(setErrorMessage('Please upload less than 500kb photo size.'));
          return false;
        }
        await convertBase64(file, fieldName);
      }
    } catch (e) {
      captureException(e);
      console.log('error', e);
    }
  };

  const handleFaviconUploadClick = () => {
    inputRef.current?.click();
  };

  const handleLogoUploadClick = () => {
    inputLogoRef.current?.click();
  };

  const onChangeField = useCallback(
    (propsName: string, value: string) => {
      dispatch(updateBrandDomainDetails({ propsName, value }));
    },
    [dispatch]
  );

  const onClickAddLink = useCallback(async () => {
    try {
      setLoading(true);
      if (brandDomainDetails?.domain?.startsWith('https') || brandDomainDetails?.domain?.startsWith('http')) {
        return dispatch(setErrorMessage(`Cannot add invalid domain name "${brandDomainDetails?.domain}".`));
      } else {
        const response = await dispatch(addDomainInVercelProject(brandDomainDetails?.domain));
        if (response) {
          setDnsDetails(response);
        }
      }
    } catch (e) {
      captureException(e);
    } finally {
      setLoading(false);
    }
  }, [brandDomainDetails?.domain, dispatch]);

  const onClickSaveDetails = useCallback(async () => {
    try {
      setLoading(true);
      const payload: any = {
        data: {
          primaryColor: brandDomainDetails?.primaryColor,
          primaryDarkColor: brandDomainDetails?.primaryDarkColor,
          metaTitle: brandDomainDetails?.metaTitle
        }
      };
      if (brandDomainDetails?.logo?.url?.startsWith('https://firebasestorage')) {
        payload.logo = brandDomainDetails.logo;
      }

      if (brandDomainDetails?.faviconIcon?.url?.startsWith('https://firebasestorage')) {
        payload.faviconIcon = brandDomainDetails.faviconIcon;
      }
      const response = await dispatch(updateBrandingDetails(payload));
      if (response) {
        setBrandingStoredData(response);
        dispatch(getBrandDataBasedOnWorkspaceId(workspace?.id));
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }, [
    brandDomainDetails.faviconIcon,
    brandDomainDetails.logo,
    brandDomainDetails?.metaTitle,
    brandDomainDetails?.primaryColor,
    brandDomainDetails?.primaryDarkColor,
    dispatch,
    workspace?.id
  ]);

  const openDeleteModel = useCallback(() => {
    setDeleteOpen(true);
  }, []);

  const close = useCallback(() => {
    setDeleteOpen(false);
  }, []);

  const onClickDeleteDomain = useCallback(async () => {
    try {
      setLoading(true);
      const response = await dispatch(removeVercelDomain(brandDomainDetails?.domain));
      if (response) {
        loadData();
        close();
      }
    } catch (e) {
      captureException(e);
    } finally {
      setLoading(false);
    }
  }, [brandDomainDetails?.domain, close, dispatch, loadData]);

  return (
    <div>
      <AppLayout isPosition={true}>
        <Group>
          <TitleDiv>
            <Header>
              {isMobile && <ResponsiveHeader />}
              <Personaltext>Branding & Domains</Personaltext>
            </Header>
          </TitleDiv>
          <Content>
            <Box>
              <Top>
                <Title>Brand</Title>
                <Desc>Host the documentation at your own domain</Desc>
              </Top>
              <Bottom>
                <Card>
                  <Square>
                    <Logo>
                      <ColorPicker
                        value={brandDomainDetails?.primaryColor}
                        onChange={(color: any, hex: string) => onChangeField('primaryColor', hex)}
                      />
                    </Logo>
                    <Bottomdiv>
                      <p>Color</p>
                      <Mode>(Light Mode)</Mode>
                    </Bottomdiv>
                  </Square>
                  <Square>
                    <Logo>
                      <ColorPicker
                        value={brandDomainDetails?.primaryDarkColor ? brandDomainDetails?.primaryDarkColor : '#00A16F'}
                        onChange={(color: any, hex: string) => onChangeField('primaryDarkColor', hex)}
                      />
                    </Logo>
                    <Bottomdiv>
                      <p>Color</p>
                      <Mode>(Dark Mode)</Mode>
                    </Bottomdiv>
                  </Square>
                  <Square>
                    <input
                      type='file'
                      onChange={(event) => handleFileRead(event, 'faviconIcon')}
                      ref={inputRef}
                      style={{ display: 'none' }}
                      disabled={isLoading}
                    />
                    <Logo isHover={true} onClick={handleFaviconUploadClick}>
                      {!isEmpty(brandDomainDetails?.faviconIcon?.url) ? (
                        <>
                          <img
                            src={brandDomainDetails?.faviconIcon?.url}
                            alt='color'
                            width={38}
                            height={38}
                            className='favicon-logo'
                          />
                        </>
                      ) : (
                        <Empty>
                          {themeMode?.theme === 'dark' ? (
                            <img src={emptyfavicondark} alt='color' width={38} height={38} className='empty-logo' />
                          ) : (
                            <img src={emptyfaviconlight} alt='color' width={38} height={38} className='empty-logo' />
                          )}
                        </Empty>
                      )}
                      <Overlay className='upload-block'>
                        <Textblock>
                          <SVGIcon
                            name='logo-upload-icon'
                            width='18'
                            height='18'
                            viewBox='0 0 18 18'
                            className='upload-icon'
                          />
                          <p>Upload favicon</p>
                        </Textblock>
                      </Overlay>
                    </Logo>
                    <p>Favicon</p>
                  </Square>
                  <Square>
                    <input
                      type='file'
                      onChange={(event) => handleFileRead(event, 'logo')}
                      ref={inputLogoRef}
                      style={{ display: 'none' }}
                      disabled={isLoading}
                    />
                    <Logo className='logo-img' isHover={true} onClick={handleLogoUploadClick}>
                      {!isEmpty(brandDomainDetails?.logo?.url) ? (
                        <>
                          <img
                            src={brandDomainDetails?.logo?.url}
                            alt='color'
                            width={160}
                            height={38}
                            className='logo'
                          />
                        </>
                      ) : (
                        <Empty className='logo-empty'>
                          {themeMode?.theme === 'dark' ? (
                            <img src={emptyfavicondark} alt='color' width={38} height={38} />
                          ) : (
                            <img src={emptyfaviconlight} alt='color' width={38} height={38} />
                          )}
                          <h4>Logo Text</h4>
                        </Empty>
                      )}

                      <Overlay className='upload-block'>
                        <Textblock Display={true}>
                          <SVGIcon
                            name='logo-upload-icon'
                            width='18'
                            height='18'
                            viewBox='0 0 18 18'
                            className='upload-icon'
                          />
                          <p>Upload logo</p>
                        </Textblock>
                      </Overlay>
                    </Logo>
                    <p>Logo</p>
                  </Square>
                </Card>
                <InputDiv>
                  <TitleText>Meta title</TitleText>
                  <MetaInput
                    type='text'
                    placeholder='Enter meta title'
                    value={brandDomainDetails?.metaTitle}
                    onChange={(e) => onChangeField('metaTitle', e.target.value)}
                  />
                </InputDiv>
                <div>
                  <Button
                    type='submit'
                    title='Save'
                    resWidth={100}
                    isLoading={isLoading}
                    onClick={() => onClickSaveDetails()}
                    disabled={initialLoading}
                  />
                </div>
              </Bottom>
            </Box>
            <Box hide={true}>
              <Top>
                <Title>Domain</Title>
                <Desc>Host the documentation at your own domain</Desc>
              </Top>
              <Bottom>
                <Domaincontent>
                  <div>
                    <Deleteicon>
                      <Topcontent>
                        <Left>https://</Left>
                        <Input
                          type='text'
                          value={brandDomainDetails?.domain}
                          disabled={!isEmpty(brandindStoredData?.domain)}
                          onChange={(e) => onChangeField('domain', e.target.value)}></Input>
                      </Topcontent>
                      {isEmpty(brandindStoredData?.domain) ? (
                        <Button title='Add' isLoading={isLoading} type='button' onClick={onClickAddLink} />
                      ) : (
                        <Icon onClick={openDeleteModel}>
                          <SVGIcon
                            name='delete-setting-icon'
                            width='18'
                            height='18'
                            viewBox='0 0 18 18'
                            className='delete-icon'
                          />
                        </Icon>
                      )}
                    </Deleteicon>
                  </div>
                  {dnsDetails?.records?.length > 0 && (
                    <Bottomcontent>
                      <div>
                        <Buttons>
                          <SVGIcon
                            name='brand-setting-icon'
                            width='14'
                            height='14'
                            viewBox='0 0 14 14'
                            className='fill-color'
                          />
                          <p>{dnsDetails?.misconfigured ? 'DNS Invalid Configuration' : 'DNS Valid Configuration'}</p>
                        </Buttons>
                      </div>
                    </Bottomcontent>
                  )}
                  {/* dns configuration */}
                  {dnsDetails?.records?.length > 0 && (
                    <Description>
                      <div>
                        <Detail>
                          <Heading>DNS Configuration</Heading>
                          <Contents>
                            Please add the following to your DNS configuration to successfully deploy your documentation
                            on the custom domain.
                          </Contents>
                        </Detail>
                        <Image
                          src='https://firebasestorage.googleapis.com/v0/b/teamcamp-app.appspot.com/o/MediaIcons%2Ffigma_loading.svg?alt=media&token=e441ca3d-1855-464a-8485-aeec7b2ad1db'
                          alt='Refresh Icon'
                        />
                      </div>
                      <Table>
                        <thead>
                          <tr>
                            <th className='first-column'>Type</th>
                            <th className='second-column'>Name</th>
                            <th className='third-column'>Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dnsDetails?.records?.map((item) => (
                            <tr key={item?.id}>
                              <td className='type-column'>{item?.type || ''}</td>
                              <td className='name-column'>{item?.name || ''}</td>
                              <td className='value-column'>{item?.value || ''}</td>
                              <Icon1>
                                <SVGIcon
                                  name='copy-icon'
                                  width='18'
                                  height='18'
                                  viewBox='0 0 18 18'
                                  className='copy-icon'
                                />
                              </Icon1>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Description>
                  )}
                </Domaincontent>
              </Bottom>
            </Box>
            <Box hide={true}>
              <Top>
                <Title>Email</Title>
                <Desc>Host the documentation at your own domain</Desc>
              </Top>

              <BoxCommingSoon>
                <Tag>Coming soon</Tag>
              </BoxCommingSoon>
            </Box>
          </Content>
        </Group>
      </AppLayout>
      <ModalCustom open={isModalOpen} onClose={handleCloseModal} width={633}>
        <Addaccountmodal onCancel={handleCloseModal} />
      </ModalCustom>
      <ModalCustom open={deleteOpen} onClose={close} width={334}>
        <Deletemodal
          onClose={close}
          onOk={onClickDeleteDomain}
          loading={isLoading}
          modaltitle='Remove domain?'
          description='Are you sure you want to remove domain?'
        />
      </ModalCustom>
    </div>
  );
}
