import styled from 'styled-components';

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  .loading-animation {
    overflow: hidden;
  }

  .loading-animation::before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    animation: loading 1s infinite;
    background: linear-gradient(to right, transparent, var(--background-tertiary), transparent);
  }

  @keyframes loading {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;

const Box = styled.div`
  width: 100%;
  border: 0.5px solid var(--border-primary);
  background-color: var(--background-primary);
  border-radius: 12px;
`;

const Content = styled.div`
  padding: 10px 14px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

const Bottom = styled.div`
  max-width: 378px;
  width: 100%;
  height: 10px;
  border-radius: 6px;
  background-color: var(--background-muted);
  @media (max-width: 449px) {
    width: 100%;
    max-width: 260px;
  }
`;

const Left = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  .first-line {
    width: 337px;
  }
  .third-line {
    width: 138px;
  }
  .forth-line {
    width: 459px;
  }
  @media (max-width: 449px) {
    .first-line {
      width: 285px;
    }
    .second-line {
      width: 210px;
    }
    .third-line {
      width: 246px;
    }
    .forth-line {
      width: 186px;
    }
  }
`;

const Right = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  .dot-icon {
    cursor: pointer;
    path {
      fill: var(--text-tertiary);
    }
  }
  @media (max-width: 449px) {
    .dot-icon {
      display: none;
    }
  }
`;

const Circle = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: var(--background-muted);
  @media (max-width: 449px) {
    width: 18px;
    height: 18px;
  }
`;

const Leftline = styled.div`
  width: 243px;
  height: 12px;
  border-radius: 6px;
  background-color: var(--background-muted);
  @media (max-width: 449px) {
    width: 200px;
  }
  @media (max-width: 375px) {
    width: 160px;
  }
  @media (max-width: 320px) {
    width: 130px;
  }
`;

const Users = styled.div`
  display: flex;
`;

const User = styled.div`
  border-radius: 20px;
  border: 2px solid var(--background-primary);
  background: var(--background-muted);
  width: 24px;
  height: 24px;
  margin-left: -9px;
  @media (max-width: 449px) {
    width: 20px;
    height: 20px;
  }
`;

const Content1 = styled.div`
  padding: 10px 14px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Bottomcontent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-top: 0.5px solid var(--border-primary);
  .first {
    padding-top: 10px;
  }
  @media (max-width: 449px) {
    gap: 16px;
  }
`;

const Div = styled.div`
  width: 14px;
  height: 14px;
  @media (max-width: 449px) {
    width: 18px;
    height: 18px;
  }
`;

export { Main, Box, Content, Top, Bottom, Left, Right, Circle, Leftline, Users, User, Content1, Bottomcontent, Div };
