import styled from 'styled-components';
import { Typography } from '../../../global/theme';

const Maindiv = styled.div`
  display: flex;
  flex-direction: column;
  .ProseMirror {
    max-height: 208px;
    min-height: 120px;
    overflow-y: auto;
    ::-webkit-scrollbar {
      background-color: var(--background-primary) !important;
      width: 6px !important;
    }
    ::-webkit-scrollbar-thumb {
      background-color: var(--text-tertairy) !important;
      width: 6px !important;
      margin-bottom: 4px !important;
      border-radius: 7px !important;
      min-height: 20px !important;
    }
  }
  .ProseMirror-menubar {
    padding: 7px;
    background: unset;
    border-bottom: 0.5px solid var(--border-primary);
  }

  .ProseMirror-icon {
    padding: 2px 6px;
    border: 0 solid var(--border-primary);
    border-radius: 4px;
    svg {
      fill: var(--text-secondary);
    }
    :hover {
      padding: 2px 6px;
      background: var(--neutral-secondary);
      svg {
        fill: var(--text-primary);
      }
    }
  }
`;

const Header = styled.div`
  display: flex;
  padding: 12px 16px;
  gap: 20px;
  justify-content: space-between;
  border-bottom: 0.5px solid var(--border-primary);
  p {
    color: var(--text-primary);
    ${Typography.heading_md}
    margin: 0;
  }
`;
const CloseIcon = styled.div`
  display: inline-flex;
  cursor: pointer;
  width: 18px;
  height: 18px;
  .stroke-color {
    path {
      stroke: var(--text-primary);
    }
  }
  :hover {
    background-color: var(--neutral-secondary);
    border-radius: 4px;
  }
`;
const Content = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: var(--background-primary);
  box-shadow: var(--shadow-card);
  border-radius: 0 0 12px 12px;
  gap: 24px;

  img {
    max-width: 50%;
    max-height: 50%;
  }
`;
const IconSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 7px;
  .active {
    svg {
      fill: var(--text-primary);
    }
  }
`;
const Icon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  border: 0.5px solid transparent;
  &:hover {
    background: var(--neutral-secondary);
    border-radius: 50%;
    svg path {
      fill: var(--text-primary);
    }
  }
  .svg-icon {
    fill: var(--text-secondary);
  }
`;
const Uploaddiv = styled.div`
  color: var(--text-primary);
`;

const FileBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 0;
`;

export { Maindiv, Header, Content, CloseIcon, FileBox, Icon, IconSection, Uploaddiv };
