import styled from 'styled-components';
import { Typography } from '../../global/theme';

const ActivityTitle = styled.div`
  ${Typography.body_lg_semibold}
  color: var(--text-primary);
  margin: 0;
`;

const ItemBlock = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 0.5px solid var(--border-primary);
  padding-left: 32px;
  margin-left: 13px;
  gap: 15px;
  @media (max-width: 449px) {
    gap: 16px;
  }
`;

const ItemChange = styled.div`
  display: flex;
  gap: 12px;
  margin-left: -46px;
  padding-bottom: 15px;
  @media (max-width: 449px) {
    gap: 10px;
  }
`;

const SubTitle = styled.h3`
  ${Typography.body_md_medium}
  color: var(--text-primary);
  margin: 0;
  span {
    ${Typography.body_md}
    color: var(--text-secondary);
  }
`;
const Update = styled.h3`
  ${Typography.body_md}
  color: var(--text-secondary);
  margin: 0;
`;
const ActivityContentView = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding-top: 4px;
  @media (max-width: 449px) {
    padding-top: 0;
  }
`;

const ActivitySubContent = styled.div`
  display: flex;
  gap: 6px;
`;

const SubText = styled.p`
  width: 100%;
  ${Typography.body_md_medium}
  color: var(--text-primary);
  margin: 0;
  word-break: break-word;
`;
const Text = styled.p`
  ${Typography.body_md}
  color: var(--text-secondary);
  margin: 0;
`;

const ActivityView = styled.div`
  :last-child {
    border-left: none;
  }
  min-height: 300px;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const LeftBarView = styled.div`
  display: flex;
  flex-direction: column;
`;

const Icon = styled.div``;

const Activity = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 24px;
`;

export {
  Activity,
  ActivityTitle,
  ItemBlock,
  ItemChange,
  SubTitle,
  ActivityContentView,
  ActivitySubContent,
  SubText,
  Text,
  Icon,
  LeftBarView,
  ActivityView,
  Update
};
