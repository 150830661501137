import { ConfigProvider, Radio, Space } from 'antd';
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  clearBillableHoursTimeEntryDetail,
  setCustomerInvoiceData,
  updateBillableHoursTimeEntryDetail,
  updateInvoiceBillableHoursDetail
} from '../../../../actions/invoiceActions';
import SVGIcon from '../../../../assets/images/svg/SVGIcon';
import AppLayout from '../../../../component/appLayout';
import { BILLABLE_HOURES, DISPLAY_HOURES } from '../../../../global/constants';
import { getDateByThisMonth, getFormattedDate, getUrlParam, isEmpty } from '../../../../helpers/common';
import { ROUTES } from '../../../../routes/urls';
import { getIndividualCustomerDetail } from '../../../../services/customerServices';
import {
  getCustomerInvoiceData,
  getCustomerInvoiceTimeframes,
  updateTrackTimeInvoiceDetails
} from '../../../../services/invoiceService';
import {
  Group,
  Headerblock,
  Icon,
  HeaderTitle,
  Group1,
  MainGroup,
  Quantity,
  Price,
  Label,
  Text,
  Inputvalue,
  Check,
  Title,
  Group2,
  Desc,
  Billingtime,
  Billinghour,
  Leftbilling,
  Buttons,
  Formview,
  Timeentry,
  Timedetail,
  ResponsiveNavbarIcon,
  StyledTable,
  TableRow,
  TableCell,
  TableheaderCell,
  DropdownDiv,
  SelectDiv,
  ItemDiv,
  Input,
  IconDiv
} from '../addNewInvoiceModal/styles';
import Button from '../../../../component/button';
import { captureException } from '../../../../services/logService';
import { RootReducerInterface } from '../../../../interfaces/RootReducerInterface';
import Responsivnavbar from '../../../../component/navbar/responsivenavbar';
import { Dropdown, DropdownItem } from '../../../../component/Dropdown';

const AddNewInvoiceModal: React.FC = () => {
  //States
  const [isSelectall, setIsSelectall] = useState(true);
  const [loading, setLoading] = useState(false);

  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { workspace: workspaceSelector, invoice: invoiceSelector } = stateSelector || {};
  const { workspace } = workspaceSelector;
  const { id: workspace_id } = workspace;
  const { customerInvoiceData, customerInvoiceTimeframes, inputInvoiceDetail, customerBillablrHoursDetail } =
    invoiceSelector;

  //Other variables
  const history = useHistory();
  const customerId = getUrlParam(history.location.search, 'customer');
  const dispatch = useDispatch();

  // load initial data
  const loadData = useCallback(async () => {
    if (!isEmpty(workspace_id)) {
      await Promise.all([
        dispatch(getCustomerInvoiceData(workspace_id, customerId)),
        dispatch(getCustomerInvoiceTimeframes(workspace_id)),
        dispatch(getIndividualCustomerDetail(customerId))
      ]);
    }
  }, [dispatch, workspace_id, customerId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    return () => {
      dispatch(clearBillableHoursTimeEntryDetail());
    };
  }, [dispatch]);

  // manage input values
  const onChangeInputEntryValue = useCallback(
    (propsName: string, value: boolean) => {
      dispatch(updateBillableHoursTimeEntryDetail({ propsName, value }));
    },
    [dispatch]
  );

  // update assign value
  const onChangeAssign = useCallback(
    async (item: string, value: boolean) => {
      let updatedUsersList;
      updatedUsersList = customerInvoiceData?.map((ele) => {
        return ele?.id === item ? { ...ele, checked: value } : ele;
      });
      const isAllUsersSelected = updatedUsersList.every((user: { checked: boolean }) => user.checked);
      setIsSelectall(isAllUsersSelected);
      const filterUser = updatedUsersList?.filter((item: { checked: boolean }) => item?.checked);
      dispatch(setCustomerInvoiceData(updatedUsersList));
      const usersFilter = filterUser?.map((item: { id: string }) => {
        return item?.id;
      });
      dispatch(updateInvoiceBillableHoursDetail({ propsName: 'projects', value: usersFilter }));
    },
    [customerInvoiceData, dispatch]
  );

  // select user based on checkbox
  const onChangeSelectAll = useCallback(
    async (value: boolean) => {
      setIsSelectall(value);
      const updatedList = customerInvoiceData?.map((ele) => {
        return { ...ele, checked: value };
      });
      const filterUser = updatedList?.filter((item: { checked: boolean }) => item?.checked);
      dispatch(setCustomerInvoiceData(updatedList));
      const usersFilter = filterUser?.map((item: { id: string }) => {
        return item?.id;
      });
      dispatch(updateInvoiceBillableHoursDetail({ propsName: 'projects', value: usersFilter }));
    },
    [customerInvoiceData, dispatch]
  );

  // handle review invoice button click
  const onButtonClick = useCallback(async () => {
    try {
      setLoading(true);
      const payload: any = {
        ...customerBillablrHoursDetail,
        billableHoursTimeframe: customerBillablrHoursDetail?.billableHoursTimeframe?.text
          ? customerBillablrHoursDetail?.billableHoursTimeframe?.text
          : getDateByThisMonth(),
        projects: customerBillablrHoursDetail?.projects,
        customerId: customerId
      };
      if (customerBillablrHoursDetail?.billableHoursType !== BILLABLE_HOURES.INCLUDE_UNINVOICES_BILLABLE_HOURES_FROM) {
        delete payload?.billableHoursTimeframe;
      }
      if (
        customerBillablrHoursDetail?.displayHoursType !== DISPLAY_HOURES.DETAILED ||
        customerBillablrHoursDetail?.billableHoursType === BILLABLE_HOURES.DO_NOT_INCLUDE_ANY_HOURS
      ) {
        delete payload?.displayHoursOption;
      }
      if (customerBillablrHoursDetail?.billableHoursType === BILLABLE_HOURES.DO_NOT_INCLUDE_ANY_HOURS) {
        delete payload?.displayHoursType;
      }
      const response = await dispatch(updateTrackTimeInvoiceDetails(workspace_id, payload));
      if (response) {
        dispatch(clearBillableHoursTimeEntryDetail());
        history.push(`${ROUTES.ADD_NEW_INVOICES_2}?customer=${customerId}`);
      }
    } catch (error) {
      captureException(error);
      console.log('error', error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [customerBillablrHoursDetail, customerId, dispatch, history, workspace_id]);

  // handle cancel button click
  const onClickCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  // handle radio button input value change
  const onChangeRadioInputValue = useCallback(
    (propsName: string, value: number) => {
      dispatch(updateInvoiceBillableHoursDetail({ propsName, value }));
    },
    [dispatch]
  );

  // render customer invoice data
  const renderCustomerInvoiceData = useMemo(() => {
    return customerInvoiceData?.map((item, index: number) => (
      <>
        <TableRow key={item?.id}>
          <TableCell>
            <Check>
              <Inputvalue
                type='checkbox'
                className='checkbox-round'
                checked={item?.checked}
                onChange={(e) => onChangeAssign(item?.id, e.target.checked)}
              />
              <Label>{item?.name}</Label>
            </Check>
          </TableCell>
          <TableCell>{item?.unInvoicedHours}</TableCell>
          {!isEmpty(item?.lastInvoice) ? (
            <TableCell>
              {getFormattedDate(new Date(item?.lastInvoice?.date))} • ${item?.lastInvoice?.amount} •
              {item?.lastInvoice?.number}
            </TableCell>
          ) : (
            <TableCell>N/A</TableCell>
          )}
        </TableRow>
      </>
    ));
  }, [customerInvoiceData, onChangeAssign]);

  return (
    <>
      <AppLayout>
        <Group>
          <Headerblock>
            <Icon>
              <SVGIcon name='invoice-header-icon' width='28' height='28' viewBox='0 0 28 28' className='invoice-icon' />
            </Icon>
            <ResponsiveNavbarIcon>
              <Responsivnavbar />
            </ResponsiveNavbarIcon>
            <HeaderTitle>Invoice for {inputInvoiceDetail?.customer?.companyName}</HeaderTitle>
          </Headerblock>
          <MainGroup>
            <Group1>
              <StyledTable>
                <thead>
                  <tr>
                    <TableheaderCell>
                      <Check>
                        <Inputvalue
                          type='checkbox'
                          className='checkbox-round'
                          checked={isSelectall}
                          onChange={() => onChangeSelectAll(!isSelectall)}
                        />
                        <Text>SELECT</Text>
                      </Check>
                    </TableheaderCell>
                    <TableheaderCell>
                      <Quantity>Uninvoiced Hours</Quantity>
                    </TableheaderCell>
                    <TableheaderCell>
                      <Price>Last Invoice</Price>
                    </TableheaderCell>
                  </tr>
                </thead>
                <tbody>{renderCustomerInvoiceData}</tbody>
              </StyledTable>
            </Group1>

            <Group2>
              <Title>For Time & Materials projects, what hours would you like to invoice?</Title>
              <Desc>
                <Billingtime>
                  <Leftbilling>
                    <Billinghour>Billable Hours</Billinghour>
                    <ConfigProvider
                      theme={{
                        components: {
                          Radio: {
                            colorPrimary: 'var(--brand-primary)'
                          }
                        }
                      }}>
                      <Radio.Group
                        value={customerBillablrHoursDetail?.billableHoursType}
                        onChange={(e) => onChangeRadioInputValue('billableHoursType', e.target.value)}>
                        <Space direction='vertical'>
                          <Radio value={BILLABLE_HOURES.ALL_UNINVOICED_BILLABLE_HOURES}>
                            All uninvoiced billable hours
                          </Radio>
                          <DropdownDiv>
                            <Radio value={BILLABLE_HOURES.INCLUDE_UNINVOICES_BILLABLE_HOURES_FROM}>
                              Include uninvoiced billable hours from{' '}
                            </Radio>
                            <Formview>
                              <Dropdown
                                isMinWidth={1}
                                content={
                                  <SelectDiv>
                                    <Input
                                      value={
                                        !isEmpty(customerBillablrHoursDetail?.billableHoursTimeframe?.value)
                                          ? customerBillablrHoursDetail?.billableHoursTimeframe?.value
                                          : customerInvoiceTimeframes[0]?.value
                                      }
                                    />
                                    <IconDiv>
                                      <SVGIcon
                                        name='angledown-icon'
                                        width='18'
                                        height='18'
                                        viewBox='0 0 18 18'
                                        className='angledown-icon'
                                      />
                                    </IconDiv>
                                  </SelectDiv>
                                }
                                trigger='click'>
                                <ItemDiv>
                                  {customerInvoiceTimeframes.map((item) => (
                                    <DropdownItem
                                      key={item.value}
                                      label={item.value}
                                      onClick={() =>
                                        dispatch(
                                          updateInvoiceBillableHoursDetail({
                                            propsName: 'billableHoursTimeframe',
                                            value: item
                                          })
                                        )
                                      }
                                    />
                                  ))}
                                </ItemDiv>
                              </Dropdown>
                            </Formview>
                          </DropdownDiv>
                          <Radio value={BILLABLE_HOURES.DO_NOT_INCLUDE_ANY_HOURS}>Do not include any hours</Radio>
                        </Space>
                      </Radio.Group>
                    </ConfigProvider>
                  </Leftbilling>
                </Billingtime>
                {customerBillablrHoursDetail?.billableHoursType !== BILLABLE_HOURES.DO_NOT_INCLUDE_ANY_HOURS && (
                  <Leftbilling>
                    <Billinghour>How to Display Hours</Billinghour>
                    <ConfigProvider
                      theme={{
                        components: {
                          Radio: {
                            colorPrimary: 'var(--brand-primary)'
                          }
                        }
                      }}>
                      <Radio.Group
                        value={customerBillablrHoursDetail?.displayHoursType}
                        onChange={(e) => onChangeRadioInputValue('displayHoursType', e.target.value)}>
                        <Space direction='vertical'>
                          <Radio value={DISPLAY_HOURES.BY_TASK}>
                            By Task: Combine all hours for one task into one line
                          </Radio>
                          <Radio value={DISPLAY_HOURES.BY_PERSON}>
                            By Person: Combine all hours for one person into one line
                          </Radio>
                          <Radio value={DISPLAY_HOURES.BY_PROJECT}>
                            By Project: Combine all hours for one project into one line
                          </Radio>
                        </Space>
                      </Radio.Group>
                    </ConfigProvider>
                  </Leftbilling>
                )}
                {customerBillablrHoursDetail?.displayHoursType === DISPLAY_HOURES.DETAILED &&
                  customerBillablrHoursDetail?.billableHoursType !== BILLABLE_HOURES.DO_NOT_INCLUDE_ANY_HOURS && (
                    <Leftbilling>
                      <Timedetail>
                        <Billinghour>Time Entry Details</Billinghour>
                        <Timeentry>Include the following info for each line item:</Timeentry>
                      </Timedetail>
                      <Check>
                        <Inputvalue
                          type='checkbox'
                          className='checkbox-round'
                          defaultChecked
                          checked={customerBillablrHoursDetail?.displayHoursOption?.project}
                          onChange={(e) =>
                            onChangeInputEntryValue(
                              'project',
                              !customerBillablrHoursDetail?.displayHoursOption?.project
                            )
                          }
                        />
                        <Label>Project</Label>
                      </Check>
                      <Check>
                        <Inputvalue
                          type='checkbox'
                          className='checkbox-round'
                          defaultChecked
                          checked={customerBillablrHoursDetail?.displayHoursOption?.task}
                          onChange={(e) =>
                            onChangeInputEntryValue('task', !customerBillablrHoursDetail?.displayHoursOption?.task)
                          }
                        />
                        <Label>Task</Label>
                      </Check>
                      <Check>
                        <Inputvalue
                          type='checkbox'
                          className='checkbox-round'
                          defaultChecked
                          checked={customerBillablrHoursDetail?.displayHoursOption?.people}
                          onChange={(e) =>
                            onChangeInputEntryValue('people', !customerBillablrHoursDetail?.displayHoursOption?.people)
                          }
                        />
                        <Label>People</Label>
                      </Check>
                      <Check>
                        <Inputvalue
                          type='checkbox'
                          className='checkbox-round'
                          defaultChecked
                          checked={customerBillablrHoursDetail?.displayHoursOption?.date}
                          onChange={(e) =>
                            onChangeInputEntryValue('date', !customerBillablrHoursDetail?.displayHoursOption?.date)
                          }
                        />
                        <Label>Date</Label>
                      </Check>
                    </Leftbilling>
                  )}
              </Desc>
            </Group2>
            <Buttons>
              <Button
                title='Review Invoice'
                onClick={onButtonClick}
                isLoading={loading}
                disabled={loading}
                modelbtn={true}
              />
              <Button title='Cancel' secondary={true} onClick={onClickCancel} hasNotBoxshadow modelbtn={true} />
            </Buttons>
          </MainGroup>
        </Group>
      </AppLayout>
    </>
  );
};

export default AddNewInvoiceModal;
