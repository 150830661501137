import styled from 'styled-components';
import { Typography } from '../../../global/theme';

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  .loading-animation {
    overflow: hidden;
  }

  .loading-animation::before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    animation: loading 1s infinite;
    background: linear-gradient(to right, transparent, var(--background-tertiary), transparent);
  }

  @keyframes loading {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;

const Box = styled.div`
  max-width: 662px;
  width: 100%;
  border-radius: 8px;
  border: 0.5px solid var(--border-primary);
  background: var(--background-primary);
`;
const First = styled.div`
  padding: 15px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  border-bottom: 0.5px solid var(--border-primary);
  :last-child {
    border-bottom: none;
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const Top = styled.div`
  width: 136px;
  height: 9px;
  border-radius: 7px;
  background-color: var(--background-muted);
`;

const Bottom = styled.div`
  width: 280px;
  height: 9px;
  border-radius: 7px;
  background-color: var(--background-muted);
`;

const Right = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 5px;
  background-color: var(--background-muted);
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Title = styled.div`
  color: var(--text-primary);
  ${Typography.heading_md}
`;

export { Main, Box, First, Left, Right, Top, Bottom, Content, Title };
