import styled from 'styled-components';
import { Typography } from '../../../global/theme';

const Empty = styled.div`
  margin-left: 40px;

  @media (max-width: 449px) {
    margin-left: 0;
    width: calc(100% - 32px);
    margin: 0 auto;
  }
`;

const Emptyscreen = styled.div`
  display: flex;
  gap: 18px;
  align-items: end;
  @media (max-width: 449px) {
    padding: 0 24px;
  }
`;

const Left = styled.div`
  padding-bottom: 23px;
  .left-arrow-empty {
    path {
      stroke: var(--brand-primary);
    }
    path.circle-fill {
      fill: var(--background-primary);
    }
  }
  @media (max-width: 449px) {
    padding-bottom: 20px;
  }
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 45px;
  @media (max-width: 449px) {
    padding-bottom: 53px;
  }
`;

const Top = styled.div`
  display: flex;
  .empty-image {
    width: 200px;
    height: 122px;
  }
  @media (max-width: 449px) {
    .empty-image {
      max-height: 110px;
      max-width: 200px;
      width: 100%;
      height: 100%;
    }
  }
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;
const Title = styled.div`
  color: var(--text-primary);
  ${Typography.body_lg_semibold}
  @media (max-width: 449px) {
    text-align: center;
  }
`;

const Texts = styled.div`
  color: var(--text-secondary);
  ${Typography.body_md}
  @media (max-width: 449px) {
    text-align: center;
  }
`;

export { Empty, Emptyscreen, Left, Right, Top, Bottom, Title, Texts };
