import { useSelector } from 'react-redux';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { Bottom, Empty, Emptyscreen, Left, Right, Texts, Title, Top } from './style';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import darkmessageempty from '../../../assets/images/emptystates/darkmessageempty.svg';
import emptymessagescreen from '../../../assets/images/emptystates/emptymessagelight.svg';

export const MessageEmptyState: any = () => {
  //use selector state variables
  const settingsSelector = useSelector((state: RootReducerInterface) => state.settings);
  const { themeMode } = settingsSelector;
  return (
    <Empty>
      <Emptyscreen>
        <Left>
          <SVGIcon name='empty-messagemobile' width='61' height='56' viewBox='0 0 60 56' className='left-arrow-empty' />
        </Left>
        <Right>
          <Top>
            {themeMode?.theme === 'dark' ? (
              <img src={darkmessageempty} alt='darkmessageempty' width={200} height={122} className='empty-image' />
            ) : (
              <img src={emptymessagescreen} alt='emptymessagescreen' width={200} height={122} className='empty-image' />
            )}
          </Top>
          <Bottom>
            <Title>No messages at the moment.</Title>
            <Texts>Type below to add your message.</Texts>
          </Bottom>
        </Right>
      </Emptyscreen>
    </Empty>
  );
};
