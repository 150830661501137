import axios from 'axios';
import { setGithubAccountList, setGithubRepositoryList, setSettingLoader } from '../actions/settingActions';
import { REACT_APP_TEAMCAMP_APIURL } from '../global/environment';
import { AnyAction, Dispatch } from 'redux';
import { captureException } from './logService';
import { getAPIErrorReason } from '../helpers/common';
import { setErrorMessage } from '../actions/messageActions';

/**
 * @desc github connect
 * @param {*}
 */
export const githubAccountConnect: any = (installationId: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(setSettingLoader(true));
    const payload = { installationId };
    const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/github/bind`, payload);
    if (response) return true;
    return false;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to add github account! please try again';
    captureException(error, 'settings');
    dispatchSettingError(error, dispatch);
    return false;
  } finally {
    await dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Get account list
 * @param {*}
 */
export const getAccountsList: any = () => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(setSettingLoader(true));
    const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/github/getAccountsList`);
    if (response) {
      dispatch(setGithubAccountList(response.data));
      return true;
    }
    return false;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to get account data! please try again';
    captureException(error);
    dispatchSettingError(error, dispatch);
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc delete github account
 * @param {*}
 */
export const deleteGithubAccount: any = (accountId: number) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(setSettingLoader(true));
    const response = await axios.delete(`${REACT_APP_TEAMCAMP_APIURL}/github/uninstall/${accountId}`);
    if (response) {
      return true;
    }
    return false;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to delete github account ! please try again';
    captureException(error);
    dispatchSettingError(error, dispatch);
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

/**
 * @desc Get repository list
 * @param {*}
 */
export const getRepositoryList: any = () => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(setSettingLoader(true));
    const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/github/getRepoList`);
    if (response) {
      dispatch(setGithubRepositoryList(response.data));
      return response.data;
    }
    return false;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to get repository data! please try again';
    captureException(error);
    dispatchSettingError(error, dispatch);
    return false;
  } finally {
    dispatch(setSettingLoader(false));
  }
};

function dispatchSettingError(msg: string, dispatch: Dispatch<AnyAction>) {
  dispatch(setErrorMessage(msg));
}
