import { Component } from 'react';
import { isEmpty } from '../../../helpers/common';
import { UserInterface } from '../../../interfaces/chatMessageInterface';
import { randomColorsArray } from '../../../global/row';
import { ChatBoxGroup, ChatBoxWrapper, TextWrapper, UserLogo, UserLogoWrapper } from './styles';
import UserPreferenceSingleton from '../../../helpers/userPreferenceSingleton';

interface Props {
  imgSrc: string;
  size?: number;
  name: string;
  users?: UserInterface[];
}

class ChatBoxAvatar extends Component<Props> {
  getAvtarName = (name: string): string => {
    if (isEmpty(name)) return '#';
    const splitName = name.trim().split(' ');
    if (splitName.length === 1) {
      return this.getChar(name);
    }
    if (this.props.users && this.props.users.length > 2) {
      return `${this.getChar(splitName[0])}`;
    }
    return `${this.getChar(splitName[0])}${this.getChar(splitName[1])}`;
  };

  getChar = (value: string): string => value.charAt(0).toUpperCase();

  getAsciiChar = (name: string): number => this.getChar(name).charCodeAt(0);

  getColorIndex = (name: string): number => {
    const asciiValue = this.getAsciiChar(name);
    return (asciiValue - 64) % (randomColorsArray.length - 1);
  };

  renderUserLogoOrText = (
    user: UserInterface | undefined,
    size: number | undefined,
    additionalProps: Record<string, unknown>
  ) => {
    const avatarName = this.getAvtarName(user?.name || '');
    let colorIndex = this.getColorIndex(avatarName);
    const randColor = randomColorsArray[colorIndex];
    return !isEmpty(user?.profile_photo) ? (
      <UserLogo size={size} src={user?.profile_photo} {...additionalProps} />
    ) : (
      <TextWrapper size={size} randColor={randColor} {...additionalProps}>
        <span>{avatarName}</span>
      </TextWrapper>
    );
  };

  render() {
    const { size, users } = this.props;
    const currentUser = UserPreferenceSingleton.getInstance().getCurrentUser();
    const groupUser = users?.filter((user) => user?.id !== currentUser?.id);

    return (
      <UserLogoWrapper>
        <ChatBoxWrapper size={size}>
          {this.renderUserLogoOrText(groupUser?.[0], size, {
            isLeft: users && users?.length > 2
          })}

          {users && users?.length > 2 && (
            <ChatBoxGroup size={size}>
              {this.renderUserLogoOrText(groupUser?.[1], size, {
                isRight: true,
                isImageTop: users && users?.length > 3
              })}

              {users?.length > 3 &&
                this.renderUserLogoOrText(groupUser?.[2], size, {
                  isRight: true,
                  isImageBottom: true
                })}
            </ChatBoxGroup>
          )}
        </ChatBoxWrapper>
      </UserLogoWrapper>
    );
  }
}

export default ChatBoxAvatar;
