import styled, { css } from 'styled-components';
import { Typography } from '../../global/theme';

interface Props {
  isDisabled?: boolean;
  iscustom?: boolean;
  isLoading?: boolean;
}
const Main = styled.div`
  max-width: 1022px;
  width: 100%;
  margin: 0 auto;
`;

const Header = styled.div`
  margin: 13px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Leftdiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;
const Rightdiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;
const Headertext = styled.div`
  ${Typography.heading_md}
  color: var(--text-primary);
`;

const Icon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .headerarrow {
    height: 18px;
    width: 18px;
    path {
      stroke: var(--text-primary);
    }
  }
  :hover {
    background-color: var(--neutral-secondary);
    border-radius: 4px;
  }
`;
const Bottomsection = styled.div`
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const Topheader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Text = styled.div`
  ${Typography.body_lg_semibold}
  color: var(--text-primary);
`;
const Export = styled.div`
  a {
    ${Typography.body_md_medium}
    color: var(--brand-primary);
    cursor: pointer;
  }
`;
const Maincontent = styled.div`
  border: 0.5px solid var(--border-primary);
  border-radius: 12px;
  background: var(--background-primary);
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

const Firstcontent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding: 12px 16px 11px;
  border-bottom: 0.5px solid var(--border-primary);
  :last-child {
    border-bottom: none;
  }
`;
const Topictitle = styled.h2`
  ${Typography.body_lg_medium}
  color: var(--text-primary);
  margin: 0;
  @media (max-width: 449px) {
    display: -webkit-box;
    max-width: 187px;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
const Topicdescription = styled.p`
  ${Typography.body_md}
  color: var(--text-secondary);
  margin: 0;
  @media (max-width: 449px) {
    display: -webkit-box;
    max-width: 187px;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
  }
`;
const LeftContant = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media (max-width: 449px) {
    max-width: 187px;
    gap: 4px;
  }
`;
const RightContant = styled.div`
  display: flex;
  align-items: center;
`;
const Timetitle = styled.p`
  ${Typography.body_md}
  margin: 0px;
  color: var(--brand-primary);
  span {
    color: var(--brand-primary);
    white-space: nowrap;
    ${Typography.body_lg}
  }
`;
const Iconborder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px;
  border: 0.5px solid var(--border-primary);
  border-radius: 2px;
  background: var(--brand-primary);
  .lock-icon {
    fill: var(--brand-primary);
  }
`;
const Icon1 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  svg {
    fill: none;
    stroke: var(--text-secondary);
  }
  :hover {
    background-color: var(--neutral-secondary);
    border-radius: 4px;
    svg {
      stroke: var(--text-primary);
    }
  }
`;
const Borderdiv = styled.div`
  border-left: 0.5px solid var(--border-primary);
  height: 24px;
  width: 1px;
  margin-left: 12px;
  margin-right: 8px;
`;
const Rightcontent = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 0.5px solid var(--border-primary);
  background: var(--background-primary);
  .disable-calendar {
    color: var(--text-secondary) !important;
  }
`;
const Button = styled.button<Props>`
  width: 154px;
  background: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  padding: 5px 6px 5px 12px;
  align-items: center;
  cursor: pointer;
  ${(props) =>
    props.iscustom &&
    css`
      padding: 5px 6px 5px 12px;
      border-width: 0 0.5px 0 0;
      border-color: var(--border-primary);
      border-radius: 8px 0 0 8px;
    `}
  p {
    ${Typography.body_md}
    color: var(--text-primary);
    margin: 0;
  }
  ${(props) =>
    props.disabled &&
    css`
      p {
        color: var(--text-secondary);
      }
      svg {
        path {
          stroke: var(--text-secondary) !important;
        }
      }
    `}
  .dropdown-icon {
    transform: rotate(180deg);
    stroke: var(--text-primary);
  }
  .stroke-color {
    path {
      stroke: var(--text-primary);
    }
  }
`;
const Dropbox = styled.div<Props>`
  position: absolute;
  width: 100%;
  max-width: 154px;
  background: var(--background-primary);
  border-radius: 8px;
  border: 0.5px solid var(--border-primary);
  padding: 0;
  margin-top: 5px;
  z-index: 99;

  ${(props: Props) =>
    props.isLoading &&
    css`
      opacity: 0.8;
      cursor: default;
      pointer-events: none;
    `}
`;
const Bottomdata = styled.div<Props>`
  height: 100%;
  max-height: 196px;
  overflow: auto;
  padding: 6px 0;
  ::-webkit-scrollbar {
    display: none;
  }
  ${(props: Props) =>
    props.isLoading &&
    css`
      opacity: 0.8;
      cursor: default;
      pointer-events: none;
    `}
`;

const Createitem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 6px 12px;
  :hover {
    background: var(--neutral-secondary);
    cursor: pointer;
  }
  p {
    ${Typography.body_md}
    color: var(--text-primary);
    margin: 0;
  }
`;
const Datedropbox = styled.div<Props>`
  position: absolute;
  right: 0;
  max-width: min-content;
  background: var(--background-primary);
  border-radius: 8px;
  border: 0.5px solid var(--border-primary);
  padding: 0;
  margin-top: 5px;
  z-index: 99;

  .react-calendar__navigation {
    margin-bottom: 10px;
  }
  .react-calendar__navigation button {
    background: none;
    font-size: 14px;
    height: 20px;
    margin-top: 0px;
    padding: 0 3px;
  }
  .react-calendar__tile--now:enabled:hover,
  .kKROaE .react-calendar__tile--now:enabled:focus {
    border-radius: 37px;
  }
  .rdrStartEdge .rdrEndEdge {
    color: var(--brand-primary) !important;
  }
  .menuStyle {
    box-shadow: none;
    padding: 0px;
    display: flex;
    flex-direction: column;
    .menuitem {
      white-space: nowrap;
      overflow: hidden;
      display: block;
      text-overflow: ellipsis;
    }
  }
  .rdrMonth .rdrDayDisabled {
    background-color: var(--background-primary);
  }
  .rdrCalendarWrapper {
    background-color: var(--background-primary);
    border-radius: 8px;
  }
  .rdrDayNumber span {
    color: var(--text-primary);
  }
  .rdrDayPassive .rdrDayNumber span {
    color: var(--text-secondary);
  }
  .rdrWeekDay {
    color: var(--text-secondary);
  }
  .rdrDay {
    color: var(--text-secondary) !important ;
  }

  .rdrDateDisplayWrapper {
    background-color: var(--background-primary);
    border-radius: 8px;
  }
  .rdrDateDisplayItem input {
    background-color: var(--background-primary);
    border: 0.5px solid var(--border-primary);
    color: var(--text-primary);
    border-radius: 4px;
  }
  .rdrDateDisplayItem {
    background-color: var(--background-primary);
    box-shadow: none;
  }
  .rdrMonthAndYearPickers select:hover {
    background-color: var(--neutral-secondary);
  }
  .rdrMonthAndYearPickers select {
    color: var(--text-primary);
  }
  .rdrDateDisplay {
    color: transparent !important ;
  }
  .rdrNextPrevButton {
    background-color: var(--neutral-secondary);
  }
  .rdrStartEdge {
    color: var(--text-secondary) !important;
  }
  .rdrInRange {
    color: var(--text-secondary) !important;
  }
  .rdrEndEdge {
    color: var(--text-secondary) !important;
  }
  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
    color: var(--background-primary);
  }

  .rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after,
  .rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after,
  .rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after,
  .rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after {
    background-color: var(--background-primary);
  }
  .rdrNextButton i {
    border-color: transparent transparent transparent var(--text-primary);
  }
  .rdrPprevButton i {
    border-color: transparent var(--text-primary) transparent transparent;
  }
  ${(props) =>
    props.isLoading &&
    css`
      opacity: 0.8;
      cursor: default;
      pointer-events: none;
    `}
`;
const Text1 = styled.div`
  ${Typography.body_md}
  text-align: center;
  color: var(--text-primary);
  margin: 0;
  span {
    color: var(--brand-primary);
    padding-left: 12px;
    border-left: 0.5px solid var(--border-primary);
  }
`;
const Monthpicker = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  gap: 10px;
  width: auto;
  background: var(--background-primary);
  border-width: 1px 1px 1px 0;
  border-color: var(--border-primary);
  border-radius: 8px;
  padding: 5px 6px 5px 12px;
  .dropdown-icon {
    transform: rotate(180deg);
  }
  .stroke-color {
    stroke: var(--text-primary);
  }
  .fill-color {
    fill: var(--text-secondary);
    width: 16px;
    height: 16px;
  }
  .arrows {
    path {
      stroke: var(--text-primary);
    }
  }
  .arrow-icon {
    stroke: var(--text-primary);
  }
  .disabled {
    path {
      stroke: var(--text-secondary);
    }
  }
`;
const Dropheader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 12px;
  border-bottom: 0.5px solid var(--border-primary);
`;
const Inputarea = styled.input`
  max-width: 100%;
  width: 130px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  ${Typography.body_md}
  color: var(--text-primary);
  margin: 0;
  padding: 0;
  border: none;
  background-color: var(--background-primary);
  :focus {
    outline: none;
  }
  .placeholder {
    color: var(--text-secondary);
  }
`;
const Check = styled.div`
  display: flex;
  gap: 10px;
  .checkbox-round {
    width: 7px;
    height: 7px;
    border-radius: 2px;
    vertical-align: middle;
    border: 0.5px solid var(--border-secondary);
    appearance: none;
    outline: none;
    cursor: pointer;
    margin: 0;
    padding: 7px;
  }
  input[type='checkbox'] {
    position: relative;
    cursor: pointer;
  }
  input[type='checkbox']:checked {
    background-color: var(--brand-primary);
    border: 0.5px solid var(--brand-primary);
  }
  input[type='checkbox']:checked::before {
    content: '';
    position: absolute;
    right: 50%;
    top: 50%;
    width: 3px;
    height: 8px;
    border: solid var(--text-white);
    border-width: 0 2px 2px 0;
    margin: -0.5px -1px 0 -1px;
    transform: rotate(45deg) translate(-50%, -50%) scale(0.7);
    z-index: 2;
  }
`;

const Empty = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  left: 50%;
`;

const ItemDiv = styled.div`
  max-height: 200px;
  overflow: auto;
  scrollbar-width: none;
`;

export {
  Main,
  Header,
  Leftdiv,
  Rightdiv,
  Headertext,
  Icon,
  Bottomsection,
  Topheader,
  Text,
  Export,
  Maincontent,
  Firstcontent,
  LeftContant,
  Topictitle,
  Topicdescription,
  RightContant,
  Timetitle,
  Iconborder,
  Icon1,
  Borderdiv,
  Rightcontent,
  Button,
  Dropbox,
  Bottomdata,
  Createitem,
  Datedropbox,
  Monthpicker,
  Text1,
  Dropheader,
  Inputarea,
  Check,
  Empty,
  ItemDiv
};
