import styled from 'styled-components';
import { Typography } from '../../global/theme';

interface Props {
  maxWidth: number;
}

const FileBoxMain = styled.div<Props>`
  max-width: ${(props) => props.maxWidth}px;
  width: 100%;
  background-color: var(--background-primary);
  border-radius: 8px;
  border: 0.5px solid var(--border-primary);
  /* margin-top: 4px; */
  a {
    color: var(--text-link);
    text-decoration: none !important;
    word-break: break-all;
    :hover {
      text-decoration: none !important;
    }
  }
  @media (max-width: 449px) {
    max-width: 100%;
  }
`;
const FileBoxContainer = styled.div`
  margin-top: unset !important;
  padding: 6px;
  display: flex;
  gap: 12px;
  align-items: center;
`;

const FileBox = styled.div`
  display: flex;
  width: 100%;
  gap: 4px;
  align-items: center;
  margin-top: unset !important;
`;

const ImgageDiv = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  position: relative;
  margin-top: unset !important;
`;

const FileIconDiv = styled.div`
  margin-top: unset !important;
  svg {
    margin-top: unset !important;
  }
`;

const FileType = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
  ${Typography.body_sm_semibold}
  transform: translate(-46%, -30%);
  color: var(--text-primary);
`;

const FileInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1px;
`;

const FileTitle = styled.h4`
  margin: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  white-space: break-spaces;
  word-break: break-all;
  &.h4 {
    ${Typography.body_md_medium};
    color: var(--text-primary);
  }
`;

const FileSize = styled.p`
  margin: 0;
  margin-top: 0px !important;
  &.p {
    ${Typography.body_xs};
    color: var(--text-secondary);
  }
`;

const CloseButton = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    stroke: var(--text-secondary);
  }
  :hover {
    background-color: var(--neutral-secondary);
    border-radius: 4px;
  }
`;

export {
  FileBoxMain,
  FileBoxContainer,
  FileBox,
  ImgageDiv,
  FileIconDiv,
  FileType,
  FileInfo,
  FileTitle,
  FileSize,
  CloseButton
};
