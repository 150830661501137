//App
export const SET_APP_LOADER = 'SET_APP_LOADER';
export const SET_SCREEN_RECORDING_LOADER = 'SET_SCREEN_RECORDING_LOADER';
export const SET_SCREEN_RECORDING_URL = 'SET_SCREEN_RECORDING_URL';
export const SET_CUSTOM_BRANDING = 'SET_CUSTOM_BRANDING';
export const SET_VERCEL_MODAL_OPEN = 'SET_VERCEL_MODAL_OPEN';
export const SET_SEARCHED_GIF = 'SET_SEARCHED_GIF';
export const SET_IS_NAVBAR_SHOW = 'SET_IS_NAVBAR_SHOW';
export const SET_IS_BUBBLE_MENU_OPEN = 'SET_IS_BUBBLE_MENU_OPEN';
export const SET_APP_INIT_LOADING = 'SET_APP_INIT_LOADING';
export const SET_USER_MODAL_DETAIL = 'SET_USER_MODAL_DETAIL';

// Auth
export const SET_AUTH_LOADER = 'SET_AUTH_LOADER';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_AUTH_VALIDATION_ERRORS = 'SET_AUTH_VALIDATION_ERRORS';
export const SET_PASSWORD_DETAIL = 'SET_PASSWORD_DETAIL';
export const UPDATE_PASSWORD_DETAIL = 'UPDATE_PASSWORD_DETAIL';
export const CLEAR_PASSWORD_DETAIL = 'CLEAR_PASSWORD_DETAIL';
export const SET_INVITE_INPUT = 'SET_INVITE_INPUT';
export const UPDATE_INVITE_INPUT = 'UPDATE_INVITE_INPUT';
export const CLEAR_INVITE_INPUT = 'CLEAR_INVITE_INPUT';
export const SET_USER_PROFILE_DATA = 'SET_USER_PROFILE_DATA';

//Message
export const SET_RES_SUCCESS_MSG = 'SET_RES_SUCCESS_MSG';
export const SET_RES_ERROR_MSG = 'SET_RES_ERROR_MSG';
export const SET_RES_WARNING_MSG = 'SET_RES_WARNING_MSG';
export const CLEAR_RES_MSG = 'CLEAR_RES_MSG';

//Workspace
export const SET_WORKSPACE_LOADER = 'SET_WORKSPACE_LOADER';
export const SET_WORKSPACE_LIST = 'SET_WORKSPACE_LIST';
export const SET_CURRENT_WORKSPACE = 'SET_CURRENT_WORKSPACE';
export const SET_WORKSPACE_DETAIL = 'SET_WORKSPACE_DETAIL';
export const UPDATE_WORKSPACE_DETAIL = 'UPDATE_WORKSPACE_DETAIL';
export const ADD_USER_ITEM = 'ADD_USER_ITEM';
export const UPDATE_USER_ITEM = 'UPDATE_USER_ITEM';
export const DELETE_USER_ITEM = 'DELETE_USER_ITEM';
export const CLEAR_WORKSPACE_DETAIL_ITEM = 'CLEAR_WORKSPACE_DETAIL_ITEM';
export const UPDATE_WORKSPACE_INPUT_DETAIL = 'UPDATE_WORKSPACE_INPUT_DETAIL';
export const CLEAR_WORKSPACE_INPUT_DETAIL = 'CLEAR_WORKSPACE_INPUT_DETAIL';
export const SET_WORKSPACE_USAGE = 'SET_WORKSPACE_USAGE';
export const SET_WORKSPACE_TOTAL_TASKS = 'SET_WORKSPACE_TOTAL_TASKS';

//Project
export const SET_PROJECT_LOADER = 'SET_PROJECT_LOADER';
export const SET_PROJECT_DETAIL = 'SET_PROJECT_DETAIL';
export const UPDATE_PROJECT_DETAIL = 'UPDATE_PROJECT_DETAIL';
export const CLEAR_PROJECT_DETAIL = 'CLEAR_PROJECT_DETAIL';
export const SET_PROJECT_LIST = 'SET_PROJECT_LIST';
export const SET_FAVOURITE_PROJECT_LIST = 'SET_FAVOURITE_PROJECT_LIST';
export const SET_PROJECT_FILES = 'SET_PROJECT_FILES';
export const SET_MOVE_PROJECT_FILES = 'SET_MOVE_PROJECT_FILES';
export const SET_PROJECT_ALL_FILES = 'SET_PROJECT_ALL_FILES';
export const SET_CURRENT_PROJECT = 'SET_CURRENT_PROJECT';
export const UPDATE_CURRENT_PROJECT = 'UPDATE_CURRENT_PROJECT';
export const CLEAR_CURRENT_PROJECT = 'CLEAR_CURRENT_PROJECT';
export const SET_PROJECT_STATUS = 'SET_PROJECT_STATUS';
export const CLEAR_PROJECT_STATUS = 'CLEAR_PROJECT_STATUS';
export const SET_DISCUSSION_DATA = 'SET_DISCUSSION_DATA';
export const SET_DOC_DATA = 'SET_DOC_DATA';
export const UPDATE_DOC_DATA = 'UPDATE_DOC_DATA';
export const CLEAR_DOC_DATA = 'CLEAR_DOC_DATA';
export const SET_FILE_VIEW_DATA = 'SET_FILE_VIEW_DATA';
export const SET_PROJECT_ACTIVITY = 'SET_PROJECT_ACTIVITY';
export const SET_PROJECT_ARCHIVE_DATA = 'SET_PROJECT_ARCHIVE_DATA';
export const SET_USER_ACTIVITY = 'SET_USER_ACTIVITY';
export const SET_MILESTONE_LIST = 'SET_MILESTONE_LIST';
export const UPDATE_MILESTONE_LIST = 'UPDATE_MILESTONE_LIST';
export const SET_ARCHIVE_MILESTONE_LIST = 'SET_ARCHIVE_MILESTONE_LIST';
export const UPDATE_ARCHIVE_MILESTONE_LIST = 'UPDATE_ARCHIVE_MILESTONE_LIST';
export const SET_UPLOAD_FILE_PERCENTAGE = 'SET_UPLOAD_FILE_PERCENTAGE';
export const SET_FILES_LIST_TYPE = 'SET_FILES_LIST_TYPE';
export const SET_STATUS_INPUT = 'SET_STATUS_INPUT';
export const UPDATE_STATUS_INPUT = 'UPDATE_STATUS_INPUT';
export const CLEAR_STATUS_INPUT = 'CLEAR_STATUS_INPUT';
export const SET_PROJECT_LIST_USER_NOT_IN = 'SET_PROJECT_LIST_USER_NOT_IN';

//Customer
export const SET_CUSTOMER_LOADER = 'SET_CUSTOMER_LOADER';
export const SET_CUSTOMER_LIST = 'SET_CUSTOMER_LIST';
export const SET_FILTERED_CUSTOMER_LIST = 'SET_FILTERED_CUSTOMER_LIST';
export const SET_CUSTOMER_DETAIL = 'SET_CUSTOMER_DETAIL';
export const UPDATE_CUSTOMER_DETAIL = 'UPDATE_CUSTOMER_DETAIL';
export const CLEAR_CUSTOMER_DETAIL = 'CLEAR_CUSTOMER_DETAIL';
export const SET_INDIVIDUAL_CUSTOMER_DETAIL = 'SET_INDIVIDUAL_CUSTOMER_DETAIL';
export const SET_CUSTOMER_PROJECT_DETAIL = 'SET_CUSTOMER_PROJECT_DETAIL';
export const SET_CUSTOMERS_INVOICE_LIST = 'SET_CUSTOMERS_INVOICE_LIST';
export const SET_CUSTOMERS_PROJECT_LIST = 'SET_CUSTOMERS_PROJECT_LIST';
export const SET_MEMBER_INPUT = 'SET_MEMBER_INPUT';
export const UPDATE_MEMBER_INPUT = 'UPDATE_MEMBER_INPUT';
export const CLEAR_MEMBER_INPUT = 'CLEAR_MEMBER_INPUT';
export const SET_CUSTOMER_MEMBERS = 'SET_CUSTOMER_MEMBERS';

//Setting
export const SET_SETTING_LOADER = 'SET_SETTING_LOADER';
export const SET_PROJECT_SETTING_DETAIL = 'SET_PROJECT_SETTING_DETAIL';
export const UPDATE_PROJECT_SETTING_DETAIL = 'UPDATE_PROJECT_SETTING_DETAIL';
export const CLEAR_PROJECT_SETTING_DETAIL = 'CLEAR_PROJECT_SETTING_DETAIL';
export const SET_PROFILE_SETTING_DATA = 'SET_PROFILE_SETTING_DATA';
export const UPDATE_PROFILE_SETTING_DATA = 'UPDATE_PROFILE_SETTING_DATA';
export const CLEAR_PROFILE_SETTING_DATA = 'CLEAR_PROFILE_SETTING_DATA';
export const SET_EMAIL_DETAIL = 'SET_EMAIL_DETAIL';
export const UPDATE_EMAIL_DETAIL = 'UPDATE_EMAIL_DETAIL';
export const CLEAR_EMAIL_DETAIL = 'CLEAR_EMAIL_DETAIL';
export const SET_API_KEY_LIST = 'SET_API_KEY_LIST';
export const UPDATE_THEME_MODE = 'UPDATE_THEME_MODE';
export const SET_SETTING_INTEGRATION = 'SET_SETTING_INTEGRATION';
export const UPDATE_APP_SETTING_DATA = 'UPDATE_APP_SETTING_DATA';
export const SET_SUBSCRIPTION_LIST = 'SET_SUBSCRIPTION_LIST';
export const SET_INVOICING_DETAIL = 'SET_INVOICING_DETAIL';
export const SET_INVOICE_SETTING = 'SET_INVOICE_SETTING';
export const UPDATE_INVOICING_DETAIL = 'UPDATE_INVOICING_DETAIL';
export const CLEAR_INVOICING_DETAIL = 'CLEAR_INVOICING_DETAIL';
export const SET_PROJECT_TEMPLATE_INPUT = 'SET_PROJECT_TEMPLATE_INPUT';
export const UPDATE_PROJECT_TEMPLATE_INPUT = 'UPDATE_PROJECT_TEMPLATE_INPUT';
export const CLEAR_PROJECT_TEMPLATE_INPUT = 'CLEAR_PROJECT_TEMPLATE_INPUT';
export const SET_PROJECT_TEMPLATE_DETAILS = 'SET_PROJECT_TEMPLATE_DETAILS';
export const UPDATE_PROJECT_TEMPLATE_DETAILS = 'UPDATE_PROJECT_TEMPLATE_DETAILS';
export const ADD_TASK_IN_PROJECT_TEMPLATE = 'ADD_TASK_IN_PROJECT_TEMPLATE';
export const UPDATE_TASK_IN_PROJECT_TEMPLATE = 'UPDATE_TASK_IN_PROJECT_TEMPLATE';
export const CLEAR_PROJECT_TEMPLATE_DETAILS = 'CLEAR_PROJECT_TEMPLATE_DETAILS';
export const SET_PROJECT_TEMPLATE_LIST = 'SET_PROJECT_TEMPLATE_LIST';
export const SET_TEMPLATE_TASK_GROUPS = 'SET_TEMPLATE_TASK_GROUPS';
export const SET_TEMPLATE_FILTERED_TASK_LIST = 'SET_TEMPLATE_FILTERED_TASK_LIST';
export const SET_TEMPLATE_OTHER_TASKS = 'SET_TEMPLATE_OTHER_TASKS';
export const SET_BRAND_DOMAIN_DETAILS = 'SET_BRAND_DOMAIN_DETAILS';
export const UPDATE_BRAND_DOMAIN_DETAILS = 'UPDATE_BRAND_DOMAIN_DETAILS';
export const CLEAR_BRAND_DOMAIN_DETAILS = 'CLEAR_BRAND_DOMAIN_DETAILS';
export const SET_REFERRAL_DETAILS = 'SET_REFERRAL_DETAILS';
export const SET_NOTIFICATIONS_SETTING_DETAIL = 'SET_NOTIFICATIONS_SETTING_DETAIL';
export const UPDATE_NOTIFICATIONS_SETTING_DETAIL = 'UPDATE_NOTIFICATIONS_SETTING_DETAIL';
export const CLEAR_NOTIFICATIONS_SETTING_DETAIL = 'CLEAR_NOTIFICATIONS_SETTING_DETAIL';
export const SET_TASK_TEMPLATE_DATA = 'SET_TASK_TEMPLATE_DATA';
export const UPDATE_TASK_TEMPLATE_DATA = 'UPDATE_TASK_TEMPLATE_DATA';
export const CLEAR_TASK_TEMPLATE_DATA = 'CLEAR_TASK_TEMPLATE_DATA';
export const SET_TASK_TEMPLATE_LIST = 'SET_TASK_TEMPLATE_LIST';
export const SET_THEME_TYPE = 'SET_THEME_TYPE';
export const SET_GITHUB_ACCOUNT_LIST = 'SET_GITHUB_ACCOUNT_LIST';
export const SET_GITHUB_REPOSITORY_LIST = 'SET_GITHUB_REPOSITORY_LIST';
export const SET_FILTERED_GITHUB_REPO_LIST = 'SET_FILTERED_GITHUB_REPO_LIST';

//Task
export const SET_TASK_LOADER = 'SET_TASK_LOADER';
export const SET_MY_TASK_DATA = 'SET_MY_TASK_DATA';
export const UPDATE_MY_TASK_DATA = 'UPDATE_MY_TASK_DATA';
export const UPDATE_MY_TASK_FIELD = 'UPDATE_MY_TASK_FIELD';
export const CLEAR_MY_TASK_DATA = 'CLEAR_MY_TASK_DATA';
export const SET_OVERDUE_TASKS = 'SET_OVERDUE_TASKS';
export const SET_MY_TASK_DETAILS_DATA = 'SET_MY_TASK_DETAILS_DATA';
export const SET_MY_TASK_NEW_COMMENTS = 'SET_MY_TASK_NEW_COMMENTS';
export const CLEAR_MY_TASK_DETAILS_DATA = 'CLEAR_MY_TASK_DETAILS_DATA';
export const UPDATE_MY_TASK_DETAILS_DATA = 'UPDATE_MY_TASK_DETAILS_DATA';
export const UPDATE_TASK_COMMENT = 'UPDATE_TASK_COMMENT';
export const SET_TIMELOG_DATA = 'SET_TIMELOG_DATA';
export const SET_CREATE_TASK_INPUT = 'SET_CREATE_TASK_INPUT';
export const UPDATE_CREATE_TASK_INPUT = 'UPDATE_CREATE_TASK_INPUT';
export const CLEAR_CREATE_TASK_INPUT = 'CLEAR_CREATE_TASK_INPUT';
export const SET_GROUP_INPUT = 'SET_GROUP_INPUT';
export const UPDATE_GROUP_INPUT = 'UPDATE_GROUP_INPUT';
export const CLEAR_GROUP_INPUT = 'CLEAR_GROUP_INPUT';
export const SET_TASKGROUP_LIST = 'SET_TASKGROUP_LIST';
export const SET_TASKS_LIST = 'SET_TASKS_LIST';
export const SET_FILTERED_TASK_LIST = 'SET_FILTERED_TASK_LIST';
export const UPDATE_FILTERED_TASK_LIST = 'UPDATE_FILTERED_TASK_LIST';
export const UPDATE_TASK_FIELD = 'UPDATE_TASK_FIELD';
export const CLEAR_FILTERED_TASK_LIST = 'CLEAR_FILTERED_TASK_LIST';
export const SET_SHOW_TASK_FILTER = 'SET_SHOW_TASK_FILTER';
export const SET_MEMBER_TASK_DATA = 'SET_MEMBER_TASK_DATA';
export const UPDATE_MEMBER_TASK_DATA = 'UPDATE_MEMBER_TASK_DATA';
export const UPDATE_MEMBER_TASK_FIELD = 'UPDATE_MEMBER_TASK_FIELD';
export const CLEAR_MEMBER_TASK_DATA = 'CLEAR_MEMBER_TASK_DATA';
export const SET_TASKS_FILTER_DATA = 'SET_TASKS_FILTER_DATA';
export const SET_PROJECT_TASKGROUP_LIST = 'SET_PROJECT_TASKGROUP_LIST';
export const SET_MILESTONE_INPUT = 'SET_MILESTONE_INPUT';
export const UPDATE_MILESTONE_INPUT = 'UPDATE_MILESTONE_INPUT';
export const CLEAR_MILESTONE_INPUT = 'CLEAR_MILESTONE_INPUT';
export const SET_PROJECT_TASK_MILESTONE_LIST = 'SET_PROJECT_TASK_MILESTONE_LIST';
export const SET_LABEL_INPUT = 'SET_LABEL_INPUT';
export const UPDATE_LABEL_INPUT = 'UPDATE_LABEL_INPUT';
export const CLEAR_LABEL_INPUT = 'CLEAR_LABEL_INPUT';
export const SET_SUBTASK_LIST = 'SET_SUBTASK_LIST';
export const UPDATE_SUBTASK_LIST = 'UPDATE_SUBTASK_LIST';
export const CLEAR_SUBTASK_LIST = 'CLEAR_SUBTASK_LIST';
export const SET_IS_TASK_FILTER_OPEN = 'SET_IS_TASK_FILTER_OPEN';
export const SET_TASK_FILTER = 'SET_TASK_FILTER';
export const UPDATE_TASK_FILTER = 'UPDATE_TASK_FILTER';
export const CLEAR_TASK_FILTER = 'CLEAR_TASK_FILTER';
export const SET_OTHER_TASK_LIST = 'SET_OTHER_TASK_LIST';

//Time tracking
export const SET_TIME_TRACKING_LOADER = 'SET_TIME_TRACKING_LOADER';
export const SET_TIME_LOG_DATA = 'SET_TIME_LOG_DATA';
export const SET_CURRENT_TIME_TRACKING = 'SET_CURRENT_TIME_TRACKING';
export const UPDATE_TASK_DETAILS = 'UPDATE_TASK_DETAILS';
export const CLEAR_CURRENT_TIME_TRACKING = 'CLEAR_CURRENT_TIME_TRACKING';
export const SET_TIME_ENTRY_INPUT = 'SET_TIME_ENTRY_INPUT';
export const UPDATE_TIME_ENTRY_INPUT = 'UPDATE_TIME_ENTRY_INPUT';
export const CLEAR_TIME_ENTRY_INPUT = 'CLEAR_TIME_ENTRY_INPUT';
export const SET_TIME_TRACKING_ACTIVITY = 'SET_TIME_TRACKING_ACTIVITY';
export const SET_LIST_DROPDOWN = 'SET_LIST_DROPDOWN';
export const UPDATE_LIST_DROPDOWN = 'UPDATE_LIST_DROPDOWN';
export const CLEAR_LIST_DROPDOWN = 'CLEAR_LIST_DROPDOWN';
export const SET_TIMRE_TRACKING_REPORT_DATA = 'SET_TIMRE_TRACKING_REPORT_DATA';
export const SET_REPORT_FILTER = 'SET_REPORT_FILTER';
export const UPDATE_REPORT_FILTER = 'UPDATE_REPORT_FILTER';
export const CLEAR_REPORT_FILTER = 'CLEAR_REPORT_FILTER';
export const SET_CURRENT_TRACKING_TIME = 'SET_CURRENT_TRACKING_TIME';
export const SET_MY_TIME_LOG_DATA = 'SET_MY_TIME_LOG_DATA';
export const SET_MY_TIME_LOG_FILTER = 'SET_MY_TIME_LOG_FILTER';

//Invoice
export const SET_INVOICE_LOADER = 'SET_INVOICE_LOADER';
export const SET_INVOICE_LIST = 'SET_INVOICE_LIST';
export const SET_FILTERED_INVOICE_LIST = 'SET_FILTERED_INVOICE_LIST';
export const SET_INVOICE_VIEW_DATA = 'SET_INVOICE_VIEW_DATA';
export const SET_INVOICE_ACTIVITY_DETAIL = 'SET_INVOICE_ACTIVITY_DETAIL';
export const SET_INVOICE_DETAIL = 'SET_INVOICE_DETAIL';
export const UPDATE_INVOICE_DETAIL = 'UPDATE_INVOICE_DETAIL';
export const CLEAR_INVOICE_DETAIL = 'CLEAR_INVOICE_DETAIL';
export const SET_INVOICE_LIST_FILTERED_WISE = 'SET_INVOICE_LIST_FILTERED_WISE';
export const SET_CUSTOMER_INVOICE_DATA = 'SET_CUSTOMER_INVOICE_DATA';
export const SET_CUTOMER_INVOICE_TIMEFRAMES = 'SET_CUTOMER_INVOICE_TIMEFRAMES';
export const SET_INVOICE_BILLABLE_HOURS_DETAIL = 'SET_INVOICE_BILLABLE_HOURS_DETAIL';
export const UPDATE_INVOICE_BILLABLE_HOURS_DETAIL = 'UPDATE_INVOICE_BILLABLE_HOURS_DETAIL';
export const UPDATE_BILLABLE_HOURS_TIME_ENTRY_DETAIL = 'UPDATE_BILLABLE_HOURS_TIME_ENTRY_DETAIL';
export const CLEAR_INVOICE_BILLABLE_HOURS_DETAIL = 'CLEAR_INVOICE_BILLABLE_HOURS_DETAIL';

//Report
export const SET_REPORT_LOADER = 'SET_REPORT_LOADER';
export const SET_REPORT_TASK_DETAILS = 'SET_REPORT_TASK_DETAILS';
export const SET_FILTERED_REPORT_TASK_DETAILS = 'SET_FILTERED_REPORT_TASK_DETAILS';
export const SET_PAYMENT_REPORT_DATA = 'SET_PAYMENT_REPORT_DATA';
export const SET_FILTERED_PAYMENT_REPORT_DATA = 'SET_FILTERED_PAYMENT_REPORT_DATA';
export const SET_FILTERED_INVOICE_REPORT_DATA = 'SET_FILTERED_INVOICE_REPORT_DATA';
export const SET_INVOICE_REPORT_DATA = 'SET_INVOICE_REPORT_DATA';
export const SET_PROJECT_REPORT_DATA = 'SET_PROJECT_REPORT_DATA';
export const UPDATE_FILTERED_PROJECT_REPORT_DATA = 'UPDATE_FILTERED_PROJECT_REPORT_DATA';
export const SET_FILTERED_PROJECT_REPORT_DATA = 'SET_FILTERED_PROJECT_REPORT_DATA';
export const SET_TEAM_MEMBER_REPORT_DATA = 'SET_TEAM_MEMBER_REPORT_DATA';
export const SET_FILTERED_TEAM_MEMBER_REPORT_DATA = 'SET_FILTERED_TEAM_MEMBER_REPORT_DATA';

//Chat Message
export const SET_CHAT_MESSAGE_LOADER = 'SET_CHAT_MESSAGE_LOADER';
export const SET_CHAT_GROUPS = 'SET_CHAT_GROUPS';
export const SET_PIN_CHAT_GROUPS = 'SET_PIN_CHAT_GROUPS';
export const SET_UNPIN_CHAT_GROUPS = 'SET_UNPIN_CHAT_GROUPS';
export const SET_CURRENT_CHAT_GROUP = 'SET_CURRENT_CHAT_GROUP';
export const SET_CURRENT_CHAT_GROUP_NAME = 'SET_CURRENT_CHAT_GROUP_NAME';
export const UPDATE_CURRENT_CHAT_GROUP_NAME = 'UPDATE_CURRENT_CHAT_GROUP_NAME';
export const CLEAR_CURRENT_CHAT_GROUP_NAME = 'CLEAR_CURRENT_CHAT_GROUP_NAME';

//Snap
export const SET_SNAP_LOADER = 'SET_SNAP_LOADER';
export const SET_SNAP_LIST = 'SET_SNAP_LIST';

// Doc
export const SET_DOC_LOADER = 'SET_DOC_LOADER';
export const SET_DOC_VERSION_LIST = 'SET_DOC_VERSION_LIST';

//Help center
export const SET_HELP_CENTER_LOADER = 'SET_HELP_CENTER_LOADER';
