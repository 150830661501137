import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { ROUTES } from '../../../routes/urls';

import { Backbutton, Menu, NavbarMain, Submenu, Title, Content, Maindiv, ResponsiveHeader } from './styles';
import Responsivnavbar from '../responsivenavbar';
import { BackIcon } from '../../../pages/project/details/tasks/taskDetail/styles';

const ReportNavbar: React.FC = () => {
  //Other variable
  const history = useHistory();
  const location = useLocation();

  const onClickReports = useCallback(
    (path: any) => {
      history.push(path);
    },
    [history]
  );
  return (
    <>
      <NavbarMain className='report-navbar'>
        <Content>
          <ResponsiveHeader>
            <Responsivnavbar />
            <p>Reports</p>
          </ResponsiveHeader>
          <Backbutton onClick={() => onClickReports(ROUTES.INBOX)} className='back-button'>
            <BackIcon className='back-icon'>
              <SVGIcon
                name='left-arrow-icon'
                width='18'
                height='18'
                viewBox='0 0 16 16'
                fill='none'
                className='arrow-icon'
              />
            </BackIcon>
            <p>Reports</p>
          </Backbutton>
          <Maindiv>
            <Menu>
              <Title className='first-item report-title'>
                <p>Finances</p>
              </Title>
              <Submenu
                onClick={() => onClickReports(ROUTES.REPORT_PAYMENTS)}
                isActive={location?.pathname?.startsWith('/report/payments')}
                className={
                  location?.pathname?.startsWith('/report/payments') ? 'active-report-submenu' : 'report-submenu'
                }>
                <h2>Payments</h2>
                <p>Overview of all recorded payments</p>
              </Submenu>
              <Submenu
                onClick={() => onClickReports(ROUTES.REPORT_INVOICE)}
                isActive={location?.pathname?.startsWith('/report/report_invoice')}
                className={
                  location?.pathname?.startsWith('/report/report_invoice') ? 'active-report-submenu' : 'report-submenu'
                }>
                <h2>Invoices</h2>
                <p>Advanced invoice search and filtering</p>
              </Submenu>
            </Menu>
            <Menu>
              <Title className='report-title'>
                <p>Assignments</p>
              </Title>
              <Submenu
                onClick={() => onClickReports(ROUTES.REPORT_PROJECTS)}
                isActive={location?.pathname?.startsWith('/report/projects')}
                className={
                  location?.pathname?.startsWith('/report/projects') ? 'active-report-submenu' : 'report-submenu'
                }>
                <h2>Projects</h2>
                <p>Advanced project search and filtering</p>
              </Submenu>
              <Submenu
                onClick={() => onClickReports(ROUTES.REPORT_TASK)}
                isActive={location?.pathname?.startsWith('/report/task')}
                className={location?.pathname?.startsWith('/report/task') ? 'active-report-submenu' : 'report-submenu'}>
                <h2>Tasks</h2>
                <p>Advanced task search and filtering</p>
              </Submenu>
              <Submenu
                onClick={() => onClickReports(ROUTES.REPORT_TEAM_TIMELINE)}
                isActive={location?.pathname?.startsWith('/report/team_timeline')}
                className={
                  location?.pathname?.startsWith('/report/team_timeline') ? 'active-report-submenu' : 'report-submenu'
                }>
                <h2>Team Timeline</h2>
                <p>Get a timeline overview for each team member</p>
              </Submenu>
            </Menu>
            <Menu>
              <Title className='report-title'>
                <p>Time</p>
              </Title>
              <Submenu
                className={
                  location?.pathname?.startsWith('/report/times') ? 'active-report-submenu' : 'submenu report-submenu'
                }
                onClick={() => onClickReports(ROUTES.REPORT_TIMES)}
                isActive={location?.pathname?.startsWith('/report/times')}>
                <h2>Time</h2>
                <p>
                  Get a basic overview of how much time you tracked across projects or narrow down the results with
                  advanced filters
                </p>
              </Submenu>
            </Menu>
          </Maindiv>
        </Content>
      </NavbarMain>
    </>
  );
};

export default ReportNavbar;
