import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import SVGIcon from '../../../assets/images/svg/SVGIcon';

type CommandItem = {
  title: string;
  iconName: string;
  command: (item: CommandItem) => void;
};

type CommandsListProps = {
  items: CommandItem[];
  command: (item: CommandItem) => void;
};

export type CommandsListHandle = {
  onKeyDown: (event: React.KeyboardEvent) => boolean;
};

const CommandsList = forwardRef<CommandsListHandle, CommandsListProps>(({ items, command }, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    setSelectedIndex(0);
  }, [items]);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'ArrowUp') {
      event.preventDefault();
      setSelectedIndex((prevIndex) => (prevIndex - 1 + items.length) % items.length);
      return true;
    }

    if (event.key === 'ArrowDown') {
      event.preventDefault();
      setSelectedIndex((prevIndex) => (prevIndex + 1) % items.length);
      return true;
    }

    if (event.key === 'Enter') {
      event.preventDefault();
      selectItem(selectedIndex);
      return true;
    }

    if (event.key === 'Escape') {
      return true;
    }

    return false;
  };

  const selectItem = (index: number) => {
    const item = items[index];
    if (item) {
      command(item);
    }
  };

  useImperativeHandle(ref, () => ({
    onKeyDown: handleKeyDown
  }));

  return (
    <div
      className='dropdown-menu'
      tabIndex={0} // Allows the div to receive focus for keyboard events
    >
      {items.length > 0 ? (
        items.map((item, index) => (
          <button
            key={item?.title}
            className={`item ${index === selectedIndex ? 'is-selected' : ''}`}
            onClick={() => selectItem(index)}>
            <SVGIcon name={item.iconName} width='16' height='16' viewBox='0 0 16 16' />
            {item.title}
          </button>
        ))
      ) : (
        <div className='item'>No result</div>
      )}
    </div>
  );
});

export default CommandsList;
