import { useCallback, useState } from 'react';
import {
  Box,
  Icon,
  Items,
  ListItem,
  Loading,
  LoadingDiv,
  LoadingWrap,
  LockIcon,
  ModelBox,
  ModelHeader,
  ProjectList,
  SearchInput,
  Text,
  Title
} from './styled';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from '../../../helpers/common';
import { GithubRepositoryList } from '../../../interfaces/SettingsInterface';
import { setFilteredGithubRepositoryList } from '../../../actions/settingActions';
import EmptyState from '../../emptyState';
import projectempty from '../../../assets/images/emptystates/emptyrepolight.svg';
import projectdarkempty from '../../../assets/images/emptystates/emptyrepodark.svg';
import noprojectfound from '../../../assets/images/emptystates/notfoundrepolight.svg';
import noprojectfounddark from '../../../assets/images/emptystates/notfoundrepodark.svg';
import { selectGithubRepositoryList, selectFilteredGithubRepoList, selectThemeMode } from '../../../store/selectors';

interface Props {
  onClose?: () => void;
  handleSelect: (id: GithubRepositoryList) => void;
  loading: boolean;
}
function SearchRepositoryModel({ onClose, handleSelect, loading }: Props) {
  //State variables
  const [searchInput, setSearchInput] = useState('');

  //use selector state variables with memoized selectors
  const githubRepositoryList = useSelector(selectGithubRepositoryList);
  const filteredGithubRepoList = useSelector(selectFilteredGithubRepoList);
  const themeMode = useSelector(selectThemeMode);

  //other variables
  const dispatch = useDispatch();
  const getImageSource = () => (themeMode?.theme === 'dark' ? projectdarkempty : projectempty);
  const notFoundProjectSource = () => (themeMode?.theme === 'dark' ? noprojectfounddark : noprojectfound);

  //Handle repository search
  const handleSearch = useCallback(
    (value: string) => {
      setSearchInput(value);
      if (!isEmpty(value)) {
        const searchText = searchInput.trim().toLowerCase();
        const filteredRepositories: GithubRepositoryList[] = githubRepositoryList?.filter((repo) => {
          const repoName = repo?.fullName?.trim().toLowerCase() || '';
          return repoName.includes(searchText);
        });
        dispatch(setFilteredGithubRepositoryList(filteredRepositories));
      } else {
        dispatch(setFilteredGithubRepositoryList(githubRepositoryList));
      }
    },
    [dispatch, githubRepositoryList, searchInput]
  );
  return (
    <ModelBox>
      <ModelHeader>
        <SearchInput type='text' placeholder='Search repositories' onChange={(e) => handleSearch(e.target.value)} />
        <Icon onClick={onClose}>
          <SVGIcon name='close-icon' width='18' height='18' viewBox='0 0 18 18' fill='none' className='close-icon' />
        </Icon>
      </ModelHeader>
      <ProjectList>
        {!loading && !isEmpty(githubRepositoryList) && !isEmpty(filteredGithubRepoList) && (
          <Title>Your repositories</Title>
        )}
        {!loading && isEmpty(githubRepositoryList) && (
          <Box>
            <EmptyState
              header='No repositories'
              isgap={16}
              image={getImageSource()}
              hideButton
              isCustomerDetail={true}
            />
          </Box>
        )}
        {loading && (
          <Box>
            <LoadingDiv>
              <LoadingWrap>
                <Loading />
              </LoadingWrap>
              <Text>Loading...</Text>
            </LoadingDiv>
          </Box>
        )}
        {!loading && !isEmpty(githubRepositoryList) && isEmpty(filteredGithubRepoList) && (
          <Box>
            <EmptyState
              header='No search results found.'
              title='Try changing your search term.'
              isgap={16}
              image={notFoundProjectSource()}
              hideButton
              isCustomerDetail={true}
            />
          </Box>
        )}
        {!loading && !isEmpty(githubRepositoryList) && !isEmpty(filteredGithubRepoList) && (
          <Items>
            {!loading &&
              !isEmpty(githubRepositoryList) &&
              !isEmpty(filteredGithubRepoList) &&
              filteredGithubRepoList?.map((repo) => (
                <ListItem key={repo?.id} onClick={() => handleSelect(repo)}>
                  {repo?.private ? (
                    <LockIcon>
                      <SVGIcon
                        name='private-lock-icon'
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        className='lock-icon'
                      />
                    </LockIcon>
                  ) : (
                    <LockIcon>
                      <SVGIcon name='repo-public-icon' width='16' height='16' viewBox='0 0 16 16' />
                    </LockIcon>
                  )}
                  <p>{repo?.fullName}</p>
                </ListItem>
              ))}
          </Items>
        )}
      </ProjectList>
    </ModelBox>
  );
}

export default SearchRepositoryModel;
