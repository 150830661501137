import styled from 'styled-components';
import { Typography } from '../../../global/theme';

const Main = styled.form`
  max-width: 462px;
  width: 100%;
  background-color: var(--background-primary);
  border-radius: 12px;
  box-shadow: var(--shadow-modal);
`;

const Top = styled.div`
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  border-bottom: 0.5px solid var(--border-primary);
  align-items: center;
`;

const Left = styled.div`
  color: var(--text-primary);
  ${Typography.heading_md}
`;

const Right = styled.div`
  display: inline-flex;
  .close-icon {
    cursor: pointer;
    path {
      stroke: var(--text-primary);
    }
  }
  :hover {
    background-color: var(--neutral-secondary);
    border-radius: 4px;
  }
`;

const Bottom = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Formbutton = styled.div`
  display: flex;
  gap: 12px;
  align-items: flex-end;
  justify-content: flex-end;
`;
const Form = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Label = styled.label`
  ${Typography.body_md_medium}
  color: var(--text-secondary);
`;
const Inputvalue = styled.input`
  background-color: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 6px;
  padding: 8px 12px;
  ${Typography.body_lg}
  color: var(--text-primary);
  outline: none;
  :focus {
    border: 0.5px solid var(--brand-primary);
    z-index: 999;
  }
  ::placeholder {
    ${Typography.body_lg}
    color: var(--text-secondary);
  }
`;
export { Main, Top, Left, Right, Bottom, Formbutton, Form, Label, Inputvalue };
