import styled, { css } from 'styled-components';
import { Typography } from '../../../global/theme';

interface Props {
  color?: string;
  isCustomcontextmenu?: boolean;
  isCreateTask?: boolean;
  isTaskDetail?: boolean;
}

const Dropcontainer = styled.div<Props>`
  padding-top: 6px;
  background: var(--background-primary);
  box-shadow: var(--shadow-modal);
  border-radius: 8px;
  padding: 0px;
  z-index: 1;
  ${(props: Props) =>
    props.isCustomcontextmenu &&
    css`
      position: relative;
      min-width: 140px;
      width: unset;
      border: 1px solid var(--border-primary);
    `}
`;

const Dropblock = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const Droptextform = styled.p`
  ${Typography.body_md}
  color: var(--text-primary);
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 96px;
`;

const Icon = styled.div<Props>`
  margin: 4px;
  max-width: 8px;
  width: 100%;
  height: 8px;
  border-radius: 20px;
  ${(props: Props) =>
    props.color &&
    css`
      background-color: ${props.color};
    `}
`;

const Dropcontent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  cursor: pointer;
  padding: 6px 12px;
  :hover {
    background: var(--neutral-secondary);
  }
  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const SvgIconDiv = styled.div<Props>`
  display: flex;
  align-items: center;
  border: 0.5px solid transparent;
  padding: 5px 11px;
  gap: 10px;
  border-radius: 6px;
  svg {
    stroke: var(--text-secondary);
    margin: 2px 0;
  }
  &:hover {
    border: 0.5px solid var(--border-primary);
    background-color: var(--background-primary);
    svg {
      stroke: var(--text-primary);
    }
  }
  ${(props: Props) =>
    props.isCreateTask &&
    css`
      padding: 2px 7px;
      gap: 6px;
      border-radius: 8px;
      border: 0.5px solid var(--border-primary);
      &:hover {
        background-color: var(--neutral-secondary);
      }
    `}
`;

const BlankText = styled.p`
  margin: 0;
  display: block;
  color: var(--text-primary);
  ${Typography.body_md_medium}
  ${(props: Props) =>
    props.isCreateTask &&
    css`
      display: none;
    `}
`;

const Icondiv = styled.div<Props>`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  padding: 5px 11px;
  border: 0.5px solid transparent;
  border-radius: 6px;
  :hover {
    border: 0.5px solid var(--border-primary);
    background-color: var(--background-primary);
  }
  ${(props: Props) =>
    props.isTaskDetail &&
    css`
      max-width: 165px;
      @media (max-width: 449px) {
        max-width: 250px;
      }
    `}
  ${(props: Props) =>
    props.isCreateTask &&
    css`
      border: 0.5px solid var(--border-primary);
      padding: 2px 7px;
      border-radius: 8px;
      gap: 10px;
    `}
`;
const Coloricon = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
`;
const LabelIcon = styled.div<Props>`
  max-width: 8px;
  width: 100%;
  height: 8px;
  border-radius: 20px;
  ${(props: Props) =>
    props.color &&
    css`
      background-color: ${props.color};
    `}
`;
const RightText = styled.div<Props>`
  ${Typography.body_md_medium}
  color: var(--text-primary);
  margin: 0;
  width: 100%;
  white-space: nowrap;

  p {
    ${Typography.body_md_medium}
    margin: 0;
  }
  ${(props: Props) =>
    props.isCreateTask &&
    css`
      p {
        color: var(--text-secondary);
      }
      display: none;
    `}
`;

const CheckBox = styled.input`
  position: relative;
  appearance: none;
  max-width: 14px;
  width: 100%;
  height: 14px;
  border: 1px solid var(--border-secondary);
  border-radius: 4px;
  background-color: var(--background-primary);
  cursor: pointer;
  outline: none;
  margin: 0;
  :checked {
    background-color: var(--brand-primary);
    opacity: 1;
    border: 0.5px solid var(--brand-primary);
  }
  &:checked::after {
    content: '';
    position: absolute;
    right: 50%;
    top: 50%;
    width: 3px;
    height: 8px;
    border: solid var(--text-white);
    border-width: 0 2px 2px 0;
    margin: -0.5px -1px 0 -1px;
    -webkit-transform: rotate(45deg) translate(-50%, -50%) scale(0.7);
    -ms-transform: rotate(45deg) translate(-50%, -50%) scale(0.7);
    transform: rotate(45deg) translate(-50%, -50%) scale(0.7);
    z-index: 2;
  }
`;
const IconMainDiv = styled.div`
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
`;

const ContentDiv = styled.div`
  height: 100%;
  max-height: 192px;
  overflow: auto;
  scrollbar-width: none;
`;

export {
  Dropcontainer,
  Dropcontent,
  Dropblock,
  Droptextform,
  Icon,
  LabelIcon,
  Icondiv,
  Coloricon,
  SvgIconDiv,
  RightText,
  BlankText,
  CheckBox,
  IconMainDiv,
  ContentDiv
};
