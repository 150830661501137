import React from 'react';
import { Dropdown, DropdownDivider, DropdownItem } from '../../component/Dropdown';

function App() {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 30 }}>
      <Dropdown content={<button style={{ width: 600 }}>Menu</button>} trigger='contextmenu'>
        <DropdownItem>
          <span role='img' aria-label='user'>
            👤
          </span>
          User
        </DropdownItem>
        <DropdownItem>
          <span role='img' aria-label='user'>
            👤
          </span>
          User 1
        </DropdownItem>
        <DropdownItem>
          <span role='img' aria-label='user'>
            👤
          </span>
          User 2
        </DropdownItem>
        <DropdownItem>
          <Dropdown
            content={
              <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                <span role='img' aria-label='user'>
                  👤
                </span>
                Sub Menu
              </div>
            }
            trigger='click'>
            <DropdownItem>
              <span role='img' aria-label='profile'>
                📝
              </span>
              Profile Settings
            </DropdownItem>
            <DropdownItem>
              <span role='img' aria-label='notifications'>
                🔔
              </span>
              Notifications
            </DropdownItem>
            <DropdownDivider />
            <DropdownItem>
              <span role='img' aria-label='security'>
                🔒
              </span>
              Security
            </DropdownItem>
          </Dropdown>
        </DropdownItem>
        <DropdownDivider />
        <DropdownItem>
          <span role='img' aria-label='logout'>
            🚪
          </span>
          Logout
        </DropdownItem>
      </Dropdown>
      <Dropdown content={<button>Menu</button>} trigger='click'>
        <DropdownItem>
          <span role='img' aria-label='user'>
            👤
          </span>
          User
        </DropdownItem>
        <DropdownItem>
          <span role='img' aria-label='user'>
            👤
          </span>
          User 1
        </DropdownItem>
        <DropdownItem>
          <span role='img' aria-label='user'>
            👤
          </span>
          User 2
        </DropdownItem>
        <DropdownItem>
          <Dropdown
            content={
              <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                <span role='img' aria-label='user'>
                  👤
                </span>
                Sub Menu
              </div>
            }
            trigger='hover'>
            <DropdownItem>
              <span role='img' aria-label='profile'>
                📝
              </span>
              Profile Settings
            </DropdownItem>
            <DropdownItem>
              <span role='img' aria-label='notifications'>
                🔔
              </span>
              Notifications
            </DropdownItem>
            <DropdownDivider />
            <DropdownItem>
              <span role='img' aria-label='security'>
                🔒
              </span>
              Security
            </DropdownItem>
          </Dropdown>
        </DropdownItem>
        <DropdownDivider />
        <DropdownItem>
          <span role='img' aria-label='logout'>
            🚪
          </span>
          Logout
        </DropdownItem>
      </Dropdown>
    </div>
  );
}

export default App;
