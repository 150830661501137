import React, { useCallback, useEffect, useState } from 'react';
import AppLayout from '../../../component/appLayout';
import { Personaltext } from '../styles';
import Button from '../../../component/button';
import {
  Bottom,
  Bottomcontent,
  BottomDiv,
  Box,
  Card,
  // BottomDiv,
  // Card,
  // Icon,
  Content,
  Description,
  Empty,
  Header,
  Icon,
  Leftcontent,
  Leftdata,
  Price,
  Rightcontent,
  Row,
  Rowdata,
  Subscriptiongroup,
  Title,
  Topplan
} from './styles';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { useSelector, useDispatch } from 'react-redux';
import { getSubscriptionList } from '../../../services/settingServices';
import moment from 'moment';
import { countDays, getUrlParam, isEmpty } from '../../../helpers/common';
import Subscriptionloading from '../../../component/loading/subscriptionloading';
import { captureException } from '../../../services/logService';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import EmptyState from '../../../component/emptyState';
import taskdarkempty from '../../../assets/images/emptystates/darkmytaskempty.svg';
import tasklightempty from '../../../assets/images/emptystates/mytask-empty.svg';
import { PersonalHeader } from '../apps/styles';
import { ResponsiveHeader } from '../SettingHeaderResponsive';
import { useMobileDevice } from '../../../global/useMobile';
import { useHistory } from 'react-router-dom';

const Subscription: React.FC = () => {
  // States
  const [loading, setLoading] = useState(false);
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { settings: settingsSelector, workspace: workspaceSelector } = stateSelector || {};
  const { subscriptionList, themeMode } = settingsSelector;
  const { workspace } = workspaceSelector;
  const { id: workspace_id } = workspace;
  // Other variables
  const isMobile = useMobileDevice();
  const history = useHistory();
  const status = getUrlParam(history.location.search, 'status');
  const dispatch = useDispatch();

  const getImageSource = () => (themeMode?.theme === 'dark' ? taskdarkempty : tasklightempty);

  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      if (!isEmpty(workspace_id)) {
        await dispatch(getSubscriptionList(workspace_id));
      }
    } catch (error) {
      captureException(error);
      console.log('error', error);
    } finally {
      if (!isEmpty(workspace_id)) setLoading(false);
    }
  }, [dispatch, workspace_id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <>
      <AppLayout isPosition={true}>
        <Subscriptiongroup>
          <Header>
            <PersonalHeader>
              {isMobile && <ResponsiveHeader />}
              <Personaltext>Subscription</Personaltext>
            </PersonalHeader>
          </Header>
          <Content>
            {loading && <Subscriptionloading />}
            {/* Payment Successfull card */}
            {(!loading ||
              subscriptionList?.activeSubscription?.length > 0 ||
              subscriptionList?.pastSubscription?.length > 0) && (
              <>
                {status && status === 'success' && (
                  <Card>
                    <Icon>
                      <SVGIcon name='successfull-icon' width='42' height='42' viewBox='0 0 42 42' />
                    </Icon>
                    <BottomDiv>
                      <h1>Payment Successful! </h1>
                      <p>Your subscription is now active. Enjoy seamless access to all premium features!</p>
                    </BottomDiv>
                  </Card>
                )}
                {/* Payment failed card */}
                {status && status === 'failed' && (
                  <Card>
                    <Icon>
                      <SVGIcon name='failed-icon' width='42' height='42' viewBox='0 0 42 42' />
                    </Icon>
                    <BottomDiv>
                      <h1>Payment Failed </h1>
                      <p>
                        There was an issue with your payment. Update your details to keep your access uninterrupted.
                      </p>
                    </BottomDiv>
                    <Button title='Retry' secondary={true} hasNotBoxshadow={true} modelbtn={true} />
                  </Card>
                )}
              </>
            )}
            {!loading &&
              subscriptionList?.activeSubscription?.length > 0 &&
              subscriptionList?.activeSubscription?.map((item) => {
                const daysDifference = countDays(item?.end);
                const isLifetime = item?.planName?.toLowerCase().includes('lifetime');
                return (
                  <Bottomcontent key={item?.id}>
                    <Box>
                      <Leftcontent>
                        <h1>
                          {item?.planName} - for {Number(item?.users) < 0 ? 'Unlimited' : item?.users} Users
                        </h1>
                        <Description>
                          <p>{isLifetime ? 'Lifetime' : `${daysDifference} days remaining`}</p>
                        </Description>
                      </Leftcontent>
                      <Rightcontent>
                        <SVGIcon
                          name='more-icon'
                          width='18'
                          height='18'
                          viewBox='0 0 18 18'
                          fill='none'
                          className='svg-icon'
                        />
                      </Rightcontent>
                    </Box>
                  </Bottomcontent>
                );
              })}
            {false && !loading && subscriptionList?.activeSubscription?.length === 0 && (
              <Topplan>
                <Leftdata>
                  <h1>Unlimited</h1>
                  <p>
                    Choose a plan that aligns with your goals and enjoy the full power of Teamcamp without any hidden
                    costs.
                  </p>
                  <Rowdata>
                    <Row>
                      <SVGIcon
                        name='correct-sign-icon'
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        className='correct-sign-icon'
                      />
                      <p>Unlimited user, project & task</p>
                    </Row>
                    <Row>
                      <SVGIcon
                        name='correct-sign-icon'
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        className='correct-sign-icon'
                      />
                      <p>Desktop time tracker</p>
                    </Row>
                    <Row>
                      <SVGIcon
                        name='correct-sign-icon'
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        className='correct-sign-icon'
                      />
                      <p>Invoicing and Payment</p>
                    </Row>
                  </Rowdata>
                  <Button title='Upgrade' />
                </Leftdata>
                <Rightcontent>
                  <Price>
                    $59 <span>/ month</span>
                  </Price>
                  {themeMode?.theme === 'dark' ? (
                    <SVGIcon
                      name='dark-background-subscription'
                      width='662'
                      height='150'
                      viewBox='0 0 662 150'
                      className='background-image'
                    />
                  ) : (
                    <SVGIcon
                      name='light-background-subscription'
                      width='662'
                      height='150'
                      viewBox='0 0 662 150'
                      className='background-image'
                    />
                  )}
                </Rightcontent>
              </Topplan>
            )}
            {!loading &&
              subscriptionList?.activeSubscription?.length === 0 &&
              subscriptionList?.pastSubscription?.length === 0 && (
                <Empty>
                  <EmptyState
                    hideButton={true}
                    image={getImageSource()}
                    header="You don't have any subscription"
                    title='There are no subscriptions'
                  />
                </Empty>
              )}
            {!loading && subscriptionList?.pastSubscription?.length > 0 && (
              <Bottom>
                <Title>Past Subscription</Title>
                <Bottomcontent>
                  {subscriptionList?.pastSubscription?.map((item) => {
                    const daysDifference = countDays(item?.end);
                    return (
                      <Box key={item?.id}>
                        <Leftcontent>
                          <h1>
                            {item?.planName} - for {Number(item?.users) < 0 ? 'Unlimited' : item?.users} Users
                          </h1>
                          {daysDifference > 0 ? (
                            <Description>
                              <p> {daysDifference} days remaining </p>:
                            </Description>
                          ) : (
                            <Description>
                              <p>{moment(item?.end).format('MMM DD, YYYY')}</p>
                            </Description>
                          )}
                        </Leftcontent>
                      </Box>
                    );
                  })}
                </Bottomcontent>
              </Bottom>
            )}
          </Content>
        </Subscriptiongroup>
      </AppLayout>
    </>
  );
};

export default Subscription;
