import { ConfigProvider, TimePicker } from 'antd';

interface Props {
  value: any;
  onSelect: (value: any) => void;
  placement?: 'topLeft';
  style?: any;
}

export default function TimePickerModal({ value, onSelect, placement, style }: Props) {
  //Other variable
  const config = {
    token: {
      colorPrimaryBg: 'var(--background-primary)',
      colorBgContainer: 'var(--background-primary)',
      controlItemBgHover: 'var(--neutral-secondary)',
      colorBgBase: 'var(--background-primary)',
      colorLink: 'var(--text-primary)',
      colorLinkHover: 'var(--text-primary)',
      colorPrimary: 'var(--brand-primary)',
      colorPrimaryHover: '#03724F',
      colorText: 'var(--text-primary)',
      boxShadowSecondary: 'var(--shadow-modal)',
      colorBorderSecondary: 'var(--border-primary)'
    }
  };
  return (
    <ConfigProvider theme={config}>
      <TimePicker
        getPopupContainer={(trigger: HTMLElement) => trigger.parentNode as HTMLElement}
        format={'HH:mm'}
        allowClear={false}
        value={value}
        onSelect={onSelect}
        suffixIcon={null}
        placement={placement}
        popupStyle={{
          border: '0.5px solid',
          borderRadius: 8,
          borderColor: 'var(--border-primary)'
        }}
        style={style}
      />
    </ConfigProvider>
  );
}
