import React from 'react';
import { Loadingbox, Loaddata, Leftloading, Left, Right, Top, Bottom, Rightloading, Main } from './styles';

export default function Chatloading() {
  return (
    <div>
      <Main>
        <Loadingbox>
          <Loaddata>
            <Leftloading>
              <Left className='loading-animation'></Left>
              <Right>
                <Top className='loading-animation'></Top>
                <Bottom className='loading-animation'></Bottom>
              </Right>
            </Leftloading>
            <Rightloading className='loading-animation' />
          </Loaddata>
        </Loadingbox>
        <Loadingbox>
          <Loaddata>
            <Leftloading>
              <Left className='loading-animation'></Left>
              <Right>
                <Top className='loading-animation'></Top>
                <Bottom className='loading-animation'></Bottom>
              </Right>
            </Leftloading>
            <Rightloading className='loading-animation' />
          </Loaddata>
        </Loadingbox>
        <Loadingbox>
          <Loaddata>
            <Leftloading>
              <Left className='loading-animation'></Left>
              <Right>
                <Top className='loading-animation'></Top>
                <Bottom className='loading-animation'></Bottom>
              </Right>
            </Leftloading>
            <Rightloading className='loading-animation' />
          </Loaddata>
        </Loadingbox>
        <Loadingbox>
          <Loaddata>
            <Leftloading>
              <Left className='loading-animation'></Left>
              <Right>
                <Top className='loading-animation'></Top>
                <Bottom className='loading-animation'></Bottom>
              </Right>
            </Leftloading>
            <Rightloading className='loading-animation' />
          </Loaddata>
        </Loadingbox>
        <Loadingbox>
          <Loaddata>
            <Leftloading>
              <Left className='loading-animation'></Left>
              <Right>
                <Top className='loading-animation'></Top>
                <Bottom className='loading-animation'></Bottom>
              </Right>
            </Leftloading>
            <Rightloading className='loading-animation' />
          </Loaddata>
        </Loadingbox>
        <Loadingbox>
          <Loaddata>
            <Leftloading>
              <Left className='loading-animation'></Left>
              <Right>
                <Top className='loading-animation'></Top>
                <Bottom className='loading-animation'></Bottom>
              </Right>
            </Leftloading>
            <Rightloading className='loading-animation' />
          </Loaddata>
        </Loadingbox>
        <Loadingbox>
          <Loaddata>
            <Leftloading>
              <Left className='loading-animation'></Left>
              <Right>
                <Top className='loading-animation'></Top>
                <Bottom className='loading-animation'></Bottom>
              </Right>
            </Leftloading>
            <Rightloading className='loading-animation' />
          </Loaddata>
        </Loadingbox>
        <Loadingbox>
          <Loaddata>
            <Leftloading>
              <Left className='loading-animation'></Left>
              <Right>
                <Top className='loading-animation'></Top>
                <Bottom className='loading-animation'></Bottom>
              </Right>
            </Leftloading>
            <Rightloading className='loading-animation' />
          </Loaddata>
        </Loadingbox>
        <Loadingbox>
          <Loaddata>
            <Leftloading>
              <Left className='loading-animation'></Left>
              <Right>
                <Top className='loading-animation'></Top>
                <Bottom className='loading-animation'></Bottom>
              </Right>
            </Leftloading>
            <Rightloading className='loading-animation' />
          </Loaddata>
        </Loadingbox>
      </Main>
    </div>
  );
}
