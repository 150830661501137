import React from 'react';
import { Loadingimage } from './styles';
import loadingstate from '../../../../src/assets/images/loadingstate.gif';

export default function ReportTimelineLoading() {
  return (
    <>
      {' '}
      <Loadingimage>
        <img src={loadingstate} alt='loadingstate' width={26} height={26} className='loading-state' />
      </Loadingimage>
    </>
  );
}
