import styled from 'styled-components';
import { Typography } from '../../../global/theme';

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 14px 0 24px;
  @media (max-width: 449px) {
    align-items: center;
    margin: 0 -16px;
    padding: 12px 16px;
    position: sticky;
    z-index: 999;
    top: 0;
    background: var(--background-primary);
    border-bottom: 0.5px solid var(--border-primary);
  }
`;

const Headerblock = styled.div`
  display: flex;
  gap: 30px;
  @media (max-width: 449px) {
    gap: 24px;
    align-items: center;
  }
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const Icon = styled.div`
  display: inline-flex;
  position: relative;
  .svgicon {
    cursor: pointer;
    path {
      stroke: var(--text-primary);
    }
    :hover {
      background-color: var(--neutral-secondary);
      border-radius: 4px;
    }
  }
  .close-icon {
    cursor: pointer;
    path {
      stroke: var(--text-primary);
    }
    :hover {
      background-color: var(--neutral-secondary);
      border-radius: 4px;
    }
  }
  .doticon {
    cursor: pointer;
    stroke: var(--text-secondary);
    fill: var(--text-secondary);

    :hover {
      background-color: var(--neutral-secondary);
      border-radius: 4px;
    }
  }
  .invoice-icon {
    fill: var(--brand-primary);
  }

  @media (max-width: 449px) {
    .svgicon {
      padding: 0 15px;
    }
    .doticon {
      width: 18px;
      height: 18px;
    }
    &.dot-icon {
      padding: 7px;
    }
  }
  @media (max-width: 449px) {
    .close-icon {
      width: 18px;
      height: 18px;
    }
  }
  .close-icon {
    cursor: pointer;
    stroke: var(--text-primary);
  }
  .fillColor {
    stroke: var(--brand-primary);
  }
  .more-icon {
    cursor: pointer;
    fill: var(--text-secondary);
    stroke: var(--text-secondary);
    :hover {
      background-color: var(--neutral-secondary);
      border-radius: 4px;
    }
  }
`;

const Title = styled.h1`
  ${Typography.heading_lg}
  color: var(--text-primary);
  margin: 0;
  white-space: nowrap;
`;

const Menu = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  .active {
    display: inline-flex;
    background-color: var(--neutral-secondary);
    border-radius: 4px;
  }
  @media (max-width: 449px) {
    gap: 16px;
  }
`;

const Headerbtn = styled.div`
  @media (max-width: 449px) {
    display: none;
  }
`;
const Headerplusbtn = styled.div`
  display: none;
  @media (max-width: 449px) {
    display: flex;
    padding-top: 2px;
  }
`;
const Resnavbar = styled.div`
  display: none;
  @media (max-width: 449px) {
    display: flex;
    width: 24px;
    height: 24px;
  }
`;
export { Content, Headerblock, Heading, Icon, Title, Menu, Headerbtn, Headerplusbtn, Resnavbar };
