import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CalendarComponent from './Calendarcomponent';
import { Dropdown, Menu as MenuAnt, ConfigProvider } from 'antd';
import {
  AssignBlock,
  AssignBoxs,
  AssignHead,
  Bottomsection,
  CalenderMainDiv,
  CloseIcon,
  Div,
  Rightside,
  View,
  ViewDropdown,
  Weekly
} from './styles';
import Actions from './Actions';
import SVGIcon from '../../assets/images/svg/SVGIcon';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';
import { updateProjectTemplateDetails } from '../../actions/settingActions';
import {
  updateCreateTaskInput,
  updateMemberTaskField,
  updateMyTaskDetailsData,
  updateMyTaskField,
  updateTaskField,
  updatSubTaskList
} from '../../actions/taskActions';
import UserPreferenceSingleton from '../../helpers/userPreferenceSingleton';
import { isEmpty } from '../../helpers/common';
import { nanoid } from 'nanoid';
import { COMMENT_TYPE } from '../../global/constants';
import { DataCommentsInterface } from '../../interfaces/TaskInterface';
import { trackAnalyticActivity } from '../../services/analyticsService';
import {
  createNewComment,
  updateTaskLocalData,
  updateRecurringTaskDetails,
  updateTaskDetails,
  loadMyTaskData
} from '../../services/taskServices';
import Submenu from './subMenuWeeklyMenu';
import SubMenuMonthly from './subMenuMonthly';
import { TASK_ANALYTICS } from '../../global/analyticsConstants';
import { DropdownDivider, DropdownItem } from '../Dropdown';

interface Props {
  data?: any;
  loadData?: () => void;
  isUpdateReducer?: any;
  date?: any;
  isTaskDetails?: boolean;
  isCalendarhover?: boolean;
  isTemplateProject?: boolean;
  dueResult?: { overDue: boolean; dueTime: string };
  taskGrid?: boolean;
  isCustomcontextmenu?: boolean;
  calanderDate?: any;
  setCalanderDate?: any;
  style?: any;
  setDisplayState?: (value: string) => void;
  setLinkDueDate?: (value: any) => void;
  isShareDocModel?: boolean;
  isSelectedTime?: boolean;
  isRecurringTask?: boolean;
  isMyTask?: boolean;
  isSubTask?: boolean;
  isMember?: boolean;
}

export default function CalendarDropdownMenu(props: Props) {
  const {
    data,
    isUpdateReducer = false,
    isTemplateProject = false,
    isCustomcontextmenu = false,
    calanderDate,
    setCalanderDate,
    style,
    setDisplayState,
    setLinkDueDate,
    isRecurringTask = false,
    isMyTask,
    isSubTask = false,
    isTaskDetails,
    isMember
  } = props;
  //States
  const [isSelectedRepeat, setIsSelectedRepeat] = useState(false);
  const [isShowWeeklySubmenu, setIsShowWeeklySubmenu] = useState(false);
  const [weeklySubmenuPosition, setWeeklySubmenuPosition] = useState<'upwards' | 'downwards'>('downwards');
  const [isShowMonthlySubmenu, setIsShowMonthlySubmenu] = useState(false);
  const [monthlySubmenuPosition, setMonthlySubmenuPosition] = useState<'upwards' | 'downwards'>('downwards');
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { workspace: workspaceSelector, settings: settingsSelector, task: taskSelector } = stateSelector || {};
  const { projectTemplateDetails } = settingsSelector;
  const { createTaskInput } = taskSelector;
  const { workspace } = workspaceSelector;
  const { id: workspace_id } = workspace;
  //Other variables
  const dispatch = useDispatch();
  const day = moment().format('ddd');
  const tomorrow = moment().add(1, 'days').format('ddd');
  const config = {
    token: {
      paddingXXS: 0,
      colorBgElevated: 'var(--background-primary)',
      boxShadowSecondary: 'var(--shadow-modal)',
      controlItemBgHover: 'var(--neutral-secondary)',
      borderRadiusSM: 0
    },
    components: {
      Dropdown: {
        zIndexPopup: 1111
      }
    }
  };

  const handleMouseEnter = (
    e: { key: string; domEvent: React.MouseEvent<HTMLElement> },
    setPosition: (position: 'upwards' | 'downwards') => void,
    setShowSubmenu: (show: boolean) => void
  ) => {
    const domEvent = e.domEvent;
    const triggerElement = domEvent.currentTarget;
    const rect = triggerElement.getBoundingClientRect();
    const spaceBelow = window.innerHeight - rect.bottom;
    const spaceAbove = rect.top;

    if (spaceBelow < 200 && spaceAbove > 200) {
      setPosition('upwards');
    } else {
      setPosition('downwards');
    }

    setShowSubmenu(true);
  };

  const updateTemplateProjectData = useCallback(
    (dueDate: string | null) => {
      const updatedTasks = projectTemplateDetails?.tasks?.map((x) => {
        if (x?.id === props?.data?.id) {
          return { ...x, dueDate };
        } else {
          return x;
        }
      });
      dispatch(updateProjectTemplateDetails({ propsName: 'tasks', value: updatedTasks }));
    },
    [dispatch, projectTemplateDetails?.tasks, props?.data?.id]
  );

  useEffect(() => {
    if (!isEmpty(createTaskInput?.recurringInterval)) {
      setIsSelectedRepeat(true);
    } else {
      setIsSelectedRepeat(false);
    }
  }, [createTaskInput?.recurringInterval]);

  const getDueDateAndDisplay = useCallback((input: string): { dueDate: string | null; display: string } => {
    switch (input) {
      case 'today':
        return { dueDate: moment().toISOString(), display: 'Today' };
      case 'tomorrow':
        return { dueDate: moment().add(1, 'days').toISOString(), display: 'Tomorrow' };
      case 'nextweek':
        return { dueDate: moment().clone().day(8).toISOString(), display: 'Next Week' };
      case 'nodate':
        return { dueDate: null, display: '' };
      default:
        return { dueDate: null, display: '' };
    }
  }, []);

  const createUpdatedComments = useCallback(
    (type: number, payload: any, userDetails: any) => {
      return [
        ...(props?.data?.updatedComments || []),
        {
          id: nanoid(),
          type,
          updatedBy: userDetails?.id,
          updatedTime: new Date().toISOString(),
          createdOn: new Date().toISOString(),
          user: { ...userDetails, name: userDetails?.given_name },
          dueDate: payload?.dueDate,
          status: true
        }
      ];
    },
    [props?.data?.updatedComments]
  );

  const handleTaskDetailsForNoDate = useCallback(
    async (payload: any, userDetails: any, payloadTask: any) => {
      const updatedComments = createUpdatedComments(COMMENT_TYPE.REMOVE_DUE_DATE, payload, userDetails);
      dispatch(updateMyTaskDetailsData({ propsName: 'dueDate', value: null }));
      dispatch(updateMyTaskDetailsData({ propsName: 'updatedComments', value: updatedComments }));
      const response = await dispatch(updateTaskDetails(payload?.id, payloadTask));
      const result = await dispatch(
        createNewComment(payload?.id, {
          Type: COMMENT_TYPE.REMOVE_DUE_DATE
        })
      );
      if (response && result) props?.loadData?.();
    },
    [createUpdatedComments, dispatch, props]
  );

  const createComment = useCallback((payload: any, dueDate: string | null, userDetails: any) => {
    const newComment: DataCommentsInterface = {
      _id: nanoid(),
      CreatedBy: userDetails?.id,
      CreatedTime: new Date().toISOString(),
      UpdatedBy: userDetails?.id,
      UpdatedTime: new Date().toISOString(),
      Type: COMMENT_TYPE.SET_DUE_DATE,
      DueDate: dueDate
    };

    if (!isEmpty(payload?.previousDueDate)) {
      newComment.Type = COMMENT_TYPE.CHANGE_DUE_DATE;
      newComment.PreviousDueDate = payload?.previousDueDate;
    }
    return newComment;
  }, []);

  const handleNoDate = useCallback(
    async (payload: any) => {
      const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
      const payloadTask = { dueDateUnset: true, projectId: payload?.projectId };

      if (isRecurringTask) {
        const response = await dispatch(updateRecurringTaskDetails(payload?.id, payloadTask));
        if (response) props?.loadData?.();
      } else {
        await handleTaskDetailsForNoDate(payload, userDetails, payloadTask);
      }
    },
    [dispatch, handleTaskDetailsForNoDate, isRecurringTask, props]
  );

  const trackDueDateActivity = useCallback((type: number) => {
    if (type === COMMENT_TYPE.SET_DUE_DATE) trackAnalyticActivity(TASK_ANALYTICS.SCHEDULED);
    else trackAnalyticActivity(TASK_ANALYTICS.RESCHEDULED);
  }, []);

  const updateTaskDetailsAndComments = useCallback(
    async (payload: any, newComment: any, payloadTask: any) => {
      if (isMyTask || isMember) {
        const updatedTaskData = {
          taskId: payload?.id,
          key: 'dueDate',
          value: payload?.dueDate
        };
        isMyTask ? dispatch(updateMyTaskField(updatedTaskData)) : dispatch(updateMemberTaskField(updatedTaskData));
        const updatedData = { ...data, dueDate: payload?.dueDate };
        dispatch(updateTaskLocalData(updatedData));
        if (isMyTask) {
          await dispatch(loadMyTaskData(false));
        }
      } else dispatch(updateTaskField({ taskId: payload?.id, key: 'dueDate', value: payload?.dueDate }));

      if (isSubTask) {
        const taskDetailsClone = JSON.parse(JSON.stringify(data));
        taskDetailsClone.dueDate = payload?.dueDate;
        dispatch(updatSubTaskList(taskDetailsClone));
      }
      if (isTaskDetails) {
        const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
        dispatch(updateMyTaskDetailsData({ propsName: 'dueDate', value: payload?.dueDate }));
        const updatedComments = [
          ...(props?.data?.updatedComments || []),
          {
            id: newComment?.[`_id`],
            type: newComment.Type,
            updatedBy: userDetails?.id,
            updatedTime: new Date().toISOString(),
            createdOn: new Date().toISOString(),
            user: { ...userDetails, name: userDetails?.given_name },
            dueDate: payload?.dueDate,
            previousDueDate: newComment?.PreviousDueDate,
            status: true
          }
        ];
        dispatch(updateMyTaskDetailsData({ propsName: 'updatedComments', value: updatedComments }));
      }

      const response = await dispatch(updateTaskDetails(payload?.id, payloadTask));
      const result = await dispatch(createNewComment(payload?.id, newComment));

      if (response && result) {
        trackDueDateActivity(newComment.Type);
        if (!isMyTask && !isMember) props?.loadData?.();
      }
    },
    [data, dispatch, isMember, isMyTask, isSubTask, isTaskDetails, props, trackDueDateActivity]
  );

  const handleDueDateUpdate = useCallback(
    async (dueDate: string | null, payload: any, userDetails: any) => {
      const newComment = createComment(payload, dueDate, userDetails);
      const payloadTask = { dueDate, projectId: payload?.projectId };

      if (isRecurringTask) {
        const response = await dispatch(updateRecurringTaskDetails(payload?.id, payloadTask));
        if (response && newComment.Type) {
          trackDueDateActivity(newComment.Type);
          props?.loadData?.();
        }
      } else {
        await updateTaskDetailsAndComments(payload, newComment, payloadTask);
      }
    },
    [createComment, dispatch, isRecurringTask, props, trackDueDateActivity, updateTaskDetailsAndComments]
  );

  const onClickTime = useCallback(
    async (input: string) => {
      const { dueDate, display } = getDueDateAndDisplay(input);
      if (setDisplayState) setDisplayState(display);

      // Main logic
      if (!props?.isShareDocModel) {
        if (isTemplateProject) {
          updateTemplateProjectData(dueDate);
        } else if (isUpdateReducer) {
          dispatch(updateCreateTaskInput({ propsName: 'dueDate', value: dueDate }));
        } else {
          const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
          const payload = {
            ...props.data,
            companyId: workspace_id,
            previousDueDate: props?.data?.dueDate,
            dueDate
          };

          if (input === 'nodate') {
            await handleNoDate(payload);
          } else {
            await handleDueDateUpdate(dueDate, payload, userDetails);
          }
        }
      } else {
        if (setLinkDueDate) setLinkDueDate(dueDate);
      }
    },
    [
      getDueDateAndDisplay,
      setDisplayState,
      props?.isShareDocModel,
      props.data,
      isTemplateProject,
      isUpdateReducer,
      updateTemplateProjectData,
      dispatch,
      workspace_id,
      handleNoDate,
      handleDueDateUpdate,
      setLinkDueDate
    ]
  );

  const setDailyTask = useCallback(async () => {
    try {
      const payloadTask = { projectId: props?.data?.projectId, start: null, recurringInterval: 'daily' };
      const response = await dispatch(updateRecurringTaskDetails(props?.data?.id, payloadTask));
      if (response) {
        props?.loadData?.();
      }
      dispatch(updateCreateTaskInput({ propsName: 'start', value: null }));
      dispatch(updateCreateTaskInput({ propsName: 'recurringInterval', value: 'daily' }));
      setIsOptionSelected(true);
    } catch (error) {
      console.log('error', error);
    }
  }, [dispatch, props]);

  const onRemoveRecurring = useCallback(() => {
    try {
      dispatch(updateCreateTaskInput({ propsName: 'start', value: null }));
      dispatch(updateCreateTaskInput({ propsName: 'recurringInterval', value: null }));
    } catch (error) {
      console.log('error', error);
    }
  }, [dispatch]);

  const handleDropdownClick = useCallback(
    (input: string) => {
      onClickTime(input);
    },
    [onClickTime]
  );

  const handleClick = useCallback((e: MouseEvent) => {
    e.stopPropagation();
  }, []);

  const recurringMenuDropdown = useMemo(() => {
    return (
      <Dropdown
        trigger={['click']}
        placement='bottomCenter'
        transitionName=''
        getPopupContainer={(trigger: HTMLElement) => trigger.parentNode as HTMLElement}
        dropdownRender={() => (
          <div>
            <ViewDropdown
              isOptionSelected={isOptionSelected}
              isShowWeeklySubmenu={isShowWeeklySubmenu}
              isShowMonthlySubmenu={isShowMonthlySubmenu}>
              <MenuAnt className='menustyle'>
                <MenuAnt.Item key='daily' style={{ padding: 0 }} className='menuitem' onClick={setDailyTask}>
                  <View>Daily</View>
                </MenuAnt.Item>
                <MenuAnt.Item
                  key='weekly'
                  style={{ padding: 0 }}
                  onMouseEnter={(e) => handleMouseEnter(e, setWeeklySubmenuPosition, setIsShowWeeklySubmenu)}
                  onMouseLeave={() => setIsShowWeeklySubmenu(false)}
                  className='menuitem'>
                  <View isShowSubmenu={isShowWeeklySubmenu}>
                    {' '}
                    <span>Weekly</span>
                    <SVGIcon
                      name='calendar-right-arrow-icon'
                      width='16'
                      height='16'
                      viewBox='0 0 16 16'
                      className='svgicon'
                    />
                  </View>
                  {isShowWeeklySubmenu && (
                    <Submenu
                      className={weeklySubmenuPosition === 'upwards' ? 'submenu-upwards' : 'submenu-downwards'}
                      data={props?.data}
                      loadData={props?.loadData}
                    />
                  )}
                </MenuAnt.Item>
                <MenuAnt.Item
                  key='monthly'
                  style={{ padding: 0 }}
                  onMouseEnter={(e) => handleMouseEnter(e, setMonthlySubmenuPosition, setIsShowMonthlySubmenu)}
                  onMouseLeave={() => setIsShowMonthlySubmenu(false)}
                  className='menuitem'>
                  <View isShowSubmenu={isShowMonthlySubmenu}>
                    {' '}
                    <span>Monthly</span>
                    <SVGIcon
                      name='calendar-right-arrow-icon'
                      width='16'
                      height='16'
                      viewBox='0 0 16 16'
                      className='svgicon'
                    />
                  </View>
                  {isShowMonthlySubmenu && (
                    <SubMenuMonthly
                      className={monthlySubmenuPosition === 'upwards' ? 'submenu-upwards' : 'submenu-downwards'}
                      data={props?.data}
                      loadData={props?.loadData}
                    />
                  )}
                </MenuAnt.Item>
              </MenuAnt>
            </ViewDropdown>
          </div>
        )}>
        <Rightside isSelectedRepeat={isSelectedRepeat || isRecurringTask} onClick={(e: any) => handleClick(e)}>
          <SVGIcon
            name='repeat-task-icon'
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            className='stroke-color'
          />
          <p>Repeat</p>
        </Rightside>
      </Dropdown>
    );
  }, [
    handleClick,
    isOptionSelected,
    isRecurringTask,
    isSelectedRepeat,
    isShowMonthlySubmenu,
    isShowWeeklySubmenu,
    monthlySubmenuPosition,
    props?.data,
    props?.loadData,
    setDailyTask,
    weeklySubmenuPosition
  ]);

  return (
    <>
      <AssignBoxs isCustomcontextmenu={isCustomcontextmenu} style={style}>
        <AssignHead>
          <Actions date={calanderDate} />
        </AssignHead>
        <DropdownDivider />
        <AssignBlock>
          <DropdownItem
            label='Today'
            iconName='today-icon'
            iconSize={16}
            iconViewBox='0 0 24 24'
            onClick={() => handleDropdownClick('today')}
            isSuffix={day}
          />
          <DropdownItem
            label='Tomorrow'
            iconName='tomorrow-icon'
            iconSize={16}
            iconViewBox='0 0 24 24'
            onClick={() => handleDropdownClick('tomorrow')}
            isSuffix={tomorrow}
          />
          <DropdownItem
            label='Next Week'
            iconName='next-week-icon'
            iconSize={16}
            iconViewBox='0 0 24 24'
            onClick={() => handleDropdownClick('nextweek')}
            isSuffix='Mon'
          />
          <DropdownItem
            label='No Date'
            iconName='no-date-icon'
            iconSize={16}
            iconViewBox='0 0 24 24'
            onClick={() => handleDropdownClick('nodate')}
          />
        </AssignBlock>
        <DropdownDivider />
        <CalenderMainDiv>
          <CalendarComponent
            isTemplateProject={isTemplateProject}
            updateTemplateProjectData={updateTemplateProjectData}
            date={calanderDate}
            setCalanderDate={setCalanderDate}
            data={props?.data}
            isUpdateReducer={isUpdateReducer}
            loadData={props?.loadData}
            isShareDocModel={props?.isShareDocModel}
            setLinkDate={setLinkDueDate}
            isRecurringTask={isRecurringTask}
            isMyTask={isMyTask}
            isSubTask={isSubTask}
            isTaskDetails={isTaskDetails}
            isMember={isMember}
          />
        </CalenderMainDiv>
        {(isUpdateReducer || isRecurringTask) && !isTemplateProject && (
          <>
            <DropdownDivider />
            <Bottomsection isSelectedRepeat={isSelectedRepeat || isRecurringTask}>
              <Div isSelectedRepeat={isSelectedRepeat || isRecurringTask}>
                <ConfigProvider theme={config}></ConfigProvider>
                {recurringMenuDropdown}
                {(isSelectedRepeat || isRecurringTask) && (
                  <Weekly>
                    <p>
                      {isRecurringTask && !isSelectedRepeat
                        ? props?.data?.recurringInterval
                        : createTaskInput?.recurringInterval}
                    </p>
                    {!isRecurringTask && (
                      <CloseIcon onClick={onRemoveRecurring}>
                        <SVGIcon
                          name='selected-close-icon'
                          width='16'
                          height='16'
                          viewBox='0 0 16 16'
                          fill='none'
                          className='close-icon'
                        />
                      </CloseIcon>
                    )}
                  </Weekly>
                )}
              </Div>
            </Bottomsection>
          </>
        )}
      </AssignBoxs>
    </>
  );
}
