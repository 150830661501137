import React, { useCallback, useEffect } from 'react';
import {
  Group,
  Content,
  Icon,
  Title,
  Formblock,
  Profile,
  Heading,
  Text,
  Subblock,
  Item,
  Label,
  Inputvalue,
  Textformat,
  FormContent,
  Endblock,
  ProfileView,
  ProfileContent
} from './styles';
import profile from '../../../assets/images/profile.svg';
import { industryList, teamMember } from '../../../global/row';
import { useDispatch, useSelector } from 'react-redux';
import { updateWorkspaceInputDetail } from '../../../actions/workspaceActions';
import { checkStepOneValidation } from '../../../services/workspaceService';
import UserPreferenceSingleton from '../../../helpers/userPreferenceSingleton';
import Button from '../../../component/button';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { Select } from 'antd';
import { isEmpty } from '../../../helpers/common';
import { trackAnalyticActivity } from '../../../services/analyticsService';
import AppLogo from '../../../component/appLogo';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import { VIEW_ANALYTICS } from '../../../global/analyticsConstants';

interface Props {
  gotoNext: () => void;
}

const OnboardingStep1: React.FC<Props> = ({ gotoNext }) => {
  //use selector state variables
  const workspaceSelector = useSelector((state: RootReducerInterface) => state.workspace);
  const { workspaceInputDetails } = workspaceSelector;
  const { name, industry, teamType } = workspaceInputDetails;
  // Other variables
  const dispatch = useDispatch();
  const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();

  useEffect(() => {
    trackAnalyticActivity(VIEW_ANALYTICS.ONBOARDING_STEP_1);
  }, []);

  const onUpdateInputValue = useCallback(
    (propsName: string, value: any) => {
      dispatch(updateWorkspaceInputDetail({ propsName, value }));
    },
    [dispatch]
  );

  const onSubmit = useCallback(
    async (e: React.SyntheticEvent) => {
      e.preventDefault();
      const payload = {
        name: workspaceInputDetails?.name,
        industry: workspaceInputDetails?.industry?.value,
        teamType: workspaceInputDetails?.teamType?.value
      };
      const isValidate = await dispatch(checkStepOneValidation(payload));
      if (!isValidate) return;
      gotoNext();
    },
    [dispatch, gotoNext, workspaceInputDetails]
  );
  return (
    <>
      <Group className='windows-onboarding-flow'>
        <Content onSubmit={onSubmit}>
          <Icon>
            <AppLogo height='48' />
          </Icon>
          <Title>Just a few quick things to set up your account…</Title>
          <FormContent>
            <Formblock>
              <Profile>
                <Icon>
                  <img src={profile} alt='' />
                </Icon>
                <ProfileView>
                  <ProfileContent>
                    <Heading>Hello, {userDetails?.given_name}</Heading>
                  </ProfileContent>
                  <Text>First, tell us a bit about your company.</Text>
                </ProfileView>
              </Profile>

              <Subblock>
                <Item>
                  <Label>Company Name</Label>
                  <Inputvalue
                    typeof='text'
                    placeholder='Enter company name'
                    value={name}
                    onChange={(e) => {
                      onUpdateInputValue('name', e.target.value);
                    }}
                  />
                </Item>
                <Item>
                  <Label>Team Member</Label>
                  <Select
                    showSearch={false}
                    placeholder='Select a team member'
                    getPopupContainer={(trigger: HTMLElement) => trigger.parentNode as HTMLElement}
                    onChange={(value, item: any) => {
                      onUpdateInputValue('teamType', item);
                    }}
                    filterOption={(input, option: any) =>
                      (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={teamMember}
                    value={!isEmpty(teamType) ? teamType : undefined}
                    className='select'
                    menuItemSelectedIcon={
                      <>
                        <SVGIcon name='checkbox-icon' width='16' height='16' viewBox='0 0 16 16' fill='none' />
                      </>
                    }
                    suffixIcon={
                      <SVGIcon
                        name='angledown-icon'
                        width='18'
                        height='18'
                        viewBox='0 0 18 18'
                        className='angledown-icon'
                      />
                    }
                  />
                </Item>
                <Item>
                  <Label>Industry</Label>
                  <Select
                    showSearch={false}
                    placeholder='Select an industry'
                    getPopupContainer={(trigger: HTMLElement) => trigger.parentNode as HTMLElement}
                    onSelect={(value, item: any) => {
                      onUpdateInputValue('industry', item);
                    }}
                    filterOption={(input, option: any) =>
                      (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={industryList}
                    value={!isEmpty(industry) ? industry : undefined}
                    menuItemSelectedIcon={
                      <>
                        <SVGIcon name='checkbox-icon' width='16' height='16' viewBox='0 0 16 16' fill='none' />
                      </>
                    }
                    suffixIcon={
                      <SVGIcon
                        name='angledown-icon'
                        width='18'
                        height='18'
                        viewBox='0 0 18 18'
                        className='angledown-icon'
                      />
                    }
                  />
                </Item>
              </Subblock>
            </Formblock>
            <Endblock>
              <Textformat>Step 1 to 2</Textformat>
              <Button title='Next' type='submit' resWidth={100} />
            </Endblock>
          </FormContent>
        </Content>
      </Group>
    </>
  );
};
export default OnboardingStep1;
