import styled, { css } from 'styled-components';
import { Typography } from '../../global/theme';

interface Props {
  isDisabled?: boolean;
  isCurrent?: boolean;
}
const Timetraking = styled.div`
  width: 100%;
  max-width: 1022px;
  margin: auto;
`;
const Headerblock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 14px 0 24px;
  gap: 80px;
  @media (max-width: 449px) {
    position: fixed;
    height: 56px;
    top: 0;
    left: 0;
    z-index: 1;
    padding: 0 16px;
    gap: 24px;
    margin: 0;
    width: calc(100vw - 32px);
    background: var(--background-primary);
    border-bottom: 0.5px solid var(--border-primary);
    .hidebutton {
      display: none;
    }
  }
`;
const Heading = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
const Icon = styled.div`
  display: inline-flex;
  .time-header-icon {
    stroke: var(--brand-primary);
  }
  .time-header-icon {
    fill: var(--brand-primary);
  }
  .close-icon {
    cursor: pointer;
    stroke: var(--text-primary);
    :hover {
      background-color: var(--neutral-secondary);
      border-radius: 4px;
    }
  }
  @media (max-width: 449px) {
    &.timeheader {
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
`;
const Icon1 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  svg {
    fill: none;
    stroke: var(--text-secondary);
  }
  :hover {
    background-color: var(--neutral-secondary);
    border-radius: 4px;
    svg {
      stroke: var(--text-primary);
    }
  }
`;
const Title = styled.h1`
  ${Typography.heading_lg}
  color: var(--text-primary);
  margin: 0;
`;

const Datesider = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: var(--background-primary);
  border-radius: 12px;
  border: 0.5px solid var(--border-primary);
`;
const Leftblock = styled.div<Props>`
  padding: 0 3px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  margin-top: -1px;
  cursor: pointer;
  ${(props: Props) =>
    props.isDisabled &&
    css`
      opacity: 0.8;
      pointer-events: none;
    `}
  @media (max-width: 449px) {
    border-right: 0;
  }
`;
const Siderbox = styled.div<Props>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  padding: 10px 16px;
  border-top: 2px solid transparent;
  margin-top: -1px;

  cursor: pointer;
  ${(props: Props) =>
    props.isDisabled &&
    css`
      color: var(--border-primary);
      opacity: 0.8;
      pointer-events: none;
      background-color: var(--background-secondary);
      margin-top: 0;
    `}

  ${(props: Props) =>
    props.isCurrent &&
    css`
      background: var(--neutral-secondary);
      border-top: 2px solid var(--brand-primary);
      margin-top: -1px;
    `}

    ${(props: Props) =>
    !props.isDisabled &&
    css`
      :hover {
        background: var(--neutral-secondary);
        border-top: 2px solid var(--brand-primary);
        margin-top: -1px;
      }
    `}
    @media (max-width: 449px) {
    gap: 6px;
    margin-top: 0px;
    :last-child {
      border-right: 0;
    }

    ${(props: Props) =>
      !props.isDisabled &&
      css`
        :hover {
          margin-top: 0;
        }
      `}
  }
`;

const Datetitle = styled.h1<Props>`
  ${Typography.body_sm_medium}
  color: var(--text-secondary);
  margin: 0px;
  @media (max-width: 449px) {
    width: 118px;
  }
  ${(props: Props) =>
    props.isDisabled &&
    css`
      color: var(--border-primary);
    `}
`;
const Timetitle = styled.p<Props>`
  ${Typography.body_md}
  margin: 0px;
  color: var(--text-primary);
  span {
    color: var(--brand-primary);
    white-space: nowrap;
  }
  @media (max-width: 449px) {
    line-height: 20px;
  }
  ${(props: Props) =>
    props.isDisabled &&
    css`
      color: var(--border-primary);
    `}
`;
const Rightblock = styled.div<Props>`
  padding: 0px 3px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  cursor: pointer;
  ${(props: Props) =>
    props.isDisabled &&
    css`
      opacity: 0.8;
      pointer-events: none;
    `}
`;
const Maincontent = styled.div`
  border: 0.5px solid var(--border-primary);
  border-radius: 8px;
  background: var(--background-primary);
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;
const Topicdata = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 12px 16px;
  gap: 120px;
  border-bottom: 0.5px solid var(--border-primary);
  :last-child {
    border: none;
  }
  @media (max-width: 449px) {
    gap: 24px;
    padding: 12px;
  }
`;
const LeftContant = styled.div`
  max-width: 762px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media (max-width: 449px) {
    max-width: 187px;
    gap: 4px;
  }
`;
const RightContant = styled.div`
  display: flex;
  align-items: center;
`;
const Topicdescription = styled.p`
  ${Typography.body_md}
  color: var(--text-secondary);
  margin: 0;
  @media (max-width: 449px) {
    display: -webkit-box;
    max-width: 187px;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: var(--text-secondary);
    text-overflow: ellipsis;
    line-height: 20px;
    word-break: break-all;
  }
`;
const Topictitle = styled.h2`
  ${Typography.body_lg_medium}
  color: var(--text-primary);
  margin: 0;
  @media (max-width: 449px) {
    display: -webkit-box;
    max-width: 187px;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
const Timestop = styled.div`
  margin-right: 8px;
  padding: 0 8px 0 12px;
  border-right: 1px solid var(--border-primary);
  cursor: pointer;
  width: 24px;
  height: 24px;
  .time-stop-icon {
    fill: var(--accent-success);
  }
`;

const Iconborder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px;
  border: 1px solid var(--border-primary);
  border-radius: 2px;
  background: var(--brand-primary);
  .lock-icon {
    fill: var(--brand-primary);
  }
`;

const Trackingcontent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media (max-width: 449px) {
    margin-top: 72px;
  }
`;

const Middleline = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-left: 0.5px solid var(--border-primary);
  @media (max-width: 449px) {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    border-left: 0.5px solid var(--border-primary);
    border-right: 0.5px solid var(--border-primary);

    ::-webkit-scrollbar {
      display: none;
    }
  }
  .box {
    max-width: 138px;
    width: 100%;
    border-right: 0.5px solid var(--border-primary);
    @media (max-width: 449px) {
      :last-child {
        border-right: none;
      }
      max-width: 150px;
    }
  }
`;
const SvgDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    fill: none;
  }
`;
const ResponsiveHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
`;
const ResponsiveNavbarIcon = styled.div`
  display: none;
  @media (max-width: 449px) {
    display: flex;
  }
`;
const Empty = styled.div`
  position: absolute;
  top: 55%;
  left: calc(50% + 110px);
  transform: translate(-50%, -45%);
  @media (max-width: 449px) {
    left: 50%;
  }
`;

const TimelogIcon = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .mytimelog-icon {
    path {
      stroke: var(--text-secondary);
    }
    circle {
      stroke: var(--text-secondary);
    }
  }
  :hover {
    background-color: var(--neutral-secondary);
  }
`;
const RightsideIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
export {
  Headerblock,
  LeftContant,
  RightContant,
  Heading,
  Icon,
  Title,
  Datetitle,
  Datesider,
  Timetitle,
  Leftblock,
  Siderbox,
  Rightblock,
  Timetraking,
  Maincontent,
  Topicdata,
  Topictitle,
  Topicdescription,
  Timestop,
  Iconborder,
  Trackingcontent,
  Middleline,
  Icon1,
  SvgDiv,
  ResponsiveNavbarIcon,
  ResponsiveHeader,
  Empty,
  TimelogIcon,
  RightsideIcon
};
