/* eslint-disable max-len */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import AppLayout from '../../../component/appLayout';
import {
  Heading,
  Container,
  Activity,
  ActivityTitle,
  ItemBlock,
  ItemChange,
  SubTitle,
  ActivityContentView,
  ActivitySubContent,
  ActivityView,
  ActivityContentsView,
  Text1,
  ItemDiv
} from './style';
import {
  Header,
  Rightcontent,
  Monthpicker,
  Text,
  Button,
  Datedropbox,
  Empty,
  Leftcontent,
  DropdownButton,
  DateSelection,
  SvgIconDiv,
  HeaderLeft,
  BackIcon
} from '../styles';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { DateRange } from 'react-date-range';
import Avatar from '../../../component/avatar/Avatar';
import { useSelector, useDispatch } from 'react-redux';
import { SelectionRangeInterface } from '../../../interfaces/TimeReportInterface';
import moment from 'moment';
import { getAllProjectTasks } from '../../../services/taskServices';
import { COMMENT_TYPE, TASK_PRIORITY_LABELS, TIME_DATE_SELECTED_TEXT } from '../../../global/constants';
import { floatToHHMM, isEmpty } from '../../../helpers/common';
import { TeamActivityStatusList } from '../../../global/row';
import { ProjectDetailInterface } from '../../../interfaces/ProjectInterface';
import { getProjectList } from '../../../services/projectServices';
import { setFilteredTeamMemberReportData, setTeamMemberReportData } from '../../../actions/reportActions';
import {
  ActivityListInterface,
  TaskCommentsInterface,
  UserCheckListInterface
} from '../../../interfaces/ReportInterface';
import reportdarkempty from '../../../assets/images/emptystates/reportdarkempty.svg';
import reportempty from '../../../assets/images/emptystates/report-empty.svg';
import EmptyState from '../../../component/emptyState';
import { captureException } from '../../../services/logService';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import ReportTimelineLoading from '../../../component/loading/reporttimelineLoading';
import { Bottomdata, CutstomItemDiv } from '../styles/style';
import ReportDurationDropdown from '../../../component/dropdowns/reportdurationdropdown/reportdurationdropdown';
import { Dropdown, DropdownItem } from '../../../component/Dropdown';
import { useHistory } from 'react-router-dom';

const Team_timeline: React.FC = () => {
  //Refs
  const buttonRef = useRef<HTMLDivElement | null>(null);
  //States
  const [loading, setLoading] = useState(false);
  const [isDateDropdownOpen, setIsDateDropdownOpen] = useState(false);
  const [isTextDropdownOpen, setIsTextDropdownOpen] = useState(false);
  const [isStatusDropdownOpen, setIsStatusDropdownOpen] = useState(false);
  const [isCustomerDropdownOpen, setIsCustomerDropdownOpen] = useState(false);
  const [isProjectDropdownOpen, setIsProjectDropdownOpen] = useState(false);
  const [activityList, setActivityList] = useState<ActivityListInterface[]>([]);
  const [usersList, setUsersList] = useState<UserCheckListInterface[]>([]);
  const [projectList, setProjectList] = useState<ProjectDetailInterface[]>([]);
  const [selectAllUser, setSelectAllUser] = useState(true);
  const [userSearchResult, setUserSearchResult] = useState<UserCheckListInterface[]>([]);
  const [isUserSearch, setIsUserSearch] = useState(false);
  const [searchUser, setSearchUser] = useState('');
  const [selectAllProject, setSelectAllProject] = useState(true);
  const [searchProject, setSearchProject] = useState('');
  const [searchProjectResult, setSearchProjectResult] = useState<ProjectDetailInterface[]>([]);
  const [isProjectSearch, setIsProjectSearch] = useState(false);
  const [selectAllActivity, setSelectAllActivity] = useState(true);
  const [searchActivity, setSearchActivity] = useState('');
  const [searchActivityResult, setSearchActivityResult] = useState<ActivityListInterface[]>([]);
  const [isActivitySearch, setIsActivitySearch] = useState(false);
  const [selectedRange, setSelectedRange] = useState<SelectionRangeInterface>({
    startDate: new Date(moment().format('ddd MMM D YYYY 00:00:00 [GMT+0530]')),
    endDate: new Date(moment().format('ddd MMM D YYYY 00:00:00 [GMT+0530]')),
    key: 'selection'
  });
  const [selectedText, setSelectedText] = useState('Custom');
  //Use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { report: reportSelector, settings: settingsSelector, workspace: workspaceSelector } = stateSelector;
  const { teamMemberData, filterTeamMemberData } = reportSelector;
  const { themeMode } = settingsSelector;
  const { workspace } = workspaceSelector;
  const { id: workspace_id } = workspace;
  // Other variables
  const dispatch = useDispatch();
  const getImageSource = () => (themeMode?.theme === 'dark' ? reportdarkempty : reportempty);
    const history = useHistory();
  

  const applyFilters = useCallback(
    async (
      activity: ActivityListInterface[],
      members: UserCheckListInterface[],
      projects: ProjectDetailInterface[],
      selectedDate: SelectionRangeInterface,
      dataList?: TaskCommentsInterface[]
    ) => {
      // Clone the commentList
      let filteredActivitiesList: TaskCommentsInterface[];
      if (dataList) {
        filteredActivitiesList = JSON.parse(JSON.stringify(dataList));
      } else {
        filteredActivitiesList = JSON.parse(JSON.stringify(teamMemberData));
      }
      const selectedtActivity = activity?.filter((item) => item?.checked);
      const selectedMembers = members?.filter((item) => item?.checked);
      const selectedProject = projects?.filter((item) => item?.checked);

      filteredActivitiesList = filteredActivitiesList.filter((item) => {
        // Activity filter
        const activityFilterResult = selectedtActivity.some((act) => {
          switch (act.key) {
            case 0:
              return act.key === 0 && item.Type === 20;
            case 1:
              return act.key === 1 && item.Type === 10 && item.taskStatus === true;
            case 2:
              return act.key === 2 && item.Type === 10 && item.taskStatus === false;
            case 3:
              return act.key === 3 && item.Type === 2;
            case 4:
              return act.key === 4 && item.Type === 7;
            default:
              return true; // No activity filter
          }
        });

        // Members filter
        const memberIds = selectedMembers.map((member: { id: string }) => member.id);
        const memberFilterResult = memberIds.includes(item.CreatedBy);

        // Project filter
        const projectIds = selectedProject.map((project: { id: string }) => project.id);
        const projectFilterResult = projectIds.includes(item.projectId);

        // Date filter
        const dateFilterResult =
          moment(item?.CreatedTime) >= moment(selectedDate.startDate) &&
          moment(item?.CreatedTime) <= moment(selectedDate.endDate).add(1, 'day');
        // Combine all filters with AND logic
        return activityFilterResult && memberFilterResult && projectFilterResult && dateFilterResult;
      });
      // Return the filtered list
      dispatch(setFilteredTeamMemberReportData(filteredActivitiesList || []));
    },
    [dispatch, teamMemberData]
  );
  const loadData = useCallback(async () => {
    try {
      if (!isEmpty(workspace_id)) {
        setLoading(true);
        const result = await dispatch(getAllProjectTasks());
        const newData = await result?.map((element: any) => {
          const comments = element.comments;
          const updatedComments = comments?.map((item: any) => {
            const newItem = {
              ...item,
              name: element?.name,
              projectName: element?.projectName,
              taskStatus: element?.status,
              projectId: element?.projectId
            };
            return newItem;
          });
          // Add a new comment with task creation time
          let newComment = [
            {
              Type: 20,
              CreatedBy: element?.createdBy,
              CreatedTime: element?.createdOn,
              name: element?.name,
              projectName: element?.projectName,
              taskStatus: element?.status,
              projectId: element?.projectId
            },
            ...updatedComments
          ];
          // Filter out unwanted comment types
          newComment = newComment?.filter((item) => item?.Type !== COMMENT_TYPE.COMMENT);
          return newComment;
        });
        const fullList = newData?.flat();
        const sortedList = fullList?.toSorted(
          (a: any, b: any) => new Date(a.CreatedTime).getTime() - new Date(b.CreatedTime).getTime()
        );
        dispatch(setTeamMemberReportData(sortedList || []));
        const statusList = TeamActivityStatusList?.map((ele: ActivityListInterface) => {
          return { ...ele, checked: true };
        });
        setActivityList(statusList);
        let projectList = await dispatch(getProjectList());
        projectList = projectList?.map((item: ProjectDetailInterface) => {
          return { ...item, checked: true };
        });
        setProjectList(projectList);
        const usersList = workspace?.users?.map((ele) => {
          return { ...ele, checked: true };
        });
        setUsersList(usersList);
        await applyFilters(statusList, usersList, projectList, selectedRange, sortedList);
      }
    } catch (error) {
      captureException(error);
      console.log('Error fetching data:', error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, workspace]);

  useEffect(() => {
    loadData();
    return () => {
      dispatch(setFilteredTeamMemberReportData([]));
      setSearchActivity('');
      setSearchUser('');
      setSearchProject('');
      setIsActivitySearch(false);
      setIsProjectSearch(false);
      setIsUserSearch(false);
      setSearchActivityResult([]);
      setSearchProjectResult([]);
      setUserSearchResult([]);
    };
  }, [dispatch, loadData]);

  const onClickonStatus = useCallback((value: boolean) => {
    setIsStatusDropdownOpen(value);
    setIsCustomerDropdownOpen(false);
    setIsProjectDropdownOpen(false);
    setIsDateDropdownOpen(false);
    setIsTextDropdownOpen(false);
  }, []);

  const onClickonClient = useCallback((value: boolean) => {
    setIsCustomerDropdownOpen(value);
    setIsStatusDropdownOpen(false);
    setIsProjectDropdownOpen(false);
    setIsDateDropdownOpen(false);
    setIsTextDropdownOpen(false);
  }, []);

  const onClickonProject = useCallback((value: boolean) => {
    setIsProjectDropdownOpen(value);
    setIsCustomerDropdownOpen(false);
    setIsStatusDropdownOpen(false);
    setIsDateDropdownOpen(false);
    setIsTextDropdownOpen(false);
  }, []);

  const openDateDropdown = useCallback((value: boolean) => {
    setIsDateDropdownOpen(value);
    setIsStatusDropdownOpen(false);
    setIsProjectDropdownOpen(false);
    setIsCustomerDropdownOpen(false);
    setIsTextDropdownOpen(false);
  }, []);

  const openTextDropdown = useCallback((value: boolean) => {
    setIsTextDropdownOpen(value);
    setIsDateDropdownOpen(false);
    setIsStatusDropdownOpen(false);
    setIsProjectDropdownOpen(false);
    setIsCustomerDropdownOpen(false);
  }, []);

  const searchQueryActivity = useCallback(
    (value: string) => {
      if (!isEmpty(value)) {
        const result =
          activityList?.filter((item: { label: string }) =>
            item?.label?.toLowerCase().includes(value?.toLowerCase())
          ) || [];
        if (result) {
          setSearchActivityResult(result);
          return null;
        }
      } else {
        setSearchActivityResult([]);
        setIsActivitySearch(false);
      }
    },
    [activityList]
  );

  const onChangeSearchActivity = useCallback(
    (value: string) => {
      let timeout;
      if (value) {
        setSearchActivity(value);
        if (!isActivitySearch) setIsActivitySearch(true);
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
          searchQueryActivity(value);
        }, 300);
      } else {
        setSearchActivity('');
        if (isActivitySearch) setIsActivitySearch(false);
        setSearchActivityResult([]);
      }
    },
    [isActivitySearch, searchQueryActivity]
  );

  const onSelectAllActivity = useCallback(
    async (value: boolean) => {
      setSelectAllActivity(value);
      const updatedActivityList = activityList?.map((ele: ActivityListInterface) => {
        return { ...ele, checked: value };
      });
      setActivityList(updatedActivityList);
      applyFilters(updatedActivityList, usersList, projectList, selectedRange);
    },
    [activityList, applyFilters, projectList, selectedRange, usersList]
  );

  const onChangeActivity = useCallback(
    async (item: number, value: boolean) => {
      let updatedStatusList;
      updatedStatusList = activityList?.map((ele) => {
        return ele?.key === item ? { ...ele, checked: value } : ele;
      });
      const isAllStatusSelected = updatedStatusList.every((user) => user.checked);
      setSelectAllActivity(isAllStatusSelected);
      const activities = updatedStatusList?.filter((item) => item?.checked);
      setActivityList(updatedStatusList);
      applyFilters(activities, usersList, projectList, selectedRange);
    },
    [activityList, applyFilters, projectList, selectedRange, usersList]
  );

  const renderActivityData = useMemo(() => {
    return (
      <Bottomdata>
        {isEmpty(searchActivity) && (
          <DropdownItem
            label='Select All'
            isCheckBox={true}
            onClick={() => onSelectAllActivity(!selectAllActivity)}
            isChecked={selectAllActivity}
          />
        )}
        {(isEmpty(searchActivity) ? activityList : searchActivityResult)?.map((item) => {
          return (
            <DropdownItem
              label={item?.label}
              onClick={() => onChangeActivity(item?.key, !item?.checked)}
              isCheckBox={true}
              isChecked={item?.checked}
              key={item?.key}
            />
          );
        })}
      </Bottomdata>
    );
  }, [searchActivity, selectAllActivity, activityList, searchActivityResult, onSelectAllActivity, onChangeActivity]);

  const searchQueryUser = useCallback(
    (value: string) => {
      if (!isEmpty(value)) {
        const result =
          usersList?.filter((item: { name: string }) => item?.name?.toLowerCase().includes(value?.toLowerCase())) || [];
        if (result) {
          setUserSearchResult(result);
          return null;
        }
      } else {
        setUserSearchResult([]);
        setIsUserSearch(false);
      }
    },
    [usersList]
  );

  const onChangeSearchUser = useCallback(
    (value: string) => {
      let timeout;
      if (value) {
        setSearchUser(value);
        if (!isUserSearch) setIsUserSearch(true);
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
          searchQueryUser(value);
        }, 300);
      } else {
        setSearchUser('');
        if (isUserSearch) setIsUserSearch(false);
        setUserSearchResult([]);
      }
    },
    [isUserSearch, searchQueryUser]
  );

  const onChangeUser = useCallback(
    async (item: string, value: boolean) => {
      let updatedUsersList;
      if (value) {
        updatedUsersList = usersList?.map((ele) => {
          return ele?.id === item ? { ...ele, checked: true } : ele;
        });
      } else {
        updatedUsersList = usersList?.map((ele) => {
          return ele?.id === item ? { ...ele, checked: false } : ele;
        });
      }
      const isAllUsersSelected = updatedUsersList.every((user) => user.checked);
      setSelectAllUser(isAllUsersSelected);
      const filterUser = updatedUsersList?.filter((item) => item?.checked);
      setUsersList(updatedUsersList);
      applyFilters(activityList, filterUser, projectList, selectedRange);
    },
    [activityList, applyFilters, projectList, selectedRange, usersList]
  );

  const onChangeSelectAllUser = useCallback(
    async (value: boolean) => {
      setSelectAllUser(value);
      const updatedUsersList = usersList?.map((ele) => {
        return { ...ele, checked: value };
      });
      setUsersList(updatedUsersList);
      applyFilters(activityList, updatedUsersList, projectList, selectedRange);
    },
    [activityList, applyFilters, projectList, selectedRange, usersList]
  );

  const renderCustomerData = useMemo(() => {
    return (
      <Bottomdata>
        {isEmpty(searchUser) && (
          <DropdownItem
            label='Select All'
            isCheckBox={true}
            onClick={() => onChangeSelectAllUser(!selectAllUser)}
            isChecked={selectAllUser}
          />
        )}
        {(isEmpty(searchUser) ? usersList : userSearchResult)?.map(
          (item: { checked: boolean; id: string; name: string }) => {
            return (
              <DropdownItem
                label={item?.name}
                onClick={() => onChangeUser(item?.id, !item?.checked)}
                isCheckBox={true}
                isChecked={item?.checked}
                key={item?.id}
              />
            );
          }
        )}
      </Bottomdata>
    );
  }, [searchUser, selectAllUser, usersList, userSearchResult, onChangeSelectAllUser, onChangeUser]);

  const searchProjectQuery = useCallback(
    (value: string) => {
      if (!isEmpty(value)) {
        const result = projectList?.filter((item) => item?.name?.toLowerCase().includes(value?.toLowerCase())) || [];
        if (result) {
          setSearchProjectResult(result);
          return null;
        }
      } else {
        setSearchProjectResult([]);
        setIsProjectSearch(false);
      }
    },
    [projectList]
  );

  const onChangeSearchProject = useCallback(
    (value: string) => {
      let timeout;
      if (value) {
        setSearchProject(value);
        if (!isProjectSearch) setIsProjectSearch(true);
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
          searchProjectQuery(value);
        }, 300);
      } else {
        setSearchProject('');
        if (isProjectSearch) setIsProjectSearch(false);
        setSearchProjectResult([]);
      }
    },
    [isProjectSearch, searchProjectQuery]
  );

  const onSelectAllProject = useCallback(
    async (value: boolean) => {
      setSelectAllProject(value);
      const updatedProjectsList = projectList?.map((ele) => {
        return { ...ele, checked: value };
      });
      setProjectList(updatedProjectsList);
      applyFilters(activityList, usersList, updatedProjectsList, selectedRange);
    },
    [activityList, applyFilters, projectList, selectedRange, usersList]
  );

  const onChangeProject = useCallback(
    async (item: string, value: boolean) => {
      let updatedUsersList;
      if (value) {
        updatedUsersList = projectList?.map((ele) => {
          return ele?.id === item ? { ...ele, checked: true } : ele;
        });
      } else {
        updatedUsersList = projectList?.map((ele) => {
          return ele?.id === item ? { ...ele, checked: false } : ele;
        });
      }
      const isAllUsersSelected = updatedUsersList.every((user) => user.checked);
      setSelectAllProject(isAllUsersSelected);
      const filterUser = updatedUsersList?.filter((item) => item?.checked);
      setProjectList(updatedUsersList);
      applyFilters(activityList, usersList, filterUser, selectedRange);
    },
    [activityList, applyFilters, projectList, selectedRange, usersList]
  );

  const renderProjectData = useMemo(() => {
    return (
      <Bottomdata isborderredius>
        {isEmpty(searchProject) && (
          <DropdownItem
            label='Select All'
            isCheckBox={true}
            onClick={() => onSelectAllProject(!selectAllProject)}
            isChecked={selectAllProject}
          />
        )}
        {(isEmpty(searchProject) ? projectList : searchProjectResult)?.map((item) => {
          return (
            <DropdownItem
              label={item?.name}
              onClick={(e) => onChangeProject(item?.id, !item?.checked)}
              isCheckBox={true}
              isChecked={item?.checked}
              key={item?.id}
            />
          );
        })}
      </Bottomdata>
    );
  }, [searchProject, selectAllProject, projectList, searchProjectResult, onSelectAllProject, onChangeProject]);

  const handleSelect = useCallback(
    async (ranges: { selection: { startDate: Date; endDate: Date; key: string } }) => {
      setSelectedRange(ranges.selection);
      setSelectedText(TIME_DATE_SELECTED_TEXT.CUSTOM);
      applyFilters(activityList, usersList, projectList, ranges.selection);
    },
    [activityList, applyFilters, projectList, usersList]
  );

  const renderActivity = useCallback(
    (item: TaskCommentsInterface) => {
      const userData = workspace?.users.find((ele) => ele?.id === item?.CreatedBy);
      let assigneeDetails;
      if (item?.Type === COMMENT_TYPE.ADD_ASSIGNEE || item?.Type === COMMENT_TYPE.REMOVE_ASSIGNEE) {
        assigneeDetails = workspace?.users.find((ele) => ele?.id === item?.Assignee);
      }
      switch (item?.Type) {
        case 1:
          return (
            <>
              <Avatar
                imgSrc={userData?.profile_photo || ''}
                name={userData?.name ? userData.name : 'U N'}
                size={30}
                isMediumBorder={true}
              />
              <ActivityContentView>
                <ActivitySubContent>
                  <SubTitle>{userData?.name}</SubTitle>
                  <Text1>
                    set due date to <span>{moment(item?.DueDate).format('DD MMM, YYYY')}</span>{' '}
                    <span>{item?.name}</span> in <span>{item?.projectName}</span>.
                  </Text1>
                </ActivitySubContent>
              </ActivityContentView>
            </>
          );
        case 2:
          return (
            <>
              <Avatar
                imgSrc={userData?.profile_photo || ''}
                name={userData?.name ? userData.name : 'U N'}
                size={30}
                isMediumBorder={true}
              />
              <ActivityContentView>
                <ActivitySubContent>
                  <SubTitle>{userData?.name}</SubTitle>
                  <Text1>
                    change due date from <span>{moment(item?.PreviousDueDate).format('DD MMM, YYYY')}</span> to{' '}
                    <span>{moment(item?.DueDate).format('DD MMM, YYYY')}</span> <span>{item?.name}</span> in{' '}
                    <span>{item?.projectName}</span>.
                  </Text1>
                </ActivitySubContent>
              </ActivityContentView>
            </>
          );
        case 3:
          return (
            <>
              <Avatar
                imgSrc={userData?.profile_photo || ''}
                name={userData?.name ? userData.name : 'U N'}
                size={30}
                isMediumBorder={true}
              />
              <ActivityContentView>
                <ActivitySubContent>
                  <SubTitle>{userData?.name}</SubTitle>
                  <Text1>
                    assign task to <span>{assigneeDetails?.name}</span> <span>{item?.name}</span> in{' '}
                    <span>{item?.projectName}</span>.
                  </Text1>
                </ActivitySubContent>
              </ActivityContentView>
            </>
          );
        case 4:
          return (
            <>
              <Avatar
                imgSrc={userData?.profile_photo || ''}
                name={userData?.name ? userData.name : 'U N'}
                size={30}
                isMediumBorder={true}
              />
              <ActivityContentView>
                <ActivitySubContent>
                  <SubTitle>{userData?.name}</SubTitle>
                  <Text1>
                    unassign task to <span>{assigneeDetails?.name}</span> <span>{item?.name}</span> in{' '}
                    <span>{item?.projectName}</span>.
                  </Text1>
                </ActivitySubContent>
              </ActivityContentView>
            </>
          );
        case 5:
          return (
            <>
              <Avatar
                imgSrc={userData?.profile_photo || ''}
                name={userData?.name ? userData.name : 'U N'}
                size={30}
                isMediumBorder={true}
              />
              <ActivityContentView>
                <ActivitySubContent>
                  <SubTitle>{userData?.name}</SubTitle>
                  <Text1>
                    remove assignee <span>{item?.name}</span> in <span>{item?.projectName}</span>.
                  </Text1>
                </ActivitySubContent>
              </ActivityContentView>
            </>
          );
        case 6:
          return (
            <>
              <Avatar
                imgSrc={userData?.profile_photo || ''}
                name={userData?.name ? userData.name : 'U N'}
                size={30}
                isMediumBorder={true}
              />
              <ActivityContentView>
                <ActivitySubContent>
                  <SubTitle>{userData?.name}</SubTitle>
                  <Text1>
                    change priority from <span>{TASK_PRIORITY_LABELS[Number(item?.PreviousPriority)]}</span> to{' '}
                    <span>{TASK_PRIORITY_LABELS[Number(item?.Priority)]}</span> <span>{item?.name}</span> in{' '}
                    <span>{item?.projectName}</span>.
                  </Text1>
                </ActivitySubContent>
              </ActivityContentView>
            </>
          );
        case 7:
          return (
            <>
              <Avatar
                imgSrc={userData?.profile_photo || ''}
                name={userData?.name ? userData.name : 'U N'}
                size={30}
                isMediumBorder={true}
              />
              <ActivityContentView>
                <ActivitySubContent>
                  <SubTitle>{userData?.name}</SubTitle>
                  <Text1>
                    change group from <span>{item?.PreviousGroup}</span> to <span>{item?.Group}</span>{' '}
                    <span>{item?.name}</span> in <span>{item?.projectName}</span>.
                  </Text1>
                </ActivitySubContent>
              </ActivityContentView>
            </>
          );
        case 8:
          return (
            <>
              <Avatar
                imgSrc={userData?.profile_photo || ''}
                name={userData?.name ? userData.name : 'U N'}
                size={30}
                isMediumBorder={true}
              />
              <ActivityContentView>
                <ActivitySubContent>
                  <SubTitle>{userData?.name}</SubTitle>
                  <Text1>
                    updated task <span>{item?.name}</span> in <span>{item?.projectName}</span>.
                  </Text1>
                </ActivitySubContent>
              </ActivityContentView>
            </>
          );
        case 9:
          return (
            <>
              <Avatar
                imgSrc={userData?.profile_photo || ''}
                name={userData?.name ? userData.name : 'U N'}
                size={30}
                isMediumBorder={true}
              />
              <ActivityContentView>
                <ActivitySubContent>
                  <SubTitle>{userData?.name}</SubTitle>
                  <Text1>
                    remove due date <span>{item?.name}</span> in <span>{item?.projectName}</span>.
                  </Text1>
                </ActivitySubContent>
              </ActivityContentView>
            </>
          );
        case 10:
          return (
            <>
              <Avatar
                imgSrc={userData?.profile_photo || ''}
                name={userData?.name ? userData.name : 'U N'}
                size={30}
                isMediumBorder={true}
              />
              <ActivityContentView>
                <ActivitySubContent>
                  <SubTitle>{userData?.name}</SubTitle>
                  <Text1>
                    {item?.taskStatus ? 'completed the task' : 're‑opened the task'} <span>{item?.name}</span> in{' '}
                    <span>{item?.projectName}</span>.
                  </Text1>
                </ActivitySubContent>
              </ActivityContentView>
            </>
          );
        case 11:
          return (
            <>
              <Avatar
                imgSrc={userData?.profile_photo || ''}
                name={userData?.name ? userData.name : 'U N'}
                size={30}
                isMediumBorder={true}
              />
              <ActivityContentView>
                <ActivitySubContent>
                  <SubTitle>{userData?.name}</SubTitle>
                  <Text1>
                    set estimate time to{' '}
                    <span>
                      {floatToHHMM(item?.estimateTime)} hours <span>{item?.name}</span> in{' '}
                      <span>{item?.projectName}</span>.
                    </span>
                  </Text1>
                </ActivitySubContent>
              </ActivityContentView>
            </>
          );
        case 13:
          return (
            <>
              <Avatar
                imgSrc={userData?.profile_photo || ''}
                name={userData?.name ? userData.name : 'U N'}
                size={30}
                isMediumBorder={true}
              />
              <ActivityContentView>
                <ActivitySubContent>
                  <SubTitle>{userData?.name}</SubTitle>
                  <Text1>
                    add the task in milestone <span>{item?.Group}</span> <span>{item?.name}</span> in{' '}
                    <span>{item?.projectName}</span>
                  </Text1>
                </ActivitySubContent>
              </ActivityContentView>
            </>
          );
        case 20:
          return (
            <>
              <Avatar
                imgSrc={userData?.profile_photo || ''}
                name={userData?.name ? userData.name : 'U N'}
                size={30}
                isMediumBorder={true}
              />
              <ActivityContentView>
                <ActivitySubContent>
                  <SubTitle>{userData?.name}</SubTitle>
                  <Text1>
                    created the task <span>{item?.name}</span> in <span>{item?.projectName}</span>
                  </Text1>
                </ActivitySubContent>
              </ActivityContentView>
            </>
          );
        default:
          return;
      }
    },
    [workspace?.users]
  );

  const groupDataByDate = (data: TaskCommentsInterface[]) => {
    const groupedData: any = {};
    data.forEach((comment) => {
      const date = moment(comment?.CreatedTime).format('DD MMM, YYYY');
      if (!groupedData[date]) {
        groupedData[date] = [];
      }
      groupedData[date].push(comment);
    });
    return groupedData;
  };
  const groupedData = groupDataByDate(filterTeamMemberData);
  return (
    <>
      <AppLayout>
                <Header>
                  {/* back Icon for responsive ui  */}
                  <HeaderLeft>
                    <BackIcon onClick={() => history.goBack()}>
                      <SVGIcon name='report-left-arrow-icon' width='24' height='24' viewBox='24' />
                    </BackIcon>
                    Team Timeline
                  </HeaderLeft>
                </Header>
        <Container>
          <Heading>
            <Leftcontent>
              <Dropdown
                isMinWidth={152}
                onChangeSearch={onChangeSearchActivity}
                content={
                  <DropdownButton>
                    <Button onClick={() => onClickonStatus(!isStatusDropdownOpen)}>
                      <p>Activity</p>
                      <SVGIcon
                        name='dropdown-icon'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        className={`dropdown-icon stroke-color ${isStatusDropdownOpen ? 'open' : ''}`}
                      />
                    </Button>
                  </DropdownButton>
                }
                trigger='click'
                onOutsideClick={() => setIsStatusDropdownOpen(false)}>
                <ItemDiv>{renderActivityData}</ItemDiv>
              </Dropdown>
              <Dropdown
                isMinWidth={152}
                onChangeSearch={onChangeSearchUser}
                content={
                  <DropdownButton>
                    <Button onClick={() => onClickonClient(!isCustomerDropdownOpen)}>
                      <p>Member</p>
                      <SVGIcon
                        name='dropdown-icon'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        className={`dropdown-icon stroke-color ${isCustomerDropdownOpen ? 'open' : ''}`}
                      />
                    </Button>
                  </DropdownButton>
                }
                trigger='click'
                onOutsideClick={() => setIsCustomerDropdownOpen(false)}>
                <ItemDiv>{renderCustomerData}</ItemDiv>
              </Dropdown>
              <Dropdown
                isMinWidth={152}
                onChangeSearch={onChangeSearchProject}
                content={
                  <DropdownButton>
                    <Button onClick={() => onClickonProject(!isProjectDropdownOpen)}>
                      <p>Project</p>
                      <SVGIcon
                        name='dropdown-icon'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        className={`dropdown-icon stroke-color ${isProjectDropdownOpen ? 'open' : ''}`}
                      />
                    </Button>
                  </DropdownButton>
                }
                trigger='click'
                onOutsideClick={() => setIsProjectDropdownOpen(false)}>
                <ItemDiv>{renderProjectData}</ItemDiv>
              </Dropdown>
            </Leftcontent>
            <Rightcontent>
              <Dropdown
                isMinWidth={152}
                content={
                  <DropdownButton ref={buttonRef} onClick={() => openTextDropdown(!isTextDropdownOpen)}>
                    <Button iscustom>
                      <p>{selectedText}</p>
                      <SVGIcon
                        name='dropdown-icon'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        className={`dropdown-icon ${isTextDropdownOpen ? 'open' : ''} stroke-color`}
                      />
                    </Button>
                  </DropdownButton>
                }
                trigger='click'
                onOutsideClick={() => setIsTextDropdownOpen(false)}>
                <CutstomItemDiv>
                  <ReportDurationDropdown
                    buttonref={buttonRef}
                    setSelectionRange={(value) => setSelectedRange(value)}
                    setIsStatusDropdownOpen={(value) => setIsStatusDropdownOpen(value)}
                    setDateSelectedText={(value) => setSelectedText(value)}
                  />
                </CutstomItemDiv>
              </Dropdown>

              <DropdownButton isMonthPicker>
                <div onClick={() => openDateDropdown(!isDateDropdownOpen)}>
                  <Monthpicker>
                    <DateSelection>
                      <SvgIconDiv>
                        <SVGIcon
                          name='calendar-icon'
                          width='18'
                          height='18'
                          viewBox='0 0 18 18'
                          className='fill-color'
                        />
                      </SvgIconDiv>
                      <Text>{`${moment(selectedRange?.startDate).format('MMM DD')} - ${moment(
                        selectedRange?.endDate
                      ).format('MMM DD')}`}</Text>
                    </DateSelection>
                    <SVGIcon
                      name='dropdown-icon'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      className={isDateDropdownOpen ? 'dropdown-icon arrows' : 'arrows'}
                    />
                  </Monthpicker>
                </div>
                {isDateDropdownOpen && (
                  <div className='dropdown-content'>
                    <Datedropbox>
                      <DateRange
                        editableDateInputs={true}
                        onChange={handleSelect}
                        moveRangeOnFirstSelection={false}
                        ranges={[
                          {
                            startDate: new Date(selectedRange?.startDate),
                            endDate: new Date(selectedRange?.endDate),
                            key: selectedRange?.key
                          }
                        ]}
                        maxDate={new Date()}
                      />
                    </Datedropbox>
                  </div>
                )}
              </DropdownButton>
            </Rightcontent>
          </Heading>
          <Activity>
            {!loading &&
              filterTeamMemberData?.length > 0 &&
              Object.entries(groupedData).map(([date, comments]: any) => (
                <div key={date}>
                  <ActivityTitle>{date}</ActivityTitle>
                  <ActivityView>
                    {comments.map((comment: TaskCommentsInterface) => (
                      <ItemBlock key={comment['_id']}>
                        <ItemChange>{renderActivity(comment)}</ItemChange>
                        <ActivityContentsView />
                      </ItemBlock>
                    ))}
                  </ActivityView>
                </div>
              ))}
            {loading && <ReportTimelineLoading />}
            {!loading && filterTeamMemberData?.length === 0 && (
              <Empty>
                <EmptyState
                  header={'There is no report data.'}
                  title={'There are no report data for this date. Please try another Filters.'}
                  image={getImageSource()}
                  name={''}
                  hideButton={true}
                />
              </Empty>
            )}
          </Activity>
        </Container>
      </AppLayout>
    </>
  );
};

export default Team_timeline;
