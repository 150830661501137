import React, { useCallback, useRef, useState } from 'react';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import {
  Draggable,
  Droppable,
  DragDropContext,
  DropResult,
  DraggableProvided,
  DraggableStateSnapshot
} from 'react-beautiful-dnd';
import { Addg, Status, Statusrows, Iconhidden, Line, Statusrow1 } from './styles';
import Deletemodal from '../../models/deleteModel';
import { TaskGroupInterface } from '../../../interfaces/TaskInterface';
import { ProjectTemplateDetailsInterface, ProjectTemplateGroupInterface } from '../../../interfaces/SettingsInterface';
import { ProjectDetailInterface } from '../../../interfaces/ProjectInterface';
import { Autotext, Box, Leftrow, Row, Subtitle, Title } from '../style';
import { Icon2 } from '../priorities/styles';
import Button from '../../button';
import { MENU_OPTIONS } from '../../../global/constants';
import CustomDropdown from '../../dropdowns/customDropdown';
import ModalCustom from '../../models/modal';

// eslint-disable-next-line @typescript-eslint/no-redeclare
declare type DraggableProvided = typeof import('react-beautiful-dnd');
// eslint-disable-next-line @typescript-eslint/no-redeclare
declare type DraggableStateSnapshot = typeof import('react-beautiful-dnd');

// eslint-disable-next-line @typescript-eslint/no-redeclare
declare type DropResult = typeof import('react-beautiful-dnd');
interface Props {
  themeMode: { theme: string };
  handleDragEnd: (result: DropResult) => void;
  taskGroups: TaskGroupInterface[] | ProjectTemplateGroupInterface[];
  onClickEditGroup: (group: TaskGroupInterface | ProjectTemplateGroupInterface) => void;
  deleteGroup: (group: TaskGroupInterface | ProjectTemplateGroupInterface) => void;
  loading: boolean;
  setModalOpen: (value: boolean) => void;
  currentProject: ProjectDetailInterface | ProjectTemplateDetailsInterface;
  onUpdateGroup: (value: boolean) => void;
  loadingButton: boolean;
}

const GroupSettingCard: React.FC<Props> = (props) => {
  const {
    handleDragEnd,
    taskGroups,
    onClickEditGroup,
    deleteGroup,
    loading,
    setModalOpen,
    currentProject,
    onUpdateGroup,
    loadingButton
  } = props;
  //Refs
  const dropdownRef = useRef<React.RefObject<HTMLDivElement>[]>([]);
  //States
  const [deleteModelopen, setDeleteModalOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<TaskGroupInterface | ProjectTemplateGroupInterface>();
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [openDropdownId, setOpenDropdownId] = useState<string | null>(null);
  //Other variable
  const backgroundColor = 'var(--background-primary)';
  const borderColor = '0.5px solid var(--border-primary)';
  const getItemStyle = (isDragging: boolean, draggableStyle: React.CSSProperties) => ({
    userSelect: 'none',
    background: isDragging ? backgroundColor : 'none',
    borderRadius: isDragging ? 40 : 0,
    border: isDragging ? borderColor : 'none',
    boxShadow: isDragging ? 'var(--shadow-card-hover)' : 'none',
    ...draggableStyle
  });
  const menuItems = [
    {
      key: MENU_OPTIONS.EDIT,
      label: 'Edit'
    },
    {
      key: MENU_OPTIONS.DELETE,
      label: 'Delete',
      className: 'red-text'
    }
  ];

  const Opendeletemodel = (group: TaskGroupInterface | ProjectTemplateGroupInterface) => {
    setSelectedGroup(group);
    setDeleteModalOpen(true);
  };

  const onClose = () => {
    setDeleteModalOpen(false);
  };

  const onDeleteGroup = useCallback(() => {
    if (selectedGroup) deleteGroup(selectedGroup);
    setDeleteModalOpen(false);
  }, [deleteGroup, selectedGroup]);

  const onClickAddGroup = useCallback(() => {
    setModalOpen(true);
  }, [setModalOpen]);

  const toggleDropdown = useCallback(
    (e: React.SyntheticEvent, item: TaskGroupInterface | ProjectTemplateGroupInterface) => {
      e.stopPropagation();
      if (openDropdownId === item?.id) {
        setIsOpenDropdown(false);
        setOpenDropdownId(null);
      } else {
        setIsOpenDropdown(true);
        setOpenDropdownId(item?.id);
      }
    },
    [openDropdownId]
  );

  const handleClickMenu = useCallback(
    (key: string | number, group: TaskGroupInterface | ProjectTemplateGroupInterface) => {
      key = Number(key);
      if (key === MENU_OPTIONS.EDIT) {
        onClickEditGroup(group);
      } else if (key === MENU_OPTIONS.DELETE) {
        Opendeletemodel(group);
      }
    },
    [onClickEditGroup]
  );
  return (
    <>
      <Box>
        <Row>
          <Leftrow>
            <Title>Group</Title>
            <Subtitle>Groups define task progression and can be customized and reordered</Subtitle>
          </Leftrow>
          <Autotext>
            {loadingButton ? (
              <Button isLoading={loadingButton} />
            ) : (
              <label className='switch'>
                <input
                  type='checkbox'
                  checked={currentProject?.isGroupEnabled}
                  onChange={() => onUpdateGroup(!currentProject?.isGroupEnabled)}
                />
                <span className='slider round'></span>
              </label>
            )}
          </Autotext>
        </Row>
        {currentProject?.isGroupEnabled && (
          <Status>
            <DragDropContext onDragEnd={(result: DropResult) => handleDragEnd(result)}>
              <Droppable droppableId='group-names'>
                {(provided: DraggableProvided) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {taskGroups?.map((group, index: number) => {
                      // Ensure the `dropdownRef` is initialized for each group
                      if (!dropdownRef?.current[index]) {
                        dropdownRef.current[index] = React.createRef<HTMLDivElement>();
                      }

                      return (
                        <Draggable key={group?.id} draggableId={group?.id} index={index}>
                          {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                            <Line>
                              <Iconhidden
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                                style={{ display: snapshot.isDragging ? 'none' : 'block' }}>
                                <SVGIcon
                                  className='svgicon'
                                  name='three-dots-vertical-icon'
                                  width='16'
                                  height='16'
                                  viewBox='0 0 16 16'
                                  fill='none'
                                />
                              </Iconhidden>
                              <Statusrows
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                                <Title>{group?.name}</Title>
                                <Icon2 ref={dropdownRef?.current[index]} onClick={(e) => toggleDropdown(e, group)}>
                                  <SVGIcon
                                    name='loading-more-icon'
                                    width='18'
                                    height='18'
                                    viewBox='0 0 18 18'
                                    className='moreicon'
                                  />
                                  {isOpenDropdown && openDropdownId === group?.id && (
                                    <CustomDropdown
                                      menuItems={menuItems}
                                      width={120}
                                      isOpen={isOpenDropdown}
                                      setIsOpen={setIsOpenDropdown}
                                      buttonref={dropdownRef?.current[index]}
                                      handleClick={(key) => handleClickMenu(key, group)}
                                      isCalendarviewCard={true}
                                      setOpenDropdownId={setOpenDropdownId}
                                    />
                                  )}
                                </Icon2>
                              </Statusrows>
                            </Line>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided?.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <Statusrow1 onClick={onClickAddGroup}>
              <Addg>Add Group</Addg>
            </Statusrow1>
          </Status>
        )}
      </Box>
      <ModalCustom open={deleteModelopen} onClose={onClose} width={334}>
        <Deletemodal
          onClose={onClose}
          onOk={() => onDeleteGroup()}
          loading={loading}
          modaltitle='Delete Group?'
          description='Are you sure you want to delete this group?'
        />
      </ModalCustom>
    </>
  );
};

export default GroupSettingCard;
