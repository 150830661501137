import styled from 'styled-components';
import { Typography } from '../../global/theme';

const Main = styled.div``;

const Snapheader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 0 24px;
  position: sticky;
  top: 0;
  z-index: 1111;
  margin-right: -2px;
  background: var(--background-secondary);
  .ant-dropdown {
    width: 100%;
    max-width: 260px;
  }
  @media (max-width: 449px) {
    padding: 12px 0;
  }
`;

const Leftside = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

const Rightside = styled.div``;

const Icon = styled.div`
  width: 28px;
  height: 28px;
`;

const Heading = styled.div`
  ${Typography.heading_lg}
  color: var(--text-primary);
`;
const Snaps = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(174px, 1fr));
  grid-template-rows: auto;
  grid-row-gap: 23px;
  grid-column-gap: 23px;
  margin-bottom: 24px;
  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(154px, 1fr));
  }
`;
const Emptyblock = styled.div`
  position: relative;
  height: calc(100vh - 70px);
  display: flex;
  justify-content: center;
`;
const Empty = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ResponsivenavbarIcon = styled.div`
  display: none;
  @media (max-width: 449px) {
    display: flex;
  }
`;

const HeaderTitle = styled.div`
  @media (max-width: 449px) {
    display: flex;
    align-items: center;
    gap: 24px;
  }
`;
export {
  Main,
  Snapheader,
  Leftside,
  Rightside,
  Icon,
  Heading,
  Snaps,
  Emptyblock,
  Empty,
  ResponsivenavbarIcon,
  HeaderTitle
};
