import styled, { css } from 'styled-components';
import { Typography } from '../../../global/theme';
interface Props {
  backgroundColor?: boolean;
  Display?: boolean;
  isHover?: boolean;
}
const Group = styled.div`
  width: 100%;
  max-width: 662px;
  margin: 0 auto;
  margin-bottom: 24px;
  .ant-modal .ant-modal-content {
    padding: 0;
  }
`;
const Personaltext = styled.div`
  ${Typography.heading_lg}
  color: var(--text-primary);
`;
const Box = styled.div<{ hide?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  ${(props: { hide?: boolean }) =>
    props.hide &&
    css`
      display: none;
    `}
`;
const Top = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;
const Bottom = styled.div`
  border: 0.5px solid var(--border-primary);
  border-radius: 8px;
  background-color: var(--background-primary);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const Title = styled.h4`
  margin: 0;
  ${Typography.heading_md}
  color: var(--text-primary);
`;
const Desc = styled.p`
  margin: 0;
  ${Typography.body_lg_medium}
  color: var(--text-primary);
`;
const Card = styled.div`
  display: flex;
  gap: 20px;
  align-items: flex-start;
  @media (max-width: 449px) {
    flex-wrap: wrap;
  }
`;
const Square = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: center;
  p {
    margin: 0;
    ${Typography.body_lg_medium};
    color: var(--text-primary);
  }
`;
const Logo = styled.div<Props>`
  width: 64px;
  height: 64px;
  border: 0.5px solid var(--border-primary);
  border-radius: 6px;
  padding: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  .ant-color-picker-trigger {
    background: none;
  }
  .ant-color-picker-trigger.ant-color-picker-trigger-active {
    box-shadow: none;
  }
  .favicon-logo {
    height: 64px;
    width: auto;
    border-radius: 4px;
  }
  &.logo-img {
    width: max-content;
    padding: 7px;
    height: 64px;
    .logo {
      height: 64px;
      width: auto;
      border-radius: 4px;
    }
  }
  ${(props: Props) =>
    props.isHover &&
    css`
      &:hover {
        border: 0.5px solid transparent;
        .favicon-logo {
          filter: blur(1.5px);
        }
        .empty-logo {
          filter: blur(1.5px);
        }
        .logo {
          filter: blur(1.5px);
        }
        .logo-empty {
          filter: blur(1.5px);
        }
        .upload-block {
          display: flex;
          cursor: pointer;
        }
      }
    `}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const Domaincontent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const Topcontent = styled.div`
  border: 0.5px solid var(--border-primary);
  border-radius: 6px;
  display: flex;
  background-color: var(--background-primary);
  width: 100%;
`;
const Bottomcontent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Left = styled.div`
  padding: 8px 15px 8px 12px;
  border-right: 0.5px solid var(--border-primary);
  ${Typography.body_lg_medium}
  color: var(--text-secondary);
`;

const Input = styled.input<Props>`
  padding: 8px 10px;
  ${Typography.body_lg_medium}
  color: var(--text-secondary);
  background-color: var(--background-primary);
  border: none;
  width: 100%;
  border-radius: 0 8px 8px 0;
  :focus {
    outline: none;
  }
  ${(props: Props) =>
    props.backgroundColor &&
    css`
      background: var(--background-tertiary);
    `}
`;
const Buttons = styled.button`
  background-color: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  padding: 5px 12px;
  border-radius: 36px;
  display: flex;
  gap: 5px;
  cursor: pointer;
  &:hover {
    background-color: var(--background-secondary);
  }
  p {
    color: var(--text-primary);
    ${Typography.body_sm}
    margin: 0;
  }
  .fill-color {
    path {
      stroke: var(--text-primary);
    }
    circle {
      stroke: var(--text-primary);
    }
  }
  &.button {
    margin-top: 8px;
  }
`;
const Empty = styled.div`
  height: 38px;
  &.logo-empty {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  h4 {
    margin: 0;
    ${Typography.heading_md}
    color: var(--text-placeholder);
  }
`;
const Description = styled.div`
  padding-top: 4px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  div {
    display: flex;
  }
`;

const Image = styled.img`
  width: 18px;
  height: 18px;
  max-width: 100%;
  max-height: 18px;
  cursor: pointer;
  margin: 3px;
`;

const Icon1 = styled.div`
  height: 24px;
  margin-right: 9px;
  .copy-icon {
    padding: 3px;
    cursor: pointer;
    stroke: var(--text-secondary);
    path {
      stroke: var(--text-secondary);
    }
  }
`;

const Detail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;
const Heading = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  color: var(--text-primary);
`;
const Contents = styled.p`
  margin: 0;
  ${Typography.body_md}
  color: var(--text-secondary);
`;
const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  border-style: hidden;
  border-radius: 8px;
  box-shadow: 0 0 0 1px var(--border-primary);
  background: var(--background-primary);
  thead {
    tr {
      border-top: none;
      background: var(--background-tertiary);
      border-radius: 8px 8px 0 0;
    }
  }
  tr {
    border-top: 0.5px solid var(--border-primary);
    display: flex;
    align-items: center;
  }
  th {
    margin: 0;
    ${Typography.body_lg_medium}
    color: var(--text-primary);
    text-align: left;
  }
  th.first-column {
    max-width: 60px;
    padding: 12px 10px 12px 12px;
    width: 100%;
  }
  th.second-column {
    max-width: 170px;
    padding: 12px 10px;
    width: 100%;
  }
  th.third-column {
    max-width: 328px;
    padding: 12px 12px 12px 10px;
    width: 100%;
  }
  td {
    margin: 0;
    ${Typography.body_md}
    color: var(--text-secondary);
    text-align: left;
  }
  .type-column {
    text-transform: uppercase;
    max-width: 60px;
    padding: 12px 10px 12px 12px;
    width: 100%;
  }
  .name-column {
    max-width: 170px;
    padding: 12px 10px;
    width: 100%;
  }
  .value-column {
    max-width: 328px;
    padding: 12px 12px 12px 10px;
    width: 100%;
  }
`;
const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  .delete-icon {
    fill: var(--text-secondary);
  }
  :hover {
    background-color: var(--neutral-secondary);
    border-radius: 4px;
  }
`;
const Deleteicon = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const InputDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TitleText = styled.div`
  ${Typography.body_md_medium}
  color: var(--text-secondary);
`;

const MetaInput = styled.input`
  padding: 7px 12px;
  border: 0.5px solid var(--border-primary);
  border-radius: 6px;
  ${Typography.body_lg}
  color: var(--text-primary);
  background: var(--background-primary);
  outline: none;
  ::placeholder {
    color: var(--text-secondary);
  }
  :focus {
    border: 0.5px solid var(--brand-primary);
  }
`;

const TitleDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0 12px;
`;

const Tag = styled.div`
  ${Typography.body_sm}
  color: var(--text-secondary);
  background-color: var(--neutral-secondary);
  padding: 2px 5px;
  border-radius: 6px;
  border: 0.5px solid transparent;
`;

const BoxCommingSoon = styled.div`
  border: 0.5px solid var(--border-primary);
  background-color: var(--background-primary);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  padding: 37px 0;
`;
const Overlay = styled.div`
  background-color: var(--background-thumb-overlay);
  border: 0.5px solid transparent;
  top: 0;
  left: 0;
  width: 100%;
  height: 78px;
  position: absolute;
  border-radius: 6px;
  display: none;
  justify-content: center;
  cursor: pointer;
`;
const Textblock = styled.div<Props>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  p {
    ${Typography.body_sm_medium}
    margin: 0;
    color: var(--text-white);
    text-align: center;
  }
  .upload-icon {
    width: 18px;
    height: 18px;
    path {
      stroke: var(--text-white);
    }
  }
  ${(props: Props) =>
    props.Display &&
    css`
      flex-direction: row;
      flex-wrap: wrap;
      margin: auto;
    `}
`;
const Mode = styled.div`
  color: var(--text-secondary);
  ${Typography.body_sm};
`;
const Bottomdiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export {
  TitleDiv,
  MetaInput,
  InputDiv,
  TitleText,
  Group,
  Personaltext,
  Box,
  Top,
  Bottom,
  Title,
  Desc,
  Card,
  Square,
  Logo,
  Content,
  Domaincontent,
  Topcontent,
  Bottomcontent,
  Left,
  Input,
  Buttons,
  Empty,
  Description,
  Detail,
  Heading,
  Contents,
  Table,
  Icon,
  Deleteicon,
  Icon1,
  Image,
  Tag,
  BoxCommingSoon,
  Overlay,
  Textblock,
  Mode,
  Bottomdiv
};
