import styled from 'styled-components';
import { Typography } from '../../../global/theme';

const Default = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 3px 5px;
  ${Typography.body_xs}
  color: var(--text-primary);
  background: var(--background-muted);
  border-radius: 3px;
`;

const Rightrow1 = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  .svgicon {
    fill: var(--text-secondary);
  }
  .no-icon {
    fill: var(--text-placeholder);
  }
`;

const Status = styled.div`
  max-width: 453px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  border-top: 0.5px solid var(--border-primary);
  padding-top: 12px;
`;

const Statusrow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 8px;
  width: -webkit-fill-available;
  justify-content: space-between;
  gap: 10px;
`;

const Icon2 = styled.div`
  display: inline-flex;
  width: 18px;
  height: 18px;
  cursor: pointer;
  position: relative;
  svg {
    cursor: pointer;
    fill: var(--text-secondary);
    stroke: var(--text-secondary);
    border-radius: 4px;
    :hover {
      background-color: var(--background-muted);
    }
  }
`;

export { Default, Rightrow1, Statusrow, Status, Icon2 };
