import { AnimatedArc, SpinnerContainer, WorkSpaceLoadingWrapper } from './styles';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { useSelector } from 'react-redux';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';

export default function WorkSpaceLoading() {
  const settingsSelector = useSelector((state: RootReducerInterface) => state.settings);
  const { themeMode } = settingsSelector;
  return (
    <WorkSpaceLoadingWrapper>
      <SpinnerContainer>
        <AnimatedArc className={themeMode?.theme === 'dark' ? 'dark-border' : ''} />
        <SVGIcon name='workspace-loading-logo' width='36' height='36' viewBox='0 0 36 36' />
      </SpinnerContainer>
      <p>Setting up your workspace...</p>
    </WorkSpaceLoadingWrapper>
  );
}
