import React, { useCallback } from 'react';
import { Bottom, Div, First, Form, Formbutton, Inputvalue, Label, Left, Main, Right, Top } from './styles';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import Button from '../../button';

interface Props {
  onCancel: () => void;
}
const Addaccountmodal: React.FC<Props> = (props) => {
  const { onCancel } = props;

  const onClickCancel = useCallback(() => {
    onCancel();
  }, [onCancel]);

  return (
    <Main>
      <Top>
        <Left>Add Account</Left>
        <Right onClick={onClickCancel}>
          <SVGIcon name='close-icon' width='18' height='18' viewBox='0 0 18 18' className='close-icon' />
        </Right>
      </Top>
      <Bottom>
        <First>
          <Form>
            <Label>Name Of Your Sender</Label>
            <Inputvalue type='text' placeholder='Enter your sender name ' />
          </Form>
          <Form>
            <Label>Sender Email Full Name</Label>
            <Inputvalue type='text' placeholder='Enter sender email full name ' />
          </Form>
        </First>
        <Form>
          <Label>Sender Email</Label>
          <Inputvalue type='email' placeholder='contact@email.com' />
        </Form>
        <First>
          <Form>
            <Label>Label</Label>
            <Inputvalue type='text' placeholder='contact@email.com ' />
          </Form>
          <Form>
            <Label>SMTP Password</Label>
            <Inputvalue type='text' placeholder='**** ' />
          </Form>
        </First>
        <Form>
          <Label>SMTP Host</Label>
          <Div>
            <Inputvalue type='text' placeholder='smtp.domain.com ' className='smtp-input-field' />
            <Inputvalue type='text' placeholder='port no. ' className='port-input-field' />
          </Div>
        </Form>
        <Formbutton>
          <Button title='Test Email' secondary={true} type='button' hasNotBoxshadow modelbtn={true} />
          <Button title='Add Account' type='submit' modelbtn={true} />
        </Formbutton>
      </Bottom>
    </Main>
  );
};

export default Addaccountmodal;
