import React, { useCallback, useState } from 'react';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import {
  Client,
  Customer,
  DropTitle,
  Formbutton,
  IconDiv,
  Invoisetype,
  ItemDiv,
  ProfileDropdown,
  SelectDiv
} from './styles';
import { ConfigProvider, Radio, Space } from 'antd';
import Button from '../../button';
import { CREATE_INVOICE_TYPE } from '../../../global/constants';
import { CustomerDetailsInterface } from '../../../interfaces/CustomerInterface';
import { useSelector, useDispatch } from 'react-redux';
import { getProjectHours } from '../../../services/invoiceService';
import { ROUTES } from '../../../routes/urls';
import { useHistory } from 'react-router-dom';
import ModalCustom from '../modal';
import CustomerModal from '../customerModal/customerModal';
import { captureException } from '../../../services/logService';
import { isEmpty } from '../../../helpers/common';
import { setErrorMessage } from '../../../actions/messageActions';
import { trackAnalyticActivity } from '../../../services/analyticsService';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import { INVOICE_ANALYTICS } from '../../../global/analyticsConstants';
import { Dropdown, DropdownItem } from '../../Dropdown';

export default function Invoicemodal({
  closemodel,
  isCustomerDetail,
  customerDetail,
  CustomerInvoiceModal
}: {
  closemodel: () => void;
  isCustomerDetail?: boolean;
  customerDetail?: { id: string; companyName: string };
  CustomerInvoiceModal?: boolean;
}) {
  //States
  const [customer, setCustomer] = React.useState({
    id: customerDetail?.id || '',
    companyName: customerDetail?.companyName || undefined
  });
  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState('');
  const [nextLoading, setNextLoading] = useState(false);
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { customer: customerSelector, workspace: workspaceSelector } = stateSelector || {};
  const { customers } = customerSelector;
  const { workspace } = workspaceSelector;
  const { id: workspace_id } = workspace;
  //Other variable
  const dispatch = useDispatch();
  const history = useHistory();

  const onUpdateInputValue = useCallback((value: string, obj: CustomerDetailsInterface) => {
    setCustomer(obj);
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChange = (value: string) => {
    setValue(value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onButtonClick = useCallback(
    async (e: React.SyntheticEvent) => {
      try {
        e.preventDefault();
        setNextLoading(true);
        if (isEmpty(customer?.id)) {
          dispatch(setErrorMessage('Please select customer!'));
          return;
        }
        if (value === CREATE_INVOICE_TYPE.TRACKED_TIME_AND_EXPENSE && !isEmpty(customer?.id)) {
          const result = await dispatch(getProjectHours(workspace_id, customer?.id));
          if (result > 0) {
            trackAnalyticActivity(INVOICE_ANALYTICS.VIEW_TIMELOG);
            history.push(`${ROUTES.ADD_NEW_INVOICES}?customer=${customer?.id}`);
          } else {
            history.push(`${ROUTES.ADD_NEW_INVOICES_2}?customer=${customer?.id}`);
          }
        } else if (value === CREATE_INVOICE_TYPE.BLANK_INVOICE && !isEmpty(customer?.id)) {
          history.push(`${ROUTES.ADD_NEW_INVOICES_2}?customer=${customer?.id}`);
        }
      } catch (e) {
        captureException(e);
        console.log('ERROR', e);
      } finally {
        setNextLoading(false);
      }
    },
    [value, history, customer, dispatch, workspace_id]
  );
  return (
    <>
      <ProfileDropdown>
        <DropTitle>Customer</DropTitle>
        <Dropdown content={undefined} children={undefined}></Dropdown>
        <Customer>
          <Dropdown
            isMinWidth={CustomerInvoiceModal ? 339 : 291}
            content={
              <SelectDiv CustomerInvoiceModal={CustomerInvoiceModal}>
                <p>{customer?.companyName || 'Select a customer'}</p>
                <IconDiv>
                  <SVGIcon
                    name='angledown-icon'
                    width='18'
                    height='18'
                    viewBox='0 0 18 18'
                    className='angledown-icon'
                  />
                </IconDiv>
              </SelectDiv>
            }
            trigger='click'>
            <ItemDiv>
              {customers.map((item) => {
                const displayName = item.companyName || 'Unknown';
                const isSelected = customer?.companyName === item.companyName;
                return (
                  <DropdownItem
                    key={item.value}
                    onClick={() => onUpdateInputValue(item.value ?? '', item)}
                    label={displayName}
                    isSuffix={
                      isSelected ? <SVGIcon name='checkbox-icon' width='16' height='16' viewBox='0 0 16 16' /> : null
                    }
                  />
                );
              })}
            </ItemDiv>
          </Dropdown>

          {!isCustomerDetail && (
            <Client onClick={handleOpen}>
              <SVGIcon name='black-plus-icon' width='16' height='16' viewBox='0 0 16 16' className='plus-icon' />
            </Client>
          )}
        </Customer>
        <Invoisetype>
          <DropTitle className='type'>Invoice Type</DropTitle>

          <ConfigProvider
            theme={{
              components: {
                Radio: {
                  colorPrimary: 'var(--brand-primary)'
                }
              }
            }}>
            <Radio.Group value={value} onChange={(e) => handleChange(e.target.value)}>
              <Space direction='vertical'>
                <Radio value={CREATE_INVOICE_TYPE.TRACKED_TIME_AND_EXPENSE}>
                  Create an invoice for tracked time and expenses
                </Radio>
                <Radio value={CREATE_INVOICE_TYPE.BLANK_INVOICE}>Create a blank invoice</Radio>
              </Space>
            </Radio.Group>
          </ConfigProvider>
        </Invoisetype>
        <Formbutton>
          <Button
            onClick={(e: React.ChangeEvent<HTMLInputElement>) => onButtonClick(e)}
            title={'Next Step'}
            isLoading={nextLoading}
            modelbtn={true}
          />
          <Button title='Cancel' onClick={closemodel} secondary={true} type='button' hasNotBoxshadow modelbtn={true} />
        </Formbutton>
      </ProfileDropdown>
      <ModalCustom open={open} onClose={handleClose}>
        <CustomerModal handleCloseModal={handleClose} />
      </ModalCustom>
    </>
  );
}
