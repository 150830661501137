import styled from 'styled-components';
import { Typography } from '../../global/theme';

const Window = styled.div`
  display: none;
  padding: 6px 8px;
  position: relative;
`;
const ArrowSection = styled.div`
  display: flex;
  gap: 4px;
  -webkit-app-region: no-drag;
`;
const Icon = styled.div`
  -webkit-app-region: no-drag;
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    path {
      fill: var(--text-secondary);
    }
  }
  :hover {
    background-color: var(--background-hover);
    border-radius: 4px;
    svg {
      path {
        fill: var(--text-primary);
      }
    }
    .minimize-icon,
    .close-icon {
      path {
        stroke: var(--text-primary);
      }
    }
    .expand-icon {
      path {
        stroke: var(--text-primary);
        fill: none;
      }
    }
  }
  .forward-icon {
    transform: rotate(180deg);
  }
  .close-icon {
    stroke: var(--text-secondary);
  }
  svg {
    path {
      fill: var(--text-secondary);
    }
  }
  .minimize-icon,
  .close-icon {
    path {
      stroke: var(--text-secondary);
    }
  }
  .expand-icon {
    path {
      stroke: var(--text-secondary);
      fill: none;
    }
  }
`;
const UrlSection = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  -webkit-app-region: no-drag;
`;
const UrlText = styled.p`
  ${Typography.body_md_medium};
  color: var(--text-secondary);
  margin: 0;
`;
const ExpandCloseSection = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;
const NavigateArea = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: 205px;
  width: 100%;
`;
const LogoIcon = styled.div`
  width: 24px;
  height: 24px;
  svg {
    path {
      fill: var(--text-secondary);
    }
  }
`;
const MainHeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
export {
  Window,
  ArrowSection,
  Icon,
  UrlSection,
  UrlText,
  ExpandCloseSection,
  NavigateArea,
  LogoIcon,
  MainHeaderContent
};
