import styled, { css } from 'styled-components';
import { Typography } from '../../../global/theme';

interface Props {
  isdefault?: boolean;
  isActive?: boolean;
  color?: string;
}

const Rightrow1 = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  .svgicon {
    path {
      fill: var(--text-secondary);
    }
  }
  .no-priority-icon {
    path {
      fill: var(--text-tertiary);
    }
  }
  .high-priority-icon {
    path {
      fill: var(--text-secondary);
    }
  }
`;
const Status = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  border-top: 0.5px solid var(--border-primary);
  padding-top: 12px;
`;

const Statusrow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 8px;
  width: -webkit-fill-available;
  justify-content: space-between;
  gap: 10px;
`;
const Statusrow1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 8px;
  justify-content: space-between;
`;

const Icon2 = styled.div`
  display: inline-flex;
  width: 18px;
  height: 18px;
  cursor: pointer;
  position: relative;
  svg {
    cursor: pointer;
    fill: var(--text-secondary);
    stroke: var(--text-secondary);
    :hover {
      background-color: var(--background-muted);
      border-radius: 4px;
    }
  }
`;

const Circleicon = styled.div<Props>`
  margin: 4px;
  width: 8px;
  height: 8px;
  border-radius: 20px;
  ${(props: Props) =>
    props.color &&
    css`
      background-color: ${props.color};
    `}
`;
const Labelgroup = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  p {
    ${Typography.body_md_medium}
    color: var(--text-secondary);
    margin: 0;
    cursor: pointer;
  }
  .plus-icon {
    fill: var(--text-secondary);
  }
`;
export { Rightrow1, Statusrow, Status, Icon2, Statusrow1, Circleicon, Labelgroup };
