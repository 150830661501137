import styled from 'styled-components';
import { Typography } from '../../../global/theme';

interface Props {
  progress?: number;
  color?: string;
}

const Group = styled.div`
  width: 100%;
  margin: auto;
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-gap: 17px;
  background-color: var(--background-secondary);
  grid-template-columns: repeat(auto-fill, minmax(191px, 1fr));
  @media (max-width: 449px) {
    grid-gap: 20px;
  }
`;

const Projectcontent = styled.div`
  width: 100%;
  max-width: 238px;
  border: 0.5px solid var(--border-primary);
  background-color: var(--background-primary);
  border-radius: 12px;
  cursor: pointer;
  :hover {
    box-shadow: var(--shadow-card-hover);
    transition: all 0.3s;
  }
  @media (max-width: 449px) {
    width: 100%;
    max-width: -webkit-fill-available;
  }
`;

const Projectitem = styled.div`
  width: 100%;
  max-width: 238px;
  background-color: var(--background-primary);
  border: none;
  border-radius: 12px 12px 0 0;
  @media (max-width: 449px) {
    max-width: 375px;
    width: 100%;
  }
`;

const Projectlist = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: var(--background-primary);
  margin: 15px 16px 0 16px;
  @media (max-width: 449px) {
    width: auto;
    max-width: 100%;
  }
`;

const Document = styled.div`
  margin-top: 2px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  :hover {
    background-color: var(--background-muted);
    border-radius: 4px;

    svg {
      filter: brightness(var(--brightness-value2));
    }
  }
  svg {
    fill: var(--accent-highlight);
  }
  @media (max-width: 449px) {
    margin-top: 0px;
    padding: 9px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

const Title = styled.h1`
  ${Typography.body_lg_medium}
  color:var(--text-primary);
  margin: 0 0;
`;

const Text = styled.p`
  ${Typography.body_md}
  color: var(--text-secondary);
  margin: 0;
`;

const Point = styled.div`
  position: relative;
  margin-left: 16px;
  margin-bottom: 12px;
  border: none;
  small {
    position: absolute;
    border-radius: 50%;
    background-color: ${(props: Props) => props.color};
    top: 6px;
    width: 10px;
    height: 10px;
    left: -0.5px;
  }

  span {
    ${Typography.body_sm}
    color: var(--text-primary);
    margin-left: 16px;
  }
`;

const ProgressDiv = styled.div`
  display: flex;
  padding: 0 16px;
  .ant-progress-line .ant-progress-outer .ant-progress-inner div.ant-progress-bg {
    height: 5px !important;
  }
  .ant-progress .ant-progress-inner {
    background-color: var(--border-primary);
  }
  .prograss {
    .ant-progress-outer {
      display: flex;
    }

    .ant-progress-inner {
      height: 5px;
      .ant-progress {
        .ant-progress-bg {
          height: 5px;
        }
      }
    }
  }
`;

const AssignContent = styled.div`
  width: 100%;
  max-width: 238px;
  padding-top: 5px;
  margin-bottom: -18px;
`;

const Assigngroup = styled.div`
  width: 100%;
  max-width: 140px;
  margin-left: 16px;
  margin-top: -9px;

  ul {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
    margin-top: 16px;
  }

  li {
    margin-right: -8px;
    padding-bottom: 12px;
    position: relative;
    @media (max-width: 449px) {
      padding-bottom: 12px;
      margin-right: -9px;
    }
  }

  small {
    padding: 5px;
    border-radius: 20px;
    background: var(--text-secondary);
    border: 2px solid var(--background-primary);
  }

  span {
    ${Typography.body_xs_semibold}
    color: var(--text-white);
  }

  .plus-icon {
    width: 20px;
    height: 8px;
    border-radius: 24px;
    align-items: center;
    justify-content: center;
    color: white;
    display: flex;
    background: var(--text-secondary);
    ${Typography.body_xs_semibold}
    border: 2px solid var(--background-primary);
    position: relative;
    top: 0;
    @media (max-width: 449px) {
      width: 20px;
      height: 8px;
    }
  }
`;
const Plus = styled.div`
  position: absolute;
  top: 4px;
  @media (max-width: 449px) {
    top: 4px;
  }
`;
const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 18px;
  height: 100%;
`;
const Bottom = styled.div``;
export {
  Group,
  Container,
  Projectcontent,
  Projectitem,
  Projectlist,
  Document,
  Icon,
  Title,
  Text,
  Point,
  ProgressDiv,
  AssignContent,
  Assigngroup,
  Plus,
  Box,
  Bottom
};
