import DatePicker from '../../../component/dateTime/datePicker';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearInvoiceDetail, updateInvoiceDetail, setInvoiceDetail } from '../../../actions/invoiceActions';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import AppLayout from '../../../component/appLayout';
import {
  InvoiceDetailInterface,
  InvoiceItemsInterface,
  projectIdInterface
} from '../../../interfaces/InvoiceInterface';
import { createNewInvoice, getInvoiceId, getInvoiceViewData, updateInvoice } from '../../../services/invoiceService';
import {
  Headerblock,
  Heading,
  Icon,
  HeaderTitle,
  MainGroup,
  Group1,
  Row,
  Row2,
  Label,
  Inputlabel,
  Input1,
  Tex,
  Anothertex,
  Notex,
  Group2,
  Gheader,
  Rightheader,
  Project,
  Quantity,
  Amount,
  Fields,
  Inputarea,
  Quantityrate,
  Amountrate,
  AddButton,
  Inputfield,
  Bottomfield,
  Total,
  Subtotal,
  Name,
  Content,
  Totalamount,
  Totalcontent,
  Emailbox,
  Inputvalue,
  Group3,
  Buttons,
  Group,
  Price,
  Unitrate,
  Rowrightheader,
  Checks,
  RemoveIcon,
  TaxOutside,
  Checks2,
  Label1,
  Removeicon,
  Tax,
  Dragicon,
  ResponsiveNavbar,
  NavbarDiv,
  ItemRow,
  ResponsiveLabel,
  SelectDiv,
  IconDiv,
  SelectInput,
  ItemDiv,
  SelectOptionDiv
} from './styles';
import { currencyWithDecimal, getUrlParam, isEmpty } from '../../../helpers/common';
import { CurrencyList, currencySymbols } from '../../../global/row';
import { useHistory, useParams } from 'react-router-dom';
import { getCustomerProjectDetail, getIndividualCustomerDetail } from '../../../services/customerServices';
import { INVOICE_STATUS } from '../../../global/constants';
import moment from 'moment';
import Button from '../../../component/button';
import UserPreferenceSingleton from '../../../helpers/userPreferenceSingleton';
import { getInvoicingSettingsData } from '../../../services/settingServices';
import { captureException } from '../../../services/logService';
import { setErrorMessage } from '../../../actions/messageActions';
import { trackAnalyticActivity } from '../../../services/analyticsService';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import {
  DragDropContext,
  Droppable,
  DraggableProvided,
  DropResult,
  Draggable,
  DraggableStateSnapshot
} from 'react-beautiful-dnd';
import { nanoid } from 'nanoid';
import { INVOICE_ANALYTICS } from '../../../global/analyticsConstants';
import { useMobileDevice } from '../../../global/useMobile';
import Responsivnavbar from '../../../component/navbar/responsivenavbar';
import { Dropdown, DropdownItem } from '../../../component/Dropdown';
import { CustomerProjectDetailInterface } from '../../../interfaces/CustomerInterface';
// eslint-disable-next-line @typescript-eslint/no-redeclare
declare type DraggableProvided = typeof import('react-beautiful-dnd');
// eslint-disable-next-line @typescript-eslint/no-redeclare
declare type DraggableStateSnapshot = typeof import('react-beautiful-dnd');

// eslint-disable-next-line @typescript-eslint/no-redeclare
declare type DropResult = typeof import('react-beautiful-dnd');

const NewInvoiceSecondStep: React.FC = () => {
  //States
  const [loading, setLoading] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [searchProjectQueries, setSearchProjectQueries] = useState<Record<string, string>>({});
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { workspace: workspaceSelector, invoice: invoiceSelector, customer: customerSelector } = stateSelector || {};
  const { workspace } = workspaceSelector;
  const { id: workspace_id } = workspace;
  const { inputInvoiceDetail } = invoiceSelector;
  const { customerProjectDetail } = customerSelector;
  //Other variable
  const dispatch = useDispatch();
  const history = useHistory();
  const customerId = getUrlParam(history.location.search, 'customer');
  const params: { invoice_id: string } = useParams();
  const ref = currencySymbols.find((x) => x?.code === inputInvoiceDetail?.currency);
  const symbol = ref?.symbol;
  const taxFieldWidth = inputInvoiceDetail.tax.length === 2 ? '178px' : '206px';
  const taxFieldCount = inputInvoiceDetail.tax.length;
  const mobile = useMobileDevice();

  // set initial indexes for invoice items
  const setInitialIndexes = (invoiceItems: InvoiceItemsInterface[]) => {
    // Set indexes starting from 100, increasing by 100 for each item
    return invoiceItems?.map((item, idx) => {
      return { ...item, index: 100 + idx * 100 };
    });
  };

  // load initial data
  const loadData = useCallback(async () => {
    try {
      if (!isEmpty(workspace_id)) {
        setLoading(true);
        const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
        await dispatch(getIndividualCustomerDetail(customerId));
        const projectResult = await dispatch(getCustomerProjectDetail(workspace_id, customerId));
        if (isEmpty(inputInvoiceDetail?.id) || !inputInvoiceDetail?.id) {
          const invoicingDetails = await dispatch(getInvoicingSettingsData(workspace_id));
          if (!isEmpty(invoicingDetails?.note))
            dispatch(updateInvoiceDetail({ propsName: 'notes', value: invoicingDetails?.note }));
          if (!isEmpty(invoicingDetails?.tax) || invoicingDetails?.tax?.length > 0)
            dispatch(updateInvoiceDetail({ propsName: 'tax', value: invoicingDetails?.tax }));
          if (invoicingDetails?.dueDays && invoicingDetails?.dueDays !== 0) {
            const formattedDate = moment(new Date()).format();
            dispatch(updateInvoiceDetail({ propsName: 'date', value: formattedDate }));
            const formattedDueDate = isEmpty(inputInvoiceDetail?.dueDate)
              ? moment().add(invoicingDetails?.dueDays, 'days').format()
              : inputInvoiceDetail?.dueDate;
            dispatch(updateInvoiceDetail({ propsName: 'dueDate', value: formattedDueDate }));
            dispatch(updateInvoiceDetail({ propsName: 'dueDays', value: invoicingDetails?.dueDays }));
          }
          await dispatch(getInvoiceId(userDetails?.id, workspace_id, !isEmpty(inputInvoiceDetail?.currency)));
          if (inputInvoiceDetail?.items?.length > 0 && isEmpty(inputInvoiceDetail?.items[0]?.id)) {
            const newItems = inputInvoiceDetail?.items?.map((item) => {
              if (!isEmpty(item?.projectId)) {
                const project = projectResult?.find((ele: { id: projectIdInterface }) => ele?.id === item?.projectId);
                return { ...item, projectId: project };
              } else {
                return item;
              }
            });
            const itemsWithIndexes = setInitialIndexes(newItems);
            const sortedItems = itemsWithIndexes.toSorted((a, b) => (a?.index ?? 0) - (b?.index ?? 0));
            // Update the state
            dispatch(updateInvoiceDetail({ propsName: 'items', value: sortedItems }));
          }
        } else {
          const newItems = inputInvoiceDetail?.items?.map((item) => {
            if (!isEmpty(item?.projectId)) {
              const project = projectResult?.find((ele: { id: projectIdInterface }) => ele?.id === item?.projectId);
              return { ...item, projectId: project };
            } else {
              return item;
            }
          });
          dispatch(updateInvoiceDetail({ propsName: 'emails', value: [] }));
          const itemsWithIndexes = setInitialIndexes(newItems);
          dispatch(updateInvoiceDetail({ propsName: 'items', value: itemsWithIndexes }));
        }
        if (params?.invoice_id) {
          const response: any = await Promise.all([
            dispatch(getInvoiceViewData(params?.invoice_id)),
            dispatch(getCustomerProjectDetail(workspace_id, customerId))
          ]);
          let invoiceData: InvoiceDetailInterface = response[0];
          if (response[0]?.items && response[0]?.items) {
            const updatedItems: InvoiceItemsInterface[] = invoiceData?.items?.map((item: any) => {
              if (item?.projectId) {
                const project = projectResult?.find((ele: { id: projectIdInterface }) => ele?.id === item?.projectId);
                return { ...item, projectId: project };
              } else {
                return item;
              }
            });
            invoiceData = { ...invoiceData, items: updatedItems };
          }
          dispatch(setInvoiceDetail(invoiceData));
        }
      }
    } catch (error) {
      captureException(error);
      console.log('error', error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace_id, dispatch, customerId, params?.invoice_id, inputInvoiceDetail?.id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    return () => {
      dispatch(clearInvoiceDetail());
    };
  }, [dispatch]);

  // calculate total amount logic
  const calculateTotalAmount = useCallback(
    async (invoiceInput: InvoiceDetailInterface) => {
      let subTotal = invoiceInput?.items?.reduce((sum: number, val) => sum + Number(val?.amount || 0), 0);
      let totalDiscount = (subTotal * Number(invoiceInput?.discount)) / 100;
      let totalTax = 0;
      let totalTax2 = 0;
      invoiceInput?.items?.forEach((item) => {
        let discountedAmount = Number(item?.amount) - (Number(item?.amount) * Number(invoiceInput?.discount)) / 100;
        if (item?.isTax && invoiceInput?.tax[0]?.value > 0) {
          totalTax = totalTax + (Number(discountedAmount) * Number(invoiceInput?.tax[0]?.value)) / 100;
        }
        if (item?.isTax2 && invoiceInput?.tax[1]?.value > 0) {
          totalTax2 = totalTax2 + (Number(discountedAmount) * Number(invoiceInput?.tax[1]?.value)) / 100;
        }
      });
      let totalAmount = Number(subTotal) - Number(totalDiscount) + Number(totalTax) + Number(totalTax2);
      invoiceInput = { ...invoiceInput, subTotal, totalDiscount, totalTax, totalTax2, totalAmount };
      dispatch(setInvoiceDetail(invoiceInput));
    },
    [dispatch]
  );

  // handle input field change
  const onChangeInputField = useCallback(
    async (propsName: string, value: string) => {
      let invoiceInput = JSON.parse(JSON.stringify(inputInvoiceDetail));
      invoiceInput[propsName] = value;
      dispatch(updateInvoiceDetail({ propsName, value }));
      await calculateTotalAmount(invoiceInput);
    },
    [dispatch, inputInvoiceDetail, calculateTotalAmount]
  );

  // manage tax input field
  const onChangeTaxInputField = useCallback(
    async (index: number, value: string) => {
      let invoiceInput = JSON.parse(JSON.stringify(inputInvoiceDetail));
      let tax = invoiceInput?.tax;
      if (index === 0) {
        tax[0] = { ...tax[0], value: value };
      } else if (index === 1) {
        tax[1] = { ...tax[1], value: value };
      }
      invoiceInput.tax = tax;
      dispatch(updateInvoiceDetail({ propsName: 'tax', value: tax }));
      await calculateTotalAmount(invoiceInput);
    },
    [dispatch, inputInvoiceDetail, calculateTotalAmount]
  );

  // manage items input field
  const onChangeItemsInputField = useCallback(
    (index: number, propsName: string, value: string | number | boolean) => {
      let invoiceInput = JSON.parse(JSON.stringify(inputInvoiceDetail));
      let items = invoiceInput?.items;
      items[index][propsName] = value;
      if (propsName === 'qty') {
        items[index]['amount'] = Number(value || 0) * items[index].unitPrice;
      } else if (propsName === 'unitPrice') {
        items[index]['amount'] = Number(value || 0) * items[index].qty;
      }
      invoiceInput.items = items;
      dispatch(updateInvoiceDetail({ propsName: 'items', value: items }));
      if (propsName === 'isTax' || propsName === 'isTax2' || propsName === 'qty' || propsName === 'unitPrice') {
        calculateTotalAmount(invoiceInput);
      }
    },
    [dispatch, inputInvoiceDetail, calculateTotalAmount]
  );

  // Update search query for a specific item
  const onChangeSearch = useCallback((itemId: string, value: string) => {
    setIsSearch(true);
    setSearchProjectQueries({});
    setSearchProjectQueries(() => ({
      [itemId]: value // Store search query per item
    }));
  }, []);

  // manage items input field
  const onChangeProject = useCallback(
    (index: number, propsName: string, value: object) => {
      let invoiceInput = JSON.parse(JSON.stringify(inputInvoiceDetail));
      let items = invoiceInput?.items;
      items[index][propsName] = value;
      invoiceInput.items = items;
      // Set search query for this item based on selected project
      setIsSearch(false);
      dispatch(updateInvoiceDetail({ propsName: 'items', value: items }));
    },
    [dispatch, inputInvoiceDetail]
  );

  // handle date change
  const onChangeDate = useCallback(
    (propsName: string, value: Date) => {
      dispatch(updateInvoiceDetail({ propsName, value: !isEmpty(value) ? moment(new Date(value)).format() : null }));
      let timeDiff = 0;
      let dueDays = 0;
      if (propsName === 'date') {
        timeDiff = new Date(inputInvoiceDetail?.dueDate).getTime() - new Date(value).getTime();
        dueDays = timeDiff / (1000 * 60 * 60 * 24);
      } else if (propsName === 'dueDate') {
        timeDiff = new Date(value).getTime() - new Date(inputInvoiceDetail?.date).getTime();
        dueDays = timeDiff / (1000 * 60 * 60 * 24);
      }
      dispatch(updateInvoiceDetail({ propsName: 'dueDays', value: !isEmpty(value) ? Math.round(dueDays) : 0 }));
    },
    [dispatch, inputInvoiceDetail]
  );

  // handle add items
  const onAddItems = useCallback(async () => {
    let itemClone = JSON.parse(JSON.stringify(inputInvoiceDetail?.items));
    const currentItem = inputInvoiceDetail?.items?.length - 1;
    const currentIndex = inputInvoiceDetail?.items?.[currentItem]?.index;
    let Clone = {
      id: nanoid(),
      index: (currentIndex || 0) + 100,
      amount: 0,
      desc: '',
      isTax: true,
      isTax2: true,
      itemType: 1,
      projectId: '',
      qty: 1,
      unitPrice: 0
    };
    const newItems = [...itemClone, Clone];
    dispatch(updateInvoiceDetail({ propsName: 'items', value: newItems }));
  }, [dispatch, inputInvoiceDetail]);

  // handle create invoice
  const onCreateInvoice = useCallback(
    async (e: React.SyntheticEvent, value: number) => {
      try {
        e.preventDefault();
        setLoading(true);
        let itemsClone = JSON.parse(JSON.stringify(inputInvoiceDetail?.items));
        const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
        itemsClone = itemsClone?.map((ele: InvoiceItemsInterface) => {
          return {
            ...ele,
            projectId: ele?.projectId?.id,
            qty: Number(ele?.qty),
            unitPrice: Number(ele?.unitPrice)
          };
        });
        if (itemsClone?.some((x: { amount: number }) => !x?.amount || x?.amount === 0)) {
          dispatch(setErrorMessage('Please enter valid amount for every items!'));
          return;
        }
        if (itemsClone?.some((x: { desc?: string }) => !x?.desc)) {
          dispatch(setErrorMessage('Please add a description for every item!'));
          return;
        }
        const allTaxes = inputInvoiceDetail?.tax?.map((ele) => {
          return {
            ...ele,
            value: Number(ele?.value)
          };
        });
        let payload;
        let response;
        if (!isEmpty(inputInvoiceDetail?.id)) {
          payload = {
            ...inputInvoiceDetail,
            items: itemsClone,
            status: INVOICE_STATUS.SAVE,
            userId: userDetails?.id,
            customerId: inputInvoiceDetail?.customer?.id,
            discount: Number(inputInvoiceDetail?.discount),
            tax: allTaxes
          };
          if (payload?.customer) delete payload?.customer;
          response = await dispatch(updateInvoice(inputInvoiceDetail?.id, payload));
          if (response) {
            const { data } = response;
            trackAnalyticActivity(INVOICE_ANALYTICS.EDITED);
            dispatch(clearInvoiceDetail());
            history.push(`/invoices/view/${data['_id']}`);
          }
        } else {
          if (value === INVOICE_STATUS.DRAFT) {
            payload = {
              ...inputInvoiceDetail,
              items: itemsClone,
              status: INVOICE_STATUS.DRAFT,
              userId: userDetails?.id,
              customerId: inputInvoiceDetail?.customer?.id,
              discount: Number(inputInvoiceDetail?.discount),
              tax: allTaxes
            };
            if (payload?.customer) delete payload?.customer;
            response = await dispatch(createNewInvoice(payload));
            if (response) {
              trackAnalyticActivity(INVOICE_ANALYTICS.CREATED);
              dispatch(clearInvoiceDetail());
              history.push(`/invoices`);
            }
          } else if (value === INVOICE_STATUS.SAVE) {
            payload = {
              ...inputInvoiceDetail,
              items: itemsClone,
              status: INVOICE_STATUS.SAVE,
              userId: userDetails?.id,
              customerId: inputInvoiceDetail?.customer?.id,
              discount: Number(inputInvoiceDetail?.discount),
              tax: allTaxes
            };
            if (payload?.customer) delete payload?.customer;
            response = await dispatch(createNewInvoice(payload));
            if (response) {
              trackAnalyticActivity(INVOICE_ANALYTICS.CREATED);
              const { data } = response;
              dispatch(clearInvoiceDetail());
              history.push(`/invoices/view/${data?.data?.['_id']}`);
            }
          }
        }
      } catch (e) {
        captureException(e);
        console.log('ERROR', e);
      } finally {
        setLoading(false);
      }
    },
    [dispatch, inputInvoiceDetail, history]
  );

  // for apply tax to invoice
  const onAddTax = useCallback(async () => {
    let itemClone = JSON.parse(JSON.stringify(inputInvoiceDetail?.tax));
    let Clone;
    if (inputInvoiceDetail?.tax.length === 0) {
      Clone = {
        name: 'Tax',
        value: 0
      };
    } else {
      Clone = {
        name: 'Tax2',
        value: 0
      };
    }
    const newItems = [...itemClone, Clone];
    dispatch(updateInvoiceDetail({ propsName: 'tax', value: newItems }));
  }, [dispatch, inputInvoiceDetail]);

  // for delete item
  const deleteItem = useCallback(
    (index: number) => {
      let invoiceInput = JSON.parse(JSON.stringify(inputInvoiceDetail));
      let items = invoiceInput?.items;
      if (items?.length <= 1) {
        items = [
          {
            amount: 0,
            desc: '',
            isTax: true,
            isTax2: true,
            itemType: 1,
            projectId: '',
            qty: 1,
            unitPrice: 0
          }
        ];
      } else {
        items.splice(index, 1);
      }
      dispatch(updateInvoiceDetail({ propsName: 'items', value: items }));
      invoiceInput.items = items;
      calculateTotalAmount(invoiceInput);
    },
    [dispatch, inputInvoiceDetail, calculateTotalAmount]
  );

  // for delete tax form invoice
  const deleteTax = useCallback(() => {
    let invoiceInput = JSON.parse(JSON.stringify(inputInvoiceDetail));
    let tax = invoiceInput?.tax;
    tax.pop();
    dispatch(updateInvoiceDetail({ propsName: 'tax', value: tax }));
    invoiceInput.tax = tax;
    calculateTotalAmount(invoiceInput);
  }, [dispatch, inputInvoiceDetail, calculateTotalAmount]);

  //calculate the task index
  const calculateUpdatedTaskIndex = useCallback(
    (destinationIndex: number, sourceIndex: number, updatedItems: any[]) => {
      if (destinationIndex === 0) {
        // First item
        const nextTaskIndex = updatedItems[0]?.index;
        return nextTaskIndex ? nextTaskIndex - 50 : new Date().getTime();
      } else if (destinationIndex === updatedItems?.length - 1) {
        // Last item
        const previousTaskIndex = updatedItems[updatedItems?.length - 1]?.index;
        return previousTaskIndex ? previousTaskIndex + 50 : new Date().getTime();
      } else {
        // Middle items
        if (destinationIndex > sourceIndex) {
          const previousTaskIndex: number = updatedItems[destinationIndex]?.index || 0;
          const nextTaskIndex: number = updatedItems[destinationIndex + 1]?.index || previousTaskIndex + 100;
          return (previousTaskIndex + nextTaskIndex) / 2;
        } else {
          const previousTaskIndex: number = updatedItems[destinationIndex - 1]?.index || 0;
          const nextTaskIndex: number = updatedItems[destinationIndex]?.index || previousTaskIndex + 100;
          return (previousTaskIndex + nextTaskIndex) / 2;
        }
      }
    },
    []
  );

  // update the dragged item index
  const updateDraggedItemIndex = useCallback(
    (items: InvoiceItemsInterface[], draggableId: string, updatedTaskIndex: number) => {
      return items
        .map((item) => (item?.id === draggableId ? { ...item, index: updatedTaskIndex } : item))
        .toSorted((a, b) => (a.index as number) - (b.index as number));
    },
    []
  );

  // for drag and drop
  const handleDragEnd = (item: DropResult) => {
    try {
      const { destination, draggableId, source } = item || {};
      if (!destination || source?.index === destination?.index) return;
      const updatedItems = [...(inputInvoiceDetail?.items || [])];
      const updatedTaskIndex = calculateUpdatedTaskIndex(destination?.index, source?.index, updatedItems);
      const newItems = updateDraggedItemIndex(updatedItems, draggableId, updatedTaskIndex);
      dispatch(updateInvoiceDetail({ propsName: 'items', value: newItems }));
    } catch (error) {
      console.log('error', error);
    }
  };

  // set Field component style
  const getItemStyle = (isDragging: boolean, draggableStyle: React.CSSProperties) => ({
    userSelect: 'none',
    background: isDragging ? 'var(--background-primary)' : 'none',
    // borderRadius: isDragging ? 40 : 0,
    borderTop: isDragging ? `1px solid var(--border-primary)}` : 'none',
    borderBottom: `1px solid var(--border-primary)}`,
    cursor: 'auto',
    ...draggableStyle
  });

  // calculate textarea height
  const calculateTextareaHeight = (taxFieldCount: number) => {
    if (taxFieldCount === 0) return '104px';
    else if (taxFieldCount === 1) return '132px';
    else if (taxFieldCount === 2) return '160px';
    else return 'auto';
  };

  const handleCloseItemDropdown = useCallback(() => {
    setIsSearch(false);
    setSearchProjectQueries({});
  }, []);

  // render invoice tax detail
  const renderInvoiceTexDetail = useMemo(() => {
    if (inputInvoiceDetail?.tax?.length < 1) {
      return <Anothertex onClick={onAddTax}>( Apply tax to invoice )</Anothertex>;
    }
    return (
      <>
        {inputInvoiceDetail.tax.map((item, index) => (
          <Inputlabel key={item?.id}>
            <Label>{item.name}</Label>
            <Tex>
              <Input1
                style={{ width: taxFieldWidth }}
                min={0}
                placeholder='0'
                type='number'
                value={item?.value}
                onChange={(e) => onChangeTaxInputField(index, e.target.value)}
              />
            </Tex>
          </Inputlabel>
        ))}
        <TaxOutside>
          <Notex onClick={deleteTax}>No tax</Notex>
          {inputInvoiceDetail.tax.length < 2 && <Anothertex onClick={onAddTax}>Apply another tax</Anothertex>}
        </TaxOutside>
      </>
    );
  }, [inputInvoiceDetail?.tax, deleteTax, onAddTax, taxFieldWidth, onChangeTaxInputField]);

  // render invoice detail item
  const renderInvoiceDetailItem = useMemo(() => {
    return inputInvoiceDetail?.items?.map((item, index: number) => {
      const searchText = searchProjectQueries[item?.id]?.trim().toLowerCase() || '';

      const searchedLinkedProject: CustomerProjectDetailInterface[] = searchText
        ? customerProjectDetail?.filter((x) => x?.name?.trim().toLowerCase().includes(searchText))
        : customerProjectDetail;

      return (
        <Draggable key={item?.id} draggableId={item?.id} index={index}>
          {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            // <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <div key={item?.id}>
              <Fields
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                <Dragicon
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}
                  // style={{ display: snapshot.isDragging ? 'none' : 'block' }}
                >
                  <SVGIcon name='invoice-drag-icon' viewBox='0 0 16 16' width='16' height='16' className='drag-icon' />
                </Dragicon>
                <ItemRow className='first-row'>
                  <Label1>Item {index + 1}</Label1>
                  <RemoveIcon onClick={() => deleteItem(index)}>
                    <SVGIcon
                      name='invoice-details-delete-icon'
                      width='16'
                      height='16'
                      viewBox='0 0 16 16'
                      fill='none'
                      className='delete-icon'
                    />
                  </RemoveIcon>
                </ItemRow>
                <Inputarea
                  type='text'
                  placeholder='Add Description'
                  value={item?.desc}
                  onChange={(e) => onChangeItemsInputField(index, 'desc', e.target.value)}
                />
                <Rowrightheader>
                  <ItemRow>
                    <ResponsiveLabel>Linked Project</ResponsiveLabel>

                    <Dropdown
                      isMinWidth={132}
                      content={
                        <SelectOptionDiv>
                          <input
                            placeholder='Select Project'
                            value={isSearch ? searchProjectQueries[item?.id] : item?.projectId?.name || ''}
                            onChange={(e) => onChangeSearch(item?.id, e.target.value)}
                          />
                          {/* <IconDiv> */}
                          <SVGIcon
                            name='angledown-icon'
                            width='18'
                            height='18'
                            viewBox='0 0 18 18'
                            className='angledown-icon'
                          />
                          {/* </IconDiv> */}
                        </SelectOptionDiv>
                      }
                      trigger='click'
                      onOutsideClick={handleCloseItemDropdown}>
                      {searchedLinkedProject.map((item) => (
                        <DropdownItem
                          key={item.key}
                          label={item.name}
                          onClick={() => onChangeProject(index, 'projectId', item)}
                        />
                      ))}
                    </Dropdown>
                  </ItemRow>
                  <ItemRow>
                    <ResponsiveLabel>Quantity</ResponsiveLabel>
                    <Quantityrate
                      type='number'
                      min={1}
                      placeholder='1'
                      value={item?.qty}
                      onChange={(e) => onChangeItemsInputField(index, 'qty', e.target.value)}
                    />
                  </ItemRow>
                  <ItemRow>
                    <ResponsiveLabel>Unit Price</ResponsiveLabel>
                    <Unitrate
                      type='number'
                      min={0}
                      placeholder='0.00'
                      value={item?.unitPrice}
                      onChange={(e) => onChangeItemsInputField(index, 'unitPrice', e.target.value)}
                    />
                  </ItemRow>
                  {inputInvoiceDetail.tax.length > 0 && (
                    <ItemRow>
                      <ResponsiveLabel>{inputInvoiceDetail.tax[0]?.name}</ResponsiveLabel>
                      <Checks2>
                        <Inputvalue
                          type='checkbox'
                          className='checkbox-round'
                          checked={item?.isTax}
                          onChange={(e) => onChangeItemsInputField(index, 'isTax', !item?.isTax)}
                        />
                      </Checks2>
                    </ItemRow>
                  )}
                  {inputInvoiceDetail.tax.length > 1 && (
                    <ItemRow>
                      <ResponsiveLabel>{inputInvoiceDetail.tax[1]?.name}</ResponsiveLabel>
                      <Checks>
                        <Inputvalue
                          type='checkbox'
                          className='checkbox-round'
                          checked={item?.isTax2}
                          onChange={(e) => onChangeItemsInputField(index, 'isTax2', !item?.isTax2)}
                        />
                      </Checks>
                    </ItemRow>
                  )}
                  <ItemRow>
                    <ResponsiveLabel>Amount</ResponsiveLabel>
                    <Amountrate>{currencyWithDecimal(item?.amount)}</Amountrate>
                  </ItemRow>
                  {!mobile && (
                    <RemoveIcon onClick={() => deleteItem(index)} className='remove-icon-web'>
                      <SVGIcon
                        name='invoice-details-delete-icon'
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        fill='none'
                        className='delete-icon'
                      />
                    </RemoveIcon>
                  )}
                </Rowrightheader>
              </Fields>
            </div>
            // </div>
          )}
        </Draggable>
      );
    });
  }, [
    inputInvoiceDetail?.items,
    inputInvoiceDetail.tax,
    searchProjectQueries,
    customerProjectDetail,
    isSearch,
    handleCloseItemDropdown,
    mobile,
    deleteItem,
    onChangeItemsInputField,
    onChangeSearch,
    onChangeProject
  ]);

  return (
    <AppLayout>
      {/* responsive header ui */}
      <ResponsiveNavbar className={!isEmpty(inputInvoiceDetail?.id) ? 'extra-padding' : ''}>
        <NavbarDiv>
          <Responsivnavbar />
          <HeaderTitle>Invoice for {inputInvoiceDetail?.customer?.companyName}</HeaderTitle>
        </NavbarDiv>
        {isEmpty(inputInvoiceDetail?.id) && (
          <Button
            onClick={(e: React.SyntheticEvent) => onCreateInvoice(e, INVOICE_STATUS.DRAFT)}
            title='Save as Draft'
            secondary={true}
            isLoading={loading}
            hasNotBoxshadow
          />
        )}
      </ResponsiveNavbar>
      <Group>
        {/* web header ui */}
        <Headerblock>
          <Heading>
            <Icon>
              <SVGIcon name='invoice-header-icon' width='28' height='28' viewBox='0 0 28 28' className='invoice-icon' />
            </Icon>
            <HeaderTitle>Invoice for {inputInvoiceDetail?.customer?.companyName}</HeaderTitle>
          </Heading>
          {isEmpty(inputInvoiceDetail?.id) && (
            <Button
              onClick={(e: React.SyntheticEvent) => onCreateInvoice(e, INVOICE_STATUS.DRAFT)}
              title='Save as Draft'
              secondary={true}
              isLoading={loading}
              hasNotBoxshadow
            />
          )}
        </Headerblock>
        <MainGroup>
          <Group1>
            <Row>
              <Inputlabel>
                <Label>INVOICE FOR</Label>
                <Input1
                  placeholder='Walter Melon'
                  type='text'
                  value={inputInvoiceDetail?.customer?.companyName}
                  onChange={(e) => onChangeInputField('companyName', e.target.value)}
                />
              </Inputlabel>
              <Inputlabel>
                <Label>Currency</Label>
                <Dropdown
                  isMinWidth={230}
                  content={
                    <SelectDiv>
                      <SelectInput
                        placeholder={inputInvoiceDetail?.currency ? inputInvoiceDetail.currency : 'Select Currency'}
                        type='text'
                      />
                      <IconDiv>
                        <SVGIcon
                          name='angledown-icon'
                          width='18'
                          height='18'
                          viewBox='0 0 18 18'
                          className='angledown-icon'
                        />
                      </IconDiv>
                    </SelectDiv>
                  }
                  trigger='click'>
                  <ItemDiv>
                    {CurrencyList.map((item) => (
                      <DropdownItem
                        key={item.key}
                        label={item.value}
                        onClick={(e) => onChangeInputField('currency', item.key)}
                      />
                    ))}
                  </ItemDiv>
                </Dropdown>
              </Inputlabel>
              <Inputlabel>
                <Label>Invoice Id</Label>
                <Input1
                  placeholder='TUT/2018-19/0011'
                  type='text'
                  value={inputInvoiceDetail?.number}
                  onChange={(e) => onChangeInputField('number', e.target.value)}
                  disabled={!isEmpty(inputInvoiceDetail?.id)}
                />
              </Inputlabel>
              <Inputlabel>
                <Label>Issue Date</Label>
                <DatePicker
                  label='Enter the date'
                  value={!isEmpty(inputInvoiceDetail?.date) ? inputInvoiceDetail?.date : ''}
                  onChange={(date) => onChangeDate('date', date)}
                />
              </Inputlabel>
            </Row>
            <Row2>
              <Inputlabel>
                <Label>Due Date</Label>
                <DatePicker
                  label='Enter the date'
                  value={!isEmpty(inputInvoiceDetail?.dueDate) ? inputInvoiceDetail?.dueDate : ''}
                  onChange={(date) => onChangeDate('dueDate', date)}
                />
              </Inputlabel>
              <Inputlabel>
                <Label>Discount</Label>
                <Input1
                  min={0}
                  placeholder='0'
                  type='number'
                  value={inputInvoiceDetail?.discount}
                  onChange={(e) => onChangeInputField('discount', e.target.value)}
                />
              </Inputlabel>
              {renderInvoiceTexDetail}
            </Row2>
          </Group1>
          <Group2>
            <Gheader>
              <Label1>DESCRIPTION</Label1>
              <Rightheader>
                <Project>Linked Project</Project>
                <Quantity>Qty</Quantity>
                <Price>Unit Price</Price>
                {inputInvoiceDetail?.tax.length > 0 && <Tax>{inputInvoiceDetail.tax[0]?.name} </Tax>}
                {inputInvoiceDetail?.tax.length > 1 && <Tax>{inputInvoiceDetail.tax[1]?.name}</Tax>}
                <Amount>Amount</Amount>
                <Removeicon></Removeicon>
              </Rightheader>
            </Gheader>
            <DragDropContext onDragEnd={(result: DropResult) => handleDragEnd(result)}>
              <Droppable droppableId='invoice_items'>
                {(provided: DraggableProvided) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {renderInvoiceDetailItem}
                    {provided?.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <AddButton onClick={onAddItems}>
              <Icon>
                <SVGIcon
                  name='gray-plus-icon'
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                  className='plus-icon'
                />
              </Icon>
              <span>Add Item</span>
            </AddButton>
            <Bottomfield>
              <Inputfield
                style={{ height: calculateTextareaHeight(taxFieldCount) }}
                placeholder='Thanks for your business'
                value={inputInvoiceDetail?.notes}
                onChange={(e) => onChangeInputField('notes', e.target.value)}
              />
              <Total>
                <Subtotal>
                  <Name>Subtotal</Name>
                  <Content>
                    {symbol} {currencyWithDecimal(inputInvoiceDetail.subTotal)}
                  </Content>
                </Subtotal>
                <Subtotal>
                  <Name>Discount ({inputInvoiceDetail?.discount || 0}%)</Name>
                  <Content>
                    - {symbol} {currencyWithDecimal(inputInvoiceDetail?.totalDiscount)}
                  </Content>
                </Subtotal>
                {inputInvoiceDetail.tax.length > 0 && (
                  <Subtotal>
                    <Name>
                      {inputInvoiceDetail.tax[0]?.name} ({inputInvoiceDetail?.tax[0]?.value || 0}%)
                    </Name>
                    <Content>
                      {' '}
                      {symbol}
                      {currencyWithDecimal(inputInvoiceDetail?.totalTax)}
                    </Content>
                  </Subtotal>
                )}
                {inputInvoiceDetail.tax.length > 1 && (
                  <Subtotal>
                    <Name>
                      {inputInvoiceDetail.tax[1]?.name} ({inputInvoiceDetail?.tax[1]?.value || 0}%)
                    </Name>
                    <Content>
                      {' '}
                      {symbol}
                      {currencyWithDecimal(inputInvoiceDetail?.totalTax2)}
                    </Content>
                  </Subtotal>
                )}
                <Totalamount>
                  <Name>Amount Due: </Name>
                  <Totalcontent>
                    {symbol} {currencyWithDecimal(inputInvoiceDetail?.totalAmount)}
                  </Totalcontent>
                </Totalamount>
              </Total>
            </Bottomfield>
          </Group2>
          <Group3>
            <Emailbox></Emailbox>
            <Buttons>
              <Button
                onClick={(e: React.SyntheticEvent) => onCreateInvoice(e, INVOICE_STATUS.SAVE)}
                title={isEmpty(inputInvoiceDetail?.id) ? 'Create' : 'Update'}
                isLoading={loading}
              />
            </Buttons>
          </Group3>
        </MainGroup>
      </Group>
    </AppLayout>
  );
};

export default NewInvoiceSecondStep;
