import { ActionCreatorWithPayload, Dispatch, createAction } from '@reduxjs/toolkit';
import * as Actions from './types';
import {
  InvoiceReportInterface,
  PaymentReportDetails,
  ProjectReportInterface,
  TaskCommentsInterface
} from '../interfaces/ReportInterface';

/**
 * @desc Set Report loader
 */
export const setReportLoader: ActionCreatorWithPayload<boolean, string> = createAction(Actions.SET_REPORT_LOADER);

/**
 * @desc Set report tasks
 */
export const setReportTaskDetails: ActionCreatorWithPayload<any, string> = createAction(
  Actions.SET_REPORT_TASK_DETAILS
);

/**
 * @desc Set filter report tasks
 */
export const setFilteredReportTaskDetails: ActionCreatorWithPayload<any, string> = createAction(
  Actions.SET_FILTERED_REPORT_TASK_DETAILS
);

/**
 * @desc Set payment report data
 */
export const setPaymentReportData: ActionCreatorWithPayload<PaymentReportDetails[], string> = createAction(
  Actions.SET_PAYMENT_REPORT_DATA
);

/**
 * @desc Set filtered payment report
 */
export const setFilteredPaymentReportData: ActionCreatorWithPayload<PaymentReportDetails[], string> = createAction(
  Actions.SET_FILTERED_PAYMENT_REPORT_DATA
);

/**
 * @desc Set Invoice report data
 */
export const setInvoiceReportData: ActionCreatorWithPayload<InvoiceReportInterface[], string> = createAction(
  Actions.SET_INVOICE_REPORT_DATA
);

/**
 * @desc Set filtered Invoice report
 */
export const setFilteredInvoiceReportData: ActionCreatorWithPayload<InvoiceReportInterface[], string> = createAction(
  Actions.SET_FILTERED_INVOICE_REPORT_DATA
);

/**
 * @desc Set Project report data
 */
export const setProjectReportData: ActionCreatorWithPayload<ProjectReportInterface[], string> = createAction(
  Actions.SET_PROJECT_REPORT_DATA
);

/**
 * @desc Set filter Project report Data
 */
export const setFilteredProjectReportData: ActionCreatorWithPayload<ProjectReportInterface[], string> = createAction(
  Actions.SET_FILTERED_PROJECT_REPORT_DATA
);

/**
 * @desc Update filter Project report Data
 */
export const updateFilteredProjectReportData: ActionCreatorWithPayload<any, string> = createAction(
  Actions.UPDATE_FILTERED_PROJECT_REPORT_DATA
);

/**
 * @desc Set Team member report data
 */
export const setTeamMemberReportData: ActionCreatorWithPayload<any, string> = createAction(
  Actions.SET_TEAM_MEMBER_REPORT_DATA
);

/**
 * @desc Set filter team member report Data
 */
export const setFilteredTeamMemberReportData: ActionCreatorWithPayload<TaskCommentsInterface[], string> = createAction(
  Actions.SET_FILTERED_TEAM_MEMBER_REPORT_DATA
);

/**
 * @desc Clear Reports Data
 */
export const clearReportsData = () => (dispatch: Dispatch) => {
  dispatch(setReportLoader(false));
  dispatch(setReportTaskDetails([]));
  dispatch(setFilteredReportTaskDetails([]));
  dispatch(setTeamMemberReportData([]));
  dispatch(setFilteredTeamMemberReportData([]));
};
