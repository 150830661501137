import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { APP_INIT_RESPONSE_TYPE } from '../../../global/constants';
import UserPreferenceSingleton from '../../../helpers/userPreferenceSingleton';
import { ROUTES } from '../../../routes/urls';
import { login, validateIsLoggedIn } from '../../../services/authServices';
import Button from '../../../component/button';
import { trackAnalyticActivity, trackPage } from '../../../services/analyticsService';
import { captureException } from '../../../services/logService';
import { CustomWindow } from '../../../interfaces/OneSignalInterface';
import AppLogo from '../../../component/appLogo';
import {
  Group,
  Content,
  TopView,
  Icon,
  Title,
  GoogleButton,
  Overtext,
  Text,
  Form,
  Formblock,
  Inputvalue,
  Formview,
  Viewer,
  Hiddennow,
  Formtext,
  Formtextpoint
} from '../styles';
import { VIEW_ANALYTICS } from '../../../global/analyticsConstants';

declare const window: CustomWindow;

const Login: React.FC = () => {
  //state
  const [username, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  //Other variable
  let history = useHistory();
  const locationSearch = useLocation().search;
  const isFromLogoutPage = new URLSearchParams(locationSearch).get('logout');
  const dispatch = useDispatch();

  useEffect(() => {
    trackPage({ visit_url: 'login' });
    trackAnalyticActivity(VIEW_ANALYTICS.LOGIN_PAGE);
    if (!isFromLogoutPage && validateIsLoggedIn()) {
      history.push(ROUTES.INBOX);
    }
  }, [history, isFromLogoutPage]);

  // for login form submit
  const onSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();
      try {
        const payload = {
          username,
          password,
          appName: 'WebAppProject'
        };
        setLoading(true);
        const result = await dispatch(login(payload));
        if (result && result.type === APP_INIT_RESPONSE_TYPE.REDIRECT) {
          window.OneSignal.push(function () {
            window.OneSignal.setSubscription(true);
          });
          history.push(result.path);
        } else {
          let organization = UserPreferenceSingleton.getInstance().getWorkspace();
          if (organization?.id) {
            const intercomPayload = {
              company: {
                name: organization?.name,
                id: organization?.id
              }
            };
            if (window && typeof (window as any)?.Intercom === 'function') {
              (window as any).Intercom('update', intercomPayload);
            }
            if (window && (window as any)?.posthog) {
              (window as any).posthog.group('Workspace', intercomPayload.company?.id, {
                name: intercomPayload.company?.name
              });
            }
            const returnUrl = new URLSearchParams(locationSearch).get('return-url');
            if (returnUrl) history.push(decodeURIComponent(returnUrl));
            else history.push('/');
          }
        }
      } catch (e) {
        captureException(e);
      } finally {
        setLoading(false);
      }
    },
    [password, username, dispatch, history, locationSearch]
  );

  return (
    <>
      <Group isLoginPage={true} className='windows-onboarding-flow'>
        <Content isLoginPage={true}>
          <TopView>
            <Icon>
              <AppLogo height='60' />
            </Icon>
            <Title>Login</Title>
            <Hiddennow>
              <GoogleButton
                title='Sign Up with Google'
                iconName='google-icon'
                type='button'
                iconSize={16}
                iconViewBox='0 0 16 16'
                secondary={true}
              />
              <Overtext>
                <Text>or</Text>
              </Overtext>
            </Hiddennow>

            <Form onSubmit={onSubmit} isLoginPage={true}>
              <Formblock>
                <Inputvalue
                  type='email'
                  placeholder='Email Address'
                  value={username}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  isLoginPage={true}
                />
                <Inputvalue
                  type='password'
                  placeholder='Password'
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  className='password-input'
                  isLoginPage={true}
                />
              </Formblock>
              <Formview>
                <Viewer onClick={() => history.push('/membership/forgotpassword')}>Forgot Password?</Viewer>

                <Button type='submit' onClick={onSubmit} title='Login' isLoading={loading} resWidth={100} />
              </Formview>
            </Form>
          </TopView>
          <Formtextpoint>
            <Formtext>Don’t have an account?</Formtext>
            <Viewer onClick={() => history.push('/membership/signup')}>Create New Account</Viewer>
          </Formtextpoint>
        </Content>
      </Group>
    </>
  );
};

export default Login;
