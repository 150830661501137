/* eslint-disable no-template-curly-in-string */
import styled, { css, keyframes } from 'styled-components';
import { Typography } from '../../global/theme';

interface WrapButton {
  secondary: boolean;
  isDelete: boolean;
  size: string;
  isLoading?: boolean;
  modelbtn: boolean;
  commentbtn?: boolean;
  isSearchModelbtn?: boolean;
  isActive?: boolean;
  isMicmodalbtn?: boolean;
  isCancelbtn?: boolean;
  smallbutton?: boolean;
  hasNotBoxshadow?: boolean;
  isPadding?: boolean;
  isMemberTask?: boolean;
  invoiceDetailButton?: boolean;
}

const getBackground = (props: WrapButton) => {
  if (props.isDelete) return 'var(--accent-error)';
  if (props.secondary) return 'var(--text-white)';
  if (props.isLoading) return 'var(--brand-primary-hover)';
  return 'var(--neutral-secondary)';
};

interface Prop {
  isLoading?: boolean;
  isDelete?: boolean;
  style?: boolean;
  width?: string;
  resWidth?: string;
  isMicmodalbtn?: boolean;
  isRecordButton?: boolean;
}

const Buttons = styled.button<WrapButton>`
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  ${Typography.body_md_medium}
  font-family: unset;
  padding: 5px 19px;
  border-radius: 36px;
  background-color: var(--brand-primary);
  border: 0.5px solid transparent;
  color: var(--text-white);
  white-space: nowrap;
  cursor: pointer;
  :hover {
    background-color: var(--brand-primary-hover);
  }
  ${(props) =>
    props.secondary &&
    css`
      background-color: var(--background-primary);
      color: var(--text-primary);
      border: 0.5px solid var(--border-primary);

      &:hover {
        background-color: var(--background-secondary);
      }
    `}
  ${(props) =>
    props.hasNotBoxshadow &&
    css`
      box-shadow: none;
    `}

    

    ${(props) =>
    props.isSearchModelbtn &&
    css`
      padding: 7px 14px;
      background-color: var(--background-primary);
      color: var(--text-secondary);
      border: 0.5px solid var(--border-primary);

      &:hover {
        background-color: var(--background-primary);
        color: var(--text-primary);
      }

      ${props.isActive &&
      css`
        background-color: var(--neutral-secondary);
        color: var(--text-primary);

        &:hover {
          background-color: var(--neutral-secondary);
        }
      `}
    `}

  ${(props) =>
    props.isDelete &&
    css`
      background: var(--accent-error);
      color: var(--text-white);
      :hover {
        background: var(--accent-error);
        color: var(--text-white);
      }
    `}

  ${(props) =>
    props.size === 'large' &&
    css`
      padding: 9px 24px 9px 24px;
    `}

  ${(props) =>
    props.size === 'small' &&
    css`
      padding: 4px 15px !important;
      ${Typography.body_sm_medium};
    `}

  ${(props) =>
    props.disabled &&
    css`
      background: ${getBackground(props)};
      color: var(--text-secondary);
      background-color: var(--border-primary);
      &:hover {
        background: ${props.isLoading ? '  color: var(--brand-primary-hover);' : 'var(--neutral-secondary);'};
        background-color: var(--border-primary);
      }
    `}
    ${(props) =>
    props.isMicmodalbtn &&
    css`
      width: -webkit-fill-available;
    `}
      ${(props) =>
    props.isPadding &&
    css`
      padding: 7px 23px;
      @media (max-width: 449px) {
        padding: 7px 23px !important;
      }
    `}
  @media (max-width: 449px) {
    width: 100%;
    padding: 5px 19px;
    white-space: nowrap;
    ${(props) =>
      props.commentbtn &&
      css`
        padding: 6px 16px;
      `}
  }
  @media (max-width: 449px) {
    ${(props) =>
      props.modelbtn &&
      css`
        box-shadow: none;
        padding: 7px 19px;
        white-space: nowrap;
        width: unset;
      `}
  }
  @media (max-width: 449px) {
    ${(props) =>
      props.isCancelbtn &&
      css`
        padding: 7px 19px;
        white-space: nowrap;
      `}
  }
  ${(props) =>
    props.smallbutton &&
    css`
      padding: 5px 16px;
      ${Typography.body_sm_medium}
      @media (max-width: 449px) {
        padding: 5px 16px;
      }
    `}
  ${(props) =>
    props.invoiceDetailButton &&
    css`
      padding: 6px 20px;
      ${Typography.body_sm_medium}
      @media (max-width: 449px) {
        padding: 6px 20px;
      }
    `}
  ${(props) =>
    props.isMemberTask &&
    css`
      padding: 5px 16px;
      ${Typography.body_sm_medium}
      @media (max-width: 449px) {
        padding: 5px 16px;
      }
    `}
`;

const ButtonWrap = styled.div<Prop>`
  position: relative;
  display: inline-block;
  ${(props) =>
    props.isLoading &&
    css`
      &::before {
        position: absolute;
        top: 50%;
        left: calc(50% - 2px);
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: var(--text-white);
        z-index: 2;
        margin-top: 4px;
        -webkit-animation: 0.45s cubic-bezier(0, 0, 0.15, 1) infinite alternate ${ball};
        animation: 0.45s cubic-bezier(0, 0, 0.15, 1) infinite alternate ${ball};
        -webkit-animation-delay: 0.15s;
        animation-delay: 0.15s;
      }
      ${Buttons} {
        cursor: wait;
        color: transparent;
        &:hover {
          cursor: wait;
        }
        &::before {
          position: absolute;
          top: 50%;
          left: calc(50% - 2px);
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          content: '';
          display: block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: var(--text-white);
          z-index: 2;
          margin-top: 4px;
          -webkit-animation: 0.45s cubic-bezier(0, 0, 0.15, 1) infinite alternate ${ball};
          animation: 0.45s cubic-bezier(0, 0, 0.15, 1) infinite alternate ${ball};
          margin-left: -15px;
        }
        &::after {
          position: absolute;
          top: 50%;
          left: calc(50% - 2px);
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          content: '';
          display: block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: var(--text-white);
          z-index: 2;
          margin-top: 4px;
          -webkit-animation: 0.45s cubic-bezier(0, 0, 0.15, 1) infinite alternate ${ball};
          animation: 0.45s cubic-bezier(0, 0, 0.15, 1) infinite alternate ${ball};
          margin-left: 15px;
          -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
        }
      }
    `}
  ${(props) =>
    props.style &&
    css`
      &::before {
        background-color: grey;
      }
      ${Buttons} {
        &::before {
          background-color: grey;
        }
        &::after {
          background-color: grey;
        }
      }
    `}
    ${(props) =>
    props.width &&
    css`
      width: ${props.width}px;
    `}
        ${(props) =>
    props.isMicmodalbtn &&
    css`
      display: block;
    `}
    @media (max-width: 449px) {
    ${(props) =>
      props.resWidth &&
      css`
        width: ${props.resWidth}%;
      `}
    ${(props) =>
      props.isRecordButton &&
      css`
        width: max-content;
      `}
  }
`;

const ball = keyframes`
  from {
    -webkit-transform: translateY(0) scaleY(0.8);
    transform: translateY(0) scaleY(0.8);
  }
  to {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
`;

export { Buttons, ButtonWrap, ball };
