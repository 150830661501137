import styled from 'styled-components';

const Main = styled.div`
  width: 100%;
  background-color: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 12px;
  .loading-animation {
    overflow: hidden;
  }

  .loading-animation::before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    animation: loading 1s infinite;
    background: linear-gradient(to right, transparent, var(--background-tertiary), transparent);
  }

  @keyframes loading {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;

const Box = styled.div`
  border-bottom: 0.5px solid var(--border-primary);
  padding: 16px;
  display: flex;
  gap: 40px;
  justify-content: space-between;
  :last-child {
    border-bottom: none;
  }
  @media (max-width: 449px) {
    flex-direction: column;
    gap: 12px;
  }
`;
const Div = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  @media (max-width: 449px) {
    gap: 15px;
    flex-direction: column;
    align-items: flex-start;
  }
`;
const First = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  @media (max-width: 449px) {
    align-items: flex-start;
  }
`;

const Circle = styled.div`
  width: 18px;
  height: 18px;
  background-color: var(--background-muted);
  border-radius: 9px;
`;
const Longline = styled.div`
  width: 122px;
  height: 10px;
  background-color: var(--background-muted);
  border-radius: 7px;
  @media (max-width: 449px) {
    width: 230px;
    height: 12px;
  }
  @media (max-width: 320px) {
    width: 215px;
  }
`;
const Shortline = styled.div`
  width: 100px;
  height: 8px;
  background-color: var(--background-muted);
  border-radius: 7px;
  @media (max-width: 449px) {
    width: 142px;
    height: 10px;
  }
`;

const Second = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  @media (max-width: 449px) {
    display: none;
  }
`;

const Third = styled.div`
  display: flex;
  @media (max-width: 449px) {
    padding-left: 42px;
  }
`;

const Smallcircle = styled.div`
  width: 10px;
  height: 10px;
  background-color: var(--background-muted);
  border-radius: 5px;
`;
const Line = styled.div`
  width: 122px;
  height: 8px;
  background-color: var(--background-muted);
  border-radius: 7px;
`;

const UserLogo = styled.div`
  width: 20px;
  height: 20px;
  background-color: var(--background-muted);
  border-radius: 20px;
  border: 2px solid var(--background-primary);
  margin-left: -9px;
`;

const Circlediv = styled.div`
  width: 18px;
  height: 18px;
`;
export { Main, Box, First, Second, Third, Circle, Longline, Shortline, Line, Smallcircle, UserLogo, Div, Circlediv };
