import React from 'react';
import {
  CloseButton,
  FileBox,
  FileBoxContainer,
  FileBoxMain,
  FileIconDiv,
  FileInfo,
  FileSize,
  FileTitle,
  FileType,
  ImgageDiv
} from './style';
import { CommentFilesInterface } from '../../interfaces/TaskInterface';
import { extensionName } from '../../helpers/common';
import { FILE_EXTENTIONS, FILE_TYPES } from '../../global/constants';
import FileSVGIcon from '../../assets/images/svg/filesIconSvg';
import SVGIcon from '../../assets/images/svg/SVGIcon';

interface Props {
  file: CommentFilesInterface;
  projectId?: string;
  history?: any;
  onClickUpdate?: (e: React.SyntheticEvent) => void;
  isClose?: boolean;
  maxWidth: number;
  onClose?: () => void;
  href?: string;
}

export const RenderFilePreview = ({
  file,
  projectId,
  history,
  isClose,
  onClickUpdate,
  maxWidth,
  onClose,
  href
}: Props) => {
  //Other variable
  const extension = extensionName(file).toLowerCase();

  return (
    <FileBoxMain key={file?.size} maxWidth={maxWidth}>
      <a onClick={onClickUpdate} href={href} rel='noopener noreferrer'>
        <FileBoxContainer>
          <FileBox>
            <ImgageDiv className='image-div'>
              {/* File ICon design with text */}
              {(!file?.uploadFileType || file?.uploadFileType === FILE_TYPES.UPLOAD) &&
              !FILE_EXTENTIONS.includes(extension) ? (
                <FileIconDiv>
                  <FileSVGIcon name={'blank'} height='32' width='32' viewBox='0 0 60 60' />
                  <FileType
                    style={{
                      fontSize: extension?.length === 4 ? 7 : 5
                    }}
                    className='file-text'>
                    {extension?.length <= 5 && extension.split('.')?.pop()?.toUpperCase()}
                  </FileType>
                </FileIconDiv>
              ) : (
                <FileSVGIcon
                  name={
                    !file?.uploadFileType || file?.uploadFileType === FILE_TYPES.UPLOAD
                      ? extension
                      : `icon_${file?.uploadFileType}`
                  }
                  height='32'
                  width='32'
                  viewBox='0 0 60 60'
                />
              )}
            </ImgageDiv>
            {/* File name and File size  */}
            <FileInfo>
              <FileTitle className='h4'>{file?.name}</FileTitle>
              {parseFloat(file?.size) > 0 && <FileSize className='p'>{file?.size}</FileSize>}
            </FileInfo>
          </FileBox>
          {/* File Close/Remove Icon  */}
          {isClose && (
            <CloseButton onClick={onClose}>
              <SVGIcon name='close-icon' width='20' height='20' viewBox='0 0 18 18' fill='none' className='svgicon' />
            </CloseButton>
          )}
        </FileBoxContainer>
      </a>
    </FileBoxMain>
  );
};
