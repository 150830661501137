/* eslint-disable max-len */
import styled, { css } from 'styled-components';
import { Typography } from '../../../global/theme';

interface Props {
  isdark?: string;
  isLastData?: boolean;
}

const Group = styled.div`
  width: 100%;
  max-width: 1022px;
  margin: auto;
  /* height: 100dvh; */
  box-orient: vertical;
  flex-direction: column;
  display: flex;
  @media (max-width: 449px) {
    gap: 24px;
  }
`;

const Headerblock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  margin: 14px 0 24px;
  @media (max-width: 449px) {
    margin: 0 -16px;
    padding: 12px 16px;
    gap: 16px;
    background-color: var(--background-primary);
    border-bottom: 0.5px solid var(--border-primary);
    position: sticky;
    top: 0;
    z-index: 99;
  }
`;

const Input = styled.input`
  width: -webkit-fill-available;
  padding: 4px 11px;
  background: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 6px;
  ${Typography.body_lg}
  color: var(--text-primary);
  ::placeholder {
    color: var(--text-secondary);
  }
  :focus {
    border: 0.5px solid var(--brand-primary);
  }
  :focus-visible {
    outline: none;
  }
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
const Rightheading = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 12px;
  .active {
    background-color: var(--neutral-secondary);
    border-radius: 4px;
  }
  svg {
    cursor: pointer;
    fill: var(--text-secondary);
    stroke: var(--text-secondary);
  }
  @media (max-width: 449px) {
    gap: 6px;
  }
`;
const Icon = styled.div`
  display: inline-flex;
  .invoice-icon {
    fill: var(--brand-primary);
  }
  .more-icon {
    cursor: pointer;
    fill: var(--text-secondary);
    stroke: var(--text-secondary);
    :hover {
      background-color: var(--neutral-secondary);
      border-radius: 4px;
    }
  }
`;

const Title = styled.h1`
  ${Typography.heading_lg}
  color: var(--text-primary);
  margin: 0;
  white-space: nowrap;
`;
const Icons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;
const Icon1 = styled.div`
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  :hover {
    background-color: var(--neutral-secondary);
    border-radius: 4px;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-left: 25px;
  align-items: flex-start;
  padding-bottom: 24px;
  @media (max-width: 449px) {
    margin: 0;
    flex-direction: column;
    width: calc(100vw - 32px);
    gap: 24px;
  }
`;
const Leftcontent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 678px;
  position: sticky;
  top: 20px;
  @media (max-width: 449px) {
    position: unset;
    justify-content: center;
  }
`;

const Maindiv = styled.div<Props>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
  border: 0.5px solid var(--border-primary);
  background: var(--background-primary);
  max-width: 678px;
  @media (max-width: 449px) {
    gap: 24px;
    width: calc(100vw - 68px);
    padding: 0 8px;
    border-left: 0;
    border-right: 0;
  }
  .after {
    background: var(--background-tertiary);
    border: 0.5px solid var(--border-primary);
    width: 24px;
    height: 100%;
    border-radius: 0px 8px 8px 0px;
    ${(props: Props) =>
      props.isdark === 'light'
        ? css`
            background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 16 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fillRule='evenodd' clipRule='evenodd' d='M7 12.3422C10.3137 12.3422 13 9.69115 13 6.42087C13 3.1506 10.3137 0.499512 7 0.499512C3.68629 0.499512 1 3.1506 1 6.42087C1 9.69115 3.68629 12.3422 7 12.3422Z' fill='%23F6F9FC' stroke='%23E3E8EA' strokeWidth='0.888889'/%3E%3C/svg%3E");
          `
        : css`
            background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 16 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fillRule='evenodd' clipRule='evenodd' d='M7 12.3422C10.3137 12.3422 13 9.69115 13 6.42087C13 3.1506 10.3137 0.499512 7 0.499512C3.68629 0.499512 1 3.1506 1 6.42087C1 9.69115 3.68629 12.3422 7 12.3422Z' fill='%232E2E30' stroke='%232E2E30' strokeWidth='0.888889'/%3E%3C/svg%3E");
          `}
    background-repeat: repeat-y;
    background-position: center;
    background-size: 18px;
    position: absolute;
    right: -25px;
  }
  .before {
    background: var(--background-tertiary);
    position: absolute;
    border: 0.5px solid var(--border-primary);
    width: 24px;
    height: 100%;
    ${(props: Props) =>
      props.isdark === 'light'
        ? css`
            background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 16 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fillRule='evenodd' clipRule='evenodd' d='M7 12.3422C10.3137 12.3422 13 9.69115 13 6.42087C13 3.1506 10.3137 0.499512 7 0.499512C3.68629 0.499512 1 3.1506 1 6.42087C1 9.69115 3.68629 12.3422 7 12.3422Z' fill='%23F6F9FC' stroke='%23E3E8EA' strokeWidth='0.888889'/%3E%3C/svg%3E");
          `
        : css`
            background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 16 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fillRule='evenodd' clipRule='evenodd' d='M7 12.3422C10.3137 12.3422 13 9.69115 13 6.42087C13 3.1506 10.3137 0.499512 7 0.499512C3.68629 0.499512 1 3.1506 1 6.42087C1 9.69115 3.68629 12.3422 7 12.3422Z' fill='%232E2E30' stroke='%232E2E30' strokeWidth='0.888889'/%3E%3C/svg%3E");
          `}
    border-radius: 8px 0px 0px 8px;
    background-repeat: repeat-y;
    background-position: center;
    background-size: 18px;
    left: -25px;
  }
  @media (max-width: 449px) {
    .after {
      width: 16px;
      background-size: 8px;
      border-radius: 0 4px 4px 0;
      right: -17px;
    }
    .before {
      width: 16px;
      background-size: 8px;
      border-radius: 4px 0 0 4px;
      left: -17px;
    }
  }
`;

const Topinvoice = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 20px;
  @media (max-width: 449px) {
    flex-direction: column-reverse;
    gap: 24px;
    padding: 0;
  }
`;

const Invoiceleft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 30px;
  .invoice-logo-image {
    max-width: 40px;
    max-height: 40px;
    height: auto;
    border-radius: 4px;
  }
  @media (max-width: 449px) {
    width: 100%;
    margin-top: 0;
  }
`;
const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const Inputid = styled.div`
  display: flex;
  gap: 15px;
  justify-content: space-between;
  p {
    ${Typography.body_md_medium}
    color: var(--text-primary);
    margin: 0px;
    width: 169px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  h1 {
    ${Typography.body_md}
    color: var(--text-secondary);
    margin: 0px;
    align-items: flex-end;
    max-width: 70px;
    width: 100%;
  }
  @media (max-width: 449px) {
    justify-content: unset;
  }
`;
const Text = styled.p`
  ${Typography.body_md}
  color: var(--text-secondary);
  margin: 0px;
  align-items: flex-end;
  white-space: break-spaces;
  @media (max-width: 449px) {
    width: 100%;
  }
`;
const Textdate = styled.p`
  ${Typography.body_md}
  color: var(--text-secondary);
  margin: 0px;
  align-items: flex-end;
`;
const Info = styled.p`
  ${Typography.body_md_medium}
  color: var(--text-primary);
  margin: 0px;
  width: 200px;
  @media (max-width: 449px) {
    width: 100%;
  }
`;
const Info1 = styled.p`
  ${Typography.body_md_medium}
  color: var(--text-primary);
  margin: 0px;
`;
const Infotext = styled.p`
  ${Typography.body_md_medium}
  color: var(--text-primary);
  margin: 0px;
  width: 206px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const Invoicemiddle = styled.div`
  margin-top: -23px;
  display: flex;
  .paid-symbol {
    width: 100px;
    height: 100px;
    clip-path: inset(24% 0 0 0);
  }
  @media (max-width: 449px) {
    margin-top: 0;
    position: absolute;
    top: 5px;
    right: 5px;
    .paid-symbol {
      width: 50px;
      height: 50px;
    }
  }
`;
const Invoiceright = styled.div`
  margin: 30px 0 0;
  display: flex;
  flex-direction: column;
  gap: 46px;
  width: 273px;
  @media (max-width: 449px) {
    max-width: 100%;
    width: 100%;
    gap: 18px;
    margin: 0;
  }
`;
const Invoicetitle = styled.div`
  text-align: center;
  ${Typography.heading_lg}
  color: var(--text-secondary);
`;
const Address = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 449px) {
    gap: 24px;
  }
`;
const From = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 20px;
  @media (max-width: 449px) {
    gap: 4px;
    justify-content: unset;
    width: 100%;
    flex-direction: column;
  }
`;
const Fromaddress = styled.div`
  display: flex;
  flex-direction: column;
  width: 178px;
  @media (max-width: 449px) {
    width: 100%;
  }
`;

const Invoicetable = styled.div``;
const Activitycontent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 6px;
`;
const Gheader = styled.div`
  padding: 11px 20px;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  border-top: 0.5px solid var(--border-primary);
  background: var(--background-tertiary);
  margin-top: 30px;
`;
const Rightheader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Label = styled.p`
  ${Typography.body_md_medium}
  color: var(--text-secondary);
  margin: 0px;
`;
const Quantity = styled.p`
  ${Typography.body_md_medium}
  color: var(--text-secondary);
  border-right: 0.5px solid var(--border-primary);
  padding: 0px 12px 0px 0px;
  margin: 0px;
  text-transform: uppercase;
`;
const Amount = styled.p`
  ${Typography.body_md_medium}
  color: var(--text-secondary);
  padding-left: 53px;
  margin: 0px;
  text-transform: uppercase;
`;
const Price = styled.p`
  ${Typography.body_md_medium}
  color: var(--text-secondary);
  border-right: 0.5px solid var(--border-primary);
  padding: 0px 12px 0px 27px;
  margin: 0px;
  text-transform: uppercase;
`;
const Row = styled.div`
  padding: 8px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px;
  gap: 12px;
  border-top: 0.5px solid var(--border-primary);
  @media (max-width: 449px) {
    width: 100%;
    flex-direction: column;
    padding: 0;
    border: 0.5px solid var(--border-primary);
    border-radius: 4px;
    gap: 0;
  }
`;
const Leftrow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin: 0px;
  max-width: 362px;
  width: 100%;
  @media (max-width: 449px) {
    background-color: var(--background-secondary);
    max-width: none;
    padding: 10px;
    border-bottom: 0.5px solid var(--border-primary);
    width: calc(100% - 20px);
    border-radius: 4px 4px 0 0;
  }
`;

const Rightrow = styled.div`
  display: flex;
  margin: 0px;
  @media (max-width: 449px) {
    flex-direction: column;
    width: 100%;
  }
`;

const TableDataResponsive = styled.div<Props>`
  @media (max-width: 449px) {
    border-bottom: 0.5px solid var(--border-primary);
    padding: 8px 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    ${(props) =>
      props.isLastData &&
      css`
        border-bottom: none;
      `}
  }
`;

const InvoiceTableTitle = styled.h5`
  display: none;
  @media (max-width: 449px) {
    display: block;
    color: var(--text-secondary);
    margin: 0px;
    text-transform: uppercase;
  }
`;

const Inforate = styled.p`
  ${Typography.body_md_medium}
  color: var(--text-primary);
  margin: 0px;
  text-align: end;
  width: 88px;
  padding: 0 12px;
  @media (max-width: 449px) {
    padding: 0;
    text-align: unset;
    width: auto;
  }
`;

const Total = styled.div<{ isHideBottomBorder: boolean }>`
  padding: 20px;
  border-top: 0.5px solid var(--border-primary);
  border-bottom: 0.5px solid var(--border-primary);
  gap: 12px;
  display: flex;
  flex-direction: column;
  ${(props: { isHideBottomBorder: boolean }) =>
    props.isHideBottomBorder &&
    css`
      border-bottom: none;
    `}
  @media (max-width: 449px) {
    border-top: none;
    padding: 24px 8px 30px;
    margin: 0 -8px;
    gap: 10px;
  }
`;

const TopcontentTotal = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  @media (max-width: 449px) {
    gap: 4px;
  }
`;
const Subtotal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  gap: 24px;
  @media (max-width: 449px) {
    justify-content: space-between;
  }
`;

const Name = styled.div`
  ${Typography.body_md}
  color: var(--text-secondary);
`;

const Totalamount = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  gap: 24px;
  @media (max-width: 449px) {
    justify-content: space-between;
    padding-top: 12px;
    border-top: 0.5px solid var(--border-primary);
  }
`;
const Totalcontent = styled.p`
  ${Typography.heading_md}
  align-items: center;
  margin: 0;
  width: 100px;
  text-align: end;
  color: var(--text-primary);
`;
const Totalcontents = styled.p`
  ${Typography.heading_md}
  align-items: center;
  margin: 0;
  color: var(--text-primary);
`;
const Totalrate = styled.div`
  ${Typography.body_md_medium}
  color: var(--text-primary);
  margin: 0px;
  width: 100px;
  text-align: end;
`;
const Notediv = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  p {
    ${Typography.body_md}
    color: var(--text-primary);
    margin: 0px;
    white-space: break-spaces;
  }
  h2 {
    ${Typography.heading_sm}
    align-items: center;
    color: var(--text-primary);
    margin: 0px;
  }
  @media (max-width: 449px) {
    padding: 15px 0;
    gap: 6px;
  }
`;
const Activity = styled.div`
  background: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 8px;
  padding: 16px;
`;
const Activitybottom = styled.div`
  max-width: 274px;
  width: 100%;
  @media (max-width: 449px) {
    max-width: calc(100vw - 32px);
  }
`;
const Activityrow = styled.li`
  border-top: 0.5px solid var(--border-primary);
  padding-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 17px;
  padding-inline-start: 0;
`;
const Activityleft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  @media (max-width: 449px) {
    width: 100%;
  }
`;
const Activityright = styled.div`
  text-align: center;
  .active {
    display: inline-flex;
    background-color: var(--neutral-secondary);
    border-radius: 4px;
  }
`;
const Recorddropdown = styled.div`
  padding: 16px 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const Recorddetail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  .ant-picker {
    width: 100%;
    background-color: var(--background-primary);
    border: 0.5px solid var(--border-primary);
    padding: 7px 12px;
  }
  .ant-picker .ant-picker-input > input {
    color: var(--text-primary);
    ${Typography.body_lg_medium}
  }
  .ant-picker .ant-picker-suffix > * path {
    fill: var(--text-primary);
  }
  .ant-picker-input > input::placeholder,
  .ant-picker-focused .ant-picker-input > input::placeholder {
    color: var(--text-secondary);
  }
  .ant-picker-focused {
    border: 0.5px solid var(--brand-primary);
    box-shadow: var(--shadow-input-focus);
  }
  .ant-input {
    background-color: var(--background-primary);
    border: 0.5px solid var(--border-primary);
    color: var(--text-primary);
    ${Typography.body_lg_medium}
  }
  .ant-input::placeholder {
    color: var(--text-secondary);
  }
  .ant-input:focus {
    border: 0.5px solid var(--brand-primary);
    box-shadow: var(--shadow-input-focus);
  }
  .ant-picker .ant-picker-clear > * {
    background-color: var(--background-primary);
  }
  .ant-picker .ant-picker-clear {
    color: var(--brand-primary);
  }
  [type='number'] {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background-color: var(--brand-primary) !important;
  }
  .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 0.5px solid var(--brand-primary) !important;
  }
  .ant-picker-dropdown .ant-picker-header-view button:hover {
    color: var(--brand-primary) !important ;
  }
  .ant-picker-dropdown .ant-picker-header button {
    color: var(--text-secondary) !important;
  }
`;

const InputDiv = styled.input`
  min-width: 202px;
  border: 0.5px solid var(--border-primary);
  border-radius: 6px;
  padding: 7px 12px;
  color: var(--text-primary);
  ${Typography.body_lg}
  ::placeholder {
    color: var(--text-secondary);
  }
  :focus {
    border: 0.5px solid var(--brand-primary);
  }
  :focus-visible {
    border: 0.5px solid var(--brand-primary);
    outline: none;
  }
`;

const TextArea = styled.textarea`
  min-width: 204px;
  min-height: 60px;
  overflow: hidden;
  padding: 7px 12px;
  ${Typography.body_lg}
  color: var(--text-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 6px;
  resize: none;
  scrollbar-width: none;
  :focus {
    border: 0.5px solid var(--brand-primary);
  }
  :focus-visible {
    border: 0.5px solid var(--brand-primary);
    outline: none;
  }
`;

const Quantityrate = styled.p`
  ${Typography.body_md_medium}
  color: var(--text-primary);
  margin: 0px;
  width: 28px;
  text-align: center;
  padding-right: 12px;
  padding-left: 12px;
  @media (max-width: 449px) {
    padding: 0;
    width: auto;
  }
`;

const Amountrate = styled.p`
  ${Typography.body_md_medium}
  color: var(--text-primary);
  margin: 0px;
  width: 100px;
  text-align: end;
  padding-left: 12px;
  @media (max-width: 449px) {
    padding: 0;
    width: auto;
  }
`;

const InvoiceResponsive = styled.div`
  @media (max-width: 449px) {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    justify-content: center;
    padding: 18px 0;
    .invoice-logo-image {
      max-width: 40px;
      max-height: 40px;
      height: auto;
      border-radius: 4px;
    }
  }
`;

const SvgIconDiv = styled.div`
  background: var(--brand-primary);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  .send-icon {
    fill: var(--text-white);
    stroke: var(--text-white);
  }
`;

const InvoiceCard = styled.div`
  @media (max-width: 449px) {
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: center;
  }
`;
const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const ResponsiveNavbarIcon = styled.div`
  @media (max-width: 449px) {
    display: flex;
  }
`;
export {
  Group,
  Headerblock,
  Heading,
  Icon,
  Title,
  Rightheading,
  Icons,
  Icon1,
  Content,
  Leftcontent,
  Maindiv,
  Activity,
  Invoicetable,
  Topinvoice,
  Invoiceright,
  Invoicemiddle,
  Invoiceleft,
  Details,
  Input,
  Inputid,
  Text,
  Info,
  Infotext,
  Fromaddress,
  From,
  Address,
  Invoicetitle,
  Activitycontent,
  Gheader,
  Quantity,
  Amount,
  Label,
  Price,
  Rightheader,
  Row,
  Rightrow,
  Leftrow,
  Inforate,
  Total,
  Subtotal,
  Name,
  Totalamount,
  Totalcontent,
  Totalrate,
  Notediv,
  Activityrow,
  Activityleft,
  Activityright,
  Recorddropdown,
  Recorddetail,
  Amountrate,
  Quantityrate,
  Activitybottom,
  Info1,
  Totalcontents,
  Textdate,
  InvoiceResponsive,
  SvgIconDiv,
  InvoiceCard,
  TopcontentTotal,
  TableDataResponsive,
  InvoiceTableTitle,
  HeaderLeft,
  ResponsiveNavbarIcon,
  InputDiv,
  TextArea
};
