import UserPreferenceSingleton from '../helpers/userPreferenceSingleton';
import LocalDbService, { FILES_TABLE_NAME, UPDATE_ON_ID } from './localDbService';

let singleton: any;
let organizationId: any;

export default class FilesCacheService extends LocalDbService {
  constructor(props: any) {
    const db_name = `${props.organizationId}`;
    props.dbName = db_name;
    props.tableName = FILES_TABLE_NAME;
    super(props);
  }

  static getInstance() {
    const company = UserPreferenceSingleton.getInstance().getWorkspace();
    if (company) {
      if (!singleton || organizationId !== company?.id) {
        organizationId = company?.id;
        singleton = new FilesCacheService({ organizationId: company?.id });
      }
    }
    return singleton;
  }

  static removeInstance() {
    if (singleton) singleton.closeDb();
    organizationId = undefined;
    singleton = undefined;
  }

  // async getFiles() {
  //     const result = await this.get();
  //     return result;
  // }

  async getFilesByTable(tableId: string) {
    if (tableId && this.db) {
      const rows = await this.getDb().where('tableId').equals(tableId).toArray();
      const result = rows?.map((x: any) => x?.value);
      return result;
    }
    return [];
  }

  async getFilesByTableIds(tableIds: any) {
    if (tableIds && tableIds.length > 0 && this.db) {
      const rows = await this.getDb().where('tableId').anyOf(tableIds).toArray();
      const result = rows.map((x: any) => x.value);
      return result;
    }
    return [];
  }

  async setFiles(projectId: string, value: any, companyId: string) {
    if (!value || companyId !== organizationId) return false;
    const filesData = [
      {
        id: projectId,
        value: value
      }
    ];
    const result = await this.addBulk(filesData);
    return result;
  }

  async updateFiles(newList: any, archivedList: any, companyId: any) {
    if (companyId !== organizationId) return false;
    await this.syncList(newList, archivedList);
  }

  async updateProjectFiles(data: any, archived: any, projectId: string) {
    if (!projectId) return;
    const projectFiles = await this.getItem(projectId);
    let fieldValueOnly: any = projectFiles?.value;

    if (data?.length > 0) {
      data?.forEach((item: any) => {
        let status = false;
        fieldValueOnly = fieldValueOnly?.map((x: any) => {
          if (x?.id === item?.id) {
            status = true;
            return item;
          } else return x;
        });
        if (!status) {
          fieldValueOnly = [...(fieldValueOnly || []), item];
        }
      });
    }
    if (archived?.length > 0) {
      archived?.forEach((item: any) => {
        const dataIndex = fieldValueOnly?.findIndex((x: any) => x?.id === item?.id);
        if (dataIndex !== -1) {
          fieldValueOnly?.splice(dataIndex, 1);
        }
      });
    }
    const fileFields = [
      {
        id: projectId,
        value: fieldValueOnly
      }
    ];
    const result = await this.addBulk(fileFields);
    return result;
  }

  async getLastUpdatedTime(projectId?: string) {
    const result = await super.getLastUpdatedTime(`${UPDATE_ON_ID.FILES}_${projectId}`);
    return result;
  }

  async setLastUpdatedTime(value: any, companyId: string, projectId?: string) {
    if (companyId !== organizationId) return false;
    const result = await super.setLastUpdatedTime(`${UPDATE_ON_ID.FILES}_${projectId}`, value);
    return result;
  }
}
