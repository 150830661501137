import React, { useCallback, useEffect, useState } from 'react';
import { DropHeader, InputArea } from './style';
interface Props {
  onChangeSearch?: (value: string) => void;
}
function DropdownSearch({ onChangeSearch }: Props) {
  const [searchQuery, setSearchQuery] = useState('');

  // On search change
  const onUpdateSearch = useCallback(
    (value: string) => {
      setSearchQuery(value);
      onChangeSearch?.(value);
    },
    [onChangeSearch]
  );

  useEffect(() => {
    return () => {
      onChangeSearch?.('');
    };
  }, [onChangeSearch]);

  return (
    <div>
      <DropHeader className='search-div' onClick={(e) => e.stopPropagation()}>
        <InputArea
          type='text'
          placeholder='Search'
          autoFocus
          value={searchQuery}
          onChange={(e) => onUpdateSearch(e.target.value)}
        />
      </DropHeader>
    </div>
  );
}

export default DropdownSearch;
