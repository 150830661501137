import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AppLayout from '../../component/appLayout';
import {
  Borderdiv,
  Bottomsection,
  Empty,
  Export,
  Firstcontent,
  Icon1,
  LeftContant,
  Main,
  Maincontent,
  RightContant,
  Text,
  Timetitle,
  Topheader,
  Topicdescription,
  Topictitle
} from './styles';
import SVGIcon from '../../assets/images/svg/SVGIcon';
import { useDispatch, useSelector } from 'react-redux';
import { loadEditTimeLog, loadMyTimeLog } from '../../services/timeTrackingServices';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';
import { SelectRangeInterface } from '../../interfaces/ReportInterface';
import { getDayFromDate, getMonthRange, getTextForDateRange, isEmpty } from '../../helpers/common';
import { getUserPreferenceFieldData } from '../../helpers/firebaseHelper';
import moment from 'moment';
import { ProjectDetailInterface } from '../../interfaces/ProjectInterface';
import { getProjectList } from '../../services/projectServices';
import { TimeLogInterface } from '../../interfaces/TimeReportInterface';
import { MyTimeLogCSVHeader } from '../../global/row';
import { CSVLink } from 'react-csv';
import reportdarkempty from '../../assets/images/emptystates/reportdarkempty.svg';
import reportempty from '../../assets/images/emptystates/report-empty.svg';
import EmptyState from '../../component/emptyState';
import ReportTimelineLoading from '../../component/loading/reporttimelineLoading';
import AddNewTimeMoadal from '../../component/models/addNewTimeModel/addNewTimeModal';
import ModalCustom from '../../component/models/modal';
import { clearTimeEntryInput, setMyTimeLogFilter, setTimeEntryInput } from '../../actions/timeTrackingActions';
import MyTimelogHeader from './myTimelogHeader';

export default function MyTimelog() {
  // States
  const [loading, setLoading] = useState(false);
  const [totalTime, setTotalTime] = useState('0 hours 0 minutes');
  const [filterLogList, setFilterLogList] = useState<TimeLogInterface[]>([]);
  const [open, setOpen] = useState(false);

  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const {
    settings: settingsSelector,
    workspace: workspaceSelector,
    timeTracking: timeTrackingSelector
  } = stateSelector || {};
  const { workspace } = workspaceSelector;
  const { themeMode } = settingsSelector;
  const { myTimeLog } = timeTrackingSelector;

  // Other variables
  const dispatch = useDispatch();

  // get image source based on theme
  const getImageSource = () => (themeMode?.theme === 'dark' ? reportdarkempty : reportempty);

  // for calculating total time
  const calculateTotalTime = useCallback((list: TimeLogInterface[]) => {
    const total = list?.reduce((acc: number, item: TimeLogInterface) => {
      return acc + (item?.hours || 0);
    }, 0);
    const timeString = convertDecimalToHoursMinutes(total, true);
    setTotalTime(timeString);
  }, []);

  // implement filter logic
  const applyFilter = useCallback(
    async (
      projects: ProjectDetailInterface[],
      selectionRange: SelectRangeInterface,
      myTimeReportData?: TimeLogInterface[]
    ) => {
      const selectedProjects = projects?.filter((x) => x?.checked);
      const selectedProjectIds = selectedProjects?.map((x) => x?.id);
      if (!myTimeReportData) myTimeReportData = myTimeLog;
      let timeLogData: TimeLogInterface[] = JSON.parse(JSON.stringify(myTimeReportData));
      const startDate = moment(selectionRange.startDate);
      const endDate = moment(selectionRange.endDate).add(1, 'day');
      timeLogData = timeLogData?.filter(
        (item) =>
          selectedProjectIds?.includes(item?.projectId) &&
          moment(item?.date) >= startDate &&
          moment(item?.date) <= endDate
      );
      const formattedTimeLogData = timeLogData?.map((item) => ({
        ...(item || {}),
        day: getDayFromDate(item?.date)
      }));
      setFilterLogList(formattedTimeLogData || []);
      calculateTotalTime(timeLogData || []);
    },
    [calculateTotalTime, myTimeLog]
  );

  // initial load data
  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      if (!workspace?.id) return;
      setFilterLogList([]);
      const result = await getUserPreferenceFieldData(`my_timelog_filter_${workspace?.id}`);
      const monthRange = getMonthRange(result?.selected_range?.startDate, result?.selected_range?.endDate);
      const [logList, projectsResponse] = await Promise.all([
        dispatch(loadMyTimeLog(workspace?.id, monthRange)),
        dispatch(getProjectList(workspace?.id))
      ]);
      const updatedProjectsResponse = projectsResponse?.map((item: ProjectDetailInterface) => {
        return { id: item?.id, checked: true };
      });

      const hasNewProjects =
        !result?.project_list ||
        updatedProjectsResponse.some(
          (project: { id: string }) => !result.project_list.some((p: { id: string }) => p.id === project?.id)
        );

      const updatedDateRange = result?.selected_range
        ? {
            startDate: new Date(result?.selected_range?.startDate).toISOString(),
            endDate: new Date(result?.selected_range?.endDate).toISOString(),
            key: 'selection'
          }
        : {
            startDate: new Date(new Date().setHours(0, 0, 0, 0) - 330 * 60 * 1000).toISOString(),
            endDate: new Date(new Date().setHours(0, 0, 0, 0) - 330 * 60 * 1000).toISOString(),
            key: 'selection'
          };
      const selectedText = getTextForDateRange(updatedDateRange.startDate, updatedDateRange.endDate);
      const data = {
        ...(result || {}),
        project_list: hasNewProjects ? updatedProjectsResponse : result?.project_list,
        selected_text: selectedText,
        selected_range: updatedDateRange,
        selected_all_project: isEmpty(result?.selected_all_project) ? true : result?.selected_all_project
      };
      dispatch(setMyTimeLogFilter(data));

      await applyFilter(
        !data?.project_list ? projectsResponse : data?.project_list,
        !data?.selected_range
          ? {
              startDate: new Date(),
              endDate: new Date(),
              key: 'selection'
            }
          : {
              startDate: new Date(data?.selected_range?.startDate),
              endDate: new Date(data?.selected_range?.endDate),
              key: data?.selected_range?.key
            },
        logList
      );
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace?.id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  // open update modal
  const handleOpenUpdateModal = useCallback(
    async (item: TimeLogInterface) => {
      dispatch(setTimeEntryInput(item));
      await dispatch(loadEditTimeLog());
      setOpen(true);
    },
    [dispatch]
  );

  // close add modal
  const closeAddModal = useCallback(() => {
    setOpen(false);
    dispatch(clearTimeEntryInput());
  }, [dispatch]);

  // convert decimal to hours and minutes
  const convertDecimalToHoursMinutes = (decimalHours: number, isFullString: boolean, withoutInitials?: boolean) => {
    const hours = Math.floor(decimalHours); // Get the integer part (hours)
    const minutes = Math.round((decimalHours - hours) * 60); // Convert decimal to minutes
    return isFullString
      ? `${hours} hours ${minutes} minutes`
      : withoutInitials
      ? `${hours} : ${minutes}`
      : `${hours}h ${minutes}m`;
  };

  // Prepares and memoizes CSV data with formatted dates.
  const getExportCsvData = useMemo(() => {
    const csvData = filterLogList?.map((item) => ({
      ...(item || {}),
      date: moment(item?.date).format('DD-MM-YYYY')
    }));
    return csvData || [];
  }, [filterLogList]);

  // render filter log list
  const renderFilterLogList = useMemo(() => {
    return filterLogList?.map((item: TimeLogInterface, index) => {
      const time = convertDecimalToHoursMinutes(item?.hours, false);
      return (
        <Firstcontent key={`${item.date + item.taskId}`}>
          <LeftContant>
            <Topictitle>{item?.projectName}</Topictitle>
            <Topicdescription>{item?.taskName}</Topicdescription>
          </LeftContant>
          <RightContant>
            <Timetitle>
              <span>{time}</span>
            </Timetitle>
            <Borderdiv />
            <Icon1 onClick={() => handleOpenUpdateModal(item)}>
              <SVGIcon name='edit-icon' width='18' height='19' viewBox='0 0 18 19' fill='none' />
            </Icon1>
          </RightContant>
        </Firstcontent>
      );
    });
  }, [filterLogList, handleOpenUpdateModal]);

  return (
    <>
      <AppLayout>
        <Main>
          <MyTimelogHeader
            loading={loading}
            setLoading={setLoading}
            setTotalTime={setTotalTime}
            setFilterLogList={setFilterLogList}
          />
          {!loading && filterLogList?.length > 0 && (
            <Bottomsection>
              <Topheader>
                <Text>Total time logged: {totalTime}</Text>
                <CSVLink
                  data={getExportCsvData}
                  headers={MyTimeLogCSVHeader}
                  filename={`time_log_report.csv`}
                  style={{ textDecoration: 'none' }}>
                  <Export>
                    <a>Export</a>
                  </Export>
                </CSVLink>
              </Topheader>
              <Maincontent>{renderFilterLogList}</Maincontent>
            </Bottomsection>
          )}
          {loading && filterLogList?.length === 0 && <ReportTimelineLoading />}
          {!loading && filterLogList?.length === 0 && (
            <Empty>
              <EmptyState
                header={`You don't have time log data`}
                title={'There are no time log data for this date. Please try another Filters.'}
                image={getImageSource()}
                name={''}
                hideButton={true}
              />
            </Empty>
          )}
        </Main>
        <ModalCustom open={open} onClose={closeAddModal} isTracker={true}>
          <AddNewTimeMoadal onCloseModal={closeAddModal} loadData={loadData} isMyTimeLogModal={true} />
        </ModalCustom>
      </AppLayout>
    </>
  );
}
