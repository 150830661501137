import styled, { css } from 'styled-components';
import { Typography } from '../../global/theme';

interface Props {
  isActive?: boolean;
  isTeamcontainer?: boolean;
  isMiddlecontainer?: boolean;
  isdeleticonhover?: boolean;
  isNewRow?: boolean;
  isDelete?: boolean;
}

const Group = styled.form<Props>`
  width: 100%;
  max-width: 662px;
  margin: auto;
  margin-bottom: 24px;
`;
const Container = styled.div`
  background-color: var(--background-secondary);
`;

const Settingcontent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 14px 0 24px;
`;

const Content = styled.div`
  display: flex;
  gap: 20px;
`;

const Icon = styled.div<Props>`
  display: flex;
  cursor: pointer;
  .delete-icon {
    path {
      fill: var(--text-secondary);
    }
  }
  .delete-icon {
    fill: var(--text-secondary);
  }
  .more-icon {
    fill: var(--text-secondary);
    stroke: var(--text-secondary);
    :hover {
      background: var(--neutral-secondary);
      border-radius: 4px;
    }
  }
  .profile-image {
    border-radius: 50%;
    width: 60px;
    height: 60px;
  }
  .invoice-logo-image {
    border-radius: 4px;
    max-width: 40px;
    max-height: 40px;
    height: auto;
  }
  ${(props: Props) =>
    props.isdeleticonhover &&
    css`
      width: 24px;
      height: 24px;
      align-items: center;
      justify-content: center;
      :hover {
        background-color: var(--neutral-secondary);
        border-radius: 4px;
      }
    `}
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  color: var(--text-primary);
`;

const Title = styled.h1`
  ${Typography.heading_lg}
  margin: 0;
`;

const Buttonblock = styled.div`
  display: flex;
  gap: 5px;
  cursor: pointer;
`;

const Taskbutton = styled.button<Props>`
  ${Typography.body_md_medium}
  background-color: var(--background-secondary);
  color: var(--border-secondary);
  padding: 7px 21px;
  border: 0.5px solid transparent;

  ${(props: Props) =>
    props.isActive &&
    css`
      ${Typography.body_md_medium}
      background-color: var(--text-white);
      border: 0.5px solid var(--border-primary);
      box-shadow: var(--shadow-button);
      border-radius: 36px;
      padding: 7px 21px;
      color: var(--text-secondary);
    `}
`;

const Details = styled.div<Props>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 19px 20px;
  background-color: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 8px;
  align-items: flex-start;
  ${(props) =>
    props.isDelete &&
    css`
      gap: 12px;
    `}
`;

const Title1 = styled.h2`
  ${Typography.heading_md}
  color: var(--text-primary);
  margin: 0;
`;

const Text = styled.p`
  ${Typography.body_md}
  color: var(--text-secondary);
  margin: 0;
`;

const Userprofile = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const Uploadtext = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Title2 = styled.div`
  ${Typography.body_md_medium}
  color: var(--text-primary);
  margin: 0;
  cursor: pointer;
`;

const Formblock = styled.div<Props>`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  ${(props: Props) =>
    props.isNewRow &&
    css`
      flex-direction: column;
    `}
`;

const FormMain = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Form = styled.div<Props>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  .angledown-icon {
    path {
      stroke: var(--text-primary);
    }
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 34px;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item {
    line-height: 20px;
  }
  .ant-select-selection-search {
    height: 34px;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    color: var(--text-primary);
    padding: 6px 0;
    padding-right: 28px;
  }
  .ant-select-selection-item {
    height: 32px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 36px;
  }
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover
    .ant-select-selector {
    border: 0.5px solid var(--border-primary);
    background-color: var(--background-primary);
  }
  .ant-select-focused.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
      .ant-pagination-size-changer
    )
    .ant-select-selector {
    border: 0.5px solid var(--brand-primary);
    z-index: 999;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 0.5px solid var(--border-primary);
    background-color: var(--background-primary);
  }
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    color: var(--text-secondary);
    padding: 8px 0;
  }
  .ant-select-focused.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
      .ant-pagination-size-changer
    )
    .ant-select-selector {
    border: 0.5px solid var(--brand-primary);
    box-shadow: var(--shadow-input-focus);
  }
  &.ant-select-item-option-content {
    color: var(--text-primary);
  }
  .ant-select-single .ant-select-selector {
    color: var(--text-primary);
    ${Typography.body_md}
  }
  .ant-select .ant-select-arrow {
    padding-top: 5px !important;
  }
  .ant-select-dropdown .ant-select-item-option-content {
    white-space: normal !important;
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    word-break: break-all;
    -webkit-box-orient: vertical !important;
  }
  .ant-select-single {
    height: 36px;
  }
  .ant-select .ant-select-arrow svg {
    margin-bottom: 5px;
  }
  /* .ant-select-item-option-content {
    color: var(--text-primary);
    ${Typography.body_md}
  } */
`;

const Label = styled.label`
  ${Typography.body_md_medium}
  color: var(--text-secondary);
`;

const InputDiv = styled.div`
  position: relative;
  :focus-within .angledown-icon {
    display: none;
  }
`;

const IconDiv = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const Inputvalue = styled.input`
  width: 234px;
  ${Typography.body_lg_medium}
  color: var(--text-primary);
  background-color: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 6px;
  padding: 8px 12px;
  outline: none;
  :focus {
    border: 0.5px solid var(--brand-primary);
    z-index: 999;
  }
`;

const Projectcontent = styled.div<Props>`
  max-width: 662px;
  background-color: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  margin: auto;
  border-radius: 8px;
  margin-bottom: 45px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

const Subcontent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px 20px;
`;

const InputNameTitle = styled.div`
  ${Typography.body_md_medium}
  color: var(--text-secondary);
`;

const InputAddressTitle = styled.div`
  ${Typography.body_md_medium}
  color: var(--text-secondary);
  margin-top: 10px;
`;

const Autotext = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  max-width: 532px;
  width: 100%;

  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--brand-primary);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    top: 1px;
    left: 1px;
    background-color: var(--text-white);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: var(--brand-primary);
  }

  input:focus + .slider {
    box-shadow: 0 0 1px var(--brand-primary);
  }

  input:checked + .slider:before {
    transform: translateX(20px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;

const Title4 = styled.h5`
  ${Typography.body_md_medium}
  color: var(--text-primary);
  margin: 0;
`;

const InputSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-top: 6px;
  background-color: var(--background-tertiary);
  border-radius: 4px;
  gap: 6px;
`;

const Formcontent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 10px;
  margin-top: 6px;
  background-color: var(--background-tertiary);
  border-radius: 4px;
`;

const NameInput = styled.input`
  ${Typography.body_md}
  color: var(--text-primary);
  background-color: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 6px;
  padding: 5px 12px;
  outline: none;

  :focus {
    border: 0.5px solid var(--brand-primary);
  }
  ::placeholder {
    color: var(--border-secondary);
  }
`;

const Inputside = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  .label {
    margin-bottom: 20px;
  }
  .first {
    width: 308px;
  }
  .second {
    width: 110px;
  }
  .note {
    width: 450px;
  }
  p {
    ${Typography.body_md}
    color: var(--text-secondary);
    margin: 0;
  }
  @media (max-width: 449px) {
    flex-wrap: wrap;
  }
`;

const Inputvalue1 = styled.input`
  width: 226px;
  ${Typography.body_md}
  color: var(--text-primary);
  background-color: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 6px;
  padding: 5px 12px;
  outline: none;

  :focus {
    border: 0.5px solid var(--brand-primary);
  }
  ::placeholder {
    color: var(--border-secondary);
  }

  &[type='number'] {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

const Addtask = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const Text1 = styled.p`
  ${Typography.body_md_medium}
  color: var(--brand-primary);
  margin: 0;
  cursor: pointer;
  display: inline-flex;
`;

const Features = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  .option {
    gap: 10px;
  }
  .ant-radio-wrapper {
    color: var(--text-secondary);
    ${Typography.body_md}
  }
  .ant-radio-wrapper .ant-radio-inner:not(.ant-radio-checked) {
    border: 1px solid var(--border-secondary);
    background-color: var(--background-primary);
  }
  .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    background-color: var(--brand-primary);
    border: var(--brand-primary);
  }
`;
const Invoicedue = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Paymentcontent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Paymentblock = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 8px;
`;

const Title5 = styled.h5`
  ${Typography.heading_md}
  color: var(--text-primary);
  margin: 0;
`;

const Textblocks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Text2 = styled.p`
  ${Typography.body_md}
  color: var(--text-secondary);
  margin: 0;
`;

const Text3 = styled.a`
  ${Typography.body_md_medium}
  color: var(--brand-primary);
  margin: 0;
  text-decoration: none;
  width: fit-content;
`;

const Text4 = styled.p`
  ${Typography.body_md}
  color: var(--text-secondary);
  margin: 0;
  span {
    font-weight: 700;
  }
`;

const Tax = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Teammate = styled.div`
  padding: 13px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid var(--border-primary);
  :last-child {
    border-bottom: none;
  }
  .pending {
    opacity: 0.6;
  }
`;
const PersonInfo = styled.div`
  display: flex;
  gap: 12px;
`;

const TeammateName = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  h1 {
    ${Typography.body_md_medium}
    color: var(--text-primary);
    margin: 0;
  }
  p {
    ${Typography.body_sm}
    color: var(--text-secondary);
    margin: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    word-break: break-all;
  }
`;

const Pendingbutton = styled.button`
  padding: 2px 5px;
  ${Typography.body_sm}
  color: var(--text-secondary);
  background: var(--border-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 6px;
`;
const Personaltext = styled.div`
  ${Typography.heading_lg}
  color: var(--text-primary);
`;
const PersonalHeader = styled.div<Props>`
  max-width: 662px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px auto 12px;
  gap: 30px;
  @media (max-width: 449px) {
    margin: 0;
    padding: 20px 0 12px;
  }
`;
const Pesonalbtns = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const Profileinfo = styled.div`
  max-width: 662px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  gap: 20px;
  background: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 8px;
`;
const Notificationcontent = styled.div`
  background-color: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 8px;
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;
const Notificationdiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
`;
const Pending = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 12px;
  align-items: center;
  .active {
    background: var(--neutral-secondary);
    border-radius: 4px;
  }
`;

const AdminText = styled.div`
  padding: 2px 5px;
  ${Typography.body_sm}
  color: var(--text-secondary);
  background: var(--border-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 6px;
`;

const Rightdiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  .active {
    display: flex;
    background: var(--neutral-secondary);
    border-radius: 4px;
  }
`;
const Top = styled.div`
  border-radius: 6px;
  border: 1px solid var(--neutral-secondary);
  background: var(--neutral-secondary);
  color: var(--text-secondary);
  ${Typography.body_sm}
  padding: 2px 6px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Middle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const Connectbtn = styled.div`
  padding: 3px 8px;
  border-radius: 6px;
  border: 1px solid var(--neutral-secondary);
  background: var(--neutral-secondary);
  color: var(--brand-primary);
  ${Typography.body_md_medium}
`;
const Formmain = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
`;

const Inputfield = styled.textarea`
  height: 100px;
  padding: 5px 12px;
  ${Typography.body_md}
  color: var(--text-primary);
  font-family: unset;
  background-color: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 6px;
  resize: none;
  outline: none;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  :focus {
    border: 0.5px solid var(--brand-primary);
  }

  ::placeholder {
    ${Typography.body_md}
    color: var(--text-secondary);
  }
`;
const Projectheadingtop = styled.div`
  padding: 11px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  .checkbox-round {
    width: 7px;
    height: 7px;
    border-radius: 8px;
    vertical-align: middle;
    border: 1px solid var(--border-secondary);
    appearance: none;
    outline: none;
    cursor: pointer;
    margin: 0;
    padding: 7px;
  }
  input[type='checkbox'] {
    position: relative;
    cursor: pointer;
    opacity: 0.5;
  }
  input[type='checkbox']:checked {
    background-color: var(--brand-primary);
    opacity: 1;
    border: 1px solid var(--brand-primary);
  }
  input[type='checkbox']:checked::before {
    content: '';
    position: absolute;
    right: 50%;
    top: 50%;
    width: 3px;
    height: 8px;
    border: solid var(--text-white);
    border-width: 0 2px 2px 0;
    margin: -0.5px -1px 0 -1px;
    transform: rotate(45deg) translate(-50%, -50%) scale(0.7);
    z-index: 2;
  }
`;
const Titletext = styled.h4`
  ${Typography.heading_md}
  color: var(--text-primary);
  margin: 0;
`;
const Plusicon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .plus-icon {
    fill: var(--brand-primary);
  }
`;

const Header = styled.div`
  @media (max-width: 449px) {
    display: flex;
    align-items: center;
    gap: 24px;
  }
`;

const ItemDiv = styled.div`
  max-height: 190px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: 4px;
  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--text-placeholder);
    border-radius: 12px;
    min-height: 28px;
  }
`;

const SelectedText = styled.div`
  position: absolute;
  top: 10px;
  left: 15px;
  pointer-events: none;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 218px;
  width: 100%;
  white-space: nowrap;
`;

export {
  Header,
  InputSection,
  NameInput,
  InputNameTitle,
  InputAddressTitle,
  Group,
  Container,
  Settingcontent,
  Content,
  Icon,
  Heading,
  Title,
  Taskbutton,
  Buttonblock,
  Details,
  Title1,
  Text,
  Userprofile,
  Uploadtext,
  Title2,
  Formblock,
  FormMain,
  Form,
  Label,
  Inputvalue,
  Projectcontent,
  Subcontent,
  Autotext,
  Title4,
  Formcontent,
  Inputside,
  Inputvalue1,
  Addtask,
  Text1,
  Features,
  Paymentcontent,
  Paymentblock,
  Title5,
  Text2,
  Text3,
  Text4,
  Tax,
  Teammate,
  PersonInfo,
  TeammateName,
  Pendingbutton,
  Pesonalbtns,
  Personaltext,
  PersonalHeader,
  Profileinfo,
  Notificationdiv,
  Notificationcontent,
  Pending,
  AdminText,
  Rightdiv,
  Top,
  Middle,
  Textblocks,
  Row,
  Connectbtn,
  Formmain,
  Invoicedue,
  Inputfield,
  Projectheadingtop,
  Titletext,
  Plusicon,
  InputDiv,
  ItemDiv,
  IconDiv,
  SelectedText
};
