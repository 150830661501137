import React from 'react';
import {
  Activity,
  Activitybottom,
  Activitybox,
  Address,
  Amount,
  Amountrate,
  Box,
  Desc,
  Description,
  Details,
  First,
  From,
  Gheader,
  Heading,
  Inforate,
  Inputdesc,
  Inputid,
  Inputtitle,
  Invoiceleft,
  Invoiceright,
  Invoicetable,
  Label,
  Leftcontent,
  Leftrow,
  Line,
  Maincontent,
  Maindiv,
  Note,
  Notedescription,
  Notetitle,
  Price,
  Quantity,
  Quantityrate,
  Rightbottom,
  Rightbox,
  Rightcontent,
  Rightheader,
  Rightrow,
  Row,
  Title,
  Title2,
  Topbottom,
  Topinvoice,
  Topleft,
  Total,
  Totalcontent
} from './styles';
import { useSelector } from 'react-redux';
import darkemptylogo from '../../../assets/images/emptystates/darkemptylogo.svg';
import emptylogo from '../../../assets/images/emptystates/emptylogo.svg';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';

export default function Invoicedetailloading() {
  //use selector state variables
  const settingsSelector = useSelector((state: RootReducerInterface) => state.settings);
  const { themeMode } = settingsSelector;
  return (
    <div>
      <Maincontent>
        <Leftcontent>
          <Maindiv isdark={themeMode.theme}>
            <div className='before' />
            <Topinvoice>
              <Invoiceleft>
                {themeMode?.theme === 'dark' ? (
                  <img src={darkemptylogo} alt='emptyprofile' width={40} height={40} />
                ) : (
                  <img src={emptylogo} alt='emptyprofile' width={40} height={40} />
                )}
                <Details>
                  <Inputid>
                    <Inputtitle className='loading-animation' />
                    <Inputdesc className='loading-animation' />
                  </Inputid>
                  <Inputid>
                    <Inputtitle className='loading-animation' />
                    <Inputdesc className='loading-animation' />
                  </Inputid>
                  <Inputid>
                    <Inputtitle className='loading-animation' />
                    <Inputdesc className='last loading-animation' />
                  </Inputid>
                </Details>
              </Invoiceleft>
              <Invoiceright>
                <Title2>INVOICE</Title2>
                <Address>
                  <Box>
                    <From className='loading-animation' />
                    <Rightbox>
                      <Title className='title loading-animation' />
                      <Rightbottom>
                        <First className='loading-animation' />
                        <First className='second loading-animation' />
                        <First className='third loading-animation' />
                      </Rightbottom>
                    </Rightbox>
                  </Box>
                  <Box>
                    <From className='loading-animation' />
                    <Rightbox>
                      <Title className='title loading-animation' />
                      <Rightbottom>
                        <First className='loading-animation' />
                        <First className='second loading-animation' />
                        <First className='third loading-animation' />
                      </Rightbottom>
                    </Rightbox>
                  </Box>
                </Address>
              </Invoiceright>
            </Topinvoice>
            <Invoicetable>
              <Gheader>
                <Label>Description</Label>
                <Rightheader>
                  <Quantity>Qty</Quantity>
                  <Price>Unit Price</Price>
                  <Amount>Amount</Amount>
                </Rightheader>
              </Gheader>
              <Row>
                <Leftrow>
                  <Topleft className='loading-animation' />
                  <Topbottom className='loading-animation' />
                </Leftrow>
                <Rightrow>
                  <Quantityrate className='loading-animation' />
                  <Inforate className='loading-animation' />
                  <Amountrate className='loading-animation' />
                </Rightrow>
              </Row>
              <Row>
                <Leftrow>
                  <Topleft className='loading-animation' />
                  <Topbottom className='loading-animation' />
                </Leftrow>
                <Rightrow>
                  <Quantityrate className='loading-animation' />
                  <Inforate className='loading-animation' />
                  <Amountrate className='loading-animation' />
                </Rightrow>
              </Row>
              <Row>
                <Leftrow>
                  <Topleft className='loading-animation' />
                  <Topbottom className='loading-animation' />
                </Leftrow>
                <Rightrow>
                  <Quantityrate className='loading-animation' />
                  <Inforate className='loading-animation' />
                  <Amountrate className='loading-animation' />
                </Rightrow>
              </Row>
              <Total>
                <Line>
                  <Heading className='first loading-animation' />
                  <Description className='loading-animation' />
                </Line>
                <Line>
                  <Heading className='second loading-animation' />
                  <Description className='loading-animation' />
                </Line>
                <Line>
                  <Heading className='loading-animation' />
                  <Description className='loading-animation' />
                </Line>
                <Line>
                  <Heading className='loading-animation' />
                  <Description className='loading-animation' />
                </Line>
              </Total>
              <Note>
                <Notetitle className='loading-animation' />
                <Notedescription className='loading-animation' />
              </Note>
            </Invoicetable>
            <div className='after' />
          </Maindiv>
        </Leftcontent>
        <Rightcontent>
          <Activitybox>
            <Activity>
              <Totalcontent>Activity</Totalcontent>
              <Activitybottom>
                <Title className='loading-animation' />
                <Desc className='loading-animation' />
              </Activitybottom>
              <Activitybottom>
                <Title className='loading-animation' />
                <Desc className='loading-animation' />
              </Activitybottom>
              <Activitybottom>
                <Title className='loading-animation' />
                <Desc className='loading-animation' />
              </Activitybottom>
              <Activitybottom className='last'>
                <Title className='loading-animation' />
                <Desc className='loading-animation' />
              </Activitybottom>
            </Activity>
          </Activitybox>
        </Rightcontent>
      </Maincontent>
    </div>
  );
}
