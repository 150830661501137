import { makeAutoObservable } from 'mobx';
import { ProjectModel } from '../models/project';
import { RawProject } from '../interfaces/project';
import { createObservableProject } from '../models/project';
import { PROJECT_FILTER_TYPE } from '../../global/constants';
import { getUserPreferenceFieldData } from '../../helpers/firebaseHelper';
import { rootStore } from '../rootStore';
import { captureException } from '@sentry/react';

export class ProjectStore {
  currentProject: ProjectModel | null = null;
  projects: ProjectModel[] = [];
  loading: boolean = true;
  query: string = '';
  sortType: number = PROJECT_FILTER_TYPE.CREATED_DATE;
  projectUserNotIn: ProjectModel[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  get favoriteProjects() {
    return this.projects.filter((project) => project.isFavorite).toSorted((a, b) => a.index - b.index);
  }

  get filteredProjects() {
    const filteredProjects = this.projects.filter((project) =>
      project.name.toLowerCase().includes(this.query.toLowerCase())
    );

    if (this.sortType === PROJECT_FILTER_TYPE.CREATED_DATE) {
      return filteredProjects.toSorted((a, b) => a.createdOn.getTime() - b.createdOn.getTime());
    } else if (this.sortType === PROJECT_FILTER_TYPE.ALPHABETIC) {
      return filteredProjects.toSorted((a, b) => a.name.localeCompare(b.name));
    }

    return filteredProjects;
  }

  setProjects(rawProjects: RawProject[]) {
    this.projects = rawProjects.map(createObservableProject);
    this.loading = false;
  }

  upsertProjects(rawProjects: RawProject[]) {
    const newProjects = [];
    const updatedProjects = [];

    for (const rawProject of rawProjects) {
      const projectIndex = this.projects.findIndex((project) => project.id === rawProject.id);
      if (projectIndex !== -1) {
        const updatedProject = createObservableProject(rawProject);

        updatedProject.index = this.projects[projectIndex].index;
        updatedProject.progressData = this.projects[projectIndex].progressData;

        this.projects[projectIndex] = updatedProject;
        updatedProjects.push(updatedProject);
      } else {
        const newProject = createObservableProject(rawProject);
        this.projects.push(newProject);
        newProjects.push(newProject);
      }
    }

    return { newProjects, updatedProjects };
  }

  deleteProject(projectId: string) {
    this.projects = this.projects.filter((project) => project.id !== projectId);
  }

  setQuery(query: string) {
    this.query = query;
  }

  setProjectUserNotIn(projectUserNotIn: RawProject[]) {
    this.projectUserNotIn = projectUserNotIn.map(createObservableProject);
  }

  setSortType(sortType: number) {
    this.sortType = sortType;
  }

  setCurrentProject(projectId: string) {
    this.currentProject = this.projects.find((project) => project.id === projectId) || null;
  }

  *updateProjectIndexes() {
    try {
      const workspaceId = rootStore.workspaceStore.currentWorkspace?.id;

      if (!workspaceId) {
        return;
      }

      const userFilter: Record<string, number> = yield getUserPreferenceFieldData(`favorite_projects_${workspaceId}`);
      if (!userFilter) {
        return;
      }

      for (const project of this.projects) {
        const index = userFilter[project.id] ?? new Date(project?.createdOn).getTime();
        project.index = index;
      }
    } catch (err) {
      console.error(err);
      captureException(err);
    }
  }

  updateProjectProgressData() {
    for (const project of this.projects) {
      project.updateProgressData();
    }
  }

  joinProject(projectId: string) {
    const project = this.projectUserNotIn.find((project) => project.id === projectId);
    if (project) {
      this.projectUserNotIn = this.projectUserNotIn.filter((project) => project.id !== projectId);
      this.projects.push(project);
    }
  }
}
