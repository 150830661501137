import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { setErrorMessage, setSuccessMessage } from '../../../actions/messageActions';
import { getUrlParam, isEmpty } from '../../../helpers/common';
import {
  addFileData,
  addProjectResource,
  getCurrentProjectDetails,
  getProjectFiles,
  updateProjectResource
} from '../../../services/projectServices';
import { captureException } from '../../../services/logService';
import { LinkModalInterface, ProjectFileData } from '../../../interfaces/ProjectInterface';
import { FILE_TYPES, RESOURCE_TYPE } from '../../../global/constants';
import {
  DropboxModal,
  Icon,
  ModalBlock,
  ModalButton,
  ModalContent,
  ModalForm,
  ModalHeader,
  ModalInput,
  ModalLabel,
  ModalSubContent,
  ModalTitle
} from './style';
import Button from '../../button';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { useSelector } from 'react-redux';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import { updateCurrentProject } from '../../../actions/projectActions';
import { addTaskResource, getMyTaskDetailsData, updateTaskResource } from '../../../services/taskServices';
import { updateMyTaskDetailsData } from '../../../actions/taskActions';
import { TaskLinkList } from '../../../interfaces/TaskInterface';
interface Props {
  onClose: () => void;
  modalData: LinkModalInterface | undefined;
  loadFilesData?: () => void;
  accounting?: boolean;
  itemData?: ProjectFileData | null;
  taskId?: string;
}

export const LinkFileModal: React.FC<Props> = (props) => {
  const { onClose, modalData, loadFilesData, accounting, itemData, taskId } = props;
  //States
  const [name, setName] = useState(itemData?.name || '');
  const [url, setUrl] = useState(itemData?.url || '');
  const [loading, setLoading] = useState(false);
  //Other variable
  const dispatch = useDispatch();
  const params: { id: string } = useParams();
  const history = useHistory();
  const folderId = getUrlParam(history.location.search, 'folder');

  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { project: projectSelector, workspace: workspaceSelector, task: taskSelector } = stateSelector || {};
  const { currentProject } = projectSelector;
  const { workspace } = workspaceSelector;
  const { taskDetails } = taskSelector;

  const onCreateFile = useCallback(
    async (e: React.SyntheticEvent) => {
      try {
        e.preventDefault();
        setLoading(true);
        if (isEmpty(name) && accounting) {
          dispatch(setErrorMessage('Please enter file name!'));
          return;
        } else if (isEmpty(url)) {
          dispatch(setErrorMessage('Please enter valid URL!'));
          return;
        }

        let response: TaskLinkList;
        if (accounting) {
          const payload = {
            FileType: null,
            IsSystemFile: true,
            Name: name,
            ParentId: folderId ? folderId : null,
            ProjectId: params?.id,
            Size: null,
            UploadFileType: modalData?.modalType,
            Url: url
          };
          response = await dispatch(addFileData(payload));
        } else {
          if (itemData?.id) {
            const payload = {
              label: name,
              reference: !url.startsWith('https://') ? `https://${url.trim()}` : url
            };
            response = await dispatch(
              taskId
                ? updateTaskResource(taskId, itemData?.id, payload)
                : updateProjectResource(currentProject?.id, itemData?.id, payload)
            );
            if (response) {
              const taskLink = taskDetails?.links?.map((item) =>
                item['_id'] === itemData?.id
                  ? {
                      ...item,
                      Label: response?.Label,
                      Reference: response?.Reference,
                      IconUrl: response?.IconUrl
                    }
                  : item
              );
              const projectResource = currentProject?.resources?.map((item) =>
                item['_id'] === itemData?.id ? { ...item, Label: name, Reference: url } : item
              );
              dispatch(
                taskId
                  ? updateMyTaskDetailsData({ propsName: 'links', value: taskLink })
                  : updateCurrentProject({ propsName: 'resources', value: projectResource })
              );
            }
          } else {
            const payload: {
              label?: string;
              reference: string;
              type?: number;
            } = {
              label: name,
              reference: !url.startsWith('https://') ? `https://${url.trim()}` : url
            };
            if (taskId) {
              response = await dispatch(addTaskResource(taskId, payload));
            } else {
              payload.type = RESOURCE_TYPE.LINK;
              response = await dispatch(addProjectResource(currentProject?.id, payload));
            }
          }
        }
        if (response) {
          onClose();
          setName('');
          setUrl('');
          if (accounting) {
            loadFilesData?.();
            await dispatch(getProjectFiles(params?.id));
          } else {
            if (itemData?.id) {
              dispatch(setSuccessMessage('Link updated'));
            } else {
              if (taskId) {
                dispatch(setSuccessMessage('Link added successfully'));
                await dispatch(getMyTaskDetailsData(taskId));
              } else {
                await dispatch(getCurrentProjectDetails(workspace?.id, currentProject?.id));
              }
            }
          }
        }
      } catch (e) {
        captureException(e);
        console.log('ERROR', e);
      } finally {
        setLoading(false);
      }
    },
    [
      name,
      url,
      accounting,
      dispatch,
      folderId,
      params?.id,
      modalData?.modalType,
      itemData?.id,
      taskId,
      currentProject?.id,
      currentProject?.resources,
      taskDetails,
      onClose,
      loadFilesData,
      workspace?.id
    ]
  );

  const getButtonText = useCallback(() => {
    if (modalData?.modalType === FILE_TYPES.ADD_LINK && !itemData) return 'Add link';
    if (modalData?.modalType === FILE_TYPES.ADD_LINK && itemData?.id) return 'Save';
    if (itemData?.id) return 'Update';
    return 'Save';
  }, [itemData, modalData?.modalType]);

  return (
    <>
      <DropboxModal>
        <ModalBlock>
          <ModalHeader>
            <Icon>
              <SVGIcon
                name={modalData?.name}
                width='20'
                height='20'
                viewBox={'0 0 20 20'}
                // viewBox={
                //   modalData?.modalType === FILE_TYPES.DRIVE || modalData?.modalType === FILE_TYPES.RESOURCES
                //     ? '0 0 16 16'
                //     : '0 0 20 20'
                // }
                className={
                  modalData?.modalType === FILE_TYPES.RESOURCES || modalData?.modalType === FILE_TYPES.ADD_LINK
                    ? 'copy-link-icon'
                    : ''
                }
              />
            </Icon>

            <ModalTitle>{itemData?.id ? modalData?.updateTitle : modalData?.title}</ModalTitle>
          </ModalHeader>
          <Icon onClick={onClose}>
            <SVGIcon name='modal-cross-sign-icon' width='18' height='18' viewBox='0 0 18 18' className='fillColor' />
          </Icon>
        </ModalBlock>
        <ModalContent>
          <ModalForm>
            <ModalSubContent>
              {modalData?.label1 && <ModalLabel>{modalData?.label1}</ModalLabel>}
              <ModalInput
                type='text'
                placeholder={modalData?.placeholder}
                value={name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                autoFocus
              />
            </ModalSubContent>
            <ModalSubContent>
              <ModalLabel>
                {modalData?.label}{' '}
                {modalData?.modalType !== FILE_TYPES.NOTION &&
                  modalData?.modalType !== FILE_TYPES.RESOURCES &&
                  modalData?.modalType !== FILE_TYPES.ADD_LINK && (
                    <a
                      href={modalData?.href}
                      rel={modalData?.modalType === FILE_TYPES.DROPBOX ? 'noopener noreferrer' : 'noreferrer'}
                      target='_blank'>
                      Browse {modalData?.modalType !== FILE_TYPES.NOTION ? modalData?.modal : ''}
                    </a>
                  )}
              </ModalLabel>
              <ModalInput
                type='text'
                placeholder={modalData?.placeholder2}
                value={url}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUrl(e.target.value)}
              />
            </ModalSubContent>
          </ModalForm>
          <ModalButton>
            <Button title='Cancel' onClick={onClose} secondary={true} type='button' />
            <Button onClick={onCreateFile} title={getButtonText()} isLoading={loading} />
          </ModalButton>
        </ModalContent>
      </DropboxModal>
    </>
  );
};
