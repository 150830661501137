import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
  AddMember,
  Addmilestone,
  Addoption,
  DropdownMenu,
  Dropheader,
  Dropitem,
  Icon,
  Inputarea,
  ItemLabel,
  Menu,
  MenuList,
  ShowAddMember,
  ShowMember,
  Textblock
} from './style';
import { isEmpty } from '../../../helpers/common';
import SVGIcon from '../../../assets/images/svg/SVGIcon';

interface MenuItem {
  key?: number | string;
  id?: string;
  icon?: React.ReactNode;
  label?: string;
  className?: string;
}
interface Props {
  menuItems: MenuItem[];
  handleClick?: (value: number | string) => void;
  customElement?: (item: any) => React.ReactNode;
  onClose?: () => void;
  width?: number;
  buttonref?: React.RefObject<HTMLDivElement | null>;
  isOpen?: boolean;
  setIsOpen: (open: boolean) => void;
  isCustomcontextmenu?: boolean;
  isCalendarviewCard?: boolean;
  isdisabled?: boolean;
  isSnoozeDropdown?: boolean;
  isOutside?: boolean;
  top?: number | string;
  isCustomerList?: boolean;
  isCustomerListLastChild?: boolean;
  isReportDuration?: boolean;
  ClassNameOuterBody?: string;
  right?: number | string;
  isZindex?: boolean;
  isSearch?: boolean;
  isLabel?: boolean;
  isTaskDetailMilestone?: boolean;
  onAddMilestone?: () => void;
  onAddMember?: () => void;
  isSelectProjectDropdown?: boolean;
  placeholder?: string;
  setOpenDropdownId?: (id: string | null) => void;
  isMessage?: boolean;
  showAddMember?: boolean;
  isGithubRepo?: boolean;
  isApplySmallWidth?: boolean;
  isInvoiceList?: boolean;
}

export default function CustomDropdown({
  menuItems,
  customElement,
  handleClick,
  buttonref,
  setIsOpen,
  isOpen,
  width,
  isCustomcontextmenu,
  isCalendarviewCard,
  isdisabled,
  isSnoozeDropdown,
  isOutside,
  top,
  isCustomerList,
  isCustomerListLastChild,
  isReportDuration,
  ClassNameOuterBody,
  right,
  isZindex,
  isSearch,
  isLabel,
  isTaskDetailMilestone,
  onAddMilestone,
  isSelectProjectDropdown,
  placeholder,
  setOpenDropdownId,
  isMessage,
  showAddMember,
  onAddMember,
  isGithubRepo,
  isApplySmallWidth,
  isInvoiceList
}: Props) {
  //Ref
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  //States
  const [position, setPosition] = useState<'top' | 'bottom'>('bottom');
  const [alignment, setAlignment] = useState<'left' | 'center' | 'right'>('left');
  const [dropdownTopOffset, setDropdownTopOffset] = useState<number>(0);
  const [searchResult, setSearchResult] = useState<any[]>(menuItems || []);
  const [query, setQuery] = useState('');
  //Other variable
  const buttonRef = buttonref;
  // calculate button height for position top or bottom
  const buttonHeight = buttonRef?.current?.offsetHeight;

  useLayoutEffect(() => {
    if (isOpen && buttonRef?.current && dropdownRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const dropdownHeight = dropdownRef.current.offsetHeight;
      const spaceBelow = window.innerHeight - buttonRect.bottom - (isMessage ? 85 : 0);
      const spaceAbove = buttonRect.top;
      // Handle horizontal alignment
      const spaceLeft = buttonRect.left;
      const spaceRight = window.innerWidth - buttonRect.right;
      // calculate dropdown width for alignment of dropdown
      const dropdownWidth = dropdownRef.current.offsetWidth - buttonRect.width;

      if (spaceBelow < dropdownHeight && spaceAbove > dropdownHeight) {
        setPosition('top');
        setDropdownTopOffset(buttonRect.top - dropdownHeight);
      } else {
        setPosition('bottom');
        setDropdownTopOffset(buttonRect.top);
      }

      // Handle horizontal alignment

      if (spaceLeft < dropdownWidth && spaceRight < dropdownWidth) {
        setAlignment('center');
      } else if (spaceRight < dropdownWidth) {
        setAlignment('right');
      } else {
        setAlignment('left');
      }
    }
  }, [alignment, buttonRef, dropdownTopOffset, isMessage, isOpen, position]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!dropdownRef.current?.contains(event.target as Node) && !buttonRef?.current?.contains(event.target as Node)) {
        setIsOpen(false);
        setOpenDropdownId?.(null);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [buttonRef, setIsOpen, setOpenDropdownId]);

  const handleMenuOptionClick = useCallback(
    (key: number | string) => {
      handleClick?.(key); // Pass the selected item key to the parent
      setIsOpen(false); // Close the dropdown after selection
    },
    [handleClick, setIsOpen]
  );

  const searchQuery = useCallback(
    (value: string) => {
      const result = menuItems?.filter((item: any) => item?.name?.toLowerCase().includes(value?.toLowerCase())) || [];
      if (isEmpty(value)) setSearchResult(menuItems);
      else setSearchResult(result);
      return null;
    },
    [menuItems]
  );

  const onChangeSearch = useCallback(
    (e: { target: { value: string } }) => {
      const value = e.target.value;
      setQuery(value);
      if (!isEmpty(value)) {
        searchQuery(value);
      } else {
        searchQuery(value);
      }
    },
    [searchQuery]
  );

  return (
    <>
      <DropdownMenu
        ref={dropdownRef}
        direction={position}
        alignment={alignment}
        dropdownTopOffset={dropdownTopOffset}
        buttonHeights={buttonHeight}
        isCustomcontextmenu={isCustomcontextmenu}
        isCalendarviewCard={isCalendarviewCard}
        isdisabled={isdisabled}
        isSnoozeDropdown={isSnoozeDropdown}
        className={ClassNameOuterBody}
        isOutside={isOutside}
        top={isOutside ? `${top}` : ''}
        isReportDuration={isReportDuration}
        isCustomerList={isCustomerList}
        isCustomerListLastChild={isCustomerListLastChild}
        right={isOutside ? `${right}` : ''}
        isZindex={isZindex}
        isSelectProjectDropdown={isSelectProjectDropdown}
        showAddMember={showAddMember}
        isGithubRepo={isGithubRepo}
        isApplySmallWidth={isApplySmallWidth}
        isInvoiceList={isInvoiceList}
        onClick={(e) => {
          e.stopPropagation();
        }}>
        {isSearch && (
          <Dropheader
            isSelectProjectDropdown={isSelectProjectDropdown}
            onClick={(e) => {
              e.stopPropagation();
            }}>
            <Inputarea type='text' placeholder={placeholder} autoFocus={true} value={query} onChange={onChangeSearch} />
            {isLabel && (
              <Textblock>
                <span>L</span>
              </Textblock>
            )}
          </Dropheader>
        )}
        <Menu showAddMember={showAddMember} isSelectProjectDropdown={isSelectProjectDropdown}>
          <MenuList
            isReportDuration={isReportDuration}
            isCustomcontextmenu={isCustomcontextmenu}
            width={width}
            isLabel={isLabel}
            isSnoozeDropdown={isSnoozeDropdown}
            isTaskDetailMilestone={isTaskDetailMilestone}
            isSelectProjectDropdown={isSelectProjectDropdown}
            showAddMember={showAddMember}>
            {searchResult?.map((item, index) => {
              return (
                <Dropitem
                  isLabel={isLabel}
                  key={item?.key || item?.id || index}
                  onClick={() => handleMenuOptionClick(item?.key || item?.id || index)}
                  isSnoozeDropdown={isSnoozeDropdown}
                  showAddMember={showAddMember}>
                  {customElement ? (
                    customElement(item)
                  ) : (
                    <>
                      {item.icon && <Icon>{item.icon}</Icon>}
                      <ItemLabel className={item?.className}>{item.label}</ItemLabel>
                    </>
                  )}
                </Dropitem>
              );
            })}
          </MenuList>
        </Menu>
        {isTaskDetailMilestone && (
          <Addmilestone onClick={onAddMilestone}>
            <SVGIcon name='black-plus-icon' width='16' height='16' viewBox='0 0 16 16' />
            <Addoption>Create New</Addoption>
          </Addmilestone>
        )}
        {showAddMember && (
          <ShowAddMember>
            <ShowMember onClick={onAddMember}>
              <SVGIcon name='member-plus-icon' width='20' height='20' viewBox='0 0 16 16' className='add-icon' />
              <AddMember>Add Member</AddMember>
            </ShowMember>
          </ShowAddMember>
        )}
      </DropdownMenu>
    </>
  );
}
