import styled from 'styled-components';
import { Typography } from '../../../global/theme';

const Group = styled.div`
  width: 100%;
  max-width: 1022px;
  margin: auto;
`;

const Container = styled.div`
  border: 0.5px solid var(--border-primary);
  border-radius: 8px;
  background-color: var(--background-primary);
  margin-bottom: 24px;
`;

const Projectitem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  padding: 16px;
  border-bottom: 0.5px solid var(--border-primary);
  :last-child {
    border-bottom: none;
  }
`;
const HeaderTitle = styled.h1`
  ${Typography.heading_lg}
  color: var(--text-primary);
  margin: 0;
`;
const Title = styled.h1`
  ${Typography.body_lg_semibold}
  color: var(--text-primary);
  margin: 0;
`;

const Text = styled.p`
  ${Typography.body_md}
  color: var(--text-secondary);
  margin: 0;
`;

const ProjectName = styled.div`
  max-width: 540px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  @media (max-width: 449px) {
    gap: 4px;
  }
`;

const Assigngroup1 = styled.div`
  width: 100%;
  max-width: 140px;
  ul {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
    justify-content: flex-end;
  }

  li {
    margin-left: -10px;
  }

  small {
    padding: 5px;
    border-radius: 20px;
    background: var(--text-secondary);
    border: 2px solid var(--background-primary);
  }

  span {
    ${Typography.body_xs_semibold}
    color: var(--text-white);
  }

  .plus-icon {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    align-items: center;
    justify-content: center;
    color: var(--text-white);
    display: flex;
    background-color: var(--text-secondary);
    ${Typography.body_xs_semibold}
    border: 2px solid var(--background-primary);
    position: relative;
    top: 0;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--background-secondary);
  margin: 14px 0 24px;
  @media (max-width: 449px) {
    align-items: center;
    margin: 0 -16px 12px;
    padding: 13px 16px;
    position: sticky;
    z-index: 999;
    top: 0;
    background: var(--background-primary);
    border-bottom: 0.5px solid var(--border-primary);
  }
`;

const Headerblock = styled.div`
  display: flex;
  gap: 30px;
  @media (max-width: 449px) {
    gap: 24px;
    align-items: center;
  }
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const Icon = styled.div`
  display: inline-flex;
  cursor: pointer;
  position: relative;
  .stroke-color {
    stroke: var(--brand-primary);
  }
  .doticon {
    stroke: var(--text-tertiary);
    fill: var(--text-tertiary);
    :hover {
      background-color: var(--neutral-secondary);
      border-radius: 4px;
    }
  }
`;

const Archivebox = styled.div`
  .menuStyle {
    display: flex;
    flex-direction: column;
    padding: 6px 0;
    gap: 0px;
    background: var(--background-primary);
    border: 0.5px solid var(--border-primary);
    box-shadow: var(--shadow-modal);
    border-radius: 8px;
    margin: 0px;

    .menuitem {
      margin: 0;
      padding: 6px 12px;
      :hover {
        background: none;
      }
    }
  }
`;

const Rightcontent = styled.div`
  display: flex;
  gap: 20px;
`;
const Resnavbar = styled.div`
  display: none;
  @media (max-width: 449px) {
    display: flex;
    width: 24px;
    height: 24px;
  }
`;
export {
  Group,
  Assigngroup1,
  ProjectName,
  Text,
  Title,
  Projectitem,
  Container,
  Heading,
  Headerblock,
  Icon,
  Content,
  HeaderTitle,
  Archivebox,
  Rightcontent,
  Resnavbar
};
