import styled, { css } from 'styled-components';
import { Typography } from '../../global/theme';

interface Props {
  isTaskGrid?: boolean;
  isOverdue?: boolean;
  color?: string;
}
const Card = styled.div<Props>`
  min-width: 200px;
  border: 0.5px solid var(--border-primary);
  border-radius: 8px;
  background-color: var(--background-primary);
  ${(props: Props) =>
    props.isTaskGrid &&
    css`
      min-width: 328px;
      border-radius: 12px;
      width: unset;
    `}
  :hover {
    transition: all 0.3s;
    border: 0.5px solid var(--text-placeholder);
    .task-grid-calander-icon {
      display: block;
    }
  }
`;
const Firstcards = styled.div<Props>`
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  :hover {
    .plus-icon {
      visibility: visible;
    }
  }
  ${(props: Props) =>
    props.isTaskGrid &&
    css`
      padding: 12px;
    `}
`;

const Label = styled.div`
  ${Typography.body_md}
  color: var(--text-primary);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  word-break: break-word;
  a {
    color: var(--text-link);
    text-decoration: underline;
    :hover {
      text-decoration: none;
    }
  }
`;
const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
`;

const Assigngroup = styled.div`
  ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: max-content;
    margin-left: 8px;
  }

  li {
    margin-left: -9px;
    border-radius: 50%;
  }

  .plus-icon {
    visibility: hidden;
  }
  .large-margin {
    margin-left: -64px;
  }
  .small-margin {
    margin-left: -20px;
  }
`;

const AvatarImage = styled.div`
  display: flex;
  align-items: center;
  border-radius: 50%;

  > div > img {
    max-width: 100%;
  }
`;

const Box = styled.div`
  border-top: 0.5px solid var(--border-primary);
`;
const Content = styled.div`
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  cursor: pointer;
`;
const Days = styled.div`
  ${Typography.body_md}
  color: var(--text-secondary);
`;

const Dates = styled.div`
  ${Typography.body_md}
  color: var(--text-primary);
`;

const Dropitem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 6px 12px;
  &.priority-box {
    width: 100%;
  }
  :hover {
    background: var(--neutral-secondary);
  }
  p {
    ${Typography.body_md}
    color: var(--text-primary);
    margin: 0;
  }
`;

const Top = styled.div<Props>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: baseline;
  ${(props: Props) =>
    props.isTaskGrid &&
    css`
      gap: 6px;
    `}
  position: relative;
  abbr {
    text-decoration: none;
  }
`;
const Group = styled.div`
  background-color: var(--neutral-secondary);
  padding: 2px 4px;
  ${Typography.body_sm}
  color: var(--text-secondary);
  border-radius: 4px;
  :hover {
    color: var(--text-primary);
  }
`;
const Milestone = styled.div`
  background-color: var(--neutral-secondary);
  padding: 1px 4px;
  ${Typography.body_sm}
  color: var(--text-secondary);
  border-radius: 4px;
  margin-bottom: 2px;
  cursor: pointer;
  :hover {
    color: var(--text-primary);
  }
`;
const Groupbox = styled.div`
  width: 152px;
  background: var(--background-primary);
  box-shadow: var(--shadow-modal);
  border-radius: 8px;
  .ant-dropdown-menu {
    padding: 6px 0;
    border-radius: 8px;
  }
`;
const Milestonebox = styled.div`
  width: 162px;
  background: var(--background-primary);
  box-shadow: var(--shadow-modal);
  border-radius: 8px;
  .ant-dropdown-menu {
    padding: 6px 0;
    border-radius: 8px;
  }
`;
const Topline = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 6px;
  width: 100%;
  .fill-color {
    fill: var(--text-secondary);
  }
`;

const Check = styled.div`
  display: flex;
  gap: 6px;
  align-items: flex-start;

  .svg-icon {
    path {
      stroke: var(--text-secondary);
    }
  }
  .cancelled-icon {
    path {
      fill: var(--text-tertiary);
    }
  }
  .task-status-icon {
    path.fill1 {
      fill: var(--background-primary);
    }
    path.fill2 {
      fill: var(--text-secondary);
    }
    path.fill3 {
      fill: var(--background-primary);
    }
  }
  .task-todo-icon {
    path {
      fill: var(--background-primary);
      stroke: var(--text-secondary);
    }
  }
  .task-inprogress-icon {
    path.first-path {
      fill: var(--background-primary);
    }
  }
  .task-inreview-icon {
    path.first-path {
      fill: var(--background-primary);
    }
  }
  .task-completed-icon {
    path {
      fill: var(--text-tertiary);
    }
  }
  .checkbox-round {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid var(--text-secondary);
    appearance: none;
    outline: none;
    cursor: pointer;
    margin: 0;
    padding: 7px;
  }
  input[type='checkbox'] {
    position: relative;
    cursor: pointer;
    opacity: 1;
  }
  input[type='checkbox']:checked {
    background-color: var(--text-tertiary);
    opacity: 1;
    border: 1px solid var(--text-tertiary);
  }
  input[type='checkbox']:checked::before {
    content: '';
    position: absolute;
    right: 50%;
    top: 50%;
    width: 3px;
    height: 8px;
    border: solid var(--text-white);
    border-width: 0 2px 2px 0;
    margin: -0.5px -1px 0 -1px;
    transform: rotate(45deg) translate(-50%, -50%) scale(0.7);
    z-index: 2;
  }
`;
const Inputvalue2 = styled.input``;
const Checkbox = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  :hover {
    background-color: var(--neutral-secondary);
    input {
      filter: brightness(var(--brightness-value));
      background-color: transparent;
    }
  }
`;
const Bottomright = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  .ant-tooltip .ant-tooltip-inner {
    padding: 4px 8px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    background-color: var(--border-primary);
    border: 0.5px solid var(--border-primary);
    box-shadow: var(--shadow-card);
    color: var(--text-primary);
  }
  .ant-tooltip {
    ${Typography.body_sm}
    --antd-arrow-background-color: var(--border-primary);
  }
  .ant-tooltip .ant-tooltip-content {
    top: -1px;
  }
  .ant-tooltip-arrow {
    bottom: 1px !important;
  }
  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }
`;

const Deadview = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px;
  border-radius: 4px;
  :hover {
    background-color: var(--neutral-secondary);
  }
`;
const Text = styled.p<Props>`
  ${Typography.body_md}
  color: var(--text-secondary) !important;
  margin: 0;

  ${(props: Props) =>
    props.isOverdue &&
    css`
      color: var(--accent-error) !important;
    `}
`;
const FlagIcon = styled.div`
  display: inline-flex;
  width: 14px;
  height: 14px;
  .redflag {
    fill: var(--accent-error);
  }
  .grayflag {
    fill: var(--text-secondary);
  }
`;
const Icondiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  border: 0.5px solid var(--border-primary);
  border-radius: 15px;
`;
const LabelIcon = styled.div<Props>`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  ${(props: Props) =>
    props.color &&
    css`
      background-color: ${props.color};
    `}
`;
const Righttext = styled.div`
  ${Typography.body_sm}
  color: var(--text-primary);
`;

const Coloricon = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  padding: 2px 6px;
  &.label-with-border {
    border-left: 0.5px solid var(--border-primary);
  }
`;
const IconDueDate = styled.div`
  width: 24px;
  height: 24px;
  .calendar-fill-color {
    fill: var(--text-secondary);
  }
  .calendar-due-fill-color {
    fill: var(--accent-error);
  }
`;
const Textdate = styled.p<Props>`
  ${Typography.body_md}
  color: var(--text-secondary);
  margin: 0;
  white-space: nowrap;
  ${(props: Props) =>
    props.isOverdue &&
    css`
      color: var(--accent-error);
    `}
  &.task-completed {
    color: var(--text-secondary);
  }
`;

const OverLayDiv = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const TooltipDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export {
  Card,
  Firstcards,
  Label,
  Bottom,
  Assigngroup,
  AvatarImage,
  Box,
  Content,
  Days,
  Dates,
  Dropitem,
  Top,
  Group,
  Groupbox,
  Topline,
  Check,
  Inputvalue2,
  Checkbox,
  Bottomright,
  Deadview,
  Text,
  FlagIcon,
  Milestone,
  Milestonebox,
  Icondiv,
  LabelIcon,
  Righttext,
  Coloricon,
  IconDueDate,
  Textdate,
  OverLayDiv,
  TooltipDiv
};
