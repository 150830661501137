import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Content,
  Itemblock,
  Tasklist,
  Title,
  Showhide,
  Data,
  ShowTask,
  Selectbox,
  Line,
  Selected,
  Closeicon,
  RemoveIcon,
  Bottom,
  OptionDiv,
  Show,
  Filter,
  TaskNumber,
  Text2,
  SubTaskList,
  LineDiv,
  ShowSubTask
} from '../styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SVGIcon from '../../../../../assets/images/svg/SVGIcon';
import { calculateDueTime, isEmpty } from '../../../../../helpers/common';
import { ProjectDetailInterface, StatusListInterface } from '../../../../../interfaces/ProjectInterface';
import Collapsedopdown from '../../../../../component/dropdowns/collapseDropdown';
import Deletemodal from '../../../../../component/models/deleteModel';
import CreateTaskModal from '../../../../../component/models/createTaskModal';
import {
  clearCreateTaskInput,
  clearSubTaskList,
  setFilteredTasksList,
  setIsTaskFilterOpen,
  setMyTaskDetailsData,
  updateCreateTaskInput,
  updateFilteredTasksList,
  updatSubTaskList
} from '../../../../../actions/taskActions';
import { useHotkeys } from 'react-hotkeys-hook';
import {
  COMMENT_TYPE,
  SHOW_TASK_BY_ORDER,
  SHOW_TASK_GROUPING_TYPE,
  STATUS_TYPE,
  TASK_PRIORITY
} from '../../../../../global/constants';
import UserPreferenceSingleton from '../../../../../helpers/userPreferenceSingleton';
import { nanoid } from 'nanoid';
import ModalCustom from '../../../../../component/models/modal';
import {
  DropResult,
  DragDropContext,
  Droppable,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot
} from 'react-beautiful-dnd';
import { createNewComment, onChangeComment, updateTaskDetails } from '../../../../../services/taskServices';
import TaskLoading from '../../../../../component/loading/taskloading';
import { trackAnalyticActivity } from '../../../../../services/analyticsService';
import { ProjectTemplateDetailsInterface } from '../../../../../interfaces/SettingsInterface';
import {
  DataUpdatedCommentsInterface,
  FilteredCompletedTasksListInterface,
  FilteredTasksListInterface,
  TaskDetailsInterface,
  TaskGroupInterface
} from '../../../../../interfaces/TaskInterface';
import { captureException } from '../../../../../services/logService';
import { updateProjectTemplateDetails } from '../../../../../actions/settingActions';
import { RootReducerInterface } from '../../../../../interfaces/RootReducerInterface';
import TaskStatusDropdown from '../../../../../component/dropdowns/taskStatusDropdown';
import ConfirmationModal from '../../../../../component/models/confirmationModal';
import Statusdropdown from '../../../../../component/dropdowns/statusDropdown';
import { TaskItem } from '../taskItem';
import { TASK_ANALYTICS } from '../../../../../global/analyticsConstants';

// eslint-disable-next-line @typescript-eslint/no-redeclare
declare type DropResult = typeof import('react-beautiful-dnd');
// eslint-disable-next-line @typescript-eslint/no-redeclare
declare type DraggableProvided = typeof import('react-beautiful-dnd');
// eslint-disable-next-line @typescript-eslint/no-redeclare
declare type DraggableStateSnapshot = typeof import('react-beautiful-dnd');
interface Props {
  loadData: () => void;
  setModalOpen?: boolean;
  propsLoading: boolean;
  isPadding?: boolean;
  taskGroups: TaskGroupInterface[];
  groupingStatus: number;
  currentProject: ProjectDetailInterface | ProjectTemplateDetailsInterface;
  isTemplateProject?: boolean;
  completedTask?: number;
  hiddenTask?: number;
  otherTask: FilteredCompletedTasksListInterface;
  setOtherTask: (item: FilteredCompletedTasksListInterface) => void;
  filteredTasksList: FilteredTasksListInterface[];
}

const TasksPage: React.FC<Props> = (props) => {
  const {
    loadData,
    setModalOpen,
    propsLoading,
    isPadding,
    taskGroups,
    currentProject,
    isTemplateProject,
    completedTask,
    hiddenTask,
    groupingStatus,
    otherTask,
    setOtherTask,
    filteredTasksList
  } = props;
  // States
  const [open, setOpen] = useState(false);
  const [isOpenFromShortcut, setIsOpenFromShortcut] = useState(false);
  const [deleteModelOpen, setDeleteModelOpen] = useState(false);
  const [selectedDeleteModelOpen, setSelectedDeleteModelOpen] = useState(false);
  const [showCompletedTask, setShowCompletedTask] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [selectedItem, setSelectedItem] = useState<TaskDetailsInterface>();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isAssignDropdownOpen, setIsAssignDropdownOpen] = useState(false);
  const [currentAssignBox, setCurrentAssignBox] = useState<string>('');
  const [selectedTasks, setSelectedTasks] = useState<{ taskId: string; groupId: string }[]>([]);
  const [hoveredTask, setHoveredTask] = useState<{ taskId: string; groupId: string }>({ groupId: '', taskId: '' });
  const [selectedTasksFromCompleted, setSelectedTasksFromCompleted] = useState(false);
  const [isHoverSelect, setHoverSelect] = useState(selectedTasks?.length === 0);
  const [isConfirmationModelOpen, setIsConfirmationModelOpen] = useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);
  const [currentTask, setCurrentTask] = useState<TaskDetailsInterface>();
  const [currentGroup, setCurrentGroup] = useState<FilteredTasksListInterface>();
  const [cancelLoading, setCancelLoading] = useState(false);
  const [status, setStatus] = useState<StatusListInterface>();
  const [previousStatus, setPreviousStatus] = useState<StatusListInterface>();
  const [isCheckBox, setIsCheckBox] = useState(false);
  const [currentTaskGroup, setCurrentTaskGroup] = useState<string>('');
  const [openSubTask, setOpenSubtask] = useState<{ id: string; showCompleted: boolean }>({
    id: '',
    showCompleted: false
  });

  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const {
    settings: settingsSelector,
    project: projectSelector,
    workspace: workspaceSelector,
    task: tasksSelector
  } = stateSelector || {};
  const { workspace } = workspaceSelector;
  const { id: workspace_id } = workspace;
  const { loading: projectLoading, milestoneList } = projectSelector;
  const { loading: taskLoading, taskFilter } = tasksSelector;
  const { projectTemplateDetails } = settingsSelector;
  const loading = projectLoading || taskLoading || propsLoading;
  let taskDifference = 0;
  if (completedTask && hiddenTask) {
    taskDifference = completedTask - hiddenTask;
  }
  // Other variables
  const history = useHistory();
  const dispatch = useDispatch();

  const handleCancel = useCallback(() => {
    setOpen(false);
    setIsOpenFromShortcut(false);
    dispatch(clearCreateTaskInput());
  }, [dispatch]);

  useEffect(() => {
    setSelectedTasks([]);
    setSelectedTasksFromCompleted(false);
    setHoveredTask({ groupId: '', taskId: '' });
  }, [currentProject]);

  // for open confirmation model
  const handleOpenConfirmationModal = useCallback((isCheckBox: boolean) => {
    setIsCheckBox(isCheckBox);
    setIsConfirmationModelOpen(true);
  }, []);

  // for close confirmation model
  const closeConfirmationModal = useCallback(() => {
    setIsConfirmationModelOpen(false);
  }, []);

  // for close delete model
  const closeDeleteModel = useCallback(() => {
    setDeleteModelOpen(false);
  }, []);

  // for open selected delete model
  const openSelectedDeleteModel = useCallback(() => {
    setSelectedDeleteModelOpen(true);
  }, []);

  // for close selected delete model
  const closeSelectedDeleteModel = useCallback(() => {
    setSelectedDeleteModelOpen(false);
  }, []);

  // handle assign group click
  const onAssignGroupClick = useCallback((taskId: string, groupId: string) => {
    setCurrentTaskGroup(groupId);
    setCurrentAssignBox(taskId);
  }, []);

  // manage task checkbox
  const onClickTaskCheckbox = useCallback(
    async (e: React.SyntheticEvent, item: TaskDetailsInterface, group: FilteredTasksListInterface, isOpen: boolean) => {
      e.stopPropagation();
      if (item?.subTasks) {
        let allSubTasksStatus = true;
        if (item?.subTasks && item?.subTasks?.length > 0) {
          for (const subTask of item?.subTasks) {
            if (subTask?.status === false) {
              allSubTasksStatus = false;
              break;
            }
          }
        }
        if (allSubTasksStatus === false && !item?.status && isEmpty(item?.parentTaskId)) {
          setCurrentTask(item);
          setCurrentGroup(group);
          handleOpenConfirmationModal(true);
          return;
        }
      }
      let taskData = JSON.parse(JSON.stringify(item || {}));
      let groupData = JSON.parse(JSON.stringify(group || {}));
      const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
      const newComment: DataUpdatedCommentsInterface = {
        id: nanoid(),
        type: COMMENT_TYPE.STATUS_CHANGE,
        updatedBy: userDetails?.id,
        updatedTime: new Date().toISOString(),
        createdOn: new Date().toISOString(),
        user: { ...userDetails, name: userDetails?.given_name },
        status: !item?.status,
        isArchived: false
      };
      const comments = [...(item?.updatedComments || []), newComment];
      taskData.status = !item?.status;
      taskData.companyId = workspace_id;
      taskData.updatedComments = comments;
      if (isOpen) {
        let tasksList = groupData?.tasks?.map((obj: TaskDetailsInterface) => {
          return obj.id === taskData.id ? taskData : obj;
        });
        taskData.status = !item?.status;
        groupData.tasks = tasksList;
        dispatch(updateFilteredTasksList(groupData));
      } else {
        let completedTasksList = groupData?.completedTasks?.filter(
          (task: FilteredTasksListInterface) => task.id !== taskData.id
        );
        groupData.completedTasks = completedTasksList;
        groupData.tasks.splice(0, 0, taskData);
        dispatch(updateFilteredTasksList(groupData));
      }
      const payloadTask = { status: !item?.status, projectId: item?.projectId };
      const result = await dispatch(updateTaskDetails(item?.id, payloadTask));
      const commentResult = await dispatch(
        createNewComment(item?.id, {
          Type: COMMENT_TYPE.STATUS_CHANGE,
          Status: !item?.status
        })
      );

      if (result && commentResult) {
        if (!item?.status) {
          trackAnalyticActivity(TASK_ANALYTICS.MARKED_DONE);
        } else {
          trackAnalyticActivity(TASK_ANALYTICS.REOPENED);
          loadData();
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, loadData, filteredTasksList, workspace_id, currentProject]
  );

  // manage other task checkbox
  const onClickOtherTaskCheckbox = useCallback(
    async (e: React.SyntheticEvent, item: TaskDetailsInterface, isOpen: boolean) => {
      e.stopPropagation();
      let taskData = JSON.parse(JSON.stringify(item));
      const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
      const newComment: DataUpdatedCommentsInterface = {
        id: nanoid(),
        createdOn: new Date().toISOString(),
        updatedBy: userDetails?.id,
        updatedTime: new Date().toISOString(),
        type: COMMENT_TYPE.STATUS_CHANGE,
        status: !item?.status,
        user: { ...userDetails, name: userDetails?.given_name },
        isArchived: false
      };

      const allComments = item?.updatedComments;
      const comments = [...(allComments || []), newComment];
      taskData.status = !item?.status;
      taskData.companyId = workspace_id;
      taskData.updatedComments = comments;
      if (isOpen) {
        let otherClone = JSON.parse(JSON.stringify(otherTask));
        let tasksList = otherTask?.tasks?.map((obj) => {
          return obj?.id === taskData?.id ? taskData : obj;
        });
        otherClone.tasks = tasksList;
        setOtherTask(otherClone);
        if (isTemplateProject) return;
      } else {
        let taskToRemove = otherTask?.completedTasks?.find((obj) => obj.id === taskData.id);
        taskToRemove = JSON.parse(JSON.stringify(taskToRemove));
        if (taskToRemove) {
          let updatedCompletedTasks = otherTask?.completedTasks?.filter((obj) => obj.id !== taskData.id);
          otherTask.completedTasks = updatedCompletedTasks;
          taskToRemove.status = !item?.status;
          let updatedTasks = [...(otherTask?.tasks || []), taskToRemove];
          otherTask.tasks = updatedTasks;
          setOtherTask(otherTask);
        }
      }
      const payloadTask = { status: !item?.status, projectId: item?.projectId };
      const result = await dispatch(updateTaskDetails(item?.id, payloadTask));
      const commentResult = await dispatch(
        createNewComment(item?.id, {
          Type: COMMENT_TYPE.STATUS_CHANGE,
          Status: !item?.status
        })
      );
      if (result && commentResult) {
        if (!item?.status) trackAnalyticActivity(TASK_ANALYTICS.MARKED_DONE);
        else trackAnalyticActivity(TASK_ANALYTICS.REOPENED);
      }
    },
    [dispatch, isTemplateProject, otherTask, setOtherTask, workspace_id]
  );

  // logic for show task details
  const onClickTaskDetails = useCallback(
    (task: TaskDetailsInterface) => {
      if (isTemplateProject) return;
      const eventProperties = {
        source: 'task list',
        title: task?.name || ''
      };
      trackAnalyticActivity(TASK_ANALYTICS.VIEW, eventProperties);
      dispatch(clearSubTaskList());
      dispatch(setMyTaskDetailsData(task));
      history.push(`/projects/details/${task?.projectId}/tasks?task=${task?.id}`);
    },
    [isTemplateProject, dispatch, history]
  );

  // logic for delete task
  const deleteTask = useCallback(
    async (task: TaskDetailsInterface) => {
      try {
        setDeleteLoading(true);
        if (isTemplateProject) {
          const cloneTasks = JSON.parse(JSON.stringify(projectTemplateDetails?.tasks || []));
          const taskIndex = cloneTasks?.findIndex((x: { id: string }) => x?.id === task?.id);
          if (taskIndex !== -1) {
            cloneTasks?.splice(taskIndex, 1);
            dispatch(updateProjectTemplateDetails({ propsName: 'tasks', value: cloneTasks }));
          }
          setDeleteModelOpen(false);
          return;
        }
        const payloadTask = { isArchived: true, projectId: task?.projectId };
        const response = await dispatch(updateTaskDetails(task?.id, payloadTask));
        setDeleteModelOpen(false);
        if (response) {
          loadData && loadData();
        }
      } catch (e) {
        captureException(e);
        console.log('ERROR', e);
      } finally {
        setDeleteLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, loadData, projectTemplateDetails]
  );

  // Handle shortcut key 'C' to open the task creation modal with pre-filled default values.
  useHotkeys('c', async () => {
    setTimeout(() => {
      setIsOpenFromShortcut(true);
      setOpen(true);
      dispatch(updateCreateTaskInput({ propsName: 'projectId', value: currentProject }));
      dispatch(updateCreateTaskInput({ propsName: 'priority', value: currentProject?.priority?.default || 0 }));
      if (taskGroups?.length > 0) {
        dispatch(updateCreateTaskInput({ propsName: 'groupId', value: taskGroups[0] }));
      }
    }, 100);
  });

  const getItemStyle = (isDragging: boolean, draggableStyle: React.CSSProperties) => ({
    userSelect: 'none',
    background: isDragging ? 'var(--background-primary)' : 'none',
    borderRadius: isDragging ? 40 : 40,
    border: isDragging ? '0.5px solid var(--border-primary)' : '0.5px solid transparent',
    boxShadow: isDragging ? 'var(--shadow-card-hover)' : 'none',
    margin: isDragging ? '0 -10px' : '0',
    padding: isDragging ? '0 10px' : '0',
    ...draggableStyle,
    height: isDragging ? '36px' : 'unset'
  });

  //logic for drag end
  const onDragEnd = useCallback(
    async (result: DropResult) => {
      if (
        !result.destination ||
        groupingStatus === SHOW_TASK_GROUPING_TYPE.NO_GROUPING ||
        groupingStatus === SHOW_TASK_GROUPING_TYPE.GROUPING_BY_USER
      ) {
        return filteredTasksList;
      }
      const sourceGroupId = result.source.droppableId;
      const destinationGroupId = result.destination.droppableId;
      if (
        sourceGroupId === destinationGroupId &&
        taskFilter?.orderByStatus === SHOW_TASK_BY_ORDER.CUSTOM &&
        result?.destination?.index !== result.source.index
      ) {
        const filteredlistClone: FilteredTasksListInterface[] = JSON.parse(JSON.stringify(filteredTasksList || []));
        const groupIndex = filteredlistClone.findIndex((group) => group.id === sourceGroupId);
        if (groupIndex !== -1) {
          const currentGroup = filteredlistClone?.[groupIndex];
          const draggedTaskIndex = currentGroup?.tasks?.findIndex(
            (task: { id: string }) => task.id === result.draggableId
          );
          const draggedTaskCompletedIndex = currentGroup?.completedTasks?.findIndex(
            (task: { id: string }) => task.id === result.draggableId
          );
          const draggedTask = currentGroup?.tasks?.find((task: { id: string }) => task.id === result.draggableId);
          if (draggedTaskIndex !== -1 || draggedTaskCompletedIndex !== -1) {
            const groupTasks = JSON.parse(
              JSON.stringify(draggedTaskIndex !== -1 ? currentGroup?.tasks || [] : currentGroup?.completedTasks || [])
            );
            const newIndex = result?.destination?.index;
            let updatedTaskIndex: number;
            const updatedTaskList = JSON.parse(JSON.stringify(filteredTasksList || []));
            if (newIndex === 0) {
              const nextTaskIndex = groupTasks?.[0]?.index;
              updatedTaskIndex = nextTaskIndex ? nextTaskIndex - 50 : new Date(draggedTask?.createdOn || '').getTime();
            } else if (newIndex === groupTasks?.length - 1) {
              const previousTaskIndex = groupTasks?.[groupTasks?.length - 1]?.index;
              updatedTaskIndex = previousTaskIndex
                ? previousTaskIndex + 50
                : new Date(draggedTask?.createdOn || '').getTime();
            } else {
              if (result?.destination?.index > result.source.index) {
                const previousTaskIndex: number = groupTasks?.[result?.destination?.index]?.index || 0;
                const nextTaskIndex: number = groupTasks?.[result?.destination?.index + 1]?.index || 0;
                updatedTaskIndex = (previousTaskIndex + nextTaskIndex) / 2;
              } else {
                const previousTaskIndex: number = groupTasks?.[result?.destination?.index - 1]?.index || 0;
                const nextTaskIndex: number = groupTasks?.[result?.destination?.index]?.index || 0;
                updatedTaskIndex = (previousTaskIndex + nextTaskIndex) / 2;
              }
            }
            const elementToMove = groupTasks.splice(result.source.index, 1)[0];
            groupTasks.splice(result.destination.index, 0, { ...(elementToMove || []), index: updatedTaskIndex });
            if (draggedTaskIndex !== -1) updatedTaskList[groupIndex].tasks = groupTasks;
            else updatedTaskList[groupIndex].completedTasks = groupTasks;
            dispatch(setFilteredTasksList(updatedTaskList));
            const payloadTask = { index: updatedTaskIndex, projectId: currentProject?.id };
            await dispatch(updateTaskDetails(result?.draggableId, payloadTask));
          }
        }
        return;
      } else if (sourceGroupId === destinationGroupId) {
        return filteredTasksList;
      }

      const newFilteredlist = JSON.parse(JSON.stringify(filteredTasksList));

      const updatedTaskList = [...newFilteredlist];
      const sourceGroupIndex = updatedTaskList.findIndex((group) => group.id === sourceGroupId);
      const destinationGroupIndex = updatedTaskList.findIndex((group) => group.id === destinationGroupId);
      if (sourceGroupIndex !== -1 && destinationGroupIndex !== -1) {
        const draggedTaskIndex = updatedTaskList[sourceGroupIndex]?.tasks?.findIndex(
          (task: { id: string }) => task?.id === result?.draggableId
        );
        const draggedCompletedTaskIndex = updatedTaskList[sourceGroupIndex]?.completedTasks?.findIndex(
          (task: { id: string }) => task?.id === result?.draggableId
        );

        if (draggedTaskIndex !== -1 || draggedCompletedTaskIndex !== -1) {
          const newIndex = result?.destination?.index;
          const taskListKey = draggedTaskIndex !== -1 ? 'tasks' : 'completedTasks';
          const updatedSourceTasks = JSON.parse(JSON.stringify(updatedTaskList[sourceGroupIndex]?.[taskListKey] || []));
          const taskToMove: TaskDetailsInterface = JSON.parse(
            JSON.stringify(
              updatedSourceTasks[draggedTaskIndex !== -1 ? draggedTaskIndex : draggedCompletedTaskIndex] || {}
            )
          );
          updatedSourceTasks.splice(draggedTaskIndex !== -1 ? draggedTaskIndex : draggedCompletedTaskIndex, 1);

          let payload: {
            index?: number;
            groupId?: string;
            statusId?: string;
            milestoneId?: string;
            priority?: number;
          } = {};
          if (
            groupingStatus === SHOW_TASK_GROUPING_TYPE.GROUPING_BY_GROUP ||
            (groupingStatus === SHOW_TASK_GROUPING_TYPE.DEFAULT && currentProject?.isGroupEnabled)
          ) {
            taskToMove.groupId = destinationGroupId;
            const previousGroup = taskGroups?.find((x) => x?.id === sourceGroupId);
            const updatedGroup = taskGroups?.find((x) => x?.id === destinationGroupId);
            dispatch(
              createNewComment(result?.draggableId, {
                Type: COMMENT_TYPE.CHANGE_GROUP,
                PreviousGroup: previousGroup?.name,
                Group: updatedGroup?.name
              })
            );
            payload = {
              groupId: destinationGroupId
            };
          } else if (groupingStatus === SHOW_TASK_GROUPING_TYPE.GROUPING_BY_STATUS) {
            taskToMove.statusId = destinationGroupId;
            const currentStatus = currentProject?.statusData?.find((x) => x['_id'] === destinationGroupId);
            const previousStatus = currentProject?.statusData?.find((x) => x['_id'] === sourceGroupId);
            dispatch(
              createNewComment(result?.draggableId, {
                Type: COMMENT_TYPE.STATUS_ID_CHANGE,
                PreviousGroup: previousStatus?.Name,
                Group: currentStatus?.Name,
                Priority: currentStatus?.Type
              })
            );
            payload = {
              statusId: destinationGroupId
            };
          } else if (groupingStatus === SHOW_TASK_GROUPING_TYPE.GROUPING_BY_MILESTONE) {
            taskToMove.milestoneId = destinationGroupId;
            const milestone = milestoneList?.find((x) => x?.id === destinationGroupId);
            dispatch(
              createNewComment(result?.draggableId, {
                Type: COMMENT_TYPE.SET_MILESTONE,
                Group: milestone?.milestoneName
              })
            );
            payload = {
              milestoneId: destinationGroupId
            };
          } else if (groupingStatus === SHOW_TASK_GROUPING_TYPE.GROUPING_BY_PRIORITY) {
            taskToMove.priority = destinationGroupId;
            dispatch(
              createNewComment(result?.draggableId, {
                Type: COMMENT_TYPE.CHANGE_PRIORITY,
                PreviousPriority: Number(sourceGroupId) === 5 ? TASK_PRIORITY.NO_PRIORITY : Number(sourceGroupId),
                Priority: Number(destinationGroupId) === 5 ? TASK_PRIORITY.NO_PRIORITY : Number(destinationGroupId)
              })
            );
            if (taskToMove.priority === 5) {
              payload = {
                priority: TASK_PRIORITY.NO_PRIORITY
              };
            } else {
              payload = {
                priority: destinationGroupId
              };
            }
          }

          const destinationTasks = updatedTaskList[destinationGroupIndex]?.[taskListKey] || [];
          let newTaskIndex = 0;
          if (taskFilter?.orderByStatus === SHOW_TASK_BY_ORDER.CUSTOM) {
            if (destinationTasks.length === 0 || newIndex >= destinationTasks.length) {
              newTaskIndex =
                destinationTasks.length === 0
                  ? new Date(taskToMove.createdOn || '').getTime()
                  : destinationTasks[destinationTasks.length - 1].index + 50;
            } else if (newIndex === 0) {
              const nextTaskIndex = destinationTasks[0].index;
              newTaskIndex = nextTaskIndex ? nextTaskIndex - 50 : new Date(taskToMove.createdOn || '').getTime();
            } else {
              const previousTaskIndex = destinationTasks[newIndex - 1].index || 0;
              const nextTaskIndex = destinationTasks[newIndex].index || 0;
              newTaskIndex = (previousTaskIndex + nextTaskIndex) / 2;
            }

            taskToMove.index = newTaskIndex;
            destinationTasks.splice(newIndex, 0, taskToMove);
          } else {
            destinationTasks.push(taskToMove);
          }
          updatedTaskList[destinationGroupIndex][taskListKey] = destinationTasks;
          updatedTaskList[sourceGroupIndex][taskListKey].splice(
            draggedTaskIndex !== -1 ? draggedTaskIndex : draggedCompletedTaskIndex,
            1
          );
          dispatch(setFilteredTasksList(updatedTaskList));
          if (isTemplateProject) {
            const updatedTasks = projectTemplateDetails?.tasks?.map((task) => {
              if (task?.id === result.draggableId) return { ...(task || {}), groupId: destinationGroupId };
              else return task;
            });
            dispatch(updateProjectTemplateDetails({ propsName: 'tasks', value: updatedTasks }));
            return;
          } else {
            if (taskFilter?.orderByStatus === SHOW_TASK_BY_ORDER.CUSTOM) {
              payload.index = newTaskIndex;
            }
            const payloadTask = { ...(payload || {}), projectId: currentProject?.id };
            const resultUpdate = await dispatch(updateTaskDetails(result?.draggableId, payloadTask));
            if (resultUpdate) {
              trackAnalyticActivity(TASK_ANALYTICS.GROUP_CHANGED);
            }
          }
        }
      }
    },
    [
      groupingStatus,
      taskFilter?.orderByStatus,
      filteredTasksList,
      dispatch,
      currentProject?.id,
      currentProject?.isGroupEnabled,
      currentProject?.statusData,
      isTemplateProject,
      taskGroups,
      milestoneList,
      projectTemplateDetails?.tasks
    ]
  );

  // toggle completed task
  const toggleCompletedTask = useCallback((value: boolean, id: string) => {
    if (!value) {
      setSelectedId('');
    }
    setSelectedId(id);
    setShowCompletedTask(value);
  }, []);

  // for select initial task
  const selectInitialTask = () => {
    // Select the first task of the first group if nothing is selected or hovered.

    if (isEmpty(hoveredTask?.taskId) && selectedTasks?.length === 0) {
      const firstGroup = filteredTasksList[0];
      if (firstGroup && firstGroup?.tasks && firstGroup?.tasks?.length > 0) {
        setSelectedTasks([
          {
            groupId: groupingStatus === SHOW_TASK_GROUPING_TYPE.NO_GROUPING ? 'no_grouping' : firstGroup?.id || '',
            taskId: firstGroup?.tasks[0]?.id
          }
        ]);
      }
    }
  };

  useEffect(() => {
    setHoverSelect(selectedTasks?.length === 0);
  }, [selectedTasks?.length]);

  // logic for find next selectable task
  const findNextSelectableTask = (currentGroupId: string, currentTaskId: string, direction: string) => {
    const tasksToSearch = selectedTasksFromCompleted ? 'completedTasks' : 'tasks';
    const allGroups = [...filteredTasksList];
    const currentGroupIndex =
      groupingStatus === SHOW_TASK_GROUPING_TYPE.NO_GROUPING
        ? 0
        : allGroups?.findIndex((group) => group?.id === currentGroupId);
    const currentTasks: TaskDetailsInterface[] = allGroups[currentGroupIndex]?.[tasksToSearch] || [];
    const currentTaskIndex: number = currentTasks?.findIndex((task) => task?.id === currentTaskId);

    if (direction === 'down') {
      if (currentTaskIndex + 1 < currentTasks?.length) {
        return { nextGroupId: currentGroupId, nextTaskId: currentTasks[currentTaskIndex + 1]?.id };
      }
    } else if (direction === 'up') {
      if (currentTaskIndex > 0) {
        return { nextGroupId: currentGroupId, nextTaskId: currentTasks[currentTaskIndex - 1]?.id };
      }
    }
    return { nextGroupId: null, nextTaskId: null };
  };

  // function for expand selection
  const expandSelection = (direction: string) => {
    let startPoint = hoveredTask;
    if (isEmpty(startPoint.taskId) && selectedTasks.length > 0) {
      startPoint = selectedTasks[selectedTasks?.length - 1];
    }
    if (isEmpty(startPoint.taskId) && selectedTasks?.length === 0) {
      selectInitialTask();
      return;
    }

    if (!isEmpty(startPoint?.taskId) && selectedTasks?.length === 0) {
      setSelectedTasks([
        {
          groupId: groupingStatus === SHOW_TASK_GROUPING_TYPE.NO_GROUPING ? 'no_grouping' : startPoint?.groupId,
          taskId: startPoint?.taskId
        }
      ]);
      setHoverSelect(false);
      setHoveredTask({ groupId: '', taskId: '' });
      return;
    }
    let currentPoint = startPoint;

    let foundNewTask = false;

    for (let i = 0; i < selectedTasks?.length + 1; i++) {
      // Limit to the number of tasks + 1 to prevent infinite loops
      const next = findNextSelectableTask(currentPoint?.groupId, currentPoint?.taskId, direction);
      if (isEmpty(next?.nextGroupId) || isEmpty(next?.nextTaskId)) {
        // If no next task is found, reset to the first task of the same group
        // eslint-disable-next-line no-loop-func
        const firstTaskIdInGroup = filteredTasksList?.find((group) => group?.id === currentPoint?.groupId)?.tasks?.[0]
          ?.id;
        currentPoint = { groupId: currentPoint?.groupId, taskId: firstTaskIdInGroup || '' };
        break;
      }
      currentPoint = { groupId: next?.nextGroupId || '', taskId: next?.nextTaskId || '' };

      // Check if the new task is already selected or not
      const index = selectedTasks?.findIndex(
        // eslint-disable-next-line no-loop-func
        (task) => task?.groupId === currentPoint?.groupId && task?.taskId === currentPoint?.taskId
      );
      if (index === -1) {
        // If not found in the selected list, select it
        selectedTasks?.push(currentPoint);
        foundNewTask = true;
        break;
      } else if (i > 0) {
        continue;
      }
    }

    if (!foundNewTask && selectedTasks?.length > 0) {
      if (direction === 'down') {
        selectedTasks.shift(); // Remove the first element if navigating down
      } else {
        selectedTasks.pop(); // Remove the last element if navigating up
      }
    }
    setHoverSelect(selectedTasks?.length === 0);
    if (selectedTasks?.length === 0) {
      setSelectedTasksFromCompleted(false);
    }
    setSelectedTasks([...selectedTasks]);
  };

  //  Handle 'Shift+Down' shortcut to expand task selection downward and update the border radius for selected tasks.
  useHotkeys('shift+down', async () => {
    const selectedItems = document.querySelectorAll('.selectedtask');
    selectedItems.forEach(function (item: any) {
      item.style.borderRadius = '40px';
    });
    expandSelection('down');
  });

  // Handle 'Shift+Up' shortcut to expand task selection upward and update the border radius for selected tasks.
  useHotkeys('shift+up', async () => {
    const selectedItems = document.querySelectorAll('.selectedtask');
    selectedItems.forEach(function (item: any) {
      item.style.borderRadius = '40px';
    });
    expandSelection('up');
  });

  // logic for hover task
  const handleHoverTask = useCallback(
    (groupId: string, taskId: string) => {
      if (isHoverSelect) {
        setSelectedTasksFromCompleted(false);
        setHoveredTask({ groupId, taskId });
      }
    },
    [isHoverSelect]
  );

  // Handle hover on completed tasks
  const handleCompletedHoverTask = useCallback(
    (groupId: string, taskId: string) => {
      if (isHoverSelect) {
        setSelectedTasksFromCompleted(true);
        setHoveredTask({ groupId, taskId });
      }
    },
    [isHoverSelect]
  );

  // for check is selected
  const isSelected = useCallback(
    (groupId: string, taskId: string) => {
      return selectedTasks?.some(
        (st: { groupId: string; taskId: string }) => st?.groupId === groupId && st?.taskId === taskId
      );
    },
    [selectedTasks]
  );

  // function for remove selected task
  const onRemoveSelectedTasks = useCallback(() => {
    const selectedItems = document.querySelectorAll('.selectedtask');
    selectedItems.forEach(function (item: any) {
      item.style.borderRadius = '40px';
    });
    setSelectedTasks([]);
    setHoverSelect(true);
    setSelectedTasksFromCompleted(false);
  }, []);

  // for delete selected task
  const onDeleteSelectedTasks = useCallback(
    async (tasks: { taskId: string; groupId: string }[]) => {
      try {
        setDeleteLoading(true);
        const promises = tasks.map(async (task) => {
          const payloadTask = { isArchived: true, projectId: currentProject?.id };
          const response = await dispatch(updateTaskDetails(task?.taskId, payloadTask));
          return response;
        });

        await Promise.all(promises);
        setSelectedTasks([]);
        loadData && loadData();
        setSelectedDeleteModelOpen(false);
      } catch (e) {
        captureException(e);
        console.log('ERROR', e);
      } finally {
        setDeleteLoading(false);
      }
    },
    [currentProject?.id, dispatch, loadData]
  );

  // Function to dynamically update border-radius for selected tasks based on their position
  function updateBorderRadius() {
    const selectedItems = document.querySelectorAll('.selectedtask');
    const numSelectedItems = selectedItems.length;

    selectedItems.forEach(function (item: any, index: number) {
      if (numSelectedItems === 1) {
        item.style.borderRadius = '40px';
      } else if (index === 0) {
        item.style.borderRadius = '18px 18px 0 0';
      } else if (index === numSelectedItems - 1) {
        item.style.borderRadius = '0 0 18px 18px';
      } else {
        item.style.borderRadius = '0';
      }
    });
  }

  // Add event listener to update border-radius when a key is pressed
  document.addEventListener('keydown', function (event) {
    updateBorderRadius();
  });

  // handle complete project task
  const onCompleteProjectTask = useCallback(async () => {
    if (currentTask) {
      const commentData = onChangeComment(currentTask);
      let taskData = JSON.parse(JSON.stringify(currentTask || {}));
      let groupData = JSON.parse(JSON.stringify(currentGroup || {}));
      taskData.status = !currentTask?.status;
      taskData.companyId = workspace_id;
      taskData.updatedComments = commentData?.updatedComments;
      let tasksList = groupData?.tasks?.map((obj: TaskDetailsInterface) => {
        return obj.id === taskData.id ? taskData : obj;
      });
      taskData.status = !currentTask?.status;
      groupData.tasks = tasksList;
      dispatch(updateFilteredTasksList(groupData));
      const payloadTask = { status: !currentTask?.status, projectId: currentTask?.projectId };
      const result = await dispatch(updateTaskDetails(currentTask?.id, payloadTask));
      const commentResult = await dispatch(
        createNewComment(currentTask?.id, {
          Type: COMMENT_TYPE.STATUS_CHANGE,
          Status: !currentTask?.status
        })
      );
      if (result && commentResult) {
        trackAnalyticActivity(TASK_ANALYTICS.MARKED_DONE);
      }
    }
  }, [currentGroup, currentTask, dispatch, workspace_id]);

  // for handle confirm
  const onClickConfirm = useCallback(async () => {
    setConfirmationLoading(true);
    try {
      if (currentTask?.subTasks && currentTask?.subTasks?.length > 0) {
        for (const subTask of currentTask?.subTasks) {
          if (subTask?.status === false) {
            const taskDetailsClone = JSON.parse(JSON.stringify(subTask));
            taskDetailsClone.status = true;
            dispatch(updatSubTaskList(taskDetailsClone));
            const payloadTask = { status: true, projectId: subTask?.projectId };
            const result = dispatch(updateTaskDetails(subTask?.id, payloadTask));
            const commentResult = dispatch(
              createNewComment(subTask?.id, {
                Type: COMMENT_TYPE.STATUS_CHANGE,
                Status: true
              })
            );
            if (result && commentResult) {
              trackAnalyticActivity(TASK_ANALYTICS.MARKED_DONE);
            }
          }
        }
        onCompleteProjectTask();
      }
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setConfirmationLoading(false);
      setIsConfirmationModelOpen(false);
    }
  }, [currentTask, dispatch, onCompleteProjectTask]);

  // for change status
  const onChangeStatus = useCallback(
    async (
      status: StatusListInterface,
      previousStatus: StatusListInterface,
      task: TaskDetailsInterface,
      group?: FilteredTasksListInterface,
      isSubTask?: boolean
    ) => {
      const payloadTask = { statusId: status['_id'], projectId: task?.projectId };
      let taskData = JSON.parse(JSON.stringify(task));
      const commentData = onChangeComment(task);
      if (isTemplateProject) {
        const updatedTasks = projectTemplateDetails?.tasks?.map((task) => {
          if (task?.id === taskData.id) return { ...(task || {}), statusId: status['_id'] };
          else return task;
        });
        dispatch(updateProjectTemplateDetails({ propsName: 'tasks', value: updatedTasks }));
        return;
      } else {
        if (group) {
          let groupData = JSON.parse(JSON.stringify(group || {}));
          taskData.statusId = status['_id'];
          taskData.companyId = workspace_id;
          taskData.updatedComments = commentData?.updatedComments;
          if (isSubTask) {
            const parentTask = groupData?.tasks?.map((obj: TaskDetailsInterface) => {
              if (obj.id === taskData.parentTaskId) {
                // Update the subtask list in the parent task
                obj.subTasks = obj.subTasks?.map((subTask: TaskDetailsInterface) =>
                  subTask.id === taskData.id ? taskData : subTask
                );
              }
              return obj;
            });
            groupData.tasks = parentTask;
          } else {
            let tasksList = groupData?.tasks?.map((obj: TaskDetailsInterface) => {
              return obj.id === taskData.id ? taskData : obj;
            });
            groupData.tasks = tasksList;
          }
          dispatch(updateFilteredTasksList(groupData));
        } else {
          taskData.statusId = status['_id'];
          taskData.companyId = workspace_id;
          taskData.updatedComments = commentData?.updatedComments;
          if (isSubTask) {
            const parentTask = otherTask?.tasks?.map((obj: TaskDetailsInterface) => {
              if (obj.id === taskData.parentTaskId) {
                // Update the subtask list in the parent task
                obj.subTasks = obj.subTasks?.map((subTask: TaskDetailsInterface) =>
                  subTask.id === taskData.id ? taskData : subTask
                );
              }
              return obj;
            });
            otherTask.tasks = parentTask;
          } else {
            let tasksList = otherTask?.tasks?.map((obj) => {
              return obj?.id === taskData?.id ? taskData : obj;
            });
            otherTask.tasks = tasksList;
          }
          setOtherTask(otherTask);
        }
        const result = await dispatch(updateTaskDetails(task?.id, payloadTask));
        if (result) {
          await dispatch(
            createNewComment(task?.id, {
              Type: COMMENT_TYPE.STATUS_ID_CHANGE,
              Group: status?.Name,
              PreviousGroup: previousStatus?.Name,
              Priority: status?.Type
            })
          );
          trackAnalyticActivity(TASK_ANALYTICS.STATUS_CHANGED);
        }
        if (loadData && task.status) loadData();
      }
    },
    [dispatch, loadData, otherTask, setOtherTask, workspace_id, isTemplateProject, projectTemplateDetails?.tasks]
  );

  // handle update status
  const onUpdateStatus = useCallback(
    async (
      status: StatusListInterface,
      previousStatus: StatusListInterface,
      task: TaskDetailsInterface,
      isSubTask: boolean,
      group?: FilteredTasksListInterface
    ) => {
      if (status['_id'] && status['_id'] !== previousStatus['_id']) {
        let allSubTasksStatus = true;
        if (task?.subTasks && task?.subTasks?.length > 0) {
          for (const subTask of task?.subTasks) {
            if (subTask?.status === false) {
              allSubTasksStatus = false;
              break;
            }
          }
        }
        if (status?.Type === STATUS_TYPE.COMPLETED && !allSubTasksStatus) {
          handleOpenConfirmationModal(false);
          setStatus(status);
          setPreviousStatus(previousStatus);
          setCurrentTask(task);
          setCurrentGroup(group);
          return;
        }

        onChangeStatus(status, previousStatus, task, group, isSubTask);
      }
    },
    [handleOpenConfirmationModal, onChangeStatus]
  );

  // for confirm status
  const onClickConfirmStatus = useCallback(async () => {
    setConfirmationLoading(true);
    try {
      if (status) {
        const payloadTask = { statusId: status['_id'], projectId: currentTask?.projectId };
        if (currentTask?.subTasks && currentTask?.subTasks?.length > 0 && status?.Type === STATUS_TYPE?.COMPLETED) {
          for (const subTask of currentTask?.subTasks) {
            if (subTask?.status === false) {
              const result = await dispatch(updateTaskDetails(subTask?.id, payloadTask));
              if (result) {
                await dispatch(
                  createNewComment(subTask?.id, {
                    Type: COMMENT_TYPE.STATUS_ID_CHANGE,
                    Group: status?.Name,
                    PreviousGroup: previousStatus?.Name,
                    Priority: status?.Type
                  })
                );
                const taskDetailsClone = JSON.parse(JSON.stringify(subTask));
                taskDetailsClone.statusId = status['_id'];
                dispatch(updatSubTaskList(taskDetailsClone));
                trackAnalyticActivity(TASK_ANALYTICS.STATUS_CHANGED);
              }
            }
          }
        }
      }
      if (status && previousStatus && currentTask) {
        onChangeStatus(status, previousStatus, currentTask, currentGroup);
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setConfirmationLoading(false);
      closeConfirmationModal();
    }
  }, [closeConfirmationModal, currentGroup, currentTask, dispatch, onChangeStatus, previousStatus, status]);

  // handle on click no
  const onClickNo = useCallback(async () => {
    setCancelLoading(true);
    try {
      if (isCheckBox) {
        onCompleteProjectTask();
      } else {
        if (status && previousStatus && currentTask && currentGroup) {
          onChangeStatus(status, previousStatus, currentTask, currentGroup);
        }
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setCancelLoading(false);
      setIsConfirmationModelOpen(false);
    }
  }, [currentGroup, currentTask, isCheckBox, onChangeStatus, onCompleteProjectTask, previousStatus, status]);

  // logic for bulk update group
  const onBulkUpdateGroup = useCallback(
    async (
      group: TaskGroupInterface,
      tasks: {
        taskId: string;
        groupId: string;
      }[]
    ) => {
      let promises: Promise<void>[] = [];
      tasks.forEach(async (task) => {
        const payloadTask = { groupId: group?.id, projectId: currentProject?.id };
        promises.push(dispatch(updateTaskDetails(task?.taskId, payloadTask)));
      });
      await Promise.all(promises);
      setSelectedTasks([]);
      if (loadData) loadData();
    },
    [currentProject?.id, dispatch, loadData]
  );

  // logic for bulk update status
  const onBulkUpdateStatus = useCallback(
    async (
      status: StatusListInterface,
      previousStatus: StatusListInterface,
      tasks: {
        taskId: string;
        groupId: string;
      }[]
    ) => {
      let promises: Promise<void>[] = [];
      tasks.forEach(async (task) => {
        const payloadTask = { statusId: status?.['_id'], projectId: currentProject?.id };
        promises.push(dispatch(updateTaskDetails(task?.taskId, payloadTask)));
      });
      await Promise.all(promises);
      setSelectedTasks([]);
      if (loadData) loadData();
    },
    [currentProject?.id, dispatch, loadData]
  );

  // for show more options
  const handleShowOptions = useCallback(() => {
    dispatch(setIsTaskFilterOpen(true));
  }, [dispatch]);

  //handle subtask show trigger
  const handleSubtaskOpen = useCallback(
    (id: string) => {
      let payload = {
        id: '',
        showCompleted: false
      };
      if (openSubTask?.id === id) {
        setOpenSubtask(payload);
      } else {
        setOpenSubtask({ ...payload, id: id });
      }
    },
    [openSubTask?.id]
  );

  //handle show completed subtask
  const handleShowCompletedSubTask = useCallback((id: string, isShow: boolean) => {
    let payload = {
      id: id,
      showCompleted: isShow
    };
    setOpenSubtask(payload);
  }, []);

  // render filtered task list
  const renderFilteredTaskList = useMemo(() => {
    return filteredTasksList?.map((item, index: number) => {
      let comparedValue: string;
      if (
        groupingStatus === SHOW_TASK_GROUPING_TYPE.GROUPING_BY_USER ||
        groupingStatus === SHOW_TASK_GROUPING_TYPE.GROUPING_BY_PRIORITY
      ) {
        comparedValue = item?.name || '';
      } else {
        comparedValue = item?.id || '';
      }
      if (
        (item?.tasks && item?.tasks?.length > 0) ||
        (item?.completedTasks && item?.completedTasks?.length > 0) ||
        taskFilter?.showEmptyGroup
      ) {
        return (
          <Droppable droppableId={item.id} key={item?.id}>
            {(provided: DraggableProvided) => {
              return (
                <Itemblock key={item?.id} ref={provided.innerRef} {...provided.droppableProps}>
                  {!isEmpty(item?.name) && (
                    <Tasklist>
                      <Title>{item?.name}</Title>
                      {!isTemplateProject && (
                        <Collapsedopdown
                          groupDetail={item}
                          loadData={loadData}
                          setModalOpen={setModalOpen}
                          isTaskpage={true}
                          workspaceDetail={workspace}
                        />
                      )}
                    </Tasklist>
                  )}
                  {item?.tasks?.map((task: TaskDetailsInterface, taskIndex: number) => {
                    if (item?.isCollapse) return <></>;
                    let dueResult: { overDue: boolean; dueTime: string; dueParam?: string } | undefined;
                    if (task?.dueDate) {
                      dueResult = calculateDueTime(task.dueDate);
                    }
                    let statusDetails: StatusListInterface | undefined;
                    if (currentProject?.statusEnable && currentProject?.statusData?.length > 0) {
                      if (!task?.statusId) {
                        statusDetails = currentProject?.defaultStatus;
                      } else statusDetails = currentProject?.statusData?.find((x) => x['_id'] === task?.statusId);
                    }
                    let currentTaskSubtask = task?.subTasks?.filter((sub) => !sub?.status);
                    if (openSubTask?.id === task?.id && openSubTask?.showCompleted) {
                      const completedTask = task?.subTasks?.filter((sub) => sub?.status);
                      currentTaskSubtask = [...(currentTaskSubtask || []), ...(completedTask || [])];
                    }
                    const totalCompletedSubTask = task?.subTasks?.filter((sub) => sub?.status);
                    return (
                      <Draggable draggableId={task?.id} index={taskIndex} key={task?.id}>
                        {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
                          return (
                            <div
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...provided.draggableProps}
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...{ ref: provided.innerRef }}
                              key={task?.id}
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...{
                                style: getItemStyle(snapshot.isDragging, provided.draggableProps.style)
                              }}>
                              <TaskItem
                                dueResult={dueResult}
                                taskGroups={taskGroups}
                                currentProject={currentProject}
                                onClick={() => onClickTaskDetails(task)}
                                isSelected={isSelected(
                                  groupingStatus === SHOW_TASK_GROUPING_TYPE.NO_GROUPING
                                    ? 'no_grouping'
                                    : item?.id || '',
                                  task?.id
                                )}
                                loadData={loadData}
                                onClickCheckbox={(e) => onClickTaskCheckbox(e, task, item, true)}
                                onMouseEnter={() => handleHoverTask(item.id || '', task.id)}
                                onMouseLeave={() => handleHoverTask('', '')}
                                onUpdateStatus={(status: StatusListInterface, previousStatus: StatusListInterface) =>
                                  onUpdateStatus(status, previousStatus, task, false, item)
                                }
                                provided={provided}
                                snapshot={snapshot}
                                statusDetails={statusDetails}
                                task={task}
                                taskContentKey={`task_${task?.id}_${index}_task_index_${taskIndex}`}
                                isOverdue={dueResult?.overDue && !task.status}
                                isTemplateProject={isTemplateProject}
                                setOpen={(value: boolean) => setOpen(value)}
                                setDeleteModelopen={(value: boolean) => setDeleteModelOpen(value)}
                                setSelectedItem={(value: TaskDetailsInterface) => setSelectedItem(value)}
                                currentAssignBox={item?.id === currentTaskGroup ? currentAssignBox : ''}
                                setCurrentAssignBox={(value: string) => onAssignGroupClick(value, item?.id || '')}
                                isAssignDropdownOpen={isAssignDropdownOpen}
                                setIsAssignDropdownOpen={(value: boolean) => setIsAssignDropdownOpen(value)}
                                handleSubtaskOpen={(value: string) => handleSubtaskOpen(value)}
                                isActiveSubTask={openSubTask?.id === task?.id}
                              />
                              {!snapshot.isDragging &&
                                task?.subTasks &&
                                task?.subTasks?.length > 0 &&
                                (taskFilter?.showSubTask || openSubTask?.id === task?.id) && (
                                  <ShowSubTask>
                                    <LineDiv
                                      isApplyMargin={totalCompletedSubTask && totalCompletedSubTask?.length > 0}
                                    />
                                    <SubTaskList>
                                      {currentTaskSubtask?.map((sub) => {
                                        const x: TaskDetailsInterface = sub;
                                        let dueResult:
                                          | { overDue: boolean; dueTime: string; dueParam?: string }
                                          | undefined;
                                        if (sub?.dueDate) {
                                          dueResult = calculateDueTime(sub.dueDate);
                                        }
                                        let statusDetails: StatusListInterface | undefined;
                                        if (currentProject?.statusEnable && currentProject?.statusData?.length > 0) {
                                          if (!sub?.statusId) {
                                            statusDetails = currentProject?.defaultStatus;
                                          } else
                                            statusDetails = currentProject?.statusData?.find(
                                              (x) => x['_id'] === sub?.statusId
                                            );
                                        }
                                        return (
                                          <TaskItem
                                            key={x?.id}
                                            dueResult={dueResult}
                                            taskGroups={taskGroups}
                                            currentProject={currentProject}
                                            onClick={() => onClickTaskDetails(x)}
                                            isSelected={isSelected(
                                              groupingStatus === SHOW_TASK_GROUPING_TYPE.NO_GROUPING
                                                ? 'no_grouping'
                                                : item?.id || '',
                                              x?.id
                                            )}
                                            loadData={loadData}
                                            onClickCheckbox={(e) => onClickTaskCheckbox(e, x, item, true)}
                                            onMouseEnter={() => handleHoverTask(item.id || '', x?.id)}
                                            onMouseLeave={() => handleHoverTask('', '')}
                                            onUpdateStatus={(
                                              status: StatusListInterface,
                                              previousStatus: StatusListInterface
                                            ) => onUpdateStatus(status, previousStatus, x, true, item)}
                                            provided={provided}
                                            snapshot={snapshot}
                                            statusDetails={statusDetails}
                                            task={x}
                                            taskContentKey={`task_${x?.id}_${index}_task_index_${taskIndex}`}
                                            isOverdue={dueResult?.overDue && !x.status}
                                            isTemplateProject={isTemplateProject}
                                            setOpen={(value: boolean) => setOpen(value)}
                                            setDeleteModelopen={(value: boolean) => setDeleteModelOpen(value)}
                                            setSelectedItem={(value: TaskDetailsInterface) => setSelectedItem(value)}
                                            currentAssignBox={
                                              item?.id === currentTaskGroup || !currentTaskGroup ? currentAssignBox : ''
                                            }
                                            setCurrentAssignBox={(value: string) =>
                                              onAssignGroupClick(value, item?.id || '')
                                            }
                                            isAssignDropdownOpen={isAssignDropdownOpen}
                                            setIsAssignDropdownOpen={(value: boolean) => setIsAssignDropdownOpen(value)}
                                            isSubTask={true}
                                            group={item}
                                          />
                                        );
                                      })}
                                      {/* show and hide complete task ui */}
                                      {totalCompletedSubTask && totalCompletedSubTask?.length > 0 && (
                                        <>
                                          {(!openSubTask?.showCompleted || openSubTask?.id !== task?.id) && (
                                            <Showhide
                                              showSubTask={true}
                                              onClick={() => handleShowCompletedSubTask(task?.id, true)}>
                                              Show Completed Tasks
                                            </Showhide>
                                          )}
                                          {openSubTask?.showCompleted && openSubTask?.id === task?.id && (
                                            <Showhide
                                              showSubTask={true}
                                              onClick={() => handleShowCompletedSubTask(task?.id, false)}>
                                              Hide Completed Tasks
                                            </Showhide>
                                          )}
                                        </>
                                      )}
                                    </SubTaskList>
                                  </ShowSubTask>
                                )}
                            </div>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                  {provided?.placeholder}
                  {item?.completedTasks && item?.completedTasks?.length > 0 && (
                    <ShowTask>
                      {selectedId === comparedValue && showCompletedTask ? (
                        <Showhide onClick={() => toggleCompletedTask(false, '')}>Hide Completed Tasks</Showhide>
                      ) : (
                        <Showhide
                          onClick={() =>
                            toggleCompletedTask(
                              true,
                              groupingStatus === SHOW_TASK_GROUPING_TYPE.GROUPING_BY_USER ||
                                groupingStatus === SHOW_TASK_GROUPING_TYPE.GROUPING_BY_PRIORITY
                                ? item?.name
                                : item?.id || ''
                            )
                          }>
                          Show Completed Tasks
                        </Showhide>
                      )}
                      {selectedId === comparedValue && showCompletedTask && item?.completedTasks?.length > 0 && (
                        <Data isShowCompletedTask={true}>
                          {item?.completedTasks?.map((task, completedtaskIndex: number) => {
                            let dueResult: { overDue: boolean; dueTime: string; dueParam?: string } | undefined;
                            if (task?.dueDate) {
                              dueResult = calculateDueTime(task.dueDate);
                            }
                            let statusDetails: StatusListInterface | undefined;
                            if (currentProject?.statusEnable && currentProject?.statusData?.length > 0) {
                              if (!task?.statusId) statusDetails = currentProject?.defaultStatus;
                              else statusDetails = currentProject?.statusData?.find((x) => x['_id'] === task?.statusId);
                            }
                            let currentTaskSubtask = task?.subTasks?.filter((sub) => !sub?.status);
                            if (openSubTask?.id === task?.id && openSubTask?.showCompleted) {
                              currentTaskSubtask = task?.subTasks;
                            }
                            const totalCompletedSubTask = task?.subTasks?.filter((sub) => sub?.status);

                            return (
                              <Draggable draggableId={task.id} index={completedtaskIndex} key={task.id}>
                                {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                                  <>
                                    <TaskItem
                                      dueResult={dueResult}
                                      taskGroups={taskGroups}
                                      currentProject={currentProject}
                                      onClick={() => onClickTaskDetails(task)}
                                      isSelected={isSelected(
                                        groupingStatus === SHOW_TASK_GROUPING_TYPE.NO_GROUPING
                                          ? 'no_grouping'
                                          : item?.id || '',
                                        task?.id
                                      )}
                                      // eslint-disable-next-line max-len
                                      taskContentKey={`task_${task?.id}_${index}_task_index_${completedtaskIndex}`}
                                      loadData={loadData}
                                      onClickCheckbox={(e) => onClickTaskCheckbox(e, task, item, false)}
                                      onMouseEnter={() => handleCompletedHoverTask(item.id || '', task.id)}
                                      onMouseLeave={() => handleCompletedHoverTask('', '')}
                                      onUpdateStatus={(
                                        status: StatusListInterface,
                                        previousStatus: StatusListInterface
                                      ) => onUpdateStatus(status, previousStatus, task, false, item)}
                                      provided={provided}
                                      snapshot={snapshot}
                                      statusDetails={statusDetails}
                                      task={task}
                                      isCompletedTask
                                      isTemplateProject={isTemplateProject}
                                      setOpen={(value: boolean) => setOpen(value)}
                                      setDeleteModelopen={(value: boolean) => setDeleteModelOpen(value)}
                                      setSelectedItem={(value: TaskDetailsInterface) => setSelectedItem(value)}
                                      currentAssignBox={
                                        item?.id === currentTaskGroup || !currentTaskGroup ? currentAssignBox : ''
                                      }
                                      setCurrentAssignBox={(value: string) => onAssignGroupClick(value, item?.id || '')}
                                      isAssignDropdownOpen={isAssignDropdownOpen}
                                      setIsAssignDropdownOpen={(value: boolean) => setIsAssignDropdownOpen(value)}
                                      handleSubtaskOpen={(value: string) => handleSubtaskOpen(value)}
                                      isActiveSubTask={openSubTask?.id === task?.id}
                                    />
                                    {/* show subtask ui */}
                                    {task?.subTasks &&
                                      task?.subTasks?.length > 0 &&
                                      (taskFilter?.showSubTask || openSubTask?.id === task?.id) && (
                                        <ShowSubTask>
                                          <LineDiv
                                            isApplyMargin={totalCompletedSubTask && totalCompletedSubTask?.length > 0}
                                          />
                                          <SubTaskList>
                                            {currentTaskSubtask?.map((sub) => {
                                              const x: TaskDetailsInterface = sub;
                                              let dueResult:
                                                | { overDue: boolean; dueTime: string; dueParam?: string }
                                                | undefined;
                                              if (sub?.dueDate) {
                                                dueResult = calculateDueTime(sub.dueDate);
                                              }
                                              let statusDetails: StatusListInterface | undefined;
                                              if (
                                                currentProject?.statusEnable &&
                                                currentProject?.statusData?.length > 0
                                              ) {
                                                if (!sub?.statusId) statusDetails = currentProject?.defaultStatus;
                                                else
                                                  statusDetails = currentProject?.statusData?.find(
                                                    (x) => x['_id'] === sub?.statusId
                                                  );
                                              }
                                              return (
                                                <TaskItem
                                                  key={x?.id}
                                                  isSubTask={true}
                                                  dueResult={dueResult}
                                                  taskGroups={taskGroups}
                                                  currentProject={currentProject}
                                                  onClick={() => onClickTaskDetails(x)}
                                                  isSelected={isSelected(
                                                    groupingStatus === SHOW_TASK_GROUPING_TYPE.NO_GROUPING
                                                      ? 'no_grouping'
                                                      : item?.id || '',
                                                    x?.id
                                                  )}
                                                  // eslint-disable-next-line max-len
                                                  taskContentKey={`task_${x?.id}_${index}_task_index_${completedtaskIndex}`}
                                                  loadData={loadData}
                                                  onClickCheckbox={(e) => onClickTaskCheckbox(e, x, item, false)}
                                                  onMouseEnter={() => handleCompletedHoverTask(item.id || '', x?.id)}
                                                  onMouseLeave={() => handleCompletedHoverTask('', '')}
                                                  onUpdateStatus={(
                                                    status: StatusListInterface,
                                                    previousStatus: StatusListInterface
                                                  ) => onUpdateStatus(status, previousStatus, x, true, item)}
                                                  provided={provided}
                                                  snapshot={snapshot}
                                                  statusDetails={statusDetails}
                                                  task={x}
                                                  isCompletedTask
                                                  isTemplateProject={isTemplateProject}
                                                  setOpen={(value: boolean) => setOpen(value)}
                                                  setDeleteModelopen={(value: boolean) => setDeleteModelOpen(value)}
                                                  setSelectedItem={(value: TaskDetailsInterface) =>
                                                    setSelectedItem(value)
                                                  }
                                                  currentAssignBox={
                                                    item?.id === currentTaskGroup ? currentAssignBox : ''
                                                  }
                                                  setCurrentAssignBox={(value: string) =>
                                                    onAssignGroupClick(value, item?.id || '')
                                                  }
                                                  isAssignDropdownOpen={isAssignDropdownOpen}
                                                  setIsAssignDropdownOpen={(value: boolean) =>
                                                    setIsAssignDropdownOpen(value)
                                                  }
                                                  group={item}
                                                />
                                              );
                                            })}
                                            {/* show and hide complete task ui */}
                                            {totalCompletedSubTask && totalCompletedSubTask?.length > 0 && (
                                              <>
                                                {(!openSubTask?.showCompleted || openSubTask?.id !== task?.id) && (
                                                  <Showhide
                                                    showSubTask={true}
                                                    onClick={() => handleShowCompletedSubTask(task?.id, true)}>
                                                    Show Completed Tasks
                                                  </Showhide>
                                                )}
                                                {openSubTask?.showCompleted && openSubTask?.id === task?.id && (
                                                  <Showhide
                                                    showSubTask={true}
                                                    onClick={() => handleShowCompletedSubTask(task?.id, false)}>
                                                    Hide Completed Tasks
                                                  </Showhide>
                                                )}
                                              </>
                                            )}
                                          </SubTaskList>
                                        </ShowSubTask>
                                      )}
                                  </>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided?.placeholder}
                        </Data>
                      )}
                    </ShowTask>
                  )}
                </Itemblock>
              );
            }}
          </Droppable>
        );
      } else {
        return <React.Fragment key={item?.id}></React.Fragment>;
      }
    });
  }, [
    filteredTasksList,
    groupingStatus,
    taskFilter?.showEmptyGroup,
    taskFilter?.showSubTask,
    isTemplateProject,
    loadData,
    setModalOpen,
    workspace,
    selectedId,
    showCompletedTask,
    currentProject,
    taskGroups,
    isSelected,
    currentTaskGroup,
    currentAssignBox,
    isAssignDropdownOpen,
    openSubTask?.id,
    openSubTask?.showCompleted,
    onClickTaskDetails,
    onClickTaskCheckbox,
    handleHoverTask,
    onUpdateStatus,
    onAssignGroupClick,
    handleSubtaskOpen,
    handleShowCompletedSubTask,
    toggleCompletedTask,
    handleCompletedHoverTask
  ]);

  // render other task
  const renderOtherTask = useMemo(() => {
    return (
      (otherTask?.tasks?.length > 0 || (otherTask?.completedTasks && otherTask?.completedTasks?.length > 0)) && (
        <Itemblock isHide={groupingStatus === SHOW_TASK_GROUPING_TYPE.GROUPING_BY_PRIORITY}>
          <Tasklist
            isGap={groupingStatus === SHOW_TASK_GROUPING_TYPE.NO_GROUPING}
            isHide={groupingStatus === SHOW_TASK_GROUPING_TYPE.NO_GROUPING}>
            <Title>Other Tasks</Title>
          </Tasklist>
          {otherTask?.tasks?.map((task, index: number) => {
            let dueResult: { overDue: boolean; dueTime: string; dueParam?: string } | undefined;
            if (task?.dueDate) {
              dueResult = calculateDueTime(task.dueDate);
            }
            let statusDetails: StatusListInterface | undefined;
            if (currentProject?.statusEnable && currentProject?.statusData?.length > 0) {
              if (!task?.statusId) statusDetails = currentProject?.defaultStatus;
              else statusDetails = currentProject?.statusData?.find((x) => x['_id'] === task?.statusId);
            }
            let currentTaskSubtask = task?.subTasks?.filter((sub) => !sub?.status);
            if (openSubTask?.id === task?.id && openSubTask?.showCompleted) {
              currentTaskSubtask = task?.subTasks;
            }
            const totalCompletedSubTask = task?.subTasks?.filter((sub) => sub?.status);
            return (
              <>
                <TaskItem
                  dueResult={dueResult}
                  taskGroups={taskGroups}
                  currentProject={currentProject}
                  loadData={loadData}
                  onClickCheckbox={(e) => onClickOtherTaskCheckbox(e, task, true)}
                  onClick={() => onClickTaskDetails(task)}
                  onUpdateStatus={(status: StatusListInterface, previousStatus: StatusListInterface) =>
                    onUpdateStatus(status, previousStatus, task, false)
                  }
                  statusDetails={statusDetails}
                  task={task}
                  taskContentKey={`task_${task?.id}_task_index_${index}`}
                  isOtherTask
                  isOverdue={dueResult?.overDue}
                  isTemplateProject={isTemplateProject}
                  setOpen={(value: boolean) => setOpen(value)}
                  setDeleteModelopen={(value: boolean) => setDeleteModelOpen(value)}
                  setSelectedItem={(value: TaskDetailsInterface) => setSelectedItem(value)}
                  setCurrentAssignBox={(value: string) => setCurrentAssignBox(value)}
                  currentAssignBox={currentAssignBox}
                  isAssignDropdownOpen={isAssignDropdownOpen}
                  setIsAssignDropdownOpen={(value: boolean) => setIsAssignDropdownOpen(value)}
                  handleSubtaskOpen={(value: string) => handleSubtaskOpen(value)}
                  isActiveSubTask={openSubTask?.id === task?.id}
                />
                {/* show subtask ui */}
                {task?.subTasks &&
                  task?.subTasks?.length > 0 &&
                  (taskFilter?.showSubTask || openSubTask?.id === task?.id) && (
                    <ShowSubTask>
                      <LineDiv isApplyMargin={totalCompletedSubTask && totalCompletedSubTask?.length > 0} />
                      <SubTaskList>
                        {currentTaskSubtask?.map((sub) => {
                          const item: TaskDetailsInterface = sub;
                          let dueResult: { overDue: boolean; dueTime: string; dueParam?: string } | undefined;
                          if (sub?.dueDate) {
                            dueResult = calculateDueTime(sub.dueDate);
                          }
                          let statusDetails: StatusListInterface | undefined;
                          if (currentProject?.statusEnable && currentProject?.statusData?.length > 0) {
                            if (!sub?.statusId) statusDetails = currentProject?.defaultStatus;
                            else statusDetails = currentProject?.statusData?.find((x) => x['_id'] === sub?.statusId);
                          }
                          return (
                            <TaskItem
                              key={item?.id}
                              isSubTask={true}
                              dueResult={dueResult}
                              taskGroups={taskGroups}
                              currentProject={currentProject}
                              loadData={loadData}
                              onClickCheckbox={(e) => onClickOtherTaskCheckbox(e, item, true)}
                              onClick={() => onClickTaskDetails(item)}
                              onUpdateStatus={(status: StatusListInterface, previousStatus: StatusListInterface) =>
                                onUpdateStatus(status, previousStatus, item, true)
                              }
                              statusDetails={statusDetails}
                              task={item}
                              taskContentKey={`task_${item?.id}_task_index_${index}`}
                              isOtherTask
                              isOverdue={dueResult?.overDue}
                              isTemplateProject={isTemplateProject}
                              setOpen={(value: boolean) => setOpen(value)}
                              setDeleteModelopen={(value: boolean) => setDeleteModelOpen(value)}
                              setSelectedItem={(value: TaskDetailsInterface) => setSelectedItem(value)}
                              setCurrentAssignBox={(value: string) => setCurrentAssignBox(value)}
                              currentAssignBox={currentAssignBox}
                              isAssignDropdownOpen={isAssignDropdownOpen}
                              setIsAssignDropdownOpen={(value: boolean) => setIsAssignDropdownOpen(value)}
                              group={item}
                            />
                          );
                        })}
                        {totalCompletedSubTask && totalCompletedSubTask?.length > 0 && (
                          <>
                            {(!openSubTask?.showCompleted || openSubTask?.id !== task?.id) && (
                              <Showhide showSubTask={true} onClick={() => handleShowCompletedSubTask(task?.id, true)}>
                                Show Completed Tasks
                              </Showhide>
                            )}
                            {openSubTask?.showCompleted && openSubTask?.id === task?.id && (
                              <Showhide showSubTask={true} onClick={() => handleShowCompletedSubTask(task?.id, false)}>
                                Hide Completed Tasks
                              </Showhide>
                            )}
                          </>
                        )}
                      </SubTaskList>
                    </ShowSubTask>
                  )}
              </>
            );
          })}
          {otherTask?.completedTasks && otherTask?.completedTasks?.length > 0 && (
            <ShowTask>
              {selectedId === 'otherTasks' && showCompletedTask ? (
                <Showhide onClick={() => toggleCompletedTask(false, '')}>Hide Completed Tasks</Showhide>
              ) : (
                <Showhide onClick={() => toggleCompletedTask(true, 'otherTasks')}>Show Completed Tasks</Showhide>
              )}

              {selectedId === 'otherTasks' && showCompletedTask && otherTask?.completedTasks?.length > 0 && (
                <>
                  {otherTask?.completedTasks?.map((task, index: number) => {
                    let dueResult: { overDue: boolean; dueTime: string; dueParam?: string } | undefined;
                    if (task?.dueDate) {
                      dueResult = calculateDueTime(task.dueDate);
                    }
                    let statusDetails: StatusListInterface | undefined;
                    if (currentProject?.statusEnable && currentProject?.statusData?.length > 0) {
                      if (!task?.statusId) statusDetails = currentProject?.defaultStatus;
                      else statusDetails = currentProject?.statusData?.find((x) => x['_id'] === task?.statusId);
                    }
                    let currentTaskSubtask = task?.subTasks?.filter((sub) => !sub?.status);
                    if (openSubTask?.id === task?.id && openSubTask?.showCompleted) {
                      currentTaskSubtask = task?.subTasks;
                    }
                    const totalCompletedSubTask = task?.subTasks?.filter((sub) => sub?.status);
                    return (
                      <>
                        <TaskItem
                          key={task?.id}
                          dueResult={dueResult}
                          taskGroups={taskGroups}
                          currentProject={currentProject}
                          loadData={loadData}
                          onClickCheckbox={(e) => onClickOtherTaskCheckbox(e, task, false)}
                          onClick={() => onClickTaskDetails(task)}
                          onUpdateStatus={(status: StatusListInterface, previousStatus: StatusListInterface) =>
                            onUpdateStatus(status, previousStatus, task, false)
                          }
                          statusDetails={statusDetails}
                          task={task}
                          taskContentKey={`task_${task?.id}_task_index_${index}`}
                          isOtherTask
                          isTemplateProject={isTemplateProject}
                          setOpen={(value: boolean) => setOpen(value)}
                          setDeleteModelopen={(value: boolean) => setDeleteModelOpen(value)}
                          setSelectedItem={(value: TaskDetailsInterface) => setSelectedItem(value)}
                          setCurrentAssignBox={(value: string) => setCurrentAssignBox(value)}
                          currentAssignBox={currentAssignBox}
                          isAssignDropdownOpen={isAssignDropdownOpen}
                          setIsAssignDropdownOpen={(value: boolean) => setIsAssignDropdownOpen(value)}
                          handleSubtaskOpen={(value: string) => handleSubtaskOpen(value)}
                          isActiveSubTask={openSubTask?.id === task?.id}
                        />
                        {/* show subtask ui */}
                        {task?.subTasks &&
                          task?.subTasks?.length > 0 &&
                          (taskFilter?.showSubTask || openSubTask?.id === task?.id) && (
                            <ShowSubTask>
                              <LineDiv isApplyMargin={totalCompletedSubTask && totalCompletedSubTask?.length > 0} />
                              <SubTaskList>
                                {currentTaskSubtask?.map((sub) => {
                                  const item: TaskDetailsInterface = sub;
                                  let dueResult: { overDue: boolean; dueTime: string; dueParam?: string } | undefined;
                                  if (sub?.dueDate) {
                                    dueResult = calculateDueTime(sub.dueDate);
                                  }
                                  let statusDetails: StatusListInterface | undefined;
                                  if (currentProject?.statusEnable && currentProject?.statusData?.length > 0) {
                                    if (!sub?.statusId) statusDetails = currentProject?.defaultStatus;
                                    else
                                      statusDetails = currentProject?.statusData?.find(
                                        (x) => x['_id'] === sub?.statusId
                                      );
                                  }
                                  return (
                                    <TaskItem
                                      key={item?.id}
                                      isSubTask={true}
                                      dueResult={dueResult}
                                      taskGroups={taskGroups}
                                      currentProject={currentProject}
                                      loadData={loadData}
                                      onClickCheckbox={(e) => onClickOtherTaskCheckbox(e, item, false)}
                                      onClick={() => onClickTaskDetails(item)}
                                      onUpdateStatus={(
                                        status: StatusListInterface,
                                        previousStatus: StatusListInterface
                                      ) => onUpdateStatus(status, previousStatus, item, true)}
                                      statusDetails={statusDetails}
                                      task={item}
                                      taskContentKey={`task_${item?.id}_task_index_${index}`}
                                      isOtherTask
                                      isTemplateProject={isTemplateProject}
                                      setOpen={(value: boolean) => setOpen(value)}
                                      setDeleteModelopen={(value: boolean) => setDeleteModelOpen(value)}
                                      setSelectedItem={(value: TaskDetailsInterface) => setSelectedItem(value)}
                                      setCurrentAssignBox={(value: string) => setCurrentAssignBox(value)}
                                      currentAssignBox={currentAssignBox}
                                      isAssignDropdownOpen={isAssignDropdownOpen}
                                      setIsAssignDropdownOpen={(value: boolean) => setIsAssignDropdownOpen(value)}
                                    />
                                  );
                                })}
                                {/* show and hide complete task ui */}
                                {totalCompletedSubTask && totalCompletedSubTask?.length > 0 && (
                                  <>
                                    {(!openSubTask?.showCompleted || openSubTask?.id !== task?.id) && (
                                      <Showhide
                                        showSubTask={true}
                                        onClick={() => handleShowCompletedSubTask(task?.id, true)}>
                                        Show Completed Tasks
                                      </Showhide>
                                    )}
                                    {openSubTask?.showCompleted && openSubTask?.id === task?.id && (
                                      <Showhide
                                        showSubTask={true}
                                        onClick={() => handleShowCompletedSubTask(task?.id, false)}>
                                        Hide Completed Tasks
                                      </Showhide>
                                    )}
                                  </>
                                )}
                              </SubTaskList>
                            </ShowSubTask>
                          )}
                      </>
                    );
                  })}
                </>
              )}
            </ShowTask>
          )}
        </Itemblock>
      )
    );
  }, [
    otherTask?.tasks,
    otherTask?.completedTasks,
    groupingStatus,
    selectedId,
    showCompletedTask,
    currentProject,
    taskGroups,
    loadData,
    isTemplateProject,
    currentAssignBox,
    isAssignDropdownOpen,
    taskFilter?.showSubTask,
    openSubTask?.id,
    openSubTask?.showCompleted,
    onClickOtherTaskCheckbox,
    onClickTaskDetails,
    onUpdateStatus,
    handleSubtaskOpen,
    handleShowCompletedSubTask,
    toggleCompletedTask
  ]);

  // render task loading content
  const renderTaskLoadingContent = useMemo(() => {
    return loading && filteredTasksList?.length === 0 && otherTask?.tasks?.length === 0 && <TaskLoading />;
  }, [filteredTasksList?.length, loading, otherTask?.tasks?.length]);

  // render task list
  const renderTaskList = useMemo(() => {
    return (
      (filteredTasksList?.length > 0 ||
        (otherTask?.tasks && otherTask?.tasks?.length > 0) ||
        (otherTask?.completedTasks && otherTask?.completedTasks?.length > 0) ||
        taskFilter?.showEmptyGroup) && (
        <>
          {renderFilteredTaskList}
          {renderOtherTask}
          {!projectLoading &&
            (filteredTasksList?.length > 0 || otherTask?.tasks?.length > 0) &&
            taskDifference > 0 &&
            completedTask &&
            completedTask > 0 && (
              <OptionDiv>
                <Filter>
                  <TaskNumber>{taskDifference} Tasks</TaskNumber>
                  <Text2>hidden by filter options</Text2>
                </Filter>
                <Show onClick={handleShowOptions}>
                  <p>Show options</p>
                </Show>
              </OptionDiv>
            )}
        </>
      )
    );
  }, [
    completedTask,
    filteredTasksList?.length,
    handleShowOptions,
    otherTask?.completedTasks,
    otherTask?.tasks,
    projectLoading,
    renderFilteredTaskList,
    renderOtherTask,
    taskDifference,
    taskFilter?.showEmptyGroup
  ]);

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Content isHide={groupingStatus === SHOW_TASK_GROUPING_TYPE.NO_GROUPING} isPadding={isPadding}>
          {renderTaskLoadingContent}
          {renderTaskList}
          <ModalCustom open={open} onClose={handleCancel} isCreateTask={true}>
            <CreateTaskModal
              loadData={loadData}
              isTemplateProject={isTemplateProject}
              onCancel={handleCancel}
              isFromShortcut={isOpenFromShortcut}
            />
          </ModalCustom>
          <ModalCustom open={deleteModelOpen} onClose={closeDeleteModel} width={334}>
            <Deletemodal
              onClose={closeDeleteModel}
              onOk={() => selectedItem && deleteTask(selectedItem)}
              loading={deleteLoading}
              modaltitle={`${
                (selectedItem?.subTasks?.length ?? 0) > 0
                  ? `Delete this task and ${selectedItem?.subTasks?.length} subtasks?`
                  : 'Delete Task?'
              }`}
              description={`${
                (selectedItem?.subTasks?.length ?? 0) > 0
                  ? 'This action will remove this task and all its connected subtasks.'
                  : 'Are you sure you want to delete this task?'
              }`}
            />
          </ModalCustom>
        </Content>
      </DragDropContext>
      {selectedTasks?.length > 0 && (
        <Bottom>
          <Selectbox>
            <Selected>
              <span>{selectedTasks?.length} Selected</span>
              <Closeicon onClick={onRemoveSelectedTasks}>
                <SVGIcon
                  name='selected-close-icon'
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                  className='close-icon'
                />
              </Closeicon>
            </Selected>
            <Line />
            {currentProject?.isGroupEnabled && (
              <Statusdropdown onUpdateGroup={(group) => onBulkUpdateGroup(group, selectedTasks)} />
            )}
            {currentProject?.statusEnable && currentProject?.statusData?.length > 0 && (
              <TaskStatusDropdown
                defaultStatus={currentProject?.defaultStatus}
                statusList={currentProject?.statusData}
                isCreateTask={true}
                onUpdateStatus={(status: StatusListInterface, previousStatus: StatusListInterface) =>
                  onBulkUpdateStatus(status, previousStatus, selectedTasks)
                }
              />
            )}
            <RemoveIcon onClick={() => openSelectedDeleteModel()}>
              <SVGIcon
                name='invoice-details-delete-icon'
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='var(--text-secondary)'
              />
            </RemoveIcon>
          </Selectbox>
        </Bottom>
      )}
      <ModalCustom open={selectedDeleteModelOpen} onClose={closeSelectedDeleteModel} width={334}>
        <Deletemodal
          onClose={closeSelectedDeleteModel}
          modaltitle='Delete Tasks?'
          description='Are you sure you want to delete this selected tasks?'
          onOk={() => onDeleteSelectedTasks(selectedTasks)}
          loading={deleteLoading}
        />
      </ModalCustom>
      <ModalCustom open={isConfirmationModelOpen} onClose={closeConfirmationModal} width={334}>
        <ConfirmationModal
          loading={confirmationLoading}
          cancelLoading={cancelLoading}
          onOk={isCheckBox ? onClickConfirm : onClickConfirmStatus}
          onNo={onClickNo}
          onClose={closeConfirmationModal}
          modaltitle='Uncompleted Subtasks'
          description='Do you want to complete all these subtasks?'
        />
      </ModalCustom>
    </>
  );
};

export default TasksPage;
