import styled, { css } from 'styled-components';
import { Typography } from '../../../global/theme';

interface Props {
  isActive: boolean;
}

const MainDiv = styled.div`
  padding: 12px;
  @media (max-width: 449px) {
    padding: 10px 12px 3px 12px;
  }
`;

const Buttons = styled.div`
  background: var(--background-secondary);
  border-radius: 90px;
  display: flex;
  padding: 4px;
  margin: 0 4px;
  @media (max-width: 449px) {
    justify-content: space-between;
    margin: 0 8px 4px 8px;
  }
`;

const Taskbutton = styled.button<Props>`
  max-width: 62px;
  min-width: 62px;
  width: 100%;
  background-color: transparent;
  ${Typography.body_sm};
  color: var(--text-secondary);
  padding: 4px 0;
  border: 0.5px solid transparent;
  border-radius: 16px;
  cursor: pointer;
  :hover {
    border: 0.5px solid var(--border-primary);
    box-shadow: var(--shadow-card);
    border-radius: 16px;
  }
  ${(props: Props) =>
    props.isActive &&
    css`
      border: 0.5px solid var(--border-primary);
      color: var(--text-primary);
      background: var(--background-tertiary);
      box-shadow: var(--shadow-card);
    `}
  @media (max-width: 449px) {
    max-width: 112px;
    min-width: 112px;
  }
`;

const Profile = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: max-content;
  padding: 4px 12px 4px 4px;
  :hover {
    background-color: var(--neutral-secondary);
    border-radius: 50px;
  }
  img {
    width: 20px;
    height: 20px;
  }
  .profile-image {
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }
  .dropdown-icon {
    transform: rotate(180deg);
  }
  .stroke-color {
    stroke: var(--text-primary);
  }
  @media (max-width: 449px) {
    margin: 0;
    padding: 9px 8px;
    gap: 12px;
    .stroke-color {
      display: none;
    }
    .profile-image {
      width: 26px;
      height: 26px;
    }
  }
`;

const UserName = styled.div`
  ${Typography.body_md_semibold};
  color: var(--text-primary);
  p {
    margin: 0;
    ${Typography.body_sm};
    color: var(--text-primary);
  }
  @media (max-width: 449px) {
    margin-right: 8px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export { Buttons, Taskbutton, Profile, UserName, MainDiv };
