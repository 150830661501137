import React from 'react';
import { Bottom, Box, Content, First, Left, Main, Right, Title, Top } from './styles';

export default function Subscriptionloading() {
  return (
    <div>
      <Main>
        <Box>
          <First>
            <Left>
              <Top className='loading-animation'></Top>
              <Bottom className='loading-animation'></Bottom>
            </Left>
            <Right className='loading-animation'></Right>
          </First>
        </Box>
        <Content>
          <Title>Past Subscription</Title>
          <Box>
            <First>
              <Left>
                <Top className='loading-animation'></Top>
                <Bottom className='loading-animation'></Bottom>
              </Left>
              <Right className='loading-animation'></Right>
            </First>
            <First>
              <Left>
                <Top className='loading-animation'></Top>
                <Bottom className='loading-animation'></Bottom>
              </Left>
              <Right className='loading-animation'></Right>
            </First>
            <First>
              <Left>
                <Top className='loading-animation'></Top>
                <Bottom className='loading-animation'></Bottom>
              </Left>
              <Right className='loading-animation'></Right>
            </First>
          </Box>
        </Content>
      </Main>
    </div>
  );
}
