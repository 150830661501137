import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '../../../component/avatar/Avatar';
import { getProjectArchiveData, updateProjectData } from '../../../services/projectServices';
import AppLayout from '../../../component/appLayout';
import {
  Assigngroup1,
  Container,
  Content,
  Group,
  Headerblock,
  HeaderTitle,
  Heading,
  Icon,
  Projectitem,
  ProjectName,
  Text,
  Title,
  Rightcontent,
  Resnavbar
} from './styles';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import ProjectallLoading from '../../../component/loading/projectallloading';
import ModalCustom from '../../../component/models/modal';
import Deletemodal from '../../../component/models/deleteModel';
import { trackAnalyticActivity } from '../../../services/analyticsService';
import { setErrorMessage, setSuccessMessage } from '../../../actions/messageActions';
import { captureException } from '../../../services/logService';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import Responsivnavbar from '../../../component/navbar/responsivenavbar';
import { MENU_OPTIONS } from '../../../global/constants';
import CustomDropdown from '../../../component/dropdowns/customDropdown';
import { ProjectDetailInterface } from '../../../interfaces/ProjectInterface';
import { PROJECT_ANALYTICS } from '../../../global/analyticsConstants';

const ArchiveProject: React.FC = () => {
  // Refs
  const MenuDropdownRef = useRef<React.RefObject<HTMLDivElement>[]>([]);
  // States
  const [loading, setLoading] = useState(false);
  const [OpenRestoreModel, setOpenRestoreModel] = useState(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [projectId, setProjectId] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [openDropdownId, setOpenDropdownId] = useState<string | null>(null);
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { project: projectArchiveSelector, workspace: workspaceSelector } = stateSelector || {};
  const { workspace } = workspaceSelector;
  const { id: workspace_id } = workspace;
  const { projectArchiveData } = projectArchiveSelector;
  // Other variables
  const dispatch = useDispatch();
  const menuItems = [
    {
      key: MENU_OPTIONS.RESTORE,
      label: 'Restore'
    },
    {
      key: MENU_OPTIONS.DELETE,
      label: 'Delete',
      className: 'red-text'
    }
  ];

  const loadData = useCallback(async () => {
    try {
      if (workspace_id) {
        setLoading(true);
        await dispatch(getProjectArchiveData(workspace_id));
      }
    } catch (e) {
      captureException(e);
      console.log('error', e);
    } finally {
      setLoading(false);
    }
  }, [workspace_id, dispatch]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onClickRestore = useCallback((id: string) => {
    setOpenRestoreModel(true);
    setProjectId(id);
  }, []);

  const closeRestoreModel = useCallback(() => {
    setProjectId('');
    setOpenRestoreModel(false);
  }, []);

  const onOkRestore = useCallback(async () => {
    try {
      setSubmitLoading(true);
      if (!workspace?.isAdmin) return dispatch(setErrorMessage(`You can't Restore project.`));
      const updatePayload = {
        isArchived: false
      };
      const result = await dispatch(updateProjectData(projectId, updatePayload));
      if (result) {
        trackAnalyticActivity(PROJECT_ANALYTICS.RESTORED);
        closeRestoreModel();
        await dispatch(getProjectArchiveData(workspace_id));
        dispatch(setSuccessMessage('Project Restore Sucessfully!'));
      }
    } catch (e) {
      captureException(e);
      console.log('ERROR', e);
    } finally {
      setSubmitLoading(false);
    }
  }, [closeRestoreModel, dispatch, projectId, workspace?.isAdmin, workspace_id]);

  const onClickDelete = useCallback((id: string) => {
    setOpenDeleteModel(true);
    setProjectId(id);
  }, []);

  const closeDeleteModel = useCallback(() => {
    setProjectId('');
    setOpenDeleteModel(false);
  }, []);

  const onDeleteProject = useCallback(async () => {
    try {
      setSubmitLoading(true);
      if (!workspace?.isAdmin) return dispatch(setErrorMessage(`You can't delete project.`));
      const payload = {
        IsDeleted: true
      };
      const result = await dispatch(updateProjectData(projectId, payload));
      if (result) {
        trackAnalyticActivity(PROJECT_ANALYTICS.DELETED);
        closeDeleteModel();
        await dispatch(getProjectArchiveData(workspace_id));
        dispatch(setSuccessMessage('Project Deleted Sucessfully!'));
      }
    } catch (e) {
      captureException(e);
      console.log('ERROR', e);
    } finally {
      setSubmitLoading(false);
    }
  }, [closeDeleteModel, dispatch, projectId, workspace?.isAdmin, workspace_id]);

  const openArchivedDropdown = useCallback(
    (e: React.SyntheticEvent, id: string) => {
      e.stopPropagation();
      if (openDropdownId === id) {
        setIsDropdownOpen(false);
        setOpenDropdownId(null);
      } else {
        setIsDropdownOpen(true);
        setOpenDropdownId(id);
      }
    },
    [openDropdownId]
  );

  const handleMenuClick = useCallback(
    (key: number | string, item: ProjectDetailInterface) => {
      key = Number(key);
      if (key === MENU_OPTIONS.RESTORE) {
        onClickRestore(item?.id);
      } else if (key === MENU_OPTIONS.DELETE) {
        onClickDelete(item?.id);
      }
      setIsDropdownOpen(false);
    },
    [onClickRestore, onClickDelete]
  );

  return (
    <>
      <AppLayout>
        <Group>
          <Content>
            <Headerblock>
              <Resnavbar>
                <Responsivnavbar />
              </Resnavbar>
              <Heading>
                <Icon>
                  <SVGIcon
                    name='projects-icon'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    className='stroke-color'
                  />
                </Icon>
                <HeaderTitle>Archive Projects</HeaderTitle>
              </Heading>
            </Headerblock>
          </Content>
          {loading && <ProjectallLoading />}
          {!loading && projectArchiveData?.length === 0 && <>No Archieve Projects</>}
          {!loading && (
            <>
              <Container>
                {projectArchiveData?.length > 0 &&
                  projectArchiveData?.map((item, index) => {
                    if (!MenuDropdownRef?.current[index]) {
                      MenuDropdownRef.current[index] = React.createRef<HTMLDivElement>();
                    }

                    return (
                      <Projectitem key={item?.id}>
                        <ProjectName>
                          <Title>{item?.name}</Title>
                          <Text>by {item?.createdBy?.name}</Text>
                        </ProjectName>

                        <Rightcontent>
                          <Assigngroup1>
                            <ul>
                              {item?.users?.map((img, index) => {
                                if (index > 3) return <React.Fragment key={img?.id}></React.Fragment>;
                                return (
                                  <li key={img?.id}>
                                    <Avatar
                                      imgSrc={img?.profile_photo || ''}
                                      name={img?.name ? img.name : 'U N'}
                                      size={24}
                                    />
                                  </li>
                                );
                              })}
                              {item?.users?.length > 4 && <li className='plus-icon'>+{item?.users?.length - 4}</li>}
                            </ul>
                          </Assigngroup1>
                          <Icon
                            ref={MenuDropdownRef?.current[index]}
                            onClick={(e) => openArchivedDropdown(e, item?.id)}>
                            <SVGIcon
                              className='doticon'
                              name='three-dots-horizontal-icon'
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                            />
                            {isDropdownOpen && openDropdownId === item?.id && (
                              <CustomDropdown
                                handleClick={(key) => handleMenuClick(key, item)}
                                menuItems={menuItems}
                                buttonref={MenuDropdownRef?.current[index]}
                                isOpen={isDropdownOpen}
                                setIsOpen={setIsDropdownOpen}
                                setOpenDropdownId={setOpenDropdownId}
                                width={70}
                              />
                            )}
                          </Icon>
                        </Rightcontent>
                      </Projectitem>
                    );
                  })}
              </Container>
            </>
          )}
        </Group>
      </AppLayout>
      <ModalCustom open={OpenRestoreModel} onClose={closeRestoreModel} width={334}>
        <Deletemodal
          onClose={closeRestoreModel}
          onOk={onOkRestore}
          isYes={true}
          loading={submitLoading}
          modaltitle='Restore Project?'
          description='Are you sure you want to restore this project?'
          isPrimaryButton={true}
        />
      </ModalCustom>
      <ModalCustom open={openDeleteModel} onClose={closeDeleteModel} width={334}>
        <Deletemodal
          onClose={closeDeleteModel}
          onOk={onDeleteProject}
          isYes={true}
          loading={submitLoading}
          modaltitle='Delete Project?'
          description='Are you sure you want to delete this project?'
        />
      </ModalCustom>
    </>
  );
};

export default ArchiveProject;
