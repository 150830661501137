import React, { useEffect, useCallback, useState, useRef } from 'react';
import AppLayout from '../../component/appLayout';
import {
  Group,
  Icon,
  Pendingbutton,
  PersonalHeader,
  Personaltext,
  PersonInfo,
  Projectcontent,
  Teammate,
  TeammateName,
  Pending,
  AdminText,
  Rightdiv,
  Header
} from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { getWorkspaceDetails } from '../../services/workspaceService';
import SVGIcon from '../../assets/images/svg/SVGIcon';
import Avatar from '../../component/avatar/Avatar';
import { isEmpty } from '../../helpers/common';
import { changeUserAdmin, deleteInvitedUser } from '../../services/settingServices';
import Deletemodal from '../../component/models/deleteModel';
import Button from '../../component/button';
import ModalCustom from '../../component/models/modal';
import Inviteteamsmodal from '../../component/models/inviteteamsmodal';
import { SETTING_TEAMS_MENU_OPTION, USER_ROLE } from '../../global/constants';
import { captureException } from '../../services/logService';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';
import SuccessfullModel from '../../component/models/succesfullModel';
import { ResponsiveHeader } from './SettingHeaderResponsive';
import { useMobileDevice } from '../../global/useMobile';
import Teamsloading from '../../component/loading/teamsLoading';
import { UsersWorkspaceInterface } from '../../interfaces/WorkspaceInterface';
import { Dropdown, DropdownItem } from '../../component/Dropdown';

const Team: React.FC = () => {
  // Refs
  const adminDropdownRef = useRef<React.RefObject<HTMLDivElement>[]>([]);
  const deleteDropdownRef = useRef<React.RefObject<HTMLDivElement>[]>([]);
  // States
  const [selectedItem, setSelectedItem] = useState();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isSuccesfullModalOpen, setIsSucessfullModalOpen] = useState(false);
  const [clientLoginUrl, setClientLoginUrl] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  //use selector state variables
  const workspaceSelector = useSelector((state: RootReducerInterface) => state.workspace);
  const { workspace } = workspaceSelector;
  const { id: workspace_id } = workspace;
  // Other variables
  const dispatch = useDispatch();
  const isMobile = useMobileDevice();

  const handleOnClick = () => {
    setIsModalOpen(true);
  };

  const handleOnclickOpenModel = () => {
    setIsSucessfullModalOpen(true);
  };

  const handleOnclickCloseModel = () => {
    setIsSucessfullModalOpen(false);
  };

  const handleCloseModal = (clientUrl?: string) => {
    setIsModalOpen(false);
    if (clientUrl) {
      setClientLoginUrl(clientUrl);
      handleOnclickOpenModel();
    }
  };

  const loadData = useCallback(async () => {
    if (!isEmpty(workspace_id)) await dispatch(getWorkspaceDetails(workspace_id));
  }, [workspace_id, dispatch]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const [deleteModelopen, Open] = useState(false);
  const Opendeletemodel = useCallback(
    (item: any) => {
      Open(true);
      setSelectedItem(item);
    },
    [setSelectedItem]
  );

  const close = useCallback(() => {
    Open(false);
    setSelectedItem(undefined);
  }, [setSelectedItem]);

  const onClickDelete = useCallback(async () => {
    try {
      setDeleteLoading(true);
      const result = await dispatch(deleteInvitedUser(workspace_id, selectedItem));
      if (result) {
        close();
        loadData();
      }
    } catch (error) {
      captureException(error);
      console.log('error', error);
      setDeleteLoading(false);
    } finally {
      setDeleteLoading(false);
    }
  }, [dispatch, workspace_id, selectedItem, close, loadData]);

  const onClickAdmin = useCallback(
    async (userId: string) => {
      const result = await dispatch(changeUserAdmin(workspace_id, userId));
      if (result) {
        loadData();
      }
    },
    [dispatch, workspace_id, loadData]
  );

  const getMenuItems = (user: { isAdmin: boolean }) => [
    { key: SETTING_TEAMS_MENU_OPTION.ADMIN, label: user.isAdmin ? 'Remove Admin' : 'Make Admin' },
    { key: SETTING_TEAMS_MENU_OPTION.DELETE, label: 'Delete' }
  ];
  const handleClick = useCallback(
    (key: number | string, user: UsersWorkspaceInterface) => {
      key = Number(key);
      if (key === SETTING_TEAMS_MENU_OPTION.ADMIN) {
        onClickAdmin(user?.id);
      } else if (key === SETTING_TEAMS_MENU_OPTION.DELETE) {
        Opendeletemodel(user);
      }
    },
    [Opendeletemodel, onClickAdmin]
  );

  return (
    <>
      <AppLayout>
        <Group>
          <PersonalHeader isTeamcontainer={true}>
            <Header>
              {isMobile && <ResponsiveHeader />}
              <Personaltext>Teams</Personaltext>
            </Header>
            <Button title='Invite' type='button' onClick={handleOnClick} modelbtn />
          </PersonalHeader>
          {isEmpty(workspace) && <Teamsloading />}

          {!isEmpty(workspace) && (
            <Projectcontent isTeamcontainer={true}>
              {workspace?.users?.map((user, index) => {
                if (!adminDropdownRef?.current[index]) {
                  adminDropdownRef.current[index] = React.createRef<HTMLDivElement>();
                }
                if (user?.role !== USER_ROLE.CLIENT)
                  return (
                    <Teammate key={user?.id}>
                      <PersonInfo>
                        <Avatar imgSrc={user?.profile_photo} name={user?.name} size={32} isNotBorder={true} />
                        <TeammateName>
                          <h1>{user?.name}</h1>
                          <p>{user?.email}</p>
                        </TeammateName>
                      </PersonInfo>
                      <Rightdiv>
                        {user?.isOwner ? (
                          <AdminText>Owner</AdminText>
                        ) : user?.isAdmin ? (
                          <AdminText>Admin</AdminText>
                        ) : (
                          ''
                        )}
                        <Dropdown
                          activeClassName='active'
                          content={
                            <Icon className='custom-dropdown' ref={adminDropdownRef?.current[index]}>
                              <SVGIcon
                                name='more-icon'
                                width='18'
                                height='18'
                                viewBox='0 0 18 18'
                                className='more-icon'
                              />
                            </Icon>
                          }
                          trigger='click'>
                          <>
                            {getMenuItems(user).map((item) => (
                              <DropdownItem
                                key={item.key}
                                label={item.label}
                                onClick={() => handleClick(item.key, user)}
                                tone={item.label === 'Delete' ? 'critical' : 'primary'}
                              />
                            ))}
                          </>
                        </Dropdown>
                      </Rightdiv>
                    </Teammate>
                  );
                else return <React.Fragment key={user?.id}></React.Fragment>;
              })}
              {workspace?.invitedUsers?.map((user, index) => {
                if (!deleteDropdownRef?.current[index]) {
                  deleteDropdownRef.current[index] = React.createRef<HTMLDivElement>();
                }
                if (user?.role !== USER_ROLE.CLIENT)
                  return (
                    <Teammate key={user?.id}>
                      <PersonInfo className='pending'>
                        <Avatar
                          imgSrc={user?.profile_photo}
                          name={!isEmpty(user?.name) ? user?.name : 'U'}
                          size={32}
                          isNotBorder={true}
                        />
                        <TeammateName>
                          <h1>{user?.name}</h1>
                          <p>{user?.email}</p>
                        </TeammateName>
                      </PersonInfo>
                      <Pending>
                        <Pendingbutton>pending</Pendingbutton>
                        <Dropdown
                          activeClassName='active'
                          content={
                            <Icon ref={deleteDropdownRef?.current[index]}>
                              <SVGIcon
                                name='more-icon'
                                width='18'
                                height='18'
                                viewBox='0 0 18 18'
                                className='more-icon'
                              />
                            </Icon>
                          }
                          trigger='click'>
                          <DropdownItem
                            label='Delete'
                            onClick={() => {
                              Opendeletemodel(user);
                            }}
                            tone='critical'
                          />
                        </Dropdown>
                      </Pending>
                    </Teammate>
                  );
                else return <React.Fragment key={user?.id}></React.Fragment>;
              })}
            </Projectcontent>
          )}
        </Group>
      </AppLayout>
      <ModalCustom open={isModalOpen} onClose={handleCloseModal} width={400} isTracker>
        <Inviteteamsmodal onCancel={handleCloseModal} workspace_id={workspace_id} />
      </ModalCustom>
      <ModalCustom open={isSuccesfullModalOpen} onClose={handleOnclickCloseModel} width={420}>
        <SuccessfullModel isFromTeam={true} clientLoginUrl={clientLoginUrl} onCancel={handleOnclickCloseModel} />
      </ModalCustom>
      <ModalCustom open={deleteModelopen} onClose={close} width={334}>
        <Deletemodal
          onClose={close}
          onOk={onClickDelete}
          loading={deleteLoading}
          modaltitle='Delete Team member?'
          description='Are you sure you want to delete this team member?'
        />
      </ModalCustom>
    </>
  );
};

export default Team;
