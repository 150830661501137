import styled, { css } from 'styled-components';
import { Typography } from '../../../global/theme';
interface Props {
  isBorderRadius?: boolean;
  isActive?: boolean;
  variant?: 'no-border' | 'border' | 'hover-border';
  iconTone?: 'fill' | 'stroke';
  size?: 'small' | 'medium';
}
const Title = styled.p<Props>`
  ${Typography.body_md_medium}
  color: var(--text-secondary);
  margin: 0;
  ${(props: Props) =>
    props.isActive &&
    css`
      color: var(--text-primary);
    `}
`;
const ButtonMainDiv = styled.div<Props>`
  padding: 5px 11px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  border: 0.5px solid transparent;
  border-radius: 8px;
  cursor: pointer;
  svg {
    ${(props: Props) =>
      props.iconTone === 'fill' &&
      css`
        fill: var(--text-secondary);
      `}
    ${(props: Props) =>
      props.iconTone === 'stroke' &&
      css`
        stroke: var(--text-secondary);
        fill: none;
      `}
  }
  ${(props: Props) =>
    props.isBorderRadius &&
    css`
      border-radius: 30px;
      padding: 4px;
    `}
  ${(props: Props) =>
    props.variant === 'no-border' &&
    css`
      border: none;
      padding: 0;
    `}
    ${(props: Props) =>
    props.variant === 'border' &&
    css`
      border: 0.5px solid var(--border-primary);
    `}
  :hover {
    background: var(--neutral-secondary);
    border-radius: 5px;
    p {
      color: var(--text-primary);
    }
    svg {
      ${(props: Props) =>
        props.iconTone === 'fill' &&
        css`
          fill: var(--text-primary);
        `}
      ${(props: Props) =>
        props.iconTone === 'stroke' &&
        css`
          stroke: var(--text-primary);
          fill: none;
        `}
    }
    ${(props: Props) =>
      props.isBorderRadius &&
      css`
        border-radius: 30px;
        padding: 4px;
      `}
    ${(props: Props) =>
      props.variant === 'hover-border' &&
      css`
        border: 0.5px solid var(--border-primary);
        background-color: var(--background-primary);
      `}
      ${(props: Props) =>
      props.variant === 'no-border' &&
      css`
        background: unset;
      `}
  }
  ${(props: Props) =>
    props.size === 'small' &&
    css`
      padding: 2px 7px;
      gap: 6px;
      border-radius: 6px;
    `}
`;

export { ButtonMainDiv, Title };
