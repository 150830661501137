import React, { useCallback, useState } from 'react';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { Icon } from './styles';

import Deletemodal from '../../models/deleteModel';
import { ProjectTaskInterface } from '../../../interfaces/ProjectInterface';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updateFilteredTasksList } from '../../../actions/taskActions';
import { setErrorMessage } from '../../../actions/messageActions';
import ModalCustom from '../../models/modal';
import { deleteTaskGroup } from '../../../services/taskServices';
import { captureException } from '../../../services/logService';
import { WorkspaceInterfase } from '../../../interfaces/WorkspaceInterface';
import { SETTING_TEAMS_MENU_OPTION } from '../../../global/constants';
import Dropdown from '../../Dropdown/MainDropdown';
import DropdownItem from '../../Dropdown/DropdownItem';

interface Props {
  groupDetail?: any;
  loadData: () => void;
  setModalOpen?: boolean;
  isTaskpage?: boolean;
  isWidth?: boolean;
  workspaceDetail?: WorkspaceInterfase;
}

const Collapsedopdown: React.FC<Props> = (props) => {
  const { groupDetail, loadData, workspaceDetail } = props;
  //States
  const [deleteModelOpen, setDeleteModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentGroup, setCurrentGroup]: any = useState({
    createdOn: '',
    id: '',
    name: '',
    projectId: '',
    tasks: []
  });
  //Other variable
  const dispatch = useDispatch();
  const params: { id: string } = useParams();

  const Opendeletemodel = () => {
    setDeleteModalOpen(true);
  };

  const close = () => {
    setDeleteModalOpen(false);
  };

  const deleteGroup = useCallback(
    async () => {
      try {
        setLoading(true);
        if (currentGroup?.tasks?.length === 0) {
          const result = await dispatch(deleteTaskGroup(currentGroup?.id));
          setDeleteModalOpen(false);
          if (result) {
            loadData();
          }
        } else {
          dispatch(setErrorMessage("You Can't Delete This Group (Group Should be Empty)"));
          setDeleteModalOpen(false);
        }
      } catch (e) {
        captureException(e);
        console.log('ERROR', e);
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, currentGroup, props, params?.id, loadData]
  );

  const collapse = useCallback(
    async (group: any) => {
      const updatedGroup = { ...group, isCollapse: !group?.isCollapse };
      await dispatch(updateFilteredTasksList(updatedGroup));
    },
    [dispatch]
  );

  const onClickMenu = useCallback((item: ProjectTaskInterface) => {
    setCurrentGroup(item);
  }, []);

  const handleClick = useCallback(
    (key: number | string) => {
      key = Number(key);
      if (key === SETTING_TEAMS_MENU_OPTION.ADMIN) {
        onClickMenu(groupDetail);
        collapse(groupDetail);
      } else if (key === SETTING_TEAMS_MENU_OPTION.DELETE) {
        Opendeletemodel();
      }
    },
    [collapse, groupDetail, onClickMenu]
  );

  return (
    <>
      <div>
        <Dropdown
          content={
            <Icon>
              <SVGIcon
                name='three-dots-horizontal-icon'
                width='20'
                height='20'
                viewBox='0 0 24 24'
                className='moreicon'
              />
            </Icon>
          }
          trigger='click'>
          <DropdownItem
            key={SETTING_TEAMS_MENU_OPTION.ADMIN}
            label={groupDetail?.isCollapse ? 'Expand Group' : 'Collapse Group'}
            onClick={() => handleClick(SETTING_TEAMS_MENU_OPTION.ADMIN)}
          />
          {workspaceDetail?.isOwner ||
            (workspaceDetail?.isAdmin && (
              <DropdownItem
                key={SETTING_TEAMS_MENU_OPTION.DELETE}
                label='Delete'
                onClick={() => handleClick(SETTING_TEAMS_MENU_OPTION.DELETE)}
                tone='critical'
              />
            ))}
        </Dropdown>
      </div>
      <ModalCustom open={deleteModelOpen} onClose={close} width={334}>
        <Deletemodal
          onClose={close}
          onOk={deleteGroup}
          loading={loading}
          modaltitle='Delete Group?'
          description='Are you sure to want to delete this group?'
        />
      </ModalCustom>
    </>
  );
};

export default Collapsedopdown;
