import React, { useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { isEmpty } from '../../helpers/common';
import { updateUserPreference } from '../../helpers/firebaseHelper';
import { appInit } from '../../services/appService';
import { useDispatch } from 'react-redux';
import { ROUTES } from '../../routes/urls';
import AppLoading from '../../component/loading/appLoading';
import UserPreferenceSingleton from '../../helpers/userPreferenceSingleton';

const WorkspaceRedirect: React.FC = () => {
  // Other variables
  const dispatch = useDispatch();
  const history = useHistory();
  const params: { workspace_id: string } = useParams();

  const loadData = useCallback(async () => {
    if (!isEmpty(params?.workspace_id)) {
      try {
        const workspace = UserPreferenceSingleton.getInstance().getWorkspace();
        if (params?.workspace_id === workspace?.id) history.push(ROUTES.INBOX);
        await updateUserPreference({ current_workspace_id: params?.workspace_id });
        await dispatch(appInit());
        history.push(ROUTES.INBOX);
      } catch (e) {}
    }
  }, [dispatch, history, params?.workspace_id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div>
      <AppLoading />
    </div>
  );
};

export default WorkspaceRedirect;
