import { RefObject } from 'react';
import { Submenu, Item } from './styles';
import { REACT_APP_BLOCKSUITE_URL } from '../../../../../global/environment';

export default function ExportSubMenu({ editorIframe }: { editorIframe: RefObject<HTMLIFrameElement> }) {
  // for export in to pdf
  const exportToPdf = () => {
    editorIframe.current?.contentWindow?.postMessage({ type: 'export', payload: 'pdf' }, `${REACT_APP_BLOCKSUITE_URL}`);
  };

  // for export in to HTML
  const exportToHtml = () => {
    editorIframe.current?.contentWindow?.postMessage(
      { type: 'export', payload: 'html' },
      `${REACT_APP_BLOCKSUITE_URL}`
    );
  };

  // for export in to markDown
  const exportToMarkdown = () => {
    editorIframe.current?.contentWindow?.postMessage(
      { type: 'export', payload: 'markdown' },
      `${REACT_APP_BLOCKSUITE_URL}`
    );
  };

  // for export in to PNG
  const exportToPng = () => {
    editorIframe.current?.contentWindow?.postMessage({ type: 'export', payload: 'png' }, `${REACT_APP_BLOCKSUITE_URL}`);
  };

  return (
    <Submenu>
      <Item onClick={exportToPdf}>Export to PDF</Item>
      <Item onClick={exportToHtml}>Export to HTML</Item>
      <Item onClick={exportToPng}>Export to PNG</Item>
      <Item onClick={exportToMarkdown}>Export to Markdown</Item>
    </Submenu>
  );
}
