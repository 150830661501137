import { combineReducers } from 'redux';
import { createReducer } from '../helpers/reduxHelpers';
import * as Actions from '../actions/types';
import { createReducer as createReducerOrig, PayloadAction,current } from '@reduxjs/toolkit';
import { ReducerInterface } from '../interfaces/ReducerInterface';
import { nanoid } from 'nanoid';

const setInvoiceLoaderReducer = createReducer({
  initialState: false,
  actionType: Actions.SET_INVOICE_LOADER
});

const setInvoiceListReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_INVOICE_LIST
});

const filteredInvoiceListReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_FILTERED_INVOICE_LIST
});

const setInvoiceViewDataReducer = createReducer({
  initialState: {},
  actionType: Actions.SET_INVOICE_VIEW_DATA
});

const setInvoiceActivityDetailReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_INVOICE_ACTIVITY_DETAIL
});

const initialInvoiceState = {
  companyId: '',
  currency: '',
  customer: {},
  date: new Date(),
  discount: 0,
  dueDate: new Date(),
  dueDays: '',
  emails: [],
  isSendMeCopy: false,
  items: [
    {
      id: nanoid(),
      index: 100,
      amount: 0,
      desc: '',
      isTax: true,
      isTax2: true,
      itemType: 1,
      projectId: '',
      qty: 1,
      unitPrice: 0
    }
  ],
  notes: '',
  number: '',
  status: 0,
  subTotal: 0,
  tax: [
    {
      name: 'Tax',
      value: 0
    }
  ],
  totalAmount: 0,
  totalDiscount: 0,
  totalTax: 0,
  totalTax2: 0
};

const setInputInvoiceDataReducer = createReducerOrig(initialInvoiceState, (builder) => {
  builder
    .addCase(Actions.SET_INVOICE_DETAIL, (state = initialInvoiceState, action: PayloadAction<any, never>) => {
      return { ...(action.payload || {}) };
    })
    .addCase(Actions.UPDATE_INVOICE_DETAIL, (state, action: PayloadAction<ReducerInterface, never>) => {
      const invoice = JSON.parse(JSON.stringify(current(state)));
      invoice[action.payload.propsName] = action.payload.value;
      return invoice;
    })
    .addCase(Actions.CLEAR_INVOICE_DETAIL, () => {
      const initialData = JSON.parse(JSON.stringify(initialInvoiceState));
      return initialData;
    });
});

const setInvoiceListFilteredWiseReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_INVOICE_LIST_FILTERED_WISE
});

const setCustomerInvoiceData = createReducer({
  initialState: [],
  actionType: Actions.SET_CUSTOMER_INVOICE_DATA
});

const setCustomerInvoiceTimeframes = createReducer({
  initialState: [],
  actionType: Actions.SET_CUTOMER_INVOICE_TIMEFRAMES
});

const initialBillableState = {
  customerId: '',
  type: '1',
  billableHoursType: 1,
  billableHoursTimeframe: '',
  displayHoursType: 1,
  displayHoursOption: { project: true, task: true, people: true, date: true, notes: false },
  projects: []
};

const setInvoiceBillableHoursDetailReducer = createReducerOrig(initialBillableState, (builder) => {
  builder
    .addCase(
      Actions.SET_INVOICE_BILLABLE_HOURS_DETAIL,
      (state = initialBillableState, action: PayloadAction<any, never>) => {
        return { ...(action.payload || {}) };
      }
    )
    .addCase(Actions.UPDATE_INVOICE_BILLABLE_HOURS_DETAIL, (state, action: PayloadAction<ReducerInterface, never>) => {
      const invoice = JSON.parse(JSON.stringify(current(state)));
      invoice[action.payload.propsName] = action.payload.value;
      return invoice;
    })
    .addCase(
      Actions.UPDATE_BILLABLE_HOURS_TIME_ENTRY_DETAIL,
      (state, action: PayloadAction<ReducerInterface, never>) => {
        const invoice = JSON.parse(JSON.stringify(current(state)));
        const timeEntry = invoice?.displayHoursOption;
        timeEntry[action.payload.propsName] = action.payload.value;
        invoice['displayHoursOption'] = timeEntry;
        return invoice;
      }
    )
    .addCase(Actions.CLEAR_INVOICE_BILLABLE_HOURS_DETAIL, () => {
      const initialData = JSON.parse(JSON.stringify(initialBillableState));
      return initialData;
    });
});

const invoiceReducer = combineReducers({
  loading: setInvoiceLoaderReducer,
  invoices: setInvoiceListReducer,
  filteredInvoices: filteredInvoiceListReducer,
  invoiceViewData: setInvoiceViewDataReducer,
  invoiceActivityDetail: setInvoiceActivityDetailReducer,
  invoiceListFilteredWise: setInvoiceListFilteredWiseReducer,
  customerInvoiceData: setCustomerInvoiceData,
  customerInvoiceTimeframes: setCustomerInvoiceTimeframes,
  inputInvoiceDetail: setInputInvoiceDataReducer,
  customerBillablrHoursDetail: setInvoiceBillableHoursDetailReducer
});

export default invoiceReducer;
