/* eslint-disable max-len */
import styled, { css } from 'styled-components';
import { Typography } from '../../../../../global/theme';

interface Props {
  isShowHover?: boolean;
  isActive?: boolean;
  isCommentModelContent?: boolean;
  isHover?: boolean;
  color?: string;
  isOverdue?: boolean;
  isApplyBottomSpacing?: boolean;
  hasSubtasks?: boolean;
  isComment?: boolean;
  isAssignModalOverlay?: boolean;
  completePercentage?: number;
  contactusModal?: boolean;
  isTaskDetailPreview?: boolean;
  isTimeOver?: boolean;
  progress?: number;
  isOverflowHidden?: boolean;
  isDisabled?: boolean;
}
const Group = styled.div<Props>`
  display: flex;
  height: 100%;
  .reaponsiveheader {
    display: none;
  }
  @media (max-width: 449px) {
    flex-direction: column;

    .webheader {
      display: none;
    }
    .reaponsiveheader {
      display: flex;
      width: calc(100vw - 32px);
      margin: 0 -16px;
      padding-left: 16px;
      padding-right: 16px;
      ${(props) =>
        props.isTaskDetailPreview &&
        css`
          margin: 0;
        `}
    }
  }
`;

const LeftBar = styled.div<Props>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-right: 0.5px solid var(--border-primary);
  margin: 0;
  ${(props) =>
    props.isTaskDetailPreview &&
    css`
      height: calc(100dvh - 72px);
    `}
  @media (max-width: 449px) {
    height: 100dvh;
    z-index: 9;
    padding-right: 0;
    border-right: 0;
    border-bottom: 0.5px solid var(--border-primary);
  }
`;

const Header = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 24px;
  z-index: 999;
  border-bottom: 0.5px solid var(--border-primary);

  ${(props) =>
    props.isTaskDetailPreview &&
    css`
      background-color: var(--background-secondary);
    `}
  @media (max-width: 449px) {
    padding: 14px 0;
    position: fixed;
    background-color: var(--background-primary);
  }
`;

const HeaderBlock = styled.div<Props>`
  display: flex;
  flex-direction: column;
  gap: 34px;
  overflow: auto;
  min-height: 200px;
  height: 100%;
  ::-webkit-scrollbar {
    display: none;
  }
  ${(props) =>
    props.isOverflowHidden &&
    css`
      overflow: hidden;
    `}
  @media (max-width: 449px) {
    gap: 30px;
    margin-top: 59px;
  }
`;

const BackIcon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .arrow-icon {
    stroke: var(--text-primary);
  }
  :hover {
    background-color: var(--neutral-secondary);
    border-radius: 4px;
  }
`;

const IconPoint = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  background-size: cover;
  position: relative;
  .dot-icon {
    fill: var(--text-secondary);
    :hover {
      background: var(--neutral-secondary);
      border-radius: 4px;
    }
  }
  .headerarrow {
    :hover {
      background: var(--neutral-secondary);
      border-radius: 3px;

      svg {
        path {
          stroke: var(--text-primary);
        }
      }
    }
  }
  .github-icons {
    path {
      stroke: var(--text-secondary);
    }
    :hover {
      path {
        stroke: var(--text-primary);
      }
      background: var(--neutral-secondary);
      border-radius: 4px;
    }
  }
  .headerarrow {
    path {
      stroke: var(--text-primary);
    }
  }
  @media (max-width: 449px) {
    .headerarrow {
      width: 18px;
      height: 18px;
    }
  }
`;

const Text = styled.span<Props>`
  ${Typography.body_md}
  color: var(--text-secondary);
  margin: 0;
  margin-left: 5px;
  span {
    margin-left: 0;
  }
  ${(props) =>
    props.isComment &&
    css`
      margin-left: 0;
    `}
`;

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const HeaderContent = styled.div<Props>`
  display: flex;
  flex-direction: column;
  gap: 0;
  padding: 24px 0 0px 0;
  @media (max-width: 449px) {
    padding: 16px 0 0px 0;
    ${(props) =>
      props.isTaskDetailPreview &&
      css`
        padding: 0;
      `}
  }
`;

const Activity = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 20px;
  @media (max-width: 449px) {
    gap: 12px;
    border-top: 0.5px solid var(--border-primary);
  }
`;

const ActivityTitle = styled.h2`
  ${Typography.body_lg_semibold}
  color: var(--text-primary);
  margin: 0;
`;

const ItemBlock = styled.div`
  display: flex;
  flex-direction: column-reverse;
  padding-left: 24px;
  margin-left: 24px;
  &:last-child {
    border-left: none !important;
  }
`;

const ItemChange = styled.div<Props>`
  .ant-image {
    margin: 0;
  }
  display: flex;
  gap: 12px;
  margin-left: -48px;
  padding: 6px 12px;
  position: relative;
  span {
    word-break: break-word;
  }

  ${(props) =>
    props.isHover &&
    css`
      margin-bottom: 12px;
      flex-direction: column;
      gap: 6px;
      padding: 12px;
      background-color: var(--background-primary);
      border-radius: 12px;
      border: 0.5px solid var(--border-primary);
      :hover {
        .rightsection {
          display: block;
        }
      }
      &:first-child {
        margin-bottom: 12px;
      }
    `}
  :first-child {
    border-left: none;
  }
  ${(props) =>
    props.isApplyBottomSpacing &&
    css`
      margin-top: 12px;
    `}
  ${(props) =>
    props.isActive &&
    css`
      background-color: var(--background-primary);
      border-radius: 12px;
      .rightsection {
        display: block;
      }
    `}
    .threaddiv {
    cursor: pointer;
    margin-top: 6px;
    padding: 8px;
    border-radius: 4px;
    border: 0.5px solid var(--border-primary);
    background: var(--background-tertiary);
    width: 100%;
    max-width: fit-content;
    margin-top: 6px;
    p + div > .ant-image {
      margin: 10px 0 0;
    }
  }
  .content {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  .line {
    width: 1px;
    background-color: var(--text-placeholder);
    border: 1px solid var(--text-placeholder);
    height: auto;
    border-radius: 3px;
  }
  .rightdiv {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  .header {
    display: flex;
    flex-direction: row;
    gap: 50px;
    justify-content: space-between;
  }
  .leftheader {
    display: flex;
    flex-direction: row;
    gap: 3px;
    align-items: center;
  }
  .name {
    color: var(--text-primary);
    ${Typography.body_md}
  }
  .time {
    color: var(--text-secondary);
    ${Typography.body_md}
  }
  .textblock {
    width: 100%;
    max-width: 100%;
    display: block;
    color: var(--text-primary);
    ${Typography.body_md}
    p {
      color: var(--text-primary);
      ${Typography.body_md}
      margin: 0;
      a {
        color: var(--text-secondary);
        word-break: break-word;
      }
      img {
        max-width: 300px;
        max-height: 300px;
        width: auto;
        height: auto;
      }
    }
    span {
      margin: 0;
      ${Typography.body_md}
      color: var(--text-primary);
    }
  }
  .text {
    width: 100%;
    max-width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    color: var(--text-primary);
    ${Typography.body_md}
    p {
      color: var(--text-primary);
      ${Typography.body_md}
      margin: 0;
      a {
        color: var(--text-secondary);
        word-break: break-word;
      }
      img {
        max-width: 300px;
        max-height: 300px;
        width: auto;
        height: auto;
      }
    }
    span {
      margin: 0;
      ${Typography.body_md}
      color: var(--text-primary);
    }
  }
  .filediv {
    display: flex;
    flex-direction: row;
    gap: 5px;
    flex-wrap: wrap;
    align-items: center;
  }
  .imagediv {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
  }
  .figma-main-div {
    position: relative;
    z-index: 999;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background-color: var(--background-secondary);
    border: 0.5px solid var(--border-primary);
    width: fit-content;
    overflow: hidden;
    margin-top: 12px;
    .ant-image {
      margin: 0;
    }
    .ant-image .ant-image-img {
      border-radius: 0 !important;
      max-width: 100%;
      width: auto;
      max-height: 419px;
      height: auto;
    }
  }
  p + .figma-main-div {
    margin-top: 10px;
  }
  @media (max-width: 449px) {
    .figma-main-div .ant-image .ant-image-img {
      border-right: unset !important;
      border-left: unset !important;
    }
  }
  .figma-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    padding: 8px;
    border-bottom: 0.5px solid var(--border-primary);
    @media (max-width: 449px) {
      flex-direction: column;
      align-items: baseline;
      gap: 6px;
    }
  }
  .figma-desc-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }
  .figma-link-div {
    padding: 5px 16px;
    background-color: var(--background-primary);
    color: var(--text-primary);
    border: 0.5px solid var(--border-primary);
    cursor: pointer;
    text-decoration: none;
    border-radius: 36px;
    ${Typography.body_sm}
    &:hover {
      background-color: var(--background-secondary);
      border-radius: 36px;
      text-decoration: none;
    }
    @media (max-width: 449px) {
      flex-direction: column;
      margin-left: 30px;
    }
  }
  .figma-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    @media (max-width: 449px) {
      flex-direction: column;
      gap: 4px;
      align-items: flex-start;
    }
  }
  .figma-description {
    color: var(--text-primary);
    ${Typography.body_sm_medium}
  }
  .figma-filename {
    color: var(--text-secondary);
    ${Typography.body_sm_medium}
  }
  .figma-icon {
    width: 24px;
    height: 24px;
  }
  .figma-preview-image {
    border-radius: 0px !important;
    max-width: 100%;
    width: auto;
    max-height: 419px;
    height: auto;
    margin: 0 auto;
    display: flex;
  }
  .figma-right-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
  .figma-animation {
    height: 24px;
    display: flex;
    margin-top: unset !important;
    .refresh-icon-spin {
      animation-name: spin;
      animation-duration: 1000ms;
      animation-iteration-count: infinite;

      -webkit-animation-name: spin;
      -webkit-animation-duration: 1000ms;
      -webkit-animation-iteration-count: infinite;
    }
    @-ms-keyframes spin {
      from {
        -ms-transform: rotate(0deg);
      }
      to {
        -ms-transform: rotate(360deg);
      }
    }
    @-moz-keyframes spin {
      from {
        -moz-transform: rotate(0deg);
      }
      to {
        -moz-transform: rotate(360deg);
      }
    }
    @-webkit-keyframes spin {
      from {
        -webkit-transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(360deg);
      }
    }
    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
    @media (max-width: 449px) {
      position: absolute;
      right: 48px;
      top: 8px;
      width: 24px;
      height: 24px;
      .ant-image .ant-image-img {
        width: 100%;
      }
    }
  }
  .refresh-icon {
    width: 18px;
    height: 18px;
    max-width: 100%;
    max-height: 18px;
    cursor: pointer;
    padding: 3px;
    margin-top: unset !important;
  }
  .inbox-image {
    margin: 0 auto;
  }
  .filetext {
    color: var(--text-primary);
    ${Typography.body_md_medium}
    margin-top: 2px;
  }
  @media (max-width: 449px) {
    .filetext {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
    .refresh-icon {
      width: 16px;
      height: 16px;
      padding: 4px;
    }
  }
`;

const RightSection = styled.div`
  display: none;
  position: absolute;
  right: 12px;
  @media (max-width: 449px) {
    display: block;
  }
  &.active {
    display: block;
  }

  .dropdown-content {
    position: relative;
    z-index: 999;
    top: 10px;
    @media (max-width: 320px) {
      left: 64%;
      transform: translate(-50%, 0);
    }
  }
  .dropdown-content-not-users {
    position: relative;
    z-index: 999;
    top: 10px;
    @media (max-width: 320px) {
      left: 52%;
      transform: translate(-50%, 0);
    }
  }
`;

const ReactionDiv = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  .svgicon {
    stroke: var(--text-secondary);
  }
  &:hover {
    background-color: var(--neutral-secondary);
    .svgicon {
      stroke: var(--text-primary);
    }
  }
`;

const Portion = styled.div`
  display: flex;
  gap: 6px;
  align-items: flex-start;
  .active {
    border-radius: 4px;
    background-color: var(--neutral-secondary);
  }
`;

const ReactionShow = styled.div<Props>`
  border: 0.5px solid var(--border-primary);
  border-radius: 16px;
  padding: 1px 7px;
  display: flex;
  align-items: center;
  gap: 6px;
  width: fit-content;
  margin-top: 8px;
  :hover {
    background-color: var(--neutral-secondary);
    cursor: pointer;
  }
  .reaction-icon {
    font-size: 13px;
    line-height: 16px;
  }
  p {
    ${Typography.body_md}
    color: var(--text-primary);
    margin: 0;
  }
  ${(props) =>
    props.isDisabled &&
    `
      pointer-events: none;
    `}
`;

const SubTitle = styled.h1`
  ${Typography.body_md}
  color: var(--text-primary);
  margin: 0;
  span {
    color: var(--text-secondary);
  }
`;
const ActivityContentView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    margin: 0;
    word-break: break-word;
  }
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const ActivitySubContent = styled.div<Props>`
  display: flex;
  gap: 5px;
  @media (max-width: 449px) {
    padding-top: 0px;
  }
  ${(props) =>
    props.isComment &&
    css`
      gap: 3px;
      align-items: center;

      @media (max-width: 375px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 0;
      }
    `}
`;

const SubText = styled.div<{ figmaRefreshLoading?: boolean }>`
  .file-box {
    cursor: pointer;
    max-width: 240px;
    display: inline-flex;
    padding: 10px 8px;
    background-color: var(--background-primary);
    border: 0.5px solid var(--border-primary);
    border-radius: 8px;
    box-shadow: var(--shadow-card);
    align-items: center;
    gap: 8px;
    margin-top: 12px;
  }
  .image-div {
    width: 32px;
    height: 32px;
  }
  .textarea {
    display: flex;
    flex-direction: column;
    gap: 4px;
    h4 {
      max-width: 184px;
      ${Typography.body_md_medium}
      margin: 0px;
      color: var(--text-primary);
      overflow: hidden;
      text-overflow: ellipsis;
    }
    p {
      ${Typography.body_xs}
      color: var(--text-secondary);
      margin: 0px;
    }
  }
  .svg {
    width: 32px;
    height: 32px;
  }
  p + .figma-main-div {
    margin-top: 8px;
  }
  .figma-main-div {
    position: relative;
    z-index: 999;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background-color: var(--background-secondary);
    border: 0.5px solid var(--border-primary);
    width: fit-content;
    overflow: hidden;
    margin-top: 12px;
    .ant-image {
      margin: 0;
      margin-top: unset !important;
    }
    .ant-image .ant-image-img {
      border-radius: 0 !important ;
      max-width: 100%;
      width: auto;
      max-height: 419px;
      height: auto;
      margin-top: unset !important;
      @media (max-width: 449px) {
        border-right: unset;
        border-left: unset;
      }
    }
  }
  .inbox-image {
    margin: 0 auto;
  }
  .figma-desc-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    margin-top: unset !important;
    @media (max-width: 449px) {
      gap: 6px;
    }
  }
  .figma-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    padding: 8px;
    border-bottom: 0.5px solid var(--border-primary);
    margin-top: unset !important;
    @media (max-width: 449px) {
      flex-direction: column;
      align-items: baseline;
      gap: 6px;
    }
  }
  .figma-link-div {
    padding: 5px 16px;
    background-color: var(--background-primary);
    color: var(--text-primary);
    border: 0.5px solid var(--border-primary);
    cursor: pointer;
    text-decoration: none;
    border-radius: 36px;
    ${Typography.body_sm_medium}
    margin-top: unset !important;
    &:hover {
      background-color: var(--background-secondary);
      border-radius: 36px;
    }
    @media (max-width: 449px) {
      flex-direction: column;
      margin-left: 30px;
    }
  }
  .figma-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    @media (max-width: 449px) {
      flex-direction: column;
      gap: 4px;
      align-items: flex-start;
    }
  }
  .figma-description {
    color: var(--text-primary);
    ${Typography.body_sm_medium}
    margin-top: unset !important;
  }
  .figma-filename {
    color: var(--text-secondary);
    ${Typography.body_sm_medium}
  }
  .figma-icon {
    width: 24px;
    height: 24px;
    margin-top: unset !important;
  }
  .figma-preview-image {
    border-radius: 0 !important;
    max-width: 100%;
    width: auto;
    max-height: 419px;
    height: auto;
    margin: 0 auto;
    display: flex;
  }
  .figma-right-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
  .figma-animation {
    height: 24px;
    display: flex;
    .refresh-icon-spin {
      animation-name: spin;
      animation-duration: 1000ms;
      animation-iteration-count: infinite;

      -webkit-animation-name: spin;
      -webkit-animation-duration: 1000ms;
      -webkit-animation-iteration-count: infinite;
    }
    @-ms-keyframes spin {
      from {
        -ms-transform: rotate(0deg);
      }
      to {
        -ms-transform: rotate(360deg);
      }
    }
    @-moz-keyframes spin {
      from {
        -moz-transform: rotate(0deg);
      }
      to {
        -moz-transform: rotate(360deg);
      }
    }
    @-webkit-keyframes spin {
      from {
        -webkit-transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(360deg);
      }
    }
    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
    @media (max-width: 449px) {
      position: absolute;
      right: 48px;
      top: 8px;
      width: 24px;
      height: 24px;
      .ant-image .ant-image-img {
        width: 100%;
      }
    }
  }
  .refresh-icon {
    width: 18px;
    height: 18px;
    max-width: 100%;
    max-height: 18px;
    cursor: pointer;
    padding: 3px;
    ${(props: { figmaRefreshLoading?: boolean }) =>
      props.figmaRefreshLoading &&
      css`
        pointer-events: none;
      `}
    @media (max-width: 449px) {
      width: 16px;
      height: 16px;
      padding: 4px;
    }
  }
  .ant-image {
    margin-bottom: 0 !important;
  }

  .screen-recording-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .e-rte-video .e-video-inline {
    width: 355px !important;
  }
  .screen-recording-name {
    color: var(--text-primary);
    ${Typography.body_sm_medium}
    max-width: 300px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
  .screen-recording-right-div {
    display: flex;
    gap: 12px;
  }
  .open-video-icon {
    max-width: 100%;
    max-height: 100%;
    margin-top: unset !important;
    cursor: pointer;
    :hover {
      background-color: var(--neutral-secondary);
      border-radius: 4px;
    }
  }
  .video-more-icon {
    max-width: 100%;
    max-height: 100%;
    margin-top: unset !important;
    cursor: pointer;
    :hover {
      background-color: var(--neutral-secondary);
      border-radius: 4px;
    }
  }
  .inbox-video {
    max-width: 353px;
    width: 100%;
    height: auto;
  }
  .screen-recording-animation {
    width: 16px;
    height: 16px;
    margin-top: unset !important;
  }
  .screen-recording-more {
    width: 16px;
    height: 16px;
  }
  .inbox-image {
    position: relative;
    display: flex;
  }
  .video-icon {
    position: absolute;
    top: 45%;
    left: 45%;
    cursor: pointer;
  }
  .video-preview {
    border: 0.5px solid var(--border-primary) !important;
    border-radius: 8px;
    max-width: 353px;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--background-tertiary) !important;
    margin-top: 8px;
    overflow: hidden;
  }
  .e-rte-table td,
  .e-rte-table th {
    border: 0.5px solid var(--border-primary);
  }
`;

const FileSection = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  cursor: pointer;
  margin-top: 4px;
`;

const ButtonBlock = styled.div<Props>`
  display: flex;
  gap: 6px;
  justify-content: flex-end;
  .ant-dropdown .ant-dropdown-menu {
    background-color: var(--background-primary);
    border: 0.5px solid var(--border-primary);
  }
  ${(props) =>
    props.isCommentModelContent &&
    css`
      align-items: center;
    `}
  ${(props) =>
    props.contactusModal &&
    css`
      justify-content: unset;
    `}
  @media (max-width: 449px) {
    gap: 4px;
  }
`;

const LeftBarView = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
`;

const RightBar = styled.div<Props>`
  width: 402px;
  display: flex;
  flex-direction: column;
  padding: 46px 0 40px 24px;
  gap: 24px;
  position: relative;
  overflow: auto;
  /* height: calc(100vh - 86px); */
  ::-webkit-scrollbar {
    display: none;
  }
  ${(props) =>
    props.isTaskDetailPreview &&
    css`
      height: calc(100dvh - 158px);
    `}
  @media (max-width: 449px) {
    padding: 0;
    height: 100%;
    width: 100%;
    overflow: unset;
    gap: 20px;
  }
`;

const Listening = styled.div<{ hideBorderBottom?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-bottom: ${({ hideBorderBottom }) => (hideBorderBottom ? 'none' : `0.5px solid var(--border-primary)`)};
  padding-bottom: 24px;
  @media (max-width: 449px) {
    padding-bottom: 20px;
  }
`;

const RightItemBlocks = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  pointer-events: none;
  :hover .left {
    color: var(--text-secondary);
  }

  :hover .right {
    background: var(--background-primary);
    border: 0.5px solid var(--border-primary);
    border-radius: 6px;
  }
  .dropdown {
    position: relative;
    top: 0;
  }
  @media (max-width: 449px) {
    padding: 0;
  }
`;
const RightItemBlock = styled.div<Props>`
  display: flex;
  align-items: center;
  gap: 20px;
  pointer-events: none;
  :hover .left {
    color: var(--text-secondary);
  }
  ${(props: Props) =>
    props.isShowHover &&
    css`
      :hover .right {
        background: var(--background-primary);
        border: 0.5px solid var(--border-primary);
        border-radius: 6px;
      }
    `}
  :hover .plus-icon {
    visibility: visible;
    border: none;
  }
  .subscriber {
    cursor: auto;
  }
  @media (max-width: 449px) {
    padding: 0;
  }
`;
const RightSubTitle = styled.h6<Props>`
  ${Typography.body_md_medium}
  color: var(--text-secondary);
  margin: 0;
  white-space: nowrap;
  width: 70px;
`;

const Assigngroup = styled.div`
  ul {
    display: flex;
    align-items: center;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  li {
    display: flex;
    margin-right: -8px;
    border-radius: 50%;
  }

  .plus-icon {
    visibility: hidden;
  }
`;
const AvatarImage = styled.div`
  display: flex;
  align-items: center;
  border-radius: 50%;
  > div > img {
    max-width: 24px;
  }
`;
const TimeLog = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (max-width: 449px) {
    padding: 0;
  }
`;

const TimerHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

const TimeLogTitle = styled.h6`
  ${Typography.body_md_semibold}
  color: var(--text-secondary);
  margin: 0;
`;

const TimeLogContent = styled.div`
  display: flex;
  gap: 4px;
  @media (max-width: 449px) {
    flex-wrap: wrap;
  }
`;

const Taskarea = styled.div<{ figmaRefreshLoading?: boolean }>`
  width: 100%;
  ${Typography.body_lg}
  color: var(--text-secondary);
  margin: 0px;
  border: none;
  resize: none;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  .figma-main-div {
    position: relative;
    z-index: 99;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background-color: var(--background-secondary);
    border: 0.5px solid var(--border-primary);
    width: fit-content;
    overflow: hidden;
    margin-top: 12px;
    .ant-image {
      margin: 0;
    }
    .ant-image .ant-image-img {
      border-radius: 0;
      max-width: 100%;
      width: auto;
      max-height: 419px;
      height: auto;
      margin: 0;
      border-radius: 0px !important;
      @media (max-width: 449px) {
        border-right: unset;
        border-left: unset;
      }
    }
  }
  .figma-desc-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    margin-top: 0;
  }
  .figma-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    padding: 8px;
    border-bottom: 0.5px solid var(--border-primary);
    margin-top: 0;
    @media (max-width: 449px) {
      flex-direction: column;
      align-items: baseline;
      gap: 6px;
    }
  }
  .figma-link-div {
    padding: 5px 16px;
    background-color: var(--background-primary);
    color: var(--text-primary) !important;
    border: 0.5px solid var(--border-primary);
    cursor: pointer;
    text-decoration: none !important;
    border-radius: 36px;
    ${Typography.body_sm_medium}
    &:hover {
      background-color: var(--background-secondary) !important;
      border-radius: 36px !important;
    }
    @media (max-width: 449px) {
      flex-direction: column;
      margin-left: 30px;
    }
  }
  .figma-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    @media (max-width: 449px) {
      flex-direction: column;
      gap: 4px;
      align-items: flex-start;
    }
  }
  .figma-description {
    color: var(--text-primary);
    ${Typography.body_sm_medium}
    margin-top: 0;
  }
  .figma-filename {
    color: var(--text-secondary);
    ${Typography.body_sm_medium}
  }
  .figma-icon {
    width: 24px;
    height: 24px;
    margin-top: 0;
  }
  .figma-preview-image {
    border-radius: 0 !important;
    max-width: 100%;
    width: auto;
    max-height: 419px;
    height: auto;
    margin: 0 auto;
    display: flex;
  }
  .figma-right-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
  .figma-animation {
    height: 24px;
    display: flex;
    margin-top: unset !important;
    .refresh-icon-spin {
      animation-name: spin;
      animation-duration: 1000ms;
      animation-iteration-count: infinite;

      -webkit-animation-name: spin;
      -webkit-animation-duration: 1000ms;
      -webkit-animation-iteration-count: infinite;
    }
    @-ms-keyframes spin {
      from {
        -ms-transform: rotate(0deg);
      }
      to {
        -ms-transform: rotate(360deg);
      }
    }
    @-moz-keyframes spin {
      from {
        -moz-transform: rotate(0deg);
      }
      to {
        -moz-transform: rotate(360deg);
      }
    }
    @-webkit-keyframes spin {
      from {
        -webkit-transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(360deg);
      }
    }
    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
    @media (max-width: 449px) {
      position: absolute;
      right: 48px;
      top: 8px;
      width: 24px;
      height: 24px;
      .ant-image .ant-image-img {
        width: 100%;
      }
    }
  }
  .refresh-icon {
    width: 18px;
    height: 18px;
    max-width: 100%;
    max-height: 18px;
    cursor: pointer;
    padding: 3px;
    margin-top: unset !important;
    ${(props: { figmaRefreshLoading?: boolean }) =>
      props.figmaRefreshLoading &&
      css`
        pointer-events: none;
      `}
    @media (max-width: 449px) {
      width: 16px;
      height: 16px;
      padding: 4px;
    }
  }
  .inbox-image {
    margin: 0 auto;
    margin-top: unset !important;
  }
  .e-rte-table td,
  .e-rte-table th {
    border: 0.5px solid var(--border-primary);
  }
  @media (max-width: 449px) {
    .ant-image .ant-image-img {
      width: auto;
    }
  }
`;

const RightAssign = styled.div`
  display: flex;
  align-items: center;
  padding: 3px 11px;
  border: 0.5px solid transparent;
  cursor: pointer;
  pointer-events: auto;
  margin-left: -3px;

  ul {
    list-style-type: none;
    height: 24px;
  }

  li {
  }
  .plus-icon {
    visibility: hidden;
  }
`;
const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  a {
    color: var(--text-link);
    text-decoration: underline;
    :hover {
      text-decoration: none;
    }
  }

  .file-box {
    cursor: pointer;
    max-width: max-content;
    display: inline-flex;
    padding: 10px 16px 10px 8px;
    background-color: var(--background-primary);
    border: 0.5px solid var(--border-primary);
    border-radius: 8px;
    box-shadow: var(--shadow-card);
    align-items: center;
    gap: 8px;
    margin-top: 12px;
  }
  .textarea {
    width: 130px;
    white-space: unset !important;
  }
  .h4 {
    ${Typography.body_md_medium}
    color: var(--text-primary);
    margin: 0 0 4px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    white-space: inherit;
  }
  .p {
    ${Typography.body_xs}
    color: var(--text-secondary);
    margin: 0;
  }
  .svg {
    width: 32px;
    height: 32px;
  }
`;

const ActivityView = styled.div<Props>`
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 24px;
  @media (max-width: 449px) {
    ${(props) =>
      props.isTaskDetailPreview &&
      css`
        margin-bottom: 60px;
      `}
  }
`;
const FormBlock = styled.form``;

const InputTitle = styled.div`
  width: 100%;
  display: inline-block;
  ${Typography.heading_lg}
  color: var(--text-primary);
  padding: 0;
  border: none;
  outline: none;
  ::placeholder {
    ${Typography.heading_lg}
    color: var(--text-secondary);
  }
  a {
    color: var(--text-secondary);
    text-decoration: underline;
    :hover {
      text-decoration: none;
    }
  }
`;

const Form = styled.div`
  display: flex;
  align-items: baseline;
  gap: 8px;
  .img {
    width: 20px;
    height: 20px;
    padding-top: 2px;
  }
  .checkbox-round {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid var(--text-secondary);
    appearance: none;
    outline: none;
    cursor: pointer;
    margin: 0;
    padding: 7px;
  }

  input[type='checkbox'] {
    position: relative;
    cursor: pointer;
    opacity: 1;
  }

  input[type='checkbox']:checked {
    background-color: var(--text-tertiary);
    opacity: 1;
    border: 1px solid var(--text-tertiary);
  }

  input[type='checkbox']:checked::before {
    content: '';
    position: absolute;
    right: 50%;
    top: 50%;
    width: 4px;
    height: 12px;
    border: solid var(--text-white);
    border-width: 0 2px 2px 0;
    margin: -1px -0.5px 0 -1px;
    transform: rotate(45deg) translate(-50%, -50%) scale(0.7);
    z-index: 2;
  }
  .task-open-icon {
    path {
      stroke: var(--text-secondary);
    }
    path.first-path {
      fill: var(--background-primary);
    }
  }
  .complete-icon {
    path {
      fill: var(--text-tertiary);
    }
  }
`;

const Projectname = styled.div`
  ${Typography.body_lg}
  margin: 0;
  color: var(--text-secondary);
  padding: 2px 4px;
  cursor: pointer;
  :hover {
    color: var(--text-primary);
    background-color: var(--neutral-secondary);
    border-radius: 4px;
  }
`;

const OverLayDiv = styled.div<Props>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const TaskDetailContainer = styled.div<Props>`
  max-width: 850px;
  width: -webkit-fill-available;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  @media (max-width: 449px) {
    padding: 0;
    gap: 20px;
    ${(props) =>
      props.isTaskDetailPreview &&
      css`
        padding: 12px 16px;
      `}
  }
`;

const TaskContainer = styled.div`
  max-width: 850px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Undomessage = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-top: 5px;
  p {
    color: var(--text-secondary);
    ${Typography.body_md}
    font-style: italic;
    margin: 0;
  }
  a {
    color: var(--brand-primary);
    ${Typography.body_md_medium}
    text-decoration-line: underline;
    cursor: pointer;
  }
`;

const Timer = styled.div`
  position: relative;
  @media (max-width: 449px) {
    width: -webkit-fill-available;
  }
  .time-icon {
    path {
      stroke: var(--text-secondary);
    }
  }
`;

const Action = styled.div`
  display: flex;
  max-width: 83px;
  width: 100%;
  align-items: center;
  border-radius: 0 36px 36px 0;
  background-color: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  cursor: pointer;
  position: relative;
  z-index: 1;
  span {
    ${Typography.body_md_medium};
    color: var(--text-primary);
  }
`;

const Timelog = styled.p`
  margin: 0;
  ${Typography.body_sm};
  span {
    ${Typography.body_sm_medium};
    color: var(--text-primary);
  }
`;

const Record = styled.div`
  position: absolute;
  left: -13px;
  z-index: 11;
  width: 26px;
  height: 26px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid var(--border-primary);
  background-color: var(--background-primary);
  border-radius: 50%;
  box-shadow: var(--shadow-button);
  .timer-stop-icon {
    path {
      fill: var(--text-secondary);
    }
  }
  .timer-start-icon {
    path {
      fill: var(--text-secondary);
    }
  }
`;

const Reactbox = styled.div`
  .menuStyle {
    display: flex;
    flex-direction: column;
    padding: 3px 0;
    border: 0.5px solid var(--border-primary);
    background-color: var(--background-primary);
    box-shadow: var(--shadow-modal);
    border-radius: 8px;
    margin: 0px;

    .menuitem {
      margin: 0;
      :hover {
        background-color: unset !important;
        border-radius: 0;
      }
    }
  }
`;
const Collpasetext = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 6px 12px;
  cursor: pointer;
  span {
    color: var(--accent-error);
    margin: 0;
  }
  p {
    ${Typography.body_md}
    color: var(--text-primary);
    margin: 0;
  }
  :hover {
    background-color: var(--neutral-secondary);
  }
`;

const Reactiondiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  width: fit-content;
  @media (max-width: 449px) {
    padding-top: 2px;
  }
`;

const Time = styled.div`
  padding: 3px 11px 2px 22px;
  width: 50px;
`;
const Addsubtask = styled.div<Props>`
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  margin-top: 20px;
  p {
    ${Typography.body_md_medium}
    color: var(--text-secondary);
    margin: 0;
  }
  .plus-icon {
    stroke: var(--text-secondary);
  }
  :hover {
    p {
      color: var(--text-primary);
    }
    .plus-icon {
      stroke: var(--text-primary);
    }
  }
`;

const SubTaskList = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 16px; */
  &.has-maintask {
    /* gap: 0px; */
  }
  @media (max-width: 449px) {
    gap: 20px;
    &.has-maintask {
      /* gap: 0px; */
    }
  }
`;

const Subtask = styled.div<Props>`
  padding: 3px 5px;
  display: flex;
  justify-content: space-between;
  gap: 40px;
  cursor: pointer;
  border-radius: 34px;
  border: 0.5px solid transparent !important;
  position: relative;
  :hover {
    border: 0.5px solid var(--border-primary) !important;
    background-color: var(--background-primary) !important;
    box-shadow: var(--shadow-card) !important;

    .svgicon {
      opacity: 1;
    }
    .plus-icon {
      visibility: visible;
      .fill-color {
        stroke: var(--neutral-secondary);
      }
    }
  }

  ${(props: Props) =>
    props.isActive &&
    css`
      border: 0.5px solid var(--border-primary) !important;
      background-color: var(--background-primary) !important;
      box-shadow: var(--shadow-card) !important;
    `}
  @media (max-width: 449px) {
    min-height: 24px;
    padding: 5px 17px 5px 9px;
  }
`;
const Iconhidden = styled.div`
  position: absolute;
  left: -15px;
  top: 9px;
  cursor: pointer;
  .svgicon {
    opacity: 0;
    stroke: var(--text-secondary);
  }

  @media (max-width: 449px) {
    .svgicon {
      display: none;
    }
  }
`;
const Taskitem = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  width: 100%;

  @media (max-width: 449px) {
    justify-content: space-between;
    width: 100%;
    gap: 8px;
  }
`;
const Form1 = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  .svg-icon {
    path {
      stroke: var(--text-secondary);
    }
  }
  .cancelled-icon {
    path {
      fill: var(--text-tertiary);
    }
  }
  .task-inprogress-icon {
    path.first-path {
      fill: var(--background-primary);
    }
  }
  .task-todo-icon {
    path {
      fill: var(--background-primary);
      stroke: var(--text-secondary);
    }
  }
  .task-inreview-icon {
    path.first-path {
      fill: var(--background-primary);
    }
  }
  .task-status-icon {
    path.fill1 {
      fill: var(--background-primary);
    }
    path.fill2 {
      fill: var(--text-secondary);
    }
    path.fill3 {
      fill: var(--background-primary);
    }
  }
  .task-completed-icon {
    path {
      fill: var(--text-tertiary);
    }
  }
  .checkbox-round {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid var(--text-secondary);
    appearance: none;
    outline: none;
    cursor: pointer;
    margin: 0;
    padding: 7px;
  }
  input[type='checkbox'] {
    position: relative;
    cursor: pointer;
    opacity: 1;
    background-color: var(--background-primary);
  }
  input[type='checkbox']:checked {
    background-color: var(--text-tertiary);
    opacity: 1;
    border: 1px solid var(--text-tertiary);
  }
  input[type='checkbox']:checked::before {
    content: '';
    position: absolute;
    right: 50%;
    top: 50%;
    width: 3px;
    height: 8px;
    border: solid var(--text-white);
    border-width: 0 2px 2px 0;
    margin: -0.5px -1px 0 -1px;
    transform: rotate(45deg) translate(-50%, -50%) scale(0.7);
    z-index: 2;
  }
`;
const Inputvalues = styled.input``;
const Label = styled.label`
  ${Typography.body_md}
  color: var(--text-primary);
  margin: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-word;
  a {
    color: var(--text-link);
    display: inline-block;
    text-decoration: underline;
    :hover {
      text-decoration: none;
    }
  }
`;
const Right = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  @media (max-width: 449px) {
    display: none;
  }
`;
const Duedate = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  cursor: default;
  .calendar-fill-color {
    fill: var(--text-secondary);
  }
  .calendar-due-fill-color {
    fill: var(--accent-error);
  }
  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }
`;

const Detailtask = styled.p<Props>`
  ${Typography.body_md}
  color: var(--text-secondary);
  margin: 0;
  white-space: nowrap;

  ${(props: Props) =>
    props.isOverdue &&
    css`
      color: var(--accent-error);
    `}
`;

const Subtasktitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;
const Maintask = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  span {
    color: var(--text-secondary);
    ${Typography.body_lg}
    white-space: nowrap;
  }
`;
const Form2 = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  border: 0.5px solid transparent;
  padding: 3px 10px 3px 6px;
  border-radius: 20px;
  .svg-icon {
    path {
      stroke: var(--text-secondary);
    }
  }
  .cancelled-icon {
    path {
      fill: var(--text-tertiary);
    }
  }
  .task-todo-icon {
    path {
      fill: var(--background-primary);
      stroke: var(--text-secondary);
    }
  }
  .task-inprogress-icon {
    path.first-path {
      fill: var(--background-primary);
    }
  }
  .task-inreview-icon {
    path.first-path {
      fill: var(--background-primary);
    }
  }
  .task-status-icon {
    path.fill1 {
      fill: var(--background-primary);
    }
    path.fill2 {
      fill: var(--text-secondary);
    }
    path.fill3 {
      fill: var(--background-primary);
    }
  }
  .task-completed-icon {
    path {
      fill: var(--text-tertiary);
    }
  }
  cursor: pointer;
  :hover {
    border: 0.5px solid var(--border-primary);
    background-color: var(--background-primary);
  }
  .checkbox-round {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid var(--border-secondary);
    appearance: none;
    outline: none;
    cursor: pointer;
    margin: 0;
    padding: 7px;
  }
  input[type='checkbox'] {
    position: relative;
    cursor: pointer;
    opacity: 1;
    background-color: var(--background-primary);
  }
  input[type='checkbox']:checked {
    background-color: var(--text-tertiary);
    opacity: 1;
    border: 1px solid var(--text-tertiary);
  }
  input[type='checkbox']:checked::before {
    content: '';
    position: absolute;
    right: 50%;
    top: 50%;
    width: 3px;
    height: 8px;
    border: solid var(--text-white);
    border-width: 0 2px 2px 0;
    margin: -0.5px -1px 0 -1px;
    transform: rotate(45deg) translate(-50%, -50%) scale(0.7);
    z-index: 2;
  }
`;
const TaskLabel = styled.div`
  color: var(--text-primary);
  ${Typography.body_lg}
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
`;

const Taskcount = styled.div`
  padding: 3px 10px;
  border: 0.5px solid var(--border-primary);
  border-radius: 20px;
  color: var(--text-primary);
  ${Typography.body_md}
`;

const Editicon = styled.div`
  max-width: 24px;
  width: 100%;
  height: 24px;
  z-index: 1;
  background-color: var(--neutral-secondary);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  .calendar-icon {
    path {
      fill: var(--text-secondary);
    }
  }
  .milestone-icon {
    path {
      stroke: var(--text-secondary);
    }
  }

  .label-icon {
    path {
      stroke: var(--text-secondary);
    }
  }
  .estimate-icon {
    path {
      fill: var(--text-secondary);
    }
  }
  .reopentask-icon {
    path {
      stroke: var(--text-secondary);
    }
  }
  .completedtask-icon {
    path.fill1 {
      fill: var(--text-placeholder);
    }
  }
`;
const Div = styled.div<Props>`
  display: flex;
  gap: 12px;
  ${(props) =>
    props.isComment &&
    css`
      @media (max-width: 449px) {
        align-items: center;
      }
    `}
`;
const Div2 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  img {
    margin-top: 0 !important;
    max-width: 100%;
    max-height: 100%;
  }
`;
const Borderdiv = styled.div`
  position: absolute;
  border-left: 0.5px solid var(--border-primary);
  height: 36px;
  left: 24px;
  top: 10px;
  z-index: 0;
  @media screen {
    height: 100%;
  }
`;
const Divsubtask = styled.div`
  border-bottom: 0.5px solid var(--border-primary);
  padding: 4px 0;
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const Statusicon = styled.div`
  max-width: 24px;
  width: 100%;
  height: 24px;
  z-index: 1;
  background-color: var(--neutral-secondary);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 14px;
    height: 14px;
  }
  .svg-icon {
    path {
      stroke: var(--text-secondary);
    }
  }
  .cancelled-icon {
    path {
      fill: var(--text-tertiary);
    }
  }
  .task-todo-icon {
    path {
      fill: transparent;
      stroke: var(--text-secondary);
    }
  }
  .task-inprogress-icon {
    path.first-path {
      fill: transparent;
    }
  }
  .task-inreview-icon {
    path.first-path {
      fill: transparent;
    }
  }
  .task-status-icon {
    path {
      fill: var(--text-secondary);
    }
  }
  .task-completed-icon {
    path {
      fill: var(--text-tertiary);
    }
  }
`;
const Replyicon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: var(--neutral-secondary);
    .svgicon {
      stroke: var(--text-primary);
    }
  }
  .svgicon {
    stroke: var(--text-secondary);
  }
`;

const Svgfile = styled.div`
  width: 32px;
  height: 32px;
`;

const Multiplelabel = styled.div`
  border-radius: 15px;
  border: 0.5px solid var(--border-primary);
  display: flex;
  cursor: auto;
  @media (max-width: 449px) {
    display: none;
  }
`;

const Dropblock = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 3px 9px 3px 5px;
  border-left: 0.5px solid var(--border-primary);
  &.left-border {
    border-left: none;
  }
`;

const Doticon = styled.div<Props>`
  margin: 4px;
  width: 8px;
  height: 8px;
  border-radius: 20px;
  ${(props: Props) =>
    props.color &&
    css`
      background-color: ${props.color};
    `}
`;
const Droplabel = styled.div`
  ${Typography.body_md}
  color: var(--text-primary);
`;

const SubTaskSection = styled.div``;

const AddSubTaskSection = styled.div`
  border-bottom: 0.5px solid var(--border-primary);
  padding-bottom: 12px;
  display: flex;
  justify-content: space-between;
`;

const SubtaskHeadLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 20px;
  p {
    ${Typography.body_md_medium}
    margin: 0;
    color: var(--text-secondary);
  }
`;
const SubtaskHeadRight = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 20px;
`;

const TaskIndicatorText = styled.div`
  ${Typography.body_md_medium}
  color: var(--text-secondary);
`;

const TaskIndicator = styled.div<Props>`
  display: flex;
  gap: 6px;
  align-items: center;
  .circle {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: relative;
    background: conic-gradient(
      #4caf50 ${(props) => props.completePercentage! * 3.6}deg,
      #ddd 0deg ${(props) => props.completePercentage! * 3.6}deg
    );
  }
  .innerCircle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    background: var(--background-secondary);
    border-radius: 50%;
  }
`;

const AddsubtaskIcon = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;
  .plus-icon {
    stroke: var(--text-secondary);
  }
  :hover {
    background-color: var(--neutral-secondary);
    border-radius: 4px;
  }
`;
const Githubicon = styled.div`
  width: 24px;
  height: 24px;
  z-index: 1;
  .github-icon {
    rect {
      fill: var(--neutral-secondary);
    }
    path {
      fill: var(--text-primary);
    }
  }
`;
const GithubMainDiv = styled.div`
  padding: 6px 12px;
  border: 0.5px solid var(--border-primary);
  background-color: var(--background-primary);
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;
const Githubsmallicon = styled.div`
  display: flex;
  .github-icon {
    path {
      fill: var(--text-primary);
    }
  }
`;
const Bottomcontent = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
const Texts = styled.div`
  ${Typography.body_md_medium};
  color: var(--text-secondary);
`;
const Dot = styled.div`
  width: 3px;
  height: 3px;
  background-color: var(--text-secondary);
  border-radius: 50%;
  margin: 5px;
`;

const GithubTitle = styled.div`
  ${Typography.body_md_medium};
  color: var(--text-primary);
`;
const Mergedlabel = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  p {
    ${Typography.body_md_medium}
    color: var(--text-secondary);
    margin: 0;
  }
`;
const Labelicon = styled.div`
  width: 14px;
  height: 14px;
  display: flex;
`;
const MergeText = styled.div`
  ${Typography.body_md};
  color: var(--text-secondary);
`;

const TaskAreaFile = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  cursor: pointer;
  margin-top: 12px;
`;

const StartButton = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  box-shadow: var(--shadow-button);
  cursor: pointer;
  .timer-start-icon {
    padding: 4px;
    path {
      fill: var(--text-secondary);
    }
  }
`;
const ProgressBar = styled.div<Props>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: var(--neutral-secondary);
  z-index: 0;
  width: ${(props) => props.progress}%;
  ${(props) =>
    props.isTimeOver &&
    css`
      background: rgba(219, 11, 11, 0.08);
    `}
`;
const ProgressWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  border-radius: 0 36px 36px 0;
  z-index: -1;
`;
const Icons = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
const FileIconDiv = styled.div`
  position: relative;
  .file-text {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 50%;
    font-weight: 700;
    color: var(--text-secondary);
  }
`;

const FileType = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
  ${Typography.body_sm_semibold}
  transform: translate(-46%, -30%);
  color: var(--text-primary);
`;

const GithubPRSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 20px;
`;
const ButtonIcon = styled.div`
  padding: 4px;
  border-radius: 12px;
  display: flex;
  :hover {
    background-color: var(--neutral-secondary);
    input {
      filter: brightness(var(--brightness-value));
      background-color: transparent;
    }
  }
`;
const LinkSection = styled.div`
  border-bottom: 0.5px solid var(--border-primary);
  padding-bottom: 20px;
`;
const LinkCard = styled.div`
  padding: 10px;
  background-color: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  margin-top: 8px;
`;
const Content = styled.div`
  display: flex;
  gap: 8px;
`;
const SocialIcon = styled.div`
  width: 20px;
  height: 20px;
  .copy-link-icon {
    path {
      stroke: var(--text-primary);
    }
  }
`;
const LinkText = styled.p`
  span {
    ${Typography.body_md_medium};
    color: var(--text-primary);
    margin-right: 12px;
  }
  margin: 0;
  ${Typography.body_md}
  color: var(--text-secondary);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
`;

const LinkRightContent = styled.div`
  display: flex;
  max-width: 180px;
  width: 100%;
  justify-content: end;
`;
const TimeText = styled.p`
  margin: 0;
  ${Typography.body_md};
  color: var(--text-secondary);
`;
const LinkOpenIcon = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 4px;
  padding: 3px;
  display: flex;
  margin-left: 12px;
  margin-right: 6px;
  cursor: pointer;
  svg {
    path {
      fill: var(--text-secondary);
    }
  }
  :hover {
    background-color: var(--neutral-secondary);
    svg {
      path {
        fill: var(--text-primary);
      }
    }
  }
`;
const LinkMoreOption = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 4px;
  padding: 3px;
  display: flex;
  cursor: pointer;
  svg {
    path {
      fill: var(--text-secondary);
    }
  }
  :hover {
    background-color: var(--neutral-secondary);
    svg {
      path {
        fill: var(--text-primary);
      }
    }
  }
`;
const LinkTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  margin-top: 20px;
  p {
    ${Typography.body_md_medium};
    color: var(--text-secondary);
    margin: 0;
  }
`;
const AddLinkIcon = styled.div`
  cursor: pointer;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  .plus-icon {
    stroke: var(--text-secondary);
  }
  :hover {
    background-color: var(--neutral-secondary);
    .plus-icon {
      stroke: var(--text-primary);
    }
  }
`;

const TooltipContent = styled.div`
  padding: 4px 8px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  gap: 2px;
`;

export {
  Group,
  LeftBar,
  Header,
  HeaderBlock,
  HeaderTitle,
  HeaderContent,
  IconPoint,
  Text,
  Activity,
  ActivityTitle,
  ItemBlock,
  ItemChange,
  SubTitle,
  ActivityContentView,
  ActivitySubContent,
  ButtonBlock,
  LeftBarView,
  RightBar,
  Listening,
  RightItemBlock,
  RightSubTitle,
  Assigngroup,
  TimeLog,
  TimerHead,
  TimeLogTitle,
  TimeLogContent,
  Taskarea,
  TextBlock,
  ActivityView,
  FormBlock,
  InputTitle,
  AvatarImage,
  RightAssign,
  RightItemBlocks,
  Form,
  Projectname,
  OverLayDiv,
  TaskDetailContainer,
  TaskContainer,
  RightSection,
  ReactionDiv,
  Portion,
  ReactionShow,
  Undomessage,
  FileSection,
  Action,
  Record,
  Timer,
  Reactbox,
  Collpasetext,
  Reactiondiv,
  Time,
  Addsubtask,
  Subtask,
  Taskitem,
  Form1,
  Inputvalues,
  Label,
  Right,
  Duedate,
  Detailtask,
  Subtasktitle,
  Maintask,
  Form2,
  TaskLabel,
  Taskcount,
  Editicon,
  Div,
  Div2,
  Borderdiv,
  Divsubtask,
  Statusicon,
  Replyicon,
  Svgfile,
  SubTaskList,
  Multiplelabel,
  Droplabel,
  Dropblock,
  Doticon,
  SubTaskSection,
  AddSubTaskSection,
  SubtaskHeadLeft,
  SubtaskHeadRight,
  TaskIndicatorText,
  TaskIndicator,
  AddsubtaskIcon,
  SubText,
  BackIcon,
  Iconhidden,
  Githubicon,
  GithubMainDiv,
  Githubsmallicon,
  Bottomcontent,
  Texts,
  Dot,
  GithubTitle,
  Labelicon,
  Mergedlabel,
  MergeText,
  TaskAreaFile,
  StartButton,
  ProgressBar,
  ProgressWrapper,
  Icons,
  FileIconDiv,
  FileType,
  GithubPRSection,
  ButtonIcon,
  LinkSection,
  LinkCard,
  Content,
  SocialIcon,
  LinkText,
  LinkRightContent,
  LinkOpenIcon,
  TimeText,
  LinkMoreOption,
  AddLinkIcon,
  LinkTitle,
  TooltipContent,
  Timelog
};
