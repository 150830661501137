import { useCallback, useEffect, useMemo, useState } from 'react';
import { BottomDiv, Header, Heading, Inputarea, List, MainDiv, Status, SVGDiv, Task, TaskHeading } from './style';
import SVGIcon from '../../assets/images/svg/SVGIcon';
import { StatusListInterface } from '../../interfaces/ProjectInterface';
import { isEmpty, renderStatusIconName } from '../../helpers/common';
import { getTasksList, updateTaskDetails } from '../../services/taskServices';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';
import { TaskDetailsInterface } from '../../interfaces/TaskInterface';
import { DropdownButton } from '../Dropdown';

interface Props {
  defaultStatus: StatusListInterface | undefined;
  statusList: StatusListInterface[];
  onClose?: () => void;
  currentProjectId?: string;
  currentTaskId?: string;
  loadParentTaskData: () => void;
}

const SubtaskPopup = (props: Props) => {
  const { statusList, defaultStatus, onClose, currentProjectId, currentTaskId, loadParentTaskData } = props;
  //State
  const [searchText, setSearchText] = useState('');
  const [filteredSubTaskList, setFilteredSubTaskList] = useState<TaskDetailsInterface[]>([]);
  //use selector state variables
  const taskSelector = useSelector((state: RootReducerInterface) => state.task);
  const { tasksList } = taskSelector;
  //Other variable
  const dispatch = useDispatch();

  const loadData = useCallback(async () => {
    const result = await dispatch(getTasksList(currentProjectId, true, false));
    // Filter tasks that are not completed, have no sub-tasks, and match the currentTaskId
    const uncompletedTask = result.filter(
      (task: TaskDetailsInterface) => !task.status && !task.subTasks && task.id !== currentTaskId
    );
    // Sort tasks by createdOn date
    const sortedTasks = uncompletedTask?.toSorted((a: TaskDetailsInterface, b: TaskDetailsInterface) => {
      const dateA = new Date(a?.createdOn).getTime();
      const dateB = new Date(b?.createdOn).getTime();
      return dateB - dateA;
    });
    setFilteredSubTaskList(sortedTasks);
  }, [currentProjectId, currentTaskId, dispatch]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onSearchValue = useCallback(
    (searchText: string) => {
      if (!isEmpty(searchText)) {
        const searchNewText = searchText.trim().toLowerCase();
        const words = searchNewText.split(' ').filter((word) => word.length > 0);
        const filteredtask = filteredSubTaskList.filter((item: TaskDetailsInterface) => {
          const title = item?.name.trim().toLowerCase() || '';
          return words.some((word) => title.includes(word));
        });
        setFilteredSubTaskList(filteredtask);
      } else {
        const uncompletedTaskList = tasksList?.filter(
          (task) => !task.status && !task.subTasks && task.id !== currentTaskId
        );
        setFilteredSubTaskList(uncompletedTaskList);
      }
    },
    [currentTaskId, filteredSubTaskList, tasksList]
  );

  const handleChange = useCallback(
    (value: string) => {
      setSearchText(value);
      setTimeout(() => {
        onSearchValue(value);
      }, 300);
    },
    [onSearchValue]
  );

  const handleTaskClick = useCallback(
    async (taskId: string, projectId: string) => {
      try {
        const payload = {
          parentTaskId: currentTaskId,
          projectId: projectId
        };
        await dispatch(updateTaskDetails(taskId, payload));
        if (onClose) onClose();
        loadParentTaskData();
      } catch (error: any) {
        console.log('error: ', error);
      }
    },
    [currentTaskId, dispatch, loadParentTaskData, onClose]
  );

  const renderSubtaskList = useMemo(() => {
    if (isEmpty(filteredSubTaskList)) return null;
    return filteredSubTaskList.map((item: TaskDetailsInterface) => {
      const currentStatus = statusList?.find((x) => x['_id'] === item.statusId) || defaultStatus || statusList?.[0];
      return (
        <Task key={item?.id} onClick={() => handleTaskClick(item?.id, item?.projectId)}>
          <Status>
            <DropdownButton iconName={renderStatusIconName(currentStatus?.Type)} variant={'no-border'} />
          </Status>
          <TaskHeading>{item?.name}</TaskHeading>
        </Task>
      );
    });
  }, [defaultStatus, filteredSubTaskList, handleTaskClick, statusList]);
  return (
    <>
      <MainDiv>
        <Header>
          <Inputarea
            type='text'
            placeholder='Search for task to add as a sub-task...'
            autoFocus={true}
            value={searchText}
            onChange={(e) => handleChange(e.target.value)}
          />
          <SVGDiv onClick={onClose}>
            <SVGIcon name='close-icon' width='18px' height='18px' viewBox='0 0 18 18' className='close-icon' />
          </SVGDiv>
        </Header>
        <BottomDiv>
          <Heading>Recent</Heading>
          <List>{renderSubtaskList}</List>
        </BottomDiv>
      </MainDiv>
    </>
  );
};

export default SubtaskPopup;
