import { combineReducers } from 'redux';
import { createReducer } from '../helpers/reduxHelpers';
import * as Actions from '../actions/types';
import { current, PayloadAction, createReducer as createReducerOrig } from '@reduxjs/toolkit';
import { isEmpty } from '../helpers/common';
import { ReducerInterface } from '../interfaces/ReducerInterface';
import { TaskFilterInterface, TaskInterfaceDetails } from '../interfaces/TaskInterface';
import {
  SHOW_COMPLETED_TASK_BY_DATE,
  TASK_LIST_TYPE,
  SHOW_TASK_BY_ORDER,
  SHOW_TASK_GROUPING_TYPE
} from '../global/constants';

const setTaskLoaderReducer = createReducer({
  initialState: false,
  actionType: Actions.SET_TASK_LOADER
});

const setTimelogDataReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_TIMELOG_DATA
});

const tasksListReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_TASKS_LIST
});

const taskGroupsReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_TASKGROUP_LIST
});

const showTaskStatusReducer = createReducer({
  initialState: '',
  actionType: Actions.SET_SHOW_TASK_FILTER
});

const groupingTaskNameReducer = createReducer({
  initialState: '',
  actionType: Actions.SET_TASKS_FILTER_DATA
});

const initialFilteredState: Array<any> = [];
const filteredTasksListReducer = createReducerOrig(initialFilteredState, (builder) => {
  builder
    .addCase(Actions.SET_FILTERED_TASK_LIST, (state = initialFilteredState, action: PayloadAction<any, never>) => {
      return [...(action.payload || [])];
    })
    .addCase(Actions.UPDATE_FILTERED_TASK_LIST, (state, action: PayloadAction<any, never>) => {
      let task = JSON.parse(JSON.stringify(current(state)));
      task = task?.map((item: any) => {
        if (!isEmpty(item?.id)) {
          return item?.id === action?.payload?.id ? action?.payload : item;
        } else {
          const id = item?.tasks?.map((ele: any) => ele?.groupId);
          return id === action?.payload?.id ? action?.payload : item;
        }
      });
      return task;
    })
    .addCase(
      Actions.UPDATE_TASK_FIELD,
      (state, action: PayloadAction<{ taskId: string; key: string; value: any }, never>) => {
        return state?.map((group) => {
          if (group?.tasks) {
            const updatedTasks = group.tasks?.map((task: any) => {
              if (task.id === action.payload.taskId) {
                return { ...(task || {}), [action.payload.key]: action.payload.value };
              }
              return task;
            });
            return { ...(group || {}), tasks: updatedTasks };
          }
          return group;
        });
      }
    )
    .addCase(Actions.CLEAR_FILTERED_TASK_LIST, () => {
      const project = JSON.parse(JSON.stringify(initialFilteredState));
      return project;
    });
});

const initialState: any = [];
const setTaskListReducer = createReducerOrig(initialState, (builder) => {
  builder
    .addCase(Actions.SET_MY_TASK_DATA, (state = initialState, action: PayloadAction<TaskInterfaceDetails[], never>) => {
      return [...(action.payload || [])];
    })
    .addCase(Actions.UPDATE_MY_TASK_DATA, (state, action: PayloadAction<{ index: number; value: any }, never>) => {
      const tasks = JSON.parse(JSON.stringify(current(state)));
      tasks[action.payload.index] = action.payload.value;
      return tasks;
    })
    .addCase(
      Actions.UPDATE_MY_TASK_FIELD,
      (state, action: PayloadAction<{ taskId: string; key: string; value: any }, never>) => {
        return state.map((group: any) => {
          if (group?.tasks) {
            const updatedTasks = group?.tasks?.map((task: any) => {
              if (task?.id === action.payload.taskId) {
                return { ...(task || {}), [action.payload.key]: action.payload.value };
              }
              return task;
            });
            return { ...(group || {}), tasks: updatedTasks || [] };
          }
          return group;
        });
      }
    )
    .addCase(Actions.CLEAR_MY_TASK_DATA, () => {
      const project = JSON.parse(JSON.stringify(initialState));
      return project;
    });
});

const taskData = {};
const setMyTaskDetailsDataReducer = createReducerOrig(taskData, (builder) => {
  builder
    .addCase(Actions.SET_MY_TASK_DETAILS_DATA, (state = taskData, action: PayloadAction<any, never>) => {
      return { ...(action.payload || {}) };
    })
    .addCase(Actions.UPDATE_MY_TASK_DETAILS_DATA, (state, action: PayloadAction<ReducerInterface, never>) => {
      const project = JSON.parse(JSON.stringify(current(state)));
      project[action.payload.propsName] = action.payload.value;
      return project;
    })
    .addCase(Actions.UPDATE_TASK_COMMENT, (state, action: PayloadAction<{ commentId: string; value: any }, never>) => {
      const taskDetails = JSON.parse(JSON.stringify(current(state)));
      const updatedComments = taskDetails.updatedComments?.map((x: any) =>
        x.id === action.payload.commentId ? { ...x, ...action.payload.value } : x
      );
      taskDetails.updatedComments = updatedComments;
      return taskDetails;
    })
    .addCase(Actions.CLEAR_MY_TASK_DETAILS_DATA, () => {
      const task = JSON.parse(JSON.stringify(taskData));
      return task;
    });
});

const creaateTaskInput = {
  description: '',
  name: '',
  groupId: {},
  projectId: {},
  status: false,
  priority: 0,
  users: [],
  parentTaskId: '',
  estimate: undefined,
  labels: [],
  start: null,
  recurringInterval: null
};
const setTaskDetailsInputReducer = createReducerOrig(creaateTaskInput, (builder) => {
  builder
    .addCase(Actions.SET_CREATE_TASK_INPUT, (state = creaateTaskInput, action: PayloadAction<any, never>) => {
      return { ...(action.payload || {}) };
    })
    .addCase(Actions.UPDATE_CREATE_TASK_INPUT, (state, action: PayloadAction<ReducerInterface, never>) => {
      const input = JSON.parse(JSON.stringify(current(state)));
      input[action.payload.propsName] = action.payload.value;
      return input;
    })
    .addCase(Actions.CLEAR_CREATE_TASK_INPUT, () => {
      const input = JSON.parse(JSON.stringify(creaateTaskInput));
      return input;
    });
});

const memberTaskinitialState: any = [];
const setMemberTaskListReducer = createReducerOrig(memberTaskinitialState, (builder) => {
  builder
    .addCase(Actions.SET_MEMBER_TASK_DATA, (state = memberTaskinitialState, action: PayloadAction<any, never>) => {
      return [...(action.payload || [])];
    })
    .addCase(Actions.UPDATE_MEMBER_TASK_DATA, (state, action: PayloadAction<{ index: number; value: any }, never>) => {
      const tasks = JSON.parse(JSON.stringify(current(state)));
      tasks[action.payload.index] = action.payload.value;
      return tasks;
    })
    .addCase(
      Actions.UPDATE_MEMBER_TASK_FIELD,
      (state, action: PayloadAction<{ taskId: string; key: string; value: any }, never>) => {
        return state.map((group: any) => {
          if (group?.tasks) {
            const updatedTasks = group?.tasks?.map((task: any) => {
              if (task?.id === action.payload.taskId) {
                return { ...(task || {}), [action.payload.key]: action.payload.value };
              }
              return task;
            });
            return { ...(group || {}), tasks: updatedTasks || [] };
          }
          return group;
        });
      }
    )
    .addCase(Actions.CLEAR_MEMBER_TASK_DATA, () => {
      const project = JSON.parse(JSON.stringify(memberTaskinitialState));
      return project;
    });
});

const initialSubTaskListState: Array<any> = [];
const subTaskListReducer = createReducerOrig(initialSubTaskListState, (builder) => {
  builder
    .addCase(Actions.SET_SUBTASK_LIST, (state = initialSubTaskListState, action: PayloadAction<any, never>) => {
      return [...(action.payload || [])];
    })
    .addCase(Actions.UPDATE_SUBTASK_LIST, (state, action: PayloadAction<any, never>) => {
      let task = JSON.parse(JSON.stringify(current(state)));
      task = task?.map((item: { id: string }) => {
        return item?.id === action?.payload?.id ? action?.payload : item;
      });
      return task;
    })
    .addCase(Actions.CLEAR_SUBTASK_LIST, () => {
      const project = JSON.parse(JSON.stringify(initialSubTaskListState));
      return project;
    });
});

const selectedGroupListReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_PROJECT_TASKGROUP_LIST
});

const overDuetasksReducer = createReducer({
  initialState: 0,
  actionType: Actions.SET_OVERDUE_TASKS
});

const setIsTaskFilterOpenReducer = createReducer({
  initialState: false,
  actionType: Actions.SET_IS_TASK_FILTER_OPEN
});

const initialTaskFilterState: TaskFilterInterface = {
  showSubTask: false,
  showCompletedTaskByFilter: SHOW_COMPLETED_TASK_BY_DATE.LAST_MONTH,
  showRecentlyCompletedTask: true,
  showEmptyGroup: true,
  taskViewType: TASK_LIST_TYPE.TASKS,
  groupingStatus: SHOW_TASK_GROUPING_TYPE.DEFAULT,
  groupName: '',
  orderByName: '',
  orderByStatus: SHOW_TASK_BY_ORDER.DUE_DATE
};

const setTaskFilterReducer = createReducerOrig(initialTaskFilterState, (builder) => {
  builder
    .addCase(Actions.SET_TASK_FILTER, (state = initialTaskFilterState, action: PayloadAction<any, never>) => {
      return { ...(action.payload || {}) };
    })
    .addCase(Actions.UPDATE_TASK_FILTER, (state, action: PayloadAction<ReducerInterface, never>) => {
      const project = JSON.parse(JSON.stringify(current(state)));
      project[action.payload.propsName] = action.payload.value;
      return project;
    })
    .addCase(Actions.CLEAR_TASK_FILTER, () => {
      const task = JSON.parse(JSON.stringify(initialTaskFilterState));
      return task;
    });
});

const otherTaskListReducer = createReducer({
  initialState: { tasks: [], completedTasks: [] },
  actionType: Actions.SET_OTHER_TASK_LIST
});

const taskReducer = combineReducers({
  loading: setTaskLoaderReducer,
  myTask: setTaskListReducer,
  taskDetails: setMyTaskDetailsDataReducer,
  timelogData: setTimelogDataReducer,
  createTaskInput: setTaskDetailsInputReducer,
  taskGroups: taskGroupsReducer,
  tasksList: tasksListReducer,
  filteredTasksList: filteredTasksListReducer,
  showTaskStatus: showTaskStatusReducer,
  membersTask: setMemberTaskListReducer,
  tasksName: groupingTaskNameReducer,
  selectedGroupList: selectedGroupListReducer,
  overDuetasks: overDuetasksReducer,
  subTaskList: subTaskListReducer,
  isTaskFilterOpen: setIsTaskFilterOpenReducer,
  taskFilter: setTaskFilterReducer,
  otherTask: otherTaskListReducer
});

export default taskReducer;
