import styled, { css } from 'styled-components';
import { Typography } from '../../../global/theme';

interface Props {
  isActive?: boolean;
  istrackingActivity?: boolean;
}

const MainMenu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
  @media (max-width: 449px) {
    padding: 0 12px;
    background-color: var(--background-primary);
    gap: 3px;
  }
`;

const Menu = styled.div`
  position: relative;
  width: 100%;
  max-width: 190px;
  padding: 7px 20px 7px 0;
  display: inline-flex;
  gap: 12px;
  justify-content: space-between;
  cursor: pointer;
  &:hover {
    background: var(--background-secondary);
  }
  @media (max-width: 449px) {
    height: auto;
    padding: 11px 12px 11px 8px;
    width: 100%;
    max-width: -webkit-fill-available;
    justify-content: space-between;
    border-radius: 4px;
  }
`;

const Left = styled.div<Props>`
  margin-left: 20px;
  ${Typography.body_md_medium};
  color: var(--text-primary);
  display: inline-flex;
  align-items: center;
  gap: 12px;
  .fill-color {
    fill: var(--text-primary);
  }
  .stroke-color {
    stroke: var(--text-primary);
  }
  span {
    width: 106px;
    @media (max-width: 449px) {
      width: auto;
      white-space: nowrap;
    }
  }
  .pointer {
    visibility: hidden;
  }
  ${(props: Props) =>
    props.isActive &&
    css`
      .pointer {
        visibility: visible;
      }
    `}
  @media (max-width: 449px) {
    margin-left: 0;
    gap: 8px;
    svg {
      width: 18px;
    }
    span {
      width: auto;
      white-space: nowrap;
    }
    .fill-color {
      width: 20px;
      height: 20px;
    }
    .stroke-color {
      width: 20px;
      height: 20px;
    }
    span {
      ${Typography.body_xl_medium};
    }
  }
`;

const Right = styled.div`
  display: inline-flex;
  align-items: center;
  width: 24px;
  ${Typography.body_sm};
  color: var(--text-secondary);
  justify-content: center;
  @media (max-width: 449px) {
    width: auto;
    color: var(--text-primary);
  }
`;

const ProjectsName = styled.div<Props>`
  margin-top: 20px;
  margin-bottom: 70px;
  ${(props: Props) =>
    props.istrackingActivity &&
    css`
      margin-bottom: 140px;
    `}
  @media (max-width: 449px) {
    padding: 24px 12px 0 20px;
    margin: 0;
    background: var(--background-primary);

    p {
      margin: 0;
      padding-bottom: 0;
      &.project {
        padding-bottom: 10px;
      }
    }
  }
`;
const ProjectTitle = styled.div`
  padding: 0 0 10px 20px;
  ${Typography.body_md_medium};
  color: var(--text-secondary);
  @media (max-width: 449px) {
    padding: 0 0 4px 0px;
  }
`;
const Viewer = styled.div`
  cursor: pointer;
  margin-left: 12px;
  display: flex;
  gap: 16px;
  align-items: center;
  ${Typography.body_md};
  padding: 7px 8px;
  color: var(--text-primary);
  @media (max-width: 449px) {
    padding: 8px;
    gap: 8px;
    margin-left: 0;
    padding: 11px 8px 11px 0;
  }
`;

const Timer = styled.div`
  width: 186px;
  padding: 12px 12px 0;
  z-index: 999;
  background-color: var(--background-primary);

  @media (max-width: 449px) {
    width: 100%;
    padding: 0;
  }
`;

const Action = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;
  gap: 4px;
  width: auto;
  align-items: center;
  span {
    ${Typography.body_md};
    color: var(--text-primary);
    width: 90px;
  }
  .time-icon {
    path {
      stroke: var(--text-primary);
    }
  }

  @media (max-width: 449px) {
    span {
      width: auto;
    }
  }
`;

const Record = styled.div`
  width: 24px;
  height: 24px;
  border: 1px solid var(--border-primary);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  background-color: var(--background-primary);
  cursor: pointer;
  svg {
    margin: 8px 0 6px 8px;
  }
  .record-icon {
    rect {
      fill: var(--text-secondary);
    }
  }
`;

const Icon = styled.div`
  width: 18px;
  height: 18px;
  .dot-icon {
    path {
      stroke: var(--text-primary);
    }
  }
  @media (max-width: 449px) {
    .dot-icon {
      width: 20px;
      height: 20px;
      path {
        stroke: var(--text-secondary);
      }
    }
  }
`;

const IconView = styled.div`
  position: absolute;
  top: 8px;
  left: -2px;
  @media (max-width: 449px) {
    left: 0;
    top: 12px;
  }
`;

const Content = styled.div``;

const BottomSection = styled.div`
  width: 210px;
  position: fixed;
  bottom: 0;
  background-color: var(--background-primary);
  padding-bottom: 6px;
  @media (max-width: 449px) {
    width: auto;
    max-width: 100%;
    padding-bottom: 0;
    padding: 20px;
    margin-bottom: 64px;
    background-color: transparent;
  }
`;
const Check = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  width: 18px;
  height: 18px;

  .checkbox-round {
    width: 12px;
    height: 12px;
    border-radius: 9px;
    vertical-align: middle;
    border: 1px solid var(--border-secondary);
    appearance: none;
    outline: none;
    cursor: pointer;
    margin: 0;
    padding: 7px;
    background-color: var(--background-primary);
  }
  input[type='checkbox'] {
    position: relative;
    cursor: pointer;
    opacity: 0.5;
  }
  input[type='checkbox']:checked {
    background-color: var(--brand-primary);
    opacity: 1;
    border: 1px solid var(--brand-primary);
  }
  input[type='checkbox']:checked::before {
    content: '';
    position: absolute;
    right: 50%;
    top: 50%;
    width: 3px;
    height: 8px;
    border: solid var(--text-white);
    border-width: 0 2px 2px 0;
    margin: -0.5px -1px 0 -1px;
    transform: rotate(45deg) translate(-50%, -50%) scale(0.7);
    z-index: 2;
  }
`;
const Inputvalue = styled.input``;
const Box = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid transparent;
  cursor: pointer;
  .top-section {
    display: none;
  }
  :hover {
    border: 0.5px solid var(--border-primary);
    border-radius: 8px;
    background: var(--background-tertiary);
    box-shadow: var(--shadow-card);
    .top-section {
      display: flex;
    }
  }
  @media (max-width: 449px) {
    width: 100%;
    border: 0.5px solid var(--border-primary);
    border-radius: 8px;
    background: var(--background-tertiary);
    box-shadow: var(--shadow-card);
    .top-section {
      display: flex;
    }
  }
`;

const Top = styled.div`
  display: flex;
  gap: 4px;
  padding: 6px 8px 4px;
  cursor: pointer;
  :hover {
    background-color: var(--neutral-secondary);
    border-radius: 8px 8px 0 0;
  }
  p {
    color: var(--text-primary);
    margin: 0;
    ${Typography.body_sm_medium}
  }
`;

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 6px 8px;
  align-items: center;
`;

const Recoredtime = styled.div`
  width: 100%;
  max-width: 178px;
  margin-left: 12px;
  border-radius: 8px;
  border: 0.5px solid var(--border-primary);
  background: var(--background-tertiary);
  box-shadow: var(--shadow-card);
  &.copyclip {
    width: 100%;
    max-width: 178px;
    margin: 0 20px 0 12px;
  }
  @media (max-width: 449px) {
    max-width: 190px;
    &.copyclip {
      max-width: 190px;
      margin: 0 14px 0 14px;
    }
  }
`;
const Showtime = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  padding: 8px;
  border-bottom: 1px solid var(--border-primary);

  .record-icon {
    margin-right: 4px;
    rect {
      fill: var(--text-secondary);
    }
  }
  .clip-icon {
    margin-right: 4px;
    stroke: var(--text-primary);
  }
  .close-icon {
    cursor: pointer;
    stroke: var(--text-secondary);
    :hover {
      background-color: var(--neutral-secondary);
      border-radius: 4px;
    }
  }

  p {
    color: var(--text-primary);
    ${Typography.body_md_medium}
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const Time = styled.div`
  color: var(--text-primary);
  ${Typography.body_md}
`;

const Stopbutton = styled.div`
  display: flex;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  color: var(--text-primary);
  ${Typography.body_md_medium}
  :hover {
    cursor: pointer;
    background-color: var(--neutral-secondary);
    border-radius: 0 0 8px 8px;
  }
`;

const Recordtext = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  .record-icon {
    stroke: var(--text-secondary);
    fill: var(--text-secondary);
    :hover {
      stroke: var(--text-primary);
      fill: var(--text-primary);
    }
  }
`;
const Plandiv = styled.div`
  padding: 6px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: 186px;
  width: 100%;
  @media (max-width: 449px) {
    max-width: 196px;
    margin-top: 0px;
    padding: 0;
  }
  .button {
    width: 100%;
    svg {
      path {
        stroke: var(--text-primary);
      }
    }
  }
`;
const Dropdownbutton = styled.div`
  width: 24px;
  height: 24px;
  border: 0.5px solid var(--border-primary);
  border-radius: 50%;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  background-color: var(--background-primary);
  cursor: pointer;

  &:hover {
    background-color: var(--background-secondary);
  }
  .stroke-color {
    padding: 4px;
    path {
      stroke: var(--text-primary);
    }
  }
  @media (max-width: 449px) {
    width: 30px;
    height: 30px;
    .stroke-color {
      width: 18px;
      height: 18px;
      padding: 6px;
    }
  }
`;

const ViewDropdown = styled.div`
  .menustyle {
    display: flex;
    flex-direction: column;
    padding: 6px 0;
    background: var(--background-primary);
    border: 1px solid var(--border-primary);
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    margin: 0px;
    width: 212px;
    .menuitem {
      margin: 0;
      :hover {
        background-color: var(--neutral-secondary);
        border-radius: 0;
      }
    }
    @media (max-width: 449px) {
      width: 196px;
    }
  }
`;

const View = styled.div`
  cursor: pointer;
  padding: 6px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  .stroke-color {
    path {
      stroke: var(--text-secondary);
    }
  }
`;
const Text = styled.div`
  ${Typography.body_md}
  color: var(--text-secondary);
  margin: 0px;
  line-height: 16px;
  padding: 6px 12px;
  @media (max-width: 449px) {
    padding: 6px 22px;
  }
`;
const Subview = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  p {
    ${Typography.body_md}
    color: var(--text-primary);
    margin: 0px;
    line-height: 16px;
  }
  .fill-color {
    width: 16px;
    height: 16px;
    path {
      fill: var(--text-primary);
    }
  }
  .stroke-color {
    width: 16px;
    height: 16px;
    path {
      stroke: var(--text-primary);
    }
    rect {
      stroke: var(--text-primary);
    }
  }
`;
const ProjectItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
  @media (max-width: 449px) {
    gap: 3px;
    margin-bottom: 120px;
  }
`;
export {
  MainMenu,
  Menu,
  Left,
  Right,
  ProjectsName,
  Icon,
  Viewer,
  Timer,
  Record,
  Action,
  IconView,
  BottomSection,
  Check,
  Inputvalue,
  Box,
  Top,
  Bottom,
  Stopbutton,
  Recoredtime,
  Showtime,
  Time,
  Recordtext,
  Plandiv,
  Dropdownbutton,
  ViewDropdown,
  View,
  Text,
  Subview,
  ProjectTitle,
  ProjectItems,
  Content
};
