import React from 'react';
import { Firstline, Leftdiv, Line, Main, Profile, Rightdiv, Row, Secondline, Square } from './styles';

export default function Teamsloading() {
  return (
    <>
      <Main>
        <Row>
          <Leftdiv>
            <Profile className='loading-animation' />
            <Line>
              <Firstline className='loading-animation' />
              <Secondline className='loading-animation' />
            </Line>
          </Leftdiv>
          <Rightdiv>
            <Square className='loading-animation' />
          </Rightdiv>
        </Row>
        <Row>
          <Leftdiv>
            <Profile className='loading-animation' />
            <Line>
              <Firstline className='loading-animation' />
              <Secondline className='loading-animation' />
            </Line>
          </Leftdiv>
          <Rightdiv>
            <Square className='loading-animation' />
          </Rightdiv>
        </Row>
        <Row>
          <Leftdiv>
            <Profile className='loading-animation' />
            <Line>
              <Firstline className='loading-animation' />
              <Secondline className='loading-animation' />
            </Line>
          </Leftdiv>
          <Rightdiv>
            <Square className='loading-animation' />
          </Rightdiv>
        </Row>
        <Row>
          <Leftdiv>
            <Profile className='loading-animation' />
            <Line>
              <Firstline className='loading-animation' />
              <Secondline className='loading-animation' />
            </Line>
          </Leftdiv>
          <Rightdiv>
            <Square className='loading-animation' />
          </Rightdiv>
        </Row>
        <Row>
          <Leftdiv>
            <Profile className='loading-animation' />
            <Line>
              <Firstline className='loading-animation' />
              <Secondline className='loading-animation' />
            </Line>
          </Leftdiv>
          <Rightdiv>
            <Square className='loading-animation' />
          </Rightdiv>
        </Row>
      </Main>
    </>
  );
}
