import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { clearWorkspaceInputDetail } from '../../actions/workspaceActions';
import OnboardingStep1 from './onboardingStep1';
import OnboardingStep2 from './onboardingStep2';

const Onborading: React.FC = () => {
  // States
  const [currentStep, setCurrentStep] = useState(1);
  // Other variables
  const dispatch = useDispatch();

  const gotoStep2 = useCallback(() => {
    setCurrentStep(2);
  }, []);

  useEffect(() => {
    return () => {
      dispatch(clearWorkspaceInputDetail());
    };
  }, [dispatch]);

  return (
    <>
      {currentStep === 1 && <OnboardingStep1 gotoNext={gotoStep2} />}
      {currentStep === 2 && <OnboardingStep2 />}
    </>
  );
};

export default Onborading;
