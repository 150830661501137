import UserPreferenceSingleton from '../helpers/userPreferenceSingleton';
import LocalDbService, { DRAFT_MESSAGES, UPDATE_ON_ID } from './localDbService';

let singleton: any;
let organizationId: any;

export default class DraftMessageCacheService extends LocalDbService {
  constructor(props: any) {
    const db_name = `${props.organizationId}`;
    props.dbName = db_name;
    props.tableName = DRAFT_MESSAGES;
    super(props);
  }

  static getInstance() {
    const company = UserPreferenceSingleton.getInstance().getWorkspace();
    if (company) {
      if (!singleton || organizationId !== company?.id) {
        organizationId = company?.id;
        singleton = new DraftMessageCacheService({ organizationId: company?.id });
      }
    }
    return singleton;
  }

  static removeInstance() {
    if (singleton) singleton.closeDb();
    organizationId = undefined;
    singleton = undefined;
  }

  /**
   * Retrieves a draft message by its unique identifier
   * @param messageId - Unique identifier of the draft message
   */
  async getCurrentDraftMessage(messageId: string) {
    if (!messageId) return null;
    return await this.getItem(messageId);
  }

  /**
   * Removes a draft message from storage
   * @param messageId - Unique identifier of the draft message
   */
  async removeDraftMessage(messageId: string) {
    if (!messageId) return false;
    await this.removeBulk([messageId]); // Use array since removeBulk expects a list
    return true;
  }

  async getLastUpdatedTime(workspace_id?: string) {
    const result = await super.getLastUpdatedTime(`${UPDATE_ON_ID.DRAFT_MESSAGE}_${workspace_id}`);
    return result;
  }

  async setLastUpdatedTime(value: any, companyId: string) {
    if (companyId !== organizationId) return false;
    const result = await super.setLastUpdatedTime(`${UPDATE_ON_ID.DRAFT_MESSAGE}_${companyId}`, value);
    return result;
  }
}
