import React, { useCallback, useState } from 'react';
import {
  Buttons,
  Content,
  Icon,
  LastIcon,
  Left,
  Maincontent,
  ProjectTitle,
  Projectname,
  Right,
  Taskbutton
} from './styles';
import SVGIcon from '../../assets/images/svg/SVGIcon';
import Button from '../button';
import { useHistory } from 'react-router-dom';
import ModalCustom from '../models/modal';
import CreateTaskModal from '../models/createTaskModal';
import { useSelector, useDispatch } from 'react-redux';
import { saveProjectTemplateDetails } from '../../services/settingServices';
import { setProjectTaskGroupsList, updateCreateTaskInput } from '../../actions/taskActions';
import { trackAnalyticActivity } from '../../services/analyticsService';
import { updateProjectTemplateDetails } from '../../actions/settingActions';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';
import { PROJECT_ANALYTICS } from '../../global/analyticsConstants';

interface Props {
  isSettingpage?: boolean;
  loading?: boolean;
}
export default function TemplateHeader(props: Props) {
  const { isSettingpage = false, loading } = props;
  //States
  const [isModalOpen, setIsModalOpen] = useState(false);
  //use selector state variables
  const settingsSelector = useSelector((state: RootReducerInterface) => state.settings);
  const { projectTemplateDetails, templateTaskGroups, loading: settingLoading } = settingsSelector;
  //Other variable
  const dispatch = useDispatch();
  const history = useHistory();

  const handleOnClick = useCallback(async () => {
    dispatch(updateCreateTaskInput({ propsName: 'groupId', value: templateTaskGroups[0] }));
    dispatch(updateCreateTaskInput({ propsName: 'projectId', value: projectTemplateDetails }));
    dispatch(updateCreateTaskInput({ propsName: 'priority', value: projectTemplateDetails?.priority?.default || 0 }));
    dispatch(setProjectTaskGroupsList(templateTaskGroups));
    setIsModalOpen(true);
  }, [dispatch, projectTemplateDetails, templateTaskGroups]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const onClickTasks = useCallback(() => {
    if (projectTemplateDetails?.['_id']) history.push(`/settings/templates/${projectTemplateDetails?.['_id']}`);
  }, [history, projectTemplateDetails]);

  const onClickSettings = useCallback(() => {
    if (projectTemplateDetails?.['_id'])
      history.push(`/settings/templates/${projectTemplateDetails?.['_id']}?tab=setting`);
  }, [history, projectTemplateDetails]);

  const onClickSave = useCallback(async () => {
    const cloneDetails = JSON.parse(JSON.stringify(projectTemplateDetails));
    delete cloneDetails?.users;
    delete cloneDetails?.defaultStatus;
    trackAnalyticActivity(PROJECT_ANALYTICS.TEMPLATE_DATA_UPDATED);
    await dispatch(saveProjectTemplateDetails(cloneDetails));
    dispatch(updateProjectTemplateDetails({ propsName: 'updated', value: false }));
  }, [dispatch, projectTemplateDetails]);

  return (
    <div>
      <Maincontent>
        <Content>
          <Left>
            <Projectname>
              <Icon onClick={() => history.push('/settings/templates')}>
                <SVGIcon
                  name='report-left-arrow-icon'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  className='arrow-icon'
                />
              </Icon>
              <ProjectTitle>{projectTemplateDetails?.name || ''}</ProjectTitle>
            </Projectname>
            <Buttons>
              <Taskbutton isActive={!isSettingpage} onClick={onClickTasks}>
                Tasks
              </Taskbutton>
              <Taskbutton isActive={isSettingpage} onClick={onClickSettings}>
                Settings
              </Taskbutton>
            </Buttons>
          </Left>
          <Right>
            {!isSettingpage && (
              <LastIcon disabled={loading || settingLoading} onClick={handleOnClick}>
                <SVGIcon name='task-plus-icon' width='32' height='33' viewBox='0 0 32 33' className='circle' />
              </LastIcon>
            )}
            <Button title='Save Template' onClick={onClickSave} disabled={loading || settingLoading} />
          </Right>
        </Content>
      </Maincontent>
      <ModalCustom open={isModalOpen} onClose={handleCloseModal}>
        <CreateTaskModal onCancel={handleCloseModal} isTemplateProject={true} />
      </ModalCustom>
    </div>
  );
}
