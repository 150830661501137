import {
  ExpandCloseSection,
  Icon,
  LogoIcon,
  MainHeaderContent,
  NavigateArea,
  UrlSection,
  UrlText,
  Window
} from './styles';
import SVGIcon from '../../assets/images/svg/SVGIcon';
import BackForwardIcon from './backForwardIcon';
import { useCallback } from 'react';
import copy from 'clipboard-copy';
import { setSuccessMessage } from '../../actions/messageActions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setIsNavbarShow } from '../../actions/appActions';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';

export default function WindowHeader() {
  // reducer state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { app: appSelector } = stateSelector || {};
  const { isNavbarShow } = appSelector || {};
  // Other variables
  const dispatch = useDispatch();
  const history = useHistory();

  const onClickRefresh = useCallback(() => {
    window.location.reload();
  }, []);

  const onClickCopyUrl = useCallback(async () => {
    const url = window.location.href;
    await copy(url);
    dispatch(setSuccessMessage('Page URL copied successfully!'));
  }, [dispatch]);

  const onClickMinimize = useCallback(() => {
    if (window && (window as any).electronApi) (window as any).electronApi.windowAction('minimize'); // close window
  }, []);

  const onClickFullScreen = useCallback(() => {
    if (window && (window as any).electronApi) (window as any).electronApi.windowAction('fullscreen'); // close window
    // window.electronApi.windowAction(‘exit-fullscreen‘) // exit fullscreen
  }, []);

  const onClickClose = useCallback(() => {
    if (window && (window as any).electronApi) (window as any).electronApi.windowAction('close'); // close window
  }, []);

  const onClickLogo = useCallback(() => {
    history.push('/');
  }, [history]);

  const onClickSideBarToddle = useCallback(
    (value: boolean) => {
      dispatch(setIsNavbarShow(value));
    },
    [dispatch]
  );

  return (
    <Window className='windowheader'>
      <NavigateArea>
        <LogoIcon onClick={onClickLogo}>
          <SVGIcon name='teamcamp-logo' width='24' height='24' viewBox='24' />
        </LogoIcon>
        <Icon onClick={() => onClickSideBarToddle(!isNavbarShow)}>
          <SVGIcon name='expand-sidebar-icon' width='16' height='16' viewBox='16' />
        </Icon>
      </NavigateArea>
      <MainHeaderContent>
        <BackForwardIcon />
        <UrlSection>
          <Icon onClick={onClickRefresh}>
            <SVGIcon name='refresh-icon' width='16' height='16' viewBox='16' />
          </Icon>
          <UrlText>Teamcamp.app</UrlText>
          <Icon onClick={onClickCopyUrl}>
            <SVGIcon name='url-icon' width='16' height='16' viewBox='16' />
          </Icon>
        </UrlSection>
        <ExpandCloseSection>
          <Icon onClick={onClickMinimize}>
            <SVGIcon name='minimize-icon' width='16' height='16' viewBox='16' fill='none' className='minimize-icon' />
          </Icon>
          <Icon onClick={onClickFullScreen}>
            <SVGIcon name='expand-icon' width='16' height='16' viewBox='16' fill='none' className='expand-icon' />
          </Icon>
          <Icon onClick={onClickClose}>
            <SVGIcon name='app-close-icon' width='16' height='16' viewBox='16' fill='none' className='close-icon' />
          </Icon>
        </ExpandCloseSection>
      </MainHeaderContent>
    </Window>
  );
}
