import React from "react";
import AppLayout from "../../component/appLayout";
import Header from "./header";
import { Group } from "./styles";


const Time: React.FC = () => {
    return (<>
        <AppLayout>
            <Group>
                <Header />
                <div>This is Time content</div>
            </Group>
        </AppLayout>
    </>);
};

export default Time;