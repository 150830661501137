import { useHistory } from 'react-router-dom';
import AppLayout from '../../../../component/appLayout';
import { getUrlParam, isEmpty } from '../../../../helpers/common';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ROUTES } from '../../../../routes/urls';
import { RootReducerInterface } from '../../../../interfaces/RootReducerInterface';
import { sentryAccountConnect } from '../../../../services/settingServices';
import { setErrorMessage } from '../../../../actions/messageActions';

function SentryCallback() {
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { workspace: workspaceSelector } = stateSelector;
  const { workspace } = workspaceSelector;
  // Other variables
  const history = useHistory();
  const dispatch = useDispatch();
  const installationId = getUrlParam(history.location.search, 'installationId');
  const code = getUrlParam(history.location.search, 'code');

  const loadData = useCallback(async () => {
    if (!isEmpty(workspace?.id)) {
      // call service to add sentry connection
      if (installationId && code) {
        const payload = {
          installationId,
          code
        };
        const response = await dispatch(sentryAccountConnect(payload));
        if (response) {
          history.push(ROUTES.INTEGRATIONS_SETTING);
        }
      } else {
        dispatch(setErrorMessage('Invalid sentry redirect URL'));
      }
    }
  }, [code, dispatch, history, installationId, workspace?.id]);

  useEffect(() => {
    loadData();
  }, [loadData]);
  return <AppLayout>Loading ...</AppLayout>;
}

export default SentryCallback;
