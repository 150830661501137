import styled from 'styled-components';
import { Typography } from '../../../global/theme';

const Rightrow = styled.div`
  cursor: pointer;
  p {
    ${Typography.body_md_medium}
    color: var(--brand-primary);
    margin: 0;
  }
`;

const ButtonBlock = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 18px 4px 4px;
  gap: 8px;
  background: var(--background-secondary);
  border-radius: 20px;
  border: none;
  position: relative;
  .stroke-color {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 6px;
    top: 6px;
    path {
      stroke: var(--text-secondary);
    }
  }
  :hover .stroke-color {
    opacity: 1;
  }
  .closeicon {
    display: none;
  }
  p {
    margin: 0;
    ${Typography.body_md_medium}
    color: var(--text-primary);
  }
  :hover {
    padding: 4px 4px 4px 4px;
    cursor: pointer;
    .closeicon {
      padding: 5px 5px 6px 8px;
      display: block;
      margin-left: -9px;
      fill: var(--background-secondary);
      stroke: var(--text-secondary);
    }
  }
  @media (max-width: 449px) {
    padding: 4px 12px 4px 4px;
    .closeicon {
      display: block;
    }
    svg {
      path {
        stroke: var(--text-secondary);
      }
    }
  }
`;

const Row1 = styled.div`
  display: flex;
  align-items: center;
  max-width: 463px;
  width: 100%;
  flex-direction: row;
  flex-flow: row wrap;
  border-top: 0.5px solid var(--border-primary);
  padding-top: 12px;
  gap: 12px;
`;

const Icons = styled.div``;

export { Rightrow, ButtonBlock, Row1, Icons };
