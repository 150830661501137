import { Content, Icon, Task } from './styles';
import SVGIcon from '../../../../assets/images/svg/SVGIcon';

interface Props {
  heading: string;
  count: number;
  iconname: string;
}
export default function Taskcard(Props: Props) {
  // Other variables
  const { heading, count, iconname } = Props;
  return (
    <>
      <Task>
        <Icon>
          <SVGIcon name={iconname} width='28' height='28' viewBox='0 0 28 28' />
        </Icon>
        <Content>
          <p>{heading}</p>
          <h2>{count}</h2>
        </Content>
      </Task>
    </>
  );
}
