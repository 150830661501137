import styled from 'styled-components';

const Main = styled.div`
  max-width: 662px;
  background-color: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  margin: auto;
  border-radius: 8px;
  margin-bottom: 24px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  .loading-animation {
    overflow: hidden;
  }

  .loading-animation::before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    animation: loading 1s infinite;
    background: linear-gradient(to right, transparent, var(--background-tertiary), transparent);
  }

  @keyframes loading {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;
const Row = styled.div`
  padding: 16px 20px;
  border-bottom: 0.5px solid var(--border-primary);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  :last-child {
    border-bottom: none;
  }
`;

const Leftdiv = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
const Profile = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--background-muted);
`;

const Line = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Firstline = styled.div`
  width: 80px;
  height: 10px;
  border-radius: 20px;
  background-color: var(--background-muted);
`;

const Secondline = styled.div`
  width: 186px;
  height: 10px;
  border-radius: 20px;
  background-color: var(--background-muted);
  @media (max-width: 320px) {
    width: 140px;
  }
`;
const Square = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background-color: var(--background-muted);
`;
const Rightdiv = styled.div`
  width: 16px;
  height: 16px;
`;
export { Main, Row, Leftdiv, Profile, Line, Firstline, Secondline, Square, Rightdiv };
