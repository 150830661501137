import React from 'react';
import { Box, Content, Dates, Days } from './styles';

interface Props {
  dayname: string;
  date: number;
}

export default function Cardheader(Props: Props) {
  const { dayname, date } = Props;
  return (
    <div>
      <Box>
        <Content>
          <Days>{dayname}</Days>
          <Dates className='date-text'>{date}</Dates>
        </Content>
      </Box>
    </div>
  );
}
