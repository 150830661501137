import { AnyAction, Dispatch } from 'redux';
import { clearResponseMessage, setErrorMessage } from '../actions/messageActions';
import axios from 'axios';
import { getAPIErrorReason } from '../helpers/common';
import { setSnapList, setSnapLoader } from '../actions/snapActions';
import { REACT_APP_TEAMCAMP_APIURL } from '../global/environment';
import { captureException } from './logService';

export const getSnapListByUser: any = () => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setSnapLoader(true));
    const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/share/getSnapByUser`);
    const { data } = response;
    if (response) {
      dispatch(setSnapList(data));
    }
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to get snap list please try again';
    captureException(error);
    dispatchSnapError(error, dispatch);
  } finally {
    dispatch(setSnapLoader(false));
  }
};

export const deleteSnap: any = (snapId: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setSnapLoader(true));
    const response = await axios.delete(`${REACT_APP_TEAMCAMP_APIURL}/share/deleteShare/${snapId}`);
    if (response) {
      return true;
    }
    return false;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to delete snap please try again';
    captureException(error);
    dispatchSnapError(error, dispatch);
  } finally {
    dispatch(setSnapLoader(false));
  }
};

export const renameSnap: any = (snapId: string, payload: any) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setSnapLoader(true));
    const response = await axios.put(`${REACT_APP_TEAMCAMP_APIURL}/share/updateShare/${snapId}`, payload);
    if (response) {
      return true;
    }
    return false;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to update snap data please try again';
    captureException(error);
    dispatchSnapError(error, dispatch);
  } finally {
    dispatch(setSnapLoader(false));
  }
};

function dispatchSnapError(msg: string, dispatch: Dispatch<AnyAction>) {
  dispatch(setErrorMessage(msg));
}
