import React, { useState, useRef, useEffect } from 'react';
import { DropdownContent, DropdownWrapper, Trigger } from './style';

interface DropdownProps {
  trigger: React.ReactNode;
  content: React.ReactNode;
  placement?: 'bottomLeft' | 'bottomRight';
}

const TaskFilterCustomDropdown: React.FC<DropdownProps> = ({ trigger, content, placement = 'bottomLeft' }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Toggle dropdown
  const toggleDropdown = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsOpen((prev) => !prev);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      const targetElement = e.target as HTMLElement;
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(targetElement) &&
        !targetElement.closest('.reference-item')
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <DropdownWrapper ref={dropdownRef}>
      <Trigger onClick={toggleDropdown}>{trigger}</Trigger>
      {isOpen && <DropdownContent>{content}</DropdownContent>}
    </DropdownWrapper>
  );
};

export default TaskFilterCustomDropdown;
