import styled, { css } from 'styled-components';
import { Typography } from '../../../../global/theme';

interface Props {
  isOverdue?: boolean;
  isActive?: boolean;
  hasContent?: boolean;
  isShowCompletedTask?: boolean;
  isShowAllCompletedTask?: boolean;
  status?: number;
  ProgressPercentage?: number;
  hasGap?: boolean;
  color?: string;
}
const Group = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (max-width: 449px) {
    gap: 0;
  }
`;
const Milestoneitem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 9px 14px;
  gap: 10px;
  background: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  box-shadow: var(--shadow-card);
  border-radius: 12px;
  position: relative;
  :last-child {
    margin-bottom: 24px;
  }
  :hover .dotted-icon {
    opacity: 1;
  }
  @media (max-width: 449px) {
    padding: 12px 16px;
    border-radius: 0;
    border-width: 1px;
    border-style: none none solid;
    background: none;
    box-shadow: none;
    margin: 0 -16px;
    gap: 8px;
    :last-child {
      border-bottom: none;
      padding: 12px 16px;
      margin-bottom: 0;
    }
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  width: 100%;
  justify-content: space-between;
  gap: 10px;
`;
const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  p {
    ${Typography.body_md_medium}
    color: var(--text-secondary);
    margin: 0;
  }
  @media (max-width: 449px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
const Leftheader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 10px;
  .header-icon {
    fill: var(--background-primary);
    stroke: var(--text-secondary);
    path {
      stroke: none;
    }
  }
`;
const Rightheader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  position: relative;
  .dot-icon {
    cursor: pointer;
    :hover {
      background-color: var(--neutral-secondary);
      border-radius: 4px;
    }
    path {
      fill: var(--text-tertiary);
    }
  }
  .ant-dropdown {
    z-index: 99;
  }
`;
const Title = styled.div`
  ${Typography.body_md_medium}
  color: var(--text-primary);
  @media (max-width: 449px) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
`;
const Assigngroup1 = styled.div`
  ul {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
    margin: 0;
    justify-content: flex-end;
    height: 24px;
    width: max-content;
  }
  li {
    margin-left: -9px;
  }
  @media (max-width: 449px) {
    li {
      :first-child {
        margin-left: 0;
      }
    }
  }
  .plus-icon {
    @media (max-width: 449px) {
      display: none;
      width: 20px;
      border-radius: 24px;
      align-items: center;
      justify-content: center;
      color: var(--text-white);
      display: flex;
      background: var(--text-secondary);
      border: 2px solid var(--background-primary);
      position: relative;
      top: 0;
    }
  }
`;

const Plus = styled.div`
  position: absolute;
  top: 4px;
  @media (max-width: 449px) {
    top: 4px;
  }
`;

const Taskcontent = styled.div<{ isActive?: boolean }>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0.5px solid transparent;
  padding: 4px 6px;
  margin: 0px -1px;
  border-radius: 34px;
  gap: 40px;
  :hover .svgicon {
    opacity: 1;
  }

  :hover .plus-icon {
    visibility: visible;
    margin-left: -9px;
  }

  :hover .task-input {
    background-color: var(--background-primary);
  }

  :hover .calendar-icon {
    visibility: visible;
  }

  :hover {
    background-color: var(--background-primary);
    border: 0.5px solid var(--border-primary);
    box-shadow: var(--shadow-card);
  }

  ${(props: { isActive?: boolean }) =>
    props.isActive &&
    css`
      background-color: var(--background-primary);
      border: 0.5px solid var(--border-primary);
      box-shadow: var(--shadow-card);
    `}
  @media (max-width:449px) {
    padding: 8px 0;
    margin: 0;
    border: 0;
    :hover {
      background-color: unset;
      border: none;
      box-shadow: none;
    }
    ${(props: { isActive?: boolean }) =>
      props.isActive &&
      css`
        background-color: none;
        border: none;
        box-shadow: none;
      `}
  }
`;

const Taskitem = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  @media (max-width: 449px) {
    width: 100%;
    justify-content: space-between;
    gap: 8px;
  }
`;

const Form = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  .checkbox-round {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid var(--text-secondary);
    appearance: none;
    outline: none;
    cursor: pointer;
    margin: 0;
    padding: 7px;
  }

  input[type='checkbox'] {
    position: relative;
    cursor: pointer;
    opacity: 1;
  }

  input[type='checkbox']:checked {
    background-color: var(--text-tertiary);
    opacity: 1;
    border: 1px solid var(--text-tertiary);
  }

  input[type='checkbox']:checked::before {
    content: '';
    position: absolute;
    right: 50%;
    top: 50%;
    width: 3px;
    height: 8px;
    border: solid var(--text-white);
    border-width: 0 2px 2px 0;
    margin: -0.5px -1px 0 -1px;
    transform: rotate(45deg) translate(-50%, -50%) scale(0.7);
    z-index: 2;
  }
  @media (max-width: 449px) {
    gap: 10px;
  }
`;
const ButtonIcon = styled.div`
  padding: 4px;
  border-radius: 12px;
  display: flex;
  :hover {
    background-color: var(--neutral-secondary);
    input {
      filter: brightness(var(--brightness-value));
      background-color: transparent;
    }
  }
`;
const Inputvalue = styled.input``;

const Label = styled.label`
  ${Typography.body_md}
  color: var(--text-primary);
  margin: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
`;

const Assigngroup = styled.div`
  ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: max-content;
  }

  li {
    margin-left: -9px;
    border-radius: 50%;
  }
  .plus-icon {
    visibility: hidden;
  }
  @media (max-width: 449px) {
    .plus-icon {
      display: none;
      visibility: hidden;
    }
  }
`;

const AvatarImage = styled.div`
  display: flex;
  align-items: center;
  border-radius: 50%;

  > div > img {
    max-width: 100%;
  }
`;

const Deadline = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  ${(props: Props) =>
    props.hasGap &&
    css`
      gap: 0;
    `}
  .calendar-icon {
    visibility: hidden;
  }
  svg {
    vertical-align: middle;
  }
  .ant-dropdown {
    z-index: 99;
  }
  @media (max-width: 449px) {
    display: none;
  }
`;
const DeadBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }
`;

const Text = styled.p<Props>`
  ${Typography.body_md}
  color: var(--text-secondary);
  margin: 0;
  span {
    color: var(--accent-error);
  }
  ${(props) =>
    props.isOverdue &&
    css`
      color: var(--accent-error);
    `}
`;

const Icon2 = styled.div<Props>`
  display: inline-flex;
  cursor: pointer;
  .svgicon {
    path {
      stroke: var(--text-tertiary);
      fill: var(--text-tertiary);
    }
    :hover {
      background-color: var(--neutral-secondary);
      border-radius: 4px;
    }
  }
`;
const Iconhidden = styled.div`
  position: absolute;
  left: -15px;
  top: 9px;
  .svgicon {
    opacity: 0;
    stroke: var(--text-secondary);
  }
  @media (max-width: 449px) {
    display: none;
  }
`;
const Data = styled.div<Props>`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 0.5px solid var(--border-primary);
  padding-top: ${(props) => (props.isShowAllCompletedTask ? '0' : '5px')};
  ${(props) =>
    props.isShowCompletedTask &&
    css`
      border-top: none;
      padding-top: 0;
    `}
  @media (max-width:449px) {
    border-top: none;
    padding-top: 0;
  }
`;

const Empty = styled.div`
  position: absolute;
  width: fit-content;
  top: 50%;
  left: calc(50% + 110px);
  transform: translate(-50%, -50%);
  @media (max-width: 449px) {
    left: 50%;
    width: calc(100vw - 32px);
  }
`;

const Second = styled.div<Props>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  width: 100%;
  justify-content: ${(props) => (props.hasContent ? 'space-between' : 'flex-end')};
  gap: 10px;
`;

const Milestoneempty = styled.div`
  border-top: 0.5px solid var(--border-primary);
  @media (max-width: 449px) {
    border-top: none;
  }
`;

const Emptybox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 21px 0;
  @media (max-width: 449px) {
    padding: 12px 0;
  }
`;

const TopEmpty = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const Emptytitle = styled.div`
  color: var(--text-primary);
  text-align: center;
  ${Typography.body_md_semibold}
`;
const BottomEmpty = styled.div`
  button {
    padding: 5px 16px;
    ${Typography.body_sm_medium}
  }
`;
const Images = styled.div`
  height: 88px;
  width: 160px;
  .empty-image {
    width: 160px;
    height: 88px;
  }
`;

const Showhide = styled.a`
  ${Typography.body_md_medium}
  color: var(--text-secondary);
  cursor: pointer;
  max-width: 146px;
  width: 100%;
  :hover {
    color: var(--text-primary);
  }
  @media (max-width: 449px) {
    max-width: max-content;
  }
`;
const ShowTask = styled.div`
  display: flex;
  gap: 6px;
  flex-direction: column;
`;

const Iconhide = styled.div`
  position: absolute;
  left: -18px;
  top: 7px;
  cursor: pointer;
  .dotted-icon {
    opacity: 0;
    stroke: var(--text-secondary);
  }
  @media (max-width: 449px) {
    left: 0;
    top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    .dotted-icon {
      opacity: 0;
      visibility: hidden;
    }
  }
`;
const Buttons = styled.div`
  text-align: center;
  margin: -8px 0 16px;
  button {
    padding: 6px 20px;
    ${Typography.body_md_medium}
  }
  @media (max-width: 449px) {
    margin: 0px 0 12px;
    &.hide-text-button {
      margin: 0;
    }
  }
`;
const IconDueDate = styled.div`
  .calendar-fill-color {
    fill: var(--text-secondary);
  }
  .calendar-due-fill-color {
    fill: var(--accent-error);
  }
`;
const Multiplelabel = styled.div`
  border-radius: 15px;
  border: 0.5px solid var(--border-primary);
  display: flex;
  > div {
    &:not(:first-child) {
      border-left: 0.5px solid var(--border-primary);
    }
  }
`;
const Dropblock = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 3px 9px 3px 5px;
`;
const Doticon = styled.div<Props>`
  margin: 4px;
  width: 8px;
  height: 8px;
  border-radius: 20px;
  ${(props: Props) =>
    props.color &&
    css`
      background-color: ${props.color};
    `}
`;
const Droplabel = styled.div`
  ${Typography.body_md}
  color: var(--text-primary);
`;

const OverLayDiv = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  @media (max-width: 449px) {
    background: var(--background-overlay);
    z-index: 1111;
  }
`;

const SVGDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 449px) {
    display: none;
  }
`;

const TooltipContent = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export {
  Group,
  Milestoneitem,
  Header,
  Content,
  Leftheader,
  Rightheader,
  Title,
  Assigngroup1,
  Form,
  Taskitem,
  Inputvalue,
  Label,
  Assigngroup,
  AvatarImage,
  Deadline,
  DeadBlock,
  Text,
  Icon2,
  Taskcontent,
  Iconhidden,
  Data,
  Empty,
  Second,
  Milestoneempty,
  Emptybox,
  BottomEmpty,
  TopEmpty,
  Emptytitle,
  Images,
  Showhide,
  ShowTask,
  Iconhide,
  Buttons,
  IconDueDate,
  Multiplelabel,
  Dropblock,
  Droplabel,
  Doticon,
  OverLayDiv,
  SVGDiv,
  Plus,
  ButtonIcon,
  TooltipContent
};
