import axios from 'axios';
import moment from 'moment';
import { Dispatch, AnyAction } from 'redux';
import { clearResponseMessage, setErrorMessage } from '../actions/messageActions';
import {
  setCurrentStartTime,
  setCurrentTimeTracking,
  setListDropdown,
  setMyTimeLogData,
  setTimeEntryInput,
  setTimeLogData,
  setTimeTrackingActivity,
  setTimeTrackingLoader,
  setTimeTrackingReportData,
  updateListDropdown
} from '../actions/timeTrackingActions';
import { TIMER_STATUS, WEEK_DAYS } from '../global/constants';
import { floatToHHMM, getAPIErrorReason, getDateString, getFirebaseUniqueUserId, isEmpty } from '../helpers/common';
import UserPreferenceSingleton from '../helpers/userPreferenceSingleton';
import TimeTrackingCacheService from './timeTrackingCatchService';
import { REACT_APP_TEAMCAMP_APIURL } from '../global/environment';
import { captureException } from './logService';
import { ref, remove, set } from 'firebase/database';
import { db } from '../utils/firebase';
import { RootReducerInterface } from '../interfaces/RootReducerInterface';
import { CreateTimeLogDataInterface } from '../interfaces/TimeTrackingInterface';
import MyTimeLogCacheService from './myTimeLogCacheService';
import TasksCacheService from './tasksCatchServices';

/**
 * @desc get Time Logs
 * @param {*}
 */
export const getTimeLogs: any =
  (workspace_id: string, startingDate: string, endingDate: string, activeDate: string) =>
  async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setTimeTrackingLoader(true));
      if (!workspace_id) return false;
      const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
      const params = {
        user_id: userDetails?.id,
        company_id: workspace_id,
        start_date: getDateString(startingDate),
        end_date: getDateString(endingDate)
      };
      const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/timeLog/getWeekWiseTimeLogs`, {
        params
      });
      if (response) {
        const { data } = response;
        let dateRef = startingDate;
        let arrayRef: any = [];
        let index = 0;
        WEEK_DAYS.forEach((day: string) => {
          let hours = 0;
          let formated_date = getDateString(dateRef);
          const currentFormatedDate = Number(getDateString(new Date()));
          const logData = data.filter(
            (item: { formated_date: string; date: Date }) => moment(item?.date).format('YYYYMMDD') === formated_date
          );
          logData?.forEach((item: { hours: number }) => {
            hours = hours + item?.hours;
          });
          let dayObject = {
            index,
            day,
            date: dateRef,
            formated_date,
            logData,
            hours,
            active: Number(formated_date) <= currentFormatedDate
          };
          if (formated_date === activeDate) dispatch(setCurrentTimeTracking(dayObject));
          arrayRef.push(dayObject);
          dateRef = moment(new Date(dateRef)).add(1, 'days').toLocaleString();
          index = index + 1;
        });
        dispatch(setTimeLogData(arrayRef));
      }
      return false;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to get time tracking list please try again';
      captureException(error);
      dispatchTimeTrackingError(error, dispatch);
      return false;
    } finally {
      dispatch(setTimeTrackingLoader(false));
    }
  };

/**
 * @desc get time tracking
 * @param {*}
 */
export const getActiveTime: any = () => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/timeLog/getActiveTimelogs`);
    const { data } = response;
    if (data) {
      if (data?.length === 0) dispatch(setCurrentStartTime({ task_id: undefined, time: 0 }));
      dispatch(setTimeTrackingActivity(data));
      return true;
    }
    return false;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to get active time tracking please try again';
    captureException(error);
    dispatchTimeTrackingError(error, dispatch);
    return false;
  } finally {
  }
};

/* @desc add time log data
 * @param {*}
 */
export const addTimeLogDetails: any =
  (payload: CreateTimeLogDataInterface) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setTimeTrackingLoader(true));
      const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/timeLog/addTimeLog`, payload);
      if (response) {
        return true;
      }
      return false;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to add time log data please try again';
      captureException(error);
      dispatchTimeTrackingError(error, dispatch);
      return false;
    } finally {
      dispatch(setTimeTrackingLoader(false));
    }
  };

/* @desc update time log data
 * @param {*}
 */
export const updateTimeLogDetails: any =
  (itemId: string, payload: CreateTimeLogDataInterface) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setTimeTrackingLoader(true));
      const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/timeLog/putTimelogForTracker/${itemId}`, payload);
      if (response) {
        return response;
      }
      return undefined;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to update time log data please try again';
      captureException(error);
      dispatchTimeTrackingError(error, dispatch);
      return undefined;
    } finally {
      dispatch(setTimeTrackingLoader(false));
    }
  };

/* @desc load Edit Details
 * @param {*}
 */
export const loadEditTimeLog: any =
  () => async (dispatch: Dispatch<AnyAction>, getState: () => RootReducerInterface) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setTimeTrackingLoader(true));
      const state = getState();
      const input = state.timeTracking.timeEntryInput || {};
      const list = state.project.list;
      const projectList = list?.map((item: { name: string; id: string }) => {
        return { label: item?.name, id: item?.id, value: item?.id };
      });
      if (input?.id) {
        const project = projectList?.find((item: { id: string }) => item?.id === input?.projectId);
        //get project group list
        const tasks = await TasksCacheService.getInstance()?.getAllTaskByProject(project?.id);
        const taskList = tasks?.map((item: any) => {
          return { ...item, value: item?.name, key: item?.id };
        });
        const task = taskList?.find((item: { id: string }) => item?.id === input?.taskId);
        const updatedInput = {
          ...input,
          projectId: project,
          // taskGroupId: taskGroup,
          taskId: task,
          hours: floatToHHMM(input?.hours)
        };
        dispatch(setTimeEntryInput(updatedInput));
        dispatch(setListDropdown({ projectList, taskList }));
      } else {
        dispatch(updateListDropdown({ propsName: 'projectList', value: projectList }));
      }
    } catch (e) {
      const error = 'Unable to load edit time log data please try again';
      captureException(error);
      dispatchTimeTrackingError(error, dispatch);
      return false;
    } finally {
      dispatch(setTimeTrackingLoader(false));
    }
  };

export const loadTimeLogs: any = (workspace_id: string, months: string[]) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setTimeTrackingLoader(true));
    if (!months || months?.length === 0) {
      const today = new Date();
      const lastMonths = [];
      for (let i = 0; i < 3; i++) {
        const month = (today.getMonth() + 1).toString().padStart(2, '0');
        const year = today.getFullYear();
        lastMonths.push(`${month}-${year}`);
        today.setMonth(today.getMonth() - 1);
      }
      months = lastMonths;
    }

    const local_data = await TimeTrackingCacheService.getInstance()?.getTimeTrackingByWorkspace();
    if (local_data && local_data.length > 0) {
      dispatch(setTimeTrackingReportData(local_data));
    }

    let lastUpdatedTime = await TimeTrackingCacheService.getInstance()?.getLastUpdatedTimeMonthWise(workspace_id);

    let updateTimeArray = lastUpdatedTime && lastUpdatedTime !== 0 ? JSON.parse(JSON.stringify(lastUpdatedTime)) : {};
    const currentTime = moment(); // Current time
    const adjustedTime = currentTime.subtract(30, 'minutes').toISOString();

    let hasUpdatedData = false;
    await Promise.all(
      months.map(async (month: string) => {
        updateTimeArray[month] = adjustedTime;
        const monthLastUpdatedTime = lastUpdatedTime?.[month];
        const params = {
          date: month,
          lastUpdatedTime: monthLastUpdatedTime
        };

        let next_page_value = '';
        do {
          const response: any = await axios.get(
            `${REACT_APP_TEAMCAMP_APIURL}/timeLog/getTimeLogReport${next_page_value}`,
            { params }
          );
          const { data, nextPage } = response.data;

          if (lastUpdatedTime === 0 || isEmpty(lastUpdatedTime)) {
            await TimeTrackingCacheService.getInstance()?.setTimeTrackingData(data, workspace_id);
            hasUpdatedData = true;
          } else {
            await TimeTrackingCacheService.getInstance()?.updateTimeLogList(data, [], workspace_id);
            if (data && data.length > 0) {
              hasUpdatedData = true;
            }
          }
          next_page_value = nextPage;
        } while (next_page_value);
      })
    );

    if (hasUpdatedData) {
      await TimeTrackingCacheService.getInstance()?.setLastUpdatedTimeMonthWise(updateTimeArray, workspace_id);
      const local_updated_data = await TimeTrackingCacheService.getInstance()?.getTimeTrackingByWorkspace();
      if (local_updated_data && local_updated_data.length > 0) {
        dispatch(setTimeTrackingReportData(local_updated_data));
        return local_updated_data;
      }
    } else {
      const local_updated_data = await TimeTrackingCacheService.getInstance()?.getTimeTrackingByWorkspace();
      dispatch(setTimeTrackingReportData(local_updated_data));
      return local_updated_data;
    }
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to load time log data please try again';
    captureException(error);
    dispatchTimeTrackingError(error, dispatch);
    return false;
  } finally {
    dispatch(setTimeTrackingLoader(false));
  }
};
export const loadMyTimeLog: any = (workspace_id: string, months: string[]) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setTimeTrackingLoader(true));
    if (!months || months?.length === 0) {
      const today = new Date();
      const lastMonths = [];
      for (let i = 0; i < 3; i++) {
        const month = (today.getMonth() + 1).toString().padStart(2, '0');
        const year = today.getFullYear();
        lastMonths.push(`${month}-${year}`);
        today.setMonth(today.getMonth() - 1);
      }
      months = lastMonths;
    }

    const local_data = await MyTimeLogCacheService.getInstance()?.getMyTimeLogByWorkspace();
    if (local_data && local_data.length > 0) {
      dispatch(setMyTimeLogData(local_data));
    }

    let lastUpdatedTime = await MyTimeLogCacheService.getInstance()?.getLastUpdatedTimeMonthWise(workspace_id);

    let updateTimeArray = lastUpdatedTime && lastUpdatedTime !== 0 ? JSON.parse(JSON.stringify(lastUpdatedTime)) : {};
    const currentTime = moment(); // Current time
    const adjustedTime = currentTime.subtract(30, 'minutes').toISOString();

    let hasUpdatedData = false;
    await Promise.all(
      months.map(async (month: string) => {
        updateTimeArray[month] = adjustedTime;
        const monthLastUpdatedTime = lastUpdatedTime?.[month];
        const params = {
          date: month,
          lastUpdatedTime: monthLastUpdatedTime
        };

        let next_page_value = '';
        do {
          const response: any = await axios.get(
            `${REACT_APP_TEAMCAMP_APIURL}/timeLog/myTimeLogsReport${next_page_value}`,
            { params }
          );
          const { data, nextPage } = response.data;

          if (lastUpdatedTime === 0 || isEmpty(lastUpdatedTime)) {
            await MyTimeLogCacheService.getInstance()?.setMyTimeLogData(data, workspace_id);
            hasUpdatedData = true;
          } else {
            await MyTimeLogCacheService.getInstance()?.updateTimeLogList(data, [], workspace_id);
            if (data && data.length > 0) {
              hasUpdatedData = true;
            }
          }
          next_page_value = nextPage;
        } while (next_page_value);
      })
    );

    if (hasUpdatedData) {
      await MyTimeLogCacheService.getInstance()?.setLastUpdatedTimeMonthWise(updateTimeArray, workspace_id);
      const local_updated_data = await MyTimeLogCacheService.getInstance()?.getMyTimeLogByWorkspace();
      if (local_updated_data && local_updated_data.length > 0) {
        dispatch(setMyTimeLogData(local_updated_data));
        return local_updated_data;
      }
    } else {
      const local_updated_data = await MyTimeLogCacheService.getInstance()?.getMyTimeLogByWorkspace();
      dispatch(setMyTimeLogData(local_updated_data));
      return local_updated_data;
    }
  } catch (e: any) {
    const error = getAPIErrorReason(e) || 'Unable to load my time log data please try again';
    captureException(error);
    dispatchTimeTrackingError(error, dispatch);
  } finally {
    dispatch(setTimeTrackingLoader(false));
  }
};
/**
 * @desc get time tracking
 * @param {*}
 */
export const startTracker: any = (taskId: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    const uniqueId: { uniqueUserId: string; userId: string; workspaceId: string } = getFirebaseUniqueUserId();
    set(ref(db, `timer/${uniqueId?.uniqueUserId}`), {
      taskId: taskId,
      status: TIMER_STATUS?.START,
      userId: uniqueId?.userId,
      workspaceId: uniqueId?.workspaceId
    });
    return true;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to update time tracking when start tracker please try again';
    captureException(error);
    dispatchTimeTrackingError(error, dispatch);
    return false;
  } finally {
  }
};

/**
 * @desc get time tracking
 * @param {*}
 */
export const stopTracker: any = () => async (dispatch: Dispatch<AnyAction>) => {
  try {
    const uniqueId = getFirebaseUniqueUserId();
    remove(ref(db, `timer/${uniqueId?.uniqueUserId}`));
    return true;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to update time tracking when stop tracker please try again';
    captureException(error);
    dispatchTimeTrackingError(error, dispatch);
    return false;
  } finally {
  }
};

function dispatchTimeTrackingError(msg: string, dispatch: Dispatch<AnyAction>) {
  dispatch(setErrorMessage(msg));
}
