/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable max-len */
import { useCallback, useMemo, useRef, useState } from 'react';
import { isElectronApp, isEmpty } from '../../helpers/common';
import { DropBox, ScreenRecordMain, YoutubePlayer } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { setSuccessMessage } from '../../actions/messageActions';
import copy from 'clipboard-copy';
import { captureException } from '../../services/logService';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';
import ReactPlayer from 'react-player';
import SVGIcon from '../../assets/images/svg/SVGIcon';
import { Dropdown, DropdownItem } from '../Dropdown';

interface Props {
  videoId?: string;
  videoName: string;
  videoUrl: string;
  isYouTubePreview?: boolean;
  isStartFromVideo?: boolean;
}

const RenderVideoFile: React.FC<Props> = (props) => {
  const { videoId, videoName, videoUrl, isYouTubePreview = false, isStartFromVideo = false } = props;
  //Refs
  const videoRef: any = useRef(null);
  const playerWrapperRef: any = useRef<HTMLDivElement | null>(null);
  //States
  const [isPlaying, setIsPlaying] = useState(false);
  const [oncePlay, setOncePlay] = useState(false);
  //use selector state variables
  const settingsSelector = useSelector((state: RootReducerInterface) => state.settings);
  const { themeMode } = settingsSelector;
  //Other variable
  const dispatch = useDispatch();

  const togglePlay = () => {
    if (!isYouTubePreview) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setIsPlaying(true);
      } else {
        videoRef.current.pause();
        setIsPlaying(false);
      }
    } else {
      setIsPlaying(!isPlaying);
      setOncePlay(true);
    }
  };
  const handleStandardFullscreen = useCallback(
    (ref: any) => {
      if (!ref) return;
      if (ref.current.requestFullscreen) {
        if (isElectronApp()) {
          window.open(videoUrl, '_blank');
        } else {
          ref.current.requestFullscreen();
        }
      } else if (ref.current.mozRequestFullScreen) {
        ref.current.mozRequestFullScreen();
      } else if (ref.current.webkitRequestFullscreen) {
        ref.current.webkitRequestFullscreen();
      } else if (ref.current.msRequestFullscreen) {
        ref.current.msRequestFullscreen();
      }
    },
    [videoUrl]
  );

  const toggleFullscreen = () => {
    if (!isYouTubePreview) {
      handleStandardFullscreen(videoRef);
      videoRef.current.play(); // Ensure the video plays when entering fullscreen
      setIsPlaying(true); // Set playing state
    } else {
      if (!isPlaying) {
        // If the video is in preview mode (light is enabled), start playing
        setIsPlaying(true); // Play the video
        setOncePlay(true);
      }
      handleStandardFullscreen(playerWrapperRef);
    }
  };

  const onClickShare = useCallback(
    async (share_id: string) => {
      try {
        if (share_id !== undefined) {
          if (isYouTubePreview) {
            await copy(`https://www.youtube.com/watch?v=${share_id}`);
            dispatch(setSuccessMessage('YouTube url copied successfully!'));
          } else {
            await copy(`https://share.teamcamp.app/${share_id}`);
            dispatch(setSuccessMessage('Screen recording url copied successfully!'));
          }
        }
      } catch (error) {
        captureException(error);
        console.log('Failed to copy url: ', error);
      }
    },
    [dispatch, isYouTubePreview]
  );

  const renderMoreMenuContent = useMemo(
    () => (
      <div className='screen-recording-more'>
        {themeMode?.theme === 'dark' ? (
          <img
            src={
              'https://firebasestorage.googleapis.com/v0/b/teamcamp-app.appspot.com/o/MediaIcons%2FIcon_more.svg?alt=media&token=4bfff6bb-8d93-496e-b97c-d4b6d9eb0aa2'
            }
            id='more-video-refresh-ref'
            alt='More Image'
            className='video-more-icon'
          />
        ) : (
          <img
            src={
              'https://firebasestorage.googleapis.com/v0/b/teamcamp-app.appspot.com/o/MediaIcons%2Fmore_updated_light.svg?alt=media&token=2d54af7d-8675-4621-ba69-e0a1247f5114'
            }
            id='more-video-refresh-ref'
            alt='More Image'
            className='video-more-icon'
          />
        )}
      </div>
    ),
    [themeMode?.theme]
  );

  return (
    <ScreenRecordMain isStartFromVideo={isStartFromVideo}>
      <div className='video-preview'>
        <div className='screen-recording-header'>
          <div className='screen-recording-desc-div'>
            {isYouTubePreview && (
              <img
                src={
                  'https://firebasestorage.googleapis.com/v0/b/teamcamp-app.appspot.com/o/MediaIcons%2FYouTube_icon.png?alt=media&token=ef342c7b-16fc-4a2c-b245-8e9eb87ff785'
                }
                alt='youtube'
                width='20'
                height='20'
                className='youtube-icon'
              />
            )}
            <div className='screen-recording-text'>
              <div className='screen-recording-name'>{isYouTubePreview ? `YouTube_video_${videoName}` : videoName}</div>
            </div>
          </div>
          <div className='screen-recording-right-div'>
            <div className='screen-recording-animation' onClick={toggleFullscreen}>
              {themeMode?.theme === 'dark' ? (
                <img
                  src={
                    'https://firebasestorage.googleapis.com/v0/b/teamcamp-app.appspot.com/o/MediaIcons%2FIcon_dark_mode_full_screen.svg?alt=media&token=063f8f8a-8ae7-4874-8a46-a08ca596a8ac'
                  }
                  id='screen-recording-open'
                  alt='Open video'
                  className='open-video-icon'
                />
              ) : (
                <img
                  src={
                    'https://firebasestorage.googleapis.com/v0/b/teamcamp-app.appspot.com/o/MediaIcons%2FIcon_light_mode_full_screen.svg?alt=media&token=bcad4f9e-dcd0-4444-bc79-521026f18d80'
                  }
                  id='screen-recording-open'
                  alt='Open video'
                  className='open-video-icon'
                />
              )}
            </div>
            {!isEmpty(videoId) && videoId && (
              <Dropdown content={renderMoreMenuContent}>
                <div>
                  <DropBox>
                    <DropdownItem label='Share' onClick={() => onClickShare(videoId)} />
                  </DropBox>
                </div>
              </Dropdown>
            )}
          </div>
        </div>
        <div className='inbox-image' onDoubleClick={toggleFullscreen}>
          {isYouTubePreview ? (
            <YoutubePlayer ref={playerWrapperRef}>
              <ReactPlayer
                url={videoUrl}
                playing={isPlaying} // Video will play when isPlaying is true
                controls={true} // Show YouTube controls for forward/backward seeking
                height='100%'
                width='100%'
                light={
                  !isPlaying &&
                  !oncePlay && (
                    <img
                      src={`https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`}
                      alt='Thumbnail'
                      style={{ height: '200px', width: '353px' }}
                      className='img-thumbnail'
                    />
                  )
                } // Only show the thumbnail initially
                config={{
                  youtube: {
                    playerVars: {
                      modestbranding: 1, // Minimal branding
                      showinfo: 0, // Hide video info
                      controls: 1, // Show YouTube controls
                      disablekb: 0, // Enable keyboard controls for seeking
                      iv_load_policy: 3, // Hide annotations
                      fs: 1, // Allow full-screen
                      rel: 0 // Don't show related videos
                    }
                  }
                }}
                onPause={() => setIsPlaying(false)}
                onPlay={() => setIsPlaying(true)}
              />
            </YoutubePlayer>
          ) : (
            <video ref={videoRef} controls={false} className='inbox-video'>
              <source type='video/mp4' src={videoUrl} />
            </video>
          )}
          <div onClick={togglePlay}>
            {isPlaying ? (
              <SVGIcon name='video-pause-icon' width='30' height='30' viewBox='0 0 30 30' className='video-icon' />
            ) : (
              <img
                src={
                  'https://firebasestorage.googleapis.com/v0/b/teamcamp-app.appspot.com/o/MediaIcons%2Fvideoplay.png?alt=media&token=ef342c7b-16fc-4a2c-b245-8e9eb87ff785'
                }
                alt='video-play'
                className='video-icon'
                width='30'
                height='30'
              />
            )}
          </div>
        </div>
      </div>
    </ScreenRecordMain>
  );
};

export default RenderVideoFile;
