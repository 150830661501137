/* eslint-disable max-len */
import styled, { css } from 'styled-components';
import { Typography } from '../../../global/theme';

const Messagediv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  justify-content: space-between;
  padding: 12px 24px 11px;
  border-bottom: 0.5px solid var(--border-primary);
  position: sticky;
  top: 0;
  @media (max-width: 449px) {
    padding: 13px 16px;
    &.project {
      padding: 12px 16px;
    }
  }
`;

const Leftheader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  p {
    margin: 0;
    ${Typography.body_lg_medium}
    color: var(--text-primary);
  }
  .teamcampicon {
    rect {
      fill: var(--background-tertiary);
      stroke: var(--border-primary);
    }
    path {
      fill: var(--text-primary);
    }
  }
  @media (max-width: 449px) {
    p {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      word-break: break-all;
    }
    .teamcampicon {
      max-width: 30px;
      width: 100%;
    }
  }
`;

const ResponsiveBackIcon = styled.div`
  display: none;
  @media (max-width: 449px) {
    display: flex;
    height: 24px;
    .left-arrow-icon {
      path {
        stroke: var(--text-primary);
      }
    }
  }
`;

const Searchheader = styled.div<{ tagsSelected: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 0.5px solid var(--border-primary);
  position: sticky;
  top: 0;
  gap: 0;
  position: relative;
  z-index: 999;
  background-color: var(--background-secondary);
  p {
    margin: 0;
    ${Typography.body_lg}
    color: var(--text-primary);
  }
  ${(props) =>
    props.tagsSelected &&
    css`
      .ant-select-multiple .ant-select-selector {
        padding: 13px 13px 9px 36px !important;
      }
    `}
  @media (max-width: 449px) {
    align-items: flex-start;
  }
`;

const Text = styled.div`
  color: var(--text-primary);
  ${Typography.body_lg}
  position: absolute;
  left: 24px;
  @media (max-width: 449px) {
    text-align: center;
    padding-top: 3px;
    padding-right: 12px;
    left: 16px;
    top: 14px;
  }
`;

const Selects = styled.div`
  width: 100%;
  .ant-select {
    color: var(--text-primary);
    ${Typography.body_lg}
    margin: 0 24px;
    width: calc(100% - 48px) !important;
  }
  .ant-select .ant-select-selection-placeholder {
    color: var(--text-secondary);
    ${Typography.body_lg}
    padding-left: 24px;
    padding-top: 3px;
  }
  .ant-select-multiple .ant-select-selector {
    padding: 13px 13px 9px 36px;
    border: none;
    background: none;
    margin-bottom: 3px;
    :focus {
      border: none;
      box-shadow: none;
    }
  }
  .ant-select-focused.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
      .ant-pagination-size-changer
    )
    .ant-select-selector {
    box-shadow: none;
  }
  .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
    color: var(--text-primary);
    ${Typography.body_lg}
  }

  .ant-select-multiple .ant-select-selection-search {
    margin-inline-start: -1px;
  }
  .ant-select-selection-item {
    padding: 3px;
    margin: 0;
    height: 32px;
    border: 0.5px solid var(--border-primary);
    border-radius: 20px;
  }
  .anticon {
    display: none;
  }
  .ant-select-selection-overflow {
    gap: 12px;
  }
  .ant-tag {
    background-color: var(--background-secondary);
    border: 0.5px solid var(--border-primary);
  }
  @media (max-width: 449px) {
    .ant-select-selection-overflow {
      gap: 6px;
    }
    .ant-select .ant-select-selection-placeholder {
      padding-left: 18px;
    }
    .ant-select-multiple .ant-select-selector {
      padding-left: 30px;
    }
    .ant-select {
      width: calc(100% - 32px) !important;
      margin: 0 16px;
    }
  }
`;

const Dropmenu = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  p {
    margin: 0;
    color: var(--text-primary);
    ${Typography.body_sm_medium}
  }
  .teamcampicon {
    rect {
      fill: var(--background-tertiary);
      stroke: var(--border-primary);
    }
    path {
      fill: var(--text-primary);
    }
  }
`;
const Droptag = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  p {
    margin: 0;
    color: var(--text-primary);
    ${Typography.body_md_medium}
  }
  .fillColor {
    stroke: var(--text-secondary);
    margin-left: -2px;
    cursor: pointer;
  }
`;

const Closeicon = styled.div`
  height: 20px;
`;

const Rightheader = styled.div`
  cursor: pointer;
  height: 18px;
  padding: 3px;
  .plusicon {
    stroke: var(--text-secondary);
  }
  :hover {
    background-color: var(--neutral-secondary);
    border-radius: 4px;
  }
  @media (max-width: 449px) {
    padding: 7px;
  }
`;

const Icons = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: 449px) {
    align-items: center;
  }
`;

const AssignContent = styled.div`
  width: 100%;
  max-width: 238px;
  position: relative;
  @media (max-width: 449px) {
    max-width: unset;
    width: 75px;
  }
`;

const Assigngroup = styled.div`
  width: 100%;
  max-width: 140px;
  margin-left: 16px;

  ul {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  li {
    margin-right: -8px;
    position: relative;
    @media (max-width: 449px) {
      margin-right: -9px;
    }
  }

  .plus-icon {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    color: white;
    display: flex;
    background: var(--background-muted);
    ${Typography.body_xs_semibold}
    border: 2px solid var(--background-primary);
    position: relative;
    top: 0;
    @media (max-width: 449px) {
      width: 20px;
      height: 20px;
    }
  }
`;
const Plus = styled.div`
  ${Typography.body_xs_semibold}
  color: var(--text-primary);
  position: absolute;
  top: 4px;
  @media (max-width: 449px) {
    top: 4px;
  }
`;
const Doticon = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: relative;
  .doticon {
    stroke: var(--text-secondary);
    fill: var(--text-secondary);
    :hover {
      background-color: var(--neutral-secondary);
      border-radius: 4px;
    }
  }
`;

const View = styled.div`
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  :hover {
    .close-icon {
      visibility: visible;
    }
  }
`;

const Droptextform = styled.p`
  ${Typography.body_md}
  color: var(--text-primary);
  margin: 0;
`;
const Profile = styled.div``;

const Profilename = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const Close = styled.div`
  width: 20px;
  height: 20px;
  .close-icon {
    visibility: hidden;
    cursor: pointer;
    path {
      stroke: var(--text-secondary);
    }
    :hover {
      path {
        stroke: var(--text-primary);
      }
    }
  }
  @media (max-width: 449px) {
    .close-icon {
      visibility: visible;
    }
  }
`;

const Newheader = styled.div`
  display: none;
  @media (max-width: 449px) {
    display: flex;
    align-items: center;
    gap: 12px;
    border-bottom: 0.5px solid var(--border-primary);
    background-color: var(--background-primary);
    z-index: 1;
    padding: 14px 16px;
    position: sticky;
    p {
      ${Typography.heading_lg}
      color: var(--text-primary);
      margin: 0;
    }
  }
`;

const ItemDiv = styled.div`
  max-height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: 4px;
  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--text-placeholder);
    border-radius: 12px;
    min-height: 28px;
  }
`;

export {
  Messagediv,
  Header,
  Selects,
  Text,
  Dropmenu,
  Droptag,
  Closeicon,
  Searchheader,
  Leftheader,
  Rightheader,
  Icons,
  Doticon,
  View,
  Droptextform,
  Profile,
  Profilename,
  Close,
  ResponsiveBackIcon,
  Newheader,
  AssignContent,
  Assigngroup,
  Plus,
  ItemDiv
};
