import React, { useCallback, useEffect, useState } from 'react';
import {
  Group,
  Content,
  Icon,
  Title,
  Formblock,
  Profile,
  ProfileView,
  Heading,
  FormContent,
  ProfileContent,
  Text,
  Subblock,
  Form,
  Item,
  Label,
  Inputvalue,
  Items,
  Textformat,
  Endblock,
  Addblock,
  Textform,
  Icon1,
  RightSide,
  SkipOption
} from './styles';
import profile from '../../../assets/images/profile.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  addUserItem,
  clearWorkspaceInputDetail,
  deleteUserItem,
  updateUserItem
} from '../../../actions/workspaceActions';
import { isEmail, isEmpty } from '../../../helpers/common';
import { createWorkspace, dispatchWorkspaceError } from '../../../services/workspaceService';
import UserPreferenceSingleton from '../../../helpers/userPreferenceSingleton';
import Button from '../../../component/button';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { clearPreviousWorkspaceData } from '../../../services/baseServices';
import { ROUTES } from '../../../routes/urls';
import { trackAnalyticActivity } from '../../../services/analyticsService';
import { captureException } from '../../../services/logService';
import AppLogo from '../../../component/appLogo';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import { loadInitWorkspaceDetails } from '../../../services/appService';
import { VIEW_ANALYTICS } from '../../../global/analyticsConstants';

interface User {
  email?: string;
  name?: string;
}

const OnboardingStep2: React.FC = () => {
  // States
  const [loading, setLoading] = useState(false);
  //use selector state variables
  const workspaceSelector = useSelector((state: RootReducerInterface) => state.workspace);
  const { workspaceInputDetails } = workspaceSelector;
  const { users } = workspaceInputDetails;
  // Other variables
  const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    trackAnalyticActivity(VIEW_ANALYTICS.ONBOARDING_STEP_2);
  }, []);

  const onClickAdd = useCallback(() => {
    dispatch(addUserItem({ item: {} }));
  }, [dispatch]);

  const onRemoveItem = useCallback(
    (index: number) => {
      if (users?.length > 1) {
        dispatch(deleteUserItem({ index: index }));
      } else {
        const item = {
          email: '',
          name: ''
        };
        dispatch(updateUserItem({ item: item, index: index }));
      }
    },
    [dispatch, users?.length]
  );

  const onChangeInput = useCallback(
    (key: keyof User, item: User, value: string, index: number) => {
      item = {
        ...item,
        [key]: value
      };
      dispatch(updateUserItem({ item: item, index: index }));
    },
    [dispatch]
  );

  const onSubmit = useCallback(
    async (e: React.SyntheticEvent, isSkip?: boolean) => {
      try {
        setLoading(true);
        e.preventDefault();
        let error: { email?: string } = {};
        await dispatch(clearPreviousWorkspaceData());
        if (isSkip) {
          dispatch(clearWorkspaceInputDetail());
          const clearData = { email: '', name: '' };
          dispatch(updateUserItem({ item: clearData, index: 0 }));
        }
        for (const item of workspaceInputDetails.users) {
          if (!isEmpty(item?.email) && !isEmail(item?.email) && !isSkip) {
            error.email = `Please enter a valid email address`;
            break;
          }
        }
        if (!isEmpty(error) && error?.email && !isSkip) {
          dispatchWorkspaceError(error?.email, dispatch);
          setLoading(false);
          return;
        }
        let payload = {
          industry: workspaceInputDetails?.industry?.value,
          teamType: workspaceInputDetails?.teamType?.value,
          name: workspaceInputDetails?.name
        };
        if (!isSkip) {
          payload = {
            ...(workspaceInputDetails || {}),
            ...(payload || {})
          };
        }
        const result = await dispatch(createWorkspace(payload));
        try {
          if (result) {
            const response = await dispatch(loadInitWorkspaceDetails(result?.id));
            if (response?.length > 0 && response[0]?.id) history.push(`/projects/details/${response[0]?.id}/tasks`);
            else history.push(ROUTES.INBOX);
          }
        } catch (e) {
          captureException(e);
        }
      } catch (e) {
        captureException(e);
        console.log('Error', e);
      } finally {
        setLoading(false);
      }
    },
    [dispatch, history, workspaceInputDetails]
  );

  return (
    <>
      <Group className='windows-onboarding-flow'>
        <Content onSubmit={onSubmit}>
          <Icon>
            <AppLogo height='48' />
          </Icon>
          <Title>Just a few quick things to set up your account…</Title>
          <FormContent>
            <Formblock>
              <Profile>
                <Icon>
                  <img src={profile} alt='profile' />
                </Icon>
                <ProfileView>
                  <ProfileContent>
                    <Heading>Hello, {userDetails?.given_name}</Heading>
                  </ProfileContent>
                  <Text>Invite Members</Text>
                </ProfileView>
              </Profile>

              <Subblock>
                <Form>
                  <Items>
                    <Label>Email Address</Label>
                    <Label>Full Name ( Optional )</Label>
                  </Items>
                  {users?.map((item: User, index: number) => {
                    return (
                      <Item key={index}>
                        <Inputvalue
                          key={index}
                          typeof='email'
                          value={item?.email}
                          onChange={(e) => {
                            onChangeInput('email', item, e.target.value, index);
                          }}
                          placeholder='Email'
                        />
                        <Inputvalue
                          typeof='text'
                          value={item?.name}
                          onChange={(e) => {
                            onChangeInput('name', item, e.target.value, index);
                          }}
                          placeholder='Full Name'
                        />
                        <Icon
                          onClick={() => {
                            onRemoveItem(index);
                          }}>
                          <SVGIcon
                            name='round-close-icon'
                            width='18'
                            height='18'
                            viewBox='0 0 18 18'
                            className='round-close-icon'
                          />
                        </Icon>
                      </Item>
                    );
                  })}
                </Form>
                <Addblock>
                  <Icon1 onClick={onClickAdd}>
                    <SVGIcon name='round-plus-icon' width='18' height='18' viewBox='0 0 18 18' />
                  </Icon1>
                  <Textform>
                    <span onClick={onClickAdd}>Add another</span>
                  </Textform>
                </Addblock>
              </Subblock>
            </Formblock>
            <Endblock>
              <Textformat>Step 2 to 2</Textformat>
              <RightSide>
                <SkipOption disable={loading} onClick={(e: React.SyntheticEvent) => onSubmit(e, true)}>
                  Skip
                </SkipOption>
                <Button title='Get Started' type='submit' isLoading={loading} resWidth={100} />
              </RightSide>
            </Endblock>
          </FormContent>
        </Content>
      </Group>
    </>
  );
};
export default OnboardingStep2;
