import styled from 'styled-components';
import { Typography } from '../../global/theme';

const Title = styled.div`
  ${Typography.body_md_medium}
  color: var(--text-primary);
`;
const Headline = styled.h6`
  ${Typography.body_lg}
  color: var(--text-secondary);
  margin: 0;
`;

const Icon1 = styled.div`
  display: inline-flex;
  cursor: pointer;
  .closeicon {
    stroke: var(--text-primary);
  }
  :hover {
    background-color: var(--neutral-secondary);
    border-radius: 4px;
  }
`;
const Inputvalues = styled.input`
  width: -webkit-fill-available;
  padding: 8px 12px;
  ${Typography.body_lg}
  color: var(--text-primary);
  border: 0.5px solid var(--border-primary);
  background-color: var(--background-primary);
  border-radius: 6px;
  outline: none;
  :focus {
    border: 0.5px solid var(--brand-primary);
    z-index: 999;
  }
  ::placeholder {
    ${Typography.body_lg}
    color: var(--text-primary);
  }
`;

const Groupmodal = styled.div`
  padding: 24px 16px 16px 0px;
`;
const Groupbody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  padding: 16px 16px 0;
`;
const Groupmodel = styled.form`
  background: var(--background-primary);
  box-shadow: var(--shadow-modal);
  border-radius: 8px;
  text-align: end;
`;
const GroupModalhead = styled.div`
  border-bottom: 0.5px solid var(--border-primary);
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Modeltitle = styled.p`
  ${Typography.body_md_medium}
  color: var(--text-secondary);
  margin: 0;
  cursor: pointer;
`;
const Todotext = styled.p`
  ${Typography.heading_md}
  color: var(--text-primary);
  cursor: pointer;
  margin: 0px;
`;

const Icon = styled.div`
  display: inline-flex;
  cursor: pointer;
  .closeicon {
    stroke: var(--text-primary);
  }
  :hover {
    background-color: var(--neutral-secondary);
    border-radius: 4px;
  }
`;
const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  gap: 12px;
  background: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  box-shadow: var(--shadow-card);
  border-radius: 12px;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  max-width: 463px;
  width: 100%;
`;
const Leftrow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
`;
const Subtitle = styled.div`
  ${Typography.body_sm}
  margin: 0;
  color: var(--text-secondary);
`;
const Autotext = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 30px;

  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--border-primary);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 14px;
    width: 14px;
    top: 3px;
    left: 3px;
    background-color: var(--text-white);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background: var(--brand-primary);
  }

  input:checked + .slider:before {
    transform: translateX(20px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;
const ProjectName = styled.div`
  max-width: 153px;
  cursor: pointer;
  padding: 1px 7px;
  border-radius: 4px;
  border: 0.5px solid var(--border-primary);
  background-color: var(--background-primary);
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  p {
    ${Typography.body_md};
    color: var(--text-primary);
    margin: 0;
    max-width: 130px;
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    word-break: break-all;
  }
`;
const ArrowIcon = styled.div`
  width: 16px;
  height: 16px;
  cursor: pointer;
  .fill-color {
    width: 16px;
    height: 16px;
    path {
      stroke: var(--text-primary);
    }
  }
  .dropdown-icon {
    transform: rotate(180deg);
  }
`;
const Tag = styled.div`
  cursor: pointer;
  color: var(--brand-primary);
  ${Typography.body_md_medium};
`;
export {
  Title,
  Headline,
  Icon1,
  Inputvalues,
  Groupmodal,
  Groupbody,
  Groupmodel,
  GroupModalhead,
  Modeltitle,
  Todotext,
  Icon,
  Box,
  Row,
  Leftrow,
  Subtitle,
  Autotext,
  ProjectName,
  ArrowIcon,
  Tag
};
