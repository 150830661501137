import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { ROUTES } from '../../../routes/urls';
import {
  Content,
  Headerblock,
  Heading,
  Icon,
  Title,
  Menu as MenuDiv,
  Headerbtn,
  Headerplusbtn,
  Resnavbar
} from './styles';
import { Modalhead, Headline } from '../../customer/styles';
import CreateNewProjectModal from '../../../component/models/createNewProjectModal';
import { clearProjectDetail } from '../../../actions/projectActions';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../component/button';
import Resbutton from '../../../component/resbutton/resbutton';
import ModalCustom from '../../../component/models/modal';
import Deletemodal from '../../../component/models/deleteModel';
import { isEmpty } from 'lodash';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import Responsivnavbar from '../../../component/navbar/responsivenavbar';
import { Dropdown, DropdownItem } from '../../../component/Dropdown';
import { WorkspaceModel } from '../../../mobx/models/workspace';

interface Props {
  workspaceDetails: WorkspaceModel | undefined | null;
}

const Header: React.FC<Props> = (props) => {
  const { workspaceDetails } = props;

  // States
  const [closeModel, setCloseModel] = useState(false);
  const [isOpen, setOpen] = useState(false);
  //use selector state variables
  const projectSelector = useSelector((state: RootReducerInterface) => state.project);
  const { newProjectItem } = projectSelector;
  const { name, customer } = newProjectItem || {};
  // Other variables
  const history = useHistory();
  const dispatch = useDispatch();

  const OpenModal = () => {
    setOpen(true);
  };

  const CloseModal = () => {
    setOpen(false);
    dispatch(clearProjectDetail());
  };

  const Cancel = useCallback(() => {
    if (!isEmpty(name) || !isEmpty(customer)) {
      setCloseModel(true);
    } else {
      setOpen(false);
      dispatch(clearProjectDetail());
    }
  }, [customer, dispatch, name]);

  const onOnkModel = () => {
    setCloseModel(false);
    setOpen(false);
    dispatch(clearProjectDetail());
  };

  const close = () => {
    setCloseModel(false);
  };

  return (
    <>
      <Content>
        <Headerblock>
          <Resnavbar>
            <Responsivnavbar />
          </Resnavbar>
          <Heading>
            <Icon>
              <SVGIcon
                name='projects-icon'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                className='fillColor'
              />
            </Icon>
            <Title>Projects</Title>
          </Heading>
        </Headerblock>
        <MenuDiv>
          <Headerbtn>
            {(workspaceDetails?.isOwner || workspaceDetails?.isAdmin) && (
              <Button
                title='New Project'
                onClick={OpenModal}
                iconName='plus-icon'
                type='button'
                iconSize={18}
                iconViewBox='0 0 18 18'
                iconColor='#fff'
              />
            )}
          </Headerbtn>
          {(workspaceDetails?.isOwner || workspaceDetails?.isAdmin) && (
            <Headerplusbtn>
              <Resbutton onClick={OpenModal} />
            </Headerplusbtn>
          )}
          {(workspaceDetails?.isAdmin || workspaceDetails?.isOwner) && (
            <Dropdown
              activeClassName='active'
              content={
                <Icon className='dot-icon'>
                  <SVGIcon
                    className='doticon'
                    name='three-dots-horizontal-icon'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                  />
                </Icon>
              }>
              <DropdownItem
                label='Archived'
                tone='critical'
                onClick={() => {
                  history.push(ROUTES.ARCHIVE_PROJECT);
                }}
              />
            </Dropdown>
          )}
        </MenuDiv>
      </Content>
      <ModalCustom open={isOpen} onClose={Cancel} width={632}>
        <Modalhead>
          <Headline>Create New Project</Headline>
          <Icon onClick={Cancel}>
            <SVGIcon name='close-icon' width='18' height='18' viewBox='0 0 18 18' fill='none' className='close-icon' />
          </Icon>
        </Modalhead>
        <CreateNewProjectModal onClose={close} handleCloseModal={CloseModal} onClickCancel={Cancel} />
      </ModalCustom>
      <ModalCustom open={closeModel} onClose={close} width={334}>
        <Deletemodal
          isYes={true}
          onClose={close}
          onOk={onOnkModel}
          modaltitle='Confirmation Model'
          description='Are you sure you want close this model?'
        />
      </ModalCustom>
    </>
  );
};

export default Header;
