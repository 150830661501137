import React, { useCallback, useEffect, useRef, useState } from 'react';
import AppLayout from '../../../component/appLayout';
import Button from '../../../component/button';
import {
  Apilink,
  Content,
  Description,
  Details,
  Emptystateapi,
  First,
  Header,
  Heading,
  Icon1,
  Icon2,
  Icons,
  Personaltext,
  Subscriptiongroup
} from './styles';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import Addapimodal from '../../../component/models/addapimodal';
import { useDispatch, useSelector } from 'react-redux';
import { deleteApiKey, getApiKeyList } from '../../../services/settingServices';
import copy from 'clipboard-copy';
import { setSuccessMessage } from '../../../actions/messageActions';
import ModalCustom from '../../../component/models/modal';
import Deletemodal from '../../../component/models/deleteModel';
import Apiloading from '../../../component/loading/apiloading';
import emptyapi from '../../../assets/images/emptystates/emptyapi.svg';
import emptyapidark from '../../../assets/images/emptystates/emptyapidark.svg';
import { captureException } from '../../../services/logService';
import { isEmpty } from '../../../helpers/common';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import EmptyState from '../../../component/emptyState';
import { ResponsiveHeader } from '../SettingHeaderResponsive';
import { useMobileDevice } from '../../../global/useMobile';
import { PersonalHeader } from '../apps/styles';
import { Dropdown, DropdownItem } from '../../../component/Dropdown';

const Apikey: React.FC = () => {
  // Refs
  const buttonRef = useRef<React.RefObject<HTMLDivElement>[]>([]);
  // States
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteModelopen, setDeleteModalOpen] = useState(false);
  const [selectedItemID, setSelectedItemId] = useState('');
  const [deleteLoading, setDeleteLoading] = useState(false);
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { settings: settingsSelector, workspace: workspaceSelector } = stateSelector || {};
  const { workspace } = workspaceSelector;
  const { id: workspace_id } = workspace;
  const { apiKeyList, themeMode } = settingsSelector;
  // Other variables
  const dispatch = useDispatch();
  const isMobile = useMobileDevice();

  const getImageSource = () => (themeMode?.theme === 'dark' ? emptyapidark : emptyapi);

  const handleCreateKeyClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      if (!isEmpty(workspace_id)) await dispatch(getApiKeyList(workspace_id));
    } catch (error) {
      captureException(error);
      console.log('error', error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [dispatch, workspace_id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const Opendeletemodel = () => {
    setDeleteModalOpen(true);
  };

  const onClose = () => {
    setDeleteModalOpen(false);
  };

  const handleCopyClick = useCallback(
    async (text: string) => {
      try {
        await copy(text);
        dispatch(setSuccessMessage('API key copied successfully!'));
      } catch (error) {
        captureException(error);
        console.log('Failed to copy text: ', error);
      }
    },
    [dispatch]
  );

  const deleteAPIKey = useCallback(
    async (id: string) => {
      try {
        setDeleteLoading(true);
        const result = await dispatch(deleteApiKey(id));
        if (result) {
          setDeleteModalOpen(false);
          loadData();
        }
      } catch (error) {
        captureException(error);
        console.log('ERROR', error);
        return;
      } finally {
        setDeleteLoading(false);
        setIsModalOpen(false);
      }
    },
    [dispatch, loadData]
  );

  return (
    <div>
      <AppLayout isPosition={true}>
        <Subscriptiongroup>
          <Header>
            <PersonalHeader>
              {isMobile && <ResponsiveHeader />}
              <Personaltext>API Keys</Personaltext>
            </PersonalHeader>
            <Button title='Create Key' type='button' onClick={handleCreateKeyClick} modelbtn />
          </Header>
          {loading && <Apiloading />}
          {!loading && apiKeyList?.length === 0 && (
            <>
              <Emptystateapi>
                <EmptyState
                  header='No API keys created yet'
                  title=' Explore our'
                  image={getImageSource()}
                  hideButton={true}
                  isApiKeyEmpty={true}
                  linktext=' API Doc'
                  href='https://api.teamcamp.app'
                />
              </Emptystateapi>
            </>
          )}

          {!loading && apiKeyList?.length > 0 && (
            <>
              <Content>
                {apiKeyList?.map((item, index) => {
                  if (!buttonRef?.current[index]) {
                    buttonRef.current[index] = React.createRef<HTMLDivElement>();
                  }
                  return (
                    <First key={item['_id']}>
                      <Details>
                        <Heading>{item?.title}</Heading>
                        <Description>**** ***** **** {item?.apiKey?.slice(-4)}</Description>
                      </Details>
                      <Icons>
                        <Icon1 onClick={() => handleCopyClick(item?.apiKey)}>
                          <SVGIcon name='copy-icon' width='18' height='18' viewBox='0 0 18 18' className='copy-icon' />
                        </Icon1>

                        <Dropdown
                          activeClassName='active'
                          content={
                            <Icon2 ref={buttonRef?.current[index]} onClick={() => setSelectedItemId(item['_id'])}>
                              <SVGIcon
                                name='more-icon'
                                width='18'
                                height='18'
                                viewBox='0 0 18 18'
                                fill='none'
                                className='more-icon'
                              />
                            </Icon2>
                          }
                          trigger='click'>
                          <DropdownItem label='Delete' tone='critical' onClick={() => Opendeletemodel()} />
                        </Dropdown>
                      </Icons>
                    </First>
                  );
                })}
              </Content>
              <Apilink>
                Explore our
                <a href='https://api.teamcamp.app' target='_blank' rel='noreferrer'>
                  API Doc
                </a>
              </Apilink>
            </>
          )}
          <ModalCustom open={isModalOpen} onClose={handleCloseModal} width={462}>
            <Addapimodal onCancel={handleCloseModal} loadData={loadData} workspace_id={workspace_id} />
          </ModalCustom>
          <ModalCustom open={deleteModelopen} onClose={onClose} width={334}>
            <Deletemodal
              onClose={onClose}
              onOk={() => deleteAPIKey(selectedItemID)}
              loading={deleteLoading}
              modaltitle='Delete Apikey?'
              description='Are you sure you want to delete this apikey?'
            />
          </ModalCustom>
        </Subscriptiongroup>
      </AppLayout>
    </div>
  );
};
export default Apikey;
