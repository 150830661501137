import React, { memo, ReactNode } from 'react';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import {
  AvtarDiv,
  CheckBox,
  CheckMarkIcon,
  CrossIconWrapper,
  Description,
  DescriptionDiv,
  DropDiv,
  Item,
  ItemMainDiv,
  Label,
  Suffix
} from './style';
import Avatar from '../../avatar/Avatar';
interface DropdownItemProps {
  onClick?: (event?: React.MouseEvent, value?: number | string) => void;
  children?: React.ReactNode;
  label?: string;
  iconName?: string;
  iconSize?: number;
  isSuffix?: string | ReactNode; //label text at last in dropdown
  description?: string;
  tone?: 'primary' | 'critical'; //color of label
  isCheckBox?: boolean;
  justifyAlign?: 'center' | 'spaceBetween'; //alignment of label
  align?: 'center' | 'flex-start'; // item align
  isAvatar?: boolean; //condition to show avatar
  avtarSrc?: string;
  isSuffixCross?: boolean; //hover on label close icon show
  isSelected?: boolean; //label selected
  isChecked?: boolean; //checkbox selected state
  iconViewBox?: string; // viewBox for icon if different from default 0 0 16 16
  iconTone?: 'fill' | 'stroke';
  isActive?: boolean;
  onSuffixClick?: () => void; //onclick of cross icon
}

const DropdownItem = memo(
  ({
    onClick,
    children,
    label,
    iconName,
    iconSize = 16,
    isSuffix,
    description,
    tone = 'primary',
    isCheckBox = false,
    justifyAlign = 'spaceBetween',
    align = 'center',
    isAvatar = false,
    avtarSrc,
    isSuffixCross = false,
    isSelected = false,
    isChecked = false,
    iconViewBox = `0 0 ${iconSize} ${iconSize}`,
    iconTone = 'fill',
    isActive = false,
    onSuffixClick
  }: DropdownItemProps) => {
    return (
      <ItemMainDiv className='reference-item'>
        <Item
          isActive={isActive}
          className='dropdown-item'
          onClick={(e: any) => onClick && onClick(e)}
          justifyAlign={justifyAlign}
          iconTone={iconTone}>
          <DropDiv align={align}>
            {children}
            {isCheckBox && (
              <CheckBox
                type='checkbox'
                onClick={(e) => {
                  e.stopPropagation();
                  onClick?.(e);
                }}
                checked={isChecked}
              />
            )}
            {isAvatar && (
              <AvtarDiv>
                <Avatar imgSrc={avtarSrc || ''} name={label || ''} size={18} />
              </AvtarDiv>
            )}
            {iconName && <SVGIcon name={iconName} width={`${iconSize}`} height={`${iconSize}`} viewBox={iconViewBox} />}
            <DescriptionDiv>
              <Label tone={tone}>{label}</Label>
              {description && <Description>{description}</Description>}
            </DescriptionDiv>
          </DropDiv>
          {isSuffix && <Suffix>{isSuffix}</Suffix>}
          {isSuffixCross && (
            <CrossIconWrapper
              onClick={(e) => {
                onSuffixClick?.();
              }}>
              <SVGIcon
                name='cross-icon'
                width='10'
                height='10'
                viewBox='0 0 10 10'
                fill='var(--background-secondary)'
                stroke='var(--text-secondary)'
              />
            </CrossIconWrapper>
          )}
          {isSelected && (
            <CheckMarkIcon>
              <SVGIcon
                name='correct-sign-icon'
                width='18'
                height='18'
                viewBox='0 0 16 16'
                stroke='var(--text-primary)'
              />
            </CheckMarkIcon>
          )}
        </Item>
      </ItemMainDiv>
    );
  }
);

export default DropdownItem;
