import React from 'react';
import {
  Bottom,
  Bottomcontent,
  Box,
  Circle,
  Content,
  Content1,
  Div,
  Left,
  Leftline,
  Main,
  Right,
  Top,
  User,
  Users
} from './styles';
import SVGIcon from '../../../assets/images/svg/SVGIcon';

export default function Milestoneloading() {
  return (
    <div>
      <Main>
        <Box>
          <Content>
            <Top>
              <Left>
                <Div>
                  <Circle className='loading-animation'></Circle>
                </Div>
                <Leftline className='loading-animation'></Leftline>
              </Left>
              <Right>
                <Users>
                  <User className='loading-animation' />
                  <User className='loading-animation' />
                  <User className='loading-animation' />
                  <User className='loading-animation' />
                </Users>
                <SVGIcon
                  className='dot-icon'
                  name='milestone-three-dot-verticle-icon'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                />
              </Right>
            </Top>
            <Bottom className='loading-animation'></Bottom>
          </Content>
        </Box>
        <Box>
          <Content1>
            <Top>
              <Left>
                <Div>
                  <Circle className='loading-animation'></Circle>
                </Div>
                <Leftline className='loading-animation'></Leftline>
              </Left>
              <Right>
                <Users>
                  <User className='loading-animation' />
                  <User className='loading-animation' />
                  <User className='loading-animation' />
                  <User className='loading-animation' />
                </Users>
                <SVGIcon
                  className='dot-icon'
                  name='milestone-three-dot-verticle-icon'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                />
              </Right>
            </Top>
            <Bottom className='loading-animation'></Bottom>
            <Bottomcontent>
              <Left className='first'>
                <Div>
                  <Circle className='loading-animation'></Circle>
                </Div>
                <Leftline className='first-line loading-animation'></Leftline>
              </Left>
              <Left>
                <Div>
                  <Circle className='loading-animation'></Circle>
                </Div>
                <Leftline className='second-line loading-animation'></Leftline>
              </Left>
              <Left>
                <Div>
                  <Circle className='loading-animation'></Circle>
                </Div>
                <Leftline className='third-line loading-animation'></Leftline>
              </Left>
              <Left>
                <Div>
                  <Circle className='loading-animation'></Circle>
                </Div>
                <Leftline className='forth-line loading-animation'></Leftline>
              </Left>
            </Bottomcontent>
          </Content1>
        </Box>
      </Main>
    </div>
  );
}
