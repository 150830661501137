import { AnyAction, Dispatch } from 'redux';
import { clearResponseMessage, setErrorMessage } from '../actions/messageActions';
import {
  setInvoiceReportData,
  setPaymentReportData,
  setProjectReportData,
  setReportLoader
} from '../actions/reportActions';
import { getAPIErrorReason } from '../helpers/common';
import axios from 'axios';
import moment from 'moment';
import { REACT_APP_TEAMCAMP_APIURL } from '../global/environment';
import UserPreferenceSingleton from '../helpers/userPreferenceSingleton';
import { captureException } from './logService';

export const getPaymentReportData: any =
  (workspace_id: string, start_date: Date, end_date: Date) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setReportLoader(true));
      const params = {
        companyId: workspace_id,
        start_date: moment(start_date).format('YYYY-MM-DD'),
        end_date: moment(end_date).format('YYYY-MM-DD')
      };
      const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/report/paymentReport`, { params });
      dispatch(setPaymentReportData(response?.data));
      return response?.data;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to get payment report data please try again';
      captureException(error);
      dispatchReportError(error, dispatch);
      return undefined;
    } finally {
      dispatch(setReportLoader(false));
    }
  };

export const getInvoiceReportData: any = (workspace_id: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setReportLoader(true));
    const params = {
      companyId: workspace_id
    };
    const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/report/invoiceReport`, { params });
    dispatch(setInvoiceReportData(response?.data));
    return response?.data;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to get Invoice report data please try again';
    captureException(error);
    dispatchReportError(error, dispatch);
    return undefined;
  } finally {
    dispatch(setReportLoader(false));
  }
};

export const getProjectReportData: any = (workspace_id: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setReportLoader(true));
    const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
    const params = {
      companyId: workspace_id,
      userId: userDetails?.id
    };
    const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/report/projectReport`, { params });
    dispatch(setProjectReportData(response?.data));
    return response?.data;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to get Project report data please try again';
    captureException(error);
    dispatchReportError(error, dispatch);
    return undefined;
  } finally {
    dispatch(setReportLoader(false));
  }
};

function dispatchReportError(msg: string, dispatch: Dispatch<AnyAction>) {
  dispatch(setErrorMessage(msg));
}
