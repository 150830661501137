import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  DropboxModal,
  ModalBlock,
  ModalButton,
  ModalContent,
  ModalForm,
  ModalHeader,
  ModalInput,
  ModalTitle
} from './style';
import Button from '../../button';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { Icon } from '../deleteModel/styles';
import { SnapListInterface } from '../../../interfaces/snapInterface';
import { renameSnap } from '../../../services/snapService';

interface SnapData {
  onClose: () => void;
  loadData: () => void;
  snapData: SnapListInterface;
}

const SnapRenameModal: React.FC<SnapData> = (props) => {
  const { snapData } = props;
  //States
  const [loading, setLoading] = useState(false);
  //Other variable
  const dispatch = useDispatch();
  const fileNameWithoutExtension =
    snapData?.Name && snapData?.Name?.split('.')?.length > 1
      ? snapData?.Name?.split('.').slice(0, -1).join('.')
      : snapData?.Name || '';
  const [snapName, setSnapName] = useState(fileNameWithoutExtension);
  
  const onRenameFile = useCallback(
    async (e: React.SyntheticEvent) => {
      try {
        e.preventDefault();
        setLoading(true);
        const payload = {
          Name: snapName
        };
        await dispatch(renameSnap(snapData['_id'], payload));
        props?.loadData();
      } catch (error) {
        console?.log('error', error);
      } finally {
        setLoading(false);
        props?.onClose();
      }
    },
    [dispatch, props, snapData, snapName]
  );

  return (
    <>
      <DropboxModal>
        <ModalBlock>
          <ModalHeader>
            <ModalTitle>Rename File</ModalTitle>
          </ModalHeader>
          <Icon onClick={props?.onClose}>
            <SVGIcon name='modal-cross-sign-icon' width='18' height='18' viewBox='0 0 18 18' className='fillColor' />
          </Icon>
        </ModalBlock>
        <ModalContent onSubmit={onRenameFile}>
          <ModalForm>
            <ModalInput
              type='text'
              placeholder='Enter File name'
              value={snapName}
              onChange={(e: { target: { value: string } }) => setSnapName(e.target.value)}></ModalInput>
          </ModalForm>
          <ModalButton>
            <Button type={'submit'} title={'Rename'} isLoading={loading} modelbtn={true} />
          </ModalButton>
        </ModalContent>
      </DropboxModal>
    </>
  );
};

export default SnapRenameModal;
