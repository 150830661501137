import { useCallback, useEffect, useState } from 'react';
import { startOfWeek, getWeek, addWeeks, subWeeks, subMonths, addMonths } from 'date-fns';
import { Buttons, Cards, Dates, Header, Icon, Leftarrow, Leftheader, Main, Rightheader } from './styles';
import SVGIcon from '../../../../../assets/images/svg/SVGIcon';
import Weekviewcard from '../../../../../component/weekviewCard';
import moment from 'moment';
import {
  DataCommentsInterface,
  MonthTasksDataInterface,
  TaskDetailsInterface
} from '../../../../../interfaces/TaskInterface';
import { useDispatch, useSelector } from 'react-redux';
import {
  createNewComment,
  getMyTaskDetailsData,
  getTaskGroupList,
  loadProjectTasks,
  updateTaskDetails
} from '../../../../../services/taskServices';
import Monthview from '../../../../../component/monthView';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import UserPreferenceSingleton from '../../../../../helpers/userPreferenceSingleton';
import { nanoid } from 'nanoid';
import { COMMENT_TYPE, SHOW_TASK_BY_ORDER } from '../../../../../global/constants';
import { getFileContent, isEmpty } from '../../../../../helpers/common';
import { trackAnalyticActivity } from '../../../../../services/analyticsService';
import { useHotkeys } from 'react-hotkeys-hook';
import ModalCustom from '../../../../../component/models/modal';
import CreateTaskModal from '../../../../../component/models/createTaskModal';
import { clearCreateTaskInput, setCreateTaskInput, updateCreateTaskInput } from '../../../../../actions/taskActions';
import { TaskGroupInterface } from '../../../../../interfaces/TaskInterface';
import { File, ProjectDetailInterface } from '../../../../../interfaces/ProjectInterface';
import { WorkspaceInterfase } from '../../../../../interfaces/WorkspaceInterface';
import Deletemodal from '../../../../../component/models/deleteModel';
import { captureException } from '../../../../../services/logService';
import { RootReducerInterface } from '../../../../../interfaces/RootReducerInterface';
import { TASK_ANALYTICS } from '../../../../../global/analyticsConstants';

// eslint-disable-next-line @typescript-eslint/no-redeclare
declare type DropResult = typeof import('react-beautiful-dnd');

interface Props {
  propsLoading: boolean;
  loadData: () => void;
  loadWeekWiseTasks: (item: string) => void;
  weekTasks: TaskDetailsInterface[];
  currentMonth: Date;
  setCurrentMonth: (date: Date) => void;
  monthData: MonthTasksDataInterface[];
  setWeekTasks?: (tasks: TaskDetailsInterface[]) => void;
  taskGroups: TaskGroupInterface[];
  currentProject: ProjectDetailInterface;
  orderByStatus?: number;
  workspaceDetails?: WorkspaceInterfase;
}

function CalendarView(props: Props) {
  const {
    loadData,
    loadWeekWiseTasks,
    weekTasks,
    currentMonth,
    setCurrentMonth,
    monthData,
    setWeekTasks,
    orderByStatus,
    taskGroups,
    currentProject,
    workspaceDetails
  } = props;
  const dispatch = useDispatch();
  // States
  // const [taskDropdown, setTaskDropdown] = useState(false);

  // For display montheView functionality manage below state
  // const [monthView, setMonthView] = useState(false);
  const [startDayOfWeek, setStartDayOfWeek] = useState(moment(startOfWeek(new Date(), { weekStartsOn: 1 })));
  const [isOpenFromShortcut, setIsOpenFromShortcut] = useState(false);
  const [selectedItem, setSelectedItem] = useState<TaskDetailsInterface>();
  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  //use selector state variables
  const tasksSelector = useSelector((state: RootReducerInterface) => state.task);
  const { taskFilter } = tasksSelector;
  // Other variables
  // For monthView variable manage static false
  const monthView = false;

  const close = useCallback(() => {
    setIsOpenFromShortcut(false);
    setOpen(false);
    dispatch(clearCreateTaskInput());
  }, [dispatch]);

  const loadTasksData = useCallback(async () => {
    if (!isEmpty(workspaceDetails?.id)) {
      await dispatch(loadProjectTasks(currentProject?.id));
      loadWeekWiseTasks(moment(startOfWeek(currentMonth, { weekStartsOn: 1 })).format('YYYY-MM-DD'));
      loadData();
    }
  }, [currentMonth, currentProject?.id, dispatch, loadData, loadWeekWiseTasks, workspaceDetails?.id]);

  useEffect(() => {
    loadWeekWiseTasks(moment(startOfWeek(new Date(), { weekStartsOn: 1 })).format('YYYY-MM-DD'));
  }, [loadWeekWiseTasks]);

  const onClickToday = useCallback(() => {
    setCurrentMonth(new Date());
    const startDayOfWeekDate = moment(startOfWeek(new Date(), { weekStartsOn: 1 }));
    setStartDayOfWeek(moment(startDayOfWeekDate));
    loadWeekWiseTasks(startDayOfWeekDate.format('YYYY-MM-DD'));
  }, [loadWeekWiseTasks, setCurrentMonth]);

  const isCurrentWeek = (): boolean => {
    const startOfWeekDisplayed = moment(startDayOfWeek);
    const endOfWeekDisplayed = moment(startOfWeekDisplayed).add(6, 'days');
    const today = moment();
    return today.isBetween(startOfWeekDisplayed, endOfWeekDisplayed, 'day', '[]');
  };

  useHotkeys('c', () => {
    setTimeout(() => {
      setIsOpenFromShortcut(true);
      setOpen(true);
      dispatch(updateCreateTaskInput({ propsName: 'projectId', value: currentProject }));
      dispatch(updateCreateTaskInput({ propsName: 'priority', value: currentProject?.priority?.default || 0 }));
      if (taskGroups?.length > 0) {
        dispatch(updateCreateTaskInput({ propsName: 'groupId', value: taskGroups[0] }));
      }
    }, 100);
  });

  // const handleTaskOpen = (flag: boolean) => {
  //   setTaskDropdown(flag);
  // };

  const changeWeekHandle = (btnType: string, isMonthView: boolean) => {
    if (!isMonthView) {
      let updatedMonth, updatedWeek;
      if (btnType === 'prev') {
        updatedMonth = subWeeks(currentMonth, 1);
        updatedWeek = getWeek(subWeeks(currentMonth, 1));
      } else if (btnType === 'next') {
        updatedMonth = addWeeks(currentMonth, 1);
        updatedWeek = getWeek(addWeeks(currentMonth, 1));
      }
      if (updatedMonth && updatedWeek) {
        setCurrentMonth(updatedMonth);
        const startDayOfWeekDate = moment(startOfWeek(updatedMonth, { weekStartsOn: 1 }));
        setStartDayOfWeek(startDayOfWeekDate);
        loadWeekWiseTasks(startDayOfWeekDate.format('YYYY-MM-DD'));
      }
    } else {
      let updatedMonth;
      if (btnType === 'prev') {
        updatedMonth = subMonths(currentMonth, 1);
      } else if (btnType === 'next') {
        updatedMonth = addMonths(currentMonth, 1);
      }
      if (updatedMonth) {
        setCurrentMonth(updatedMonth);
        const startDayOfWeekDate = moment(startOfWeek(updatedMonth, { weekStartsOn: 1 }));
        setStartDayOfWeek(startDayOfWeekDate);
      }
    }
  };

  const getPriorityValue = useCallback((taskPriority?: number, projectPriorityDefault?: number) => {
    if (taskPriority !== undefined) return taskPriority;
    if (projectPriorityDefault !== undefined) return projectPriorityDefault;
    return 0;
  }, []);

  const onClickEdit = useCallback(
    async (task: TaskDetailsInterface) => {
      const taskData = await dispatch(getMyTaskDetailsData(task?.id));
      const contentData = getFileContent(taskData?.description);
      const taskClone = JSON.parse(JSON.stringify(taskData));
      taskClone.description = `${taskData?.description || ''}<p></p>`;
      taskClone.uploadedFiles = contentData?.uploadedFiles;
      dispatch(setCreateTaskInput(taskClone));
      await dispatch(getTaskGroupList(currentProject?.id, true));
      if (!isEmpty(task?.groupId)) {
        const groupId = taskGroups?.find((group) => group.id === task?.groupId);
        dispatch(updateCreateTaskInput({ propsName: 'groupId', value: groupId }));
      }
      dispatch(updateCreateTaskInput({ propsName: 'projectId', value: currentProject }));
      dispatch(
        updateCreateTaskInput({
          propsName: 'priority',
          value: getPriorityValue(taskData?.priority, currentProject?.priority?.default)
        })
      );
      setOpen(true);
    },
    [currentProject, dispatch, getPriorityValue, taskGroups]
  );

  const onClickClone = useCallback(
    async (task: TaskDetailsInterface) => {
      const taskData = await dispatch(getMyTaskDetailsData(task?.id));
      const taskFiles = taskData?.Files?.map((item: File) => {
        const { __typename, ...other } = item;
        return other;
      });
      const cloneTaskdetails = {
        Files: taskFiles,
        description: `${taskData?.description || ''}<p></p>`,
        name: taskData?.name,
        groupId: taskData?.groupId,
        projectId: taskData?.projectId,
        priority: taskData?.priority,
        users: taskData?.users,
        labels: taskData?.labels,
        estimate: taskData?.estimate,
        milestoneId: taskData?.milestoneId,
        statusId: taskData?.statusId,
        parentTaskId: !isEmpty(taskData?.parentTaskId) ? taskData?.parentTaskId : null
      };
      dispatch(setCreateTaskInput(cloneTaskdetails));
      if (!isEmpty(task?.groupId)) {
        const groupId = taskGroups?.find((group) => group.id === task?.groupId);
        dispatch(updateCreateTaskInput({ propsName: 'groupId', value: groupId }));
      }
      dispatch(updateCreateTaskInput({ propsName: 'projectId', value: currentProject }));
      await dispatch(getTaskGroupList(currentProject?.id, true));
      dispatch(
        updateCreateTaskInput({
          propsName: 'priority',
          value:
            taskData?.priority !== undefined
              ? taskData.priority
              : currentProject?.priority?.default !== undefined
              ? currentProject.priority.default
              : 0
        })
      );
      setOpen(true);
    },
    [dispatch, currentProject, taskGroups]
  );

  const OpenModal = (item: TaskDetailsInterface) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const Cancel = () => {
    setIsModalOpen(false);
    setSelectedItem(undefined);
  };

  const deleteTask = useCallback(
    async (task: TaskDetailsInterface) => {
      try {
        setDeleteLoading(true);
        const payloadTask = { isArchived: true, projectId: task?.projectId };
        const response = await dispatch(updateTaskDetails(task?.id, payloadTask));
        setIsModalOpen(false);
        setSelectedItem(undefined);
        if (response) {
          loadData();
        }
      } catch (error) {
        captureException(error);
        console.log('error', error);
      } finally {
        setDeleteLoading(false);
      }
    },
    [dispatch, loadData]
  );

  const renderDays = useCallback(() => {
    const days = [];
    let startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    for (let i = 0; i < 7; i++) {
      const loopDate = moment(startDate).add(i, 'days').format('DD-MM-YYYY');
      let thisDayTasks;
      let completedTasks;
      if (orderByStatus === SHOW_TASK_BY_ORDER.PRIORITY) {
        const priorityData = [1, 2, 3, 4, 0];
        thisDayTasks = weekTasks
          ?.filter((x) => moment(x?.dueDate).format('DD-MM-YYYY') === loopDate && !x?.status)
          ?.toSorted(
            (a: TaskDetailsInterface, b: TaskDetailsInterface) =>
              priorityData.indexOf(a?.priority || 0) - priorityData.indexOf(b?.priority || 0)
          );
        completedTasks = weekTasks
          ?.filter((x) => moment(x?.dueDate).format('DD-MM-YYYY') === loopDate && x?.status)
          .toSorted(
            (a: TaskDetailsInterface, b: TaskDetailsInterface) =>
              priorityData.indexOf(a?.priority || 0) - priorityData.indexOf(b?.priority || 0)
          );
      } else if (orderByStatus === SHOW_TASK_BY_ORDER.CREATED_DATE) {
        thisDayTasks = weekTasks
          ?.filter((x) => moment(x?.dueDate).format('DD-MM-YYYY') === loopDate && !x?.status)
          .toSorted((a: { createdOn: string }, b: { createdOn: string }) => {
            const dateA: Date = a?.createdOn ? new Date(a?.createdOn) : new Date();
            const dateB: Date = b?.createdOn ? new Date(b?.createdOn) : new Date();
            return dateA.getTime() - dateB.getTime();
          });
        completedTasks = weekTasks
          ?.filter((x) => moment(x?.dueDate).format('DD-MM-YYYY') === loopDate && x?.status)
          .toSorted((a: { createdOn: string }, b: { createdOn: string }) => {
            const dateA: Date = a?.createdOn ? new Date(a?.createdOn) : new Date();
            const dateB: Date = b?.createdOn ? new Date(b?.createdOn) : new Date();
            return dateA.getTime() - dateB.getTime();
          });
      }
      const isoDate = moment(startDate).add(i, 'days').toDate().toString();
      if (taskFilter?.showRecentlyCompletedTask) {
        completedTasks?.sort(
          (a: TaskDetailsInterface, b: TaskDetailsInterface) =>
            new Date(b?.updatedTime || '').getTime() - new Date(a?.updatedTime || '').getTime()
        );
      }

      days.push(
        <Weekviewcard
          key={i}
          loadData={loadTasksData}
          dayname={weekDays[i]}
          date={Number(moment(startDate).add(i, 'days').format('DD'))}
          tasks={thisDayTasks || []}
          completedTasks={completedTasks || []}
          datestring={isoDate}
          onClickEdit={onClickEdit}
          onClickClone={onClickClone}
          OpenModal={OpenModal}
        />
      );
    }
    return days;
  }, [
    currentMonth,
    loadTasksData,
    onClickClone,
    onClickEdit,
    orderByStatus,
    taskFilter?.showRecentlyCompletedTask,
    weekTasks
  ]);

  // const onClickMonthWeekView = useCallback((value: boolean) => {
  //   setMonthView(value);
  //   setTaskDropdown(false);
  // }, []);

  const onDragEnd = useCallback(
    async (result: DropResult) => {
      if (!result.destination) {
        return;
      }
      const sourceDueDate = result.source.droppableId;
      const destinationDueDate = result.destination.droppableId;
      if (sourceDueDate !== destinationDueDate) {
        const updatedDate = moment(destinationDueDate).utc().format();
        const taskIndex = weekTasks?.findIndex((item) => item?.id === result.draggableId);
        if (taskIndex === -1) return;
        const updateTask: TaskDetailsInterface = { ...weekTasks[taskIndex] };
        const payload = {
          previousDueDate: updateTask?.dueDate,
          dueDate: updatedDate
        };

        let updatedTasks = [...weekTasks];
        updatedTasks[taskIndex] = {
          ...updateTask,
          dueDate: updatedDate
        };

        setWeekTasks && setWeekTasks(updatedTasks);
        const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
        const newComment: DataCommentsInterface = {
          _id: nanoid(),
          CreatedBy: userDetails?.id,
          CreatedTime: new Date().toISOString(),
          UpdatedBy: userDetails?.id,
          UpdatedTime: new Date().toISOString(),
          Type: COMMENT_TYPE.SET_DUE_DATE,
          DueDate: updatedDate
        };
        if (!isEmpty(payload?.previousDueDate)) {
          newComment['Type'] = COMMENT_TYPE.CHANGE_DUE_DATE;
          newComment['PreviousDueDate'] = payload?.previousDueDate;
        }

        const payloadTask = { dueDate: payload?.dueDate, projectId: updateTask?.projectId };
        const response = await dispatch(updateTaskDetails(updateTask?.id, payloadTask));

        const commentPayload: DataCommentsInterface = {
          Type: COMMENT_TYPE.SET_DUE_DATE,
          DueDate: updatedDate
        };
        if (!isEmpty(payload?.previousDueDate)) {
          commentPayload['Type'] = COMMENT_TYPE.CHANGE_DUE_DATE;
          commentPayload['PreviousDueDate'] = payload?.previousDueDate;
        }

        const resultRes = await dispatch(createNewComment(updateTask?.id, commentPayload));

        if (response && resultRes) {
          if (newComment.Type === COMMENT_TYPE.SET_DUE_DATE) trackAnalyticActivity(TASK_ANALYTICS.SCHEDULED);
          else trackAnalyticActivity(TASK_ANALYTICS.RESCHEDULED);
          loadTasksData();
        }
      }
    },
    [dispatch, loadTasksData, setWeekTasks, weekTasks]
  );

  return (
    <div>
      <Main>
        <Header>
          <Leftheader>
            {/* Week view */}
            {monthView ? (
              <Dates>{moment(currentMonth).format('MMM')} 2024</Dates>
            ) : (
              <Dates>
                {startDayOfWeek.format('DD MMM')} - {moment(startDayOfWeek).add(6, 'days').format('DD MMM')},{' '}
                {currentMonth.getFullYear()}
              </Dates>
            )}
            {/* Month view */}
            <Icon>
              <Leftarrow onClick={() => changeWeekHandle('prev', monthView)}>
                <SVGIcon name='left-arrow-icon' width='17' height='17' viewBox='0 0 17 17' className='arrow-icon' />
              </Leftarrow>
              <Leftarrow onClick={() => changeWeekHandle('next', monthView)}>
                <SVGIcon
                  name='right-arrow-icon'
                  width='17'
                  height='17'
                  viewBox='0 0 17 17'
                  className='right-arrow-icon'
                  stroke='var(--text-secondary)'
                />
              </Leftarrow>
            </Icon>
          </Leftheader>
          <Rightheader>
            {!isCurrentWeek() && <Buttons onClick={onClickToday}>Today</Buttons>}
            {/* calander view in weekview monthview dropdown hide for now */}
            {/* <Dropdown
              trigger={['click']}
              onOpenChange={handleTaskOpen}
              placement='bottomRight'
              dropdownRender={() => (
                <>
                  <Viewdropdown>
                    <Menu className='menuStyle'>
                      <Menu.Item className='menuitem' onClick={() => onClickMonthWeekView(false)}>
                        Week view
                      </Menu.Item>
                      <Menu.Item className='menuitem' onClick={() => onClickMonthWeekView(true)}>
                        Month view
                      </Menu.Item>
                    </Menu>
                  </Viewdropdown>
                </>
              )}>
              <Status>
                <p>{monthView ? 'Month view' : 'Week view'}</p>
                <SVGIcon
                  name='dropdown-icon'
                  width='20'
                  height='20'
                  viewBox='0 0 24 24'
                  fill='none'
                  className={taskDropdown ? 'dropdown-icon fill-color' : 'fill-color'}
                />
              </Status>
            </Dropdown> */}
          </Rightheader>
        </Header>
        {/* Week view and Month view */}
        {monthView ? (
          <Monthview currentMonth={currentMonth} monthData={monthData} />
        ) : (
          <DragDropContext onDragEnd={onDragEnd}>
            <Cards>{renderDays()}</Cards>
          </DragDropContext>
        )}
        <ModalCustom open={open} onClose={close} isCreateTask={true}>
          <CreateTaskModal loadData={loadTasksData} onCancel={close} isFromShortcut={isOpenFromShortcut} />
        </ModalCustom>
        <ModalCustom open={isModalOpen} onClose={Cancel} width={334}>
          <Deletemodal
            onClose={Cancel}
            onOk={() => selectedItem && deleteTask(selectedItem)}
            loading={deleteLoading}
            modaltitle='Delete Task?'
            description='Are you sure you want to delete this task?'
          />
        </ModalCustom>
      </Main>
    </div>
  );
}

export default CalendarView;
