import React from 'react';
import AppLayout from '../../../component/appLayout';
import {
  ActiveTag,
  BottomContent,
  Card,
  Cards,
  CardSection,
  Content,
  Details,
  Dot,
  Doticon,
  Group,
  Header,
  Icon,
  LeftContent,
  Personaltext,
  SessionContent,
  SubHeader,
  TitleSection
} from './styles';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import Button from '../../../component/button';

// card data
const deviceCardData = [
  {
    iconName: 'mobile-device-icon',
    title: 'Teamcamp iOS',
    location: 'Surat, IN',
    lastSeen: 'Last seen 7 days ago',
    iconClassName: 'svg-icon'
  },
  {
    iconName: 'chrome-icon',
    title: 'Chrome on macOS',
    location: 'Surat, IN',
    lastSeen: 'Last seen 1 month ago',
    hasRevokeButton: true,
    iconClassName: 'svg-stroke-color'
  },
  {
    iconName: 'desktop-icon',
    title: 'Teamcamp Desktop on Windows',
    location: 'Ahmedabad, IN',
    lastSeen: 'Last seen 2 months ago',
    iconClassName: 'svg-icon'
  },
  {
    iconName: 'arc-icon',
    title: 'Arc on macOS',
    location: 'Ahmedabad, IN',
    lastSeen: 'Last seen 3 months ago',
    iconClassName: 'arc-icon'
  }
];
interface Props {
  iconName: string;
  title: string;
  location: string;
  lastSeen: string;
  hasRevokeButton?: boolean;
  iconClassName?: string;
}
// card component
const DeviceCard = (props: Props) => (
  <Cards>
    <LeftContent>
      <Icon>
        <SVGIcon name={props.iconName} width='18' height='18' viewBox='0 0 18 18' className={props.iconClassName} />
      </Icon>
      <TitleSection>
        <h2>{props.title}</h2>
        <Details>
          <p>{props.location}</p>
          <Doticon>
            <Dot />
          </Doticon>
          <p>{props.lastSeen}</p>
        </Details>
      </TitleSection>
    </LeftContent>
    {props.hasRevokeButton && <Button title='Revoke' secondary hasNotBoxshadow smallbutton />}
  </Cards>
);
function SecurityAccess() {
  return (
    <AppLayout isPosition={true}>
      <Group>
        <Header>
          <Personaltext>Security and access</Personaltext>
        </Header>
        <BottomContent>
          <SessionContent>
            <Content>
              <h2>Sessions</h2>
              <p>Devices logged into your account</p>
            </Content>
            <Card>
              <LeftContent>
                <Icon>
                  <SVGIcon name='desktop-icon' width='18' height='18' viewBox='0 0 18 18' className='svg-icon' />
                </Icon>
                <TitleSection>
                  <h2>Teamcamp Desktop on macOS</h2>
                  <p>Surat, IN</p>
                </TitleSection>
              </LeftContent>
              <ActiveTag>Active</ActiveTag>
            </Card>
          </SessionContent>
          <SessionContent>
            <SubHeader>
              <h2>Other sessions</h2>
              <Button title='Revoke all' secondary={true} hasNotBoxshadow smallbutton={true} />
            </SubHeader>
            <CardSection>
              {/* card ui */}
              {deviceCardData.map((item) => (
                <DeviceCard key={item.title} {...item} />
              ))}
            </CardSection>
          </SessionContent>
        </BottomContent>
      </Group>
    </AppLayout>
  );
}

export default SecurityAccess;
