import styled, { css } from 'styled-components';

interface Props {
  isMarginTop?: boolean;
  isMember?: boolean;
}
const Main = styled.div<Props>`
  .bottom-loading {
    margin-top: 31px;
  }
  .second-line {
    margin-top: 12px;
  }
  .second-bottom {
    margin: 9px 0 0 12px;
    gap: 21px;
  }
  .loading-animation {
    overflow: hidden;
  }

  .loading-animation::before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    animation: loading 1s infinite;
    background: linear-gradient(to right, transparent, var(--background-tertiary), transparent);
  }

  @keyframes loading {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  ${(props: Props) =>
    props.isMarginTop &&
    css`
      @media (max-width: 449px) {
        margin-top: 72px;
      }
    `}
  ${(props: Props) =>
    props.isMember &&
    css`
      @media (max-width: 449px) {
        margin-top: 0;
      }
    `}
`;

const Top = styled.div`
  border-radius: 6px;
  width: 100px;
  height: 12px;
  background-color: var(--background-muted);
  margin-left: 12px;
`;

const Line = styled.div`
  width: 918px;
  height: 1px;
  background-color: var(--background-muted);
  margin-top: 6px;
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin: 12px 0 0 12px;
`;
const First = styled.div`
  display: flex;
  gap: 10px;
  .second {
    width: 520px;
    @media (max-width: 449px) {
      width: 360px;
    }
  }
  .third {
    width: 280px;
  }
  .forth {
    width: 170px;
  }
`;

const Left = styled.div`
  border-radius: 33px;
  width: 14px;
  height: 14px;
  background-color: var(--background-muted);
`;
const Right = styled.div`
  border-radius: 6px;
  width: 480px;
  height: 12px;
  background-color: var(--background-muted);
  @media (max-width: 449px) {
    width: 360px;
  }
`;

export { Main, Top, Line, Bottom, First, Left, Right };
