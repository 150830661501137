import styled from 'styled-components';
import { Typography } from '../../../global/theme';

const Group = styled.div`
  width: 100%;
  max-width: 662px;
  margin: 0 auto;
  margin-bottom: 24px;
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 0 12px;
  width: 100%;
  max-width: 662px;
  margin: 0 auto;
  align-items: center;
`;
const Personaltext = styled.div`
  ${Typography.heading_lg}
  color: var(--text-primary);
`;
const Main = styled.div`
  border-radius: 8px;
  border: 0.5px solid var(--border-primary);
  background: var(--background-primary);
  padding: 11px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 186px;
  @media (max-width: 449px) {
    max-width: 154px;
  }
  @media (max-width: 375px) {
    max-width: 139px;
  }
`;
const Title = styled.div`
  ${Typography.body_md_medium}
  color: var(--text-primary);
`;
const Count = styled.div`
  ${Typography.heading_md}
  color: var(--text-primary);
`;
const Total = styled.div`
  display: flex;
  gap: 16px;
  @media (max-width: 449px) {
    flex-wrap: wrap;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Storage = styled.div`
  width: 100%;
  max-width: 622px;
  border: 0.5px solid var(--border-primary);
  border-radius: 12px;
  padding: 19px;
  background-color: var(--background-primary);
  box-shadow: var(--shadow-card);
  display: flex;
  gap: 24px;
  align-items: center;
  @media (max-width: 449px) {
    width: auto;
  }
`;
const Left = styled.div`
  position: relative;
  .ant-progress.ant-progress-circle .ant-progress-text {
    ${Typography.body_md_medium}
    color: var(--text-primary);
  }
  .ant-progress .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
    stroke: var(--brand-primary) !important;
  }
  .ant-progress-circle-path {
    transform: rotate(90deg) !important;
  }
  .ant-progress .ant-progress-circle-trail {
    stroke: var(--neutral-secondary) !important;
  }
  .ant-progress.ant-progress-circle .ant-progress-inner {
    background-color: var(--background-primary);
  }
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;
const Heading = styled.h1`
  margin: 0;
  ${Typography.heading_lg}
  color: var(--text-primary);
`;
const Detail = styled.p`
  margin: 0;
  ${Typography.body_md}
  color: var(--text-secondary);
`;
const Topline = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .stroke-color {
    path {
      stroke: var(--text-secondary);
    }
  }
  .ant-tooltip .ant-tooltip-inner {
    padding: 4px 8px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    background-color: var(--border-primary);
    color: var(--text-primary);
  }
  .ant-tooltip {
    ${Typography.body_sm}
    --antd-arrow-background-color: var(--border-primary);
  }
  .ant-tooltip .ant-tooltip-content {
    top: -10px;
    width: 167px;
    right: -10px;
  }
  .ant-tooltip-arrow {
    top: -10px !important;
  }
  .ant-tooltip-placement-bottomRight > .ant-tooltip-arrow {
    right: 0;
  }
`;

const CircleProgress = styled.svg`
  transform: rotate(90deg); /* Rotates progress to start from bottom */

  .progress-trail {
    fill: none;
    stroke: var(--neutral-secondary); /* Background color of the progress circle */
    stroke-width: 30;
  }

  .progress-bar {
    fill: none;
    stroke: var(--brand-primary); /* Active progress color */
    stroke-width: 30;
    stroke-linecap: butt;
    transition: stroke-dashoffset 0.6s ease-in-out;
  }
`;
const ProgressText = styled.text`
  ${Typography.body_md_medium}
  color: var(--text-primary);
  position: absolute;
  top: 50%;
  width: max-content;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export {
  Group,
  Header,
  Personaltext,
  Main,
  Title,
  Count,
  Total,
  Content,
  Storage,
  Left,
  Right,
  Heading,
  Detail,
  Topline,
  CircleProgress,
  ProgressText,
  Icon
};
