import styled, { css } from 'styled-components';
import Button from '../../component/button';
import { Typography } from '../../global/theme';

interface Prop {
  isSent?: boolean;
  isDeleteUserPage?: boolean;
  isForgotPasswordPage?: boolean;
  isForgotPasswordSent?: boolean;
  isLoginPage?: boolean;
  isResetPasswordPage?: boolean;
  isSignupPage?: boolean;
}

const Group = styled.div<Prop>`
  width: 100%;
  height: 100dvh;
  overflow: auto;
  margin: auto;
  background-color: var(--background-secondary);
  display: flex;
  align-items: center;
  justify-content: center;

  ${(Props: Prop) =>
    Props.isDeleteUserPage &&
    css`
      @media (max-width: 449px) {
        min-height: 382px;
      }
    `}
  ${(Props: Prop) =>
    Props.isForgotPasswordPage &&
    css`
      @media (max-width: 449px) {
        min-height: 384px;
      }
    `}
     ${(Props: Prop) =>
    (Props.isLoginPage || Props.isResetPasswordPage) &&
    css`
      @media (max-width: 449px) {
        min-height: 465px;
      }
    `}
      ${(Props: Prop) =>
    Props.isSignupPage &&
    css`
      @media (max-width: 449px) {
        min-height: 556px;
      }
    `}
`;
const Content = styled.div<Prop>`
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  background-color: var(--background-secondary);
  ${(Props: Prop) =>
    Props.isSent &&
    css`
      width: 100%;
      max-width: 100%;
      justify-content: center;
    `}

  @media (max-width: 449px) {
    max-width: 100%;
    padding: 30px 16px;
  }
  ${(Props: Prop) =>
    Props.isLoginPage &&
    css`
      gap: 80px;
      align-items: unset;
      @media (max-width: 449px) {
        gap: 30px;
      }
    `}
  ${(Props: Prop) =>
    Props.isSignupPage &&
    css`
      max-width: 582px;
      gap: 46px;
      @media (max-width: 449px) {
        max-width: 100%;
        padding: 30px 24px;
        gap: 30px;
      }
    `}
`;

const Icon = styled.div`
  @media (max-width: 449px) {
    .svgicon {
      width: 40px;
      height: 40px;
    }
  }
`;
const Title = styled.h1`
  ${Typography.heading_lg}
  color: var(--text-primary);
  margin: 0;
  text-align: center;
  @media (max-width: 449px) {
    align-items: center;
  }
`;
const Form = styled.form<Prop>`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 20px;
  @media (max-width: 449px) {
    gap: 30px;
  }
  ${(Props: Prop) =>
    Props.isLoginPage &&
    css`
      width: 100%;
      max-width: 300px;
      flex-direction: column;
      @media (max-width: 449px) {
        max-width: 100%;
        gap: 16px;
      }
    `}
`;
const Formblock = styled.div`
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  @media (max-width: 449px) {
    max-width: 100%;
  }
`;
const Inputvalue = styled.input`
  ${Typography.body_lg}
  color: var(--text-primary);
  border: 0.5px solid var(--border-primary);
  background: var(--background-primary);
  padding: 12px 16px;
  border-radius: 4px;
  outline: none;

  :focus {
    border: 0.5px solid var(--brand-primary);
    z-index: 999;
  }
  ::placeholder {
    ${Typography.body_lg}
    color: var(--border-secondary);
  }
  ${(Props: Prop) =>
    (Props.isLoginPage || Props.isResetPasswordPage || Props.isSignupPage) &&
    css`
      border-radius: 4px 4px 0px 0px;
      &.password {
        border-radius: 0px;
        margin-top: -1px;
      }
      &.password-input {
        border-radius: 0px 0px 4px 4px;
        margin-top: -1px;
      }
    `}
`;

const Formtextarea = styled.div`
  display: flex;
  justify-content: center;
  gap: 4px;
  ${(Props: Prop) =>
    Props.isResetPasswordPage &&
    css`
      gap: 6px;
      margin-top: 50px;
      @media (max-width: 449px) {
        margin-top: 0;
      }
    `}
`;

const Formtext = styled.p<Prop>`
  ${Typography.body_md}
  color: var(--text-secondary);
  margin: 0;
  ${(Props: Prop) =>
    Props.isSent &&
    css`
      text-align: center;
      max-width: 560px;
      @media (max-width: 449px) {
        margin-bottom: 10px;
      }
    `}

  ${(Props: Prop) =>
    Props.isForgotPasswordSent &&
    css`
      text-align: center;
      @media (max-width: 449px) {
        margin-bottom: 10px;
      }
    `}
`;

const Formtext1 = styled.p`
  ${Typography.body_md_medium}
  color: var(--brand-primary);
  margin: 0;
`;

const Viewer = styled.a`
  cursor: pointer;
  color: var(--brand-primary);
  text-decoration: none;
  ${Typography.body_md_medium}
  ${(Props: Prop) =>
    Props.isSignupPage &&
    css`
      ${Typography.body_sm_medium}
    `}
`;

const FormView = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;
  @media (max-width: 449px) {
    gap: 30px;
  }
`;

const ResetView = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (max-width: 449px) {
    align-items: center;
  }
`;
const TopView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 100%;
  max-width: 300px;
  @media (max-width: 449px) {
    max-width: 100%;
  }
`;
const GoogleButton = styled(Button)`
  align-items: center;
  ${Typography.body_lg}
  color: var(--text-secondary);
  border-radius: 54px;
  padding: 7px 20px 7px 10px;
  gap: 24px;
  border: none;
`;

const Overtext = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  border-bottom: 0.5px solid var(--border-primary);
`;

const Text = styled.p`
  width: 100%;
  max-width: 36px;
  position: absolute;
  top: -7px;
  margin: 0;
  text-align: center;
  ${Typography.body_md_medium}
  color: var(--text-secondary);
  background-color: var(--background-secondary);
`;

const Formview = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 449px) {
    flex-direction: column;
    gap: 30px;
    align-items: flex-end;
  }
`;
const Hiddennow = styled.div`
  display: none;
`;
const Formtextpoint = styled.div`
  display: flex;
  justify-content: center;
  gap: 4px;
`;
const Formcontent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  @media (max-width: 449px) {
    width: 100%;
    gap: 15px;
  }
`;
const Textline = styled.p`
  ${Typography.body_sm}
  color: var(--text-secondary);
  margin: 0;
  @media (max-width: 449px) {
    text-align: center;
  }
`;
export {
  Group,
  Content,
  Icon,
  Title,
  Formblock,
  Form,
  Inputvalue,
  Formtextarea,
  Formtext,
  Viewer,
  Formtext1,
  FormView,
  ResetView,
  TopView,
  GoogleButton,
  Overtext,
  Text,
  Formview,
  Hiddennow,
  Formtextpoint,
  Formcontent,
  Textline
};
