/* eslint-disable max-len */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  AttachFile,
  Bottomsection,
  Buttons,
  DescriptionSection,
  Detail,
  Group,
  HeaderText,
  HeaderTitle,
  Input,
  Inputs,
  Leftbuttons,
  OverLayDiv,
  ResponsiveNavbarIcon,
  TasktemplateHeader,
  Text,
  Textarea,
  Textfied,
  Top,
  Uploaddiv
} from './styles';
import AppLayout from '../appLayout';
import Button from '../button';
import Inline from '../comment/inline';
import { useSelector, useDispatch } from 'react-redux';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';
import AssignBox from '../dropdowns/assignPopup';
import Prioritydropdown from '../dropdowns/priorityDropdown';
import { setErrorMessage, setSuccessMessage } from '../../actions/messageActions';
import { isEmpty } from 'lodash';
import { handleFileUpload } from '../../helpers/common';
import { clearTaskTemplateData, updateTaskTemplateData } from '../../actions/settingActions';
import { TaskTemplateDataInterface } from '../../interfaces/SettingsInterface';
import {
  createNewTaskTemplate,
  getTaskTemplateDetails,
  updateTaskTemplateDetails
} from '../../services/settingServices';
import { useHistory, useParams } from 'react-router-dom';
import { ROUTES } from '../../routes/urls';
import SVGIcon from '../../assets/images/svg/SVGIcon';
import { FILE_TYPES } from '../../global/constants';
import { CommentFilesInterface } from '../../interfaces/TaskInterface';
import { captureException } from '../../services/logService';
import Responsivnavbar from '../navbar/responsivenavbar';

const TaskTemplateDetails: React.FC = () => {
  //Refs
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const inlineRef = useRef<any>(null);
  //States
  const [fileUploading, setFileUploading] = useState(false);
  const [isAssignDropdownOpen, setIsAssignDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updatedFile, setUpdatedFile] = useState<CommentFilesInterface[]>();
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { settings: settingsSelector, project: projectSelector, workspace: workspaceSelector } = stateSelector || {};
  const { workspace } = workspaceSelector;
  const { taskTemplateData } = settingsSelector;
  const { uploadFilePercent } = projectSelector;
  //Other variable
  const dispatch = useDispatch();
  const history = useHistory();
  const params: { id: string } = useParams();

  const loadData = useCallback(async () => {
    if (isEmpty(taskTemplateData?.['_id'])) {
      try {
        await dispatch(getTaskTemplateDetails(workspace, params?.id));
      } catch (error) {
        captureException(error);
        console.error('Failed to load task template data', error);
      }
    }
  }, [dispatch, params?.id, taskTemplateData, workspace]);

  useEffect(() => {
    if (updatedFile && updatedFile.length > 0) {
      dispatch(updateTaskTemplateData({ propsName: 'Files', value: updatedFile }));
    }
  }, [dispatch, updatedFile]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onChangeInputField = useCallback(
    (propsName: string, value: string) => {
      dispatch(updateTaskTemplateData({ propsName, value }));
    },
    [dispatch]
  );

  const handleFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleInput = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const onFileUpload = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target;
      if (!files || files.length === 0) {
        dispatch(setErrorMessage('No files selected.'));
        return;
      }
      try {
        setFileUploading(true);
        const fileArray = Array.from(files); // Convert FileList to Array
        const updatedFiles = await handleFileUpload(taskTemplateData?.Files || [], fileArray, dispatch, inlineRef); //call common function to upload file in firebase
        const value = [...(taskTemplateData?.Files || []), ...(updatedFiles || [])];
        setUpdatedFile(value);
      } catch (e) {
        captureException(e);
      } finally {
        setFileUploading(false);
      }
    },
    [taskTemplateData?.Files, dispatch]
  );
  const handleSubmit = async () => {
    const taskInput: TaskTemplateDataInterface = JSON.parse(JSON.stringify(taskTemplateData));
    const updatedFiles = taskInput?.Files?.map((file) => {
      const { __typename, ...newFile } = file;
      return {
        file_type: newFile.file_type,
        href: newFile.href,
        size: newFile.size,
        uploadFileType: FILE_TYPES.UPLOAD,
        name: newFile.name
      };
    });
    if (!taskTemplateData?.templateName || !taskTemplateData?.name) {
      dispatch(setErrorMessage('Template name and task name are required.'));
      return;
    }
    const payload = {
      Title: taskTemplateData.templateName,
      TaskTitle: taskTemplateData?.name,
      Description: taskTemplateData?.description,
      Priority: taskTemplateData?.priority,
      Assignee: taskTemplateData?.users?.map((x) => x?.['id']),
      Attachment: !isEmpty(updatedFiles) ? updatedFiles : []
    };
    try {
      setLoading(true);
      let result;
      if (taskTemplateData?.['_id']) {
        result = await dispatch(updateTaskTemplateDetails(taskTemplateData?.['_id'], payload));
      } else {
        result = await dispatch(createNewTaskTemplate(payload));
        dispatch(setSuccessMessage('Task template created successfully!'));
      }
      if (result) {
        history.push(ROUTES.TEMPLATES_SETTING);
        dispatch(clearTaskTemplateData());
      }
    } catch (error) {
      captureException(error);
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AppLayout>
      <Group>
        <TasktemplateHeader>
          <HeaderTitle>
            <ResponsiveNavbarIcon>
              <Responsivnavbar />
            </ResponsiveNavbarIcon>
            <HeaderText>New Template</HeaderText>
          </HeaderTitle>
          <Button
            title={taskTemplateData?.['_id'] ? 'Save Template' : 'Create New'}
            isLoading={loading}
            onClick={handleSubmit}
            modelbtn={true}
          />
        </TasktemplateHeader>
        <Bottomsection>
          <Top>
            <Text>Template Name</Text>
            <Input
              placeholder='Add a descriptive name...'
              type='text'
              value={taskTemplateData?.templateName}
              onChange={(e) => onChangeInputField('templateName', e.target.value)}
            />
          </Top>
          <Inputs>
            <Textfied>
              <Textarea
                placeholder='Task Title'
                rows={1}
                ref={textareaRef}
                onInput={handleInput}
                value={taskTemplateData?.name}
                onChange={(e) => onChangeInputField('name', e.target.value)}
              />
              <DescriptionSection>
                <Inline
                  ref={inlineRef}
                  isTaskModel={true}
                  uploadedFiles={[...(taskTemplateData?.Files || [])]}
                  setUploadedFiles={setUpdatedFile}
                  fieldValue={taskTemplateData?.description || ''}
                  setValueContent={(value: string) =>
                    dispatch(updateTaskTemplateData({ propsName: 'description', value }))
                  }
                  placeholder={'Add Description....'}
                  isApplyMaxHeight={240}
                  isApplyMinHeight={90}
                  iscreateTask
                  propsLoading={loading}
                  setPropsLoading={(value: boolean) => setLoading(value)}
                  isScrollbarDesign
                />
              </DescriptionSection>
            </Textfied>
            {fileUploading && <Uploaddiv>Uploading ({Math.floor(uploadFilePercent)}%)</Uploaddiv>}
            <Buttons>
              <Leftbuttons>
                <Detail>
                  <AssignBox
                    setIsDropdownOpen={setIsAssignDropdownOpen}
                    isDropdownOpen={isAssignDropdownOpen}
                    isHumanIcon={true}
                    isUpdateReducer={true}
                    isCreateTaskModal={true}
                    projectUsers={workspace?.users}
                    isTaskTemplate={true}
                  />
                </Detail>
                <Detail>
                  <Prioritydropdown taskData={taskTemplateData} icon={true} isCreateTask={true} isTaskTemplate={true} />
                </Detail>
              </Leftbuttons>
              <input type='file' ref={fileInputRef} style={{ display: 'none' }} onChange={onFileUpload} multiple />
              <AttachFile onClick={handleFileInput}>
                <SVGIcon name='attachment-icon' width='18' height='18' viewBox='0 0 18 18' className='svg' />
              </AttachFile>
            </Buttons>
          </Inputs>
        </Bottomsection>
      </Group>
      {isAssignDropdownOpen && (
        <OverLayDiv
          className='dropdown-overlay'
          onClick={() => {
            setIsAssignDropdownOpen(false);
          }}
        />
      )}
    </AppLayout>
  );
};

export default TaskTemplateDetails;
