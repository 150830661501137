import styled, { css } from 'styled-components';
import { Typography } from '../../../../global/theme';

interface Props {
  isdefault?: boolean;
  isActive?: boolean;
  color?: string;
}

const Group = styled.div`
  width: 100%;
  max-width: 503px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-bottom: 24px;
  gap: 16px;
`;
const Title = styled.div`
  ${Typography.body_md_medium}
  color: var(--text-primary);
`;

const Modalblock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const Searchbar = styled.div`
  padding: 0 16px;
`;
const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  h1 {
    ${Typography.body_lg}
    color: var(--text-secondary);
    margin: 0 0 16px;
  }
  .search {
    border: 0.5px solid var(--border-primary);
    border-radius: 6px;
    :focus {
      border: 0.5px solid var(--border-primary);
    }
    :hover {
      border: 0.5px solid var(--border-primary);
    }
  }
  .ant-input-group .ant-input {
    background-color: var(--background-primary);
  }
  .ant-input-search .ant-input:hover,
  .ant-input-search .ant-input:focus {
    border: none;
    box-shadow: none;
  }
  .ant-input-search .ant-input {
    border: none;
  }
  .ant-input::placeholder {
    color: var(--text-secondary);
    ${Typography.body_lg}
  }
  .ant-input {
    color: var(--text-primary);
    ${Typography.body_lg}
    padding: 8px 12px;
  }
  .ant-btn-default {
    background: var(--background-primary);
    box-shadow: none;
    border: none;
  }
  .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button:not(.ant-btn-primary):hover {
    border: none;
  }
  .ant-input-search .ant-input-search-button {
    height: 36px;
  }
  .anticon svg {
    fill: var(--text-secondary);
  }
`;

const Members = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 176px;
  overflow: auto;
  white-space: nowrap;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const Profile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 20px;
  cursor: pointer;
  :hover {
    background-color: var(--neutral-secondary);
  }
`;

const Name = styled.div`
  width: 330px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  p {
    ${Typography.body_md_medium}
    text-align: right;
    margin: 0;
    color: var(--text-primary);
  }
`;

const Check = styled.div`
  display: flex;
  gap: 10px;

  .checkbox-round {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid var(--border-primary);
    appearance: none;
    outline: none;
    cursor: pointer;
    margin: 0;
    padding: 7px;
  }
  input[type='checkbox'] {
    position: relative;
    cursor: pointer;
    opacity: 0.5;
  }
  input[type='checkbox']:checked {
    background-color: var(--brand-primary);
    opacity: 1;
    border: 1px solid var(--brand-primary);
  }
  input[type='checkbox']:checked::before {
    content: '';
    position: absolute;
    right: 50%;
    top: 50%;
    width: 3px;
    height: 8px;
    border: solid var(--text-white);
    border-width: 0 2px 2px 0;
    margin: -0.5px -1px 0 -1px;
    transform: rotate(45deg) translate(-50%, -50%) scale(0.7);
    z-index: 2;
  }
`;

const Inputvalue2 = styled.input``;

const Invite = styled.div`
  ${Typography.body_md_medium}
  text-decoration: none;
  list-style: none;
  color: var(--brand-primary);
  padding: 16px 0;
  text-align: center;
  cursor: pointer;
  border-top: 0.5px solid var(--border-primary);
`;

const Modalhead = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 16px 12px;
`;

const Taskbutton = styled.button<Props>`
  background-color: transparent;
  ${Typography.body_md_medium}
  color: var(--text-secondary);
  padding: 8px 15px;
  border: 0.5px solid transparent;
  border-radius: 16px;
  cursor: pointer;

  :hover {
    border: 0.5px solid var(--border-primary);
    box-shadow: var(--shadow-card);
    border-radius: 16px;
  }
  ${(props: Props) =>
    props.isActive &&
    css`
      background-color: var(--background-primary);
      border: 0.5px solid var(--border-primary);
      box-shadow: var(--shadow-card);
      color: var(--text-primary);
    `}
`;

export {
  Group,
  Title,
  Modalblock,
  ModalContent,
  Members,
  Profile,
  Name,
  Check,
  Inputvalue2,
  Invite,
  Modalhead,
  Searchbar,
  Taskbutton
};
