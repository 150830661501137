import { ActionCreatorWithOptionalPayload, ActionCreatorWithPayload, createAction, Dispatch } from '@reduxjs/toolkit';
import * as Actions from './types';
import { ReducerInterface } from '../interfaces/ReducerInterface';
import {
  FilteredCompletedTasksListInterface,
  FilteredTasksListInterface,
  TaskDetailsInterface,
  TaskFilterInterface,
  TaskGroupList,
  TaskInterfaceDetails,
  UpdateMemberTaskInterface
} from '../interfaces/TaskInterface';
import { LogDataInterface } from '../interfaces/TimeTrackingInterface';
import { MilestoneListInterface } from '../interfaces/ProjectInterface';

/**
 * @desc Set task loader
 */
export const setTaskLoader: ActionCreatorWithPayload<boolean, string> = createAction(Actions.SET_TASK_LOADER);

/**
 * @desc Set mytask settings detail
 */
export const setMyTaskData: ActionCreatorWithPayload<TaskInterfaceDetails[], string> = createAction(
  Actions.SET_MY_TASK_DATA
);

/**
 * @desc update specific task field
 */
export const updateMyTaskField: ActionCreatorWithPayload<{ taskId: string; key: string; value: any }, string> =
  createAction(Actions.UPDATE_MY_TASK_FIELD);

/**
 * @desc update mytask settings detail
 */
export const updateMyTaskData: ActionCreatorWithPayload<{ value: UpdateMemberTaskInterface; index: number }, string> =
  createAction(Actions.UPDATE_MY_TASK_DATA);

/**
 * @desc Set mytask settings detail
 */
export const setOverDueTasks: ActionCreatorWithPayload<number, string> = createAction(Actions.SET_OVERDUE_TASKS);

/**
 * @desc set timelog data
 */
export const setTimelogData: ActionCreatorWithPayload<LogDataInterface[], string> = createAction(
  Actions.SET_TIMELOG_DATA
);

/**
 * @desc set mytask details data
 */
export const setMyTaskDetailsData: ActionCreatorWithPayload<TaskDetailsInterface, string> = createAction(
  Actions.SET_MY_TASK_DETAILS_DATA
);

/**
 * @desc update mytask detailS data
 */
export const updateMyTaskDetailsData: ActionCreatorWithPayload<ReducerInterface, string> = createAction(
  Actions.UPDATE_MY_TASK_DETAILS_DATA
);

/**
 * @desc update mytask detailS data
 */
export const updateTaskCommentItem: ActionCreatorWithPayload<{ commentId: string; value: any }, string> = createAction(
  Actions.UPDATE_TASK_COMMENT
);

/**
 * @desc clear mytask details data
 */
export const clearMyTaskDetailsData: ActionCreatorWithOptionalPayload<undefined, string> = createAction(
  Actions.CLEAR_MY_TASK_DETAILS_DATA
);

/**
 * @desc set new comments data
 */
export const setMyTaskNewComments: ActionCreatorWithPayload<any, string> = createAction(
  Actions.SET_MY_TASK_NEW_COMMENTS
);

/**
 * @desc clear mytask settings detail
 */
export const clearMyTaskData: ActionCreatorWithOptionalPayload<undefined, string> = createAction(
  Actions.CLEAR_MY_TASK_DATA
);

/**
 * @desc set task details input
 */
export const setCreateTaskInput: ActionCreatorWithPayload<any, string> = createAction(Actions.SET_CREATE_TASK_INPUT);

/**
 * @desc update task details input
 */
export const updateCreateTaskInput: ActionCreatorWithPayload<ReducerInterface, string> = createAction(
  Actions.UPDATE_CREATE_TASK_INPUT
);

/**
 * @desc clear task details input
 */
export const clearCreateTaskInput: ActionCreatorWithOptionalPayload<undefined, string> = createAction(
  Actions.CLEAR_CREATE_TASK_INPUT
);

/**
 * @desc set taskgroup list
 */
export const setTaskGroupsList: ActionCreatorWithPayload<TaskGroupList[], string> = createAction(
  Actions.SET_TASKGROUP_LIST
);

/**
 * @desc set tasks list
 */
export const setTasksList: ActionCreatorWithPayload<TaskDetailsInterface[], string> = createAction(
  Actions.SET_TASKS_LIST
);

/**
 * @desc set filtered tasks list
 */
export const setFilteredTasksList: ActionCreatorWithPayload<FilteredTasksListInterface[], string> = createAction(
  Actions.SET_FILTERED_TASK_LIST
);

/**
 * @desc update filtered tasks list
 */
export const updateFilteredTasksList: ActionCreatorWithPayload<any, string> = createAction(
  Actions.UPDATE_FILTERED_TASK_LIST
);

/**
 * @desc update specific task field
 */
export const updateTaskField: ActionCreatorWithPayload<{ taskId: string; key: string; value: any }, string> =
  createAction(Actions.UPDATE_TASK_FIELD);

/**
 * @desc clear filtered tasks list
 */
export const clearFilteredTasksList: ActionCreatorWithOptionalPayload<undefined, string> = createAction(
  Actions.CLEAR_FILTERED_TASK_LIST
);

/**
 * @desc set show task filter
 */
export const setShowTaskStatus: ActionCreatorWithPayload<string, string> = createAction(Actions.SET_SHOW_TASK_FILTER);

/**
 * @desc set Tasks Filter Data
 */
export const setTasksFilterData: ActionCreatorWithPayload<string, string> = createAction(Actions.SET_TASKS_FILTER_DATA);

/**
 * @desc set Member Task Data
 */
export const setMemberTaskData: ActionCreatorWithPayload<TaskInterfaceDetails[], string> = createAction(
  Actions.SET_MEMBER_TASK_DATA
);

/**
 * @desc update Member Task Data
 */
export const updateMemberTaskData: ActionCreatorWithPayload<UpdateMemberTaskInterface, string> = createAction(
  Actions.UPDATE_MEMBER_TASK_DATA
);

/**
 * @desc update Member Task field
 */
export const updateMemberTaskField: ActionCreatorWithPayload<{ taskId: string; key: string; value: any }, string> =
  createAction(Actions.UPDATE_MEMBER_TASK_FIELD);

/**
 * @desc clear Member Task Data
 */
export const clearMemberTaskData: ActionCreatorWithOptionalPayload<undefined, string> = createAction(
  Actions.CLEAR_MEMBER_TASK_DATA
);

/**
 * @desc set taskgroup list
 */
export const setProjectTaskGroupsList: ActionCreatorWithPayload<any, string> = createAction(
  Actions.SET_PROJECT_TASKGROUP_LIST
);

/**
 * @desc set task milestone list
 */
export const setProjectTaskMilestoneList: ActionCreatorWithPayload<MilestoneListInterface[], string> = createAction(
  Actions.SET_PROJECT_TASK_MILESTONE_LIST
);

/**
 * @desc set Sub Task List
 */
export const setSubTaskList: ActionCreatorWithPayload<TaskDetailsInterface[], string> = createAction(
  Actions.SET_SUBTASK_LIST
);

/**
 * @desc update Sub Task List
 */
export const updatSubTaskList: ActionCreatorWithPayload<any, string> = createAction(Actions.UPDATE_SUBTASK_LIST);

/**
 * @desc clear Sub Task List
 */
export const clearSubTaskList: ActionCreatorWithOptionalPayload<undefined, string> = createAction(
  Actions.CLEAR_SUBTASK_LIST
);

/**
 * @desc Set task filter state
 */
export const setIsTaskFilterOpen: ActionCreatorWithPayload<boolean, string> = createAction(
  Actions.SET_IS_TASK_FILTER_OPEN
);

/**
 * @desc set Task filter
 */
export const setTaskFilter: ActionCreatorWithPayload<TaskFilterInterface, string> = createAction(
  Actions.SET_TASK_FILTER
);

/**
 * @desc update Task filter
 */
export const updateTaskFilter: ActionCreatorWithPayload<ReducerInterface, string> = createAction(
  Actions.UPDATE_TASK_FILTER
);

/**
 * @desc clear Task filter
 */
export const clearTaskFilter: ActionCreatorWithOptionalPayload<undefined, string> = createAction(
  Actions.CLEAR_TASK_FILTER
);

/**
 * @desc set Other task list
 */
export const setOtherTaskList: ActionCreatorWithPayload<FilteredCompletedTasksListInterface, string> = createAction(
  Actions.SET_OTHER_TASK_LIST
);

/**
 * @desc Clear tasks Data
 */
export const clearProjectTasksData = () => (dispatch: Dispatch) => {
  dispatch(setTaskLoader(false));
  dispatch(setMyTaskData([]));
  dispatch(setTimelogData([]));
  dispatch(clearMyTaskDetailsData());
  dispatch(setOverDueTasks(0));
  dispatch(clearCreateTaskInput());
  dispatch(setTaskGroupsList([]));
  dispatch(setTasksList([]));
  dispatch(clearFilteredTasksList());
  dispatch(setShowTaskStatus(''));
  dispatch(setTasksFilterData(''));
  dispatch(clearMemberTaskData());
  dispatch(setProjectTaskGroupsList([]));
  dispatch(clearMyTaskData());
  dispatch(clearTaskFilter());
  dispatch(setOtherTaskList({ tasks: [], completedTasks: [] }));
};
