import styled, { css } from 'styled-components';
import { Typography } from '../../../global/theme';
interface Props {
  tone?: 'primary' | 'critical';
  justifyAlign?: 'center' | 'spaceBetween';
  align?: 'center' | 'flex-start';
  isSelected?: boolean;
  iconTone?: 'fill' | 'stroke';
  isActive?: boolean;
}

const Label = styled.p<Props>`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  ${Typography.body_md}
  margin: 0;
  ${(props) =>
    props.tone === 'critical' &&
    css`
      color: var(--accent-error);
    `}
  ${(props) =>
    props.tone === 'primary' &&
    css`
      color: var(--text-primary);
    `}
    @media (max-width: 449px) {
    ${Typography.body_lg}
  }
`;

const CrossIconWrapper = styled.div`
  visibility: hidden; /* Initially hidden */
`;
const ItemMainDiv = styled.div`
  padding: 0 4px;
  @media (max-width: 449px) {
    padding: 0 10px;
  }
`;

const Item = styled.div<Props>`
  display: flex;
  padding: 4px 6px;
  cursor: pointer;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  svg {
    margin: 2px 1px;
    ${(props: Props) =>
      props.iconTone === 'fill' &&
      css`
        fill: var(--text-primary);
      `}
    ${(props: Props) =>
      props.iconTone === 'stroke' &&
      css`
        stroke: var(--text-primary);
        fill: none;
      `}
  }
  &:hover {
    background-color: var(--neutral-secondary);
    ${CrossIconWrapper} {
      visibility: visible;
    }
  }
  ${(props) =>
    props.justifyAlign === 'center' &&
    css`
      justify-content: center;
    `}
  ${(props) =>
    props.justifyAlign === 'spaceBetween' &&
    css`
      justify-content: space-between;
    `}
    ${(props) =>
    props.isActive &&
    css`
      background-color: var(--neutral-secondary);
    `}
    @media (max-width: 449px) {
    padding: 12px;
    border-radius: 8px;
  }
`;
const DropDiv = styled.div<Props>`
  display: flex;
  width: 100%;
  gap: 8px;
  overflow: hidden;
  ${(props) =>
    props.align === 'center' &&
    css`
      align-items: center;
    `}
  ${(props) =>
    props.align === 'flex-start' &&
    css`
      align-items: flex-start;
    `}
`;

const Suffix = styled.p`
  ${Typography.body_md}
  color: var(--text-secondary);
  margin: 0;
  display: flex;
`;

const DescriptionDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow: hidden;
`;

const Description = styled.div`
  ${Typography.body_sm}
  color: var(--text-secondary);
`;
const CheckBox = styled.input`
  position: relative;
  appearance: none;
  max-width: 14px;
  width: 100%;
  height: 14px;
  border: 1px solid var(--border-secondary);
  border-radius: 4px;
  background-color: var(--background-primary);
  cursor: pointer;
  outline: none;
  margin: 0;
  :checked {
    background-color: var(--brand-primary);
    opacity: 1;
    border: 0.5px solid var(--brand-primary);
  }
  &:checked::after {
    content: '';
    position: absolute;
    right: 50%;
    top: 50%;
    width: 3px;
    height: 8px;
    border: solid var(--text-white);
    border-width: 0 2px 2px 0;
    margin: -0.5px -1px 0 -1px;
    -webkit-transform: rotate(45deg) translate(-50%, -50%) scale(0.7);
    -ms-transform: rotate(45deg) translate(-50%, -50%) scale(0.7);
    transform: rotate(45deg) translate(-50%, -50%) scale(0.7);
    z-index: 2;
  }
`;

const Input = styled.input`
  color: var(--text-primary);
  border: none;
  box-shadow: none;
  background-color: unset;
  width: 100%;
  ::placeholder {
    ${Typography.body_md}
    color: var(--text-secondary);
  }
  &:focus {
    border: 0.5px solid var(--primary-color); /* Border color when focused */
    outline: none; /* Remove default outline */
  }
`;

const AvtarDiv = styled.div`
  width: 22px;
  height: 22px;
`;

const CheckMarkIcon = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
  }
`;

export {
  Label,
  Item,
  DropDiv,
  Suffix,
  DescriptionDiv,
  Description,
  CheckBox,
  CrossIconWrapper,
  Input,
  ItemMainDiv,
  AvtarDiv,
  CheckMarkIcon
};
