import { createSelector } from 'reselect';
import { RootReducerInterface } from '../interfaces/RootReducerInterface';

// Base selectors
// const selectState = (state: RootReducerInterface) => state;
const selectSettings = (state: RootReducerInterface) => state.settings;
const selectApp = (state: RootReducerInterface) => state.app;
const selectAuth = (state: RootReducerInterface) => state.auth;
const selectWorkspace = (state: RootReducerInterface) => state.workspace;
const selectProject = (state: RootReducerInterface) => state.project;
const selectTask = (state: RootReducerInterface) => state.task;
const selectCustomer = (state: RootReducerInterface) => state.customer;
const selectTimeTracking = (state: RootReducerInterface) => state.timeTracking;
const selectInbox = (state: RootReducerInterface) => state.inbox;
const selectInvoice = (state: RootReducerInterface) => state.invoice;

// Memoized selectors
export const selectThemeMode = createSelector([selectSettings], (settings) => settings?.themeMode);

export const selectThemeType = createSelector([selectSettings], (settings) => settings?.themeType);

export const selectCustomBranding = createSelector([selectApp], (app) => app?.customBranding);

export const selectAppInitLoading = createSelector([selectApp], (app) => app?.appInitLoading);

export const selectUserDetails = createSelector([selectAuth], (auth) => auth?.userDetails);

export const selectInboxData = createSelector([selectInbox], (inbox) => inbox);

export const selectWorkspaceData = createSelector([selectWorkspace], (workspace) => workspace?.workspace);

// New selectors for SearchRepositoryModel
export const selectGithubRepositoryList = createSelector(
  [selectSettings],
  (settings) => settings?.githubRepositoryList
);

export const selectFilteredGithubRepoList = createSelector(
  [selectSettings],
  (settings) => settings?.filteredGithubRepoList
);

// New selectors for CustomerModal
export const selectNewCustomer = createSelector([selectCustomer], (customer) => customer?.newCustomer);

// New selectors for AddNewTimeModal
export const selectTimeTrackingState = createSelector(
  [selectTimeTracking, selectWorkspace],
  (timeTracking, workspace) => ({
    currentTimeTracking: timeTracking?.currentTimeTracking,
    timeEntryInput: timeTracking?.timeEntryInput,
    listDropdown: timeTracking?.listDropdown,
    workspace: workspace?.workspace
  })
);

// New selectors for Navbar
export const selectNavbarState = createSelector(
  [selectProject, selectWorkspace, selectTimeTracking, selectApp, selectInvoice],
  (project, workspace, timeTracking, app, invoice) => ({
    isNavbarShow: app?.isNavbarShow,
    favouriteProjects: project?.favouriteProjects,
    workspace: workspace?.workspace,
    trackingActivity: timeTracking?.trackingActivity,
    invoices: invoice?.invoices
  })
);

// New selectors for Tasks
export const selectTasksState = createSelector(
  [selectWorkspace, selectAuth, selectSettings, selectTask],
  (workspace, auth, settings, task) => ({
    workspace: workspace?.workspace,
    userDetails: auth?.userDetails,
    themeMode: settings?.themeMode,
    myTask: task?.myTask
  })
);

// New selectors for LabelDropdown
export const selectTaskDetails = createSelector([selectTask], (task) => task?.taskDetails);

// New selectors for StatusDropdown
export const selectSelectedGroupList = createSelector([selectTask], (task) => task?.selectedGroupList);

// New selectors for SettingNavbar
export const selectSettingNavbarState = createSelector([selectSettings, selectWorkspace], (settings, workspace) => ({
  projectTemplateDetails: settings?.projectTemplateDetails,
  loading: settings?.loading,
  notificationSettingDetails: settings?.notificationSettingDetails,
  workspace: workspace?.workspace
}));

// Combined selectors for complex state
export const selectAppState = createSelector(
  [
    selectThemeMode,
    selectThemeType,
    selectCustomBranding,
    selectAppInitLoading,
    selectUserDetails,
    selectWorkspaceData
  ],
  (themeMode, themeType, customBranding, appInitLoading, userDetails, workspace) => ({
    themeMode,
    themeType,
    customBranding,
    appInitLoading,
    userDetails,
    workspace
  })
);
