/* eslint-disable max-len */
import { Node, mergeAttributes } from '@tiptap/core';

export interface LightshotEmbedOptions {
  HTMLAttributes?: Record<string, any>;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    lightshotEmbed: {
      setLightshotEmbed: (options: { url: string; previewLink: string }) => ReturnType;
    };
  }
}

export const LightshotEmbed = Node.create<LightshotEmbedOptions>({
  name: 'lightshotEmbed',

  group: 'block',
  atom: true,
  draggable: false, // Change as per your needs

  addAttributes() {
    return {
      url: { default: '' },
      previewLink: { default: '' }
    };
  },

  parseHTML() {
    return [
      {
        tag: 'div.prnt-preview-main-div',
        getAttrs: (dom: HTMLElement) => ({
          url: dom.getAttribute('url'),
          previewLink: dom.querySelector('.prnt-inbox-image img')?.getAttribute('src') || ''
        })
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(HTMLAttributes, {
        class: 'prnt-preview-main-div',
        url: HTMLAttributes.url,
        name: HTMLAttributes.previewLink
      }),
      [
        'div',
        { class: 'prnt-preview-header' },
        [
          'div',
          { class: 'prnt-desc-div' },
          [
            'img',
            {
              src: 'https://firebasestorage.googleapis.com/v0/b/teamcamp-app.appspot.com/o/MediaIcons%2FLightShot.png?alt=media&token=05979875-3f7c-4836-8242-7b10a8a12cf1',
              class: 'prnt-icon',
              width: '20px',
              height: '20px'
            }
          ],
          [
            'div',
            { class: 'prnt-text' },
            ['a', { class: 'prnt-url-link', href: HTMLAttributes.url, target: '_blank' }, HTMLAttributes.url]
          ]
        ]
      ],
      [
        'div',
        { class: 'prnt-inbox-image' },
        ['img', { src: HTMLAttributes.previewLink, alt: 'Figma Image', class: 'print-screen' }]
      ]
    ];
  },

  addCommands() {
    return {
      setLightshotEmbed:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options
          });
        }
    };
  }
});
