import styled from 'styled-components';
import { Typography } from '../../../global/theme';

const Modalblock = styled.form`
  display: flex;
  flex-direction: column;
`;

const Formation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 16px 20px 24px;
  @media (max-width: 449px) {
    margin: 16px 16px 0 16px;
    gap: 16px;
  }
`;

const Input = styled.input`
  width: -webkit-fill-available;
  padding: 5px 12px;
  background: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 6px;
  ${Typography.body_lg}
  color: var(--text-primary);
  ::placeholder {
    color: var(--text-placeholder);
  }
  :focus {
    border: 0.5px solid var(--brand-primary);
  }
  :focus-visible {
    outline: none;
  }
`;

const Formblock = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: 449px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const Form = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  .ant-picker .ant-picker-clear > * {
    width: 18px;
    height: 18px;
    padding-top: 4px;
    background-color: var(--background-primary);
  }
  .ant-input {
    color: var(--text-primary);
    border: 0.5px solid var(--border-primary);
    background-color: var(--background-primary);
    ${Typography.body_lg}
    padding: 7px 12px;
  }
  .ant-input:focus {
    border: 0.5px solid var(--brand-primary);
    box-shadow: var(--shadow-input-focus);
  }

  .ant-select-dropdown {
    background-color: red !important;
  }
  .ant-input::-webkit-input-placeholder {
    color: var(--text-placeholder);
  }
  .ant-picker .ant-picker-input > input {
    color: var(--text-primary);
    ${Typography.body_lg}
  }
  .ant-picker .ant-picker-input > input::placeholder {
    color: var(--text-placeholder);
  }
  .ant-picker {
    background-color: var(--background-primary);
    border: 0.5px solid var(--border-primary);
    padding: 7px 12px;
  }
  .ant-picker-focused {
    border: 0.5px solid var(--brand-primary);
    box-shadow: var(--shadow-input-focus);
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: 0.5px solid var(--border-primary);
    background-color: var(--background-primary);
    padding: 0 12px;
  }
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover
    .ant-select-selector {
    border: 0.5px solid var(--border-primary);
  }
  .ant-select-focused.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
      .ant-pagination-size-changer
    )
    .ant-select-selector {
    border: 0.5px solid var(--brand-primary);
    box-shadow: var(--shadow-input-focus) !important;
  }
  .fillColor {
    stroke: var(--text-primary);
  }
  .ant-picker .ant-picker-suffix > * path {
    fill: var(--text-primary);
  }
  .ant-select-selection-placeholder {
    color: var(--text-placeholder);
  }
  .ant-select-selection-item {
    color: var(--text-primary) !important;
  }
  .ant-select {
    color: var(--text-primary) !important;
    ${Typography.body_lg}
  }
  .ant-select-single .ant-select-selector {
    color: var(--text-primary) !important;
    ${Typography.body_lg}
  }
  .ant-picker-dropdown .ant-picker-cell {
    color: var(--text-primary) !important;
  }
  .ant-picker-dropdown .ant-picker-ranges {
    padding: 9px 0;
  }
  .select {
    .angledown-icon {
      path {
        stroke: var(--text-primary);
      }
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: 0.5px solid var(--border-primary);
      background-color: var(--background-primary);
    }

    .ant-select-focused.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
        .ant-pagination-size-changer
      )
      .ant-select-selector {
      border: 0.5px solid var(--brand-primary);
      box-shadow: none;
    }
    .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover
      .ant-select-selector {
      border-color: var(--brand-primary);
      box-shadow: none;
    }
    .ant-select-single .ant-select-selector .ant-select-selection-search {
      padding: 4px 0;
      inset-inline-start: 12px;
      inset-inline-end: 16px;

      @media (max-width: 449px) {
        padding: 8px 0;
      }
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
      ${Typography.body_lg}
    }
    .ant-select-single.ant-select-open .ant-select-selection-item {
      color: var(--text-primary);
    }
    &.ant-select-item-option-content {
      color: var(--text-primary);
    }
  }
  .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background-color: var(--brand-primary) !important;
  }
  .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 0.5px solid var(--brand-primary) !important;
  }
  .ant-picker-dropdown .ant-picker-header-view button:hover {
    color: var(--brand-primary) !important ;
  }
  .ant-picker-dropdown .ant-picker-header button {
    color: var(--text-secondary) !important;
  }
  .ant-picker-dropdown .ant-picker-panel-container .ant-picker-panel {
    border: none !important;
  }
  .ant-picker-dropdown .ant-picker-header-view button {
    color: var(--text-secondary) !important;
  }
`;

const Label = styled.label`
  ${Typography.body_md_medium}
  color: var(--text-secondary);
  span {
    color: var(--accent-error);
  }
`;

const Formbutton = styled.div`
  display: flex;
  gap: 10px;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0 16px 16px 0;

  @media (max-width: 449px) {
    padding-top: 24px;
    justify-content: end;
    gap: 12px;
  }
`;

const Formview = styled.div`
  position: relative;
  z-index: 1;
`;

const SelectInput = styled.input`
  min-width: 566px;
  padding: 7px 12px;
  color: var(--text-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 6px;
  ${Typography.body_lg}
  ::placeholder {
    color: var(--text-secondary);
  }
  :focus-visible {
    border-color: var(--brand-primary);
    outline: unset;
  }
  @media (max-width: 449px) {
    min-width: calc(100vw - 90px);
  }
`;

const ItemDiv = styled.div`
  max-height: 110px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: 4px;
  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--text-placeholder);
    border-radius: 12px;
    min-height: 28px;
  }
`;

const InputDiv = styled.div`
  position: relative;
`;

const IconDiv = styled.div`
  position: absolute;
  top: 8px;
  right: 10px;
  stroke: var(--text-primary);
  .angledown-icon {
    transition: transform 0.3s ease;
  }

  .angledown-icon.rotate {
    transform: rotate(180deg);
  }
`;

const SelectedText = styled.div`
  position: absolute;
  top: 8px;
  left: 12px;
  pointer-events: none;
`;
export {
  Modalblock,
  Formblock,
  Form,
  Label,
  Formbutton,
  Input,
  Formation,
  Formview,
  ItemDiv,
  IconDiv,
  InputDiv,
  SelectInput,
  SelectedText
};
