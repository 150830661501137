import styled from 'styled-components';

const Group = styled.div`
  display: flex;
  overflow: hidden;
  margin: 0 -24px;
  height: 100dvh;
  @media (max-width: 449px) {
    margin: 0 -16px;
  }
`;

const Leftdiv = styled.div`
  width: 100%;
  max-width: 306px;
  /* height: 100dvh; */
  overflow: auto;
  scrollbar-width: none;
  border-right: 0.5px solid var(--border-primary);
  background-color: var(--background-primary);
  @media (max-width: 449px) {
    max-width: 100%;
    border-right: none;
    overflow: unset;
  }
`;

const Rightdiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
`;
export { Group, Rightdiv, Leftdiv };
