/* eslint-disable max-len */
import styled, { css } from 'styled-components';
import { calculateColor } from '../../../helpers/common';
import { Typography } from '../../../global/theme';
interface Props {
  isActive?: boolean;
  ProgressPercentage?: number;
  status?: number;
}

const ProjectName = styled.div<Props>`
  max-width: 170px;
  width: 100%;
  margin-left: 12px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 6px 8px;
  gap: 12px;
  p {
    color: var(--text-primary);
    ${Typography.body_md};
    margin: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
  svg {
    margin-right: 16px;
  }

  :hover {
    background-color: var(--background-secondary);
    border-radius: 23px;
  }
  @media (max-width: 449px) {
    max-width: -webkit-fill-available;
    margin-left: -8px;
    padding: 10px 8px;
    gap: 8px;
    p {
      padding-bottom: 0;
    }
  }
  ${(props: Props) =>
    props.isActive &&
    css`
      background-color: var(--background-secondary);
      border: 1px solid var(--border-primary);
      box-shadow: var(--shadow-card);
      border-radius: 23px;
      p {
        color: var(--text-primary);
        padding-bottom: 0;
      }
    `}
`;

const ProjectProgress = styled.div`
  width: 12px;
  height: 12px;
  border: 1px solid var(--border-secondary);
  border-radius: 50%;
  padding: 2px;
  @media (max-width: 449px) {
    width: 14px;
    height: 14px;
  }
`;

const FillDiv = styled.div<Props>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: conic-gradient(
    ${({ status }) => calculateColor(status || 0)} ${({ ProgressPercentage }) => ProgressPercentage}%,
    0,
    transparent 100%
  );
  @media (max-width: 449px) {
    width: 14px;
    height: 14px;
  }
`;
export { ProjectName, ProjectProgress, FillDiv };
