import React, { useCallback, useState } from 'react';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import {
  Modalhead,
  Headline,
  ModalContent,
  Icon,
  ModalMain,
  Bottomline,
  Bottom,
  List,
  Figure,
  Datetime
} from './styles';
import { formatDate, isEmpty } from '../../../helpers/common';
import { ScreenshotListInterface } from '../../../interfaces/TimeReportInterface';
import { useHotkeys } from 'react-hotkeys-hook';
interface UserData {
  taskname?: string;
  time?: string;
  mouseclicks?: string;
  mainimage: ScreenshotListInterface[];
  keyboardhits?: string;
  modalClose?: () => void;
}

const Screenshotmodal: React.FC<UserData> = (props) => {
  //States
  const [image, setImage] = useState<ScreenshotListInterface>(props.mainimage[0]);
  const [index, setIndex] = useState(0);

  const onClickImage = useCallback((item: ScreenshotListInterface, index: number) => {
    setImage(item);
    setIndex(index);
  }, []);

  const onClickPreviousImage = useCallback(() => {
    if (props?.mainimage?.length > 1) {
      const newIndex = index > 0 ? index - 1 : props?.mainimage.length - 1;
      setIndex(newIndex);
      setImage(props?.mainimage[newIndex]);
    }
  }, [index, props?.mainimage]);

  const onClickNextImage = useCallback(() => {
    if (props?.mainimage?.length > 1) {
      const newIndex = index < props?.mainimage.length - 1 ? index + 1 : 0;
      setIndex(newIndex);
      setImage(props?.mainimage[newIndex]);
    }
  }, [index, props?.mainimage]);

  useHotkeys('ArrowLeft', async (event) => {
    event.preventDefault();
    onClickPreviousImage();
  });

  useHotkeys('ArrowRight', async (event) => {
    event.preventDefault();
    onClickNextImage();
  });

  return (
    <ModalMain>
      <Icon onClick={props?.modalClose}>
        <SVGIcon
          name='modal-cross-sign-icon'
          width='18'
          height='18'
          viewBox='0 0 18 18'
          fill='none'
          className='fillColor'
        />
      </Icon>
      <Modalhead>
        <Headline>{props.taskname}</Headline>
        <Bottomline>
          <p>{props.time}</p>
        </Bottomline>
      </Modalhead>
      <ModalContent>
        <div onClick={onClickPreviousImage}>
          <SVGIcon
            name='Leftarrow-icon'
            width='24'
            height='25'
            viewBox='0 0 24 25'
            fill='none'
            className='arrows-icon'
          />
        </div>
        <img
          src={!isEmpty(image) ? image?.Url : props.mainimage[0]?.Url}
          width={576}
          height={324}
          alt='screenshotimage'
        />
        <Datetime>
          <p>{formatDate(image?.CreatedTime).time}</p> <p>{formatDate(image?.CreatedTime).formattedDate}</p>
        </Datetime>
        <div onClick={onClickNextImage}>
          <SVGIcon
            name='Rightarrow-icon'
            width='24'
            height='25'
            viewBox='0 0 24 25'
            fill='none'
            className='arrows-icon'
          />
        </div>
      </ModalContent>
      <Bottom>
        <List>
          {props.mainimage?.map((item, index: number) => {
            return (
              <Figure key={item?.Url} isActive={image?.Url === item?.Url} onClick={() => onClickImage(item, index)}>
                <img src={item?.Url} width={80} height={44} alt='screenshotimage' />
              </Figure>
            );
          })}
        </List>
      </Bottom>
    </ModalMain>
  );
};

export default Screenshotmodal;
