import { isDev, isEmpty } from '../helpers/common';
import _ from 'lodash';
import { captureException } from './logService';

/**
 * @desc set use and company for analytics
 */
export const setAnlayticUserAndCompany = (user: any, company: any) => {
  try {
    if (isDev()) {
      return;
    }
    if ((window as any).rudderanalytics) {
      (window as any).rudderanalytics.identify(user.id, {
        email: user.email,
        name: user.name,
        createdAt: user?.user_created_on ? new Date(user?.user_created_on * 1000).toISOString() : undefined,
        isOwner: company?.isOwner ? true : undefined,
        isAdmin: company?.isAdmin ? true : undefined,
        isCustomer: company && !isEmpty(company?.customerRole) ? true : undefined
      });
      (window as any).rudderanalytics.group(company.id);
    }
  } catch (e) {
    captureException(e);
    console.log('ANALYTICS ERROR', e);
  }
};

/**
 * @desc clear user information for analytics
 */
export const clearAnlayticsUser = () => {
  try {
    if (isDev()) {
      return;
    }
    // Need to implement logic for clear analytics
  } catch (e) {
    captureException(e);
    console.log('ANALYTICS ERROR', e);
  }
};

/**
 * @desc track activity in analytics
 */
export const trackAnalyticActivity = (name: any, params?: any) => {
  try {
    if (isDev()) {
      return;
    }
    if ((window as any).rudderanalytics) {
      (window as any).rudderanalytics.track(name, params);
    }
  } catch (e) {
    captureException(e);
    console.log('ANALYTICS ERROR', e);
  }
};

/**
 * @desc track page view
 */
export const trackPage = (params: any) => {
  try {
    const fnTrackPage = _.throttle(() => {
      if (isDev()) {
        return;
      }
      // Nothing implemented! need to implement here
    }, 500);
    fnTrackPage();
  } catch (e) {
    captureException(e);
    console.log('ANALYTICS ERROR trackPage', e);
  }
};

/**
 * @desc track page view
 */
export const workspaceSetAnalytics = (workspace_id: string) => {
  try {
    if (isDev()) {
      return;
    }
    // Nothing implemented! need to implement here
  } catch (e) {
    captureException(e);
    console.log('ANALYTICS ERROR workspace set', e);
  }
};
