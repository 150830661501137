import React, { useCallback, useMemo } from 'react';
import SVGIcon from '../../assets/images/svg/SVGIcon';
import { Group, Container, Fileblock, Icon, Filecontent, Title, Text, Moreicon, Image, FileIcon } from './styles';
import { ProjectFileData, ProjectFilesInterface } from '../../interfaces/ProjectInterface';
import { FILE_TYPES, FILES_MENU_OPTIONS } from '../../global/constants';
import { Dropdown, DropdownItem } from '../Dropdown';
interface processdata {
  header: any;
  title: string;
  endtext: string;
  itemData: ProjectFilesInterface;
  fileType?: number;
  onClickFile: () => void;
  onClickRename?: () => void;
  OpenModal?: () => void;
  Opendeletemodel?: () => void;
  currentItem: ProjectFileData | null;
  setCurrentItem: any;
  isImage?: boolean;
}

const Filedata: React.FC<processdata> = (props) => {
  const { onClickFile, onClickRename, OpenModal, Opendeletemodel, isImage, currentItem, itemData, setCurrentItem } =
    props;

  // Generate menu items based on file type
  const menuItems = useMemo(() => {
    return itemData.uploadFileType === FILE_TYPES.UPLOAD
      ? [
          { key: FILES_MENU_OPTIONS.VIEW, label: 'View' },
          { key: FILES_MENU_OPTIONS.DOWNLOAD, label: 'Download' },
          { key: FILES_MENU_OPTIONS.RENAME, label: 'Rename' },
          { key: FILES_MENU_OPTIONS.MOVETOFOLDER, label: 'Move to folder' },
          { key: FILES_MENU_OPTIONS.DELETE, label: 'Delete' }
        ]
      : [
          { key: FILES_MENU_OPTIONS.VIEW, label: 'View' },
          { key: FILES_MENU_OPTIONS.RENAME, label: 'Rename' },
          { key: FILES_MENU_OPTIONS.MOVETOFOLDER, label: 'Move to folder' },
          { key: FILES_MENU_OPTIONS.DELETE, label: 'Delete' }
        ];
  }, [itemData.uploadFileType]);

  // Handle menu item click
  const handleMenuClick = useCallback(
    (key: number | string) => {
      key = Number(key);
      if (key === FILES_MENU_OPTIONS.VIEW) {
        onClickFile();
      } else if (key === FILES_MENU_OPTIONS.RENAME) {
        if (onClickRename) onClickRename();
      } else if (key === FILES_MENU_OPTIONS.MOVETOFOLDER) {
        if (OpenModal) OpenModal();
      } else if (key === FILES_MENU_OPTIONS.DELETE) {
        if (Opendeletemodel) Opendeletemodel();
      } else if (key === FILES_MENU_OPTIONS.DOWNLOAD) {
        if (itemData?.url && itemData?.name) {
          fetch(itemData.url)
            .then((response) => {
              if (!response.ok) {
                throw new Error(`Failed to fetch file: ${response.statusText}`);
              }
              return response.blob();
            })
            .then((blob) => {
              const blobUrl = window.URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = blobUrl;
              link.download = itemData.name;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              window.URL.revokeObjectURL(blobUrl);
            })
            .catch((error) => {
              console.error('Error during download:', error);
            });
        } else {
          console.warn('Invalid file URL or name:', itemData);
        }
      }
    },
    [onClickFile, onClickRename, OpenModal, Opendeletemodel, itemData]
  );
  // Memoized dropdown items
  const MenuItemDropdown = useMemo(() => {
    return menuItems.map((item) => (
      <DropdownItem
        label={item.label}
        key={item.key}
        onClick={() => handleMenuClick(item.key)}
        tone={item.label === 'Delete' ? 'critical' : 'primary'}
      />
    ));
  }, [menuItems, handleMenuClick]);

  return (
    <Group>
      <div onClick={(e) => e.stopPropagation()}>
        <Container onClick={onClickFile}>
          <Fileblock isActive={itemData?.id === currentItem?.id}>
            <Image isImage={isImage}>
              {!isImage && (
                <FileIcon>
                  {props.fileType === FILE_TYPES.DRIVE && (
                    <SVGIcon
                      name='file-googledrive-icon'
                      width='18'
                      height='18'
                      viewBox='0 0 18 18'
                      className='fillColor'
                    />
                  )}
                  {props.fileType === FILE_TYPES.ONEDRIVE && (
                    <SVGIcon
                      name='file-onedrive-icon'
                      width='18'
                      height='18'
                      viewBox='0 0 18 18'
                      className='fillColor'
                    />
                  )}
                  {props.fileType === FILE_TYPES.DROPBOX && (
                    <SVGIcon
                      name='file-dropbox-icon'
                      width='18'
                      height='18'
                      viewBox='0 0 18 18'
                      className='fillColor'
                    />
                  )}
                </FileIcon>
              )}
              <Icon isImage={isImage}>{props.header}</Icon>
              <Moreicon className='moreicon' isImage={isImage}>
                <Dropdown
                  content={
                    <div
                      onClick={() =>
                        setCurrentItem({
                          name: itemData?.name,
                          id: itemData?.id,
                          uploadFileType: itemData?.uploadFileType,
                          fileType: itemData?.fileType
                        })
                      }>
                      <SVGIcon name='more-icon' width='18' height='18' viewBox='0 0 18 18' className='fillColor' />
                    </div>
                  }
                  trigger='click'
                  onOutsideClick={() => setCurrentItem(null)}>
                  {MenuItemDropdown}
                </Dropdown>
              </Moreicon>
            </Image>
            <Filecontent>
              <Title>{props.title}</Title>
              <Text>{props.endtext}</Text>
            </Filecontent>
          </Fileblock>
        </Container>
      </div>
    </Group>
  );
};

export default Filedata;
