import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { DescriptionInputSection, FileBox } from './styles';
import TiptapEditor from '../../tiptapEditor';
import { CommentFilesInterface } from '../../../interfaces/TaskInterface';
import { isMediaRecordingSupported, removeFileFromFirebase } from '../../../helpers/common';
import RecordingSnapAttach from '../../recording';
import ModalCustom from '../../models/modal';
import InsertGifModal from '../../models/insertGifModal';
import { RenderFilePreview } from '../../renderFilePreview';
import { updateCreateTaskInput } from '../../../actions/taskActions';
import { useDispatch } from 'react-redux';

interface Props {
  valueContent: string;
  setValueContent: (value: string) => void;
  setPropsLoading: (value: boolean) => void;
  disable?: boolean;
  uploadedFiles: CommentFilesInterface[];
  setUploadedFiles: any;
  setStateLoading: (value: boolean) => void;
  handleOpenSnapModal: () => void;
  handleFileInput: () => void;
}
const DescriptionInputBox = forwardRef((props: Props, ref) => {
  const {
    disable = false,
    uploadedFiles,
    setUploadedFiles,
    valueContent,
    setValueContent,
    setPropsLoading,
    setStateLoading,
    handleOpenSnapModal,
    handleFileInput
  } = props;
  // Ref variables
  const editorRef = useRef<any>(null);
  //States
  const [openGifModal, setIsOpenGifModal] = useState(false);
  //other variables
  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    addRecordingCard(fileUrl: string, fileName: string) {
      editorRef.current.addRecordingCard(fileUrl, fileName);
    },
    addImage(fileUrl: string) {
      editorRef.current.addImage(fileUrl);
    },
    clearContent() {
      editorRef?.current?.clearValue();
    }
  }));

  const onClickClose = useCallback(
    (index: number, file: CommentFilesInterface) => {
      if (file && !file.uploadFileType && file.href.startsWith('https://firebasestorage.googleapis'))
        removeFileFromFirebase(file.href, dispatch);
      const filesClone = JSON.parse(JSON.stringify(uploadedFiles));
      filesClone.splice(index, 1);
      setUploadedFiles(filesClone);
      dispatch(updateCreateTaskInput({ propsName: 'Files', value: filesClone }));
    },
    [uploadedFiles, setUploadedFiles, dispatch]
  );

  const addDroppedFiles = useCallback(
    (files: CommentFilesInterface[]) => {
      setUploadedFiles((prevFiles: CommentFilesInterface[]) => [...(prevFiles || []), ...(files || [])]);
    },
    [setUploadedFiles]
  );

  const uploadSnapEmbed = useCallback((firebaseUrl: string, fileName: string, videoId: string) => {
    editorRef?.current?.addRecordingCard(firebaseUrl, fileName, videoId);
  }, []);

  const handleOpenGifModal = useCallback(() => {
    setIsOpenGifModal(true);
  }, []);

  const handleCloseGifModal = useCallback(() => {
    setIsOpenGifModal(false);
  }, []);
  return (
    <>
      <DescriptionInputSection>
        <TiptapEditor
          ref={editorRef}
          placeHolder='Add Description....'
          valueContent={valueContent}
          setValueContent={setValueContent}
          setLoading={(value: boolean) => {
            setPropsLoading(value);
          }}
          setStateLoading={(value: boolean) => setStateLoading(value)}
          addUploadedFiles={addDroppedFiles}
          users={[]}
          recordSnapModalOpen={handleOpenSnapModal}
          handleFileInput={handleFileInput}
          handleGifModal={handleOpenGifModal}
        />
        {isMediaRecordingSupported() && (
          <RecordingSnapAttach
            setLoading={(value: boolean) => {
              setPropsLoading(value);
            }}
            uploadSnapEmbed={uploadSnapEmbed}
          />
        )}
        {!disable && uploadedFiles?.length > 0 && (
          <FileBox>
            {uploadedFiles?.length > 0 &&
              uploadedFiles?.map((file: CommentFilesInterface, index: number) => {
                return (
                  <>
                    <RenderFilePreview
                      key={file?.href}
                      file={file}
                      isClose={true}
                      maxWidth={210}
                      onClose={() => onClickClose(index, file)}
                    />
                  </>
                );
              })}
          </FileBox>
        )}
      </DescriptionInputSection>
      <ModalCustom
        open={openGifModal}
        onClose={handleCloseGifModal}
        width={686}
        hasNotBoxShadow={true}
        isGifModal={true}>
        <InsertGifModal
          setSelectedGif={(url: string) => {
            editorRef.current.addImage(url);
          }}
          closeModal={handleCloseGifModal}
        />
      </ModalCustom>
    </>
  );
});

export default DescriptionInputBox;
