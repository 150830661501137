import { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Button, Buttons, Content } from './styles';
import EmptyState from '../emptyState';
import { useSelector } from 'react-redux';
import { TIME_REPORT_CHART_TYPE_ID } from '../../global/constants';
import { ChartDataInterface } from '../../interfaces/TimeReportInterface';
import reportdarkempty from '../../assets/images/emptystates/reportdarkempty.svg';
import reportempty from '../../assets/images/emptystates/report-empty.svg';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';

interface Props {
  projectWiseChartData: ChartDataInterface;
  onChangeChartType: (value: number) => void;
  isOverviewPage?: boolean;
}

const LineChart = ({ projectWiseChartData, onChangeChartType, isOverviewPage }: Props) => {
  //State
  const [chartData, setChartData] = useState<any[]>([]);
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { settings: settingsSelector, timeTracking: timeTrackingSelector } = stateSelector || {};
  const { themeMode } = settingsSelector;
  const { reportFilter } = timeTrackingSelector;
  //Other variable
  const getImageSource = () => (themeMode?.theme === 'dark' ? reportdarkempty : reportempty);
  const options: Highcharts.Options = {
    chart: {
      type: 'column',
      borderRadius: 8,
      marginTop: 18,
      marginRight: 24,
      marginLeft: 60,
      marginBottom: 55
    },
    xAxis: {
      categories: projectWiseChartData?.x_axis_data,
      title: {
        text: null
      },
      labels: {
        style: {
          color: 'var(--text-secondary)',
          fontSize: '12px',
          fontWeight: '500',
          lineHeight: '16px',
          fontFamily: 'Inter, sans-serif'
        }
      }
    },
    yAxis: {
      title: {
        text: ''
      },
      labels: {
        formatter: function (this: any) {
          // Convert minutes to hours and minutes format (e.g., 90 minutes to "1:30")
          const hours = Math.floor(this.value / 60);
          const minutes = this.value % 60;
          return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
        },
        style: {
          color: 'var(--text-secondary)',
          fontSize: '12px',
          fontWeight: '500',
          lineHeight: '16px',
          fontFamily: 'Inter, sans-serif'
        }
      }
    },
    plotOptions: {
      column: {
        stacking: 'normal'
      }
    },
    series: chartData,
    tooltip: {
      backgroundColor: 'var(--text-primary)',
      borderRadius: 2,
      style: {
        fontFamily: 'Inter, sans-serif',
        color: 'var(--background-primary)',
        fontSize: '12px',
        fontWeight: '500',
        lineHeight: '16px'
      },
      formatter: function () {
        return `<b>${this.series.name}</b>: ${Math.floor((this.y || 0) / 60)}h ${(this.y || 0) % 60}m<br/>`;
      }
    }
  };

  useEffect(() => {
    if (projectWiseChartData?.data) {
      const formattedData = projectWiseChartData?.data?.map((item) => ({
        name: item.name,
        data: item.data.map((time) => {
          const [hours, minutes] = time.split(' : ');
          return parseInt(hours) * 60 + parseInt(minutes);
        })
      }));
      setChartData(formattedData);
    }
  }, [projectWiseChartData]);

  return (
    <Content isOverviewPage={isOverviewPage}>
      <Buttons isOverviewPage={isOverviewPage}>
        <Button
          isactive={reportFilter?.chartType === TIME_REPORT_CHART_TYPE_ID.PROJECT}
          onClick={() => onChangeChartType(TIME_REPORT_CHART_TYPE_ID.PROJECT)}>
          Projects
        </Button>
        <Button
          isactive={reportFilter?.chartType === TIME_REPORT_CHART_TYPE_ID.MEMBER}
          onClick={() => onChangeChartType(TIME_REPORT_CHART_TYPE_ID.MEMBER)}>
          Members
        </Button>
      </Buttons>
      {chartData.length === 0 ? (
        !isOverviewPage && (
          <EmptyState
            hideButton={true}
            image={getImageSource()}
            header={`You don't have reports.`}
            title='There are no report data for this date. Please try another Filters.'
            ismargin={41}
            isgap={10}
            isReportTimeEmpty={true}
          />
        )
      ) : (
        <div id='chartContainer'>
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
      )}
    </Content>
  );
};

export default LineChart;
