import React, { useState, useEffect, useCallback } from 'react';
import { ItemDiv } from './styles';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';
import { useDispatch, useSelector } from 'react-redux';
// import AssignDropdownMenu from '../dropdowns/assignPopup/assignDropdownMenu';
import CalendarDropdownMenu from '../calendarPopUp/calendarDropdownMenu';
import { COMMENT_TYPE } from '../../global/constants';
import { TaskDetailsInterface, TaskGroupInterface } from '../../interfaces/TaskInterface';
import { trackAnalyticActivity } from '../../services/analyticsService';
import {
  MilestoneListInterface,
  ProjectDetailInterface,
  StatusListInterface,
  UserInterface
} from '../../interfaces/ProjectInterface';
import {
  createNewComment,
  getTaskGroupList,
  updateTaskLocalData,
  updateRecurringTaskDetails,
  updateTaskDetails,
  loadMyTaskData
} from '../../services/taskServices';
import { ProjectTemplateDetailsInterface } from '../../interfaces/SettingsInterface';
import {
  updateCreateTaskInput,
  updateMemberTaskField,
  updateMyTaskField,
  updateTaskField,
  updatSubTaskList
} from '../../actions/taskActions';
import { TASK_ANALYTICS } from '../../global/analyticsConstants';
import ModalCustom from '../models/modal';
import CreateTaskModal from '../models/createTaskModal';
import { Dropdown, DropdownDivider, DropdownItem } from '../Dropdown';
import { menuItems } from '../../helpers/list';
import { isEmpty, renderStatusIconName } from '../../helpers/common';
import { LabelDropdown } from '../dropdowns/labeldropdown/labelDropdownMenu';
import AssignBox from '../dropdowns/assignPopup';

interface MyCustomContextMenuProps {
  targetId: string;
  options?: string[];
  classes?: {
    listWrapper?: string;
    listItem?: string;
  };
  name?: string;
  taskData: TaskDetailsInterface;
  loadData: () => void;
  currentProject?: ProjectDetailInterface | ProjectTemplateDetailsInterface;
  setCurrentProject?: () => void;
  setCurrentActiveTask?: Function;
  isTemplateProject?: boolean;
  currentActiveTask: string;
  onClickView?: () => void;
  onClickClone?: () => void;
  onClickEdit?: () => void;
  onClickDelete?: () => void;
  onUpdateStatus?: (status: StatusListInterface, previousStatus: StatusListInterface) => void;
  isMyTask?: boolean;
  children: any;
  statusList: StatusListInterface[];
  isRecurringTask?: boolean;
  isSubTask?: boolean;
  // for hide create subtask option in milestone page
  isMilestonePage?: boolean;
  isMember?: boolean;
}
const MyCustomContextMenu: React.FC<MyCustomContextMenuProps> = ({
  taskData,
  currentProject,
  setCurrentProject,
  loadData,
  setCurrentActiveTask,
  onClickView,
  onClickClone,
  onClickEdit,
  onClickDelete,
  onUpdateStatus,
  isTemplateProject = false,
  isMyTask = false,
  children,
  statusList,
  isRecurringTask,
  isSubTask = false,
  isMilestonePage = false,
  isMember = false
}) => {
  //States
  const [calanderDate, setCalanderDate] = useState(new Date());
  const [openCreateTaskModel, setOpenCreateTaskModel] = useState(false);
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { task: tasksSelector, project: projectSelector } = stateSelector || {};
  const { taskGroups } = tasksSelector;
  const { milestoneList } = projectSelector;
  //Other task
  const dispatch = useDispatch();
  const currentStatus =
    statusList?.find((x) => x['_id'] === taskData?.statusId) || currentProject?.defaultStatus || statusList?.[0];

  useEffect(() => {
    if (taskData?.dueDate) {
      setCalanderDate(new Date(taskData.dueDate));
    }
  }, [taskData?.dueDate]);

  const onOpenChange = useCallback(
    (value: boolean) => {
      if (value) {
        if (setCurrentActiveTask) setCurrentActiveTask(taskData?.id);
        if (setCurrentProject) setCurrentProject();
      } else {
        if (setCurrentActiveTask) {
          setCurrentActiveTask('');
        }
      }
    },
    [setCurrentActiveTask, setCurrentProject, taskData?.id]
  );

  const closeContextModal = useCallback(
    (isAssigneeSection: boolean = false) => {
      if (currentProject?.multiAssignee && isAssigneeSection) {
        return;
      }
      if (setCurrentActiveTask) {
        setCurrentActiveTask('');
        onOpenChange(false);
      }
    },
    [currentProject?.multiAssignee, onOpenChange, setCurrentActiveTask]
  );

  const onClickUpdateStatus = useCallback(
    (status: StatusListInterface, previousStatus: StatusListInterface) => {
      if (onUpdateStatus) onUpdateStatus(status, previousStatus);
      closeContextModal();
    },
    [closeContextModal, onUpdateStatus]
  );

  const onClickGroup = useCallback(
    async (group: TaskGroupInterface) => {
      if (taskData?.groupId !== group?.id) {
        const previousGroup = taskGroups?.find((x) => x?.id === taskData?.groupId);
        const payloadTask = { groupId: group?.id, projectId: taskData?.projectId };
        const result = await dispatch(updateTaskDetails(taskData?.id, payloadTask));
        const commentResult = await dispatch(
          createNewComment(taskData?.id, {
            Type: COMMENT_TYPE.CHANGE_GROUP,
            PreviousGroup: previousGroup?.name,
            Group: group?.name
          })
        );

        if (result && commentResult) {
          trackAnalyticActivity(TASK_ANALYTICS.GROUP_CHANGED);
          loadData();
          closeContextModal();
        }
      }
    },
    [closeContextModal, dispatch, loadData, taskData?.groupId, taskData?.id, taskData?.projectId, taskGroups]
  );

  // remove parent task from sum task
  const removeParentTask = useCallback(async () => {
    const payloadTask = { parentUnset: true, projectId: taskData?.projectId };
    if (isMyTask || isMember) {
      const updatedTaskData = {
        taskId: taskData?.id,
        key: 'parentTaskId',
        value: ''
      };
      isMyTask ? dispatch(updateMyTaskField(updatedTaskData)) : dispatch(updateMemberTaskField(updatedTaskData));
    }
    const result = await dispatch(updateTaskDetails(taskData?.id, payloadTask));
    if (result) {
      if (isMyTask) {
        const updatedData = { ...taskData, parentTaskId: '' };
        dispatch(updateTaskLocalData(updatedData));
        if (!isMember) await dispatch(loadMyTaskData(false));
      }
      closeContextModal();
    }
  }, [closeContextModal, dispatch, isMember, isMyTask, taskData]);

  //create sub task
  const createSubTask = async () => {
    dispatch(updateCreateTaskInput({ propsName: 'projectId', value: currentProject }));
    await dispatch(getTaskGroupList(currentProject?.id, true));
    if (taskGroups?.length > 0) {
      dispatch(updateCreateTaskInput({ propsName: 'groupId', value: taskGroups[0] }));
    }
    dispatch(updateCreateTaskInput({ propsName: 'priority', value: currentProject?.priority?.default || 0 }));
    dispatch(updateCreateTaskInput({ propsName: 'parentTaskId', value: taskData.id }));
    setOpenCreateTaskModel(true);
  };

  // for cancel create task
  const handleCancel = useCallback(() => {
    setOpenCreateTaskModel(false);
  }, []);

  const onClickUpdatePriority = useCallback(
    async (key: number) => {
      if (isRecurringTask) {
        const payload = { priority: Number(key), projectId: taskData?.projectId };
        const response = await dispatch(updateRecurringTaskDetails(taskData?.id, payload));
        if (response) {
          trackAnalyticActivity(TASK_ANALYTICS.PRIORITY_CHANGED);
          loadData();
          closeContextModal();
        }
      } else {
        const updatedTaskData = {
          taskId: taskData?.id,
          key: 'priority',
          value: Number(key)
        };
        if (isMyTask || isMember) {
          isMyTask ? dispatch(updateMyTaskField(updatedTaskData)) : dispatch(updateMemberTaskField(updatedTaskData));
          const updatedData = { ...taskData, priority: Number(key) };
          dispatch(updateTaskLocalData(updatedData));
          isMyTask && (await dispatch(loadMyTaskData(false)));
        } else {
          dispatch(updateTaskField(updatedTaskData));
        }
        if (isSubTask) {
          const taskDetailsClone = JSON.parse(JSON.stringify(taskData));
          taskDetailsClone.priority = Number(key);
          dispatch(updatSubTaskList(taskDetailsClone));
        }
        const payloadTask = { priority: Number(key), projectId: taskData?.projectId };
        const result = await dispatch(updateTaskDetails(taskData?.id, payloadTask));
        const commentResult = await dispatch(
          createNewComment(taskData?.id, {
            Type: COMMENT_TYPE.CHANGE_PRIORITY,
            PreviousPriority: taskData?.priority,
            Priority: Number(key)
          })
        );
        if (result && commentResult) {
          trackAnalyticActivity(TASK_ANALYTICS.PRIORITY_CHANGED);
          closeContextModal();
        }
      }
    },
    [closeContextModal, dispatch, isMember, isMyTask, isRecurringTask, isSubTask, loadData, taskData]
  );

  const onViewClick = useCallback(() => {
    if (onClickView) onClickView();
    closeContextModal();
  }, [closeContextModal, onClickView]);

  const onCloneClick = useCallback(() => {
    if (onClickClone) onClickClone();
    closeContextModal();
  }, [closeContextModal, onClickClone]);

  const onEditClick = useCallback(() => {
    if (onClickEdit) onClickEdit();
    closeContextModal();
  }, [closeContextModal, onClickEdit]);

  const onDeleteClick = useCallback(() => {
    if (onClickDelete) onClickDelete();
    closeContextModal();
  }, [closeContextModal, onClickDelete]);

  const updateTaskMilestone = useCallback(
    async (milestone: MilestoneListInterface) => {
      const payloadTask = { milestoneId: milestone?.id, projectId: taskData?.projectId };
      dispatch(
        updateTaskField({
          taskId: taskData?.id,
          key: 'milestoneId',
          value: milestone?.id
        })
      );
      const result = await dispatch(updateTaskDetails(taskData?.id, payloadTask));
      const commentResult = await dispatch(
        createNewComment(taskData?.id, {
          Type: COMMENT_TYPE.SET_MILESTONE,
          Group: milestone?.milestoneName
        })
      );
      if (result && commentResult) {
        trackAnalyticActivity(TASK_ANALYTICS.MILESTONE_SELECTED);
        loadData();
        closeContextModal();
      }
    },
    [taskData?.projectId, taskData?.id, dispatch, loadData, closeContextModal]
  );

  const removeTaskMilestone = useCallback(async () => {
    if (!isEmpty(taskData?.milestoneId)) {
      const payloadTask = { milestoneIdUnset: true, projectId: taskData?.projectId };
      const result = await dispatch(updateTaskDetails(taskData?.id, payloadTask));
      if (result) {
        loadData();
        closeContextModal();
      }
    }
  }, [closeContextModal, dispatch, loadData, taskData?.id, taskData?.milestoneId, taskData?.projectId]);

  return (
    <div>
      <Dropdown
        setCurrentProject={setCurrentProject}
        content={children}
        trigger='contextmenu'
        isMinWidth={168}
        updateDropdownOpen={onOpenChange}>
        {!isMyTask && currentProject?.isGroupEnabled && (
          <Dropdown
            content={
              <ItemDiv>
                <DropdownItem
                  label='Group'
                  iconName='contextmenu-group-icon'
                  iconSize={16}
                  iconViewBox='0 0 18 18'
                  iconTone='stroke'
                />
              </ItemDiv>
            }
            trigger='hover'>
            {taskGroups.map((item) => (
              <DropdownItem
                key={item.index}
                label={item.name}
                onClick={() => {
                  onClickGroup(item);
                }}
              />
            ))}
          </Dropdown>
        )}
        {currentProject?.statusEnable && statusList?.length > 0 && (
          <Dropdown
            content={
              <ItemDiv>
                <DropdownItem label='Status' iconName='status-icon' iconSize={16} iconTone='stroke' />
              </ItemDiv>
            }
            trigger='hover'>
            {statusList.map((item, index) => (
              <DropdownItem
                key={index}
                label={item.Name}
                onClick={() => onClickUpdateStatus(item, currentStatus)}
                iconName={renderStatusIconName(item.Type)}
              />
            ))}
          </Dropdown>
        )}
        {(currentProject?.priority?.enabled || isMyTask === true) && (
          <Dropdown
            content={
              <ItemDiv>
                <DropdownItem label='Priority' iconName='Priority-High-icon' iconSize={16} iconTone='fill' />
              </ItemDiv>
            }
            trigger='hover'>
            {menuItems.map((item, index) => (
              <DropdownItem
                key={index}
                label={item.label}
                onClick={() => onClickUpdatePriority(item.key)}
                iconName={item.icon}
              />
            ))}
          </Dropdown>
        )}
        {currentProject?.isMilestone && !isMyTask && (
          <Dropdown
            content={
              <ItemDiv>
                <DropdownItem label='Milestone' iconName='milestone-option-icon' iconSize={16} iconTone='stroke' />
              </ItemDiv>
            }
            trigger='hover'>
            <DropdownItem label='No Milestone' onClick={removeTaskMilestone} />
            {milestoneList.map((item, index) => (
              <DropdownItem key={item['_id']} label={item.milestoneName} onClick={() => updateTaskMilestone(item)} />
            ))}
          </Dropdown>
        )}

        <AssignBox
          projectUsers={currentProject?.users as UserInterface[]}
          currentTask={taskData}
          isContextMenu={true}
          isMultiAssignee={currentProject?.multiAssignee}
          loadData={loadData}
        />

        {currentProject?.labelsEnabled && !isMyTask && (
          <LabelDropdown
            taskData={taskData}
            currentProject={currentProject as ProjectDetailInterface}
            isContextMenu={true}
          />
        )}

        <Dropdown
          content={
            <ItemDiv>
              <DropdownItem label='Due date' iconName='secound-calendar-icon' iconSize={16} />
            </ItemDiv>
          }
          trigger='hover'>
          <CalendarDropdownMenu
            isRecurringTask={isRecurringTask}
            isCustomcontextmenu={true}
            data={taskData}
            calanderDate={calanderDate}
            setCalanderDate={setCalanderDate}
            loadData={() => {
              loadData();
              closeContextModal();
            }}
            isMyTask={isMyTask}
            isSubTask={isSubTask}
          />
        </Dropdown>
        <DropdownDivider />
        {isSubTask ? (
          <DropdownItem
            label='Remove parent task'
            iconName='remove-parent-task-icon'
            iconSize={16}
            iconTone='stroke'
            onClick={removeParentTask}
          />
        ) : (
          !isMilestonePage && (
            <DropdownItem
              label=' Create subtask'
              iconName='create-sub-task-icon'
              iconSize={16}
              iconTone='stroke'
              onClick={createSubTask}
            />
          )
        )}
        <DropdownDivider />
        {!isRecurringTask && <DropdownItem onClick={onViewClick} label='View' iconName='view-icon' iconTone='stroke' />}
        {!isRecurringTask && (
          <DropdownItem onClick={onCloneClick} label='Clone' iconName='copy-icon-16x16' iconTone='stroke' />
        )}
        <DropdownItem onClick={onEditClick} label='Edit' iconName='edit-icon-16x16' iconTone='stroke' />
        <DropdownItem
          onClick={onDeleteClick}
          label='Delete'
          tone='critical'
          iconName='more-menu-delete-icon'
          iconSize={16}
        />
      </Dropdown>
      <ModalCustom open={openCreateTaskModel} onClose={handleCancel} isCreateTask={true}>
        <CreateTaskModal loadData={loadData} onCancel={handleCancel} istaskDetail={true} />
      </ModalCustom>
    </div>
  );
};

export default MyCustomContextMenu;
