import styled from 'styled-components';
import { Typography } from '../../../global/theme';
const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const Heading = styled.div`
  position: sticky;
  top: 60px;
  display: flex;
  justify-content: space-between;
  margin: 0 -24px 20px;
  border-bottom: 0.5px solid var(--border-primary);
  padding: 14px 24px;
  background: var(--background-secondary);
  z-index: 99;
  @media (max-width: 449px) {
    display: grid;
    grid-template-columns: repeat(1, 2fr);
    gap: 10px;
  }
`;

const Activity = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const ActivityTitle = styled.h2`
  ${Typography.heading_md}
  color: var(--text-primary);
  margin: 0;
  padding-bottom: 14px;
`;

const ItemBlock = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 0.5px solid var(--border-primary);
  padding-left: 28px;
  margin-left: 18px;
  :last-child {
    border-left: none;
  }
`;

const ItemChange = styled.div`
  display: flex;
  gap: 10px;
  margin-left: -58px;
  padding: 12px;
  position: relative;

  &:first-child {
    padding: 0 12px;
  }
  :last-child {
    margin-bottom: 15px;
  }
  @media (max-width: 449px) {
    gap: 12px;
  }
`;

const SubTitle = styled.h1`
  ${Typography.body_md_semibold}
  color: var(--text-primary);
  margin: 0;
  white-space: nowrap;
  span {
    ${Typography.body_md}
    color: var(--text-secondary);
  }
`;
const ActivityContentView = styled.div`
  display: flex;
`;
const ActivityContentsView = styled.div`
  display: flex;
  padding-bottom: 20px;
`;

const ActivitySubContent = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  @media (max-width: 449px) {
    flex-wrap: wrap;
  }
`;
const ActivityView = styled.div``;

const Text1 = styled.div`
  ${Typography.body_md}
  text-align: center;
  color: var(--text-secondary);
  margin: 0;
  padding-top: 2px;
  span {
    ${Typography.body_md_medium}
    color: var(--text-primary);
    margin: 0;
  }
`;
const ItemDiv = styled.div`
  max-width: 152px;
  width: 100%;
  @media (max-width: 449px) {
    max-width: unset;
  }
`;

export {
  Heading,
  Container,
  Activity,
  ActivityTitle,
  ItemBlock,
  ItemChange,
  SubTitle,
  ActivityContentView,
  ActivityContentsView,
  ActivitySubContent,
  ActivityView,
  Text1,
  ItemDiv
};
