import styled from 'styled-components';
import { Typography } from '../../../global/theme';

const Status = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  border-top: 0.5px solid var(--border-primary);
  padding-top: 12px;
  max-width: 463px;
  width: 100%;
`;

const PlusIcon = styled.div`
  width: 18px;
  height: 18px;
  cursor: pointer;
  :hover {
    background-color: var(--neutral-secondary);
    border-radius: 4px;
  }
  .svg-icon {
    path {
      stroke: var(--text-primary);
    }
  }
`;
const Iconhidden = styled.div`
  position: absolute;
  left: -18px;
  top: 7px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  .svgicon {
    opacity: 0;
    stroke: var(--text-secondary);
  }
`;

const StatusGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  max-width: 463px;
`;
const Head = styled.div`
  display: flex;
  justify-content: space-between;
  background: var(--background-secondary);
  padding: 6px 8px;
  border-radius: 4px;
  align-items: center;
`;
const Bottom = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 6px 8px;
  align-items: center;
  &:hover {
    background: var(--neutral-secondary) !important;
    border-radius: 30px !important;
  }
  :hover .svgicon {
    opacity: 1;
  }
`;
const Heading = styled.div`
  ${Typography.body_md_medium}
  text-align: left;
  color: var(--text-primary);
`;
const BottomTitle = styled.div`
  ${Typography.body_md_medium}
  text-align: left;
  color: var(--text-primary);
`;
const LeftDiv = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  .svg-icon {
    path {
      stroke: var(--text-secondary);
    }
  }
  .cancelled-icon {
    path {
      fill: var(--text-tertiary);
    }
  }
  .task-status-icon {
    path.fill1 {
      fill: var(--background-primary);
    }
    path.fill2 {
      fill: var(--text-secondary);
    }
    path.fill3 {
      fill: var(--background-primary);
    }
  }
  .task-todo-icon {
    path {
      fill: var(--background-primary);
      stroke: var(--text-secondary);
    }
  }
  .task-inprogress-icon {
    path.first-path {
      fill: var(--background-primary);
    }
  }
  .task-inreview-icon {
    path.first-path {
      fill: var(--background-primary);
    }
  }
  .task-completed-icon {
    path {
      fill: var(--text-tertiary);
    }
  }
`;

const Default = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 3px 5px;
  ${Typography.body_xs}
  color: var(--text-primary);
  background: var(--background-muted);
  border-radius: 3px;
`;
const Div = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export { Status, Iconhidden, PlusIcon, StatusGroup, Head, Heading, Bottom, BottomTitle, LeftDiv, Default, Div };
