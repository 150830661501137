import React from 'react';
import {
  Bottom,
  CheckBox,
  Content,
  Description,
  Group,
  Header,
  Left,
  Personaltext,
  Right,
  Storage,
  TeamMember,
  Title,
  Top,
  TopTitle,
  Total
} from './style';
const UsageLoadingState: React.FC = () => {
  return (
    <>
      <Group>
        <Header>
          <Personaltext>Usage</Personaltext>
        </Header>
        <Content>
          <Storage>
            <Left>
              <div className='circle loading-animation'>
                <div className='segment segment1'></div>
                <div className='inner-circle'></div>
              </div>
            </Left>
            <Right>
              <Title className='loading-animation'></Title>
              <Description className='loading-animation'></Description>
            </Right>
          </Storage>
          <Total>
            <TeamMember>
              <Top>
                <TopTitle className='loading-animation'></TopTitle>
                <CheckBox className='loading-animation'></CheckBox>
              </Top>
              <Bottom className='loading-animation'></Bottom>
            </TeamMember>
            <TeamMember>
              <Top>
                <TopTitle className='loading-animation'></TopTitle>
              </Top>
              <Bottom className='loading-animation'></Bottom>
            </TeamMember>
            <TeamMember>
              <Top>
                <TopTitle className='loading-animation'></TopTitle>
              </Top>
              <Bottom className='loading-animation'></Bottom>
            </TeamMember>
          </Total>
        </Content>
      </Group>
    </>
  );
};

export default UsageLoadingState;
