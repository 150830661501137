import React, { useEffect, useCallback, useState, useMemo } from 'react';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import {
  Estimatebox,
  Rightrow1,
  Titles,
  EstimateIcon,
  TaskModal,
  Input,
  Lable,
  Inputlable,
  Buttons,
  Text
} from './styles';
import Button from '../../button';
import dayjs from 'dayjs';
import { floatToHHMM, isEmpty } from '../../../helpers/common';
import { isString } from 'lodash';
import TimePickerModal from '../../timePicker';
import Dropdown from '../../Dropdown/MainDropdown';

interface Props {
  createTask?: boolean;
  taskData?: any;
  setEstimate?: any;
  isTaskDetails?: boolean;
  isEstimateDropdownOpen?: boolean;
  setIsEstimateDropdownOpen: (value: boolean) => void;
}

const Estimatedropdown: React.FC<Props> = (props) => {
  const { isTaskDetails = false, taskData, setEstimate, createTask = false, setIsEstimateDropdownOpen } = props;
  //State
  const [selectedTime, setSelectedTime] = useState('00:00');

  const handleTimeChange = (value: any) => {
    setSelectedTime(value);
  };

  const onclickSet = useCallback(
    async (time: string) => {
      await setEstimate(time);
      setIsEstimateDropdownOpen(false);
    },
    [setEstimate, setIsEstimateDropdownOpen]
  );

  const toggleCloseDropdown = useCallback(
    async (value: boolean) => {
      setIsEstimateDropdownOpen(value);
    },
    [setIsEstimateDropdownOpen]
  );

  const handleInputClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent the dropdown from closing when clicking inside the input
  }, []);

  useEffect(() => {
    if (
      !isEmpty(taskData?.estimate) &&
      !isString(taskData?.estimate) &&
      taskData?.estimate !== '00:00' &&
      taskData?.estimate !== 0
    ) {
      setSelectedTime(floatToHHMM(taskData?.estimate));
    }
  }, [taskData?.estimate, setSelectedTime]);

  const taskDetailsDropdown = useMemo(() => {
    return (
      <>
        <SVGIcon name='Estimate-icon' width='16' height='16' viewBox='0 0 16 16' fill='none' className='time-icon' />
        <Titles isTaskDetails={isTaskDetails}>
          {!isEmpty(taskData?.estimate) &&
          isString(taskData?.estimate) &&
          taskData?.estimate !== '00:00' &&
          taskData?.estimate !== 0
            ? taskData?.estimate
            : !isEmpty(taskData?.estimate) &&
              !isString(taskData?.estimate) &&
              taskData?.estimate !== '00:00' &&
              taskData?.estimate !== 0
            ? floatToHHMM(taskData?.estimate)
            : 'Set Time'}
        </Titles>
      </>
    );
  }, [isTaskDetails, taskData?.estimate]);

  const createTaskDropdown = useMemo(() => {
    return (
      <>
        <EstimateIcon>
          <TaskModal changeIconColor={!isTaskDetails && !isEmpty(taskData?.estimate)}>
            <SVGIcon
              name='Estimate-icon'
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              className='time-icon'
            />
          </TaskModal>
        </EstimateIcon>
        {!isEmpty(taskData?.estimate) && taskData?.estimate !== '00:00' && taskData?.estimate !== 0 ? (
          <Titles isTaskDetails={isTaskDetails}>
            {isString(taskData?.estimate) ? taskData?.estimate : floatToHHMM(taskData?.estimate)}
          </Titles>
        ) : (
          <Text isCreateTask={createTask}>Estimate</Text>
        )}
      </>
    );
  }, [createTask, isTaskDetails, taskData?.estimate]);

  return (
    <>
      <Dropdown
        isMinWidth={198}
        content={
          <Rightrow1 isCreateTask={createTask} isTaskDetails={isTaskDetails}>
            {isTaskDetails ? taskDetailsDropdown : createTaskDropdown}
          </Rightrow1>
        }
        trigger='click'
        modalTitle='Estimation'>
        <Estimatebox>
          <Inputlable>
            <Lable>Duration (HH:MM)</Lable>
            <Input onClick={handleInputClick}>
              <TimePickerModal
                value={dayjs(selectedTime, 'HH:mm')}
                onSelect={(value) => handleTimeChange(value.format('HH:mm'))}
              />
            </Input>
          </Inputlable>
          <Buttons>
            <Button title='Set' onClick={() => onclickSet(selectedTime)} modelbtn={true} />
            <Button title='Cancel' secondary onClick={() => toggleCloseDropdown(false)} modelbtn={true} />
          </Buttons>
        </Estimatebox>
      </Dropdown>
    </>
  );
};

export default Estimatedropdown;
