import { ActionCreatorWithPayload, createAction } from '@reduxjs/toolkit';
import * as Actions from '../actions/types';

/**
 * @desc Set Customer loader
 */
export const setDocLoader: ActionCreatorWithPayload<boolean, string> = createAction(Actions.SET_DOC_LOADER);

/**
 * @desc Set Customer list
 */
export const setDocVersionList: ActionCreatorWithPayload<any, string> = createAction(Actions.SET_DOC_VERSION_LIST);
