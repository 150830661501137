import moment from 'moment';
import UserPreferenceSingleton from '../helpers/userPreferenceSingleton';
import LocalDbService, { TASKS_TABLE_NAME, UPDATE_ON_ID } from './localDbService';
import { isEmpty } from '../helpers/common';
import { TaskDetailsInterface } from '../interfaces/TaskInterface';

let singleton: any;
let organizationId: any;

export default class TasksCacheService extends LocalDbService {
  constructor(props: any) {
    const db_name = `${props.organizationId}`;
    props.dbName = db_name;
    props.tableName = TASKS_TABLE_NAME;
    super(props);
  }

  static getInstance() {
    const company = UserPreferenceSingleton.getInstance().getWorkspace();
    if (company) {
      if (!singleton || organizationId !== company?.id) {
        organizationId = company?.id;
        singleton = new TasksCacheService({ organizationId: company?.id });
      }
    }
    return singleton;
  }

  static removeInstance() {
    if (singleton) singleton.closeDb();
    organizationId = undefined;
    singleton = undefined;
  }

  async getTasksByWorkspace() {
    const rows = await this.getDb().toArray();
    const result = rows
      .map((x: { value: TaskDetailsInterface }) => x.value)
      .filter((x: TaskDetailsInterface) => isEmpty(x?.parentTaskId));
    return result || [];
  }

  async getTasksByUser(userId: string, projectIds: string[]) {
    const rows = await this.getDb().where('projectId').anyOf(projectIds).sortBy('createdAt');

    const result: TaskDetailsInterface[] = rows.map((x: { value: TaskDetailsInterface }) => x.value);
    const childTasks = result?.filter((x) => !isEmpty(x?.parentTaskId));
    if (childTasks?.length > 0) {
      const updatedTasks = result?.map((task) => {
        let taskDetails = task;
        if (!isEmpty(taskDetails?.parentTaskId)) {
          const parentTask = result?.find((x) => x?.id === taskDetails?.parentTaskId);
          if (parentTask) {
            taskDetails.parentTaskName = parentTask?.name;
          } else {
            taskDetails.parentTaskId = undefined;
          }
        }
        const subTasks = childTasks?.filter((x) => x?.parentTaskId === taskDetails?.id);
        if (subTasks?.length > 0) {
          taskDetails.subTasks = subTasks;
        }
        return taskDetails;
      });
      return updatedTasks?.filter((x) => !x.status && x?.users?.some((y: any) => y?.id === userId));
    }
    return result?.filter((x) => !x.status && x?.users?.some((y: any) => y?.id === userId));
  }

  async getTasksByProject(projectId: string, isSubTaskLoading = true) {
    if (projectId && this.db) {
      const rows = await this.getDb().where('projectId').equals(projectId).sortBy('createdAt');
      const result: TaskDetailsInterface[] = rows.map((x: { value: TaskDetailsInterface }) => x.value);
      const filteredTasks = isSubTaskLoading ? result : result?.filter((x) => isEmpty(x?.parentTaskId));
      const childTasks = result?.filter((x) => x?.parentTaskId);
      if (childTasks?.length > 0) {
        const updatedTasks = filteredTasks?.map((task) => {
          const subTasks = childTasks?.filter((x) => x?.parentTaskId === task?.id);
          if (subTasks?.length > 0) {
            return { ...(task || {}), subTasks: subTasks };
          } else {
            return task;
          }
        });
        return updatedTasks;
      }
      return filteredTasks;
    }
    return [];
  }

  async getAllTaskByProject(projectId: string) {
    if (projectId && this.db) {
      const rows = await this.getDb().where('projectId').equals(projectId).sortBy('createdAt');
      const result: TaskDetailsInterface[] = rows.map((x: { value: TaskDetailsInterface }) => x.value);
      return result;
    }
    return [];
  }

  async getTasksByDueDate(projectId: string, startDueDate: Date, endDueDate: Date, showSubTask: boolean) {
    if (projectId && this.db) {
      const rows = await this.getDb().where('projectId').equals(projectId).sortBy('createdAt');
      const result = rows
        .map((x: { value: TaskDetailsInterface }) => x?.value)
        .filter(
          (x: TaskDetailsInterface) =>
            new Date(x?.dueDate) >= new Date(startDueDate) &&
            new Date(x?.dueDate) <= new Date(endDueDate) &&
            (showSubTask || isEmpty(x?.parentTaskId))
        );
      return result;
    }
    return [];
  }

  async getItem(id: any) {
    if (this.db) {
      const rows = await this.getDb().where({ id: id }).limit(1).toArray();
      if (rows && rows.length > 0) {
        const result = rows.map((x: any) => x.value).filter((x: any) => x);
        const allRows = await this.getDb().toArray();
        const allResult: TaskDetailsInterface[] = allRows.map((x: { value: TaskDetailsInterface }) => x.value);
        const childTasks = allResult?.filter((x) => !isEmpty(x?.parentTaskId));
        if (childTasks?.length > 0) {
          const subTasks = childTasks?.filter((x) => x?.parentTaskId === result?.[0]?.id);
          return { ...(result[0] || {}), subTasks };
        }
        return result[0];
      }
    }
    return null;
  }

  async getFilteredTasks(filter: any) {
    if (this.db) {
      const rows = await this.getDb()
        .filter(
          (x: any) =>
            (filter?.project?.length > 0 ? filter?.project?.includes(x?.projectId) : true) &&
            (filter?.users?.length > 0 ? x?.value?.users?.some((y: any) => filter?.users?.includes(y.id)) : true) &&
            (filter?.status
              ? (filter.status.includes(1) && x?.status === true) ||
                (filter.status.includes(0) && x?.status === false) ||
                (!filter.status.includes(1) && !filter.status.includes(0))
              : true) &&
            (filter?.dueDate
              ? (filter.dueDate.includes(0) && !isEmpty(x?.value?.dueDate) && moment(x?.value?.dueDate) <= moment()) ||
                (filter.dueDate.includes(1) && !isEmpty(x?.value?.dueDate) && moment(x?.value?.dueDate) >= moment()) ||
                (filter.dueDate.includes(2) && isEmpty(x?.value?.dueDate))
              : true)
        )
        .toArray();
      const tasks = await Promise.all(
        rows?.map(async (x: { value: TaskDetailsInterface }) => {
          let taskDetails = x.value;
          if (isEmpty(taskDetails?.parentTaskId)) return taskDetails;
          else {
            const parentTask = await TasksCacheService.getInstance()?.getItem(taskDetails?.parentTaskId);
            return { ...taskDetails, parentTaskName: parentTask?.name };
          }
        })
      );
      return tasks;
    }
    return [];
  }

  async getSubTasks(projectId: string, taskId: string) {
    const rows = await this.getDb().where('projectId').equals(projectId).sortBy('createdAt');
    const result = rows
      .map((x: { value: TaskDetailsInterface }) => {
        if (isEmpty(x.value.subtaskIndex)) {
          x.value.subtaskIndex = new Date(x.value.createdOn).getTime();
        }
        return x.value;
      })
      .filter((x: any) => x.parentTaskId === taskId);
    return result || [];
  }

  async setTasks(value: any, companyId: string) {
    if (!value || companyId !== organizationId) return false;
    const result = await this.addBulk(value);
    return result;
  }

  async updateTasks(newList: any, archivedList: any, companyId: any) {
    if (companyId !== organizationId) return false;
    await this.syncList(newList, archivedList);
  }

  async getLastUpdatedTime(workspace_id?: string) {
    const result = await super.getLastUpdatedTime(`${UPDATE_ON_ID.TASKS}_${workspace_id}`);
    return result;
  }

  async setLastUpdatedTime(value: any, companyId: string) {
    if (companyId !== organizationId) return false;
    const result = await super.setLastUpdatedTime(`${UPDATE_ON_ID.TASKS}_${companyId}`, value);
    return result;
  }

  async getLastProjectList(workspace_id?: string) {
    const result = await super.getLastUpdatedTime(`LAST_PROJECT_WISE_UPDATED_TIME_LIST_${workspace_id}`);
    return result === 0 || !result ? {} : result;
  }

  async setLastProjectList(value: any, companyId: string) {
    if (companyId !== organizationId) return false;
    const result = await super.setLastUpdatedTime(`LAST_PROJECT_WISE_UPDATED_TIME_LIST_${companyId}`, value);
    return result;
  }
}
