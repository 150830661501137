import styled, { css } from 'styled-components';

interface Props {
  isProjectDetail?: boolean;
}
const MessageInputSection = styled.div<Props>`
  display: flex;
  align-items: center;
  border: 0.5px solid var(--border-primary);
  background-color: var(--background-primary);
  border-radius: 8px;
  padding: 8px 8px 8px 12px;
  min-height: 30px;
  box-shadow: var(--shadow-card);
  position: relative;
  @media (max-width: 449px) {
    position: relative;
    margin: 0 16px;
    ${(props) =>
      props.isProjectDetail &&
      css`
        margin: 0;
      `}
  }
`;
const IconSection = styled.div`
  display: flex;
  gap: 4px;
  position: absolute;
  right: 8px;
  bottom: 8px;
  .active {
    svg {
      fill: var(--text-primary);
    }
  }
`;
const Icon = styled.div<{ disable?: boolean }>`
  display: flex;
  position: relative;
  .fillColor {
    fill: var(--text-secondary);
    cursor: pointer;
    :hover {
      fill: var(--text-primary);
    }
  }
  .attachment-fill-Color {
    fill: var(--text-secondary);
    cursor: pointer;
    :hover {
      fill: var(--text-primary);
    }
  }
  ${(props) =>
    props.disable &&
    css`
      pointer-events: none;
    `}
`;
const TextBox = styled.div`
  width: calc(100% - 104px);
  .tiptap {
    max-height: 240px;
    overflow: auto;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const Uploaddiv = styled.div`
  color: var(--text-primary);
`;

const FileBox = styled.div<{ isApplyMargin: string }>`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: ${(props) => props.isApplyMargin};
  padding: 0;
`;

export { MessageInputSection, IconSection, Icon, TextBox, Uploaddiv, FileBox };
