/**
 * @desc Environment Variables
 */
export const REACT_APP_ENV = process.env.REACT_APP_ENV;
export const REACT_APP_APIURL = process.env.REACT_APP_APIURL;
export const REACT_APP_APIURL1 = process.env.REACT_APP_APIURL1;
export const STRIPE_URL = process.env.REACT_APP_STRIPE_URL;
export const REACT_APP_SYNCFUSION_KEY: any = process.env.REACT_APP_SYNCFUSION_KEY;
export const REACT_APP_TEAMCAMP_APIURL = process.env.REACT_APP_TEAMCAMP_APIURL;
export const REACT_APP_FIGMA_OAUTH_URL = process.env.REACT_APP_FIGMA_OAUTH_URL;
export const REACT_APP_AMPLITUDE_ANALYTICS_KEY: any = process.env.REACT_APP_AMPLITUDE_ANALYTICS_KEY;
export const REACT_APP_SENTRY_KEY = process.env.REACT_APP_SENTRY_KEY;
export const REACT_APP_CONTENTFUL_SPACE_ID = process.env.REACT_APP_CONTENTFUL_SPACE_ID;
export const REACT_APP_CONTENTFUL_PREVIEW_ACCESS_TOKEN = process.env.REACT_APP_CONTENTFUL_PREVIEW_ACCESS_TOKEN;
export const REACT_APP_FIREBASE_CONFIG = process.env.REACT_APP_FIREBASE_CONFIG;
export const REACT_APP_CONECT_API_KEY = process.env.REACT_APP_CONECT_API_KEY;
export const REACT_APP_BLOCKSUITE_URL = process.env.REACT_APP_BLOCKSUITE_URL;
export const REACT_APP_BLOCKSUITE_WS_URL = process.env.REACT_APP_BLOCKSUITE_WS_URL;
export const REACT_APP_SUBSCRIPTION_PRICE_ID = process.env.REACT_APP_SUBSCRIPTION_PRICE_ID;
export const REACT_APP_GITHUB_APP_PUBLIC_URL = process.env.REACT_APP_GITHUB_APP_PUBLIC_URL;
export const REALTIME_WS_URL = process.env.REACT_APP_REALTIME_WS_URL;
export const REACT_APP_SENTRY_URL = process.env.REACT_APP_SENTRY_URL;
