import styled, { css } from 'styled-components';
import { Typography } from '../../../global/theme';

interface Props {
  isCreateTask?: boolean;
  isTaskDetails?: boolean;
}

const RightViewer = styled.div<Props>`
  cursor: pointer;
`;
const Dropdown = styled.div`
  position: relative;
`;
const Rightrow1 = styled.div<Props>`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  .dropdown {
    height: 16px;
  }
  ${(props: Props) =>
    props.isCreateTask &&
    css`
      padding: 2px 7px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 6px;
      border: 0.5px solid var(--border-primary);
      :hover {
        background: var(--neutral-secondary);
        border-radius: 5px;
        border: 0.5px solid var(--border-primary);
        p {
          color: var(--text-primary) !important;
        }
        .time-icon {
          path {
            stroke: var(--text-primary);
          }
        }
      }
      @media (max-width: 449px) {
        &.show-text {
          padding: 2px 7px;
        }
      }
    `}

  ${(props: Props) =>
    props.isTaskDetails &&
    css`
      display: flex;
      align-items: center;
      padding: 5px 11px;
      border: 0.5px solid transparent;
      cursor: pointer;
      border-radius: 6px;
      pointer-events: auto;
      ul {
        list-style-type: none;
      }

      li {
      }
      .plus-icon {
        visibility: hidden;
      }
      :hover {
        background: var(--background-primary);
        border: 0.5px solid var(--border-primary);
        border-radius: 6px;
      }
    `}
  .time-icon {
    path {
      stroke: var(--text-primary);
    }
  }
`;

const Titles = styled.div<Props>`
  ${Typography.body_md_medium}
  color: var(--text-primary);
  white-space: nowrap;
  margin: 0;
  p {
    ${Typography.body_md_medium}
    color: var(--text-secondary) !important;
    margin: 0;
  }
  ${(props: Props) =>
    props.isTaskDetails &&
    css`
      color: var(--text-primary) !important;
    `}
  @media (max-width: 449px) {
    display: flex;
    ${(props: Props) =>
      props.isTaskDetails &&
      css`
        display: block;
      `}
  }
`;
const Text = styled.div<Props>`
  ${Typography.body_md_medium}
  color: var(--text-secondary) !important;
  margin: 0;
  ${(props: Props) =>
    props.isCreateTask &&
    css`
      display: none;
    `}
`;
const EstimateIcon = styled.div`
  display: flex;
  align-items: center;
`;

const TaskModal = styled.div`
  width: 16px;
  height: 16px;
  margin: 2px 0;
  .time-icon {
    path {
      stroke: var(--text-secondary);
    }
  }
  @media (max-width: 449px) {
    svg {
      path {
        ${(props: { changeIconColor?: boolean }) =>
          props.changeIconColor &&
          css`
            stroke: var(--text-secondary) !important;
          `}
      }
    }
  }
`;

const Input = styled.div`
  background: var(--background-primary);
  width: 100%;
  &:focus-visible {
    outline: none;
  }
  .ant-picker {
    width: 100%;
    background: var(--background-primary);
    border: 0.5px solid var(--border-primary);
    padding: 6px 11px;
    ${Typography.body_lg_medium}
    box-shadow: none;
  }
  .ant-picker .ant-picker-input > input {
    color: var(--text-primary);
  }
  .ant-btn-primary {
    background: var(--brand-primary);
  }
  .ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
    background: var(--brand-primary-hover);
  }
  .ant-picker-dropdown .ant-picker-panel-container .ant-picker-panel {
    border: none !important;
  }
  .ant-picker-dropdown {
    min-width: 122px !important;
  }
  .ant-picker-dropdown .ant-picker-ranges > li {
    line-height: normal;
  }
  @media (max-width: 449px) {
    .ant-picker-dropdown .ant-picker-time-panel-column {
      overflow-y: auto;
    }
  }
`;
const Lable = styled.div`
  color: var(--text-secondary);
  ${Typography.body_md_medium}
`;
const Box = styled.div`
  position: absolute;
  pointer-events: none;
  margin-top: 6px;
  z-index: 99;
`;
const Estimatebox = styled.div`
  padding: 8px 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  @media (max-width: 449px) {
    padding: 8px 22px;
  }
`;

const Inputlable = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  pointer-events: auto;
  input[type='time'] {
    color: var(--text-secondary) !important;
    ${Typography.body_lg_medium}
  }
  input[type='time'].selected {
    color: var(--text-primary) !important;
  }

  input[type='time']::-webkit-calendar-picker-indicator {
    opacity: 0;
    cursor: pointer;
  }
  input[type='time']::-webkit-inner-spin-button,
  input[type='time']::-webkit-clear-button {
    display: none;
  }
`;
const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  pointer-events: auto;
`;

const Main = styled.div<Props>`
  ${(props: Props) =>
    props.isTaskDetails &&
    css`
      width: 100%;
      display: flex;
      justify-content: flex-start;
      position: absolute;
      @media (max-width: 1024px) {
        justify-content: flex-end;
      }
      @media (max-width: 449px) {
        justify-content: flex-start;
      }
    `}
`;

export {
  Estimatebox,
  RightViewer,
  Rightrow1,
  Titles,
  EstimateIcon,
  TaskModal,
  Input,
  Lable,
  Box,
  Inputlable,
  Buttons,
  Dropdown,
  Main,
  Text
};
