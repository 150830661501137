/* eslint-disable no-template-curly-in-string */
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { setCurrentProject } from '../../../actions/projectActions';
import { FillDiv, ProjectName, ProjectProgress } from './styles';
import { toJS } from 'mobx';

interface ProjectNameInterface {
  item: any;
  onCloseNavbar?: () => void;
}
const ProjectItem: React.FC<ProjectNameInterface> = (props) => {
  const { item, onCloseNavbar } = props;
  //Other variable
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const isProject = location?.pathname?.startsWith(`/projects/details/${item?.id}`);

  const onClickProject = useCallback(() => {
    dispatch(setCurrentProject(toJS(item)));
    history.push(`/projects/details/${item?.id}/tasks`);
    onCloseNavbar?.();
  }, [dispatch, item, history, onCloseNavbar]);

  return (
    <>
      <ProjectName
        onClick={onClickProject}
        isActive={isProject}
        className={isProject ? 'active-project-name' : 'project-name'}>
        <ProjectProgress>
          <FillDiv ProgressPercentage={Number(item?.progressData.progress)} status={item?.progressData.status} />
        </ProjectProgress>
        <p>{item?.name}</p>
      </ProjectName>
    </>
  );
};

export { ProjectItem };
