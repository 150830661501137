import { makeAutoObservable } from 'mobx';
import { AvatarUserInterface } from '../../interfaces/WorkspaceInterface';
import { RawUser } from '../interfaces/user';

export class UserModel {
  // Common
  id: string;
  email: string;
  name: string;
  avatar: AvatarUserInterface;
  isOwner?: boolean;
  isAdmin: boolean;
  phone: string | undefined;
  profile_photo: string | undefined;
  role?: number;

  constructor(rawUser: RawUser) {
    makeAutoObservable(this);

    this.id = rawUser.id;
    this.email = rawUser.email;
    this.name = rawUser.name;
    this.avatar = rawUser.avatar;
    this.isOwner = rawUser.isOwner;
    this.isAdmin = rawUser.isAdmin;
    this.phone = rawUser.phone;
    this.profile_photo = rawUser.profile_photo;
    this.role = rawUser.role;
  }
}

export const createObservableUser = (rawUser: RawUser) => {
  return new UserModel(rawUser);
};
