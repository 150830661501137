import styled from 'styled-components';
import { Typography } from '../../../global/theme';

const ModalBox = styled.div`
  max-width: 686px;
  width: 100%;
  background-color: var(--background-primary);
  border-radius: 8px;
  border: 0.5px solid var(--border-primary);
  height: 67dvh;
  display: flex;
  flex-direction: column;
`;
const Input = styled.input`
  border-bottom: 0.5px solid var(--border-primary);
  background-color: var(--background-primary);
  border-radius: 8px 8px 0 0;
  padding: 13px 16px;
  ${Typography.body_lg};
  color: var(--text-primary);
  border-top: none;
  border-left: none;
  border-right: none;

  ::placeholder {
    color: var(--text-secondary);
  }
  :focus {
    outline: none;
  }
`;
const GifItem = styled.div`
  padding: 8px 8px 8px 8px;
  column-count: 3;
  column-gap: 8px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  .gif-container {
    position: relative;
    width: 100%;
    max-width: 218px;
    height: max-content;
    cursor: pointer;
    display: flex;
    margin-bottom: 8px;
    img {
      width: 100%;
      height: auto;
    }

    &:hover::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
  @media (max-width: 449px) {
    column-count: 2;
  }
`;
export { ModalBox, Input, GifItem };
