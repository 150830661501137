import { combineReducers } from 'redux';
import { createReducer } from '../helpers/reduxHelpers';
import * as Actions from '../actions/types';
import { current, PayloadAction, createReducer as createReducerOrig } from '@reduxjs/toolkit';
import { InviteInputInterface } from '../interfaces/AuthInterface';
import { ReducerInterface } from '../interfaces/ReducerInterface';
import { DEFAULT_DATE_FORMAT } from '../global/constants';

const currentUserReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_CURRENT_USER
});

const authLoaderReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_AUTH_LOADER
});

const authValidationErrorReducer = createReducer({
  initialState: null,
  actionType: Actions.SET_AUTH_VALIDATION_ERRORS
});

const setUserDetails = createReducer({
  initialState: { dateFormat: DEFAULT_DATE_FORMAT },
  actionType: Actions.SET_USER_PROFILE_DATA
});

const passwordinitialState = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: ''
};
const passwordDetailReducer = createReducerOrig(passwordinitialState, (builder: any) => {
  builder
    .addCase(Actions.SET_PASSWORD_DETAIL, (state = passwordinitialState, action: PayloadAction<any, never>) => {
      return { ...(action.payload || {}) };
    })
    .addCase(Actions.UPDATE_PASSWORD_DETAIL, (state: any, action: PayloadAction<any, never>) => {
      const user = JSON.parse(JSON.stringify(current(state)));
      user[action.payload.propsName] = action.payload.value;
      return user;
    })
    .addCase(Actions.CLEAR_PASSWORD_DETAIL, () => {
      const initialData = JSON.parse(JSON.stringify(passwordinitialState));
      return initialData;
    });
});

const membershipInviteState = {
  companyName: '',
  email: '',
  invitedBy: '',
  name: ''
};
const inviteInputReducer = createReducerOrig(membershipInviteState, (builder: any) => {
  builder
    .addCase(
      Actions.SET_INVITE_INPUT,
      (state = membershipInviteState, action: PayloadAction<InviteInputInterface, never>) => {
        return { ...(action.payload || {}) };
      }
    )
    .addCase(Actions.UPDATE_INVITE_INPUT, (state: any, action: PayloadAction<ReducerInterface, never>) => {
      const user = JSON.parse(JSON.stringify(current(state)));
      user[action.payload.propsName] = action.payload.value;
      return user;
    })
    .addCase(Actions.CLEAR_INVITE_INPUT, () => {
      const initialData = JSON.parse(JSON.stringify(membershipInviteState));
      return initialData;
    });
});

const authReducer = combineReducers({
  currentUser: currentUserReducer,
  loading: authLoaderReducer,
  validationError: authValidationErrorReducer,
  passwordDetail: passwordDetailReducer,
  inviteInput: inviteInputReducer,
  userDetails: setUserDetails
});

export default authReducer;
