import styled from 'styled-components';
import { Typography } from '../../../global/theme';

const Group = styled.div`
  width: 100%;
  max-width: 662px;
  margin: 0 auto;
  .loading-animation {
    overflow: hidden;
  }

  .loading-animation::before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    animation: loading 1s infinite;
    background: linear-gradient(to right, transparent, var(--background-tertiary), transparent);
  }

  @keyframes loading {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 0 12px;
  width: 100%;
  max-width: 662px;
  margin: 0 auto;
  align-items: center;
`;
const Personaltext = styled.div`
  ${Typography.heading_lg}
  color: var(--text-primary);
`;
const Total = styled.div`
  display: flex;
  gap: 16px;
  @media (max-width: 449px) {
    flex-wrap: wrap;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Storage = styled.div`
  width: 100%;
  max-width: 622px;
  border: 0.5px solid var(--border-primary);
  border-radius: 12px;
  padding: 19px;
  background-color: var(--background-primary);
  box-shadow: var(--shadow-card);
  display: flex;
  gap: 24px;
  align-items: center;
  @media (max-width: 449px) {
    width: auto;
  }
`;
const Left = styled.div`
  width: 136px;
  height: 136px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .circle {
    width: 136px;
    height: 136px;
    border-radius: 50%;
    position: relative;
    background-color: var(--background-muted);
  }

  .segment {
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    clip: rect(0px, 100px, 45px, 0px);
    background-color: var(--background-muted);
  }

  .segment1 {
    transform: rotate(125deg);
  }

  .inner-circle {
    width: 76px;
    height: 76px;
    background-color: var(--background-primary);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const Title = styled.div`
  width: 136px;
  height: 9px;
  border-radius: 7px;
  background-color: var(--background-muted);
  @media (max-width: 449px) {
    width: 126px;
  }
  @media (max-width: 375px) {
    width: 90px;
  }
  @media (max-width: 320px) {
    width: 60px;
  }
`;
const Description = styled.div`
  width: 290px;
  height: 9px;
  border-radius: 7px;
  background-color: var(--background-muted);
  @media (max-width: 449px) {
    width: 161px;
  }
  @media (max-width: 375px) {
    width: 121px;
  }
  @media (max-width: 320px) {
    width: 86px;
  }
`;
const TeamMember = styled.div`
  display: flex;
  flex-direction: column;
  width: 210px;
  padding: 16px 11px;
  gap: 10px;
  border-radius: 8px;
  border: 0.5px solid var(--border-primary);
  background-color: var(--background-primary);
  @media (max-width: 449px) {
    max-width: 164px;
    width: 100%;
  }
  @media (max-width: 375px) {
    max-width: 138px;
    width: 100%;
  }
`;
const Top = styled.div`
  display: flex;
  width: 186px;
  height: 16px;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 449px) {
    width: 100%;
    gap: 10px;
  }
`;

const TopTitle = styled.div`
  width: 135px;
  height: 9px;
  border-radius: 6px;
  background-color: var(--background-muted);
  @media (max-width: 449px) {
    max-width: 122px;
    width: 100%;
  }
`;
const Bottom = styled.div`
  width: 50px;
  height: 9px;
  border-radius: 6px;
  background-color: var(--background-muted);
`;
const CheckBox = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background-color: var(--background-muted);
`;
export {
  Group,
  Header,
  Personaltext,
  Total,
  Storage,
  Content,
  Left,
  Right,
  Title,
  Description,
  TeamMember,
  Top,
  TopTitle,
  CheckBox,
  Bottom
};
