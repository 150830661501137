import { useMemo } from 'react';
import { StatusListInterface } from '../../../interfaces/ProjectInterface';
import DropdownItem from '../../Dropdown/DropdownItem';
import Dropdown from '../../Dropdown/MainDropdown';
import DropdownButton from '../../Dropdown/DropdownButton';
import { renderStatusIconName } from '../../../helpers/common';
import { ButtonIcon } from './style';

interface Props {
  isCreateTask?: boolean;
  statusList: StatusListInterface[];
  currentStatusId?: string;
  defaultStatus: StatusListInterface | undefined;
  isTaskCheckBox?: boolean;
  isStatusIcon?: boolean;
  onUpdateStatus: (status: StatusListInterface, previousStatus: StatusListInterface) => void;
  isResponsiveStatusIcon?: boolean;
  isSubtaskPopup?: boolean;
  isZindex?: boolean;
  isHoverIcon?: boolean;
}
export default function TaskStatusDropdown(props: Props) {
  const { statusList, isCreateTask, currentStatusId, defaultStatus, onUpdateStatus, isTaskCheckBox, isHoverIcon } =
    props;

  const currentStatus = statusList?.find((x) => x['_id'] === currentStatusId) || defaultStatus || statusList?.[0];

  const renderStatusList = useMemo(() => {
    return statusList.map((status) => (
      <DropdownItem
        label={status.Name}
        iconName={renderStatusIconName(status.Type)}
        iconViewBox='0 0 17 16'
        key={status['_id']}
        onClick={() => onUpdateStatus(status, currentStatus)}
        // eslint-disable-next-line no-underscore-dangle
        isSelected={currentStatus?._id === status['_id']} // Corrected property name
      />
    ));
  }, [currentStatus, onUpdateStatus, statusList]); // Added `currentStatus` as a dependency

  const renderStatusVariant = useMemo(() => {
    if (isCreateTask) return 'border';
    if (isTaskCheckBox) return 'no-border';
    return 'hover-border';
  }, [isCreateTask, isTaskCheckBox]);

  return (
    <>
      <Dropdown
        content={
          <ButtonIcon isHoverIcon={isHoverIcon}>
            <DropdownButton
              title={!isTaskCheckBox ? currentStatus?.Name : ''}
              iconName={renderStatusIconName(currentStatus?.Type)}
              variant={renderStatusVariant}
              isActive={!isTaskCheckBox && !!currentStatus?.Name}
              size={isCreateTask ? 'small' : 'medium'}
            />
          </ButtonIcon>
        }
        trigger='click'
        modalTitle='Status'>
        {renderStatusList}
      </Dropdown>
    </>
  );
}
