import React from 'react';
import { Autotext, Box, Leftrow, Row, Subtitle, Title } from '../style';
import Button from '../../button';

interface Props {
  isToggle?: boolean;
  onUpdateSetting: (value: boolean) => void;
  loadingButton: boolean;
  title: string;
  subtitle: string;
}

const SettingCard: React.FC<Props> = (props) => {
  const { isToggle, onUpdateSetting, loadingButton, title, subtitle } = props;

  return (
    <Box>
      <Row>
        <Leftrow>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </Leftrow>
        <Autotext>
          {loadingButton ? (
            <Button isLoading={loadingButton} />
          ) : (
            <label className='switch'>
              <input type='checkbox' checked={isToggle || false} onChange={() => onUpdateSetting(!isToggle)} />
              <span className='slider round'></span>
            </label>
          )}
        </Autotext>
      </Row>
    </Box>
  );
};

export default SettingCard;
