import React, { useCallback, useEffect } from 'react';
import { Bottom, Descriptionbox, Field, Form, Formbutton, Inputvalue, Label, Left, Main, Right, Top } from './styles';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import Button from '../../button';
import { useDispatch, useSelector } from 'react-redux';
import { updateProjectTemplateInput, clearProjectTemplateInput } from '../../../actions/settingActions';
import {
  createProjectTemplate,
  getProjectTemplateList,
  saveProjectTemplateDetails
} from '../../../services/settingServices';
import { setErrorMessage } from '../../../actions/messageActions';
import { isEmpty } from '../../../helpers/common';
import { trackAnalyticActivity } from '../../../services/analyticsService';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import { PROJECT_ANALYTICS } from '../../../global/analyticsConstants';

interface Props {
  onCancel: () => void;
}
const Createtemplatemodal: React.FC<Props> = (props) => {
  const { onCancel } = props;
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { settings: settingsSelector, workspace: workspaceSelector } = stateSelector || {};
  const { projectTemplateInput, loading } = settingsSelector;
  const { workspace } = workspaceSelector;
  const { id: workspace_id } = workspace;
  //Other variable
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearProjectTemplateInput());
    };
  }, [dispatch]);

  const onCloseModal = useCallback(() => {
    dispatch(clearProjectTemplateInput());
    onCancel();
  }, [onCancel, dispatch]);

  const handleChangeDetails = useCallback(
    (propsName: string, value: string) => {
      dispatch(updateProjectTemplateInput({ propsName, value }));
    },
    [dispatch]
  );

  const onSubmit = useCallback(
    async (e: React.SyntheticEvent) => {
      try {
        e.preventDefault();
        if (isEmpty(projectTemplateInput?.name)) {
          dispatch(setErrorMessage('Please enter template name!'));
          return;
        }
        let response;
        if (projectTemplateInput?.['_id']) {
          response = await dispatch(saveProjectTemplateDetails(projectTemplateInput));
          trackAnalyticActivity(PROJECT_ANALYTICS.TEMPLATE_UPDATED);
        } else {
          response = await dispatch(
            createProjectTemplate(workspace_id, projectTemplateInput?.name, projectTemplateInput?.description)
          );
          trackAnalyticActivity(PROJECT_ANALYTICS.TEMPLATE_CREATED);
        }
        if (response) {
          onCloseModal();
          dispatch(getProjectTemplateList(workspace_id));
        }
      } catch (error) {
        console.log('error', error);
      }
    },
    [projectTemplateInput, dispatch, workspace_id, onCloseModal]
  );

  return (
    <div>
      <Main onSubmit={onSubmit}>
        <Top>
          <Left>{projectTemplateInput?.['_id'] ? 'Edit' : 'Create'} Project template</Left>
          <Right onClick={onCloseModal}>
            <SVGIcon name='close-icon' width='18' height='18' viewBox='0 0 18 18' className='close-icon' />
          </Right>
        </Top>
        <Bottom>
          <Field>
            <Form>
              <Label>Template Name</Label>
              <Inputvalue
                type='text'
                placeholder='Enter template name '
                value={projectTemplateInput?.name}
                onChange={(e) => handleChangeDetails('name', e.target.value)}
              />
            </Form>
            <Form>
              <Label>Description</Label>
              <Descriptionbox
                placeholder='Enter description'
                value={projectTemplateInput?.description}
                onChange={(e) => handleChangeDetails('description', e.target.value)}
              />
            </Form>
          </Field>
          <Formbutton>
            <Button title='Cancel' secondary={true} type='button' onClick={onCloseModal} modelbtn />
            <Button
              title={projectTemplateInput?.['_id'] ? 'Update' : 'Create'}
              type='submit'
              onClick={onSubmit}
              isLoading={loading}
              disabled={loading}
              modelbtn
            />
          </Formbutton>
        </Bottom>
      </Main>
    </div>
  );
};
export default Createtemplatemodal;
