import { combineReducers } from '@reduxjs/toolkit';
import * as Actions from '../actions/types';
import { createReducer } from '../helpers/reduxHelpers';

const setDocLoaderReducer = createReducer({
  initialState: false,
  actionType: Actions.SET_DOC_LOADER
});

const docVersionListReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_DOC_VERSION_LIST
});

const docReducer = combineReducers({
  loading: setDocLoaderReducer,
  docVersionList: docVersionListReducer
});

export default docReducer;
