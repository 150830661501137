import React, { useState } from 'react';
import { useTable } from 'react-table';
import { Productname, Tabledata, Smallheader, Table, Link, Icon, Row, TableWrapper, Empty, Date } from './styles';
import SVGIcon from '../../assets/images/svg/SVGIcon';
import Tablesubrow from '../tablesubrow/tablesubrow';
import { Tooltip, ConfigProvider } from 'antd';
import EmptyState from '../emptyState';
import reportdarkempty from '../../assets/images/emptystates/reportdarkempty.svg';
import reportempty from '../../assets/images/emptystates/report-empty.svg';
import { isEmpty } from '../../helpers/common';
import { useSelector } from 'react-redux';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';
import { ActivityDataInterface, DataItem } from '../../interfaces/TimeReportInterface';

interface maincolumns {
  Header: string;
  accessor: string;
}

interface TableComponentProps {
  columns: maincolumns[];
  data: DataItem[];
  subData?: DataItem[];
  subColumns?: maincolumns[];
  hideLink?: boolean;
  linkname?: string;
  hidecontracticon?: boolean;
}
interface Props {
  width?: boolean;
  isFontWeight?: boolean;
  showIcon?: boolean;
  highlightLastRow?: boolean;
  isborder?: boolean;
  isborderbottom?: boolean;
  isborderTop?: boolean;
  databorder?: boolean;
  handleOpen?: (item: ActivityDataInterface | DataItem) => void;
  isScrollTable?: boolean;
}
type CombinedProps = TableComponentProps & Props;
const MainTableComponent: React.FC<CombinedProps> = ({
  columns,
  data,
  width,
  showIcon,
  highlightLastRow,
  isborder,
  isborderbottom,
  databorder,
  handleOpen,
  isScrollTable
}) => {
  //States
  const [isTableOpen, setIsTableOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(-1);
  //use selector state variables
  const settingsSelector = useSelector((state: RootReducerInterface) => state.settings);
  const { themeMode } = settingsSelector;
  //Other variable
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

  const getImageSource = () => (themeMode?.theme === 'dark' ? reportdarkempty : reportempty);
  const config = {
    token: {
      colorBgSpotlight: 'var(--background-tertiary)',
      colorTextLightSolid: 'var(--text-primary)',
      borderRadius: 2,
      fontSize: 12,
      controlHeight: 27,
      lineHeight: 16
    }
  };

  const handleLinkButtonClick = (rowIndex: number) => {
    const updatedIndex = isTableOpen ? -1 : rowIndex;
    setIsTableOpen(!isTableOpen);
    setSelectedRow(updatedIndex);
  };

  return (
    /* eslint-disable react/jsx-props-no-spreading */

    <TableWrapper isScrollTable={isScrollTable}>
      <Table {...getTableProps()} isScrollTable={isScrollTable}>
        <thead>
          {rows?.length > 0 &&
            headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup?.id}>
                {headerGroup.headers.map((column, columnIndex) => (
                  <Smallheader
                    {...column.getHeaderProps()}
                    key={column.id}
                    isborderbottom={isborderbottom}
                    isScrollTable={isScrollTable}
                    className={columnIndex === headerGroup.headers.length - 2 ? 'no-border-right' : ''}>
                    {column.render('Header')}
                  </Smallheader>
                ))}
              </tr>
            ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows?.length > 0 &&
            rows.map((row, index) => {
              prepareRow(row);
              return (
                <React.Fragment key={row?.id}>
                  <tr {...row.getRowProps()} key={row?.id}>
                    {row.cells.map((cell, cellIndex) => (
                      <Tabledata
                        {...cell.getCellProps()}
                        key={cell.column.id}
                        isWidth={width ? true : false}
                        isborder={isborder}
                        isScrollTable={isScrollTable}
                        isborderTop={databorder && index === 0}
                        isFontWeight={highlightLastRow && index === rows.length - 1}>
                        {cell.column.id === 'screenshots' ? (
                          <div className='image-data'>
                            <a>
                              {!isEmpty(cell.row.original.screenshotImage) && (
                                <img
                                  src={cell.row.original.screenshotImage}
                                  alt='Screenshot'
                                  width={36}
                                  height={36}
                                  className='screen-shot'
                                  onClick={() => handleOpen && handleOpen(cell.row.original)}
                                />
                              )}
                            </a>
                            <span>{cell.value}</span>
                          </div>
                        ) : cell.column.id === 'activityName' ? (
                          <ConfigProvider theme={config}>
                            <Tooltip
                              title={cell.value}
                              getPopupContainer={(trigger: HTMLElement) => trigger.parentNode as HTMLElement}>
                              <div className='truncate-text'>
                                <p>{cell.value}</p>
                              </div>
                            </Tooltip>
                          </ConfigProvider>
                        ) : cell.column.id === 'date' ? (
                          cell.row.index === 0 || data[cell.row.index].date !== data[cell.row.index - 1].date ? (
                            <Date>
                              <span className='month'>{cell.value.split(' ')[0]}</span>
                              <span className='day'>{cell.value.split(' ')[1]}</span>
                            </Date>
                          ) : null
                        ) : cellIndex === 0 ? (
                          <>
                            <Row>
                              {showIcon && (
                                <Link>
                                  {selectedRow !== index ? (
                                    <Icon onClick={() => handleLinkButtonClick(index)}>
                                      <SVGIcon
                                        name='table-plus-icon'
                                        width='16'
                                        height='16'
                                        viewBox='0 0 16 16'
                                        fill='none'
                                        className='plus-minus-icon'
                                      />
                                    </Icon>
                                  ) : (
                                    <Icon onClick={() => handleLinkButtonClick(index)}>
                                      <SVGIcon
                                        name='table-minus-icon'
                                        width='16'
                                        height='16'
                                        viewBox='0 0 16 16'
                                        fill='none'
                                        className='plus-minus-icon'
                                      />
                                    </Icon>
                                  )}
                                </Link>
                              )}
                              <Productname isFontWeight={highlightLastRow && index === rows.length - 1}>
                                {cell.row.original[columns[0].accessor]}
                              </Productname>
                            </Row>
                          </>
                        ) : (
                          cell.render('Cell')
                        )}
                      </Tabledata>
                    ))}
                  </tr>

                  {isTableOpen && selectedRow === index && (
                    <>
                      <Tablesubrow
                        columns={[
                          { Header: 'Person Name', accessor: 'name' },
                          { Header: 'Time', accessor: 'time' },
                          { Header: 'Amount', accessor: 'amount' }
                        ]}
                        data={data[index]?.items}
                      />
                    </>
                  )}
                </React.Fragment>
              );
            })}

          {rows?.length === 0 && (
            <tr>
              <td>
                <Empty>
                  <EmptyState
                    hideButton={true}
                    image={getImageSource()}
                    header={`You don't have reports.`}
                    title='There are no report data for this date. Please try another Filters.'
                    ismargin={1}
                    isgap={10}
                    isReportTimeEmpty={true}
                  />
                </Empty>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </TableWrapper>
  );
};

export default MainTableComponent;
