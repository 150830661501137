import styled from 'styled-components';
import { Typography } from '../../global/theme';

const Printscreen = styled.div`
  width: fit-content;
  border-radius: 8px;
  border: 0.5px solid var(--border-primary);
  background-color: var(--background-tertiary);
  margin-top: 12px;
  overflow: hidden;
  .prnt-screen-header {
    display: flex;
    gap: 4px;
    margin: 0;
    align-items: center;
    padding: 8px;
    border-bottom: 0.5px solid var(--border-primary);
  }
  .ant-image .ant-image-img {
    border-right: 0;
    border-left: 0;
    border-top-right-radius: 0px !important;
  }

  .ant-image .ant-image-mask {
    border-radius: 0 0 6px 6px !important;
  }
  .prnt-url-link {
    ${Typography.body_sm_medium}
    text-decoration: underline;
    padding: 0;
    color: var(--text-link);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    word-break: break-all;
    margin: 0;
    :hover {
      background-color: transparent;
    }
  }
  .prnt-screen-box {
    display: flex;
    flex-direction: column;
    margin: 0;
  }
  .prnt-screen-top {
    margin-top: 0;
  }
  .figma-image {
    max-width: 100%;
    width: auto;
    max-height: 300px;
    height: auto;
    display: flex;
    margin: 0 auto;
    border: 0.5px solid var(--border-primary) !important;
  }
  .ant-image .ant-image-mask {
    background-color: var(--background-thumb-overlay);
    border-radius: 6px;
  }
  .ant-image {
    margin: 0;
  }
  .figma-image-container {
    margin: 0 auto;
  }
`;

const FigmaButton = styled.a`
  padding: 5px 16px;
  background-color: var(--background-primary);
  color: var(--text-primary) !important;
  border: 0.5px solid var(--border-primary);
  cursor: pointer;
  text-decoration: none !important;
  border-radius: 36px;
  ${Typography.body_sm_medium}
  margin-top: unset !important;
  &:hover {
    background-color: var(--background-secondary);
    text-decoration: none !important;
    border-radius: 36px;
  }
  @media (max-width: 449px) {
    flex-direction: column;
    margin-left: 30px;
  }
`;

const CopyIcon = styled.div`
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  &:hover {
    background-color: var(--neutral-secondary);
  }
  .svgicon {
    path {
      stroke: var(--text-primary);
    }
  }
`;

const DropdownDiv = styled.div`
  @media (max-width: 449px) {
    position: absolute;
    top: 8px;
    right: 8px;
  }
`;
export { Printscreen, FigmaButton, CopyIcon, DropdownDiv };
