import React from 'react'
import { Bottom, Card, Circle, First, Left, Leftrow1, Main, Right, Rightrow1, Row1, Row2, Row3, Top } from './styles'

export default function Taskgridloading() {
  return (
    <div>
      <Main>
        <First>
            <Top>
                <Left className='loading-animation'></Left>
                <Right className='loading-animation'></Right>
            </Top>
            <Bottom>
                <Card>
                    <Row1>
                        <Leftrow1 className='loading-animation'></Leftrow1>
                        <Rightrow1 className='loading-animation'></Rightrow1>
                    </Row1>
                    <Row2>
                         <Circle></Circle>
                          <Circle></Circle> 
                          <Circle></Circle> 
                          <Circle></Circle>
                    </Row2>
                    <Row3>
                         <Top>
                            <Left className='loading-animation'></Left>
                            <Right className='loading-animation'></Right>
                        </Top>
                    </Row3>
                </Card>
                 <Card>
                    <Row1>
                        <Leftrow1 className='loading-animation'></Leftrow1>
                        <Rightrow1 className='loading-animation'></Rightrow1>
                    </Row1>
                    <Row2>
                         <Circle></Circle>
                          <Circle></Circle> 
                          <Circle></Circle> 
                          <Circle></Circle>
                    </Row2>
                    <Row3>
                         <Top>
                            <Left className='loading-animation'></Left>
                            <Right className='loading-animation'></Right>
                        </Top>
                    </Row3>
                </Card>
                 <Card>
                    <Row1>
                        <Leftrow1 className='loading-animation'></Leftrow1>
                        <Rightrow1 className='loading-animation'></Rightrow1>
                    </Row1>
                    <Row2>
                         <Circle></Circle>
                          <Circle></Circle> 
                          <Circle></Circle> 
                          <Circle></Circle>
                    </Row2>
                    <Row3>
                         <Top>
                            <Left className='loading-animation'></Left>
                            <Right className='loading-animation'></Right>
                        </Top>
                    </Row3>
                </Card>
            </Bottom>
        </First>
         <First>
            <Top>
                <Left className='loading-animation second'></Left>
                <Right className='loading-animation'></Right>
            </Top>
            <Bottom>
                <Card>
                    <Row1>
                        <Leftrow1 className='loading-animation' ></Leftrow1>
                        <Rightrow1 className='loading-animation'></Rightrow1>
                    </Row1>
                    <Row2>
                         <Circle></Circle>
                          <Circle></Circle> 
                          <Circle></Circle> 
                          <Circle></Circle>
                    </Row2>
                    <Row3>
                         <Top>
                            <Left className='loading-animation'></Left>
                            <Right className='loading-animation'></Right>
                        </Top>
                    </Row3>
                </Card>
                 <Card>
                    <Row1>
                        <Leftrow1 className='loading-animation'></Leftrow1>
                        <Rightrow1 className='loading-animation'></Rightrow1>
                    </Row1>
                    <Row2>
                         <Circle></Circle>
                          <Circle></Circle> 
                          <Circle></Circle> 
                          <Circle></Circle>
                    </Row2>
                    <Row3>
                         <Top>
                            <Left className='loading-animation'></Left>
                            <Right className='loading-animation'></Right>
                        </Top>
                    </Row3>
                </Card>
            </Bottom>
        </First>
         <First>
            <Top>
                <Left className='loading-animation third'></Left>
                <Right className='loading-animation'></Right>
            </Top>
            <Bottom>
                <Card>
                    <Row1>
                        <Leftrow1 className='loading-animation' ></Leftrow1>
                        <Rightrow1 className='loading-animation'></Rightrow1>
                    </Row1>
                    <Row2>
                         <Circle></Circle>
                          <Circle></Circle> 
                          <Circle></Circle> 
                          <Circle></Circle>
                    </Row2>
                    <Row3>
                         <Top>
                            <Left className='loading-animation'></Left>
                            <Right className='loading-animation'></Right>
                        </Top>
                    </Row3>
                </Card>              
            </Bottom>
        </First>
      </Main>
    </div>
  )
}
