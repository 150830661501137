import styled from 'styled-components';
import { Typography } from '../../../global/theme';

const Group = styled.div`
  width: 100%;
  min-height: 560px;
  height: 100vh;
  background-color: var(--background-secondary);
  margin: auto;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 449px) {
    min-height: 751px;
  }
`;

const Content = styled.form`
  width: 100%;
  max-width: 558px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--background-secondary);
  gap: 20px;
  @media (max-width: 449px) {
    max-width: 100%;
    padding: 30px 16px;
    gap: 30px;
  }
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  .round-close-icon {
    stroke: var(--text-secondary);
  }
  @media (max-width: 449px) {
    .svgicon {
      width: 40px;
      height: 40px;
    }
  }
`;
const Icon1 = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const Title = styled.h1`
  ${Typography.heading_lg}
  color: var(--text-primary);
  margin: 0;
  @media (max-width: 449px) {
    text-align: center;
  }
`;

const Formblock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: var(--background-primary);
  border: 1px var(--border-primary);
  box-shadow: var(--shadow-card-hover);
  border-radius: 8px;
  padding: 20px;
  @media (max-width: 449px) {
    padding: 18px;
    gap: 30px;
  }
`;

const Profile = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  @media (max-width: 449px) {
    margin-top: 16px;
  }
`;

const ProfileView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  @media (max-width: 449px) {
    gap: 8px;
  }
`;

const Heading = styled.h2`
  ${Typography.body_lg_medium}
  color: var(--text-primary);
  margin: 0;
`;

const FormContent = styled.div`
  width: 100%;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 449px) {
    max-width: 100%;
    gap: 20px;
  }
`;

const Text = styled.p`
  ${Typography.body_md_medium}
  color:  var(--text-secondary);
  margin: 0;
  @media (max-width: 449px) {
    text-align: center;
  }
`;

const Subblock = styled.div`
  @media (max-width: 449px) {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 216px;
  overflow: auto;
  margin-bottom: 16px;
  padding-left: 2px;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 449px) {
    gap: 8px;
    height: 172px;
    margin-bottom: 0;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 12px;
  :last-child {
    margin-bottom: 0px;
  }
  @media (max-width: 449px) {
    margin-bottom: 0;
    gap: 8px;

    :last-child {
      margin-bottom: 0;
    }
  }
`;

const Label = styled.label`
  width: 100%;
  max-width: 193px;
  ${Typography.body_md_medium}
  color: var(--text-secondary);
  @media (max-width: 449px) {
    max-width: 100%;
    :last-child {
      width: 50%;
    }
    :first-child {
      width: 48%;
    }
  }
`;

const Inputvalue = styled.input`
  width: 167px;
  ${Typography.body_lg_medium}
  background-color: var(--background-primary);
  color: var(--text-primary);
  border: 0.5px solid var(--border-primary);
  padding: 8px 12px;
  border-radius: 6px;
  outline: none;

  :focus {
    border: 0.5px solid var(--brand-primary);
  }
  ::placeholder {
    color: var(--text-placeholder);
  }
  @media (max-width: 449px) {
    width: 100%;
    padding: 12px;
  }
`;

const ProfileContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Items = styled.div`
  display: flex;
  gap: 18px;
  margin-bottom: 8px;

  @media (max-width: 449px) {
    gap: 0;
    margin-bottom: 0;
    width: 100%;
  }
`;

const Addblock = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 12px;
  cursor: pointer;
`;

const Textform = styled.p`
  ${Typography.body_md}
  color: var(--text-secondary);
  margin: 0;
  span {
    ${Typography.body_md}
    color: var(--brand-primary);
    margin: 0;
  }
`;

const Endblock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  @media (max-width: 449px) {
    flex-direction: column;
    gap: 30px;
  }
`;

const Textformat = styled.p`
  ${Typography.body_md}
  color: var(--text-secondary);
  margin: 0;
`;

const RightSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  @media (max-width: 449px) {
    width: 100%;
    gap: 12px;
    flex-direction: column-reverse;
    button {
      padding: 10px 29px;
    }
  }
`;

const SkipOption = styled.div<{ disable?: boolean }>`
  ${Typography.body_md_medium}
  color: var(--text-secondary);
  margin: 0;
  cursor: pointer;
  pointer-events: ${(props) => (props.disable ? 'none' : 'auto')};
`;

export {
  Group,
  Content,
  Icon,
  Title,
  Formblock,
  Profile,
  ProfileView,
  Heading,
  FormContent,
  Text,
  Subblock,
  Form,
  Item,
  Label,
  Inputvalue,
  ProfileContent,
  Items,
  Addblock,
  Textform,
  Endblock,
  Textformat,
  Icon1,
  RightSide,
  SkipOption
};
