import styled from 'styled-components';
import { Typography } from '../../../../../global/theme';

const Div = styled.div`
  height: 100%;
`;
const Container = styled.div`
  background: var(--background-secondary);
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 0.5px solid var(--border-primary);
  width: 100%;
  position: sticky;
  top: 0;
  background: var(--background-secondary);
  z-index: 99;
  margin-bottom: 24px;
`;
const Leftheader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  padding-left: 24px;
  .fillColor {
    cursor: pointer;
    stroke: var(--text-primary);
    :hover {
      background: var(--neutral-secondary);
      border-radius: 4px;
    }
  }
`;
const Titles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  h1 {
    ${Typography.body_md_medium}
    color: var(--text-primary);
    margin: 0px;
  }
  p {
    ${Typography.body_xs}
    color: var(--text-secondary);
    margin: 0px;
  }
`;
const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  padding-right: 24px;
`;
const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  .pg-viewer-wrapper {
    height: 100%;
    background: var(--background-secondary);
    overflow: inherit;
    ::-webkit-scrollbar {
      display: none;
    }
    .unsupported-message {
      background: var(--background-primary);
      color: var(--text-primary);
    }
  }
  .photo-viewer-container {
    width: 100% !important;
    height: 100% !important;
  }
  .pg-viewer-wrapper img {
    border-radius: 8px;
  }
  .pdf-viewer {
    margin: 0 auto 24px;
  }
  .pdf-viewer-container {
    overflow-y: scroll;
    height: calc(100vh - 84px);
  }
  .pdf-canvas {
    box-shadow: var(--shadow-card);
    border: 1px solid var(--border-primary);
    margin: 0 auto;
    width: max-content;
    display: flex;
  }
  video {
    max-width: 980px;
    width: 100%;
    max-height: 571px;
    height: 100%;
  }
`;

const Icon = styled.div`
  cursor: pointer;
  width: 30px;
  height: 30px;
  .svg-icon {
    path {
      stroke: var(--text-secondary);
    }
    :hover {
      background-color: var(--neutral-secondary);
      border-radius: 4px;
    }
  }
`;

export { Container, Header, Leftheader, Titles, Buttons, Content, Icon, Div };
