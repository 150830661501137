import SVGIcon from '../../../assets/images/svg/SVGIcon';
import Button from '../../button';
import {
  Buttons,
  Dropdata,
  Dropdownbox,
  Form,
  Headline,
  Icon,
  Icon1,
  Iconview,
  Inputbox,
  Inputvalues,
  Label,
  Modalblock,
  Modalhead,
  SecmodalContent
} from './styles';
import { LABEL_COLOURS, LABEL_COLOUR_HASH } from '../../../global/constants';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateLabelInput } from '../../../actions/projectActions';
import { createNewLabel, getCurrentProjectDetails, updateLabel } from '../../../services/projectServices';
import { isEmpty } from 'lodash';
import { setErrorMessage } from '../../../actions/messageActions';
import UserPreferenceSingleton from '../../../helpers/userPreferenceSingleton';
import { captureException } from '../../../services/logService';
import { nanoid } from 'nanoid';
import { updateProjectTemplateDetails } from '../../../actions/settingActions';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import { Dropdown, DropdownItem } from '../../Dropdown';

interface Props {
  onClose: () => void;
  isTemplateProject?: boolean;
}
export default function Labelmodal({ onClose, isTemplateProject = false }: Props) {
  //States
  const [isLoading, setIsLoading] = useState(false);
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { settings: settingsSelector, project: projectSelector, workspace: workspaceSelector } = stateSelector || {};
  const { workspace } = workspaceSelector;
  const { id: workspace_id } = workspace;
  const { labelInput, currentProject } = projectSelector;
  const { projectTemplateDetails } = settingsSelector;
  //Other variable
  const dispatch = useDispatch();

  const addLabelInTemplateProject = useCallback(
    (labelObj: any) => {
      const labelsClone = JSON.parse(JSON.stringify(projectTemplateDetails.labelsList || []));
      dispatch(updateProjectTemplateDetails({ propsName: 'labelsList', value: [...(labelsClone || []), labelObj] }));
    },
    [dispatch, projectTemplateDetails]
  );

  const updateLabelInTemplateProject = useCallback(
    (labelObj: any) => {
      const updatedLabels = projectTemplateDetails?.labelsList?.map((x) => {
        if (x?.id === labelObj?.id) return labelObj;
        else return x;
      });
      dispatch(updateProjectTemplateDetails({ propsName: 'labelsList', value: updatedLabels }));
    },
    [dispatch, projectTemplateDetails]
  );

  const onSubmit = useCallback(
    async (e: React.SyntheticEvent) => {
      e.preventDefault();
      if (isEmpty(labelInput?.name)) {
        dispatch(setErrorMessage('Please enter label name.'));
        return;
      } else if (isEmpty(labelInput?.color)) {
        dispatch(setErrorMessage('Please select label color.'));
        return;
      }
      setIsLoading(true);
      try {
        let result;
        if (!isEmpty(labelInput?.id)) {
          if (isTemplateProject) {
            const user = UserPreferenceSingleton.getInstance().getCurrentUser();
            const labelObj = {
              id: labelInput?.id,
              name: labelInput?.name,
              color: labelInput?.color,
              createdBy: labelInput?.createdBy,
              createdOn: labelInput?.createdOn,
              updatedBy: user?.id,
              updatedOn: new Date().toISOString()
            };
            updateLabelInTemplateProject(labelObj);
            onClose();
          } else {
            const payload = {
              name: labelInput?.name,
              color: labelInput?.color
            };
            result = await dispatch(updateLabel(payload, currentProject?.id, labelInput?.id));
          }
        } else {
          if (isTemplateProject) {
            const user = UserPreferenceSingleton.getInstance().getCurrentUser();
            const labelObj = {
              id: String(nanoid()),
              name: labelInput?.name,
              color: labelInput?.color,
              createdBy: user?.id,
              createdOn: new Date().toISOString(),
              updatedBy: user?.id,
              updatedOn: new Date().toISOString()
            };
            addLabelInTemplateProject(labelObj);
            onClose();
          } else {
            const payload = {
              name: labelInput?.name,
              color: labelInput?.color
            };
            result = await dispatch(createNewLabel(payload, currentProject?.id));
          }
        }
        if (result) {
          onClose();
          await dispatch(getCurrentProjectDetails(workspace_id, currentProject?.id));
        }
      } catch (error) {
        captureException(error);
        console.log('error', error);
      } finally {
        setIsLoading(false);
      }
    },
    [
      addLabelInTemplateProject,
      currentProject?.id,
      dispatch,
      isTemplateProject,
      labelInput?.color,
      labelInput?.createdBy,
      labelInput?.createdOn,
      labelInput?.id,
      labelInput?.name,
      onClose,
      updateLabelInTemplateProject,
      workspace_id
    ]
  );

  const onChangeInput = useCallback(
    (propsName: string, value: string) => {
      dispatch(updateLabelInput({ propsName, value }));
    },
    [dispatch]
  );

  const renderLabelItems = useMemo(() => {
    return LABEL_COLOURS.map((x) => (
      <DropdownItem key={x?.label} onClick={() => onChangeInput('color', x?.label)} label={x?.label}>
        <Icon iconColor={x.color} />
      </DropdownItem>
    ));
  }, [onChangeInput]);

  return (
    <>
      <Modalhead>
        <Headline>{!isEmpty(labelInput?.id) ? 'Update Label' : 'Create New Label'}</Headline>
        <Icon1 onClick={onClose}>
          <SVGIcon name='close-icon' width='18' height='18' viewBox='0 0 18 18' fill='none' className='close-icon' />
        </Icon1>
      </Modalhead>
      <Modalblock onSubmit={onSubmit}>
        <SecmodalContent>
          <Form>
            <Label>Label Name</Label>
            <Iconview>
              <Inputvalues
                type='text'
                placeholder='Enter label name'
                value={labelInput?.name}
                onChange={(e) => onChangeInput('name', e.target.value)}
              />
            </Iconview>
          </Form>
          <Form>
            <Label>Label Color</Label>
            <Dropdown
              isMinWidth={428}
              content={
                <Dropdownbox>
                  {labelInput?.color && <Icon iconColor={LABEL_COLOUR_HASH[labelInput?.color]} />}
                  <Inputbox placeholder='Select color' value={labelInput?.color} />
                  <div className='svg'>
                    <SVGIcon
                      name='angledown-icon'
                      width='18'
                      height='18'
                      viewBox='0 0 18 18'
                      className='angledown-icon'
                    />
                  </div>
                </Dropdownbox>
              }
              trigger='click'>
              <Dropdata>{renderLabelItems}</Dropdata>
            </Dropdown>
          </Form>
          <Buttons>
            <Button title='Cancel' secondary={true} type='button' onClick={onClose} modelbtn={true} hasNotBoxshadow />
            <Button
              type='submit'
              title={labelInput?.id ? 'update' : 'Create Label'}
              isLoading={isLoading}
              modelbtn={true}
            />
          </Buttons>
        </SecmodalContent>
      </Modalblock>
    </>
  );
}
