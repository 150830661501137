import styled, { css } from 'styled-components';
import { Typography } from '../../global/theme';

interface Props {
  isCompletedTasksOpen?: boolean;
  isActive?: boolean;
  isWeekend?: boolean;
}

const Main = styled.div<Props>`
  display: flex;
  flex-direction: column;
  min-width: 220px;
  width: 100%;
  cursor: pointer;
  :hover {
    background-color: var(--neutral-secondary);
    .date-text {
      color: var(--brand-primary);
    }
    .hide-show-task-text {
      display: flex;
    }
  }

  ${(props: Props) =>
    props.isCompletedTasksOpen &&
    css`
      .hide-show-task-text {
        display: flex;
      }
    `}

  ${(props: Props) =>
    props.isActive &&
    css`
      background-color: var(--neutral-secondary);
      .date-text {
        color: var(--brand-primary);
      }
      .hide-show-task-text {
        display: flex;
      }
    `}
  ${(props: Props) =>
    props.isWeekend &&
    css`
      min-width: 70px;
      max-width: 70px;
    `}
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  /* align-items: center; */
  padding: 0 8px 0 8px;
  height: calc(100vh - 154px);
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  :last-child {
    padding-bottom: 8px;
  }
`;
const Showhide = styled.a`
  display: none;
  width: max-content;
  ${Typography.body_md}
  color: var(--text-secondary);
  cursor: pointer;
  padding: 4px 0;
  :hover {
    color: var(--text-primary);
  }
`;
export { Main, Bottom, Showhide };
