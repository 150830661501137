import React from 'react';
import {
  Border,
  Bottom,
  Bottomthird,
  Center,
  Circle,
  Descriptoin,
  First,
  Footer,
  Left,
  Leftdiv,
  Main,
  Middle,
  Middlethird,
  Right,
  Rightdiv,
  Third,
  Top,
  Topborder,
  Topthird
} from './styles';
import SVGIcon from '../../../assets/images/svg/SVGIcon';

interface Props {
  isMessagesloading?: boolean;
  iswidth?: boolean;
  isProjectMessageDetail?: boolean;
}
export default function MessageLoading(Props: Props) {
  return (
    <div>
      <Main isMessagesloading={Props.isMessagesloading} isProjectMessageDetail={Props.isProjectMessageDetail}>
        <Border isMessagesloading={Props.isMessagesloading}>
          <Topborder />
          <Center className='loading-animation' />
          <Topborder />
        </Border>
        <Descriptoin isMessagesloading={Props.isMessagesloading}>
          <First>
            <Circle isMessagesloading={Props.isMessagesloading}>
              <Left isMessagesloading={Props.isMessagesloading} className='loading-animation'></Left>
            </Circle>
            <Right isMessagesloading={Props.isMessagesloading}>
              <Top className='loading-animation'></Top>
              <Bottom isMessagesloading={Props.isMessagesloading} className='loading-animation'></Bottom>
            </Right>
          </First>
          <First>
            <Circle isMessagesloading={Props.isMessagesloading}>
              <Left isMessagesloading={Props.isMessagesloading} className='loading-animation'></Left>
            </Circle>
            <Right iswidth={Props.iswidth}>
              <Top className='loading-animation'></Top>
              <Middle isMessagesloading={Props.isMessagesloading} className='loading-animation'></Middle>
              <Bottom iswidth={Props.iswidth} className='loading-animation'></Bottom>
            </Right>
          </First>
          <Third>
            <Circle isMessagesloading={Props.isMessagesloading}>
              <Left isMessagesloading={Props.isMessagesloading} className='loading-animation'></Left>
            </Circle>
            <Right iswidth={true}>
              <Topthird className='loading-animation'></Topthird>
              <Middlethird isMessagesloading={Props.isMessagesloading} className='loading-animation'></Middlethird>
              <Bottomthird isMessagesloading={Props.isMessagesloading} className='loading-animation'></Bottomthird>
            </Right>
          </Third>
        </Descriptoin>
        <Border isMessagesloading={Props.isMessagesloading}>
          <Topborder />
          <Center className='loading-animation' />
          <Topborder />
        </Border>
        <Descriptoin isMessagesloading={Props.isMessagesloading}>
          <First>
            <Circle isMessagesloading={Props.isMessagesloading}>
              <Left isMessagesloading={Props.isMessagesloading} className='loading-animation'></Left>
            </Circle>
            <Right isMessagesloading={Props.isMessagesloading}>
              <Top className='loading-animation'></Top>
              <Bottom isMessagesloading={Props.isMessagesloading} className='loading-animation'></Bottom>
            </Right>
          </First>
          <First>
            <Circle isMessagesloading={Props.isMessagesloading}>
              <Left isMessagesloading={Props.isMessagesloading} className='loading-animation'></Left>
            </Circle>
            <Right iswidth={Props.iswidth}>
              <Top className='loading-animation'></Top>
              <Bottom isMessagesloading={Props.isMessagesloading} className='loading-animation'></Bottom>
              {Props.isMessagesloading && <Bottom iswidth={Props.iswidth} className='loading-animation'></Bottom>}
            </Right>
          </First>
        </Descriptoin>
        <Footer isProjectMessageDetail={Props.isProjectMessageDetail}>
          <Leftdiv className='loading-animation'></Leftdiv>
          <Rightdiv>
            <SVGIcon name='message-emoji-icon' width='32' height='32' viewBox='0 0 32 32' className='svg' />
            <SVGIcon name='message-attachment-icon' width='32' height='32' viewBox='0 0 32 32' className='svg' />
            <SVGIcon name='send-discuss-icon' width='32' height='32' viewBox='0 0 32 32' className='svg' />
          </Rightdiv>
        </Footer>
      </Main>
    </div>
  );
}
