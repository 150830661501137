import styled, { css } from 'styled-components';
import { Typography } from '../../../global/theme';

interface Props {
  isTaskdetail?: boolean;
  isCalendarview?: boolean;
  isPadding?: boolean;
  isSelectedtask?: boolean;
  isCustomcontextmenu?: boolean;
  isCreateTaskModal?: boolean;
  isAssignModal?: boolean;
  isMilestone?: boolean;
  isSubtask?: boolean;
}

const Dropcontainer = styled.div<Props>`
  position: absolute;
  .menuStyle {
    width: 180px;
  }
  z-index: 2;
  ${(props) =>
    props.isSubtask &&
    css`
      @media (max-width: 449px) {
        top: 5px !important;
        transform: translate(-100%, 10px);
      }
    `}

  ${(props: Props) =>
    props.isTaskdetail &&
    css`
      z-index: 111;
      margin-left: -10px;
      top: unset !important;
      padding-top: 6px;
    `}
  ${(props: Props) =>
    props.isCreateTaskModal &&
    css`
      top: unset !important;
      padding-top: 6px;
      margin-bottom: 35px !important;
    `}
  ${(props: Props) =>
    props.isSelectedtask &&
    css`
      .menuStyle {
        bottom: 40px;
      }
    `}
      ${(props: Props) =>
    props.isCustomcontextmenu &&
    css`
      .menuStyle {
        position: unset;
      }
      padding-top: 0;
      position: relative !important;
    `}
    ${(props: Props) =>
    props.isTaskdetail &&
    css`
      margin-bottom: 35px;
    `}
        ${(props: Props) =>
    props.isAssignModal &&
    css`
      @media (max-width: 449px) {
        position: fixed !important;
        top: calc(50% - 100px) !important;
        width: calc(100% - 32px);
        margin: 0 16px;
        left: 0;
        z-index: 11111;
        .menuStyle {
          width: 100%;
        }
      }
    `}
        ${(props: Props) =>
    props.isMilestone &&
    css`
      @media (max-width: 449px) {
        position: fixed !important;
        top: 50% !important;
        width: calc(100% - 32px);
        margin: 0 16px;
        left: 0;
        z-index: 11111;
        transform: translate(0, -50%);
        .menuStyle {
          width: 100%;
        }
      }
    `}
`;

const Dropheader = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  padding: 6px 14px 6px 10px;
`;

const Inputarea = styled.input`
  max-width: 100%;
  width: 116px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  ${Typography.body_md}
  color: var(--text-primary);
  background-color: var(--background-primary);
  margin: 0;
  padding: 0;
  border: none;

  :focus {
    outline: none;
  }
  .placeholder {
    color: var(--text-secondary);
  }
  @media (max-width: 449px) {
    max-width: 297px;
    width: 100%;
  }
`;

const Textblock = styled.div`
  display: flex;
  background: var(--border-primary);
  border-radius: 4px;
  padding: 2px 4px;
  height: 12px;

  span {
    ${Typography.body_xs}
    color: var(--text-secondary);
    text-align: center;
  }
`;
const Dropselect = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  color: var(--text-primary);
  ${Typography.body_md}
  cursor: pointer;
  :hover {
    background: var(--neutral-secondary);
    border: none;
  }
`;

const Dropcontent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  cursor: pointer;
  padding: 8px 12px;
  :hover {
    background: var(--neutral-secondary);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    .checkbox-round {
      border: 1px solid var(--text-secondary);
    }
  }
`;

const Drop = styled.div`
  &:not(:last-child) {
    :hover {
      background: var(--neutral-secondary);
    }
  }
  .menuitem.disabled {
    pointer-events: none;
  }
`;

const Dropblock = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const Droptextform = styled.p`
  ${Typography.body_md}
  color: var(--text-primary);
  margin: 0;
`;

const Icon = styled.div`
  display: flex;
  align-items: center;

  svg {
    vertical-align: middle;
  }
  @media (max-width: 449px) {
    .avtar {
      border: none;
    }
  }
`;

const Signmode = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  svg {
    vertical-align: middle;
  }

  .fill-color {
    stroke: var(--background-primary);
    fill: var(--border-secondary);
  }
  align-items: center;
  p {
    ${Typography.body_md_medium}
    margin: 0;
    color: var(--text-primary);
    white-space: nowrap;
  }

  @media (max-width: 449px) {
    p {
      display: inline-flex;
    }
    .fill-color {
      display: none;
    }
  }
`;

const Rightrow1 = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 3px 0;
  align-items: center;
`;

const RightText = styled.p`
  ${Typography.body_md}
  color: var(--text-primary);
  margin: 0;
  white-space: nowrap;
`;

const AvatarImage = styled.div`
  display: flex;
  align-items: center;
  border-radius: 50%;

  > div > img {
    max-width: 100%;
  }
`;
const Assigngroup = styled.div`
  ul {
    display: flex;
    align-items: center;
    list-style-type: none;
    padding-left: 9px;
    margin: 0;
  }

  li {
    margin-left: -8px;
    border-radius: 50%;
    .plus-icon {
      visibility: hidden;
    }
  }
  .fill-color {
    stroke: var(--background-primary);
    path {
      stroke: var(--background-primary);
    }
  }
  .fill-color {
    fill: var(--text-tertiary);
  }
  .fill-color {
    svg {
    }
  }
  .plus-icon {
    fill: var(--border-secondary);
    stroke: var(--background-primary);
  }
`;
const HumanIcon = styled.div<Props>`
  padding: 5px 7px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 6px;
  border: 0.5px solid var(--border-primary);
  p {
    ${Typography.body_md_medium}
    color: var(--text-secondary) !important;
    margin: 0;
  }

  svg {
    fill: var(--text-secondary);
  }
  :hover {
    background: var(--neutral-secondary);
    border-radius: 5px;
    p {
      color: var(--text-primary) !important;
    }
    svg {
      fill: var(--text-primary);
    }
  }
  @media (max-width: 449px) {
    p {
      display: inline-flex;
    }
    padding: 5px;
  }
  ${(props: Props) =>
    props.isPadding &&
    css`
      cursor: pointer;
      padding: 5px 7px;
      :hover {
        p {
          color: var(--text-secondary) !important;
        }
      }
    `}
  ${(props: Props) =>
    props.isCreateTaskModal &&
    css`
      padding: 2px 7px;
      border-radius: 6px;
      @media (max-width: 449px) {
        padding: 2px 7px !important;
      }
    `}
`;

const Assignbottom = styled.div`
  height: 100%;
  max-height: 192px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 1px;
  svg {
    margin: 0;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  .selected {
    background: var(--neutral-secondary);
    .checkbox-round {
      border: 1px solid var(--text-secondary);
    }
  }
  :last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  @media (max-width: 449px) {
    max-height: 204px;
  }
`;
const Assign = styled.div`
  padding: 6px 8px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 6px;
  border: 0.5px solid var(--border-primary);
  border-radius: 5px;
  flex-wrap: wrap;

  :hover {
    background: var(--neutral-secondary);
    border-radius: 5px;
  }
  @media (max-width: 449px) {
    padding: 5px;
    flex-wrap: wrap;
    max-width: max-content;
  }
  ${(props: Props) =>
    props.isCreateTaskModal &&
    css`
      padding: 2px 7px;
      border-radius: 6px;
      @media (max-width: 449px) {
        padding: 2px 7px !important;
      }
    `}
`;

const Div = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;
const Top = styled.div<Props>`
  ${(props: Props) =>
    props.isCreateTaskModal &&
    css`
      position: relative;
    `}
  ${(props: Props) =>
    props.isCalendarview &&
    css`
      position: relative;
      margin-left: -20px;
    `}
  ${(props: Props) =>
    props.isCustomcontextmenu &&
    css`
      display: contents;
    `}
    
    ${(props: Props) =>
    props.isTaskdetail &&
    css`
      position: relative;
    `}
`;
const Dropboxdiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const Checkbox = styled.div`
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  .checkbox-round {
    width: 7px;
    height: 7px;
    border-radius: 2px;
    vertical-align: middle;
    border: 1px solid transparent;
    background-color: var(--background-primary);
    appearance: none;
    outline: none;
    cursor: pointer;
    margin: 0;
    padding: 6px;
  }
  input[type='checkbox'] {
    position: relative;
    cursor: pointer;
    opacity: 1;
  }
  input[type='checkbox']:checked {
    background-color: var(--brand-primary);
    opacity: 1;
    border: 0.5px solid var(--brand-primary);
  }
  input[type='checkbox']:checked::before {
    content: '';
    position: absolute;
    right: 50%;
    top: 50%;
    width: 3px;
    height: 8px;
    border: solid var(--text-white);
    border-width: 0 2px 2px 0;
    margin: -0.5px -1px 0 -1px;
    transform: rotate(45deg) translate(-50%, -50%) scale(0.7);
    z-index: 2;
  }
`;
const Inputvalue2 = styled.input``;
const Blankdiv = styled.div`
  width: 14px;
  height: 14px;
`;
const Unassigndiv = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
export {
  Dropcontainer,
  Dropheader,
  Inputarea,
  Textblock,
  Dropselect,
  Dropcontent,
  Dropblock,
  Droptextform,
  Icon,
  Signmode,
  Drop,
  Rightrow1,
  AvatarImage,
  Assigngroup,
  RightText,
  HumanIcon,
  Assignbottom,
  Assign,
  Div,
  Top,
  Dropboxdiv,
  Checkbox,
  Inputvalue2,
  Blankdiv,
  Unassigndiv
};
