import moment from 'moment';
import MessageLoading from '../../loading/messageLoading';
import {
  AvatarWrap,
  Comments,
  Desc,
  DesignDate,
  Discussdiv,
  Discussiontop,
  EmojiDiv,
  FileBox,
  FileDiv,
  FileIconDiv,
  FileType,
  FolderRight,
  Footer,
  Group,
  Icon,
  Leftcomment,
  Leftdiv,
  Message,
  MessagesDivMain,
  OverLay,
  Reactiondiv,
  ReactionDiv,
  ReactionShow,
  Replyicon,
  Rightcomment,
  RightIcons,
  RightSection,
  ScrollRef,
  Tagdesc,
  Topdiv,
  Undomessage
} from './style';
import {
  categorizeFiles,
  convertBase64ToFile,
  extensionName,
  getDateObjecttoMMMDDYYYY,
  getFirebaseUrlFromFile,
  isEmpty,
  isEmptyMessage,
  isMediaRecordingSupported
} from '../../../helpers/common';
import { CommentFilesInterface, CommentReactionsInterface } from '../../../interfaces/TaskInterface';
import Avatar from '../../avatar/Avatar';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { EmojiClickData } from 'emoji-picker-react';
import RenderHtml from '../../renderHtml';
import RenderVideoFile from '../../renderVideoFile';
import { CHAT_GROUP_TYPE, DRAFT_MESSAGE_TYPE, FILE_EXTENTIONS, FILE_TYPES } from '../../../global/constants';
import { MessageEmptyState } from '../../emptyState/messageempty';
import RenderExistingFile from '../../renderExistingFile';
import { useSelector, useDispatch } from 'react-redux';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { trackAnalyticActivity } from '../../../services/analyticsService';
import { addReactionOnMessage } from '../../../services/projectServices';
import { useHistory, useParams } from 'react-router-dom';
import { ReplyMessageDataInterface } from '../../../interfaces/MessageInterface';
import { sendDiscussionMessage, updateDiscussionMessageDetail } from '../../../services/chatMessageService';
import { setDiscussionData, setProjectFiles } from '../../../actions/projectActions';
import { captureException } from '../../../services/logService';
import { getFigmaPreviewUrl } from '../../../services/taskServices';
import { nanoid } from 'nanoid';
import { MessageDetailsInterface, MessageReplyInterface } from '../../../interfaces/chatMessageInterface';
import { ProjectFilesInterface } from '../../../interfaces/ProjectInterface';
import ChatMessageCacheService from '../../../services/chatMessageCacheService';
import { useMobileDevice } from '../../../global/useMobile';
import { setErrorMessage } from '../../../actions/messageActions';
import { setCurrentChatGroup } from '../../../actions/chatMessageActions';
import { doc, setDoc } from 'firebase/firestore';
import { database } from '../../../utils/firebase';
import InfiniteScroll from 'react-infinite-scroll-component';
import Emoji from '../../emojiPicker/emojiPicker';
import MessagesInputBox from '../../inputBox/messageInputBox';
import { MESSAGE_ANALYTICS } from '../../../global/analyticsConstants';
import ModalCustom from '../../models/modal';
import SnapRecordModal from '../../models/snapRecordModal';
import InsertGifModal from '../../models/insertGifModal';
import { RenderFilePreview } from '../../renderFilePreview';
import { RenderImagePreview } from '../../renderImagePreview';
import FileSVGIcon from '../../../assets/images/svg/filesIconSvg';
import { Dropdown, DropdownItem } from '../../Dropdown';
import DraftMessageCacheService from '../../../services/draftMessageCacheService';
import UserPreferenceSingleton from '../../../helpers/userPreferenceSingleton';
import UserModal from '../../models/userModal';
import { setUserModalDetail } from '../../../actions/appActions';
import { UsersWorkspaceInterface } from '../../../interfaces/WorkspaceInterface';

interface Props {
  loading: boolean;
  messages: MessageDetailsInterface[];
  loadData: () => void;
  projectAllFiles: ProjectFilesInterface[];
  currentProject: any;
  isNavbarMessage: boolean;
  newGroup?: boolean;
  isProjectMessageDetail?: boolean;
  isMessageLoading?: boolean;
  setSelectFile: (value: boolean) => void;
  selectFile?: boolean;
  isMessage?: boolean;
  figmaRefreshLoading?: boolean;
  isProjectDetail?: boolean;
  attachment?: boolean;
  setAttachment: (value: boolean) => void;
}

export const Messagesfeed = ({
  loading,
  messages,
  loadData,
  projectAllFiles,
  currentProject,
  isNavbarMessage,
  newGroup,
  isProjectMessageDetail,
  isMessageLoading,
  setSelectFile,
  selectFile,
  isMessage,
  isProjectDetail,
  attachment,
  setAttachment
}: Props) => {
  //Refs
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const messageRef = useRef(null);
  const inlineRef = useRef<any>(null);
  const buttonRef = useRef<React.RefObject<HTMLDivElement>[]>([]);
  const emojiButtonRef = useRef<React.RefObject<HTMLDivElement>[]>([]);
  // States
  const [openEmoji, setOpenEmoji] = useState(false);
  // it is used as message id to open dropdown
  const [selectedMesaage, setSelectedMessage] = useState<string | null>(null);
  const [deletedMessage, setDeletedMessage] = useState<string[]>([]);
  const [replyMessage, setReplyMessage] = useState<ReplyMessageDataInterface>({});
  const [figmaRefreshLoading, setFigmaRefreshLoading] = useState(false);
  const [hoveredReaction, setHoveredReaction] = useState<string>();
  const [isloading, setLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<CommentFilesInterface[]>([]);
  const [msg, setMsg] = useState<string>('');
  const [displayedMessages, setDisplayedMessages] = useState<MessageDetailsInterface[]>([]);
  const [currentIndex, setCurrentIndex] = useState(messages?.length - 1);
  const [isSnapModalOpen, setIsSnapModalOpen] = useState(false);
  const [openGifModal, setIsOpenGifModal] = useState(false);
  const [emojiLoading, setEmojiLoading] = useState(false);
  const [isOpenUserModal, setIsOpenUserModal] = useState(false);

  // Reducer selector variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { workspace: workspaceSelector, auth: authSelector, app: appSelector } = stateSelector || {};
  const { workspace } = workspaceSelector;
  const { userDetails } = authSelector;
  const { isBubbleMenuOpen } = appSelector;
  // Other variables
  let dateRef: string | null;
  const batchSize = 20; // Number of messages to display at a time
  const params: { id: string } = useParams();
  const history = useHistory();
  const mobile = useMobileDevice();
  const dispatch = useDispatch();
  const newGroupState = !!(isNavbarMessage && newGroup);
  const chatGroupId = UserPreferenceSingleton.getInstance().getLastMessageGroup();

  useEffect(() => {
    if (inlineRef?.current) {
      inlineRef?.current.clearContent();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [currentProject?.id]);

  const loadDraftMessage = useCallback(async () => {
    const chatGroup = new URLSearchParams(window.location.search).get('chatGroup') || chatGroupId;
    setUploadedFiles([]);
    const draftMessage = await DraftMessageCacheService.getInstance().getCurrentDraftMessage(chatGroup);
    if (draftMessage && inlineRef.current) {
      inlineRef.current.addContent(draftMessage.message);
      setMsg(draftMessage.message);
      setUploadedFiles(draftMessage.file);
    }
  }, [chatGroupId]);

  useEffect(() => {
    loadDraftMessage();
    setReplyMessage({});
  }, [loadDraftMessage]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      inline: 'nearest',
      block: 'nearest'
    });
  };

  useEffect(() => {
    scrollToBottom();
  }, []);

  useEffect(() => {
    const dropdown = document.querySelector('.dropdown-content');
    if (dropdown instanceof HTMLElement) {
      const rect = dropdown.getBoundingClientRect();
      const windowHeight = window.innerHeight || document.documentElement.clientHeight;

      if (rect.bottom + 10 > windowHeight) {
        dropdown.style.top = `-${rect.height + 5}px`;
      } else {
        dropdown.style.top = 'auto';
      }
    }
  }, [openEmoji]);

  const handleOpenChange = (itemId: string) => {
    if (selectedMesaage === itemId) {
      setOpenEmoji(false);
      setSelectedMessage(null);
    } else {
      setOpenEmoji(true);
      setSelectedMessage(itemId);
    }
  };

  const closeEmojiDropdown = () => {
    setOpenEmoji(false);
    setSelectedMessage('');
  };

  const onReactOnMessage = useCallback(
    async (emojiData: { emoji: string }, item: MessageDetailsInterface) => {
      setEmojiLoading(true);
      trackAnalyticActivity(MESSAGE_ANALYTICS.MESSAGE_REACTED);
      closeEmojiDropdown();
      const payload = {
        reaction: emojiData?.emoji,
        commentId: item?.id,
        userId: userDetails?.id,
        companyId: workspace?.id,
        groupName: currentProject?.name
      };
      const response = await dispatch(addReactionOnMessage(payload));
      await ChatMessageCacheService.getInstance()?.updateExisting(item?.id, { ...item, reactions: response.Reactions });
      if (response && loadData) {
        loadData();
        setEmojiLoading(false);
      }
    },
    [currentProject?.name, dispatch, loadData, userDetails?.id, workspace?.id]
  );

  const onClickReply = useCallback((item: MessageDetailsInterface) => {
    setReplyMessage({
      id: item?.id,
      Message: item?.message,
      Files: item?.uploadedFile,
      CreatedTime: item?.createdOn,
      Sender: item?.sender?.name,
      Sender_id: item?.sender?.id
    });
  }, []);

  const onClickDelete = useCallback(
    async (itemId: string) => {
      if (!itemId) {
        dispatch(setErrorMessage('Invalid message id! Try again later.'));
        return;
      }
      const payload = {
        IsArchived: true
      };
      const result = await dispatch(updateDiscussionMessageDetail(itemId, payload));
      if (result) {
        setSelectedMessage(null);
        trackAnalyticActivity(MESSAGE_ANALYTICS.REMOVED);
        setDeletedMessage((prevDeletedMessages) => [...prevDeletedMessages, itemId]);
      }
    },
    [dispatch]
  );

  // Helper function to update comments in Redux
  const updateMessageInState = useCallback((updatedHtmlString: string, messageId: string) => {
    setDisplayedMessages((prevMessages) =>
      prevMessages.map((message) => (message?.id === messageId ? { ...message, message: updatedHtmlString } : message))
    );
  }, []);

  // Helper function to parse comment HTML
  const getMessageBody = useCallback((messageHtml: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(messageHtml, 'text/html');
    return doc.querySelector('body');
  }, []);

  // Helper function to add/remove animation
  const toggleRefreshAnimation = useCallback((body: HTMLElement, isSpin: boolean) => {
    const refreshIcon = body.querySelector('.refresh-icon');
    if (refreshIcon) {
      refreshIcon.classList.toggle('refresh-icon-spin', isSpin);
    }
  }, []);

  // Helper function to extract Figma fileId and nodes
  const extractFigmaUrlData = useCallback((figmaUrl: string) => {
    const url = new URL(figmaUrl);
    return { fileId: url.pathname.split('/')[2], nodes: url.searchParams.get('node-id') };
  }, []);

  const onClickHandleRefreshFigma = useCallback(
    async (event: React.MouseEvent, item: MessageDetailsInterface) => {
      try {
        setFigmaRefreshLoading(true);
        if ((event.target as HTMLElement).id !== 'figma-refresh-ref') return;
        const body = getMessageBody(item?.message || '');
        if (!body) return;
        const figmaUrlDiv = body.querySelector('.figma-link-div');
        if (!(figmaUrlDiv instanceof HTMLAnchorElement)) return;
        toggleRefreshAnimation(body, true);
        updateMessageInState(body.innerHTML, item?.id);
        const { fileId, nodes } = extractFigmaUrlData(figmaUrlDiv.href);
        const figmaData = await dispatch(getFigmaPreviewUrl({ fileId, nodes }));
        if (!figmaData) return;
        const previewImage = body.querySelector('.figma-preview-image');
        if (previewImage instanceof HTMLImageElement) {
          previewImage.src = figmaData?.filePreviewUrl || '';
        }
        const description = body.querySelector('.figma-description');
        if (description) {
          description.textContent = figmaData?.frameNames;
        }
        toggleRefreshAnimation(body, false);
        const filename = body.querySelector('.figma-filename');
        if (filename) {
          filename.textContent = figmaData?.projectName || '';
        }
        const updatedHtmlString = body.innerHTML;
        updateMessageInState(updatedHtmlString, item.id);
        await dispatch(updateDiscussionMessageDetail(item?.id, { Message: updatedHtmlString }));
      } catch (e) {
        captureException(e);
      } finally {
        setFigmaRefreshLoading(false);
      }
    },
    [dispatch, extractFigmaUrlData, getMessageBody, toggleRefreshAnimation, updateMessageInState]
  );

  const onClickUndo = useCallback(
    async (item: { id: string }) => {
      const payload = {
        IsArchived: false
      };
      const result = await dispatch(updateDiscussionMessageDetail(item?.id, payload));
      if (result) {
        setDeletedMessage((prevDeletedMessages) => prevDeletedMessages.filter((id) => id !== item?.id));
      }
    },
    [dispatch]
  );

  const handleValueChange = useCallback(async (text: string) => {
    setMsg(text);
    const chatGroup = new URLSearchParams(window.location.search).get('chatGroup');
    const chatGroupId = chatGroup || UserPreferenceSingleton.getInstance().getLastMessageGroup();
    // Add a delay of 1 second before calling onFileChange
    if (text !== '<p></p>' && !isEmpty(text)) {
      setTimeout(async () => {
        const draftMessage = await DraftMessageCacheService.getInstance().getCurrentDraftMessage(chatGroupId);
        const message = {
          id: chatGroupId,
          type: DRAFT_MESSAGE_TYPE.COMMENT,
          message: text,
          file: [...(draftMessage?.file || [])]
        };
        await DraftMessageCacheService.getInstance().addBulk([message]);
      }, 200);
    } else if (text === '<p></p>') {
      const draftMessage = await DraftMessageCacheService.getInstance().getCurrentDraftMessage(chatGroupId);
      if (isEmpty(draftMessage?.file)) {
        await DraftMessageCacheService.getInstance().removeBulk([chatGroupId]);
      }
    }
  }, []);

  const onFileChange = useCallback(async (file: CommentFilesInterface[]) => {
    const chatGroupId = UserPreferenceSingleton.getInstance().getLastMessageGroup();
    setUploadedFiles(file);
    const draftMessage = await DraftMessageCacheService.getInstance().getCurrentDraftMessage(chatGroupId);
    const message = {
      id: chatGroupId,
      type: DRAFT_MESSAGE_TYPE.COMMENT,
      message: draftMessage?.message,
      file: file || []
    };
    await DraftMessageCacheService.getInstance().addBulk([message]);
    if ((isEmpty(draftMessage?.message) || draftMessage?.message === '<p></p>') && isEmpty(file)) {
      await DraftMessageCacheService.getInstance().removeBulk([chatGroupId]);
    }
  }, []);

  const onClickFile = useCallback(
    (file: CommentFilesInterface) => {
      const fileName = file.name.replaceAll(' ', '_');
      const chatMessageId = isNavbarMessage ? currentProject?.id : params?.id;
      if (file.href?.startsWith('/projects/files')) {
        window.open(file.href, '_blank');
      } else if (
        file.href?.startsWith('https://') &&
        file?.uploadFileType !== FILE_TYPES.UPLOAD &&
        file?.isCreatedDoc
      ) {
        window.open(file.href, '_blank');
      } else if (
        file.href?.startsWith('https://') &&
        (file?.uploadFileType === FILE_TYPES.DOCS ||
          file?.uploadFileType === FILE_TYPES.DRIVE ||
          file?.uploadFileType === FILE_TYPES.DROPBOX ||
          file?.uploadFileType === FILE_TYPES.FIGMA ||
          file?.uploadFileType === FILE_TYPES.ONEDRIVE)
      ) {
        window.open(file.href, '_blank');
      } else {
        history.push(`/projects/details/${chatMessageId}/files/file-view/${fileName}?fileUrl=${file?.href}`);
      }
    },
    [currentProject?.id, history, isNavbarMessage, params?.id]
  );

  useEffect(() => {
    // Initially display the latest messages
    setDisplayedMessages(messages?.slice(-batchSize));
    setCurrentIndex(messages?.length - batchSize);
  }, [messages]);

  const loadMoreMessages = () => {
    // Load older messages
    const newIndex = Math.max(currentIndex - batchSize, 0);
    setDisplayedMessages(messages?.slice(newIndex, messages?.length));
    setCurrentIndex(newIndex);
  };

  const setInitialDisplayMessages = useCallback((messages: any) => {
    if (messages?.length > 0) {
      setDisplayedMessages(messages?.slice(0, 20));
      setCurrentIndex(20);
    }
  }, []);

  useCallback(() => {
    setInitialDisplayMessages(messages);
  }, [messages, setInitialDisplayMessages]);

  const attachmentPage = () => {
    const withoutParentData = projectAllFiles?.filter((x: any) => isEmpty(x?.parentId));
    dispatch(setProjectFiles(withoutParentData));
    if (mobile) {
      setSelectFile(true);
    } else {
      setAttachment(true);
    }
  };

  const onSendComment = useCallback(
    async (uploadedFiles: CommentFilesInterface[]) => {
      try {
        if (isEmptyMessage(msg) && isEmpty(uploadedFiles)) {
          return dispatch(setErrorMessage("Message can't be empty."));
        }
        if (!isEmpty(msg) || uploadedFiles?.length > 0) {
          if (isNavbarMessage && isEmpty(currentProject?.id)) {
            return dispatch(setErrorMessage('Please select a recipient user.'));
          }
          setLoading(true);
          let commentData = msg;
          const pattern = /<p><span>​<\/span><\/p>|<p><br><\/p>|<p><br><br><\/p>/g;
          if (!isEmpty(msg)) {
            while (pattern.test(commentData)) {
              commentData = commentData.replace(pattern, '');
            }
            if (commentData.match(/<br\s*>\s*<\/p>$/)) commentData = commentData.replace(/<br\s*>\s*<\/p>$/, '</p>');
          }
          const tempElement = document.createElement('div');
          tempElement.innerHTML = commentData || '';
          const linkElements = tempElement.querySelectorAll('img');
          let promises: Promise<void>[] = [];
          linkElements.forEach((linkElement) => {
            const myPromise = new Promise<void>(async (myResolve, myReject) => {
              try {
                const srclink = linkElement?.getAttribute('src');
                if (!isEmpty(srclink)) {
                  if (
                    !srclink?.startsWith('https://firebasestorage') &&
                    !srclink?.startsWith('https://figma') &&
                    !srclink?.startsWith('/static') &&
                    !srclink?.startsWith('https://')
                  ) {
                    const fileData = await convertBase64ToFile(srclink);
                    const fileUrl = await getFirebaseUrlFromFile({ file: fileData }, 'files/', dispatch);
                    if (fileUrl) {
                      commentData = commentData?.replace(srclink || '', fileUrl);
                    }
                  }
                }
                myResolve(); // Resolve the promise if everything succeeds
              } catch (error) {
                myReject(error); // Reject the promise if something fails
              }
            });
            promises.push(myPromise);
          });
          if (isNavbarMessage) {
            const replyPayload = !isEmpty(replyMessage)
              ? {
                  message: replyMessage?.Message,
                  createdOn: replyMessage?.CreatedTime,
                  files: replyMessage?.Files,
                  id: replyMessage?.id,
                  sender: replyMessage?.Sender
                }
              : {};
            const messagePayload = {
              projectId: currentProject?.id,
              messageGroupId: null,
              sender: userDetails,
              message: commentData,
              createdOn: new Date().toISOString(),
              isRead: true,
              uploadedFile: uploadedFiles,
              reactions: null,
              reply: replyPayload,
              isArchived: false,
              id: nanoid()
            };

            dispatch(
              setCurrentChatGroup({
                ...currentProject,
                messages: [...(currentProject?.messages || []), messagePayload]
              })
            );
          } else {
            const replyPayload: MessageReplyInterface | undefined = !isEmpty(replyMessage)
              ? {
                  message: replyMessage?.Message || '',
                  createdOn: replyMessage?.CreatedTime || '',
                  files: replyMessage?.Files || [],
                  id: replyMessage?.id || '',
                  sender: replyMessage?.Sender || ''
                }
              : undefined;

            const messagePayload = {
              projectId: currentProject?.id,
              fileExt: '',
              messageGroupId: null,
              sender: {
                avatar: {
                  avatar: userDetails?.avatar?.avatar || '',
                  avatar_120: userDetails?.avatar?.avatar_120 || ''
                },
                email: userDetails?.email || '',
                id: userDetails?.id || '',
                name: userDetails?.name,
                phone: userDetails?.phone,
                profile_photo: userDetails?.profile_photo,
                isAdmin: userDetails?.isAdmin || false,
                isOwner: false
              },
              message: commentData,
              createdOn: new Date().toISOString(),
              isRead: true,
              uploadedFile: uploadedFiles,
              reactions: [],
              reply: replyPayload,
              isArchived: false,
              id: nanoid()
            };

            dispatch(setDiscussionData([...messages, messagePayload]));
          }
          const editorElement: HTMLElement | null = document.getElementById('inlineRTE_rte-edit-view');
          if (editorElement) {
            editorElement.innerHTML = '<p><br /></p>';
          }
          setReplyMessage({});
          setMsg('');
          inlineRef.current.clearContent();
          setUploadedFiles([]);
          setAttachment(false);

          await Promise.all(promises).then(() => {});
          currentProject?.users?.forEach(async (user: any) => {
            if (user?.id !== userDetails?.id) {
              await setDoc(doc(database, 'messages', `${workspace?.id}_${user?.id}`), { updated: true });
            }
          });

          if (isNavbarMessage) {
            const payload = {
              Message: commentData,
              ProjectId: currentProject?.type === CHAT_GROUP_TYPE.PROJECT ? currentProject?.id : undefined,
              ReadUsers: [userDetails?.id],
              UploadedFiles: uploadedFiles,
              Reply: !isEmpty(replyMessage) ? replyMessage : null,
              MessageGroupId: currentProject?.type === CHAT_GROUP_TYPE.PERSONAL ? currentProject?.id : undefined,
              CompanyId: workspace?.id
            };
            const result = await dispatch(sendDiscussionMessage(payload));

            if (result) {
              trackAnalyticActivity(MESSAGE_ANALYTICS.SENT, {
                type: currentProject?.type === CHAT_GROUP_TYPE.PERSONAL ? 'personal' : 'project',
                with_reply: isEmpty(replyMessage) ? false : true
              });
              loadData();
            }
          } else {
            const payload = {
              Message: commentData,
              ProjectId: currentProject?.id,
              ReadUsers: [userDetails?.id],
              UploadedFiles: uploadedFiles,
              Reply: !isEmpty(replyMessage) ? replyMessage : null
            };
            const result = await dispatch(sendDiscussionMessage(payload));
            if (result) {
              trackAnalyticActivity(MESSAGE_ANALYTICS.SENT, {
                type: 'project',
                with_reply: isEmpty(replyMessage) ? false : true
              });
              loadData();
            }
          }
          await DraftMessageCacheService.getInstance().removeBulk([currentProject?.id]);
        }
      } catch (e) {
        captureException(e);
        console.log('Error', e);
      } finally {
        setLoading(false);
      }
    },
    [
      msg,
      dispatch,
      isNavbarMessage,
      currentProject,
      setAttachment,
      replyMessage,
      userDetails,
      messages,
      workspace?.id,
      loadData
    ]
  );
  const getFileType = useCallback((uploadFileType: number | undefined, extension: string): string => {
    if (uploadFileType === FILE_TYPES.DOCS) return 'docs';
    if (uploadFileType === FILE_TYPES.WHITEBOARD) return 'white';
    return extension;
  }, []);

  const onSelectFile = useCallback(
    (item: any) => {
      const parts = item?.name?.split('.');
      const extension = parts[parts.length - 1];
      let fileUrl;
      if (item?.uploadFileType === FILE_TYPES.DOCS || item?.uploadFileType === FILE_TYPES.WHITEBOARD) {
        if (!isEmpty(item?.parentId) && !isEmpty(item?.id)) {
          fileUrl = `/projects/files/${params?.id}/docs/${item?.id}?folder=${item?.parentId}`;
        } else if (!isEmpty(item?.id) && isEmpty(item?.parentId)) {
          fileUrl = `/projects/files/${params?.id}/docs/${item?.id}`;
        }
      } else {
        fileUrl = item?.url;
      }
      const sizeInMB = item?.size / (1024 * 1024);
      let sizeValue;
      if (sizeInMB >= 1) {
        sizeValue = `${sizeInMB.toFixed(2)} MB`;
      } else {
        const sizeInKB = item?.size / 1024;
        sizeValue = `${sizeInKB.toFixed(2)} KB`;
      }
      if (extension === 'png' || extension === 'jpeg' || extension === 'jpg') {
        inlineRef.current.addImageInContent(fileUrl);
      } else if (extension === 'mp4' || extension === 'mov' || extension === 'mkv' || extension === 'webm') {
        inlineRef.current.addRecordingCard(fileUrl, item?.name);
      } else {
        const newObj: CommentFilesInterface = {
          name: item?.name,
          href: fileUrl || '',
          size: sizeValue,
          file_type: getFileType(item?.uploadFileType, extension),
          isCreatedDoc: true,
          uploadFileType: Number(item?.uploadFileType)
        };
        setUploadedFiles([...uploadedFiles, newObj]);
        onFileChange([...uploadedFiles, newObj]);
      }
    },
    [getFileType, onFileChange, params?.id, uploadedFiles]
  );

  const handleOpenSnapModal = useCallback(() => {
    setIsSnapModalOpen(true);
  }, []);

  const handleCloseSnapModal = useCallback(() => {
    setIsSnapModalOpen(false);
  }, []);

  const handleOpenGifModal = useCallback(() => {
    setIsOpenGifModal(true);
  }, []);

  const handleCloseGifModal = useCallback(() => {
    setIsOpenGifModal(false);
  }, []);

  const onClickUserPopup = useCallback(
    (member: UsersWorkspaceInterface) => {
      dispatch(setUserModalDetail(member));
      setIsOpenUserModal(true);
    },
    [dispatch]
  );

  const onCloseUserModal = useCallback(() => {
    setIsOpenUserModal(false);
  }, []);

  if (selectFile) {
    return (
      <RenderExistingFile
        setAttachment={() => setAttachment(false)}
        isMessage={isMessage}
        setSelectFile={(value: boolean) => setSelectFile(value)}
        onSelectFile={onSelectFile}
      />
    );
  }

  return (
    <Group isProjectMessageDetail={isProjectMessageDetail}>
      <Leftdiv
        isProjectMessageDetail={isProjectMessageDetail}
        id='scrollable-container'
        ref={containerRef}
        className={isProjectMessageDetail ? 'project-message-detail' : ''}
        isOverflowHidden={isBubbleMenuOpen}>
        <MessagesDivMain>
          {/* as position is fixed for input we calculate the height of the input 
          to make the comment scroll as the input height increase */}
          <Discussdiv>
            {loading && (messages?.length === 0 || !messages) && (
              <MessageLoading
                isMessagesloading={isNavbarMessage ? true : false}
                iswidth={isNavbarMessage ? true : false}
                isProjectMessageDetail={isProjectMessageDetail}
              />
            )}

            {messages?.length > 0 && !newGroupState && (
              <Topdiv>
                {loading && (messages?.length === 0 || !messages) && (
                  <MessageLoading
                    isMessagesloading={isMessageLoading}
                    isProjectMessageDetail={isProjectMessageDetail}
                  />
                )}
                <InfiniteScroll
                  dataLength={displayedMessages?.length || 0}
                  next={loadMoreMessages}
                  hasMore={currentIndex > 0}
                  loader={
                    <MessageLoading
                      isMessagesloading={isMessageLoading}
                      isProjectMessageDetail={isProjectMessageDetail}
                    />
                  }
                  endMessage={<></>}
                  scrollableTarget='scrollable-container'
                  scrollThreshold={0.7}
                  inverse={true}>
                  {displayedMessages?.map((item: any, index: number) => {
                    [buttonRef, emojiButtonRef].forEach((ref) => {
                      if (!ref.current[index]) {
                        ref.current[index] = React.createRef<HTMLDivElement>();
                      }
                    });
                    const { imageFiles, videoFiles, audioFiles, otherFiles } = categorizeFiles(item?.uploadedFile);
                    let formatedDate = getDateObjecttoMMMDDYYYY(item?.createdOn);
                    let flag = false;
                    if (formatedDate !== dateRef) {
                      flag = true;
                      dateRef = formatedDate;
                    }

                    const isFirstUserMessage =
                      flag || index === 0 || item?.sender?.id !== displayedMessages?.[index - 1]?.sender?.id;
                    // Flag for next message
                    let nextMesssageDate =
                      displayedMessages?.[index + 1]?.createdOn &&
                      getDateObjecttoMMMDDYYYY(displayedMessages?.[index + 1]?.createdOn);

                    const isShowUserImage =
                      nextMesssageDate !== formatedDate ||
                      item?.sender?.id !== displayedMessages?.[index + 1]?.sender?.id;
                    const reactionCounts = item?.reactions?.reduce(
                      (
                        acc: {
                          [ReactionType: string]: number;
                        },
                        reaction: CommentReactionsInterface
                      ) => {
                        const { ReactionType } = reaction;
                        acc[ReactionType] = (acc[ReactionType] || 0) + 1;
                        return acc;
                      },
                      {}
                    );
                    return (
                      <div key={item?.id} id={item?.id} style={!isNavbarMessage ? { width: '100%' } : {}}>
                        {flag && (
                          <Discussiontop isProjectMessageDetail={isProjectMessageDetail}>
                            <DesignDate>
                              <span>{formatedDate}</span>
                            </DesignDate>
                          </Discussiontop>
                        )}
                        <Comments
                          isFirstUserMessage={isFirstUserMessage}
                          // isShowHover={item?.id === highlightedMessage}
                          isProjectMessageDetail={isProjectMessageDetail}>
                          <Leftcomment isProjectMessageDetail={isProjectMessageDetail} hasUserImage={isShowUserImage}>
                            {isShowUserImage && (
                              <AvatarWrap onClick={() => onClickUserPopup(item?.sender)}>
                                <Avatar
                                  imgSrc={item?.sender?.profile_photo}
                                  name={item?.sender?.name ? item?.sender?.name : 'U N'}
                                  size={36}
                                  isNotBorder={true}
                                  classname='avtar'
                                />
                                <OverLay />
                              </AvatarWrap>
                            )}
                          </Leftcomment>
                          <Rightcomment
                            isActive={(openEmoji && selectedMesaage === item?.id) || selectedMesaage === item?.id}>
                            {!deletedMessage.includes(item?.id) && (
                              <RightSection className='rightsection'>
                                <RightIcons>
                                  <EmojiDiv onClick={() => onReactOnMessage({ emoji: '👍' }, item)}>👍</EmojiDiv>
                                  <EmojiDiv onClick={() => onReactOnMessage({ emoji: '👋' }, item)}>👋</EmojiDiv>
                                  <ReactionDiv
                                    ref={emojiButtonRef?.current[index]}
                                    isActive={
                                      (openEmoji && selectedMesaage === item?.id) || selectedMesaage === item?.id
                                    }
                                    onClick={() => handleOpenChange(item?.id)}>
                                    <SVGIcon
                                      name='reaction-icon'
                                      width='16'
                                      height='16'
                                      viewBox='0 0 18 18'
                                      className='svgicon'
                                    />
                                    {openEmoji && selectedMesaage === item?.id && (
                                      <Emoji
                                        emojiButtonref={emojiButtonRef?.current[index]}
                                        isOpenEmoji={openEmoji}
                                        setIsOpenEmoji={setOpenEmoji}
                                        setOpenDropdownId={setSelectedMessage}
                                        isMessage
                                        onEmojiClick={(emojiData: EmojiClickData) => onReactOnMessage(emojiData, item)}
                                        isEmojiLoading={emojiLoading}
                                      />
                                    )}
                                  </ReactionDiv>
                                  <Replyicon
                                    onClick={() => onClickReply(item)}
                                    isProjectMessageDetail={isProjectMessageDetail}>
                                    <SVGIcon
                                      name='reply-icon'
                                      width='16'
                                      height='16'
                                      viewBox='0 0 18 18'
                                      className='svgicon'
                                    />
                                  </Replyicon>
                                  {userDetails?.id === item?.sender?.id && (
                                    <>
                                      <Dropdown
                                        activeClassName='active'
                                        content={
                                          <Icon
                                            onClick={() => {
                                              setSelectedMessage(item?.id);
                                            }}>
                                            <SVGIcon
                                              name='three-dots-icon'
                                              width='16'
                                              height='16'
                                              viewBox='0 0 18 18'
                                              className='fillColor'
                                            />
                                          </Icon>
                                        }
                                        trigger='click'
                                        onOutsideClick={() => setSelectedMessage(null)}>
                                        <DropdownItem
                                          label='Delete'
                                          tone='critical'
                                          onClick={() => onClickDelete(item?.id)}
                                        />
                                      </Dropdown>
                                    </>
                                  )}
                                </RightIcons>
                              </RightSection>
                            )}

                            <Tagdesc
                              className='tiptap'
                              isreply={!isEmpty(item?.reply)}
                              showGap={item?.message && item?.uploadedFile && item?.uploadedFile?.length > 0}
                              isLastUserMessage={isShowUserImage}
                              isFirstUserMessage={isFirstUserMessage}
                              figmaRefreshLoading={figmaRefreshLoading}>
                              {!deletedMessage.includes(item?.id) && !isEmpty(item?.reply) && (
                                <div
                                  className='threaddiv'
                                  onClick={() => {
                                    const repliedMessage = document.getElementById(item?.reply?.id);
                                    if (repliedMessage) {
                                      repliedMessage.scrollIntoView({ behavior: 'smooth', block: 'start' });
                                    }
                                  }}>
                                  <div className='content'>
                                    <div className='line' />

                                    <div className='rightdiv'>
                                      <div className='header'>
                                        <div className='leftheader'>
                                          <div className='name'>{item?.reply?.sender || ''}</div>
                                          <div className='time'>{moment(item?.reply?.createdOn).format('hh:mm a')}</div>
                                        </div>
                                      </div>
                                      {!isEmpty(item?.reply?.message) && (
                                        <div
                                          className='text'
                                          dangerouslySetInnerHTML={{ __html: item?.reply?.message }}
                                        />
                                      )}
                                      <div className='filediv'>
                                        {item?.reply?.files?.map((x: any, index: number) => {
                                          const extension = extensionName(x).toLowerCase();
                                          return (
                                            <div key={x?.name} className='imagediv'>
                                              {(!x?.uploadFileType || x?.uploadFileType === FILE_TYPES.UPLOAD) &&
                                              !FILE_EXTENTIONS.includes(extension) ? (
                                                <FileIconDiv>
                                                  <FileSVGIcon
                                                    name={'blank'}
                                                    height='32'
                                                    width='32'
                                                    viewBox='0 0 60 60'
                                                  />
                                                  <FileType
                                                    style={{
                                                      fontSize: extension?.length === 4 ? 7 : 5
                                                    }}
                                                    className='file-text'>
                                                    {extension?.length <= 5 &&
                                                      extension.split('.')?.pop()?.toUpperCase()}
                                                  </FileType>
                                                </FileIconDiv>
                                              ) : (
                                                <FileSVGIcon
                                                  name={
                                                    !x?.uploadFileType || x?.uploadFileType === FILE_TYPES.UPLOAD
                                                      ? extension
                                                      : `icon_${x?.uploadFileType}`
                                                  }
                                                  height='32'
                                                  width='32'
                                                  viewBox='0 0 60 60'
                                                />
                                              )}

                                              <div className='filetext'>{x?.name}</div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {!deletedMessage.includes(item?.id) ? (
                                !isEmpty(item?.message) && (
                                  <Desc
                                    figmaRefreshLoading={figmaRefreshLoading}
                                    className='description'
                                    onClick={(e) => onClickHandleRefreshFigma(e, item)}>
                                    <RenderHtml htmlstring={item?.message} />
                                  </Desc>
                                )
                              ) : (
                                <Undomessage>
                                  <p>This message has been deleted.</p>
                                  <a onClick={() => onClickUndo(item)}>Undo</a>
                                </Undomessage>
                              )}
                              {!deletedMessage.includes(item?.id) &&
                                imageFiles?.length > 0 &&
                                imageFiles?.map((item) => {
                                  return (
                                    <RenderImagePreview key={item?.href} imageUrl={item?.href} altText={item?.name} />
                                  );
                                })}
                              {!deletedMessage.includes(item?.id) &&
                                videoFiles &&
                                videoFiles?.length > 0 &&
                                videoFiles?.map((item) => {
                                  return (
                                    <RenderVideoFile key={item?.href} videoUrl={item?.href} videoName={item?.name} />
                                  );
                                })}
                              {!deletedMessage.includes(item?.id) &&
                                audioFiles &&
                                audioFiles?.length > 0 &&
                                audioFiles?.map((item) => {
                                  return (
                                    <audio key={item?.href} controls>
                                      <source type='audio/mp3' src={item?.href} />
                                    </audio>
                                  );
                                })}

                              {!deletedMessage.includes(item?.id) && otherFiles && otherFiles?.length > 0 && (
                                <FileBox>
                                  {otherFiles?.map((file) => {
                                    return (
                                      <FileDiv>
                                        <RenderFilePreview
                                          key={file?.href}
                                          file={file}
                                          maxWidth={240}
                                          onClickUpdate={() => onClickFile(file)}
                                        />
                                      </FileDiv>
                                    );
                                  })}
                                </FileBox>
                              )}
                            </Tagdesc>
                            {item?.reactions &&
                              item?.reactions?.length > 0 &&
                              !deletedMessage?.some((deletedItem) => deletedItem === item?.id) && (
                                <Dropdown
                                  content={
                                    <Reactiondiv>
                                      {Object.entries(reactionCounts || {}).map(([type, count]: [string, unknown]) => (
                                        <ReactionShow
                                          isDisabled={emojiLoading}
                                          onClick={() => onReactOnMessage({ emoji: type }, item)}
                                          key={`reactions_${type}_${count}`}
                                          onMouseOver={() => setHoveredReaction(type)}>
                                          <div className='reaction-icon'>{type}</div>
                                          <p>{count as number}</p>
                                        </ReactionShow>
                                      ))}
                                    </Reactiondiv>
                                  }
                                  trigger='hover'>
                                  <>
                                    {Object.entries(reactionCounts)?.map(([type]) => (
                                      <div key={type}>
                                        {type === hoveredReaction &&
                                          item?.reactions
                                            ?.filter((reaction: any) => reaction.ReactionType === hoveredReaction)
                                            .map((reactedUser: any) => {
                                              const user = workspace?.users?.find(
                                                (workspaceUser) => workspaceUser?.id === reactedUser?.UserId
                                              );
                                              return (
                                                <DropdownItem
                                                  key={`Reactions_type_${reactedUser?.UserId}`}
                                                  label={user?.name}
                                                  isAvatar={true}
                                                  avtarSrc={user?.profile_photo || ''}
                                                />
                                              );
                                            })}
                                      </div>
                                    ))}
                                  </>
                                </Dropdown>
                              )}
                          </Rightcomment>
                        </Comments>
                      </div>
                    );
                  })}
                </InfiniteScroll>
                <ScrollRef ref={messagesEndRef} />
              </Topdiv>
            )}
          </Discussdiv>
          <Footer isProjectMessageDetail={isProjectMessageDetail} className='messages-input'>
            <Message ref={messageRef}>
              {!loading && messages?.length === 0 && <MessageEmptyState />}
              {(!loading || isloading || messages?.length > 0) && (
                <MessagesInputBox
                  ref={inlineRef}
                  valueContent={msg}
                  setValueContent={(value: string) => handleValueChange(value)}
                  onClickSelectFile={attachmentPage}
                  isEveryoneMentionEnable={
                    isNavbarMessage
                      ? currentProject?.type !== CHAT_GROUP_TYPE.PERSONAL ||
                        (currentProject?.users && currentProject?.users?.length > 2)
                      : true
                  }
                  users={currentProject?.users?.filter((user: any) => !isEmpty(user?.id))}
                  uploadedFiles={uploadedFiles}
                  setUploadedFiles={onFileChange}
                  sendComment={() => onSendComment(uploadedFiles)}
                  propsLoading={isloading}
                  setPropsLoading={(value: boolean) => setLoading(value)}
                  replyMessage={replyMessage}
                  setReplyMessage={() => setReplyMessage({})}
                  isSelectFileEnable={isNavbarMessage ? currentProject?.type !== CHAT_GROUP_TYPE.PERSONAL : true}
                  isProjectDetail={isProjectDetail}
                  handleOpenSnapModal={handleOpenSnapModal}
                  handleOpenGifModal={handleOpenGifModal}
                  isCalendarViewCard={true}
                  isAutoFocus={!newGroup}
                />
              )}
            </Message>
          </Footer>
        </MessagesDivMain>
      </Leftdiv>
      {attachment && (
        <FolderRight
          isProjectMessageDetail={isProjectMessageDetail}
          className={isProjectMessageDetail ? 'project-message-detail' : ''}>
          <RenderExistingFile
            setAttachment={() => setAttachment(false)}
            isMessage={true}
            setSelectFile={(value: boolean) => setSelectFile(value)}
            isProjectMessageDetail={isProjectMessageDetail}
            onSelectFile={onSelectFile}
          />
        </FolderRight>
      )}
      {isMediaRecordingSupported() && (
        <ModalCustom open={isSnapModalOpen} onClose={handleCloseSnapModal} width={258}>
          <SnapRecordModal handleCloseModal={handleCloseSnapModal} />
        </ModalCustom>
      )}
      <ModalCustom
        open={openGifModal}
        onClose={handleCloseGifModal}
        width={686}
        hasNotBoxShadow={true}
        isGifModal={true}>
        <InsertGifModal
          setSelectedGif={(url: string) => {
            inlineRef.current.addImageInContent(url);
          }}
          closeModal={handleCloseGifModal}
        />
      </ModalCustom>
      <ModalCustom open={isOpenUserModal} onClose={onCloseUserModal} width={280}>
        <UserModal onClose={onCloseUserModal} />
      </ModalCustom>
    </Group>
  );
};
