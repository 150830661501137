import React, { useCallback, useState } from 'react';
import { Box, Detail, Maindiv, Tag } from './styles';
import Button from '../../../component/button';
import ModalCustom from '../../../component/models/modal';
import Createtemplatemodal from '../../../component/models/createTemplateModal';
import { trackAnalyticActivity } from '../../../services/analyticsService';
import { PROJECT_ANALYTICS } from '../../../global/analyticsConstants';

interface Props {
  heading: string;
  isProject?: boolean;
  isPadding?: boolean;
  isTask?: boolean;
  onClickTaskTemplate?: () => void;
}
export default function Card(Props: Props) {
  const { heading, isProject, isPadding, isTask, onClickTaskTemplate } = Props;
  // States
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOnClick = () => {
    trackAnalyticActivity(PROJECT_ANALYTICS.TEMPLATE_CREATE_CLICKED);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleClick = useCallback(() => {
    if (isProject) {
      handleOnClick();
    } else {
       onClickTaskTemplate?.();
    }
  }, [isProject, onClickTaskTemplate]);

  return (
    <div>
      <Maindiv>
        <Box isPadding={isPadding}>
          <Detail>{heading}</Detail>
          {(isProject || isTask) && <Button title='Create Template' smallbutton={true} onClick={handleClick} />}
          {!isProject && !isTask && <Tag>Coming soon</Tag>}
        </Box>
      </Maindiv>
      <ModalCustom open={isModalOpen} onClose={handleCloseModal} width={400}>
        <Createtemplatemodal onCancel={handleCloseModal} />
      </ModalCustom>
    </div>
  );
}
