import styled from 'styled-components';
import { Typography } from '../../../global/theme';

const Group = styled.div`
  width: 100%;
  min-height: 560px;
  height: 100dvh;
  background-color: var(--background-secondary);
  margin: auto;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 449px) {
    min-height: 781px;
  }
`;

const Content = styled.form`
  width: 100%;
  max-width: 558px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--background-secondary);
  gap: 20px;
  @media (max-width: 449px) {
    gap: 30px;
    max-width: 100%;
    padding: 30px 16px;
  }
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  @media (max-width: 449px) {
    .svgicon {
      width: 40px;
      height: 40px;
    }
  }
`;

const Title = styled.h1`
  ${Typography.heading_lg}
  color: var(--text-primary);
  margin: 0;
  @media (max-width: 449px) {
    text-align: center;
  }
`;

const Formblock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  box-shadow: var(--shadow-card-hover);
  border-radius: 8px;
  padding: 20px;
  @media (max-width: 449px) {
    padding: 18px;
    gap: 30px;
  }
`;

const Profile = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  @media (max-width: 449px) {
    margin-top: 16px;
  }
`;

const Heading = styled.h2`
  ${Typography.body_lg_medium}
  color: var(--text-primary);
  margin: 0;
`;

const Text = styled.p`
  ${Typography.body_md}
  color: var(--text-secondary);
  margin: 0;

  @media (max-width: 449px) {
    text-align: center;
  }
`;

const Subblock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (max-width: 449px) {
    gap: 20px;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  .angledown-icon {
    path {
      stroke: var(--text-secondary);
    }
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 34px;
    padding: 7px 0;
    @media (max-width: 449px) {
      height: 44px;
      padding: 11px 0;
    }
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item {
    padding: 0;
    ${Typography.body_lg_medium}
    color: var(--text-primary);
  }
  .ant-select-selection-search {
    height: 34px;
    @media (max-width: 449px) {
      height: 44px;
    }
  }

  .ant-select-single {
    height: 34px;
    @media (max-width: 449px) {
      height: 44px;
    }
  }
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    ${Typography.body_lg_medium}
    color: var(--text-placeholder);
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 36px;
    padding: 8px 12px;
    background-color: var(--background-primary);
    border: 0.5px solid var(--border-primary);
    @media (max-width: 449px) {
      height: 44px;
      padding: 12px;
    }
  }

  .ant-select-focused.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
      .ant-pagination-size-changer
    )
    .ant-select-selector {
    border: 0.5px solid var(--brand-primary);
    box-shadow: var(--shadow-input-focus);
  }

  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover
    .ant-select-selector {
    border: 0.5px solid var(--brand-primary);
  }
  .ant-select-dropdown {
    background-color: var(--background-primary);
    border: 0.5px solid var(--border-primary);
  }
  .ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: var(--brand-primary) !important;
    ${Typography.body_lg_medium}
  }
  .rc-virtual-list-scrollbar-thumb {
    background: var(--border-secondary) !important;
    width: 6px !important;
    margin-bottom: 4px !important;
    border-radius: 7px !important;
    min-height: 20px !important;
  }
`;

const Label = styled.label`
  ${Typography.body_md_medium}
  color: var(--text-secondary);
`;

const Inputvalue = styled.input`
  ${Typography.body_lg_medium}
  background-color: var(--background-primary);
  color: var(--text-primary);
  border: 0.5px solid var(--border-primary);
  padding: 7px 12px;
  border-radius: 6px;
  outline: none;
  :focus {
    border: 0.5px solid var(--brand-primary);
  }
  ::placeholder {
    ${Typography.body_lg_medium}
    color:  var(--text-placeholder);
  }
  @media (max-width: 449px) {
    padding: 12px;
  }
`;

const Endblock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  @media (max-width: 449px) {
    flex-direction: column;
    gap: 30px;
  }
`;

const Textformat = styled.p`
  ${Typography.body_md}
  color: var(--text-secondary);
  margin: 0;
`;

const FormContent = styled.div`
  width: 100%;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 449px) {
    gap: 20px;
  }
`;

const ProfileView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  @media (max-width: 449px) {
    gap: 8px;
  }
`;

const ProfileContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export {
  Group,
  Content,
  Icon,
  Title,
  Formblock,
  Profile,
  Heading,
  Text,
  Subblock,
  Item,
  Label,
  Inputvalue,
  Endblock,
  Textformat,
  FormContent,
  ProfileView,
  ProfileContent
};
