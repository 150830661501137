import React, { useCallback, useEffect, useState } from 'react';
import {
  Buttondiv,
  Detail,
  Div,
  Featurediv,
  Features,
  Heading,
  Icon,
  Icons,
  Leftdiv,
  Modaldiv,
  Progressdiv,
  Progresstext,
  Rightdiv,
  Subdiv,
  Text,
  Title
} from './styles';
import Button from '../../button';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { Progress } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getSubscriptionUrl } from '../../../services/settingServices';
import { getTotalTaskCount } from '../../../services/taskServices';
import { trackAnalyticActivity } from '../../../services/analyticsService';
import { ACCOUNT_ANALYTICS } from '../../../global/analyticsConstants';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';

export default function PlanUpgradeModel() {
  //Other variable
  const primaryColor = 'var(--brand-primary)';
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [loading, setLoading] = useState(false);
  //use selector state variables
  const workspaceSelector = useSelector((state: RootReducerInterface) => state.workspace);
  const { workspaceTotalTask, workspace } = workspaceSelector;

  const loadData = useCallback(async () => {
    const data = await dispatch(getTotalTaskCount());
    if (data) {
      const analyticsParams = {
        source: 'navbar',
        task_count: Number(data.totalTasks),
        workspace_name: workspace?.name
      };

      trackAnalyticActivity(ACCOUNT_ANALYTICS.SALES_UPGRADE_POPUP_DISPLAYED, analyticsParams);
    }
  }, [dispatch, workspace?.name]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onClickChoose = useCallback(async () => {
    try {
      setLoading(true);
      const response = await dispatch(getSubscriptionUrl());
      if (response?.subscriptionUrl) {
        window.open(response?.subscriptionUrl, '_blank');
      } else {
        console.error('URL not found in response');
      }
    } catch (e) {
      console.log('Error ', e);
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  return (
    <>
      <Modaldiv>
        <Div>
          <Leftdiv>
            <Icon>
              <SVGIcon name='uparrow-icon' width='36' height='36' viewBox='0 0 37 37' />
            </Icon>
            <Heading>Your workspace is on the free plan</Heading>
            <Detail>Upgrade to remove limits and access additional features</Detail>
            <Progressdiv>
              <Progress
                percent={
                  (Number(workspaceTotalTask) / 150) * 100 > 100 ? 100 : (Number(workspaceTotalTask) / 150) * 100
                }
                showInfo={false}
                strokeColor={primaryColor}
                style={{ marginBottom: 0, marginInlineEnd: 0, height: 2 }}
                className='prograss '
              />
              <Progresstext>
                <span>{Number(workspaceTotalTask)}</span>/150 Tasks
              </Progresstext>
            </Progressdiv>
          </Leftdiv>
          <Rightdiv>
            <Title>Paid features</Title>
            <Features>
              <Featurediv>
                <Subdiv>
                  <Icons>
                    <SVGIcon
                      name='navbar-Customer-icon'
                      width='18'
                      height='18'
                      viewBox='0 0 18 18'
                      fill='none'
                      className='stroke-color'
                    />
                  </Icons>
                  <p>Unlimited User</p>
                </Subdiv>
                <Subdiv>
                  <Icons>
                    <SVGIcon
                      name='navbar-project-icon'
                      width='16'
                      height='16'
                      viewBox='0 0 18 18'
                      className='fill-color'
                    />
                  </Icons>
                  <p>Unlimited Projects & Task</p>
                </Subdiv>
                <Subdiv>
                  <Icons>
                    <SVGIcon
                      name='navbar-milestone-icon'
                      width='16'
                      height='16'
                      viewBox='0 0 16 16'
                      fill='none'
                      className='stroke-color'
                    />
                  </Icons>
                  <p>Milestone</p>
                </Subdiv>
                <Subdiv>
                  <Icons>
                    <SVGIcon
                      name='help-center-icon'
                      width='16'
                      height='16'
                      viewBox='0 0 18 18'
                      fill='none'
                      className='fill-color'
                    />
                  </Icons>
                  <p>Docs & Whiteboard</p>
                </Subdiv>
              </Featurediv>
              <Featurediv>
                <Subdiv>
                  <Icons>
                    <SVGIcon
                      name='navbar-Report-icon'
                      width='16'
                      height='16'
                      viewBox='0 0 18 18'
                      fill='none'
                      className='stroke-color'
                    />
                  </Icons>
                  <p>Advanced Reports</p>
                </Subdiv>

                <Subdiv>
                  <Icons>
                    <SVGIcon
                      name='navbar-client-portal-icon'
                      width='16'
                      height='16'
                      viewBox='0 0 16 16'
                      className='circle-stroke-color'
                    />
                  </Icons>
                  <p>Client Portal</p>
                </Subdiv>

                <Subdiv>
                  <Icons>
                    <SVGIcon
                      name='navbar-Invoice-icon'
                      width='16'
                      height='16'
                      viewBox='0 0 18 18'
                      fill='none'
                      className='stroke-color'
                    />
                  </Icons>
                  <p>Invoicing and Payment</p>
                </Subdiv>

                <Subdiv>
                  <Icons>
                    <SVGIcon
                      name='navbar-support-icon'
                      width='16'
                      height='16'
                      viewBox='0 0 16 16'
                      className='stroke-color'
                    />
                  </Icons>
                  <p>Priority Support</p>
                </Subdiv>
              </Featurediv>
            </Features>
          </Rightdiv>
        </Div>
        <Buttondiv>
          <Text>49$ / Month</Text>
          <Button title='Upgrade Now' modelbtn={true} isLoading={loading} smallbutton={true} onClick={onClickChoose} />
        </Buttondiv>
      </Modaldiv>
    </>
  );
}
