import styled from 'styled-components';
import { Typography } from '../../../global/theme';
const Container = styled.div`
  width: 100%;
  max-width: 449px;
  height: 100vh;
`;
const Group = styled.div`
  height: calc(100vh - 53px);
  background-color: var(--background-secondary);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 53px;
`;
const Content = styled.div`
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;
const Topcontent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const Icon = styled.div`
  padding-bottom: 16px;
`;
const Title = styled.div`
  padding-bottom: 12px;
  color: var(--text-primary);
  ${Typography.heading_lg}
  text-align: center;
`;
const Descrioption = styled.div`
  color: var(--text-secondary);
  ${Typography.body_lg}
  text-align: center;
`;
const Buttondiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;
const Link = styled.a`
  width: 100%;
  padding: 12px 0;
  color: var(--text-secondary);
  ${Typography.body_lg_medium}
  text-decoration: none;
  text-align: center;
`;
const Bottom = styled.div`
  width: 100%;
  .firstimg {
    display: flex;
    width: 100%;
    height: auto;
  }
`;
export { Container, Topcontent, Group, Content, Icon, Title, Descrioption, Buttondiv, Link, Bottom };
