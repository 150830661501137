import styled from 'styled-components';
import { Typography } from '../../../global/theme';

const MainModal = styled.div`
  max-width: 462px;
  width: 100%;
  border-radius: 12px;
  background-color: var(--background-primary);
  box-shadow: var(--shadow-modal);
`;

const ModalHead = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  gap: 20px;
  border-bottom: 0.5px solid var(--border-primary);
`;

const ModalTitle = styled.p`
  width: 100%;
  ${Typography.heading_md}
  color: var(--text-primary);
  margin: 0;
`;

const Icon = styled.div`
  display: inline-flex;
  cursor: pointer;
  .fillColor {
    stroke: var(--text-primary);
  }
  :hover {
    background-color: var(--neutral-secondary);
    border-radius: 4px;
  }
`;
const ModalBottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
`;

const Description = styled.p`
  margin: 0;
  ${Typography.body_lg}
  color: var(--text-secondary);
`;

const Form = styled.form`
  display: flex;
  gap: 12px;
  flex-direction: column;
`;

const VerificationBox = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
`;

const InputArea = styled.input`
  padding: 12px 16px;
  border: 0.5px solid var(--border-primary);
  border-radius: 6px;
  ${Typography.body_lg}
  color: var(--text-primary);
  ::placeholder {
    color: var(--text-secondary);
  }
  :focus {
    outline: none;
  }
`;

const VerificationCode = styled.span`
  ${Typography.body_md_medium}
  color: var(--brand-primary);
  cursor: pointer;
`;

const ButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  justify-content: end;
`;

export {
  MainModal,
  ModalHead,
  ModalTitle,
  Icon,
  ModalBottom,
  Description,
  Form,
  VerificationBox,
  InputArea,
  VerificationCode,
  ButtonDiv
};
