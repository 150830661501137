import React, { useCallback, useEffect, useState } from 'react';
import Accounting from '../../../../component/accounting';
import AppLayout from '../../../../component/appLayout';
import { useDispatch, useSelector } from 'react-redux';
import { getDiscussionData, getProjectFiles } from '../../../../services/projectServices';
import { useParams } from 'react-router-dom';
import { isEmpty } from '../../../../helpers/common';
import { setDiscussionData } from '../../../../actions/projectActions';
import { RootReducerInterface } from '../../../../interfaces/RootReducerInterface';
import { Messagesfeed } from '../../../../component/navbarmessagedata/messagefeed';

const Messages: React.FC = () => {
  // States
  const [selectFile, setSelectFile] = useState<boolean>(false);
  const [attachment, setAttachment] = useState(false);

  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { project: projectSelector, workspace: workspaceSelector } = stateSelector || {};
  const { discussionData, currentProject, loading, projectAllFiles } = projectSelector;
  const { workspace } = workspaceSelector;

  // Other variables
  const dispatch = useDispatch();
  const params: { id: string } = useParams();

  // load initial data
  const loadData = useCallback(async () => {
    if (!isEmpty(workspace?.id)) {
      await dispatch(getDiscussionData(params?.id));
      await dispatch(getProjectFiles(params?.id, currentProject?.users));
    }
  }, [currentProject?.users, dispatch, params?.id, workspace?.id]);

  useEffect(() => {
    loadData();
    return () => {
      dispatch(setDiscussionData([]));
    };
  }, [loadData, dispatch]);

  return (
    <>
      <AppLayout isMessageBox={true} isOverflowHidden={true}>
        {!selectFile && <Accounting />}{' '}
        <Messagesfeed
          loading={loading}
          messages={discussionData}
          loadData={loadData}
          currentProject={currentProject}
          projectAllFiles={projectAllFiles}
          isNavbarMessage={false}
          isProjectMessageDetail
          setSelectFile={(value: boolean) => setSelectFile(value)}
          selectFile={selectFile}
          isProjectDetail={true}
          attachment={attachment}
          setAttachment={setAttachment}
        />
      </AppLayout>
    </>
  );
};

export default Messages;
