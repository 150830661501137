import styled from 'styled-components';
import { Typography } from '../../../global/theme';
const ModalBox = styled.div`
  padding: 19px 20px;
  background-color: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  box-shadow: var(--shadow-modal);
  border-radius: 12px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  position: relative;
  max-width: 378px;
  width: 100%;
`;

const TitleDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  max-width: 380px;
  width: 100%;
`;

const Title = styled.div`
  ${Typography.heading_md}
  color: var(--text-primary);
`;

const Description = styled.div`
  ${Typography.body_md}
  text-align: center;
  color: var(--text-secondary);
  max-width: 300px;
`;
const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const BottomText = styled.div`
  ${Typography.body_md}
  text-align: left;
  color: var(--text-primary);
`;
const Link = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
`;
const LinkBox = styled.div`
  display: flex;
  gap: 12px;
`;
const LinkText = styled.div`
  ${Typography.body_md}
  text-align: left;
  color: var(--text-secondary);
  width: 282px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const IconDiv = styled.div`
  cursor: pointer;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 16px;
  right: 16px;
  .fillColor {
    stroke: var(--text-secondary);
    :hover {
      background-color: var(--neutral-secondary);
      border-radius: 4px;
    }
  }
`;
const BottomBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const Line = styled.div`
  border-top: 0.5px solid var(--border-primary);
`;
export {
  ModalBox,
  Title,
  Description,
  TitleDiv,
  Bottom,
  BottomText,
  Link,
  LinkBox,
  LinkText,
  IconDiv,
  BottomBox,
  Line
};
