import { ActionCreatorWithOptionalPayload, ActionCreatorWithPayload, createAction, Dispatch } from '@reduxjs/toolkit';
import * as Actions from './types';
import { ReducerInterface } from '../interfaces/ReducerInterface';
import {
  ActivityListInterface,
  DocDataInterface,
  LabelDetailsInterface,
  MilestoneListInterface,
  MoveFilesInterface,
  ProjectDetailInterface,
  ProjectFilesInterface,
  ProjectStatusInterface,
  StatusInputInterface
} from '../interfaces/ProjectInterface';
import { TaskGroupInterface } from '../interfaces/TaskInterface';
import { MessageDetailsInterface } from '../interfaces/chatMessageInterface';
import { rootStore } from '../mobx/rootStore';

/**
 * @desc Set Project loader
 */
export const setProjectLoader: ActionCreatorWithPayload<boolean, string> = createAction(Actions.SET_PROJECT_LOADER);

/**
 * @desc Set Project detail
 */
export const setProjectDetail: ActionCreatorWithPayload<ProjectDetailInterface, string> = createAction(
  Actions.SET_PROJECT_DETAIL
);

/**
 * @desc Set Project List
 */
export const setProjectList: ActionCreatorWithPayload<ProjectDetailInterface[], string> = createAction(
  Actions.SET_PROJECT_LIST
);

/**
 * @desc Set Favourite Project List
 */
export const setFavouriteProjectList: ActionCreatorWithPayload<ProjectDetailInterface[], string> = createAction(
  Actions.SET_FAVOURITE_PROJECT_LIST
);

/**
 * @desc Set Project detail
 */
export const updateProjectDetail: ActionCreatorWithPayload<ReducerInterface, string> = createAction(
  Actions.UPDATE_PROJECT_DETAIL
);

/**
 * @desc Clear Project detail
 */
export const clearProjectDetail: ActionCreatorWithOptionalPayload<undefined, string> = createAction(
  Actions.CLEAR_PROJECT_DETAIL
);

/**
 * @desc Set Project Files
 */
export const setProjectFiles: ActionCreatorWithPayload<ProjectFilesInterface[], string> = createAction(
  Actions.SET_PROJECT_FILES
);

/**
 * @desc Set Project Files
 */
export const setMoveProjectFiles: ActionCreatorWithPayload<MoveFilesInterface[], string> = createAction(
  Actions.SET_MOVE_PROJECT_FILES
);

/**
 * @desc Set Project Files
 */
export const setProjectAllFiles: ActionCreatorWithPayload<ProjectFilesInterface[], string> = createAction(
  Actions.SET_PROJECT_ALL_FILES
);

/**
 * @desc Set current Project Files
 */
export const setCurrentProject: ActionCreatorWithPayload<ProjectDetailInterface, string> = createAction(
  Actions.SET_CURRENT_PROJECT
);

/**
 * @desc Set current Project Files
 */
export const updateCurrentProject: ActionCreatorWithPayload<{ propsName: string; value: any }, string> = createAction(
  Actions.UPDATE_CURRENT_PROJECT
);

/**
 * @desc Set current Project Files
 */
export const clearCurrentProject: ActionCreatorWithOptionalPayload<undefined, string> = createAction(
  Actions.CLEAR_CURRENT_PROJECT
);

/**
 * @desc Set Project Status
 */
export const setProjectStatus: ActionCreatorWithPayload<ProjectStatusInterface, string> = createAction(
  Actions.SET_PROJECT_STATUS
);

/**
 * @desc clear Project Status
 */
export const clearProjectStatus: ActionCreatorWithOptionalPayload<undefined, string> = createAction(
  Actions.CLEAR_PROJECT_STATUS
);

/**
 * @desc Set discussion Data
 */
export const setDiscussionData: ActionCreatorWithPayload<MessageDetailsInterface[], string> = createAction(
  Actions.SET_DISCUSSION_DATA
);

/**
 * @desc Set discussion Data
 */
export const setDocData: ActionCreatorWithPayload<DocDataInterface, string> = createAction(Actions.SET_DOC_DATA);

/**
 * @desc Set discussion Data
 */
export const updateDocData: ActionCreatorWithPayload<ReducerInterface, string> = createAction(Actions.UPDATE_DOC_DATA);

/**
 * @desc Set discussion Data
 */
export const clearDocData: ActionCreatorWithOptionalPayload<undefined, string> = createAction(Actions.CLEAR_DOC_DATA);

/*
 * @desc Set file view data
 */
export const setFileViewData: ActionCreatorWithPayload<ProjectFilesInterface | {}, string> = createAction(
  Actions.SET_FILE_VIEW_DATA
);

/*
 * @desc Set project Activity data
 */
export const setProjectActivity: ActionCreatorWithPayload<ProjectDetailInterface[], string> = createAction(
  Actions.SET_PROJECT_ACTIVITY
);

/*
 * @desc Set project Activity data
 */
export const setProjectArchiveData: ActionCreatorWithPayload<ProjectDetailInterface[], string> = createAction(
  Actions.SET_PROJECT_ARCHIVE_DATA
);

/**
 * @desc set task details input
 */
export const setGroupInput: ActionCreatorWithPayload<TaskGroupInterface, string> = createAction(
  Actions.SET_GROUP_INPUT
);

/**
 * @desc update task details input
 */
export const updateGroupInput: ActionCreatorWithPayload<ReducerInterface, string> = createAction(
  Actions.UPDATE_GROUP_INPUT
);

/**
 * @desc clear task details input
 */
export const clearGroupInput: ActionCreatorWithOptionalPayload<undefined, string> = createAction(
  Actions.CLEAR_GROUP_INPUT
);

/**
 * @desc set task details input
 */
export const setUserActivity: ActionCreatorWithPayload<ActivityListInterface[], string> = createAction(
  Actions.SET_USER_ACTIVITY
);

/**
 * @desc Set Milestone List
 */
export const setMilestoneList: ActionCreatorWithPayload<MilestoneListInterface[], string> = createAction(
  Actions.SET_MILESTONE_LIST
);

/**
 * @desc update milestone List
 */
export const updateMilestoneList: ActionCreatorWithPayload<MilestoneListInterface, string> = createAction(
  Actions.UPDATE_MILESTONE_LIST
);

/**
 * @desc Set Milestone List
 */
export const setArchiveMilestoneList: ActionCreatorWithPayload<MilestoneListInterface[], string> = createAction(
  Actions.SET_ARCHIVE_MILESTONE_LIST
);

/**
 * @desc update milestone List
 */
export const updateArchiveMilestoneList: ActionCreatorWithPayload<MilestoneListInterface, string> = createAction(
  Actions.UPDATE_ARCHIVE_MILESTONE_LIST
);

/**
 * @desc Set Milestone Input
 */
export const setMilestoneInput: ActionCreatorWithPayload<MilestoneListInterface, string> = createAction(
  Actions.SET_MILESTONE_INPUT
);

/**
 * @desc update milestone input
 */
export const updateMilestoneInput: ActionCreatorWithPayload<ReducerInterface, string> = createAction(
  Actions.UPDATE_MILESTONE_INPUT
);

/**
 * @desc clear milestone input
 */
export const clearMilestoneInput: ActionCreatorWithOptionalPayload<undefined, string> = createAction(
  Actions.CLEAR_MILESTONE_INPUT
);

/**
 * @desc Set Upload file percentage
 */
export const setUploadFilePercentage: ActionCreatorWithPayload<number, string> = createAction(
  Actions.SET_UPLOAD_FILE_PERCENTAGE
);

/**
 * @desc set Files List Type
 */
export const setFilesListType: ActionCreatorWithPayload<number, string> = createAction(Actions.SET_FILES_LIST_TYPE);

/**
 * @desc set label input
 */
export const setLabelInput: ActionCreatorWithPayload<LabelDetailsInterface, string> = createAction(
  Actions.SET_LABEL_INPUT
);

/**
 * @desc update label input
 */
export const updateLabelInput: ActionCreatorWithPayload<ReducerInterface, string> = createAction(
  Actions.UPDATE_LABEL_INPUT
);

/**
 * @desc clear label input
 */
export const clearLabelInput: ActionCreatorWithOptionalPayload<undefined, string> = createAction(
  Actions.CLEAR_LABEL_INPUT
);

/**
 * @desc set status input
 */
export const setStatusInput: ActionCreatorWithPayload<StatusInputInterface, string> = createAction(
  Actions.SET_STATUS_INPUT
);

/**
 * @desc update status input
 */
export const updateStatusInput: ActionCreatorWithPayload<ReducerInterface, string> = createAction(
  Actions.UPDATE_STATUS_INPUT
);

/**
 * @desc clear status input
 */
export const clearStatusInput: ActionCreatorWithOptionalPayload<undefined, string> = createAction(
  Actions.CLEAR_STATUS_INPUT
);

/**
 * @desc Set Project List
 */
export const setProjectListUserNotIn: ActionCreatorWithPayload<ProjectDetailInterface[], string> = createAction(
  Actions.SET_PROJECT_LIST_USER_NOT_IN
);

/**
 * @desc Clear project Data
 */
export const clearProjectData = () => (dispatch: Dispatch) => {
  dispatch(setProjectLoader(false));
  dispatch(clearProjectDetail());
  dispatch(setProjectList([]));
  dispatch(setFavouriteProjectList([]));
  dispatch(setProjectFiles([]));
  dispatch(clearDocData());
  dispatch(setFileViewData({}));
  dispatch(setUserActivity([]));
  dispatch(clearGroupInput());
  dispatch(setProjectArchiveData([]));
  dispatch(setProjectActivity([]));
  dispatch(setDiscussionData([]));
  dispatch(clearProjectStatus());
  dispatch(clearCurrentProject());
  dispatch(setProjectAllFiles([]));
  dispatch(setMoveProjectFiles([]));
  dispatch(setMilestoneList([]));
  dispatch(clearMilestoneInput());
  dispatch(setUploadFilePercentage(0));
  dispatch(setProjectListUserNotIn([]));
  rootStore.projectStore.setProjects([]);
  rootStore.projectStore.setProjectUserNotIn([]);
};
