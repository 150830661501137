import React, { useEffect, useCallback, useState } from 'react';
import AppLayout from '../../component/appLayout';
import { useDispatch, useSelector } from 'react-redux';
import { getAllRecurringTasks } from '../../services/taskServices';
import TasksData from '../../component/tasks';
import { captureException } from '../../services/logService';
import { isEmpty } from '../../helpers/common';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';

const RecurringTask: React.FC = () => {
  // States
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  //use selector state variables
  const workspaceSelector = useSelector((state: RootReducerInterface) => state.workspace);
  const { workspace } = workspaceSelector;
  // Other variables
  const dispatch = useDispatch();
  const loadData = useCallback(async () => {
    try {
      if (!isEmpty(workspace?.id)) {
        setLoading(true);
        const response = await dispatch(getAllRecurringTasks());
        setData(response);
      }
    } catch (e) {
      captureException(e);
      console.log('error', e);
    } finally {
      setLoading(false);
    }
  }, [dispatch, workspace?.id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <AppLayout>
      <TasksData data={data} loading={loading} isMyTask={true} loadData={loadData} isRecurringTask={true} />
    </AppLayout>
  );
};

export default RecurringTask;
