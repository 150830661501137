import styled, { css } from 'styled-components';
import { Typography } from '../../../global/theme';

interface Props {
  isPadding?: boolean;
}
const Main = styled.div`
  width: 100%;
  max-width: 662px;
  margin: 0 auto;
  margin-bottom: 24px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 0 12px;
  width: 100%;
  max-width: 662px;
  margin: 0 auto;
  align-items: center;
`;

const Title = styled.div`
  ${Typography.heading_lg}
  color: var(--text-primary);
`;

const Maindiv = styled.div`
  max-width: 662px;
  width: 100%;
`;
const Heading = styled.div`
  ${Typography.heading_md}
  color: var(--text-primary);
`;

const Box = styled.div<Props>`
  border: 0.5px solid var(--border-primary);
  background-color: var(--background-primary);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  padding: 35px 0;
  ${(props) =>
    props.isPadding &&
    css`
      padding: 37px 0;
    `}
`;
const Detail = styled.div`
  ${Typography.body_lg_medium}
  color: var(--text-primary);
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const Tag = styled.div`
  ${Typography.body_sm}
  color: var(--text-secondary);
  background-color: var(--neutral-secondary);
  padding: 2px 6px;
  border-radius: 6px;
  border: 0.5px solid transparent;
`;
const Firstcard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  cursor: pointer;
`;

const Projectcard = styled.div`
  border: 0.5px solid var(--border-primary);
  background-color: var(--background-primary);
  border-radius: 8px;
`;
const Firstprojects = styled.div`
  padding: 9px 16px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid var(--border-primary);
  :last-child {
    border-bottom: none;
  }
  .active{
    border-radius: 4px;
    background: var(--neutral-secondary);
  }
`;
const Left = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const Icon = styled.div`
  height: 18px;
  cursor: pointer;
  position: relative;
  .more-icon {
    cursor: pointer;
    fill: var(--text-secondary);
    stroke: var(--text-secondary);
    :hover {
      border-radius: 4px;
      background: var(--neutral-secondary);
    }
  }
`;
const Name = styled.div`
  ${Typography.body_lg_medium}
  color: var(--text-primary);
`;

const Description = styled.div`
  ${Typography.body_md}
  color: var(--text-secondary);
`;

const BoxLoading = styled.div`
  max-width: 662px;
  width: 100%;
  border-radius: 8px;
  border: 0.5px solid var(--border-primary);
  background: var(--background-primary);
  .loading-animation {
    overflow: hidden;
  }

  .loading-animation::before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    animation: loading 1s infinite;
    background: linear-gradient(to right, transparent, var(--background-tertiary), transparent);
  }

  @keyframes loading {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;
const FirstLoading = styled.div`
  padding: 15px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  border-bottom: 0.5px solid var(--border-primary);
  :last-child {
    border-bottom: none;
  }
`;

const RightLoading = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 5px;
  background-color: var(--background-muted);
`;

const LeftLoading = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const TopLoading = styled.div`
  width: 136px;
  height: 9px;
  border-radius: 7px;
  background-color: var(--background-muted);
`;

const BottomLoading = styled.div`
  width: 280px;
  height: 9px;
  border-radius: 7px;
  background-color: var(--background-muted);
`;

const Group = styled.div`
  width: 100%;
  max-width: 503px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 5px;
  padding-bottom: 24px;
  gap: 15px;
`;

const TitleSetting = styled.div`
  ${Typography.body_md_medium}
  color: var(--text-primary);
`;
const HeaderTask = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Tasklist = styled.div`
  border-radius: 8px;
  border: 0.5px solid var(--border-primary);
  background-color: var(--background-primary);
`;
const Task = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 16px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid var(--border-primary);
  :last-child {
    border-bottom: none;
  }
  .active{
    border-radius: 4px;
    background: var(--neutral-secondary);
  }
`;
const Tasktext = styled.div`
  ${Typography.body_lg_medium}
  color: var(--text-primary);
`;

export {
  Main,
  Header,
  Title,
  Maindiv,
  Heading,
  Box,
  Detail,
  Bottom,
  Tag,
  Firstcard,
  Projectcard,
  Firstprojects,
  Left,
  Icon,
  Name,
  Description,
  BoxLoading,
  FirstLoading,
  RightLoading,
  LeftLoading,
  TopLoading,
  BottomLoading,
  TitleSetting,
  Group,
  HeaderTask,
  Tasklist,
  Task,
  Tasktext
};
