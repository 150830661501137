import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Accounting from '../../../../component/accounting';
import AppLayout from '../../../../component/appLayout';
import { Group, Empty, Buttons } from './styles';

import Deletemodal from '../../../../component/models/deleteModel';
import { useSelector, useDispatch } from 'react-redux';
import milestonelightempty from '../../../../assets/images/emptystates/milestonelightempty.svg';
import milestonedarkempty from '../../../../assets/images/emptystates/milestonedarkempty.svg';
import Button from '../../../../component/button';
import {
  deleteMilestone,
  getArchiveMilestoneList,
  getMilestoneList,
  updateMilestone
} from '../../../../services/projectServices';
import { useParams } from 'react-router-dom';
import ModalCustom from '../../../../component/models/modal';
import MilestoneModal from '../../../../component/models/milestoneModal';
import Milestoneloading from '../../../../component/loading/milestoneloading';
import { clearMilestoneInput, setMilestoneList } from '../../../../actions/projectActions';
import { isEmpty } from 'lodash';
import { clearCreateTaskInput } from '../../../../actions/taskActions';
import CreateTaskModal from '../../../../component/models/createTaskModal';
import { getTaskGroupList, updateTaskDetails } from '../../../../services/taskServices';

import { captureException } from '../../../../services/logService';
import { RootReducerInterface } from '../../../../interfaces/RootReducerInterface';
import { TaskDetailsInterface } from '../../../../interfaces/TaskInterface';
import { DragDropContext, Draggable, Droppable, DraggableProvided } from 'react-beautiful-dnd';
import EmptyState from '../../../../component/emptyState';
import MilestoneItem from './milestoneItem';

// eslint-disable-next-line @typescript-eslint/no-redeclare
declare type DropResult = typeof import('react-beautiful-dnd');
// eslint-disable-next-line @typescript-eslint/no-redeclare
declare type DraggableProvided = typeof import('react-beautiful-dnd');

const Milestone: React.FC = () => {
  // Refs
  const menuEditRef = useRef<React.RefObject<HTMLDivElement>[]>([]);
  const menuRestoreRef = useRef<React.RefObject<HTMLDivElement>[]>([]);

  // States
  const [deleteModelOpen, setDeleteModelOpen] = useState(false);
  const [archiveModelOpen, setArchiveModelOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isOpenMilestoneModal, setIsOpenMilestoneModal] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [open, setOpen] = useState(false);
  const [showArchived, setShowArchived] = useState(false);
  const [taskDeleteModelOpen, setTaskDeleteModelOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<TaskDetailsInterface>();
  const [deleteLoading, setDeleteLoading] = useState<boolean>();

  // use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { settings: settingsSelector, project: projectSelector, workspace: workspaceSelector } = stateSelector || {};
  const { themeMode } = settingsSelector;
  const { workspace } = workspaceSelector;
  const { milestoneList, archiveMilestoneList, currentProject } = projectSelector;

  // Other variables
  const dispatch = useDispatch();
  const params: { id: string } = useParams();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getImageSource = () => (themeMode?.theme === 'dark' ? milestonedarkempty : milestonelightempty);

  // load initial data
  const loadData = useCallback(async () => {
    try {
      if (!isEmpty(workspace?.id)) {
        setLoading(true);
        await dispatch(getMilestoneList(params?.id));
        await dispatch(getArchiveMilestoneList(params?.id));
        await dispatch(getTaskGroupList(currentProject?.id));
      }
    } catch (error) {
      captureException(error);
      console.log('error', error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [currentProject?.id, dispatch, params?.id, workspace?.id]);

  useEffect(() => {
    loadData();
    return () => {
      dispatch(setMilestoneList([]));
    };
  }, [dispatch, loadData]);

  // close delete modal
  const closeDeleteModal = useCallback(() => {
    setSelectedId('');
    setDeleteModelOpen(false);
  }, []);

  // close archive modal
  const closeArchiveModal = useCallback(() => {
    setSelectedId('');
    setArchiveModelOpen(false);
  }, []);

  // open milestone modal
  const openMilestoneModal = useCallback(() => {
    setIsOpenMilestoneModal(true);
  }, []);

  // close milestone modal
  const closeMilestoneModal = useCallback(() => {
    setIsOpenMilestoneModal(false);
    dispatch(clearMilestoneInput());
  }, [dispatch]);

  // delete milestone
  const onDeleteMilestone = useCallback(async () => {
    try {
      setButtonLoading(true);
      const result = await dispatch(deleteMilestone(selectedId));
      if (result) {
        closeDeleteModal();
        loadData();
      }
    } catch (error) {
      captureException(error);
      console.log('error', error);
    } finally {
      setButtonLoading(false);
    }
  }, [closeDeleteModal, dispatch, loadData, selectedId]);

  // archive milestone
  const onArchiveMilestone = useCallback(async () => {
    try {
      setButtonLoading(true);
      const payload = {
        milestoneId: selectedId,
        isArchived: true
      };
      const result = await dispatch(updateMilestone(payload));
      if (result) {
        closeArchiveModal();
        loadData();
      }
    } catch (error) {
      captureException(error);
    } finally {
      setButtonLoading(false);
    }
  }, [closeArchiveModal, dispatch, loadData, selectedId]);

  // fide oldest date
  const findOldestCreatedOnDate = useCallback((tasks: TaskDetailsInterface[]): string | null => {
    let oldestDate: string | null = null;
    tasks.forEach((task) => {
      if (!oldestDate || task?.createdOn < oldestDate) {
        oldestDate = task.createdOn;
      }
    });
    return oldestDate;
  }, []);

  // find newest date
  const findNewestDueDate = useCallback((tasks: TaskDetailsInterface[]): string | null => {
    let newestDate: string | null = null;
    tasks.forEach((task) => {
      if (!newestDate || task?.dueDate > newestDate) {
        newestDate = task.dueDate;
      }
    });
    return newestDate;
  }, []);

  // handle cancel
  const handleCancel = useCallback(() => {
    setOpen(false);
    setSelectedId('');
    dispatch(clearCreateTaskInput());
  }, [dispatch]);

  // logic for drag end
  const onDragEnd = useCallback(
    async (result: DropResult) => {
      if (!result?.destination) return;
      if (result?.source?.index !== result?.destination?.index) {
        const milestoneId = result?.draggableId;
        const selectedMilestone = milestoneList?.find((milestone) => milestone?.id === milestoneId);
        if (!selectedMilestone) return;
        const milestones = JSON.parse(JSON.stringify(milestoneList));
        const milestone = JSON.parse(JSON.stringify(selectedMilestone));

        let updatedTaskIndex;
        const newIndex = result.destination.index;
        if (newIndex === 0) {
          const nextTaskIndex = milestones[0]?.index;
          updatedTaskIndex = nextTaskIndex ? nextTaskIndex - 50 : new Date(milestone.createdOn).getTime();
        } else if (newIndex === milestones.length - 1) {
          const previousTaskIndex = milestones[milestones.length - 1]?.index;
          updatedTaskIndex = previousTaskIndex ? previousTaskIndex + 50 : new Date(milestone.createdOn).getTime();
        } else {
          if (newIndex < result.source.index) {
            const previousTaskIndex = milestones?.[newIndex - 1]?.index || 0;
            const nextTaskIndex = milestones?.[newIndex]?.index || 0;
            updatedTaskIndex = (previousTaskIndex + nextTaskIndex) / 2;
          } else {
            const previousTaskIndex = milestones?.[newIndex]?.index || 0;
            const nextTaskIndex = milestones?.[newIndex + 1]?.index || 0;
            updatedTaskIndex = (previousTaskIndex + nextTaskIndex) / 2;
          }
        }
        milestone.index = updatedTaskIndex;
        const updatedMilestones = milestoneList?.map((item) => {
          return item?.id === milestoneId ? milestone : item;
        });
        const sortedMilestone = updatedMilestones?.toSorted((a, b) => {
          return a?.index - b?.index;
        });
        dispatch(setMilestoneList(sortedMilestone));
        try {
          const payload = {
            milestoneId: milestoneId,
            index: updatedTaskIndex
          };
          dispatch(updateMilestone(payload, false));
        } catch (error) {
          captureException(error);
          console.log('Error', error);
        }
      }
    },
    [dispatch, milestoneList]
  );

  // handle toggle for archive milestone
  const handleToggle = useCallback(() => {
    setShowArchived((prevState) => !prevState);
  }, []);

  // close delete modal
  const onDeleteModalClose = useCallback(() => {
    setTaskDeleteModelOpen(false);
  }, []);

  // delete task
  const deleteTask = useCallback(
    async (task: TaskDetailsInterface) => {
      try {
        setDeleteLoading(true);
        const payloadTask = { isArchived: true, projectId: task?.projectId };
        const response = await dispatch(updateTaskDetails(task?.id, payloadTask));
        setTaskDeleteModelOpen(false);
        if (response) {
          loadData();
        }
      } catch (e) {
        captureException(e);
        console.log('ERROR', e);
      } finally {
        setDeleteLoading(false);
      }
    },
    [dispatch, loadData]
  );

  // render milestone list
  const renderMilestoneList = useMemo(() => {
    return (
      milestoneList?.length > 0 &&
      milestoneList?.map((item, milestoneIndex) => {
        if (!menuEditRef?.current[milestoneIndex]) {
          menuEditRef.current[milestoneIndex] = React.createRef<HTMLDivElement>();
        }
        const uncompletedTaskList = item?.tasks?.filter((item) => item?.status !== true);
        const newOldestDate = findOldestCreatedOnDate(item?.tasks);
        const newestDueDate = findNewestDueDate(item?.tasks);
        const completedTaskList = item?.tasks?.filter((item) => item?.status === true);
        return (
          <>
            <Draggable draggableId={item.id} index={milestoneIndex} key={item.id}>
              {(provided: DraggableProvided) => (
                <MilestoneItem
                  isMilestoneItem={true}
                  item={item}
                  loadData={loadData}
                  milestoneIndex={milestoneIndex}
                  newOldestDate={newOldestDate}
                  newestDueDate={newestDueDate}
                  completedTaskList={completedTaskList}
                  uncompletedTaskList={uncompletedTaskList}
                  closeArchiveModal={closeArchiveModal}
                  setOpen={setOpen}
                  setArchiveModelopen={setArchiveModelOpen}
                  setIsOpenMilestoneModal={setIsOpenMilestoneModal}
                  setSelectedItem={setSelectedItem}
                  setTaskDeleteModelopen={setTaskDeleteModelOpen}
                  setDeleteModelopen={setDeleteModelOpen}
                  setButtonLoading={setButtonLoading}
                  setSelectedId={setSelectedId}
                  selectedId={selectedId}
                  provided={provided}
                />
              )}
            </Draggable>
          </>
        );
      })
    );
  }, [closeArchiveModal, findNewestDueDate, findOldestCreatedOnDate, loadData, milestoneList, selectedId]);

  // render milestone loading component
  const renderMilestoneLoading = useMemo(() => {
    return loading && milestoneList?.length === 0 && <Milestoneloading />;
  }, [loading, milestoneList?.length]);

  // render empty state
  const renderEmptyState = useMemo(() => {
    return (
      !loading &&
      milestoneList?.length === 0 && (
        <Empty>
          <EmptyState
            image={getImageSource()}
            header='No milestones added yet'
            title='You can add New Milestone by clicking the button below'
            name='New Milestone'
            onButtonClick={openMilestoneModal}
          />
        </Empty>
      )
    );
  }, [getImageSource, loading, milestoneList?.length, openMilestoneModal]);

  // render archive milestone list
  const renderArchiveMilestoneList = useMemo(() => {
    return (
      archiveMilestoneList?.length > 0 && (
        <>
          {(!loading || milestoneList?.length > 0) && (
            <Buttons className={showArchived ? 'hide-text-button' : ''}>
              <Button
                title={showArchived ? 'Hide archived milestones' : 'Show archived milestones'}
                secondary={true}
                onClick={handleToggle}
              />
            </Buttons>
          )}

          <Group>
            {showArchived &&
              archiveMilestoneList?.map((item, milestoneIndex) => {
                if (!menuRestoreRef?.current[milestoneIndex]) {
                  menuRestoreRef.current[milestoneIndex] = React.createRef<HTMLDivElement>();
                }
                const newOldestDate = findOldestCreatedOnDate(item?.tasks);
                const newestDueDate = findNewestDueDate(item?.tasks);
                const completedTaskList = item?.tasks?.filter((item) => item?.status === true);
                const uncompletedTaskList = item?.tasks?.filter((item) => item?.status !== true);
                return (
                  <>
                    <MilestoneItem
                      isMilestoneItem={false}
                      item={item}
                      loadData={loadData}
                      milestoneIndex={milestoneIndex}
                      newOldestDate={newOldestDate}
                      newestDueDate={newestDueDate}
                      completedTaskList={completedTaskList}
                      uncompletedTaskList={uncompletedTaskList}
                      closeArchiveModal={closeArchiveModal}
                      setOpen={setOpen}
                      setArchiveModelopen={setArchiveModelOpen}
                      setIsOpenMilestoneModal={setIsOpenMilestoneModal}
                      setSelectedItem={setSelectedItem}
                      setTaskDeleteModelopen={setTaskDeleteModelOpen}
                      setDeleteModelopen={setDeleteModelOpen}
                      setButtonLoading={setButtonLoading}
                      setSelectedId={setSelectedId}
                      selectedId={selectedId}
                    />
                  </>
                );
              })}
          </Group>
        </>
      )
    );
  }, [
    archiveMilestoneList,
    closeArchiveModal,
    findNewestDueDate,
    findOldestCreatedOnDate,
    handleToggle,
    loadData,
    loading,
    milestoneList?.length,
    selectedId,
    showArchived
  ]);

  return (
    <>
      <AppLayout>
        <Accounting />
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='milestones-id'>
            {(provided: DraggableProvided) => (
              <Group ref={provided.innerRef} {...provided.droppableProps}>
                {renderMilestoneList}
                {renderMilestoneLoading}
                {renderEmptyState}
                {provided?.placeholder}
              </Group>
            )}
          </Droppable>
        </DragDropContext>

        {renderArchiveMilestoneList}
      </AppLayout>
      <ModalCustom width={462} open={isOpenMilestoneModal} onClose={closeMilestoneModal}>
        <MilestoneModal onClose={closeMilestoneModal} projectId={params?.id} />
      </ModalCustom>
      <ModalCustom open={deleteModelOpen} onClose={closeDeleteModal} width={334}>
        <Deletemodal
          onClose={closeDeleteModal}
          onOk={onDeleteMilestone}
          modaltitle='Delete Milestone?'
          description='Are you sure you want to delete this milestone?'
          loading={buttonLoading}
        />
      </ModalCustom>
      <ModalCustom open={archiveModelOpen} onClose={closeArchiveModal} width={334}>
        <Deletemodal
          onClose={closeArchiveModal}
          onOk={onArchiveMilestone}
          modaltitle='Archive Milestone?'
          description='Are you sure you want to archive this milestone?'
          isArchive={true}
          loading={buttonLoading}
        />
      </ModalCustom>
      <ModalCustom open={open} onClose={handleCancel} isCreateTask={true}>
        <CreateTaskModal loadData={loadData} onCancel={handleCancel} />
      </ModalCustom>
      <ModalCustom open={taskDeleteModelOpen} onClose={onDeleteModalClose} width={334}>
        <Deletemodal
          onClose={onDeleteModalClose}
          onOk={() => selectedItem && deleteTask(selectedItem)}
          loading={deleteLoading}
          modaltitle='Delete Task?'
          description='Are you sure you want to delete this task?'
        />
      </ModalCustom>
    </>
  );
};
export default Milestone;
