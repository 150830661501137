import React, { useState } from 'react';
import AppLayout from '../../component/appLayout';
import { BottomNavigation } from '../../component/bottomNavigation';
import Navbar from '../../component/navbar';

const ResponsiveNavbar: React.FC = () => {
  // State variable
  const [openContactUsModal, setOpenContactUsModal] = useState(false);

  return (
    <>
      <AppLayout>
        <Navbar setOpenContactUsModal={() => setOpenContactUsModal(!openContactUsModal)} />
      </AppLayout>
      {/* bottom navigation bar */}
      <BottomNavigation />
    </>
  );
};

export default ResponsiveNavbar;
