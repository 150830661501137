import styled from 'styled-components';

const Table = styled.div`
  background-color: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  width: 100%;
  max-width: 1022px;
  border-radius: 8px;
  .loading-animation {
    overflow: hidden;
  }

  .loading-animation::before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    animation: loading 1s infinite;
    background: linear-gradient(to right, transparent, var(--background-tertiary), transparent);
  }

  @keyframes loading {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;
const TableHeader = styled.div`
  padding: 12px;
  display: flex;
  justify-content: space-between;
  border-radius: 8px 8px 0px 0px;
  align-items: center;
  background-color: var(--background-tertiary);
  @media (max-width: 449px) {
    padding: 15px 12px;
    gap: 24px;
    justify-content: flex-start;
  }
`;
const UserName = styled.div`
@media (max-width: 449px){
  max-width: 170px;
  width: 100%;
}
@media (max-width: 375px){
  max-width: 140px;
}
`;
const Tabledata = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
    @media (max-width: 449px) {
   width: 100%;
   justify-content: space-between;
  }

`;
const TableDetail = styled.div`
  display: flex;
  align-items: center;
  padding: 11px 12px;
  border-top: 0.5px solid var(--border-primary);
  position: relative;
  .third {
    width: auto;
    padding-left: 37px;
  }
  .forth {
    width: auto;
    padding-left: 92px;
    align-items: end;
  }
  .number-last {
    padding-left: 26px;
  }
  @media (max-width: 449px) {
    padding: 13px 12px;
    gap: 20px;
    justify-content: space-between;
  }
`;
const Nameloading = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 400px;
  .third-top {
    width: 80px;
  }
  .third-bottom {
    width: 100px;
  }
  .forth-top {
    width: 60px;
  }
  .forth-bottom {
    width: 85px;
  }
  @media (max-width: 449px) {
    &.third {
      display: none;
    }
    &.forth {
      display: none;
    }
    gap: 10px;
    width: 100%;
    max-width: 170px;
  }
`;

const Top = styled.div`
  background-color: var(--background-muted);
  border-radius: 7px;
  width: 100px;
  height: 9px;
  @media (max-width: 449px) {
    height: 10px;
  }
`;
const Bottom = styled.div`
  background-color: var(--background-muted);
  border-radius: 7px;
  width: 120px;
  height: 6px;
  @media (max-width: 449px) {
    height: 8px;
  }
`;
const Numberloading = styled.div`
  display: flex;
  align-items: center;
  padding-left: 60px;
  .last {
    width: 70px;
  }
  @media (max-width: 449px) {
    max-width: 109px;
    width: 100%;
    &.number-last {
      display: none;
    }
    padding-left: 0;
  }
`;

const Middle = styled.div`
  background-color: var(--background-muted);
  border-radius: 7px;
  width: 80px;
  height: 6px;
  @media (max-width: 449px) {
    height: 10px;
    width: 78px;
  }
`;

const Last = styled.div`
  padding-left: 28px;
  .more-icon {
    fill: var(--background-muted);
    stroke: var(--background-muted);
  }
  @media (max-width: 449px){
    padding-left: 0;
  }
`;
const H1 = styled.div`
  background-color: var(--background-muted);
  border-radius: 7px;
  width: 84px;
  height: 9px;
  @media (max-width: 449px) {
    height: 10px;
  }
`;
const H2 = styled.div`
  background-color: var(--background-muted);
  border-radius: 7px;
  width: 80px;
  height: 9px;
  @media (max-width: 449px) {
    height: 10px;
    width: 78px;
  }
`;
const H3 = styled.div`
  background-color: var(--background-muted);
  border-radius: 7px;
  width: 60px;
  height: 9px;
`;
const H4 = styled.div`
  background-color: var(--background-muted);
  border-radius: 7px;
  width: 70px;
  height: 9px;
`;
const Div = styled.div`
  padding-left: 12px;
  border-left: 0.5px solid var(--border-primary);
  &.third-column {
    padding-left: 60px;
  }
  &.first-column {
    padding-right: 9px;
  }
  &.second-column {
    padding-right: 58px;
  }
  &.last-column{
    width: 18px;
    height: 18px;
    border-left: none;
  }
  @media (max-width: 449px) {
 
    padding-left: 10px;
    &.second-column {
      display: none;
    }
    &.third-column {
      display: none;
    }
    &.fourth-column {
      display: none;
    }
     &.last-column{
   padding-left: 0;
  }
  }
`;
export {
  Table,
  TableHeader,
  UserName,
  Tabledata,
  TableDetail,
  Nameloading,
  Top,
  Bottom,
  Numberloading,
  Middle,
  Last,
  H1,
  H2,
  H3,
  H4,
  Div
};
