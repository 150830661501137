import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import TiptapEditor from '../../tiptapEditor';
import { CommentFilesInterface } from '../../../interfaces/TaskInterface';
import { FileBox, Icon, IconSection, MessageInputSection, TextBox, Uploaddiv } from './styles';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import CommentDropdown from '../../dropdowns/commentDropdown/commentdropdown';
import { EmojiClickData } from 'emoji-picker-react';
import { UserInterface } from '../../../interfaces/chatMessageInterface';
import { useSelector, useDispatch } from 'react-redux';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import { ReplyMessageDataInterface } from '../../../interfaces/MessageInterface';
import { captureException } from '../../../services/logService';
import { setErrorMessage } from '../../../actions/messageActions';
import { handleFileUpload, isMediaRecordingSupported, removeFileFromFirebase } from '../../../helpers/common';
import Emoji from '../../emojiPicker/emojiPicker';
import RecordingSnapAttach from '../../recording';
import { RenderFilePreview } from '../../renderFilePreview';
import { Dropdown } from '../../Dropdown';

interface Props {
  valueContent: string;
  setValueContent: (value: string) => void;
  onClickSelectFile: () => void;
  isEveryoneMentionEnable?: boolean;
  users: UserInterface[];
  uploadedFiles: CommentFilesInterface[];
  setUploadedFiles: any;
  sendComment: (files: CommentFilesInterface[]) => void;
  replyMessage: any;
  setReplyMessage: (value: ReplyMessageDataInterface) => void;
  propsLoading?: boolean;
  setPropsLoading: (value: boolean) => void;
  isSelectFileEnable: boolean;
  isProjectDetail?: boolean;
  handleOpenSnapModal: () => void;
  handleOpenGifModal: () => void;
  isCalendarViewCard?: boolean;
  isAutoFocus?: boolean;
}

const MessagesInputBox = forwardRef((props: Props, ref) => {
  const {
    valueContent,
    setValueContent,
    onClickSelectFile,
    isEveryoneMentionEnable,
    users,
    setUploadedFiles,
    uploadedFiles,
    sendComment,
    replyMessage,
    propsLoading,
    setPropsLoading,
    setReplyMessage,
    isSelectFileEnable,
    isProjectDetail,
    handleOpenSnapModal,
    handleOpenGifModal,
    isAutoFocus = true
  } = props || {};
  // Refs
  const editorRef = useRef<any>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const emojiPickerRef = useRef<HTMLDivElement | null>(null);
  // States
  const [loading, setLoading] = useState(false);
  const [isEmojiDropdownOpen, setIsEmojiDropdownOpen] = useState(false);
  // use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { auth: authSelector, project: projectSelector } = stateSelector || {};
  const { uploadFilePercent } = projectSelector;
  const { userDetails } = authSelector;
  // Other variables
  const dispatch = useDispatch();
  const usersWithAll = [
    { id: 'everyone', name: 'Everyone', profile_photo: '', value: 'everyone', email: 'everyone' },
    ...(users?.filter((user) => user?.id !== userDetails?.id) || [])
  ];

  useImperativeHandle(ref, () => ({
    addRecordingCard(fileUrl: string, fileName: string) {
      editorRef.current.addRecordingCard(fileUrl, fileName);
    },
    addImageInContent(fileUrl: string) {
      editorRef.current.addImage(fileUrl);
    },
    addContent(value: string) {
      editorRef.current.addContent(value);
    },
    clearContent() {
      editorRef?.current?.clearValue();
    }
  }));

  const handleFileInput = useCallback(() => {
    queueMicrotask(() => {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    });
  }, []);

  const handleEmojiSelected = (emojiData: EmojiClickData) => {
    editorRef.current.addContentExistingLine(emojiData.emoji);
  };

  const openEmojiDropdown = useCallback((value: boolean) => {
    setIsEmojiDropdownOpen(value);
  }, []);

  const onSendComment = useCallback(() => {
    setUploadedFiles([]);
    setValueContent('');
    if (sendComment) sendComment(uploadedFiles);
  }, [sendComment, setUploadedFiles, setValueContent, uploadedFiles]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
      event.preventDefault(); // Prevent default behavior if needed
      onSendComment();
    }
  };

  const addDroppedFiles = useCallback(
    (files: CommentFilesInterface[]) => {
      setUploadedFiles((prevFiles: CommentFilesInterface[]) => [...(prevFiles || []), ...(files || [])]);
    },
    [setUploadedFiles]
  );

  const onFileUpload = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target;
      if (!files || files.length === 0) {
        dispatch(setErrorMessage('No files selected.'));
        return;
      }
      try {
        setLoading(true);
        const fileArray = Array.from(files); // Convert FileList to Array

        //Call common function to upload file in firebase
        const updatedFiles = await handleFileUpload(uploadedFiles || [], fileArray, dispatch, editorRef);
        const value = [...(uploadedFiles || []), ...(updatedFiles || [])];
        setUploadedFiles(value);
      } catch (error) {
        captureException(error);
        dispatch(setErrorMessage('Error occurred while uploading files.'));
      } finally {
        setLoading(false);
      }
    },
    [dispatch, uploadedFiles, editorRef, setUploadedFiles]
  );

  const onClickClose = useCallback(
    (index: number, file: CommentFilesInterface) => {
      if (file && !file.uploadFileType && file.href.startsWith('https://firebasestorage.googleapis'))
        removeFileFromFirebase(file.href, dispatch);
      const filesClone = JSON.parse(JSON.stringify(uploadedFiles));
      filesClone.splice(index, 1);
      setUploadedFiles(filesClone);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    },
    [dispatch, uploadedFiles, setUploadedFiles]
  );

  const uploadSnapEmbed = useCallback((firebaseUrl: string, fileName: string, videoId: string) => {
    editorRef?.current?.addRecordingCard(firebaseUrl, fileName, videoId);
  }, []);

  return (
    <>
      <MessageInputSection isProjectDetail={isProjectDetail}>
        <TextBox onKeyDown={handleKeyDown}>
          <TiptapEditor
            ref={editorRef}
            valueContent={valueContent}
            setValueContent={setValueContent}
            setLoading={(value: boolean) => {
              if (setPropsLoading) setPropsLoading(value);
              else setLoading(value);
            }}
            setStateLoading={(value: boolean) => setLoading(value)}
            addUploadedFiles={addDroppedFiles}
            replyMessage={replyMessage}
            setReplyMessage={setReplyMessage}
            placeHolder='Type a message'
            users={isEveryoneMentionEnable ? usersWithAll : users?.filter((user) => user?.id !== userDetails?.id)}
            isAutoFocus={isAutoFocus}
            recordSnapModalOpen={handleOpenSnapModal}
            handleFileInput={handleFileInput}
            handleGifModal={handleOpenGifModal}
          />
          {isMediaRecordingSupported() && (
            <RecordingSnapAttach
              setLoading={(value: boolean) => {
                if (setPropsLoading) setPropsLoading(value);
                else setLoading(value);
              }}
              uploadSnapEmbed={uploadSnapEmbed}
            />
          )}
          {loading && <Uploaddiv>Uploading ({Math.floor(uploadFilePercent)}%)</Uploaddiv>}
          <input type='file' ref={fileInputRef} style={{ display: 'none' }} onChange={onFileUpload} multiple />

          {uploadedFiles?.length > 0 && (
            <FileBox isApplyMargin={uploadedFiles?.length > 0 ? '32px' : '0'}>
              {uploadedFiles?.length > 0 &&
                uploadedFiles?.map((file: CommentFilesInterface, index: number) => {
                  return (
                    <>
                      <RenderFilePreview
                        key={file?.href}
                        file={file}
                        maxWidth={240}
                        isClose={true}
                        onClose={() => {
                          onClickClose(index, file);
                        }}
                      />
                    </>
                  );
                })}
            </FileBox>
          )}
        </TextBox>

        <IconSection>
          <Icon ref={emojiPickerRef} onClick={() => openEmojiDropdown(!isEmojiDropdownOpen)}>
            <SVGIcon name='message-emoji-icon' width='32' height='32' viewBox='0 0 32 32' className='fillColor' />
            {isEmojiDropdownOpen && (
              <Emoji
                isOpenEmoji={isEmojiDropdownOpen}
                onEmojiClick={handleEmojiSelected}
                setIsOpenEmoji={setIsEmojiDropdownOpen}
                emojiButtonref={emojiPickerRef}
              />
            )}
          </Icon>

          <Dropdown
            activeClassName='active'
            content={
              <Icon>
                <SVGIcon
                  name='message-attachment-icon'
                  width='32'
                  height='32'
                  viewBox='0 0 32 32'
                  className='attachment-fill-Color'
                />
              </Icon>
            }>
            <CommentDropdown
              onClickUploadFile={handleFileInput}
              onClickSelectFile={onClickSelectFile}
              isSelectFileEnable={isSelectFileEnable}
            />
          </Dropdown>
          <Icon onClick={onSendComment} disable={loading || propsLoading}>
            <SVGIcon name='send-discuss-icon' width='32' height='32' viewBox='0 0 32 32' className='fillColor' />
          </Icon>
        </IconSection>
      </MessageInputSection>
    </>
  );
});

export default MessagesInputBox;
