import styled from 'styled-components';
import { Typography } from '../../../global/theme';

const Maindiv = styled.div`
  display: flex;
  flex: auto;
  height: 100vh;
  overflow: hidden !important;
  .loading-animation {
    overflow: hidden;
  }
  .loading-animation::before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    animation: loading 1s infinite;
    background: linear-gradient(to right, transparent, var(--background-tertiary), transparent);
  }

  @keyframes loading {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  @media (max-width: 449px) {
    flex-direction: column;
    overflow: auto !important;
    scrollbar-width: none;
  }
`;

const Leftdiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 auto;
  padding: 24px 24px 0 0;
  max-width: 1022px;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 449px) {
    overflow: unset;
    padding: 24px 0 0;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;
const Profile = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const Name = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const First = styled.div`
  width: 87px;
  height: 10px;
  background-color: var(--background-muted);
  border-radius: 6px;
`;

const Desc = styled.div`
  width: 110px;
  height: 21px;
  background-color: var(--background-muted);
  border-radius: 6px;
`;
const Icon = styled.div`
  display: inline-flex;
  .large-more-icon {
    fill: var(--background-muted);
  }
  .more-icon {
    fill: var(--background-muted);
    stroke: var(--background-muted);
  }
`;

const Project = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Title = styled.div`
  color: var(--text-primary);
  ${Typography.heading_md}
`;

const Cards = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-gap: 17px;
  grid-template-columns: repeat(auto-fill, minmax(194px, 1fr));
  @media (max-width: 449px) {
    grid-row-gap: 0px;
    border: 0.5px solid var(--border-primary);
    border-radius: 12px;
    overflow: hidden;
  }
`;

const Firstcard = styled.div`
  border: 0.5px solid var(--border-primary);
  border-radius: 12px;
  max-width: 240px;
  width: 100%;
  background-color: var(--background-primary);
  @media (max-width: 449px) {
    max-width: 100%;
    width: auto;
    border: none;
    border-radius: 0;
    border-bottom: 0.5px solid var(--border-primary);
    :last-child {
      border-bottom: none;
    }
  }
`;

const Topcard = styled.div`
  padding: 16px 16px 17px;
  display: flex;
  flex-direction: column;
  gap: 39px;
  .bottom {
    width: 112px;
    height: 8px;
  }
  .last-bottom {
    width: 171px;
    height: 6px;
  }
  @media (max-width: 449px) {
    padding: 16px 16px 0;
    .last-bottom {
      display: none;
    }
  }
`;

const Detail = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Leftside = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
`;

const Right = styled.div`
  width: 57px;
  height: 21px;
  background-color: var(--background-muted);
  border-radius: 6px;
`;
const Bottomcard = styled.div`
  padding: 16px;
  display: flex;
  border-top: 0.5px solid var(--border-primary);
  @media (max-width: 449px) {
    border-top: none;
    padding: 12px 22px 16px;
  }
`;
const Circle = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 3px solid var(--background-primary);
  background: var(--background-muted);
  margin-left: -8px;
`;
const Files = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Filecards = styled.div`
  display: flex;
  gap: 14px;
  flex-wrap: wrap;
  @media (max-width: 449px) {
    gap: 8px;
  }
`;

const Firstfile = styled.div`
  border-radius: 12px;
  border: 0.5px solid var(--border-primary);
  background: var(--background-primary);
  max-width: 167px;
  width: 100%;
  @media (max-width: 449px) {
    max-width: calc(50% - 6px);
  }
`;
const Topfile = styled.div`
  padding: 28px 0;
  display: flex;
  justify-content: center;
  .fill-color {
    fill: var(--background-muted);
  }
`;

const Bottomfile = styled.div`
  padding: 11px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-top: 0.5px solid var(--border-primary);
  .top {
    width: 70px;
    height: 12px;
    border-radius: 8px;
  }
  .bottom {
    width: 120px;
    height: 8px;
    border-radius: 8px;
  }
`;

const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Link = styled.a`
  text-decoration: none;
  cursor: pointer;
  gap: 8px;
  color: var(--brand-primary);
  ${Typography.body_md_medium}
`;
const Invoice = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  @media (max-width: 449px) {
    display: none;
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  border-style: hidden;
  border-radius: 8px;
  box-shadow: 0 0 0 0.5px var(--border-primary);
  background: var(--background-primary);
  margin-left: 1px;
  margin-bottom: 24px;
  .row {
    padding: 0;
  }
  tr {
    border-top: 0.5px solid var(--border-primary);
    display: flex;
    align-items: center;
    padding: 10px 12px;
    justify-content: space-between;
  }
  thead {
    tr {
      border-top: none;
      background: var(--background-tertiary);
      border-radius: 8px 8px 0 0;
    }
  }
  th {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
    text-align: left;
    padding: 10px 0 10px 20px;

    .lineicon {
      stroke: var(--background-muted);
    }
    p {
      margin: 0;
      ${Typography.body_md_medium}
    }

    text-transform: uppercase;
    color: var(--text-secondary);
    :last-child {
      border-right: 0;
      width: 18px;
    }
    &:first-child {
      padding: 10px 0 10px 12px;
    }
  }
  td {
    width: 100%;
  }
  tr td:nth-last-child(3) {
    text-align: right;
  }
  tr th:nth-last-child(3) {
    justify-content: end;
  }
  tr td:nth-last-child(2) {
    width: 89%;
  }
  tr td:last-child {
    width: 18px;
  }
  .third {
    padding-right: 20px;
    width: 120px;
    text-align: right;
  }
  .first {
    width: 103px;
    height: 9px;
    border-radius: 7px;
  }
  .first-row {
    gap: 40px;
  }
`;

const Second = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  .top {
    width: 80px;
    height: 9px;
    border-radius: 7px;
  }
  .bottom {
    width: 100px;
    height: 6px;
    border-radius: 7px;
  }
`;
const Third = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-end;
  .top {
    width: 60px;
    height: 9px;
    border-radius: 7px;
  }
  .bottom {
    width: 85px;
    height: 6px;
    border-radius: 7px;
  }
`;
const Forth = styled.div`
  .top {
    width: 66px;
    height: 6px;
    border-radius: 7px;
  }
`;
const Rightdiv = styled.div`
  width: 100%;
  max-width: 268px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px 0 24px 16px;
  border-left: 0.5px solid var(--border-primary);
  .secondbox {
    padding: 10px 12px;
  }
  @media (max-width: 449px) {
    border-left: none;
    max-width: 100%;
    padding: 0 0 16px;
    margin-top: 16px;
  }
`;

const Topright = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Box = styled.div`
  padding: 12px;
  border: 0.5px solid var(--border-primary);
  background-color: var(--background-primary);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;
const Firstmember = styled.div`
  padding-bottom: 18px;
  border-bottom: 0.5px solid var(--border-primary);
  display: flex;
  flex-direction: column;
  gap: 7px;
  padding-top: 12px;
  :last-child {
    border-bottom: none;
    padding-bottom: 6px;
  }
  :first-child {
    padding-top: 0;
  }
`;
const Topmember = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Bottomember = styled.div`
  width: 150px;
  height: 6px;
  border-radius: 7px;
  background-color: var(--background-muted);
`;
const Lefttop = styled.div`
  width: 80px;
  height: 8px;
  border-radius: 7px;
  background-color: var(--background-muted);
`;
const Righttop = styled.div`
  display: flex;
  gap: 8px;
  .right {
    width: 49px;
    height: 19px;
    border-radius: 6px;
    background-color: var(--background-muted);
  }
`;
const Firstinfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-bottom: 0.5px solid var(--border-primary);
  padding: 15px 0;
  :last-child {
    border-bottom: none;
    padding-bottom: 5px;
  }
  :first-child {
    padding-top: 5px;
  }
`;

const Topinfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Bottominfo = styled.div`
  width: 74px;
  height: 6px;
  border-radius: 7px;
  background-color: var(--background-muted);
`;
const Leftinfo = styled.div`
  width: 136px;
  height: 8px;
  border-radius: 7px;
  background-color: var(--background-muted);
`;

const Rightinfo = styled.div`
  width: 44px;
  height: 8px;
  border-radius: 7px;
  background-color: var(--background-muted);
`;

export {
  Maindiv,
  Leftdiv,
  Header,
  Profile,
  Name,
  First,
  Desc,
  Icon,
  Project,
  Title,
  Cards,
  Firstcard,
  Topcard,
  Bottomcard,
  Detail,
  Leftside,
  Right,
  Circle,
  Files,
  Filecards,
  Firstfile,
  Topfile,
  Bottomfile,
  Heading,
  Link,
  Invoice,
  Table,
  Second,
  Third,
  Forth,
  Rightdiv,
  Topright,
  Box,
  Firstmember,
  Bottomember,
  Topmember,
  Lefttop,
  Righttop,
  Firstinfo,
  Topinfo,
  Bottominfo,
  Leftinfo,
  Rightinfo
};
