import AppLayout from '../../../../component/appLayout';
import {
  Bottomcontent,
  Content,
  Data,
  Datatext,
  Datatitle,
  Group,
  Header,
  Headertext,
  Icon,
  Leftside,
  LeftsideData,
  Removebutton,
  Logo,
  Main,
  RepositoryData,
  Subheader,
  Text,
  Title
} from './styles';
import SVGIcon from '../../../../assets/images/svg/SVGIcon';
import Button from '../../../../component/button';
import githublogo from '../../../../../src/assets/images/githublogo.svg';
import githubdarklogo from '../../../../../src/assets/images/githubdarklogo.svg';
import { useSelector, useDispatch } from 'react-redux';
import { RootReducerInterface } from '../../../../interfaces/RootReducerInterface';
import { useHistory } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { REACT_APP_GITHUB_APP_PUBLIC_URL } from '../../../../global/environment';
import { deleteGithubAccount, getAccountsList } from '../../../../services/githubServices';
import ModalCustom from '../../../../component/models/modal';
import Deletemodal from '../../../../component/models/deleteModel';
import { captureException } from '../../../../services/logService';

export default function GithubIntegration() {
  //States
  const [selectedModalId, setSelectedModalId] = useState<number | null>(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  //Use selector state variable
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { settings: settingsSelector, workspace: workspaceReducer } = stateSelector;
  const { themeMode, githubAccountList } = settingsSelector;
  const { workspace } = workspaceReducer;
  //Other variable
  const history = useHistory();
  const dispatch = useDispatch();

  const loadData = useCallback(() => {
    if (workspace?.id) {
      dispatch(getAccountsList());
    }
  }, [dispatch, workspace?.id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const Opendeletemodel = useCallback((id: number) => {
    setSelectedModalId(id);
  }, []);

  const onClose = useCallback(() => {
    setSelectedModalId(null);
  }, []);

  const handleBackClick = useCallback(() => {
    history.goBack();
  }, [history]);

  const onClickLinkAccount = useCallback(() => {
    const navigateUrl = `${REACT_APP_GITHUB_APP_PUBLIC_URL}/installations/select_target`;
    window.open(navigateUrl, '_blank');
  }, []);

  const getLastSegment = useCallback((url: string): string => {
    const segments = url.split('/');
    return segments.pop() || '';
  }, []);

  const removeGithubAccount = useCallback(
    async (accountId: number) => {
      try {
        if (!accountId) return;
        setDeleteLoading(true);
        const response = await dispatch(deleteGithubAccount(accountId));
        setSelectedModalId(null);
        if (response) {
          loadData();
        }
      } catch (error) {
        captureException(error);
        console.log('ERROR', error);
        return;
      } finally {
        setDeleteLoading(false);
      }
    },
    [dispatch, loadData]
  );

  return (
    <AppLayout>
      <Group>
        <Main>
          <Header>
            <Icon onClick={handleBackClick}>
              <SVGIcon name='left-arrow-icon' width='16' height='16' viewBox='0 0 16 16' className='arrow-icon' />
            </Icon>
            <Title>Integrations</Title>
          </Header>
        </Main>
        <Bottomcontent>
          <Subheader>
            <Leftside>
              <Logo>
                {themeMode?.theme === 'dark' ? (
                  <img src={githubdarklogo} alt='githu-logo' width={42} height={42} />
                ) : (
                  <img src={githublogo} alt='github-logo' width={42} height={42} />
                )}
              </Logo>
              <Content>
                <Text>Github</Text>
                <Headertext>Automate pull request and commit workflows with task sync.</Headertext>
              </Content>
            </Leftside>
            <Button title='Link Github Account' onClick={onClickLinkAccount} />
          </Subheader>
          <RepositoryData>
            {githubAccountList?.length > 0 &&
              githubAccountList?.map((item) => {
                const dataText = getLastSegment(item?.accountUrl);
                return (
                  <Data key={item?.id}>
                    <LeftsideData>
                      <Datatitle>{item?.account}</Datatitle>
                      <Datatext>{`/${dataText} - ${item?.totalRepositories} Repository`} </Datatext>
                    </LeftsideData>
                    <Removebutton onClick={() => Opendeletemodel(item?.installationId)}>Remove</Removebutton>
                  </Data>
                );
              })}
          </RepositoryData>
        </Bottomcontent>
        <ModalCustom open={selectedModalId !== null} onClose={onClose} width={334}>
          <Deletemodal
            onClose={onClose}
            onOk={() => removeGithubAccount(selectedModalId as number)}
            loading={deleteLoading}
            modaltitle=' Remove Github Account'
            description=' Are you sure you want to remove this account?'
          />
        </ModalCustom>
      </Group>
    </AppLayout>
  );
}
