import styled from 'styled-components';
import { Typography } from '../../global/theme';

const Main = styled.div`
  &.image {
    margin-top: 6px;
  }
  .ant-image .ant-image-mask:hover {
    background-color: var(--background-thumb-overlay);
  }
  .ant-image .ant-image-mask {
    border-radius: 8px;
  }
  .ant-image .ant-image-img {
    max-width: 300px;
    max-height: 300px;
    width: auto;
    height: auto;
  }
  .custom-mask-content {
    height: 28px !important;
  }
  .ant-image-img {
    object-fit: contain;
  }
  .ant-image {
    background-color: var(--border-primary) !important;
    border-radius: 4px;
  }
`;

const ScreenRecordMain = styled.div`
  .screen-recording-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .e-rte-video .e-video-inline {
    width: 355px !important;
  }
  .screen-recording-name {
    color: var(--text-primary);
    ${Typography.body_md_medium};
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    word-break: break-all;
  }
  .screen-recording-right-div {
    display: flex;
    gap: 12px;
  }
  .open-video-icon {
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
    :hover {
      background-color: var(--neutral-secondary);
      border-radius: 4px;
    }
  }
  .video-more-icon {
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
    :hover {
      background-color: var(--neutral-secondary);
      border-radius: 4px;
    }
  }
  .inbox-video {
    width: 86px;
    height: 72px;
    border-radius: 4px;
    border: 0.5px solid var(--border-primary);
  }
  .screen-recording-animation {
    width: 16px;
    height: 16px;
  }
  .screen-recording-more {
    width: 16px;
    height: 16px;
  }
  .inbox-image-container {
    position: relative;
    width: 88px;
    height: 74px;
    img {
      height: 100%;
      margin: 0 auto;
      object-fit: contain;
    }
  }
  .youtube-preview-image {
    border-radius: 4px;
    border: 0.5px solid var(--border-primary);
  }
  .video-icon {
    position: absolute;
    top: 45%;
    left: 45%;
    cursor: pointer;
  }
  .video-preview {
    border-radius: 8px;
    padding: 6px 0;
    max-width: 483px;
    width: 100%;
    display: flex;
    gap: 12px;
    flex-direction: row-reverse;
    justify-content: flex-end;
    background-color: var(--background-primary);
    overflow: hidden;
  }
  .header {
    display: flex;
    gap: 6px;
    align-items: center;
  }
  .screen-recording-text {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .button {
    ${Typography.body_sm_medium};
    color: var(--text-primary);
    border: 0.5px solid var(--border-primary);
    background-color: var(--background-primary);
    border-radius: 36px;
    padding: 5px 15px;
    max-width: 61px;
    width: 100%;
    :hover {
      background-color: var(--background-secondary);
    }
  }
`;

const Figma = styled.div`
  pointer-events: all !important;
  padding: 5px 16px;
  width: fit-content;
  background-color: var(--background-primary);
  color: var(--text-primary);
  border: 0.5px solid var(--border-primary);
  cursor: pointer;
  text-decoration: none;
  border-radius: 36px;
  ${Typography.body_sm_medium};
  white-space: nowrap;
  &:hover {
    background-color: var(--background-secondary);
    border-radius: 36px;
  }
`;

const Printscreen = styled.div`
  width: fit-content;
  border-radius: 8px;
  padding: 6px 0;
  background-color: var(--background-primary);
  .prnt-screen-header {
    display: flex;
    gap: 2px;
    margin: 0;
  }
  .prnt-screen-text {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .prnt-url-link {
    ${Typography.body_sm_medium}
    text-decoration: underline;
    padding: 0;
    color: var(--text-link);
    :hover {
      background-color: transparent;
    }
  }
  .prnt-screen-box {
    display: flex;
    flex-direction: row-reverse;
    gap: 12px;
    align-items: center;
    margin: 0;
    @media (max-width: 320px) {
      flex-direction: column-reverse;
      align-items: flex-start;
    }
  }
  .ant-image-img {
    object-fit: contain;
  }
  .ant-image {
    background-color: var(--border-primary) !important;
    border-radius: 4px;
  }
  .figma-image {
    width: 86px;
    height: 72px;
    display: flex;
    border: 0.5px solid var(--border-primary);
  }
  .ant-image .ant-image-mask {
    border-radius: 6px;
  }
  .ant-image {
    margin: 0;
  }
  .figma-image-container {
    margin: 0 auto;
    width: 88px;
    height: 74px;
    @media (max-width: 320px) {
      margin: 0;
    }
  }
  .logo {
    width: 20px;
    height: 20px;
  }
  .header {
    display: flex;
    gap: 6px;
    align-items: center;
  }
  .prnt-screen-title {
    color: var(--text-primary);
    ${Typography.body_md_medium};
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
  .buttons {
    display: flex;
    gap: 12px;
    @media (max-width: 449px) {
      flex-direction: column;
      width: max-content;
    }
  }
  .button {
    ${Typography.body_sm_medium};
    color: var(--text-primary);
    border: 0.5px solid var(--border-primary);
    background-color: var(--background-primary);
    border-radius: 36px;
    padding: 5px 15px;
    :hover {
      background-color: var(--background-secondary);
      border-radius: 36px !important;
    }
  }
  a {
    text-decoration: none;
  }
  .custom-mask-content {
    height: 28px;
  }
`;
const SvgDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .img-cleanshot {
    width: 20px;
    height: 20px;
  }
`;

export { Main, ScreenRecordMain, Printscreen, Figma, SvgDiv };
