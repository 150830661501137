import React from 'react';
import { Bottom, First, FirstLine, Line, Main, SecondLine, Top } from './styles';
import SVGIcon from '../../../assets/images/svg/SVGIcon';

export default function Fileloading() {
  return (
    <div>
      <Main>
        <First>
          <Top>
            <SVGIcon name='file-loading-icon' width='60' height='60' viewBox='0 0 60 60' className='fill-color' />
          </Top>
          <Line></Line>
          <Bottom>
            <FirstLine className='loading-animation' />
            <SecondLine className='loading-animation' />
          </Bottom>
        </First>
        <First>
          <Top>
            <SVGIcon name='document-loading-icon' width='60' height='60' viewBox='0 0 60 60' className='fill-color' />
          </Top>
          <Line></Line>
          <Bottom>
            <FirstLine className='loading-animation' />
            <SecondLine className='loading-animation' />
          </Bottom>
        </First>
        <First>
          <Top>
            <SVGIcon name='image-loading-icon' width='60' height='60' viewBox='0 0 60 60' className='fill-color' />
          </Top>
          <Line></Line>
          <Bottom>
            <FirstLine className='loading-animation' />
            <SecondLine className='loading-animation' />
          </Bottom>
        </First>
        <First>
          <Top>
            <SVGIcon name='font-loading-icon' width='60' height='60' viewBox='0 0 60 60' className='fill-color' />
          </Top>
          <Line></Line>
          <Bottom>
            <FirstLine className='loading-animation' />
            <SecondLine className='loading-animation' />
          </Bottom>
        </First>
        <First>
          <Top>
            <SVGIcon name='pdf-loading-icon' width='60' height='60' viewBox='0 0 60 60' className='fill-color' />
          </Top>
          <Line></Line>
          <Bottom>
            <FirstLine className='loading-animation' />
            <SecondLine className='loading-animation' />
          </Bottom>
        </First>
      </Main>
    </div>
  );
}
