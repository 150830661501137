import styled from 'styled-components';
import { Typography } from '../../../../global/theme';

const Group = styled.div`
  max-width: 662px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 24px;
`;
const Main = styled.div`
  padding: 20px 0 12px;
  margin: 0 auto;
`;
const Header = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  margin: 7px 0;
`;
const Title = styled.div`
  ${Typography.body_lg}
  color: var(--text-secondary);
`;
const Icon = styled.div`
  width: 18px;
  height: 18px;
  cursor: pointer;
  .arrow-icon {
    width: 18px;
    height: 18px;
    path {
      stroke: var(--text-primary);
    }
  }
  :hover {
    background-color: var(--neutral-secondary);

    border-radius: 4px;
  }
`;
const Bottomcontent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Subheader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
`;
const Leftside = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;
const Logo = styled.div`
  width: 42px;
  height: 42px;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const Text = styled.div`
  ${Typography.heading_lg};
  color: var(--text-primary);
`;

const Headertext = styled.div`
  ${Typography.body_md};
  color: var(--text-secondary);
`;
const RepositoryData = styled.div`
  border-radius: 8px;
  border: 0.5px solid var(--border-primary);
  background-color: var(--background-primary);
  display: flex;
  flex-direction: column;
`;
const Data = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  border-bottom: 0.5px solid var(--border-primary);
  padding: 12px 16px;
  :last-child {
    border-bottom: none;
  }
`;
const LeftsideData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;
const Datatitle = styled.h2`
  ${Typography.body_lg_medium};
  color: var(--text-primary);
  margin: 0;
`;

const Datatext = styled.p`
  ${Typography.body_md};
  color: var(--text-secondary);
  margin: 0;
`;
const Removebutton = styled.div`
  ${Typography.body_md_medium};
  color: var(--brand-primary);
  cursor: pointer;
`;

export {
  Group,
  Main,
  Header,
  Title,
  Icon,
  Bottomcontent,
  Subheader,
  Leftside,
  Logo,
  Content,
  Headertext,
  Text,
  RepositoryData,
  Data,
  LeftsideData,
  Datatext,
  Datatitle,
  Removebutton
};
