import { Image } from 'antd';
import { CenterLine, CustomPreviewIcon, LeftPreview, Main, SvgDiv } from './style';
import SVGIcon from '../../assets/images/svg/SVGIcon';
import { useDispatch } from 'react-redux';
import { setErrorMessage, setSuccessMessage } from '../../actions/messageActions';
import { useCallback } from 'react';
import { getAPIErrorReason } from '../../helpers/common';

interface Props {
  imageUrl: string;
  altText: string;
  isInbox?: boolean;
  isMargin?: boolean;
  isImage?: boolean;
}

export const RenderImagePreview = (props: Props) => {
  //props variables
  const { imageUrl, altText, isInbox, isMargin, isImage } = props || {};
  // Other variables
  const dispatch = useDispatch();

  // Get file name from firebase URL
  const getFilename = (url: string) => {
    const decodedUrl = decodeURIComponent(url); // Decode %20 and other URL-encoded characters
    const parts = decodedUrl.split('/'); // Split by '/'
    const fullFilename = parts?.pop()?.split('?')[0]; // Get the last part and remove query params
    const nameWithoutSuffix = fullFilename?.replace(/_(\d+)(\.\w+)$/, '$2');
    return nameWithoutSuffix;
  };

  //   On Click Image DownLoad
  const handleDownloadImage = useCallback(
    async (e: React.SyntheticEvent) => {
      try {
        e.stopPropagation();
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = getFilename(imageUrl) || 'downloaded_image.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // Revoke the Blob URL to free up memory
        URL.revokeObjectURL(blobUrl);
      } catch (error) {
        dispatch(setErrorMessage(getAPIErrorReason(error) || 'Something went wrong!'));
      }
    },
    [dispatch, imageUrl]
  );

  // On Click Image Copy to Clipboard
  const handleCopyImage = useCallback(
    async (e: React.SyntheticEvent) => {
      try {
        e.stopPropagation();
        const response = await fetch(imageUrl);
        const blob = await response.blob();

        // Convert JPEG to PNG
        const imageBitmap = await createImageBitmap(blob);
        const canvas = document.createElement('canvas');
        canvas.width = imageBitmap.width;
        canvas.height = imageBitmap.height;
        const ctx = canvas.getContext('2d');

        if (ctx) {
          ctx.drawImage(imageBitmap, 0, 0);
          const pngBlob = await new Promise<Blob>((resolve) =>
            canvas.toBlob((blob) => blob && resolve(blob), 'image/png')
          );

          const clipboardItem = new ClipboardItem({ 'image/png': pngBlob });
          await navigator.clipboard.write([clipboardItem]);
          dispatch(setSuccessMessage('Image copied to clipboard!'));
        }
      } catch (error) {
        dispatch(setErrorMessage(getAPIErrorReason(error)));
      }
    },
    [dispatch, imageUrl]
  );

  return (
    <Main isMargin={isMargin} onClick={(e) => e.stopPropagation()} isImage={isImage}>
      <Image
        key={imageUrl}
        src={imageUrl}
        alt={altText || ''}
        style={{ borderRadius: '8px' }}
        preview={{
          closeIcon: (
            <>
              <CustomPreviewIcon>
                <LeftPreview>
                  <SvgDiv
                    onClick={(e) => {
                      handleDownloadImage(e);
                    }}>
                    <SVGIcon name='download-icon' width='18' height='18' viewBox='0 0 18 18' />
                  </SvgDiv>
                  <SvgDiv
                    onClick={(e) => {
                      handleCopyImage(e);
                    }}>
                    <SVGIcon name='copy-image-icon' width='18' height='18' viewBox='0 0 18 18' />
                  </SvgDiv>
                </LeftPreview>
                <CenterLine />
                <SvgDiv>
                  <SVGIcon name='close-preview-icon' width='18' height='18' viewBox='0 0 18 18' />
                </SvgDiv>
              </CustomPreviewIcon>
            </>
          ),
          mask: (
            <div className='custom-mask-content'>
              <SVGIcon
                name='preview-icon'
                width={isInbox ? '28' : '48'}
                height={isInbox ? '28' : '48'}
                viewBox='0 0 48 48'
              />
            </div>
          )
        }}
        onClick={(e) => e.stopPropagation()}
      />
    </Main>
  );
};
