import styled, { css } from 'styled-components';
import { Typography } from '../../global/theme';

interface Props {
  isActive: boolean;
}
const Maincontent = styled.div`
  position: sticky;
  top: 0;
  z-index: 99;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 24px;
  background-color: var(--background-secondary);
  margin: 0 -24px 24px -24px;
  border-bottom: 0.5px solid var(--border-primary);
  gap: 30px;
`;

const Left = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
`;
const Projectname = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;
const Icon = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  .arrow-icon {
    stroke: var(--text-secondary);
    :hover {
      background: var(--neutral-secondary);
      border-radius: 4px;
    }
  }
`;

const Taskbutton = styled.div<Props>`
  background-color: transparent;
  ${Typography.body_md_medium}
  color: var(--text-secondary);
  padding: 6px 15px;
  border: 0.5px solid transparent;
  border-radius: 16px;
  cursor: pointer;

  :hover {
    border: 0.5px solid var(--border-primary);
    box-shadow: var(--shadow-card);
    border-radius: 16px;
  }

  ${(props) =>
    props.isActive &&
    css`
      background-color: var(--background-primary);
      border: 0.5px solid var(--border-primary);
      box-shadow: var(--shadow-card);
      border-radius: 16px;
      padding: 6px 15px;
      ${Typography.body_md_medium}
      color: var(--text-primary);
    `}
`;
const Buttons = styled.div`
  display: flex;
  gap: 5px;
`;

const Right = styled.div`
  display: flex;
  gap: 12px;
`;
const LastIcon = styled.div<{ disabled: boolean }>`
  width: 32px;
  height: 32px;
  cursor: pointer;
  .circle {
    fill: var(--brand-primary);
  }
  ${(props: { disabled: boolean }) =>
    props.disabled &&
    css`
      pointer-events: none;
    `}
`;

const ProjectTitle = styled.h1`
  ${Typography.heading_lg}
  color: var(--text-primary);
  margin: 0;
`;

export { Maincontent, Content, Left, Projectname, Icon, Taskbutton, Buttons, Right, LastIcon, ProjectTitle };
