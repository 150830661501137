/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Command } from 'cmdk';
import { Emptystate, Header, Icon, Main, PopUp, Tab } from './vercelstyle';
import { useSelector } from 'react-redux';
import { ProjectDetailInterface } from '../../interfaces/ProjectInterface';
import { useHistory } from 'react-router-dom';
import { isEmpty, isMediaRecordingSupported } from '../../helpers/common';
import { UsersWorkspaceInterface } from '../../interfaces/WorkspaceInterface';
import SVGIcon from '../../assets/images/svg/SVGIcon';
import Button from '../button';
import { TABS_COMMAND_K, TABS_COMMAND_K_ID } from '../../global/constants';
import Avatar from '../avatar/Avatar';
import itemdarkempty from '../../assets/images/emptystates/searchmodaldarkempty.svg';
import itemempty from '../../assets/images/emptystates/searchmodallightempty.svg';
import RecordItem from './recordItem';
import CmdkItem from './cmdkItem';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';
import EmptyState from '../emptyState';
import { setUserModalDetail } from '../../actions/appActions';
import { useDispatch } from 'react-redux';

export function VercelCMDK({
  closePopup,
  openProjectModal,
  openTaskModal,
  openSnapModal,
  openUserModal,
  setOpenContactUsModal,
  isSearchScreen,
  searchValue = ''
}: {
  closePopup: Function;
  openProjectModal: Function;
  openTaskModal: Function;
  openSnapModal: Function;
  openUserModal: Function;
  setOpenContactUsModal: () => void;
  isSearchScreen?: boolean;
  searchValue?: string;
}) {
  //Refs
  const ref = useRef<HTMLDivElement | null>(null);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  //States
  const [currentTab, setCurrentTab] = useState(TABS_COMMAND_K_ID.ALL);
  const [inputValue, setInputValue] = useState('');
  const [filteredList, setFilteredList] = useState<ProjectDetailInterface[]>([]);
  const [filterTeamsList, setFilterTeamsList] = useState<UsersWorkspaceInterface[]>([]);
  const [pages, setPages] = useState<string[]>(['home']);
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { settings: settingsSelector, project: projectSelector, workspace: workspaceSelector } = stateSelector || {};
  const { list } = projectSelector;
  const { workspace } = workspaceSelector;
  const { themeMode } = settingsSelector;

  //Other variable
  const activePage = pages[pages.length - 1];
  const isHome = activePage === 'home';
  const history = useHistory();
  const getImageSource = () => (themeMode?.theme === 'dark' ? itemdarkempty : itemempty);
  const dispatch = useDispatch();

  const popPage = React.useCallback(() => {
    setPages((pages) => {
      const x = [...pages];
      x.splice(-1, 1);
      return x;
    });
  }, []);

  // const onKeyDown = React.useCallback(
  //   (e: KeyboardEvent) => {
  //     if (isHome || inputValue.length) {
  //       return;
  //     }

  //     if (e.key === 'Backspace') {
  //       e.preventDefault();
  //       popPage();
  //     }
  //   },
  //   [inputValue.length, isHome, popPage]
  // );

  function bounce() {
    if (ref.current) {
      ref.current.style.transform = 'scale(0.96)';
      setTimeout(() => {
        if (ref.current) {
          ref.current.style.transform = '';
        }
      }, 100);

      setInputValue('');
    }
  }

  const onChangeSearchValue = useCallback(
    (value: string) => {
      setInputValue(value);
      const searchText = value.trim().toLowerCase();
      const filteredProjects: ProjectDetailInterface[] = list?.filter((x) =>
        x?.name?.trim().toLowerCase().includes(searchText)
      );
      const filterMembers: UsersWorkspaceInterface[] = workspace?.users?.filter((y) =>
        y?.name?.trim().toLowerCase().includes(searchText)
      );
      setFilteredList(filteredProjects);
      setFilterTeamsList(filterMembers);
    },
    [list, workspace?.users]
  );

  useEffect(() => {
    if (searchValue) {
      onChangeSearchValue(searchValue);
    }
  }, [searchValue]);

  const onSelectProject = (project: ProjectDetailInterface) => {
    history.push(`/projects/details/${project?.id}/tasks`);
    setInputValue('');
    closePopup();
  };

  const onSelectMember = (member: UsersWorkspaceInterface) => {
    dispatch(setUserModalDetail(member));
    setInputValue('');
    closePopup();
    openUserModal();
  };

  const onclickHelp = useCallback(() => {
    const newTab = window.open(`https://www.teamcamp.app/help`, '_blank');
    if (newTab) {
      newTab.focus();
      closePopup();
    } else {
      alert('Please allow pop-ups for this site to open in a new tab.');
    }
  }, [closePopup]);

  const handleContactSupport = useCallback(() => {
    // Open contact us modal
    setOpenContactUsModal();
    closePopup();
  }, [closePopup]);

  const onClickCreateProject = useCallback(() => {
    openProjectModal();
    setInputValue('');
    closePopup();
  }, []);

  const onClickSnap = useCallback(() => {
    history.push('/snap');
    setInputValue('');
    closePopup();
  }, []);

  const onClickRecurringTask = useCallback(() => {
    history.push('/recurring-tasks');
    setInputValue('');
    closePopup();
  }, []);

  const onClickCreateTask = useCallback(() => {
    openTaskModal();
    setInputValue('');
    closePopup();
  }, []);

  const onClickNavigateSetting = () => {
    history.push('/settings/personal');
    setInputValue('');
    closePopup();
  };

  const scrollToUp = () => {
    messagesEndRef.current?.scrollIntoView({
      inline: 'nearest'
    });
  };

  useEffect(() => {
    scrollToUp();
  }, [inputValue]);

  const homeRender = useMemo(() => {
    const projectList = isEmpty(inputValue) && isEmpty(searchValue) ? list : filteredList;
    const peopleList = isEmpty(inputValue) && isEmpty(searchValue) ? workspace?.users : filterTeamsList;
    return (
      <>
        <div ref={messagesEndRef} />
        {(currentTab === TABS_COMMAND_K_ID.ALL || currentTab === TABS_COMMAND_K_ID.PROJECTS) &&
          projectList?.length > 0 && (
            <Command.Group heading='Projects'>
              <Projects list={projectList} onSelectProject={onSelectProject} />
            </Command.Group>
          )}
        {(currentTab === TABS_COMMAND_K_ID.ALL || currentTab === TABS_COMMAND_K_ID.PEOPLE) &&
          peopleList?.length > 0 && (
            <Command.Group heading='People'>
              <Teams list={peopleList} onSelectMember={onSelectMember} />
            </Command.Group>
          )}
        {currentTab === TABS_COMMAND_K_ID.ALL && (
          <Command.Group heading='Quick actions'>
            {(isEmpty(inputValue) ||
              'Create new task'.trim().toLowerCase().includes(inputValue.trim().toLowerCase())) && (
              <CmdkItem shortcut='⇧ T' onSelect={onClickCreateTask}>
                <PlusIcon />
                Create new task
              </CmdkItem>
            )}
            {workspace?.isAdmin &&
              (isEmpty(inputValue) ||
                'Create new project'.trim().toLowerCase().includes(inputValue.trim().toLowerCase())) && (
                <CmdkItem shortcut='⇧ P' onSelect={onClickCreateProject}>
                  <PlusIcon />
                  Create new project
                </CmdkItem>
              )}
            {(isEmpty(inputValue) || 'Snap'.trim().toLowerCase().includes(inputValue.trim().toLowerCase())) && (
              <CmdkItem onSelect={onClickSnap}>
                <RecordingSnapIcon />
                Snap
              </CmdkItem>
            )}
            {isMediaRecordingSupported() &&
              (isEmpty(inputValue) || 'Record Snap'.trim().toLowerCase().includes(inputValue.trim().toLowerCase())) && (
                <RecordItem openSnapModal={openSnapModal} closePopup={closePopup} setInputValue={setInputValue} />
              )}
            {(isEmpty(inputValue) ||
              'Navigating to setting'.trim().toLowerCase().includes(inputValue.trim().toLowerCase())) && (
              <CmdkItem shortcut='⇧ S' onSelect={onClickNavigateSetting}>
                <NavigationIcon />
                Navigating to setting
              </CmdkItem>
            )}
            {(isEmpty(inputValue) ||
              'Recurring Task'.trim().toLowerCase().includes(inputValue.trim().toLowerCase())) && (
              <CmdkItem onSelect={onClickRecurringTask}>
                <RecurringTaskIcon />
                Recurring Task
              </CmdkItem>
            )}
          </Command.Group>
        )}
        {currentTab === TABS_COMMAND_K_ID.ALL && (
          <Command.Group heading='Help'>
            <CmdkItem
              shortcut='⇧ D'
              onSelect={() => {
                onclickHelp();
              }}>
              <DocsIcon />
              Search Docs...
            </CmdkItem>
            <CmdkItem
              onSelect={() => {
                handleContactSupport();
              }}>
              <FeedbackIcon />
              Send Feedback...
            </CmdkItem>
            <CmdkItem
              onSelect={() => {
                handleContactSupport();
              }}>
              <ContactIcon />
              Contact Support
            </CmdkItem>
          </Command.Group>
        )}
      </>
    );
  }, [workspace?.users, currentTab, filterTeamsList, inputValue, list, filteredList]);

  return (
    <Main>
      <PopUp isSearchScreen={isSearchScreen}>
        <div className='vercel'>
          <Command
            ref={ref}
            shouldFilter={false}
            onKeyDown={(e: React.KeyboardEvent) => {
              if (e.key === 'Enter') {
                bounce();
              }

              if (isHome || inputValue.length) {
                return;
              }

              if (e.key === 'Backspace') {
                e.preventDefault();
                popPage();
                bounce();
              }
            }}>
            {/* <div>
              {pages.map((p) => (
                <div key={p} cmdk-vercel-badge=''>
                  {p}
                </div>
              ))}
            </div> */}
            <Header isSearchScreen={isSearchScreen}>
              <Command.Input
                autoFocus
                placeholder='Search for projects, people ,tasks, files...'
                // setting
                // placeholder='Search for setting...'
                value={inputValue}
                onValueChange={(value) => {
                  onChangeSearchValue(value);
                }}
              />
              <Icon onClick={() => closePopup()}>
                <SVGIcon name='close-icon' width='18' height='18' viewBox='0 0 18 18' className='close-icon' />
              </Icon>
            </Header>
            <Tab>
              {Object.entries(TABS_COMMAND_K).map(([key, value]) => (
                <Button
                  onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                    e.stopPropagation();
                    setCurrentTab(Number(key));
                  }}
                  key={key}
                  title={value}
                  isSearchModelbtn
                  isActive={Number(key) === currentTab}
                />
              ))}
            </Tab>
            {/* {isEmpty(inputValue) ? ( */}
            <Command.List>
              <Command.Empty>
                <Emptystate>
                  <EmptyState
                    title='No result found'
                    hideButton={true}
                    isgap={7}
                    image={getImageSource()}
                    isSearchmodalEmpty={true}
                  />
                </Emptystate>
              </Command.Empty>
              {/* <Home
                  currentTab={currentTab}
                  searchProjects={onClickSearchProject}
                  searchTeams={onClickSearchTeams}
                  onclickHelp={onclickHelp}
                  onclickTalkToUs={onclickTalkToUs}
                  onRecordSnap={onRecordSnap}
                  onSelectMember={onSelectMember}
                /> */}
              {/* <HomeRender /> */}
              {homeRender}
            </Command.List>
            {/* ) : (
              <Command.List>
                <Command.Group heading='Projects'>
                  {filteredList?.map((item: any) => {
                    return <Item onSelect={() => onSelectProject(item)}>{item?.name}</Item>;
                  })}
                </Command.Group>
                <Command.Group heading='People'>
                  <Teams list={filterTeamsList} onSelectMember={onSelectMember} />
                </Command.Group>
                {'Record Snap'.trim().toLowerCase().includes(inputValue.trim().toLowerCase()) && (
                  <Command.Group heading='Recording'>
                    <Item onSelect={onRecordSnap}>Record Snap</Item>
                  </Command.Group>
                )}
              </Command.List>
            )} */}
          </Command>
        </div>
      </PopUp>
    </Main>
  );
}

function Projects({ onSelectProject, list }: { onSelectProject: Function; list: ProjectDetailInterface[] }) {
  return (
    <>
      {list?.map((project) => (
        <CmdkItem key={project?.id} onSelect={() => onSelectProject(project)}>
          <ProjectsIcon />
          {project?.name}
        </CmdkItem>
      ))}
    </>
  );
}

function Teams({ onSelectMember, list }: { onSelectMember: Function; list: UsersWorkspaceInterface[] }) {
  return (
    <>
      {list?.map((member) => (
        <CmdkItem key={member?.id} onSelect={() => onSelectMember(member)}>
          <Avatar
            imgSrc={member?.profile_photo}
            name={member?.name ? member.name : 'U N'}
            size={18}
            isNotBorder
            classname='search-profile'
          />
          {member?.name}
        </CmdkItem>
      ))}
    </>
  );
}

function ProjectsIcon() {
  return (
    <svg
      fill='none'
      height='24'
      shapeRendering='geometricPrecision'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      width='24'>
      <path d='M3 3h7v7H3z'></path>
      <path d='M14 3h7v7h-7z'></path>
      <path d='M14 14h7v7h-7z'></path>
      <path d='M3 14h7v7H3z'></path>
    </svg>
  );
}

function PlusIcon() {
  return (
    <svg
      fill='none'
      height='24'
      shapeRendering='geometricPrecision'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      width='24'>
      <path d='M12 5v14'></path>
      <path d='M5 12h14'></path>
    </svg>
  );
}

// function TeamsIcon() {
//   return (
//     <svg
//       fill='none'
//       height='24'
//       shapeRendering='geometricPrecision'
//       stroke='currentColor'
//       strokeLinecap='round'
//       strokeLinejoin='round'
//       strokeWidth='1.5'
//       viewBox='0 0 24 24'
//       width='24'>
//       <path d='M17 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2'></path>
//       <circle cx='9' cy='7' r='4'></circle>
//       <path d='M23 21v-2a4 4 0 00-3-3.87'></path>
//       <path d='M16 3.13a4 4 0 010 7.75'></path>
//     </svg>
//   );
// }

function DocsIcon() {
  return (
    <svg
      fill='none'
      height='24'
      shapeRendering='geometricPrecision'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      width='24'>
      <path d='M14 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V8z'></path>
      <path d='M14 2v6h6'></path>
      <path d='M16 13H8'></path>
      <path d='M16 17H8'></path>
      <path d='M10 9H8'></path>
    </svg>
  );
}

function FeedbackIcon() {
  return (
    <svg
      fill='none'
      height='24'
      shapeRendering='geometricPrecision'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      width='24'>
      <path d='M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z'></path>
    </svg>
  );
}

function ContactIcon() {
  return (
    <svg
      fill='none'
      height='24'
      shapeRendering='geometricPrecision'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.5'
      viewBox='0 0 24 24'
      width='24'>
      <path d='M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z'></path>
      <path d='M22 6l-10 7L2 6'></path>
    </svg>
  );
}
function NavigationIcon() {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M10.798 14.7575L14.5571 4.61558C14.8329 3.87152 14.1083 3.1469 13.3643 3.42268L3.21772 7.1846C2.36261 7.50203 2.43017 8.73286 3.31396 8.95497L7.88564 10.1034L9.02671 14.6603C9.24881 15.545 10.4806 15.6126 10.798 14.7575V14.7575Z'
        stroke='currentColor'
        stroke-width='1.2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}
function RecurringTaskIcon() {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M15.7529 7.49943L12.862 4.60848C11.8304 3.57688 10.4312 2.9974 8.97236 2.99756V2.99756C6.7234 2.99772 4.70107 4.36694 3.86573 6.455L3.74793 6.74912'
        stroke='currentColor'
        stroke-width='1.2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M12.7517 7.4993H15.7529V4.49805'
        stroke='currentColor'
        stroke-width='1.2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M2.24793 10.5005L5.13888 13.3914C6.17045 14.423 7.56962 15.0025 9.0285 15.0024V15.0024C11.2775 15.0022 13.2998 13.633 14.1351 11.5449L14.2529 11.2508'
        stroke='currentColor'
        stroke-width='1.2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M5.24805 10.501H2.24805V13.501'
        stroke='currentColor'
        stroke-width='1.2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
}
function RecordingSnapIcon() {
  return (
    <svg stroke='currentColor' width='18' height='18' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'>
      <path
        stroke=''
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='1.18'
        d='m8.206 6.793 2.911 1.722a.562.562 0 0 1 0 .969l-2.911 1.722a.563.563 0 0 1-.85-.485V7.278c0-.436.475-.707.85-.485v0Z'
        clip-rule='evenodd'
        fill='none'></path>
      <path
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='1.18'
        d='M13.5 15.75h-9a2.25 2.25 0 0 1-2.25-2.25v-9A2.25 2.25 0 0 1 4.5 2.25h9a2.25 2.25 0 0 1 2.25 2.25v9a2.25 2.25 0 0 1-2.25 2.25Z'
        clip-rule='evenodd'
        fill='none'></path>
    </svg>
  );
}
// function FileDocIcon() {
//   return (
//     <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
//       <path
//         d='M14.4629 5.60742V15.5332C14.4629 16.0664 14.0293 16.5 13.4961 16.5H4.56641C4.0332 16.5 3.59961 16.0664 3.59961 15.5332V2.4668C3.59961 1.93359 4.0332 1.5 4.56641 1.5H10.3555L14.4629 5.60742Z'
//         fill='#518FF5'
//       />
//       <path
//         d='M6.25391 9.03223H11.8086V9.65039H6.25391V9.03223ZM6.25391 10.4092H11.8086V11.0273H6.25391V10.4092ZM6.25391 11.7891H11.8086V12.4072H6.25391V11.7891ZM6.25391 13.166H10.2061V13.7842H6.25391V13.166Z'
//         fill='white'
//       />
//       <path opacity='0.19' d='M10.915 5.45215L14.4629 8.32617V5.625L12.4531 4.45898L10.915 5.45215Z' fill='black' />
//       <path
//         d='M14.4629 5.60742H11.3223C10.7891 5.60742 10.3555 5.17383 10.3555 4.64062V1.5L14.4629 5.60742Z'
//         fill='#A6C5FA'
//       />
//     </svg>
//   );
// }
// function FileZipIcon() {
//   return (
//     <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
//       <path
//         d='M15.328 3.70312H7.2186C7.17446 3.70315 7.13122 3.71565 7.09387 3.73917C7.05651 3.76269 7.02656 3.79628 7.00746 3.83608C6.98835 3.87588 6.98088 3.92026 6.98589 3.96412C6.9909 4.00797 7.0082 4.04952 7.03579 4.08398L8.06704 5.37305C8.17645 5.51043 8.31549 5.62131 8.47377 5.69741C8.63205 5.7735 8.80548 5.81285 8.9811 5.8125H16.2655C16.3276 5.8125 16.3873 5.78781 16.4312 5.74385C16.4752 5.6999 16.4999 5.64028 16.4999 5.57812V4.875C16.4995 4.56431 16.3759 4.26646 16.1562 4.04677C15.9365 3.82708 15.6387 3.7035 15.328 3.70312Z'
//         fill='#FFB125'
//       />
//       <path
//         d='M16.5 5.34375H8.98125C8.87601 5.34373 8.77212 5.32004 8.67726 5.27444C8.58241 5.22884 8.49903 5.16249 8.43328 5.08031L7.26094 3.615C7.10767 3.4228 6.91299 3.26767 6.69141 3.16119C6.46984 3.0547 6.22708 2.9996 5.98125 3H2.67188C2.36107 3 2.063 3.12347 1.84323 3.34324C1.62347 3.56301 1.5 3.86108 1.5 4.17188L1.5 13.5469C1.5 13.8577 1.62347 14.1557 1.84323 14.3755C2.063 14.5953 2.36107 14.7188 2.67188 14.7188H15.3281C15.6389 14.7188 15.937 14.5953 16.1568 14.3755C16.3765 14.1557 16.5 13.8577 16.5 13.5469V5.34375Z'
//         fill='#FCD354'
//       />
//       <path
//         d='M5.35176 11.444V10.2547C5.35176 10.1261 5.24997 10.0243 5.12141 10.0243H4.47855C4.34998 10.0243 4.24819 10.1261 4.24819 10.2547V11.444L3.88927 12.494C3.78747 12.7886 3.8357 13.1154 4.01783 13.3672C4.19462 13.619 4.48925 13.769 4.79998 13.769C5.1107 13.769 5.39998 13.619 5.58212 13.3672C5.76426 13.1154 5.81248 12.7887 5.71069 12.494L5.35176 11.444ZM5.20177 13.094C5.01964 13.3565 4.58034 13.3565 4.39285 13.094C4.30179 12.9654 4.27499 12.794 4.32856 12.644L4.44643 12.2904H5.1482L5.27142 12.644C5.31964 12.794 5.29819 12.9654 5.20177 13.094ZM3.375 3.63865V4.29223C3.375 4.4208 3.47679 4.52259 3.60536 4.52259H4.47857V5.4976H3.60536C3.47679 5.4976 3.375 5.59939 3.375 5.72796V6.23152C3.375 6.36009 3.47679 6.46723 3.60536 6.46723H4.47857V7.43687H3.60536C3.47679 7.43687 3.375 7.53866 3.375 7.66723V8.17615C3.375 8.30471 3.47679 8.40651 3.60536 8.40651H4.47857V9.14578C4.47857 9.27435 4.58571 9.37614 4.71428 9.37614H5.99464C6.11786 9.37614 6.225 9.27435 6.225 9.14578V8.63686C6.225 8.51365 6.11786 8.40651 5.99464 8.40651H5.11608V7.43687H5.99464C6.11786 7.43687 6.225 7.33508 6.225 7.20651V6.69759C6.225 6.56903 6.11786 6.46723 5.99464 6.46723H5.11608V5.4976H5.99464C6.11786 5.4976 6.225 5.39046 6.225 5.26189V4.75832C6.225 4.62976 6.11786 4.52261 5.99464 4.52261H5.11608V3.6333L3.375 3.63865Z'
//         fill='white'
//       />
//     </svg>
//   );
// }
// function SettingIcon() {
//   return (
//     <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
//       <path
//         fill-rule='evenodd'
//         clip-rule='evenodd'
//         d='M11.6627 2.33897H11.6627C12.2817 2.54527 12.6991 3.12457 12.6989 3.77698V3.81802C12.6987 4.64233 13.3574 5.31563 14.1815 5.33345L14.2702 5.33537C14.8323 5.34752 15.3414 5.66986 15.5928 6.17272V6.17272C15.8846 6.75626 15.7701 7.46105 15.3087 7.92225L15.2796 7.95125C14.6966 8.53397 14.6863 9.47586 15.2564 10.0712L15.3177 10.1352C15.7066 10.5412 15.8387 11.1292 15.6609 11.6625V11.6625C15.4546 12.2815 14.8753 12.6989 14.2229 12.6987H14.1819C13.3576 12.6985 12.6843 13.3572 12.6665 14.1813L12.6645 14.27C12.6524 14.832 12.33 15.3412 11.8272 15.5926V15.5926C11.2436 15.8844 10.5389 15.7699 10.0777 15.3085L10.0487 15.2794C9.46594 14.6964 8.52404 14.6861 7.9287 15.2562L7.8647 15.3175C7.45867 15.7064 6.87073 15.8385 6.33738 15.6607V15.6607C5.71844 15.4544 5.30102 14.8751 5.3012 14.2227V14.1817C5.30142 13.3574 4.6427 12.6841 3.81858 12.6663L3.72994 12.6643C3.16786 12.6522 2.65871 12.3298 2.40729 11.827V11.827C2.11552 11.2435 2.22996 10.5387 2.69137 10.0775L2.7204 10.0485C3.30344 9.46577 3.31375 8.52389 2.74362 7.92854L2.68228 7.86448C2.29343 7.45844 2.16134 6.87051 2.33911 6.33717V6.33717C2.54542 5.71823 3.12472 5.30081 3.77714 5.30098H3.81816C4.64247 5.30121 5.31577 4.64249 5.33359 3.81837L5.33551 3.72972C5.34766 3.16766 5.66998 2.65852 6.17282 2.4071V2.4071C6.75636 2.11532 7.46115 2.22979 7.92235 2.69125L7.95133 2.72025C8.53406 3.30331 9.47597 3.31361 10.0713 2.74344L10.1353 2.68216C10.5413 2.29327 11.1293 2.16116 11.6627 2.33897Z'
//         stroke='currentColor'
//         stroke-width='1.125'
//         stroke-linecap='round'
//         stroke-linejoin='round'
//       />
//       <path
//         fill-rule='evenodd'
//         clip-rule='evenodd'
//         d='M8.99999 7.68652C8.27468 7.68652 7.68671 8.2745 7.68671 8.9998C7.68671 9.72511 8.27468 10.3131 8.99999 10.3131C9.72529 10.3131 10.3133 9.72511 10.3133 8.9998C10.3133 8.2745 9.72529 7.68652 8.99999 7.68652ZM6.56171 8.9998C6.56171 7.65318 7.65336 6.56152 8.99999 6.56152C10.3466 6.56152 11.4383 7.65318 11.4383 8.9998C11.4383 10.3464 10.3466 11.4381 8.99999 11.4381C7.65336 11.4381 6.56171 10.3464 6.56171 8.9998Z'
//         fill='currentColor'
//       />
//     </svg>
//   );
// }
