import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import AppLayout from '../../../component/appLayout';
import {
  Bottomdata,
  Check,
  Createitem,
  Dropbox,
  Dropheader,
  Inputarea,
  Table,
  TaskDone,
  TaskPending
} from '../styles/style';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProjectTasks } from '../../../services/taskServices';
import moment from 'moment';
import { ProjectDetailInterface } from '../../../interfaces/ProjectInterface';
import { getProjectList } from '../../../services/projectServices';
import { setFilteredReportTaskDetails } from '../../../actions/reportActions';
import { isEmpty } from '../../../helpers/common';
import TasksCacheService from '../../../services/tasksCatchServices';
import {
  BackIcon,
  Button,
  Container,
  Contentheading,
  DropdownButton,
  Empty,
  Header,
  HeaderLeft,
  Leftcontent,
  TableSection
} from '../styles';
import EmptyState from '../../../component/emptyState';
import { TaskReportDateList, TaskReportStatusList } from '../../../global/row';
import reportdarkempty from '../../../assets/images/emptystates/reportdarkempty.svg';
import reportempty from '../../../assets/images/emptystates/report-empty.svg';
import { ActivityListInterface, UserCheckListInterface } from '../../../interfaces/ReportInterface';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import { useHistory } from 'react-router-dom';

const Task: React.FC = () => {
  // States
  const [searchResult, setSearchResult] = useState<UserCheckListInterface[]>([]);
  const [searchProjectResult, setSearchProjectResult] = useState<ProjectDetailInterface[]>([]);
  const [isSearch, setIsSearch] = useState(false);
  const [isProjectSearch, setIsProjectSearch] = useState(false);
  const [searchUser, setSearchUser] = useState('');
  const [searchProject, setSearchProject] = useState('');
  const [filterData, setFilterData]: any = useState();
  const [usersList, setUsersList] = useState<UserCheckListInterface[]>([]);
  const [projectList, setProjectList] = useState<ProjectDetailInterface[]>([]);
  const [selectAllUser, setSelectAllUser] = useState(true);
  const [selectAllProject, setSelectAllProject] = useState(true);
  const [isProjectDropdownOpen, setIsProjectDropdownOpen] = useState(false);
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
  const [isStatusDropdownOpen, setIsStatusDropdownOpen] = useState(false);
  const [isDateDropdownOpen, setIsDateDropdownOpen] = useState(false);
  const [selectAllStatus, setSelectAllStatus] = useState(true);
  const [statusList, setStatusList] = useState<ActivityListInterface[]>([]);
  const [selectAllDate, setSelectAllDate] = useState(true);
  const [dateList, setDateList] = useState<ActivityListInterface[]>([]);
  //Use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { report: reportSelector, workspace: workspaceSelector, settings: settingsSelector } = stateSelector;
  const { filteredReportTask, loading } = reportSelector;
  const { themeMode } = settingsSelector;
  const { workspace } = workspaceSelector;
  const { id: workspace_id } = workspace;
  //Other variables
  const history = useHistory();
  const getImageSource = () => (themeMode?.theme === 'dark' ? reportdarkempty : reportempty);
  const dispatch = useDispatch();

  const loadData = useCallback(async () => {
    if (!isEmpty(workspace_id)) {
      await dispatch(getAllProjectTasks());
      let projectList = await dispatch(getProjectList());
      projectList = projectList?.map((item: ProjectDetailInterface) => {
        return { ...item, checked: true };
      });
      setProjectList(projectList);
      const local_updated_data = await TasksCacheService.getInstance()?.getFilteredTasks({});
      dispatch(setFilteredReportTaskDetails(local_updated_data));
      const usersList = workspace?.users?.map((ele) => {
        return { ...ele, checked: true };
      });
      setUsersList(usersList);
      const statusList = TaskReportStatusList?.map((ele: ActivityListInterface) => {
        return { ...ele, checked: true };
      });
      setStatusList(statusList);
      const timeList = TaskReportDateList?.map((ele) => {
        return { ...ele, checked: true };
      });
      setDateList(timeList);
    }
  }, [dispatch, workspace?.users, workspace_id]);

  const applyFilters = useCallback(
    async (key: string, item: any) => {
      let filterList = filterData || {};
      if (key === 'project') {
        if (item === null) {
          filterList = { ...filterList, project: null, users: null };
        } else {
          filterList = { ...filterList, project: item, users: null };
        }
      } else if (key === 'status') {
        filterList = { ...filterList, status: item };
      } else if (key === 'dueDate') {
        filterList = {
          ...filterList,
          dueDate: item
        };
      } else if (key === 'assign') {
        filterList = { ...filterList, users: item };
      }
      setFilterData(filterList);
      const local_updated_data = await TasksCacheService.getInstance()?.getFilteredTasks(filterList);
      dispatch(setFilteredReportTaskDetails(local_updated_data));
    },
    [dispatch, filterData]
  );

  const onChangeAssign = useCallback(
    async (item: string, value: boolean) => {
      let updatedUsersList: UserCheckListInterface[];
      if (value) {
        updatedUsersList = usersList?.map((ele) => {
          return ele?.id === item ? { ...ele, checked: true } : ele;
        });
      } else {
        updatedUsersList = usersList?.map((ele) => {
          return ele?.id === item ? { ...ele, checked: false } : ele;
        });
      }
      const isAllUsersSelected = updatedUsersList.every((user) => user.checked);
      setSelectAllUser(isAllUsersSelected);
      const filterUser = updatedUsersList?.filter((item) => item?.checked);
      const usersFilter = filterUser?.map((item) => {
        return item?.id;
      });
      setUsersList(updatedUsersList);
      applyFilters('assign', usersFilter);
    },
    [usersList, applyFilters]
  );

  useEffect(() => {
    loadData();
    return () => {
      setSearchResult([]);
      setIsSearch(false);
      setSearchUser('');
      setSearchProject('');
      setIsProjectSearch(false);
      setSearchProjectResult([]);
    };
  }, [loadData]);

  const searchQuery = useCallback(
    (value: string) => {
      if (!isEmpty(value)) {
        const result = usersList?.filter((item) => item?.name?.toLowerCase().includes(value?.toLowerCase())) || [];
        if (result) {
          setSearchResult(result);
          return null;
        }
      } else {
        setSearchResult([]);
        setIsSearch(false);
      }
    },
    [usersList]
  );

  const searchProjectQuery = useCallback(
    (value: string) => {
      if (!isEmpty(value)) {
        const result = projectList?.filter((item) => item?.name?.toLowerCase().includes(value?.toLowerCase())) || [];
        if (result) {
          setSearchProjectResult(result);
          return null;
        }
      } else {
        setSearchProjectResult([]);
        setIsProjectSearch(false);
      }
    },
    [projectList]
  );

  const onChangeSearchUser = useCallback(
    (e: { target: { value: string } }) => {
      const value = e.target.value;
      setSearchUser(value);
      let timeout;
      if (value) {
        if (!isSearch) setIsSearch(true);
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
          searchQuery(value);
        }, 300);
      } else {
        if (isSearch) setIsSearch(false);
        setSearchResult([]);
      }
    },
    [isSearch, searchQuery]
  );

  //project list actions

  const onChangeSearchProject = useCallback(
    (e: { target: { value: string } }) => {
      const value = e.target.value;
      setSearchProject(value);
      let timeout;
      if (value) {
        if (!isProjectSearch) setIsProjectSearch(true);
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
          searchProjectQuery(value);
        }, 300);
      } else {
        if (isProjectSearch) setIsProjectSearch(false);
        setSearchProjectResult([]);
      }
    },
    [isProjectSearch, searchProjectQuery]
  );

  const onSelectAllProject = useCallback(
    async (value: boolean) => {
      setSelectAllProject(value);
      let filterProjects;
      const updatedProjectsList = projectList?.map((ele) => {
        return { ...ele, checked: value };
      });
      if (value) {
        filterProjects = null;
      } else {
        filterProjects = [];
      }
      setProjectList(updatedProjectsList);
      applyFilters('project', filterProjects);
    },
    [projectList, applyFilters]
  );

  const onChangeProject = useCallback(
    async (item: string, value: boolean) => {
      let updatedUsersList: ProjectDetailInterface[];
      if (value) {
        updatedUsersList = projectList?.map((ele) => {
          return ele?.id === item ? { ...ele, checked: true } : ele;
        });
      } else {
        updatedUsersList = projectList?.map((ele) => {
          return ele?.id === item ? { ...ele, checked: false } : ele;
        });
      }
      const isAllUsersSelected = updatedUsersList.every((user) => user.checked);
      setSelectAllProject(isAllUsersSelected);
      const filterUser = updatedUsersList?.filter((item) => item?.checked);
      const usersFilter = filterUser?.map((item) => {
        return item?.id;
      });
      setProjectList(updatedUsersList);
      applyFilters('project', usersFilter);
    },
    [applyFilters, projectList]
  );

  const onChangeSelectAll = useCallback(
    async (value: boolean) => {
      setSelectAllUser(value);
      let filterUsers;
      const updatedUsersList = usersList?.map((ele) => {
        return { ...ele, checked: value };
      });
      if (value) {
        filterUsers = null;
      } else {
        filterUsers = [];
      }
      setUsersList(updatedUsersList);
      applyFilters('assign', filterUsers);
    },
    [usersList, applyFilters]
  );

  const renderUserData = useMemo(() => {
    return (
      <div className='dropdown-content'>
        <Dropbox>
          <div className='menuStyle'>
            <Dropheader>
              <Inputarea type='text' placeholder='Assign to ...' value={searchUser} onChange={onChangeSearchUser} />
            </Dropheader>
            <Bottomdata>
              {isEmpty(searchUser) && (
                <Createitem onClick={() => onChangeSelectAll(!selectAllUser)}>
                  <Check>
                    <input type='checkbox' className='checkbox-round' checked={selectAllUser} />
                  </Check>
                  <p>Select All</p>
                </Createitem>
              )}
              {(isEmpty(searchUser) ? usersList : searchResult)?.map(
                (item: { checked: boolean; id: string; name: string }) => {
                  return (
                    <Createitem key={item?.id} onClick={(e) => onChangeAssign(item?.id, !item?.checked)}>
                      <Check>
                        <input type='checkbox' className='checkbox-round' checked={item?.checked} />
                      </Check>
                      <p>{item?.name}</p>
                    </Createitem>
                  );
                }
              )}
            </Bottomdata>
          </div>
        </Dropbox>
      </div>
    );
  }, [selectAllUser, onChangeAssign, onChangeSearchUser, onChangeSelectAll, searchUser, searchResult, usersList]);

  const openProjectDropdown = useCallback((value: boolean) => {
    setIsProjectDropdownOpen(value);
    setIsUserDropdownOpen(false);
    setIsStatusDropdownOpen(false);
    setIsDateDropdownOpen(false);
  }, []);

  const openUserDropdown = useCallback((value: boolean) => {
    setIsUserDropdownOpen(value);
    setIsProjectDropdownOpen(false);
    setIsStatusDropdownOpen(false);
    setIsDateDropdownOpen(false);
  }, []);

  const openStatusDropdown = useCallback((value: boolean) => {
    setIsStatusDropdownOpen(value);
    setIsProjectDropdownOpen(false);
    setIsUserDropdownOpen(false);
    setIsDateDropdownOpen(false);
  }, []);

  const openDateDropdown = useCallback((value: boolean) => {
    setIsDateDropdownOpen(value);
    setIsProjectDropdownOpen(false);
    setIsUserDropdownOpen(false);
    setIsStatusDropdownOpen(false);
  }, []);

  const renderProjectData = useMemo(() => {
    return (
      // eslint-disable-next-line react/jsx-filename-extension
      <div className='dropdown-content'>
        <Dropbox>
          <div className='menuStyle'>
            <Dropheader>
              <Inputarea type='text' placeholder='Search...' value={searchProject} onChange={onChangeSearchProject} />
            </Dropheader>
            <Bottomdata>
              {isEmpty(searchProject) && (
                <Createitem onClick={() => onSelectAllProject(!selectAllProject)}>
                  <Check>
                    <input type='checkbox' className='checkbox-round' checked={selectAllProject} />
                  </Check>
                  <p>Select All</p>
                </Createitem>
              )}
              {(isEmpty(searchProject) ? projectList : searchProjectResult)?.map((item) => {
                return (
                  <Createitem key={item?.id} onClick={(e) => onChangeProject(item?.id, !item?.checked)}>
                    <Check>
                      <input type='checkbox' className='checkbox-round' checked={item?.checked} />
                    </Check>
                    <p>{item?.name}</p>
                  </Createitem>
                );
              })}
            </Bottomdata>
          </div>
        </Dropbox>
      </div>
    );
  }, [
    searchProject,
    onChangeSearchProject,
    selectAllProject,
    projectList,
    searchProjectResult,
    onSelectAllProject,
    onChangeProject
  ]);

  const onSelectAllStatus = useCallback(
    async (value: boolean) => {
      setSelectAllStatus(value);
      let filterStatus;
      const updatedStatusList = statusList?.map((ele) => {
        return { ...ele, checked: value };
      });
      if (value) {
        filterStatus = null;
      } else {
        filterStatus = [];
      }
      setStatusList(updatedStatusList);
      applyFilters('status', filterStatus);
    },
    [statusList, applyFilters]
  );

  const onChangeStatus = useCallback(
    async (item: number, value: boolean) => {
      let updatedStatusList: ActivityListInterface[];
      updatedStatusList = statusList?.map((ele) => {
        return ele?.key === item ? { ...ele, checked: value } : ele;
      });
      const isAllStatusSelected = updatedStatusList.every((user) => user.checked);
      setSelectAllStatus(isAllStatusSelected);
      const filterUser = updatedStatusList?.filter((item) => item?.checked);
      const usersFilter = filterUser?.map((item) => {
        return item?.key;
      });
      setStatusList(updatedStatusList);
      applyFilters('status', usersFilter);
    },
    [applyFilters, statusList]
  );

  const onSelectAllDate = useCallback(
    async (value: boolean) => {
      setSelectAllDate(value);
      let filterDate;
      const updatedDateList = dateList?.map((ele) => {
        return { ...ele, checked: value };
      });
      if (value) {
        filterDate = null;
      } else {
        filterDate = [];
      }
      setDateList(updatedDateList);
      applyFilters('dueDate', filterDate);
    },
    [dateList, applyFilters]
  );

  const onChangeDate = useCallback(
    async (item: number, value: boolean) => {
      let updatedDateList: ActivityListInterface[];
      updatedDateList = dateList?.map((ele) => {
        return ele?.key === item ? { ...ele, checked: value } : ele;
      });
      const isAllStatusSelected = updatedDateList.every((user) => user.checked);
      setSelectAllDate(isAllStatusSelected);
      const filterUser = updatedDateList?.filter((item) => item?.checked);
      const usersFilter = filterUser?.map((item) => {
        return item?.key;
      });
      setDateList(updatedDateList);
      applyFilters('dueDate', usersFilter);
    },
    [applyFilters, dateList]
  );

  return (
    <>
      <AppLayout>
        <Header>
          {/* back Icon for responsive ui  */}
          <HeaderLeft>
            <BackIcon onClick={() => history.goBack()}>
              <SVGIcon name='report-left-arrow-icon' width='24' height='24' viewBox='24' />
            </BackIcon>
            Tasks
          </HeaderLeft>
        </Header>
        <Container>
          <Contentheading>
            <Leftcontent>
              <DropdownButton>
                <div onClick={() => openProjectDropdown(!isProjectDropdownOpen)}>
                  <Button placeholder='By Project'>
                    <p>Project</p>
                    <SVGIcon
                      name='dropdown-icon'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      className={isProjectDropdownOpen ? 'dropdown-icon stroke-color' : 'stroke-color'}
                    />
                  </Button>
                </div>
                {isProjectDropdownOpen && renderProjectData}
              </DropdownButton>

              <DropdownButton>
                <div onClick={() => openUserDropdown(!isUserDropdownOpen)}>
                  <Button>
                    <p>Assigned</p>
                    <SVGIcon
                      name='dropdown-icon'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      className={isUserDropdownOpen ? 'dropdown-icon stroke-color' : 'stroke-color'}
                    />
                  </Button>
                </div>
                {isUserDropdownOpen && renderUserData}
              </DropdownButton>
              <DropdownButton>
                <div onClick={() => openStatusDropdown(!isStatusDropdownOpen)}>
                  <Button>
                    <p>Status</p>
                    <SVGIcon
                      name='dropdown-icon'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      className={isStatusDropdownOpen ? 'dropdown-icon stroke-color' : 'stroke-color'}
                    />
                  </Button>
                </div>
                {isStatusDropdownOpen && (
                  <div className='dropdown-content'>
                    <Dropbox>
                      <div className='menuStyle'>
                        <Bottomdata isborderredius>
                          <Createitem onClick={() => onSelectAllStatus(!selectAllStatus)}>
                            <Check>
                              <input type='checkbox' className='checkbox-round' checked={selectAllStatus} />
                            </Check>
                            <p>Select All</p>
                          </Createitem>
                          {statusList?.map((item) => {
                            return (
                              <Createitem key={item?.key} onClick={(e) => onChangeStatus(item?.key, !item?.checked)}>
                                <Check>
                                  <input type='checkbox' className='checkbox-round' checked={item?.checked} />
                                </Check>
                                <p>{item?.label}</p>
                              </Createitem>
                            );
                          })}
                        </Bottomdata>
                      </div>
                    </Dropbox>
                  </div>
                )}
              </DropdownButton>
              <DropdownButton>
                <div onClick={() => openDateDropdown(!isDateDropdownOpen)}>
                  <Button>
                    <p>Due Date</p>
                    <SVGIcon
                      name='dropdown-icon'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      className={isDateDropdownOpen ? 'dropdown-icon stroke-color' : 'stroke-color'}
                    />
                  </Button>
                </div>
                {isDateDropdownOpen && (
                  <div className='dropdown-content'>
                    <Dropbox>
                      <div className='menuStyle'>
                        <Bottomdata isborderredius>
                          <Createitem onClick={() => onSelectAllDate(!selectAllDate)}>
                            <Check>
                              <input type='checkbox' className='checkbox-round' checked={selectAllDate} />
                            </Check>
                            <p>Select All</p>
                          </Createitem>
                          {dateList?.map((item) => {
                            return (
                              <Createitem key={item?.key} onClick={(e) => onChangeDate(item?.key, !item?.checked)}>
                                <Check>
                                  <input type='checkbox' className='checkbox-round' checked={item?.checked} />
                                </Check>
                                <p>{item?.label}</p>
                              </Createitem>
                            );
                          })}
                        </Bottomdata>
                      </div>
                    </Dropbox>
                  </div>
                )}
              </DropdownButton>
            </Leftcontent>
          </Contentheading>

          {(filteredReportTask?.length === 0 || !filteredReportTask) && !loading && (
            <Empty>
              <EmptyState
                header={'This is no report data.'}
                title={'You have no report data for this filter. Search for another filter.'}
                image={getImageSource()}
                name={''}
                hideButton={true}
              />
            </Empty>
          )}
          {filteredReportTask?.length > 0 && (
            <TableSection>
              <Table istasks>
                <thead>
                  <tr>
                    <th>STATUS</th>
                    <th>PROJECT NAME</th>
                    <th>NAME</th>
                    <th>ASSIGNED</th>
                    <th>DUE DATE</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredReportTask?.map((item, index: number) => {
                    const assignTo = item?.users?.map((user, index: number) => {
                      if (index === 0 && user?.name) {
                        return user?.name;
                      } else if (user?.name) {
                        return ', ' + user?.name;
                      }
                      return '';
                    });
                    return (
                      <tr key={item?.name}>
                        <td>
                          {item?.status === true ? <TaskDone>COMPLETED</TaskDone> : <TaskPending>PENDING</TaskPending>}
                        </td>
                        <td>{item?.projectName}</td>
                        <td className='task-name'>{item?.name}</td>
                        <td>{assignTo}</td>
                        <td>{item?.dueDate ? moment(item?.dueDate).format('MMM DD, yyyy') : 'No Due Date'}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </TableSection>
          )}
        </Container>
      </AppLayout>
    </>
  );
};
export default Task;
