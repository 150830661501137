import styled from 'styled-components';
import { Typography } from '../../../global/theme';

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 0 12px;
  width: 100%;
  max-width: 662px;
  margin: 0 auto;
`;
const Subscriptiongroup = styled.div`
  width: 100%;
  max-width: 662px;
  margin: 0 auto;
  margin-bottom: 24px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Bottomcontent = styled.div`
  border-radius: 8px;
  border: 0.5px solid var(--border-primary);
  background: var(--background-primary);
`;

const Box = styled.div`
  display: flex;
  padding: 12px 16px;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid var(--border-primary);
  :last-child {
    border-bottom: none;
  }
`;

const Leftcontent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const Rightcontent = styled.div`
  svg {
    cursor: pointer;
    fill: var(--text-secondary);
    stroke: var(--text-secondary);
  }
`;

const Description = styled.div`
  display: flex;
  align-items: center;
  p {
    color: var(--text-secondary);
    ${Typography.body_md}
    margin: 0;
    a {
      color: var(--brand-primary);
      ${Typography.body_md}
      text-decoration: none;
    }
  }
`;
const Autotext = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 30px;

  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--background-muted);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 14px;
    width: 14px;
    top: 3px;
    left: 3px;
    background-color: var(--text-white);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background: var(--brand-primary);
  }

  input:checked + .slider:before {
    transform: translateX(20px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;

const Icon = styled.div`
  .fill-color {
    fill: var(--text-primary);
  }
  .stroke-color path {
    stroke: var(--text-primary);
  }
`;
const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 483px;
  width: 100%;
  h1 {
    color: var(--text-primary);
    ${Typography.body_lg_medium}
    margin: 0;
  }
`;

const Label = styled.div`
  padding: 2px 5px;
  border-radius: 6px;
  border: 1px solid var(--neutral-secondary);
  background: var(--neutral-secondary);
  p {
    color: var(--text-secondary);
    ${Typography.body_sm}
    margin: 0;
    white-space: nowrap;
  }
`;

const PersonalHeader = styled.div`
  @media (max-width: 449px) {
    display: flex;
    align-items: center;
    gap: 24px;
  }
`;

export {
  Autotext,
  Bottom,
  Bottomcontent,
  Box,
  Content,
  Description,
  Header,
  Icon,
  Label,
  Leftcontent,
  Rightcontent,
  Subscriptiongroup,
  Text,
  PersonalHeader
};
