import React, { useCallback, useState } from 'react';
import { Box, Content, Form, Formbutton, Header, Icon, Inputvalue, Label, Title } from './styles';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import Button from '../../button';
import UserPreferenceSingleton from '../../../helpers/userPreferenceSingleton';
import { useDispatch, useSelector } from 'react-redux';
import { createNewMilestone, getMilestoneList, updateMilestone } from '../../../services/projectServices';
import { isEmpty } from '../../../helpers/common';
import { setErrorMessage } from '../../../actions/messageActions';
import { clearMilestoneInput, updateMilestoneInput } from '../../../actions/projectActions';
import { captureException } from '../../../services/logService';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';

interface Props {
  onClose: () => void;
  projectId: string;
}

const MilestoneModal: React.FC<Props> = (props) => {
  const { onClose, projectId } = props;
  //States
  const [loading, setLoading] = useState(false);
  //use selector state variables
  const projectSelector = useSelector((state: RootReducerInterface) => state.project);
  const { milestoneInput } = projectSelector;
  //Other variable
  const dispatch = useDispatch();

  const onChangeMilestoneName = useCallback(
    (value: string) => {
      dispatch(updateMilestoneInput({ propsName: 'milestoneName', value: value }));
    },
    [dispatch]
  );

  const onSubmit = useCallback(
    async (e: React.SyntheticEvent) => {
      try {
        e.preventDefault();
        setLoading(true);
        if (isEmpty(milestoneInput?.milestoneName)) {
          dispatch(setErrorMessage('Please enter milestone name.'));
          return;
        }
        if (!isEmpty(milestoneInput?.id)) {
          const payload = {
            milestoneId: milestoneInput?.id,
            milestoneName: milestoneInput?.milestoneName
          };
          const result = await dispatch(updateMilestone(payload));
          if (result) {
            onClose();
            dispatch(clearMilestoneInput());
            await dispatch(getMilestoneList(projectId, false, true));
          }
        } else {
          const currentUser = UserPreferenceSingleton.getInstance().getCurrentUser();
          const payload = {
            userId: currentUser?.id,
            projectId,
            milestoneName: milestoneInput?.milestoneName
          };
          const result = await dispatch(createNewMilestone(payload));
          if (result) {
            onClose();
            dispatch(clearMilestoneInput());
            await dispatch(getMilestoneList(projectId, false, true));
          }
        }
      } catch (error) {
        captureException(error);
        console.log('error', error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    },
    [dispatch, milestoneInput, onClose, projectId]
  );

  return (
    <div>
      <Box onSubmit={onSubmit}>
        <Header>
          <Title>{!isEmpty(milestoneInput?.id) ? 'Edit Milestone' : 'Create New Milestone'} </Title>
          <Icon onClick={onClose}>
            <SVGIcon name='close-icon' width='18' height='18' viewBox='0 0 18 18' className='close-icon' />
          </Icon>
        </Header>
        <Content>
          <Form>
            <Label>Milestone Name</Label>
            <Inputvalue
              type='text'
              placeholder='Enter milestone name'
              value={milestoneInput?.milestoneName}
              onChange={(e) => onChangeMilestoneName(e.target.value)}
            />
          </Form>
          <Formbutton>
            <Button title='Cancel' secondary={true} type='button' onClick={onClose} hasNotBoxshadow />
            <Button
              title={!isEmpty(milestoneInput?.id) ? 'Edit Milestone' : 'Create Milestone'}
              type='submit'
              isLoading={loading}
              disabled={loading}
            />
          </Formbutton>
        </Content>
      </Box>
    </div>
  );
};

export default MilestoneModal;
