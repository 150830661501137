import styled from 'styled-components';
import { Typography } from '../../../global/theme';

const ModalMain = styled.div`
  padding: 0px;
  @media (max-width: 449px) {
    width: 100%;
  }
`;

const Modalhead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 0.5px solid var(--border-primary);
`;

const Headline = styled.h6`
  ${Typography.heading_md}
  color: var(--text-primary);
  margin: 0;
`;

const Emailsection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  gap: 12px;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;
`;
const Icon = styled.div`
  display: inline-flex;
  cursor: pointer;
  .cross-icon {
    path {
      stroke: var(--text-primary);
    }
  }
  :hover {
    background-color: var(--neutral-secondary);
    border-radius: 4px;
  }
`;
const Text = styled.label`
  color: var(--text-secondary);
  ${Typography.body_md_medium}
`;

const Emailname = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 8px;
  .angledown-icon {
    display: flex;
    max-width: 18px;
    height: 18px;
    width: 100%;
    path {
      stroke: var(--text-primary);
    }
  }
`;

const Messagesection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 8px;
`;
const Messagebox = styled.textarea`
  max-width: 100%;
  width: 616px;
  overflow: auto;
  ${Typography.body_lg}
  color: var(--text-primary);
  font-family: 'inter', sans-serif;
  border-radius: 6px;
  border: 0.5px solid var(--border-primary);
  background: var(--background-primary);
  padding: 8px 12px;
  height: 92px;
  resize: none;
  &::-webkit-scrollbar {
    display: none;
  }
  :focus {
    outline: none;
    border: 0.5px solid var(--brand-primary);
  }
  ::placeholder {
    color: var(--text-placeholder);
  }
  @media (max-width: 449px) {
    width: calc(100% - 27px);
  }
`;
const Payment = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding-top: 8px;
`;
const Check = styled.div`
  display: flex;
  p {
    color: var(--text-primary);
    ${Typography.body_md_medium}
    margin: 0;
  }
  a {
    color: var(--brand-primary);
    ${Typography.body_md_medium}
    cursor: pointer;
    text-decoration: none;
  }
  .checkbox-round {
    width: 7px;
    height: 7px;
    border-radius: 12px;
    vertical-align: middle;
    border: 1px solid var(--border-primary);
    appearance: none;
    outline: none;
    cursor: pointer;
    margin: 0;
    padding: 7px;
  }
  input[type='checkbox'] {
    position: relative;
    cursor: pointer;
    opacity: 0.5;
  }
  input[type='checkbox']:checked {
    background-color: var(--brand-primary);
    opacity: 1;
    border: 1px solid var(--brand-primary);
  }
  input[type='checkbox']:checked::before {
    content: '';
    position: absolute;
    right: 50%;
    top: 50%;
    width: 3px;
    height: 8px;
    border: solid var(--text-white);
    border-width: 0 2px 2px 0;
    margin: -0.5px -1px 0 -1px;
    transform: rotate(45deg) translate(-50%, -50%) scale(0.7);
    z-index: 2;
  }
  .label-checkbox {
    color: var(--text-secondary);
    ${Typography.body_md_medium}
    margin: 0;
    padding-left: 8px;
  }
`;
const Inputvalue = styled.input``;

const InputDiv = styled.div`
  justify-content: space-between;
  display: flex;
  gap: 4px;
  min-width: 616px;
  max-width: 616px;
  padding: 7px 12px;
  color: var(--text-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 6px;
  ${Typography.body_lg}
  ::placeholder {
    color: var(--text-secondary);
  }
  :focus-visible {
    border-color: var(--brand-primary);
    outline: unset;
  }
  .angledown-icon.rotate {
    transform: rotate(180deg);
  }
  @media (max-width: 449px) {
    min-width: calc(100vw - 90px);
  }
`;

const Input = styled.input`
  border: none;
  outline: none;
  background: transparent;
`;

const Dropdiv = styled.div`
  max-height: 115px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: 4px;
  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--text-placeholder);
    border-radius: 12px;
    min-height: 28px;
  }
`;

const SelectedEmail = styled.div`
  display: flex;
  padding: 0 4px 0 8px;
  background-color: var(--neutral-secondary);
  border: 0.5px solid var(--border-primary);
  border-radius: 4px;
  align-items: center;
  gap: 4px;
  svg {
    stroke: var(--text-secondary);
  }
`;

const InputData = styled.div`
  display: flex;
  gap: 4px;
  max-width: 590px;
  flex-wrap: wrap;
`;

const Placeholder = styled.span`
  display: flex;
  position: absolute;
  left: 15px;
  color: var(--text-secondary);
`;
export {
  Modalhead,
  Headline,
  Buttons,
  ModalContent,
  Icon,
  ModalMain,
  Emailsection,
  Text,
  Emailname,
  Messagesection,
  Messagebox,
  Payment,
  Check,
  Inputvalue,
  Input,
  Dropdiv,
  SelectedEmail,
  InputDiv,
  InputData,
  Placeholder
};
