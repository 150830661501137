import moment from 'moment';
import { TIME_DATE_SELECTED_TEXT } from '../../../global/constants';
import { useCallback } from 'react';
import { SelectionRangeInterface } from '../../../interfaces/TimeReportInterface';
import { DropdownMenuDiv } from './styles';
import DropdownItem from '../../Dropdown/DropdownItem';

const dateOptions = [
  { key: TIME_DATE_SELECTED_TEXT.TODAY, label: 'Today' },
  { key: TIME_DATE_SELECTED_TEXT.YESTERDAY, label: 'Yesterday' },
  { key: TIME_DATE_SELECTED_TEXT.THIS_WEEK, label: 'This week' },
  { key: TIME_DATE_SELECTED_TEXT.LAST_WEEK, label: 'Last week' },
  { key: TIME_DATE_SELECTED_TEXT.LAST_7_DAYS, label: 'Last 7 days' },
  { key: TIME_DATE_SELECTED_TEXT.THIS_MONTH, label: 'This month' },
  { key: TIME_DATE_SELECTED_TEXT.LAST_MONTH, label: 'Last month' },
  { key: TIME_DATE_SELECTED_TEXT.LAST_30_DAYS, label: 'Last 30 days' },
  { key: TIME_DATE_SELECTED_TEXT.CUSTOM, label: 'Custom' }
];

interface Props {
  setDateSelectedText?: (value: string) => void;
  setIsStatusDropdownOpen: (value: boolean) => void;
  setSelectionRange?: (value: SelectionRangeInterface) => void;
  updateDateRangeChange?: (startDate: Date, endDate: Date, selected_text: string) => void;
  buttonref?: React.RefObject<HTMLDivElement | null>;
}
export default function ReportDurationDropdown({
  setDateSelectedText,
  setIsStatusDropdownOpen,
  setSelectionRange,
  updateDateRangeChange
}: Props) {
  const handleClick = useCallback(
    async (key: string) => {
      const dateRanges: Record<string, { startDate: Date; endDate: Date }> = {
        [TIME_DATE_SELECTED_TEXT.TODAY]: {
          startDate: moment().startOf('day').toDate(),
          endDate: moment().startOf('day').toDate()
        },
        [TIME_DATE_SELECTED_TEXT.YESTERDAY]: {
          startDate: moment().subtract(1, 'day').startOf('day').toDate(),
          endDate: moment().subtract(1, 'day').startOf('day').toDate()
        },
        [TIME_DATE_SELECTED_TEXT.THIS_WEEK]: {
          startDate: moment().startOf('week').toDate(),
          endDate: moment().toDate()
        },
        [TIME_DATE_SELECTED_TEXT.LAST_WEEK]: {
          startDate: moment().subtract(1, 'week').startOf('week').toDate(),
          endDate: moment().subtract(1, 'week').endOf('week').toDate()
        },
        [TIME_DATE_SELECTED_TEXT.THIS_MONTH]: {
          startDate: moment().startOf('month').toDate(),
          endDate: moment().toDate()
        },
        [TIME_DATE_SELECTED_TEXT.LAST_MONTH]: {
          startDate: moment().subtract(1, 'month').startOf('month').toDate(),
          endDate: moment().subtract(1, 'month').endOf('month').toDate()
        },
        [TIME_DATE_SELECTED_TEXT.LAST_30_DAYS]: {
          startDate: moment().subtract(30, 'days').startOf('day').toDate(),
          endDate: moment().toDate()
        },
        [TIME_DATE_SELECTED_TEXT.LAST_7_DAYS]: {
          startDate: moment().subtract(7, 'days').startOf('day').toDate(),
          endDate: moment().toDate()
        }
      };
      if (key === TIME_DATE_SELECTED_TEXT.CUSTOM) {
        setDateSelectedText?.(TIME_DATE_SELECTED_TEXT.CUSTOM);
        return;
      }
      const selectedRange = dateRanges[key];
      if (selectedRange) {
        const { startDate, endDate } = selectedRange;
        if (updateDateRangeChange) updateDateRangeChange(startDate, endDate, key);
        if (setDateSelectedText) setDateSelectedText(key);
        if (setSelectionRange) setSelectionRange({ startDate, endDate, key: 'selection' });
        setIsStatusDropdownOpen(false);
      }
    },
    [setDateSelectedText, setIsStatusDropdownOpen, setSelectionRange, updateDateRangeChange]
  );

  return (
    <DropdownMenuDiv>
      {dateOptions.map((option) => (
        <DropdownItem key={option.key} label={option.label} onClick={() => handleClick(option.key)} />
      ))}
    </DropdownMenuDiv>
  );
}
