/* eslint-disable max-len */
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { convertBase64ToFile, convertBlobUrlToDataUrl, getFirebaseUrlsFromFiles, isEmpty } from '../../helpers/common';
import { useMediaRecorder } from '../useMediaRecorder/useMediaRecorder';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';
import { captureException } from '../../services/logService';
import { addShareData } from '../../services/projectServices';
import { setScreenRecordingUrl } from '../../actions/appActions';
import { SHARE_TYPE } from '../../global/constants';
import { setErrorMessage } from '../../actions/messageActions';

function RecordingSnapAttach({
  setLoading,
  uploadSnapEmbed
}: {
  setLoading: Function;
  uploadSnapEmbed?: (url: string, fileName: string, videoId: string) => void;
}) {
  const dispatch = useDispatch();
  const { mediaBlobUrl, clearBlobUrl } = useMediaRecorder();
  const projectSelector = useSelector((state: RootReducerInterface) => state.project);
  const { currentProject } = projectSelector;

  const onScreenCaptureComplete = useCallback(
    async (mediaBlobUrl: any) => {
      try {
        setLoading(true);
        const baseFile = await convertBlobUrlToDataUrl(mediaBlobUrl)
          .then((regularUrl) => {
            return regularUrl;
          })
          .catch((error) => {
            captureException(error);
            console.error('Error:', error.message);
          });
        const fileData: any = await convertBase64ToFile(baseFile, true);
        if (fileData?.size > 2.5e8) {
          dispatch(setErrorMessage('Please upload a file smaller than 250 MB.'));
          return false;
        }
        const firebaseUrl = await getFirebaseUrlsFromFiles([{ file: fileData }], 'screen_recordings/', dispatch);
        if (firebaseUrl) {
          const sharePayload = {
            ref: firebaseUrl,
            type: SHARE_TYPE.snap
          };
          const shareResponse = await dispatch(addShareData(sharePayload));
          const snapUrl = `https://share.teamcamp.app/${shareResponse?.id}`;
          dispatch(setScreenRecordingUrl(snapUrl || ''));
          const fileName = fileData?.name;

          if (uploadSnapEmbed) uploadSnapEmbed(firebaseUrl[0] || '', fileName, shareResponse?.id);
        }
      } catch (e) {
        captureException(e);
        console.log('error : ', e);
      } finally {
        clearBlobUrl();
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentProject?.id, dispatch, setLoading]
  );

  useEffect(() => {
    if (!isEmpty(mediaBlobUrl)) onScreenCaptureComplete(mediaBlobUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaBlobUrl]);

  return <></>;
}

export default RecordingSnapAttach;
