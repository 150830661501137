import React, { useCallback, useEffect, useRef, useState } from 'react';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import {
  Modalhead,
  Headline,
  Buttons,
  ModalContent,
  Icon,
  ModalMain,
  Emailsection,
  Text,
  Emailname,
  Messagesection,
  Messagebox,
  Payment,
  Check,
  Inputvalue,
  Dropdiv,
  SelectedEmail,
  InputDiv,
  Input,
  InputData,
  Placeholder
} from './styles';
import Button from '../../button';
import { CustomerDetailsInterface } from '../../../interfaces/CustomerInterface';
import { useDispatch } from 'react-redux';
import { getCustomerList } from '../../../services/customerServices';
import { sendInvoiceToCustomers } from '../../../services/invoiceService';
import { ROUTES } from '../../../routes/urls';
import { isDev, isEmail, isEmpty } from '../../../helpers/common';
import { setErrorMessage } from '../../../actions/messageActions';
import { captureException } from '../../../services/logService';
import { trackAnalyticActivity } from '../../../services/analyticsService';
import { INVOICE_ANALYTICS } from '../../../global/analyticsConstants';
import { Dropdown, DropdownItem } from '../../Dropdown';
import { useMobileDevice } from '../../../global/useMobile';
import { getCompanySettingIntegration } from '../../../services/settingServices';
interface Props {
  onClose: () => void;
  invoiceId: string;
  workspaceId: string;
}

const Sendinvoicemodal: React.FC<Props> = (props) => {
  const { invoiceId, workspaceId } = props;
  //Refs
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  //States
  const [emailList, setEmailList] = useState<string[]>([]);
  const [messageInput, setMessageInput] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [customerWithEmailList, setCustomerWithEmailList] = useState<{ value: string; label: string }[]>([]);
  const [loading, setLoading] = useState(false);
  const [paymentEnabled, setPaymentEnabled] = useState(false);
  const [isOnlinePayment, setIsOnlinePayment] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const isMobile = useMobileDevice();
  const isMinWidth = isMobile ? Math.max(window.innerWidth - 65) : 640;
  //Other variable
  const dispatch = useDispatch();

  const handleTextareaInput = useCallback(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      if (textarea.scrollHeight > textarea.clientHeight) {
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    }
  }, []);

  const onChangeEmailInput = useCallback((value: any) => {
    setSearchTerm(value);
  }, []);

  const loadData = useCallback(async () => {
    try {
      if (!isEmpty(workspaceId)) {
        setLoading(true);
        let customersList = await dispatch(getCustomerList());
        customersList = customersList?.map((x: CustomerDetailsInterface) => {
          return { ...x, label: `${x?.companyName} - ${x?.email}`, value: x?.email };
        });
        setCustomerWithEmailList(customersList);
        const result = await dispatch(getCompanySettingIntegration(workspaceId));
        setPaymentEnabled(result?.stripe_connected);
      }
    } catch (error) {
      captureException(error);
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  }, [dispatch, workspaceId]);

  useEffect(() => {
    handleTextareaInput();
    loadData();
  }, [handleTextareaInput, loadData]);

  const onClickClose = useCallback(() => {
    props?.onClose();
    setMessageInput('');
    setIsOnlinePayment(false);
    setCustomerWithEmailList([]);
    setPaymentEnabled(false);
  }, [props]);

  const onSubmit = useCallback(
    async (e: React.SyntheticEvent) => {
      try {
        e.preventDefault();
        setLoading(true);
        if (isEmpty(emailList) || emailList?.length === 0) {
          dispatch(setErrorMessage('Please enter atleast one recipient.'));
          return;
        }
        const invalidEmail = emailList?.find((email) => !isEmail(email));
        if (invalidEmail) {
          dispatch(setErrorMessage('Please enter valid email address.'));
          return;
        }
        const payload = {
          emails: emailList,
          onlinePayment: isOnlinePayment,
          message: messageInput,
          invoiceId: invoiceId,
          isDev: isDev()
        };
        const result = await dispatch(sendInvoiceToCustomers(payload));
        if (result) {
          trackAnalyticActivity(INVOICE_ANALYTICS.SENT);
          onClickClose();
        }
      } catch (error) {
        captureException(error);
        console.log('error', error);
      } finally {
        setLoading(false);
      }
    },
    [dispatch, emailList, invoiceId, isOnlinePayment, messageInput, onClickClose]
  );

  // Handle item selection
  const selectEmail = useCallback(
    (email: string) => {
      if (!emailList.includes(email)) {
        setEmailList([...emailList, email]);
        setSearchTerm(''); // Clear input after selection
      }
    },
    [emailList]
  );

  // Remove selected email div completely when pressing Backspace
  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Backspace' && searchTerm === '' && emailList.length > 0) {
        setEmailList(emailList.slice(0, -1)); // Remove last email completely
      }
    },
    [emailList, searchTerm]
  );

  // Toggle dropdown when clicking on InputDiv
  const handleToggleDropdown = useCallback(() => {
    setIsDropdownOpen((prev) => !prev);
  }, []);

  return (
    <ModalMain>
      <Modalhead>
        <Headline>Send Invoice</Headline>
        <Icon onClick={onClickClose}>
          <SVGIcon
            name='modal-cross-sign-icon'
            width='18'
            height='18'
            viewBox='0 0 18 18'
            fill='none'
            className='cross-icon'
          />
        </Icon>
      </Modalhead>
      <ModalContent>
        <Emailsection>
          <Text>To (You can enter multiple recipients.)</Text>
          <Emailname>
            <Dropdown
              isMinWidth={isMinWidth}
              content={
                <InputDiv className='input-dropdown' onClick={handleToggleDropdown}>
                  <InputData>
                    {/* Placeholder when no emails are selected */}
                    {emailList.length === 0 && searchTerm === '' && <Placeholder>Person or Email</Placeholder>}

                    {/* Display selected emails */}
                    {emailList.map((email, index) => (
                      <SelectedEmail key={index}>
                        {email}
                        <span onClick={() => setEmailList(emailList.filter((e) => e !== email))}>
                          <SVGIcon name='cross-icon' width='8px' height='8px' viewBox='0 0 10 10' />
                        </span>
                      </SelectedEmail>
                    ))}

                    {/* Input for searching and adding emails */}
                    <Input
                      type='text'
                      onChange={(e) => onChangeEmailInput(e.target.value)}
                      onKeyDown={handleKeyDown}
                      value={searchTerm}
                    />
                  </InputData>

                  <SVGIcon
                    name='angledown-icon'
                    width='18'
                    height='18'
                    viewBox='0 0 18 18'
                    className={`angledown-icon ${isDropdownOpen ? 'rotate' : ''}`}
                  />
                </InputDiv>
              }
              trigger='click'>
              <Dropdiv>
                {customerWithEmailList
                  ?.filter(
                    (item) => !isEmpty(item?.value) && item.value.toLowerCase().includes(searchTerm.toLowerCase())
                  )
                  .map((customer) => {
                    const isSelected = emailList.includes(customer.value);
                    return (
                      <DropdownItem
                        isChecked={isSelected}
                        key={customer.value}
                        label={customer.label}
                        onClick={() => selectEmail(customer.value)}
                        isSuffix={
                          isSelected ? (
                            <SVGIcon name='checkbox-icon' width='16' height='16' viewBox='0 0 16 16' />
                          ) : null
                        }
                      />
                    );
                  })}
              </Dropdiv>
            </Dropdown>
          </Emailname>
          <Messagesection>
            <Text>Message</Text>
            <Messagebox placeholder='Enter your Message' onChange={(e) => setMessageInput(e.target.value)}></Messagebox>
          </Messagesection>
          <Payment>
            {paymentEnabled ? (
              <>
                <Check>
                  <Inputvalue
                    type='checkbox'
                    id='paymentDisable'
                    name='paymentDisable'
                    className='checkbox-round'
                    checked={isOnlinePayment}
                    onChange={(e) => setIsOnlinePayment(e.target.checked)}
                  />
                  <label htmlFor='paymentDisable' className='label-checkbox'>
                    Collect Online Payment
                  </label>
                </Check>
              </>
            ) : (
              <>
                <Check>
                  <p>
                    <a href={ROUTES.PAYMENT_SETTING}>Configure</a> stripe of Paypal to collect Payment
                  </p>
                </Check>
              </>
            )}
          </Payment>
        </Emailsection>
        <Buttons>
          <Button
            title='Cancel'
            onClick={onClickClose}
            type='button'
            secondary={true}
            modelbtn={true}
            hasNotBoxshadow
          />
          <Button
            title='Send Invoice'
            onClick={onSubmit}
            type='button'
            modelbtn={true}
            isLoading={loading}
            disabled={loading}
          />
        </Buttons>
      </ModalContent>
    </ModalMain>
  );
};

export default Sendinvoicemodal;
