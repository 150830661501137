import styled, { css } from 'styled-components';
import { Typography } from '../../../../global/theme';

interface Props {
  isActive?: boolean;
}

const Group = styled.div`
  width: 100%;
  margin: auto;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 23px;
  margin: 20px auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(186px, 1fr));
  grid-template-rows: auto;
  grid-row-gap: 23px;
  grid-column-gap: 23px;

  img {
    object-fit: contain;
  }
  @media (max-width: 449px) {
    margin: 24px auto;
    grid-row-gap: 15px;
    grid-column-gap: 15px;
    grid-template-columns: repeat(auto-fill, minmax(128px, 1fr));

    .file-data-image {
      width: 53px;
      height: 53px;
    }
  }
`;

const FileDetails = styled.div`
  @media (max-width: 449px) {
    .loading-image {
      display: none;
    }
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const Leftside = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
  margin-top: 39px;
  @media (max-width: 449px) {
    margin-top: 11px;
  }
`;

const Title = styled.h1`
  ${Typography.body_lg_semibold}
  color: var(--text-primary);
  margin: 0;
`;

const Text = styled.p`
  ${Typography.body_md_medium}
  color: var(--text-secondary);
  white-space: nowrap;
  margin: 0;
  @media (max-width: 449px) {
    white-space: normal;
    text-align: end;
  }
`;

const Icon = styled.div`
  cursor: pointer;
  display: flex;
  gap: 15px;
  /* margin-top: 5px; */
  :hover .fillColor {
    background-color: var(--neutral-secondary);
    border-radius: 4px;
  }

  @media (max-width: 449px) {
    gap: 2px;
  }
  .fillColor {
    stroke: var(--text-primary);
  }
`;

const Rightside = styled.div`
  .mobile-view {
    display: none;
  }
  .web-view {
    path {
      stroke: var(--brand-primary);
    }
    path.circle-fill {
      fill: var(--background-primary);
    }
  }
  @media (max-width: 449px) {
    .web-view {
      display: none;
    }
  }

  @media (max-width: 449px) {
    margin-top: -82px;
    z-index: 999;
    .mobile-view {
      display: block;
    }
    .mobile-view {
      path {
        stroke: var(--brand-primary);
      }
      path.circle-fill {
        fill: var(--background-primary);
      }
    }
  }
`;

const BreadCrumb = styled.div`
  display: flex;
`;

const BreadCrumbContent = styled.div`
  display: flex;
`;

const BredCrumbBlock = styled.div<Props>`
  padding: 4px 8px;
  ${Typography.body_md}
  color: var(--text-primary);
  cursor: pointer;

  :hover {
    color: var(--text-secondary);
  }

  ${(props: Props) =>
    props.isActive &&
    css`
      color: var(--brand-primary);
    `}
`;

const BreadSlash = styled.div`
  padding: 4px 0px;
  ${Typography.body_md}
  color: var(--text-primary);
  margin: 0 2px;
`;

const FileIconDiv = styled.div`
  position: relative;
  .file-text {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 35%;
    left: 25%;
    width: 50%;
    font-weight: 700;
    color: var(--text-secondary);
  }
  .upload-file-text {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 28%;
    left: 25%;
    width: 50%;
    font-weight: 700;
    color: var(--text-primary);
  }
`;
const Uploadingcard = styled.div`
  width: 100%;
  max-width: 230px;
  border: 0.5px solid var(--border-primary);
  background-color: var(--background-primary);
  box-shadow: var(--shadow-modal);
  border-radius: 8px;
  padding: 11px;
  margin-bottom: 20px;
  position: absolute;
  bottom: 0;
  left: 50%;
`;

const Uploadingcontent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

const Filetext = styled.div`
  width: 159px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const Filename = styled.div`
  ${Typography.body_md_medium}
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--text-primary);
`;
const Filesize = styled.div`
  ${Typography.body_sm}
  color: var(--text-secondary);
`;
const ProgressDiv = styled.div`
  display: flex;
  padding: 0;
  margin-top: 6px;
  .ant-progress-line .ant-progress-outer .ant-progress-inner div.ant-progress-bg {
    height: 3px !important;
  }
  .ant-progress .ant-progress-inner {
    background-color: var(--border-primary);
    margin-top: -21px;
  }
  .ant-progress .ant-progress-text {
    position: absolute;
    bottom: 15px;
    right: 0;
    ${Typography.body_md}
    color: var(--text-primary);
  }
  .ant-progress.ant-progress-show-info .ant-progress-outer {
    margin-inline-end: 0;
    padding-inline-end: 0;
    height: 3px;
  }
  .prograss {
    .ant-progress-outer {
    }
  }
`;

const InputDiv = styled.div`
  position: relative;
  .angledown-icon.rotate {
    transform: rotate(180deg);
  }
`;

const Input = styled.input`
  max-width: 396px;
  min-width: 396px;
  width: 100%;
  padding: 10px 16px;
  background-color: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 6px;
  color: var(--text-primary);
  ${Typography.body_lg}
  ::placeholder {
    color: var(--text-secondary);
  }
  :focus-visible {
    outline: none;
    border: 0.5px solid var(--brand-primary);
  }
`;

const IconDiv = styled.div`
  display: flex;
  position: absolute;
  top: 10px;
  right: 15px;
`;

const SelectedText = styled.div`
  position: absolute;
  top: 10px;
  left: 18px;
  pointer-events: none;
`;

export {
  Group,
  Container,
  Content,
  Leftside,
  Title,
  Text,
  Icon,
  Rightside,
  BreadCrumb,
  BreadCrumbContent,
  BredCrumbBlock,
  BreadSlash,
  FileDetails,
  FileIconDiv,
  Uploadingcard,
  Uploadingcontent,
  Filename,
  Filesize,
  Filetext,
  ProgressDiv,
  InputDiv,
  Input,
  IconDiv,
  SelectedText
};
