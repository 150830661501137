import {
  INVOICE_STATUS_TYPE,
  SHOW_COMPLETED_TASK_BY_DATE,
  MENU_OPTIONS,
  TASK_PRIORITY,
  TASK_REPORT_STATUS,
  TASK_REPORT_TIME_TYPE
} from './constants';

const teamMember = [
  { label: 'Personal', value: 1 },
  { label: 'Freelance Business', value: 2 },
  { label: 'Team/Organization', value: 3 }
];

const industryList = [
  { label: 'Accounting', value: 'Accounting' },
  { label: 'Advertising / Marketing / PR', value: 'Advertising / Marketing / PR' },
  { label: 'Architecture', value: 'Architecture' },
  { label: 'Construction', value: 'Construction' },
  { label: 'Consulting - IT, Software', value: 'Consulting - IT, Software' },
  { label: 'Consulting - Engineering', value: 'Consulting - Engineering' },
  { label: 'Consulting - Management', value: 'Consulting - Management' },
  { label: 'Consulting - Healthcare', value: 'Consulting - Healthcare' },
  { label: 'Consulting - VAR/Systems Integrator', value: 'Consulting - VAR/Systems Integrator' },
  { label: 'Financial Services', value: 'Financial Services' },
  { label: 'Legal', value: 'Legal' },
  { label: 'Medical / Pharma / Biotech', value: 'Medical / Pharma / Biotech' },
  { label: 'Non-profit', value: 'Non-profit' },
  { label: 'Web Design', value: 'Web Design' },
  { label: 'Web Development', value: 'Web Development' },
  { label: 'Other', value: 'Other' }
];

const randomColorsArray = ['#4fcb71', '#f5a623', '#7ed321', '#50e3c2', '#9B9B9B', '#4a90e2', '#ff7c73', '#FF2C54'];

const currencySymbols = [
  { code: 'AED', symbol: '\u062f.\u0625;', name: 'UAE dirham' },
  { code: 'AFN', symbol: 'Afs', name: 'Afghan afghani' },
  { code: 'ALL', symbol: 'L', name: 'Albanian lek' },
  { code: 'AMD', symbol: 'AMD', name: 'Armenian dram' },
  { code: 'ANG', symbol: 'NA\u0192', name: 'Netherlands Antillean gulden' },
  { code: 'AOA', symbol: 'Kz', name: 'Angolan kwanza' },
  { code: 'ARS', symbol: '$', name: 'Argentine peso' },
  { code: 'AUD', symbol: '$', name: 'Australian dollar' },
  { code: 'AWG', symbol: '\u0192', name: 'Aruban florin' },
  { code: 'AZN', symbol: 'AZN', name: 'Azerbaijani manat' },
  { code: 'BAM', symbol: 'KM', name: 'Bosnia and Herzegovina konvertibilna marka' },
  { code: 'BBD', symbol: 'Bds$', name: 'Barbadian dollar' },
  { code: 'BDT', symbol: '\u09f3', name: 'Bangladeshi taka' },
  { code: 'BGN', symbol: 'BGN', name: 'Bulgarian lev' },
  { code: 'BHD', symbol: '.\u062f.\u0628', name: 'Bahraini dinar' },
  { code: 'BIF', symbol: 'FBu', name: 'Burundi franc' },
  { code: 'BMD', symbol: 'BD$', name: 'Bermudian dollar' },
  { code: 'BND', symbol: 'B$', name: 'Brunei dollar' },
  { code: 'BOB', symbol: 'Bs.', name: 'Bolivian boliviano' },
  { code: 'BRL', symbol: 'R$', name: 'Brazilian real' },
  { code: 'BSD', symbol: 'B$', name: 'Bahamian dollar' },
  { code: 'BTN', symbol: 'Nu.', name: 'Bhutanese ngultrum' },
  { code: 'BWP', symbol: 'P', name: 'Botswana pula' },
  { code: 'BYR', symbol: 'Br', name: 'Belarusian ruble' },
  { code: 'BZD', symbol: 'BZ$', name: 'Belize dollar' },
  { code: 'CAD', symbol: '$', name: 'Canadian dollar' },
  { code: 'CDF', symbol: 'F', name: 'Congolese franc' },
  { code: 'CHF', symbol: 'Fr.', name: 'Swiss franc' },
  { code: 'CLP', symbol: '$', name: 'Chilean peso' },
  { code: 'CNY', symbol: '\u00a5', name: 'Chinese/Yuan renminbi' },
  { code: 'COP', symbol: 'Col$', name: 'Colombian peso' },
  { code: 'CRC', symbol: '\u20a1', name: 'Costa Rican colon' },
  { code: 'CUC', symbol: '$', name: 'Cuban peso' },
  { code: 'CVE', symbol: 'Esc', name: 'Cape Verdean escudo' },
  { code: 'CZK', symbol: 'K\u010d', name: 'Czech koruna' },
  { code: 'DJF', symbol: 'Fdj', name: 'Djiboutian franc' },
  { code: 'DKK', symbol: 'Kr', name: 'Danish krone' },
  { code: 'DOP', symbol: 'RD$', name: 'Dominican peso' },
  { code: 'DZD', symbol: '\u062f.\u062c', name: 'Algerian dinar' },
  { code: 'EEK', symbol: 'KR', name: 'Estonian kroon' },
  { code: 'EGP', symbol: '\u00a3', name: 'Egyptian pound' },
  { code: 'ERN', symbol: 'Nfa', name: 'Eritrean nakfa' },
  { code: 'ETB', symbol: 'Br', name: 'Ethiopian birr' },
  { code: 'EUR', symbol: '\u20ac', name: 'European Euro' },
  { code: 'FJD', symbol: 'FJ$', name: 'Fijian dollar' },
  { code: 'FKP', symbol: '\u00a3', name: 'Falkland Islands pound' },
  { code: 'GBP', symbol: '\u00a3', name: 'British pound' },
  { code: 'GEL', symbol: 'GEL', name: 'Georgian lari' },
  { code: 'GHS', symbol: 'GH\u20b5', name: 'Ghanaian cedi' },
  { code: 'GIP', symbol: '\u00a3', name: 'Gibraltar pound' },
  { code: 'GMD', symbol: 'D', name: 'Gambian dalasi' },
  { code: 'GNF', symbol: 'FG', name: 'Guinean franc' },
  { code: 'GQE', symbol: 'CFA', name: 'Central African CFA franc' },
  { code: 'GTQ', symbol: 'Q', name: 'Guatemalan quetzal' },
  { code: 'GYD', symbol: 'GY$', name: 'Guyanese dollar' },
  { code: 'HKD', symbol: 'HK$', name: 'Hong Kong dollar' },
  { code: 'HNL', symbol: 'L', name: 'Honduran lempira' },
  { code: 'HRK', symbol: 'kn', name: 'Croatian kuna' },
  { code: 'HTG', symbol: 'G', name: 'Haitian gourde' },
  { code: 'HUF', symbol: 'Ft', name: 'Hungarian forint' },
  { code: 'IDR', symbol: 'Rp', name: 'Indonesian rupiah' },
  { code: 'ILS', symbol: '\u20aa', name: 'Israeli new sheqel' },
  { code: 'INR', symbol: '\u20B9', name: 'Indian rupee' },
  { code: 'IQD', symbol: '\u062f.\u0639', name: 'Iraqi dinar' },
  { code: 'IRR', symbol: 'IRR', name: 'Iranian rial' },
  { code: 'ISK', symbol: 'kr', name: 'Icelandic kr\u00f3na' },
  { code: 'JMD', symbol: 'J$', name: 'Jamaican dollar' },
  { code: 'JOD', symbol: 'JOD', name: 'Jordanian dinar' },
  { code: 'JPY', symbol: '\u00a5', name: 'Japanese yen' },
  { code: 'KES', symbol: 'KSh', name: 'Kenyan shilling' },
  { code: 'KGS', symbol: '\u0441\u043e\u043c', name: 'Kyrgyzstani som' },
  { code: 'KHR', symbol: '\u17db', name: 'Cambodian riel' },
  { code: 'KMF', symbol: 'KMF', name: 'Comorian franc' },
  { code: 'KPW', symbol: 'W', name: 'North Korean won' },
  { code: 'KRW', symbol: 'W', name: 'South Korean won' },
  { code: 'KWD', symbol: 'KWD', name: 'Kuwaiti dinar' },
  { code: 'KYD', symbol: 'KY$', name: 'Cayman Islands dollar' },
  { code: 'KZT', symbol: 'T', name: 'Kazakhstani tenge' },
  { code: 'LAK', symbol: 'KN', name: 'Lao kip' },
  { code: 'LBP', symbol: '\u00a3', name: 'Lebanese lira' },
  { code: 'LKR', symbol: 'Rs', name: 'Sri Lankan rupee' },
  { code: 'LRD', symbol: 'L$', name: 'Liberian dollar' },
  { code: 'LSL', symbol: 'M', name: 'Lesotho loti' },
  { code: 'LTL', symbol: 'Lt', name: 'Lithuanian litas' },
  { code: 'LVL', symbol: 'Ls', name: 'Latvian lats' },
  { code: 'LYD', symbol: 'LD', name: 'Libyan dinar' },
  { code: 'MAD', symbol: 'MAD', name: 'Morocodean dirham' },
  { code: 'MDL', symbol: 'MDL', name: 'Moldovan leu' },
  { code: 'MGA', symbol: 'FMG', name: 'Malagasy ariary' },
  { code: 'MKD', symbol: 'MKD', name: 'Macedonian denar' },
  { code: 'MMK', symbol: 'K', name: 'Myanma kyat' },
  { code: 'MNT', symbol: '\u20ae', name: 'Mongolian tugrik' },
  { code: 'MOP', symbol: 'P', name: 'Macanese pataca' },
  { code: 'MRO', symbol: 'UM', name: 'Mauritanian ouguiya' },
  { code: 'MUR', symbol: 'Rs', name: 'Mauritian rupee' },
  { code: 'MVR', symbol: 'Rf', name: 'Maldivian rufiyaa' },
  { code: 'MWK', symbol: 'MK', name: 'Malawian kwacha' },
  { code: 'MXN', symbol: '$', name: 'Mexican peso' },
  { code: 'MYR', symbol: 'RM', name: 'Malaysian ringgit' },
  { code: 'MZM', symbol: 'MTn', name: 'Mozambican metical' },
  { code: 'NAD', symbol: 'N$', name: 'Namibian dollar' },
  { code: 'NGN', symbol: '\u20a6', name: 'Nigerian naira' },
  { code: 'NIO', symbol: 'C$', name: 'Nicaraguan c\u00f3rdoba' },
  { code: 'NOK', symbol: 'kr', name: 'Norwegian krone' },
  { code: 'NPR', symbol: 'NRs', name: 'Nepalese rupee' },
  { code: 'NZD', symbol: 'NZ$', name: 'New Zealand dollar' },
  { code: 'OMR', symbol: 'OMR', name: 'Omani rial' },
  { code: 'PAB', symbol: 'B./', name: 'Panamanian balboa' },
  { code: 'PEN', symbol: 'S/.', name: 'Peruvian nuevo sol' },
  { code: 'PGK', symbol: 'K', name: 'Papua New Guinean kina' },
  { code: 'PHP', symbol: '\u20b1', name: 'Philippine peso' },
  { code: 'PKR', symbol: 'Rs.', name: 'Pakistani rupee' },
  { code: 'PLN', symbol: 'z\u0142', name: 'Polish zloty' },
  { code: 'PYG', symbol: '\u20b2', name: 'Paraguayan guarani' },
  { code: 'QAR', symbol: 'QR', name: 'Qatari riyal' },
  { code: 'RON', symbol: 'L', name: 'Romanian leu' },
  { code: 'RSD', symbol: 'din.', name: 'Serbian dinar' },
  { code: 'RUB', symbol: 'R', name: 'Russian ruble' },
  { code: 'SAR', symbol: 'SR', name: 'Saudi riyal' },
  { code: 'SBD', symbol: 'SI$', name: 'Solomon Islands dollar' },
  { code: 'SCR', symbol: 'SR', name: 'Seychellois rupee' },
  { code: 'SDG', symbol: 'SDG', name: 'Sudanese pound' },
  { code: 'SEK', symbol: 'kr', name: 'Swedish krona' },
  { code: 'SGD', symbol: 'S$', name: 'Singapore dollar' },
  { code: 'SHP', symbol: '\u00a3', name: 'Saint Helena pound' },
  { code: 'SLL', symbol: 'Le', name: 'Sierra Leonean leone' },
  { code: 'SOS', symbol: 'Sh.', name: 'Somali shilling' },
  { code: 'SRD', symbol: '$', name: 'Surinamese dollar' },
  { code: 'SYP', symbol: 'LS', name: 'Syrian pound' },
  { code: 'SZL', symbol: 'E', name: 'Swazi lilangeni' },
  { code: 'THB', symbol: '\u0e3f', name: 'Thai baht' },
  { code: 'TJS', symbol: 'TJS', name: 'Tajikistani somoni' },
  { code: 'TMT', symbol: 'm', name: 'Turkmen manat' },
  { code: 'TND', symbol: 'DT', name: 'Tunisian dinar' },
  { code: 'TRY', symbol: 'TRY', name: 'Turkish new lira' },
  { code: 'TTD', symbol: 'TT$', name: 'Trinidad and Tobago dollar' },
  { code: 'TWD', symbol: 'NT$', name: 'New Taiwan dollar' },
  { code: 'TZS', symbol: 'TZS', name: 'Tanzanian shilling' },
  { code: 'UAH', symbol: 'UAH', name: 'Ukrainian hryvnia' },
  { code: 'UGX', symbol: 'USh', name: 'Ugandan shilling' },
  { code: 'USD', symbol: 'US$', name: 'United States dollar' },
  { code: 'UYU', symbol: '$U', name: 'Uruguayan peso' },
  { code: 'UZS', symbol: 'UZS', name: 'Uzbekistani som' },
  { code: 'VEB', symbol: 'Bs', name: 'Venezuelan bolivar' },
  { code: 'VND', symbol: '\u20ab', name: 'Vietnamese dong' },
  { code: 'VUV', symbol: 'VT', name: 'Vanuatu vatu' },
  { code: 'WST', symbol: 'WS$', name: 'Samoan tala' },
  { code: 'XAF', symbol: 'CFA', name: 'Central African CFA franc' },
  { code: 'XCD', symbol: 'EC$', name: 'East Caribbean dollar' },
  { code: 'XDR', symbol: 'SDR', name: 'Special Drawing Rights' },
  { code: 'XOF', symbol: 'CFA', name: 'West African CFA franc' },
  { code: 'XPF', symbol: 'F', name: 'CFP franc' },
  { code: 'YER', symbol: 'YER', name: 'Yemeni rial' },
  { code: 'ZAR', symbol: 'R', name: 'South African rand' },
  { code: 'ZMK', symbol: 'ZK', name: 'Zambian kwacha' },
  { code: 'ZWR', symbol: 'Z$', name: 'Zimbabwean dollar' }
];

const CurrencyList = [
  { key: 'INR', value: 'Indian Rupee - INR' },
  { key: 'USD', value: 'United States Dollar - USD' },
  { key: 'EUR', value: 'Euro - EUR' },
  { key: 'GBP', value: 'British Pound - GBP' },
  { key: 'AUD', value: 'Australian Dollar - AUD' },
  { key: 'CAD', value: 'Canadian Dollar - CAD' },
  { key: 'JPY', value: 'Japanese Yen - JPY' },
  { key: 'BYR', value: 'Belarusian Ruble - BYR' },
  { key: 'AED', value: 'United Arab Emirates Dirham - AED' },
  { key: 'AFN', value: 'Afghan Afghani - AFN' },
  { key: 'ALL', value: 'Albanian Lek - ALL' },
  { key: 'AMD', value: 'Armenian Dram - AMD' },
  { key: 'ANG', value: 'Netherlands Antillean Gulden - ANG' },
  { key: 'AOA', value: 'Angolan Kwanza - AOA' },
  { key: 'ARS', value: 'Argentine Peso - ARS' },
  { key: 'AWG', value: 'Aruban Florin - AWG' },
  { key: 'AZN', value: 'Azerbaijani Manat - AZN' },
  { key: 'BAM', value: 'Bosnia and Herzegovina Convertible Mark - BAM' },
  { key: 'BBD', value: 'Barbadian Dollar - BBD' },
  { key: 'BDT', value: 'Bangladeshi Taka - BDT' },
  { key: 'BGN', value: 'Bulgarian Lev - BGN' },
  { key: 'BHD', value: 'Bahraini Dinar - BHD' },
  { key: 'BIF', value: 'Burundian Franc - BIF' },
  { key: 'BMD', value: 'Bermudian Dollar - BMD' },
  { key: 'BND', value: 'Brunei Dollar - BND' },
  { key: 'BOB', value: 'Bolivian Boliviano - BOB' },
  { key: 'BRL', value: 'Brazilian Real - BRL' },
  { key: 'BSD', value: 'Bahamian Dollar - BSD' },
  { key: 'BTC', value: 'Bitcoin - BTC' },
  { key: 'BTN', value: 'Bhutanese Ngultrum - BTN' },
  { key: 'BWP', value: 'Botswana Pula - BWP' },
  { key: 'BYN', value: 'Belarusian Ruble - BYN' },
  { key: 'BZD', value: 'Belize Dollar - BZD' },
  { key: 'CDF', value: 'Congolese Franc - CDF' },
  { key: 'CHF', value: 'Swiss Franc - CHF' },
  { key: 'CLF', value: 'Unidad de Fomento - CLF' },
  { key: 'CLP', value: 'Chilean Peso - CLP' },
  { key: 'CNY', value: 'Chinese Renminbi Yuan - CNY' },
  { key: 'COP', value: 'Colombian Peso - COP' },
  { key: 'CRC', value: 'Costa Rican Colón - CRC' },
  { key: 'CUC', value: 'Cuban Convertible Peso - CUC' },
  { key: 'CUP', value: 'Cuban Peso - CUP' },
  { key: 'CVE', value: 'Cape Verdean Escudo - CVE' },
  { key: 'CZK', value: 'Czech Koruna - CZK' },
  { key: 'DJF', value: 'Djiboutian Franc - DJF' },
  { key: 'DKK', value: 'Danish Krone - DKK' },
  { key: 'DOP', value: 'Dominican Peso - DOP' },
  { key: 'DZD', value: 'Algerian Dinar - DZD' },
  { key: 'EEK', value: 'Estonian Kroon - EEK' },
  { key: 'EGP', value: 'Egyptian Pound - EGP' },
  { key: 'ERN', value: 'Eritrean Nakfa - ERN' },
  { key: 'ETB', value: 'Ethiopian Birr - ETB' },
  { key: 'FJD', value: 'Fijian Dollar - FJD' },
  { key: 'FKP', value: 'Falkland Pound - FKP' },
  { key: 'GBX', value: 'British Penny - GBX' },
  { key: 'GEL', value: 'Georgian Lari - GEL' },
  { key: 'GGP', value: 'Guernsey Pound - GGP' },
  { key: 'GHS', value: 'Ghanaian Cedi - GHS' },
  { key: 'GIP', value: 'Gibraltar Pound - GIP' },
  { key: 'GMD', value: 'Gambian Dalasi - GMD' },
  { key: 'GNF', value: 'Guinean Franc - GNF' },
  { key: 'GTQ', value: 'Guatemalan Quetzal - GTQ' },
  { key: 'GYD', value: 'Guyanese Dollar - GYD' },
  { key: 'HKD', value: 'Hong Kong Dollar - HKD' },
  { key: 'HNL', value: 'Honduran Lempira - HNL' },
  { key: 'HRK', value: 'Croatian Kuna - HRK' },
  { key: 'HTG', value: 'Haitian Gourde - HTG' },
  { key: 'HUF', value: 'Hungarian Forint - HUF' },
  { key: 'IDR', value: 'Indonesian Rupiah - IDR' },
  { key: 'ILS', value: 'Israeli New Sheqel - ILS' },
  { key: 'IMP', value: 'Isle of Man Pound - IMP' },
  { key: 'IQD', value: 'Iraqi Dinar - IQD' },
  { key: 'IRR', value: 'Iranian Rial - IRR' },
  { key: 'ISK', value: 'Icelandic Króna - ISK' },
  { key: 'JEP', value: 'Jersey Pound - JEP' },
  { key: 'JMD', value: 'Jamaican Dollar - JMD' },
  { key: 'JOD', value: 'Jordanian Dinar - JOD' },
  { key: 'KES', value: 'Kenyan Shilling - KES' },
  { key: 'KGS', value: 'Kyrgyzstani Som - KGS' },
  { key: 'KHR', value: 'Cambodian Riel - KHR' },
  { key: 'KMF', value: 'Comorian Franc - KMF' },
  { key: 'KPW', value: 'North Korean Won - KPW' },
  { key: 'KRW', value: 'South Korean Won - KRW' },
  { key: 'KWD', value: 'Kuwaiti Dinar - KWD' },
  { key: 'KYD', value: 'Cayman Islands Dollar - KYD' },
  { key: 'KZT', value: 'Kazakhstani Tenge - KZT' },
  { key: 'LAK', value: 'Lao Kip - LAK' },
  { key: 'LBP', value: 'Lebanese Pound - LBP' },
  { key: 'LKR', value: 'Sri Lankan Rupee - LKR' },
  { key: 'LRD', value: 'Liberian Dollar - LRD' },
  { key: 'LSL', value: 'Lesotho Loti - LSL' },
  { key: 'LTL', value: 'Lithuanian Litas - LTL' },
  { key: 'LVL', value: 'Latvian Lats - LVL' },
  { key: 'LYD', value: 'Libyan Dinar - LYD' },
  { key: 'MAD', value: 'Moroccan Dirham - MAD' },
  { key: 'MDL', value: 'Moldovan Leu - MDL' },
  { key: 'MGA', value: 'Malagasy Ariary - MGA' },
  { key: 'MKD', value: 'Macedonian Denar - MKD' },
  { key: 'MMK', value: 'Myanmar Kyat - MMK' },
  { key: 'MNT', value: 'Mongolian Tögrög - MNT' },
  { key: 'MOP', value: 'Macanese Pataca - MOP' },
  { key: 'MRO', value: 'Mauritanian Ouguiya - MRO' },
  { key: 'MTL', value: 'Maltese Lira - MTL' },
  { key: 'MUR', value: 'Mauritian Rupee - MUR' },
  { key: 'MVR', value: 'Maldivian Rufiyaa - MVR' },
  { key: 'MWK', value: 'Malawian Kwacha - MWK' },
  { key: 'MXN', value: 'Mexican Peso - MXN' },
  { key: 'MYR', value: 'Malaysian Ringgit - MYR' },
  { key: 'MZN', value: 'Mozambican Metical - MZN' },
  { key: 'NAD', value: 'Namibian Dollar - NAD' },
  { key: 'NGN', value: 'Nigerian Naira - NGN' },
  { key: 'NIO', value: 'Nicaraguan Córdoba - NIO' },
  { key: 'NOK', value: 'Norwegian Krone - NOK' },
  { key: 'NPR', value: 'Nepalese Rupee - NPR' },
  { key: 'NZD', value: 'New Zealand Dollar - NZD' },
  { key: 'OMR', value: 'Omani Rial - OMR' },
  { key: 'PAB', value: 'Panamanian Balboa - PAB' },
  { key: 'PEN', value: 'Peruvian Sol - PEN' },
  { key: 'PGK', value: 'Papua New Guinean Kina - PGK' },
  { key: 'PHP', value: 'Philippine Peso - PHP' },
  { key: 'PKR', value: 'Pakistani Rupee - PKR' },
  { key: 'PLN', value: 'Polish Złoty - PLN' },
  { key: 'PYG', value: 'Paraguayan Guaraní - PYG' },
  { key: 'QAR', value: 'Qatari Riyal - QAR' },
  { key: 'RON', value: 'Romanian Leu - RON' },
  { key: 'RSD', value: 'Serbian Dinar - RSD' },
  { key: 'RUB', value: 'Russian Ruble - RUB' },
  { key: 'RWF', value: 'Rwandan Franc - RWF' },
  { key: 'SAR', value: 'Saudi Riyal - SAR' },
  { key: 'SBD', value: 'Solomon Islands Dollar - SBD' },
  { key: 'SCR', value: 'Seychellois Rupee - SCR' },
  { key: 'SDG', value: 'Sudanese Pound - SDG' },
  { key: 'SEK', value: 'Swedish Krona - SEK' },
  { key: 'SGD', value: 'Singapore Dollar - SGD' },
  { key: 'SHP', value: 'Saint Helenian Pound - SHP' },
  { key: 'SKK', value: 'Slovak Koruna - SKK' },
  { key: 'SLL', value: 'Sierra Leonean Leone - SLL' },
  { key: 'SOS', value: 'Somali Shilling - SOS' },
  { key: 'SRD', value: 'Surinamese Dollar - SRD' },
  { key: 'SSP', value: 'South Sudanese Pound - SSP' },
  { key: 'STD', value: 'São Tomé and Príncipe Dobra - STD' },
  { key: 'SVC', value: 'Salvadoran Colón - SVC' },
  { key: 'SYP', value: 'Syrian Pound - SYP' },
  { key: 'SZL', value: 'Swazi Lilangeni - SZL' },
  { key: 'THB', value: 'Thai Baht - THB' },
  { key: 'TJS', value: 'Tajikistani Somoni - TJS' },
  { key: 'TMM', value: 'Turkmenistani Manat - TMM' },
  { key: 'TMT', value: 'Turkmenistani Manat - TMT' },
  { key: 'TND', value: 'Tunisian Dinar - TND' },
  { key: 'TOP', value: 'Tongan Paʻanga - TOP' },
  { key: 'TRY', value: 'Turkish Lira - TRY' },
  { key: 'TTD', value: 'Trinidad and Tobago Dollar - TTD' },
  { key: 'TWD', value: 'New Taiwan Dollar - TWD' },
  { key: 'TZS', value: 'Tanzanian Shilling - TZS' },
  { key: 'UAH', value: 'Ukrainian Hryvnia - UAH' },
  { key: 'UGX', value: 'Ugandan Shilling - UGX' },
  { key: 'UYU', value: 'Uruguayan Peso - UYU' },
  { key: 'UZS', value: 'Uzbekistan Som - UZS' },
  { key: 'VEF', value: 'Venezuelan Bolívar - VEF' },
  { key: 'VND', value: 'Vietnamese Đồng - VND' },
  { key: 'VUV', value: 'Vanuatu Vatu - VUV' },
  { key: 'WST', value: 'Samoan Tala - WST' },
  { key: 'XAF', value: 'Central African Cfa Franc - XAF' },
  { key: 'XAG', value: 'Silver (Troy Ounce) - XAG' },
  { key: 'XAU', value: 'Gold (Troy Ounce) - XAU' },
  { key: 'XBA', value: 'European Composite Unit - XBA' },
  { key: 'XBB', value: 'European Monetary Unit - XBB' },
  { key: 'XBC', value: 'European Unit of Account 9 - XBC' },
  { key: 'XBD', value: 'European Unit of Account 17 - XBD' },
  { key: 'XCD', value: 'East Caribbean Dollar - XCD' },
  { key: 'XDR', value: 'Special Drawing Rights - XDR' },
  { key: 'XFU', value: 'UIC Franc - XFU' },
  { key: 'XOF', value: 'West African Cfa Franc - XOF' },
  { key: 'XPD', value: 'Palladium - XPD' },
  { key: 'XPF', value: 'Cfp Franc - XPF' },
  { key: 'XPT', value: 'Platinum - XPT' },
  { key: 'YER', value: 'Yemeni Rial - YER' },
  { key: 'ZAR', value: 'South African Rand - ZAR' },
  { key: 'ZMK', value: 'Zambian Kwacha - ZMK' },
  { key: 'ZMW', value: 'Zambian Kwacha - ZMW' },
  { key: 'ZWD', value: 'Zimbabwean Dollar - ZWD' },
  { key: 'ZWL', value: 'Zimbabwean Dollar - ZWL' },
  { key: 'ZWN', value: 'Zimbabwean Dollar - ZWN' },
  { key: 'ZWR', value: 'Zimbabwean Dollar - ZWR' }
];

const TimeReportCSVHeader = [
  { label: 'Activity', key: 'activityName' },
  { label: 'Project', key: 'projectName' },
  { label: 'Workers', key: 'member' },
  { label: 'Duration', key: 'duration' },
  { label: 'Time', key: 'time' },
  { label: 'Start Time', key: 'startTime' },
  { label: 'End Time', key: 'endTime' }
];

const MyTimeLogCSVHeader = [
  { label: 'Date', key: 'date' },
  { label: 'Hours', key: 'hours' },
  { label: 'Project Name', key: 'projectName' },
  { label: 'Task Name', key: 'taskName' },
  { label: 'User Name', key: 'userName' }
];

const PaymentReportCSVHeader = [
  { label: 'Invoice No', key: 'invoiceNumber' },
  { label: 'Projects', key: 'projects' },
  { label: 'Client Company', key: 'customerCompanyName' },
  { label: 'Paid On', key: 'paidOn' },
  { label: 'Paid Amount', key: 'paidAmount' }
];

const invoiceReportCSVHeader = [
  { label: 'Status', key: 'status' },
  { label: 'Paid On', key: 'paidOn' },
  { label: 'Due On', key: 'dueOn' },
  { label: 'Invoice No', key: 'invoiceNo' },
  { label: 'Projects', key: 'projects' },
  { label: 'Rate', key: 'rate' },
  { label: 'Discount', key: 'discount' }
];

const TaskReportStatusList = [
  { label: 'Pending', key: TASK_REPORT_STATUS.PENDING },
  { label: 'Completed', key: TASK_REPORT_STATUS.COMPLETED }
];

const InvoiceReportStatusList = [
  { label: 'None', key: INVOICE_STATUS_TYPE.NONE },
  { label: 'Draft', key: INVOICE_STATUS_TYPE.DRAFT },
  { label: 'Over Due', key: INVOICE_STATUS_TYPE.OVER_DUE },
  { label: 'Pending', key: INVOICE_STATUS_TYPE.PENDING },
  { label: 'Partially Paid', key: INVOICE_STATUS_TYPE.PARTIALLY_PAID },
  { label: 'Paid', key: INVOICE_STATUS_TYPE.PAID }
];

const TaskReportDateList = [
  { label: 'Due', key: TASK_REPORT_TIME_TYPE.PAST },
  { label: 'Future', key: TASK_REPORT_TIME_TYPE.FUTURE },
  { label: 'No Due Date', key: TASK_REPORT_TIME_TYPE.NODATE }
];

const TeamActivityStatusList = [
  { label: 'Task created', key: 0 },
  { label: 'Task completed', key: 1 },
  { label: 'Task re-opened', key: 2 },
  { label: 'Change due date', key: 3 },
  { label: 'Change group', key: 4 }
];

const PrioritiesList = [
  { key: TASK_PRIORITY.NO_PRIORITY, title: 'No Priority', icon: 'Priority-No-priority-icon' },
  { key: TASK_PRIORITY.URGENT, title: 'Urgent', icon: 'Priority-Urgent-icon' },
  { key: TASK_PRIORITY.HIGH, title: 'High', icon: 'Priority-High-icon' },
  { key: TASK_PRIORITY.MEDIUM, title: 'Medium', icon: 'Priority-Medium-icon' },
  { key: TASK_PRIORITY.LOW, title: 'Low', icon: 'Priority-low-icon' }
];
const menuEditItems = [
  { key: MENU_OPTIONS.EDIT, label: 'Edit' },
  { key: MENU_OPTIONS.ARCHIVE, label: 'Archive' }
];
const menuRestoreItems = [
  { key: MENU_OPTIONS.RESTORE, label: 'Restore' },
  { key: MENU_OPTIONS.DELETE, label: 'Delete' }
];

// Define your menu items for CommonDropdownMenu
const menuItems = [
  { key: 4, label: 'None' },
  { key: 5, label: 'Group' },
  { key: 1, label: 'Due Date' },
  { key: 2, label: 'Project' },
  { key: 3, label: 'Priority' }
];

const completedTaskMenu = [
  { key: SHOW_COMPLETED_TASK_BY_DATE.ALL, label: 'All' },
  { key: SHOW_COMPLETED_TASK_BY_DATE.YESTERDAY, label: 'Yesterday' },
  { key: SHOW_COMPLETED_TASK_BY_DATE.LAST_WEEK, label: 'Last week' },
  { key: SHOW_COMPLETED_TASK_BY_DATE.LAST_MONTH, label: 'Last month' },
  { key: SHOW_COMPLETED_TASK_BY_DATE.NONE, label: 'None' }
];

const invoiceDetailMoreMenu = [
  { key: MENU_OPTIONS.EDIT, label: 'Edit' },
  { key: MENU_OPTIONS.DUPLICATE, label: 'Duplicate' },
  { key: MENU_OPTIONS.DELETE, label: 'Delete' }
];
export {
  teamMember,
  industryList,
  randomColorsArray,
  currencySymbols,
  CurrencyList,
  TimeReportCSVHeader,
  TaskReportStatusList,
  TaskReportDateList,
  InvoiceReportStatusList,
  PaymentReportCSVHeader,
  invoiceReportCSVHeader,
  TeamActivityStatusList,
  MyTimeLogCSVHeader,
  PrioritiesList,
  completedTaskMenu,
  menuEditItems,
  menuRestoreItems,
  menuItems,
  invoiceDetailMoreMenu
};
