import React, { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  ArrowIcon,
  Bottomcontent,
  Bottomtag,
  Content,
  Div,
  Dot,
  Doticon,
  Emptybox,
  Firsthistory,
  Heading,
  Icon,
  Leftcontent,
  Line,
  Modalbox,
  Rightcontent,
  Subhistory,
  Subtext,
  Text
} from './styles';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import EmptyState from '../../emptyState';
import versionhistoryempty from '../../../assets/images/emptystates/emptyversionhistory.svg';
import darkversionhistoryempty from '../../../assets/images/emptystates/darkemptyversionhistory.svg';
import { useSelector, useDispatch } from 'react-redux';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import Button from '../../button';
import { DocDataInterface, DocVersion, DocVersionList } from '../../../interfaces/ProjectInterface';
import moment from 'moment';
import { COLOR_THEME_TYPE } from '../../../global/constants';
import { setSuccessMessage } from '../../../actions/messageActions';
import { getDocVersion, getDocVersionList } from '../../../services/docServices';
import { REACT_APP_BLOCKSUITE_URL } from '../../../global/environment';

interface Props {
  onClose: () => void;
  docUrl: string;
  docData: DocDataInterface;
  mainIframe: RefObject<HTMLIFrameElement>;
}

interface HistoryItem {
  date: string;
  times: Version[];
}
interface Version {
  time: string;
  id: number;
}

const versionsCache = new Map<number, string>();

export default function VersionHistoryModal(props: Props) {
  const { onClose, mainIframe, docData, docUrl } = props;
  //Refs
  const iframeRef = useRef<HTMLIFrameElement>(null);
  //States
  const [isEmptyStateVisible, setIsEmptyStateVisible] = useState(false);
  const [isHistoryShow, setIsHistoryShow] = useState<{ [key: string]: boolean }>({});
  const [historyData, setHistoryData] = useState<HistoryItem[]>([]);
  const [activeVersion, setActiveVersion] = useState<Version>({ id: -1, time: '' });
  const [loading, setLoading] = useState(false);
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { settings: settingsSelector, workspace: workspaceSelector } = stateSelector || {};
  const { themeMode } = settingsSelector;
  const { workspace } = workspaceSelector;
  //Other variable
  const getImageSource = () => (themeMode?.theme === 'dark' ? darkversionhistoryempty : versionhistoryempty);
  const dispatch = useDispatch();
  const isDarkMode = settingsSelector.themeMode.theme === COLOR_THEME_TYPE.DARK;

  useEffect(() => {
    const lodDocVersions = async () => {
      const docVersions = (await dispatch(getDocVersionList(docData.id))) as DocVersionList;

      if (docVersions.length <= 0) {
        setIsEmptyStateVisible(true);
        return;
      }

      const formattedDocVersions = formatDocVersions(docVersions);

      setHistoryData(formattedDocVersions);
      setActiveVersion(formattedDocVersions[0].times[0]);

      const docVersion = (await dispatch(getDocVersion(formattedDocVersions[0].times[0].id))) as DocVersion;

      iframeRef.current?.contentWindow?.postMessage(
        { type: 'update_content', payload: docVersion.Content },
        `${REACT_APP_BLOCKSUITE_URL}`
      );
    };

    const listner = (ev: MessageEvent<any>) => {
      const { type } = ev.data;
      if (type === 'history-restore-complete') {
        setTimeout(() => {
          setLoading(false);
          dispatch(setSuccessMessage('Document Restored Successfully'));
        }, 300);
      }
    };

    window.addEventListener('message', listner);
    lodDocVersions();

    return () => window.removeEventListener('message', listner);
  }, [dispatch, docData.id]);

  const formatDocVersions = (docVersions: DocVersionList) => {
    const dateMap = new Map<string, HistoryItem>();

    for (const version of docVersions) {
      const today = new Date();
      const versionDate = new Date(version.createdAt);

      const isToday = today.toDateString() === versionDate.toDateString();

      const date = isToday ? 'Today' : moment(versionDate).format('ddd, DD MMM YYYY');
      const time = moment(versionDate).format('h:mm a');

      const timeItem = { time, id: version.id };

      if (dateMap.has(date)) {
        const historyItem = dateMap.get(date);
        if (historyItem)
          dateMap.set(date, {
            ...historyItem,
            times: [...(historyItem?.times || []), timeItem]
          });
      } else {
        dateMap.set(date, { date, times: [timeItem] });
      }
    }

    return Array.from(dateMap.values());
  };

  const handleHistoryClick = (section: string) => {
    setIsHistoryShow((prevSections) => ({
      ...prevSections,
      [section]: !prevSections[section]
    }));
  };

  const handleVersionClick = async (version: Version) => {
    setActiveVersion(version);
  };

  const fetchAndPostActiveVersion = useCallback(
    async (versionId: number) => {
      let content: string;
      if (versionsCache.has(versionId)) {
        content = versionsCache.get(versionId) || '';
      } else {
        const docVersion = (await dispatch(getDocVersion(versionId))) as DocVersion;
        content = docVersion.Content;
        versionsCache.set(versionId, content);
      }
      iframeRef.current?.contentWindow?.postMessage(
        { type: 'update_content', payload: content },
        `${REACT_APP_BLOCKSUITE_URL}`
      );
    },
    [dispatch]
  );

  const updateContent = () => {
    setLoading(true);
    mainIframe.current?.contentWindow?.postMessage(
      { type: 'request_version_restore', payload: activeVersion.id },
      `${REACT_APP_BLOCKSUITE_URL}`
    );

    if (mainIframe.current) {
      mainIframe.current.onload = () => {
        setLoading(false);
        onClose();
      };
    }
  };

  const docIframe = useMemo(
    () => (
      <iframe
        key={activeVersion.id}
        ref={iframeRef}
        src={`${docUrl}/viewer?workspace_id=${workspace.id}&theme=${isDarkMode ? 'dark' : 'light'}&isEdge=${
          docData.isEdge
        }`}
        height={500}
        width={'100%'}
        title='teamcamp doc'
        className='docs-section'
        allow='clipboard-read; clipboard-write'
        onLoad={() => activeVersion.id !== -1 && fetchAndPostActiveVersion(activeVersion.id)}
      />
    ),
    [activeVersion.id, fetchAndPostActiveVersion, isDarkMode, docData.isEdge, docUrl, workspace.id]
  );

  return (
    <Modalbox>
      <Icon onClick={onClose} isEmptyStateVisible={isEmptyStateVisible}>
        <SVGIcon name='file-close-icon' width='24' height='24' viewBox='0 0 24 24' className='fill-color' />
      </Icon>
      <Content>
        <Leftcontent>{docIframe}</Leftcontent>
        <Rightcontent>
          <Heading>Version History</Heading>
          <Bottomcontent>
            {historyData?.map((history) => (
              <React.Fragment key={history?.date}>
                <Firsthistory onClick={() => handleHistoryClick(history?.date)}>
                  <ArrowIcon>
                    <SVGIcon
                      name='right-arrows-icon'
                      width='16'
                      height='16'
                      viewBox='0 0 16 16'
                      className={isHistoryShow[history?.date] ? 'rotated fill-color' : 'fill-color'}
                    />
                  </ArrowIcon>
                  <Text>{history?.date}</Text>
                </Firsthistory>
                {isHistoryShow[history?.date] && (
                  <Div>
                    {history?.times.map((time) => (
                      <Subhistory
                        key={time?.id}
                        onClick={() => handleVersionClick(time)}
                        isActive={time?.id === activeVersion?.id}>
                        <Doticon>
                          <Line />
                          <Dot isActive={time?.id === activeVersion?.id} />
                        </Doticon>
                        <Subtext>{time?.time}</Subtext>
                      </Subhistory>
                    ))}
                  </Div>
                )}
              </React.Fragment>
            ))}
          </Bottomcontent>
          <Bottomtag>
            <Button title='Restore Current Version' milestone={true} isLoading={loading} onClick={updateContent} />
          </Bottomtag>
        </Rightcontent>
      </Content>
      {/* Emptystate ui */}
      {isEmptyStateVisible && (
        <Emptybox>
          <EmptyState
            header='No document history yet'
            title='Your document history is currently empty.'
            image={getImageSource()}
            hideButton={true}
          />
        </Emptybox>
      )}
    </Modalbox>
  );
}
