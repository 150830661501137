import React, { createContext, useContext, ReactNode, useEffect, useState, useCallback } from 'react';
import { useReactMediaRecorder } from 'react-media-recorder';

export interface MediaRecorderContextProps {
  status: string;
  startRecording: () => void;
  stopRecording: () => void;
  mediaBlobUrl: string | null;
  clearBlobUrl: () => void;
  duration: number;
  setSelectedAudioDeviceId: (deviceId: string) => void;
  audioInputDevices: MediaDeviceInfo[];
  selectedAudioDeviceLabel: string;
  updateDevices: () => void;
}

const MediaRecorderContext = createContext<MediaRecorderContextProps | undefined>(undefined);

interface MediaRecorderProviderProps {
  children: ReactNode;
}

const MediaRecorderProvider: React.FC<MediaRecorderProviderProps> = ({ children }) => {
  //State
  const [audioInputDevices, setAudioInputDevices] = useState<MediaDeviceInfo[]>([]);
  const [selectedAudioDeviceId, setSelectedAudioDeviceId] = useState<string>('none');
  const [selectedAudioDeviceLabel, setSelectedAudioDeviceLabel] = useState<string>('No Microphone');

  const updateDevices = useCallback(async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const permissionStatus = await navigator.permissions.query({ name: 'microphone' as PermissionName });
    if (permissionStatus.state === 'granted') {
      const audioDevices = devices.filter((device) => device.kind === 'audioinput');
      setAudioInputDevices(audioDevices);
      if (audioDevices.length > 0) {
        const defaultDevice = audioDevices.find((device) => device.deviceId === 'default');
        const deviceToSelect = defaultDevice || audioDevices[0];
        setSelectedAudioDeviceId(deviceToSelect.deviceId);
        setSelectedAudioDeviceLabel(deviceToSelect.label || 'Default Microphone');
      } else {
        setSelectedAudioDeviceId('');
        setSelectedAudioDeviceLabel('No Microphone');
      }
    } else {
      setAudioInputDevices([]);
      setSelectedAudioDeviceId('');
      setSelectedAudioDeviceLabel('No Microphone');
    }
  }, []);

  useEffect(() => {
    // Initial device check
    updateDevices();

    // Event listener for changes in media devices
    navigator.mediaDevices.addEventListener('devicechange', updateDevices);

    // Cleanup function
    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', updateDevices);
    };
  }, [updateDevices]);

  const mediaRecorder = useReactMediaRecorder({
    screen: true,
    video: true,
    audio:
      audioInputDevices.length > 0 && selectedAudioDeviceId !== 'none' ? { deviceId: selectedAudioDeviceId } : false
  });

  const [realTimeDuration, setRealTimeDuration] = useState<number>(0);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (mediaRecorder.status === 'recording') {
      intervalId = setInterval(() => {
        setRealTimeDuration((prevDuration) => prevDuration + 1);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [mediaRecorder.status]);

  useEffect(() => {
    if (mediaRecorder.status === 'stopped') {
      setRealTimeDuration(0);
    }
  }, [mediaRecorder.status]);

  return (
    <MediaRecorderContext.Provider
      value={{
        ...mediaRecorder,
        duration: realTimeDuration,
        setSelectedAudioDeviceId: (deviceId: string) => {
          const selectedDevice = audioInputDevices.find((device) => device.deviceId === deviceId);
          setSelectedAudioDeviceId(deviceId);
          setSelectedAudioDeviceLabel(selectedDevice ? selectedDevice.label : 'No Microphone');
        },
        updateDevices: () => updateDevices(),

        audioInputDevices,
        selectedAudioDeviceLabel
      }}>
      {children}
    </MediaRecorderContext.Provider>
  );
};

export default MediaRecorderProvider;

export const useMediaRecorder = (): MediaRecorderContextProps => {
  const context = useContext(MediaRecorderContext);
  if (!context) {
    throw new Error('useMediaRecorder must be used within a MediaRecorderProvider');
  }
  return context;
};
