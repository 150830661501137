import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getUrlParam, isEmpty } from '../../../helpers/common';
import { useDispatch, useSelector } from 'react-redux';
import { figmaConnectRedirect } from '../../../services/settingServices';
import { ROUTES } from '../../../routes/urls';
import AppLayout from '../../../component/appLayout';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';

function FigmaConnectRedirect() {
  //use selector state variables
  const workspaceSelector = useSelector((state: RootReducerInterface) => state.workspace);
  const { workspace } = workspaceSelector;
  const { id: workspace_id } = workspace;
  // Other variables
  const history = useHistory();
  const dispatch = useDispatch();
  const code = getUrlParam(history.location.search, 'code');
  const state = getUrlParam(history.location.search, 'state');

  const loadData = useCallback(async () => {
    if (!isEmpty(workspace_id)) {
      const paramsObj = {
        state: isEmpty(state) ? undefined : state,
        code: code
      };
      await dispatch(figmaConnectRedirect(paramsObj, workspace_id));
      history.push(ROUTES.INTEGRATIONS_SETTING);
    }
  }, [code, dispatch, history, state, workspace_id]);

  useEffect(() => {
    loadData();
  }, [loadData]);
  return <AppLayout>Loading ...</AppLayout>;
}

export default FigmaConnectRedirect;
