import React from 'react';
import SVGIcon from '../../assets/images/svg/SVGIcon';
import { Buttons, ButtonWrap } from './styles';

interface Props {
  title?: string;
  onClick?: any;
  isLoading?: boolean;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  size?: any;
  secondary?: boolean;
  iconSize?: any;
  iconName?: string;
  isDelete?: boolean;
  width?: string;
  iconColor?: string;
  strokeWidth?: number;
  style?: any;
  iconViewBox?: string;
  iconStyle?: string;
  resWidth?: any;
  modelbtn?: boolean;
  commentbtn?: boolean;
  milestone?: boolean;
  isSearchModelbtn?: boolean;
  isActive?: boolean;
  className?: string;
  isMicmodalbtn?: boolean;
  isCancelbtn?: boolean;
  smallbutton?: boolean;
  hasNotBoxshadow?: boolean;
  isPadding?: boolean;
  isMemberTask?: boolean;
  isRecordButton?: boolean;
  invoiceDetailButton?: boolean;
}

const Button: React.FC<Props> = (Props) => {
  const {
    title,
    size,
    disabled,
    type,
    onClick,
    secondary = false,
    iconSize,
    iconName,
    isLoading,
    isDelete = false,
    width,
    iconColor,
    style,
    strokeWidth,
    iconViewBox,
    iconStyle,
    resWidth,
    modelbtn = false,
    commentbtn,
    isSearchModelbtn,
    isActive,
    className,
    isMicmodalbtn,
    isCancelbtn,
    smallbutton,
    hasNotBoxshadow,
    isPadding = false,
    isMemberTask = false,
    isRecordButton = false,
    invoiceDetailButton = false,
    ...props
  } = Props;
  // const getIconStrokeColor = () => {
  //   if (disabled) {
  //     return '#90989A';
  //   }
  //   if (secondary) {
  //     return '#000000';
  //   }
  //   return '';
  // };

  return (
    <ButtonWrap
      isLoading={isLoading}
      width={width}
      style={style}
      isDelete={isDelete}
      resWidth={resWidth}
      className={className}
      isMicmodalbtn={isMicmodalbtn}
      isRecordButton={isRecordButton}>
      <Buttons
        onClick={onClick}
        secondary={secondary}
        isDelete={isDelete}
        disabled={disabled || isLoading}
        isLoading={isLoading}
        size={size}
        type={type}
        modelbtn={modelbtn}
        commentbtn={commentbtn}
        isSearchModelbtn={isSearchModelbtn}
        isActive={isActive}
        className={className}
        isMicmodalbtn={isMicmodalbtn}
        isCancelbtn={isCancelbtn}
        smallbutton={smallbutton}
        hasNotBoxshadow={hasNotBoxshadow}
        isPadding={isPadding}
        isMemberTask={isMemberTask}
        invoiceDetailButton={invoiceDetailButton}
        {...props}>
        {iconName && (
          <SVGIcon
            name={iconName}
            height={iconSize}
            width={iconSize}
            // stroke={getIconStrokeColor()}
            fill={iconColor}
            strokeWidth={strokeWidth}
            viewBox={iconViewBox}
            style={iconStyle}
          />
        )}
        {title || ''}
      </Buttons>
    </ButtonWrap>
  );
};

export default Button;
