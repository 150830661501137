import { useParams } from 'react-router-dom';
import { Monitoring } from 'react-scan/monitoring'; // Import this first before React
import history from '../history';

export default function ReactScanMonitoring() {
  const params = useParams(); // i.e { projectId: "123" }
  const { pathname } = history.location; // i.e /project/123/page

  return (
    // .. rest of your components
    <Monitoring
      apiKey='18c7GJ2j_g61anoGwno_wK-iAgXw7PUA' // Safe to expose publically
      url='https://monitoring.react-scan.com/api/v1/ingest'
      commit={process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA} // optional but recommended
      branch={process.env.REACT_APP_VERCEL_GIT_COMMIT_REF} // optional but recommended
      params={params}
      path={pathname}
    />
    // ... rest of your components
  );
}
