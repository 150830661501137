import React, { useCallback, useState } from 'react';
import {
  Box,
  Btn,
  ConnectedDiv,
  Desc,
  Description,
  Leftcontent,
  Logo,
  Rightcontent,
  Tag,
  Tagbtn,
  Title,
  View
} from './styles';
import Button from '../../component/button';
import { REACT_APP_FIGMA_OAUTH_URL, REACT_APP_SENTRY_URL } from '../../global/environment';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../routes/urls';
import { captureException } from '../../services/logService';
import { useDispatch, useSelector } from 'react-redux';
import { disconnectFigmaAccount, disconnectStripe } from '../../services/settingServices';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';
import SVGIcon from '../../assets/images/svg/SVGIcon';

interface Props {
  title: string;
  description: string;
  isConnect?: boolean;
  isConnected?: boolean;
  isCommingsoontag?: boolean;
  isLearnmorebtn?: boolean;
  isLast?: boolean;
  loadData: Function;
  IntegrationsLogoname?: string;
}

export default function Integrationbox(props: Props) {
  //State
  const [loading, setLoading] = useState(false);

  //Other variable
  const history = useHistory();
  const dispatch = useDispatch();

  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { workspace: workspaceSelector } = stateSelector || {};
  const { workspace } = workspaceSelector;

  // connect with figma
  const figmaConnect = useCallback(() => {
    try {
      setLoading(true);
      const newTab = window.open(REACT_APP_FIGMA_OAUTH_URL, '_blank');
      if (newTab) {
        newTab.focus();
      } else {
        alert('Please allow pop-ups for this site to open in a new tab.');
      }
    } catch (e) {
      captureException(e);
    } finally {
      setLoading(false);
    }
  }, []);

  // connect with figma
  const sentryConnect = useCallback(() => {
    try {
      setLoading(true);
      const newTab = window.open(REACT_APP_SENTRY_URL, '_blank');
      if (newTab) {
        newTab.focus();
      } else {
        alert('Please allow pop-ups for this site to open in a new tab.');
      }
    } catch (e) {
      captureException(e);
    } finally {
      setLoading(false);
    }
  }, []);

  // Disconnect with figma
  const sentryDisconnect = useCallback(() => {
    try {
      setLoading(true);
      const newTab = window.open('https://sentry.io/settings', '_blank');
      if (newTab) {
        newTab.focus();
      } else {
        alert('Please allow pop-ups for this site to open in a new tab.');
      }
    } catch (e) {
      captureException(e);
    } finally {
      setLoading(false);
    }
  }, []);

  // disconnect from figma
  const figmaDisconnect = useCallback(async () => {
    const result = await dispatch(disconnectFigmaAccount());
    if (result) {
      props?.loadData();
    }
  }, [dispatch, props]);

  // disconnect from stripe
  const stripeDisconnect = useCallback(async () => {
    const result = await dispatch(disconnectStripe(workspace?.id));
    if (result) {
      props?.loadData();
    }
  }, [dispatch, props, workspace?.id]);

  // connect with different platform
  const onClickConnect = useCallback(() => {
    if (props?.title === 'Figma') {
      figmaConnect();
    } else if (props?.title === 'Github') {
      history.push(`integration/github`);
    } else if (props?.title === 'Sentry') {
      sentryConnect();
    }
  }, [figmaConnect, history, props?.title, sentryConnect]);

  // redirect on learn more about platform
  const onClickLearnMore = useCallback(() => {
    if (props?.title === 'Stripe') {
      history.push(ROUTES.PAYMENT_SETTING);
    } else if (props?.title === 'Zapier') {
      window.open('https://zapier.com/apps/teamcamp/integrations', '_blank', 'noopener,noreferrer');
    } else if (props?.title === 'Api') {
      window.open('https://api.teamcamp.app', '_blank', 'noopener,noreferrer');
    }
  }, [history, props?.title]);

  // disconnect from different platform
  const onClickDisconnect = useCallback(() => {
    try {
      setLoading(true);
      if (props?.title === 'Figma') figmaDisconnect();
      else if (props?.title === 'Stripe') stripeDisconnect();
      else if (props?.title === 'Sentry') sentryDisconnect();
    } catch (e) {
      captureException(e);
    } finally {
      setLoading(false);
    }
  }, [figmaDisconnect, props?.title, sentryDisconnect, stripeDisconnect]);

  return (
    <div>
      <Box isLast={props.isLast}>
        <Leftcontent>
          <Logo>
            <SVGIcon name={props.IntegrationsLogoname} width='28' height='28' viewBox='28' />
          </Logo>
          <Description>
            <Title>{props.title}</Title>
            <Desc>{props.description}</Desc>
          </Description>
        </Leftcontent>
        <Rightcontent>
          {props.isConnect && (
            <Button title='Connect' secondary={true} onClick={onClickConnect} disabled={loading} smallbutton={true} />
          )}
          {props.isConnected && (
            <ConnectedDiv>
              <Tag className='connect-button'>Connected</Tag>
              <View className='disconnect-button'>
                <Button
                  title='Disconnect'
                  secondary={true}
                  onClick={onClickDisconnect}
                  disabled={loading}
                  isLoading={loading}
                />
              </View>
            </ConnectedDiv>
          )}
          {props.isCommingsoontag && <Tagbtn>Coming soon</Tagbtn>}
          {props.isLearnmorebtn && <Btn onClick={onClickLearnMore}>Learn more</Btn>}
        </Rightcontent>
      </Box>
    </div>
  );
}
