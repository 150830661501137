import styled from 'styled-components';

const Icon = styled.div`
  display: inline-flex;
  cursor: pointer;
  :hover {
    background-color: var(--neutral-secondary);
    border-radius: 4px;
  }
  .moreicon {
    fill: var(--text-secondary);
    stroke: var(--text-secondary);
  }
`;
export { Icon };
