import { Icon } from '../../component/navbar/responsivenavbar/styles';
import SVGIcon from '../../assets/images/svg/SVGIcon';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../routes/urls';

export const ResponsiveHeader = () => {
  const history = useHistory();

  const onClickBackIcon = useCallback(
    (path: string) => {
      history.push(path);
    },
    [history]
  );

  return (
    <>
      <Icon onClick={() => onClickBackIcon(ROUTES.SETTING)}>
        <SVGIcon
          name='responsive-back-icon'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          className='line-icon'
        />
      </Icon>
    </>
  );
};
