import React from 'react';
import {
  Box,
  Circle,
  Circlediv,
  Div,
  First,
  Line,
  Longline,
  Main,
  Second,
  Shortline,
  Smallcircle,
  Third,
  UserLogo
} from './styles';

export default function ProjectallLoading() {
  return (
    <div>
      <Main>
        <Box>
          <First>
            <Circlediv>
              <Circle className='loading-animation'></Circle>
            </Circlediv>
            <Div>
              <Longline className='loading-animation'></Longline>
              <Shortline className='loading-animation'></Shortline>
            </Div>
          </First>
          <Second>
            <Smallcircle className='loading-animation'></Smallcircle>
            <Line className='loading-animation'></Line>
          </Second>
          <Third>
            <UserLogo className='loading-animation'></UserLogo>
            <UserLogo className='loading-animation'></UserLogo>
            <UserLogo className='loading-animation'></UserLogo>
            <UserLogo className='loading-animation'></UserLogo>
          </Third>
        </Box>
        <Box>
          <First>
            <Circlediv>
              <Circle className='loading-animation'></Circle>
            </Circlediv>
            <Div>
              <Longline className='loading-animation'></Longline>
              <Shortline className='loading-animation'></Shortline>
            </Div>
          </First>
          <Second>
            <Smallcircle className='loading-animation'></Smallcircle>
            <Line className='loading-animation'></Line>
          </Second>
          <Third>
            <UserLogo className='loading-animation'></UserLogo>
            <UserLogo className='loading-animation'></UserLogo>
            <UserLogo className='loading-animation'></UserLogo>
            <UserLogo className='loading-animation'></UserLogo>
          </Third>
        </Box>
        <Box>
          <First>
            <Circlediv>
              <Circle className='loading-animation'></Circle>
            </Circlediv>
            <Div>
              <Longline className='loading-animation'></Longline>
              <Shortline className='loading-animation'></Shortline>
            </Div>
          </First>
          <Second>
            <Smallcircle className='loading-animation'></Smallcircle>
            <Line className='loading-animation'></Line>
          </Second>
          <Third>
            <UserLogo className='loading-animation'></UserLogo>
            <UserLogo className='loading-animation'></UserLogo>
            <UserLogo className='loading-animation'></UserLogo>
            <UserLogo className='loading-animation'></UserLogo>
          </Third>
        </Box>
        <Box>
          <First>
            <Circlediv>
              <Circle className='loading-animation'></Circle>
            </Circlediv>
            <Div>
              <Longline className='loading-animation'></Longline>
              <Shortline className='loading-animation'></Shortline>
            </Div>
          </First>
          <Second>
            <Smallcircle className='loading-animation'></Smallcircle>
            <Line className='loading-animation'></Line>
          </Second>
          <Third>
            <UserLogo className='loading-animation'></UserLogo>
            <UserLogo className='loading-animation'></UserLogo>
            <UserLogo className='loading-animation'></UserLogo>
            <UserLogo className='loading-animation'></UserLogo>
          </Third>
        </Box>
        <Box>
          <First>
            <Circlediv>
              <Circle className='loading-animation'></Circle>
            </Circlediv>
            <Div>
              <Longline className='loading-animation'></Longline>
              <Shortline className='loading-animation'></Shortline>
            </Div>
          </First>
          <Second>
            <Smallcircle className='loading-animation'></Smallcircle>
            <Line className='loading-animation'></Line>
          </Second>
          <Third>
            <UserLogo className='loading-animation'></UserLogo>
            <UserLogo className='loading-animation'></UserLogo>
            <UserLogo className='loading-animation'></UserLogo>
            <UserLogo className='loading-animation'></UserLogo>
          </Third>
        </Box>
        <Box>
          <First>
            <Circlediv>
              <Circle className='loading-animation'></Circle>
            </Circlediv>
            <Div>
              <Longline className='loading-animation'></Longline>
              <Shortline className='loading-animation'></Shortline>
            </Div>
          </First>
          <Second>
            <Smallcircle className='loading-animation'></Smallcircle>
            <Line className='loading-animation'></Line>
          </Second>
          <Third>
            <UserLogo className='loading-animation'></UserLogo>
            <UserLogo className='loading-animation'></UserLogo>
            <UserLogo className='loading-animation'></UserLogo>
            <UserLogo className='loading-animation'></UserLogo>
          </Third>
        </Box>
        <Box>
          <First>
            <Circlediv>
              <Circle className='loading-animation'></Circle>
            </Circlediv>
            <Div>
              <Longline className='loading-animation'></Longline>
              <Shortline className='loading-animation'></Shortline>
            </Div>
          </First>
          <Second>
            <Smallcircle className='loading-animation'></Smallcircle>
            <Line className='loading-animation'></Line>
          </Second>
          <Third>
            <UserLogo className='loading-animation'></UserLogo>
            <UserLogo className='loading-animation'></UserLogo>
            <UserLogo className='loading-animation'></UserLogo>
            <UserLogo className='loading-animation'></UserLogo>
          </Third>
        </Box>
        <Box>
          <First>
            <Circlediv>
              <Circle className='loading-animation'></Circle>
            </Circlediv>
            <Div>
              <Longline className='loading-animation'></Longline>
              <Shortline className='loading-animation'></Shortline>
            </Div>
          </First>
          <Second>
            <Smallcircle className='loading-animation'></Smallcircle>
            <Line className='loading-animation'></Line>
          </Second>
          <Third>
            <UserLogo className='loading-animation'></UserLogo>
            <UserLogo className='loading-animation'></UserLogo>
            <UserLogo className='loading-animation'></UserLogo>
            <UserLogo className='loading-animation'></UserLogo>
          </Third>
        </Box>
        <Box>
          <First>
            <Circlediv>
              <Circle className='loading-animation'></Circle>
            </Circlediv>
            <Div>
              <Longline className='loading-animation'></Longline>
              <Shortline className='loading-animation'></Shortline>
            </Div>
          </First>
          <Second>
            <Smallcircle className='loading-animation'></Smallcircle>
            <Line className='loading-animation'></Line>
          </Second>
          <Third>
            <UserLogo className='loading-animation'></UserLogo>
            <UserLogo className='loading-animation'></UserLogo>
            <UserLogo className='loading-animation'></UserLogo>
            <UserLogo className='loading-animation'></UserLogo>
          </Third>
        </Box>
        <Box>
          <First>
            <Circlediv>
              <Circle className='loading-animation'></Circle>
            </Circlediv>
            <Div>
              <Longline className='loading-animation'></Longline>
              <Shortline className='loading-animation'></Shortline>
            </Div>
          </First>
          <Second>
            <Smallcircle className='loading-animation'></Smallcircle>
            <Line className='loading-animation'></Line>
          </Second>
          <Third>
            <UserLogo className='loading-animation'></UserLogo>
            <UserLogo className='loading-animation'></UserLogo>
            <UserLogo className='loading-animation'></UserLogo>
            <UserLogo className='loading-animation'></UserLogo>
          </Third>
        </Box>
      </Main>
    </div>
  );
}
