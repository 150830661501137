import styled from 'styled-components';
import { Typography } from '../../global/theme';

const TiptapMain = styled.div`
  .figma-main-div {
    pointer-events: none;
    -webkit-user-modify: read-only;
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background-color: var(--background-secondary);
    border: 0.5px solid var(--border-primary);
    width: fit-content;
    overflow: hidden;
    margin-top: 12px;
  }
  .figma-desc-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }
  .figma-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    padding: 8px;
    border-bottom: 0.5px solid var(--border-primary);
  }
  .figma-link-div {
    pointer-events: all !important;
    padding: 5px 16px;
    background-color: var(--background-primary);
    color: var(--text-primary);
    border: 0.5px solid var(--border-primary);
    cursor: pointer;
    text-decoration: none;
    border-radius: 36px;
    ${Typography.body_sm_medium}
    white-space: nowrap;
    &:hover {
      background-color: var(--background-secondary);
      border-radius: 36px;
      text-decoration: none;
    }
  }
  .figma-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }
  .figma-description {
    color: var(--text-primary);
    ${Typography.body_sm_medium}
  }
  .figma-filename {
    color: var(--text-secondary);
    ${Typography.body_sm_medium}
  }
  .figma-icon {
    width: 24px;
    height: 24px;
  }
  .figma-preview-image {
    border-radius: 0px !important;
    border-right: 0.5px solid var(--border-primary);
    border-left: 0.5px solid var(--border-primary);
    max-width: 100%;
    width: auto;
    max-height: 419px;
    height: auto;
    margin: 0 auto;
    display: flex;
  }
  @media (max-width: 449px) {
    .figma-main-div .ant-image .ant-image-img {
      border-right: unset !important;
      border-left: unset !important;
    }
  }
  .figma-right-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }
  .figma-animation {
    width: 24px;
    height: 24px;
    display: flex;
    .refresh-icon-spin {
      animation-name: spin;
      animation-duration: 1000ms;
      animation-iteration-count: infinite;

      -webkit-animation-name: spin;
      -webkit-animation-duration: 1000ms;
      -webkit-animation-iteration-count: infinite;
    }
    @-ms-keyframes spin {
      from {
        -ms-transform: rotate(0deg);
      }
      to {
        -ms-transform: rotate(360deg);
      }
    }
    @-moz-keyframes spin {
      from {
        -moz-transform: rotate(0deg);
      }
      to {
        -moz-transform: rotate(360deg);
      }
    }
    @-webkit-keyframes spin {
      from {
        -webkit-transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(360deg);
      }
    }
    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
  .refresh-icon {
    width: 18px;
    height: 18px;
    max-width: 100%;
    max-height: 18px;
    cursor: pointer;
    padding: 3px;
  }

  .cleanshot-video-main-div {
    -webkit-user-modify: read-only;
    border: 0.5px solid var(--border-primary);
    border-radius: 8px;
    padding: 8px;
    max-width: 354px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
    background-color: var(--background-primary);
    margin-top: 12px;
  }
  .screen-recording-custom-main-div {
    -webkit-user-modify: read-only;
    border: 0.5px solid var(--border-primary);
    border-radius: 8px;
    max-width: 354px;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--background-primary);
    margin-top: 12px;
  }
  .screen-recording-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 8px;
    border-bottom: 0.5px solid var(--border-primary);
    gap: 4px;
  }
  .e-rte-video .e-video-inline {
    width: 355px !important;
  }
  .screen-recording-name {
    color: var(--text-primary);
    ${Typography.body_sm_medium}
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    word-break: break-all;
  }
  .screen-recording-right-div {
    display: flex;
    gap: 8px;
  }
  .open-video-icon {
    max-width: 100%;
    max-height: 100%;
  }
  .video-more-icon {
    max-width: 100%;
    max-height: 100%;
  }
  .inbox-video {
    max-width: 353px;
    width: 100%;
    height: auto;
    border-radius: 0 0 6px 6px;
  }
  .screen-recording-animation {
    width: 16px;
    height: 16px;
  }
  .screen-recording-more {
    width: 16px;
    height: 16px;
  }
  .inbox-image {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .video-icon {
    position: absolute;
    top: 45%;
    left: 45%;
  }
  .prnt-desc-div {
    display: flex;
    gap: 2px;
    align-items: center;
  }
  .prnt-icon {
    margin: 0;
  }
  .prnt-url-link {
    cursor: pointer;
    ${Typography.body_sm_medium}
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    word-break: break-all;
    max-width: 300px;
  }
  .prnt-text {
    padding: 0 4px;
    :hover {
      background-color: var(--neutral-secondary);
      border-radius: 4px;
    }
  }
  .prnt-preview-main-div {
    -webkit-user-modify: read-only;
    display: flex;
    flex-direction: column;
    width: fit-content;
    border-radius: 8px;
    border: 0.5px solid var(--border-primary);
    background-color: var(--background-primary);
    position: relative;
    z-index: 1;
    margin-top: 12px;
  }
  .prnt-preview-header {
    padding: 8px;
    border-bottom: 0.5px solid var(--border-primary);
  }
  .cleanshot-image-main-div {
    -webkit-user-modify: read-only;
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: fit-content;
    border-radius: 8px;
    border: 0.5px solid var(--border-primary);
    padding: 7px;
    background-color: var(--background-primary);
    position: relative;
    z-index: 1;
  }
  .print-screen {
    border-radius: 0 0 6px 6px;
    max-width: 100%;
    width: auto;
    max-height: 200px;
    height: auto;
    display: flex;
    background: var(--background-tertiary);
    box-shadow: var(--shadow-card);
    margin: 0 auto;
  }

  .textblock {
    width: 100%;
    max-width: 100%;
    display: block;
    color: var(--text-primary);
    ${Typography.body_md}
    p {
      color: var(--text-primary);
      ${Typography.body_md}
      margin: 0;
      a {
        color: var(--text-link);
        word-break: break-word;
      }
      img {
        max-width: 300px;
        max-height: 300px;
        width: auto;
        height: auto;
      }
    }
    span {
      margin: 0;
      ${Typography.body_md}
      color: var(--text-primary);
    }
  }
  .text {
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: var(--text-primary);
    ${Typography.body_md}
    text-overflow: ellipsis;
    p {
      margin: 0;
      padding: 0;
      ${Typography.body_md_medium}
    }
    img {
      max-width: 300px;
      max-height: 300px;
      width: auto;
      height: auto;
      margin: 0 auto;
      border: 0.5px solid var(--border-primary);
      border-radius: 8px;
      @media (max-width: 449px) {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
      }
    }
  }
  .youtube-preview-main-div {
    -webkit-user-modify: read-only;
    border: 0.5px solid var(--border-primary);
    border-radius: 8px;
    max-width: 354px;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--background-primary);
    margin-top: 12px;
    .youtube-preview-image {
      max-width: 354px;
      width: 100%;
      height: auto;
      border-radius: 0 0 6px 6px;
    }
    .e-rte-image.e-imginline {
      vertical-align: baseline;
    }
    .screen-recording-desc-div {
      display: flex;
      align-items: center;
      gap: 6px;
    }
    .youtube-icon {
      width: 20px;
      height: 20px;
    }
  }
  .screen-recording-desc-div {
    display: flex;
    align-items: center;
    gap: 6px;
  }
  .e-rte-image.e-imginline {
    vertical-align: baseline;
  }
  .e-rte-video.e-video-inline {
    max-width: 354px;
  }

  .video-icon {
    width: 30px !important;
    height: 30px !important;
  }
  .prnt-icon {
    width: 20px !important;
    height: 20px !important;
  }

  .threaddiv {
    padding: 8px;
    border-radius: 4px;
    border: 0.5px solid var(--border-primary);
    background: var(--background-tertiary);
    width: 100%;
    max-width: fit-content;
  }
  .content {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  .line {
    width: 1px;
    background-color: var(--text-placeholder);
    border: 0.5px solid var(--text-placeholder);
    height: auto;
    border-radius: 3px;
  }
  .rightdiv {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .header {
    display: flex;
    flex-direction: row;
    gap: 50px;
    justify-content: space-between;
  }
  .leftheader {
    display: flex;
    flex-direction: row;
    gap: 9px;
    align-items: center;
  }
  .rightheader {
    cursor: pointer;
    height: 18px;
    .svgicon {
      stroke: var(--text-primary);
      fill: var(--text-primary);
    }
    :hover {
      background-color: var(--neutral-secondary);
      border-radius: 4px;
    }
  }
  .name {
    color: var(--text-primary);
    ${Typography.body_md}
  }
  .time {
    color: var(--text-secondary);
    ${Typography.body_md}
  }

  .copy-icon {
    width: 18px;
    height: 18px;
    cursor: pointer;
    background: var(--background-secondary);
    display: none;
  }
  @media (max-width: 449px) {
    .screen-recording-name {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      word-break: break-all;
    }
    .e-rte-image.e-imginline {
      padding: 0;
    }
    .open-video-icon {
      margin-bottom: 3px;
    }
    .video-more-icon {
      margin-bottom: 3px;
    }

    .figma-icon {
      width: 18px;
      height: 18px;
      margin: 0;
    }
    .figma-header {
      flex-direction: column;
      align-items: baseline;
      gap: 6px;
    }
    .figma-text {
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
    }
    .figma-desc-div {
      align-items: flex-start;
    }
    .figma-link-div {
      margin-left: 22px;
    }
    .figma-animation {
      position: absolute;
      top: 8px;
      right: 8px;
      padding: 0;
    }
    .figma-description {
      margin-right: 25px;
    }
    .text {
      word-break: break-all;
      p {
        img {
          width: 100%;
          border-radius: 4px;
        }
      }
    }
    .header {
      gap: 12px;
    }
    .threaddiv {
      max-width: -webkit-fill-available;
    }
    .rightdiv {
      gap: 4px;
      width: 100%;
    }
    .screen-recording-custom-main-div {
      max-width: -webkit-fill-available;
    }
    .cleanshot-video-main-div {
      max-width: -webkit-fill-available;
    }
    .youtube-preview-main-div {
      max-width: -webkit-fill-available;
    }
    .e-rte-video.e-video-inline {
      max-width: 100%;
    }
    .rightdiv {
      gap: 4px;
      word-break: break-all;
    }
    .leftheader {
      gap: 3px;
    }
  }
`;

const TableMainDiv = styled.div`
  display: flex;
  gap: 8px;
  max-width: 391px;
  background-color: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  box-shadow: var(--shadow-modal);
  padding: 4px 4px 4px 12px;
  border-radius: 50px;
  position: absolute;
  left: 50%;
  top: -35px;
  transform: translateX(-50%);
  z-index: 1;
`;

const ButtonMainDiv = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  p {
    margin: 0;
    ${Typography.body_md}
    color: var(--text-secondary);
  }
`;

const IconDiv = styled.div`
  display: flex;
  padding: 4px;
  border-radius: 50px;
  svg {
    stroke: var(--text-secondary);
  }
  &:hover {
    background-color: var(--neutral-secondary);
    svg {
      stroke: var(--text-primary);
    }
    .header-icon {
      stroke: none;
      fill: var(--text-primary);
    }
  }
  .header-icon {
    stroke: none;
    fill: var(--text-secondary);
  }
`;

const Divider = styled.div`
  border: 0.5px solid var(--border-primary);
`;
export { TiptapMain, TableMainDiv, ButtonMainDiv, IconDiv, Divider };
