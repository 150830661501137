import { AnyAction, Dispatch } from 'redux';
import { clearResponseMessage, setErrorMessage } from '../actions/messageActions';
import axios from 'axios';
import { getAPIErrorReason } from '../helpers/common';
import { setHelpCenterLoader } from '../actions/helpCenterActions';
import { REACT_APP_TEAMCAMP_APIURL } from '../global/environment';
import { FeedbackPayloadInterface } from '../interfaces/helpCenterInterface';
import { captureException } from './logService';

export const sendFeedbackMessage: any =
  (payload: FeedbackPayloadInterface) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setHelpCenterLoader(true));
      const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/contactUs`, payload);
      if (response) {
        return response;
      }
      return false;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to send feedback please try again';
      captureException(error);
      dispatchSnapError(error, dispatch);
    } finally {
      dispatch(setHelpCenterLoader(false));
    }
  };

function dispatchSnapError(msg: string, dispatch: Dispatch<AnyAction>) {
  dispatch(setErrorMessage(msg));
}
