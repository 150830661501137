import styled, { css } from 'styled-components';

interface Props {
  isMessagesloading?: boolean;
  iswidth?: boolean;
  isProjectMessageDetail?: boolean;
}

const Main = styled.div<Props>`
  max-width: 715px;
  width: 100%;
  .loading-animation {
    overflow: hidden;
  }

  .loading-animation::before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    animation: loading 1s infinite;
    background: linear-gradient(to right, transparent, var(--background-tertiary), transparent);
  }

  @keyframes loading {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  ${(props) =>
    props.isMessagesloading &&
    css`
      padding-top: 24px;
    `}
  ${(props) =>
    props.isProjectMessageDetail &&
    css`
      padding-top: 24px;
      margin-bottom: 44px;
    `}
`;
const Topborder = styled.div`
  width: 100%;
  display: block;
  border-bottom: 0.5px solid var(--background-muted);
  text-align: center;
  max-width: 271px;
  width: 100%;
`;
const Center = styled.div`
  border-radius: 6px;
  background: var(--background-muted);
  max-width: 140px;
  height: 8px;
  width: 100%;
`;
const Border = styled.div<Props>`
  display: flex;
  gap: 16px;
  align-items: center;
  margin-bottom: 24px;
  @media (max-width: 449px) {
    ${(props) =>
      props.isMessagesloading &&
      css`
        margin: 0px 16px 20px;
      `}
  }
`;

const Descriptoin = styled.div<Props>`
  display: flex;
  gap: 20px;
  flex-direction: column;
  @media (max-width: 449px) {
    width: calc(100vw - 32px);
    ${(props) =>
      props.isMessagesloading &&
      css`
        align-items: center;
      `}
  }
`;
const First = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;
const Left = styled.div<Props>`
  width: 42px;
  height: 42px;
  background: var(--background-muted);
  border-radius: 50%;
  ${(props) =>
    props.isMessagesloading &&
    css`
      width: 36px;
      height: 36px;
    `}
`;
const Circle = styled.div<Props>`
  width: 42px;
  height: 42px;
  ${(props) =>
    props.isMessagesloading &&
    css`
      width: 36px;
      height: 36px;
    `}
`;
const Right = styled.div<Props>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  ${(props) =>
    props.isMessagesloading &&
    css`
      gap: 12px;
    `}
  ${(props) =>
    props.iswidth &&
    css`
      gap: 8px;
    `}
`;
const Top = styled.div`
  border-radius: 6px;
  background: var(--background-muted);
  width: 78px;
  height: 12px;
`;
const Bottom = styled.div<Props>`
  width: 372px;
  height: 12px;
  border-radius: 6px;
  background: var(--background-muted);
  @media (max-width: 449px) {
    width: 287px;
  }
  @media (max-width: 375px) {
    width: 268px;
  }
  ${(props) =>
    props.isMessagesloading &&
    css`
      height: 6px;
    `}
  ${(props) =>
    props.iswidth &&
    css`
      height: 6px;
      width: 143px;
    `}
`;
const Middle = styled.div<Props>`
  width: 143px;
  height: 12px;
  border-radius: 6px;
  background: var(--background-muted);
  @media (max-width: 449px) {
    width: 287px;
  }
  @media (max-width: 375px) {
    width: 268px;
  }
  ${(props) =>
    props.isMessagesloading &&
    css`
      width: 372px;
      height: 6px;
    `}
`;
const Topthird = styled.div`
  width: 96px;
  height: 12px;
  border-radius: 6px;
  background: var(--background-muted);
`;
const Middlethird = styled.div<Props>`
  width: 591px;
  height: 12px;
  border-radius: 6px;
  background: var(--background-muted);
  @media (max-width: 449px) {
    width: 287px;
  }
  @media (max-width: 375px) {
    width: 268px;
  }
  ${(props) =>
    props.isMessagesloading &&
    css`
      width: 475px;
      height: 6px;
    `}
`;
const Bottomthird = styled.div<Props>`
  width: 657px;
  height: 12px;
  border-radius: 6px;
  background: var(--background-muted);
  @media (max-width: 449px) {
    width: 287px;
  }
  @media (max-width: 375px) {
    width: 268px;
  }
  ${(props) =>
    props.isMessagesloading &&
    css`
      width: 661px;
      height: 6px;
    `}
`;
const Third = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  padding-bottom: 36px;
`;
const Footer = styled.div<Props>`
  border-radius: 8px;
  border: 0.5px solid var(--border-primary);
  background: var(--background-primary);
  box-shadow: var(--shadow-card);
  display: flex;
  padding: 8px 8px 8px 20px;
  align-items: center;
  max-width: 685px;
  width: 100%;
  justify-content: space-between;
  margin-top: 32px;
  position: fixed;
  bottom: 25px;
  @media (max-width: 449px) {
    width: -webkit-fill-available;
    padding: 6px 16px 6px 20px;
    margin: 0 16px 0;
    bottom: 20px;
    ${(props) =>
      props.isProjectMessageDetail &&
      css`
        margin: 0 16px 0 0;
      `}
  }
`;
const Leftdiv = styled.div`
  width: 262px;
  height: 12px;
  border-radius: 6px;
  background: var(--background-muted);
  @media (max-width: 449px) {
    width: 175px;
  }
`;
const Rightdiv = styled.div`
  display: flex;
  gap: 6px;
  .svg {
    fill: var(--text-secondary);
  }
  .fill-color {
    stroke: var(--text-secondary);
  }
`;

export {
  Main,
  Topborder,
  Center,
  Border,
  Descriptoin,
  First,
  Left,
  Right,
  Top,
  Bottom,
  Middle,
  Topthird,
  Middlethird,
  Bottomthird,
  Third,
  Footer,
  Leftdiv,
  Rightdiv,
  Circle
};
