import styled from 'styled-components';
const Loadingheader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  padding: 12px 24px 12px;
  border-bottom: 0.5px solid var(--border-primary);
  .loading-animation {
    overflow: hidden;
  }
  .loading-animation::before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    animation: loading 1s infinite;
    background: linear-gradient(to right, transparent, var(--background-tertiary), transparent);
  }

  @keyframes loading {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;
const Circle = styled.div`
  width: 30px;
  height: 30px;
  background-color: var(--background-muted);
  border-radius: 50%;
`;

const Line = styled.div`
  width: 100px;
  height: 9px;
  background-color: var(--background-muted);
  border-radius: 7px;
`;
export { Circle, Line, Loadingheader };
