/* eslint-disable no-sparse-arrays */
import Login from '../pages/membership/login';
import Signup from '../pages/membership/signup';
import Forgetpassword from '../pages/membership/forgotpasword';
import OnboardingStep2 from '../pages/onborading/onboardingStep2';
import OnboardingStep3 from '../pages/onborading/onboardingStep3';
import Project from '../pages/project';
import SelectWorkspace from '../pages/more/selectWorkspace';
import Signupjoin from '../pages/more/signupjoin';
import MyTask from '../pages/task/myTask';
import RecurringTask from '../pages/recurring/recurringTask';
import Navbarmessage from '../pages/message/messages';
import Invoices from '../pages/invoices';
import InvoicesDetails from '../pages/invoices/invoiceDetails';
import Personal from '../pages/settings/personal';
import Email from '../pages/settings/email';
import Password from '../pages/settings/password';
import Notification from '../pages/settings/notification';
import Tasks from '../pages/project/details/tasks';
import Files from '../pages/project/details/files';
import Overview from '../pages/project/details/overview';
import Favourite from '../pages/project/favourite';
import Payment from '../pages/settings/payment';
import Onborading from '../pages/onborading/onborading';
import Mobileonborading from '../pages/onborading/mobileonboarding/mobileonboarding';
import Customer from '../pages/customer';
import Customerdetail from '../pages/customer/customerDetail';
import TimeTracking from '../pages/timeTracking';
import Team from '../pages/settings/team';
import Invoice from '../pages/settings/invoice';
import Time from '../pages/settings/time';
import { ROUTES } from './urls';
import Inbox from '../pages/inbox';
import AddNewInvoiceModal from '../pages/invoices/addNewInvoice/addNewInvoiceModal/addNewInvoiceModal';
import NewInvoiceSecondStep from '../pages/invoices/addNewInvoice/newInvoiceSecondStep';
import TaskDetail from '../pages/project/details/tasks/taskDetail';
import Uninvoiced from '../pages/report/uninvoiced';
import Team_timeline from '../pages/report/team_timeline';
import Project_timeline from '../pages/report/project_timeline';
import Payments from '../pages/report/payments';
import ReportInvoice from '../pages/report/report_invoice';
import Projects from '../pages/report/projects';
import Task from '../pages/report/tasks';
import Times from '../pages/report/time';
import Messages from '../pages/project/details/messages';
import CreateNewDoc from '../pages/project/details/files/createNewDoc';
import FileView from '../pages/project/details/files/fileView';
import ArchiveProject from '../pages/project/archiveProject';
import General from '../pages/settings/general';
import ResetPassword from '../pages/membership/resetPassword';
import DetailsSetting from '../pages/project/details/setting';
import Milestone from '../pages/project/details/milestone';
import MembersOvewviewDetails from '../pages/project/details/members/overview';
import MembersTaskDetails from '../pages/project/details/members/tasks';
import StripePayment from '../pages/settings/stripePayment';
import Subscription from '../pages/settings/subscription/subscription';
import Apps from '../pages/settings/apps/apps';
import Apikey from '../pages/settings/apikey/apikey';
import Integrations from '../pages/settings/integrations/integrations';
import FigmaConnectRedirect from '../pages/settings/redirectPages/figmaRedirect';
import Referearn from '../pages/settings/referEarn/referearn';
import BrandDomain from '../pages/settings/brandDomain/branddomain';
import Deleteuser from '../pages/membership/deleteUser';
import templates from '../pages/settings/templates/templates';
import TemplateProjectDetails from '../pages/settings/templates/templateDetails';
import WorkspaceRedirect from '../pages/workspaceRedirect';
import Usage from '../pages/settings/usage/usage';
import Snap from '../pages/snap/snap';
import NavbarmessagesDetails from '../pages/message/messageDetails';
import MyTimelog from '../pages/myTimelog';
import TaskTemplateDetails from '../component/taskTemplateDetails';
import ResponsiveSearch from '../pages/responsiveSearch/responsicesearch';
import GithubIntegration from '../pages/settings/integrations/githubIntegration';
import GithubCallback from '../pages/settings/integrations/githubIntegration/githubCallback';
import ReportNavbar from '../component/navbar/ReportNavbar';
import DropdownCallback from '../pages/dropdown';
import SecurityAccess from '../pages/settings/securityAccess/securityAccess';
import SentryCallback from '../pages/settings/integrations/sentryIntegration';
import SettingNavbar from '../component/navbar/SettingNavbar';
import ResponsiveNavbar from '../pages/responsiveNavbar/responsiveNavbar';

const router = [
  {
    path: ROUTES.LOGIN,
    component: Login,
    private: false
  },
  {
    path: ROUTES.SIGNUP,
    component: Signup,
    private: false
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    component: Forgetpassword,
    private: false
  },
  {
    path: ROUTES.DELETE_USER,
    component: Deleteuser,
    private: false
  },
  {
    path: ROUTES.WORKSPACE,
    component: Onborading,
    private: true
  },
  {
    path: ROUTES.MOBILEONBORDING,
    component: Mobileonborading,
    private: true
  },

  {
    path: ROUTES.ONBOARDING_STEP2,
    component: OnboardingStep2,
    private: true
  },
  {
    path: ROUTES.WELCOME,
    component: OnboardingStep3,
    private: true
  },
  ,
  {
    path: ROUTES.MEMBERSHIP_INVITATION,
    component: Signupjoin,
    private: false
  },
  {
    path: ROUTES.SELECT_WORKSPACE,
    component: SelectWorkspace,
    private: true
  },
  {
    path: ROUTES.RESET_PASSWORD,
    component: ResetPassword,
    private: false
  },
  // With Navbar
  {
    path: ROUTES.MYTASK,
    component: MyTask,
    private: true
  },
  {
    path: ROUTES.NAVBARMESSAGE,
    component: Navbarmessage,
    private: true
  },
  {
    path: ROUTES.NAVBARMESSAGE_DETAILS_MOBILE,
    component: NavbarmessagesDetails,
    private: true
  },
  {
    path: ROUTES.SNAP,
    component: Snap,
    private: true
  },
  {
    path: ROUTES.INVOICES,
    component: Invoices,
    private: true
  },
  {
    path: '/invoices/view/:id',
    component: InvoicesDetails,
    private: true
  },
  {
    path: ROUTES.ADD_NEW_INVOICES,
    component: AddNewInvoiceModal,
    private: true
  },
  {
    path: ROUTES.ADD_NEW_INVOICES_2,
    component: NewInvoiceSecondStep,
    private: true
  },
  {
    path: ROUTES.EDIT_INVOICES,
    component: NewInvoiceSecondStep,
    private: true
  },
  {
    path: ROUTES.ALL_PROJECT,
    component: Project,
    private: true
  },
  {
    path: ROUTES.FAVOURITE_PROJECT,
    component: Favourite,
    private: true
  },
  {
    path: '/projects/details/:id/tasks',
    component: Tasks,
    private: true
  },
  {
    path: '/projects/details/:id/taskdetail',
    component: TaskDetail,
    private: true
  },
  {
    path: '/projects/details/:id/files',
    component: Files,
    private: true
  },
  {
    path: '/projects/details/:id/files/file-view/:name',
    component: FileView,
    private: true
  },
  {
    path: '/projects/details/:id/messages',
    component: Messages,
    private: true
  },
  {
    path: '/projects/details/:id/overview',
    component: Overview,
    private: true
  },
  {
    path: '/projects/details/:id/milestone',
    component: Milestone,
    private: true
  },
  {
    path: '/projects/files/:id/docs/:docid',
    component: CreateNewDoc,
    private: true
  },
  {
    path: '/projects/details/:id/setting',
    component: DetailsSetting,
    private: true
  },
  {
    path: ROUTES.PERSONAL_SETTING,
    component: Personal,
    private: true
  },
  {
    path: ROUTES.EMAIL_SETTING,
    component: Email,
    private: true
  },
  {
    path: ROUTES.PASSWORD_SETTING,
    component: Password,
    private: true
  },
  {
    path: ROUTES.NOTIFICATION_SETTING,
    component: Notification,
    private: true
  },
  {
    path: ROUTES.SECURITY_ACCESS_SETTING,
    component: SecurityAccess,
    private: true
  },
  {
    path: ROUTES.GENERAL_SETTING,
    component: General,
    private: true
  },
  {
    path: ROUTES.REFEREARN_SETTING,
    component: Referearn,
    private: true
  },
  {
    path: ROUTES.BRANDDOMAIN_SETTING,
    component: BrandDomain,
    private: true
  },
  {
    path: ROUTES.USAGE_SETTING,
    component: Usage,
    private: true
  },
  {
    path: '/members/:id/overview',
    component: MembersOvewviewDetails,
    private: true
  },
  {
    path: '/members/:id/tasks',
    component: MembersTaskDetails,
    private: true
  },
  {
    path: ROUTES.TEMPLATES_SETTING,
    component: templates,
    private: true
  },
  {
    path: ROUTES.TEMPLATES_SETTING_DETAILS,
    component: TemplateProjectDetails,
    private: true
  },
  {
    path: ROUTES.PAYMENT_SETTING,
    component: Payment,
    private: true
  },
  {
    path: ROUTES.PAYMENT_STRIPE_SETTING,
    component: StripePayment,
    private: true
  },
  {
    path: ROUTES.FIGMA_CONNECT_REDIRECT,
    component: FigmaConnectRedirect,
    private: true
  },
  {
    path: ROUTES.TEAM_SETTING,
    component: Team,
    private: true
  },
  {
    path: ROUTES.SUBSCRIPTION_SETTING,
    component: Subscription,
    private: true
  },
  {
    path: ROUTES.APPS_SETTING,
    component: Apps,
    private: true
  },
  {
    path: ROUTES.API_SETTING,
    component: Apikey,
    private: true
  },
  {
    path: ROUTES.INTEGRATIONS_SETTING,
    component: Integrations,
    private: true
  },
  {
    path: ROUTES.INVOICE_SETTING,
    component: Invoice,
    private: true
  },
  {
    path: ROUTES.TIME_SETTING,
    component: Time,
    private: true
  },
  {
    path: ROUTES.INBOX,
    component: Inbox,
    private: true
  },
  {
    path: ROUTES.CUSTOMER,
    component: Customer,
    private: true
  },
  {
    path: '/customer/:id',
    component: Customerdetail,
    private: true
  },
  {
    path: ROUTES.TIMETRACKING,
    component: TimeTracking,
    private: true
  },
  {
    path: ROUTES.REPORT,
    component: ReportNavbar,
    private: true
  },
  {
    path: ROUTES.REPORT_PAYMENTS,
    component: Payments,
    private: true
  },
  {
    path: ROUTES.REPORT_UNINVOICED,
    component: Uninvoiced,
    private: true
  },
  {
    path: ROUTES.REPORT_INVOICE,
    component: ReportInvoice,
    private: true
  },
  {
    path: ROUTES.REPORT_PROJECTS,
    component: Projects,
    private: true
  },
  {
    path: ROUTES.REPORT_TASK,
    component: Task,
    private: true
  },
  {
    path: ROUTES.REPORT_TEAM_TIMELINE,
    component: Team_timeline,
    private: true
  },
  {
    path: ROUTES.REPORT_PROJECTS_TIMELINE,
    component: Project_timeline,
    private: true
  },
  {
    path: ROUTES.REPORT_TIMES,
    component: Times,
    private: true
  },
  {
    path: ROUTES.ARCHIVE_PROJECT,
    component: ArchiveProject,
    private: true
  },
  {
    path: ROUTES.RECURRINGTASK,
    component: RecurringTask,
    private: true
  },
  {
    path: ROUTES.TASK_TEMPLATES_SETTING_DETAILS,
    component: TaskTemplateDetails,
    private: true
  },
  {
    path: ROUTES.SEARCH,
    component: ResponsiveSearch,
    private: true
  },
  {
    path: ROUTES.SETTING,
    component: SettingNavbar,
    private: true
  },
  {
    path: ROUTES.HOME,
    component: ResponsiveNavbar,
    private: true
  },
  {
    path: ROUTES.WORKSPACE_REDIRECT,
    component: WorkspaceRedirect,
    private: true
  },
  {
    path: ROUTES.MY_TIMELOG,
    component: MyTimelog,
    private: true
  },
  {
    path: ROUTES.INTEGRATIONS_DETAILS,
    component: GithubIntegration,
    private: true
  },
  {
    path: ROUTES.GITHUB_CALLBACK,
    component: GithubCallback,
    private: true
  },
  {
    path: ROUTES.SENTRY_CALLBACK,
    component: SentryCallback,
    private: true
  },
  {
    path: '/test/dropdown',
    component: DropdownCallback,
    private: true
  }
];

export default router;
