import SVGIcon from '../../../assets/images/svg/SVGIcon';
import {
  Leftheader,
  Messagedata,
  Messagecontent,
  Messageheader,
  Rightheader,
  Box,
  Messagedetail,
  Name,
  Icon,
  Newchat,
  Rightchat,
  ResponsiveBarIcon,
  IconWithTitle,
  Emptybox,
  SectionHeading
} from './styles';
import { useSelector, useDispatch } from 'react-redux';
import { ChatGroupInterface } from '../../../interfaces/chatMessageInterface';
import { CHAT_GROUP_TYPE } from '../../../global/constants';
import Chatloading from '../../loading/chatloading';
import { isEmpty } from '../../../helpers/common';
import emptyprofile from '../../../assets/images/emptystates/empty-profile.svg';
import darkemptyprofile from '../../../assets/images/emptystates/darkemptyprofile.svg';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import Responsivnavbar from '../../navbar/responsivenavbar';
import EmptyState from '../../emptyState';
import darkemptymessage from '../../../assets/images/emptystates/darkemptymessage.svg';
import lightemptymessage from '../../../assets/images/emptystates/lightmessageempty.svg';
import { useMemo } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { setPinChatGroups } from '../../../actions/chatMessageActions';
import { updateChatGroupOrder } from '../../../services/chatMessageService';
import ChatBoxAvatar from '../../avatar/chatboxAvatar/ChatBoxAvatar';

// eslint-disable-next-line @typescript-eslint/no-redeclare
declare type DraggableProvided = typeof import('react-beautiful-dnd');

// eslint-disable-next-line @typescript-eslint/no-redeclare
declare type DraggableStateSnapshot = typeof import('react-beautiful-dnd');

// eslint-disable-next-line @typescript-eslint/no-redeclare
declare type DropResult = typeof import('react-beautiful-dnd');

interface Props {
  onClickNewChat: () => void;
  onClickChatGroup: (group: ChatGroupInterface) => void;
  newGroup: boolean;
  loading: boolean;
}

export default function Chatbox({ onClickNewChat, onClickChatGroup, newGroup, loading }: Props) {
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { settings: settingsSelector, chatMessage: chatMessageSelector } = stateSelector || {};
  const { themeMode } = settingsSelector;
  const { chatGroups, pinChatGroups, unpinChatGroups, currentChatGroup } = chatMessageSelector || {};
  //Other variable
  const getImageSource = () => (themeMode?.theme === 'dark' ? darkemptymessage : lightemptymessage);
  const dispatch = useDispatch();

  const handleDragEnd = async (item: DropResult) => {
    try {
      const { destination, draggableId, source } = item || {};
      const { index: sourceIndex } = source || {};
      const { index: destinationIndex } = destination;
      let updatedGroupIndex;
      if (destinationIndex === 0) {
        const nextGroupIndex = pinChatGroups?.[0]?.order;
        updatedGroupIndex = nextGroupIndex ? nextGroupIndex - 500 : new Date().getTime();
      } else if (destinationIndex === pinChatGroups?.length - 1) {
        const previousGroupIndex = pinChatGroups?.[pinChatGroups?.length - 1]?.order;
        updatedGroupIndex = previousGroupIndex ? previousGroupIndex + 500 : new Date().getTime();
      } else {
        if (destinationIndex > sourceIndex) {
          const previousGroupIndex: number = pinChatGroups?.[destinationIndex]?.order || 0;
          const nextGroupIndex: number = pinChatGroups?.[destinationIndex + 1]?.order || 0;
          updatedGroupIndex = (previousGroupIndex + nextGroupIndex) / 2;
        } else {
          const previousGroupIndex: number = pinChatGroups?.[destinationIndex - 1]?.order || 0;
          const nextGroupIndex: number = pinChatGroups?.[destinationIndex]?.order || 0;
          updatedGroupIndex = (previousGroupIndex + nextGroupIndex) / 2;
        }
      }

      const updatedPinGroups = pinChatGroups.map((chatGroup) =>
        chatGroup?.id === draggableId ? { ...(chatGroup || {}), order: updatedGroupIndex } : chatGroup
      );

      // Sort the updated list
      const sortedList = updatedPinGroups.toSorted((a, b) => (a?.order ?? 0) - (b?.order ?? 0));
      // Update the state
      dispatch(setPinChatGroups(sortedList));

      const payload = {
        Order: updatedGroupIndex
      };
      await dispatch(updateChatGroupOrder(draggableId, payload));
    } catch (error) {
      console.log('error', error);
    }
  };

  const renderUnpinChatGroup = useMemo(
    () =>
      unpinChatGroups?.map((group) => {
        if (group?.messages?.length === 0) return null;
        const isActive = currentChatGroup?.id === group?.id;
        const isProject = group?.type === CHAT_GROUP_TYPE.PROJECT;
        return (
          <Box key={group?.id} isActive={isActive} onClick={() => onClickChatGroup(group)}>
            <Rightchat>
              <Icon>
                {isProject && (
                  <SVGIcon
                    name='teamcamp-mobile-icon'
                    width='26'
                    height='26'
                    viewBox='0 0 30 30'
                    className='teamcampicon'
                  />
                )}
                {group?.users && !isProject && (
                  <>
                    <ChatBoxAvatar
                      imgSrc={group?.profilePic || ''}
                      name={group?.users[0]?.name}
                      size={26}
                      users={group?.users}
                    />
                  </>
                )}
              </Icon>
              <Messagedetail>
                <Name hasUnreadMessages={group?.hasUnreadMessages}>{group?.name || ''}</Name>
              </Messagedetail>
            </Rightchat>
          </Box>
        );
      }),
    [currentChatGroup?.id, onClickChatGroup, unpinChatGroups]
  );
  return (
    <>
      <Messagecontent>
        <Messageheader>
          <IconWithTitle>
            <ResponsiveBarIcon>
              <Responsivnavbar />
            </ResponsiveBarIcon>
            <Leftheader>Messages</Leftheader>
          </IconWithTitle>
          <Rightheader onClick={onClickNewChat}>
            <SVGIcon name='newchat-icon' width='18' height='18' viewBox='0 0 18 18' fill='none' className='plusicon' />
          </Rightheader>
        </Messageheader>
        <Messagedata>
          {pinChatGroups?.length > 0 && (
            <>
              <SectionHeading>Pinned</SectionHeading>
              <DragDropContext onDragEnd={(result: DropResult) => handleDragEnd(result)}>
                <Droppable droppableId='favourite_project'>
                  {(provided: DraggableProvided) => (
                    <div
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                      {pinChatGroups.map((group, index) => {
                        if (group?.messages?.length === 0) return null;

                        const isActive = currentChatGroup?.id === group?.id;
                        const isProject = group?.type === CHAT_GROUP_TYPE.PROJECT;
                        return (
                          <Draggable key={group?.id} draggableId={group?.id} index={index}>
                            {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                              <div
                                ref={provided.innerRef}
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...provided.draggableProps}
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...provided.dragHandleProps}>
                                <Box isActive={isActive} onClick={() => onClickChatGroup(group)}>
                                  <Rightchat>
                                    <Icon>
                                      {isProject && (
                                        <SVGIcon
                                          name='teamcamp-mobile-icon'
                                          width='26'
                                          height='26'
                                          viewBox='0 0 30 30'
                                          className='teamcampicon'
                                        />
                                      )}
                                      {group?.users && !isProject && (
                                        <ChatBoxAvatar
                                          imgSrc={group?.profilePic || ''}
                                          name={group?.users[0]?.name}
                                          size={26}
                                          users={group?.users}
                                        />
                                      )}
                                    </Icon>
                                    <Messagedetail>
                                      <Name hasUnreadMessages={group?.hasUnreadMessages}>{group?.name || ''}</Name>
                                    </Messagedetail>
                                  </Rightchat>
                                </Box>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </>
          )}

          {unpinChatGroups?.length > 0 && <SectionHeading>Recent</SectionHeading>}
          {newGroup && (
            <Newchat>
              {/* <SVGIcon name='new-chat-icon' width='26' height='26' viewBox='0 0 30 30' className='chaticon' /> */}
              {themeMode?.theme === 'dark' ? (
                <img src={darkemptyprofile} alt='emptyprofile' width={26} height={26} />
              ) : (
                <img src={emptyprofile} alt='emptyprofile' width={26} height={26} />
              )}
              <Name>New chat</Name>
            </Newchat>
          )}
          {loading && (!chatGroups || chatGroups?.length === 0) && <Chatloading />}
          {unpinChatGroups?.length > 0 && renderUnpinChatGroup}
          {chatGroups?.length === 0 && isEmpty(currentChatGroup) && !loading && (
            <Emptybox>
              <EmptyState
                header='No messages yet'
                title='You have no messages at the moment'
                name='New message'
                image={getImageSource()}
                isMessageEmpty={true}
                onButtonClick={onClickNewChat}
                ismaxwidth={true}
              />
            </Emptybox>
          )}
        </Messagedata>
      </Messagecontent>
    </>
  );
}
