import styled from 'styled-components';
import { Typography } from '../../../global/theme';
const ModalBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
  max-width: 632px;
  border-radius: 12px;
  @media (max-width: 449px) {
    width: calc(100vw - 32px);
  }
`;
const Modalblock = styled.form`
  display: flex;
  flex-direction: column;
  @media (max-width: 449px) {
  }
  width: 100%;
`;
const Modalhead = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 12px 16px;
  border-bottom: 0.5px solid var(--border-primary);
  @media (max-width: 449px) {
    justify-content: space-between;
  }
`;

const Headline = styled.h6`
  ${Typography.heading_md}
  color: var(--text-primary);
  margin: 0;
  width: 562px;
  @media (max-width: 449px) {
    width: auto;
  }
`;

const Formation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 16px 24px;
  @media (max-width: 449px) {
    gap: 20px;
    padding: 16px 16px 24px;
  }
`;

const Formblock = styled.div`
  display: flex;
  gap: 50px;
  flex-direction: row;
  align-items: center;
  @media (max-width: 449px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const Form = styled.div`
  max-width: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  .angledown-icon {
    path {
      stroke: var(--text-primary);
    }
  }

  .ant-select-dropdown {
    color: var(--text-white) !important;
  }

  .ant-select-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background: var(--text-primary);
  }
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover
    .ant-select-selector {
    border-color: var(--border-primary);
  }
  .ant-select-focused.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
      .ant-pagination-size-changer
    )
    .ant-select-selector {
    box-shadow: var(--shadow-input-focus);
    z-index: 999;
    border-color: var(--border-primary);
  }
  .ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: var(--text-white);
    background: none;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: 40px;
    padding: 7px 12px;
    @media (max-width: 449px) {
      padding: 12px;
    }
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    ${Typography.body_lg_medium}
    height: 18px;
    color: var(--text-primary);
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 0.5px solid var(--border-primary);
    background: var(--background-primary);
    color: var(--text-primary);
  }
  .ant-select-dropdown .ant-select-item-option {
    display: flex;
    ${Typography.body_lg}
    color: var(--text-primary);
  }
  .ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: var(--text-primary);
    background-color: var(--neutral-secondary) !important;
    ${Typography.body_lg}
    height: 18px;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    ${Typography.body_lg}
    height: 20px;
    color: var(--text-placeholder);
  }
  .ant-select-multiple .ant-select-selection-placeholder {
    inset-inline-start: 14px;
  }
  .ant-picker-focused {
    border: 0.5px solid var(--brand-primary) !important;
    box-shadow: var(--shadow-input-focus);
  }
  .ant-picker:hover {
    border-color: var(--border-primary);
  }
  .ant-picker {
    border: 0.5px solid var(--border-primary);
    border-radius: 6px;
    padding: 8px 12px;
    background: var(--background-primary);
    @media (max-width: 449px) {
      padding: 12px;
    }
  }
  .ant-picker .ant-picker-input {
    color: var(--text-secondary);
  }
  .ant-picker .ant-picker-input > input {
    color: var(--text-primary);
    ${Typography.body_lg}
  }
  .ant-select-focused.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
      .ant-pagination-size-changer
    )
    .ant-select-selector {
    border: 0.5px solid var(--brand-primary);
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 34px;
    @media (max-width: 449px) {
      height: 42px;
    }
  }
  .ant-select-selection-search {
    .ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
      background: var(--brand-primary-hover);
    }
    height: 34px;
    @media (max-width: 449px) {
      height: 42px;
    }
  }
  .ant-select-selection-item {
    height: 32px;
    @media (max-width: 449px) {
      height: 40px;
    }
  }
  .ant-select-single {
    height: 36px;
    @media (max-width: 449px) {
      height: 44px;
    }
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 36px;
    @media (max-width: 449px) {
      height: 44px;
    }
  }
  .ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
    background: var(--brand-primary-hover);
  }
  @media (max-width: 449px) {
    max-width: 100%;
  }
  .autocomplate {
    .ant-select-focus {
      border: none;
    }
  }

  .checkbox-round {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid var(--border-secondary);
    appearance: none;
    outline: none;
    cursor: pointer;
    margin: 0;
    padding: 7px;
  }

  input[type='checkbox'] {
    position: relative;
    cursor: pointer;
    opacity: 0.5;
  }

  input[type='checkbox']:checked {
    background-color: var(--brand-primary);
    opacity: 1;
    border: 1px solid var(--brand-primary);
  }

  input[type='checkbox']:checked::before {
    content: '';
    position: absolute;
    right: 50%;
    top: 50%;
    width: 3px;
    height: 8px;
    border: solid var(--text-white);
    border-width: 0 2px 2px 0;
    margin: -0.5px -1px 0 -1px;
    transform: rotate(45deg) translate(-50%, -50%) scale(0.7);
    z-index: 2;
  }
  .ant-btn-primary {
    background: var(--brand-primary);
  }
  .ant-picker-dropdown .ant-picker-panel-container .ant-picker-panel {
    border: none;
  }
  .ant-picker-dropdown {
    min-width: 122px !important;
  }
  .ant-picker-dropdown .ant-picker-ranges > li {
    line-height: normal;
  }
  @media (max-width: 449px) {
    .ant-picker-dropdown .ant-picker-time-panel-column {
      overflow-y: auto;
    }
  }
`;

const Label = styled.label`
  ${Typography.body_md_medium}
  color: var(--text-secondary);
`;

const Formbutton = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 12px;
  padding: 0 16px 16px;
`;
const Billingtitle = styled.div`
  color: var(--text-primary);
  ${Typography.body_md}
`;
const Check = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 25px;
  gap: 10px;
  align-items: center;
  @media (max-width: 449px) {
    margin: 0;
  }
`;

const Taskarea = styled.textarea`
  ${Typography.body_lg_medium}
  color: var(--text-primary);
  margin: 0px;
  background: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 4px;
  overflow: auto;
  font-family: unset;
  padding: 8px 12px;
  height: 108px;
  resize: none;
  ::-webkit-scrollbar {
    display: none;
  }
  ::placeholder {
    ${Typography.body_lg}
    color: var(--text-placeholder);
  }
  :focus {
    border: 0.5px solid var(--brand-primary);
    outline: none;
  }
  @media (max-width: 449px) {
    height: 92px;
    padding: 12px;
  }
`;

const Inputvalue = styled.input``;
const Icon = styled.div`
  display: inline-flex;
  .close-icon {
    cursor: pointer;
    stroke: var(--text-primary);
    :hover {
      background-color: var(--neutral-secondary);
      border-radius: 4px;
    }
  }
`;

const Input = styled.input`
  min-width: 574px;
  padding: 7px 12px;
  color: var(--text-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 6px;
  ${Typography.body_lg}
  ::placeholder {
    color: var(--text-secondary);
  }
  :focus-visible {
    border-color: var(--brand-primary);
    outline: unset;
  }
  @media (max-width: 449px) {
    min-width: calc(100vw - 90px);
  }
`;

const ItemDiv = styled.div`
  max-height: 110px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: 4px;
  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--text-placeholder);
    border-radius: 12px;
    min-height: 28px;
  }
`;

const InputDiv = styled.div`
  position: relative;
`;

const IconDiv = styled.div`
  position: absolute;
  top: 8px;
  right: 10px;
  .angledown-icon {
    transition: transform 0.3s ease;
  }

  .angledown-icon.rotate {
    transform: rotate(180deg);
  }
`;

const SelectedText = styled.div`
  position: absolute;
  top: 8px;
  left: 12px;
  pointer-events: none;
  overflow: hidden;
  max-width: calc(100vw - 95px);
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export {
  Modalblock,
  Modalhead,
  Headline,
  Formation,
  Formblock,
  Form,
  Label,
  Formbutton,
  ModalBox,
  Check,
  Billingtitle,
  Inputvalue,
  Taskarea,
  Icon,
  Input,
  ItemDiv,
  IconDiv,
  InputDiv,
  SelectedText
};
