import styled, { keyframes } from 'styled-components';
import { Typography } from '../../../global/theme';

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  path.fill {
    fill: var(--text-tertiary);
  }
`;

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
const AnimatedArc = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 2px solid var(--background-muted);
  border-top: 2px solid var(--text-primary);
  position: absolute;
  animation: ${rotate} 1s linear infinite;
  &.dark-border {
    border: 2px solid var(--background-tertiary);
    border-top: 2px solid var(--text-primary);
  }
`;
const WorkSpaceLoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 194px;
  p {
    margin: 0;
    ${Typography.body_lg_medium};
    color: var(--text-secondary);
  }
  @media (max-width: 449px) {
    max-width: unset;
  }
`;
export { AnimatedArc, SpinnerContainer, rotate, WorkSpaceLoadingWrapper };
