import React, { useCallback } from 'react';
import {
  Activity,
  ActivityContentView,
  ActivitySubContent,
  ActivityTitle,
  ActivityView,
  Icon,
  ItemBlock,
  ItemChange,
  LeftBarView,
  SubText,
  SubTitle,
  Text,
  Update
} from './styles';
import Avatar from '../avatar/Avatar';
import moment from 'moment';
import { ActivityListInterface } from '../../interfaces/ProjectInterface';

interface ActivityData {
  data?: ActivityListInterface[];
}

const ActivityMain: React.FC<ActivityData> = (props) => {
  const { data } = props;

  const getActionLabel = useCallback((action: string | undefined): string => {
    if (!action) return '';
    if (action === 'Created') return 'Added';
    return action;
  }, []);

  return (
    <LeftBarView>
      <Activity>
        <ActivityTitle>Activity</ActivityTitle>
        <ActivityView>
          {data?.map((item) => {
            return (
              <ItemBlock key={item?.id}>
                <ItemChange>
                  <Icon>
                    <Avatar
                      imgSrc={item?.profile_photo}
                      name={item?.user ? item?.user : 'U N'}
                      size={18}
                      isShowBorder
                    />
                  </Icon>
                  <ActivityContentView>
                    <ActivitySubContent>
                      <SubTitle>
                        {item?.user} {}
                      </SubTitle>
                      <Update>
                        {getActionLabel(item?.action)}
                        {item?.type ? item?.type : ''}
                      </Update>
                      <Text>{moment(item?.createdTime).fromNow()}</Text>
                    </ActivitySubContent>
                    <SubText>{item?.desc}</SubText>
                  </ActivityContentView>
                </ItemChange>
              </ItemBlock>
            );
          })}
        </ActivityView>
      </Activity>
    </LeftBarView>
  );
};

export default ActivityMain;
