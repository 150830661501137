import { Main, Count, Title, Topline } from './styles';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import Tooltip from '../../../component/Tooltip';
import { Icon } from '../styles';

interface Props {
  title: string;
  members?: number;
  totalMembers?: number;
  count: string | number;
  isTeamMember?: boolean;
}
export default function Usagebox(props: Props) {
  const { members, totalMembers, title, count, isTeamMember } = props || {};

  return (
    <div>
      <Main>
        {isTeamMember ? (
          <Topline>
            <Title>{title}</Title>
            <Tooltip
              title={`${members} out of ${totalMembers} users license utilised from your active subscription.`}
              placement='bottomRight'
              maxWidth={'167px'}>
              <Icon>
                <SVGIcon name='storage-info-icon' width='16' height='16' viewBox='0 0 16 16' className='stroke-color' />
              </Icon>
            </Tooltip>
          </Topline>
        ) : (
          <Title>{title}</Title>
        )}
        <Count>{count}</Count>
      </Main>
    </div>
  );
}
