import styled, { css } from 'styled-components';
import { Typography } from '../../global/theme';

interface Props {
  isActive?: boolean;
}

const TasktemplateHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 10px;
`;
const HeaderText = styled.div`
  ${Typography.heading_lg}
  color: var(--text-primary);
`;
const Group = styled.div`
  max-width: 662px;
  width: 100%;
  margin: 0 auto;
`;
const Bottomsection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const Top = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const Text = styled.div`
  ${Typography.body_md_medium}
  color: var(--text-primary);
`;
const Input = styled.input`
  width: -webkit-fill-available;
  padding: 7px 12px;
  background: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 6px;
  ${Typography.body_md}
  color: var(--text-primary);
  ::placeholder {
    color: var(--text-secondary);
  }
  :focus {
    border: 0.5px solid var(--brand-primary);
  }
  :focus-visible {
    outline: none;
  }
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding: 16px;
  background: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 8px;
  @media (max-width: 449px) {
    gap: 16px;
  }
`;
const Textfied = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const Textarea = styled.textarea`
  ${Typography.heading_lg}
  color: var(--text-primary);
  margin: 0px;
  border: none;
  padding: 0;
  font-family: unset;
  background: var(--background-primary);
  min-height: 22px !important;
  max-height: 66px;
  resize: none;
  ::-webkit-scrollbar {
    display: none;
  }
  ::placeholder {
    ${Typography.heading_lg}
    color: var(--text-primary);
  }
  :focus {
    outline: none;
  }
  a {
    color: var(--text-link);
    text-decoration: underline;
  }
`;
const DescriptionSection = styled.div`
  p {
    margin: 0;
    ${Typography.body_md_medium}
  }
  > div > div > div {
    overflow: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  .e-richtexteditor .e-rte-content .e-content p {
    margin-top: 3px;
  }
  .e-richtexteditor .rte-placeholder {
    color: var(--text-secondary) !important;
  }
  .ProseMirror {
    min-height: 90px;
  }
`;
const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  .ant-dropdown .ant-dropdown-menu {
    background-color: var(--background-primary);
    border: 0.5px solid var(--border-primary);
    box-shadow: var(--shadow-modal);
    width: 130px;
    padding: 4px 0 !important;
    background-clip: unset;
  }
`;
const Leftbuttons = styled.div`
  display: flex;
  gap: 8px;
`;
const AttachFile = styled.div<Props>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  .svg {
    path {
      fill: var(--text-secondary);
    }
  }
  :hover {
    background: var(--neutral-secondary);
    border-radius: 50%;
    .svg {
      path {
        fill: var(--text-primary);
      }
    }
  }
  ${(props) =>
    props.isActive &&
    css`
      background: var(--neutral-secondary);
      border-radius: 50%;
      .svg {
        path {
          fill: var(--text-primary);
        }
      }
    `}
`;

const Uploaddiv = styled.div<Props>`
  padding: 0 16px;
  color: var(--text-primary);
`;
const Detail = styled.div`
  cursor: pointer;
`;
const OverLayDiv = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const ResponsiveNavbarIcon = styled.div`
  display: none;
  @media (max-width: 449px) {
    display: flex;
  }
`;

const HeaderTitle = styled.div`
  @media (max-width: 449px) {
    display: flex;
    align-items: center;
    gap: 24px;
  }
`;
export {
  TasktemplateHeader,
  HeaderText,
  Group,
  Bottomsection,
  Top,
  Text,
  Input,
  Inputs,
  Textarea,
  Textfied,
  DescriptionSection,
  Buttons,
  Leftbuttons,
  AttachFile,
  Uploaddiv,
  Detail,
  OverLayDiv,
  ResponsiveNavbarIcon,
  HeaderTitle
};
