import React from 'react';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { Modalhead, Headline, Deletewarning, Buttons, ModalContent, Icon, ModalMain } from './styles';
import Button from '../../button';

interface Props {
  onOk?: () => void;
  onNo?: () => void;
  onClose?: () => void;
  loading?: boolean;
  modaltitle?: string;
  description?: string;
  cancelLoading?: boolean;
  isDelete?: boolean;
}

const ConfirmationModal: React.FC<Props> = (props) => {
  return (
    <ModalMain onClick={(e: React.SyntheticEvent) => e.stopPropagation()}>
      <Modalhead>
        <Headline isDelete={props.isDelete}>{props.modaltitle}</Headline>
        <Icon onClick={props.onClose}>
          <SVGIcon
            name='modal-cross-sign-icon'
            width='18'
            height='18'
            viewBox='0 0 18 18'
            fill='none'
            className='fillColor'
          />
        </Icon>
      </Modalhead>
      <ModalContent>
        <Deletewarning>{props.description}</Deletewarning>
        <Buttons>
          <Button
            title={props.isDelete ? 'Cancel' : 'No'}
            onClick={props.onNo ? props.onNo : props.onClose}
            type='button'
            secondary={true}
            modelbtn={true}
            hasNotBoxshadow
            isLoading={props.cancelLoading}
          />
          <Button
            onClick={props.onOk}
            title={props.isDelete ? 'Ok' : 'Yes'}
            type='button'
            isLoading={props.loading}
            modelbtn={true}
          />
        </Buttons>
      </ModalContent>
    </ModalMain>
  );
};

export default ConfirmationModal;
