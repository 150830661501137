/* eslint-disable max-len */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  clearCreateTaskInput,
  setCreateTaskInput,
  setMemberTaskData,
  setMyTaskData,
  setProjectTaskGroupsList,
  updateCreateTaskInput,
  updateMemberTaskData,
  updateMyTaskData,
  updatSubTaskList
} from '../../actions/taskActions';
import {
  createNewComment,
  deleteRecurringTask,
  getMyTaskDetailsData,
  getTaskGroupList,
  onChangeComment,
  updateTaskLocalData,
  updateTaskDetails
} from '../../services/taskServices';
import { getCurrentProjectDetails } from '../../services/projectServices';
import {
  Assigngroup,
  AvatarImage,
  Deadline,
  Form,
  Group,
  Icon,
  Icon2,
  Text as DetailTask,
  Inputvalue,
  Label,
  ProjectName,
  ProjectTitle,
  Task,
  Taskcontent,
  Taskitem,
  Tasklist,
  View,
  ViewDropdown,
  TaskHeader,
  Heading,
  Title,
  Menu,
  Taskicons,
  Filter,
  Duedate,
  Projectdate,
  Selecttext,
  Tasktitle,
  Maintasktitle,
  Dropblock,
  Doticon,
  Droplabel,
  Taskcount,
  Multiplelabel,
  IconDueDate,
  ResponsiveNavbarIcon,
  HeaderTitle,
  Div,
  Emptybox,
  Empty,
  ButtonIcon
} from './styles';
import EmptyState from '../emptyState';
import SVGIcon from '../../assets/images/svg/SVGIcon';
import Prioritydropdown from '../dropdowns/priorityDropdown';
import Avatar from '../avatar/Avatar';
import { Dropdown as AntdDropdown, Menu as MenuAnt, Tooltip } from 'antd';
import Linkify from 'react-linkify';
import { calculateDueTime, isEmpty } from '../../helpers/common';
import CreateTaskModal from '../models/createTaskModal';
import mytaskempty from '../../assets/images/emptystates/mytask-empty.svg';
import darkmytaskempty from '../../assets/images/emptystates/darkmytaskempty.svg';
import Button from '../button';
import AssignBox from '../dropdowns/assignPopup';
import {
  COMMENT_TYPE,
  LABEL_COLOUR_HASH,
  MY_TASKS_GROUP_FILTER_TYPE,
  MY_TASKS_GROUP_FILTER_TYPE_LABELS,
  STATUS_TYPE
} from '../../global/constants';
import { nanoid } from 'nanoid';
import ModalCustom from '../models/modal';
import { useParams } from 'react-router-dom';
import Resbutton from '../resbutton/resbutton';
import { File, ProjectDetailInterface, StatusListInterface } from '../../interfaces/ProjectInterface';
import TaskLoading from '../loading/taskloading';
import { trackAnalyticActivity } from '../../services/analyticsService';
import { DataUpdatedCommentsInterface, TaskDetailsInterface } from '../../interfaces/TaskInterface';
import { getUserPreferenceFieldData, updateUserPreference } from '../../helpers/firebaseHelper';
import moment from 'moment';
import MyCustomContextMenu from '../customContextMenu';
import { captureException } from '../../services/logService';
import Deletemodal from '../models/deleteModel';
import ConfirmationModal from '../models/confirmationModal';
import TaskStatusDropdown from '../dropdowns/taskStatusDropdown';
import Responsivnavbar from '../navbar/responsivenavbar';
import { useMobileDevice } from '../../global/useMobile';
import { TASK_ANALYTICS } from '../../global/analyticsConstants';
import { Dropdown, DropdownItem } from '../Dropdown';
import { menuItems } from '../../global/row';
import UserPreferenceSingleton from '../../helpers/userPreferenceSingleton';
import TasksCacheService from '../../services/tasksCatchServices';
import { selectTasksState } from '../../store/selectors';

interface DataInterface {
  title: string;
  tasks: TaskDetailsInterface[];
}

interface Tasks {
  loading?: boolean;
  isMyTask?: boolean;
  isUserTask?: boolean;
  loadData?: () => void;
  loadLocalData?: () => void;
  data: DataInterface[];
  isMember?: boolean;
  memberId?: string;
  isRecurringTask?: boolean;
}
interface AssignGroupComponentProps {
  onClickAssign: (item: TaskDetailsInterface, e?: React.SyntheticEvent) => void;
  onClickUsers: (taskId: string) => void;
  item: TaskDetailsInterface;
  project: ProjectDetailInterface | undefined;
  isAssignDropdownOpen: boolean;
  setIsAssignDropdownOpen: (isOpen: boolean) => void;
  currentAssignBox: string;
  setCurrentAssignBox: (id: string) => void;
  loadData: () => void;
  mobile: boolean;
  isRecurringTask: boolean;
  className?: string;
  isMember?: boolean;
  isMyTask?: boolean;
}
export const AssignGroupComponent: React.FC<AssignGroupComponentProps> = ({
  onClickAssign,
  onClickUsers,
  item,
  project,
  isAssignDropdownOpen,
  setIsAssignDropdownOpen,
  currentAssignBox,
  setCurrentAssignBox,
  loadData,
  mobile,
  isRecurringTask,
  className,
  isMember,
  isMyTask
}) => {
  return (
    <Assigngroup onClick={(e) => onClickAssign(item, e)} className={className}>
      <ul onClick={() => onClickUsers(item?.id)}>
        {item?.users
          ?.filter((user) => !!user?.id)
          ?.map((user) => (
            <li key={user?.id}>
              <AvatarImage>
                <Avatar imgSrc={user?.profile_photo} name={user?.name} size={20} />
              </AvatarImage>
            </li>
          ))}
        <AssignBox
          loadUser={() => onClickAssign(item)}
          setIsDropdownOpen={setIsAssignDropdownOpen}
          isDropdownOpen={isAssignDropdownOpen && currentAssignBox === item?.id}
          projectUsers={project?.users || []}
          currentTask={item}
          loadData={loadData}
          setCurrentAssignBox={setCurrentAssignBox}
          currentAssignBox={currentAssignBox}
          isAssignModal={mobile}
          isRecurringTask={isRecurringTask}
          isMultiAssignee={project?.multiAssignee}
          isMyTask={isMyTask}
          isMember={isMember}
        />
      </ul>
    </Assigngroup>
  );
};

const TasksData: React.FC<Tasks> = (props) => {
  const { loading, isMyTask, isUserTask, loadData, loadLocalData, data, isMember, memberId, isRecurringTask } = props;
  //State
  const [filteredKey, setFilteredKey] = useState<number>();
  const [deleteModelopen, setDeleteModelopen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<TaskDetailsInterface>();
  const [currentActiveTask, setCurrentActiveTask] = useState<string>('');
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [currentTask, setCurrentTask] = useState<TaskDetailsInterface>();
  const [isConfirmationModelOpen, setIsConfirmationModelOpen] = useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [status, setStatus] = useState<StatusListInterface>();
  const [previousStatus, setPreviousStatus] = useState<StatusListInterface>();
  const [isCheckBox, setIsCheckBox] = useState(false);
  const [editData, setEditData] = useState<TaskDetailsInterface>();
  const [openCreateTask, setOpenCreateTask] = useState(false);
  const [isAssignDropdownOpen, setIsAssignDropdownOpen] = useState(false);
  const [project, setProject] = useState<ProjectDetailInterface>();
  const [currentAssignBox, setCurrentAssignBox] = useState<string>('');
  //use selector state variables with memoized selectors
  const { workspace, userDetails, themeMode } = useSelector(selectTasksState);
  const { id: workspace_id } = workspace;

  //Other variable
  const params: { id: string } = useParams();
  const memberDetail = workspace?.users?.find((x: { id: string }) => x?.id === params?.id);
  const getImageSource = () => (themeMode?.theme === 'dark' ? darkmytaskempty : mytaskempty);
  const mobile = useMobileDevice();
  const dispatch = useDispatch();
  const history = useHistory();

  const loadFilterTypeData = useCallback(async () => {
    const userFilter = await getUserPreferenceFieldData('my_task_filter');
    setFilteredKey(userFilter?.group_by ?? MY_TASKS_GROUP_FILTER_TYPE.GROUP);
  }, []);

  useEffect(() => {
    loadFilterTypeData();
  }, [loadFilterTypeData]);

  const openCreateTaskModal = useCallback(async () => {
    if (isMyTask) {
      const user = workspace?.users?.find((user: { id: string }) => user?.id === userDetails?.id);
      dispatch(updateCreateTaskInput({ propsName: 'users', value: [user] }));
    } else {
      const user = workspace?.users?.find((user: { id: string }) => user?.id === memberId);
      dispatch(updateCreateTaskInput({ propsName: 'users', value: [user] }));
    }
    setOpenCreateTask(true);
  }, [dispatch, isMyTask, memberId, userDetails, workspace?.users]);

  const closeCreateTaskModal = useCallback(() => {
    setOpenCreateTask(false);
    dispatch(clearCreateTaskInput());
    dispatch(updateCreateTaskInput({ propsName: 'uploadedFiles', value: [] }));
    dispatch(setProjectTaskGroupsList([]));
  }, [dispatch]);

  const handleOpenConfirmationModal = (isCheckBox: boolean) => {
    setIsCheckBox(isCheckBox);
    setIsConfirmationModelOpen(true);
  };

  const closeConfirmationModal = () => {
    setIsConfirmationModelOpen(false);
  };

  //task data update
  const updateTaskData = useCallback(
    (item: TaskDetailsInterface, value: boolean) => {
      let taskData = JSON.parse(JSON.stringify(item));
      const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
      const newComment: DataUpdatedCommentsInterface = {
        id: nanoid(),
        createdOn: new Date().toISOString(),
        updatedBy: userDetails?.id,
        updatedTime: new Date().toISOString(),
        type: COMMENT_TYPE.STATUS_CHANGE,
        status: value,
        isArchived: false,
        user: { ...userDetails, name: userDetails?.given_name }
      };
      const allComments = item?.updatedComments;
      const comments = [...(allComments || []), newComment];
      taskData.status = value;
      taskData.companyId = workspace_id;
      taskData.updatedComments = comments;
      return taskData;
    },
    [workspace_id]
  );

  //update subtask data
  const updateSubTaskData = useCallback(
    (index: number, taskData: TaskDetailsInterface) => {
      let subData = JSON.parse(JSON.stringify(data[index]));
      subData.tasks = subData.tasks.map((obj: TaskDetailsInterface) => (obj.id === taskData.id ? taskData : obj));
      return subData;
    },
    [data]
  );

  const dispatchTaskUpdates = useCallback(
    async (item: TaskDetailsInterface, value: boolean) => {
      const payloadTask = { status: value, projectId: item?.projectId };
      const result = await dispatch(updateTaskDetails(item?.id, payloadTask));
      const commentResult = await dispatch(
        createNewComment(item?.id, {
          Type: COMMENT_TYPE.STATUS_CHANGE,
          Status: value
        })
      );
      if (result && commentResult) {
        if (value) trackAnalyticActivity(TASK_ANALYTICS.MARKED_DONE);
        else {
          trackAnalyticActivity(TASK_ANALYTICS.REOPENED);
          loadData?.();
        }
      }
    },
    [dispatch, loadData]
  );

  //sub task is compoleted or not
  const checkSubTaskStatus = useCallback((item: TaskDetailsInterface, index: number) => {
    if (item?.subTasks) {
      let allSubTasksStatus = true;
      if (item?.subTasks.length > 0) {
        for (const subTask of item?.subTasks) {
          if (subTask?.status === false) {
            allSubTasksStatus = false;
            break;
          }
        }
      }
      if (allSubTasksStatus === false && !item?.status && isEmpty(item?.parentTaskId)) {
        setCurrentTask(item);
        setCurrentTaskIndex(index);
        handleOpenConfirmationModal(true);
        return true;
      }
    }
    return false;
  }, []);

  const onClickTaskCheckBox = useCallback(
    async (item: TaskDetailsInterface, value: boolean, index: number) => {
      const openModal = checkSubTaskStatus(item, index);
      if (openModal) {
        return;
      }
      const taskData = updateTaskData(item, value);
      const subData = updateSubTaskData(index, taskData);
      if (isMyTask) {
        dispatch(updateMyTaskData({ value: subData, index }));
      } else if (isUserTask) {
        dispatch(updateMemberTaskData({ value: subData, index }));
      }
      await dispatchTaskUpdates(item, value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, data, workspace_id, userDetails]
  );

  const onCLickProject = useCallback(
    (projectId: string, e: React.SyntheticEvent) => {
      e.stopPropagation();
      history.push(`/projects/details/${projectId}/tasks`);
    },
    [history]
  );

  const onClickTaskDetails = useCallback(
    (task: TaskDetailsInterface) => {
      if (isRecurringTask) {
        return;
      } else {
        const eventProperties = {
          source: 'my task',
          title: task?.name || ''
        };
        trackAnalyticActivity(TASK_ANALYTICS.VIEW, eventProperties);
        history.push(`/projects/details/${task?.projectId}/tasks?&task=${task?.id}`);
      }
    },
    [history, isRecurringTask]
  );

  const getPriorityValue = useCallback(
    (itemPriority: number | undefined, defaultPriority: number | undefined): number => {
      if (itemPriority !== undefined) return itemPriority;
      if (defaultPriority !== undefined) return defaultPriority;
      return 0;
    },
    []
  );

  const onClickEdit = useCallback(
    async (item: TaskDetailsInterface) => {
      setEditData(item);
      if (isRecurringTask) {
        dispatch(setCreateTaskInput({ ...(item || {}), description: `${item?.description || ''}<p></p>` }));
        const projectDetails = await dispatch(getCurrentProjectDetails(workspace_id, item?.projectId));
        dispatch(updateCreateTaskInput({ propsName: 'projectId', value: projectDetails }));
        dispatch(
          updateCreateTaskInput({
            propsName: 'priority',
            value: getPriorityValue(item?.priority, projectDetails?.priority?.default)
          })
        );
      } else {
        const taskData = await dispatch(getMyTaskDetailsData(item?.id));
        dispatch(setCreateTaskInput({ ...(taskData || {}), description: `${taskData?.description || ''}<p></p>` }));
        const projectDetails = await dispatch(getCurrentProjectDetails(workspace_id, item?.projectId));
        dispatch(updateCreateTaskInput({ propsName: 'projectId', value: projectDetails }));
        dispatch(
          updateCreateTaskInput({
            propsName: 'priority',
            value:
              taskData?.priority !== undefined
                ? taskData.priority
                : projectDetails?.priority?.default !== undefined
                ? projectDetails.priority.default
                : 0
          })
        );
      }
      const taskGroups = await dispatch(getTaskGroupList(item?.projectId, true));
      if (item?.groupId) {
        const currentTaskGroup = taskGroups?.find((x: { id: string }) => x?.id === item?.groupId);
        dispatch(updateCreateTaskInput({ propsName: 'groupId', value: currentTaskGroup }));
      }
      setOpenCreateTask(true);
    },
    [dispatch, getPriorityValue, isRecurringTask, workspace_id]
  );
  const getPriorityClone = useCallback(
    (taskPriority: number | undefined, defaultPriority: number | undefined): number => {
      if (taskPriority !== undefined) return taskPriority;
      if (defaultPriority !== undefined) return defaultPriority;
      return 0;
    },
    []
  );
  const onClickClone = useCallback(
    async (item: TaskDetailsInterface) => {
      const taskData = await dispatch(getMyTaskDetailsData(item?.id));
      const taskFiles = taskData?.Files?.map((item: File) => {
        const { __typename, ...other } = item;
        return other;
      });
      const cloneTaskdetails = {
        Files: taskFiles,
        description: `${taskData?.description || ''}<p></p>`,
        name: taskData?.name,
        groupId: taskData?.groupId,
        projectId: taskData?.projectId,
        priority: taskData?.priority,
        users: taskData?.users,
        labels: taskData?.labels,
        estimate: taskData?.estimate,
        milestoneId: taskData?.milestoneId,
        statusId: taskData?.statusId,
        parentTaskId: !isEmpty(taskData?.parentTaskId) ? taskData?.parentTaskId : null
      };
      dispatch(setCreateTaskInput(cloneTaskdetails));
      const projectDetails = await dispatch(getCurrentProjectDetails(workspace_id, item?.projectId));
      const taskGroups = await dispatch(getTaskGroupList(item?.projectId, true));
      if (item?.groupId) {
        const currentTaskGroup = taskGroups?.find((x: { id: string }) => x?.id === item?.groupId);
        dispatch(updateCreateTaskInput({ propsName: 'groupId', value: currentTaskGroup }));
      }
      dispatch(updateCreateTaskInput({ propsName: 'projectId', value: projectDetails }));
      dispatch(
        updateCreateTaskInput({
          propsName: 'priority',
          value: getPriorityClone(taskData?.priority, projectDetails?.priority?.default)
        })
      );
      setOpenCreateTask(true);
    },
    [dispatch, getPriorityClone, workspace_id]
  );

  const onClickAssign = useCallback(
    async (item: TaskDetailsInterface, e?: React.SyntheticEvent) => {
      e?.stopPropagation();
      const projectDetails = await dispatch(getCurrentProjectDetails(workspace_id, item?.projectId));
      setProject(projectDetails);
    },
    [workspace_id, dispatch]
  );

  const onChangeGroupByFilter = useCallback(
    async (key: number | string) => {
      key = Number(key);
      setFilteredKey(key);
      await updateUserPreference({ my_task_filter: { group_by: key } });
      loadLocalData?.();
    },
    [loadLocalData]
  );

  const Opendeletemodel = useCallback((task?: TaskDetailsInterface) => {
    if (task) setSelectedItem(task);
    setDeleteModelopen(true);
  }, []);

  const deleteTask = useCallback(
    async (task: TaskDetailsInterface) => {
      try {
        setDeleteLoading(true);
        if (!isRecurringTask) {
          const payloadTask = { isArchived: true, projectId: task?.projectId };
          const response = await dispatch(updateTaskDetails(task?.id, payloadTask));
          setDeleteModelopen(false);
          if (response) {
            const updatedMyTaskList = data?.map((item) => ({
              ...(item || {}),
              tasks: item.tasks.filter((x) => x.id !== task?.id) // Filter out the task
            }));
            isMyTask
              ? dispatch(setMyTaskData(updatedMyTaskList || []))
              : dispatch(setMemberTaskData(updatedMyTaskList || []));
            await TasksCacheService.getInstance().removeBulk([task?.id]);
          }
        } else {
          dispatch(updateCreateTaskInput({ propsName: 'start', value: null }));
          dispatch(updateCreateTaskInput({ propsName: 'recurringInterval', value: null }));

          const response = await dispatch(deleteRecurringTask(task?.id, task?.projectId));
          setDeleteModelopen(false);
          if (response) {
            loadData?.();
          }
        }
      } catch (e) {
        captureException(e);
        console.log('ERROR', e);
      } finally {
        setDeleteLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, dispatch]
  );

  const close = () => {
    setDeleteModelopen(false);
  };

  const onCompleteMyTask = useCallback(async () => {
    if (currentTask && currentTaskIndex) {
      let taskData = JSON.parse(JSON.stringify(currentTask));
      const updatedComment = onChangeComment(currentTask);
      taskData.status = true;
      taskData.companyId = workspace_id;
      taskData.updatedComments = updatedComment?.updatedComments;
      let subData = JSON.parse(JSON.stringify(data[currentTaskIndex]));
      subData.tasks = subData.tasks.map((obj: TaskDetailsInterface) => {
        return obj.id === taskData.id ? taskData : obj;
      });
      dispatch(updateMyTaskData({ value: subData, index: currentTaskIndex }));
      const payloadTask = { status: true, projectId: currentTask?.projectId };
      const result = await dispatch(updateTaskDetails(currentTask?.id, payloadTask));
      const commentResult = await dispatch(
        createNewComment(currentTask?.id, {
          Type: COMMENT_TYPE.STATUS_CHANGE,
          Status: true
        })
      );
      if (result && commentResult) {
        trackAnalyticActivity(TASK_ANALYTICS.MARKED_DONE);
      }
    }
  }, [currentTask, currentTaskIndex, data, dispatch, workspace_id]);

  const onClickConfirm = useCallback(() => {
    setConfirmationLoading(true);
    try {
      if (currentTask?.subTasks && currentTask?.subTasks?.length > 0) {
        for (const subTask of currentTask.subTasks) {
          if (subTask?.status === false) {
            const taskDetailsClone = JSON.parse(JSON.stringify(subTask));
            taskDetailsClone.status = true;
            dispatch(updatSubTaskList(taskDetailsClone));
            const payloadTask = { status: true, projectId: subTask?.projectId };
            const result = dispatch(updateTaskDetails(subTask?.id, payloadTask));
            const commentResult = dispatch(
              createNewComment(subTask?.id, {
                Type: COMMENT_TYPE.STATUS_CHANGE,
                Status: true
              })
            );
            if (result && commentResult) {
              trackAnalyticActivity(TASK_ANALYTICS.MARKED_DONE);
            }
          }
        }
        onCompleteMyTask();
      }
    } catch (error) {
      console.log('errror :', error);
    } finally {
      setConfirmationLoading(false);
      closeConfirmationModal();
    }
  }, [currentTask?.subTasks, dispatch, onCompleteMyTask]);

  const onChangeStatus = useCallback(
    async (
      status: StatusListInterface,
      previousStatus: StatusListInterface,
      task: TaskDetailsInterface,
      index: number
    ) => {
      const payloadTask = { statusId: status['_id'], projectId: task?.projectId };
      let taskData = JSON.parse(JSON.stringify(task));
      const updatedComment = onChangeComment(task);
      taskData.statusId = status['_id'];
      taskData.companyId = workspace_id;
      taskData.updatedComments = updatedComment?.updatedComments;
      let subData = JSON.parse(JSON.stringify(data[index]));
      subData.tasks = subData.tasks.map((obj: TaskDetailsInterface) => {
        return obj.id === taskData.id ? taskData : obj;
      });
      if (!isMember) {
        dispatch(updateMyTaskData({ value: subData, index: index }));
      } else {
        dispatch(updateMemberTaskData({ value: subData, index }));
      }
      const result = await dispatch(updateTaskDetails(task?.id, payloadTask));
      if (result) {
        const updatedData = { ...taskData, statusId: status['_id'] };
        dispatch(updateTaskLocalData(updatedData));
        await dispatch(
          createNewComment(task?.id, {
            Type: COMMENT_TYPE.STATUS_ID_CHANGE,
            Group: status?.Name,
            PreviousGroup: previousStatus?.Name,
            Priority: status?.Type
          })
        );
        if (isMyTask) loadLocalData?.();
        trackAnalyticActivity(TASK_ANALYTICS.STATUS_CHANGED);
      }
    },
    [data, dispatch, isMember, isMyTask, loadLocalData, workspace_id]
  );

  const onUpdateStatus = useCallback(
    async (
      status: StatusListInterface,
      previousStatus: StatusListInterface,
      task: TaskDetailsInterface,
      index: number
    ) => {
      if (status['_id'] !== previousStatus['_id']) {
        let allSubTasksStatus = true;
        if (task?.subTasks && task?.subTasks?.length > 0) {
          for (const subTask of task.subTasks) {
            if (subTask?.status === false) {
              allSubTasksStatus = false;
              break;
            }
          }
        }
        if (status?.Type === STATUS_TYPE.COMPLETED && !allSubTasksStatus) {
          handleOpenConfirmationModal(false);
          setStatus(status);
          setPreviousStatus(previousStatus);
          setCurrentTask(task);
          setCurrentTaskIndex(index);
          return;
        }
        onChangeStatus(status, previousStatus, task, index);
      }
    },
    [onChangeStatus]
  );

  const onClickConfirmStatus = useCallback(async () => {
    setConfirmationLoading(true);
    try {
      if (status && previousStatus && currentTask) {
        const payloadTask = { statusId: status['_id'], projectId: currentTask?.projectId };
        if (currentTask?.subTasks && currentTask?.subTasks?.length > 0 && status?.Type === STATUS_TYPE?.COMPLETED) {
          for (const subTask of currentTask.subTasks) {
            if (subTask?.status === false) {
              const result = await dispatch(updateTaskDetails(subTask?.id, payloadTask));
              if (result) {
                await dispatch(
                  createNewComment(subTask?.id, {
                    Type: COMMENT_TYPE.STATUS_ID_CHANGE,
                    Group: status?.Name,
                    PreviousGroup: previousStatus?.Name,
                    Priority: status?.Type
                  })
                );
                const taskDetailsClone = JSON.parse(JSON.stringify(subTask));
                taskDetailsClone.statusId = status['_id'];
                dispatch(updatSubTaskList(taskDetailsClone));
                trackAnalyticActivity(TASK_ANALYTICS.STATUS_CHANGED);
              }
            }
          }
        }
        onChangeStatus(status, previousStatus, currentTask, currentTaskIndex);
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setConfirmationLoading(false);
      closeConfirmationModal();
    }
  }, [currentTask, currentTaskIndex, dispatch, onChangeStatus, previousStatus, status]);

  const onClickNo = useCallback(async () => {
    setCancelLoading(false);
    try {
      if (isCheckBox) {
        onCompleteMyTask();
      } else {
        if (status && previousStatus && currentTask && currentTaskIndex)
          onChangeStatus(status, previousStatus, currentTask, currentTaskIndex);
      }
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setCancelLoading(false);
      closeConfirmationModal();
    }
  }, [currentTask, currentTaskIndex, isCheckBox, onChangeStatus, onCompleteMyTask, previousStatus, status]);

  const onClickUsers = useCallback(
    (taskId: string) => {
      if (mobile) {
        setIsAssignDropdownOpen(true);
        setCurrentAssignBox(taskId);
      }
    },
    [mobile]
  );

  const MenuItemDropdown = useMemo(() => {
    return menuItems.map((item) => (
      <DropdownItem label={item.label} key={item.key} onClick={() => onChangeGroupByFilter(item.key)} />
    ));
  }, [onChangeGroupByFilter]);

  const TaskFilter = useMemo(() => {
    const getLabelForFilteredKey = () => {
      if (filteredKey === MY_TASKS_GROUP_FILTER_TYPE.NONE) return '';
      if (filteredKey !== undefined) return MY_TASKS_GROUP_FILTER_TYPE_LABELS[filteredKey];
      return '';
    };
    return (
      data?.length > 0 &&
      data[0]?.tasks?.length > 0 && (
        <Taskicons>
          <Filter isHide={filteredKey === MY_TASKS_GROUP_FILTER_TYPE.NONE}>
            <SVGIcon
              name='small-filters-icon'
              width='16'
              height='16'
              viewBox='0 0 14 14'
              className='filters-icon-color'
            />
            <Selecttext isHide={filteredKey === MY_TASKS_GROUP_FILTER_TYPE.NONE}>{getLabelForFilteredKey()}</Selecttext>
          </Filter>
        </Taskicons>
      )
    );
  }, [data, filteredKey]);

  return (
    <Group>
      {isMyTask && (
        <TaskHeader>
          <HeaderTitle>
            <ResponsiveNavbarIcon>
              <Responsivnavbar />
            </ResponsiveNavbarIcon>
            <Heading>
              <Icon>
                <SVGIcon
                  name='my-task-main-icon'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  className='fill-color'
                />
              </Icon>
              <Title>{isRecurringTask ? 'Recurring Tasks' : 'My Tasks'}</Title>
            </Heading>
          </HeaderTitle>
          {!isRecurringTask && (
            <Menu>
              <Dropdown content={TaskFilter} trigger='click'>
                {MenuItemDropdown}
              </Dropdown>
              {/* hides button inresponsive screen and calls responsive button */}
              <div className='hidebutton'>
                {data?.length > 0 && data[0]?.tasks?.length > 0 && (
                  <Button
                    title='New Task'
                    iconName='plus-icon'
                    type='button'
                    iconSize={18}
                    iconViewBox='0 0 18 18'
                    iconColor='#fff'
                    onClick={openCreateTaskModal}
                  />
                )}
              </div>
              {data?.length > 0 && data[0]?.tasks?.length > 0 && (
                <Resbutton onClick={openCreateTaskModal} className={'responsive-button'} />
              )}
            </Menu>
          )}
        </TaskHeader>
      )}
      {loading && (data?.length === 0 || !data) && <TaskLoading isMarginTop={true} isMember={isMember} />}
      {!loading && (data?.length === 0 || data[0]?.tasks?.length === 0) && !isMember && (
        <Empty>
          <Emptybox>
            <EmptyState
              header={`You have no ${isRecurringTask ? 'Recurring' : ''} Tasks ${!isRecurringTask ? 'Assigned' : ''}`}
              title={
                isRecurringTask
                  ? ''
                  : 'You have no tasks assigned for now.You can add New task by clicking the button below'
              }
              onButtonClick={openCreateTaskModal}
              image={getImageSource()}
              name={'New Task'}
              hideButton={isRecurringTask}
              ismaxwidth={true}
            />
          </Emptybox>
        </Empty>
      )}

      {!loading && data[0]?.tasks?.length === 0 && isMember && (
        <Empty>
          <Emptybox>
            <EmptyState
              header={`${memberDetail?.name} has no Any Task`}
              title={`${memberDetail?.name} has no any task for now.You can add New task by clicking the button below`}
              onButtonClick={openCreateTaskModal}
              image={getImageSource()}
              name={'New Task'}
              ismaxwidth={true}
            />
          </Emptybox>
        </Empty>
      )}
      <>
        <Div isMember={isMember}>
          {data?.length > 0 &&
            data[0]?.tasks?.length > 0 &&
            data?.map((group, index: number) => {
              return (
                <Task key={group?.title} isMember={isMember}>
                  {filteredKey !== MY_TASKS_GROUP_FILTER_TYPE.NONE && (
                    <Tasklist>
                      <ProjectTitle>{!isEmpty(group?.title) ? group?.title : ''}</ProjectTitle>
                    </Tasklist>
                  )}
                  {group?.tasks?.map((item, i: number) => {
                    const dueResult = calculateDueTime(item.dueDate);
                    return (
                      <>
                        <MyCustomContextMenu
                          key={i}
                          targetId={item?.id}
                          taskData={item}
                          loadData={loadData ? loadData : () => {}}
                          currentProject={project}
                          setCurrentProject={() => onClickAssign(item)}
                          currentActiveTask={currentActiveTask}
                          setCurrentActiveTask={(taskId: string) => setCurrentActiveTask(taskId)}
                          onClickView={() => onClickTaskDetails(item)}
                          onClickClone={() => onClickClone(item)}
                          onClickEdit={() => onClickEdit(item)}
                          onClickDelete={() => Opendeletemodel(item)}
                          statusList={item.statusList || []}
                          isMyTask={isMyTask}
                          isMember={isMember}
                          isRecurringTask={isRecurringTask}
                          onUpdateStatus={(status: StatusListInterface, previousStatus: StatusListInterface) =>
                            onUpdateStatus(status, previousStatus, item, index)
                          }
                          isSubTask={!!item?.parentTaskId}>
                          <Taskcontent
                            onClick={() => onClickTaskDetails(item)}
                            isActive={currentActiveTask === item?.id}
                            className={item?.subTasks ? 'task-item' : ''}>
                            <Taskitem>
                              <Form isTaskItem={true}>
                                <div style={{ display: 'flex', gap: 2 }}>
                                  {item?.statusDetails ? (
                                    <TaskStatusDropdown
                                      defaultStatus={item?.statusDetails}
                                      statusList={item?.statusList || []}
                                      currentStatusId={item?.statusId}
                                      isTaskCheckBox={true}
                                      onUpdateStatus={(
                                        status: StatusListInterface,
                                        previousStatus: StatusListInterface
                                      ) => onUpdateStatus(status, previousStatus, item, index)}
                                      isResponsiveStatusIcon={true}
                                      isHoverIcon={true}
                                    />
                                  ) : (
                                    <ButtonIcon>
                                      <Inputvalue
                                        type='checkbox'
                                        className='checkbox-round'
                                        onClick={(e) => e.stopPropagation()}
                                        onChange={() =>
                                          !isRecurringTask && onClickTaskCheckBox(item, !item?.status, index)
                                        }
                                        checked={item?.status}
                                      />
                                    </ButtonIcon>
                                  )}
                                  <Prioritydropdown
                                    taskData={item}
                                    icon={true}
                                    myTask={true}
                                    isTaskItem={true}
                                    isRecurringTask={isRecurringTask}
                                    isTaskCheckBox={true}
                                    isHoverIcon={true}
                                    isMember={isMember}
                                  />
                                </div>
                                <Linkify
                                  componentDecorator={(decoratedHref: string, decoratedText: string, key: number) => (
                                    <a target='blank' href={decoratedHref} key={key}>
                                      {decoratedText}
                                    </a>
                                  )}>
                                  {isEmpty(item?.parentTaskId) ? (
                                    <Label ismaintask>{item?.name}</Label>
                                  ) : (
                                    <Tasktitle onClick={() => onClickTaskDetails(item)}>
                                      <Label>{item?.name}</Label>
                                      <span>&gt;</span>
                                      <Maintasktitle>{item?.parentTaskName}</Maintasktitle>
                                    </Tasktitle>
                                  )}
                                </Linkify>
                                {item?.subTasks && (
                                  <Taskcount>
                                    {item?.subTasks?.filter((x) => x?.status)?.length}/{item?.subTasks?.length}
                                  </Taskcount>
                                )}
                              </Form>
                              <AssignGroupComponent
                                className='assign'
                                onClickAssign={onClickAssign}
                                onClickUsers={onClickUsers}
                                item={item}
                                project={project}
                                isAssignDropdownOpen={isAssignDropdownOpen}
                                setIsAssignDropdownOpen={setIsAssignDropdownOpen}
                                currentAssignBox={currentAssignBox}
                                setCurrentAssignBox={setCurrentAssignBox}
                                loadData={loadData ? loadData : () => {}}
                                mobile={mobile}
                                isRecurringTask={isRecurringTask || false}
                                isMember={isMember}
                                isMyTask={isMyTask}
                              />
                              <AntdDropdown
                                trigger={['click']}
                                placement='bottomLeft'
                                transitionName=''
                                dropdownRender={() => (
                                  <div>
                                    <ViewDropdown>
                                      <MenuAnt className='menuStyle'>
                                        <MenuAnt.Item
                                          key='1'
                                          onClick={() => onClickTaskDetails(item)}
                                          style={{ padding: 0 }}
                                          className='menuitem'>
                                          <View>View</View>
                                        </MenuAnt.Item>
                                        <MenuAnt.Item
                                          key='2'
                                          onClick={() => onClickEdit(item)}
                                          style={{ padding: 0 }}
                                          className='menuitem'>
                                          <View>Edit</View>
                                        </MenuAnt.Item>
                                        <MenuAnt.Item
                                          key='3'
                                          onClick={() => onClickClone(item)}
                                          style={{ padding: 0 }}
                                          className='menuitem'>
                                          <View>Clone</View>
                                        </MenuAnt.Item>
                                        <MenuAnt.Item key='4' style={{ padding: 0 }} className='menuitem'>
                                          <View>
                                            <span>Delete</span>
                                          </View>
                                        </MenuAnt.Item>
                                      </MenuAnt>
                                    </ViewDropdown>
                                  </div>
                                )}>
                                <Icon2 className='respodropdown'>
                                  <SVGIcon
                                    className='svgicon'
                                    name='three-dots-horizontal-icon'
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                  />
                                </Icon2>
                              </AntdDropdown>
                            </Taskitem>
                            <Deadline>
                              <AssignGroupComponent
                                className='respassign'
                                onClickAssign={onClickAssign}
                                onClickUsers={onClickUsers}
                                item={item}
                                project={project}
                                isAssignDropdownOpen={isAssignDropdownOpen}
                                setIsAssignDropdownOpen={setIsAssignDropdownOpen}
                                currentAssignBox={currentAssignBox}
                                setCurrentAssignBox={setCurrentAssignBox}
                                loadData={loadData ? loadData : () => {}}
                                mobile={mobile}
                                isRecurringTask={isRecurringTask || false}
                                isMember={isMember}
                                isMyTask={isMyTask}
                              />

                              {item?.labels?.length > 0 && (
                                <Multiplelabel>
                                  {item?.labels?.map((label, index) => {
                                    return (
                                      <Dropblock className={index === 0 ? 'left-border' : ''} key={label?.id}>
                                        <Doticon color={`${LABEL_COLOUR_HASH[label?.color]}`} />
                                        <Droplabel>{label?.name}</Droplabel>
                                      </Dropblock>
                                    );
                                  })}
                                </Multiplelabel>
                              )}
                              <Projectdate>
                                <ProjectName
                                  isRecurringtask={isRecurringTask}
                                  showRightBorder={!isEmpty(item?.dueDate)}
                                  onClick={(e) => onCLickProject(item?.projectId, e)}>
                                  <p>{item?.projectName}</p>
                                </ProjectName>
                                <Duedate>
                                  {!isEmpty(item?.dueDate) && !isRecurringTask && (
                                    <>
                                      <Tooltip
                                        title={
                                          <>
                                            <div>Due {dueResult?.dueParam}</div>
                                            <div> Due on {moment(item?.dueDate).format(userDetails?.dateFormat)}</div>
                                          </>
                                        }
                                        placement='bottom'
                                        getPopupContainer={(trigger: HTMLElement) => trigger.parentNode as HTMLElement}>
                                        <DetailTask isOverdue={dueResult?.overDue}> {dueResult?.dueTime} </DetailTask>

                                        <IconDueDate className='calendar-icon' onClick={(e) => e.stopPropagation()}>
                                          {dueResult?.overDue && !item?.status ? (
                                            <SVGIcon
                                              name='tasklist-calendar-duedate-icon'
                                              width='24'
                                              height='24'
                                              viewBox='0 0 24 24'
                                              className='calendar-due-fill-color'
                                            />
                                          ) : (
                                            <SVGIcon
                                              name='tasklist-calendar-icon'
                                              width='24'
                                              height='24'
                                              viewBox='0 0 24 24'
                                              className='calendar-fill-color'
                                            />
                                          )}
                                        </IconDueDate>
                                      </Tooltip>
                                    </>
                                  )}
                                </Duedate>
                              </Projectdate>
                            </Deadline>
                          </Taskcontent>
                        </MyCustomContextMenu>
                        {/* show subtask ui */}
                      </>
                    );
                  })}
                </Task>
              );
            })}
        </Div>
      </>
      <ModalCustom open={openCreateTask} onClose={closeCreateTaskModal} isCreateTask={true}>
        <CreateTaskModal
          isRecurringTask={isRecurringTask}
          data={editData}
          loadData={loadData}
          onCancel={closeCreateTaskModal}
          isFromMyTask={true}
        />
      </ModalCustom>

      <ModalCustom open={deleteModelopen} onClose={close} width={334}>
        <Deletemodal
          onClose={close}
          onOk={() => selectedItem && deleteTask(selectedItem)}
          loading={deleteLoading}
          modaltitle={`${
            (selectedItem?.subTasks?.length ?? 0) > 0
              ? `Delete this task and ${selectedItem?.subTasks?.length} subtasks?`
              : 'Delete Task?'
          }`}
          description={`${
            (selectedItem?.subTasks?.length ?? 0) > 0
              ? 'This action will remove this task and all its connected subtasks.'
              : 'Are you sure you want to delete this task?'
          }`}
        />
      </ModalCustom>

      <ModalCustom open={isConfirmationModelOpen} onClose={closeConfirmationModal} width={334}>
        <ConfirmationModal
          loading={confirmationLoading}
          cancelLoading={cancelLoading}
          onOk={isCheckBox ? onClickConfirm : onClickConfirmStatus}
          onNo={onClickNo}
          onClose={closeConfirmationModal}
          modaltitle='Uncompleted Subtasks'
          description='Do you want to complete all these subtasks?'
        />
      </ModalCustom>
    </Group>
  );
};

export default TasksData;
