import styled, { css } from 'styled-components';
import { Typography } from '../../../global/theme';

interface Props {
  size?: number;
  randColor?: string;
  isRight?: boolean;
  isLeft?: boolean;
  isImageTop?: boolean;
  isImageBottom?: boolean;
}
const UserLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ChatBoxWrapper = styled.div<Props>`
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  ${(props: Props) =>
    props.size &&
    css`
      width: ${props.size}px;
      height: ${props.size}px;
      object-fit: cover;
    `}
`;
const ChatBoxGroup = styled.div<Props>`
  width: 50%;
  height: 26px;
  display: flex;
  align-items: center;
  flex-direction: column;
  ${(props: Props) =>
    props.size &&
    css`
      height: ${props.size}px;
    `}
`;

const UserLogo = styled.img<Props>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  background-color: var(--background-muted);
  object-fit: cover;

  ${(props: Props) => props.size && css``}

  ${(props: Props) =>
    props.isLeft &&
    css`
      border-radius: 20px 0px 0px 20px;
      width: 50%;
      /* height: ${props.size}px; */
      object-fit: cover;
      object-position: center;
      border-right: 1px solid var(--text-white);
    `};
  ${(props: Props) =>
    props.isRight &&
    css`
      border-radius: 0px 20px 20px 0px;
      width: 100%;
      height: ${props.size}px;
      object-fit: cover;
      object-position: center;
      border: none;
    `};
  ${(props: Props) =>
    props.isImageTop &&
    css`
      border-radius: 0px 20px 0px 0px;
      width: 100%;
      height: 50%;
      object-fit: cover;
      object-position: center;
      border-bottom: 1px solid var(--text-white);
    `};
  ${(props: Props) =>
    props.isImageBottom &&
    css`
      border-radius: 0px 0px 20px 0px;
      width: 100%;
      height: 50%;
      object-fit: cover;
      object-position: center;
      border: none;
    `};
`;
const TextWrapper = styled.div<Props>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  color: white;
  display: flex;
  background-color: ${(props: Props) => props.randColor};

  span {
    ${Typography.body_xs}
  }

  ${(props: Props) =>
    props.isLeft &&
    css`
      border-radius: 20px 0px 0px 20px;
      width: 50%;
      height: ${props.size}px;
      border-right: 1px solid var(--text-white);
      span {
        ${Typography.body_xs}
      }
    `};
  ${(props: Props) =>
    props.isRight &&
    css`
      border-radius: 0px 20px 20px 0px;
      width: 13px;
      height: ${props.size}px;
    `};
  ${(props: Props) =>
    props.isImageTop &&
    css`
      border-radius: 0px 20px 0px 0px;
      width: 100%;
      height: 50%;
      border-bottom: 1px solid var(--text-white);
      span {
        font-size: 8px;
        line-height: 8px;
      }
    `};
  ${(props: Props) =>
    props.isImageBottom &&
    css`
      border-radius: 0px 0px 20px 0px;
      width: 100%;
      height: 50%;
      span {
        font-size: 8px;
        line-height: 8px;
      }
    `};
`;

export { UserLogoWrapper, ChatBoxWrapper, ChatBoxGroup, UserLogo, TextWrapper };
