import styled, { css } from 'styled-components';
import { Typography } from '../../global/theme';

const Main = styled.div<{ isActive: boolean }>`
  max-width: 184px;
  width: 100%;
  border-radius: 8px;
  border: 0.5px solid var(--border-primary);
  display: flex;
  flex-direction: column;
  box-shadow: var(--shadow-card);
  .moreicon {
    display: none;
  }
  :hover {
    cursor: pointer;
    box-shadow: var(--shadow-card-hover);
    .overlay {
      display: block;
    }
    .moreicon {
      display: flex;
    }
  }
  ${(props) =>
    props.isActive &&
    css`
      box-shadow: var(--shadow-card-hover);
      .overlay {
        display: block;
      }
      .moreicon {
        display: flex;
      }
    `}
`;
const Top = styled.div`
  height: 106px;
  position: relative;
  .img {
    width: 100%;
    height: 106px;
    max-width: 184px;
    object-fit: cover;
    border-radius: 8px 8px 0 0;
  }
  video {
    border-radius: 8px 8px 0px 0px;
    height: 100%;
    width: 100%;
    object-fit: cover;
    max-width: 184px;
  }
`;

const Bottom = styled.div`
  border-top: 0.5px solid var(--border-primary);
  padding: 11px 12px 12px;
  display: flex;
  flex-direction: column;
  gap: 3px;
`;
const Title = styled.div`
  ${Typography.body_md_medium}
  color: var(--text-primary);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
`;

const Time = styled.div`
  ${Typography.body_sm}
  color: var(--text-secondary);
`;
const Overlay = styled.div`
  background-color: var(--background-overlay);
  top: 0;
  left: 0;
  width: 100%;
  height: 106px;
  position: absolute;
  border-radius: 8px 8px 0 0;
  display: none;
  cursor: pointer;
`;
const Moreicon = styled.div`
  width: 18px;
  height: 18px;
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 16px;
  .fillColor {
    fill: var(--text-secondary);
    stroke: var(--text-secondary);
    :hover {
      background-color: var(--neutral-secondary);
      border-radius: 4px;
    }
  }
  .active {
    background-color: var(--neutral-secondary);
    border-radius: 4px;
  }
`;

const Div = styled.div``;

export { Main, Top, Bottom, Title, Time, Overlay, Moreicon, Div };
