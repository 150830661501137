import React, { useCallback, useEffect, useRef, useState } from 'react';
import AppLayout from '../../../component/appLayout';
import {
  Bottom,
  BottomLoading,
  BoxLoading,
  Description,
  FirstLoading,
  Firstcard,
  Firstprojects,
  Header,
  HeaderTask,
  Heading,
  Icon,
  Left,
  LeftLoading,
  Main,
  Name,
  Projectcard,
  RightLoading,
  Task,
  Tasklist,
  Tasktext,
  Title,
  TopLoading
} from './styles';
import Button from '../../../component/button';
import Card from './card';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { useHistory } from 'react-router-dom';
import ModalCustom from '../../../component/models/modal';
import Createtemplatemodal from '../../../component/models/createTemplateModal';
import {
  deleteProjectTemplate,
  deleteTaskTemplate,
  getProjectTemplateDetails,
  getProjectTemplateList,
  getTaskTemplateDetails,
  getTaskTemplateList
} from '../../../services/settingServices';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from '../../../helpers/common';
import { captureException } from '../../../services/logService';
import {
  clearTaskTemplateData,
  setProjectTemplateInput,
  setProjectTemplateList,
  setTaskTemplateList
} from '../../../actions/settingActions';
import Deletemodal from '../../../component/models/deleteModel';
import { trackAnalyticActivity } from '../../../services/analyticsService';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import { PersonalHeader } from '../apps/styles';
import { ResponsiveHeader } from '../SettingHeaderResponsive';
import { useMobileDevice } from '../../../global/useMobile';
import { MENU_OPTIONS } from '../../../global/constants';
import { ROUTES } from '../../../routes/urls';
import { PROJECT_ANALYTICS, VIEW_ANALYTICS } from '../../../global/analyticsConstants';
import { Dropdown, DropdownItem } from '../../../component/Dropdown';

export default function Templates() {
  // Refs
  const buttonRef = useRef<React.RefObject<HTMLDivElement>[]>([]);
  const taskButtonRef = useRef<React.RefObject<HTMLDivElement>[]>([]);
  // States
  const [openTemplateModal, setOpenTemplateModal] = useState(false);
  const [openDeleteTemplateModal, setOpenDeleteTemplateModal] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState('');
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isProjectTemplate, setIsProjectTemplate] = useState(false);
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { settings: settingsSelector, workspace: workspaceSelector } = stateSelector || {};
  const { projectTemplateList, taskTemplateList } = settingsSelector;
  const { workspace } = workspaceSelector;
  const { id: workspace_id } = workspace;
  // Other variables
  const isMobile = useMobileDevice();
  const dispatch = useDispatch();
  const history = useHistory();
  const menuItems = [
    { key: 1, label: 'Edit' },
    { key: 2, label: 'Delete' }
  ];

  const onClickCreateTemplate = useCallback(() => {
    trackAnalyticActivity(PROJECT_ANALYTICS.TEMPLATE_CREATE_CLICKED);
    setOpenTemplateModal(true);
  }, []);

  const onCloseTemplateModal = useCallback(() => {
    setOpenTemplateModal(false);
  }, []);

  const loadData = useCallback(async () => {
    try {
      if (!isEmpty(workspace_id)) {
        setLoading(true);
        await dispatch(getProjectTemplateList(workspace_id));
        await dispatch(getTaskTemplateList());
        dispatch(clearTaskTemplateData());
      }
    } catch (error) {
      captureException(error);
      console.log('error', error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [dispatch, workspace_id]);

  useEffect(() => {
    trackAnalyticActivity(VIEW_ANALYTICS.SETTING_TEMPLATE);
    loadData();
  }, [loadData]);

  useEffect(() => {
    return () => {
      dispatch(setProjectTemplateList([]));
      dispatch(setTaskTemplateList([]));
    };
  }, [dispatch]);

  const onClickEdit = useCallback(
    async (itemId: string, isProject: boolean) => {
      if (isProject) {
        const templateDetails = await dispatch(getProjectTemplateDetails(workspace, itemId, false));
        dispatch(setProjectTemplateInput(templateDetails));
        setOpenTemplateModal(true);
      } else {
        await dispatch(getTaskTemplateDetails(workspace, itemId));
        history.push(`/settings/template/taskTemplateDetails/${itemId}`);
      }
    },
    [dispatch, history, workspace]
  );

  const onClickDelete = useCallback((itemId: string) => {
    setSelectedItemId(itemId);
    setOpenDeleteTemplateModal(true);
  }, []);

  const handleClick = useCallback(
    async (key: number | string, itemId: string, isProject: boolean) => {
      key = Number(key);
      setIsProjectTemplate(isProject);
      if (key === MENU_OPTIONS.EDIT) {
        onClickEdit(itemId, isProject);
      } else {
        onClickDelete(itemId);
      }
    },
    [onClickDelete, onClickEdit]
  );

  const onCloseDeleteModal = useCallback(() => {
    setSelectedItemId('');
    setOpenDeleteTemplateModal(false);
  }, []);

  const deleteTemplate = useCallback(async () => {
    try {
      setButtonLoading(true);
      let result;
      if (isProjectTemplate) {
        result = await dispatch(deleteProjectTemplate(selectedItemId));
      } else {
        result = await dispatch(deleteTaskTemplate(selectedItemId));
      }

      if (result) {
        onCloseDeleteModal();
        loadData();
      }
    } catch (error) {
      console.log('error', error);
      setButtonLoading(false);
    } finally {
      setButtonLoading(false);
    }
  }, [dispatch, isProjectTemplate, loadData, onCloseDeleteModal, selectedItemId]);

  const handleOpenTemplate = () => {
    history.push(ROUTES.TASK_TEMPLATES_SETTING_DETAILS);
  };

  return (
    <div>
      <AppLayout>
        <Main>
          <Header>
            <PersonalHeader>
              {isMobile && <ResponsiveHeader />}
              <Title>Templates</Title>
            </PersonalHeader>
            {/* <Button onClick={onClickCreateTemplate} title='Create New' type='button' modelbtn /> */}
          </Header>
          <Bottom>
            <Firstcard>
              <HeaderTask>
                <Heading>Project</Heading>
                <Button onClick={onClickCreateTemplate} title='Create New' type='button' smallbutton={true} />
              </HeaderTask>
              {loading && projectTemplateList?.length === 0 && (
                <BoxLoading>
                  <FirstLoading>
                    <LeftLoading>
                      <TopLoading className='loading-animation'></TopLoading>
                      <BottomLoading className='loading-animation'></BottomLoading>
                    </LeftLoading>
                    <RightLoading className='loading-animation'></RightLoading>
                  </FirstLoading>
                </BoxLoading>
              )}
              {!loading && projectTemplateList?.length === 0 && (
                <Card heading={'Create template for Project'} isProject={true} />
              )}
              {/* Project card */}
              {projectTemplateList?.length > 0 && (
                <Projectcard>
                  {projectTemplateList?.map((item, index: number) => {
                    if (!buttonRef?.current[index]) {
                      buttonRef.current[index] = React.createRef<HTMLDivElement>();
                    }
                    return (
                      <Firstprojects key={item?.id}>
                        <Left onClick={() => history.push(`/settings/templates/${item['_id']}`)}>
                          <Name>{item?.name}</Name>
                          {!isEmpty(item.description) && <Description>{item?.description}</Description>}
                        </Left>
                        <Dropdown
                          activeClassName='active'
                          content={
                            <Icon ref={buttonRef?.current[index]} onClick={() => setSelectedItemId(item['_id'])}>
                              <SVGIcon
                                name='more-icon'
                                width='18'
                                height='18'
                                viewBox='0 0 18 18'
                                fill='none'
                                className='more-icon'
                              />
                            </Icon>
                          }
                          trigger='click'>
                          {menuItems.map((item) => (
                            <DropdownItem
                              key={item.key}
                              label={item.label}
                              onClick={() => handleClick(item.key, selectedItemId, true)}
                              tone={item.label === 'Delete' ? 'critical' : 'primary'}
                            />
                          ))}
                        </Dropdown>
                      </Firstprojects>
                    );
                  })}
                </Projectcard>
              )}
            </Firstcard>
            <Firstcard>
              <HeaderTask>
                <Heading>Task</Heading>
                {taskTemplateList?.length > 0 && (
                  <Button title='Create new' smallbutton={true} onClick={handleOpenTemplate} />
                )}
              </HeaderTask>

              {taskTemplateList?.length === 0 && (
                <Card
                  heading={'Create template for Task'}
                  isPadding={true}
                  isTask={true}
                  onClickTaskTemplate={handleOpenTemplate}
                />
              )}
              {/* Task list */}
              {taskTemplateList?.length > 0 && (
                <Tasklist>
                  {taskTemplateList?.map((item, index) => {
                    if (!taskButtonRef?.current[index]) {
                      taskButtonRef.current[index] = React.createRef<HTMLDivElement>();
                    }
                    return (
                      <Task key={item['_id']}>
                        <Tasktext>{item?.Title}</Tasktext>
                        <Dropdown
                          activeClassName='active'
                          content={
                            <Icon
                              ref={taskButtonRef.current[index]}
                              onClick={() => setSelectedItemId(item['_id'] ?? '')}>
                              <SVGIcon
                                name='more-icon'
                                width='18'
                                height='18'
                                viewBox='0 0 18 18'
                                fill='none'
                                className='more-icon'
                              />
                            </Icon>
                          }
                          trigger='click'>
                          {menuItems.map((item) => (
                            <DropdownItem
                              key={item.key}
                              label={item.label}
                              onClick={() => handleClick(item.key, selectedItemId, false)}
                              tone={item.label === 'Delete' ? 'critical' : 'primary'}
                            />
                          ))}
                        </Dropdown>
                      </Task>
                    );
                  })}
                </Tasklist>
              )}
            </Firstcard>
            <Firstcard>
              <Heading>File</Heading>
              <Card heading={'Create template for File'} isPadding={true} />
            </Firstcard>
          </Bottom>
        </Main>
        <ModalCustom open={openTemplateModal} onClose={onCloseTemplateModal} width={400}>
          <Createtemplatemodal onCancel={onCloseTemplateModal} />
        </ModalCustom>
        <ModalCustom open={openDeleteTemplateModal} onClose={onCloseDeleteModal} width={334}>
          <Deletemodal
            onClose={onCloseDeleteModal}
            loading={buttonLoading}
            onOk={deleteTemplate}
            modaltitle='Delete Template?'
            description='Are you sure you want to delete this template?'
          />
        </ModalCustom>
      </AppLayout>
    </div>
  );
}
