import styled from 'styled-components';
import { Typography } from '../../../global/theme';
const Groupmodal = styled.div`
  padding: 24px 16px 16px 0px;
`;
const Groupbody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  padding: 16px 16px 0;
`;
const Groupmodel = styled.form`
  background: var(--background-primary);
  box-shadow: var(--shadow-modal);
  border-radius: 8px;
  text-align: end;
`;
const GroupModalhead = styled.div`
  border-bottom: 0.5px solid var(--border-primary);
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Modeltitle = styled.p`
  ${Typography.body_md_medium}
  color: var(--text-secondary);
  margin: 0;
  cursor: pointer;
`;

const Todotext = styled.p`
  ${Typography.heading_md}
  color: var(--text-primary);
  cursor: pointer;
  margin: 0px;
`;

const Icon = styled.div`
  display: inline-flex;
  cursor: pointer;
  .closeicon {
    stroke: var(--text-primary);
  }
  :hover {
    background-color: var(--neutral-secondary);
    border-radius: 4px;
  }
`;

const Inputvalues = styled.input`
  width: -webkit-fill-available;
  padding: 8px 12px;
  ${Typography.body_lg}
  color: var(--text-primary);
  border: 0.5px solid var(--border-primary);
  background-color: var(--background-primary);
  border-radius: 6px;
  outline: none;
  :focus {
    border: 0.5px solid var(--brand-primary);
    z-index: 999;
  }
  ::placeholder {
    ${Typography.body_lg}
    color: var(--text-secondary);
  }
`;

export { Groupmodal, Groupbody, Groupmodel, GroupModalhead, Icon, Todotext, Modeltitle, Inputvalues };
