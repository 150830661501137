import styled, { css } from 'styled-components';
import { Typography } from '../../global/theme';

interface Prop {
  isPadding?: boolean;
  isProject?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  iscustom?: boolean;
  isResponsiveFullWidth?: boolean;
  isMonthPicker?: boolean;
  isRight?: boolean;
}

const Header = styled.div`
  position: sticky;
  z-index: 999;
  ${Typography.heading_lg}
  color: var(--text-primary);
  top: 0;
  align-items: center;
  padding: 16px 24px;
  background-color: var(--background-secondary);
  margin: 0 -24px;
  width: -webkit-fill-available;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 0.5px solid var(--border-primary);
  p {
    margin: 0;
    color: var(--brand-primary);
    ${Typography.body_md_medium}
    cursor: pointer;
  }
  a {
    text-decoration: none;
  }
  @media (max-width: 449px) {
    padding: 12px 16px;
    gap: 24px;
    margin: 0 -16px;
    p {
      color: var(--text-primary);
    }
  }
`;
const HeaderLeft = styled.div`
  display: flex;
  gap: 24px;
`;

const Contentheading = styled.div`
  /* temporary css untill it is set in all pages if props are required */
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* flex-wrap: wrap; */
  gap: 10px;
  @media (max-width: 449px) {
    display: grid;
    grid-template-columns: repeat(1, 2fr);
  }
`;
const Leftcontent = styled.div`
  /* temporary css untill it is set in all pages if props are required */
  max-width: 700px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  position: relative;
  @media (max-width: 449px) {
    max-width: -webkit-fill-available;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`;
const DropdownButton = styled.div<Prop>`
  max-width: 154px;
  min-width: 154px;
  width: 100%;
  @media (max-width: 449px) {
    max-width: 100%;
    ${(props) =>
      props.isResponsiveFullWidth &&
      css`
        max-width: calc(100vw - 32px);
      `}
  }
  ${(props) =>
    props.isRight &&
    css`
      max-width: 100%;
    `}
  ${(props) =>
    props.isMonthPicker &&
    css`
      max-width: 184px;
      width: 100%;
    `}
     ${(props) =>
    props.isProject &&
    css`
      @media (max-width: 449px) {
        grid-column-start: span 2;
      }
    `}
`;

const Rightcontent = styled.div`
  max-width: 336px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 0.5px solid var(--border-primary);
  background: var(--background-primary);
  .disable-calendar {
    color: var(--text-secondary) !important;
  }
  @media (max-width: 449px) {
    max-width: calc(100vw - 32px);
  }
`;

const Monthpicker = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  gap: 10px;
  width: auto;
  background: var(--background-primary);
  border-width: 0.5px 0.5px 0.5px 0;
  border-color: var(--border-primary);
  border-radius: 8px;
  padding: 4px 12px;
  white-space: nowrap;
  @media (max-width: 449px) {
    gap: 3px;
    padding: 4px 5px 4px 12px;
  }
  .dropdown-icon {
    transform: rotate(180deg);
  }
  .stroke-color {
    stroke: var(--text-primary);
  }
  .fill-color {
    fill: var(--text-secondary);
  }
  .arrows {
    path {
      stroke: var(--text-primary);
    }
  }
  .arrow-icon {
    stroke: var(--text-primary);
  }
  .disabled {
    path {
      stroke: var(--text-secondary);
    }
  }
`;
const Text = styled.div`
  ${Typography.body_md}
  text-align: center;
  color: var(--text-primary);
  margin: 0;
  span {
    color: var(--brand-primary);
    padding-left: 12px;
    border-left: 0.5px solid var(--border-primary);
  }
`;
const Total = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  justify-content: center;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 24px 0;
  @media (max-width: 449px) {
    margin-top: 16px;
    gap: 16px;
  }
`;
const Dropbox = styled.div<Prop>`
  position: absolute;
  width: 100%;
  max-width: 154px;
  background: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 8px;
  padding: 8px 0;
  ${(props: Prop) =>
    props.isPadding &&
    css`
      padding: 0 0 6px;
    `}
  ${(props: Prop) =>
    props.isProject &&
    css`
      max-width: 172px;
    `}
  .react-calendar__navigation {
    margin-bottom: 10px;
  }
  .react-calendar__navigation button {
    background: none;
    ${Typography.body_lg}
    height: 20px;
    margin-top: 0px;
    padding: 0 3px;
  }
  .react-calendar__tile--now:enabled:hover,
  .kKROaE .react-calendar__tile--now:enabled:focus {
    border-radius: 37px;
  }
  .menuStyle {
    box-shadow: none;
    padding: 0px;
    display: flex;
    flex-direction: column;
    border: none;
    .menuitem {
      white-space: nowrap;
      overflow: hidden;
      display: block;
      text-overflow: ellipsis;
    }
  }
`;
const Dropitem = styled.div`
  :hover {
    background: var(--neutral-secondary);
    cursor: pointer;
  }
  padding: 8px 16px;
  ${Typography.body_md}
  color: var(--text-primary);
`;

const Button = styled.button<Prop>`
  width: 100%;
  background: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  padding: 4px 5px 4px 12px;
  align-items: center;
  cursor: pointer;
  ${(props) =>
    props.iscustom &&
    css`
      padding: 4px 5px 4px 12px;
      border-width: 0 0.5px 0 0;
      border-color: var(--border-primary);
      border-radius: 8px 0 0 8px;
    `}
  p {
    ${Typography.body_md}
    color: var(--text-primary);
    margin: 0;
  }
  ${(props) =>
    props.disabled &&
    css`
      p {
        color: var(--text-secondary);
      }
      svg {
        path {
          stroke: var(--text-secondary) !important;
        }
      }
    `}
  .dropdown-icon {
    transform: rotate(0deg);
  }
  .open {
    transform: rotate(180deg);
  }
  .stroke-color {
    path {
      stroke: var(--text-primary);
    }
  }
`;

const Datedropbox = styled.div<Prop>`
  position: absolute;
  right: 0;
  max-width: min-content;
  background: var(--background-primary);
  border-radius: 8px;
  border: 1px solid var(--border-primary);
  padding: 0;
  margin-top: 5px;
  z-index: 99;

  .react-calendar__navigation {
    margin-bottom: 10px;
  }
  .react-calendar__navigation button {
    background: none;
    ${Typography.body_lg}
    margin-top: 0px;
    padding: 0 3px;
  }
  .react-calendar__tile--now:enabled:hover,
  .kKROaE .react-calendar__tile--now:enabled:focus {
    border-radius: 37px;
  }
  .rdrStartEdge .rdrEndEdge {
    color: var(--brand-primary) !important;
  }
  .menuStyle {
    box-shadow: none;
    padding: 0px;
    display: flex;
    flex-direction: column;
    .menuitem {
      white-space: nowrap;
      overflow: hidden;
      display: block;
      text-overflow: ellipsis;
    }
  }
  .rdrMonth .rdrDayDisabled {
    background-color: var(--background-primary);
  }
  .rdrCalendarWrapper {
    background-color: var(--background-primary);
    border-radius: 8px;
  }
  .rdrDayNumber span {
    color: var(--text-primary);
  }
  .rdrDayPassive .rdrDayNumber span {
    color: var(--text-secondary);
  }
  .rdrWeekDay {
    color: var(--text-secondary);
  }
  .rdrDay {
    color: var(--text-secondary) !important ;
  }

  .rdrDateDisplayWrapper {
    background-color: var(--background-primary);
    border-radius: 8px;
  }
  .rdrDateDisplayItem input {
    background-color: var(--background-primary);
    border: 0.5px solid var(--border-primary);
    color: var(--text-primary);
    border-radius: 4px;
  }
  .rdrDateDisplayItem {
    background-color: var(--background-primary);
    box-shadow: none;
  }
  .rdrMonthAndYearPickers select:hover {
    background-color: var(--neutral-secondary);
  }
  .rdrMonthAndYearPickers select {
    color: var(--text-primary);
  }
  .rdrDateDisplay {
    color: transparent !important ;
  }
  .rdrNextPrevButton {
    background-color: var(--neutral-secondary);
  }
  .rdrStartEdge {
    color: var(--text-secondary) !important;
  }
  .rdrInRange {
    color: var(--text-secondary) !important;
  }
  .rdrEndEdge {
    color: var(--text-secondary) !important;
  }
  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
    color: var(--background-primary);
  }

  .rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after,
  .rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after,
  .rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after,
  .rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after {
    background-color: var(--background-primary);
  }
  .rdrNextButton i {
    border-color: transparent transparent transparent var(--text-primary);
  }
  .rdrPprevButton i {
    border-color: transparent var(--text-primary) transparent transparent;
  }
  ${(props) =>
    props.isLoading &&
    css`
      opacity: 0.8;
      cursor: default;
      pointer-events: none;
    `}
`;
const Empty = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  left: 50%;
  @media (max-width: 449px) {
    transform: translate(-50%, -50%);
  }
`;
const BackIcon = styled.div`
  display: none;
  @media (max-width: 449px) {
    display: block;
    cursor: pointer;
    width: 24px;
    height: 24px;
    path {
      stroke: var(--text-primary);
    }
    :hover {
      background-color: var(--neutral-secondary);
      border-radius: 4px;
    }
  }
`;
const TableSection = styled.div`
  overflow: auto;
  border: 0.5px solid var(--border-primary);
  border-radius: 8px;
  scrollbar-width: none;
`;
const CsvDiv = styled.div`
  color: var(--brand-primary);
  ${Typography.body_md_medium}
  cursor: pointer;
`;
const DateSelection = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  @media (max-width: 449px) {
    gap: 4px;
  }
`;
const SvgIconDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ItemDiv = styled.div`
  max-width: 154px;
  min-width: 154px;
  width: 100%;
  @media (max-width: 449px) {
    max-width: unset;
  }
`;

export {
  Header,
  Contentheading,
  Rightcontent,
  Leftcontent,
  Monthpicker,
  Text,
  Total,
  Container,
  Dropitem,
  Dropbox,
  Button,
  Datedropbox,
  Empty,
  BackIcon,
  TableSection,
  DropdownButton,
  HeaderLeft,
  CsvDiv,
  DateSelection,
  SvgIconDiv,
  ItemDiv
};
