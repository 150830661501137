import styled, { css } from 'styled-components';
import { Typography } from '../../../../global/theme';

interface Props {
  isOverdue?: boolean;
  isHide?: boolean;
  isGap?: boolean;
  isTaskItem?: boolean;
  isPadding?: boolean;
  isShowAllCompletedTask?: boolean;
  isShowCompletedTask?: boolean;
  isSelected?: boolean;
  color?: string;
  hasGap?: boolean;
  isActive?: boolean;
  showSubTask?: boolean;
  isActiveSubTask?: boolean;
  isApplyMargin?: boolean;
}

const Group = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: auto;
`;

const TaskFormation = styled.div``;

const Content = styled.div<Props>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 16px;
  padding-left: 24px;

  ${(props: Props) =>
    props.isHide &&
    css`
      gap: 0px;
    `}
  ${(props: Props) =>
    props.isPadding &&
    css`
      padding-left: 0;
    `}
    @media (max-width: 449px) {
    padding-left: 0;
  }
`;

const Itemblock = styled.div<Props>`
  ${(props: Props) =>
    props.isHide &&
    css`
      display: none;
    `}
`;

const Tasklist = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${(props: Props) =>
    props.isHide &&
    css`
      display: none;
    `}

  ${(props: Props) =>
    props.isGap &&
    css`
      margin-top: 20px;
    `}
    @media (max-width: 449px) {
    align-items: flex-start;
  }
`;

const Title = styled.h1`
  ${Typography.heading_md}
  color: var(--text-primary);
  margin: 0;

  @media (max-width: 449px) {
    margin-bottom: 8px;
  }
`;

const Iconhidden = styled.div`
  position: absolute;
  left: -15px;
  top: 9px;
  cursor: pointer;
  .svgicon {
    opacity: 0;
    stroke: var(--text-secondary);
  }

  @media (max-width: 449px) {
    .svgicon {
      display: none;
    }
  }
  ${(props: Props) =>
    props.showSubTask &&
    css`
      display: none;
    `}
`;

const Taskcontent = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0.5px solid transparent;
  padding: 3px 5px;
  margin: 0 -10px;
  border-radius: 34px;
  margin-top: -1px;
  gap: 40px;

  :hover .svgicon {
    opacity: 1;
  }

  :hover .plus-icon {
    visibility: visible;
    stroke: var(--background-primary);
  }

  :hover .task-input {
    background-color: var(--text-white);
  }

  :hover {
    background: var(--background-primary) !important;
    border: 0.5px solid var(--border-primary) !important;
    box-shadow: var(--shadow-card) !important;
  }

  ${(props: Props) =>
    props.isSelected &&
    css`
      background: var(--background-primary) !important;
      border: 0.5px solid var(--border-primary) !important;
      box-shadow: var(--shadow-card) !important;
    `}

  ${(props: Props) =>
    props.isActive &&
    css`
      background: var(--background-primary) !important;
      border: 0.5px solid var(--border-primary) !important;
      box-shadow: var(--shadow-card) !important;
    `}
      ${(props: Props) =>
    props.showSubTask &&
    css`
      margin-left: 0px;
      @media (max-width: 449px) {
        padding-left: 10px !important;
      }
      :hover .svgicon {
        opacity: 0;
      }
    `}
  @media (max-width: 449px) {
    gap: 8px;
    border: none;
    padding: 6px 0px;
    margin: 0;
    .assign {
      display: flex;
    }

    .webdropdown {
      display: none;
    }
    :hover {
      border: none !important;
      box-shadow: none !important;
      background: none !important;
    }
    :hover .svgicon {
      opacity: 1;
    }
    :hover .plus-icon {
      @media (max-width: 449px) {
        display: none;
      }
    }
  }
`;

const Taskitem = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  @media (max-width: 449px) {
    width: 100%;
    justify-content: space-between;
    gap: 18px;
    height: 24px;
  }
`;

const Form = styled.div<Props>`
  display: flex;
  align-items: center;
  gap: ${(props: Props) => (props.isTaskItem ? '6px' : '10px')};
  @media (max-width: 449px) {
    gap: 10px;
    .checkbox-round {
      padding: 8px;
    }
  }
  .svg-icon {
    path {
      stroke: var(--text-secondary);
    }
  }
  .cancelled-icon {
    path {
      fill: var(--text-tertiary);
    }
  }
  .task-todo-icon {
    path {
      fill: var(--background-primary);
      stroke: var(--text-secondary);
    }
  }
  .task-inprogress-icon {
    path.first-path {
      fill: var(--background-primary);
    }
  }
  .task-inreview-icon {
    path.first-path {
      fill: var(--background-primary);
    }
  }
  .task-status-icon {
    path.fill1 {
      fill: var(--background-primary);
    }
    path.fill2 {
      fill: var(--text-secondary);
    }
    path.fill3 {
      fill: var(--background-primary);
    }
  }
  .task-completed-icon {
    path {
      fill: var(--text-tertiary);
    }
  }
  .checkbox-round {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid var(--text-secondary);
    appearance: none;
    outline: none;
    cursor: pointer;
    margin: 0;
    padding: 7px;
  }

  input[type='checkbox'] {
    position: relative;
    cursor: pointer;
    opacity: 1;
    background-color: var(--background-primary);
  }

  input[type='checkbox']:checked {
    background-color: var(--text-tertiary);
    opacity: 1;
    border: 1px solid var(--text-tertiary);
  }

  input[type='checkbox']:checked::before {
    content: '';
    position: absolute;
    right: 50%;
    top: 50%;
    width: 3px;
    height: 8px;
    border: solid var(--text-white);
    border-width: 0 2px 2px 0;
    margin: -0.5px -1px 0 -1px;
    transform: rotate(45deg) translate(-50%, -50%) scale(0.7);
    z-index: 2;
  }
  @media (max-width: 449px) {
    gap: 10px;
    .checkbox-round {
      padding: 8px;
    }
  }
`;
const ButtonIcon = styled.div`
  padding: 4px;
  border-radius: 12px;
  display: flex;
  :hover {
    background-color: var(--neutral-secondary);
    input {
      filter: brightness(var(--brightness-value));
      background-color: transparent;
    }
  }
`;
const Inputvalue = styled.input``;

const Label = styled.label`
  ${Typography.body_md}
  color: var(--text-primary);
  margin: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
  a {
    color: var(--text-link);
    text-decoration: underline;
    display: inline-block;
    :hover {
      text-decoration: none;
    }
  }
`;

const Assigngroup = styled.div`
  padding: 2px 0;
  ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: max-content;
  }

  li {
    margin-left: -10px;
    border-radius: 50%;
    background-color: var(--background-primary);
  }

  .plus-icon {
    visibility: hidden;
    &:first-child {
      margin-left: -10px;
    }
  }

  @media (max-width: 449px) {
    margin-right: 0px;
    .plus-icon {
      display: none;
    }
  }
`;

const AvatarImage = styled.div`
  display: flex;
  align-items: center;
  border-radius: 50%;

  > div > img {
    max-width: 100%;
  }
`;

const Deadline = styled.div<Props>`
  display: flex;
  align-items: center;
  gap: 12px;
  ${(props: Props) =>
    props.hasGap &&
    css`
      gap: 0;
    `}
  svg {
    vertical-align: middle;
  }
  @media (max-width: 449px) {
    display: none;
    gap: 12px;
    flex-wrap: wrap;
  }
`;

const DeadBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }
  @media (max-width: 449px) {
    display: none;
  }
`;

const Text = styled.p<Props>`
  ${Typography.body_md}
  color: var(--text-secondary);
  margin: 0;
  white-space: nowrap;
  ${(props: Props) =>
    props.isOverdue &&
    css`
      color: var(--accent-error);
    `}
`;

const ViewPopupbox = styled.div``;
const View = styled.div`
  width: 94px;
  ${Typography.body_md}
  color: var(--text-primary);
  margin: 0px;
  cursor: pointer;
  padding: 6px 12px;
  line-height: 16px;
  span {
    color: var(--accent-error);
  }
`;

const Showhide = styled.a`
  ${Typography.body_md}
  color: var(--text-secondary);
  cursor: pointer;
  max-width: 146px;
  width: 100%;
  padding-top: 6px;
  :hover {
    color: var(--text-primary);
  }
  @media (max-width: 449px) {
    max-width: max-content;
    padding: 4px 0;
  }
  ${(props: Props) =>
    props.showSubTask &&
    css`
      padding: 4px 0 4px 10px;
    `}
`;
const ShowTask = styled.div`
  display: flex;
  gap: 6px;
  flex-direction: column;
  @media (max-width: 449px) {
    gap: 0px;
  }
`;

const Data = styled.div<Props>`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 0.5px solid var(--border-primary);
  padding-top: ${(props) => (props.isShowAllCompletedTask ? '0' : '5px')};
  ${(props) =>
    props.isShowCompletedTask &&
    css`
      border-top: none;
      padding-top: 0;
    `}
`;
const Tasktitle = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  span {
    ${Typography.body_md}
    color: var(--text-secondary);
  }
`;

const Selectbox = styled.div`
  width: max-content;
  padding: 7px;
  border: 0.5px solid var(--border-primary);
  border-radius: 8px;
  margin: 0 auto;
  background-color: var(--background-primary);
  display: flex;
  gap: 8px;
`;
const Selected = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  padding: 5px 7px;
  border: 1px dashed var(--border-primary);
  border-radius: 5px;
  height: 16px;
  span {
    ${Typography.body_md_medium}
    color: var(--text-secondary);
  }
`;

const Line = styled.div`
  border-right: 1px solid var(--border-primary);
`;
const Closeicon = styled.div`
  cursor: pointer;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  .close-icon {
    stroke: var(--text-secondary);
  }
  :hover {
    background-color: var(--neutral-secondary);
    border-radius: 4px;
  }
`;
const RemoveIcon = styled.div`
  cursor: pointer;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--border-primary);
  border-radius: 4px;
  .delete-icon {
    path {
      fill: var(--text-secondary);
    }
  }
  :hover {
    background-color: var(--neutral-secondary);
    border: 1px solid var(--neutral-secondary);
  }
`;
const Bottom = styled.div`
  position: fixed;
  bottom: 0;
  padding-bottom: 20px;
  width: -webkit-fill-available;
`;
const Dropblock = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 3px 9px 3px 5px;
  border-left: 0.5px solid var(--border-primary);
  &.left-border {
    border-left: none;
  }
`;
const Doticon = styled.div<Props>`
  margin: 4px;
  width: 8px;
  height: 8px;
  border-radius: 20px;
  ${(props: Props) =>
    props.color &&
    css`
      background-color: ${props.color};
    `}
`;
const Droplabel = styled.div`
  ${Typography.body_md}
  color: var(--text-primary);
`;
const Taskcount = styled.div`
  margin-left: 2px;
  padding: 3px 9px;
  border: 0.5px solid var(--border-primary);
  border-radius: 20px;
  color: var(--text-primary);
  ${Typography.body_md};
  cursor: pointer;
  &:hover {
    background-color: var(--neutral-secondary);
  }
  @media (max-width: 449px) {
    margin-left: -2px;
  }
  ${(props: Props) =>
    props.isActiveSubTask &&
    css`
      background-color: var(--neutral-secondary);
    `}
`;
const Multiplelabel = styled.div`
  border-radius: 15px;
  border: 0.5px solid var(--border-primary);
  display: flex;
  @media (max-width: 449px) {
    display: none;
  }
`;

const IconDueDate = styled.div<Props>`
  .fill-color {
    width: 16px;
    height: 16px;
    fill: var(--text-secondary);
  }

  &:hover .fill-color {
    fill: var(--text-primary);
  }
  &:active .fill-color {
    fill: var(--text-primary);
  }
  .calendar-fill-color {
    fill: var(--text-secondary);
  }
  .calendar-due-fill-color {
    fill: var(--accent-error);
  }
  display: flex;
  p {
    ${Typography.body_md_medium}
    color: var(--text-primary);
    white-space: nowrap;
    margin: 0;
    h6 {
      ${Typography.body_md}
      color: var(--text-secondary);
      white-space: nowrap;
      margin: 0;
      :hover {
        color: var(--text-primary);
      }
    }
  }

  .task-grid-calander-icon {
    display: none;
    padding: 3px;
    border: 0.5px solid var(--border-primary);
    border-radius: 4px;
    width: 16px;
    height: 16px;
    path {
      fill: var(--text-secondary);
    }
  }
  .task-grid-calander-icon:hover {
    background-color: var(--neutral-secondary);
    border: 0.5px solid transparent;
  }
`;

const OverLayDiv = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  @media (max-width: 449px) {
    background: var(--background-overlay);
    z-index: 1111;
  }
`;

const OptionDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  @media (max-width: 320px) {
    flex-wrap: wrap;
  }
`;

const Filter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

const TaskNumber = styled.p`
  ${Typography.body_md_medium}
  margin: 0;
  color: var(--text-primary);
`;

const Text2 = styled.span`
  ${Typography.body_md_medium}
  color: #90989a;
`;

const Show = styled.div`
  p {
    ${Typography.body_md_medium}
    color: var(--brand-primary);
    margin: 0;
  }
  :hover {
    cursor: pointer;
  }
`;
const ShowSubTask = styled.div`
  display: flex;
  padding-left: 8px;
`;
const SubTaskList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const LineDiv = styled.div`
  width: 5px;
  border: 0.5px solid var(--border-secondary);
  border-radius: 0 0 0 4px;
  border-style: none none solid solid;
  margin-bottom: 18px;
  ${(props: Props) =>
    props.isApplyMargin &&
    css`
      margin-bottom: 12px;
    `}
`;

const TooltipContent = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export {
  Group,
  TaskFormation,
  Content,
  Itemblock,
  Title,
  Tasklist,
  Taskcontent,
  Taskitem,
  Form,
  Inputvalue,
  Label,
  Assigngroup,
  Deadline,
  Text,
  Iconhidden,
  AvatarImage,
  DeadBlock,
  ViewPopupbox,
  View,
  ShowTask,
  Showhide,
  Data,
  Tasktitle,
  Selectbox,
  Selected,
  Line,
  Closeicon,
  RemoveIcon,
  Bottom,
  Dropblock,
  Doticon,
  Droplabel,
  Taskcount,
  Multiplelabel,
  IconDueDate,
  OverLayDiv,
  OptionDiv,
  Filter,
  TaskNumber,
  Text2,
  Show,
  ShowSubTask,
  SubTaskList,
  LineDiv,
  ButtonIcon,
  TooltipContent
};
