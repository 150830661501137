import React, { useCallback, useEffect, useState } from 'react';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { useDispatch, useSelector } from 'react-redux';
import { clearCustomerDetail, updateCustomerDetail } from '../../../actions/customerActions';
import { getIndividualCustomerDetail, updateCustomerData } from '../../../services/customerServices';
import { isEmail, isEmpty, isNumber } from '../../../helpers/common';
import Button from '../../button';
import { setErrorMessage, setSuccessMessage } from '../../../actions/messageActions';
import { captureException } from '../../../services/logService';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import {
  Form,
  Formation,
  Formbutton,
  Headline,
  Icon,
  Iconview,
  Inputvalues,
  Label,
  MainModalDiv,
  Modalblock,
  Modalhead
} from './styles';

interface Props {
  handleCloseModal: () => void;
}

const CustomerEditModal: React.FC<Props> = (props) => {
  const { handleCloseModal } = props;
  //States
  const [loading, setLoading] = useState(false);
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { customer: customerSelector, workspace: workspaceSelector } = stateSelector || {};
  const { newCustomer, individualCustomer } = customerSelector;
  const { phone, email } = newCustomer || {};
  const { workspace } = workspaceSelector;
  const { id: workspace_id } = workspace;
  //Other variable
  const dispatch = useDispatch();

  const loadData = useCallback(async () => {
    if (!isEmpty(workspace_id)) {
      await dispatch(getIndividualCustomerDetail(individualCustomer?.id));
    }
  }, [workspace_id, dispatch, individualCustomer?.id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onUpdateInputValue = useCallback(
    (propsName: string, value: string) => {
      dispatch(updateCustomerDetail({ propsName, value }));
    },
    [dispatch]
  );

  const onSubmit = useCallback(
    async (e: React.SyntheticEvent) => {
      try {
        e.preventDefault();
        setLoading(true);
        if (!isEmail(email) && !isEmpty(email)) {
          dispatch(setErrorMessage('Please enter valid email address.'));
          return;
        } else if (!isNumber(phone) && !isEmpty(phone)) {
          dispatch(setErrorMessage('Please enter valid phone number.'));
          return;
        }
        const updatePayload = {
          Email: email,
          Phone: phone
        };
        const response = await dispatch(updateCustomerData(individualCustomer?.id, updatePayload));
        if (response) {
          dispatch(setSuccessMessage('Customer details updated successfully!'));
          handleCloseModal();
          dispatch(clearCustomerDetail());
          loadData();
        }
      } catch (e) {
        captureException(e);
        console.log('ERROR', e);
      } finally {
        setLoading(false);
      }
    },
    [email, phone, individualCustomer?.id, dispatch, handleCloseModal, loadData]
  );

  return (
    <MainModalDiv>
      <Modalblock onSubmit={onSubmit}>
        <Modalhead>
          <Headline>Update Additional Details</Headline>
          <Icon onClick={handleCloseModal}>
            <SVGIcon name='close-icon' width='18' height='18' viewBox='0 0 18 18' fill='none' className='close-icon' />
          </Icon>
        </Modalhead>
        <Formation>
          <Form>
            <Label>Company email</Label>
            <Iconview>
              <Inputvalues
                type='email'
                placeholder='Enter company email'
                value={email}
                onChange={(e) => {
                  onUpdateInputValue('email', e.target.value);
                }}
              />
            </Iconview>
          </Form>
          <Form>
            <Label>Phone number</Label>
            <Iconview>
              <Inputvalues
                type='number'
                placeholder='Enter phone number '
                value={phone}
                onChange={(e) => {
                  onUpdateInputValue('phone', e.target.value);
                }}
              />
            </Iconview>
          </Form>
        </Formation>

        <Formbutton>
          <Button
            title='Cancel'
            onClick={handleCloseModal}
            secondary={true}
            type='button'
            hasNotBoxshadow
            modelbtn={true}
          />
          <Button type='submit' title={'Update Customer'} isLoading={loading} modelbtn={true} />
        </Formbutton>
      </Modalblock>
    </MainModalDiv>
  );
};

export default CustomerEditModal;
