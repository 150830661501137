import React, { useCallback, useEffect } from 'react';
import AppLayout from '../../../../../component/appLayout';
import MembersNavbar from '../navBar';
import ActivityMain from '../../../../../component/activity';
import { useDispatch, useSelector } from 'react-redux';
import { getUserActivity } from '../../../../../services/projectServices';
import { useParams } from 'react-router';
import { Div } from './styles';
import { RootReducerInterface } from '../../../../../interfaces/RootReducerInterface';
import { isEmpty } from '../../../../../helpers/common';

const MembersOvewviewDetails: React.FC = () => {
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { project: projectSelector, workspace: workspaceSelector } = stateSelector || {};
  const { userActivity } = projectSelector;
  const { workspace } = workspaceSelector;

  // Other variables
  const dispatch = useDispatch();
  const params: { id: string } = useParams();

  // load initial data
  const loadData = useCallback(async () => {
    if (!isEmpty(workspace?.id)) {
      await dispatch(getUserActivity(params?.id));
    }
  }, [dispatch, params?.id, workspace?.id]);

  useEffect(() => {
    loadData();
  }, [loadData]);
  return (
    <>
      <AppLayout isPadding={true}>
        <MembersNavbar loadData={loadData} />
        <Div>
          <ActivityMain data={userActivity} />
        </Div>
      </AppLayout>
    </>
  );
};

export default MembersOvewviewDetails;
