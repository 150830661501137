import styled, { css } from 'styled-components';
import { Typography } from '../../global/theme';

interface Props {
  status?: string;
  isActive?: boolean;
  isBlackText?: boolean;
  isListheader?: boolean;
}

const TableHeader = styled.td`
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px 8px 0px 0px;
  background-color: var(--background-tertiary);
  gap: 20px;
  @media (max-width: 449px) {
    padding: 0;
    gap: 23px;
    .blank-div {
      position: sticky;
      right: 0;
      padding: 12px;
      background-color: var(--background-tertiary);
      display: flex;
      align-items: center;
      box-shadow: var(--shadow-card);
    }
  }
`;
const Pagedata = styled.div``;
const UserName = styled.div`
  max-width: 355px;
  width: 100%;
  h1 {
    ${Typography.body_md_medium}
    text-transform: uppercase;
    color: var(--text-secondary);
    margin: 0;
  }
  @media (max-width: 449px) {
    padding: 12px 0 12px 12px;
    max-width: 170px;
    min-width: 170px;
  }
`;
const Tabledata = styled.div`
  display: flex;
  gap: 20px;
  align-items: stretch;
  min-width: 600px;
  justify-content: center;
  width: 100%;
  h1 {
    ${Typography.body_md_medium}
    padding-left: 8px;
    padding-right: 9px;
    text-transform: uppercase;
    color: var(--text-secondary);
    margin: 0;
    border-left: 0.5px solid var(--border-primary);
    white-space: nowrap;
  }
  h3 {
    ${Typography.body_md_medium}
    padding-left: 15px;
    text-transform: uppercase;
    color: var(--text-secondary);
    margin: 0;
    width: 110px;
    border-left: 0.5px solid var(--border-primary);
  }
  h4 {
    ${Typography.body_md_medium}
    padding-left: 11px;
    padding-right: 11px;
    text-transform: uppercase;
    color: var(--text-secondary);
    margin: 0;
    border-left: 0.5px solid var(--border-primary);
    white-space: nowrap;
    width: 126px;
  }
  h2 {
    ${Typography.body_md_medium}
    width: 118px;
    text-align: right;
    text-transform: uppercase;
    color: var(--text-secondary);
    margin: 0;
    border-left: 0.5px solid var(--border-primary);
  }
  @media (max-width: 449px) {
    min-width: 570px;
  }
`;
const Table = styled.div`
  border-radius: 8px;
  background-color: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  @media (max-width: 449px) {
    width: 100%;
    overflow: auto;
    scrollbar-width: none;
  }
`;
const TableDetail = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  padding: 7px 12px;
  border-top: 0.5px solid var(--border-primary);
  position: relative;
  cursor: pointer;
  ${(props: Props) =>
    props.isActive &&
    css`
      background-color: var(--neutral-secondary);
    `}
  :hover {
    background-color: var(--neutral-secondary);
    :last-child {
      border-radius: 0 0 8px 8px;
    }
  }
  @media (max-width: 449px) {
    width: max-content;
    gap: 23px;
    padding: 0;
  }
`;
const PersonName = styled.div<Props>`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 355px;
  gap: 2px;
  h1 {
    ${Typography.body_md_medium}
    margin: 0;
    color: var(--text-primary);
  }
  p {
    ${Typography.body_md}
    margin: 0;
    color: var(--text-secondary);
  }
  ${(props) =>
    props.isBlackText &&
    css`
      p {
        ${Typography.body_md_medium}
        margin: 0;
        color: var(--text-primary);
      }
    `}
  @media (max-width: 1024px) {
    width: unset;
    max-width: unset;
  }
  @media (max-width: 449px) {
    min-width: 170px;
    padding: 7px 0 7px 12px;
  }
`;
const Project = styled.div`
  display: flex;
  gap: 23px;
  align-items: center;
  min-width: 570px;
  justify-content: center;
`;
const InvoiceNo = styled.div`
  max-width: 90px;
  width: 100%;
  padding: 0 2px;
  color: var(--text-primary);
  ${Typography.body_md_medium}
  @media (max-width:449px) {
    padding: 0 0 0 12px;
  }
`;
const InvoiceDate = styled.div`
  display: flex;
  flex-direction: column;
  width: 126px;
  gap: 2px;

  h1 {
    ${Typography.body_md_medium}
    color: var(--text-primary);
    margin: 0;
  }
  p {
    ${Typography.body_md}
    color: var(--text-secondary);
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
  }
`;
const Amount = styled.div`
  width: 130px;
  display: flex;
  flex-direction: column;
  text-align: right;
  padding-right: 12px;
  gap: 2px;

  h2 {
    ${Typography.body_md_medium}
    color: var(--text-primary);
    margin: 0;
  }
  p {
    color: var(--text-secondary);
    ${Typography.body_md}
    margin: 0;
  }
`;
const StatusPAID = styled.div<Props>`
  width: 110px;
  color: var(--accent-success);
  ${Typography.body_md_medium}
  text-transform: uppercase;
  ${(props: Props) =>
    props.status === 'PENDING' &&
    css`
      color: var(--accent-caution);
    `}
  ${(props: Props) =>
    props.status === 'PAID' &&
    css`
      color: var(--accent-success);
    `} 
    ${(props: Props) =>
    (props.status === 'DRAFT' || props.status === 'DUE') &&
    css`
      color: var(--text-secondary);
    `}
    ${(props: Props) =>
    props.status === 'OVER DUE' &&
    css`
      color: var(--accent-error);
    `}
`;

const ButtonBlock = styled.div<Props>`
  ${Typography.body_md_medium}
  color: var(--text-secondary);
  padding: 6px 15px;
  border: 0.5px solid transparent;
  cursor: pointer;
  :hover {
    background-color: var(--background-primary);
    border: 0.5px solid var(--border-primary);
    box-shadow: var(--shadow-card);
    border-radius: 999px;
  }
  ${(props: Props) =>
    props.isActive &&
    css`
      background-color: var(--background-primary);
      border: 0.5px solid var(--border-primary);
      box-shadow: var(--shadow-card);
      border-radius: 999px;
      color: var(--text-primary);
    `}
`;
const Headerblock = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  @media (max-width: 449px) {
    gap: 24px;
  }
`;
const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  @media (max-width: 449px) {
    padding: 12px 0;
    gap: 5px;
  }
`;
const Group = styled.div`
  width: 100%;
  max-width: 1022px;
  height: 100vh;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
`;
const Empty = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 449px) {
    button {
      padding: 5px 19px;
    }
  }
`;
const Arrowbtn = styled.div`
  width: 66px;
  background: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 20px;
  display: flex;
`;
const Downarrow = styled.div`
  display: flex;
  .stroke-color {
    transform: rotate(270deg);
    path {
      stroke: var(--text-secondary);
    }
  }
  .arrow-open {
    transform: rotate(90deg);
  }
`;
const Dropbox = styled.div`
  .menuStyle {
    display: flex;
    flex-direction: column;
    gap: 1px;
    padding: 4px;
    background: var(--background-primary);
    border: 0.5px solid var(--border-primary);
    box-shadow: var(--shadow-modal);
    border-radius: 8px;
    margin: 0px;

    .menuitem {
      margin: 0;
      :hover {
        background-color: var(--neutral-secondary);
        border-radius: 6px;
      }
    }
  }
`;

const Left = styled.div`
  cursor: pointer;
  padding: 8px;
  border-right: 0.5px solid var(--border-primary);
  display: flex;
  .stroke-color {
    path {
      stroke: var(--text-secondary);
    }
  }
`;
const Leftdiv = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
const Paginations = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
  @media (max-width: 449px) {
    padding: 20px 0;
    gap: 20px;
  }
  @media (max-width: 320px) {
    flex-direction: column;
  }
`;
const Right = styled.div`
  cursor: pointer;
  padding: 8px;
  display: flex;
  .stroke-color {
    transform: rotate(180deg);
    path {
      stroke: var(--text-secondary);
    }
  }
`;
const Rightdiv = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
const Rowtext = styled.div`
  ${Typography.body_lg_medium}
  color: var(--text-primary);
`;
const ShowRow = styled.div`
  border-radius: 24px;
  background-color: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  padding: 7px 7px 7px 11px;
  display: flex;
  gap: 18px;
  align-items: center;
  cursor: pointer;
`;
const Text = styled.div`
  ${Typography.body_md_medium}
  color: var(--text-secondary);
`;

const PlusIcon = styled.div`
  @media (max-width: 449px) {
    display: flex;
    .circle {
      fill: var(--brand-primary);
      cursor: pointer;
    }
  }
`;
const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

const ResponsiveNavbarIcon = styled.div`
  @media (max-width: 449px) {
    display: flex;
  }
`;

const Icon = styled.div`
  display: inline-flex;
  position: relative;
  .invoice-more-icon {
    cursor: pointer;
    fill: var(--text-secondary);
    stroke: var(--text-secondary);
    :hover {
      background-color: var(--background-muted);
      border-radius: 4px;
    }
  }
  .invoice-icon {
    fill: var(--brand-primary);
  }
`;
const OptionMenu = styled.div<Props>`
  display: flex;
  position: relative;
  @media (max-width: 449px) {
    padding: 19px 12px;
    background-color: var(--background-primary);
    display: flex;
    align-items: center;
    box-shadow: var(--shadow-card);
  }
`;

const DropdownDiv = styled.div`
  .active {
    background-color: var(--background-muted);
    border-radius: 4px;
  }
  @media (max-width: 449px) {
    position: sticky;
    right: 0;
  }
`;

const Blankdiv = styled.div`
  width: 18px;
  height: 18px;
`;
const UserDetail = styled.div`
  position: relative;
`;
export {
  TableHeader,
  UserName,
  Tabledata,
  Table,
  TableDetail,
  PersonName,
  InvoiceNo,
  InvoiceDate,
  Amount,
  Project,
  StatusPAID,
  Pagedata,
  ButtonBlock,
  Headerblock,
  Buttons,
  Group,
  Empty,
  Arrowbtn,
  Downarrow,
  Dropbox,
  Left,
  Leftdiv,
  Paginations,
  Right,
  Rightdiv,
  Rowtext,
  ShowRow,
  Text,
  PlusIcon,
  HeaderLeft,
  ResponsiveNavbarIcon,
  Icon,
  OptionMenu,
  Blankdiv,
  UserDetail,
  DropdownDiv
};
