import styled, { css } from 'styled-components';
import { Typography } from '../../../global/theme';

interface Props {
  isEmptyStateVisible?: boolean;
  isActive?: boolean;
  isExpanded?: boolean;
}
const Modalbox = styled.div`
  max-width: 770px;
  background-color: var(--background-primary);
  border-radius: 12px;
  height: 570px;
  overflow: hidden;
`;
const Icon = styled.div<Props>`
  display: flex;
  position: absolute;
  cursor: pointer;
  top: ${({ isEmptyStateVisible }) => (isEmptyStateVisible ? '16px' : '12px')};
  right: 16px;
  .fill-color {
    stroke: var(--text-secondary);
    :hover {
      background-color: var(--neutral-secondary);
      border-radius: 4px;
    }
  }
  z-index: 1;
`;
const Content = styled.div`
  display: flex;
  height: 100%;
`;
const Leftcontent = styled.div`
  max-width: 489px;
  width: 100%;
  padding: 40px;
  border-right: 0.5px solid var(--border-primary);
  .docs-section {
    border: none;
    border-radius: 4px;
  }
`;

const Rightcontent = styled.div`
  max-width: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Emptybox = styled.div`
  margin: 188px auto;
  max-width: 240px;
  width: 100%;
`;
const Heading = styled.div`
  ${Typography.heading_md}
  color: var(--text-primary);
  padding: 14px 24px 14px 16px;
  border-bottom: 0.5px solid var(--border-primary);
`;
const Bottomcontent = styled.div`
  padding: 4px 0;
  overflow: auto;
  padding-bottom: 46px;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const Firsthistory = styled.div`
  padding: 5px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  :hover {
    background-color: var(--neutral-secondary);
  }
`;
const Text = styled.div`
  ${Typography.body_md_medium}
  color: var(--text-primary);
`;
const ArrowIcon = styled.div<Props>`
  width: 18px;
  height: 18px;
  .fill-color {
    width: 18px;
    height: 18px;
    stroke: var(--text-primary);
  }
  .rotated {
    transform: rotate(90deg);
  }
`;
const Subhistory = styled.div<Props>`
  padding: 6px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  :hover {
    background-color: var(--neutral-secondary);
  }
  ${(props: Props) =>
    props.isActive &&
    css`
      background-color: var(--neutral-secondary);
    `};
`;
const Subtext = styled.div`
  ${Typography.body_md}
  color: var(--text-secondary);
`;
const Doticon = styled.div`
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
`;
const Dot = styled.div<Props>`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--text-tertiary);
  ${(props: Props) =>
    props.isActive &&
    css`
      background-color: var(--brand-primary);
    `};
`;
const Line = styled.div`
  border-left: 0.5px solid var(--border-primary);
  height: 28px;
  top: 1px;
  position: absolute;
  z-index: -1;
  left: 9px;
  ${Subhistory}:last-child & {
    border-left: none;
  }
`;
const Div = styled.div``;

const Bottomtag = styled.div`
  position: fixed;
  max-width: 170px;
  width: 100%;
  border-radius: 0 0 12px 0;
  padding: 8px 15px 16px;
  background-color: var(--background-primary);
  bottom: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
`;
export {
  Modalbox,
  Icon,
  Content,
  Leftcontent,
  Rightcontent,
  Emptybox,
  Heading,
  Bottomcontent,
  Firsthistory,
  Text,
  ArrowIcon,
  Subhistory,
  Subtext,
  Doticon,
  Dot,
  Line,
  Div,
  Bottomtag
};
