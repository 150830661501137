import * as Sentry from '@sentry/react';
import { REACT_APP_SENTRY_KEY } from '../global/environment';
import { isDev, isReactNative } from '../helpers/common';
import { trackAnalyticActivity } from './analyticsService';

/**
 * @desc init log system
 */
export const initLogService = () => {
  try {
    if (isDev()) return;

    if (!REACT_APP_SENTRY_KEY) {
      console.log('REACT_APP_SENTRY_KEY is not found');
      return;
    }

    Sentry.init({
      dsn: REACT_APP_SENTRY_KEY,
      integrations: [Sentry.browserTracingIntegration()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0
    });
  } catch (e) {
    console.error(e);
  }
};

/**
 * @desc set userid and email for error log
 */
export const setLogUser = (user) => {
  try {
    if (isDev()) return;

    if (user) {
      Sentry.withScope(function (scope) {
        scope.setUser({ id: user.id, email: user.email });
      });
    }
  } catch (e) {
    console.log(e);
  }
};

/**
 * @desc set userid and email for error log
 */
export const captureMessage = (errMessage) => {
  try {
    if (isDev()) return;
    Sentry.captureMessage(errMessage);
  } catch (e) {
    console.log('ERROR captureMessage', e);
  }
};

/**
 * @desc set userid and email for error log
 */
export const captureException = (errMessage, placement) => {
  try {
    if (isDev()) return;
    const params = {
      error: errMessage,
      from: 'client',
      placement: placement ? placement : null
    };
    if (isReactNative() && window) {
      const error = JSON.stringify(errMessage);
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'capture-exception', data: error }));
    }
    trackAnalyticActivity('error raised', params);
    Sentry.captureException(errMessage);
  } catch (e) {
    console.log('ERROR captureException', e);
  }
};
