import styled, { css } from 'styled-components';
import { Typography } from '../../global/theme';

interface Props {
  isImage?: boolean;
}

const Group = styled.div``;

const Container = styled.div`
  position: relative;
`;

const Fileblock = styled.div<{ isActive?: boolean }>`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0;
  background-color: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  box-shadow: var(--shadow-card);
  border-radius: 8px;
  cursor: pointer;
  .moreicon {
    display: none;
  }
  :hover {
    background: var(--background-primary);
    border: 0.5px solid var(--border-primary);
    box-shadow: var(--shadow-card-hover);
  }
  :hover .moreicon {
    display: flex;
  }
  ${(props) =>
    props.isActive &&
    css`
      .moreicon {
        display: flex;
      }
    `}
  @media (max-width: 449px) {
    .moreicon {
      display: flex;
    }
  }
`;

const Icon = styled.div<Props>`
  margin: auto;
  padding: 11px 0;
  width: 60px;
  height: 60px;
  ${(props) =>
    props.isImage &&
    css`
      padding: 0;
      margin: unset;
      width: 100%;
      height: 106px;
    `}
  @media (max-width: 449px) {
    width: 53px;
    height: 53px;
    padding: 8px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    ${(props) =>
      props.isImage &&
      css`
        padding: 0;
        margin: unset;
        width: 100%;
        height: 97px;
      `}
  }
`;

const Filecontent = styled.div`
  border-top: 0.5px solid var(--border-primary);
  padding: 12px;
  @media (max-width: 449px) {
    padding: 14px;
  }
`;

const Title = styled.h1`
  ${Typography.body_md_medium}
  color: var(--text-primary);
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 449px) {
    color: var(--text-primary);
  }
`;

const Text = styled.p`
  ${Typography.body_sm}
  color: var(--text-secondary);
  margin: 1px 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 449px) {
    color: var(--text-secondary);
  }
`;
const Moreicon = styled.div<Props>`
  position: absolute;
  top: 12px;
  right: 16px;
  width: 18px;
  height: 18px;
  cursor: pointer;
  .fillColor {
    fill: var(--text-primary);
    stroke: var(--text-primary);
    :hover {
      background-color: var(--neutral-secondary);
      border-radius: 4px;
      ${(props) =>
        props.isImage &&
        css`
          background-color: var(--neutral-secondary);
        `}
    }
  }
`;

const Image = styled.div<Props>`
  position: relative;
  padding: 12px 16px;
  ${(props) =>
    props.isImage &&
    css`
      padding: 0;
    `}
  @media (max-width: 449px) {
    padding: 14px 14px;
    ${(props) =>
      props.isImage &&
      css`
        padding: 0;
      `}
  }
`;

const DropboxModal = styled.div``;

const IconBlock = styled.div`
  display: flex;
  align-items: center;
  .fillColor {
    stroke: var(--text-primary);
  }
  :hover {
    background-color: var(--neutral-secondary);
    border-radius: 4px;
  }
`;

const ModalBlock = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid var(--border-primary);
  padding: 12px 16px;
`;

const ModalHeader = styled.div`
  display: flex;
  gap: 10px;
`;

const ModalTitle = styled.h2`
  ${Typography.heading_md}
  color: var(--text-primary);
  margin: 0;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;
`;

const ModalForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  .angledown-icon {
    path {
      stroke: var(--text-primary);
    }
  }
  .ant-select-single {
    height: unset;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 16px;
    height: 44px;
    @media (max-width: 449px) {
      height: 48px;
    }
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 0.5px solid var(--border-primary);
    background-color: var(--background-primary);
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 42px;
  }
  .ant-select-single .ant-select-selector {
    color: var(--text-primary);
    ${Typography.body_lg}
  }

  .ant-select-focused.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
      .ant-pagination-size-changer
    )
    .ant-select-selector {
    border: 0.5px solid var(--brand-primary);
    box-shadow: none;
  }
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover
    .ant-select-selector {
    border-color: var(--brand-primary);
    box-shadow: none;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-search {
    padding: 10px 0;
    inset-inline-start: 16px;
    inset-inline-end: 16px;

    @media (max-width: 449px) {
      padding: 12px 0;
    }
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 22px;
  }
  .ant-select-single.ant-select-open .ant-select-selection-item {
    color: var(--text-primary);
  }
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    color: var(--text-secondary);
    @media (max-width: 449px) {
      padding: 8px 0;
    }
  }
  &.ant-select-item-option-content {
    color: var(--text-primary);
  }
`;

const ModalButton = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
`;

const FileIcon = styled.div`
  position: absolute;
  cursor: pointer;
  width: 18px;
  height: 18px;
  .fillColor {
    fill: var(--text-tertiary);
  }
`;

export {
  Group,
  Container,
  Fileblock,
  Icon,
  Filecontent,
  Title,
  Text,
  Moreicon,
  Image,
  DropboxModal,
  ModalBlock,
  ModalHeader,
  ModalTitle,
  ModalContent,
  ModalForm,
  ModalButton,
  IconBlock,
  FileIcon
};
