import { REACT_APP_CONTENTFUL_PREVIEW_ACCESS_TOKEN, REACT_APP_CONTENTFUL_SPACE_ID } from '../global/environment';

export async function fetchGraphQL(query: string, preview = false, type = ['other']) {
  return fetch(`https://graphql.contentful.com/content/v1/spaces/${REACT_APP_CONTENTFUL_SPACE_ID}`, {
    method: 'POST',
    // next: { tags: type },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${REACT_APP_CONTENTFUL_PREVIEW_ACCESS_TOKEN}`
    },
    body: JSON.stringify({ query })
  }).then((response) => response.json());
}
