import * as ContextMenu from '@radix-ui/react-context-menu';
import styled from 'styled-components';
import { Typography } from '../../global/theme';

const Text = styled.div`
  ${Typography.body_xs}
  color: var(--text-primary);
  border: 0.5px solid var(--border-primary);
  background-color: var(--background-primary);
  border-radius: 4px;
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContextMenuSubTrigger = styled(ContextMenu.SubTrigger)`
  padding: 6px 12px;
  display: flex;
  align-items: center;
  gap: 10px;
  ${Typography.body_md}
  color: var(--text-primary);
  :hover {
    background-color: var(--neutral-secondary);
  }
  :focus-visible {
    outline: none;
  }
  .optionListItemblock {
    display: flex;
    align-items: center;
    gap: 10px;
    ${Typography.body_md}
    color: var(--text-primary);
  }
  &.optionListItems {
    justify-content: space-between;
  }
  .group-icon {
    stroke: var(--text-secondary);
    fill: none;
  }
  .status-icon {
    stroke: var(--text-primary);
  }
  &:focus-visible {
    outline: none;
  }
`;

const LabelTriggerDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .optionListItemblock {
    display: flex;
    align-items: center;
    gap: 10px;
    ${Typography.body_md}
    color: var(--text-primary);
  }
  .label-icon {
    path {
      stroke: var(--text-primary);
    }
  }
  :hover {
    background-color: var(--neutral-secondary);
  }
`;
const ItemDiv = styled.div`
  min-width: 168px;
  width: 100%;
`;
export { Text, ContextMenuSubTrigger, LabelTriggerDiv, ItemDiv };
