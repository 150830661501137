import styled, { css } from 'styled-components';
import { Typography } from '../../global/theme';

interface Props {
  isActive?: boolean;
  isWithoutbg?: boolean;
  isDisabled?: boolean;
}

const TabCloseIcon = styled.div`
  display: none;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  right: 3px;
  svg {
    path {
      stroke: var(--text-secondary);
    }
  }
  :hover {
    background-color: var(--icon-hover-background);
    svg {
      stroke: var(--text-primary);
      path {
        stroke: var(--text-primary);
      }
    }
  }
`;
const Tabs = styled.div<Props>`
  -webkit-app-region: no-drag;
  position: relative;
  padding: 3px 20px 3px 12px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  background-color: var(--tabs-background);
  border-radius: 6px;
  :hover {
    background-color: var(--tabs-hover-background);
  }
  :hover ${TabCloseIcon} {
    display: flex;
  }
  ${(props) =>
    props.isActive &&
    css`
      background-color: var(--tabs-hover-background);
    `}

  ${(props) =>
    props.isWithoutbg &&
    css`
      -webkit-app-region: drag;
      cursor: auto;
      background-color: transparent;
      :hover {
        background-color: transparent;
      }
    `}
`;
const Icon = styled.div`
  width: 18px;
  height: 18px;
  .navbar-inbox-icon {
    path {
      stroke: none;
      fill: var(--text-primary);
    }
  }
  .navbar-project-icon {
    path {
      stroke: none;
      fill: var(--text-primary);
    }
  }
  .transparent-fill {
    fill: transparent;
  }
  .task-inprogress-icon,
  .task-status-icon,
  .task-inreview-icon,
  .task-completed-icon,
  .task-cancelled-icon {
    stroke: none;
  }
  .open-icon {
    path {
      fill: none;
    }
  }
  .user-icon {
    stroke: none;
  }
`;
const TabsName = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  p {
    color: var(--text-primary);
    margin: 0;
    ${Typography.body_md_medium};
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    word-break: break-all;
  }
`;

const PlusIcon = styled.div<Props>`
  -webkit-app-region: no-drag;
  max-width: 26px;
  width: 100%;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background-color: var(--tabs-background);
  cursor: pointer;
  :hover {
    background-color: var(--tabs-hover-background);
    svg {
      stroke: var(--text-primary);
      path {
        stroke: var(--text-primary);
      }
    }
  }
  ${(props) =>
    props.isDisabled &&
    css`
      cursor: not-allowed;
      :hover {
        background-color: var(--tabs-background);
        svg {
          path {
            stroke: var(--text-tertiary);
          }
        }
      }
      svg {
        path {
          stroke: var(--text-tertiary);
        }
      }
    `}
`;
const TabsBarSection = styled.div`
  display: none;
  gap: 6px;
  padding: 6px 6px 0px 0px;
  position: relative;
  width: 100%;
  &.report-page {
    margin-left: 260px;
  }
  &.settings-page {
    margin-left: 210px;
  }
`;
const ActionSection = styled.div`
  display: none;
  gap: 4px;
  max-width: 194px;
  width: 100%;
  align-items: center;
  justify-content: end;
  padding-top: 6px;
  padding-right: 16px;
`;
const MacHeader = styled.div`
  display: flex;
  position: relative;
`;
const WindowsHeaderIcon = styled.div`
  -webkit-app-region: no-drag;
  width: 26px;
  height: 26px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    path {
      fill: var(--text-secondary);
    }
  }
  :hover {
    background-color: var(--background-hover);
    border-radius: 4px;
    svg {
      path {
        fill: var(--text-primary);
      }
    }
    .minimize-icon,
    .close-icon {
      path {
        stroke: var(--text-primary);
      }
    }
    .expand-icon {
      path {
        stroke: var(--text-primary);
        fill: none;
      }
    }
  }
  .forward-icon {
    transform: rotate(180deg);
  }
  .close-icon {
    stroke: var(--text-secondary);
  }
  svg {
    path {
      fill: var(--text-secondary);
    }
  }
  .minimize-icon,
  .close-icon {
    path {
      stroke: var(--text-secondary);
    }
  }
  .expand-icon {
    path {
      stroke: var(--text-secondary);
      fill: none;
    }
  }
`;
const ExpandCloseSection = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-left: 24px;
  display: none;
`;
const NavigateArea = styled.div`
  display: none;
  align-items: center;
  gap: 8px;
  width: 100%;
`;
const LogoIcon = styled.div`
  width: 24px;
  height: 24px;
  svg {
    path {
      fill: var(--text-secondary);
    }
  }
`;
export {
  Tabs,
  Icon,
  TabsName,
  TabCloseIcon,
  PlusIcon,
  TabsBarSection,
  ActionSection,
  MacHeader,
  WindowsHeaderIcon,
  ExpandCloseSection,
  LogoIcon,
  NavigateArea
};
