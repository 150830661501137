import { Node } from '@tiptap/core';

export interface FigmaEmbedOptions {
  HTMLAttributes?: Record<string, any>;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    figmaEmbed: {
      setFigmaEmbed: (options: {
        frameNames: string;
        projectName: string;
        filePreviewUrl: string;
        url: string;
        isLoading?: boolean;
        nodeId?: string;
      }) => ReturnType;
    };
  }
}

export const FigmaEmbed = Node.create<FigmaEmbedOptions>({
  name: 'figmaEmbed',

  group: 'block',
  atom: true,
  draggable: true,

  addAttributes() {
    return {
      frameNames: { default: '' },
      projectName: { default: '' },
      filePreviewUrl: { default: '' },
      url: { default: '' },
      isLoading: { default: false },
      nodeId: { default: '' }
    };
  },

  parseHTML() {
    return [
      {
        tag: 'div.figma-main-div',
        getAttrs: (dom: HTMLElement) => ({
          frameNames: dom.querySelector('.figma-description')?.textContent || '',
          projectName: dom.querySelector('.figma-filename')?.textContent || '',
          filePreviewUrl: dom.querySelector('.figma-preview-image')?.getAttribute('src') || '',
          url: dom.querySelector('.figma-link-div')?.getAttribute('href') || '',
          isLoading: dom.classList.contains('figma-loading-state'),
          nodeId: dom.getAttribute('data-node-id') || ''
        })
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const { frameNames, projectName, filePreviewUrl, url, isLoading, nodeId } = HTMLAttributes;

    const loadingIconUrl =
      // eslint-disable-next-line max-len
      'https://firebasestorage.googleapis.com/v0/b/teamcamp-app.appspot.com/o/MediaIcons%2Ffigma_loading.svg?alt=media&token=e441ca3d-1855-464a-8485-aeec7b2ad1db';

    const figmaIconUrl =
      // eslint-disable-next-line max-len
      'https://firebasestorage.googleapis.com/v0/b/teamcamp-app.appspot.com/o/MediaIcons%2FIcons%3DFill%2C%20Type%3DFile%2C%20Icon%20Name%3DFigma.png?alt=media&token=182ec435-8705-4131-9e42-f76e01d8cfea';

    if (isLoading) {
      return [
        'div',
        {
          class: 'figma-main-div figma-loading-state',
          'data-node-id': nodeId
        },
        [
          'div',
          { class: 'figma-header' },
          [
            'div',
            { class: 'figma-desc-div' },
            ['img', { src: figmaIconUrl, alt: 'Figma Icon', class: 'figma-icon' }],
            [
              'div',
              { class: 'figma-text' },
              ['div', { class: 'figma-description' }, frameNames],
              ['div', { class: 'figma-filename' }, projectName]
            ]
          ],
          [
            'div',
            { class: 'figma-right-div' },
            [
              'div',
              { class: 'figma-animation' },
              ['img', { src: loadingIconUrl, alt: 'Loading', class: 'refresh-icon refresh-icon-spin' }]
            ],
            ['a', { href: url, target: '_blank', class: 'figma-link-div' }, 'Open In Figma']
          ]
        ]
      ];
    }

    return [
      'div',
      { class: 'figma-main-div', 'data-node-id': nodeId },
      [
        'div',
        { class: 'figma-header' },
        [
          'div',
          { class: 'figma-desc-div' },
          ['img', { src: figmaIconUrl, alt: 'Figma Icon', class: 'figma-icon' }],
          [
            'div',
            { class: 'figma-text' },
            ['div', { class: 'figma-description' }, frameNames],
            ['div', { class: 'figma-filename' }, projectName]
          ]
        ],
        [
          'div',
          { class: 'figma-right-div' },
          [
            'div',
            { class: 'figma-animation', id: 'figma-refresh' },
            ['img', { src: loadingIconUrl, alt: 'Loading', class: 'refresh-icon', id: 'figma-refresh-ref' }]
          ],
          ['a', { href: url, target: '_blank', class: 'figma-link-div' }, 'Open In Figma']
        ]
      ],
      [
        'div',
        { class: 'inbox-image' },
        ['img', { src: filePreviewUrl, alt: 'Figma Preview', class: 'figma-preview-image' }]
      ]
    ];
  },

  addCommands() {
    return {
      setFigmaEmbed:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options
          });
        }
    };
  }
});
