/* eslint-disable max-len */
import { Node, mergeAttributes } from '@tiptap/core';

export interface SnapEmbedOptions {
  HTMLAttributes?: Record<string, any>;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    snapEmbed: {
      setSnapEmbed: (options: {
        url: string;
        id: string | undefined;
        recordingName: string;
        themeMode?: string;
        nodeId?: string;
      }) => ReturnType;
    };
  }
}

export const SnapEmbed = Node.create<SnapEmbedOptions>({
  name: 'snapEmbed',

  group: 'block',
  atom: true,
  draggable: true,

  addAttributes() {
    return {
      url: { default: null },
      id: { default: '' },
      recordingName: { default: '' },
      themeMode: { default: 'light' },
      nodeId: { default: '' }
    };
  },

  parseHTML() {
    return [
      {
        tag: 'div.screen-recording-custom-main-div',
        getAttrs: (dom: HTMLElement) => ({
          url: dom.getAttribute('url'),
          id: dom.getAttribute('id'),
          recordingName: dom.getAttribute('name'),
          themeMode: dom.getAttribute('data-theme-mode')
        })
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const { themeMode, url, recordingName, id } = HTMLAttributes;

    const playIconUrl =
      'https://firebasestorage.googleapis.com/v0/b/teamcamp-app.appspot.com/o/MediaIcons%2Fvideoplay.png?alt=media&token=ef342c7b-16fc-4a2c-b245-8e9eb87ff785';

    const fullscreenIconUrl =
      themeMode === 'dark'
        ? 'https://firebasestorage.googleapis.com/v0/b/teamcamp-app.appspot.com/o/MediaIcons%2FIcon_dark_mode_full_screen.svg?alt=media&token=063f8f8a-8ae7-4874-8a46-a08ca596a8ac'
        : 'https://firebasestorage.googleapis.com/v0/b/teamcamp-app.appspot.com/o/MediaIcons%2FIcon_light_mode_full_screen.svg?alt=media&token=bcad4f9e-dcd0-4444-bc79-521026f18d80';

    const moreIconUrl =
      themeMode === 'dark'
        ? 'https://firebasestorage.googleapis.com/v0/b/teamcamp-app.appspot.com/o/MediaIcons%2FIcon_more.svg?alt=media&token=4bfff6bb-8d93-496e-b97c-d4b6d9eb0aa2'
        : 'https://firebasestorage.googleapis.com/v0/b/teamcamp-app.appspot.com/o/MediaIcons%2Fmore_updated_light.svg?alt=media&token=2d54af7d-8675-4621-ba69-e0a1247f5114';

    return [
      'div',
      mergeAttributes(HTMLAttributes, {
        class: 'screen-recording-custom-main-div',
        url,
        name: recordingName,
        id
      }),
      [
        'div',
        { class: 'screen-recording-header' },
        [
          'div',
          { class: 'screen-recording-desc-div' },
          ['div', { class: 'screen-recording-text' }, ['div', { class: 'screen-recording-name' }, recordingName]]
        ],
        [
          'div',
          { class: 'screen-recording-right-div' },
          [
            'div',
            { class: 'screen-recording-animation' },
            ['img', { src: fullscreenIconUrl, class: 'open-video-icon', alt: 'Open video' }]
          ],
          [
            'div',
            { class: 'screen-recording-more' },
            ['img', { src: moreIconUrl, class: 'video-more-icon', alt: 'More options' }]
          ]
        ]
      ],
      [
        'div',
        { class: 'inbox-image' },
        ['video', { class: 'inbox-video' }, ['source', { src: url, type: 'video/mp4' }]],
        ['img', { src: playIconUrl, class: 'video-icon', width: 30, height: 30 }]
      ]
    ];
  },

  addCommands() {
    return {
      setSnapEmbed:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options
          });
        }
    };
  }
});
