/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo, useRef } from 'react';
import {
  Clientdetail,
  Firstline,
  Icon,
  Rightdiv,
  Row,
  Tab,
  Title,
  Rightcontent,
  Bottmcontent,
  Row1,
  Empty,
  Link,
  Lefttitle,
  Activebtn,
  EmailTag,
  TextDiv
} from './styles';
import { useSelector, useDispatch } from 'react-redux';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import { isEmpty } from '../../../helpers/common';
import EmptyState from '../../../component/emptyState';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { CUSTOMER_MEMBER_ROLE, CustomerDetailMoreMenu, MENU_OPTIONS } from '../../../global/constants';
import customerdarkempty from '../../../assets/images/emptystates/customerdarkempty.svg';
import customerempty from '../../../assets/images/emptystates/customer-empty.svg';
import { setCustomerDetail, setMemberInput } from '../../../actions/customerActions';
import { MemberInputInterface } from '../../../interfaces/CustomerInterface';
import { Dropdown, DropdownItem } from '../../../component/Dropdown';
import Tooltip from '../../../component/Tooltip';

interface Props {
  openDeleteModal: () => void;
  setIsModalOpen: () => void;
  openEditModal: () => void;
  setSelectedItem: (item: MemberInputInterface) => void;
}

const CustomerdetailRight: React.FC<Props> = (props) => {
  const { openDeleteModal, setIsModalOpen, openEditModal, setSelectedItem } = props;
  //Refs
  const buttonRef = useRef<React.RefObject<HTMLDivElement>[]>([]);
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { settings: settingsSelector, customer: customerSelector, workspace: workspaceSelector } = stateSelector || {};
  const { themeMode } = settingsSelector;
  const { individualCustomer, customerMembers } = customerSelector;
  const { workspace } = workspaceSelector;
  //Other variable
  const getImageSourceMember = () => (themeMode?.theme === 'dark' ? customerdarkempty : customerempty);
  //empty state gap
  const gap = window.innerWidth <= 449 ? 16 : 12;
  const dispatch = useDispatch();
  const config = {
    token: {
      colorBgSpotlight: 'var(--background-tertiary)',
      colorTextLightSolid: 'var(--text-primary)',
      borderRadius: 8,
      fontSize: 12,
      controlHeight: 27,
      lineHeight: 1.25
    }
  };

  // get current selected role icon
  const getCurrentSelectedRoleIcon = useCallback(
    (role: number) => {
      switch (role) {
        case 1:
          return (
            <>
              <Tooltip title='No access' placement='bottomLeft' maxWidth={'max-content'}>
                <span>
                  <SVGIcon name='no-icon' width='16' height='16' viewBox='0 0 16 16' className='no-access-icon' />
                </span>
              </Tooltip>
            </>
          );
        case 2:
          return (
            <>
              <Tooltip title='Project only' placement='bottomLeft' maxWidth={'max-content'}>
                <span>
                  <SVGIcon name='project-only-icon' width='16' height='16' viewBox='0 0 16 16' className='icon' />
                </span>
              </Tooltip>
            </>
          );
        case 3:
          return (
            <>
              <Tooltip title='Full access' placement='bottomLeft' maxWidth={'max-content'}>
                <span>
                  <SVGIcon name='access-icon' width='16' height='16' viewBox='0 0 16 16' className='icon' />
                </span>
              </Tooltip>
            </>
          );
        default:
          return <></>;
      }
    },
    [config, themeMode?.theme]
  );

  // for opening delete model
  const OpenDeleteModel = () => {
    openDeleteModal();
  };

  // Toggles modal visibility.
  const handleOnClick = () => {
    setIsModalOpen();
  };

  // handel member click
  const handleMemberClick = useCallback((key: number | string, item: MemberInputInterface) => {
    key = Number(key);
    if (key === MENU_OPTIONS.EDIT) {
      onClickMemberEdit(item);
    } else if (key === MENU_OPTIONS.DELETE) {
      onClickMemberDelete(item);
    }
  }, []);

  // open edit modal
  const onOpenEditModal = useCallback(() => {
    dispatch(setCustomerDetail(individualCustomer));
    openEditModal();
  }, [individualCustomer, dispatch]);

  // for edit member
  const onClickMemberEdit = useCallback(
    (member: MemberInputInterface) => {
      dispatch(setMemberInput(member));
      handleOnClick();
    },
    [dispatch]
  );

  // for delete member
  const onClickMemberDelete = useCallback((item: MemberInputInterface) => {
    setSelectedItem(item);
    OpenDeleteModel();
  }, []);

  const renderMenuItems = useCallback((customerMemberData: MemberInputInterface) => {
    return CustomerDetailMoreMenu.map((item) => (
      <DropdownItem
        key={item.key}
        label={item.label}
        onClick={() => handleMemberClick(item.key, customerMemberData)}
        tone={item.label === 'Delete' ? 'critical' : 'primary'}
      />
    ));
  }, []);

  // render client details
  const renderClientDetails = useMemo(() => {
    if (customerMembers?.length > 0) {
      return customerMembers.map((item, index) => {
        let pendingUser;
        if (item?.role !== CUSTOMER_MEMBER_ROLE.NO_ACCESS) {
          pendingUser = workspace?.invitedUsers?.find((x) => x?.id === item?.id);
        }

        // Ensure the buttonRef for this index is created
        if (!buttonRef?.current[index]) {
          buttonRef.current[index] = React.createRef<HTMLDivElement>();
        }

        return (
          <Row key={item?.id}>
            <Firstline>
              <Lefttitle>
                <p>{item?.name}</p>
                {getCurrentSelectedRoleIcon(item?.role)}
              </Lefttitle>
              <Tab>
                {pendingUser && (
                  <Tooltip
                    title='A member has been invited and is pending to join!'
                    placement='bottom'
                    maxWidth={'250px'}>
                    <Activebtn>Invited</Activebtn>
                  </Tooltip>
                )}
                <Dropdown
                  activeClassName='active'
                  content={
                    <Icon>
                      <SVGIcon
                        name='more-icon'
                        width='16'
                        height='16'
                        viewBox='0 0 18 18'
                        fill='none'
                        className='more-icon'
                      />
                    </Icon>
                  }
                  trigger='click'>
                  {renderMenuItems(item)}
                </Dropdown>
              </Tab>
            </Firstline>

            <p>{item?.email}</p>
          </Row>
        );
      });
    }

    return (
      <Empty ismemberempty={true}>
        <EmptyState
          image={getImageSourceMember()}
          header='No Members added yet'
          name='Add Member'
          isgap={gap}
          isOverviewEmpty={true}
          onButtonClick={handleOnClick}
          isCustomerMemberEmpty={true}
          hasNotPlusIcon
        />
      </Empty>
    );
  }, [customerMembers, workspace?.invitedUsers, handleMemberClick, handleOnClick]);

  return (
    <>
      <Rightdiv>
        <Rightcontent>
          <Title>
            <p>Member</p>
            {customerMembers?.length > 0 && <Link onClick={handleOnClick}>+ Add</Link>}
          </Title>
          <Clientdetail>{renderClientDetails}</Clientdetail>
        </Rightcontent>
        <Rightcontent>
          <Title>
            <p>Additional Info</p>
            <Link onClick={onOpenEditModal}>Edit</Link>
          </Title>
          <Bottmcontent>
            <Row1>
              <Firstline>
                <h5>Company Title</h5>
              </Firstline>
              <TextDiv>{individualCustomer?.companyName}</TextDiv>
            </Row1>
            {!isEmpty(individualCustomer?.phone) && (
              <Row1>
                <Firstline>
                  <h5>Phone Number</h5>
                </Firstline>
                {!isEmpty(individualCustomer?.phone) ? <TextDiv>{individualCustomer?.phone}</TextDiv> : <></>}
              </Row1>
            )}
            {!isEmpty(individualCustomer?.email) && (
              <Row1>
                <Firstline>
                  <h5>Email</h5>
                </Firstline>
                <EmailTag>{individualCustomer?.email}</EmailTag>
              </Row1>
            )}
          </Bottmcontent>
        </Rightcontent>
      </Rightdiv>
    </>
  );
};

export default CustomerdetailRight;
