import React from 'react';
import {
  Avtar,
  Bottom,
  Bottomdiv,
  Box,
  Buttondiv,
  Detail,
  Firstblock,
  Leftdiv,
  Leftside,
  Main,
  Profile,
  Responsivebottomdiv,
  Rightdiv,
  Rightside,
  Square,
  Subblock,
  Subbox,
  Subdiv,
  Top
} from './styles';

export default function Inboxloading() {
  return (
    <>
      <Main>
        <Firstblock>
          <Avtar>
            <Profile className='loading-animation' />
          </Avtar>
          <Rightside>
            <Subdiv>
              <Top className='loading-animation' />
              <Top className='bottom loading-animation' />
            </Subdiv>
            <Bottom className='loading-animation' />
          </Rightside>
        </Firstblock>
        <Firstblock>
          <Avtar>
            <Profile className='loading-animation' />
          </Avtar>
          <Rightside className='right-side'>
            <Subdiv className='right-side'>
              <Top className='loading-animation' />
              <Top className='bottom loading-animation' />
            </Subdiv>
            <Responsivebottomdiv className='loading-animation' />
            <Leftside className='loading-animation' />
          </Rightside>
        </Firstblock>
        <Firstblock>
          <Avtar>
            <Profile className='loading-animation' />
          </Avtar>
          <Rightside className='right-side'>
            <Subbox>
              <Subdiv className='right-side third-block'>
                <Top className='loading-animation' />
                <Top className='bottom loading-animation' />
              </Subdiv>
              <Subblock>
                <Top className='bottomline loading-animation' />
                <Bottomdiv>
                  <Leftdiv>
                    <Square className='loading-animation' />
                  </Leftdiv>
                  <Rightdiv>
                    <Detail>
                      <Avtar className='avtar'>
                        <Profile className='avtar loading-animation' />
                      </Avtar>
                      <Top className='right loading-animation' />
                    </Detail>
                    <Buttondiv>
                      <Bottom className='first loading-animation' />
                      <Bottom className='second loading-animation' />
                    </Buttondiv>
                  </Rightdiv>
                </Bottomdiv>
              </Subblock>
            </Subbox>
            <Responsivebottomdiv className='loading-animation' />
            <Leftside className='loading-animation' />
          </Rightside>
        </Firstblock>
        <Firstblock className='fourth-block '>
          <Avtar>
            <Profile className='loading-animation' />
          </Avtar>
          <Rightside className='right-side'>
            <Subdiv className='right-side'>
              <Top className='loading-animation' />
              <Top className='bottom loading-animation' />
            </Subdiv>
            <Leftside className='loading-animation' />
          </Rightside>
        </Firstblock>
        <Firstblock className='fifth-block'>
          <Avtar>
            <Profile className='loading-animation' />
          </Avtar>
          <Rightside className='right-side'>
            <Subdiv>
              <Top className='loading-animation' />
              <Box>
                <Top className='top loading-animation' />
                <Top className='top-bottom loading-animation' />
              </Box>
            </Subdiv>
            <Leftside className='loading-animation' />
          </Rightside>
        </Firstblock>
      </Main>
    </>
  );
}
