/* eslint-disable max-len */
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { ButtonMainDiv, Title } from './style';
interface DropdownButtonProps {
  iconName?: string;
  iconSize?: number;
  title?: string;
  isBorderRadius?: boolean;
  isActive?: boolean;
  variant?: 'no-border' | 'border' | 'hover-border';
  iconTone?: 'fill' | 'stroke';
  size?: 'small' | 'medium';
}
const DropdownButton = ({
  title,
  iconName,
  iconSize = 16,
  isBorderRadius = false,
  isActive = false,
  variant = 'border',
  iconTone = 'fill',
  size = 'medium'
}: DropdownButtonProps) => {
  return (
    <ButtonMainDiv isBorderRadius={isBorderRadius} variant={variant} iconTone={iconTone} size={size}>
      {iconName && (
        <SVGIcon name={iconName} width={`${iconSize}`} height={`${iconSize}`} viewBox={`0 0 ${iconSize} ${iconSize}`} />
      )}
      {title && <Title isActive={isActive}>{title}</Title>}
    </ButtonMainDiv>
  );
};

export default DropdownButton;
