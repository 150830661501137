import { makeAutoObservable } from 'mobx';
import { createObservableNotification, NotificationModel } from '../models/notification';
import { RawNotification } from '../interfaces/notification';
import { INBOX_FILTER_TYPE, NOTIFICATION_TYPE } from '../../global/constants';

export class NotificationStore {
  notifications: NotificationModel[] = [];
  loading: boolean = true;
  filterType: number = INBOX_FILTER_TYPE.ALL;

  constructor() {
    makeAutoObservable(this);
  }

  get filteredNotifications() {
    const currentTime = new Date().getTime();
    const notificationList = this.notifications.filter((item) => item.date.getTime() < currentTime);
    if (this.filterType === INBOX_FILTER_TYPE.ASSIGN_TO_ME) {
      return notificationList.filter((notification) => notification.type === NOTIFICATION_TYPE.TASK);
    } else if (this.filterType === INBOX_FILTER_TYPE.MENTION) {
      return notificationList.filter((notification) => notification.type === NOTIFICATION_TYPE.COMMENT);
    }
    return notificationList;
  }

  setNotifications(rawNotifications: RawNotification[]) {
    this.notifications = rawNotifications.map(createObservableNotification);
    this.loading = false;
  }

  upsertNotifications(rawNotifications: RawNotification[]) {
    const newNotifications = [];
    const updatedNotifications = [];

    for (const rawNotification of rawNotifications) {
      const notificationIndex = this.notifications.findIndex((notification) => notification.id === rawNotification.id);
      if (notificationIndex !== -1) {
        const updatedNotification = createObservableNotification(rawNotification);
        this.notifications[notificationIndex] = updatedNotification;
        updatedNotifications.push(updatedNotification);
      } else {
        const newNotification = createObservableNotification(rawNotification);
        this.notifications.unshift(newNotification);
        newNotifications.push(newNotification);
      }
    }

    return { newNotifications, updatedNotifications };
  }

  deleteNotification(id: string) {
    this.notifications = this.notifications.filter((notification) => notification.id !== id);
  }

  setFilterType(filter: number) {
    this.filterType = filter;
  }
}
