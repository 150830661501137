import React, { useCallback, useRef, useState } from 'react';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { Rightrow1, Statusrow, Status, Icon2, Statusrow1, Circleicon, Labelgroup } from './styles';
import { LABEL_COLOUR_HASH, MENU_OPTIONS } from '../../../global/constants';
import { LabelDetailsInterface, ProjectDetailInterface } from '../../../interfaces/ProjectInterface';
import { ProjectTemplateDetailsInterface } from '../../../interfaces/SettingsInterface';
import { Autotext, Box, Leftrow, Row, Subtitle, Title } from '../style';
import Button from '../../button';
import CustomDropdown from '../../dropdowns/customDropdown';

interface Props {
  currentProject: ProjectDetailInterface | ProjectTemplateDetailsInterface;
  onUpdateLabels: (value: boolean) => void;
  openDeleteLabelModel: (id: string) => void;
  onClickEditLabel: (item: LabelDetailsInterface) => void;
  handleclick: () => void;
  loadingButton: boolean;
}

const LabelsSettingCard: React.FC<Props> = (props) => {
  const { currentProject, onUpdateLabels, openDeleteLabelModel, onClickEditLabel, handleclick, loadingButton } = props;
  //Refs
  const dropdownRef = useRef<React.RefObject<HTMLDivElement>[]>([]);
  //States
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [openDropdownId, setOpenDropdownId] = useState<string | null>(null);
  //Other variables
  const menuItems = [
    {
      key: MENU_OPTIONS.EDIT,
      label: 'Edit'
    },
    {
      key: MENU_OPTIONS.DELETE,
      label: 'Delete',
      className: 'red-text'
    }
  ];

  const toggleDropdown = useCallback(
    (e: React.SyntheticEvent, item: LabelDetailsInterface) => {
      e.stopPropagation();
      if (openDropdownId === item?.id) {
        setIsDropdownOpen(false);
        setOpenDropdownId(null);
      } else {
        setIsDropdownOpen(true);
        setOpenDropdownId(item?.id);
      }
    },
    [openDropdownId]
  );

  const handleDropdownClick = useCallback(
    (key: string | number, item: LabelDetailsInterface) => {
      key = Number(key);
      if (key === MENU_OPTIONS.EDIT) {
        onClickEditLabel(item);
      } else if (key === MENU_OPTIONS.DELETE) {
        openDeleteLabelModel(item?.id);
      }
    },
    [onClickEditLabel, openDeleteLabelModel]
  );
  return (
    <Box>
      <Row>
        <Leftrow>
          <Title>Labels</Title>
          <Subtitle>Enhance task management by creating and applying labels to categorise tasks.</Subtitle>
        </Leftrow>
        <Autotext>
          {loadingButton ? (
            <Button isLoading={loadingButton} />
          ) : (
            <label className='switch'>
              <input
                type='checkbox'
                checked={currentProject?.labelsEnabled || false}
                onChange={() => onUpdateLabels(!currentProject?.labelsEnabled)}
              />
              <span className='slider round'></span>
            </label>
          )}
        </Autotext>
      </Row>
      {currentProject?.labelsEnabled && (
        <Status>
          {currentProject?.labelsList?.length > 0 &&
            currentProject?.labelsList?.map((item, index) => {
              if (!dropdownRef?.current[index]) {
                dropdownRef.current[index] = React.createRef<HTMLDivElement>();
              }
              return (
                <React.Fragment key={item?.id}>
                  <Statusrow>
                    <Rightrow1>
                      <Circleicon color={`${LABEL_COLOUR_HASH[item?.color]}`} />
                      <Title>{item?.name}</Title>
                    </Rightrow1>
                    <Rightrow1>
                      <Icon2 ref={dropdownRef?.current[index]} onClick={(e) => toggleDropdown(e, item)}>
                        <SVGIcon
                          name='loading-more-icon'
                          width='18'
                          height='18'
                          viewBox='0 0 18 18'
                          className='moreicon'
                        />
                        {isDropdownOpen && openDropdownId === item?.id && (
                          <CustomDropdown
                            menuItems={menuItems}
                            width={120}
                            isOpen={isDropdownOpen}
                            setIsOpen={setIsDropdownOpen}
                            buttonref={dropdownRef?.current[index]}
                            handleClick={(key) => handleDropdownClick(key, item)}
                            isCalendarviewCard={true}
                            setOpenDropdownId={setOpenDropdownId}
                          />
                        )}
                      </Icon2>
                    </Rightrow1>
                  </Statusrow>
                </React.Fragment>
              );
            })}
          <Statusrow1 onClick={handleclick}>
            <Labelgroup>
              <SVGIcon name='plus-icon' width='16' height='16' viewBox='0 0 18 18' className='plus-icon' />
              <p>Create New Label</p>
            </Labelgroup>
          </Statusrow1>
        </Status>
      )}
    </Box>
  );
};

export default LabelsSettingCard;
