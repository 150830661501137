import styled from 'styled-components';
import { Typography } from '../../global/theme';

const MainDiv = styled.div`
  max-width: 598px;
  width: 100%;
  border-radius: 12px;
  box-shadow: var(--shadow-modal);
`;

const Header = styled.div`
  position: relative;
  padding: 16px 20px;
  border-bottom: 0.5px solid var(--border-primary);
  .close-icon {
    cursor: pointer;
    path {
      stroke: var(--text-primary);
    }
  }
`;
const Inputarea = styled.input`
  max-width: 520px;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  ${Typography.body_lg}
  color: var(--text-primary);
  background-color: var(--background-primary);
  margin: 0;
  padding: 0;
  border: none;

  :focus {
    outline: none;
  }
  .placeholder {
    color: var(--text-secondary);
  }
`;

const SVGDiv = styled.div`
  position: absolute;
  top: 15px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  :hover {
    cursor: pointer;
    background-color: var(--neutral-secondary);
  }
`;

const BottomDiv = styled.div`
  padding: 12px 12px 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Heading = styled.p`
  margin: 0;
  padding: 0 8px 0;
  ${Typography.body_sm_medium}
  color: var(--text-secondary);
`;

const List = styled.li`
  list-style-type: none;
  max-height: 256px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  &::-webkit-scrollbar {
    background-color: var(--background-primary) !important;
    width: 6px !important;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--text-tertiary) !important;
    width: 6px !important;
    margin-bottom: 4px !important;
    border-radius: 7px !important;
    min-height: 20px !important;
  }
`;

const Task = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  :hover {
    cursor: pointer;
    background-color: var(--neutral-secondary);
  }
`;

const Status = styled.span``;

const TaskHeading = styled.p`
  margin: 0;
  ${Typography.body_md}
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
  color: var(--text-secondary);
`;

export { MainDiv, Header, Inputarea, SVGDiv, BottomDiv, Heading, Task, Status, TaskHeading, List };
