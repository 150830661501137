/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useState } from 'react';
import { Bottom, Main, Showhide } from './styles';
import Cardheader from '../calendarviewCard/cardheader';
import Calendarviewcard from '../calendarviewCard';
import { TaskDetailsInterface } from '../../interfaces/TaskInterface';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import moment from 'moment';
import MyCustomContextMenu from '../customContextMenu';
import { useSelector, useDispatch } from 'react-redux';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';
import { useHistory } from 'react-router-dom';
import { setMyTaskDetailsData, updatSubTaskList } from '../../actions/taskActions';
import { trackAnalyticActivity } from '../../services/analyticsService';
import { StatusListInterface } from '../../interfaces/ProjectInterface';
import { createNewComment, updateTaskDetails } from '../../services/taskServices';
import { COMMENT_TYPE, STATUS_TYPE } from '../../global/constants';
import ModalCustom from '../models/modal';
import ConfirmationModal from '../models/confirmationModal';
import { TASK_ANALYTICS } from '../../global/analyticsConstants';

interface Props {
  dayname: string;
  date: number;
  tasks: TaskDetailsInterface[];
  completedTasks: TaskDetailsInterface[];
  loadData: () => void;
  datestring?: string;
  onClickEdit: (item: TaskDetailsInterface) => void;
  onClickClone: (item: TaskDetailsInterface) => void;
  OpenModal: (item: TaskDetailsInterface) => void;
}
interface DraggableItemProps {
  item: any;
  index: number;
  loadData: () => void;
  currentProject: any;
  setCurrentActiveTask: (taskId: string) => void;
  currentActiveTask: string;
  onClickTaskDetails: (item: any) => void;
  onClickClone: (item: any) => void;
  onClickEdit: (item: any) => void;
  OpenModal: (item: any) => void;
  onUpdateStatus: (status: StatusListInterface, previousStatus: StatusListInterface, item: any) => void;
}
const DraggableItem = ({
  item,
  OpenModal,
  currentActiveTask,
  currentProject,
  index,
  loadData,
  onClickClone,
  onClickEdit,
  onClickTaskDetails,
  onUpdateStatus,
  setCurrentActiveTask
}: DraggableItemProps) => {
  return (
    <Draggable key={item?.id} draggableId={item?.id} index={index}>
      {(draggableProvided: any) => (
        <>
          <MyCustomContextMenu
            targetId={item?.id}
            taskData={item}
            loadData={loadData}
            currentProject={currentProject}
            setCurrentActiveTask={(taskId: string) => setCurrentActiveTask(taskId)}
            currentActiveTask={currentActiveTask}
            onClickView={() => onClickTaskDetails(item)}
            onClickClone={() => onClickClone(item)}
            onClickEdit={() => onClickEdit(item)}
            statusList={currentProject?.statusData || []}
            onClickDelete={() => OpenModal(item)}
            onUpdateStatus={(status: StatusListInterface, previousStatus: StatusListInterface) =>
              onUpdateStatus(status, previousStatus, item)
            }>
            <div
              id={item?.id}
              ref={draggableProvided.innerRef}
              {...draggableProvided.draggableProps}
              {...draggableProvided.dragHandleProps}>
              <Calendarviewcard loadData={loadData} taskDetails={item} />
            </div>
          </MyCustomContextMenu>
        </>
      )}
    </Draggable>
  );
};

export default function Weekviewcard(Props: Props) {
  const { onClickEdit, onClickClone, OpenModal, dayname, date, tasks, loadData, completedTasks, datestring } = Props;
  //State
  const [currentActiveTask, setCurrentActiveTask] = useState<string>('');
  const [status, setStatus] = useState<StatusListInterface>();
  const [previousStatus, setPreviousStatus] = useState<StatusListInterface>();
  const [currentTask, setCurrentTask] = useState<TaskDetailsInterface>();
  const [isConfirmationModelOpen, setIsConfirmationModelOpen] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [showCompletedTask, setShowCompletedTask] = useState(false);
  //use selector state variables
  const projectSelector = useSelector((state: RootReducerInterface) => state.project);
  const { currentProject } = projectSelector;
  //Other variable
  const history = useHistory();
  const dispatch = useDispatch();

  const onClickTaskDetails = useCallback(
    (task: TaskDetailsInterface) => {
      const eventProperties = {
        source: 'task list',
        title: task?.name || ''
      };
      trackAnalyticActivity(TASK_ANALYTICS.VIEW, eventProperties);
      dispatch(setMyTaskDetailsData(task));
      history.push(`/projects/details/${task?.projectId}/tasks?&task=${task?.id}`);
    },
    [dispatch, history]
  );

  const handleOpenConfirmationModal = (isCheckBox: boolean) => {
    setIsConfirmationModelOpen(true);
  };

  const closeConfirmationModal = () => {
    setIsConfirmationModelOpen(false);
  };

  const onChangeStatus = useCallback(
    async (item: StatusListInterface, task: TaskDetailsInterface, previousStatus: StatusListInterface) => {
      try {
        const payloadTask = { statusId: item['_id'], projectId: task?.projectId };
        const result = await dispatch(updateTaskDetails(task?.id, payloadTask));
        if (result) {
          await dispatch(
            createNewComment(task?.id, {
              Type: COMMENT_TYPE.STATUS_ID_CHANGE,
              Group: item?.Name,
              PreviousGroup: previousStatus?.Name || '',
              Priority: item?.Type
            })
          );
          trackAnalyticActivity(TASK_ANALYTICS.STATUS_CHANGED);
          loadData();
        }
      } catch (error) {
        console.log('error', error);
      }
    },
    [dispatch, loadData]
  );

  const onUpdateStatus = useCallback(
    async (item: StatusListInterface, previousStatus: StatusListInterface, task: TaskDetailsInterface) => {
      if (previousStatus['_id'] !== item['_id']) {
        const currentStatus =
          currentProject?.statusData?.find((x) => x['_id'] === task?.statusId) ||
          currentProject?.defaultStatus ||
          currentProject?.statusData?.[0];
        let allSubTasksStatus = true;
        if (Array.isArray(task?.subTasks) && task?.subTasks?.length > 0) {
          for (const subTask of task.subTasks) {
            if (subTask?.status === false) {
              allSubTasksStatus = false;
              break;
            }
          }
        }
        if (item?.Type === STATUS_TYPE.COMPLETED && !allSubTasksStatus) {
          handleOpenConfirmationModal(false);
          setStatus(item);
          setPreviousStatus(currentStatus);
          setCurrentTask(task);
          return;
        }

        onChangeStatus(item, task, currentStatus);
      }
    },
    [currentProject?.statusData, currentProject?.defaultStatus, onChangeStatus]
  );

  const onClickConfirmStatus = useCallback(async () => {
    setConfirmLoading(true);
    try {
      const payloadTask = { statusId: status?.['_id'], projectId: currentTask?.projectId };
      if (
        Array.isArray(currentTask?.subTasks) &&
        currentTask?.subTasks?.length > 0 &&
        status?.Type === STATUS_TYPE?.COMPLETED
      ) {
        for (const subTask of currentTask.subTasks) {
          if (subTask?.status === false) {
            const result = await dispatch(updateTaskDetails(subTask?.id, payloadTask));
            if (result) {
              await dispatch(
                createNewComment(subTask?.id, {
                  Type: COMMENT_TYPE.STATUS_ID_CHANGE,
                  Group: status?.Name,
                  PreviousGroup: previousStatus?.Name,
                  Priority: status?.Type
                })
              );
              const taskDetailsClone = JSON.parse(JSON.stringify(subTask));
              taskDetailsClone.statusId = status['_id'];
              dispatch(updatSubTaskList(taskDetailsClone));
              trackAnalyticActivity(TASK_ANALYTICS.STATUS_CHANGED);
            }
          }
        }
      }
      if (status && currentTask && previousStatus) {
        onChangeStatus(status, currentTask, previousStatus);
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsConfirmationModelOpen(false);
      setConfirmLoading(false);
    }
  }, [currentTask, dispatch, onChangeStatus, previousStatus, status]);

  const onClickNo = useCallback(() => {
    setCancelLoading(true);
    try {
      if (status && currentTask && previousStatus) {
        onChangeStatus(status, currentTask, previousStatus);
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setCancelLoading(false);
    }
  }, [currentTask, onChangeStatus, previousStatus, status]);

  return (
    <>
      <Droppable droppableId={datestring}>
        {(provided: any) => (
          <Main
            isCompletedTasksOpen={showCompletedTask}
            isWeekend={
              (dayname === 'Saturday' || dayname === 'Sunday') && tasks?.length === 0 && completedTasks?.length === 0
            }
            isActive={datestring === moment().startOf('day').toDate().toString()}
            ref={provided.innerRef}
            {...provided.droppableProps}>
            <Cardheader
              dayname={
                (dayname === 'Saturday' || dayname === 'Sunday') && tasks?.length === 0 && completedTasks?.length === 0
                  ? dayname.slice(0, 3)
                  : dayname
              }
              date={date}
            />
            <Bottom>
              {tasks?.map((item, index) => (
                <DraggableItem
                  key={item?.id}
                  item={item}
                  index={index}
                  loadData={loadData}
                  currentProject={currentProject}
                  setCurrentActiveTask={setCurrentActiveTask}
                  currentActiveTask={currentActiveTask}
                  onClickTaskDetails={onClickTaskDetails}
                  onClickClone={onClickClone}
                  onClickEdit={() => onClickEdit(item)}
                  OpenModal={OpenModal}
                  onUpdateStatus={onUpdateStatus}
                />
              ))}
              {completedTasks?.length > 0 && (
                <Showhide onClick={() => setShowCompletedTask(!showCompletedTask)} className='hide-show-task-text'>
                  {showCompletedTask ? 'Hide' : 'Show'} Completed Tasks
                </Showhide>
              )}
              {showCompletedTask &&
                completedTasks?.map((item, index) => (
                  <DraggableItem
                    key={item?.id}
                    item={item}
                    index={index}
                    loadData={loadData}
                    currentProject={currentProject}
                    setCurrentActiveTask={setCurrentActiveTask}
                    currentActiveTask={currentActiveTask}
                    onClickTaskDetails={onClickTaskDetails}
                    onClickClone={onClickClone}
                    onClickEdit={() => item}
                    OpenModal={OpenModal}
                    onUpdateStatus={onUpdateStatus}
                  />
                ))}
            </Bottom>
            {provided.placeholder}
          </Main>
        )}
      </Droppable>
      <ModalCustom open={isConfirmationModelOpen} onClose={closeConfirmationModal} width={334}>
        <ConfirmationModal
          loading={confirmLoading}
          cancelLoading={cancelLoading}
          onOk={onClickConfirmStatus}
          onNo={onClickNo}
          onClose={closeConfirmationModal}
          modaltitle='Uncompleted Subtasks'
          description='Do you want to complete all these subtasks?'
        />
      </ModalCustom>
    </>
  );
}
