import React from 'react';
import AppLayout from '../../../component/appLayout';

const Project_timeline: React.FC = () => {
  return (
    <>
      <AppLayout>
        <div>This is project_timeline content</div>
      </AppLayout>
    </>
  );
};

export default Project_timeline;
