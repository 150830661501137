import axios from 'axios';
import jwt_decode from 'jwt-decode';
import UserPreferenceSingleton from './userPreferenceSingleton';
import { captureException } from '../services/logService';

export const setupToken = () => {
  const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
  const { access_token } = userDetails || {};

  if (access_token) {
    const decoded: any = jwt_decode(access_token);
    if (!decoded.deviceId) return false;
    const currentTime = Date.now() / 1000;
    if (decoded.exp > currentTime) {
      setAuthToken(access_token);
      return access_token;
    }
  }
  return false; // if no token or expired token, return false
};

export const setupFirebaseToken = () => {
  const tokenDetails = UserPreferenceSingleton.getInstance().getFirebaseToken();
  const { token: access_token } = tokenDetails || {};

  if (access_token) {
    const decoded: any = jwt_decode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp > currentTime) {
      return access_token;
    }
  }
  return false; // if no token or expired token, return false
};

export const saveToken = (access_token: string) => {
  setAuthToken(access_token);
};

export const clearToken = () => {
  UserPreferenceSingleton.getInstance().clearStoredUserData();
  clearAuthToken();
};

export const setWorksapceIdInAxios = (workspace_id: string) => {
  try {
    axios.defaults.headers.common['workspace_id'] = workspace_id;
  } catch (e) {
    captureException(e);
    console.log('Error while settup workspace_id', e);
  }
};

// header methods
const setAuthToken = (token: any) => {
  try {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  } catch (e) {
    captureException(e);
    console.log('Error while settup token', e);
  }
};

const clearAuthToken = () => {
  delete axios.defaults.headers.common['Authorization'];
  delete axios.defaults.headers.common['workspace_id'];
};
