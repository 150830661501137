import { forwardRef, useEffect, useImperativeHandle, useState, Ref } from 'react';
import Avatar from '../avatar/Avatar';

// Define the interface for props
interface MentionListProps {
  items: any[];
  command: (item: { id: string; label: string; email: string }) => void;
}

// Define the type for the ref's imperative handle
export interface MentionListHandle {
  onKeyDown: (params: { event: KeyboardEvent }) => boolean;
}

const MentionList = forwardRef<MentionListHandle, MentionListProps>((props, ref: Ref<MentionListHandle>) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = (index: number) => {
    const item = props.items[index];
    if (item) {
      props.command({ id: item?.id, label: item?.name, email: item?.email });
    }
  };

  const upHandler = () => {
    setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length);
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => {
    setSelectedIndex(0);
  }, [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === 'ArrowUp') {
        upHandler();
        return true;
      }

      if (event.key === 'ArrowDown') {
        downHandler();
        return true;
      }

      if (event.key === 'Enter') {
        enterHandler();
        return true;
      }

      return false;
    }
  }));

  return props.items?.length > 0 ? (
    <div className='dropdown-menu'>
      {props.items.length ? (
        props.items.map((item, index) => (
          <button
            className={index === selectedIndex ? 'is-selected' : ''}
            key={item?.name}
            onClick={() => selectItem(index)}>
            <Avatar
              imgSrc={item?.profile_photo || ''}
              name={item?.name ? item?.name : 'U N'}
              size={20}
              isNotBorder
              classname='avtar'
            />
            {item?.name}
          </button>
        ))
      ) : (
        <div className='item'>No result</div>
      )}
    </div>
  ) : (
    <></>
  );
});

export default MentionList;
