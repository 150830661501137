/* eslint-disable max-len */
import React, { useCallback, useEffect, useState } from 'react';
import SVGIcon from '../../../../../assets/images/svg/SVGIcon';
import { PrimaryButton } from '../../../../../component/accounting/styles';
import { Container, Header, Leftheader, Titles, Buttons, Content, Icon, Div } from './styles';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getDateObjecttoMMMDDYYYY, isEmpty } from '../../../../../helpers/common';
import FileViewer from 'react-file-viewer';
import { getFileDetailsById } from '../../../../../services/projectServices';
import { setFileViewData } from '../../../../../actions/projectActions';
import { RootReducerInterface } from '../../../../../interfaces/RootReducerInterface';

const FileView: React.FC = () => {
  // States
  const [extension, setExtension] = useState('');
  const [fileTitle, setFileTitle] = useState('');
  const [loading, setLoading] = useState(false);

  //use selector state variables
  const projectSelector = useSelector((state: RootReducerInterface) => state.project);
  const { fileViewData } = projectSelector;

  // Other variables
  const history = useHistory();
  const dispatch = useDispatch();
  const file = history.location.search;
  const fileUrl = file.replace('?fileUrl=', '');
  const params: { name: string } = useParams();

  // Load initial data
  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      if (!isEmpty(params?.name) && isEmpty(fileUrl)) {
        const result = await dispatch(getFileDetailsById(params?.name));
        if (result) {
          const filename = result?.name;
          const parts = filename?.split('.');
          const extension = parts[parts?.length - 1];
          const lastIndex = extension?.lastIndexOf('.');
          if (lastIndex !== -1) {
            setExtension(extension?.substring(lastIndex + 1));
          } else {
            setExtension(extension);
          }
        }
      } else {
        const url = new URL(fileUrl);
        const decodedFilename = decodeURIComponent(url.pathname);
        // Get the last part of the path, which is the filename
        const parts = decodedFilename?.split('/');
        const extractedFilename = parts[parts.length - 1];
        const splitName = extractedFilename?.split('.');
        setExtension(splitName[splitName?.length - 1]);
        setFileTitle(extractedFilename);
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [dispatch, fileUrl, params?.name]);

  useEffect(() => {
    loadData();
    return () => {
      dispatch(setFileViewData({}));
    };
  }, [dispatch, loadData]);

  // for redirect back page
  const onClickBack = useCallback(() => {
    history.goBack();
  }, [history]);

  // for handle error
  const onError = (e: React.SyntheticEvent) => {
    console.log('error in file-viewer', e);
  };

  return (
    <Div>
      <Container>
        <Header>
          <Leftheader>
            {!isEmpty(fileViewData) && (
              <div onClick={onClickBack}>
                <SVGIcon
                  name='left-arrow-icon'
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                  className='fillColor'
                />
              </div>
            )}
            <Titles>
              <h1>
                {fileViewData?.fileExt !== '.xlsx' && 'Get Started with'}{' '}
                {!isEmpty(fileViewData?.name) ? fileViewData?.name : fileTitle}
              </h1>
              {!isEmpty(fileViewData?.createdOn) && (
                <p>uploaded on {getDateObjecttoMMMDDYYYY(fileViewData?.createdOn)}</p>
              )}
            </Titles>
          </Leftheader>
          <Buttons>
            <PrimaryButton
              href={!isEmpty(fileViewData?.url) ? fileViewData?.url : fileUrl}
              download='MyExampleDoc'
              target='_blank'>
              Download
            </PrimaryButton>
            <Icon
              onClick={(e) => {
                e.preventDefault();
                history.goBack();
              }}>
              <SVGIcon name='preview-close-icon' viewBox='0 0 30 30' width='30' height='30' className='svg-icon' />
            </Icon>
          </Buttons>
        </Header>
        <Content>
          <FileViewer
            fileType={extension.toLowerCase()}
            filePath={!isEmpty(fileViewData?.url) ? fileViewData?.url : fileUrl}
            onError={onError}
            loading={loading}
          />
        </Content>
      </Container>
    </Div>
  );
};

export default FileView;
