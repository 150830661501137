import styled from 'styled-components';
import { Typography } from '../../../global/theme';

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 0 12px;
  width: 100%;
  max-width: 662px;
  margin: 0 auto;
`;
const Subscriptiongroup = styled.div`
  width: 100%;
  max-width: 662px;
  margin: 0 auto;
  margin-bottom: 24px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  h1 {
    color: var(--text-primary);
  }
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Title = styled.div`
  color: var(--text-primary);
  ${Typography.heading_md}
`;
const Bottomcontent = styled.div`
  border-radius: 8px;
  border: 0.5px solid var(--border-primary);
  background: var(--background-primary);
`;

const Box = styled.div`
  display: flex;
  padding: 12px 16px;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid var(--border-primary);
  :last-child {
    border-bottom: none;
  }
`;

const Leftcontent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  h1 {
    color: var(--text-primary);
    ${Typography.body_lg_medium}
    margin: 0;
  }
`;
const Leftdata = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 430px;
  h1 {
    color: var(--text-primary);
    ${Typography.heading_lg}
    margin: 0;
  }
  p {
    margin: 0;
    color: var(--text-secondary);
    ${Typography.body_md}
  }
`;
const Rightcontent = styled.div`
  white-space: nowrap;
  height: 24px;
  .svg-icon {
    padding: 3px;

    cursor: pointer;
    fill: var(--text-secondary);
    stroke: var(--text-secondary);
    :hover {
      padding: 3px;
      border-radius: 4px;
      background: var(--neutral-secondary);
    }
  }
  .background-image {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
`;

const Description = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  color: var(--text-secondary);
  p {
    color: var(--text-secondary);
    ${Typography.body_md}
    margin: 0;
  }
`;

const Topplan = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  gap: 16px;
  border-radius: 8px;
  border: 0.5px solid var(--border-primary);
  background: var(--background-primary);
  position: relative;
  z-index: 11;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  p {
    margin: 0;
    color: var(--text-primary);
    ${Typography.body_md_medium}
  }
  .correct-sign-icon {
    path {
      stroke: var(--text-primary);
    }
  }
`;
const Rowdata = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 8px 0 14px;
`;
const Price = styled.div`
  color: var(--brand-primary);
  text-align: center;
  ${Typography.heading_lg}
  span {
    color: var(--brand-primary);
    ${Typography.body_md_medium}
  }
`;
const Empty = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Card = styled.div`
  border: 0.5px solid var(--border-primary);
  background-color: var(--background-primary);
  border-radius: 12px;
  box-shadow: var(--shadow-card);
  padding: 23px 19px 19px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`;
const Icon = styled.div`
  width: 42px;
  height: 42px;
`;
const BottomDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  h1 {
    margin: 0;
    color: var(--text-primary);
    ${Typography.heading_lg};
  }
  p {
    margin: 0;
    text-align: center;
    color: var(--text-secondary);
    ${Typography.body_lg};
  }
`;
export {
  Header,
  Subscriptiongroup,
  Bottom,
  Content,
  Title,
  Bottomcontent,
  Box,
  Leftcontent,
  Rightcontent,
  Description,
  Topplan,
  Row,
  Rowdata,
  Price,
  Leftdata,
  Empty,
  Card,
  Icon,
  BottomDiv
};
