import { ActionCreatorWithPayload, createAction } from "@reduxjs/toolkit";
import * as Actions from "./types";
/**
 * @desc Set Snap loader
 */
export const setSnapLoader: ActionCreatorWithPayload<boolean, string> = createAction(Actions.SET_SNAP_LOADER);

/**
 * @desc set snap list by user
 */
export const setSnapList: ActionCreatorWithPayload<any, string> = createAction(
    Actions.SET_SNAP_LIST
  );