import React, { useCallback, useEffect, useState } from 'react';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import CreateNewProjectModal from '../../../component/models/createNewProjectModal';
import {
  Group,
  Content,
  Icon,
  Heading,
  Title,
  Text,
  Boxcontent,
  Box,
  Icon1,
  Titleformat,
  Textformat,
  Headline,
  Modalhead,
  BoxView,
  BoxBottom,
  TopView
} from './styles';
import UserPreferenceSingleton from '../../../helpers/userPreferenceSingleton';
import Button from '../../../component/button';
import createproject from '../../../assets/images/createproject.svg';
import demoproject from '../../../assets/images/demoproject.svg';
import { createDemoProject, getProjectList } from '../../../services/projectServices';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ModalCustom from '../../../component/models/modal';
import { clearProjectDetail } from '../../../actions/projectActions';
import { trackAnalyticActivity } from '../../../services/analyticsService';
import { setErrorMessage } from '../../../actions/messageActions';
import { captureException } from '../../../services/logService';
import AppLogo from '../../../component/appLogo';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import { VIEW_ANALYTICS } from '../../../global/analyticsConstants';

const OnboardingStep3: React.FC = () => {
  // States
  const [isOpenCreateProjectModal, setIsOpenCreateProjectModal] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [webAccept, setWebAccept] = useState(false);
  //use selector state variables
  const workspaceSelector = useSelector((state: RootReducerInterface) => state.workspace);
  const { workspace: workspaceDetails } = workspaceSelector;
  const { isAdmin, isOwner } = workspaceDetails || {};
  // Other variables
  const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
  const dispatch = useDispatch();
  const history = useHistory();
  // const mobile = useMobileDevice();

  // useEffect for this component
  useEffect(() => {
    trackAnalyticActivity(VIEW_ANALYTICS.ONBOARDING_WELCOME);
  }, []);

  // On click continue web
  // const onClickContinueWeb = useCallback(() => {
  //   setWebAccept(true);
  // }, []);

  // Open create project modal
  const openCreateProjectModal = useCallback(async () => {
    if (isAdmin || isOwner) setIsOpenCreateProjectModal(true);
    else dispatch(setErrorMessage("You don't have access to create project!"));
  }, [dispatch, isAdmin, isOwner]);

  // On click continue app
  // const onClickContinueApp = useCallback(() => {
  //   const newTab = window.open(`https://teamcamp.app.link/DUIOeJICOFb`, '_blank');
  //   if (newTab) {
  //     newTab.focus();
  //   } else {
  //     alert('Please allow pop-ups for this site to open in a new tab.');
  //   }
  // }, []);

  // Close create project modal
  const closeCreateProjectModal = useCallback(() => {
    setIsOpenCreateProjectModal(false);
    dispatch(clearProjectDetail());
  }, [dispatch]);

  // On click create demo project
  const onClickDemoProject = useCallback(async () => {
    if (!isAdmin && !isOwner) {
      dispatch(setErrorMessage("You don't have access to create project!"));
      return;
    }
    let response;
    try {
      setLoading(true);
      response = await dispatch(createDemoProject());
      if (response) {
        const result = await dispatch(getProjectList());
        if (result?.length > 0) {
          history.push(`/projects/details/${response?.data?.['_id']}/tasks`);
        }
      }
    } catch (error) {
      captureException(error);
      console.error('An error occurred:', error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [isAdmin, isOwner, dispatch, history]);

  return (
    <>
      {/* {(webAccept || !mobile) && ( */}
      <>
        <Group className='windows-onboarding-flow'>
          <Content>
            <BoxView>
              <Icon>
                <AppLogo height='48' />
              </Icon>
              <TopView>
                <Heading>
                  <Title>Hello, {userDetails?.given_name}</Title>
                  <Title> !! Welcome Teamcamp Projects</Title>
                </Heading>
                <Text>
                  Let’s get you started with setting up your projects and show you what Teamcamp can do for your team
                </Text>
              </TopView>
            </BoxView>
            <Boxcontent>
              <Box>
                <Icon1>
                  <img src={createproject} alt='createproject' />
                </Icon1>
                <BoxBottom>
                  <BoxView>
                    <Titleformat>Create Your First Project </Titleformat>
                    <Textformat>
                      Let’s get you started with setting up your projects and show you what Teamcamp Projects can do for
                      your team
                    </Textformat>
                  </BoxView>
                  <Button title='Create Project' onClick={openCreateProjectModal} type='button' />
                </BoxBottom>
              </Box>
              <Box>
                <Icon1>
                  <img src={demoproject} alt='demoproject' />
                </Icon1>
                <BoxBottom>
                  <BoxView>
                    <Titleformat> Try Demo Project </Titleformat>
                    <Textformat>
                      Let’s get you started with setting up your projects and show you what Teamcamp Projects can do for
                      your team
                    </Textformat>
                  </BoxView>
                  <Button title='Demo Project' type='submit' onClick={onClickDemoProject} isLoading={loading} />
                </BoxBottom>
              </Box>
            </Boxcontent>
          </Content>
        </Group>
        <ModalCustom open={isOpenCreateProjectModal} onClose={closeCreateProjectModal} width={632}>
          <Modalhead>
            <Headline>Create New Project</Headline>
            <Icon onClick={closeCreateProjectModal}>
              <SVGIcon name='close-icon' width='18' height='18' viewBox='0 0 18 18' fill='none' />
            </Icon>
          </Modalhead>
          <CreateNewProjectModal handleCloseModal={closeCreateProjectModal} onClickCancel={closeCreateProjectModal} />
        </ModalCustom>
      </>
      {/* )} */}
      {/* {mobile && !webAccept && (
        <Mobileonborading onClickContinueWeb={onClickContinueWeb} onClickContinueApp={onClickContinueApp} />
      )}  */}
    </>
  );
};
export default OnboardingStep3;
