/* eslint-disable max-len */
import React, { useCallback, useEffect } from 'react';
import AppLayout from '../../../component/appLayout';
import { Personaltext } from '../styles';
import {
  Autotext,
  Bottom,
  Bottomcontent,
  Box,
  Content,
  Description,
  Header,
  Icon,
  Label,
  Leftcontent,
  PersonalHeader,
  Rightcontent,
  Subscriptiongroup,
  Text
} from './styles';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { useSelector, useDispatch } from 'react-redux';
import { updateAppSettings } from '../../../actions/settingActions';
import { updateAppsSettings } from '../../../services/settingServices';
import UserPreferenceSingleton from '../../../helpers/userPreferenceSingleton';
import { getWorkspaceDetails } from '../../../services/workspaceService';
import { isEmpty } from '../../../helpers/common';
import { captureException } from '../../../services/logService';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import { ResponsiveHeader } from '../SettingHeaderResponsive';
import { useMobileDevice } from '../../../global/useMobile';

const Apps: React.FC = () => {
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { settings: settingSelector, workspace: workspaceSelector } = stateSelector || {};
  const { appSettings } = settingSelector;
  const { workspace } = workspaceSelector;
  const { id: workspace_id } = workspace;
  // Other variables
  const dispatch = useDispatch();
  const isMobile = useMobileDevice();

  const loadData = useCallback(async () => {
    try {
      if (!isEmpty(workspace?.id)) {
        const apps = workspace?.apps;
        dispatch(
          updateAppSettings({
            propsName: 'invoice',
            value: !isEmpty(apps?.invoiceAndPayment) ? apps?.invoiceAndPayment : true
          })
        );
        dispatch(updateAppSettings({ propsName: 'customer', value: !isEmpty(apps?.customer) ? apps?.customer : true }));
        dispatch(
          updateAppSettings({
            propsName: 'timetracking',
            value: !isEmpty(apps?.timeTracking) ? apps?.timeTracking : true
          })
        );
        dispatch(updateAppSettings({ propsName: 'messages', value: !isEmpty(apps?.messages) ? apps?.messages : true }));
      }
    } catch (error) {
      captureException(error);
      console.log('error', error);
    }
  }, [dispatch, workspace?.apps, workspace?.id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onChangeAppSettings = useCallback(
    async (propsName: string, value: boolean) => {
      try {
        const userDetails = await UserPreferenceSingleton.getInstance().getCurrentUser();
        dispatch(updateAppSettings({ propsName, value }));
        const payload = {
          userId: userDetails?.id,
          companyId: workspace_id,
          invoicePayment: appSettings?.invoice,
          customer: appSettings?.customer,
          timeTracking: appSettings?.timetracking,
          messages: appSettings?.messages
        };
        if (propsName === 'invoice') {
          payload.invoicePayment = value;
        } else if (propsName === 'customer') {
          payload.customer = value;
        } else if (propsName === 'timetracking') {
          payload.timeTracking = value;
        } else if (propsName === 'messages') {
          payload.messages = value;
        }
        const response = await dispatch(updateAppsSettings(payload));
        if (response) await dispatch(getWorkspaceDetails(workspace_id));
      } catch (error) {
        captureException(error);
        console.log('error', error);
      }
    },
    [appSettings, dispatch, workspace_id]
  );

  const appData = [
    {
      icon: 'invoice-app-icon',
      title: 'Invoice & Payment',
      description: 'Invoicing and payments made easier by Teamcamp. Now money management is easier than ever.',
      propsName: 'invoice',
      checked: appSettings?.invoice
    },
    {
      icon: 'customer-app-icon',
      title: 'Customer',
      description: 'Group projects by client and invite them for collaboration.',
      propsName: 'customer',
      checked: appSettings?.customer
    },
    {
      icon: 'time-app-icon',
      title: 'Time Tracking',
      description:
        'This feature keeps an accurate track of all your work. Make billing your clients easy and hassle free.',
      propsName: 'timetracking',
      checked: appSettings?.timetracking
    },
    {
      icon: 'message-app-icon',
      title: 'Messages',
      description:
        'Elevate communication with integrated messaging for seamless collaboration within the teams and projects.',
      propsName: 'messages',
      checked: appSettings?.messages
    },
    {
      icon: 'calendar-app-icon',
      title: 'Calendar',
      description: 'Calendar enhances schedule management and task assignments for efficient organization.',
      propsName: '',
      comingSoon: true
    }
  ];
  return (
    <>
      <AppLayout>
        <Subscriptiongroup>
          <Header>
            <PersonalHeader>
              {isMobile && <ResponsiveHeader />}
              <Personaltext>Apps</Personaltext>
            </PersonalHeader>
          </Header>
          <Content>
            <Bottom>
              <Bottomcontent>
                {appData.map((app) => (
                  <Box key={app?.title}>
                    <Leftcontent>
                      <Icon>
                        <SVGIcon name={app.icon} width='24' height='24' viewBox='0 0 24 24' className='fill-color' />
                      </Icon>
                      <Text>
                        <h1>{app.title}</h1>
                        <Description>
                          <p>{app.description}</p>
                        </Description>
                      </Text>
                    </Leftcontent>
                    {app.comingSoon ? (
                      <Label>
                        <p>Coming soon</p>
                      </Label>
                    ) : (
                      <Rightcontent>
                        <Autotext>
                          <label className='switch'>
                            <input
                              type='checkbox'
                              checked={app.checked}
                              onChange={() => onChangeAppSettings(app.propsName, !app.checked)}
                            />
                            <span className='slider round'></span>
                          </label>
                        </Autotext>
                      </Rightcontent>
                    )}
                  </Box>
                ))}
              </Bottomcontent>
            </Bottom>
          </Content>
        </Subscriptiongroup>
      </AppLayout>
    </>
  );
};

export default Apps;
