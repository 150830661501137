import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import Button from '../../../component/button';
import { setErrorMessage } from '../../../actions/messageActions';
import { isEmail, isEmpty } from '../../../helpers/common';
import AppLogo from '../../../component/appLogo';
import {
  Content,
  Form,
  Formblock,
  Formtext,
  Formtext1,
  Formtextarea,
  FormView,
  Group,
  Icon,
  Inputvalue,
  ResetView,
  Title,
  Viewer
} from '../styles';

const Deleteuser: React.FC = () => {
  //State
  const [email, setEmail] = useState('');
  const [isDeleted, setIsDeleted] = useState(false);
  //Other variable
  const history = useHistory();
  const dispatch = useDispatch();

  // for deleting user
  const onDeleteUser = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();
      if (isEmpty(email)) {
        dispatch(setErrorMessage('Please enter email!'));
        return;
      } else if (!isEmail(email)) {
        dispatch(setErrorMessage('Please enter valid email!'));
        return;
      }
      setIsDeleted(true);
    },
    [dispatch, email]
  );

  return (
    <>
      <Group isDeleteUserPage={true} className='windows-onboarding-flow'>
        <Content isSent={isDeleted}>
          <Icon>
            <AppLogo height='60' />
          </Icon>

          {isDeleted ? <></> : <Title> Delete user</Title>}
          {isDeleted ? (
            <>
              <ResetView>
                <Title>Account deletion process initiated.</Title>
                <Formtext isSent={isDeleted}>
                  <p>
                    If there's an account associated with this email, Please check your email within the next 24 hours
                    and click the provided link to complete the account deletion process.
                  </p>
                </Formtext>
              </ResetView>
            </>
          ) : (
            <FormView>
              <Form onSubmit={onDeleteUser}>
                <Formblock>
                  <Inputvalue
                    onChange={(e) => setEmail(e.target.value)}
                    typeof='email'
                    placeholder='Email Address'
                    value={email}
                  />
                </Formblock>
                <Button title='Delete' type='submit' resWidth={100} />
              </Form>
              <Formtextarea>
                <Formtext1>
                  <Viewer
                    onClick={() => {
                      history.push('/membership/login');
                    }}>
                    Back to Login
                  </Viewer>
                </Formtext1>
              </Formtextarea>
            </FormView>
          )}
        </Content>
      </Group>
    </>
  );
};

export default Deleteuser;
