import React from 'react';
import { BottomBox, DescriptionSuccess, IconDiv, ModalBox, TitleDiv, TitleScuccess } from '../style';
import SVGIcon from '../../../../assets/images/svg/SVGIcon';

interface Props {
  onClose: () => void;
}

export const SuccessfullModal: React.FC<Props> = (props) => {
  const { onClose } = props;
  return (
    <>
      <ModalBox>
        <IconDiv onClick={onClose}>
          <SVGIcon name='file-close-icon' width='18' height='18' viewBox='0 0 24 24' className='fillColor' />
        </IconDiv>
        <BottomBox>
          <SVGIcon
            name={'subscripton-successfull-icon'}
            width='56'
            height='56'
            viewBox='0 0 56 56'
            fill='var(--neutral-secondary)'
          />
          <TitleDiv>
            <TitleScuccess>Message sent</TitleScuccess>
            <DescriptionSuccess>We’ll be touch with you soon over email.</DescriptionSuccess>
          </TitleDiv>
        </BottomBox>
      </ModalBox>
    </>
  );
};
