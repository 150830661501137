import { Group, Rightdiv, Leftdiv } from './styles';
import Chatbox from './chatbox';
import Messagedetail from './messagedetail';
import { useCallback, useState } from 'react';
import { ChatGroupInterface } from '../../interfaces/chatMessageInterface';
import {
  setChatGroups,
  setCurrentChatGroup,
  setPinChatGroups,
  setUnpinChatGroups
} from '../../actions/chatMessageActions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CHAT_GROUP_TYPE } from '../../global/constants';
import { getProjectFiles } from '../../services/projectServices';
import { useMobileDevice } from '../../global/useMobile';
import UserPreferenceSingleton from '../../helpers/userPreferenceSingleton';
import { setLastReadForDiscussion } from '../../services/chatMessageService';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';

interface Props {
  loadData: () => void;
  loading: boolean;
}
export default function Navbarmessagedata({ loadData, loading }: Props) {
  //States
  const [newGroup, setNewGroup] = useState(false);
  const [attachment, setAttachment] = useState(false);
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { chatMessage: chatMessageSelector } = stateSelector || {};
  const { chatGroups, pinChatGroups, unpinChatGroups } = chatMessageSelector || {};
  //Other variable
  const dispatch = useDispatch();
  const history = useHistory();
  const mobile = useMobileDevice();

  // Set has read messages false for current selected group
  const updateMessageGroupReducer = useCallback(
    (groupId: string) => {
      if (groupId) {
        const updatedChatGroups = chatGroups?.map((group) =>
          group?.id === groupId ? { ...group, hasUnreadMessages: false } : group
        );
        const updatedPinnedChatGroups = pinChatGroups?.map((group) =>
          group?.id === groupId ? { ...group, hasUnreadMessages: false } : group
        );
        const updatedUnpinnedChatGroups = unpinChatGroups?.map((group) =>
          group?.id === groupId ? { ...group, hasUnreadMessages: false } : group
        );
        dispatch(setChatGroups(updatedChatGroups));
        dispatch(setPinChatGroups(updatedPinnedChatGroups));
        dispatch(setUnpinChatGroups(updatedUnpinnedChatGroups));
      }
    },
    [chatGroups, dispatch, pinChatGroups, unpinChatGroups]
  );

  const onClickGroup = useCallback(
    (group: ChatGroupInterface) => {
      setAttachment(false); // close render existing file
      updateMessageGroupReducer(group?.id);
      dispatch(setLastReadForDiscussion());
      if (mobile) history.push(`/messages/details?chatGroup=${group?.id}`);
      else history.push(`/messages?chatGroup=${group?.id}`);
      if (group?.type === CHAT_GROUP_TYPE.PROJECT) {
        dispatch(getProjectFiles(group?.id));
      }
      UserPreferenceSingleton.getInstance().setLastMessageGroup(group?.id);
      dispatch(setCurrentChatGroup(group));
      setNewGroup(false);
    },
    [dispatch, history, mobile, updateMessageGroupReducer]
  );

  const onClickNewGroup = useCallback(() => {
    setAttachment(false); // close render existing file
    dispatch(setCurrentChatGroup({ id: '', name: '' }));
    setNewGroup(true);
    if (mobile) history.push(`/messages/details?newGroup=true`);
  }, [dispatch, history, mobile]);

  const onCloseNewGroup = useCallback(() => {
    setNewGroup(false);
  }, []);

  return (
    <>
      <Group className='messages-group'>
        <Leftdiv>
          <Chatbox
            newGroup={newGroup}
            onClickNewChat={() => onClickNewGroup()}
            onClickChatGroup={onClickGroup}
            loading={loading}
          />
        </Leftdiv>
        {!mobile && (
          <Rightdiv>
            <Messagedetail
              newGroup={newGroup}
              loadData={loadData}
              onCloseNewGroup={onCloseNewGroup}
              loading={loading}
              onClickNewChat={onClickNewGroup}
              attachment={attachment}
              setAttachment={setAttachment}
            />
          </Rightdiv>
        )}
      </Group>
    </>
  );
}
