/* eslint-disable max-len */
import { useState, useEffect, forwardRef, useImperativeHandle, useCallback, useRef } from 'react';
import { BubbleMenu, Editor, EditorContent, Extension } from '@tiptap/react';
import Code from '@tiptap/extension-code';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import Placeholder from '@tiptap/extension-placeholder';
import Mention from '@tiptap/extension-mention';
import Typography from '@tiptap/extension-typography';
import suggestion from './suggestion';
import UserPreferenceSingleton from '../../helpers/userPreferenceSingleton';
import Image from '@tiptap/extension-image';
import { SnapEmbed } from './snapEmbed';
import { useSelector, useDispatch } from 'react-redux';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';
import { Plugin } from '@tiptap/pm/state';
import { getShareData } from '../../services/projectServices';
import { getFirebaseUrlsFromFiles, isEmpty, isValidUrl } from '../../helpers/common';
import { nanoid } from 'nanoid';
import { getFigmaPreviewUrl } from '../../services/taskServices';
import { FigmaEmbed } from './figmaEmbed';
import { scrapeHtmlWeb } from 'scrape-html-web';
import { LightshotEmbed } from './lightShotEmbed';
import { YouTubeEmbed } from './youtubeEmbed';
import Link from '@tiptap/extension-link';
import SVGIcon from '../../assets/images/svg/SVGIcon';
import Underline from '@tiptap/extension-underline';
import { SmilieReplacer } from './SmilieReplacer';
import { ColorHighlighter } from './ColorHighlighter';
import { setErrorMessage } from '../../actions/messageActions';
import { captureException } from '../../services/logService';
import { CommentFilesInterface } from '../../interfaces/TaskInterface';
import ReplyMessage from '../comment/replyMessage';
import { TiptapMain } from './styles';
import Commands from './slashCommand/command';
import slashSuggestion from './slashCommand/suggestion';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import { TaskList } from '@tiptap/extension-task-list';
import { TaskItem } from '@tiptap/extension-task-item';
import CustomCodeBlockLowlight from './codeBlock';
import lowlight from './codeBlock/lowlight';
import { setIsBubbleMenuOpen } from '../../actions/appActions';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Strike from '@tiptap/extension-strike';
import ListItem from '@tiptap/extension-list-item';
import Blockquote from '@tiptap/extension-blockquote';
import HardBreak from '@tiptap/extension-hard-break';
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import History from '@tiptap/extension-history';
import Heading from '@tiptap/extension-heading';
import TableToolbar from './tabelToolbar';

interface Props {
  valueContent: string;
  setValueContent?: (value: string) => void;
  placeHolder?: string;
  users?: any[];
  setLoading?: (value: boolean) => void;
  setStateLoading?: (value: boolean) => void;
  addUploadedFiles?: (files: CommentFilesInterface[]) => void;
  replyMessage?: any;
  setReplyMessage?: any;
  maxHeight?: number;
  isAutoFocus?: boolean;
  recordSnapModalOpen?: () => void;
  handleFileInput?: () => void;
  handleGifModal?: () => void;
  isEditable?: boolean;
}

const TiptapEditor = forwardRef((props: Props, ref) => {
  // props variables
  const {
    valueContent = '',
    setValueContent,
    placeHolder,
    users,
    setLoading,
    setStateLoading,
    addUploadedFiles,
    replyMessage,
    setReplyMessage,
    isAutoFocus = false,
    recordSnapModalOpen,
    handleFileInput,
    handleGifModal,
    isEditable = true
  } = props || {};
  // ref variables
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  // use state variables
  const [editor, setEditor] = useState<Editor | null>(null);
  const [isAllowForFigmaPreview, setIsAllowForFigmaPreview] = useState<boolean>(true);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState('bottom');
  const [isUrlOpen, setIsUrlOpen] = useState(false);
  const [urlValue, setUrlValue] = useState('');
  const [currentCell, setCurrentCell] = useState<HTMLElement | null>(null);
  // Reducer state variables
  const settingsSelector = useSelector((state: RootReducerInterface) => state.settings);
  const { themeMode, settingIntegration } = settingsSelector;
  //other variables
  const dispatch = useDispatch();

  // toggle dropdown
  const toggleDropdown = useCallback(() => {
    setDropdownOpen(!isDropdownOpen);
  }, [isDropdownOpen]);

  // use effect function call
  useEffect(() => {
    if (isDropdownOpen) {
      adjustDropdownPosition();
      window.addEventListener('resize', adjustDropdownPosition);
      return () => {
        window.removeEventListener('resize', adjustDropdownPosition);
      };
    }
  }, [isDropdownOpen]);

  // This function give us dropdown position where it's open top or bottom
  const adjustDropdownPosition = () => {
    const buttonRect = buttonRef.current?.getBoundingClientRect();
    const dropdown = dropdownRef.current;
    if (buttonRect && dropdown) {
      const dropdownHeight = dropdown.offsetHeight;
      const spaceBelow = window.innerHeight - buttonRect.bottom;
      const spaceAbove = buttonRect.top;
      if (spaceBelow < dropdownHeight && spaceAbove > dropdownHeight) {
        setDropdownPosition('top');
      } else {
        setDropdownPosition('bottom');
      }
    }
  };

  // Add snap recording card
  const addRecordingEmbedCard = useCallback(
    (url: string, recordingName: string, videoId?: string) => {
      if (editor) {
        editor
          .chain()
          .focus()
          .insertContent([
            {
              type: 'snapEmbed',
              attrs: {
                url,
                id: videoId || '',
                recordingName,
                themeMode: themeMode?.theme || 'light'
              }
            },
            { type: 'paragraph' } // Insert an empty paragraph after the embed card
          ])
          .run();
      }
    },
    [editor, themeMode]
  );

  // Convert file to base 64
  const convertBase64 = useCallback((file: File) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        const item = {
          fileContent: fileReader.result,
          fileType: file?.type,
          name: file?.name,
          size: file?.size,
          file: file
        };
        resolve(item);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }, []);

  // Handle file read when upload file
  const handleFileRead = useCallback(
    async (file: File) => {
      if (file?.size > 2.5e8) {
        dispatch(setErrorMessage('Please upload a file smaller than 250 MB.'));
        return false;
      }
      const newPromise = convertBase64(file);
      try {
        const value = await Promise.all([newPromise]);
        return value[0];
      } catch (error) {
        captureException(error);
        console.log('error', error);
      }
    },
    [convertBase64, dispatch]
  );

  // Custom extension to embed cards for figma, youtube, snap, etc.
  const EmbedPluginExtension = Extension.create({
    name: 'embedPlugin',

    addOptions() {
      return {
        getSettings: () => ({ settingIntegration, isAllowForFigmaPreview }),
        setLoading: null,
        dispatch: null,
        scrapeHtmlWeb: null
      };
    },

    addProseMirrorPlugins() {
      return [
        new Plugin({
          props: {
            handleDrop(view, event, slice, moved) {
              event.preventDefault();
              const { schema } = view.state;
              const coords = view.posAtCoords({ left: event.clientX, top: event.clientY });
              if (coords && event.dataTransfer?.files) {
                const files: File[] = Array.from(event.dataTransfer.files); // Convert FileList to Array
                const imageFiles = files?.filter((file: File) => file?.type.startsWith('image/'));
                const otherFiles = files?.filter((file: File) => !file.type.startsWith('image/'));

                if (imageFiles && imageFiles?.length > 0) {
                  imageFiles?.forEach((file) => {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                      const src = e.target?.result as string;
                      if (src) {
                        const imageNode = schema.nodes.image.create({ src });
                        const transaction = view.state.tr.insert(coords.pos, imageNode);
                        view.dispatch(transaction);
                      }
                    };
                    reader.readAsDataURL(file);
                  });
                }

                if (otherFiles && otherFiles?.length > 0) {
                  (async () => {
                    try {
                      setStateLoading?.(true);

                      // Prepare files for Firebase upload
                      const fileData = await Promise.all(
                        otherFiles?.map(async (file) => {
                          const fileContent = await handleFileRead(file);
                          if (isEmpty(fileContent)) return null; // Skip files that failed processing
                          return { file };
                        })
                      );
                      // Filter out null values
                      const validFiles = fileData?.filter((file): file is { file: File } => file !== null);
                      // Upload files and get URLs
                      const uploadedUrls = await getFirebaseUrlsFromFiles(validFiles, 'files/', dispatch);

                      if (uploadedUrls && uploadedUrls?.length > 0) {
                        const referenceFiles: CommentFilesInterface[] = [];
                        uploadedUrls?.forEach((url: string, index: number) => {
                          const file = otherFiles[index];
                          const parts = file?.name.split('.');
                          const extension = parts[parts.length - 1];
                          const sizeInMB = file?.size / (1024 * 1024);

                          let sizeValue;
                          if (sizeInMB >= 1) {
                            sizeValue = `${sizeInMB.toFixed(2)} MB`;
                          } else {
                            const sizeInKB = file?.size / 1024;
                            sizeValue = `${sizeInKB.toFixed(2)} KB`;
                          }

                          if (['mp4', 'mov', 'mkv', 'webm'].includes(extension)) {
                            const videoNode = schema.nodes.snapEmbed.create({
                              url,
                              id: '',
                              recordingName: file?.name,
                              themeMode: themeMode?.theme || 'light'
                            });
                            const transaction = view.state.tr.insert(coords.pos, videoNode);
                            view.dispatch(transaction);
                          } else {
                            const newObj = {
                              name: file?.name,
                              href: url,
                              size: sizeValue,
                              file_type: extension
                            };
                            referenceFiles.push(newObj);
                          }
                        });
                        if (referenceFiles && referenceFiles?.length > 0) addUploadedFiles?.(referenceFiles);
                      }
                    } catch (e) {
                      console.log('error : ', e);
                    } finally {
                      setStateLoading?.(false);
                    }
                  })();
                }
              }
              return false;
            },
            handlePaste(view, event) {
              event.preventDefault();
              const clipboardData = event.clipboardData?.getData('Text');
              try {
                // Handle pasted images
                const items: any = event.clipboardData?.items;
                if (items?.length) {
                  for (const item of items) {
                    if (item.type.startsWith('image/')) {
                      const file = item.getAsFile();
                      if (file) {
                        // Create a blob URL
                        const blobUrl = URL.createObjectURL(file);

                        // Insert the image node into the editor
                        const imageNode = view.state.schema.nodes.image.create({ src: blobUrl });
                        const transaction = view.state.tr.replaceSelectionWith(imageNode);
                        view.dispatch(transaction);

                        return true; // Prevent further handling
                      }
                    }
                  }
                }

                // Embed Snap Card if share url pasted
                if (clipboardData?.includes('figma.com')) {
                  (async () => {
                    try {
                      if (!settingIntegration?.figma_connected) {
                        // If no preview URL, replace with plain text (URL)
                        const textNode = view.state.schema.text(clipboardData);
                        const fallbackTransaction = view.state.tr.replaceSelectionWith(textNode);
                        view.dispatch(fallbackTransaction);
                        return;
                      }
                      if (setLoading) setLoading(true);
                      if (!isValidUrl(clipboardData)) {
                        const textNode = view.state.schema.text(clipboardData);
                        const fallbackTransaction = view.state.tr.replaceSelectionWith(textNode);
                        view.dispatch(fallbackTransaction);
                        setIsAllowForFigmaPreview(false);
                        return false;
                      }
                      const url = new URL(clipboardData);
                      const fileId = url.pathname.split('/')[2];
                      const fileName = url.pathname.split('/')[3];
                      const nodes = url.searchParams.get('node-id');

                      // Generate a unique ID for the loading card
                      const uniqueId = nanoid();

                      // Show loading card with a unique ID
                      const loadingNode = view.state.schema.nodes.figmaEmbed.create({
                        frameNames: 'Loading...',
                        projectName: fileName,
                        filePreviewUrl: '',
                        url: clipboardData,
                        isLoading: true,
                        nodeId: uniqueId // Unique identifier
                      });

                      // Insert the loading node at the current selection
                      let transaction = view.state.tr.replaceSelectionWith(loadingNode);

                      // Insert an empty paragraph at the end for continued typing
                      const endPos = transaction.doc.content.size;
                      const emptyParagraph = view.state.schema.nodes.paragraph.create();
                      transaction = transaction.insert(endPos, emptyParagraph);
                      view.dispatch(transaction);

                      // Fetch the actual data after a slight delay (for UI effect)
                      const figmaData = await dispatch(getFigmaPreviewUrl({ fileId, nodes }));

                      if (figmaData?.filePreviewUrl) {
                        // Create final Figma embed node
                        const finalNode = view.state.schema.nodes.figmaEmbed.create({
                          frameNames: figmaData?.frameNames,
                          projectName: figmaData?.projectName || 'Example Project Name',
                          filePreviewUrl: figmaData.filePreviewUrl,
                          url: clipboardData,
                          isLoading: false,
                          nodeId: uniqueId // Retain the unique identifier
                        });

                        // Find the loading node position by uniqueId and replace it
                        view.state.doc.descendants((node, pos) => {
                          if (node.attrs.nodeId === uniqueId) {
                            const finalTransaction = view.state.tr.replaceWith(pos, pos + node.nodeSize, finalNode);
                            view.dispatch(finalTransaction);
                            return false; // Stop searching
                          }
                          return true; // Continue searching
                        });
                      } else {
                        // If no preview URL, replace with plain text (URL)
                        const textNode = view.state.schema.text(clipboardData);
                        const fallbackTransaction = view.state.tr.replaceSelectionWith(textNode);
                        view.dispatch(fallbackTransaction);
                      }
                    } catch (e) {
                      console.log('Error : ', e);
                    } finally {
                      if (setLoading) setLoading(false);
                    }
                  })();
                  return true; // Prevents further handling
                }

                // Embed figma embed state
                else if (clipboardData?.includes('figma.com')) {
                  setIsAllowForFigmaPreview(false);
                }

                // Embed share snap card
                else if (
                  clipboardData?.startsWith('https://share.teamcamp.app') &&
                  !clipboardData?.startsWith('https://share.teamcamp.app/docs')
                ) {
                  (async () => {
                    try {
                      if (setLoading) setLoading(true);
                      const url = new URL(clipboardData);
                      const fileId = url.pathname.split('/')[1];
                      const share_data = await dispatch(getShareData(fileId));
                      if (share_data) {
                        const finalSnapNode = view.state.schema.nodes.snapEmbed.create({
                          url: share_data?.ref,
                          id: fileId,
                          recordingName: share_data?.name,
                          themeMode: themeMode?.theme || 'light'
                        });

                        let transaction = view.state.tr.replaceSelectionWith(finalSnapNode);

                        // Insert an empty paragraph at the end for continued typing
                        const endPos = transaction.doc.content.size;
                        const emptyParagraph = view.state.schema.nodes.paragraph.create();
                        transaction = transaction.insert(endPos, emptyParagraph);
                        view.dispatch(transaction);
                      } else {
                        // If no preview URL, replace with plain text (URL)
                        const textNode = view.state.schema.text(clipboardData);
                        const fallbackTransaction = view.state.tr.replaceSelectionWith(textNode);
                        view.dispatch(fallbackTransaction);
                        return true;
                      }
                    } catch (e) {
                      console.log('Error : ', e);
                    } finally {
                      if (setLoading) setLoading(false);
                    }
                  })();
                  return true; // Prevents further handling
                }

                // Embed lighshot preview
                else if (clipboardData && clipboardData.startsWith('https://prnt.sc')) {
                  (async () => {
                    try {
                      const matchUrl = clipboardData.match(/https:\/\/prnt\.sc\/\S+/)?.[0] || '';
                      if (setLoading) setLoading(true);

                      const url = new URL(matchUrl);
                      const options: any = {
                        url,
                        bypassCors: true,
                        mainSelector: '.image__pic',
                        childrenSelector: [{ key: 'link', selector: 'a', attr: 'src' }]
                      };
                      const data = await scrapeHtmlWeb(options);

                      if (data[0]?.link) {
                        // Create final Figma embed node
                        const finalLightshotNode = view.state.schema.nodes.lightshotEmbed.create({
                          url: clipboardData,
                          previewLink: data[0]?.link
                        });

                        let transaction = view.state.tr.replaceSelectionWith(finalLightshotNode);

                        // Insert an empty paragraph at the end for continued typing
                        const endPos = transaction.doc.content.size;
                        const emptyParagraph = view.state.schema.nodes.paragraph.create();
                        transaction = transaction.insert(endPos, emptyParagraph);
                        view.dispatch(transaction);
                      } else {
                        // If no preview URL, replace with plain text (URL)
                        const textNode = view.state.schema.text(clipboardData);
                        const fallbackTransaction = view.state.tr.replaceSelectionWith(textNode);
                        view.dispatch(fallbackTransaction);
                        return true;
                      }
                    } catch (e) {
                      console.log('Error : ', e);
                    } finally {
                      if (setLoading) setLoading(false);
                    }
                  })();
                  return true; // Prevents further handling
                }

                // Embed youtube card preview
                else if (
                  clipboardData?.startsWith('https://www.youtube.com') ||
                  clipboardData?.startsWith('https://youtu.be')
                ) {
                  (async () => {
                    try {
                      if (setLoading) setLoading(true);

                      let videoId = '';

                      // Extract Video ID from YouTube link
                      if (clipboardData?.startsWith('https://www.youtube.com')) {
                        const url = new URL(clipboardData);
                        videoId = url.searchParams?.get('v') || '';
                      } else if (clipboardData?.startsWith('https://youtu.be')) {
                        const url = new URL(clipboardData);
                        videoId = url.pathname.slice(1);
                      }

                      if (videoId) {
                        const previewImageUrl = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
                        // Create final Figma embed node
                        const finalNode = view.state.schema.nodes.youTubeEmbed.create({
                          url: clipboardData,
                          videoId,
                          previewImageUrl,
                          themeMode: themeMode?.theme || 'light'
                        });

                        let transaction = view.state.tr.replaceSelectionWith(finalNode);

                        // Insert an empty paragraph at the end for continued typing
                        const endPos = transaction.doc.content.size;
                        const emptyParagraph = view.state.schema.nodes.paragraph.create();
                        transaction = transaction.insert(endPos, emptyParagraph);
                        view.dispatch(transaction);
                      } else {
                        // If no preview URL, replace with plain text (URL)
                        const textNode = view.state.schema.text(clipboardData);
                        const fallbackTransaction = view.state.tr.replaceSelectionWith(textNode);
                        view.dispatch(fallbackTransaction);
                        return true;
                      }
                    } catch (e) {
                      console.log('Error : ', e);
                    } finally {
                      if (setLoading) setLoading(false);
                    }
                  })();
                  return true; // Prevents further handling
                }
                return false;
              } catch (e) {
                console.log('Error : ', e);
                return false;
              }
            }
          }
        })
      ];
    }
  });

  // Function call on select record snap
  const recordSnap = useCallback(() => {
    if (recordSnapModalOpen) recordSnapModalOpen();
  }, [recordSnapModalOpen]);

  // This function call when we want to open gif modal
  const openGifModal = useCallback(() => {
    if (handleGifModal) handleGifModal();
  }, [handleGifModal]);

  // Call empty function on cmd + enter to avoid extra enter event on send
  const CommandEnterShortcut = Extension.create({
    name: 'commandEnterShortcut',
    addKeyboardShortcuts() {
      return {
        'Mod-Enter': () => {
          // Call this function just to avoid enter on cmd+enter press
          return true;
        }
      };
    }
  });

  // Extensions for tiptap editor
  const extensions = [
    Underline,
    Image,
    Placeholder.configure({
      placeholder: placeHolder || 'Write something...' // Set the placeholder text here
    }),
    Mention.configure({
      HTMLAttributes: {
        class: 'mention'
      },
      suggestion
    }),
    Bold,
    Italic,
    Strike,
    ListItem,
    Blockquote,
    HardBreak,
    HorizontalRule,
    History,
    Heading.configure({
      levels: [1, 2, 3, 4, 5, 6] // Define which heading levels to allow
    }),
    CustomCodeBlockLowlight.configure({
      lowlight,
      isRead: !isEditable
    }),
    Link.configure({
      openOnClick: false,
      autolink: true,
      defaultProtocol: 'https'
    }),
    SnapEmbed,
    FigmaEmbed,
    LightshotEmbed,
    YouTubeEmbed,
    EmbedPluginExtension,
    Typography,
    ColorHighlighter,
    SmilieReplacer,
    Code,
    Document,
    Paragraph,
    Text,
    BulletList.configure({
      keepMarks: true,
      keepAttributes: false
    }),
    OrderedList.configure({
      keepMarks: true,
      keepAttributes: false
    }),
    Commands.configure({
      suggestion: slashSuggestion({ recordSnap, openGifModal, handleFileInput })
    }),
    Table.configure({
      resizable: true
    }),
    TableRow,
    TableCell,
    TableHeader,
    TaskList,
    TaskItem.configure({
      nested: true
    }),
    CommandEnterShortcut
  ];

  // Clear editor content
  const clearContent = useCallback(() => {
    // Clear the editor content
    editor?.commands.setContent(''); // Clear content in the editor
    if (isAutoFocus) {
      editor?.commands.focus();
    }
    setValueContent?.(''); // Update the state to reflect the change
  }, [editor?.commands, isAutoFocus, setValueContent]);

  // Add image in editor content
  const addImageInContent = useCallback(
    (url: string) => {
      if (url) {
        editor
          ?.chain()
          .focus()
          .insertContent([{ type: 'image', attrs: { src: url } }, { type: 'paragraph' }])
          .run();
      }
    },
    [editor]
  );

  useEffect(() => {
    if (!editor) return;

    const handleSelectionUpdate = ({ editor }: { editor: Editor }) => {
      const { from } = editor.state.selection;
      const domAtPos = editor.view.domAtPos(from);
      const node = domAtPos.node;

      let cell: HTMLElement | null = null;

      // Ensure node is an HTMLElement before calling closest
      if (node instanceof HTMLElement) {
        cell = node.closest('td, th') as HTMLElement | null;
      } else if (node?.parentElement) {
        // If it's a text node, try parentElement
        cell = node.parentElement.closest('td, th');
      }

      if (cell && editor.isActive('table')) {
        setCurrentCell(cell);
      } else {
        setCurrentCell(null); // Hide when out of table
      }
    };

    editor.on('selectionUpdate', handleSelectionUpdate);

    return () => {
      editor.off('selectionUpdate', handleSelectionUpdate);
    };
  }, [editor]);

  // Functions which are call from parent components
  useImperativeHandle(ref, () => ({
    clearValue() {
      clearContent();
    },
    addImage(url: string) {
      addImageInContent(url);
    },
    addContent(text: string) {
      editor?.commands.setContent(text); // Clear content in the editor
    },
    addRecordingCard(firebaseUrl: string, fileName: string, videoId?: string) {
      addRecordingEmbedCard(firebaseUrl, fileName, videoId);
    },
    addContentExistingLine(content: string) {
      if (editor) {
        const { from } = editor.state.selection; // Get the current cursor position
        editor.commands.insertContentAt(from, content); // Insert the emoji at the cursor position
        editor.commands.focus(); // Focus the editor after insertion
      }
    }
  }));

  // Use effect function for set mentions uses array in local
  useEffect(() => {
    UserPreferenceSingleton.getInstance().setMentionUsers(users);
  }, [users]);

  // Initialize the editor instance
  useEffect(() => {
    const newEditor = new Editor({
      extensions,
      content: valueContent, // Set initial content here
      editable: isEditable, // Make the editor read-only
      onUpdate: ({ editor }) => {
        setValueContent?.(editor.getHTML());
      }
    });
    setEditor(newEditor);

    // Cleanup editor instance when component unmounts
    return () => {
      newEditor.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // On URL added for selected text
  const onSubmitUrlForm = useCallback(
    (e: React.SyntheticEvent) => {
      if (editor) {
        e.preventDefault();
        if (urlValue === null) {
          return;
        }
        if (urlValue === '') {
          editor.chain().focus().extendMarkRange('link').unsetLink().run();
          return;
        }
        editor.chain().focus().extendMarkRange('link').setLink({ href: urlValue }).run();
        setUrlValue('');
        setIsUrlOpen(false);
      }
    },
    [editor, urlValue]
  );

  // On Click Remove URL
  const onClickRemoveUrl = useCallback(() => {
    if (editor) {
      editor.chain().focus().extendMarkRange('link').unsetLink().run();
      setUrlValue('');
      setIsUrlOpen(false);
    }
  }, [editor]);

  // On redirect to added URL
  const onClickRedirectToUrl = useCallback(() => {
    if (!isEmpty(urlValue)) window.open(urlValue, '_blank');
  }, [urlValue]);

  // On Click Set link tooltip icon
  const setLink = useCallback(() => {
    if (editor) {
      const previousUrl = editor.getAttributes('link').href;
      setUrlValue(previousUrl ? previousUrl : '');
      setIsUrlOpen(true);
    } else {
      console.warn('Editor is null');
    }
  }, [editor]);

  // When editor not loaded show loading
  if (!editor) {
    return <div>Loading editor...</div>;
  }

  return (
    <>
      <TiptapMain>
        {editor &&
          (isUrlOpen ? (
            <>
              <BubbleMenu
                editor={editor}
                tippyOptions={{
                  duration: 50,
                  onHide: () => {
                    dispatch(setIsBubbleMenuOpen(false));
                    setUrlValue('');
                    setIsUrlOpen(false);
                  },
                  onShow: () => {
                    dispatch(setIsBubbleMenuOpen(true));
                  }
                }}>
                <div className='link-menu'>
                  <form onSubmit={onSubmitUrlForm}>
                    <input
                      placeholder='Enter URL'
                      value={urlValue}
                      onChange={(e) => {
                        setUrlValue(e.target.value);
                      }}
                    />
                  </form>
                  <div className='line-div' />
                  <div className='icon-div' onClick={onClickRedirectToUrl}>
                    <SVGIcon
                      name='share-link'
                      width='18px'
                      height='18px'
                      viewBox='0 0 18 18'
                      stroke='var(--text-secondary)'
                    />
                  </div>
                  <div className='icon-div' onClick={onClickRemoveUrl}>
                    <SVGIcon
                      name='delete-icon'
                      width='18'
                      height='18'
                      viewBox='0 0 18 18'
                      fill='var(--text-secondary)'
                    />
                  </div>
                </div>
              </BubbleMenu>
            </>
          ) : (
            <>
              <BubbleMenu
                editor={editor}
                tippyOptions={{
                  duration: 50,
                  onHide: () => {
                    dispatch(setIsBubbleMenuOpen(false));
                  },
                  onShow: () => {
                    dispatch(setIsBubbleMenuOpen(true));
                  }
                }}>
                <div className='bubble-menu'>
                  <button ref={buttonRef} onClick={toggleDropdown} className='heading-dropdown'>
                    {isDropdownOpen && (
                      <div ref={dropdownRef} className={`heading-dropdown-menu ${dropdownPosition}`}>
                        <button
                          onClick={() => {
                            editor.chain().focus().setHeading({ level: 1 }).run();
                            setDropdownOpen(false);
                          }}>
                          Huge
                        </button>
                        <button
                          onClick={() => {
                            editor.chain().focus().setHeading({ level: 2 }).run();
                            setDropdownOpen(false);
                          }}>
                          Large
                        </button>
                        <button
                          onClick={() => {
                            editor.chain().focus().setParagraph().run();
                            setDropdownOpen(false);
                          }}>
                          Normal
                        </button>
                        <button
                          onClick={() => {
                            editor.chain().focus().setHeading({ level: 4 }).run();
                            setDropdownOpen(false);
                          }}>
                          Small
                        </button>
                      </div>
                    )}
                    <SVGIcon name='heading-icon' width='18' height='18' viewBox='0 0 18 18' fill='none' />
                    <SVGIcon name='heading-drop-down-icon' width='10' height='10' viewBox='0 0 10 10' fill='none' />
                  </button>
                  <button onClick={() => editor.chain().focus().toggleBold().run()}>
                    <SVGIcon name='bold-icon' width='18' height='18' viewBox='0 0 18 18' fill='none' />
                  </button>
                  <button onClick={() => editor.chain().focus().toggleItalic().run()}>
                    <SVGIcon name='italic-icon' width='18' height='18' viewBox='0 0 18 18' fill='none' />
                  </button>
                  <button onClick={() => editor.chain().focus().toggleUnderline().run()}>
                    <SVGIcon name='underline-icon' width='18' height='18' viewBox='0 0 18 18' fill='none' />
                  </button>
                  <button onClick={() => editor.chain().focus().toggleStrike().run()}>
                    <SVGIcon name='strike-icon' width='18' height='18' viewBox='0 0 18 18' fill='none' />
                  </button>
                  <button onClick={setLink}>
                    <SVGIcon name='link-icon' width='18' height='18' viewBox='0 0 18 18' fill='none' />
                  </button>
                  <button onClick={() => editor.chain().focus().toggleBlockquote().run()}>
                    <SVGIcon name='blockquote-icon' width='18' height='18' viewBox='0 0 18 18' fill='none' />
                  </button>
                  <button onClick={() => editor.chain().focus().toggleCode().run()}>
                    <SVGIcon name='inline-code-icon' width='18' height='18' viewBox='0 0 18 18' />
                  </button>
                  <button onClick={() => editor.chain().focus().toggleCodeBlock().run()}>
                    <SVGIcon name='code-block-icon' width='18' height='18' viewBox='0 0 18 18' fill='none' />
                  </button>
                  <button onClick={() => editor.chain().focus().toggleBulletList().run()}>
                    <SVGIcon name='bullet-list-icon' width='18' height='18' viewBox='0 0 18 18' fill='none' />
                  </button>
                </div>
              </BubbleMenu>
            </>
          ))}
        {currentCell && <TableToolbar editor={editor} />}
        {setReplyMessage && <ReplyMessage replyMessage={replyMessage} setReplyMessage={setReplyMessage} />}
        <EditorContent editor={editor} />
      </TiptapMain>
    </>
  );
});

export default TiptapEditor;
