import styled from 'styled-components';
import { Typography } from '../../../global/theme';

const Group = styled.div`
  width: 100%;
  max-width: 662px;
  margin: 0 auto;
  margin-bottom: 24px;
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 0 12px;
  width: 100%;
  max-width: 662px;
  margin: 0 auto;
  align-items: center;
`;

const Personaltext = styled.div`
  ${Typography.heading_lg}
  color: var(--text-primary);
`;

const Box = styled.div`
  border-radius: 8px;
  border: 0.5px solid var(--border-primary);
  background: var(--background-primary);
`;

const Content = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const Label = styled.label`
  ${Typography.body_lg_medium}
  color: var(--text-primary);
`;
const Inputvalue = styled.input`
  ${Typography.body_lg}
  color: var(--text-secondary);
  background-color: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 6px;
  padding: 8px 12px;
  outline: none;
  :focus {
    border: 0.5px solid var(--brand-primary);
    z-index: 999;
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Socialicon = styled.div`
  display: flex;
  gap: 10px;
`;
const Icon = styled.div`
  cursor: pointer;
  width: 24px;
  height: 24px;
  .social-icon {
    fill: var(--text-secondary);
    path {
      fill: var(--text-white);
    }
  }
  .facebook-icon {
    :hover {
      fill: var(--brand-primary);
    }
  }
  .email-icon {
    :hover {
      fill: var(--brand-primary);
    }
  }
  .twitter-icon {
    :hover {
      fill: var(--brand-primary);
    }
  }
`;
const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const Total = styled.div`
  display: flex;
  gap: 16px;
  @media (max-width: 449px) {
    flex-wrap: wrap;
  }
`;
export { Group, Header, Personaltext, Box, Content, Form, Label, Inputvalue, Buttons, Socialicon, Icon, Bottom, Total };
