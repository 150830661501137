import { getUniqueId } from '../../../helpers/common';
import { First, Table, TableSection, Td, Th } from './styles';

interface Props {
  header: {
    title: string;
    columnWidth: number;
    contentWidth: number;
    alignPlace?: string;
  }[]; // Array of column names
  rowCount?: number; // Optional prop to specify the number of rows (default to 5)
}
export default function ReportLoadingState({ header, rowCount = 5 }: Props) {
  return (
    <TableSection>
      <Table>
        <thead>
          <tr>
            {header.map((column) => (
              <Th key={column?.title} alignPlace={column.alignPlace}>
                {column.title}
              </Th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: rowCount }).map((_) => (
            <tr key={getUniqueId()}>
              {header?.map((item) => (
                <Td key={item?.title} columnWidth={item?.columnWidth} alignPlace={item?.alignPlace}>
                  <First className='loading-animation' contentWidth={item?.contentWidth} />
                </Td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </TableSection>
  );
}
