import styled, { css } from 'styled-components';
import { Typography } from '../../../global/theme';

interface Props {
  direction?: 'top' | 'bottom';
  alignment?: 'left' | 'right' | 'center';
  dropdownTopOffset?: number | null; // Use the calculated top offset
  width?: number;
  buttonHeights?: number;
  isCustomcontextmenu?: boolean;
  isCalendarviewCard?: boolean;
  isdisabled?: boolean;
  isSnoozeDropdown?: boolean;
  isOutside?: boolean;
  top?: number | string;
  isReportDuration?: boolean;
  isCustomerList?: boolean;
  isCustomerListLastChild?: boolean;
  right?: number | string;
  isZindex?: boolean;
  isLabel?: boolean;
  isTaskDetailMilestone?: boolean;
  isSelectProjectDropdown?: boolean;
  showAddMember?: boolean;
  isGithubRepo?: boolean;
  isApplySmallWidth?: boolean;
  isInvoiceList?: boolean;
}

const DropdownMenu = styled.div<Props>`
  min-width: 120px;
  /* max-width: ${(props) => `${props.width}px`}; */
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  box-shadow: var(--shadow-modal);
  background: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  position: absolute;
  z-index: 2;
  border-radius: 8px;
  z-index: 999;
  /* Adjust position based on top or bottom */
  top: ${(props) =>
    props.direction === 'top' && props.dropdownTopOffset !== null
      ? ''
      : props.direction === 'bottom'
      ? `calc(${props.buttonHeights}px + 3px)`
      : ''};

  bottom: ${(props) =>
    props.direction === 'top'
      ? `calc(${props.buttonHeights}px + 3px)`
      : props.direction === 'bottom'
      ? ''
      : ''}; /* Align below the button */

  left: ${(props) => (props.alignment === 'left' ? '0' : props.alignment === 'right' ? 'auto' : '')};
  right: ${(props) => (props.alignment === 'right' ? '0' : props.alignment === 'left' ? 'auto' : '')};
  transform: ${(props) => (props.alignment === 'center' ? 'translateX(-50%)' : 'none')};

  .red-text {
    color: var(--accent-error);
  }
  ${(props: Props) =>
    props.isCustomcontextmenu &&
    css`
      display: contents;
    `}
  ${(props: Props) =>
    props.isGithubRepo &&
    css`
      width: 100%;
      overflow: auto;
      max-height: 200px;
      scrollbar-width: none;
    `}
  ${(props: Props) =>
    props.isCalendarviewCard &&
    css`
      left: auto;
      right: 0;
    `}
  ${(props: Props) =>
    props.isCustomerList &&
    css`
      left: auto;
      right: 12px;
      top: ${(props: Props) => (props.isCustomerListLastChild ? 'unset' : `calc(${props.buttonHeights}px + 22px)`)};
      bottom: ${(props: Props) => (props.isCustomerListLastChild ? `calc(${props.buttonHeights}px + 22px)` : 'unset')};
    `}
  ${(props: Props) =>
    props.isInvoiceList &&
    css`
      left: auto;
      top: auto;
      right: 10px;
      transform: translate(-50%, 0);
      bottom: ${(props: Props) => (props.isCustomerListLastChild ? `calc(${props.buttonHeights}px + 22px)` : 'unset')};
      @media (max-width: 425px) {
        right: 10%;
      }
      @media (max-width: 393px) {
        right: 15%;
      }
      @media (max-width: 375px) {
        right: 20%;
      }
    `}
  ${(props: Props) =>
    props.isdisabled &&
    css`
      display: none;
    `}
    ${(props: Props) =>
    props.isOutside &&
    css`
      right: ${props.right}px;
      left: auto;
      @media (max-width: 449px) {
        top: ${(props: Props) =>
          props.direction === 'top' && props.dropdownTopOffset !== null
            ? ''
            : props.direction === 'bottom'
            ? `calc(${props.buttonHeights}px + ${props.top}px)`
            : ''};

        bottom: ${(props) =>
          props.direction === 'top'
            ? `calc(${props.buttonHeights}px + ${props.top}px)`
            : props.direction === 'bottom'
            ? ''
            : ''};
      }
    `}
      ${(props: Props) =>
    props.isReportDuration &&
    css`
      margin-top: 3px;
    `}
    ${(props: Props) =>
    props.isApplySmallWidth &&
    css`
      @media (max-width: 449px) {
        width: 50%;
      }
    `}
    ${(props: Props) =>
    props.isSnoozeDropdown &&
    css`
      min-width: 220px;
      top: ${props.direction === 'bottom' && props.buttonHeights ? `calc(${props.buttonHeights}px + 6px)` : 'auto'};
      bottom: ${props.direction === 'top' && props.buttonHeights ? `calc(${props.buttonHeights}px + 6px)` : 'auto'};
    `}
        ${(props: Props) =>
    props.isZindex &&
    css`
      z-index: 1111;
    `}
   ${(props: Props) =>
    props.isSelectProjectDropdown &&
    css`
      margin-left: -4px;
    `}
    ${(props) =>
    props.showAddMember &&
    css`
      top: 45px;
      left: auto;
      right: 54px;
      border-radius: 10px;
      @media (max-width: 449px) {
        right: 42px;
      }
    `}
`;

const Menu = styled.div<Props>`
  ${(props) =>
    props.showAddMember &&
    css`
      padding: 4px;
    `}
  ${(props: Props) =>
    props.isSelectProjectDropdown &&
    css`
      padding: 6px 0;
      margin-right: 3px;
    `}
`;

const MenuList = styled.div<Props>`
  padding: 6px 0;
  margin: 0;
  cursor: pointer;

  ${(props: Props) =>
    props.isReportDuration &&
    css`
      padding: 5px 0;
      height: 196px;
      overflow: auto;
      scrollbar-width: none;
    `}
  ${(props: Props) =>
    props.isTaskDetailMilestone &&
    css`
      padding: 6px 0 0;
      /* height: 196px; */
      overflow: auto;
      scrollbar-width: none;
    `}
  ${(props: Props) =>
    props.isCustomcontextmenu &&
    css`
      width: ${(props: Props) => `${props.width}px`};
      box-shadow: var(--shadow-modal);
      background: var(--background-primary);
      border: 0.5px solid var(--border-primary);
      border-radius: 8px;
      padding: 5px 0;
      ${props.isLabel &&
      css`
        border: none;
        border-radius: 0 0 8px 8px;
      `}
    `}
  ${(props: Props) =>
    props.isLabel &&
    css`
      padding: 0;
    `}
  ${(props: Props) =>
    props.isSnoozeDropdown &&
    css`
      padding: 7px 0 0;
      display: flex;
      flex-direction: column;
      gap: 2px;
    `}
  ${(props: Props) =>
    props.isSelectProjectDropdown &&
    css`
      padding: 0;
      max-height: 152px;
      height: 100%;
      overflow-y: auto;
      ::-webkit-scrollbar {
        background-color: transparent;
        width: 4px;
      }
      ::-webkit-scrollbar-thumb {
        border-radius: 12px;
        background-color: var(--text-placeholder);
      }
    `}
  ${(props) =>
    props.showAddMember &&
    css`
      padding: 0;
      max-height: 144px;
      overflow: auto;
      ::-webkit-scrollbar {
        background-color: transparent;
        width: 4px;
      }
      ::-webkit-scrollbar-thumb {
        border-radius: 12px;
        background-color: var(--text-placeholder);
      }
    `}
`;

const Dropitem = styled.div<Props>`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 6px 12px;
  align-items: center;
  :hover {
    background: var(--neutral-secondary);
  }
  p {
    ${Typography.body_md}
    color: var(--text-primary);
    margin: 0;
    padding: 0 !important;
  }

  &.red-text {
    color: var(--accent-error);
  }
  ${(props: Props) =>
    props.isLabel &&
    css`
      padding: 0;
      &:last-child {
        border-radius: 0 0 8px 8px;
      }
    `}
  ${(props: Props) =>
    props.isSnoozeDropdown &&
    css`
      display: block;
      padding: 0;
      :hover {
        background-color: unset;
      }
      p {
        color: var(--text-secondary);
      }
    `}
  ${(props: Props) =>
    props.showAddMember &&
    css`
      padding: 4px 6px;
      :hover {
        border-radius: 6px;
      }
    `}
`;

const Icon = styled.div`
  width: 15px;
  height: 15px;
  .fill-color {
    fill: var(--text-secondary);
    path {
      fill: var(--text-secondary);
    }
  }
  .noicon {
    path {
      fill: var(--text-tertiary);
    }
  }
`;

const Dropheader = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  padding: 6px 14px 6px 10px;
  ${(props: Props) =>
    props.isSelectProjectDropdown &&
    css`
      padding: 8px 12px;
    `}
`;

const Inputarea = styled.input`
  max-width: 100%;
  width: 100px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  ${Typography.body_md}
  color: var(--text-primary);
  background-color: var(--background-primary);
  margin: 0;
  padding: 0;
  border: none;

  :focus {
    outline: none;
  }
  ::placeholder {
    color: var(--text-secondary);
  }
`;

const Textblock = styled.div`
  display: flex;
  background: var(--border-primary);
  border-radius: 4px;
  padding: 2px 4px;
  height: 12px;

  span {
    ${Typography.body_xs}
    color: var(--text-secondary);
    width: 8px;
    text-align: center;
  }
`;
const Addmilestone = styled.div`
  display: flex;
  gap: 4px;
  padding: 7px 12px 8px;
  align-items: center;
  border-top: 0.5px solid var(--border-primary);
  svg {
    path {
      stroke: var(--brand-primary);
    }
  }
`;

const Addoption = styled.div`
  color: var(--brand-primary);
  ${Typography.body_md_medium}
`;

// Add Member design
const ShowMember = styled.div`
  display: flex;
  gap: 10px;
  padding: 4px 6px;
  align-items: center;
  :hover {
    background-color: var(--neutral-secondary);
    border-radius: 6px;
  }
  .add-icon {
    path.stroke {
      stroke: var(--text-primary);
    }
    path {
      fill: var(--text-primary);
    }
  }
`;

const ShowAddMember = styled.div`
  border-top: 0.5px solid var(--border-primary);
  padding: 4px;
  cursor: pointer;
`;

const AddMember = styled.p`
  margin: 0;
  ${Typography.body_md}
  color: var(--text-primary);
`;

const ItemLabel = styled.span`
  ${Typography.body_md}
  color: var(--text-primary);
  margin: 0;
  padding: 0 !important;
`;

export {
  Menu,
  MenuList,
  Dropheader,
  Inputarea,
  Textblock,
  DropdownMenu,
  Dropitem,
  Icon,
  Addmilestone,
  Addoption,
  ShowAddMember,
  ShowMember,
  AddMember,
  ItemLabel
};
