import React from 'react';
import { Bottom, First, Left, Line, Main, Right, Top } from './styles';
interface Props {
  isMarginTop?: boolean;
  isMember?: boolean;
}
export default function TaskLoading(props: Props) {
  return (
    <div>
      <Main isMarginTop={props?.isMarginTop} isMember={props?.isMember}>
        <Top className='loading-animation'></Top>
        <Line></Line>
        <Bottom>
          <First>
            <Left className='loading-animation'></Left>
            <Right className='loading-animation'></Right>
          </First>
          <First>
            <Left className='loading-animation'></Left>
            <Right className='second loading-animation'></Right>
          </First>
          <First>
            <Left className='loading-animation'></Left>
            <Right className='third loading-animation'></Right>
          </First>
          <First>
            <Left className='loading-animation'></Left>
            <Right className='forth loading-animation'></Right>
          </First>
        </Bottom>

        <Top className='bottom-loading loading-animation'></Top>
        <Line className='second-line'></Line>
        <Bottom className='second-bottom'>
          <First>
            <Left className='loading-animation'></Left>
            <Right className='loading-animation'></Right>
          </First>
          <First>
            <Left className='loading-animation'></Left>
            <Right className='second loading-animation'></Right>
          </First>
          <First>
            <Left className='loading-animation'></Left>
            <Right className='third loading-animation'></Right>
          </First>
        </Bottom>
      </Main>
    </div>
  );
}
