/* eslint-disable max-len */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  ButtonComponent,
  ButtonLeft,
  ButtonRight,
  Description,
  FileBox,
  Form,
  Header,
  InputDiv,
  MainDiv,
  SvgDiv,
  Title,
  Uploaddiv
} from './style';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import { CommentFilesInterface, LinkObject } from '../../../interfaces/TaskInterface';
import TiptapEditor from '../../tiptapEditor';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import Button from '../../button';
import { setErrorMessage } from '../../../actions/messageActions';
import { captureException } from '../../../services/logService';
import { RenderFilePreview } from '../../renderFilePreview';
import {
  convertBase64ToFile,
  getFirebaseUrlFromFile,
  handleFileUpload,
  isEmpty,
  isMediaRecordingSupported,
  removeFileFromFirebase
} from '../../../helpers/common';
import ModalCustom from '../modal';
import SnapRecordModal from '../snapRecordModal';
import RecordingSnapAttach from '../../recording';
import InsertGifModal from '../insertGifModal';
import { sendFeedbackMessage } from '../../../services/helpCenterService';

interface Props {
  openSuccessfullModal: () => void;
  closeContactUsModal: () => void;
  onCancelSendFeedback?: () => void;
  disable?: boolean;
}

export const ContactUsModal: React.FC<Props> = (props) => {
  // props
  const { openSuccessfullModal, closeContactUsModal, disable = false } = props;
  // Refs
  const editorRef = useRef<any>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  // State
  const [message, setMessage] = useState<string>();
  const [uploadedFile, setUploadedFile] = useState<LinkObject[]>([]);
  const [loading, setLoading] = useState<boolean>();
  const [fileUploading, setFileUploading] = useState<boolean>(false);
  const [requiredHeight, setRequiredHeight] = useState(320);
  const [isSnapModalOpen, setIsSnapModalOpen] = useState(false);
  const [openGifModal, setIsOpenGifModal] = useState(false);
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { auth: userDetailsSelector, project: projectSelector } = stateSelector || {};
  const { uploadFilePercent } = projectSelector;
  const { userDetails } = userDetailsSelector;
  // Other variables
  const dispatch = useDispatch();

  // height calculate for input when file is uploaded
  const calculateHeight = useCallback(() => {
    let newHeight;
    if (uploadedFile.length === 0) {
      newHeight = 320;
    } else if (uploadedFile.length <= 2) {
      newHeight = 249;
    } else if (uploadedFile.length < 5) {
      newHeight = 194;
    } else {
      newHeight = 137;
    }
    setRequiredHeight(newHeight);
  }, [uploadedFile?.length]);

  useEffect(() => {
    calculateHeight();
  }, [calculateHeight]);

  const onClickSendMessage = useCallback(
    async (e: React.SyntheticEvent) => {
      e.preventDefault();
      setLoading(true);
      try {
        const attachment = uploadedFile?.map((file) => ({
          filename: file?.name,
          url: file?.href
        }));
        let commentData = message || '';
        const pattern = /<p><span>​<\/span><\/p>|<p><br><\/p>|<p><br><br><\/p>/g;
        if (!isEmpty(message)) {
          while (pattern.test(commentData)) {
            commentData = commentData.replace(pattern, '');
          }
          if (commentData.match(/<br\s*>\s*<\/p>$/)) {
            commentData = commentData.replace(/<br\s*>\s*<\/p>$/, '</p>');
          }
        }

        const tempElement = document.createElement('div');
        tempElement.innerHTML = commentData || '';
        const linkElements = Array.from(tempElement.querySelectorAll('img'));

        for (const linkElement of linkElements) {
          const srclink = linkElement?.getAttribute('src');
          if (
            !isEmpty(srclink) &&
            !srclink?.startsWith('https://firebasestorage') &&
            !srclink?.startsWith('https://figma') &&
            !srclink?.startsWith('/static') &&
            !srclink?.startsWith('https://')
          ) {
            const fileData = await convertBase64ToFile(srclink);
            const fileUrl = await getFirebaseUrlFromFile({ file: fileData }, 'files/', dispatch);
            if (fileUrl) {
              commentData = commentData?.replace(srclink || '', fileUrl);
            }
          }
        }

        const payload = {
          senderName: userDetails?.name,
          senderEmail: userDetails?.email,
          message: commentData,
          attachments: attachment
        };

        const result = await dispatch(sendFeedbackMessage(payload));
        if (result) {
          closeContactUsModal();
          openSuccessfullModal();
        }
      } catch (error: any) {
        console.error('Error sending feedback message:', error);
      } finally {
        setLoading(false);
      }
    },
    [closeContactUsModal, dispatch, message, openSuccessfullModal, uploadedFile, userDetails?.email, userDetails?.name]
  );

  const handleFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onFileUpload = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target;
      if (!files || files?.length === 0) {
        dispatch(setErrorMessage('No files selected.'));
        return;
      }
      try {
        setFileUploading(true);
        const fileArray = Array.from(files); // Convert FileList to Array
        //Call common function to upload file in firebase
        const updatedFiles = await handleFileUpload(uploadedFile || [], fileArray, dispatch, editorRef, true);
        const value = [...(uploadedFile || []), ...(updatedFiles || [])];
        setUploadedFile(value);
      } catch (error) {
        captureException(error);
        dispatch(setErrorMessage('Error occurred while uploading files.'));
      } finally {
        setFileUploading(false);
      }
    },
    [dispatch, uploadedFile]
  );

  const onClickClose = useCallback(
    (index: number, file: LinkObject) => {
      if (file && file.href.startsWith('https://firebasestorage.googleapis'))
        removeFileFromFirebase(file.href, dispatch);
      const filesClone = JSON.parse(JSON.stringify(uploadedFile));
      filesClone.splice(index, 1);
      setUploadedFile(filesClone);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    },
    [dispatch, uploadedFile]
  );

  const addDroppedFiles = useCallback((files: CommentFilesInterface[]) => {
    setUploadedFile((prevFiles: CommentFilesInterface[]) => [...(prevFiles || []), ...(files || [])]);
  }, []);

  const handleOpenSnapModal = useCallback(() => {
    setIsSnapModalOpen(true);
  }, []);

  const handleCloseSnapModal = useCallback(() => {
    setIsSnapModalOpen(false);
  }, []);

  const uploadSnapEmbed = useCallback((firebaseUrl: string, fileName: string, _videoId: string) => {
    const fileObj = {
      name: fileName,
      href: firebaseUrl,
      size: '0',
      file_type: 'mp4'
    };
    setUploadedFile((prevFiles: CommentFilesInterface[]) => [...(prevFiles || []), fileObj]);
  }, []);

  const handleOpenGifModal = useCallback(() => {
    setIsOpenGifModal(true);
  }, []);

  const handleCloseGifModal = useCallback(() => {
    setIsOpenGifModal(false);
  }, []);

  return (
    <MainDiv>
      <Header>
        <Title>Contact us</Title>
        <Description>You can also email us at support@teamcamp.app</Description>
      </Header>
      <Form maxHeight={requiredHeight}>
        <InputDiv>
          <TiptapEditor
            ref={editorRef}
            maxHeight={requiredHeight}
            addUploadedFiles={(value: any) => addDroppedFiles(value)}
            setLoading={setLoading}
            setStateLoading={setFileUploading}
            setValueContent={(value: string) => setMessage(value)}
            valueContent=''
            placeHolder='How can we help you?'
            recordSnapModalOpen={handleOpenSnapModal}
            handleFileInput={handleFileInput}
            handleGifModal={handleOpenGifModal}
          />
          {isMediaRecordingSupported() && (
            <RecordingSnapAttach setLoading={setLoading} uploadSnapEmbed={uploadSnapEmbed} />
          )}
          <input type='file' ref={fileInputRef} style={{ display: 'none' }} onChange={onFileUpload} multiple />
          {/* make upload file working  */}
          {!disable && uploadedFile?.length > 0 && (
            <FileBox>
              {uploadedFile?.length > 0 &&
                uploadedFile?.map((file: LinkObject, index: number) => {
                  return (
                    <>
                      <RenderFilePreview
                        file={file}
                        isClose={true}
                        maxWidth={227}
                        onClose={() => onClickClose(index, file)}
                      />
                    </>
                  );
                })}
            </FileBox>
          )}
        </InputDiv>
      </Form>
      <ButtonComponent>
        <ButtonLeft>
          <SvgDiv
            onClick={() => {
              handleFileInput();
            }}>
            <SVGIcon
              name='message-attachment-icon'
              width='32'
              height='32'
              viewBox='0 0 32 32'
              className='attachment-fill-Color'
            />
          </SvgDiv>
          {fileUploading && <Uploaddiv>Uploading ({Math.floor(uploadFilePercent)}%)</Uploaddiv>}
        </ButtonLeft>
        <ButtonRight>
          <Button title='Cancel' secondary modelbtn hasNotBoxshadow onClick={closeContactUsModal} />
          <Button title='Send message' modelbtn onClick={onClickSendMessage} isLoading={loading} />
        </ButtonRight>
      </ButtonComponent>
      {isMediaRecordingSupported() && (
        <ModalCustom open={isSnapModalOpen} onClose={handleCloseSnapModal} width={258}>
          <SnapRecordModal handleCloseModal={handleCloseSnapModal} />
        </ModalCustom>
      )}
      <ModalCustom
        open={openGifModal}
        onClose={handleCloseGifModal}
        width={686}
        hasNotBoxShadow={true}
        isGifModal={true}>
        <InsertGifModal
          setSelectedGif={(url: string) => {
            editorRef.current.addImage(url);
          }}
          closeModal={handleCloseGifModal}
        />
      </ModalCustom>
    </MainDiv>
  );
};
