import { clearResponseMessage, setErrorMessage, setSuccessMessage } from '../actions/messageActions';
import { getAPIErrorReason, isEmpty } from '../helpers/common';
import UserPreferenceSingleton from '../helpers/userPreferenceSingleton';
import { AnyAction, Dispatch } from 'redux';
import {
  setChatGroups,
  setChatMessageLoader,
  setPinChatGroups,
  setUnpinChatGroups
} from '../actions/chatMessageActions';
import axios from 'axios';
import { REACT_APP_TEAMCAMP_APIURL } from '../global/environment';
import ChatMessageCacheService from './chatMessageCacheService';
import moment from 'moment';
import { SendMessageDetailsInterface, UsersChatGroupInterface } from '../interfaces/chatMessageInterface';
import { captureException } from './logService';
import MessageGroupCacheService from './messageGroupCacheService';
import { RootReducerInterface } from '../interfaces/RootReducerInterface';
import { setSearchedGif } from '../actions/appActions';
/**
 * @desc Customer - Get Chat message group list
 * @param {*}
 */
export const getChatGroupList: any =
  () => async (dispatch: Dispatch<AnyAction>, getState: () => RootReducerInterface) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setChatMessageLoader(true));
      const state = getState();
      const { workspace } = state.workspace;
      const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();

      const params = {
        companyId: workspace?.id,
        userId: userDetails?.id
      };
      const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/discussions/getMessageGroup`, { params });
      if (response?.data) {
        await MessageGroupCacheService.getInstance().setMessageGroups(response?.data);
        return response?.data;
      }
      return undefined;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to get message groups please try again';
      captureException(error);
      dispatchChatMessageError(error, dispatch);
      return undefined;
    } finally {
      dispatch(setChatMessageLoader(false));
    }
  };

/**
 * @desc Customer - Get Chat messages
 * @param {*}
 */
export const getAllMessages: any =
  () => async (dispatch: Dispatch<AnyAction>, getState: () => RootReducerInterface) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setChatMessageLoader(true));
      const state = getState();
      const { workspace } = state.workspace;
      const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
      const workspace_id = workspace?.id;
      let lastUpdatedTime = await ChatMessageCacheService.getInstance()?.getLastUpdatedTime(workspace_id);
      const params = {
        companyId: workspace_id,
        userId: userDetails?.id,
        lastUpdatedTime: lastUpdatedTime
      };
      const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/discussions/getLastUpdatedMessages`, { params });
      const { data } = response || {};
      if (data?.length > 0) {
        let nonArchivedData = data?.filter((x: { isArchived: boolean }) => !x.isArchived);
        let archivedData = data
          ?.filter((x: { isArchived: boolean }) => x.isArchived)
          ?.map((x: { id: string }) => x?.id);
        if (lastUpdatedTime === 0 || isEmpty(lastUpdatedTime)) {
          await ChatMessageCacheService.getInstance()?.setMessages(nonArchivedData, workspace_id);
        } else {
          await ChatMessageCacheService.getInstance()?.updateMessages(nonArchivedData, archivedData, workspace_id);
        }
      }
      const adjustedTime = moment().subtract(1, 'hours').toISOString();
      await ChatMessageCacheService.getInstance()?.setLastUpdatedTime(adjustedTime, workspace_id);
      return true;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to get messages please try again';
      captureException(error);
      dispatchChatMessageError(error, dispatch);
      return undefined;
    } finally {
      dispatch(setChatMessageLoader(false));
    }
  };

/**
 * @desc Group - create message group
 * @param {*}
 */
export const createMessageGroup: any = (payload: UsersChatGroupInterface) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setChatMessageLoader(true));
    const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/discussions/createMessageGroup`, payload);
    const { data } = response;
    if (data) {
      return data;
    }
    return undefined;
  } catch (e) {
    captureException(e);
    dispatchChatMessageError(getAPIErrorReason(e) || 'Unable to create group please try again', dispatch);
    return undefined;
  } finally {
    dispatch(setChatMessageLoader(false));
  }
};

/**
/** 
* @desc Group - update message group
 * @param {*}
 */
export const updateMessageGroup: any =
  (groupId: string, payload: UsersChatGroupInterface) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setChatMessageLoader(true));
      const response = await axios.post(
        `${REACT_APP_TEAMCAMP_APIURL}/discussions/updateMessageGroup/${groupId}`,
        payload
      );
      const { data } = response;
      if (data) {
        dispatchChatMessageSucess('Group name updated successfully!', dispatch);
        return data;
      }
      return undefined;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to update group please try again';
      captureException(error);
      dispatchChatMessageError(error, dispatch);
      return undefined;
    } finally {
      dispatch(setChatMessageLoader(false));
    }
  };

/**
 * @desc Message - update discussion messgae
 * @param {*}
 */
export const updateDiscussionMessageDetail: any =
  (Message_Id: string, payload: boolean) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setChatMessageLoader(true));

      const response = await axios.put(
        `${REACT_APP_TEAMCAMP_APIURL}/discussions/updateDiscussionData/${Message_Id}`,
        payload
      );
      if (response) {
        return response;
      }
      return undefined;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to update message please try again';
      captureException(error);
      dispatchChatMessageError(error, dispatch);
      return undefined;
    } finally {
      dispatch(setChatMessageLoader(false));
    }
  };

/**
 * @desc Message - send discussion messgae
 * @param {*}
 */
export const sendDiscussionMessage: any =
  (payload: SendMessageDetailsInterface) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setChatMessageLoader(true));
      const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/discussions/addDiscussionData`, payload);
      const { data } = response;
      if (data) {
        return data;
      }
      return undefined;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to send message please try again';
      captureException(error);
      dispatchChatMessageError(error, dispatch);
      return undefined;
    } finally {
      dispatch(setChatMessageLoader(false));
    }
  };

/**
 * @desc Group - add members message group
 * @param {*}
 */
export const addMembersInMessageGroup: any =
  (groupId: string, payload: UsersChatGroupInterface) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setChatMessageLoader(true));
      const response = await axios.post(
        `${REACT_APP_TEAMCAMP_APIURL}/discussions/addMemberInGroup/${groupId}`,
        payload
      );
      const { data } = response;
      if (data) {
        dispatchChatMessageSucess(data.message, dispatch);
        return data;
      }
      return undefined;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to add member in group please try again';
      captureException(error);
      dispatchChatMessageError(error, dispatch);
      return undefined;
    } finally {
      dispatch(setChatMessageLoader(false));
    }
  };

/**
 * @desc Group - add members message group
 * @param {*}
 */
export const removeMemberFromMessageGroup: any =
  (groupId: string, userId: string, removeUserId: string) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setChatMessageLoader(true));
      const response = await axios.delete(`${REACT_APP_TEAMCAMP_APIURL}/discussions/removeGroupMember/${groupId}`, {
        params: { userId, removeUserId }
      });
      const { data } = response;
      if (data) {
        dispatchChatMessageSucess(data.message, dispatch);
        return true;
      }
      return false;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to remove member from message group';
      captureException(error);
      dispatchChatMessageError(error, dispatch);
      return false;
    } finally {
      dispatch(setChatMessageLoader(false));
    }
  };

/**
 * @desc Message - delete group message
 * @param {*}
 */
export const deleteGroupMessage: any = (type: number, groupId: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setChatMessageLoader(true));
    const response = await axios.put(
      `${REACT_APP_TEAMCAMP_APIURL}/discussions/deleteMessageGroup/${groupId}?Type=${type}`
    );
    if (response) {
      return true;
    }
    return false;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to archive message please try again';
    captureException(error);
    dispatchChatMessageError(error, dispatch);
    return false;
  } finally {
    dispatch(setChatMessageLoader(false));
  }
};

/*
 * @desc pin massage group
 * @param {*}
 */
export const pinMessageGroup: any = (message_group_id: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(setChatMessageLoader(true));
    if (!isEmpty(message_group_id)) {
      const order = new Date().getTime();
      const response = await axios.post(
        `${REACT_APP_TEAMCAMP_APIURL}/discussions/pinMessageGroup/${message_group_id}`,
        {
          order
        }
      );
      const { data } = response;
      if (data) {
        return data;
      }
    } else {
      dispatch(setErrorMessage('Message group id is empty'));
      return;
    }
    return undefined;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to pin group please try again';
    captureException(error);
    dispatchChatMessageError(error, dispatch);
    return undefined;
  } finally {
    dispatch(setChatMessageLoader(false));
  }
};

/**
 * @desc unpin massage group
 * @param {*}
 */
export const unPinMessageGroup: any = (message_group_id: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(setChatMessageLoader(true));
    if (!isEmpty(message_group_id)) {
      const response = await axios.delete(
        `${REACT_APP_TEAMCAMP_APIURL}/discussions/unpinMessageGroup/${message_group_id}`
      );
      if (response) {
        return true;
      }
    } else {
      dispatch(setErrorMessage('Message group id is empty'));
      return;
    }
    return undefined;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to unpin group please try again';
    captureException(error);
    dispatchChatMessageError(error, dispatch);
    return undefined;
  } finally {
    dispatch(setChatMessageLoader(false));
  }
};

/**
 * @desc Message - update discussion messgae
 * @param {*}
 */
export const updateChatGroupOrder: any =
  (Message_Id: string, payload: boolean) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setChatMessageLoader(true));

      const response = await axios.put(
        `${REACT_APP_TEAMCAMP_APIURL}/discussions/updatePinnedMessageGroup/${Message_Id}`,
        payload
      );
      if (response) {
        return response;
      }
      return undefined;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to update chat group please try again';
      captureException(error);
      dispatchChatMessageError(error, dispatch);
      return undefined;
    } finally {
      dispatch(setChatMessageLoader(false));
    }
  };

export const getSearchedGIF: any = (searchedText: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setChatMessageLoader(true));

    const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/giphy?text=${searchedText}`);
    if (response) {
      dispatch(setSearchedGif(response?.data));
      return true;
    }
    return false;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to search GIF, please try again later';
    captureException(error);
    dispatchChatMessageError(error, dispatch);
    return false;
  } finally {
    dispatch(setChatMessageLoader(false));
  }
};

/**
 * @desc Message - set last read time for message group
 * @param {*}
 */
export const setLastReadForDiscussion: any =
  () => async (dispatch: Dispatch<AnyAction>, getState: () => RootReducerInterface) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setChatMessageLoader(true));
      const state = getState();
      const { currentChatGroup, chatGroups, pinChatGroups, unpinChatGroups } = state?.chatMessage || {};
      if (currentChatGroup?.id) {
        if (currentChatGroup?.hasUnreadMessages) {
          const updatedChatGroups = chatGroups?.map((group) =>
            group?.id === currentChatGroup?.id ? { ...group, hasUnreadMessages: false } : group
          );
          const updatedPinnedChatGroups = pinChatGroups?.map((group) =>
            group?.id === currentChatGroup?.id ? { ...group, hasUnreadMessages: false } : group
          );
          const updatedUnpinnedChatGroups = unpinChatGroups?.map((group) =>
            group?.id === currentChatGroup?.id ? { ...group, hasUnreadMessages: false } : group
          );
          dispatch(setChatGroups(updatedChatGroups));
          dispatch(setPinChatGroups(updatedPinnedChatGroups));
          dispatch(setUnpinChatGroups(updatedUnpinnedChatGroups));
        }
        const payload = {
          type: currentChatGroup?.type,
          time_get_from_payload:
            currentChatGroup?.messages?.length > 0
              ? currentChatGroup?.messages[currentChatGroup?.messages.length - 1].createdOn
              : null
        };
        const response = await axios.put(`${REACT_APP_TEAMCAMP_APIURL}/discussions/${currentChatGroup?.id}`, payload);
        if (response) {
          return true;
        }
        return false;
      }
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to set last read for message group please try again';
      captureException(error);
      dispatchChatMessageError(error, dispatch);
      return undefined;
    } finally {
      dispatch(setChatMessageLoader(false));
    }
  };

function dispatchChatMessageError(msg: string, dispatch: Dispatch<AnyAction>) {
  dispatch(setErrorMessage(msg));
}

function dispatchChatMessageSucess(msg: string, dispatch: Dispatch<AnyAction>) {
  dispatch(setSuccessMessage(msg));
}
