import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { ModalOverlay, ModalContent } from './styles';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from '../../../helpers/common';
import Deletemodal from '../deleteModel';
import { clearCreateTaskInput, updateCreateTaskInput } from '../../../actions/taskActions';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';

interface Props {
  children?: React.ReactNode;
  open?: any;
  onClose?: any;
  isTracker?: boolean;
  width?: number;
  isCreateTask?: boolean;
  isZindex?: boolean;
  isMaxWidth?: boolean;
  isFreePlanModel?: boolean;
  isSubtaskpopup?: boolean;
  isHelpSupportModal?: boolean;
  hasNotBoxShadow?: boolean;
  isGifModal?: boolean;
  isContextMenu?: boolean;
}

const defaultProps = {
  position: 'center'
};

const ModalCustom = ({
  children,
  open,
  onClose,
  isCreateTask,
  hasNotBoxShadow,
  isGifModal,
  isContextMenu,
  ...props
}: Props & typeof defaultProps) => {
  //States
  const [closeModel, setCloseModel] = useState(false);
  //use selector state variables
  const taskSelector = useSelector((state: RootReducerInterface) => state.task);
  const { createTaskInput } = taskSelector;
  //Other variable
  const dispatch = useDispatch();

  const isClose = () => {
    setCloseModel(false);
  };

  const close = useCallback(() => {
    if (isCreateTask) {
      if (
        !isEmpty(createTaskInput?.name) ||
        !isEmpty(createTaskInput?.description) ||
        !isEmpty(createTaskInput.Files)
      ) {
        setCloseModel(true);
      } else {
        onClose();
      }
    } else {
      onClose();
    }
  }, [createTaskInput.Files, createTaskInput?.description, createTaskInput?.name, isCreateTask, onClose]);

  const onOnkDeleteModel = () => {
    setCloseModel(false);
    dispatch(clearCreateTaskInput());
    dispatch(updateCreateTaskInput({ propsName: 'uploadedFiles', value: [] }));
    onClose();
  };

  return (
    <>
      {open && (
        <>
          <ModalOverlay isOpen={open} onClick={close} isZindex={props?.isZindex}></ModalOverlay>

          <ModalContent
            isWidth={props?.width}
            isZindex={props?.isZindex}
            isTracker={props?.isTracker}
            isMaxWidth={props?.isMaxWidth}
            isFreePlanModel={props?.isFreePlanModel}
            isSubtaskpopup={props?.isSubtaskpopup}
            isHelpSupportModal={props?.isHelpSupportModal}
            hasNotBoxShadow={hasNotBoxShadow}
            isGifModal={isGifModal}
            isContextMenu={isContextMenu}>
            {children}
          </ModalContent>

          <ModalCustom open={closeModel} onClose={isClose} width={334}>
            <Deletemodal
              isYes={true}
              onClose={isClose}
              onOk={onOnkDeleteModel}
              modaltitle='Confirmation Model'
              description='Are you sure you want close this model?'
            />
          </ModalCustom>
        </>
      )}
    </>
  );
};

export default ModalCustom;

ModalCustom.propTypes = {
  position: PropTypes.oneOf(['center', 'right'])
};

ModalCustom.defaultProps = {
  open: false,
  position: 'center'
};
