import { combineReducers } from 'redux';
import { createReducer } from '../helpers/reduxHelpers';
import * as Actions from '../actions/types';

const setAppLoaderReducer = createReducer({
  initialState: false,
  actionType: Actions.SET_APP_LOADER
});

const screenRecordingLoadingReducer = createReducer({
  initialState: false,
  actionType: Actions.SET_SCREEN_RECORDING_LOADER
});

const screenRecordingUrlReducer = createReducer({
  initialState: '',
  actionType: Actions.SET_SCREEN_RECORDING_URL
});

const customBrandingReducer = createReducer({
  initialState: {},
  actionType: Actions.SET_CUSTOM_BRANDING
});

const responsiveVercelModalReducer = createReducer({
  initialState: false,
  actionType: Actions.SET_VERCEL_MODAL_OPEN
});

const setSearchedGif = createReducer({
  initialState: [],
  actionType: Actions.SET_SEARCHED_GIF
});

const isNavbarShowReducer = createReducer({
  initialState: true,
  actionType: Actions.SET_IS_NAVBAR_SHOW
});

const isBubbleMenuOpenReducer = createReducer({
  initialState: false,
  actionType: Actions.SET_IS_BUBBLE_MENU_OPEN
});

const appInitLoadingReducer = createReducer({
  initialState: false,
  actionType: Actions.SET_APP_INIT_LOADING
});

const userModalDetailReducer = createReducer({
  initialState: false,
  actionType: Actions.SET_USER_MODAL_DETAIL
});

const appReducer = combineReducers({
  isAppLoading: setAppLoaderReducer,
  screenRecordingLoading: screenRecordingLoadingReducer,
  screenRecordingUrl: screenRecordingUrlReducer,
  customBranding: customBrandingReducer,
  responsiveVercelModal: responsiveVercelModalReducer,
  searchedGif: setSearchedGif,
  isNavbarShow: isNavbarShowReducer,
  isBubbleMenuOpen: isBubbleMenuOpenReducer,
  appInitLoading: appInitLoadingReducer,
  userModalDetail: userModalDetailReducer
});

export default appReducer;
