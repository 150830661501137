import styled, { css } from 'styled-components';
import { Typography } from '../../../global/theme';
interface Props {
  iswidth?: boolean;
  ieTextdot?: boolean;
  isCreateTask?: boolean;
  isCustomcontextmenu?: boolean;
  isRemovepadding?: boolean;
  isStatusIcon?: boolean;
  isResponsiveStatusIcon?: boolean;
  isHoverIcon?: boolean;
}
const GroupPopupbox = styled.div`
  ${(props: Props) =>
    props.isCustomcontextmenu &&
    css`
      .ant-menu-vertical .ant-menu-item {
        margin: 0;
        line-height: unset;
        height: unset;
        width: 100%;
        border-radius: 0;
      }
      .ant-menu-light.ant-menu-root.ant-menu-vertical {
        border-inline-end: none;
        padding: 5px 0;
      }
      .ant-menu-light {
        background: var(--background-primary);
      }
    `}
  .svg-icon {
    path {
      stroke: var(--text-secondary);
    }
  }
  .cancelled-icon {
    path {
      fill: var(--text-tertiary);
    }
  }
  .task-todo-icon {
    path {
      fill: var(--background-primary);
      stroke: var(--text-secondary);
    }
  }
  .task-inprogress-icon {
    path.first-path {
      fill: var(--background-primary);
    }
  }
  .task-inreview-icon {
    path.first-path {
      fill: var(--background-primary);
    }
  }
  .task-status-icon {
    path.fill1 {
      fill: var(--background-primary);
    }
    path.fill2 {
      fill: var(--text-secondary);
    }
    path.fill3 {
      fill: var(--background-primary);
    }
  }
  .task-completed-icon {
    path {
      fill: var(--text-tertiary);
    }
  }
`;

const RightViewer = styled.div`
  /* pointer-events: auto; */
  /* position: relative; */
`;
const RightText = styled.div<Props>`
  ${Typography.body_md_medium}
  color: var(--text-primary);
  margin: 0;
  white-space: nowrap;
  h6 {
    ${Typography.body_md_medium}
    color: var(--text-secondary);
    margin: 0;
  }
  ${(props: Props) =>
    props.ieTextdot &&
    css`
      width: 115px;
      overflow: hidden;
      text-overflow: ellipsis;
      :active {
        color: var(--text-primary);
      }
    `}
  &:hover {
    color: var(--text-primary);
  }
`;
const TaskStatusName = styled.div`
  text-overflow: ellipsis;
  width: 99px;
  overflow: hidden;
  white-space: nowrap;
  ${Typography.body_md}
  color: var(--text-primary);
  margin: 0;
`;

const Div = styled.div<Props>`
  width: 18px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props: Props) =>
    props.isStatusIcon &&
    css`
      width: 20px;
      height: 20px;
      padding-top: 2px;
      svg {
        width: 20px;
        height: 20px;
      }
    `}
  @media (max-width: 449px) {
    width: 16px;
    height: 16px;
  }
  ${(props: Props) =>
    props.isResponsiveStatusIcon &&
    css`
      @media (max-width: 449px) {
        svg {
          width: 18px;
          height: 18px;
        }
      }
    `}
`;

const IconWithTitle = styled.div<Props>`
  display: flex;
  gap: 10px;
  ${(props: Props) =>
    props.isCreateTask &&
    css`
      display: flex;
      align-items: center;
      gap: 6px;
    `}
`;
const ButtonIcon = styled.div<Props>`
  ${(props: Props) =>
    props.isHoverIcon &&
    css`
      padding: 4px;
      border-radius: 12px;
      :hover {
        background-color: var(--neutral-secondary);
        svg {
          filter: brightness(var(--brightness-value));
          .transparent-fill {
            fill: none;
          }
        }
      }
    `}
`;
export { GroupPopupbox, RightViewer, RightText, TaskStatusName, Div, IconWithTitle, ButtonIcon };
