import styled from 'styled-components';
import { Typography } from '../../../global/theme';

const Integrationgroup = styled.div`
  width: 100%;
  max-width: 662px;
  margin: 0 auto;
  margin-bottom: 24px;
`;

const Main = styled.div`
  padding: 20px 0 12px;
  margin: 0 auto;
`;

const Header = styled.div`
  ${Typography.heading_lg}
  color: var(--text-primary);
`;

const Content = styled.div`
  border-radius: 8px;
  border: 0.5px solid var(--border-primary);
  background: var(--background-primary);
  max-width: 662px;
  width: 100%;
`;

export { Integrationgroup, Header, Main, Content };
