import React, { useCallback, useEffect, useState } from 'react';
import AppLayout from '../../../../../component/appLayout';
import MembersNavbar from '../navBar';
import { useDispatch, useSelector } from 'react-redux';
import { memberTaskData } from '../../../../../services/taskServices';
import { useParams } from 'react-router-dom';
import TasksData from '../../../../../component/tasks';
import { captureException } from '../../../../../services/logService';
import { MY_TASKS_GROUP_FILTER_TYPE } from '../../../../../global/constants';
import { getUserPreferenceFieldData, updateUserPreference } from '../../../../../helpers/firebaseHelper';
import { isEmpty } from '../../../../../helpers/common';
import { RootReducerInterface } from '../../../../../interfaces/RootReducerInterface';
import { Div } from '../overview/styles';
import { clearMemberTaskData } from '../../../../../actions/taskActions';

const MembersTaskDetails: React.FC = () => {
  // States
  const [loading, setLoading] = useState(false);

  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { task: taskSelector, workspace: workspaceSelector } = stateSelector || {};
  const { membersTask } = taskSelector;
  const { workspace } = workspaceSelector;

  // Other variables
  const dispatch = useDispatch();
  const params: { id: string } = useParams();

  // load initial data
  const loadData = useCallback(async () => {
    try {
      if (!isEmpty(workspace?.id)) {
        setLoading(true);
        const userFilter = await getUserPreferenceFieldData('my_task_filter');
        if (
          !userFilter?.group_by &&
          userFilter?.group_by !== MY_TASKS_GROUP_FILTER_TYPE.GROUP &&
          userFilter?.group_by !== MY_TASKS_GROUP_FILTER_TYPE.NONE &&
          userFilter?.group_by !== MY_TASKS_GROUP_FILTER_TYPE.PRIORITY &&
          userFilter?.group_by !== MY_TASKS_GROUP_FILTER_TYPE.PROJECT &&
          userFilter?.my_task_filter?.group_by !== MY_TASKS_GROUP_FILTER_TYPE.DUE_DATE
        ) {
          await updateUserPreference({ my_task_filter: { group_by: MY_TASKS_GROUP_FILTER_TYPE.DUE_DATE } });
        }
        await dispatch(memberTaskData(params?.id, userFilter?.group_by ?? MY_TASKS_GROUP_FILTER_TYPE.DUE_DATE));
      }
    } catch (e) {
      captureException(e);
      console.log('error', e);
    } finally {
      setLoading(false);
    }
  }, [workspace?.id, dispatch, params?.id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    return () => {
      dispatch(clearMemberTaskData());
    };
  }, [dispatch]);

  return (
    <>
      <AppLayout isPadding={true}>
        <MembersNavbar loadData={loadData} membersTask={membersTask} />
        <Div>
          <TasksData
            data={membersTask}
            loading={loading}
            isUserTask={true}
            loadData={loadData}
            isMember={true}
            memberId={params?.id}
          />
        </Div>
      </AppLayout>
    </>
  );
};

export default MembersTaskDetails;
