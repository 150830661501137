import { makeAutoObservable } from 'mobx';
import { createObservableUser, UserModel } from './user';
import { InvitedUser } from '../interfaces/user';
import { RawWorkspace } from '../interfaces/workspace';

export class WorkspaceModel {
  // Common
  id: string;
  name: string;
  email?: string | null;
  isAdmin: boolean;
  isOwner: boolean;

  // Relational
  invitedUsers: InvitedUser[];
  users: UserModel[];
  customerId?: string | null;
  customerRole?: number | null;

  // Others
  industry: string;
  teamType: number;
  phone?: string | null;
  logoUrl?: string | null;

  constructor(rawWorkspace: RawWorkspace) {
    makeAutoObservable(this);

    this.id = rawWorkspace.id;
    this.name = rawWorkspace.name;
    this.email = rawWorkspace.email;
    this.isAdmin = rawWorkspace.isAdmin;
    this.isOwner = rawWorkspace.isOwner;
    this.invitedUsers = rawWorkspace.invitedUsers;
    this.users = rawWorkspace.users.map((user) => createObservableUser(user));
    this.customerId = rawWorkspace.customerId;
    this.customerRole = rawWorkspace.customerRole;
    this.industry = rawWorkspace.industry;
    this.teamType = rawWorkspace.teamType;
    this.phone = rawWorkspace.phone;
    this.logoUrl = rawWorkspace.logoUrl;
  }
}

export const createObservableWorkspace = (rawWorkspace: RawWorkspace) => {
  return new WorkspaceModel(rawWorkspace);
};
