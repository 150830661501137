import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import AppLayout from '../../../component/appLayout';
import {
  Group,
  Headerblock,
  Heading,
  Icon,
  Title,
  Rightheading,
  Icons,
  Icon1,
  Content,
  Leftcontent,
  Maindiv,
  Invoicetable,
  Topinvoice,
  Invoiceright,
  Invoicemiddle,
  Invoiceleft,
  Details,
  Inputid,
  Text,
  Info,
  Fromaddress,
  From,
  Address,
  Invoicetitle,
  Gheader,
  Rightheader,
  Quantity,
  Amount,
  Label,
  Price,
  Row,
  Rightrow,
  Leftrow,
  Inforate,
  Total,
  Subtotal,
  Name,
  Totalamount,
  Totalcontent,
  Totalrate,
  Notediv,
  Recorddetail,
  Amountrate,
  Quantityrate,
  Info1,
  InvoiceResponsive,
  SvgIconDiv,
  InvoiceCard,
  TopcontentTotal,
  TableDataResponsive,
  InvoiceTableTitle,
  HeaderLeft,
  ResponsiveNavbarIcon,
  Recorddropdown,
  InputDiv,
  TextArea
} from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteInvoice,
  getInvoiceActivityDetailData,
  getInvoiceViewData,
  updateRecordPayment
} from '../../../services/invoiceService';
import { useHistory, useParams } from 'react-router-dom';
import { currencySymbols, invoiceDetailMoreMenu } from '../../../global/row';
import moment from 'moment';
import { CurrencyInterfase, projectIdInterface } from '../../../interfaces/InvoiceInterface';
import { setInvoiceActivityDetail, setInvoiceViewData, setInvoiceDetail } from '../../../actions/invoiceActions';
import Deletemodal from '../../../component/models/deleteModel';
import { currencyWithDecimal, getInvoiceIcon, isEmpty } from '../../../helpers/common';
import { getCustomerProjectDetail } from '../../../services/customerServices';
import { ROUTES } from '../../../routes/urls';
import Button from '../../../component/button';
import ModalCustom from '../../../component/models/modal';
import { setErrorMessage, setSuccessMessage } from '../../../actions/messageActions';
import Sendinvoicemodal from '../../../component/models/sendInvoicemodal/Sendinvoicemodal';
import UserPreferenceSingleton from '../../../helpers/userPreferenceSingleton';
import Invoicedetailloading from '../../../component/loading/invoicedetailLoading';
import { CUSTOMER_MEMBER_ROLE, INVOICE_STATUS_TYPE, MENU_OPTIONS } from '../../../global/constants';
import { getInvoicingSettingsData } from '../../../services/settingServices';
import { captureException } from '../../../services/logService';
import emptylogo from '../../../assets/images/emptystates/emptylogo.svg';
import darkemptylogo from '../../../assets/images/emptystates/darkemptylogo.svg';
import { REACT_APP_TEAMCAMP_APIURL } from '../../../global/environment';
import { trackAnalyticActivity } from '../../../services/analyticsService';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import InvoiceActivity from './invoiceactivity';
import { INVOICE_ANALYTICS } from '../../../global/analyticsConstants';
import Responsivnavbar from '../../../component/navbar/responsivenavbar';
import { useMobileDevice } from '../../../global/useMobile';
import { Dayjs } from 'dayjs';
import { Dropdown, DropdownItem } from '../../../component/Dropdown';
import DatePicker from '../../../component/dateTime/datePicker';

const InvoicesDetails: React.FC = () => {
  //Refs
  const buttonRef = useRef<HTMLDivElement | null>(null);
  const recordPaymentDropdownRef = useRef<any>(null);

  //States
  const [date, setDate] = useState<Dayjs | null>(null); // Type date as Dayjs or null
  const [amount, setAmount] = useState<string>();
  const [notes, setNotes] = useState<string>();
  const [deleteModelOpen, setDeleteModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [deleteInvoiceLoading, setDeleteInvoiceLoading] = useState(false);
  const [invoiceModelOpen, setInvoiceModelOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState({ id: '' });
  const [currencySymbol, setCurrencySymbol] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const {
    settings: settingsSelector,
    invoice: invoiceSelector,
    workspace: workspaceSelector,
    auth: authSelector
  } = stateSelector || {};
  const { workspace } = workspaceSelector;
  const { id: workspace_id, customerRole, isOwner, isAdmin } = workspace;
  const { invoiceViewData } = invoiceSelector;
  const { themeMode, invoiceSetting } = settingsSelector;
  const { userDetails } = authSelector;

  //Other variable
  const isMobile = useMobileDevice();
  const dispatch = useDispatch();
  const params: { id: string } = useParams();
  const history = useHistory();

  // load initial data
  const loadData = useCallback(async () => {
    try {
      if (!isEmpty(workspace_id)) {
        setLoadingData(true);
        const response = await Promise.all([
          dispatch(getInvoiceViewData(params?.id)),
          dispatch(getInvoiceActivityDetailData(params?.id, workspace)),
          dispatch(getInvoicingSettingsData(workspace_id))
        ]);
        const result = response?.[0];
        const currency: CurrencyInterfase | undefined = currencySymbols.find((x) => x.code === result?.currency);
        if (result && currency) {
          setCurrencySymbol(currency?.symbol);
        }
      }
    } catch (error) {
      captureException(error);
      console.log('error', error);
      setLoadingData(false);
    } finally {
      setLoadingData(false);
    }
  }, [dispatch, params?.id, workspace, workspace_id]);

  useEffect(() => {
    trackAnalyticActivity(INVOICE_ANALYTICS.VIEW_DETAIL);
    loadData();
    return () => {
      dispatch(
        setInvoiceViewData({
          companyId: '',
          currency: '',
          customer: {
            phone: '',
            lastName: '',
            id: '',
            firstName: '',
            companyName: '',
            email: '',
            companyId: '',
            address: '',
            status: undefined,
            projectId: ''
          },
          date: '',
          discount: 0,
          dueAmount: 0,
          dueDate: '',
          dueDays: 0,
          id: '',
          items: [],
          number: '',
          status: 0,
          statusText: '',
          tax: [],
          totalAmount: 0,
          totalDiscount: 0,
          totalTax: 0,
          totalTax2: 0,
          subTotal: 0
        })
      );
      dispatch(setInvoiceActivityDetail([]));
    };
  }, [loadData, dispatch]);

  // for update date
  const onChange = useCallback((date: Dayjs) => {
    setDate(date);
  }, []);

  // record payment
  const recordPayment = useCallback(
    async (e: React.SyntheticEvent) => {
      const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
      try {
        e.preventDefault();
        setLoading(true);
        if (isEmpty(amount) || Number(amount) <= 0) {
          dispatch(setErrorMessage('Amount must be greater then 0'));
          return;
        }
        if (Number(amount) > invoiceViewData?.dueAmount) {
          dispatch(setErrorMessage('Please enter valid amount. Amount should be less than due amount.'));
          return;
        }
        const payload = {
          date: date,
          amount: Number(amount),
          notes: notes
        };
        if (isEmpty(date)) {
          return dispatch(setErrorMessage('Please select date'));
        }
        const result = await dispatch(updateRecordPayment(userDetails?.id, workspace_id, params?.id, payload));
        if (result) {
          trackAnalyticActivity(INVOICE_ANALYTICS.PAYMENT_RECORDED);
          setDate(null);
          setAmount('');
          setNotes('');
          recordPaymentDropdownRef.current.closeDropdown();
          loadData();
        }
      } catch (error) {
        captureException(error);
        console.log('error', error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    },
    [dispatch, workspace_id, params?.id, amount, notes, date, loadData, invoiceViewData]
  );

  // open delete model
  const openDeleteModel = useCallback(() => {
    setDeleteModalOpen(true);
  }, []);

  // close delete model
  const closeDeleteModel = useCallback(() => {
    setDeleteModalOpen(false);
    setSelectedItem({ id: '' });
  }, []);

  // open invoice model
  const openInvoiceModel = useCallback(() => {
    setInvoiceModelOpen(true);
  }, []);

  // close invoice model
  const closeInvoiceModel = useCallback(() => {
    setInvoiceModelOpen(false);
  }, []);

  // for delete invoice
  const onClickDeleteInvoice = useCallback(async () => {
    try {
      setDeleteInvoiceLoading(true);
      const result = await dispatch(deleteInvoice(params?.id));
      if (result) {
        setDeleteModalOpen(false);
        trackAnalyticActivity(INVOICE_ANALYTICS.DELETED);
        dispatch(setSuccessMessage('Invoice deleted successfully!'));
        history.push(ROUTES.INVOICES);
      }
    } catch (error) {
      captureException(error);
      console.log('error', error);
    } finally {
      setDeleteInvoiceLoading(false);
    }
  }, [params?.id, dispatch, history]);

  // for invoice edit
  const onInvoiceEdit = useCallback(async () => {
    try {
      dispatch(setInvoiceDetail(invoiceViewData));
      const response = await Promise.all([
        dispatch(getInvoiceViewData(invoiceViewData?.id)),
        dispatch(getCustomerProjectDetail(workspace_id, invoiceViewData?.customer?.id))
      ]);
      if (invoiceViewData?.statusText === 'DRAFT') {
        trackAnalyticActivity(INVOICE_ANALYTICS.VIEW_DRAFT);
      }
      dispatch(setInvoiceDetail(response[0]));
      history.push(`/invoices/update/${invoiceViewData?.id}?customer=${invoiceViewData?.customer?.id}`);
    } catch (error) {
      captureException(error);
      console.log('error', error);
    }
  }, [dispatch, invoiceViewData, workspace_id, history]);

  // redirect to web invoice
  const onclickWebInvoice = useCallback(() => {
    trackAnalyticActivity(INVOICE_ANALYTICS.PUBLIC_BUTTON_CLICKED);
    const newTab = window.open(`https://invoice.teamcamp.app/${params?.id}`, '_blank');
    if (newTab) {
      newTab.focus();
    } else {
      alert('Please allow pop-ups for this site to open in a new tab.');
    }
  }, [params?.id]);

  // generate invoice pdf
  const generateInvoicePdf = useCallback(async (invoiceId: string, status: boolean) => {
    try {
      if (isEmpty(invoiceId)) return;
      const url = `${REACT_APP_TEAMCAMP_APIURL}/invoice/generateInvoicePdf/${invoiceId}?download=${status}`;
      if (status === true) {
        trackAnalyticActivity(INVOICE_ANALYTICS.PDF_BUTTON_CLICKED);
      } else {
        trackAnalyticActivity(INVOICE_ANALYTICS.PRINT_BUTTON_CLICKED);
      }
      window.open(url, '_blank');
    } catch (error) {
      captureException(error);
      console.log('error', error);
    }
  }, []);

  // for make duplicate invoice
  const onDuplicateInvoice = useCallback(async () => {
    try {
      const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
      const projectResult = await dispatch(getCustomerProjectDetail(workspace_id, invoiceViewData?.customer?.id));
      const updatedItem = invoiceViewData?.items?.map((item) => {
        if (!isEmpty(item?.projectId)) {
          const project = projectResult?.find((ele: { id: projectIdInterface }) => ele?.id === item?.projectId);
          return { ...item, projectId: project };
        } else {
          return item;
        }
      });
      const invoiceData = {
        ...invoiceViewData,
        date: new Date().toISOString(),
        userId: userDetails?.id,
        customerId: invoiceViewData?.customer?.id,
        emails: [],
        items: updatedItem,
        isSendMeCopy: false,
        dueDate: invoiceViewData?.dueDays
          ? new Date(new Date().setDate(new Date().getDate() + invoiceViewData.dueDays)).toISOString()
          : ''
      };
      const fieldsToDelete = [
        'id',
        'status',
        'paidAmount',
        'statusText',
        'number',
        'invoicePayment',
        'customer',
        'dueAmount'
      ];
      fieldsToDelete.forEach((field) => delete (invoiceData as any)?.[field]);
      dispatch(setInvoiceDetail(invoiceData));
      history.push(`${ROUTES.ADD_NEW_INVOICES_2}?customer=${invoiceViewData?.customer?.id}`);
    } catch (error) {
      captureException(error);
      console.log('error', error);
    }
  }, [dispatch, workspace_id, invoiceViewData, history]);

  // handle dropdown click
  const handleDropdownClick = useCallback(
    (key: number | string) => {
      key = Number(key);
      if (key === MENU_OPTIONS.EDIT) {
        onInvoiceEdit();
      } else if (key === MENU_OPTIONS.DELETE) {
        openDeleteModel();
      } else if (key === MENU_OPTIONS.DUPLICATE) {
        onDuplicateInvoice();
      }
    },
    [onDuplicateInvoice, onInvoiceEdit, openDeleteModel]
  );

  // render invoice data
  const renderInvoiceData = useMemo(() => {
    return invoiceViewData?.items?.map((itemdata) => {
      const ref = currencySymbols.find((x) => x.code === invoiceViewData?.currency);
      const symbol = ref?.symbol;
      return (
        <Row key={itemdata?.id}>
          <Leftrow>
            <Info1>{itemdata?.desc}</Info1>
          </Leftrow>
          <Rightrow>
            <TableDataResponsive>
              <InvoiceTableTitle>quantity</InvoiceTableTitle>
              <Quantityrate>{itemdata?.qty}</Quantityrate>
            </TableDataResponsive>
            <TableDataResponsive>
              <InvoiceTableTitle>unit price</InvoiceTableTitle>
              <Inforate>
                {symbol}
                {currencyWithDecimal(itemdata?.unitPrice)}
              </Inforate>
            </TableDataResponsive>
            <TableDataResponsive isLastData>
              <InvoiceTableTitle>amount</InvoiceTableTitle>
              <Amountrate>
                {symbol}
                {currencyWithDecimal(itemdata?.amount)}
              </Amountrate>
            </TableDataResponsive>
          </Rightrow>
        </Row>
      );
    });
  }, [invoiceViewData?.currency, invoiceViewData?.items]);

  const renderMoreMenuItems = useMemo(() => {
    return invoiceDetailMoreMenu.map((item) => (
      <DropdownItem
        key={item.key}
        label={item.label}
        onClick={() => handleDropdownClick(item.key)}
        tone={item.label === 'Delete' ? 'critical' : 'primary'}
      />
    ));
  }, [handleDropdownClick]);

  return (
    <>
      <AppLayout>
        <Group>
          {/* Header */}
          <Headerblock>
            <HeaderLeft>
              {isMobile && (
                <ResponsiveNavbarIcon>
                  <Responsivnavbar />
                </ResponsiveNavbarIcon>
              )}
              <Heading>
                {!isMobile && (
                  <Icon>
                    <SVGIcon
                      name='invoice-header-icon'
                      width='28'
                      height='28'
                      viewBox='0 0 28 28'
                      className='invoice-icon'
                    />
                  </Icon>
                )}
                <Title>{invoiceViewData?.number}</Title>
              </Heading>
            </HeaderLeft>
            <Rightheading>
              {!isMobile && (
                <Icons>
                  <Icon1 onClick={onclickWebInvoice}>
                    <SVGIcon
                      name='invoice-details-header-earth-icon'
                      width='16'
                      height='16'
                      viewBox='0 0 16 16'
                      fill='none'
                      stroke='var(--text-secondary)'
                    />
                  </Icon1>
                  <Icon1 onClick={() => generateInvoicePdf(invoiceViewData?.id, true)}>
                    <SVGIcon
                      name='file-icon'
                      width='16'
                      height='16'
                      viewBox='0 0 21 21'
                      fill='none'
                      stroke='var(--text-secondary)'
                    />
                  </Icon1>
                  <Icon1 onClick={() => generateInvoicePdf(invoiceViewData?.id, false)}>
                    <SVGIcon
                      name='invoice-details-header-print-icon'
                      width='16'
                      height='16'
                      viewBox='0 0 16 16'
                      fill='none'
                      stroke='var(--text-secondary)'
                    />
                  </Icon1>
                </Icons>
              )}
              {invoiceViewData?.status !== INVOICE_STATUS_TYPE.PAID &&
                customerRole !== CUSTOMER_MEMBER_ROLE.FULL_ACCESS &&
                (isAdmin || isOwner) && (
                  <Dropdown
                    isMinWidth={268}
                    ref={recordPaymentDropdownRef}
                    content={
                      <Button
                        title='Record Payment'
                        type='button'
                        secondary={true}
                        hasNotBoxshadow
                        smallbutton={isMobile ? true : false}
                      />
                    }
                    trigger='click'>
                    <Recorddropdown onClick={(e) => e.stopPropagation()}>
                      <Recorddetail>
                        <Label> Payment Date</Label>
                        <DatePicker onChange={onChange} />
                      </Recorddetail>
                      <Recorddetail>
                        <Label>Amount</Label>
                        <InputDiv
                          placeholder='2600.00'
                          type='number'
                          value={amount}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAmount(e.target.value)}
                        />
                      </Recorddetail>
                      <Recorddetail>
                        <Label> Notes</Label>
                        <TextArea
                          value={notes}
                          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setNotes(e.target.value)}
                        />
                      </Recorddetail>
                      <Button
                        title='Record Payment'
                        secondary={true}
                        type='submit'
                        onClick={recordPayment}
                        disabled={loading}
                        hasNotBoxshadow
                        invoiceDetailButton={isMobile ? true : false}
                        isRecordButton={isMobile ? true : false}
                      />
                    </Recorddropdown>
                  </Dropdown>
                )}
              {customerRole !== CUSTOMER_MEMBER_ROLE.FULL_ACCESS &&
                (isAdmin || isOwner) &&
                (!isMobile ? (
                  <Button title='Send Invoice' type='button' onClick={openInvoiceModel} />
                ) : (
                  <SvgIconDiv onClick={openInvoiceModel}>
                    <SVGIcon name='send-icon' width='18px' height='18px' viewBox='0 0 18 18' className='send-icon' />
                  </SvgIconDiv>
                ))}
              {customerRole !== CUSTOMER_MEMBER_ROLE.FULL_ACCESS && (isAdmin || isOwner) && (
                <Dropdown
                  activeClassName='active'
                  content={
                    <Icon1
                      ref={buttonRef}
                      onClick={() => {
                        setIsOpen(!isOpen);
                      }}>
                      <SVGIcon
                        name='invoice-more-icon'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        className='moreicon'
                      />
                    </Icon1>
                  }
                  trigger='click'>
                  {renderMoreMenuItems}
                </Dropdown>
              )}
            </Rightheading>
          </Headerblock>
          {/* Invoice  */}
          {!loadingData && (
            <Content>
              <Leftcontent>
                <Maindiv isdark={themeMode.theme}>
                  <div className='before' />
                  <Topinvoice>
                    <Invoiceleft>
                      {!isMobile &&
                        (!isEmpty(invoiceSetting?.invoiceLogo) ? (
                          <img
                            src={invoiceSetting?.invoiceLogo}
                            alt='invoice-logo'
                            width={40}
                            height={40}
                            className='invoice-logo-image'
                          />
                        ) : (
                          <>
                            {themeMode?.theme === 'dark' ? (
                              <img src={darkemptylogo} alt='emptyprofile' width={40} height={40} />
                            ) : (
                              <img src={emptylogo} alt='emptyprofile' width={40} height={40} />
                            )}
                          </>
                        ))}
                      <Details>
                        <Inputid>
                          <h1>Invoice ID</h1>
                          <p>{invoiceViewData?.number}</p>
                        </Inputid>
                        <Inputid>
                          <h1>Issue Date</h1>
                          <p>{moment(invoiceViewData?.date).format(userDetails?.dateFormat)} </p>
                        </Inputid>
                        {!isEmpty(invoiceViewData?.dueDate) && (
                          <Inputid>
                            <h1>Due Date</h1>
                            <p>{moment(invoiceViewData?.dueDate).format(userDetails?.dateFormat)}</p>
                          </Inputid>
                        )}
                      </Details>
                    </Invoiceleft>
                    {!isMobile && (
                      <Invoicemiddle>
                        <img src={getInvoiceIcon(invoiceViewData?.status)} alt='PaidSymbol' className='paid-symbol' />
                      </Invoicemiddle>
                    )}
                    <Invoiceright>
                      <InvoiceResponsive>
                        {isMobile &&
                          (!isEmpty(invoiceSetting?.invoiceLogo) ? (
                            <img
                              src={invoiceSetting?.invoiceLogo}
                              alt='invoice-logo'
                              width={40}
                              height={40}
                              className='invoice-logo-image'
                            />
                          ) : (
                            <>
                              {themeMode?.theme === 'dark' ? (
                                <img src={darkemptylogo} alt='emptyprofile' width={40} height={40} />
                              ) : (
                                <img src={emptylogo} alt='emptyprofile' width={40} height={40} />
                              )}
                            </>
                          ))}
                        {isMobile && (
                          <Invoicemiddle>
                            <img
                              src={getInvoiceIcon(invoiceViewData?.status)}
                              alt='PaidSymbol'
                              className='paid-symbol'
                            />
                          </Invoicemiddle>
                        )}
                        <Invoicetitle>INVOICE</Invoicetitle>
                      </InvoiceResponsive>
                      <Address>
                        <From>
                          <Text>From :</Text>
                          <Fromaddress>
                            <Info>{!isEmpty(invoiceSetting?.name) ? invoiceSetting?.name : workspace?.name}</Info>
                            {!isEmpty(invoiceSetting?.address) && (
                              <Text
                                dangerouslySetInnerHTML={{
                                  __html: invoiceSetting?.address.replace(/\n/g, '<br/>')
                                }}></Text>
                            )}
                          </Fromaddress>
                        </From>
                        <From>
                          <Text>Invoice For :</Text>
                          <Fromaddress>
                            <Info>{invoiceViewData?.customer?.companyName}</Info>
                            <Text>{invoiceViewData?.customer?.address}</Text>
                          </Fromaddress>
                        </From>
                      </Address>
                    </Invoiceright>
                  </Topinvoice>
                  <Invoicetable>
                    {!isMobile && (
                      <Gheader>
                        <Label>DESCRIPTION</Label>
                        <Rightheader>
                          <Quantity>Qty</Quantity>
                          <Price>Unit Price</Price>
                          <Amount>Amount</Amount>
                        </Rightheader>
                      </Gheader>
                    )}
                    <InvoiceCard>{renderInvoiceData}</InvoiceCard>
                    <Total isHideBottomBorder={isEmpty(invoiceViewData?.notes)}>
                      <TopcontentTotal>
                        <Subtotal>
                          <Name>Subtotal</Name>
                          <Totalrate>
                            {currencySymbol}
                            {currencyWithDecimal(invoiceViewData?.subTotal)}
                          </Totalrate>
                        </Subtotal>
                        <Subtotal>
                          <Name>Discount ({invoiceViewData?.discount}%)</Name>
                          <Totalrate>
                            -{currencySymbol}
                            {currencyWithDecimal(invoiceViewData?.totalDiscount)}
                          </Totalrate>
                        </Subtotal>
                        {!isEmpty(invoiceViewData?.tax?.[0]) && (
                          <Subtotal>
                            <Name>
                              {invoiceViewData?.tax?.[0]?.name || 'Tax'} ({invoiceViewData?.tax?.[0]?.value || 0}%)
                            </Name>
                            <Totalrate>
                              {currencySymbol}
                              {currencyWithDecimal(invoiceViewData?.totalTax)}
                            </Totalrate>
                          </Subtotal>
                        )}
                        {!isEmpty(invoiceViewData?.tax?.[1]) && (
                          <Subtotal>
                            <Name>
                              {invoiceViewData?.tax?.[1]?.name || 'Tax 2'} ({invoiceViewData?.tax?.[1]?.value || 0}%)
                            </Name>
                            <Totalrate>
                              {currencySymbol}
                              {currencyWithDecimal(invoiceViewData?.totalTax2)}
                            </Totalrate>
                          </Subtotal>
                        )}
                      </TopcontentTotal>
                      <Totalamount>
                        <Name>Amount Due:</Name>
                        <Totalcontent>
                          {currencySymbol}
                          {currencyWithDecimal(invoiceViewData?.dueAmount)}
                        </Totalcontent>
                      </Totalamount>
                    </Total>
                    {!isEmpty(invoiceViewData?.notes) && (
                      <Notediv>
                        <h2>Note</h2>
                        <p>{invoiceViewData?.notes}</p>
                      </Notediv>
                    )}
                  </Invoicetable>
                  <div className='after' />
                </Maindiv>
              </Leftcontent>
              <InvoiceActivity
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                loadData={loadData}
                deleteInvoiceLoading={deleteInvoiceLoading}
                setDeleteInvoiceLoading={setDeleteInvoiceLoading}
              />
            </Content>
          )}
          {loadingData && <Invoicedetailloading />}

          <ModalCustom open={deleteModelOpen} onClose={closeDeleteModel} width={334}>
            <Deletemodal
              onClose={closeDeleteModel}
              onOk={onClickDeleteInvoice}
              loading={deleteInvoiceLoading}
              modaltitle='Delete Invoice?'
              description='Are you sure to want to delete this invoice?'
            />
          </ModalCustom>
          <ModalCustom open={invoiceModelOpen} onClose={closeInvoiceModel} width={674}>
            <Sendinvoicemodal invoiceId={params?.id} onClose={closeInvoiceModel} workspaceId={workspace_id} />
          </ModalCustom>
        </Group>
      </AppLayout>
    </>
  );
};
export default InvoicesDetails;
