import styled from 'styled-components';
import { Typography } from '../../../global/theme';
const Group = styled.div`
  width: 100%;
  max-width: 1022px;
  margin: auto;
  height: 100vh;
  box-orient: vertical;
  flex-direction: column;
  display: flex;
  @media (max-width: 449px) {
    padding: 16px 0;
  }
`;

const Headerblock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 14px 0 24px;
  @media (max-width: 449px) {
    display: none;
  }
`;
const Heading = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
const Icon = styled.div`
  display: inline-flex;
  .invoice-icon {
    fill: var(--brand-primary);
  }
  .plus-icon {
    path {
      stroke: var(--text-primary);
    }
  }
`;
const HeaderTitle = styled.h1`
  ${Typography.heading_lg}
  color: var(--text-primary);
  margin: 0;
  @media (max-width: 449px) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    word-break: break-all;
  }
`;

const MainGroup = styled.div`
  width: 100%;
  margin: 0px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 14px;
`;
const Group1 = styled.div`
  background: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 12px;
  @media (max-width: 449px) {
    padding: 12px;
  }
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 21px;
  @media (max-width: 449px) {
    flex-direction: column;
    gap: 20px;
  }
`;
const Inputlabel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0px;
  gap: 8px;
  .angledown-icon {
    path {
      stroke: var(--text-secondary);
    }
  }
  .ant-picker .ant-picker-clear > * {
    width: 18px;
    height: 18px;
    background-color: var(--background-primary);
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: var(--background-primary);
    border: 0.5px solid var(--border-primary);
    height: 34px;
    padding: 0 12px;
    @media (max-width: 449px) {
      height: 32px;
    }
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selector {
    color: var(--text-primary);
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    color: var(--text-placeholder);
  }
  .ant-select-focused.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
      .ant-pagination-size-changer
    )
    .ant-select-selector {
    border: 0.5px solid var(--brand-primary);
    box-shadow: var(--shadow-input-focus);
  }
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover
    .ant-select-selector {
    border: 0.5px solid var(--brand-primary);
  }

  .ant-select:hover .ant-select-arrow:not(:last-child) {
    opacity: 1;
  }
  .ant-picker {
    background-color: var(--background-primary);
    border: 0.5px solid var(--border-primary);
    padding: 5px 12px;
    width: 232px;
  }
  .ant-picker .ant-picker-input > input {
    color: var(--text-primary);
    ${Typography.body_md}
  }
  .ant-picker .ant-picker-suffix > * path {
    fill: var(--text-secondary);
  }
  .ant-picker-input > input::placeholder,
  .ant-picker-focused .ant-picker-input > input::placeholder {
    color: var(--text-secondary);
  }
  .ant-picker-focused {
    border: 0.5px solid var(--brand-primary);
    box-shadow: var(--shadow-input-focus);
  }
  .ant-select-single .ant-select-selector {
    ${Typography.body_md_medium}
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    color: var(--text-placeholder);
    padding-top: 1px;
    ${Typography.body_md_medium}
    padding-inline-end: 28px;
  }
  .ant-select-dropdown .ant-select-item {
    ${Typography.body_md_medium}
  }
  .ant-picker .ant-picker-suffix {
    cursor: pointer;
    pointer-events: unset;
  }
  .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background-color: var(--brand-primary);
  }
  .ant-picker-dropdown .ant-picker-panel-container .ant-picker-panel {
    border: none !important;
  }
  .ant-picker-dropdown .ant-picker-ranges > li {
    line-height: normal;
  }
  .ant-picker-dropdown .ant-picker-ranges {
    padding: 9px 0;
  }

  @media (max-width: 449px) {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      height: 32px;
    }
    .ant-picker {
      width: -webkit-fill-available;
    }
    .ant-picker-dropdown .ant-picker-date-panel {
      width: auto;
    }
    .ant-picker-dropdown {
      width: calc(100vw - 56px) !important;
    }
    .ant-picker-dropdown .ant-picker-panel-container {
      width: calc(100vw - 56px);
    }
  }
`;
const Label = styled.p`
  ${Typography.body_md_medium}
  color: var(--text-secondary);
  margin: 0px;
`;
const Label1 = styled.p`
  ${Typography.body_md_medium}
  color: var(--text-secondary);
  margin: 0px;
  max-width: 466px;
  width: 100%;
  padding-right: 12px;
  text-transform: uppercase;
`;
const Input1 = styled.input`
  width: 206px;
  ${Typography.body_md}
  color: var(--text-primary);
  background: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 6px;
  padding: 8px 12px;
  font-family: 'Inter';
  :focus {
    outline: none;
    border: 0.5px solid var(--brand-primary);
    z-index: 9999;
  }
  ::placeholder {
    ${Typography.body_md}
    color: var(--text-placeholder);
  }
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  @media (max-width: 449px) {
    width: -webkit-fill-available !important;
    padding: 5px 12px;
  }
`;
const Notex = styled.p`
  ${Typography.body_md_medium}
  color: var(--brand-primary);
  cursor: pointer;
  margin: 0;
  white-space: nowrap;
`;
const Anothertex = styled.p`
  ${Typography.body_md_medium}
  color: var(--brand-primary);
  padding-left: 8px;
  border-left: 0.5px solid var(--border-primary);
  cursor: pointer;
  margin-bottom: 0;
  margin: 0;
`;
const Tex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  @media (max-width: 449px) {
    width: 100%;
  }
`;
const TaxOutside = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-end;
`;
const Row2 = styled.div`
  display: flex;
  flex-direction: row;
  gap: 21px;
  align-items: flex-end;
  @media (max-width: 449px) {
    flex-direction: column;
    align-items: normal;
    gap: 20px;
  }
`;
const Group2 = styled.div`
  background: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 8px;
  @media (max-width: 449px) {
    padding: 12px;
  }
`;
const Gheader = styled.div`
  padding: 11px 12px 11px 30px;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  border-bottom: 0.5px solid var(--border-primary);
  background-color: var(--background-tertiary);
  border-radius: 8px 8px 0 0;
  @media (max-width: 449px) {
    display: none;
  }
`;
const Rightheader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Project = styled.p`
  ${Typography.body_md_medium}
  color: var(--text-secondary);
  border-right: 0.5px solid var(--border-primary);
  padding-right: 30px;
  margin: 0px;
  text-transform: uppercase;
  white-space: nowrap;
  @media (max-width: 449px) {
    border-right: none;
    padding: 0;
  }
`;
const Quantity = styled.p`
  ${Typography.body_md_medium}
  color: var(--text-secondary);
  border-right: 0.5px solid var(--border-primary);
  padding-left: 12px;
  margin: 0px;
  text-transform: uppercase;
  width: 50px;
  text-align: center;
  @media (max-width: 449px) {
    border-right: none;
    padding: 0;
    width: unset;
  }
`;
const Tax = styled.p`
  ${Typography.body_md_medium}
  color: var(--text-secondary);
  border-right: 0.5px solid var(--border-primary);
  padding: 0 12px;
  margin: 0px;
  text-transform: uppercase;
  width: 35px;
  text-align: center;
  @media (max-width: 449px) {
    border-right: none;
    padding: 0;
  }
`;
const Amount = styled.p`
  ${Typography.body_md_medium}
  color: var(--text-secondary);
  padding-left: 12px;
  margin: 0px;
  text-transform: uppercase;
  width: 100px;
  text-align: end;
  @media (max-width: 449px) {
    padding-left: 0;
    text-align: left;
    width: unset;
  }
`;
const Fields = styled.div`
  border-bottom: 0.5px solid var(--border-primary) !important;
  padding: 8px 12px 8px 30px;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  :hover {
    .drag-icon {
      visibility: visible;
    }
  }
  @media (max-width: 449px) {
    /* display: none; */
    flex-direction: column;
    border: 0.5px solid var(--border-primary) !important;
    border-radius: 8px;
    margin-bottom: 24px;
    padding: 0;
  }
`;
const Inputarea = styled.input`
  width: 454px;
  ${Typography.body_md_medium}
  color: var(--text-primary);
  background: var(--background-primary);
  border: none;
  padding: 0px 8px 0 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-family: 'Inter';
  :focus {
    outline: none;
  }
  ::placeholder {
    color: var(--text-placeholder);
    ${Typography.body_md_medium};
  }
  @media (max-width: 449px) {
    width: -webkit-fill-available;
    padding: 8px;
    border-bottom: 0.5px solid var(--border-primary);
    border-radius: 8px 8px 0 0;
    ::placeholder {
      color: var(--text-secondary);
    }
  }
`;

const Quantityrate = styled.input`
  ${Typography.body_md_medium}
  color: var(--text-primary);
  background: var(--background-primary);
  border: 0;
  border-radius: 4px;
  margin-left: 12px;
  width: 46px;
  text-align: center;
  padding: 0;
  :focus-visible {
    outline: none;
  }
  ::placeholder {
    color: var(--text-placeholder);
    ${Typography.body_md_medium}
  }
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  @media (max-width: 449px) {
    margin-left: 0;
    text-align: end;
  }
`;

const Amountrate = styled.div`
  width: 100px;
  ${Typography.body_md_medium}
  color: var(--text-primary);
  margin: 0 12px 0 12px;
  text-align: end;
  ::placeholder {
    ${Typography.body_md_medium}
  }
  @media (max-width: 449px) {
    margin: 0;
    width: unset;
  }
`;

const AddButton = styled.div`
  display: flex;
  align-items: center;
  background: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  box-shadow: var(--shadow-button);
  border-radius: 36px;
  padding: 7px 19px;
  width: 78px;
  gap: 5px;
  margin: 80px 0px 12px 12px;
  &:hover {
    background-color: var(--background-secondary);
  }
  cursor: pointer;
  span {
    ${Typography.body_md_medium}
    color: var(--text-primary);
  }
  @media (max-width: 449px) {
    margin: 24px 0px 15px;
    padding: 5px 19px;
  }
`;
const Bottomfield = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 12px 12px;
  align-items: flex-start;
  gap: 12px;
  @media (max-width: 449px) {
    flex-direction: column-reverse;
    gap: 15px;
    margin: 0;
  }
`;
const Inputfield = styled.textarea`
  width: 700px;
  padding: 9px;
  ${Typography.body_md_medium}
  font-family: unset;
  color: var(--text-primary);
  background-color: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 6px;
  resize: none;
  outline: none;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  :focus {
    border: 0.5px solid var(--brand-primary);
    z-index: 999;
  }

  ::placeholder {
    ${Typography.body_md_medium}
    color: var(--text-secondary);
  }
  @media (max-width: 449px) {
    width: -webkit-fill-available;
    height: 134px !important;
    padding: 7px 8px;
  }
`;

const Total = styled.div`
  background: var(--background-tertiary);
  border: 0.5px solid var(--border-primary);
  box-shadow: var(--shadow-card);
  border-radius: 8px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 237px;
  height: auto;
  @media (max-width: 449px) {
    width: -webkit-fill-available;
  }
`;
const Subtotal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Name = styled.div`
  ${Typography.body_md}
  color: var(--text-secondary);
  height: 16px;
`;
const Content = styled.div`
  ${Typography.body_md_medium}
  text-align: right;
  color: var(--text-primary);
`;
const Totalamount = styled.div`
  border-top: 0.5px solid var(--border-primary);
  margin-top: 3px;
  padding: 12px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const Totalcontent = styled.div`
  ${Typography.heading_md}
  align-items: center;
  color: var(--text-primary);
`;
const Emailbox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0px;
  padding: 0px;
`;

const Checks = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
  margin: 0 21px 0 21px;
  .checkbox-round {
    width: 7px;
    height: 7px;
    border-radius: 8px;
    vertical-align: middle;
    border: 1px solid var(--text-secondary);
    appearance: none;
    outline: none;
    cursor: pointer;
    margin: 0;
    padding: 7px;
  }
  input[type='checkbox'] {
    position: relative;
    cursor: pointer;
    opacity: 0.5;
  }
  input[type='checkbox']:checked {
    background-color: var(--brand-primary);
    opacity: 1;
    border: 1px solid var(--brand-primary);
  }
  input[type='checkbox']:checked::before {
    content: '';
    position: absolute;
    right: 50%;
    top: 50%;
    width: 4px;
    height: 10px;
    border: solid var(--text-white);
    border-width: 0 2px 2px 0;
    margin: -1px -1px 0 -1px;
    transform: rotate(45deg) translate(-50%, -50%) scale(0.7);
    z-index: 2;
  }
  @media (max-width: 449px) {
    margin: 0;
    padding: 2px 0;
  }
`;

const Inputvalue = styled.input``;
const Group3 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  margin-top: 3px;
  @media (max-width: 449px) {
    margin-top: 0;
  }
`;

const RemoveIcon = styled.div`
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  .delete-icon {
    path {
      fill: var(--accent-error);
    }
  }
  :hover {
    background-color: var(--neutral-secondary);
    border-radius: 4px;
  }
  @media (max-width: 449px) {
    width: 20px;
    height: 20px;
    &.remove-icon-web {
      display: none;
    }
  }
`;
const Price = styled.p`
  ${Typography.body_md_medium}
  color: var(--text-secondary);
  border-right: 0.5px solid var(--border-primary);
  padding: 0px 12px 0px 12px;
  margin: 0px;
  text-transform: uppercase;
  width: 90px;
  text-align: end;
  @media (max-width: 449px) {
    padding:0;
    text-align: left;
    width: unset;
    border-right: none;
  }
`;

const Unitrate = styled.input`
  ${Typography.body_md_medium}
  color: var(--text-primary);
  background: var(--background-primary);
  border: none;
  border-radius: 4px;
  text-align: end;
  width: 90px;
  padding: 0 15px 0 12px;
  :focus-visible {
    outline: none;
  }
  ::placeholder {
    color: var(--text-placeholder);
    ${Typography.body_md_medium}
  }
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  @media (max-width: 449px) {
    padding: 0;
  }
`;
const Rowrightheader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 449px) {
    flex-direction: column;
    width: 100%;
  }
`;
const Checks2 = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
  margin: 0 21px 0 21px;
  .checkbox-round {
    width: 7px;
    height: 7px;
    border-radius: 8px;
    vertical-align: middle;
    border: 1px solid var(--text-secondary);
    appearance: none;
    outline: none;
    cursor: pointer;
    margin: 0;
    padding: 7px;
  }
  input[type='checkbox'] {
    position: relative;
    cursor: pointer;
    opacity: 0.5;
  }
  input[type='checkbox']:checked {
    background-color: var(--brand-primary);
    opacity: 1;
    border: 1px solid var(--brand-primary);
  }
  input[type='checkbox']:checked::before {
    content: '';
    position: absolute;
    right: 50%;
    top: 50%;
    width: 4px;
    height: 10px;
    border: solid var(--text-white);
    border-width: 0 2px 2px 0;
    margin: -1px -1px 0 -1px;
    transform: rotate(45deg) translate(-50%, -50%) scale(0.7);
    z-index: 2;
  }
  @media (max-width: 449px) {
    margin: 0;
    padding: 2px 0;
  }
`;
const Removeicon = styled.div`
  width: 24px;
  padding-left: 10px;
`;

const Dragicon = styled.div`
  width: 16px;
  height: 16px;
  position: absolute;
  left: 10px;
  .drag-icon {
    visibility: hidden;
    fill: var(--text-secondary);
    cursor: grab;
  }
  @media (max-width: 449px) {
    display: none;
  }
`;
const ResponsiveNavbar = styled.div`
  display: none;
  @media (max-width: 449px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    gap: 24px;
    margin: 0 -16px;
    background-color: var(--background-primary);
    position: sticky;
    border-bottom: 0.5px solid var(--border-primary);
    top: 0;
    z-index: 11;
    &.extra-padding {
      padding: 16px;
    }
  }
`;
const NavbarDiv = styled.div`
  display: none;
  @media (max-width: 449px) {
    display: flex;
    align-items: center;
    gap: 24px;
  }
`;

const ItemRow = styled.div`
  &.first-row {
    display: none;
  }
  @media (max-width: 449px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 8px;
    border-bottom: 0.5px solid var(--border-primary);
    width: -webkit-fill-available;
    &.first-row {
      display: flex;
      align-items: flex-start;
    }
    :last-child {
      border-bottom: none;
    }
  }
`;
const ResponsiveLabel = styled.div`
  display: none;
  @media (max-width: 449px) {
    display: block;
    color: var(--text-secondary);
    margin: 0px;
    text-transform: uppercase;
    white-space: nowrap;
    padding: 0;
  }
`;

const SelectDiv = styled.div`
  position: relative;
`;

const ItemDiv = styled.div`
  max-height: 227px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: 4px;
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background: var(--text-placeholder);
    border-radius: 12px;
    min-height: 28px;
  }
`;

const SelectInput = styled.input`
  min-width: 206px;
  max-width: 206px;
  padding: 5px 12px;
  color: var(--text-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 6px;
  ${Typography.body_md_medium}
  ::placeholder {
    color: var(--text-secondary);
  }
  :focus-visible {
    border-color: var(--brand-primary);
    outline: unset;
  }
`;

const IconDiv = styled.div`
  display: flex;
  position: absolute;
  top: 8px;
  right: 10px;
  .angledown-icon {
    transition: transform 0.3s ease;
  }
  .angledown-icon.rotate {
    transform: rotate(180deg);
  }
`;
const Input = styled.input`
  min-width: 108px;
  max-width: 108px;
  padding: 5px 12px;
  color: var(--text-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 6px;
  ${Typography.body_lg}
  ::placeholder {
    color: var(--text-secondary);
  }
  :focus-visible {
    border-color: var(--brand-primary);
    outline: unset;
  }
`;
const SelectOptionDiv = styled.div`
  display: flex;
  max-width: 108px;
  padding: 5px 12px;
  color: var(--text-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 6px;
  margin-right: 10px;

  ${Typography.body_lg}
  svg {
    stroke: var(--text-primary);
  }
  input {
    border: none;
    max-width: 85px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    ::placeholder {
      color: var(--text-secondary);
    }
    :focus-visible {
      border-color: var(--brand-primary);
      outline: unset;
    }
  }
`;
export {
  Headerblock,
  Heading,
  Icon,
  HeaderTitle,
  MainGroup,
  Group1,
  Row,
  Row2,
  Label,
  Inputlabel,
  Input1,
  Anothertex,
  Notex,
  Tex,
  Group2,
  Gheader,
  Rightheader,
  Project,
  Quantity,
  Amount,
  Fields,
  Inputarea,
  Quantityrate,
  Amountrate,
  AddButton,
  Inputfield,
  Bottomfield,
  Total,
  Subtotal,
  Name,
  Content,
  Totalamount,
  Totalcontent,
  Emailbox,
  Inputvalue,
  Group3,
  Buttons,
  Group,
  Price,
  Unitrate,
  Rowrightheader,
  Checks,
  RemoveIcon,
  TaxOutside,
  Checks2,
  Label1,
  Removeicon,
  Tax,
  Dragicon,
  ResponsiveNavbar,
  NavbarDiv,
  ItemRow,
  ResponsiveLabel,
  SelectDiv,
  ItemDiv,
  SelectInput,
  IconDiv,
  Input,
  SelectOptionDiv
};
