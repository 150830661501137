import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearEmailDetail, updateEmailDetail } from '../../actions/settingActions';
import AppLayout from '../../component/appLayout';
import { getProfileSettingData, updateEmailData } from '../../services/settingServices';
import {
  Details,
  Form,
  Formblock,
  Group,
  Inputvalue,
  Label,
  Personaltext,
  Formmain,
  PersonalHeader,
  Header
} from './styles';
import Button from '../../component/button';
import { isEmpty } from '../../helpers/common';
import { setErrorMessage } from '../../actions/messageActions';
import { captureException } from '../../services/logService';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';
import { useMobileDevice } from '../../global/useMobile';
import { ResponsiveHeader } from './SettingHeaderResponsive';

const Email: React.FC = () => {
  // States
  const [loading, setLoading] = useState(false);
  //use selector state variables
  const settingsSelector = useSelector((state: RootReducerInterface) => state.settings);
  const { emailDetail } = settingsSelector;
  // Other variables
  const dispatch = useDispatch();
  const isMobile = useMobileDevice();

  const loadData = useCallback(async () => {
    await dispatch(getProfileSettingData());
  }, [dispatch]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onChangeValue = useCallback(
    (propsName: string, value: string) => {
      dispatch(updateEmailDetail({ propsName, value }));
    },
    [dispatch]
  );

  const onClicksave = useCallback(
    async (e: React.SyntheticEvent) => {
      try {
        e.preventDefault();
        setLoading(true);
        if (isEmpty(emailDetail?.email)) {
          dispatch(setErrorMessage('Please enter email.'));
          return;
        } else if (isEmpty(emailDetail?.password)) {
          dispatch(setErrorMessage('Please enter password.'));
          return;
        }
        const payload = { email: emailDetail?.email, password: emailDetail?.password };
        const result = await dispatch(updateEmailData(payload));
        if (result) {
          loadData();
          dispatch(clearEmailDetail());
        }
      } catch (error) {
        captureException(error);
        console.log('error', error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    },
    [dispatch, emailDetail, loadData]
  );

  return (
    <>
      <AppLayout>
        <Group onSubmit={onClicksave} isMiddlecontainer={true}>
          <PersonalHeader>
            <Header>
              {isMobile && <ResponsiveHeader />}
              <Personaltext>Change your Email</Personaltext>
            </Header>
          </PersonalHeader>
          <Details>
            <Formmain>
              <Formblock>
                <Form>
                  <Label>Email</Label>
                  <Inputvalue
                    type='email'
                    value={emailDetail?.email}
                    onChange={(e) => onChangeValue('email', e.target.value)}
                  />
                </Form>
                <Form>
                  <Label>Password</Label>
                  <Inputvalue
                    type='password'
                    value={emailDetail?.password}
                    onChange={(e) => onChangeValue('password', e.target.value)}
                  />
                </Form>
              </Formblock>
              <Button title='Change Email' type='submit' isLoading={loading} disabled={loading} modelbtn />
            </Formmain>
          </Details>
        </Group>
      </AppLayout>
    </>
  );
};
export default Email;
