import styled from 'styled-components';
import { Typography } from '../../global/theme';

const Tabledata = styled.td`
  border-top: 0.5px solid var(--border-primary);
  text-align: start;
  padding: 12px;
  color: var(--text-secondary);
  ${Typography.body_md_medium}
  width: 20%;

  @media (max-width: 449px) {
    white-space: nowrap;
    max-width: 52px;
  }
  .plus-icon {
    min-width: 16px;
    height: 16px;
  }
  td &:first-child {
    padding: 12px;
    width: 60%;
  }
`;
const Productname = styled.div`
  color: var(--text-secondary);
  p {
    color: var(--text-secondary);
    ${Typography.body_md_medium}
    margin: 0;
    @media (max-width: 449px) {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      word-break: break-all;
      white-space: normal;
      overflow: hidden;
    }
  }
`;

const ProductItem = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export { Productname, Tabledata, ProductItem };
