import { combineReducers } from 'redux';
import appReducer from './appReducer';
import authReducer from './authReducer';
import workspaceReducer from './workspaceReducer';
import messageReducer from './messageReducer';
import projectReducer from './projectReducer';
import customerReducer from './customerReducer';
import settingReducer from './settingReducer';
import taskReducer from './taskReducer';
import timeTrackingReducer from './timeTrackingReducer';
import invoiceReducer from './invoiceReducer';
import reportReducer from './reportReducer';
import chatMessageReducer from './chatMessageReducer';
import snapReducer from './snapReducer';
import docReducer from './docReducer';
import helpCenterReducer from './helpCenterReducer';

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  message: messageReducer,
  workspace: workspaceReducer,
  project: projectReducer,
  customer: customerReducer,
  settings: settingReducer,
  task: taskReducer,
  timeTracking: timeTrackingReducer,
  invoice: invoiceReducer,
  report: reportReducer,
  chatMessage: chatMessageReducer,
  snap: snapReducer,
  doc: docReducer,
  helpCenter: helpCenterReducer
});

export default rootReducer;
