import React, { useCallback, useEffect, useState } from 'react';
import AppLayout from '../../component/appLayout';
import {
  Group,
  Container,
  Paymentcontent,
  Paymentblock,
  Title5,
  Text2,
  Icon,
  Text4,
  PersonalHeader,
  Personaltext,
  Top,
  Middle,
  Textblocks,
  Row,
  Connectbtn,
  Text3,
  Header
} from './styles';
import Button from '../../component/button';
import { STRIPE_URL } from '../../global/environment';
import { useDispatch, useSelector } from 'react-redux';
import SVGIcon from '../../assets/images/svg/SVGIcon';
import { disconnectStripe, getCompanySettingIntegration } from '../../services/settingServices';
import { captureException } from '../../services/logService';
import { isEmpty } from '../../helpers/common';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';
import { ResponsiveHeader } from './SettingHeaderResponsive';
import { useMobileDevice } from '../../global/useMobile';

interface StripeCheck {
  stripe_connected?: boolean;
  company_name?: string;
}

const Payment: React.FC = () => {
  // States
  const [stripeCheck, setStripeCheck] = useState<StripeCheck>();
  const [loading, setLoading] = useState<boolean>(false);
  const [isMediaSize, setIsMediaSize] = useState(false);
  //use selector state variables
  const workspaceSelector = useSelector((state: RootReducerInterface) => state.workspace);
  const { workspace } = workspaceSelector;
  const { id: workspace_id } = workspace;
  // Other variables
  const dispatch = useDispatch();
  const isMobile = useMobileDevice();

  const checkStripeConnected = useCallback(async () => {
    if (!isEmpty(workspace_id)) {
      const result = await dispatch(getCompanySettingIntegration(workspace_id));
      setStripeCheck(result);
    }
  }, [dispatch, workspace_id]);

  useEffect(() => {
    checkStripeConnected();
  }, [checkStripeConnected]);

  const onClickStripe = useCallback(() => {
    try {
      setLoading(true);
      const newTab = window.open(STRIPE_URL, '_blank');
      if (newTab) {
        newTab.focus();
      } else {
        alert('Please allow pop-ups for this site to open in a new tab.');
      }
    } catch (e) {
      captureException(e);
    } finally {
      setLoading(false);
    }
  }, []);

  const onDisconnectStripe = useCallback(async () => {
    try {
      setLoading(true);
      const result = await dispatch(disconnectStripe(workspace_id));
      if (result) {
        checkStripeConnected();
      }
    } catch (e) {
      captureException(e);
    } finally {
      setLoading(false);
    }
  }, [checkStripeConnected, dispatch, workspace_id]);

  // for managing button size in props between 375px - 449px
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 449 && screenWidth > 375) {
        setIsMediaSize(true);
      } else {
        setIsMediaSize(false);
      }
    };

    handleResize(); // Check the screen size on initial render
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <AppLayout>
        <Group>
          <Container>
            <PersonalHeader>
              <Header>
                {isMobile && <ResponsiveHeader />}
                <Personaltext>Online Payment</Personaltext>
              </Header>
            </PersonalHeader>
            <Paymentcontent>
              <Paymentblock>
                <Middle>
                  <Title5> Set up Online Payment </Title5>
                  <Textblocks>
                    <Text2>
                      Get paid faster by letting your clients pay your invocies online. You can link an existing Stripe
                      or Paypal account, or start a new one.
                    </Text2>
                    <Text3 href='https://help.teamcamp.app/en/articles/9206920-setup-stripe-and-payment'>
                      Learn more
                    </Text3>
                  </Textblocks>
                </Middle>
              </Paymentblock>
              <Paymentblock>
                <Middle>
                  <Row>
                    <Icon>
                      <SVGIcon name='stripelogo' width='96' height='40' viewBox='0 0 96 40' className='fill-color' />
                    </Icon>
                    {stripeCheck?.stripe_connected && <Connectbtn>Connected</Connectbtn>}
                  </Row>

                  {stripeCheck?.stripe_connected ? (
                    <Text4>
                      You’re currently accepting <span>credit card payments</span> through the Stripe account{' '}
                      <span>{stripeCheck?.company_name || ''}</span>
                    </Text4>
                  ) : (
                    <Text4>
                      Allows your clients to pay your invoices with their credit card. Stripe fees will apply.
                    </Text4>
                  )}
                </Middle>
                {!stripeCheck?.stripe_connected ? (
                  <Button
                    type='button'
                    title='Connect with Stripe'
                    isLoading={loading}
                    onClick={onClickStripe}
                    modelbtn={isMediaSize ? true : false}
                  />
                ) : (
                  <Button
                    type='button'
                    secondary={true}
                    title='Disconnect Account'
                    isLoading={loading}
                    onClick={onDisconnectStripe}
                    hasNotBoxshadow
                    modelbtn={isMediaSize ? true : false}
                  />
                )}
              </Paymentblock>
              <Paymentblock>
                <Top>Coming Soon</Top>
                <Middle>
                  <Icon>
                    <SVGIcon name='paypall-icon' width='149' height='40' viewBox='0 0 149 40' />
                  </Icon>
                  <Text4>
                    Allows your clients to pay your invoices with their credit card or PayPal account. PayPal fees will
                    apply.
                  </Text4>
                </Middle>
                <Button disabled={true} title='Activate PayPal' modelbtn={isMediaSize ? true : false} />
              </Paymentblock>
            </Paymentcontent>
          </Container>
        </Group>
      </AppLayout>
    </>
  );
};

export default Payment;
