import { Redirect, Route } from 'react-router-dom';
import { setupToken } from '../helpers/authTokenHelpers';
import { trackPage } from '../services/analyticsService';

const PrivateRoute = (props: any) => {
  trackPage({ visit_url: props?.location?.pathname });
  const { component: Component, role, ...rest } = props;
  const token = setupToken();

  return (
    <Route
      {...rest}
      render={(renderProps: any) => {
        if (token) {
          return <Component {...renderProps} />;
        }
        // set return url if not home page
        const search =
          rest?.location?.pathname !== '/' ? `?return-url=${encodeURIComponent(rest?.location?.pathname)}` : '';
        return (
          <Redirect
            to={{
              pathname: '/membership/login',
              search: search
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
