import styled from 'styled-components';
import { Typography } from '../../../global/theme';
const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  border-style: hidden;
  border-radius: 8px;
  box-shadow: 0 0 0 0.5px var(--border-primary);
  background: var(--background-primary);
  td {
    border: 0.5px solid var(--border-primary);
    text-align: left;
    padding: 12px;
    ${Typography.body_md_medium}
    color: var(--text-secondary);
    span {
      ${Typography.body_md_semibold}
      color: var(--text-primary);
    }
  }
  th {
    border: 0.5px solid var(--border-primary);
    text-align: left;
    padding: 12px;
    ${Typography.body_md_medium}
    text-transform: uppercase;
    color: var(--text-secondary);
  }
  tr td:last-child {
    text-align: right;
    color: var(--brand-primary);
  }
  tr th:last-child {
    text-align: right;
  }
  tr td:first-child {
  }
`;
export { Table };
