import styled from 'styled-components';
import { Typography } from '../../../global/theme';

const Main = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 449px) {
    max-width: -webkit-fill-available;
  }
`;
const Header = styled.div`
  padding: 8px 12px;
  border-bottom: 0.5px solid var(--border-primary);
  ${Typography.body_md}
  color: var(--text-primary);
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 4px 0 12px;
  margin: 0 4px;
  .active {
    background-color: var(--neutral-secondary);
    border-radius: 4px;
  }
`;
const Top = styled.div`
  min-width: 238px;
  padding: 4px 6px;
  border-radius: 4px;
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  .mic-stroke-color {
    path {
      stroke: var(--text-primary);
    }
  }
  :hover {
    background-color: var(--neutral-secondary);
  }
`;
const Text = styled.div`
  ${Typography.body_md}
  color: var(--text-primary);
`;
const Buttondiv = styled.div`
  padding: 0 12px;
  @media (max-width: 449px) {
    button {
      width: 100%;
    }
  }
`;
export { Main, Header, Bottom, Top, Text, Buttondiv };
