import styled from 'styled-components';
import { Typography } from '../../../../../global/theme';

const Main = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -24px;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0px 8px 24px;
  height: 34px;
`;

const Leftheader = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;
const Rightheader = styled.div`
  display: flex;
  gap: 16px;
`;
const Dates = styled.div`
  ${Typography.body_lg_medium}
  color: var(--text-primary);
`;
const Icon = styled.div`
  display: flex;
`;
const Leftarrow = styled.div`
  width: 21px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .arrow-icon {
    path {
      stroke: var(--text-secondary);
    }
  }
  :hover {
    background-color: var(--neutral-secondary);
    border-radius: 4px;
  }
  .right-arrow-icon {
    padding-left: 3px;
    path {
      stroke: var(--text-secondary);
    }
  }
`;

const Buttons = styled.button`
  ${Typography.body_md}
  color: var(--text-primary);
  padding: 7px 12px;
  border: 0.5px solid var(--border-primary);
  background-color: transparent;
  border-radius: 8px;
  cursor: pointer;
  :hover {
    background-color: var(--background-primary);
  }
`;
const Cards = styled.div`
  display: flex;
  overflow: auto;
  margin-right: -24px;
  ::-webkit-scrollbar {
    height: 6px;
    background-color: var(--background-secondary);
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: var(--text-placeholder);
  }
`;
export { Main, Header, Leftheader, Rightheader, Dates, Icon, Leftarrow, Buttons, Cards };
