import React, { useEffect, useCallback, useState } from 'react';
import AppLayout from '../../component/appLayout';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllProjectTasks,
  getProjectIds,
  getTaskWithStatusDetails,
  groupTasksByFilter,
  loadMyTaskData,
  setFilterData
} from '../../services/taskServices';
import TasksData from '../../component/tasks';
import { captureException } from '../../services/logService';
import { isEmpty } from '../../helpers/common';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';
import TasksCacheService from '../../services/tasksCatchServices';
import UserPreferenceSingleton from '../../helpers/userPreferenceSingleton';
import { getUserPreferenceFieldData } from '../../helpers/firebaseHelper';
import { setMyTaskData, setOverDueTasks } from '../../actions/taskActions';
import moment from 'moment';

const MyTask: React.FC = () => {
  // States
  const [loading, setLoading] = useState(false);
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { task: taskSelector, workspace: workspaceSelector, project: projectSelector } = stateSelector || {};
  const { myTask } = taskSelector;
  const { workspace } = workspaceSelector;
  const { list: projectList } = projectSelector;
  // Other variables
  const dispatch = useDispatch();

  const loadBackgroundData = useCallback(async () => {
    try {
      const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();

      let filterData = await getUserPreferenceFieldData('my_task_filter');
      filterData = await setFilterData(filterData);
      const my_task_filter = filterData?.group_by;

      const projectIds = getProjectIds(projectList);
      await dispatch(getAllProjectTasks(true)).then(async () => {
        const updatedTasksFromLocal = await TasksCacheService.getInstance()?.getTasksByUser(
          userDetails?.id,
          projectIds
        );
        const updatedTasks = getTaskWithStatusDetails(updatedTasksFromLocal, projectList);
        const updatedGroupedData = await groupTasksByFilter(updatedTasks, my_task_filter);

        updatedGroupedData?.forEach((group) => {
          group.tasks.sort((a, b) => new Date(a?.dueDate).getTime() - new Date(b?.dueDate).getTime());
        });

        dispatch(setMyTaskData(updatedGroupedData || []));
        const updatedDelayedTasks = updatedTasks.filter(
          (task) => task?.dueDate && moment(task.dueDate).startOf('day').isBefore(moment().startOf('day'))
        );
        dispatch(setOverDueTasks(updatedDelayedTasks.length || 0));
      });
    } catch (err) {
      console.log('error', err);
    }
  }, [dispatch, projectList]);

  const loadData = useCallback(async () => {
    try {
      if (!isEmpty(workspace?.id)) {
        setLoading(true);

        //call loadTaskDataFromLocal service
        await dispatch(loadMyTaskData(false));
        setLoading(false);
        // Call API in the background
        loadBackgroundData();
      }
    } catch (e) {
      captureException(e);
      console.log('error', e);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, workspace?.id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <AppLayout>
      <TasksData
        data={myTask}
        loading={loading}
        isMyTask={true}
        loadLocalData={loadData}
        loadData={loadBackgroundData}
      />
    </AppLayout>
  );
};

export default MyTask;
