import React from 'react';
import { Bottom, First, Left, Main, Right, Square, Top } from './styles';

export default function Apiloading() {
  return (
    <div>
      <Main>
        <First>
          <Left>
            <Top className='loading-animation' />
            <Bottom className='loading-animation' />
          </Left>
          <Right>
            <Square className='loading-animation' />
            <Square className='loading-animation' />
          </Right>
        </First>
        <First>
          <Left>
            <Top className='loading-animation' />
            <Bottom className='loading-animation' />
          </Left>
          <Right>
            <Square className='loading-animation' />
            <Square className='loading-animation' />
          </Right>
        </First>
        <First>
          <Left>
            <Top className='loading-animation' />
            <Bottom className='loading-animation' />
          </Left>
          <Right>
            <Square className='loading-animation' />
            <Square className='loading-animation' />
          </Right>
        </First>
        <First>
          <Left>
            <Top className='loading-animation' />
            <Bottom className='loading-animation' />
          </Left>
          <Right>
            <Square className='loading-animation' />
            <Square className='loading-animation' />
          </Right>
        </First>
        <First>
          <Left>
            <Top className='loading-animation' />
            <Bottom className='loading-animation' />
          </Left>
          <Right>
            <Square className='loading-animation' />
            <Square className='loading-animation' />
          </Right>
        </First>
      </Main>
    </div>
  );
}
