import React from 'react';
import SVGIcon from '../../assets/images/svg/SVGIcon';
import { Buttons } from './styles';

interface Props {
  onClick?: () => void;
  className?: string;
}

const Resbutton: React.FC<Props> = (Props) => {
  const { onClick, className, ...props } = Props;
  return (
    <Buttons onClick={onClick} className={className} {...props}>
      <SVGIcon name='plus-btn' width='26' height='26' viewBox='0 0 26 26' className='fill-color' />
    </Buttons>
  );
};

export default Resbutton;
