import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SVGIcon from '../../assets/images/svg/SVGIcon';
import AppLayout from '../../component/appLayout';
import {
  Headerblock,
  Heading,
  Icon,
  Title,
  Datesider,
  Leftblock,
  Siderbox,
  Datetitle,
  Timetitle,
  Rightblock,
  Timetraking,
  Maincontent,
  Topicdata,
  LeftContant,
  RightContant,
  Topicdescription,
  Topictitle,
  Timestop,
  Iconborder,
  Trackingcontent,
  Middleline,
  Icon1,
  SvgDiv,
  ResponsiveNavbarIcon,
  ResponsiveHeader,
  Empty,
  TimelogIcon,
  RightsideIcon
} from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  getActiveTime,
  getTimeLogs,
  loadEditTimeLog,
  startTracker,
  stopTracker,
  updateTimeLogDetails
} from '../../services/timeTrackingServices';
import { floatToHHMM, getDateObjecttoDDMMM, getDateString, isEmpty } from '../../helpers/common';
import {
  clearCurrentTimeTracking,
  clearTimeEntryInput,
  setCurrentTimeTracking,
  setTimeEntryInput,
  setTimeLogData,
  updateTaskData
} from '../../actions/timeTrackingActions';
import AddNewTimeMoadal from '../../component/models/addNewTimeModel/addNewTimeModal';
import TimeLoading from '../../component/loading/timeTrackingLoading';
import moment from 'moment';
import EmptyState from '../../component/emptyState';
import trackingempty from '../../assets/images/emptystates/tracking-empty.svg';
import darktimetrackingempty from '../../assets/images/emptystates/darktimetrackingempty.svg';
import { LogDataInterface, TimeTrackingDataInterface } from '../../interfaces/TimeTrackingInterface';
import Button from '../../component/button';
import ModalCustom from '../../component/models/modal';
import Resbutton from '../../component/resbutton/resbutton';
import UserPreferenceSingleton from '../../helpers/userPreferenceSingleton';
import { nanoid } from 'nanoid';
import { setSuccessMessage } from '../../actions/messageActions';
import ConfirmationModal from '../../component/models/confirmationModal';
import { captureException } from '../../services/logService';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';
import Responsivnavbar from '../../component/navbar/responsivenavbar';
import { useHistory } from 'react-router-dom';

const TimeTracking: React.FC = () => {
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const {
    settings: settingsSelector,
    timeTracking: timeTrackingSelector,
    workspace: workspaceSelector
  } = stateSelector || {};
  const { workspace } = workspaceSelector;
  const { id: workspace_id } = workspace;
  const { timeTackingData, currentTimeTracking, loading, trackingActivity } = timeTrackingSelector;
  const { themeMode } = settingsSelector;
  // Other variables
  const curr = new Date();
  const first = new Date(curr);
  const last = new Date(curr);
  if (curr.getDay() === 0) {
    first.setDate(curr.getDate() - 6); // Set to Monday of the previous week
    last.setDate(curr.getDate()); // Set to today (Sunday)
  } else {
    // Calculate the first day of the week (Monday)
    first.setDate(curr.getDate() - curr.getDay() + 1);
    // Calculate the last day of the week (Sunday)
    last.setDate(curr.getDate() - curr.getDay() + 7);
  }
  const firstDay = first.toUTCString();
  const lastDay = last.toUTCString();
  const dispatch = useDispatch();
  const history = useHistory();

  // States
  const [startDate, setStartDate] = useState(firstDay);
  const [endDate, setEndDate] = useState(lastDay);
  const [loadings, setLoadings] = useState(false);
  const [selectedTracker, setSelectedTracker] = useState<LogDataInterface>();
  const [alertModelOpen, setAlertModalOpen] = useState(false);
  const [alertLoading, setAlertLoading] = useState(false);
  const [open, setOpen] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getImageSource = () => (themeMode?.theme === 'dark' ? darktimetrackingempty : trackingempty);

  // handle icon click
  const handleIconClick = useCallback(() => {
    history.push('/timeTracking/myTimelog');
  }, [history]);

  // load initial data
  const loadData = useCallback(
    async (startDate: string, endDate: string, activeDate: string | undefined) => {
      if (!isEmpty(workspace_id) && startDate && endDate) {
        setLoadings(true);
        await dispatch(getTimeLogs(workspace_id, startDate, endDate, activeDate));
        setLoadings(false);
      }
    },
    [dispatch, workspace_id]
  );

  useEffect(() => {
    const activeDate = getDateString(currentTimeTracking?.date ? new Date(currentTimeTracking?.date) : new Date());
    loadData(startDate, endDate, activeDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace_id]);

  useEffect(() => {
    return () => {
      dispatch(setTimeLogData([]));
      dispatch(clearCurrentTimeTracking());
    };
  }, [dispatch]);

  // Sets the current time tracking item if it's active.
  const setClickTimeTab = useCallback(
    (item: TimeTrackingDataInterface) => {
      if (!item?.active) return;
      dispatch(setCurrentTimeTracking(item));
    },
    [dispatch]
  );

  // for handle left side button click
  const onClickLeft = useCallback(() => {
    let currentIndex = currentTimeTracking?.index;
    if (currentIndex === 0) {
      dispatch(clearCurrentTimeTracking());
      const rightDate = moment(new Date(currentTimeTracking?.date)).add(-1, 'days').toLocaleString();
      const leftDate = moment(new Date(currentTimeTracking?.date)).add(-7, 'days').toLocaleString();
      setStartDate(leftDate);
      setEndDate(rightDate);
      loadData(leftDate, rightDate, getDateString(rightDate));
    }
    let newObj = timeTackingData[currentIndex - 1];
    if (!newObj?.active) return;
    dispatch(setCurrentTimeTracking(newObj));
  }, [timeTackingData, currentTimeTracking, dispatch, loadData]);

  // for handle right side button click
  const onClickRight = useCallback(() => {
    let currentIndex = currentTimeTracking?.index;
    if (currentIndex === 6) {
      dispatch(clearCurrentTimeTracking());
      const leftDate = moment(new Date(currentTimeTracking?.date)).add(1, 'days').toLocaleString();
      const rightDate = moment(new Date(currentTimeTracking?.date)).add(8, 'days').toLocaleString();
      setStartDate(leftDate);
      setEndDate(rightDate);
      loadData(leftDate, rightDate, getDateString(leftDate));
    }
    let newObj = timeTackingData[currentIndex + 1];
    if (!newObj?.active) return;
    dispatch(setCurrentTimeTracking(timeTackingData[currentIndex + 1]));
  }, [timeTackingData, currentTimeTracking, dispatch, loadData]);

  // function for start time
  const startTime = useCallback(
    async (item: LogDataInterface) => {
      if (!loading) {
        if (trackingActivity?.length > 0) {
          setSelectedTracker(item);
          setAlertModalOpen(true);
          return;
        }

        const payloadUpdate = {
          TimerStartedAt: new Date().toISOString()
        };
        const result = await dispatch(updateTimeLogDetails(item?.id, payloadUpdate));
        if (result) {
          dispatch(startTracker(item?.taskId));
          dispatch(
            updateTaskData({
              ...item,
              timer_started_at: result?.data?.TimerStartedAt
            })
          );
          dispatch(setSuccessMessage('Time Started!'));
        }
        await dispatch(getActiveTime());
      }
    },
    [dispatch, trackingActivity, loading]
  );

  // for manage stop timer click
  const onClickStop = useCallback(
    async (item: LogDataInterface, startingTime: string) => {
      if (!loading) {
        const date1 = new Date(startingTime).getTime();
        const date2 = new Date().getTime();
        const timeDifference = date2 - date1; // Difference in milliseconds
        const hoursDifference = timeDifference / (1000 * 60 * 60);
        const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();

        const historyHoursSum = item?.history?.reduce((total, historyItem) => {
          return total + (historyItem.Hours || 0);
        }, 0);

        const payloadUpdate = {
          Hours: Number(Number(historyHoursSum || 0) + hoursDifference),
          TimerStartedAtUnset: true,
          History: [
            ...(item?.history || []),
            {
              _id: nanoid(),
              CreatedBy: userDetails?.id,
              CreatedTime: new Date().toISOString(),
              UpdatedBy: userDetails?.id,
              UpdatedTime: new Date().toISOString(),
              TimerStartedAt: startingTime,
              TimerEndedAt: new Date().toISOString(),
              Hours: hoursDifference
            }
          ]
        };
        const result = await dispatch(updateTimeLogDetails(item?.id, payloadUpdate));

        if (result) {
          dispatch(stopTracker());
          dispatch(
            updateTaskData({
              ...item,
              timer_started_at: result?.data?.TimerStartedAt,
              hours: result?.data?.Hours
            })
          );
          dispatch(setSuccessMessage('Time Stopped!'));
        }
        await dispatch(getActiveTime());
      }
    },
    [dispatch, loading]
  );

  // for open add modal
  const onOpenAddModal = useCallback(() => {
    setOpen(true);
  }, []);

  // close alert model
  const closeAlertModal = useCallback(() => {
    setAlertModalOpen(false);
  }, []);

  // Loads the edit time log and opens the add time log modal.
  const onClickLogTime = useCallback(async () => {
    await dispatch(loadEditTimeLog());
    onOpenAddModal();
  }, [dispatch, onOpenAddModal]);

  // close add model
  const closeAddModel = useCallback(() => {
    setOpen(false);
    dispatch(clearTimeEntryInput());
    loadData(startDate, endDate, currentTimeTracking?.formated_date);
  }, [dispatch, loadData, currentTimeTracking, startDate, endDate]);

  // edit time entry
  const onClickEdit = useCallback(
    async (item: LogDataInterface) => {
      dispatch(setTimeEntryInput(item));
      await dispatch(loadEditTimeLog());
      onOpenAddModal();
    },
    [dispatch, onOpenAddModal]
  );

  // manage stop and start time
  const onClickStopAndStartTime = useCallback(async () => {
    try {
      setAlertLoading(true);
      if (!loading) {
        const date1 = new Date(trackingActivity[0]?.TimerStartedAt).getTime();
        const date2 = new Date().getTime();
        const timeDifference = date2 - date1; // Difference in milliseconds
        const hoursDifference = timeDifference / (1000 * 60 * 60);
        const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
        const itemId = trackingActivity[0]?.['_id'];
        const historyUpdated = trackingActivity[0]?.History?.map((item) => {
          const { __typename, ...other } = item;
          return other;
        });

        const historyHoursSum = trackingActivity[0]?.History?.reduce((total, historyItem) => {
          return total + (historyItem.Hours || 0);
        }, 0);

        const payloadpayloadPrevious = {
          Hours: Number(Number(historyHoursSum || 0) + hoursDifference),
          TimerStartedAtUnset: true,
          History: [
            ...(historyUpdated || []),
            {
              _id: nanoid(),
              CreatedBy: userDetails?.id,
              CreatedTime: new Date().toISOString(),
              UpdatedBy: userDetails?.id,
              UpdatedTime: new Date().toISOString(),
              TimerStartedAt: trackingActivity[0]?.TimerStartedAt,
              TimerEndedAt: new Date().toISOString(),
              Hours: hoursDifference
            }
          ]
        };
        await dispatch(updateTimeLogDetails(itemId, payloadpayloadPrevious));

        const payload = {
          TimerStartedAt: new Date().toISOString()
        };
        const result = await dispatch(updateTimeLogDetails(selectedTracker?.id, payload));
        if (result) {
          dispatch(startTracker(selectedTracker?.taskId));
          dispatch(
            updateTaskData({
              ...selectedTracker,
              timer_started_at: result?.data?.TimerStartedAt
            })
          );
          setAlertModalOpen(false);
          dispatch(setSuccessMessage('Time Started!'));
          await dispatch(getActiveTime());
          await dispatch(getTimeLogs(workspace_id, startDate, endDate, getDateString(new Date())));
        }
      }
    } catch (error) {
      captureException(error);
      console.log('error', error);
      setAlertLoading(false);
    } finally {
      setAlertLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, loading, selectedTracker, trackingActivity, workspace_id]);

  // render current time tracking data
  const renderCurrentTimeTrackingLogData = useMemo(() => {
    if (currentTimeTracking?.logData?.length === 0 && (loading || loadings)) {
      return <TimeLoading />;
    } else {
      return (
        <Trackingcontent>
          <Datesider>
            <Leftblock onClick={onClickLeft} isDisabled={loading}>
              <SvgDiv>
                <SVGIcon
                  name='slider-left-icon'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='var(--text-secondary)'
                />
              </SvgDiv>
            </Leftblock>
            <Middleline>
              {timeTackingData?.map((item) => {
                return (
                  <div key={item?.day} className='box'>
                    <Siderbox
                      isDisabled={!item?.active}
                      isCurrent={item?.formated_date === currentTimeTracking?.formated_date}
                      onClick={() => setClickTimeTab(item)}>
                      <Datetitle isDisabled={!item?.active}>{item?.day}</Datetitle>
                      <Timetitle isDisabled={!item?.active}>{floatToHHMM(item?.hours)}</Timetitle>
                    </Siderbox>
                  </div>
                );
              })}
            </Middleline>
            <Rightblock onClick={onClickRight}>
              <SvgDiv>
                <SVGIcon
                  name='slider-right-icon'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='var(--text-secondary)'
                />
              </SvgDiv>
            </Rightblock>
          </Datesider>

          {currentTimeTracking?.logData?.length === 0 && !loading && (
            <Empty>
              <EmptyState
                header={'You have no active timelogs currently'}
                title={'Click the button below to get started'}
                image={getImageSource()}
                name={'Log Time'}
                onButtonClick={onClickLogTime}
              />
            </Empty>
          )}
          {currentTimeTracking?.logData?.length > 0 && (
            <Maincontent>
              {currentTimeTracking?.logData?.map((element) => {
                return (
                  <Topicdata key={element?.id}>
                    <LeftContant>
                      <Topictitle>{element?.projectName}</Topictitle>
                      <Topicdescription>
                        {element?.taskGroupName} {element?.taskName} {element?.notes}
                      </Topicdescription>
                    </LeftContant>
                    <RightContant>
                      <Timetitle>
                        <span>{floatToHHMM(element?.hours)}</span>
                      </Timetitle>
                      {!element?.isInvoiced && (
                        <>
                          {element?.timer_started_at ? (
                            <Timestop onClick={() => onClickStop(element, element?.timer_started_at)}>
                              <SVGIcon
                                name='time-start-icon'
                                width='24'
                                height='25'
                                viewBox='0 0 24 25'
                                fill='var(--text-muted)'
                              />
                            </Timestop>
                          ) : (
                            <Timestop onClick={() => startTime(element)}>
                              <SVGIcon
                                name='time-stop-icon'
                                width='24'
                                height='25'
                                viewBox='0 0 24 25'
                                className='time-stop-icon'
                              />
                            </Timestop>
                          )}
                        </>
                      )}
                      {element?.isInvoiced ? (
                        <>
                          <Iconborder>
                            <SVGIcon
                              name='lock-icon'
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='var(--text-secondary)'
                              className='lock-icon'
                            />
                          </Iconborder>
                        </>
                      ) : (
                        <Icon1 onClick={() => onClickEdit(element)}>
                          <SVGIcon name='edit-icon' width='18' height='19' viewBox='0 0 18 19' fill='none' />
                        </Icon1>
                      )}
                    </RightContant>
                  </Topicdata>
                );
              })}
            </Maincontent>
          )}
        </Trackingcontent>
      );
    }
  }, [
    currentTimeTracking?.formated_date,
    currentTimeTracking?.logData,
    getImageSource,
    loading,
    loadings,
    onClickEdit,
    onClickLeft,
    onClickLogTime,
    onClickRight,
    onClickStop,
    setClickTimeTab,
    startTime,
    timeTackingData
  ]);

  return (
    <>
      <AppLayout>
        <Timetraking>
          <Headerblock>
            <ResponsiveHeader>
              <ResponsiveNavbarIcon>
                <Responsivnavbar />
              </ResponsiveNavbarIcon>
              <Heading>
                <Icon className='timeheader'>
                  <SVGIcon
                    name='time-header-icon'
                    width='28'
                    height='28'
                    viewBox='0 0 24 24'
                    className='time-header-icon'
                  />
                </Icon>
                {currentTimeTracking?.date && (
                  <Title>
                    {currentTimeTracking?.day}, {getDateObjecttoDDMMM(currentTimeTracking?.date)}
                  </Title>
                )}
              </Heading>
            </ResponsiveHeader>
            <RightsideIcon>
              <TimelogIcon onClick={handleIconClick}>
                <SVGIcon name='my-timelog-icon' width='18' height='18' viewBox='0 0 18 18' className='mytimelog-icon' />
              </TimelogIcon>
              <Resbutton onClick={onClickLogTime} />
              <div className='hidebutton'>
                <Button
                  title='Log Time'
                  onClick={onClickLogTime}
                  iconName='plus-icon'
                  type='button'
                  iconSize={18}
                  iconViewBox='0 0 18 18'
                  iconColor='#fff'
                />
              </div>
            </RightsideIcon>
          </Headerblock>
          {renderCurrentTimeTrackingLogData}
          <ModalCustom open={open} onClose={closeAddModel} isTracker={true}>
            <AddNewTimeMoadal onCloseModal={closeAddModel} loadData={() => loadData} />
          </ModalCustom>
          <ModalCustom open={alertModelOpen} onClose={closeAlertModal} width={334}>
            <ConfirmationModal
              loading={alertLoading}
              onOk={onClickStopAndStartTime}
              onClose={closeAlertModal}
              modaltitle='Another timer is running.'
              description='Do you want to stop and start the timer on this task?'
            />
          </ModalCustom>
        </Timetraking>
      </AppLayout>
    </>
  );
};

export default TimeTracking;
