import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Group, Content, Header, Icon, Title, Organization, Boxcontent, Icon1, Buttons } from './styles';
import { getWorkspaceList } from '../../../services/workspaceService';
import { setCurrentWorkspace } from '../../../actions/workspaceActions';
import UserPreferenceSingleton from '../../../helpers/userPreferenceSingleton';
import { useHistory } from 'react-router-dom';
import { clearPreviousWorkspaceData } from '../../../services/baseServices';
import { WorkspaceInterfase } from '../../../interfaces/WorkspaceInterface';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import Avatar from '../../../component/avatar/Avatar';
import { appInit } from '../../../services/appService';
import { ROUTES } from '../../../routes/urls';
import { captureException } from '../../../services/logService';
import AppLogo from '../../../component/appLogo';
import { setWorksapceIdInAxios } from '../../../helpers/authTokenHelpers';
import { workspaceSetAnalytics } from '../../../services/analyticsService';
import { updateUserPreference } from '../../../helpers/firebaseHelper';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import { isDev } from '../../../helpers/common';
import WorkSpaceLoading from '../../../component/loading/workSpaceLoading';
import { rootStore } from '../../../mobx/rootStore';

const SelectWorkspace: React.FC = () => {
  // States
  const [loading, setLoading] = useState(false);
  //use selector state variables
  const workspaceSelector = useSelector((state: RootReducerInterface) => state.workspace);
  const { list } = workspaceSelector;
  // Other variables
  const dispatch = useDispatch();
  const history = useHistory();

  // load initial data
  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      await dispatch(getWorkspaceList());
    } catch (e) {
      captureException(e);
      console.log('Error', e);
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  // Select workspace
  const selectWorkspace = useCallback(
    async (item: WorkspaceInterfase) => {
      try {
        setLoading(true);
        if (!item?.id) return;
        dispatch(setCurrentWorkspace(item));
        rootStore.workspaceStore.setCurrentWorkspace(item);
        setWorksapceIdInAxios(item?.id);
        workspaceSetAnalytics(item?.id);
        await UserPreferenceSingleton.getInstance().setWorkspace(item);
        if (!isDev()) await updateUserPreference({ current_workspace_id: item?.id });
        const clearResult = await dispatch(clearPreviousWorkspaceData());
        const initResult = await dispatch(appInit());
        const intercomPayload = {
          company: {
            name: item?.name,
            id: item?.id
          }
        };
        if (window && typeof (window as any)?.Intercom === 'function') {
          (window as any).Intercom('update', intercomPayload);
        }
        if ((window as any).posthog) {
          (window as any).posthog.group('Workspace', intercomPayload.company?.id, {
            name: intercomPayload.company?.name
          });
        }
        if (clearResult && initResult) history.push('/');
      } catch (e) {
        captureException(e);
        console.log('ERROR', e);
      } finally {
        setLoading(false);
      }
    },
    [dispatch, history]
  );

  // Redirect on selected workspace
  const redirectSelectedWorkspace = useCallback(() => {
    history.push(ROUTES.WORKSPACE);
  }, [history]);

  // List of organization
  const organizationList = useMemo(() => {
    if (!list?.length) return null;
    return list?.map((item) => (
      <Boxcontent key={item?.id} onClick={() => selectWorkspace(item)} disabled={loading}>
        <Icon1>
          <Avatar imgSrc={item?.logoUrl || ''} name={item?.name} size={38} />
        </Icon1>
        <div className='name-text'>{item?.name}</div>
      </Boxcontent>
    ));
  }, [list, loading, selectWorkspace]);

  return (
    <>
      <Group isLoading={loading} className='Organisation-group'>
        {loading ? (
          <WorkSpaceLoading />
        ) : (
          <>
            <Content>
              <Header>
                <Icon>
                  <AppLogo height='60' />
                </Icon>
                <Title> Select Organisation</Title>
              </Header>
              <Organization>{organizationList}</Organization>
              <div className='button'>
                <Buttons onClick={redirectSelectedWorkspace}>
                  <SVGIcon
                    name='workplace-icon'
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    fill='none'
                    className='stroke-icon'
                  />
                  <p>Add New Workspace</p>
                </Buttons>
              </div>
            </Content>
          </>
        )}
      </Group>
    </>
  );
};

export default SelectWorkspace;
