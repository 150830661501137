import styled from 'styled-components';

const CommentInputSection = styled.div`
  background-color: var(--background-primary);
  box-shadow: var(--shadow-card);
  padding: 12px;
  border: 0.5px solid var(--border-primary);
  border-radius: 12px;
  position: relative;
  margin-bottom: 12px;
  padding-top: 7px;
  .tiptap {
    min-height: 36px;
    max-height: 240px;
    overflow: auto;
    ::-webkit-scrollbar {
      background-color: var(--text-white);
      width: 6px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: var(--text-tertiary);
      width: 6px;
      margin-bottom: 4px;
      border-radius: 7px;
      min-height: 20px;
    }
  }
  p + .figma-main-div {
    margin-top: 10px;
  }
  p + .prnt-preview-main-div {
    margin-top: 10px;
  }
  p + .youtube-preview-main-div {
    margin-top: 10px;
  }
  p + .screen-recording-custom-main-div {
    margin-top: 10px;
  }
  .icon {
    width: 18px;
    height: 18px;
    stroke: var(--text-secondary);
  }
  .text {
    p + img {
      margin: 10px 0 0;
    }
  }
`;

const IconSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 7px;
  margin-top: 6px;
  .active {
    svg {
      fill: var(--text-primary);
    }
  }
`;
const Icon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  border: 0.5px solid transparent;
  &:hover {
    background: var(--neutral-secondary);
    border-radius: 50%;
    svg path {
      fill: var(--text-primary);
    }
  }
  .svg-icon {
    fill: var(--text-secondary);
  }
`;
const Uploaddiv = styled.div`
  color: var(--text-primary);
`;

const FileBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 0;
  padding: 0;
`;

export { CommentInputSection, IconSection, Icon, Uploaddiv, FileBox };
