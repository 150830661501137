import styled, { css } from 'styled-components';
import { Typography } from '../../global/theme';
interface Props {
  isLast?: boolean;
  isFirst?: boolean;
  isOnlyOneItem?: boolean;
  isSearch?: boolean;
  isSearchInputIcon?: boolean;
  isCloseInputIcon?: boolean;
}
const Group = styled.div`
  width: 100%;
  max-width: 1022px;
  margin: auto;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 449px) {
    padding-top: 12px;
  }
`;

const Projectitem = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  padding: 16px;
  background-color: var(--background-primary);
  border-bottom: 0.5px solid var(--border-primary);
  cursor: pointer;
  :hover {
    border-bottom: 0.5px solid var(--border-primary);
    background: var(--neutral-secondary);
    .plus-icon {
      border: 2px solid var(--neutral-secondary);
    }
    .avtar {
      border: 2px solid var(--neutral-secondary);
    }
  }
  :last-child {
    border-bottom: none;
    border-radius: 0 0 12px 12px;
  }
  :first-child {
    border-radius: 12px 12px 0 0;
  }
  ${(props: Props) =>
    props.isOnlyOneItem &&
    css`
      border-radius: 12px !important;
    `}
  ${(props: Props) =>
    props.isFirst
      ? css`
          border-radius: 12px 12px 0 0;
          border-bottom: 0.5px solid var(--border-primary);
        `
      : props.isLast &&
        css`
          border-radius: 0 0 12px 12px;
        `}
  ${(props: Props) =>
    props.isFirst &&
    props.isLast &&
    css`
      border-radius: 12px 12px 12px 12px;
      border-bottom: 0.5px solid var(--border-primary);
    `}
     @media (max-width: 449px) {
    flex-direction: column;
    gap: 6px;
    position: relative;
  }
  @media (max-width: 449px) {
    &.admin-project {
      align-items: flex-start;
    }
  }
`;

const ProjectName = styled.div`
  max-width: 540px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  @media (max-width: 449px) {
    gap: 12px;
  }
`;
const Icon = styled.div`
  cursor: pointer;
  display: flex;
  padding: 4px;
  .star-icon {
    fill: var(--text-secondary);
  }
  :hover {
    background-color: var(--background-muted);
    border-radius: 4px;

    svg {
      filter: brightness(var(--brightness-value2));
    }
    .star-icon {
      fill: var(--text-primary);
    }
  }
  @media (max-width: 449px) {
    padding: 7px;
    svg {
      width: 18px;
      height: 18px;
    }
  }
`;

const Title = styled.h1`
  ${Typography.body_lg_semibold}
  color: var(--text-primary);
  margin: 0;
  cursor: pointer;
`;

const Text = styled.p`
  ${Typography.body_md}
  color: var(--text-secondary);
  margin: 0;
  @media (max-width: 449px) {
    margin-right: 20px;
  }
`;

const Point = styled.div`
  position: relative;
  width: 100%;
  max-width: 230px;
  small {
    position: absolute;
    border-radius: 50%;
    background-color: ${(props: { color: string }) => props.color};
    top: 6px;
    width: 10px;
    height: 10px;
    left: -0.5px;
  }
  span {
    ${Typography.body_sm}
    color: var(--text-primary);
    margin-left: 16px;
    @media (max-width: 449px) {
      margin: 0 0 0 18px;
    }
  }
  @media (max-width: 449px) {
    max-width: -webkit-fill-available;
    margin-left: 30px;
  }
`;

const Assigngroup1 = styled.div`
  width: 100%;
  max-width: 140px;
  @media (max-width: 449px) {
    max-width: -webkit-fill-available;
    margin-left: 36px;
    &.admin-assign {
      margin-left: 8px;
    }
  }
  ul {
    display: flex;
    flex-wrap: nowrap;
    list-style-type: none;
    padding: 0;
    margin: 0;
    justify-content: flex-end;

    @media (max-width: 449px) {
      justify-content: flex-start;
    }
  }

  li {
    margin-left: -10px;
  }

  small {
    padding: 5px;
    border-radius: 20px;
    background: var(--text-secondary);
    border: 2px solid var(--background-primary);
  }

  span {
    ${Typography.body_xs_semibold}
    color: var(--text-primary);
  }

  .plus-icon {
    width: 20px;
    height: 20px;
    border-radius: 24px;
    align-items: center;
    justify-content: center;
    color: white;
    display: flex;
    background-color: var(--text-secondary);
    ${Typography.body_xs_semibold}
    border: 2px solid var(--background-primary);
    position: relative;
    top: 0;
  }
`;

const Modalhead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 0.5px solid var(--border-primary);
`;

const Headline = styled.h6`
  ${Typography.body_lg_semibold}
  color: var(--text-primary);
  margin: 0;
`;

const Topcontent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const Bottomcontent = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 12px; */
`;
const Projectlist = styled.div`
  border: 0.5px solid var(--border-primary);
  border-radius: 12px;
  background-color: var(--background-secondary);
  margin-bottom: 20px;
`;
const Titletext = styled.div`
  color: var(--text-primary);
  ${Typography.body_lg_semibold}
`;

const Loadingstate = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const Div = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  @media (max-width: 449px) {
    gap: 4px;
    align-items: center;
  }
`;
const Emptybox = styled.div`
  display: flex;
  height: calc(100vh - 70px);
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  @media (max-width: 449px) {
    height: calc(100vh - 88px);
  }
`;
const ShowHideText = styled.div`
  ${Typography.body_md}
  color: var(--text-secondary);
  text-align: center;
  padding-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  span {
    text-decoration: underline;
    cursor: pointer;
  }
`;
const Rightdiv = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  @media (max-width: 449px) {
    flex-direction: column;
    gap: 6px;
    align-items: flex-start;
  }
`;

const BottomHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
  @media (max-width: 449px) {
    padding-bottom: 8px;
  }
`;

const FilterDiv = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  flex-direction: row;
  .active {
    background-color: var(--neutral-secondary);
    border-radius: 34px;
  }
`;

const SVGDiv = styled.div<Props>`
  padding: 5px;
  display: flex;
  align-items: center;
  position: relative;
  :hover {
    cursor: pointer;
    background-color: var(--neutral-secondary);
    border-radius: 34px;
    @media (max-width: 449px) {
      background-color: transparent;
    }
  }
  .filters-icon-color {
    fill: var(--text-secondary);
  }
  .search-icon {
    fill: var(--text-secondary);
  }
  ${(props: Props) =>
    props.isSearchInputIcon &&
    css`
      position: absolute;
      padding: 5px 5px 5px 8px;
      :hover {
        background-color: transparent;
      }
      .search-icon-active {
        fill: var(--text-primary);
      }
    `}
  ${(props: Props) =>
    props.isCloseInputIcon &&
    css`
      position: absolute;
      right: 0;
      top: 0;
      padding: 4px 4px 4px 5px;
      :hover {
        background-color: transparent;
      }
      .svgicon {
        stroke: var(--text-secondary);
      }
    `}
`;

const SearchArea = styled.div<Props>`
  position: relative;
  display: flex;
  align-items: center;
  width: ${(props) => (props.isSearch ? '200px' : '26px')};
  transition: width 0.5s ease;
  height: 26px;
  @media (max-width: 320px) {
    width: ${(props) => (props.isSearch ? '164px' : '26px')};
  }
`;

const InputArea = styled.input<Props>`
  width: ${(props) => (props.isSearch ? '100%' : '0')};
  border-radius: 6px;
  border: 0.5px solid var(--brand-primary);
  padding: ${(props) => (props.isSearch ? '5px 27px 5px 29px' : '0')};
  ${Typography.body_sm}
  animation: width 0.5s;
  outline: none;
  background: var(--background-primary);
  color: var(--text-primary);
`;

const MoreIcon = styled.div`
  position: relative;
  .projectlist-more-icon {
    cursor: pointer;
    fill: var(--text-secondary);
    stroke: var(--text-secondary);
    :hover {
      background-color: var(--background-muted);
      border-radius: 4px;
    }
  }
  height: 18px;
  @media (max-width: 449px) {
    position: absolute;
    top: 16px;
    right: 16px;
  }
`;

const MoreOptionSection = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 18px;
  max-width: 140px;
  width: 100%;
  .active {
    background-color: var(--background-muted);
    border-radius: 4px;
  }
  @media (max-width: 449px) {
    max-width: 100%;
  }
`;
const SVGIconDiv = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  .filters-icon-color {
    fill: var(--text-secondary);
    padding: 5px;
    :hover {
      cursor: pointer;
      background-color: var(--neutral-secondary);
      border-radius: 34px;
      @media (max-width: 449px) {
        background-color: transparent;
      }
    }
  }
`;

export {
  Group,
  Container,
  Projectitem,
  ProjectName,
  Icon,
  Title,
  Text,
  Point,
  Assigngroup1,
  Modalhead,
  Headline,
  Bottomcontent,
  Topcontent,
  Projectlist,
  Titletext,
  Loadingstate,
  Div,
  Emptybox,
  ShowHideText,
  Rightdiv,
  BottomHeader,
  FilterDiv,
  SVGDiv,
  SearchArea,
  InputArea,
  MoreIcon,
  MoreOptionSection,
  SVGIconDiv
};
