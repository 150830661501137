import styled from 'styled-components';
import { Typography } from '../../../global/theme';

const Modalbox = styled.div`
  max-width: 462px;
  width: 100%;
  background-color: var(--background-primary);
  box-shadow: var(--shadow-modal);
  border-radius: 12px;
`;
const Div = styled.div`
  display: flex;
  flex-direction: column;
`;
const Modalheader = styled.div`
  display: flex;
  gap: 20px;
  padding: 12px 16px;
  justify-content: space-between;
  border-bottom: 0.5px solid var(--border-primary);
`;
const Leftdiv = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
`;
const Title = styled.div`
  ${Typography.heading_md}
  color: var(--text-primary);
`;

const Heading = styled.div`
  ${Typography.body_sm}
  color: var(--text-secondary);
`;
const Rightdiv = styled.div`
  display: inline-flex;
  width: 18px;
  height: 18px;
  .close-icon {
    cursor: pointer;
    path {
      stroke: var(--text-primary);
    }
  }
  :hover {
    background-color: var(--neutral-secondary);
    border-radius: 4px;
  }
`;
const Bottom = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 2px;
`;
const Option = styled.div`
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;
const Options = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;
const Leftoptiondiv = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const Icon = styled.div`
  width: 18px;
  height: 18px;
  .global-icon {
    path {
      fill: var(--text-primary);
    }
  }
  .export-icon {
    width: 18px;
    height: 18px;
    path {
      stroke: var(--text-primary);
    }
  }
  .copy-icon {
    path {
      stroke: var(--text-primary);
    }
  }
`;
const Name = styled.div`
  ${Typography.body_md_medium}
  color: var(--text-primary);
`;

const Switch = styled.div`
  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--border-primary);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 14px;
    width: 14px;
    top: 3px;
    left: 3px;
    background-color: var(--background-secondary);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: var(--brand-primary);
  }

  input:checked + .slider:before {
    transform: translateX(20px);
    background-color: var(--text-white);
  }

  .slider.round {
    border-radius: 20px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;
const Firstoption = styled.div`
  padding: 8px;
  &.active {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    border: 0.5px solid var(--border-primary);
    background-color: var(--background-tertiary);
    gap: 16px;
    .btn {
      width: 100%;
    }
  }
`;
const Expiryoption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

export {
  Modalbox,
  Div,
  Modalheader,
  Leftdiv,
  Title,
  Heading,
  Rightdiv,
  Bottom,
  Option,
  Leftoptiondiv,
  Icon,
  Name,
  Switch,
  Firstoption,
  Expiryoption,
  Options
};
