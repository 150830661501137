import { getObjectFromArray } from '../helpers/common';
import UserPreferenceSingleton from '../helpers/userPreferenceSingleton';
import { MessageDetailsInterface } from '../interfaces/chatMessageInterface';
import LocalDbService, { CHAT_MESSAGE_TABLE_NAME, UPDATE_ON_ID } from './localDbService';

let singleton: any;
let organizationId: any;

export default class ChatMessageCacheService extends LocalDbService {
  constructor(props: any) {
    const db_name = `${props.organizationId}`;
    props.dbName = db_name;
    props.tableName = CHAT_MESSAGE_TABLE_NAME;
    super(props);
  }

  static getInstance() {
    const company = UserPreferenceSingleton.getInstance().getWorkspace();
    if (company) {
      if (!singleton || organizationId !== company?.id) {
        organizationId = company?.id;
        singleton = new ChatMessageCacheService({ organizationId: company?.id });
      }
    }
    return singleton;
  }

  static removeInstance() {
    if (singleton) singleton.closeDb();
    organizationId = undefined;
    singleton = undefined;
  }

  async getAllMessages() {
    if (this.db) {
      const rows = await this.getDb().toArray();
      const result = rows.map((x: any) => x.value).filter((x: any) => x);
      return result;
    }
    return [];
  }

  async getProjectMessages(projectId: string): Promise<MessageDetailsInterface[]> {
    const rows = await this.getDb().where('projectId').equals(projectId).toArray();
    const companyDetails = UserPreferenceSingleton.getInstance().getWorkspace();
    const usersObj = getObjectFromArray(companyDetails?.users, 'id');
    return (
      rows
        ?.map((row: any) => row.value)
        ?.filter(Boolean)
        ?.toSorted(
          (a: MessageDetailsInterface, b: MessageDetailsInterface) =>
            new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime()
        )
        ?.map((item: MessageDetailsInterface) => ({
          ...item,
          sender: usersObj[item?.sender?.id]
        })) || []
    );
  }

  async getGroupMessages(groupId: string): Promise<MessageDetailsInterface[]> {
    const rows = await this.getDb().where('messageGroupId').equals(groupId).toArray();
    const companyDetails = UserPreferenceSingleton.getInstance().getWorkspace();
    const usersObj = getObjectFromArray(companyDetails?.users, 'id');
    return (
      rows
        ?.map((row: any) => row.value)
        ?.filter(Boolean)
        ?.toSorted(
          (a: MessageDetailsInterface, b: MessageDetailsInterface) =>
            new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime()
        )
        ?.map((item: MessageDetailsInterface) => ({
          ...item,
          sender: usersObj[item?.sender?.id]
        })) || []
    );
  }

  async setMessages(value: any, companyId: string) {
    if (!value || companyId !== organizationId) return false;
    const result = await this.addBulk(value);
    return result;
  }

  async updateTasks(newList: any, archivedList: any, companyId: any) {
    if (companyId !== organizationId) return false;
    await this.syncList(newList, archivedList);
  }

  async updateMessages(data: any, archived: any, workspace_id: string) {
    if (this.db) {
      if (data && data.length > 0) {
        await this.addBulk(data);
      }
      if (archived && archived.length > 0) {
        await this.removeBulk(archived);
      }
    }
  }

  async getLastUpdatedTime(workspace_id?: string) {
    const result = await super.getLastUpdatedTime(`${UPDATE_ON_ID.CHAT_MESSAGE}_${workspace_id}`);
    return result;
  }

  async setLastUpdatedTime(value: any, companyId: string) {
    if (companyId !== organizationId) return false;
    const result = await super.setLastUpdatedTime(`${UPDATE_ON_ID.CHAT_MESSAGE}_${companyId}`, value);
    return result;
  }
}
