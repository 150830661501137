import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { ROUTES } from '../../../routes/urls';
import { logout } from '../../../services/authServices';
import { Buttons, MainDiv, Profile, Taskbutton, UserName } from './styles';
import { getIsDarkMode, isEmpty } from '../../../helpers/common';
import { COLOR_THEME, COLOR_THEME_TYPE, THEME_STATES } from '../../../global/constants';
import { setThemeType, updateThemeMode } from '../../../actions/settingActions';
import { getUserPreferenceFieldData, updateUserPreference } from '../../../helpers/firebaseHelper';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import Dropdown from '../../Dropdown/MainDropdown';
import DropdownDivider from '../../Dropdown/DropdownDivider';
import DropdownItem from '../../Dropdown/DropdownItem';
import { useMobileDevice } from '../../../global/useMobile';

const ProfileDropdown = () => {
  //State
  const [isActive, setIsActive] = useState({ light: false, dark: false, system: false });
  const [isOpen, setIsOpen] = useState(false);
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { settings: settingsSelector, workspace: workspaceSelector } = stateSelector || {};
  const { workspace } = workspaceSelector;
  const { themeType } = settingsSelector;

  //Other variable
  const dispatch = useDispatch();
  const history = useHistory();
  const mobile = useMobileDevice();

  //load them data
  const loadThemeData = useCallback(async () => {
    const isDark = await getUserPreferenceFieldData('theme_mode');
    if (isDark) {
      dispatch(setThemeType(isDark));
    }
  }, [dispatch]);

  useEffect(() => {
    loadThemeData();
    if (themeType === COLOR_THEME.LIGHT) {
      setIsActive(THEME_STATES?.light);
    } else if (themeType === COLOR_THEME.DARK) {
      setIsActive(THEME_STATES?.dark);
    } else if (themeType === COLOR_THEME.SYSTEM_DEFAULT) {
      setIsActive(THEME_STATES?.system);
    }
  }, [loadThemeData, themeType]);

  //organization name click
  const onclick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  //logout the user
  const onClickLogout = useCallback(async () => {
    const result = await dispatch(logout());
    if (result) {
      history.push(ROUTES.LOGIN);
      window.location.reload();
    }
  }, [dispatch, history]);

  //handle theme change
  const handleThemeChange = useCallback(
    async (theme: string, themeMode: number, themeTypeValue?: string) => {
      let resolvedThemeType;
      if (themeTypeValue) {
        resolvedThemeType = themeTypeValue;
      } else if (getIsDarkMode()) {
        resolvedThemeType = COLOR_THEME_TYPE.DARK;
      } else {
        resolvedThemeType = COLOR_THEME_TYPE.LIGHT;
      }
      setIsActive(THEME_STATES[theme]);
      await updateUserPreference({ theme_mode: themeMode });
      dispatch(updateThemeMode({ propsName: 'theme', value: resolvedThemeType }));
      dispatch(setThemeType(themeMode));
      if ((window as any).electronApi) {
        (window as any).electronApi.changeTheme(theme);
      }
    },
    [dispatch]
  );

  const onClickSetting = useCallback(() => {
    if (mobile) history.push('/setting');
    else history.push(ROUTES.PERSONAL_SETTING);
  }, [history, mobile]);

  return (
    <>
      <MainDiv>
        <Dropdown
          content={
            <div>
              <Profile onClick={onclick}>
                {!isEmpty(workspace?.logoUrl) ? (
                  <img src={workspace?.logoUrl} alt='profilephoto' width={20} height={20} className='profile-image' />
                ) : (
                  <SVGIcon name='invoice-details-icon' width='20' height='20' viewBox='0 0 50 50' fill='none' />
                )}
                <UserName>{workspace?.name}</UserName>
              </Profile>
            </div>
          }>
          <Buttons>
            <Taskbutton
              isActive={isActive?.light}
              onClick={() => handleThemeChange('light', COLOR_THEME.LIGHT, COLOR_THEME_TYPE.LIGHT)}>
              Light
            </Taskbutton>
            <Taskbutton
              isActive={isActive?.dark}
              onClick={() => handleThemeChange('dark', COLOR_THEME.DARK, COLOR_THEME_TYPE.DARK)}>
              Dark
            </Taskbutton>
            <Taskbutton
              isActive={isActive?.system}
              onClick={() => handleThemeChange('system', COLOR_THEME.SYSTEM_DEFAULT)}>
              System
            </Taskbutton>
          </Buttons>
          <DropdownDivider />
          <DropdownItem
            label={workspace?.name}
            description='Add or Switch Organisation'
            onClick={() => {
              history.push(ROUTES.SELECT_WORKSPACE);
            }}
          />
          <DropdownDivider />
          <DropdownItem
            iconName='settings-icon'
            label='Settings'
            iconSize={16}
            onClick={onClickSetting}
            iconViewBox='0 0 18 18'
            iconTone='stroke'
          />
          <DropdownItem iconName='logout-icon' label='Log out' onClick={onClickLogout} iconTone='stroke' />
        </Dropdown>
      </MainDiv>
    </>
  );
};
export default ProfileDropdown;
