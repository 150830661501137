import { Dropdown, Menu } from 'antd';
import React from 'react';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import AppLayout from '../../../component/appLayout';
import { Table } from './style';
import {
  Container,
  Contentheading,
  Header,
  Leftcontent,
  Monthpicker,
  Rightcontent,
  Total,
  Text,
  Dropbox,
  Dropitem,
  Button
} from '../styles';
import CalendarComponent from '../../../component/calendarPopUp/Calendarcomponent';

const Uninvoiced: React.FC = () => {
  return (
    <>
      <AppLayout>
        <Header>Uninvoiced</Header>
        <Container>
          <Contentheading>
            <Leftcontent>
              <Dropdown
                trigger={['click']}
                dropdownRender={() => (
                  <Dropbox>
                    <Menu className='menuStyle' style={{ borderRadius: 0, background: ' none' }}>
                      <Menu.Item
                        key='1'
                        style={{
                          padding: 0,
                          backgroundColor: 'rgba(0, 0, 0, 0)'
                        }}
                        className='menuitem'>
                        <Dropitem>By Project</Dropitem>
                      </Menu.Item>
                      <Menu.Item
                        key='2'
                        style={{
                          padding: 0,
                          backgroundColor: 'rgba(0, 0, 0, 0)'
                        }}
                        className='menuitem'>
                        <Dropitem>By Teammate</Dropitem>
                      </Menu.Item>
                      <Menu.Item
                        key='3'
                        style={{
                          padding: 0,
                          backgroundColor: 'rgba(0, 0, 0, 0)'
                        }}
                        className='menuitem'>
                        <Dropitem>By Customers</Dropitem>
                      </Menu.Item>
                    </Menu>
                  </Dropbox>
                )}
                className='select'>
                <Button placeholder='By Project'>
                  <p>By Customers</p>
                  <SVGIcon name='dropdown-icon' width='24' height='24' viewBox='0 0 24 24' fill='none' />
                </Button>
              </Dropdown>
              <Dropdown
                trigger={['click']}
                placement='bottomLeft'
                transitionName=''
                dropdownRender={() => (
                  <Dropbox>
                    <div style={{ placeItems: 'center', padding: '2px 8px' }}>
                      <CalendarComponent
                        isUpdateReducer={false}
                        loadData={undefined}
                        data={undefined}
                        date={undefined}
                        setCalanderDate={undefined}
                      />
                    </div>
                  </Dropbox>
                )}>
                <Monthpicker>
                  <SVGIcon
                    name='report-left-arrow-icon'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    className='arrow-icon'
                  />
                  <Text>June 2023</Text>
                  <SVGIcon
                    name='report-right-arrow-icon'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    className='arrow-icon'
                  />
                </Monthpicker>
              </Dropdown>
            </Leftcontent>
            <Rightcontent>
              <Total>
                <Text>Total (2023) : </Text>
                <Text> 12,283 USD</Text>
              </Total>
              <Text>
                <span>Export to CSV</span>
              </Text>
            </Rightcontent>
          </Contentheading>

          <Table>
            <tr>
              <th>Projects</th>
              <th>Client Company</th>
              <th>hours (hh:mm)</th>
              <th>Expenses</th>
              <th>uninvoiced amount</th>
              <th></th>
            </tr>
            <tr>
              <td>Web Design, Logo Design </td>
              <td>Walter Melon</td>
              <td>__</td>
              <td>100</td>
              <td>100</td>
              <td>Create Invoice</td>
            </tr>
            <tr>
              <td>Logo Design, Editorial Calendar</td>
              <td>Buck Kinnear</td>
              <td>34:00</td>
              <td>150</td>
              <td>2,870</td>
              <td>Create Invoice</td>
            </tr>
            <tr>
              <td>Editorial Calendar, Logo Design</td>
              <td>Brock Lee</td>
              <td>30:00</td>
              <td>200</td>
              <td>4,055</td>
              <td>Create Invoice</td>
            </tr>
            <tr>
              <td>Editorial Calendar</td>
              <td>Jimmy Changa</td>
              <td>50:00</td>
              <td>450</td>
              <td>500</td>
              <td>Create Invoice</td>
            </tr>
            <tr>
              <td>Web Design, Editorial Calendar</td>
              <td>Holly Graham</td>
              <td>45:30</td>
              <td>300</td>
              <td>1,200</td>
              <td>Create Invoice</td>
            </tr>
            <tr>
              <td>
                <span>Total</span>
              </td>
              <td>
                <span></span>
              </td>
              <td>
                <span>159:30</span>
              </td>
              <td>
                <span>1200</span>
              </td>
              <td>
                <span>8725</span>
              </td>
              <td>
                <span></span>
              </td>
            </tr>
          </Table>
        </Container>
      </AppLayout>
    </>
  );
};

export default Uninvoiced;
