import { AnyAction, Dispatch } from 'redux';
import { clearResponseMessage, setErrorMessage } from '../actions/messageActions';
import { getAPIErrorReason } from '../helpers/common';
import { captureException } from './logService';
import axios from 'axios';
import { REACT_APP_TEAMCAMP_APIURL } from '../global/environment';
import { rootStore } from '../mobx/rootStore';

/**
 * @desc mytasks - Get inbox data
 * @param {*}
 */
const formatToRawNotification: any = (item: any) => {
  //Remove any
  return {
    date: item?.snoozeDate ?? item.createdTime,
    description: item.description,
    id: item.id,
    isRead: item?.isRead || false,
    referenceId: item?.referenceId,
    referenceId2: item?.referenceId2,
    referenceId3: item?.referenceId3,
    title: item.title,
    type: item.type,
    userId: item.userId,
    createdBy: item?.username === 'Github' ? 'github' : item.createdBy,
    createdTime: item.createdTime
  };
};

/**
 * @desc mytasks - Get inbox data
 * @param {*}
 */
export const loadInboxData: any = () => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/notification/getNotificationList`);
    const { data } = response;
    if (data?.length > 0) {
      let updatedList = data?.map(formatToRawNotification);
      rootStore.notificationStore.setNotifications(updatedList);
      return updatedList;
    } else {
      rootStore.notificationStore.setNotifications([]);
    }
    return undefined;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to get notification list please try again';
    captureException(error);
    dispatchInboxError(error, dispatch);
    return undefined;
  }
};

/**
 * @desc inbox - clear all inbox messages
 * @param {*}
 */
export const clearAllNotifications: any = () => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/notification/deleteNotifications`);
    if (response) {
      return true;
    }
    return false;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to clear all inbox messages please try again';
    captureException(error);
    dispatchInboxError(error, dispatch);
    return false;
  }
};

/**
 * @desc inbox - update inbox messages
 * @param {*}
 */
export const updateNotification: any =
  (notificationId: string, payload: { snoozeDate: string }) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      const response = await axios.put(
        `${REACT_APP_TEAMCAMP_APIURL}/notification/updateNotification/${notificationId}`,
        payload
      );
      if (response) {
        return true;
      }
      return false;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to update inbox messages please try again';
      captureException(error);
      dispatchInboxError(error, dispatch);
      return false;
    }
  };

/**
 * @desc inbox - delete inbox messages
 * @param {*}
 */
export const deleteNotification: any = (notificationId: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    const response = await axios.delete(
      `${REACT_APP_TEAMCAMP_APIURL}/notification/deleteNotification/${notificationId}`
    );
    if (response) {
      return true;
    }
    return false;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to delete inbox messages please try again';
    captureException(error);
    dispatchInboxError(error, dispatch);
    return false;
  }
};

function dispatchInboxError(msg: string, dispatch: Dispatch<AnyAction>) {
  dispatch(setErrorMessage(msg));
}
