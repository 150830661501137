import React from 'react';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import {
  Tabledata,
  Userdetails,
  Username,
  Userdescription,
  Stopicon,
  Privatedata,
  Maincontent,
  Usertime,
  Circle,
  Div,
  Line,
  Header,
  Bottomdiv,
  Leftblock,
  SvgDiv,
  Rightblock,
  Middleline,
  Siderbox,
  Datetitle,
  Timetitle
} from './styles';
import { getUniqueId } from '../../../helpers/common';
const TimeLoading: React.FC = () => {
  return (
    <>
      <Maincontent>
        <Header>
          <Leftblock>
            <SvgDiv>
              <SVGIcon
                name='slider-left-icon'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                stroke='var(--text-secondary)'
              />
            </SvgDiv>
          </Leftblock>
          <Middleline>
            {Array(7)
              .fill(null)
              .map((_, i) => (
                <div key={getUniqueId()} className={`box ${i > 4 ? 'last-box' : ''}`}>
                  <Siderbox>
                    <Datetitle className='loading-animation' />
                    <Timetitle className='loading-animation' />
                  </Siderbox>
                </div>
              ))}
          </Middleline>
          <Rightblock>
            <SvgDiv>
              <SVGIcon
                name='slider-right-icon'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                stroke='var(--text-secondary)'
              />
            </SvgDiv>
          </Rightblock>
        </Header>
        <Bottomdiv>
          <Tabledata>
            <Userdetails>
              <Username className='loading-animation' />
              <Userdescription className='loading-animation' />
            </Userdetails>
            <Privatedata>
              <Usertime className='loading-animation' />
              <Div>
                <Stopicon>
                  <SVGIcon
                    name='time-loding-icon'
                    width='32'
                    height='32'
                    viewBox='0 0 33 32'
                    className='loading-icon'
                  />
                </Stopicon>
                <Line />
                <Circle className='loading-animation' />
              </Div>
            </Privatedata>
          </Tabledata>
          <Tabledata>
            <Userdetails>
              <Username className='loading-animation' />
              <Userdescription className='loading-animation' />
            </Userdetails>
            <Privatedata>
              <Usertime className='loading-animation' />
              <Div>
                <Stopicon>
                  <SVGIcon
                    name='time-loding-icon'
                    width='32'
                    height='32'
                    viewBox='0 0 33 32'
                    className='loading-icon'
                  />
                </Stopicon>
                <Line />
                <Circle className='loading-animation' />
              </Div>
            </Privatedata>
          </Tabledata>
          <Tabledata>
            <Userdetails>
              <Username className='loading-animation' />
              <Userdescription className='loading-animation' />
            </Userdetails>
            <Privatedata>
              <Usertime className='loading-animation' />
              <Div>
                <Stopicon>
                  <SVGIcon
                    name='time-loding-icon'
                    width='32'
                    height='32'
                    viewBox='0 0 33 32'
                    className='loading-icon'
                  />
                </Stopicon>
                <Line />
                <Circle className='loading-animation' />
              </Div>
            </Privatedata>
          </Tabledata>
        </Bottomdiv>
      </Maincontent>
    </>
  );
};

export default TimeLoading;
