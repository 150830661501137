import {
  PrioritySortOrder,
  SHOW_TASK_BY_ORDER,
  STATUS_ORDER,
  STATUS_TYPE,
  TASK_PRIORITY,
  TASK_PRIORITY_LABELS
} from '../global/constants';
import { ProjectDetailInterface } from '../interfaces/ProjectInterface';
import { TaskDetailsInterface, TaskGroupInterface } from '../interfaces/TaskInterface';

// /** ---------------------- PROJECT - TASK PAGE FILTER SERVICES ------------------------------ */

export const getTasksListByUser = (tasksList: TaskDetailsInterface[], projectDetail: ProjectDetailInterface) => {
  const projectUsers = new Set(projectDetail?.users?.map((item) => item?.id));

  const { tasksGroupedByUser, users, tasksWithNoUsersTasks, tasksWithNoUsersCompletedTasks } = tasksList?.reduce(
    (acc, task) => {
      if (!task?.users?.length) {
        (task?.status ? acc.tasksWithNoUsersCompletedTasks : acc.tasksWithNoUsersTasks).push(task);
      } else {
        task.users.forEach((user) => {
          if (projectUsers.has(user?.id)) {
            acc.users[user.id] = user.name;
            (acc.tasksGroupedByUser[user.id] ||= []).push(task);
          }
        });
      }
      return acc;
    },
    {
      tasksGroupedByUser: {} as Record<string, TaskDetailsInterface[]>,
      users: {} as Record<string, string>,
      tasksWithNoUsersTasks: [] as TaskDetailsInterface[],
      tasksWithNoUsersCompletedTasks: [] as TaskDetailsInterface[]
    }
  );

  const otherTasks = { tasks: tasksWithNoUsersTasks, completedTasks: tasksWithNoUsersCompletedTasks };
  const filteredTasks = Object.entries(tasksGroupedByUser).map(([userId, userTasks]) => ({
    id: userId,
    name: users[userId],
    tasks: userTasks.filter((task) => !task?.status),
    completedTasks: userTasks.filter((task) => task?.status)
  }));

  return { tasksRef: filteredTasks, otherRef: otherTasks };
};

//----------------------------------------------------------------------------------------------------------------------

export const getTasksListByStatus = (
  tasksList: TaskDetailsInterface[],
  projectDetails: ProjectDetailInterface,
  isShowRecentlyCompletedTask: boolean
) => {
  let otherTasks = { tasks: [], completedTasks: [] };
  let filteredTasks = [];

  const groupedTasks = new Map<string, TaskDetailsInterface[]>();
  tasksList?.forEach((task) => {
    const statusId = task.statusId || 'other';
    if (!groupedTasks.has(statusId)) {
      groupedTasks.set(statusId, []);
    }
    groupedTasks.get(statusId)!.push(task);
  });

  const defaultTasks = groupedTasks.get('other') || [];

  filteredTasks = projectDetails?.statusData
    .map(({ _id: id, Name, Type }) => {
      const statusTasks = groupedTasks.get(id) || [];
      const additionalTasks = projectDetails?.defaultStatus
        ? projectDetails?.defaultStatus['_id'] === id
          ? defaultTasks
          : []
        : [];
      // Merge the tasks
      let allTasks = statusTasks.concat(additionalTasks);

      // Sort tasks if the status is COMPLETED (5)
      if (Type === STATUS_TYPE.COMPLETED && isShowRecentlyCompletedTask) {
        allTasks.sort((a, b) => new Date(b.updatedTime!).getTime() - new Date(a.updatedTime!).getTime());
      }
      return {
        id,
        tasks: allTasks,
        name: Name,
        status: Type,
        completedTasks: []
      };
    })
    .sort((a, b) => STATUS_ORDER.indexOf(a.status) - STATUS_ORDER.indexOf(b.status));

  return { tasksRef: filteredTasks, otherRef: otherTasks };
};

//----------------------------------------------------------------------------------------------------------------------

export const getTasksByPriority = (tasksList: TaskDetailsInterface[]) => {
  const otherTasks = { tasks: [], completedTasks: [] };
  const filteredTasksMap = tasksList.reduce((map, task) => {
    const priority = task.priority || TASK_PRIORITY.NO_PRIORITY;

    if (!map.has(priority)) {
      map.set(priority, {
        id: priority,
        name: TASK_PRIORITY_LABELS[priority],
        tasks: [],
        completedTasks: []
      });
    }

    const current = map.get(priority);
    task.status ? current.completedTasks.push(task) : current.tasks.push(task);

    return map;
  }, new Map<number, any>());

  const filteredTasks = Array.from(filteredTasksMap.values());
  filteredTasks.sort((a, b) => PrioritySortOrder.indexOf(a.id) - PrioritySortOrder.indexOf(b.id));
  return { tasksRef: filteredTasks, otherRef: otherTasks };
};

//----------------------------------------------------------------------------------------------------------------------

export const getTasksListByNoGrouping = (tasksList: TaskDetailsInterface[], projectId: string) => {
  const otherTasks = { tasks: [], completedTasks: [] };

  const { tasks, completedTasks } = tasksList.reduce(
    (acc, task) => {
      if (task.status) {
        acc.completedTasks.push(task);
      } else {
        acc.tasks.push(task);
      }
      return acc;
    },
    { tasks: [] as TaskDetailsInterface[], completedTasks: [] as TaskDetailsInterface[] }
  );

  const filteredTasks = [
    {
      id: 0,
      index: 0,
      name: '',
      projectId: projectId,
      tasks,
      completedTasks
    }
  ];

  return { tasksRef: filteredTasks, otherRef: otherTasks };
};

//----------------------------------------------------------------------------------------------------------------------

export const getTaskListByGroup = (taskList: TaskDetailsInterface[], taskGroups: TaskGroupInterface[]) => {
  const groupedTasks = taskList.reduce<Record<string, TaskDetailsInterface[]>>((acc, item) => {
    const groupId = item.groupId || 'other';
    const key = item.status ? `${groupId}_completed` : groupId;

    if (!acc[key]) acc[key] = [];
    acc[key].push(item);

    return acc;
  }, {});

  const taskWithGroups = (Array.isArray(taskGroups) ? taskGroups : [])?.map((item) => ({
    ...item,
    tasks: groupedTasks[item?.id] || [],
    completedTasks: groupedTasks[`${item?.id}_completed`] || [],
    id: item?.id
  }));

  const otherTask = {
    tasks: groupedTasks['other'] || [],
    completedTasks: groupedTasks['other_completed'] || []
  };
  return { tasksRef: taskWithGroups, otherRef: otherTask };
};

//----------------------------------------------------------------------------------------------------------------------

export const getTaskListByMilestone = (taskList: TaskDetailsInterface[], milestoneList: any) => {
  // Create a milestone map for quick lookup
  const milestoneMap = milestoneList.reduce((acc: Record<string, string>, milestone: any) => {
    acc[milestone.id] = milestone.milestoneName;
    return acc;
  }, {});

  // Initialize the milestoneWiseTask array and otherTasks array
  const milestoneWiseTask: any[] = [];
  let otherTasks: TaskDetailsInterface[] = [];

  // Group tasks by milestone using reduce
  const tasksGroupedByMilestone = taskList.reduce((acc: Record<string, TaskDetailsInterface[]>, task) => {
    const milestoneId = task.milestoneId || 'Other';
    if (!acc[milestoneId]) {
      acc[milestoneId] = [];
    }
    acc[milestoneId].push(task);
    return acc;
  }, {});

  // Process grouped tasks
  Object.entries(tasksGroupedByMilestone).forEach(([milestoneId, tasks]) => {
    const milestoneName = milestoneMap[milestoneId] || 'Other';

    if (milestoneName === 'Other') {
      otherTasks.push(...tasks);
    } else {
      milestoneWiseTask.push({
        id: milestoneId,
        tasks: tasks.filter((task) => !task.status),
        completedTasks: tasks.filter((task) => task.status),
        name: milestoneName
      });
    }
  });

  // Add other tasks if any
  if (otherTasks.length > 0) {
    milestoneWiseTask.push({
      id: null,
      tasks: otherTasks.filter((task) => !task.status),
      completedTasks: otherTasks.filter((task) => task.status),
      name: 'Other'
    });
  }

  // Convert to array
  const tasksRef = Object.values(milestoneWiseTask);

  return { tasksRef, otherRef: { tasks: [], completedTasks: [] } };
};

//----------------------------------------------------------------------------------------------------------------------

export const sortByDueDate = (tasks: TaskDetailsInterface[]) => {
  return tasks.toSorted((a, b) => {
    const dateA = a.dueDate ? new Date(a.dueDate).getTime() : new Date().getTime();
    const dateB = b.dueDate ? new Date(b.dueDate).getTime() : new Date().getTime();
    return dateA - dateB;
  });
};

export const sortByCreatedDate = (tasks: TaskDetailsInterface[]) => {
  return tasks.toSorted((a, b) => {
    const dateA = a.createdOn ? new Date(a.createdOn).getTime() : new Date().getTime();
    const dateB = b.createdOn ? new Date(b.createdOn).getTime() : new Date().getTime();
    return dateA - dateB;
  });
};

export const sortByPriority = (tasks: TaskDetailsInterface[]) => {
  return tasks.sort((a, b) => PrioritySortOrder.indexOf(a.priority!) - PrioritySortOrder.indexOf(b.priority!));
};

export const sortByIndex = (tasks: TaskDetailsInterface[]) => {
  return tasks
    ?.map((x) => ({
      ...x,
      index: x?.index && x?.index !== 0 ? x.index : new Date(x?.createdOn).getTime()
    }))
    .toSorted((a, b) => a.index - b.index);
};

export const taskSorter = (type: number, tasks: TaskDetailsInterface[], completedTask: TaskDetailsInterface[]) => {
  let sortedTasks = tasks;
  let sortedCompletedTasks = completedTask;
  switch (type) {
    case SHOW_TASK_BY_ORDER.DUE_DATE:
      sortedTasks = sortByDueDate(tasks);
      sortedCompletedTasks = sortByDueDate(completedTask);
      break;
    case SHOW_TASK_BY_ORDER.PRIORITY:
      sortedTasks = sortByPriority(tasks);
      sortedCompletedTasks = sortByPriority(completedTask);
      break;
    case SHOW_TASK_BY_ORDER.CUSTOM:
      sortedTasks = sortByIndex(tasks);
      sortedCompletedTasks = sortByIndex(completedTask);
      break;
    default:
      sortedTasks = sortByCreatedDate(tasks);
      sortedCompletedTasks = sortByCreatedDate(completedTask);
      break;
  }
  return { tasks: sortedTasks, completedTasks: sortedCompletedTasks };
};
