import { ActionCreatorWithOptionalPayload, ActionCreatorWithPayload, createAction, Dispatch } from '@reduxjs/toolkit';
import * as Actions from './types';
import { ReducerInterface } from '../interfaces/ReducerInterface';
import { TimeTrackingDataInterface } from '../interfaces/TimeTrackingInterface';
import { ReportTimeFilterInterface } from '../interfaces/ReportInterface';

/**
 * @desc Set time tracking loader
 */
export const setTimeTrackingLoader: ActionCreatorWithPayload<boolean, string> = createAction(
  Actions.SET_TIME_TRACKING_LOADER
);

/**
 * @desc Set time tracking data
 */
export const setTimeLogData: ActionCreatorWithPayload<TimeTrackingDataInterface[], string> = createAction(
  Actions.SET_TIME_LOG_DATA
);

/**
 * @desc Set current time tracking data
 */
export const setCurrentTimeTracking: ActionCreatorWithPayload<any, string> = createAction(
  Actions.SET_CURRENT_TIME_TRACKING
);

/**
 * @desc update task data
 */
export const updateTaskData: ActionCreatorWithPayload<object, string> = createAction(Actions.UPDATE_TASK_DETAILS);

/**
 * @desc clear current time tracking
 */
export const clearCurrentTimeTracking: ActionCreatorWithOptionalPayload<undefined, string> = createAction(
  Actions.CLEAR_CURRENT_TIME_TRACKING
);

/**
 * @desc
 */
export const setTimeEntryInput: ActionCreatorWithPayload<any, string> = createAction(Actions.SET_TIME_ENTRY_INPUT);

/**
 * @desc
 */
export const updateTimeEntryInput: ActionCreatorWithPayload<ReducerInterface, string> = createAction(
  Actions.UPDATE_TIME_ENTRY_INPUT
);

/**
 * @desc
 */
export const clearTimeEntryInput: ActionCreatorWithOptionalPayload<undefined, string> = createAction(
  Actions.CLEAR_TIME_ENTRY_INPUT
);

/**
 * @desc
 */
export const setTimeTrackingActivity: ActionCreatorWithPayload<any, string> = createAction(
  Actions.SET_TIME_TRACKING_ACTIVITY
);

/**
 * @desc
 */
export const setListDropdown: ActionCreatorWithPayload<any, string> = createAction(Actions.SET_LIST_DROPDOWN);
/**
 * @desc
 */
export const updateListDropdown: ActionCreatorWithPayload<ReducerInterface, string> = createAction(
  Actions.UPDATE_LIST_DROPDOWN
);
/**
 * @desc
 */
export const clearListDropdown: ActionCreatorWithOptionalPayload<undefined, string> = createAction(
  Actions.CLEAR_LIST_DROPDOWN
);

/**
 * @desc
 */
export const setTimeTrackingReportData: ActionCreatorWithPayload<any, string> = createAction(
  Actions.SET_TIMRE_TRACKING_REPORT_DATA
);

/**
 * @desc
 */
export const setReportFilterDropdown: ActionCreatorWithPayload<any, string> = createAction(Actions.SET_REPORT_FILTER);
/**
 * @desc
 */
export const updateReportFilterDropdown: ActionCreatorWithPayload<ReducerInterface, string> = createAction(
  Actions.UPDATE_REPORT_FILTER
);
/**
 * @desc
 */
export const clearReportFilterDropdown: ActionCreatorWithOptionalPayload<undefined, string> = createAction(
  Actions.CLEAR_REPORT_FILTER
);

/**
 * @desc
 */
export const setCurrentStartTime: ActionCreatorWithPayload<any, string> = createAction(
  Actions.SET_CURRENT_TRACKING_TIME
);

/**
 * @desc set my time log data
 */
export const setMyTimeLogData: ActionCreatorWithPayload<any, string> = createAction(Actions.SET_MY_TIME_LOG_DATA);

/**
 * @desc set my time log header filter data
 */
export const setMyTimeLogFilter: ActionCreatorWithPayload<ReportTimeFilterInterface, string> = createAction(
  Actions.SET_MY_TIME_LOG_FILTER
);

/**
 * @desc Clear Web Form Data
 */
export const clearTimeTrackingData = () => (dispatch: Dispatch) => {
  dispatch(setTimeTrackingLoader(false));
  dispatch(setTimeLogData([]));
  dispatch(clearCurrentTimeTracking());
  dispatch(clearTimeEntryInput());
  dispatch(setTimeTrackingActivity([]));
  dispatch(clearListDropdown());
  dispatch(setTimeTrackingReportData([]));
  dispatch(clearReportFilterDropdown());
  dispatch(setCurrentStartTime({}));
  dispatch(setMyTimeLogFilter({}));
};
