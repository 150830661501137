import styled, { css } from 'styled-components';
import { Typography } from '../../../global/theme';

const Messagecontent = styled.div`
  display: flex;
  flex-direction: column;
  /* height: 100dvh; */
  width: 100%;
  max-width: 306px;
  /* overflow: auto; */
  background-color: var(--background-primary);
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 449px) {
    max-width: 100%;
    background-color: var(--background-secondary);
    height: 100dvh;
    overflow: auto;
  }
`;

const Messageheader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px 12px 14px;
  border-bottom: 0.5px solid var(--border-primary);
  background-color: var(--background-tertiary);
  position: sticky;
  top: 0;
  @media (max-width: 449px) {
    padding: 12px 11px 12px 16px;
    background-color: var(--background-primary);
    gap: 24px;
  }
`;

const Leftheader = styled.div`
  ${Typography.heading_md}
  color: var(--text-primary);
  @media (max-width: 449px) {
    ${Typography.heading_lg}
  }
`;
const Rightheader = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;

  .plusicon {
    stroke: var(--text-secondary);
  }
  :hover {
    background-color: var(--neutral-secondary);
    border-radius: 4px;
    path {
      stroke: var(--text-primary);
    }
  }
  @media (max-width: 449px) {
    width: 32px;
    height: 32px;
    .plusicon {
      padding: 7px;
    }
  }
`;
const Messagedata = styled.div`
  display: flex;
  flex-direction: column;
  /* height: 100%; */
  /* background-color: var(--background-primary); */
  padding: 6px 10px;
  gap: 1px;
`;

const Box = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 12px;
  padding: 4px;
  border: 0.5px solid transparent;
  border-radius: 50px;
  :hover {
    border: 0.5px solid transparent;
    background-color: var(--background-secondary);
    cursor: pointer;
  }
  ${(props: { isActive: boolean }) =>
    props.isActive &&
    css`
      border: 0.5px solid var(--border-primary);
      background-color: var(--background-secondary);
      box-shadow: var(--shadow-card);
      :hover {
        border: 0.5px solid var(--border-primary);
      }
    `}
  @media (max-width: 449px) {
    padding: 4px;
  }
`;
const Icon = styled.div`
  display: flex;
  .teamcampicon {
    rect {
      fill: var(--background-tertiary);
      stroke: var(--border-primary);
    }
    path {
      fill: var(--text-primary);
    }
  }
`;
const Messagedetail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
`;
const Name = styled.div<{ hasUnreadMessages?: boolean }>`
  ${Typography.body_md}
  color: var(--text-primary);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  ${(props: { hasUnreadMessages?: boolean }) =>
    props.hasUnreadMessages &&
    css`
      ${Typography.body_md_semibold}
    `}
`;

const Newchat = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  padding: 4px;
  background-color: var(--background-secondary);
  border: 1px solid var(--border-primary);
  border-radius: 50px;
  .chaticon {
    circle {
      fill: var(--background-tertiary);
    }
    fill: var(--text-secondary);
  }
  @media (max-width: 449px) {
    padding: 14px 16px;
  }
`;

const Rightchat = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

const ResponsiveBarIcon = styled.div`
  display: none;
  @media (max-width: 449px) {
    display: flex;
  }
`;

const IconWithTitle = styled.div`
  display: flex;
  gap: 24px;
`;
const Emptybox = styled.div`
  display: none;
  @media (max-width: 449px) {
    display: block;
  }
`;
const SectionHeading = styled.p`
  ${Typography.body_md_semibold};
  margin: 9px 0 5px;
  color: var(--text-secondary);
  :first-child {
    margin-top: 0;
  }
`;

export {
  Messageheader,
  Messagecontent,
  Leftheader,
  Rightheader,
  Messagedata,
  Box,
  Messagedetail,
  Name,
  Icon,
  Newchat,
  Rightchat,
  ResponsiveBarIcon,
  IconWithTitle,
  Emptybox,
  SectionHeading
};
