import React from 'react';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import {
  Group,
  Container,
  Tableheader,
  Titlewrap,
  Titlewrapper,
  Titlewrappers,
  Tabledata,
  Userdetails,
  Username,
  Userdescription,
  Usermail,
  Usernumber,
  Privatedata
} from './styles';

const LoadingState: React.FC = () => {
  return (
    <>
      <Group>
        <Container>
          <Tableheader>
            <Titlewrap>Name</Titlewrap>
            <Titlewrapper>EMAIL</Titlewrapper>
            <Titlewrappers>PHONE</Titlewrappers>
          </Tableheader>
          <Tabledata>
            <Userdetails>
              <Username className='loading-animation' />
              <Userdescription className='loading-animation' />
            </Userdetails>
            <Privatedata>
              <Usermail className='loading-animation' />
              <Usernumber className='loading-animation' />
            </Privatedata>
            <SVGIcon name='more-icon' width='18' height='18' viewBox='0 0 18 18' fill='none' className='more-icon' />
          </Tabledata>
          <Tabledata>
            <Userdetails>
              <Username className='loading-animation' />
              <Userdescription className='loading-animation' />
            </Userdetails>
            <Privatedata>
              <Usermail className='loading-animation' />
              <Usernumber className='loading-animation' />
            </Privatedata>
            <SVGIcon name='more-icon' width='18' height='18' viewBox='0 0 18 18' fill='none' className='more-icon' />
          </Tabledata>
          <Tabledata>
            <Userdetails>
              <Username className='loading-animation' />
              <Userdescription className='loading-animation' />
            </Userdetails>
            <Privatedata>
              <Usermail className='loading-animation' />
              <Usernumber className='loading-animation' />
            </Privatedata>
            <SVGIcon name='more-icon' width='18' height='18' viewBox='0 0 18 18' fill='none' className='more-icon' />
          </Tabledata>
          <Tabledata>
            <Userdetails>
              <Username className='loading-animation' />
              <Userdescription className='loading-animation' />
            </Userdetails>
            <Privatedata>
              <Usermail className='loading-animation' />
              <Usernumber className='loading-animation' />
            </Privatedata>
            <SVGIcon name='more-icon' width='18' height='18' viewBox='0 0 18 18' fill='none' className='more-icon' />
          </Tabledata>
          <Tabledata>
            <Userdetails>
              <Username className='loading-animation' />
              <Userdescription className='loading-animation' />
            </Userdetails>
            <Privatedata>
              <Usermail className='loading-animation' />
              <Usernumber className='loading-animation' />
            </Privatedata>
            <SVGIcon name='more-icon' width='18' height='18' viewBox='0 0 18 18' fill='none' className='more-icon' />
          </Tabledata>
        </Container>
      </Group>
    </>
  );
};

export default LoadingState;
