import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { ROUTES } from '../../../routes/urls';
import { resetPasswordDetail } from '../../../services/authServices';
import Button from '../../../component/button';
import { getUrlParam } from '../../../helpers/common';
import AppLogo from '../../../component/appLogo';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import { Content, Formblock, Group, Icon, Inputvalue, Title, Form, Viewer, Formtextarea } from '../styles';

const ResetPassword: React.FC = () => {
  //State
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  //use selector state variables
  const auth = useSelector((state: RootReducerInterface) => state.auth);
  const { loading } = auth;
  //Other variable
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const userId: string | null = getUrlParam(location?.search, 'user_id');
  const token = getUrlParam(location?.search, 'token');

  // for password reset
  const onSubmitPassword = useCallback(
    async (e: React.SyntheticEvent) => {
      e.preventDefault();
      const payload = {
        // userId: userId,
        token: token,
        email: userId,
        password: password,
        confirmPassword: confirmPassword
      };
      const result = await dispatch(resetPasswordDetail(payload));
      if (result) {
        history.push(ROUTES.LOGIN);
      }
    },
    [password, confirmPassword, dispatch, history, userId, token]
  );

  return (
    <>
      <Group isResetPasswordPage={true} className='windows-onboarding-flow'>
        <Content>
          <Icon>
            <AppLogo height='60' />
          </Icon>
          <Title> Reset Password</Title>
          <>
            <Form onSubmit={onSubmitPassword}>
              <Formblock>
                <Inputvalue
                  type='email'
                  placeholder='Email ID'
                  value={userId || ''}
                  disabled={true}
                  isResetPasswordPage={true}
                />
                <Inputvalue
                  type='password'
                  placeholder='Password'
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  isResetPasswordPage={true}
                  className='password'
                />
                <Inputvalue
                  type='password'
                  placeholder='Confirm Password'
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                  className='password-input'
                  isResetPasswordPage={true}
                />
              </Formblock>
              <Button title=' Save' type='submit' isLoading={loading} />
            </Form>
            <Formtextarea isResetPasswordPage={true}>
              <Viewer onClick={() => history.push(ROUTES.LOGIN)}>Back to Login</Viewer>
            </Formtextarea>
          </>
        </Content>
      </Group>
    </>
  );
};

export default ResetPassword;
