import React, { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SVGIcon from '../../assets/images/svg/SVGIcon';
import { ROUTES } from '../../routes/urls';
import { Settingcontent, Content, Heading, Icon, Title, Taskbutton, Buttonblock } from './styles';
import Button from '../../component/button';
import { Location } from 'history';
const Header: React.FC = () => {
  let history = useHistory();

  const location: Location = useLocation();

  const isSetting = useMemo(() => {
    return location?.pathname === ROUTES.GENERAL_SETTING;
  }, [location?.pathname]);

  const isTeam = useMemo(() => {
    return location?.pathname === ROUTES.TEAM_SETTING;
  }, [location?.pathname]);

  const isPayment = useMemo(() => {
    return location?.pathname === ROUTES.PAYMENT_SETTING;
  }, [location?.pathname]);

  const isInvoice = useMemo(() => {
    return location?.pathname === ROUTES.INVOICE_SETTING;
  }, [location?.pathname]);

  const isTime = useMemo(() => {
    return location?.pathname === ROUTES.TIME_SETTING;
  }, [location?.pathname]);

  const isProject = useMemo(() => {
    return location?.pathname === ROUTES.PROJECT_SETTING;
  }, [location?.pathname]);

  const onClickTask = useCallback(
    (path: any) => {
      history.push(path);
    },
    [history]
  );

  return (
    <>
      <Settingcontent>
        <Content>
          <Heading>
            <Icon>
              <SVGIcon name='settings-main-icon' width='34' height='34' viewBox='0 0 34 34' fill='none' />
            </Icon>
            <Title> Setting </Title>
          </Heading>
          <Buttonblock>
            <Taskbutton isActive={isSetting} onClick={() => onClickTask(ROUTES.GENERAL_SETTING)}>
              General Settings
            </Taskbutton>
            <Taskbutton isActive={isProject} onClick={() => onClickTask(ROUTES.PROJECT_SETTING)}>
              Project
            </Taskbutton>
            <Taskbutton isActive={isTime} onClick={() => onClickTask(ROUTES.TIME_SETTING)}>
              Time
            </Taskbutton>
            <Taskbutton isActive={isInvoice} onClick={() => onClickTask(ROUTES.INVOICE_SETTING)}>
              Invoice
            </Taskbutton>
            <Taskbutton isActive={isPayment} onClick={() => onClickTask(ROUTES.PAYMENT_SETTING)}>
              Payment
            </Taskbutton>
            <Taskbutton isActive={isTeam} onClick={() => onClickTask(ROUTES.TEAM_SETTING)}>
              Team
            </Taskbutton>
          </Buttonblock>
        </Content>
        <Button title='Save' type='submit' />
      </Settingcontent>
    </>
  );
};

export default Header;
