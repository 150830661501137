import styled from 'styled-components';
import { Typography } from '../global/theme';

export const AppMain = styled.div`
  .ant-dropdown-menu-submenu .ant-dropdown-menu {
    background-color: var(--background-primary);
    border: 0.5px solid transparent;
  }

  .ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    ${Typography.body_lg_medium}
    color: var(--brand-primary);
    border-radius: 3px;
  }

  .display-template {
    color: var(--brand-primary) !important;
    padding: 0 !important;
    text-decoration: none !important;
    &:hover {
      background-color: transparent !important;
      text-decoration: none !important;
    }
  }

  .EmojiPickerReact:not(.epr-search-active) .epr-category-nav > button.epr-cat-btn:hover {
    color: var(--brand-primary) !important;
  }

  .highcharts-background {
    fill: var(--background-primary) !important;
  }

  .EmojiPickerReact .epr-search-container input.epr-search {
    padding: 4px 8px !important;
    background-color: var(--background-primary) !important;
    border-radius: 6px !important;
    border: 0.5px solid var(--border-primary) !important;
    color: var(--text-primary) !important;
    ${Typography.body_sm_medium}
    height: 25px !important;
  }

  .EmojiPickerReact .epr-search-container input.epr-search::placeholder {
    color: var(--background-muted) !important;
  }

  .EmojiPickerReact .epr-search-container input.epr-search:focus {
    border: 1px solid var(--brand-primary) !important;
    background: var(--background-primary) !important;
  }

  aside.EmojiPickerReact.epr-main {
    border-radius: 12px !important;
    border: 0.5px solid var(--border-primary) !important;
    box-shadow: var(--shadow-modal) !important;
  }
`;

export const Group = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: var(--background-secondary);
  margin: auto;
  height: 100vh;
  overflow: auto;
  scrollbar-width: none;
`; 