import moment from 'moment';
import { useCallback, useState } from 'react';
import Calendar from 'react-calendar';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateCreateTaskInput,
  updateMemberTaskField,
  updateMyTaskDetailsData,
  updateMyTaskField,
  updateTaskField,
  updatSubTaskList
} from '../../actions/taskActions';
import { Calender } from './styles';
import UserPreferenceSingleton from '../../helpers/userPreferenceSingleton';
import { nanoid } from 'nanoid';
import { getUTCDate, isEmpty } from '../../helpers/common';
import { COMMENT_TYPE } from '../../global/constants';
import { trackAnalyticActivity } from '../../services/analyticsService';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';
import { DataCommentsInterface } from '../../interfaces/TaskInterface';
import {
  createNewComment,
  updateTaskLocalData,
  updateRecurringTaskDetails,
  updateTaskDetails,
  loadMyTaskData
} from '../../services/taskServices';
import { TASK_ANALYTICS } from '../../global/analyticsConstants';

interface CalendarProps {
  date?: Date;
  setCalanderDate?: (date: Date) => void;
  data?: any;
  isUpdateReducer?: boolean;
  loadData?: () => void;
  updateTemplateProjectData?: (item: string | null) => void;
  isTemplateProject?: boolean;
  isShareDocModel?: boolean;
  setLinkDate?: (value: any) => void;
  isRecurringTask?: boolean;
  isMyTask?: boolean;
  isTaskDetails?: boolean;
  isSubTask?: boolean;
  isInbox?: boolean;
  isMember?: boolean;
}
const CalendarComponent = (props: CalendarProps) => {
  const {
    date,
    isUpdateReducer,
    data,
    loadData,
    setCalanderDate,
    isTemplateProject = false,
    updateTemplateProjectData,
    isShareDocModel,
    setLinkDate,
    isRecurringTask,
    isMyTask,
    isSubTask,
    isTaskDetails,
    isInbox,
    isMember
  } = props;
  //State
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  //use selector state variables
  const workspaceSelector = useSelector((state: RootReducerInterface) => state.workspace);
  const { workspace } = workspaceSelector;
  const { id: workspace_id } = workspace;
  //other variable
  const dispatch = useDispatch();

  const onClickDate = useCallback(
    async (date: Date) => {
      const updatedDate = moment(date).utc().format();
      setSelectedDate(date);
      setCalanderDate?.(date);
      let dueDate;
      if (!isShareDocModel) {
        if (isTemplateProject) {
          dueDate = updatedDate;
          updateTemplateProjectData?.(dueDate);
        } else if (isUpdateReducer) {
          dueDate = updatedDate;
          dispatch(updateCreateTaskInput({ propsName: 'dueDate', value: dueDate }));
        } else if (isInbox) {
          return;
        } else {
          if (moment(date).toISOString() !== moment(props?.data?.dueDate).toISOString()) {
            dueDate = updatedDate;
            const payload = {
              ...data,
              companyId: workspace_id,
              previousDueDate: props?.data?.dueDate,
              dueDate: dueDate
            };
            if (payload?.subscribers) {
              delete payload?.subscribers;
            }
            if (payload?.createdBy) {
              delete payload?.createdBy;
            }
            const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
            const newComment: DataCommentsInterface = {
              _id: nanoid(),
              CreatedBy: userDetails?.id,
              CreatedTime: new Date().toISOString(),
              UpdatedBy: userDetails?.id,
              UpdatedTime: new Date().toISOString(),
              Type: COMMENT_TYPE.SET_DUE_DATE,
              DueDate: payload?.dueDate
            };
            if (!isEmpty(payload?.previousDueDate)) {
              newComment['Type'] = COMMENT_TYPE.CHANGE_DUE_DATE;
              newComment['PreviousDueDate'] = payload?.previousDueDate;
            }
            const payloadTask = { dueDate: payload?.dueDate, projectId: payload?.projectId };
            if (isRecurringTask) {
              const response = await dispatch(updateRecurringTaskDetails(payload?.id, payloadTask));
              if (response) {
                if (newComment.Type === COMMENT_TYPE.SET_DUE_DATE) trackAnalyticActivity(TASK_ANALYTICS.SCHEDULED);
                else trackAnalyticActivity(TASK_ANALYTICS.RESCHEDULED);
                loadData?.();
              }
            } else {
              const updatedTaskData = {
                taskId: payload?.id,
                key: 'dueDate',
                value: payload?.dueDate
              };
              if (isMyTask || isMember) {
                isMyTask
                  ? dispatch(updateMyTaskField(updatedTaskData))
                  : dispatch(updateMemberTaskField(updatedTaskData));
                const updatedData = { ...data, dueDate: payload?.dueDate };
                dispatch(updateTaskLocalData(updatedData));
                if (isMyTask) {
                  await dispatch(loadMyTaskData(false));
                }
              } else {
                dispatch(updateTaskField(updatedTaskData));
              }
              if (isTaskDetails) {
                const updatedComments = [
                  ...(props?.data?.updatedComments || []),
                  {
                    id: newComment?.[`_id`],
                    type: newComment.Type,
                    updatedBy: userDetails?.id,
                    updatedTime: new Date().toISOString(),
                    createdOn: new Date().toISOString(),
                    user: { ...userDetails, name: userDetails?.given_name },
                    dueDate: payload?.dueDate,
                    previousDueDate: newComment?.PreviousDueDate,
                    status: true
                  }
                ];
                dispatch(updateMyTaskDetailsData({ propsName: 'updatedComments', value: updatedComments }));
                dispatch(updateMyTaskDetailsData({ propsName: 'dueDate', value: payload?.dueDate }));
              }
              if (isSubTask) {
                const taskDetailsClone = JSON.parse(JSON.stringify(data));
                taskDetailsClone.dueDate = payload?.dueDate;
                dispatch(updatSubTaskList(taskDetailsClone));
              }
              const response = await dispatch(updateTaskDetails(payload?.id, payloadTask));
              const commentPayload: DataCommentsInterface = {
                Type: COMMENT_TYPE.SET_DUE_DATE,
                DueDate: payload?.dueDate
              };

              if (!isEmpty(payload?.previousDueDate)) {
                commentPayload['Type'] = COMMENT_TYPE.CHANGE_DUE_DATE;
                commentPayload['PreviousDueDate'] = payload?.previousDueDate;
              }

              const commentResult = await dispatch(createNewComment(payload?.id, commentPayload));

              if (response && commentResult) {
                if (newComment.Type === COMMENT_TYPE.SET_DUE_DATE) trackAnalyticActivity(TASK_ANALYTICS.SCHEDULED);
                else trackAnalyticActivity(TASK_ANALYTICS.RESCHEDULED);
                if (!isMyTask && !isMember) loadData?.();
              }
            }
          }
        }
      } else {
        setLinkDate?.(getUTCDate(moment(updatedDate)).toISOString());
      }
    },
    [
      setCalanderDate,
      isShareDocModel,
      isTemplateProject,
      isUpdateReducer,
      isInbox,
      updateTemplateProjectData,
      dispatch,
      props?.data?.dueDate,
      props?.data?.updatedComments,
      data,
      workspace_id,
      isRecurringTask,
      loadData,
      isMyTask,
      isMember,
      isTaskDetails,
      isSubTask,
      setLinkDate
    ]
  );

  return (
    <Calender onClick={(e) => e.stopPropagation()}>
      <Calendar onClickDay={onClickDate} value={selectedDate || date} />
    </Calender>
  );
};

export default CalendarComponent;
