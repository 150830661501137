import React, { useCallback, useState } from 'react';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { useDispatch, useSelector } from 'react-redux';
import { clearCustomerDetail, updateCustomerDetail } from '../../../actions/customerActions';
import { createNewCustomer, updateCustomerData } from '../../../services/customerServices';
import { isEmpty } from '../../../helpers/common';
import Button from '../../button';
import { setErrorMessage, setSuccessMessage } from '../../../actions/messageActions';
import { captureException } from '../../../services/logService';
import { useHistory } from 'react-router-dom';
import {
  Form,
  Formation,
  Formbutton,
  Headline,
  Icon,
  Iconview,
  Inputvalues,
  Label,
  MainModalDiv,
  Modalblock,
  Modalhead,
  Textarea
} from './styles';
import { selectNewCustomer } from '../../../store/selectors';

interface Props {
  loadData?: () => void;
  handleCloseModal: () => void;
}

const CustomerModal: React.FC<Props> = (props) => {
  const { handleCloseModal, loadData } = props;
  //State
  const [loading, setLoading] = useState(false);

  //use selector state variables with memoized selectors
  const newCustomer = useSelector(selectNewCustomer);
  const { companyName, address } = newCustomer || {};

  //Other variable
  const history = useHistory();
  const dispatch = useDispatch();

  const onUpdateInputValue = useCallback(
    (propsName: string, value: string) => {
      dispatch(updateCustomerDetail({ propsName, value }));
    },
    [dispatch]
  );

  const handleSubmit = useCallback(
    async (e: React.SyntheticEvent) => {
      try {
        e.preventDefault();
        setLoading(true);
        let response;
        if (isEmpty(companyName)) {
          dispatch(setErrorMessage('Please enter company name.'));
          return;
        } else if (isEmpty(address)) {
          dispatch(setErrorMessage('Please enter address.'));
          return;
        }

        if (!isEmpty(newCustomer?.id)) {
          const updatePayload = {
            CompanyName: companyName,

            Address: address
          };
          response = await dispatch(updateCustomerData(newCustomer?.id, updatePayload));
          if (response) dispatch(setSuccessMessage('Customer details updated successfully!'));
        } else {
          const createPayload = {
            CompanyName: companyName,

            Address: address
          };
          response = await dispatch(createNewCustomer(createPayload));
          if (response) {
            dispatch(setSuccessMessage('Customer added successfully!'));
            history.push(`/customer/${response['_id']}`);
          }
        }
        if (response) {
          handleCloseModal();
          dispatch(clearCustomerDetail());
          if (loadData) loadData();
        }
      } catch (e) {
        captureException(e);
        console.log('ERROR', e);
      } finally {
        setLoading(false);
      }
    },
    [companyName, address, newCustomer?.id, dispatch, history, handleCloseModal, loadData]
  );

  return (
    <MainModalDiv>
      <Modalblock onSubmit={handleSubmit}>
        <Modalhead>
          <Headline>{isEmpty(newCustomer?.id) ? 'Add new customer' : 'Update Customer Data'}</Headline>
          <Icon onClick={handleCloseModal}>
            <SVGIcon name='close-icon' width='18' height='18' viewBox='0 0 18 18' fill='none' className='close-icon' />
          </Icon>
        </Modalhead>
        <Formation>
          <Form>
            <Label>
              Company name<span>*</span>
            </Label>
            <Iconview>
              <Inputvalues
                type='text'
                placeholder='Enter company name '
                value={companyName}
                onChange={(e) => {
                  onUpdateInputValue('companyName', e.target.value);
                }}
              />
            </Iconview>
          </Form>

          <Form>
            <Label>Address</Label>
            <Textarea
              rows={5}
              placeholder="Enter company's address"
              value={address}
              onChange={(e) => {
                onUpdateInputValue('address', e.target.value);
              }}
            />
          </Form>
        </Formation>
        <Formbutton>
          <Button
            title='Cancel'
            onClick={handleCloseModal}
            secondary={true}
            type='button'
            hasNotBoxshadow
            modelbtn={true}
          />
          <Button
            type='submit'
            title={isEmpty(newCustomer?.id) ? 'Add Customer' : 'Update Customer'}
            isLoading={loading}
            modelbtn={true}
          />
        </Formbutton>
      </Modalblock>
    </MainModalDiv>
  );
};

export default CustomerModal;
