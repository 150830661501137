/* eslint-disable max-len */
import React, { useCallback, useEffect, useState } from 'react';
import AppLayout from '../../../component/appLayout';
import {
  CircleProgress,
  Content,
  Detail,
  Group,
  Header,
  Heading,
  Left,
  Personaltext,
  ProgressText,
  Right,
  Storage,
  Total
} from './styles';
import Usagebox from './usagebox';
import { getWorkspaceUsageDetails } from '../../../services/workspaceService';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import { isEmpty, mathRound } from '../../../helpers/common';
import { captureException } from '../../../services/logService';
import UsageLoadingState from '../../../component/loading/usageLoading/usageLoading';
import { PersonalHeader } from '../apps/styles';
import { ResponsiveHeader } from '../SettingHeaderResponsive';
import { useMobileDevice } from '../../../global/useMobile';

export default function Usage() {
  // States
  const [loading, setLoading] = useState(false);
  //use selector state variables
  const workspaceSelector = useSelector((state: RootReducerInterface) => state.workspace);
  const { workspace, workspaceStorage } = workspaceSelector;
  const {
    totalMembers = 0,
    members = 0,
    clients = 0,
    projects = 0,
    useStorage = 0,
    totalStorage = 0,
    sizeType = 'GB'
  } = workspaceStorage;
  // Other variables
  const isMobile = useMobileDevice();
  const dispatch = useDispatch();
  const percentage = (useStorage * 100) / totalStorage;
  const progressRadius = 60;
  const circumference = 2 * Math.PI * progressRadius;
  const strokeDashoffset = circumference - (percentage / 100) * circumference;

  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      if (!isEmpty(workspace?.id)) {
        await dispatch(getWorkspaceUsageDetails());
      }
    } catch (error) {
      captureException(error);
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  }, [dispatch, workspace?.id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div>
      <AppLayout>
        {loading && !workspaceStorage?.members && <UsageLoadingState />}
        {workspaceStorage?.members && (
          <Group>
            <Header>
              <PersonalHeader>
                {isMobile && <ResponsiveHeader />}
                <Personaltext>Usage</Personaltext>
              </PersonalHeader>
            </Header>
            <Content>
              <Storage>
                <Left>
                  <CircleProgress viewBox='0 0 152 152' width={136} height={136}>
                    <circle cx='75' cy='75' r={progressRadius} className='progress-trail' />
                    <circle
                      cx='75'
                      cy='75'
                      r={progressRadius}
                      className='progress-bar'
                      strokeDasharray={circumference}
                      strokeDashoffset={strokeDashoffset}
                    />
                  </CircleProgress>
                  <ProgressText x='50%' y='50%' dominantBaseline='middle' textAnchor='middle'>
                    {mathRound(useStorage, 2)} {sizeType}
                  </ProgressText>
                </Left>
                <Right>
                  <Heading>Storage Usage</Heading>
                  <Detail>Teamcamp uses {mathRound(percentage)}% out of your free space.</Detail>
                </Right>
              </Storage>
              <Total>
                <Usagebox
                  title={'Team Member'}
                  count={`${members} / ${totalMembers}`}
                  members={members}
                  totalMembers={totalMembers}
                  isTeamMember={true}
                />
                <Usagebox title={'Clients'} count={clients} />
                <Usagebox title={'Projects'} count={projects} />
              </Total>
            </Content>
          </Group>
        )}
      </AppLayout>
    </div>
  );
}
