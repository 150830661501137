import styled from 'styled-components';
import { Typography } from '../../../global/theme';

const Group = styled.div`
  width: 100%;
  height: 100vh;
  background-color: var(--background-secondary);
  margin: auto;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 449px) {
    display: block;
  }
`;

const Content = styled.div`
  width: 100%;
  max-width: 750px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  background-color: var(--background-secondary);
  @media (max-width: 449px) {
    max-width: 100%;
    width: auto;
    padding: 50px 16px 30px;
    gap: 30px;
  }
`;

const Icon = styled.div`
  @media (max-width: 449px) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 18px;
    .svg-icon {
      width: 40px;
      height: 40px;
    }
  }
  cursor: pointer;
  :hover {
    background-color: var(--neutral-secondary);
    border-radius: 4px;
  }
`;

const Heading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  @media (max-width: 449px) {
    flex-direction: column;
    gap: 4px;
  }
`;

const Title = styled.h1`
  ${Typography.heading_lg}
  color: var(--text-primary);
  margin: 0;
  @media (max-width: 449px) {
    text-align: center;
  }
`;

const Text = styled.p`
  ${Typography.body_md}
  color: var(--text-secondary);
  margin: 0;
  @media (max-width: 449px) {
    text-align: center;
  }
`;

const Boxcontent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 30px;
  @media (max-width: 449px) {
    flex-direction: column;
    gap: 24px;
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 36px 30px;
  background-color: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  box-shadow: var(--shadow-card);
  border-radius: 8px;
  gap: 30px;
  @media (max-width: 449px) {
    padding: 30px;
    gap: 12px;
  }
`;

const Icon1 = styled.div``;

const Titleformat = styled.h1`
  ${Typography.body_lg_semibold}
  color:  var(--text-primary);
  margin: 0;
  @media (max-width: 449px) {
    text-align: center;
  }
`;

const Textformat = styled.p`
  ${Typography.body_md}
  color: var(--text-secondary);
  text-align: center;
  margin: 0;
`;

const Modalhead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 0.5px solid var(--border-primary);
`;

const Headline = styled.h2`
  ${Typography.body_lg_semibold}
  color: var(--text-primary);
  margin: 0;
`;

const BoxView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  @media (max-width: 449px) {
    gap: 12px;
  }
`;

const BoxBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  @media (max-width: 449px) {
    gap: 24px;
  }
`;

const TopView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  @media (max-width: 449px) {
    gap: 12px;
  }
`;

export {
  Group,
  Content,
  Icon,
  Heading,
  Text,
  Title,
  Boxcontent,
  Box,
  Icon1,
  Titleformat,
  Textformat,
  Modalhead,
  Headline,
  BoxView,
  BoxBottom,
  TopView
};
