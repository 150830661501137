import { useDispatch, useSelector } from 'react-redux';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';
import React, { useCallback, useState } from 'react';
import { ProjectFilesInterface } from '../../interfaces/ProjectInterface';
import { calculateDay, extensionName, isEmpty } from '../../helpers/common';
import { setProjectFiles } from '../../actions/projectActions';
import { FILE_EXTENTIONS, FILE_TYPES } from '../../global/constants';
import {
  BreadCrumb,
  BreadCrumbContent,
  BreadSlash,
  BredCrumbBlock,
  Card,
  Carddecs,
  Cardtitle,
  CloseIcon,
  FileIconDiv,
  Folderdiv,
  FolderRight,
  Headertext,
  Icon,
  Leftcard,
  Rightcard,
  RightHeader
} from './style';
import SVGIcon from '../../assets/images/svg/SVGIcon';
import FileSVGIcon from '../../assets/images/svg/filesIconSvg';

interface Props {
  setAttachment?: () => void;
  setSelectFile?: (value: boolean) => void;
  onSelectFile?: (value: any) => void;
  isMessage?: boolean;
  isCreateTaskModal?: boolean;
  isProjectMessageDetail?: boolean;
  isProjectOverview?: boolean;
}

const RenderExistingFile: React.FC<Props> = (props) => {
  const {
    setAttachment,
    setSelectFile,
    onSelectFile,
    isMessage,
    isCreateTaskModal,
    isProjectMessageDetail,
    isProjectOverview
  } = props;
  //States
  const [path, setpath] = useState<ProjectFilesInterface[]>([]);
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { project: projectSelector } = stateSelector || {};
  const { projectAllFiles, projectFiles } = projectSelector;
  //Other variable
  const dispatch = useDispatch();

  const onClickFilesText = useCallback(() => {
    const withoutParentData = projectAllFiles?.filter((x) => isEmpty(x?.parentId));
    dispatch(setProjectFiles(withoutParentData));
    setpath([]);
  }, [dispatch, projectAllFiles]);

  const onClickPath = useCallback(
    (e: React.SyntheticEvent, item: ProjectFilesInterface) => {
      e.stopPropagation();
      const subFiles = projectAllFiles?.filter((x) => x?.parentId === item?.id);
      dispatch(setProjectFiles(subFiles));
      const itemIndex = path?.findIndex((x) => x?.id === item?.id);
      let pathClone = path;
      const ref = pathClone.slice(0, itemIndex + 1);
      setpath(ref);
    },
    [path, projectAllFiles, dispatch]
  );

  const closeFileAttach = () => {
    setpath([]);
    setAttachment?.();
    setSelectFile?.(false);
  };

  const onClickFile = useCallback(
    (e: React.SyntheticEvent, item: ProjectFilesInterface) => {
      e.stopPropagation();
      if (item?.uploadFileType === FILE_TYPES.FOLDER) {
        const subFiles = projectAllFiles?.filter((x) => x?.parentId === item?.id);
        dispatch(setProjectFiles(subFiles));
        const updatedPath = [...path, item];
        setpath(updatedPath);
      } else {
        onSelectFile?.(item);
        setSelectFile?.(false);
      }
    },
    [projectAllFiles, dispatch, path, onSelectFile, setSelectFile]
  );

  return (
    <>
      <FolderRight isMessage={isMessage} isCreateTaskModal={isCreateTaskModal} isProjectOverview={isProjectOverview}>
        <RightHeader isProjectMessageDetail={isProjectMessageDetail} isMessage={isMessage}>
          <Headertext isCreateTaskModal={isCreateTaskModal} isProjectOverview={isProjectOverview}>
            Select File to Attach{' '}
          </Headertext>
          <CloseIcon onClick={closeFileAttach}>
            <SVGIcon name='left-arrow-icon' width='18' height='18' viewBox='0 0 18 18' className='back-arrow' />
            <SVGIcon name='file-close-icon' width='24' height='24' viewBox='0 0 24 24' className='fillColor' />
          </CloseIcon>
        </RightHeader>
        {path?.length > 0 && (
          <BreadCrumb>
            <Icon>
              <SVGIcon name='filepath-folder-icon' width='24' height='24' viewBox='0 0 24 24' />
            </Icon>
            <BreadCrumbContent>
              <BredCrumbBlock onClick={onClickFilesText}>Files</BredCrumbBlock>
              {path?.map((item, index: number) => {
                return (
                  <React.Fragment key={item?.id}>
                    <BreadSlash>/</BreadSlash>
                    <BredCrumbBlock isActive={index === path?.length - 1} onClick={(e) => onClickPath(e, item)}>
                      {item?.name}
                    </BredCrumbBlock>
                  </React.Fragment>
                );
              })}
            </BreadCrumbContent>
          </BreadCrumb>
        )}
        <Folderdiv isMessage={isMessage}>
          {projectFiles?.map((item) => {
            const extension = extensionName(item).toLowerCase();
            return (
              <Card key={item.id} onClick={(e) => onClickFile(e, item)}>
                <Leftcard>
                  {(!item?.uploadFileType || item?.uploadFileType === FILE_TYPES.UPLOAD) &&
                  !FILE_EXTENTIONS.includes(extension) ? (
                    <FileIconDiv>
                      <FileSVGIcon name={'blank'} height='36' width='36' viewBox='0 0 60 60' />
                      <div
                        style={{
                          fontSize: extension?.length === 5 ? 6 : 7
                        }}
                        className='file-text'>
                        {extension.split('.')?.pop()?.toUpperCase()}
                      </div>
                    </FileIconDiv>
                  ) : (
                    <FileSVGIcon
                      name={
                        !item?.uploadFileType || item?.uploadFileType === FILE_TYPES.UPLOAD
                          ? extension
                          : `icon_${item?.uploadFileType}`
                      }
                      height='36'
                      width='36'
                      viewBox='0 0 60 60'
                    />
                  )}
                </Leftcard>
                <Rightcard>
                  <Cardtitle>{item.name}</Cardtitle>
                  <Carddecs>{`${calculateDay(item.createdOn)} by ${item?.user?.name}`}</Carddecs>
                </Rightcard>
              </Card>
            );
          })}
        </Folderdiv>
      </FolderRight>
    </>
  );
};

export default RenderExistingFile;
