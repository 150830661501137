import styled, { css } from 'styled-components';
import { Typography } from '../../../global/theme';

interface ColumnInterface {
  columnWidth?: number;
  alignPlace?: string;
}

interface ContentInterface {
  contentWidth: number;
}

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  border-style: hidden;
  border-radius: 8px;
  box-shadow: 0 0 0 0.5px var(--border-primary);
  background: var(--background-primary);
  .loading-animation {
    overflow: hidden;
  }

  .loading-animation::before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    animation: loading 1s infinite;
    background: linear-gradient(to right, transparent, var(--background-tertiary), transparent);
  }

  @keyframes loading {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  @media (max-width: 449px) {
    white-space: nowrap;
  }
`;

const Th = styled.td<ColumnInterface>`
  border: 0.5px solid var(--border-primary);
  text-align: left;
  padding: 12px;
  ${Typography.body_md_medium}
  text-transform: uppercase;
  color: var(--text-secondary);
  background-color: var(--background-tertiary);
  :first-child {
    border-radius: 8px 0 0 0;
  }
  :last-child {
    border-radius: 0 8px 0 0;
  }
  /* item align */
  ${(props: ColumnInterface) =>
    props.alignPlace &&
    css`
      text-align: ${props.alignPlace};
    `}
`;
const Td = styled.td<ColumnInterface>`
  border: 0.5px solid var(--border-primary);
  text-align: left;
  /* column width */
  ${(props: ColumnInterface) =>
    props.columnWidth &&
    css`
      width: ${props.columnWidth}px;
    `}
  /* item align */
  ${(props: ColumnInterface) =>
    props.alignPlace &&
    css`
      text-align: ${props.alignPlace};
    `}
`;

const First = styled.div<ContentInterface>`
  height: 9px;
  border-radius: 7px;
  background-color: var(--background-muted);
  margin: 12px;
  /* content width */
  ${(props: ContentInterface) =>
    props.contentWidth &&
    css`
      width: ${props.contentWidth}px;
    `}
`;
const TableSection = styled.div`
  overflow: hidden;
  border: 0.5px solid var(--border-primary);
  border-radius: 8px;
  scrollbar-width: none;
`;
export { Table, First, TableSection, Td, Th };
