import { useCallback, useState } from 'react';
import { Tableheaders, Left, Right, Icon, Allbutton, Dropbox, Bottomdata, Createitem, Titlelast } from './styles';
import SVGIcon from '../../assets/images/svg/SVGIcon';
import MainTableComponent from '../maintable/maintable';
import ModalCustom from '../models/modal';
import Screenshotmodal from '../models/screenShotModal/screenShotModal';
import { TIME_REPORT_DATA_FILTER_ID, TIME_REPORT_VIEW_TYPE_ID } from '../../global/constants';
import { useSelector } from 'react-redux';
import { ActivityDataInterface, DataItem, TableData, WorkSummaryInterface } from '../../interfaces/TimeReportInterface';
import { CSVLink } from 'react-csv';
import { TimeReportCSVHeader } from '../../global/row';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';

interface Props {
  timeSheetData: TableData;
  workSummaryData: WorkSummaryInterface[];
  activityData: ActivityDataInterface[];
  onChangeReportFilter: (propsName: string, value: number) => void;
}
export default function Tableheader({ timeSheetData, workSummaryData, activityData, onChangeReportFilter }: Props) {
  //State
  const [isStatusDropdownOpen, setIsStatusDropdownOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<ActivityDataInterface | null | DataItem>();
  //use selector state variables
  const timeTrackingSelector = useSelector((state: RootReducerInterface) => state.timeTracking);
  const { reportFilter } = timeTrackingSelector;
  const { selectedDataType } = reportFilter;

  const openStatusDropdown = useCallback((value: boolean) => {
    setIsStatusDropdownOpen(value);
  }, []);

  const handleOpen = useCallback((item: ActivityDataInterface | DataItem) => {
    setSelectedItem(item);
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    setSelectedItem(null);
  }, []);

  return (
    <div>
      <Tableheaders>
        <Left>
          <Allbutton
            isActive={selectedDataType === TIME_REPORT_DATA_FILTER_ID.WORKSUMMARY}
            onClick={() => onChangeReportFilter('selectedDataType', TIME_REPORT_DATA_FILTER_ID.WORKSUMMARY)}>
            Work Summary
          </Allbutton>
          <Allbutton
            isActive={selectedDataType === TIME_REPORT_DATA_FILTER_ID.TIMESHEET}
            onClick={() => onChangeReportFilter('selectedDataType', TIME_REPORT_DATA_FILTER_ID.TIMESHEET)}>
            Timesheet
          </Allbutton>
          <Allbutton
            isActive={selectedDataType === TIME_REPORT_DATA_FILTER_ID.ACTIVITYLOG}
            onClick={() => onChangeReportFilter('selectedDataType', TIME_REPORT_DATA_FILTER_ID.ACTIVITYLOG)}>
            Activity Log
          </Allbutton>
        </Left>
        <Right>
          <div className='custom-dropdown'>
            <div onClick={() => openStatusDropdown(!isStatusDropdownOpen)}>
              {selectedDataType !== TIME_REPORT_DATA_FILTER_ID.ACTIVITYLOG && (
                <Icon>
                  <SVGIcon name='time-tableicon' width='18' height='18' viewBox='0 0 18 18' className='svg-icon' />
                </Icon>
              )}
            </div>
            {isStatusDropdownOpen && (
              <div className='dropdown-content'>
                <Dropbox>
                  <div className='menuStyle'>
                    <Bottomdata isborderredius>
                      <Createitem
                        onClick={() => onChangeReportFilter('timeViewType', TIME_REPORT_VIEW_TYPE_ID.BY_PROJECT)}>
                        <p>By Project</p>
                      </Createitem>
                      <Createitem
                        onClick={() => onChangeReportFilter('timeViewType', TIME_REPORT_VIEW_TYPE_ID.BY_MEMBER)}>
                        <p>By Member</p>
                      </Createitem>
                      <Createitem
                        onClick={() => onChangeReportFilter('timeViewType', TIME_REPORT_VIEW_TYPE_ID.BY_ACTIVITY)}>
                        <p>By Activity</p>
                      </Createitem>
                    </Bottomdata>
                  </div>
                </Dropbox>
              </div>
            )}
          </div>
          <CSVLink
            data={activityData || []}
            headers={TimeReportCSVHeader}
            filename={`time_log_report.csv`}
            style={{ textDecoration: 'none' }}>
            <Titlelast>Export as CSV</Titlelast>
          </CSVLink>
        </Right>
      </Tableheaders>
      {selectedDataType === TIME_REPORT_DATA_FILTER_ID.WORKSUMMARY && (
        <MainTableComponent
          showIcon={true}
          width
          columns={[
            { Header: 'Projects Name', accessor: 'name' },
            { Header: 'Time', accessor: 'time' },
            { Header: 'Amount', accessor: 'amount' }
          ]}
          data={workSummaryData}
        />
      )}
      {selectedDataType === TIME_REPORT_DATA_FILTER_ID.TIMESHEET && (
        <MainTableComponent
          isScrollTable={true}
          highlightLastRow={true}
          columns={timeSheetData?.column || []}
          data={timeSheetData?.data || []}
        />
      )}
      {selectedDataType === TIME_REPORT_DATA_FILTER_ID.ACTIVITYLOG && (
        <MainTableComponent
          isborder={true}
          isborderbottom={true}
          databorder={true}
          handleOpen={(item) => handleOpen(item)}
          columns={[
            {
              Header: 'Date',
              accessor: 'date'
            },
            { Header: 'Time', accessor: 'time' },
            { Header: 'Activity Name', accessor: 'activityName' },
            { Header: 'Member', accessor: 'member' },
            { Header: 'Project Name', accessor: 'projectName' },
            { Header: 'Duration', accessor: 'duration' },
            { Header: 'Screenshots', accessor: 'screenshots' }
          ]}
          data={activityData}
        />
      )}
      <ModalCustom open={open} onClose={handleClose} isTracker={true}>
        <Screenshotmodal
          taskname={selectedItem?.activityName}
          time={`${selectedItem?.projectName} (${selectedItem?.duration}, ${selectedItem?.date})`}
          mouseclicks='3829 mouse clicks'
          keyboardhits='1101 keyboard hits'
          mainimage={selectedItem?.screenshotImageList || []}
          modalClose={handleClose}
        />
      </ModalCustom>
    </div>
  );
}
