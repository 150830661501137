import React, { useCallback, useEffect, useState } from 'react';
import AppLayout from '../../component/appLayout';
import {
  Group,
  PersonalHeader,
  Personaltext,
  Pesonalbtns,
  Notificationdiv,
  Notificationcontent,
  Projectheadingtop,
  Titletext,
  Header
} from './styles';
import Button from '../../component/button';
import { useDispatch, useSelector } from 'react-redux';
import { getNotificationSettingDetails, updateNotificationSettingDetails } from '../../services/settingServices';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';
import { clearNotificationsSettingsDetails, updateNotificationsSettingsDetails } from '../../actions/settingActions';
import { ResponsiveHeader } from './SettingHeaderResponsive';
import { useMobileDevice } from '../../global/useMobile';

const Notification: React.FC = () => {
  // States
  const [loading, setLoading] = useState(false);
  //use selector state variables
  const settingSelector = useSelector((state: RootReducerInterface) => state.settings);
  const { notificationSettingDetails } = settingSelector;
  // Other variables
  const dispatch = useDispatch();
  const isMobile = useMobileDevice();

  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      await dispatch(getNotificationSettingDetails());
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    loadData();
    return () => {
      dispatch(clearNotificationsSettingsDetails());
    };
  }, [dispatch, loadData]);

  const onUpdateNotificationDetails = useCallback(
    (propsName: string, value: boolean) => {
      dispatch(updateNotificationsSettingsDetails({ propsName, value }));
    },
    [dispatch]
  );

  const onSubmit = useCallback(
    async (e: React.SyntheticEvent) => {
      try {
        e.preventDefault();
        setLoading(true);
        const { updated, ...payload } = notificationSettingDetails || {};
        await dispatch(updateNotificationSettingDetails(payload));
        dispatch(updateNotificationsSettingsDetails({ propsName: 'updated', value: false }));
      } catch (error) {
        console.log('error', error);
      } finally {
        setLoading(false);
      }
    },
    [dispatch, notificationSettingDetails]
  );

  return (
    <>
      <AppLayout>
        <Group onSubmit={onSubmit}>
          <PersonalHeader>
            <Header>
              {isMobile && <ResponsiveHeader />}
              <Personaltext>Notifications</Personaltext>
            </Header>
            <Pesonalbtns>
              <Button title='Update' type='submit' isLoading={loading} modelbtn />
            </Pesonalbtns>
          </PersonalHeader>
          <Notificationdiv>
            <Notificationcontent>
              <Projectheadingtop>
                <input
                  type='checkbox'
                  className='checkbox-round'
                  checked={notificationSettingDetails?.Browser}
                  onChange={() => onUpdateNotificationDetails('Browser', !notificationSettingDetails?.Browser)}
                  disabled={loading}
                />
                <Titletext>Browser notification</Titletext>
              </Projectheadingtop>
            </Notificationcontent>
            <Notificationcontent>
              <Projectheadingtop>
                <input
                  type='checkbox'
                  className='checkbox-round'
                  checked={notificationSettingDetails?.Email}
                  onChange={() => onUpdateNotificationDetails('Email', !notificationSettingDetails?.Email)}
                  disabled={loading}
                />
                <Titletext>Email notification</Titletext>
              </Projectheadingtop>
            </Notificationcontent>
            <Notificationcontent>
              <Projectheadingtop>
                <input
                  type='checkbox'
                  className='checkbox-round'
                  checked={notificationSettingDetails?.Mobile}
                  onChange={() => onUpdateNotificationDetails('Mobile', !notificationSettingDetails?.Mobile)}
                  disabled={loading}
                />
                <Titletext>Mobile push notification</Titletext>
              </Projectheadingtop>
            </Notificationcontent>
          </Notificationdiv>
        </Group>
      </AppLayout>
    </>
  );
};
export default Notification;
