import React from 'react';
import { Editor } from '@tiptap/react';
import SVGIcon from '../../assets/images/svg/SVGIcon';
import { ButtonMainDiv, Divider, IconDiv, TableMainDiv } from './styles';

interface TableToolbarProps {
  editor: Editor;
}

const TableToolbar: React.FC<TableToolbarProps> = ({ editor }) => {
  if (!editor) return null;

  return (
    <TableMainDiv>
      <ButtonMainDiv>
        <p>Rows</p>
        <IconDiv onClick={() => editor.chain().focus().addRowAfter().run()}>
          <SVGIcon name='table-plus-icon' width='16' height='16' viewBox='0 0 16 16' fill='none' className='svgicon' />
        </IconDiv>
        <IconDiv onClick={() => editor.chain().focus().deleteRow().run()}>
          <SVGIcon name='table-minus-icon' width='16' height='16' viewBox='0 0 16 16' fill='none' className='svgicon' />
        </IconDiv>
      </ButtonMainDiv>
      <Divider />
      <ButtonMainDiv>
        <p>Columns</p>
        <IconDiv onClick={() => editor.chain().focus().addColumnAfter().run()}>
          <SVGIcon name='table-plus-icon' width='16' height='16' viewBox='0 0 16 16' fill='none' className='svgicon' />
        </IconDiv>
        <IconDiv onClick={() => editor.chain().focus().deleteColumn().run()}>
          <SVGIcon name='table-minus-icon' width='16' height='16' viewBox='0 0 16 16' fill='none' className='svgicon' />
        </IconDiv>
      </ButtonMainDiv>
      <Divider />
      <ButtonMainDiv>
        <p>Header</p>
        <IconDiv onClick={() => editor.chain().focus().toggleHeaderRow().run()}>
          <SVGIcon
            name='table-first-row-header-icon'
            width='16'
            height='16'
            viewBox='0 0 16 16'
            stroke='none'
            className='header-icon'
          />
        </IconDiv>
        <IconDiv onClick={() => editor.chain().focus().toggleHeaderColumn().run()}>
          <SVGIcon
            name='table-first-column-header-icon'
            width='16'
            height='16'
            viewBox='0 0 16 16'
            stroke='none'
            className='header-icon'
          />
        </IconDiv>
      </ButtonMainDiv>
      <Divider />
      <IconDiv onClick={() => editor.chain().focus().deleteTable().run()}>
        <SVGIcon name='table-delete-icon' width='16' height='16' viewBox='0 0 16 16' className='header-icon' />
      </IconDiv>
    </TableMainDiv>
  );
};

export default TableToolbar;
