import styled, { css } from 'styled-components';
import { Typography } from '../../global/theme';
interface Props {
  ismargin?: number;
  isgap?: number;
  ismaxwidth?: boolean;
  isMessageEmpty?: boolean;
  isProjectEmpty?: boolean;
  isInboxEmpty?: boolean;
  isReportTimeEmpty?: boolean;
  isApiKeyEmpty?: boolean;
  isSearchmodalEmpty?: boolean;
  isMilestoneTaskEmpty?: boolean;
  isOverviewEmpty?: boolean;
  isCustomerDetail?: boolean;
  isCustomerMemberEmpty?: boolean;
  isAllProjectEmpty?: boolean;
}
const Group = styled.div<Props>`
  width: 100%;
  margin: auto;
  ${(props) =>
    props.isMessageEmpty &&
    css`
      display: flex;
      justify-content: center;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0%, -50%);
    `}
  ${(props) =>
    props.isProjectEmpty &&
    css`
      position: absolute;
      top: calc(50% - 32px);
      left: 50%;
      transform: translate(-50%, -50%);
      width: auto;
      @media (max-width: 449px) {
        top: 50%;
      }
    `}
  ${(props) =>
    props.isAllProjectEmpty &&
    css`
      padding: 35px 0;
      background: var(--background-primary);
      border: 0.5px solid var(--border-primary);
      border-radius: 12px;
    `}
`;

const Container = styled.div<Props>`
  width: 100%;
  max-width: 240px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 auto;
  ${(props) =>
    props.ismargin &&
    css`
      margin: ${props.ismargin}px auto;
    `}
  ${(props) =>
    props.ismaxwidth &&
    css`
      max-width: 330px;
    `}
     ${(props) =>
    props.isReportTimeEmpty &&
    css`
      max-width: 100%;
    `}
`;

const Emptyblock = styled.div<Props>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.isgap &&
    css`
      gap: ${props.isgap}px;
    `}

  ${(props) =>
    props.isInboxEmpty &&
    css`
      img {
        height: 150px;
      }
    `}
    ${(props) =>
    props.isSearchmodalEmpty &&
    css`
      img {
        width: 120px;
        height: 68px;
      }
    `}
       ${(props) =>
    props.isMilestoneTaskEmpty &&
    css`
      img {
        width: 160px;
        height: 88px;
      }
    `}
  ${(props) =>
    props.isOverviewEmpty &&
    css`
      img {
        width: 160px;
        height: 88px;
      }
    `}
      ${(props) =>
    props.isCustomerMemberEmpty &&
    css`
      img {
        width: 142px;
        height: 78px;
      }
      @media (max-width: 449px) {
        img {
          width: 160px;
          height: 88px;
        }
      }
    `}
      ${(props) =>
    props.isAllProjectEmpty &&
    css`
      gap: 16px;
      img {
        width: 155px;
        height: 88px;
      }
    `}
         ${(props) =>
    props.isCustomerDetail &&
    css`
      img {
        width: 160px;
        height: 88px;
      }
    `}
`;

const Emptycontent = styled.div<Props>`
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: center;
  ${(props) =>
    props.isApiKeyEmpty &&
    css`
      gap: 12px;
    `}
  ${(props) =>
    props.isOverviewEmpty &&
    css`
      gap: 0;
    `}
`;

const Text = styled.h1<Props>`
  ${Typography.body_lg_semibold}
  display: flex;
  align-items: center;
  color: var(--text-primary);
  margin: 0;
  white-space: nowrap;
  ${(props) =>
    props.isMilestoneTaskEmpty &&
    css`
      ${Typography.body_md_semibold}
    `}
  ${(props) =>
    props.isOverviewEmpty &&
    css`
      ${Typography.body_md_semibold}
    `}
  ${(props) =>
    props.isCustomerDetail &&
    css`
      ${Typography.body_md_semibold}
    `}
  ${(props) =>
    props.isCustomerMemberEmpty &&
    css`
      ${Typography.body_md_semibold}
    `}
`;

const Title = styled.p<Props>`
  ${Typography.body_md}
  text-align: center;
  color: var(--text-secondary);
  margin: 0;
  ${(props) =>
    props.isApiKeyEmpty &&
    css`
      color: var(--text-primary);
      a {
        color: var(--brand-primary);
        text-decoration: none;
      }
    `}
  ${(props) =>
    props.isSearchmodalEmpty &&
    css`
      color: var(--text-primary);
    `}
    ${(props) =>
    props.isAllProjectEmpty &&
    css`
      font-weight: 600;
      color: var(--text-primary);
    `}
`;

const Menu = styled.div<Props>`
  display: flex;
  align-items: center;
  gap: 5px;
  ${(props) =>
    props.isMilestoneTaskEmpty &&
    css`
      .button {
        padding: 4px 15px;
        ${Typography.body_sm_medium}
      }
    `}
  ${(props) =>
    props.isOverviewEmpty &&
    css`
      .button {
        padding: 4px 15px;
        ${Typography.body_sm_medium}
      }
    `}
      ${(props) =>
    props.isCustomerDetail &&
    css`
      margin-top: 4px;
      @media (max-width: 449px) {
        margin-top: 0;
      }
    `}
`;
export { Group, Container, Emptyblock, Emptycontent, Text, Title, Menu };
