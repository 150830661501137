import React from 'react';
import {
  Bottomcard,
  Bottomember,
  Bottomfile,
  Bottominfo,
  Box,
  Cards,
  Circle,
  Desc,
  Detail,
  Filecards,
  Files,
  First,
  Firstcard,
  Firstfile,
  Firstinfo,
  Firstmember,
  Forth,
  Header,
  Heading,
  Icon,
  Invoice,
  Leftdiv,
  Leftinfo,
  Leftside,
  Lefttop,
  Link,
  Maindiv,
  Name,
  Profile,
  Project,
  Right,
  Rightdiv,
  Rightinfo,
  Righttop,
  Second,
  Table,
  Third,
  Title,
  Topcard,
  Topfile,
  Topinfo,
  Topmember,
  Topright
} from './styles';
import { useSelector } from 'react-redux';
import customeprofiledark from '../../../assets/images/customerprofiledark.svg';
import customeprofilelight from '../../../assets/images/customeprofilelight.svg';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';

export default function CustomerdetailLoading() {
  //use selector state variables
  const settingsSelector = useSelector((state: RootReducerInterface) => state.settings);
  const { themeMode } = settingsSelector;
  return (
    <div style={{ width: '100%' }}>
      <Maindiv>
        <Leftdiv>
          <Header>
            <Profile>
              {themeMode?.theme === 'dark' ? (
                <img src={customeprofiledark} width={58} height={58} alt='logo' />
              ) : (
                <img src={customeprofilelight} width={58} height={58} alt='logo' />
              )}
              <Name>
                <First className='loading-animation' />
                <Desc className='loading-animation' />
              </Name>
            </Profile>
            <Icon>
              <SVGIcon name='large-more-icon' width='24' height='24' viewBox='0 0 24 24' className='large-more-icon' />
            </Icon>
          </Header>
          <Project>
            <Title>Projects</Title>
            <Cards>
              <Firstcard>
                <Topcard>
                  <Detail>
                    <Leftside>
                      <First className='loading-animation' />
                      <First className='loading-animation bottom' />
                    </Leftside>
                    <Right className='loading-animation'></Right>
                  </Detail>
                  <First className='loading-animation last-bottom' />
                </Topcard>
                <Bottomcard>
                  <Circle className='loading-animation' />
                  <Circle className='loading-animation' />
                  <Circle className='loading-animation' />
                  <Circle className='loading-animation' />
                  <Circle className='loading-animation' />
                </Bottomcard>
              </Firstcard>
              <Firstcard>
                <Topcard>
                  <Detail>
                    <Leftside>
                      <First className='loading-animation' />
                      <First className='loading-animation bottom' />
                    </Leftside>
                    <Right className='loading-animation'></Right>
                  </Detail>
                  <First className='loading-animation last-bottom' />
                </Topcard>
                <Bottomcard>
                  <Circle className='loading-animation' />
                  <Circle className='loading-animation' />
                  <Circle className='loading-animation' />
                  <Circle className='loading-animation' />
                  <Circle className='loading-animation' />
                </Bottomcard>
              </Firstcard>
              <Firstcard>
                <Topcard>
                  <Detail>
                    <Leftside>
                      <First className='loading-animation' />
                      <First className='loading-animation bottom' />
                    </Leftside>
                    <Right className='loading-animation'></Right>
                  </Detail>
                  <First className='loading-animation last-bottom' />
                </Topcard>
                <Bottomcard>
                  <Circle className='loading-animation' />
                  <Circle className='loading-animation' />
                  <Circle className='loading-animation' />
                  <Circle className='loading-animation' />
                  <Circle className='loading-animation' />
                </Bottomcard>
              </Firstcard>
            </Cards>
          </Project>
          <Files>
            <Heading>
              <Title>Document & Files</Title>
              <Link>+ Add</Link>
            </Heading>
            <Filecards>
              <Firstfile>
                <Topfile>
                  <SVGIcon name='file-loading-icon' width='60' height='60' viewBox='0 0 60 60' className='fill-color' />
                </Topfile>
                <Bottomfile>
                  <First className='top loading-animation' />
                  <First className='bottom loading-animation' />
                </Bottomfile>
              </Firstfile>
              <Firstfile>
                <Topfile>
                  <SVGIcon
                    name='document-loading-icon'
                    width='60'
                    height='60'
                    viewBox='0 0 60 60'
                    className='fill-color'
                  />
                </Topfile>
                <Bottomfile>
                  <First className='top loading-animation' />
                  <First className='bottom loading-animation' />
                </Bottomfile>
              </Firstfile>
              <Firstfile>
                <Topfile>
                  <SVGIcon
                    name='image-loading-icon'
                    width='60'
                    height='60'
                    viewBox='0 0 60 60'
                    className='fill-color'
                  />
                </Topfile>
                <Bottomfile>
                  <First className='top loading-animation' />
                  <First className='bottom loading-animation' />
                </Bottomfile>
              </Firstfile>
              <Firstfile>
                <Topfile>
                  <SVGIcon name='pdf-loading-icon' width='60' height='60' viewBox='0 0 60 60' className='fill-color' />
                </Topfile>
                <Bottomfile>
                  <First className='top loading-animation' />
                  <First className='bottom loading-animation' />
                </Bottomfile>
              </Firstfile>
            </Filecards>
          </Files>
          <Invoice>
            <Heading>
              <Title>Invoices</Title>
              <Link>See All</Link>
            </Heading>
            <Table>
              <thead>
                <tr className='row'>
                  <th>
                    <p>Invoice no</p>
                    <SVGIcon name='line' width='1' height='21' viewBox='0 0 1 21' className='lineicon' />
                  </th>
                  <th>
                    <p>Invoice Date</p>
                    <SVGIcon name='line' width='1' height='21' viewBox='0 0 1 21' className='lineicon' />
                  </th>
                  <th>
                    <p>Amount</p>
                    <SVGIcon name='line' width='1' height='21' viewBox='0 0 1 21' className='lineicon' />
                  </th>
                  <th>
                    <p>Status</p>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr className='first-row'>
                  <td>
                    <First className='first loading-animation'></First>
                  </td>
                  <td>
                    <Second>
                      <First className='top loading-animation' />
                      <First className='bottom loading-animation' />
                    </Second>
                  </td>
                  <td>
                    <Third>
                      <First className='top loading-animation' />
                      <First className='bottom loading-animation' />
                    </Third>
                  </td>
                  <td>
                    <Forth>
                      <First className='top loading-animation' />
                    </Forth>
                  </td>
                  <td>
                    <Icon>
                      <SVGIcon name='more-icon' width='18' height='18' viewBox='0 0 18 18' className='more-icon' />
                    </Icon>
                  </td>
                </tr>
                <tr className='first-row'>
                  <td>
                    <First className='first loading-animation'></First>
                  </td>
                  <td>
                    <Second>
                      <First className='top loading-animation' />
                      <First className='bottom loading-animation' />
                    </Second>
                  </td>
                  <td>
                    <Third>
                      <First className='top loading-animation' />
                      <First className='bottom loading-animation' />
                    </Third>
                  </td>
                  <td>
                    <Forth>
                      <First className='top loading-animation' />
                    </Forth>
                  </td>
                  <td>
                    <Icon>
                      <SVGIcon name='more-icon' width='18' height='18' viewBox='0 0 18 18' className='more-icon' />
                    </Icon>
                  </td>
                </tr>
                <tr className='first-row'>
                  <td>
                    <First className='first loading-animation'></First>
                  </td>
                  <td>
                    <Second>
                      <First className='top loading-animation' />
                      <First className='bottom loading-animation' />
                    </Second>
                  </td>
                  <td>
                    <Third>
                      <First className='top loading-animation' />
                      <First className='bottom loading-animation' />
                    </Third>
                  </td>
                  <td>
                    <Forth>
                      <First className='top loading-animation' />
                    </Forth>
                  </td>
                  <td>
                    <Icon>
                      <SVGIcon name='more-icon' width='18' height='18' viewBox='0 0 18 18' className='more-icon' />
                    </Icon>
                  </td>
                </tr>
                <tr className='first-row'>
                  <td>
                    <First className='first loading-animation'></First>
                  </td>
                  <td>
                    <Second>
                      <First className='top loading-animation' />
                      <First className='bottom loading-animation' />
                    </Second>
                  </td>
                  <td>
                    <Third>
                      <First className='top loading-animation' />
                      <First className='bottom loading-animation' />
                    </Third>
                  </td>
                  <td>
                    <Forth>
                      <First className='top loading-animation' />
                    </Forth>
                  </td>
                  <td>
                    <Icon>
                      <SVGIcon name='more-icon' width='18' height='18' viewBox='0 0 18 18' className='more-icon' />
                    </Icon>
                  </td>
                </tr>
                <tr className='first-row'>
                  <td>
                    <First className='first loading-animation'></First>
                  </td>
                  <td>
                    <Second>
                      <First className='top loading-animation' />
                      <First className='bottom loading-animation' />
                    </Second>
                  </td>
                  <td>
                    <Third>
                      <First className='top loading-animation' />
                      <First className='bottom loading-animation' />
                    </Third>
                  </td>
                  <td>
                    <Forth>
                      <First className='top loading-animation' />
                    </Forth>
                  </td>
                  <td>
                    <Icon>
                      <SVGIcon name='more-icon' width='18' height='18' viewBox='0 0 18 18' className='more-icon' />
                    </Icon>
                  </td>
                </tr>
                <tr className='first-row'>
                  <td>
                    <First className='first loading-animation'></First>
                  </td>
                  <td>
                    <Second>
                      <First className='top loading-animation' />
                      <First className='bottom loading-animation' />
                    </Second>
                  </td>
                  <td>
                    <Third>
                      <First className='top loading-animation' />
                      <First className='bottom loading-animation' />
                    </Third>
                  </td>
                  <td>
                    <Forth>
                      <First className='top loading-animation' />
                    </Forth>
                  </td>
                  <td>
                    <Icon>
                      <SVGIcon name='more-icon' width='18' height='18' viewBox='0 0 18 18' className='more-icon' />
                    </Icon>
                  </td>
                </tr>
                <tr className='first-row'>
                  <td>
                    <First className='first loading-animation'></First>
                  </td>
                  <td>
                    <Second>
                      <First className='top loading-animation' />
                      <First className='bottom loading-animation' />
                    </Second>
                  </td>
                  <td>
                    <Third>
                      <First className='top loading-animation' />
                      <First className='bottom loading-animation' />
                    </Third>
                  </td>
                  <td>
                    <Forth>
                      <First className='top loading-animation' />
                    </Forth>
                  </td>
                  <td>
                    <Icon>
                      <SVGIcon name='more-icon' width='18' height='18' viewBox='0 0 18 18' className='more-icon' />
                    </Icon>
                  </td>
                </tr>
                <tr className='first-row'>
                  <td>
                    <First className='first loading-animation'></First>
                  </td>
                  <td>
                    <Second>
                      <First className='top loading-animation' />
                      <First className='bottom loading-animation' />
                    </Second>
                  </td>
                  <td>
                    <Third>
                      <First className='top loading-animation' />
                      <First className='bottom loading-animation' />
                    </Third>
                  </td>
                  <td>
                    <Forth>
                      <First className='top loading-animation' />
                    </Forth>
                  </td>
                  <td>
                    <Icon>
                      <SVGIcon name='more-icon' width='18' height='18' viewBox='0 0 18 18' className='more-icon' />
                    </Icon>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Invoice>
        </Leftdiv>
        <Rightdiv>
          <Topright>
            <Heading>
              <Title>Member</Title>
              <Link>+ Add</Link>
            </Heading>
            <Box>
              <Firstmember>
                <Topmember>
                  <Lefttop className='loading-animation'></Lefttop>
                  <Righttop>
                    <Lefttop className='right loading-animation'></Lefttop>
                    <Icon>
                      <SVGIcon name='more-icon' width='18' height='18' viewBox='0 0 18 18' className='more-icon' />
                    </Icon>
                  </Righttop>
                </Topmember>
                <Bottomember className='loading-animation'></Bottomember>
              </Firstmember>
              <Firstmember>
                <Topmember>
                  <Lefttop className='loading-animation'></Lefttop>
                  <Righttop>
                    <Icon>
                      <SVGIcon name='more-icon' width='18' height='18' viewBox='0 0 18 18' className='more-icon' />
                    </Icon>
                  </Righttop>
                </Topmember>
                <Bottomember className='loading-animation'></Bottomember>
              </Firstmember>
              <Firstmember>
                <Topmember>
                  <Lefttop className='loading-animation'></Lefttop>
                  <Righttop>
                    <Icon>
                      <SVGIcon name='more-icon' width='18' height='18' viewBox='0 0 18 18' className='more-icon' />
                    </Icon>
                  </Righttop>
                </Topmember>
                <Bottomember className='loading-animation'></Bottomember>
              </Firstmember>
            </Box>
          </Topright>
          <Topright>
            <Title>Additional Info</Title>
            <Box className='secondbox'>
              <Firstinfo>
                <Topinfo>
                  <Leftinfo className='loading-animation' />
                  <Rightinfo className='loading-animation' />
                </Topinfo>
                <Bottominfo className='loading-animation' />
              </Firstinfo>
              <Firstinfo>
                <Topinfo>
                  <Leftinfo className='loading-animation' />
                </Topinfo>
                <Bottominfo className='loading-animation' />
              </Firstinfo>
              <Firstinfo>
                <Topinfo>
                  <Leftinfo className='loading-animation' />
                </Topinfo>
                <Bottominfo className='loading-animation' />
              </Firstinfo>
              <Firstinfo>
                <Topinfo>
                  <Leftinfo className='loading-animation' />
                </Topinfo>
                <Bottominfo className='loading-animation' />
              </Firstinfo>
            </Box>
          </Topright>
        </Rightdiv>
      </Maindiv>
    </div>
  );
}
