/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Content,
  FormContent,
  Formblock,
  Group,
  Icon,
  Inputvalue,
  Item,
  Label,
  Linkcontent,
  Linkicon,
  ProfileView,
  Subblock,
  Text,
  Title
} from './styles';
import Button from '../../../component/button';
import { useDispatch, useSelector } from 'react-redux';
import { getInvitationDetails, signUp } from '../../../services/authServices';
import { useHistory, useParams } from 'react-router-dom';
import { updateInviteInput } from '../../../actions/authActions';
import { APP_INIT_RESPONSE_TYPE } from '../../../global/constants';
import { SignUpPayloadInterface } from '../../../interfaces/AuthInterface';
import AppLogo from '../../../component/appLogo';
import { getCookie, isEmpty } from '../../../helpers/common';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { captureException } from '../../../services/logService';
import AppLoading from '../../../component/loading/appLoading';

const Signupjoin: React.FC = () => {
  // States
  const [referCodeValue, setReferCodeValue] = useState('');
  const [initLoading, setInitLoading] = useState(false);
  const [linkExpire, setLinkExpire] = useState(false);

  // use selector state variables
  const authSelector = useSelector((state: RootReducerInterface) => state.auth);
  const { inviteInput, loading } = authSelector;
  // Other variables
  const dispatch = useDispatch();
  const params: { id: string } = useParams();
  const history = useHistory();

  // load invitation details
  const loadInvitationDetails = useCallback(async () => {
    try {
      setInitLoading(true);
      const response = await dispatch(getInvitationDetails(params?.id));
      if (!response) {
        setLinkExpire(true);
      }
      const cookieValue = getCookie('cross_domain_referral');
      if (cookieValue) setReferCodeValue(cookieValue);
    } catch (e) {
      captureException(e);
    } finally {
      setInitLoading(false);
    }
  }, [params?.id]);

  useEffect(() => {
    if (!inviteInput?.email) {
      loadInvitationDetails();
    }
  }, []);

  // handle input field change
  const onChangeInputField = useCallback((propsName: string, value: string) => {
    dispatch(updateInviteInput({ propsName, value }));
  }, []);

  // for join button click
  const onClickJoin = useCallback(
    async (e: React.SyntheticEvent) => {
      e.preventDefault();
      let payload: SignUpPayloadInterface = {
        name: inviteInput?.name,
        email: inviteInput?.email,
        password: inviteInput?.password,
        confirmPassword: inviteInput?.password
      };
      if (!isEmpty(referCodeValue)) payload.referCode = referCodeValue;
      const result = await dispatch(signUp(payload));
      if (result && result.type === APP_INIT_RESPONSE_TYPE.REDIRECT) {
        history.push(result.path);
      } else if (result && result.type === APP_INIT_RESPONSE_TYPE.SUCCESS) {
        history.push('/');
      }
    },
    [inviteInput]
  );

  return (
    <>
      <Group>
        {initLoading && <AppLoading />}
        {!initLoading && (
          <Content onSubmit={onClickJoin}>
            <Icon>
              <AppLogo height='60' />
            </Icon>
            {linkExpire ? (
              <Box>
                <Linkicon>
                  <SVGIcon name='invitation-link-icon' width='36' height='36' viewBox='0 0 36 36' />
                </Linkicon>
                <Linkcontent>
                  <h2>Oops! Your invitation link expired.</h2>
                  <p>Contact the admin and send them a message for new invitation link.</p>
                </Linkcontent>
                <Button onClick={() => history.push('/')} title='Go to Teamcamp' />
              </Box>
            ) : (
              <>
                <Title>
                  {inviteInput?.invitedBy} Invite you to join {inviteInput?.companyName}
                </Title>
                <FormContent>
                  <Formblock>
                    <ProfileView>
                      <Text>First, tell us a bit about your company.</Text>
                    </ProfileView>

                    <Subblock>
                      <Item>
                        <Label>Name</Label>
                        <Inputvalue
                          typeof='text'
                          placeholder='Name'
                          value={inviteInput?.name}
                          onChange={(e) => onChangeInputField('name', e.target.value)}
                        />
                      </Item>
                      <Item>
                        <Label>Email</Label>
                        <Inputvalue
                          typeof='text'
                          disabled={true}
                          placeholder='Enter your Email'
                          value={inviteInput?.email}
                          readOnly={true}
                        />
                      </Item>

                      <Item>
                        <Label>Password</Label>
                        <Inputvalue
                          type='password'
                          placeholder='Enter your Password'
                          className='password'
                          size={10}
                          value={inviteInput?.password}
                          onChange={(e) => onChangeInputField('password', e.target.value)}
                        />
                      </Item>
                    </Subblock>
                  </Formblock>
                  <div className='joinbutton'>
                    <Button title={`Join ${inviteInput?.companyName}`} disabled={loading} isLoading={loading} />
                  </div>
                </FormContent>
              </>
            )}
          </Content>
        )}
      </Group>
    </>
  );
};
export default Signupjoin;
