import styled from 'styled-components';

const Tabledata = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 15px 16px;
  border-bottom: 0.5px solid var(--border-primary);
  :last-child {
    border: none;
  }
`;

const Userdetails = styled.div`
  max-width: 762px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Username = styled.div`
  width: 100px;
  height: 14px;
  background-color: var(--background-muted);
  border-radius: 7px;
`;

const Userdescription = styled.div`
  width: 250px;
  height: 10px;
  background-color: var(--background-muted);
  border-radius: 7px;
  @media (max-width: 449px) {
    width: 160px;
  }
  @media (max-width: 320px) {
    width: 120px;
  }
`;

const Usertime = styled.div`
  width: 38px;
  height: 15px;
  background-color: var(--background-muted);
  border-radius: 7px;
`;

const Stopicon = styled.div`
  width: 32px;
  height: 32px;
`;

const Privatedata = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  .loading-icon {
    path {
      fill: var(--background-muted);
    }
    path.fill1 {
      fill: var(--background-primary);
    }
  }
`;
const Maincontent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  .loading-animation {
    overflow: hidden;
  }
  .loading-animation::before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    animation: loading 1s infinite;
    background: linear-gradient(to right, transparent, var(--background-tertiary), transparent);
  }

  @keyframes loading {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  @media (max-width: 449px) {
    margin-top: 72px;
  }
`;
const Circle = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: var(--background-muted);
`;
const Div = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const Line = styled.div`
  border-right: 0.5px solid var(--border-primary);
  width: 1px;
  height: 28px;
`;
const Header = styled.div`
  max-width: 1022px;
  width: 100%;
  border: 0.5px solid var(--border-primary);
  border-radius: 12px;
  background: var(--background-primary);
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Bottomdiv = styled.div`
  border: 0.5px solid var(--border-primary);
  border-radius: 12px;
  background: var(--background-primary);
`;
const SvgDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    fill: none;
  }
`;
const Leftblock = styled.div`
  padding: 0 3px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  margin-top: -1px;
  cursor: pointer;
  @media (max-width: 449px) {
    border-right: 0;
  }
`;
const Rightblock = styled.div`
  padding: 0px 3px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  cursor: pointer;
`;
const Middleline = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-left: 0.5px solid var(--border-primary);
  border-right: 0.5px solid var(--border-primary);
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  .box {
    max-width: 138px;
    width: 100%;
    border-right: 0.5px solid var(--border-primary);
    :last-child {
      border-right: none;
    }
    @media (max-width: 449px) {
      max-width: 150px;
    }
  }
  .last-box {
    background: var(--background-secondary);
    opacity: 0.8;
  }
`;
const Siderbox = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const Datetitle = styled.div`
  width: 70px;
  height: 12px;
  border-radius: 15px;
  background-color: var(--background-muted);
`;

const Timetitle = styled.div`
  width: 40px;
  height: 8px;
  border-radius: 15px;
  background-color: var(--background-muted);
`;

export {
  Tabledata,
  Userdetails,
  Username,
  Userdescription,
  Usertime,
  Stopicon,
  Privatedata,
  Maincontent,
  Circle,
  Div,
  Line,
  Header,
  Bottomdiv,
  SvgDiv,
  Leftblock,
  Rightblock,
  Middleline,
  Siderbox,
  Datetitle,
  Timetitle
};
