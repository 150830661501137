import styled, { css } from 'styled-components';
import { Typography } from '../../global/theme';

interface Props {
  isActive?: boolean;
  isborderredius?: boolean;
}
const Tableheaders = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: var(--background-primary);
  border-top: 0.5px solid var(--border-primary);
  border-radius: 8px 8px 0 0;
  border-left: 0.5px solid var(--border-primary);
  border-right: 0.5px solid var(--border-primary);
  @media (max-width: 449px) {
    padding: 16px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
`;
const Left = styled.div`
  display: flex;
  @media (max-width: 449px) {
    display: flex;
    width: 100%;
    overflow: scroll;
    white-space: nowrap;
    scrollbar-width: none;
  }
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  @media (max-width: 449px) {
    width: 100%;
    justify-content: flex-end;
  }
`;
const Icon = styled.div`
  padding-right: 12px;
  width: 18px;
  height: 18px;
  border-right: 0.5px solid var(--border-secondary);
  cursor: pointer;
  .svg-icon {
    path {
      stroke: var(--text-primary);
    }
  }
`;

const Titlelast = styled.div`
  color: var(--brand-primary);
  ${Typography.body_md_medium}
  padding: 0 12px;
  text-decoration: none;
  height: 18px;
  @media (max-width: 449px) {
    padding: 0 0 0 12px;
  }
`;
const Allbutton = styled.button<Props>`
  ${Typography.body_md_medium}
  background-color: var(--background-primary);
  color: var(--text-secondary);
  padding: 6px 15px;
  border: 0.5px solid transparent;
  cursor: pointer;
  ${(props: Props) =>
    props.isActive &&
    css`
      ${Typography.body_md_medium}
      background-color: var(--background-primary);
      border: 0.5px solid var(--border-primary);
      box-shadow: var(--shadow-card);
      border-radius: 16px;
      padding: 6px 15px;
      color: var(--text-primary);
    `}
  @media(max-width: 449px) {
    width: 100%;
  }
`;
const Dropbox = styled.div`
  position: absolute;
  width: 100%;
  max-width: fit-content;
  background: var(--background-primary);
  border-radius: 8px;
  border: 0.5px solid var(--border-primary);
  padding: 0;
  margin-top: 5px;
  z-index: 11;
`;
const Bottomdata = styled.div<Props>`
  height: 100%;
  max-height: 196px;
  min-width: 120px;
  overflow: auto;
  padding: 4px;
  display: flex;
  flex-direction: column;
  gap: 1px;
  :last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  ${(props: Props) =>
    props.isborderredius &&
    css`
      border-radius: 8px;
    `}
`;

const Createitem = styled.div`
  display: flex;
  gap: 10px;
  padding: 4px 6px;
  :hover {
    background: var(--neutral-secondary);
    cursor: pointer;
    border-radius: 4px;
  }
  p {
    ${Typography.body_md}
    color: var(--text-primary);
    margin: 0;
  }
`;
export { Tableheaders, Left, Right, Icon, Allbutton, Dropbox, Bottomdata, Createitem, Titlelast };
