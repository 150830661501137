import { ActionCreatorWithOptionalPayload, ActionCreatorWithPayload, createAction, Dispatch } from '@reduxjs/toolkit';
import * as Actions from './types';
import { ChatGroupInterface } from '../interfaces/chatMessageInterface';

/**
 * @desc Set Chat Message loader
 */
export const setChatMessageLoader: ActionCreatorWithPayload<boolean, string> = createAction(
  Actions.SET_CHAT_MESSAGE_LOADER
);

/**
 * @desc Set Chat Message Groups
 */
export const setChatGroups: ActionCreatorWithPayload<ChatGroupInterface[], string> = createAction(
  Actions.SET_CHAT_GROUPS
);

/**
 * @desc Set Chat Message Groups
 */
export const setPinChatGroups: ActionCreatorWithPayload<ChatGroupInterface[], string> = createAction(
  Actions.SET_PIN_CHAT_GROUPS
);

/**
 * @desc Set Chat Message Groups
 */
export const setUnpinChatGroups: ActionCreatorWithPayload<ChatGroupInterface[], string> = createAction(
  Actions.SET_UNPIN_CHAT_GROUPS
);

/**
 * @desc Set Current Chat Group
 */
export const setCurrentChatGroup: ActionCreatorWithPayload<ChatGroupInterface | {}, string> = createAction(
  Actions.SET_CURRENT_CHAT_GROUP
);

/**
 * @desc Set Current Chat Group name
 */
export const setCurrentChatGroupName: ActionCreatorWithPayload<ChatGroupInterface, string> = createAction(
  Actions.SET_CURRENT_CHAT_GROUP_NAME
);

/**
 * @desc update Current Chat Group name
 */
export const updateCurrentChatGroupName: ActionCreatorWithPayload<{ propsName: string; value: string }, string> =
  createAction(Actions.UPDATE_CURRENT_CHAT_GROUP_NAME);

/**
 * @desc clear Current Chat Group name
 */
export const clearCurrentChatGroupName: ActionCreatorWithOptionalPayload<undefined, string> = createAction(
  Actions.CLEAR_CURRENT_CHAT_GROUP_NAME
);

/**
 * @desc Clear Chat Messages Data
 */
export const clearChatMessageData: any = () => (dispatch: Dispatch) => {
  dispatch(setChatMessageLoader(false));
  dispatch(setChatGroups([]));
  dispatch(setCurrentChatGroup({}));
  dispatch(clearCurrentChatGroupName());
  dispatch(setPinChatGroups([]));
  dispatch(setUnpinChatGroups([]));
};
