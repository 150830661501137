import axios from 'axios';
import { AnyAction, Dispatch } from 'redux';
import { clearResponseMessage, setErrorMessage, setSuccessMessage } from '../actions/messageActions';
import {
  setMemberTaskData,
  setMyTaskData,
  setMyTaskDetailsData,
  setOverDueTasks,
  setProjectTaskGroupsList,
  setSubTaskList,
  setTaskLoader,
  setTimelogData,
  setTaskGroupsList,
  setTasksList
} from '../actions/taskActions';
import {
  checkIsOverdue,
  convertBase64ToFile,
  fetchAndConvertToBase64,
  getAPIErrorReason,
  getFirebaseUrlFromFile,
  getUserObject,
  groupByProject,
  isEmpty
} from '../helpers/common';
import UserPreferenceSingleton from '../helpers/userPreferenceSingleton';
import { COMMENT_TYPE, MY_TASKS_GROUP_FILTER_TYPE, TASK_PRIORITY, TASK_PRIORITY_LABELS } from '../global/constants';
import {
  calculateTaskProgress,
  getCurrentProjectDetails,
  getFavoriteSortedList,
  getProjectList,
  updateLocalProjectList
} from './projectServices';
import TasksCacheService from './tasksCatchServices';
import moment from 'moment';
import { setFilteredReportTaskDetails, setReportTaskDetails } from '../actions/reportActions';
import { REACT_APP_TEAMCAMP_APIURL } from '../global/environment';
import {
  CommentFilesInterface,
  EmojiInterface,
  MyTaskDetailsInterface,
  TaskDetailsInterface,
  TaskGroupList,
  CreateTaskInputInterface,
  taskLinkInterface
} from '../interfaces/TaskInterface';
import { LabelDetailsInterface, ProjectDetailInterface } from '../interfaces/ProjectInterface';
import { setFavouriteProjectList, setProjectList } from '../actions/projectActions';
import { captureException } from './logService';
import { getUserPreferenceFieldData, updateUserPreference } from '../helpers/firebaseHelper';
import { RootReducerInterface } from '../interfaces/RootReducerInterface';
import { UsersWorkspaceInterface } from '../interfaces/WorkspaceInterface';
import { nanoid } from 'nanoid';
import { ReplyMessageDataInterface } from '../interfaces/MessageInterface';
import { setWorkspaceTotalTask } from '../actions/workspaceActions';

/** Helper function to ensure filter data is set */
export const setFilterData = async (filterData: any) => {
  if (!filterData || filterData.group_by === undefined) {
    const defaultFilter = { group_by: MY_TASKS_GROUP_FILTER_TYPE.DUE_DATE };
    await updateUserPreference({ my_task_filter: defaultFilter });
    return defaultFilter;
  }
  return filterData;
};

/** Extract project IDs from the project list */
export const getProjectIds = (projectList: ProjectDetailInterface[]) => {
  return projectList?.map((project) => project?.id) || [];
};

/** Enrich tasks with project details */
export const getTaskWithStatusDetails = (tasks: MyTaskDetailsInterface[], projectList: ProjectDetailInterface[]) => {
  const projectMap = new Map(projectList.map((project) => [project.id, project]));

  return tasks.map((task) => {
    const projectDetails = projectMap.get(task.projectId);
    if (projectDetails?.statusEnable && projectDetails.statusData?.length) {
      const statusDetails =
        projectDetails.statusData.find((x) => x['_id'] === task.statusId) || projectDetails.defaultStatus;
      return { ...task, statusDetails, statusList: projectDetails.statusData };
    }
    return task;
  });
};

/** Fetch group name for a task */
const fetchGroupName = async (groupId: string) => {
  if (!groupId) return '';
  try {
    const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/taskGroup/getTaskGroup/${groupId}`);
    return response?.data?.Name || '';
  } catch (error) {
    console.error('Error fetching group name:', error);
    return '';
  }
};

/** Group tasks by group name */
const groupTasksByGroupName = async (tasks: MyTaskDetailsInterface[]) => {
  const groupedTasks = await Promise.all(
    tasks.map(async (task) => {
      try {
        const groupName = (await fetchGroupName(task.groupId)) || 'Other';
        return { ...task, groupName };
      } catch (e) {
        console.error('Error fetching group:', e);
        return { ...task, groupName: 'Other' };
      }
    })
  );

  const groupedByGroupName = groupedTasks.reduce<{ title: string; tasks: TaskDetailsInterface[] }[]>((acc, task) => {
    const groupName = task.groupName || 'Other';
    const existingGroup = acc.find((group) => group.title === groupName);
    if (existingGroup) {
      existingGroup.tasks.push(task);
    } else {
      acc.push({ title: groupName, tasks: [task] });
    }
    return acc;
  }, []);
  return groupedByGroupName;
};

/** Group tasks by priority */
const groupTasksByPriority = (tasks: TaskDetailsInterface[]): { title: string; tasks: TaskDetailsInterface[] }[] => {
  const priorityGroups = [
    TASK_PRIORITY.URGENT,
    TASK_PRIORITY.HIGH,
    TASK_PRIORITY.MEDIUM,
    TASK_PRIORITY.LOW,
    TASK_PRIORITY.NO_PRIORITY
  ];

  return priorityGroups.reduce<{ title: string; tasks: TaskDetailsInterface[] }[]>((acc, priority) => {
    const filteredTasks = tasks.filter((task) => task.priority === priority);
    if (filteredTasks.length > 0) {
      acc.push({ title: TASK_PRIORITY_LABELS[priority], tasks: filteredTasks });
    }
    return acc;
  }, []);
};

/** Group tasks by due date */
const groupTasksByDate = (tasks: TaskDetailsInterface[]) => {
  const todayStart = moment().startOf('day');
  const todayEnd = moment().endOf('day');

  const grouped = tasks.reduce(
    (acc, task) => {
      if (!task.dueDate) {
        acc.Other.push(task);
      } else {
        const taskDueDate = moment(task.dueDate);
        if (taskDueDate.isBefore(todayStart)) {
          acc.Delayed.push(task);
        } else if (taskDueDate.isBetween(todayStart, todayEnd, null, '[]')) {
          acc.Today.push(task);
        } else {
          acc.Upcoming.push(task);
        }
      }
      return acc;
    },
    { Delayed: [], Today: [], Upcoming: [], Other: [] } as Record<string, TaskDetailsInterface[]>
  );

  return Object.entries(grouped)
    .filter(([, tasks]) => tasks.length > 0)
    .map(([title, tasks]) => ({ title, tasks }));
};

/** Group tasks based on the filter type */
export const groupTasksByFilter = async (tasks: MyTaskDetailsInterface[], filterType: number) => {
  switch (filterType) {
    case MY_TASKS_GROUP_FILTER_TYPE.GROUP:
      return await groupTasksByGroupName(tasks);
    case MY_TASKS_GROUP_FILTER_TYPE.PROJECT:
      return groupByProject(tasks);
    case MY_TASKS_GROUP_FILTER_TYPE.PRIORITY:
      return groupTasksByPriority(tasks);
    case MY_TASKS_GROUP_FILTER_TYPE.NONE:
      return [{ title: '', tasks }];
    default:
      return groupTasksByDate(tasks);
  }
};

/**
 * @desc mytasks - Get my task data
 * @param {*}
 */
export const loadMyTaskData: any =
  (isAppInit: boolean) => async (dispatch: Dispatch<AnyAction>, getState: () => RootReducerInterface) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setTaskLoader(true));
      const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();

      //Get current filter type from firebase
      let filterData = await getUserPreferenceFieldData('my_task_filter');
      filterData = await setFilterData(filterData);
      const my_task_filter = filterData?.group_by;

      //fetch all projects tasks
      if (isAppInit) {
        await dispatch(getAllProjectTasks(true));
      }
      const state = getState();
      const projectIds = getProjectIds(state.project.list);
      // Fetch tasks from local cache first
      const myTasksFromLocal: MyTaskDetailsInterface[] = await TasksCacheService.getInstance()?.getTasksByUser(
        userDetails?.id,
        projectIds
      );
      const myTasks = getTaskWithStatusDetails(myTasksFromLocal, state.project.list);

      // Group and sort tasks
      const groupedData = await groupTasksByFilter(myTasks, my_task_filter);
      groupedData?.forEach((group) => {
        group.tasks.sort((a, b) => new Date(a?.dueDate).getTime() - new Date(b?.dueDate).getTime());
      });

      dispatch(setMyTaskData(groupedData || []));
      if (groupedData) {
        const delayedTasks = myTasks.filter(
          (task) => task?.dueDate && moment(task.dueDate).startOf('day').isBefore(moment().startOf('day'))
        );
        dispatch(setOverDueTasks(delayedTasks.length || 0));
      }

      return groupedData;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to load user task list please try again';
      captureException(error);
      dispatchTaskError(error, dispatch);
      return undefined;
    } finally {
      dispatch(setTaskLoader(false));
    }
  };
/**
 * @desc Update task data
 * @param {*}
 */
export const updateTaskDetails: any = (taskId: string, payload: any) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setTaskLoader(true));
    if (!taskId) return false;
    const response = await axios.put(`${REACT_APP_TEAMCAMP_APIURL}/Tasks/updateTask/${taskId}`, payload);
    if (response) {
      return true;
    }
    return false;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to update task list please try again';
    captureException(error);
    dispatchTaskError(error, dispatch);
    return false;
  } finally {
    dispatch(setTaskLoader(false));
  }
};

/**
 * @desc Get task details data
 * @param {*}
 */
export const getMyTaskDetailsData: any =
  (taskId: string) => async (dispatch: Dispatch<AnyAction>, getState: () => RootReducerInterface) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setTaskLoader(true));

      const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/Tasks/getTaskDetails/${taskId}`);
      const { data } = response;

      if (!data) return false;

      const state = getState();
      const { workspace } = state.workspace;

      const createdBy = workspace?.users.find((ele) => ele?.id === data?.CreatedBy) || getUserObject(data?.CreatedBy);

      const transformComments = (comments: any[]) =>
        comments?.map((element: any) => {
          const userData = workspace?.users.find((ele) => ele?.id === element?.CreatedBy) as UsersWorkspaceInterface;
          const assigneeDetails =
            element?.Type === COMMENT_TYPE.ADD_ASSIGNEE || element?.Type === COMMENT_TYPE.REMOVE_ASSIGNEE
              ? workspace?.users.find((ele) => ele?.id === element?.Assignee)
              : null;

          return {
            comment: element?.Comment,
            createdOn: element?.CreatedTime,
            id: element['_id'],
            user: userData,
            updatedBy: element?.UpdatedBy,
            updatedTime: element?.UpdatedTime,
            type: element?.Type || 0,
            dueDate: element?.DueDate,
            previousDueDate: element?.PreviousDueDate,
            assignee: assigneeDetails || undefined,
            previousPriority: element?.PreviousPriority,
            priority: element?.Priority,
            isArchived: element?.IsArchived,
            previousGroup: element?.PreviousGroup,
            group: element?.Group,
            status: element?.Status,
            estimateTime: element?.EstimateTime,
            Reactions: element?.Reactions,
            Files: element?.Files,
            reply: element?.Reply,
            isGithub: element?.IsGithub
          };
        });

      const comments = transformComments(data?.Comments);

      const project: ProjectDetailInterface = await dispatch(getCurrentProjectDetails(workspace?.id, data?.ProjectId));

      const updatedLabels = data?.Labels?.map((x: string) => {
        const labelDetails = project?.labelsList?.find((y) => y?.id === x);
        return labelDetails || {};
      })?.filter((x: LabelDetailsInterface) => x?.id);

      const users = data?.TaskUsers?.map((user: { _id: string }) => {
        const userData = workspace?.users?.find((ele: { id: string }) => ele?.id === user['_id']);
        return userData;
      }).filter((userData: UsersWorkspaceInterface) => userData !== undefined && userData !== null);

      const subscribers = data?.Subscribers?.map((subs: any) => {
        const subscriberData = workspace?.users.find((item) => item?.id === subs);
        return subscriberData ? subscriberData : subs;
      });

      const taskDetails = {
        updatedComments: comments,
        createdBy,
        createdOn: data?.CreatedTime,
        description: data?.Desc,
        dueDate: data?.DueDate || null,
        groupId: data?.GroupId,
        id: data['_id'],
        index: data?.Index,
        isOverdue: checkIsOverdue(data?.DueDate) ?? false,
        name: data?.Name,
        projectId: data?.ProjectId,
        status: data?.Status,
        priority:
          data?.Priority !== undefined && data?.Priority !== null
            ? data?.Priority
            : project?.priority?.default || TASK_PRIORITY.NO_PRIORITY,
        users,
        subscribers,
        taskSubscribers: data?.Subscribers,
        estimate: data?.EstimateTime,
        Files: data?.Files,
        milestoneId: data?.MilestoneId,
        labels: updatedLabels,
        parentTaskId: data?.ParentTaskId,
        statusId: data?.StatusId,
        isArchived: data?.isArchived || false,
        subtaskIndex: data?.SubtaskIndex,
        taskNumber: data?.TaskNumber,
        pullRequests: data?.PullRequests,
        links: data?.Links
      };

      dispatch(setMyTaskDetailsData(taskDetails));
      return taskDetails;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to get my task details please try again';
      captureException(error);
      dispatchTaskError(error, dispatch);
      return false;
    } finally {
      dispatch(setTaskLoader(false));
    }
  };

/**
 * @desc create new comment
 * @param {*}
 */
export const createNewComment: any =
  (
    taskId: string,
    payload: {
      Comment: string;
      Files: CommentFilesInterface[];
      Type: number;
      Reply?: ReplyMessageDataInterface;
    }
  ) =>
  async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setTaskLoader(true));
      const response = await axios.put(`${REACT_APP_TEAMCAMP_APIURL}/Tasks/addComment/${taskId}`, payload);
      const { data } = response;
      if (data) {
        return true;
      }
      return false;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to create new comment please try again';
      captureException(error);
      dispatchTaskError(error, dispatch);
      return false;
    } finally {
      dispatch(setTaskLoader(false));
    }
  };

/**
 * @desc Update task comment
 * @param {*} taskId commentId payload
 */
export const updateTaskComment: any =
  (
    taskId: string,
    commentId: string,
    payload: {
      Comment?: string;
      IsArchived?: boolean;
      Files?: CommentFilesInterface[];
    }
  ) =>
  async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setTaskLoader(true));
      const response = await axios.put(`${REACT_APP_TEAMCAMP_APIURL}/Tasks/${taskId}/comment/${commentId}`, payload);
      const { data } = response;
      if (data) {
        return true;
      }
      return false;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to update comment please try again';
      captureException(error);
      dispatchTaskError(getAPIErrorReason(e) || 'Unable to update comment please try again', dispatch);
      return false;
    } finally {
      dispatch(setTaskLoader(false));
    }
  };

/**
 * @desc get timelog data
 * @param {*}
 */
export const getTimelogData: any = (projectId: string, taskId: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setTaskLoader(true));
    const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
    const params = {
      userId: userDetails?.id,
      projectId: projectId,
      taskId: taskId
    };
    const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/timeLog/getTimeLogByTask`, {
      params
    });
    const { data } = response;
    if (data) {
      dispatch(setTimelogData(data));
      return data;
    }
    return false;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'unable to get timelog data for task please try again';
    captureException(error);
    dispatchTaskError(error, dispatch);
    return false;
  } finally {
    dispatch(setTaskLoader(false));
  }
};

/**
 * @desc Project - get project task
 * @param {*}
 */
export const getTaskGroupList: any =
  (project_id: string, isSelect?: boolean, isForceLoading = true) =>
  async (dispatch: Dispatch<AnyAction>, getState: () => RootReducerInterface) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setTaskLoader(true));
      const state = getState();
      if (!isForceLoading) {
        const { taskGroups } = state.task;
        if (taskGroups?.length > 0) return taskGroups;
      }
      const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/taskGroup/getTaskGroupList/${project_id}`);
      const { data } = response;
      data.sort((a: { Index: number }, b: { Index: number }) => {
        return a.Index - b.Index;
      });
      if (data.length > 0) {
        let newData = data.map((element: TaskGroupList) => {
          return {
            id: element['_id'],
            index: element?.Index,
            name: element?.Name,
            projectId: element?.ProjectId,
            isCollapse: false
          };
        });
        if (isSelect) {
          dispatch(setProjectTaskGroupsList(newData));
        } else {
          dispatch(setProjectTaskGroupsList(newData));
          dispatch(setTaskGroupsList(newData));
        }
        return newData;
      }
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to get task group list please try again';
      captureException(error);
      dispatchTaskError(error, dispatch);
      return false;
    } finally {
      dispatch(setTaskLoader(false));
    }
  };

/**
 * @desc Project - get project task
 * @param {*}
 */
export const getTasksList: any =
  (project_id: string, isForceLoading = true, isSubTaskLoading = false) =>
  async (dispatch: Dispatch<AnyAction>, getState: () => RootReducerInterface) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setTaskLoader(true));
      const state = getState();
      const { workspace } = state.workspace;
      if (!isForceLoading) {
        const { tasksList } = state.task;
        if (tasksList?.length > 0) return tasksList;
      }
      const localProjectList = await TasksCacheService.getInstance()?.getLastProjectList(workspace?.id);
      const tasksLastUpdatedTime = localProjectList[project_id]
        ? localProjectList[project_id]
        : '2000-06-01T09:34:51.737Z';
      await dispatch(getAllProjectWiseTasks([project_id], tasksLastUpdatedTime));
      const local_project_tasks = await TasksCacheService.getInstance()?.getTasksByProject(
        project_id,
        isSubTaskLoading
      );
      local_project_tasks?.forEach((task: { parentTaskId: string | null; parentTaskName: string }) => {
        if (!isEmpty(task?.parentTaskId)) {
          const parentTask = local_project_tasks?.find((x: { id: string }) => x?.id === task?.parentTaskId);
          if (parentTask) {
            task.parentTaskName = parentTask?.name || '';
          } else {
            task.parentTaskId = null;
          }
        }
      });
      dispatch(setTasksList(local_project_tasks));
      return local_project_tasks;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to get tasks list from local please try again';
      captureException(error);
      dispatchTaskError(error, dispatch);
      return false;
    } finally {
      dispatch(setTaskLoader(false));
    }
  };

/**
 * @desc Project - get project task
 * @param {*}
 */
export const loadProjectTasks: any =
  (project_id: string) => async (dispatch: Dispatch<AnyAction>, getState: () => RootReducerInterface) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setTaskLoader(true));
      const state = getState();
      const { workspace } = state.workspace;
      const localProjectList = await TasksCacheService.getInstance()?.getLastProjectList(workspace?.id);
      const tasksLastUpdatedTime = localProjectList[project_id]
        ? localProjectList[project_id]
        : '2000-06-01T09:34:51.737Z';
      await dispatch(getAllProjectWiseTasks([project_id], tasksLastUpdatedTime));
      return true;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to get project tasks list please try again';
      captureException(error);
      dispatchTaskError(error, dispatch);
      return false;
    } finally {
      dispatch(setTaskLoader(false));
    }
  };

/**
 * @desc Project - deleteTaskGroup
 * @param {*}
 */
export const deleteTaskGroup: any = (taskGroupId: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setTaskLoader(true));
    const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
    const params = {
      id: taskGroupId,
      userId: userDetails?.id
    };
    const response = await axios.delete(`${REACT_APP_TEAMCAMP_APIURL}/taskGroup/deleteTaskGroup`, { params });
    if (response) {
      dispatchTaskSuccess(response.data.message, dispatch);
      return true;
    }
    return false;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to delete task group please try again';
    captureException(error);
    dispatchTaskError(error, dispatch);
    return false;
  } finally {
    dispatch(setTaskLoader(false));
  }
};

/**
 * @desc Project - tasks - create new task
 * @param {*}
 */
export const createNewTask: any = (payload: CreateTaskInputInterface) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setTaskLoader(true));
    const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/Tasks/createTask`, payload);
    const { data } = response;
    if (data) {
      return true;
    }
    return false;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to create task please try again';
    captureException(error);
    dispatchTaskError(error, dispatch);
    return false;
  } finally {
    dispatch(setTaskLoader(false));
  }
};

/**
 *  @desc Project - update group
 * @param {*}
 */
export const updateGroupDetail: any =
  (payload: { name: string }, isIndexSetting = false) =>
  async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setTaskLoader(true));
      if (isEmpty(payload?.name) && !isIndexSetting) {
        dispatchTaskError('Please enter task group name', dispatch);
        return;
      }
      const response = await axios.put(`${REACT_APP_TEAMCAMP_APIURL}/taskGroup/putTaskGroup`, payload);
      if (response) {
        if (!isIndexSetting) dispatchTaskSuccess(response.data.message, dispatch);
        return true;
      }
      return false;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to update group please try again';
      captureException(error);
      dispatchTaskError(error, dispatch);
      return false;
    } finally {
      dispatch(setTaskLoader(false));
    }
  };

/**
 *  @desc Project - create new group
 * @param {*}
 */
export const createNewGroup: any = (payload: { name: string }) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    if (isEmpty(payload?.name)) {
      dispatchTaskError('Please enter task group name', dispatch);
      return;
    }
    dispatch(setTaskLoader(true));
    const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/taskGroup/postTaskGroup`, payload);
    if (response) {
      dispatchTaskSuccess(response.data.message, dispatch);
      return true;
    }
    return false;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to create group please try again';
    captureException(error);
    dispatchTaskError(error, dispatch);
    return false;
  } finally {
    dispatch(setTaskLoader(false));
  }
};

/**
 *  @desc Get Figma preview URL
 * @param {*}
 */
export const getFigmaPreviewUrl: any =
  (payload: any) => async (dispatch: Dispatch<AnyAction>, getState: () => RootReducerInterface) => {
    let reference = 0;
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setTaskLoader(true));
      let response: any;
      const state = getState();
      const { workspace } = state.workspace;
      const updatedPayload = { ...payload, companyId: workspace?.id };
      const fetchResultAfterDelay = async () => {
        try {
          reference = reference + 1;
          response = await axios.post(
            // eslint-disable-next-line max-len
            `${REACT_APP_TEAMCAMP_APIURL}/Tasks/getFigmaPreviewUrl`,
            updatedPayload
          );
        } catch (e) {
          if (reference <= 5) await fetchResultAfterDelay();
          else {
            dispatchTaskError('Unable to get figma preview. Refresh page and try again', dispatch);
            return undefined;
          }
        }
      };
      await fetchResultAfterDelay();
      if (response?.data) {
        const base64Data = await fetchAndConvertToBase64(response?.data?.filePreviewUrl);
        const fileData = await convertBase64ToFile(base64Data);
        const fileUrl = await getFirebaseUrlFromFile({ file: fileData }, 'figma-preview/', dispatch);
        return { ...(response?.data || {}), filePreviewUrl: fileUrl };
      }
      return undefined;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to get figma preview';
      captureException(error);
      dispatchTaskError(error, dispatch);
      return undefined;
    } finally {
      dispatch(setTaskLoader(false));
    }
  };

/**
 *  @desc get all tasks
 * @param {*}
 */
export const getAllProjectTasks: any =
  (progressCount = false) =>
  async (dispatch: Dispatch<AnyAction>, getState: () => RootReducerInterface) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setTaskLoader(true));
      const state = getState();
      const { workspace } = state.workspace;
      const adjustedTime = moment().subtract(10, 'minutes').toISOString();
      const result = await TasksCacheService.getInstance()?.getTasksByWorkspace();
      if (result && result.length > 0) {
        dispatch(setReportTaskDetails(result));
      }
      let { list } = state.project;
      if (list?.length === 0 || isEmpty(list)) {
        list = await dispatch(getProjectList());
      }
      const localProjectList = await TasksCacheService.getInstance()?.getLastProjectList(workspace?.id);

      // Favorite Projects
      const favoriteProjectIds = list?.filter((x) => x.isFavorite)?.map((y) => y?.id);
      if (favoriteProjectIds?.length > 0) {
        const projectLastTimeTasks: { [key: string]: string } = {};
        favoriteProjectIds?.forEach((projectId) => {
          projectLastTimeTasks[projectId] = localProjectList[projectId];
        });

        const groupedData = Object.entries(projectLastTimeTasks).reduce((acc: any, [key, value]) => {
          if (!acc[value]) {
            acc[value] = [];
          }
          acc[value].push(key);
          return acc;
        }, {});

        const promises: Promise<boolean>[] = [];
        Object.entries(groupedData)?.forEach(([lastUpdatedTime, projects]: [string, any]) => {
          const lastTaskUpdatedTime = lastUpdatedTime !== 'undefined' ? lastUpdatedTime : '2000-06-01T09:34:51.737Z';
          if (projects && projects.length > 10) {
            for (let i = 0; i < projects.length; i += 10) {
              const projectGroup = projects.slice(i, i + 10);
              promises.push(dispatch(getAllProjectWiseTasks(projectGroup, lastTaskUpdatedTime)));
            }
          } else {
            promises.push(dispatch(getAllProjectWiseTasks(projects, lastTaskUpdatedTime)));
          }
        });
        const response = await Promise.all(promises);
        if (response.some((item) => item === false)) return;
      }

      // Non-Favorite Projects
      const nonFavoriteProjectIds = list?.filter((x) => !x.isFavorite)?.map((y) => y?.id);
      if (nonFavoriteProjectIds?.length > 0) {
        const projectLastTimeTasks: { [key: string]: string } = {};
        nonFavoriteProjectIds?.forEach((projectId) => {
          projectLastTimeTasks[projectId] = localProjectList[projectId];
        });

        const groupedData = Object.entries(projectLastTimeTasks).reduce((acc: any, [key, value]) => {
          if (!acc[value]) {
            acc[value] = [];
          }
          acc[value].push(key);
          return acc;
        }, {});

        const promises: Promise<boolean>[] = [];
        Object.entries(groupedData)?.forEach(([lastUpdatedTime, projects]: [string, any]) => {
          const lastTaskUpdatedTime = lastUpdatedTime !== 'undefined' ? lastUpdatedTime : '2000-06-01T09:34:51.737Z';
          if (projects && projects.length > 10) {
            for (let i = 0; i < projects.length; i += 10) {
              const projectGroup = projects.slice(i, i + 10);
              promises.push(dispatch(getAllProjectWiseTasks(projectGroup, lastTaskUpdatedTime)));
            }
          } else {
            promises.push(dispatch(getAllProjectWiseTasks(projects, lastTaskUpdatedTime)));
          }
        });
        const response = await Promise.all(promises);
        if (response.some((item) => item === false)) return;
      }

      let updatedTimeProjects: {
        [key: string]: string;
      } = localProjectList || {};
      list?.forEach((project) => {
        updatedTimeProjects[project?.id] = adjustedTime;
      });
      await TasksCacheService.getInstance()?.setLastProjectList(updatedTimeProjects, workspace?.id);

      try {
        const local_updated_data = await TasksCacheService.getInstance()?.getTasksByWorkspace();
        if (local_updated_data && local_updated_data.length > 0) {
          Promise.all([
            dispatch(setReportTaskDetails(local_updated_data)),
            dispatch(setFilteredReportTaskDetails(local_updated_data))
          ]);
          if (progressCount) {
            const updatedData = await Promise.all(
              list?.map(async (x: ProjectDetailInterface) => {
                const progressData = await calculateTaskProgress(x?.id);
                return { ...x, ...(progressData || {}) };
              })
            );
            const favouriteProjects = updatedData?.filter((x: any) => x.isFavorite);
            updateLocalProjectList(updatedData, workspace?.id);
            const sortedFavouriteList = await getFavoriteSortedList(workspace?.id, favouriteProjects);
            dispatch(setFavouriteProjectList(sortedFavouriteList));
            dispatch(setProjectList(updatedData));
            return local_updated_data;
          }
        }
      } catch (error) {
        captureException(error);
        console.log('error', error);
      }
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to get all project tasks please try again';
      captureException(error);
      dispatchTaskError(error, dispatch);
      return false;
    } finally {
      dispatch(setTaskLoader(false));
    }
  };

/**
 *  @desc get all project wise tasks
 * @param {*}
 */
export const getAllProjectWiseTasks: any =
  (projects: string[], lastUpdatedTime: string) =>
  async (dispatch: Dispatch<AnyAction>, getState: () => RootReducerInterface) => {
    try {
      if (!projects || projects?.length === 0) return;
      dispatch(clearResponseMessage(''));
      dispatch(setTaskLoader(true));
      const state = getState();
      const { workspace } = state.workspace;
      const { id: workspace_id } = workspace;
      const adjustedTime = moment().subtract(10, 'minutes').toISOString();
      const localProjectList = await TasksCacheService.getInstance()?.getLastProjectList(workspace_id);

      const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/Tasks/getAllTasks`, {
        projectIds: projects,
        lastUpdatedTime
      });
      const { data } = response;
      if (data?.length > 0) {
        let { list } = state.project;
        if (list?.length === 0 || isEmpty(list)) {
          list = await dispatch(getProjectList());
        }
        let taskList: any = [];
        let promises: Promise<unknown>[] = []; // This array will hold all promises.
        // Use forEach to iterate over data and process each item asynchronously.
        data?.forEach((item: any) => {
          // We wrap the async logic inside a Promise to handle it properly
          const myPromise = new Promise((resolve, reject) => {
            try {
              const project_id = item?.ProjectId;
              const project = list?.find((item: { id: string }) => item?.id === project_id);
              // Get users associated with the task
              const users = item?.TaskUsers?.map((user: { _id: string }) => {
                const userData = workspace?.users?.find((ele: { id: string }) => ele?.id === user['_id']);
                return userData;
              }).filter((userData: UsersWorkspaceInterface) => userData !== undefined && userData !== null);
              let taskUsers = item?.TaskUsers;
              // Get labels for the task
              const updatedLabels = item?.Labels?.map((x: string) => {
                const labelDetails = project?.labelsList?.find((y) => y?.id === x);
                return labelDetails || {};
              })?.filter((x: LabelDetailsInterface) => x?.id);
              // Create the new task object
              const newTask = {
                id: item['_id'],
                comments: [],
                companyId: workspace?.id,
                createdOn: item?.CreatedTime,
                createdBy: item?.CreatedBy,
                groupId: item?.GroupId,
                index: item?.Index,
                isArchived: item?.IsArchived === true ? true : false,
                description: item?.Desc || '',
                name: item?.Name,
                projectId: item?.ProjectId,
                projectName: project?.name || '',
                status: item?.Status,
                dueDate: item?.DueDate,
                Files: !isEmpty(item?.Files) ? item?.Files : [],
                priority:
                  item?.Priority !== undefined && item?.Priority !== null
                    ? item?.Priority
                    : project?.priority?.default || TASK_PRIORITY.NO_PRIORITY,
                customerId: !isEmpty(project?.customer?.id) ? project?.customer?.id : '',
                users,
                taskUsers,
                taskSubscribers: item?.Subscribers,
                estimate: item?.EstimateTime,
                labels: updatedLabels,
                milestoneId: item?.MilestoneId,
                parentTaskId: item?.ParentTaskId,
                statusId: item?.StatusId,
                updatedTime: item?.UpdatedTime,
                updatedBy: item?.UpdatedBy,
                subtaskIndex: item?.SubtaskIndex,
                taskNumber: item?.TaskNumber
              };
              // Push the new task into the task list
              taskList.push(newTask);
              // Resolve the promise when processing for each item is complete
              resolve(newTask);
            } catch (error) {
              // Reject the promise in case of error
              reject(error);
            }
          });
          // Push each promise into the promises array
          promises.push(myPromise);
        });
        try {
          // Await all promises after the forEach loop completes
          await Promise.all(promises);
          dispatch(setTasksList(taskList));

          let data = taskList?.filter((item: TaskDetailsInterface) => item?.isArchived !== true);
          let archived = taskList
            ?.filter((item: TaskDetailsInterface) => item?.isArchived === true)
            ?.map((x: { id: string }) => x?.id);

          if (lastUpdatedTime === '2000-06-01T09:34:51.737Z' || isEmpty(lastUpdatedTime)) {
            await TasksCacheService.getInstance()?.setTasks(data, workspace_id);
          } else {
            await TasksCacheService.getInstance()?.updateTasks(data, archived, workspace_id);
          }
          let updatedTimeProjects: {
            [key: string]: string;
          } = localProjectList || {};
          projects?.forEach((projectId) => {
            updatedTimeProjects[projectId] = adjustedTime;
          });
          await TasksCacheService.getInstance()?.setLastProjectList(updatedTimeProjects, workspace_id);
        } catch (error) {
          captureException(error);
          console.log('error', error);
        }
      } else if (data) {
        const local_updated_data = await TasksCacheService.getInstance()?.getTasksByWorkspace();
        dispatch(setReportTaskDetails(local_updated_data?.length > 0 ? local_updated_data : []));
        dispatch(setFilteredReportTaskDetails(local_updated_data?.length > 0 ? local_updated_data : []));
        let updatedTimeProjects: {
          [key: string]: string;
        } = localProjectList || {};
        projects?.forEach((projectId) => {
          updatedTimeProjects[projectId] = adjustedTime;
        });
        await TasksCacheService.getInstance()?.setLastProjectList(updatedTimeProjects, workspace_id);
        return data;
      }
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to get all project tasks please try again';
      captureException(error);
      dispatchTaskError(error, dispatch);
      return false;
    } finally {
      dispatch(setTaskLoader(false));
    }
  };

/**
 * @desc members - Get member task data
 * @param {*}
 */
export const memberTaskData: any =
  (member_id: string, my_task_filter: number) =>
  async (dispatch: Dispatch<AnyAction>, getState: () => RootReducerInterface) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setTaskLoader(true));
      await dispatch(getAllProjectTasks());
      const state = getState();
      const { list } = state.project;
      const projectIds = list?.map((project) => {
        return project?.id;
      });
      const tasks: MyTaskDetailsInterface[] = await TasksCacheService.getInstance()?.getTasksByUser(
        member_id,
        projectIds
      );
      const memberTask: MyTaskDetailsInterface[] = tasks?.map((task) => {
        const projectDetails = list?.find((x) => x?.id === task?.projectId);
        if (projectDetails?.statusEnable && projectDetails?.statusData && projectDetails?.statusData?.length > 0) {
          const statusDetails =
            projectDetails?.statusData?.find((x) => x['_id'] === task?.statusId) || projectDetails?.defaultStatus;
          return { ...(task || {}), statusDetails, statusList: projectDetails?.statusData };
        } else return task;
      });

      let data: { title: string; project_id?: string; tasks: MyTaskDetailsInterface[] }[] = [];
      if (memberTask?.length > 0 && my_task_filter === MY_TASKS_GROUP_FILTER_TYPE.GROUP) {
        let promises: Promise<MyTaskDetailsInterface>[] = [];
        let refList: MyTaskDetailsInterface[] = [];
        memberTask?.forEach((ele) => {
          const myPromise = (async () => {
            let groupName = '';

            if (ele?.groupId) {
              try {
                const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/taskGroup/getTaskGroup/${ele?.groupId}`);
                groupName = response?.data?.Name;
              } catch (error) {
                console.error('Error fetching group name:', error);
              }
            }

            const taskWithGroupName: MyTaskDetailsInterface = { ...ele, groupName };
            refList.push(taskWithGroupName);
            return taskWithGroupName;
          })();
          promises.push(myPromise);
        });
        try {
          await Promise.all(promises);
          refList?.forEach((item) => {
            const groupIndex = data?.findIndex((ele) => ele?.title?.toLowerCase() === item?.groupName?.toLowerCase());
            if (groupIndex === -1) {
              data?.push({ title: item?.groupName, tasks: [item] });
            } else {
              data[groupIndex] = { ...data[groupIndex], tasks: [...data[groupIndex]?.tasks, item] };
            }
          });
        } catch (e) {
          captureException(e);
        }
      } else if (memberTask?.length > 0 && my_task_filter === MY_TASKS_GROUP_FILTER_TYPE.PROJECT) {
        memberTask?.forEach((item) => {
          const projectIndex = data?.findIndex((ele) => ele?.project_id === item?.projectId);
          if (projectIndex === -1) {
            data?.push({ title: item?.projectName || '', project_id: item?.projectId, tasks: [item] });
          } else {
            data[projectIndex] = { ...data[projectIndex], tasks: [...(data[projectIndex]?.tasks || []), item] };
          }
        });
      } else if (memberTask?.length > 0 && my_task_filter === MY_TASKS_GROUP_FILTER_TYPE.PRIORITY) {
        const urgentPriorityTasks = memberTask?.filter((item) => item?.priority === TASK_PRIORITY.URGENT);
        if (urgentPriorityTasks?.length > 0)
          data?.push({
            title: TASK_PRIORITY_LABELS[TASK_PRIORITY.URGENT],
            tasks: urgentPriorityTasks
          });

        const highPriorityTasks = memberTask?.filter((item) => item?.priority === TASK_PRIORITY.HIGH);
        if (highPriorityTasks?.length > 0)
          data?.push({ title: TASK_PRIORITY_LABELS[TASK_PRIORITY.HIGH], tasks: highPriorityTasks });

        const mediumPriorityTasks = memberTask?.filter((item) => item?.priority === TASK_PRIORITY.MEDIUM);
        if (mediumPriorityTasks?.length > 0)
          data?.push({
            title: TASK_PRIORITY_LABELS[TASK_PRIORITY.MEDIUM],
            tasks: mediumPriorityTasks
          });

        const lowPriorityTasks = memberTask?.filter((item) => item?.priority === TASK_PRIORITY.LOW);
        if (lowPriorityTasks?.length > 0)
          data?.push({ title: TASK_PRIORITY_LABELS[TASK_PRIORITY.LOW], tasks: lowPriorityTasks });

        const noPriorityPriorityTasks = memberTask?.filter((item) => item?.priority === TASK_PRIORITY.NO_PRIORITY);
        if (noPriorityPriorityTasks?.length > 0)
          data?.push({
            title: TASK_PRIORITY_LABELS[TASK_PRIORITY.NO_PRIORITY],
            tasks: noPriorityPriorityTasks
          });
      } else if (memberTask?.length > 0 && my_task_filter === MY_TASKS_GROUP_FILTER_TYPE.DUE_DATE) {
        const todayStart = moment().startOf('day').toISOString();
        const todayEnd = moment().endOf('day').toISOString();
        const Delayed = memberTask?.filter((task) => moment(task?.dueDate) < moment(todayStart));
        if (Delayed?.length > 0) data?.push({ title: 'Delayed', tasks: Delayed });
        const today = memberTask?.filter((task) => {
          const taskDueDate = moment(task?.dueDate);
          return task?.dueDate && taskDueDate >= moment(todayStart) && taskDueDate <= moment(todayEnd);
        });
        if (today?.length > 0) data?.push({ title: 'Today', tasks: today });
        const upcoming = memberTask?.filter((task) => moment(task?.dueDate) > moment(todayEnd));
        if (upcoming?.length > 0) data?.push({ title: 'Upcoming', tasks: upcoming });
        const other = memberTask?.filter((task) => isEmpty(task?.dueDate) || !task?.dueDate);
        if (other?.length > 0) data?.push({ title: 'Other', tasks: other });
      } else {
        data?.push({ title: '', tasks: memberTask });
      }
      data?.forEach((group) => {
        group?.tasks?.sort((a, b) => new Date(a?.dueDate).getTime() - new Date(b?.dueDate).getTime());
      });
      dispatch(setMemberTaskData(data));
      return data;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to get member task list please try again';
      captureException(error);
      dispatchTaskError(error, dispatch);
      return undefined;
    } finally {
      dispatch(setTaskLoader(false));
    }
  };

/**
 *  @desc task - comment - add reaction
 * @param {*}
 */
export const addReactionOnCommect: any = (payload: EmojiInterface) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setTaskLoader(true));
    const response = await axios.put(`${REACT_APP_TEAMCAMP_APIURL}/Tasks/emojiReactions`, payload);
    if (response) {
      return true;
    }
    return false;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to add reaction in comment please try again';
    captureException(error);
    dispatchTaskError(error, dispatch);
    return false;
  } finally {
    dispatch(setTaskLoader(false));
  }
};

/**
 * @desc Tasks - get sub task list
 * @param {*}
 */
export const getSubTasksList: any = (project_id: string, taskId: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setTaskLoader(true));
    await dispatch(loadProjectTasks(project_id));
    const subTasksList = await TasksCacheService.getInstance()?.getSubTasks(project_id, taskId);
    const sortedList = subTasksList?.toSorted(
      (a: TaskDetailsInterface, b: TaskDetailsInterface) => (a?.subtaskIndex ?? 0) - (b?.subtaskIndex ?? 0)
    );
    dispatch(setSubTaskList(sortedList || []));
    return subTasksList;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to get sub tasks list please try again';
    captureException(error);
    dispatchTaskError(error, dispatch);
    return false;
  } finally {
    dispatch(setTaskLoader(false));
  }
};

export const onChangeComment = (item: TaskDetailsInterface) => {
  const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
  const previousUpdatedComment =
    !isEmpty(item?.updatedComments) && item?.updatedComments !== undefined ? item?.updatedComments : [];
  const updatedComments = [
    ...previousUpdatedComment,
    {
      id: nanoid(),
      type: COMMENT_TYPE.STATUS_CHANGE,
      updatedBy: userDetails?.id,
      updatedTime: new Date().toISOString(),
      createdOn: new Date().toISOString(),
      user: { ...userDetails, name: userDetails?.given_name },
      status: true
    }
  ];
  return { updatedComments };
};

/**
 * @desc Get all recurring tasks
 * @param {*}
 */
export const getAllRecurringTasks: any =
  () => async (dispatch: Dispatch<AnyAction>, getState: () => RootReducerInterface) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setTaskLoader(true));
      const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/Tasks/getAllRecurringTasks`);
      const { data } = response;
      if (data) {
        const state = getState();
        const { workspace } = state.workspace;
        let { list } = state.project;
        const responseUpdated = data?.map((item: any) => {
          const project = list?.find((x: { id: string }) => x?.id === item?.projectId);
          const updatedTasks = item?.tasks?.map((task: any) => {
            const users = task?.TaskUsers?.map((user: { _id: string }) => {
              const userData = workspace?.users?.find((ele: { id: string }) => ele?.id === user['_id']);
              return userData;
            }).filter((userData: UsersWorkspaceInterface) => userData !== undefined && userData !== null);
            const updatedLabels = task?.Labels?.map((x: string) => {
              const labelDetails = project?.labelsList?.find((y) => y?.id === x);
              return labelDetails || {};
            })?.filter((x: LabelDetailsInterface) => x?.id);
            return {
              id: task['_id'],
              comments: [],
              // companyId: workspace?.id,
              createdOn: task?.CreatedTime,
              createdBy: task?.CreatedBy,
              groupId: task?.GroupId,
              index: task?.Index,
              isArchived: task?.IsArchived === true ? true : false,
              description: task?.Desc || '',
              name: task?.Name,
              projectId: task?.ProjectId,
              projectName: item?.name || '',
              status: task?.Status,
              dueDate: task?.DueDate,
              Files: !isEmpty(task?.Files) ? task?.Files : [],
              priority:
                task?.Priority !== undefined && task?.Priority !== null
                  ? task?.Priority
                  : task?.priority?.default || TASK_PRIORITY.NO_PRIORITY,
              // priority: task?.Priority,
              // customerId: !isEmpty(project?.customer?.id) ? project?.customer?.id : '',
              users,
              taskUsers: task?.TaskUsers,
              taskSubscribers: task?.Subscribers,
              estimate: task?.EstimateTime,
              labels: updatedLabels,
              milestoneId: task?.MilestoneId,
              parentTaskId: task?.ParentTaskId,
              statusId: task?.StatusId,
              recurringInterval: task?.recurringInterval,
              start: task?.start,
              taskNumber: task?.TaskNumber
            };
          });
          return { id: item?.projectId, title: item?.name, tasks: updatedTasks };
        });
        return responseUpdated;
      }
      return false;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to get recurring tasks please try again';
      captureException(error);
      dispatchTaskError(error, dispatch);
      return false;
    } finally {
      dispatch(setTaskLoader(false));
    }
  };
export const deleteRecurringTask: any =
  (taskId: string, project_id: string) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setTaskLoader(true));
      if (!taskId) return false;
      const response = await axios.delete(
        `${REACT_APP_TEAMCAMP_APIURL}/Tasks/removeRecurringTask/${taskId}?projectId=${project_id}`
      );

      if (response) {
        return true;
      }
      return false;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to delete recurring task  please try again';
      captureException(error);
      dispatchTaskError(error, dispatch);
      return false;
    } finally {
      dispatch(setTaskLoader(false));
    }
  };

/**
 * @desc Update recurring task data
 * @param {*}
 */
export const updateRecurringTaskDetails: any =
  (recurring_task_id: string, payload: any) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setTaskLoader(true));
      if (!recurring_task_id) return false;
      const response = await axios.put(
        `${REACT_APP_TEAMCAMP_APIURL}/Tasks/updateRecurringTask/${recurring_task_id}`,
        payload
      );
      if (response) {
        return true;
      }
      return false;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to update recurring task please try again';
      captureException(error);
      dispatchTaskError(error, dispatch);
      return false;
    } finally {
      dispatch(setTaskLoader(false));
    }
  };

/**
 * @desc get total number of task
 * @param {*}
 */
export const getTotalTaskCount: any = () => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setTaskLoader(true));
    const response = await axios.get(`${REACT_APP_TEAMCAMP_APIURL}/Tasks/getTotalTasks`);
    const { data } = response;
    if (data) {
      dispatch(setWorkspaceTotalTask(data?.totalTasks));
      return data;
    }
    return false;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to get total number of task';
    captureException(error);
    dispatchTaskError(error, dispatch);
    return false;
  } finally {
    dispatch(setTaskLoader(false));
  }
};

/**
 * @desc Update project task in indexDB
 * @param {*} projetId
 * @param {*} workspaceId
 */

export const updateIndexDbProjectTasks: any =
  (projectId: string, workspaceId: string) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      const localProjectList = await TasksCacheService.getInstance()?.getLastProjectList(workspaceId);
      const tasksLastUpdatedTime = localProjectList[projectId]
        ? localProjectList[projectId]
        : '2000-06-01T09:34:51.737Z';
      await dispatch(getAllProjectWiseTasks([projectId], tasksLastUpdatedTime));
    } catch (e) {
      const error = getAPIErrorReason(e) || 'Unable to update project task in indexDB';
      captureException(error);
      dispatchTaskError(error, dispatch);
    }
  };

/**
 * @desc taskDetails - add resource
 * @param {*}
 */
export const addTaskResource: any =
  (taskId: string, payload: taskLinkInterface) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setTaskLoader(true));
      const response = await axios.post(`${REACT_APP_TEAMCAMP_APIURL}/Tasks/${taskId}/links`, payload);
      const { data } = response;
      if (data) {
        return data;
      }
      return undefined;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'unable to add link please try again';
      captureException(error);
      dispatchTaskError(error, dispatch);
      return undefined;
    } finally {
      dispatch(setTaskLoader(true));
    }
  };

/**
 * @desc task - update resource
 * @param {*}
 */
export const updateTaskResource: any =
  (taskId: string, linkId: string, payload: { label?: string; reference?: string }) =>
  async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(clearResponseMessage(''));
      dispatch(setTaskLoader(true));
      const response = await axios.put(`${REACT_APP_TEAMCAMP_APIURL}/Tasks/${taskId}/links/${linkId}`, payload);
      const { data } = response;
      if (data) {
        return data;
      }
      return undefined;
    } catch (e) {
      const error = getAPIErrorReason(e) || 'unable to add link please try again';
      captureException(error);
      dispatchTaskError(error, dispatch);
      return undefined;
    } finally {
      dispatch(setTaskLoader(false));
    }
  };

/**
 * @desc taskDetails - add resource
 * @param {*}
 */
export const deleteTaskLink: any = (taskId: string, linkId: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setTaskLoader(true));
    const response = await axios.delete(`${REACT_APP_TEAMCAMP_APIURL}/Tasks/${taskId}/links/${linkId}`);
    const { data } = response;
    if (data) {
      return data;
    }
    return false;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to delete link please try again';
    captureException(error);
    dispatchTaskError(error, dispatch);
  } finally {
    dispatch(setTaskLoader(true));
  }
};

export const updateTaskLocalData: any = (task: TaskDetailsInterface) => async (dispatch: Dispatch<AnyAction>) => {
  if (task) {
    const { statusDetails, statusList, ...rest } = task;
    await TasksCacheService.getInstance().updateExisting(task?.id, rest);
  }
};

function dispatchTaskError(msg: string, dispatch: Dispatch<AnyAction>) {
  dispatch(setErrorMessage(msg));
}

function dispatchTaskSuccess(msg: string, dispatch: Dispatch<AnyAction>) {
  dispatch(setSuccessMessage(msg));
}
