import styled from 'styled-components';
import { Typography } from '../../../global/theme';

const Modalbox = styled.div`
  max-width: 400px;
  width: 100%;
  background-color: var(--background-primary);
  border-radius: 12px;
  box-shadow: var(--shadow-modal);
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 12px 16px;
  border-bottom: 0.5px solid var(--border-primary);
`;
const Leftdiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const Bottom = styled.div`
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;
const Headertitle = styled.div`
  ${Typography.heading_md}
  color: var(--text-primary);
`;

const Headerdetail = styled.div`
  ${Typography.body_sm}
  color: var(--text-secondary);
`;
const Closeicon = styled.div`
  display: inline-flex;
  .close-icon {
    cursor: pointer;
    path {
      stroke: var(--text-primary);
    }
  }
  :hover {
    background-color: var(--neutral-secondary);
    border-radius: 4px;
  }
`;
const Action = styled.div`
  border: 0.5px solid var(--border-primary);
  padding: 7px 11px;
  background-color: var(--background-primary);
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  cursor: pointer;
  max-width: 152px;
  width: 100%;
  p {
    ${Typography.body_md_medium}
    color: var(--text-primary);
    margin: 0;
  }
  :hover {
    background-color: var(--background-secondary);
  }
`;
const Icon = styled.div`
  width: 18px;
  height: 18px;
  .markdown-icon {
    path.stroke {
      stroke: var(--text-primary);
    }
    path.fill {
      fill: var(--text-primary);
    }
  }
  .svg-icon {
    path {
      stroke: var(--text-primary);
    }
  }
  .html {
    path {
      fill: var(--text-primary);
    }
  }
  .notion-icon {
    path {
      fill: var(--text-primary);
    }
  }
  .coming-soon-icon {
    path {
      stroke: var(--text-secondary);
    }
    rect {
      stroke: var(--text-secondary);
    }
  }
`;
const Div = styled.div`
  ${Typography.body_lg_medium}
  color: var(--text-secondary);
  margin: 0;
`;
const NotionAction = styled.div`
  max-width: 152px;
  width: 100%;
  border: 0.5px solid var(--border-primary);
  padding: 7px 11px;
  background-color: var(--background-primary);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  gap: 10px;
  cursor: pointer;

  :hover {
    background-color: var(--background-secondary);
  }
`;
const Leftside = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  p {
    ${Typography.body_md_medium}
    color: var(--text-primary);
    margin: 0;
  }
`;
export {
  Modalbox,
  Header,
  Bottom,
  Leftdiv,
  Headertitle,
  Headerdetail,
  Closeicon,
  Action,
  Icon,
  Div,
  NotionAction,
  Leftside
};
