import styled, { css } from 'styled-components';
import { Typography } from '../../global/theme';
interface Props {
  isactive?: boolean;
  isOverviewPage?: boolean;
}
const Button = styled.div<Props>`
  width: fit-content !important;
  color: var(--text-secondary) !important;
  ${Typography.body_md}
  display: flex !important;
  padding: 6px 15px !important;
  border: 0.5px solid transparent;
  cursor: pointer;

  &:active {
    color: var(--text-primary) !important;
    border-radius: 16px !important;
    border: 0.5px solid var(--border-primary) !important;
    background: var(--background-primary) !important;
    box-shadow: var(--shadow-card) !important;
  }
  ${(props) =>
    props.isactive &&
    css`
      cursor: pointer;
      color: var(--text-primary) !important;
      border-radius: 16px !important;
      border: 0.5px solid var(--border-primary) !important;
      background: var(--background-primary) !important;
      box-shadow: var(--shadow-card) !important;
    `}
`;
const Buttons = styled.div<Props>`
  display: flex;
  flex-direction: row;
  margin: 20px 20px 24px;
  ${(props) =>
    props.isOverviewPage &&
    css`
      display: none;
    `}
  @media(max-width:449px) {
    margin: 16px 16px 24px;
  }
`;

const Content = styled.div<Props>`
  border-radius: 8px;
  border: 0.5px solid var(--border-primary);
  background: var(--background-primary);
  ${(props) =>
    props.isOverviewPage &&
    css`
      border: none;
      border-radius: unset;
    `}
`;
export { Button, Buttons, Content };
