import React from 'react';
import { Container, Topcontent, Group, Content, Icon, Title, Descrioption, Buttondiv, Link, Bottom } from './styles';
import Button from '../../../component/button';
import taskmobile from '../../../assets/images/taskmobile.png';
import darktaskmobile from '../../../assets/images/darktaskmobile.png';
import { useSelector } from 'react-redux';
import AppLogo from '../../../component/appLogo';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';

interface Props {
  onClickContinueWeb: () => void;
  onClickContinueApp: () => void;
}

const Mobileonborading: React.FC<Props> = (props) => {
  const { onClickContinueWeb, onClickContinueApp } = props;
  //use selector state variables
  const settingsSelector = useSelector((state: RootReducerInterface) => state.settings);
  const { themeMode } = settingsSelector;

  return (
    <Container>
      <Group>
        <Content>
          <Topcontent>
            <Icon>
              <AppLogo height='40' />
            </Icon>
            <Title>Teamcamp is best experienced using our mobile app.</Title>
            <Descrioption>Our web app has features so big, they won’t fit on small screen.</Descrioption>
          </Topcontent>
          <Buttondiv>
            <Button title='Open in free mobile app' resWidth={100} onClick={onClickContinueApp} />
            <Link onClick={onClickContinueWeb}>Continue on web anyway</Link>
          </Buttondiv>
        </Content>
        <Bottom>
          {themeMode?.theme === 'dark' ? (
            <img src={darktaskmobile} alt='taskmobile' className='firstimg' width={393} height={345} />
          ) : (
            <img src={taskmobile} alt='taskmobile' className='firstimg' width={393} height={345} />
          )}
        </Bottom>
      </Group>
    </Container>
  );
};

export default Mobileonborading;
