import styled from 'styled-components';
import { Typography } from '../../../global/theme';

const DropboxModal = styled.div``;

const ModalBlock = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid var(--border-primary);
  padding: 12px 16px;
  align-items: center;
`;

const ModalHeader = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
`;

const ModalTitle = styled.h2`
  ${Typography.heading_md}
  color: var(--text-primary);
  margin: 0;
`;

const ModalContent = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;
`;

const ModalForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ModalInput = styled.input`
  padding: 11px 16px;
  background-color: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 6px;
  ${Typography.body_lg}
  color: var(--text-primary);
  outline: none;
  :focus {
    border: 0.5px solid var(--brand-primary);
    z-index: 999;
  }
  ::placeholder {
    color: var(--text-secondary);
  }
`;

const ModalButton = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
`;

const Icon = styled.div`
  cursor: pointer;
  display: flex;
  :hover .fillColor {
    background-color: var(--neutral-secondary);
    border-radius: 4px;
  }

  .fillColor {
    stroke: var(--text-primary);
  }
  .notion-fill-color {
    fill: var(--text-primary);
  }
`;
export { DropboxModal, Icon, ModalBlock, ModalButton, ModalContent, ModalForm, ModalHeader, ModalInput, ModalTitle };
