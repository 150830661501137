/* eslint-disable max-len */
import React from 'react';
import { extensionName, isEmpty } from '../../../helpers/common';
import moment from 'moment';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { ReplyMessageDataInterface } from '../../../interfaces/MessageInterface';
import { FILE_EXTENTIONS, FILE_TYPES } from '../../../global/constants';
import { FileIconDiv, ReplyMessageDiv } from '../styles';
import FileSVGIcon from '../../../assets/images/svg/filesIconSvg';
import { FileType } from '../../renderFilePreview/style';

interface Props {
  replyMessage: ReplyMessageDataInterface;
  setReplyMessage: (value: ReplyMessageDataInterface) => void;
}

function ReplyMessage(props: Props) {
  const { replyMessage, setReplyMessage } = props;
  //Other variable
  const classList = [
    'figma-main-div',
    'youtube-preview-main-div',
    'screen-recording-custom-main-div',
    'e-rte-image e-imginline',
    'print-screen'
  ];
  const isShowInBlock = classList.some((className) => replyMessage?.Message?.includes(className));
  const classForReply = isShowInBlock ? 'textblock' : 'text';

  return (
    <ReplyMessageDiv>
      {!isEmpty(replyMessage) && (
        <div className='threaddiv'>
          <div className='content'>
            <div className='line' />

            <div className='rightdiv'>
              <div className='header'>
                <div className='leftheader'>
                  <div className='name'>{replyMessage?.Sender || ''}</div>
                  <div className='time'>{moment(replyMessage?.CreatedTime).fromNow()}</div>
                </div>
                <div className='rightheader' onClick={() => setReplyMessage({})}>
                  <SVGIcon
                    name='close-icon'
                    width='18'
                    height='18'
                    viewBox='0 0 18 18'
                    fill='none'
                    className='svgicon'
                  />
                </div>
              </div>
              {!isEmpty(replyMessage?.Message) && (
                <div
                  className={classForReply}
                  dangerouslySetInnerHTML={{
                    __html: replyMessage?.Message || ''
                  }}
                />
              )}
              {replyMessage?.Files && replyMessage?.Files?.length > 0 && (
                <div className='filediv'>
                  {replyMessage?.Files?.map((x) => {
                    const extension = extensionName(x).toLowerCase();
                    return (
                      <div key={x?.href} className='imagediv'>
                        {(!x?.uploadFileType || x?.uploadFileType === FILE_TYPES.UPLOAD) &&
                        !FILE_EXTENTIONS.includes(extension) ? (
                          <FileIconDiv>
                            <FileSVGIcon name={'blank'} height='32' width='32' viewBox='0 0 60 60' />
                            <FileType
                              style={{
                                fontSize: extension?.length === 4 ? 7 : 5
                              }}
                              className='file-text'>
                              {extension?.length <= 5 && extension.split('.')?.pop()?.toUpperCase()}
                            </FileType>
                          </FileIconDiv>
                        ) : (
                          <FileSVGIcon
                            name={
                              !x?.uploadFileType || x?.uploadFileType === FILE_TYPES.UPLOAD
                                ? extension
                                : `icon_${x?.uploadFileType}`
                            }
                            height='32'
                            width='32'
                            viewBox='0 0 60 60'
                          />
                        )}
                        {/* <SVGIcon name='gray-file-icon' width='20' height='20' viewBox='0 0 60 60' fill='none' /> */}
                        <div className='filetext'>{x?.name}</div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </ReplyMessageDiv>
  );
}

export default ReplyMessage;
