import { useCallback } from 'react';
import { Item, Submenu } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { updateCreateTaskInput } from '../../actions/taskActions';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';
import { updateRecurringTaskDetails } from '../../services/taskServices';

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

interface Props {
  className?: string;
  data?: any;
  loadData?: any;
}
export default function SubmenuWeeklyMenu(props: Props) {
  //use selector state variables
  const taskSelector = useSelector((state: RootReducerInterface) => state.task);
  const { createTaskInput } = taskSelector;
  //Other variable
  const dispatch = useDispatch();

  const setWeeklyRecurringTask = useCallback(
    async (day: string) => {
      try {
        const payloadTask = {
          projectId: props?.data?.projectId,
          start: day.toLowerCase(),
          recurringInterval: 'weekly'
        };
        const response = await dispatch(updateRecurringTaskDetails(props?.data?.id, payloadTask));
        if (response) {
          props?.loadData();
        }

        dispatch(updateCreateTaskInput({ propsName: 'start', value: day }));
        dispatch(updateCreateTaskInput({ propsName: 'recurringInterval', value: 'weekly' }));
      } catch (error) {
        console.log('error', error);
      }
    },
    [dispatch, props]
  );
  return (
    <Submenu className={props?.className}>
      {days.map((day) => (
        <Item
          key={day}
          isSelected={createTaskInput?.start === day.toString()}
          onClick={() => setWeeklyRecurringTask(day)}>
          {day}
        </Item>
      ))}
    </Submenu>
  );
}
