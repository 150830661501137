import React from 'react';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { Modalhead, Headline, Deletewarning, Buttons, ModalContent, Icon, ModalMain } from './styles';
import Button from '../../button';

interface UserData {
  onOk?: any;
  onClose?: () => void;
  loading?: boolean;
  modaltitle?: string;
  description?: string;
  isYes?: boolean;
  isArchive?: boolean;
  isPrimaryButton?: boolean;
  buttonText?: string;
}

const Deletemodal: React.FC<UserData> = (props) => {
  return (
    <ModalMain onClick={(e) => e.stopPropagation()}>
      <Modalhead>
        <Headline isArchive={props.isArchive}>{props.modaltitle}</Headline>
        <Icon onClick={props.onClose}>
          <SVGIcon name='close-icon' width='18' height='18' viewBox='0 0 18 18' fill='none' className='fillColor' />
        </Icon>
      </Modalhead>
      <ModalContent>
        <Deletewarning>{props.description}</Deletewarning>
        <Buttons>
          <Button
            title='Cancel'
            onClick={props.onClose}
            type='button'
            secondary={true}
            isCancelbtn={true}
            hasNotBoxshadow
          />
          <Button
            title={props.buttonText || (props.isYes ? 'Yes' : props.isArchive ? 'Archive' : 'Delete')}
            isDelete={props.isPrimaryButton ? false : true}
            isLoading={props.loading}
            modelbtn={true}
            onClick={props.onOk}
          />
        </Buttons>
      </ModalContent>
    </ModalMain>
  );
};

export default Deletemodal;
