/* eslint-disable max-len */
import styled, { css } from 'styled-components';
import { Typography } from '../../../global/theme';

interface Props {
  isdark?: string;
}
const Maincontent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  margin-left: 25px;
  .loading-animation {
    overflow: hidden;
  }

  .loading-animation::before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    animation: loading 1s infinite;
    background: linear-gradient(to right, transparent, var(--background-tertiary), transparent);
  }

  @keyframes loading {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;

const Leftcontent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 678px;
`;

const Rightcontent = styled.div``;

const Activitybox = styled.div`
  width: 274px;
`;

const Activity = styled.div`
  background: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 8px;
  padding: 16px;
  .last {
    margin-bottom: 0;
  }
`;
const Totalcontent = styled.p`
  ${Typography.heading_md}
  align-items: center;
  margin: 0;
  color: var(--text-primary);
  padding-bottom: 6px;
`;
const Activitybottom = styled.div`
  margin-bottom: 16px;
  border-top: 0.5px solid var(--border-primary);
  display: flex;
  gap: 12px;
  flex-direction: column;
`;

const Title = styled.div`
  background: var(--background-muted);
  border-radius: 7px;
  width: 134px;
  height: 8px;
  margin-top: 16px;
`;

const Desc = styled.div`
  background: var(--background-muted);
  border-radius: 7px;
  width: 74px;
  height: 6px;
`;
const Maindiv = styled.div<Props>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  border: 0.5px solid var(--border-primary);
  background: var(--background-primary);
  max-width: 678px;
  .after {
    border: 0.5px solid var(--border-primary);
    width: 24px;
    height: 100%;
    border-radius: 0px 8px 8px 0px;
    ${(props: Props) =>
      props.isdark === 'light'
        ? css`
            background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 16 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fillRule='evenodd' clipRule='evenodd' d='M7 12.3422C10.3137 12.3422 13 9.69115 13 6.42087C13 3.1506 10.3137 0.499512 7 0.499512C3.68629 0.499512 1 3.1506 1 6.42087C1 9.69115 3.68629 12.3422 7 12.3422Z' fill='%23F6F9FC' stroke='%23E3E8EA' strokeWidth='0.888889'/%3E%3C/svg%3E");
          `
        : css`
            background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 16 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fillRule='evenodd' clipRule='evenodd' d='M7 12.3422C10.3137 12.3422 13 9.69115 13 6.42087C13 3.1506 10.3137 0.499512 7 0.499512C3.68629 0.499512 1 3.1506 1 6.42087C1 9.69115 3.68629 12.3422 7 12.3422Z' fill='%232E2E30' stroke='%232E2E30' strokeWidth='0.888889'/%3E%3C/svg%3E");
          `}
    background-repeat: repeat-y;
    background-position: center;
    background-size: 18px;
    position: absolute;
    right: -25px;
  }
  .before {
    position: absolute;
    border: 0.5px solid var(--border-primary);
    width: 24px;
    height: 100%;
    ${(props: Props) =>
      props.isdark === 'light'
        ? css`
            background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 16 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fillRule='evenodd' clipRule='evenodd' d='M7 12.3422C10.3137 12.3422 13 9.69115 13 6.42087C13 3.1506 10.3137 0.499512 7 0.499512C3.68629 0.499512 1 3.1506 1 6.42087C1 9.69115 3.68629 12.3422 7 12.3422Z' fill='%23F6F9FC' stroke='%23E3E8EA' strokeWidth='0.888889'/%3E%3C/svg%3E");
          `
        : css`
            background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 16 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fillRule='evenodd' clipRule='evenodd' d='M7 12.3422C10.3137 12.3422 13 9.69115 13 6.42087C13 3.1506 10.3137 0.499512 7 0.499512C3.68629 0.499512 1 3.1506 1 6.42087C1 9.69115 3.68629 12.3422 7 12.3422Z' fill='%232E2E30' stroke='%232E2E30' strokeWidth='0.888889'/%3E%3C/svg%3E");
          `}
    border-radius: 8px 0px 0px 8px;
    background-repeat: repeat-y;
    background-position: center;
    background-size: 18px;
    left: -25px;
  }
`;

const Topinvoice = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 20px;
`;
const Invoiceleft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-top: 30px;
`;
const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

const Inputid = styled.div`
  display: flex;
  gap: 15px;
  .last {
    width: 119px;
  }
`;

const Inputtitle = styled.div`
  background: var(--background-muted);
  border-radius: 7px;
  width: 65px;
  height: 8px;
`;

const Inputdesc = styled.div`
  background: var(--background-muted);
  border-radius: 7px;
  width: 65px;
  height: 8px;
`;
const Invoiceright = styled.div`
  margin: 43px 0 0;
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 238px;
  width: 100%;
`;

const Title2 = styled.div`
  text-align: center;
  ${Typography.heading_lg}
  color: var(--text-primary);
`;
const Box = styled.div`
  display: flex;
  gap: 20px;
  .title {
    margin-top: 0;
  }
`;

const Address = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const From = styled.div`
  background: var(--background-muted);
  border-radius: 7px;
  width: 40px;
  height: 8px;
`;

const Rightbox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Rightbottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  .second {
    width: 118px;
  }
  .third {
    width: 64px;
  }
`;

const First = styled.div`
  background: var(--background-muted);
  border-radius: 7px;
  width: 178px;
  height: 6px;
`;
const Invoicetable = styled.div``;
const Gheader = styled.div`
  padding: 12px 19px;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  border-top: 0.5px solid var(--border-primary);
  background: var(--background-tertiary);
  margin-top: 39px;
`;
const Rightheader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Label = styled.p`
  ${Typography.body_md_medium}
  color: var(--text-secondary);
  margin: 0px;
  text-transform: uppercase;
`;
const Quantity = styled.p`
  ${Typography.body_md_medium}
  color: var(--text-secondary);
  border-left: 0.5px solid var(--border-primary);
  border-right: 0.5px solid var(--border-primary);
  padding: 0px 12px 0px 15px;
  margin: 0px;
  text-transform: uppercase;
`;
const Amount = styled.p`
  ${Typography.body_md_medium}
  color: var(--text-secondary);
  padding-left: 40px;
  margin: 0px;
  text-transform: uppercase;
`;
const Price = styled.p`
  ${Typography.body_md_medium}
  color: var(--text-secondary);
  border-right: 0.5px solid var(--border-primary);
  padding: 0px 12px 0px 25px;
  margin: 0px;
  text-transform: uppercase;
`;

const Row = styled.div`
  padding: 14px 19px;
  display: flex;
  justify-content: space-between;
  border-top: 0.5px solid var(--border-primary);
`;
const Leftrow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 11px;
`;

const Rightrow = styled.div`
  display: flex;
  gap: 37px;
`;
const Quantityrate = styled.div`
  background-color: var(--background-muted);
  width: 38px;
  height: 8px;
  border-radius: 7px;
  text-align: end;
`;

const Amountrate = styled.div`
  background-color: var(--background-muted);
  width: 69px;
  height: 8px;
  border-radius: 7px;
  text-align: end;
`;
const Inforate = styled.div`
  background-color: var(--background-muted);
  width: 69px;
  height: 8px;
  border-radius: 7px;
  text-align: end;
`;

const Topleft = styled.div`
  width: 267px;
  height: 8px;
  border-radius: 7px;
  background: var(--background-muted);
`;

const Topbottom = styled.div`
  width: 169px;
  height: 8px;
  border-radius: 7px;
  background: var(--background-muted);
`;
const Total = styled.div`
  padding: 37px 20px;
  border-top: 0.5px solid var(--border-primary);
  border-bottom: 0.5px solid var(--border-primary);
  gap: 23px;
  display: flex;
  flex-direction: column;
`;
const Line = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 53px;
  .first {
    width: 61px;
  }
  .second {
    width: 109px;
  }
`;
const Heading = styled.div`
  background-color: var(--background-muted);
  width: 73px;
  height: 8px;
  border-radius: 7px;
`;

const Description = styled.div`
  background-color: var(--background-muted);
  width: 61px;
  height: 8px;
  border-radius: 7px;
`;
const Note = styled.div`
  padding: 27px 20px 29px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const Notetitle = styled.div`
  background-color: var(--background-muted);
  width: 60px;
  height: 8px;
  border-radius: 7px;
`;

const Notedescription = styled.div`
  background-color: var(--background-muted);
  width: 367px;
  height: 8px;
  border-radius: 7px;
`;

export {
  Maincontent,
  Leftcontent,
  Rightcontent,
  Activitybox,
  Activity,
  Totalcontent,
  Activitybottom,
  Title,
  Desc,
  Maindiv,
  Topinvoice,
  Invoiceleft,
  Details,
  Inputid,
  Inputtitle,
  Inputdesc,
  Invoiceright,
  Title2,
  Box,
  Address,
  From,
  Rightbox,
  Rightbottom,
  First,
  Invoicetable,
  Label,
  Rightheader,
  Gheader,
  Price,
  Amount,
  Quantity,
  Row,
  Leftrow,
  Rightrow,
  Quantityrate,
  Amountrate,
  Inforate,
  Topleft,
  Topbottom,
  Total,
  Line,
  Heading,
  Description,
  Note,
  Notetitle,
  Notedescription
};
