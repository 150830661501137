/* eslint-disable jsx-a11y/img-redundant-alt */
import parse, { domToReact } from 'html-react-parser';
import { renderPrntImageFile } from '../../helpers/common';
import RenderVideoFile from '../renderVideoFile';
import { RenderImagePreview } from '../renderImagePreview';
import { CopyIcon, DropdownDiv, FigmaButton } from './styles';
import SVGIcon from '../../assets/images/svg/SVGIcon';
import { Dropdown, DropdownItem } from '../Dropdown';
import copy from 'clipboard-copy';
import { setSuccessMessage } from '../../actions/messageActions';
import { useDispatch } from 'react-redux';
import TiptapEditor from '../tiptapEditor';
import { escape } from 'lodash';

function RenderHtml({ htmlstring, isReplyText }: { htmlstring: string; isReplyText?: boolean }) {
  const dispatch = useDispatch();
  return (
    <>
      {parse(`<div>${htmlstring}</div>`, {
        replace: (domNode: any) => {
          if (domNode.type === 'tag' && domNode?.attribs?.class?.includes('figma-link-div')) {
            const onClickCopyFigmaLink = async (url: string) => {
              await copy(url);
              dispatch(setSuccessMessage('Figma url copied successfully!'));
            };

            return (
              <>
                <FigmaButton href={domNode.attribs.href} target='_blank'>
                  Open in Figma
                </FigmaButton>
                <DropdownDiv>
                  <Dropdown
                    isMinWidth={150}
                    content={
                      <CopyIcon>
                        <SVGIcon
                          className='svgicon'
                          name='three-dots-vertical-icon'
                          width='16'
                          height='16'
                          viewBox='0 0 16 16'
                          fill='none'
                        />
                      </CopyIcon>
                    }>
                    <DropdownItem
                      label='Copy link'
                      iconName='copy-link-icon'
                      iconSize={16}
                      iconTone='stroke'
                      onClick={() => onClickCopyFigmaLink(domNode.attribs.href)}
                    />
                  </Dropdown>
                </DropdownDiv>
              </>
            );
          } else if (
            domNode.type === 'tag' &&
            domNode.name === 'img' &&
            !domNode?.attribs?.class?.includes('figma-icon') &&
            !domNode?.attribs?.class?.includes('refresh-icon') &&
            !domNode?.attribs?.class?.includes('figma-preview-image')
          ) {
            return <RenderImagePreview imageUrl={domNode.attribs.src} isImage={true} altText={domNode.attribs.alt} />;
          } else if (
            domNode.type === 'tag' &&
            domNode.name === 'img' &&
            domNode?.attribs?.class?.includes('figma-preview-image')
          ) {
            return <RenderImagePreview imageUrl={domNode.attribs.src} altText={domNode.attribs.alt} />;
          } else if (domNode.type === 'tag' && domNode?.attribs?.class?.includes('screen-recording-custom-main-div')) {
            const regex = /^<div[^>]*\bclass=["'][^"']*\bscreen-recording-custom-main-div\b[^"']*["']/;
            const matches = regex.test(htmlstring.trim());
            return (
              <RenderVideoFile
                videoUrl={domNode?.attribs?.url}
                videoName={domNode?.attribs?.name}
                videoId={domNode?.attribs?.id}
                isStartFromVideo={isReplyText && matches}
              />
            );
          } else if (domNode.type === 'tag' && domNode?.attribs?.class?.includes('prnt-preview-main-div')) {
            return renderPrntImageFile(domNode?.attribs?.url, domNode?.attribs?.name);
          } else if (
            domNode.type === 'tag' &&
            domNode.name === 'img' &&
            domNode?.attribs?.class?.includes('figma-icon')
          ) {
            domNode.attribs.src =
              // eslint-disable-next-line max-len
              'https://firebasestorage.googleapis.com/v0/b/teamcamp-app.appspot.com/o/MediaIcons%2FIcons%3DFill%2C%20Type%3DFile%2C%20Icon%20Name%3DFigma.png?alt=media&token=182ec435-8705-4131-9e42-f76e01d8cfea';
            return domNode;
          } else if (
            domNode.type === 'tag' &&
            domNode.name === 'img' &&
            domNode?.attribs?.class?.includes('refresh-icon')
          ) {
            domNode.attribs.src =
              // eslint-disable-next-line max-len
              'https://firebasestorage.googleapis.com/v0/b/teamcamp-app.appspot.com/o/MediaIcons%2FIcons.png?alt=media&token=f85c6305-cb8d-4202-bf35-5bd8f2951758';
            return domNode;
          } else if (domNode.type === 'tag' && domNode.attribs?.class?.includes('youtube-preview-main-div')) {
            const regex = /^<div[^>]*\bclass=["'][^"']*\byoutube-preview-main-div\b[^"']*["']/;
            const matches = regex.test(htmlstring.trim());
            return (
              <RenderVideoFile
                isYouTubePreview={true}
                videoUrl={domNode?.attribs?.url}
                videoName={domNode?.attribs?.name}
                videoId={domNode?.attribs?.name}
                isStartFromVideo={isReplyText && matches}
              />
            );
          } else if (domNode.type === 'tag' && domNode.name === 'pre' && domNode.children?.[0]?.name === 'code') {
            const codeContent = domNode.children[0].children.map((child: any) => child.data).join('');
            const escapedContent = escape(codeContent);
            return <TiptapEditor valueContent={`<pre><code>${escapedContent}</code></pre>`} isEditable={false} />;
          } else if (domNode.type === 'tag' && domNode.name === 'table') {
            return <div className='tableWrapper'>{domToReact([domNode])}</div>;
          }
          return domNode;
        }
      })}
    </>
  );
}
export default RenderHtml;
