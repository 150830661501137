import { createReducer } from "../helpers/reduxHelpers";
import * as Actions from '../actions/types';
import { combineReducers } from "redux";

const setSnapLoader = createReducer({
    initialState: false,
    actionType: Actions.SET_SNAP_LOADER
  });

const setSnapListDetail = createReducer({
    initialState: [],
    actionType: Actions.SET_SNAP_LIST
  });

const snapReducer = combineReducers({
loading : setSnapLoader,
snapList : setSnapListDetail
})

export default snapReducer;