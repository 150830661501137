import styled, { css } from 'styled-components';
import { Typography } from '../../../global/theme';

interface Props {
  isLoading?: boolean;
}

const Group = styled.div<Props>`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: var(--background-secondary);
  margin: auto;
  height: 100vh;
  overflow: auto;
  scrollbar-width: none;
  ${(props: Props) =>
    props.isLoading &&
    css`
      cursor: wait;
    `}
`;

const Content = styled.div`
  width: 100%;
  max-width: 754px;
  margin: 80px auto;
  display: flex;
  background-color: var(--background-secondary);
  flex-direction: column;
  .button {
    padding-top: 32px;
    margin: 0 auto;
    padding-bottom: 24px;
  }
  @media (max-width: 449px) {
    max-width: 100%;
    padding: 50px 24px 0 24px;
    gap: 30px;
    margin: 0;
  }
`;

const Header = styled.div`
  text-align: center;
`;

const Icon = styled.div`
  @media (max-width: 449px) {
    .svg-icon {
      width: 40px;
      height: 40px;
    }
  }
`;

const Title = styled.h1`
  ${Typography.heading_lg}
  color: var(--text-primary);
  @media (max-width: 449px) {
    margin: 30px 0;
    text-align: center;
  }
`;

const Organization = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-top: 50px;
  color: var(--text-primary);
  @media (max-width: 449px) {
    margin-top: 0;
    gap: 12px;
  }
`;

const Boxcontent = styled.button`
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;
  max-width: 238px;
  background-color: var(--background-primary);
  border: 1px solid var(--border-primary);
  border-radius: 8px;
  padding: 11px;
  cursor: pointer;

  .name-text {
    ${Typography.body_lg_medium}
    color: var(--text-primary)
  }
  @media (max-width: 449px) {
    border-radius: 12px;
    padding: 12px;
    max-width: -webkit-fill-available;
    border: 1px solid var(--border-primary);
  }
`;

const Icon1 = styled.div`
  display: inline-flex;
`;
const Buttons = styled.div`
  width: fit-content;
  display: inline-flex;
  padding: 5px 20px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 36px;
  border: 0.5px solid var(--border-primary);
  background: var(--background-primary);
  box-shadow: var(--shadow-button);
  cursor: pointer;
  p {
    color: var(--text-primary);
    ${Typography.body_md_medium}
    margin: 0;
  }
  .stroke-icon {
    stroke: var(--text-primary);
  }
`;
export { Group, Content, Header, Icon, Title, Organization, Boxcontent, Icon1, Buttons };
