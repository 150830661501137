import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCreateTaskInput } from '../../../actions/taskActions';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import { TaskGroupInterface } from '../../../interfaces/TaskInterface';
import Dropdown from '../../Dropdown/MainDropdown';
import DropdownButton from '../../Dropdown/DropdownButton';
import DropdownItem from '../../Dropdown/DropdownItem';
interface Props {
  isDisabled?: boolean;
  onUpdateGroup?: (group: TaskGroupInterface) => void;
  isCreateTaskModal?: boolean;
  value?: TaskGroupInterface;
}

const Statusdropdown: React.FC<Props> = (props) => {
  const { onUpdateGroup, isCreateTaskModal, value } = props;

  //use selector state variables
  const taskSelector = useSelector((state: RootReducerInterface) => state.task);
  const { selectedGroupList } = taskSelector;

  //Other variable
  const dispatch = useDispatch();

  useEffect(() => {
    if (isCreateTaskModal && !value && selectedGroupList?.length > 0) {
      dispatch(updateCreateTaskInput({ propsName: 'groupId', value: selectedGroupList[0] }));
    }
  }, [dispatch, isCreateTaskModal, selectedGroupList, value]);

  // handle click group
  const onClickGroup = useCallback(
    (taskGroup: TaskGroupInterface) => {
      if (isCreateTaskModal) dispatch(updateCreateTaskInput({ propsName: 'groupId', value: taskGroup }));
      else if (onUpdateGroup) {
        onUpdateGroup(taskGroup);
        return;
      }
    },
    [dispatch, isCreateTaskModal, onUpdateGroup]
  );

  const renderPriorityVariant = useMemo(() => {
    if (isCreateTaskModal) return 'border';
    return 'hover-border';
  }, [isCreateTaskModal]);

  // render group list
  const renderGroupList = useMemo(() => {
    return selectedGroupList.map((group: TaskGroupInterface) => (
      <DropdownItem
        label={group.name}
        key={group?.id}
        onClick={() => onClickGroup(group)}
        isSelected={value?.id === group.id}
      />
    ));
  }, [onClickGroup, selectedGroupList, value?.id]);

  return (
    <Dropdown
      content={
        <DropdownButton
          iconName={isCreateTaskModal ? 'group-icon' : ''}
          title={value?.name || 'Group'}
          isActive={value?.name ? true : false}
          iconTone='stroke'
          size={isCreateTaskModal ? 'small' : 'medium'}
          variant={renderPriorityVariant}
        />
      }
      trigger='click'
      modalTitle='Group'>
      {renderGroupList}
    </Dropdown>
  );
};
export default Statusdropdown;
