/* eslint-disable max-len */
import styled, { css } from 'styled-components';
import { Typography } from '../../global/theme';
interface Props {
  loading?: boolean;
  isModel?: boolean;
  isApplyMinHeight?: string;
  isApplyMaxHeight?: string;
  isApplyMargin?: string;
  isApplyMsgModel?: boolean;
  isApplyCss?: boolean;
  isFileCard?: boolean;
  ishideicon?: boolean;
  isPlaceholdercolor?: boolean;
  isActive?: boolean;
  isInlineToolbar?: boolean;
  isplaceholder?: boolean;
  isPaddingright?: boolean;
  isScrollbarnone?: boolean;
  iscommentModelPadding?: boolean;
  isResponsiveMinHeight?: string;
  iscreateTask?: boolean;
  isTaskDetailComment?: boolean;
  isMessageMentionModal?: boolean;
  isContactUsModal?: boolean;
  padding?: string;
  isScrollbarDesign?: boolean;
}
const MentionTable = styled.div`
  :hover {
    background-color: var(--neutral-secondary);
    cursor: pointer;
  }
  .mentionEmpImage {
    border-radius: 50%;
  }
`;

const MentionList = styled.div`
  padding: 8px 12px;
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  .mentionEmpImage {
    border-radius: 50%;
  }
  p {
    margin: 0;
    color: var(--text-primary);
    ${Typography.body_md}
    white-space: nowrap;
  }
`;

const FileBox = styled.div<Props>`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: ${(props) => props.isApplyMargin};
  padding: 0 ${(props) => props.padding} 0;
  ${(props) =>
    props.isContactUsModal &&
    css`
      max-height: 158px;
      overflow: auto;
      scrollbar-width: none;
    `}
`;

const FileBoxContainer = styled.div<Props>`
  cursor: pointer;
  .file-box {
    max-width: max-content;
    display: inline-flex;
    padding: 5px;
    background-color: var(--background-primary);
    border: 0.5px solid var(--border-primary);
    border-radius: 8px;
    box-shadow: var(--shadow-card);
    align-items: center;
    gap: 4px;
    ${(props) =>
      props.isFileCard &&
      css`
        margin: 4px 0;
        padding: 10px 16px;
        gap: 16px;
      `}
  }
  .textarea {
    width: 130px;
  }
  .h4 {
    ${Typography.body_md_medium}
    color: var(--text-primary);
    margin: 0 0 4px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    white-space: inherit;
    word-break: break-all;
  }
  .p {
    ${Typography.body_xs}
    color: var(--text-secondary);
    margin: 0;
  }
  .svg {
    width: 32px;
    height: 32px;
  }
  .image-div {
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  @media (max-width: 449px) {
    .p {
      padding-top: 0;
    }
  }
`;

const CloseButton = styled.span`
  color: var(--text-secondary);
  cursor: pointer;
  padding-left: 12px;
`;

const TextMainDiv = styled.div<Props>`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    background-color: transparent !important;
    color: var(--text-primary) !important;
  }
  ul,
  ol {
    color: var(--text-primary) !important;
    background-color: transparent !important;
    li {
      ::marker {
        color: var(--text-primary) !important;
        background-color: transparent !important;
      }
    }
  }
  p > span {
    color: var(--text-primary) !important;
  }
  .figma-main-div {
    pointer-events: none;
    -webkit-user-modify: read-only;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 8px;
    padding: 12px;
    background-color: var(--background-secondary);
    border: 0.5px solid var(--brand-primary);
    width: fit-content;
    margin-top: 12px;
  }
  .figma-desc-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }
  .figma-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
  }
  .figma-link-div {
    pointer-events: all !important;
    padding: 5px 16px;
    background-color: var(--background-primary);
    color: var(--text-primary);
    border: 0.5px solid var(--border-primary);
    cursor: pointer;
    text-decoration: none;
    border-radius: 36px;
    ${Typography.body_sm_medium}
    white-space: nowrap;
    &:hover {
      background-color: var(--background-secondary);
      border-radius: 36px;
      text-decoration: none;
    }
  }
  .figma-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }
  .figma-description {
    color: var(--text-primary);
    ${Typography.body_sm_medium}
  }
  .figma-filename {
    color: var(--text-secondary);
    ${Typography.body_sm_medium}
  }
  .figma-icon {
    width: 24px;
    height: 24px;
  }
  .figma-preview-image {
    border-radius: 0px !important;
    border-right: 0.5px solid var(--border-primary);
    border-left: 0.5px solid var(--border-primary);
    max-width: 100%;
    width: auto;
    max-height: 419px;
    height: auto;
    margin: 0 auto;
    display: flex;
  }
  .figma-right-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }
  .figma-animation {
    width: 24px;
    height: 24px;
    display: flex;
    .refresh-icon-spin {
      animation-name: spin;
      animation-duration: 1000ms;
      animation-iteration-count: infinite;

      -webkit-animation-name: spin;
      -webkit-animation-duration: 1000ms;
      -webkit-animation-iteration-count: infinite;
    }
    @-ms-keyframes spin {
      from {
        -ms-transform: rotate(0deg);
      }
      to {
        -ms-transform: rotate(360deg);
      }
    }
    @-moz-keyframes spin {
      from {
        -moz-transform: rotate(0deg);
      }
      to {
        -moz-transform: rotate(360deg);
      }
    }
    @-webkit-keyframes spin {
      from {
        -webkit-transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(360deg);
      }
    }
    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
  .refresh-icon {
    width: 18px;
    height: 18px;
    max-width: 100%;
    max-height: 18px;
    cursor: pointer;
    padding: 3px;
  }

  a {
    color: var(--text-link);
  }

  #inlineRTE_rte-edit-view_options {
    border: var(--border-primary);
    background: var(--background-primary);
  }
  position: relative;
  .ProseMirror-widget {
    color: var(--text-placeholder) !important;
  }
  #editor,
  .editor {
    ::-webkit-scrollbar {
      display: none;
    }
    ${(props) =>
      props.isScrollbarDesign &&
      css`
        ::-webkit-scrollbar {
          display: block;
          background-color: var(--background-primary) !important;
          width: 6px !important;
        }
        ::-webkit-scrollbar-thumb {
          background-color: var(--text-tertiary) !important;
          width: 6px !important;
          margin-bottom: 4px !important;
          border-radius: 7px !important;
          min-height: 20px !important;
        }
      `}
  }
  ${(props) =>
    props.isApplyCss &&
    css`
      > div > div > div {
        min-height: 100px;
      }
      height: 100%;
      min-height: 30px;
      max-height: 642px;
      overflow: scroll;
      ::-webkit-scrollbar {
        display: none;
      }
    `}
  > div > div > span {
    position: absolute;
    pointer-events: none;
    color: var(--text-secondary) !important;
    font-size: 15px;
    ${(props) =>
      props.isApplyMsgModel &&
      css`
        padding-top: 6px;
        color: var(--text-secondary);
      `}
  }
  ${(props) =>
    props.isPlaceholdercolor &&
    css`
      > div > div > span {
        color: var(--text-placeholder) !important;
        ${Typography.body_lg}
        opacity: 1 !important;
      }
    `}
  .cleanshot-video-main-div {
    -webkit-user-modify: read-only;
    border: 1px solid var(--brand-primary);
    border-radius: 8px;
    padding: 8px;
    max-width: 354px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
    background-color: var(--background-primary);
  }
  .screen-recording-custom-main-div {
    -webkit-user-modify: read-only;
    border: 1px solid var(--brand-primary);
    border-radius: 8px;
    padding: 10px 8px;
    max-width: 354px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
    background-color: var(--background-primary);
  }
  .screen-recording-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .e-rte-video .e-video-inline {
    width: 355px !important;
  }
  .screen-recording-name {
    color: var(--text-primary);
    ${Typography.body_sm_medium}
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    word-break: break-all;
  }
  .screen-recording-right-div {
    display: flex;
    gap: 12px;
  }
  .open-video-icon {
    max-width: 100%;
    max-height: 100%;
  }
  .video-more-icon {
    max-width: 100%;
    max-height: 100%;
  }
  .inbox-video {
    width: 353px;
    height: auto;
    border-radius: 6px;
    border: 0.5px solid var(--border-primary);
  }
  .screen-recording-animation {
    width: 16px;
    height: 16px;
  }
  .screen-recording-more {
    width: 16px;
    height: 16px;
  }
  .inbox-image {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .video-icon {
    position: absolute;
    top: 45%;
    left: 45%;
  }
  .prnt-desc-div {
    display: flex;
    gap: 2px;
    align-items: center;
  }
  .prnt-icon {
    margin: 0;
  }
  .prnt-url-link {
    cursor: pointer;
    ${Typography.body_sm_medium}
    color: var(--text-link);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    word-break: break-all;
    max-width: 300px;
  }
  .prnt-text {
    padding: 0 4px;
    :hover {
      background-color: var(--neutral-secondary);
      border-radius: 4px;
    }
  }
  .prnt-preview-main-div {
    -webkit-user-modify: read-only;
    display: flex;
    flex-direction: column;
    width: fit-content;
    border-radius: 8px;
    border: 0.5px solid var(--brand-primary);
    background-color: var(--background-primary);
    position: relative;
    z-index: 1;
  }
  .cleanshot-image-main-div {
    -webkit-user-modify: read-only;
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: fit-content;
    border-radius: 8px;
    border: 1px solid var(--brand-primary);
    padding: 7px;
    background-color: var(--background-primary);
    position: relative;
    z-index: 1;
  }
  .print-screen {
    border-radius: 6px;
    max-width: 100%;
    width: auto;
    max-height: 200px;
    height: auto;
    display: flex;
    margin: 0 auto;
    border: 0.5px solid var(--border-primary);
  }
  .threaddiv {
    padding: 8px;
    border-radius: 4px;
    border: 0.5px solid var(--border-primary);
    background: var(--background-tertiary);
    width: 100%;
    max-width: fit-content;
  }
  .content {
    display: flex;
    flex-direction: row;
    gap: 9px;
  }
  .line {
    width: 2px;
    background-color: var(--text-placeholder);
    height: auto;
    border-radius: 3px;
  }
  .rightdiv {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .header {
    display: flex;
    flex-direction: row;
    gap: 50px;
    justify-content: space-between;
  }
  .leftheader {
    display: flex;
    flex-direction: row;
    gap: 9px;
    align-items: center;
  }
  .rightheader {
    cursor: pointer;
    height: 18px;
    .svgicon {
      stroke: var(--text-primary);
      fill: var(--text-primary);
    }
    :hover {
      background-color: var(--neutral-secondary);
      border-radius: 4px;
    }
  }
  .name {
    color: var(--text-primary);
    ${Typography.body_md_semibold}
  }
  .time {
    color: var(--text-secondary);
    ${Typography.body_md}
  }
  .textblock {
    width: 100%;
    max-width: 100%;
    display: block;
    color: var(--text-primary);
    ${Typography.body_md}
    p {
      color: var(--text-primary);
      ${Typography.body_md}
      margin: 0;
      a {
        color: var(--text-link);
        word-break: break-word;
      }
      img {
        max-width: 300px;
        max-height: 300px;
        width: auto;
        height: auto;
      }
    }
    span {
      margin: 0;
      ${Typography.body_md}
      color: var(--text-primary);
    }
  }
  .text {
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: var(--text-primary);
    ${Typography.body_md}
    text-overflow: ellipsis;
    p {
      margin: 0;
      padding: 0;
      ${Typography.body_md_medium}
    }
  }
  .youtube-preview-main-div {
    -webkit-user-modify: read-only;
    border: 1px solid var(--brand-primary);
    border-radius: 8px;
    padding: 8px;
    max-width: 354px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
    background-color: var(--background-primary);
    margin-top: 12px;
    .youtube-preview-image {
      max-width: 354px;
      width: 100%;
      height: auto;
      border-radius: 6px;
      border: 0.5px solid var(--border-primary);
    }
    .e-rte-image.e-imginline {
      vertical-align: baseline;
    }
    .screen-recording-desc-div {
      display: flex;
      align-items: center;
      gap: 6px;
    }
    .youtube-icon {
      width: 20px;
      height: 20px;
    }
  }
  .screen-recording-desc-div {
    display: flex;
    align-items: center;
    gap: 6px;
  }
  .e-rte-image.e-imginline {
    vertical-align: baseline;
  }
  .e-rte-video.e-video-inline {
    max-width: 354px;
  }

  .filediv {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    flex-wrap: wrap;
  }
  .imagediv {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
  }
  .filetext {
    color: var(--text-primary);
    ${Typography.body_md_medium}
    margin-top: 2px;
  }
  .e-img-resize {
    display: none;
  }
  .inlineRTE_imgResize {
    display: none;
  }
  .e-resize,
  .e-img-focus {
    width: 100% !important;
    height: 100% !important;
    border: none !important;
    outline: none !important;
  }
  .video-icon {
    width: 30px !important;
    height: 30px !important;
  }
  .prnt-icon {
    width: 20px !important;
    height: 20px !important;
  }
  .e-richtexteditor .e-rte-content .e-content pre {
    color: var(--text-primary);
    margin: 0;
  }
  ${(props) =>
    props.isTaskDetailComment &&
    css`
      .content {
        gap: 10px;
      }
      .name {
        color: var(--text-primary);
        ${Typography.body_md}
      }
      .time {
        color: var(--text-secondary);
      }
      .text {
        p {
          margin: 0;
          padding: 0;
          ${Typography.body_md}
        }
      }
      .rightdiv {
        gap: 4px;
        word-break: break-all;
      }
      .leftheader {
        gap: 3px;
      }
      .threaddiv {
        margin-bottom: 6px;
        margin-right: 24px;
      }
    `}
  .e-rte-table td,
  .e-rte-table th {
    border: 0.5px solid var(--border-primary);
  }
  @media (max-width: 449px) {
    .screen-recording-name {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      word-break: break-all;
    }
    .e-rte-image.e-imginline {
      padding: 0;
    }
    .open-video-icon {
      margin-bottom: 3px;
    }
    .video-more-icon {
      margin-bottom: 3px;
    }
    .figma-main-div {
      padding: 8px;
      gap: 6px;
      .ant-image .ant-image-img {
        border-radius: 6px !important;
      }
    }
    .figma-icon {
      width: 18px;
      height: 18px;
      margin: 0;
    }
    .figma-header {
      flex-direction: column;
      align-items: baseline;
      gap: 6px;
    }
    .figma-text {
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
    }
    .figma-desc-div {
      align-items: flex-start;
    }
    .figma-link-div {
      margin-left: 22px;
    }
    .figma-animation {
      position: absolute;
      top: 8px;
      right: 8px;
      padding: 0;
    }
    .figma-description {
      margin-right: 25px;
    }
    .text {
      word-break: break-all;
      p {
        img {
          width: 100%;
          border-radius: 4px;
        }
      }
    }
    .header {
      gap: 12px;
    }
    .threaddiv {
      max-width: -webkit-fill-available;
    }
    .rightdiv {
      gap: 4px;
      width: 100%;
    }
    .screen-recording-custom-main-div {
      max-width: -webkit-fill-available;
    }
    .cleanshot-video-main-div {
      max-width: -webkit-fill-available;
    }
    .youtube-preview-main-div {
      max-width: -webkit-fill-available;
    }
    .e-rte-video.e-video-inline {
      max-width: 100%;
    }
  }
`;

const FooterIcon = styled.div<Props>`
  display: flex;
  align-items: center;
  border-radius: 5px;
  ${(props) =>
    props.loading &&
    css`
      pointer-events: none;
    `}
  ${(props) =>
    props.isModel &&
    css`
      position: absolute;
      right: 118px;
      bottom: -57px;
    `}
    .svg {
    fill: var(--text-secondary);
  }
  ${(props) =>
    props.iscreateTask &&
    css`
      display: none;
    `}
  &:hover svg path {
    fill: var(--text-primary);
  }
  ${(props) =>
    props.isContactUsModal &&
    css`
      width: 100%;
      &:hover svg path {
        fill: var(--text-secondary);
      }
    `}
  @media (max-width: 449px) {
    .svg {
      padding: 0;
    }
    ${(props) =>
      props.isModel &&
      css`
        width: max-content;
        bottom: -87px;
        left: 0;
      `}
  }
  .fillColor {
    fill: var(--text-secondary);
    cursor: pointer;
  }
  .fill-Color {
    stroke: var(--text-secondary);
  }
  .attachment-fill-Color {
    fill: var(--text-secondary);
    cursor: pointer;
  }
  ${(props) =>
    props.isActive &&
    css`
      svg path {
        fill: var(--text-primary);
      }
    `}
`;
const SvgDiv = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  .svg {
    path {
      fill: var(--text-secondary);
    }
  }
  :hover {
    background: var(--neutral-secondary);
    border-radius: 50%;
    .svg {
      path {
        fill: var(--text-primary);
      }
    }
  }
  ${(props) =>
    props.isContactUsModal &&
    css`
      &:hover svg path {
        fill: var(--text-primary);
      }
    `}
`;
const Icon = styled.div<{ disable: boolean }>`
  display: flex;
  align-items: center;
  ${(props) =>
    props.disable &&
    css`
      pointer-events: none;
    `}
`;

const FooterIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  .svg-icon {
    cursor: pointer;
  }
  .EmojiPickerReact .epr-header .epr-header-overlay {
    padding: 10px;
  }
  .dropdown-content {
    position: relative;
    top: auto;
    bottom: 0;
    z-index: 999;
  }
  .ant-dropdown .ant-dropdown-menu {
    background: var(--background-primary);
    border-radius: 8px;
    border: 0.5px solid var(--border-primary);
    margin: 0px;
    box-shadow: var(--shadow-modal);
  }
  .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
    ${Typography.body_md}
    color: var(--text-primary);
  }
  .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover {
    background: var(--neutral-secondary);
    border-radius: 0;
  }
`;

const Top = styled.div<Props>`
  height: 14px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  ${(props) =>
    props.ishideicon &&
    css`
      display: none;
    `}
`;

const First = styled.div<Props>`
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .fill-color {
    fill: var(--neutral-secondary);
    :hover {
      fill: var(--background-muted);
      path {
        stroke: var(--text-primary);
      }
    }
  }
  .svg {
    fill: var(--text-secondary);
  }
  &:hover svg path {
    fill: var(--text-primary);
  }
`;

const MainDiv = styled.div<Props>`
  width: 100%;
  /* 108px is the width of the Icons that we have subtracted for comment width */
  ${(props) =>
    props.isApplyMsgModel &&
    css`
      width: calc(100% - 108px);
    `}
  ${(props) =>
    props.isContactUsModal &&
    css`
      display: flex;
      flex-direction: column;
      gap: 24px;
    `}
`;

const Main = styled.div<Props>`
  #inlineRTE_rte-edit-view {
    color: var(--text-primary);

    :focus-visible {
      outline: none;
    }
  }

  ${(props) =>
    props.isApplyMsgModel &&
    css`
      @media (max-width: 449px) {
        padding-right: 0;
        /* .e-richtexteditor:not(.e-rte-toolbar-enabled) {
          padding-right: 108px;
        }
        #inlineRTE_rte-edit-view {
          padding-right: 0 !important;
        } */
      }
    `}
  ${(props) =>
    props.isApplyMinHeight &&
    css`
      #inlineRTE_rte-edit-view {
        min-height: ${props.isApplyMinHeight}px;
      }
    `}
    ${(props) =>
    props.isPaddingright &&
    css`
      #inlineRTE_rte-edit-view {
        padding-right: 40px !important;
        padding-bottom: 5px;
      }
    `}
    ${(props) =>
    props.isApplyMaxHeight &&
    css`
      #inlineRTE_rte-edit-view {
        max-height: ${props.isApplyMaxHeight}px;
        min-height: ${props.isApplyMinHeight}px;
        padding-right: ${props.isContactUsModal ? '28px' : '0px'};
        overflow-y: auto;
        margin-right: ${props.isApplyMargin}px;
        word-break: break-word;
        &::-webkit-scrollbar {
          background-color: var(--background-primary) !important;
          width: 6px !important;
        }
        &::-webkit-scrollbar-thumb {
          background-color: var(--text-tertiary) !important;
          width: 6px !important;
          margin-bottom: 4px !important;
          border-radius: 7px !important;
          min-height: 20px !important;
        }
      }
    `}

  
  .e-richtexteditor.e-rte-tb-expand {
    border: none;
  }
  .e-richtexteditor .e-toolbar-wrapper {
    background: unset !important;
    height: unset !important;
  }
  .e-toolbar {
    background: unset;
  }
  .e-toolbar .e-toolbar-items {
    background: unset;
    @media (max-width: 449px) {
      width: 100%;
      justify-content: space-between;
    }
  }
  .e-richtexteditor.e-rte-tb-expand .e-rte-content,
  .e-richtexteditor.e-rte-tb-expand .e-source-content {
    border-bottom: none;
    border-top-color: var(--border-primary);
  }
  .e-richtexteditor .e-rte-content,
  .e-richtexteditor .e-source-content {
    background: transparent;
  }
  .e-richtexteditor:not(.e-rte-toolbar-enabled) {
    border: none;
  }
  .e-richtexteditor .e-rte-content,
  .e-richtexteditor .e-source-content {
    overflow: unset;
  }

  ${(props) =>
    props.isInlineToolbar &&
    css`
      .e-richtexteditor .rte-placeholder {
        padding: 0;
      }
      .e-richtexteditor .e-rte-content .e-content,
      .e-richtexteditor .e-source-content .e-content {
        padding: 0;
      }
    `}
  ${(props) =>
    props.isScrollbarnone &&
    css`
      .e-richtexteditor .e-rte-content .e-content,
      .e-richtexteditor .e-source-content .e-content {
        ::-webkit-scrollbar {
          display: none;
        }
      }
    `}
  .e-toolbar .e-toolbar-item .e-tbar-btn {
    background: unset;
  }

  .e-toolbar .e-toolbar-item .e-tbar-btn .e-icons {
    color: var(--text-secondary);
    :hover {
      color: var(--text-primary);
    }
  }
  .e-richtexteditor.e-rte-tb-expand .e-rte-content,
  .e-richtexteditor.e-rte-tb-expand .e-source-content {
    border-top: none;
  }
  .e-richtexteditor .e-rte-toolbar {
    border-style: none none solid;
    border-width: 1px;
    border-color: var(--border-primary);
  }
  .e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-tbar-btn:hover .e-icons {
    color: var(--text-primary);
  }
  .e-richtexteditor .e-rte-toolbar .e-toolbar-item.e-active .e-tbar-btn .e-icons {
    color: var(--text-primary);
  }
  .e-richtexteditor .e-rte-toolbar .e-toolbar-item.e-active .e-tbar-btn {
    background: var(--neutral-secondary);
  }
  .e-toolbar .e-toolbar-item .e-tbar-btn {
    min-height: unset;
    min-width: unset;
    padding: 2px !important;
    :hover {
      background: var(--neutral-secondary);
    }
  }

  .e-richtexteditor .e-rte-toolbar.e-rte-tb-fixed.e-show,
  .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar.e-rte-tb-fixed.e-show {
    @media (max-width: 449px) {
      display: none;
    }
  }
  .e-toolbar .e-toolbar-item:not(.e-separator) {
    padding: 0 6px;
  }
  .e-rte-toolbar .e-undo::before {
    font-size: 14px;
  }

  .e-rte-toolbar .e-redo::before {
    font-size: 14px;
  }
  &.e-btn-icon .e-undo .e-icons {
    line-height: 14px !important;
  }
  .e-toolbar .e-toolbar-item.e-overlay {
    background: none;
  }
  .e-toolbar .e-toolbar-item .e-tbar-btn .e-icons.e-btn-icon {
    line-height: 16px;
  }
  #inlineRTE_toolbar_Undo {
    width: 20px !important;
    height: 20px !important;
  }
  #inlineRTE_toolbar_Redo {
    width: 20px !important;
    height: 20px !important;
  }
  .e-dialog {
    background: var(--background-primary);
    border: 0.5px solid var(--border-primary);
    top: 34px !important;
  }
  .e-dialog .e-dlg-header-content {
    border-bottom: 0.5px solid var(--border-primary);
  }
  .e-dialog .e-footer-content {
    border-top: 0.5px solid var(--border-primary);
  }
  .e-dlg-content {
    background: var(--background-primary);
    color: var(--text-primary);
  }
  .e-rte-linkcontent .e-rte-label {
    margin-bottom: 6px !important;
  }
  .e-dlg-header {
    color: var(--text-primary);
  }
  input.e-input {
    background: var(--background-primary);
    color: var(--text-primary);
    border: 0.5px solid var(--border-primary);
    ::placeholder {
      color: var(--border-secondary);
    }
  }
  .e-input:focus:not(.e-success):not(.e-warning):not(.e-error) {
    box-shadow: none;
    border: 1px solid var(--brand-primary);
  }

  .e-checkbox-wrapper .e-label {
    color: var(--text-primary);
  }
  .e-checkbox-wrapper .e-checkbox:focus + .e-frame.e-check {
    box-shadow: unset;
    background-color: var(--brand-primary);
    border-color: var(--brand-primary);
  }
  .e-checkbox-wrapper .e-checkbox:focus + .e-frame {
    border-color: var(--text-secondary);
    box-shadow: unset;
    background-color: var(--background-primary);
  }
  .e-checkbox-wrapper:hover .e-frame {
    background-color: var(--background-primary);
  }
  .e-checkbox-wrapper .e-frame {
    background-color: var(--background-primary);
    border-color: var(--text-secondary);
  }
  .e-richtexteditor .e-rte-content .e-content a {
    color: var(--text-secondary);
    text-decoration: underline;
  }
  .e-richtexteditor .e-rte-content .e-content a:hover {
    text-decoration: underline;
  }
  .e-icon-dlg-close {
    color: var(--text-primary);
  }
  .e-dialog .e-btn.e-dlg-closeicon-btn:hover span {
    color: var(--text-primary);
  }
  .e-checkbox-wrapper .e-frame.e-check {
    background-color: var(--brand-primary);
    border-color: var(--brand-primary);
  }
  .e-footer-content .e-btn.e-primary.e-flat:not([DISABLED]) {
    background-color: var(--brand-primary);
    border-color: var(--brand-primary);
    border-radius: 36px;
    color: var(--text-white);
    border: 0.5px solid var(--brand-primary);

    :hover {
      background-color: var(--brand-primary-hover);
      border: 0.5px solid var(--brand-primary-hover);
    }
  }
  .e-btn {
    ${Typography.body_md_medium}
    padding: 8px 20px;
  }
  .e-footer-content .e-btn.e-flat:not([DISABLED]) {
    background-color: var(--background-primary);
    color: var(--text-primary);
    box-shadow: var(--shadow-button);
    border: 0.5px solid var(--border-primary);
    border-radius: 36px;

    &:hover {
      background-color: var(--background-secondary);
    }
  }
  .e-checkbox-wrapper .e-checkbox:active + .e-frame.e-check {
    background-color: var(--brand-primary);
    box-shadow: unset;
    border-color: var(--brand-primary);
    color: var(--text-primary);
  }
  ${(props) =>
    props.isplaceholder &&
    css`
      .e-richtexteditor .rte-placeholder {
        padding: 5px 0 0 0;
        ${Typography.body_lg}
      }
    `}
  @media  (max-width: 449px) {
    ${(props) =>
      props.isResponsiveMinHeight &&
      css`
        #inlineRTE_rte-edit-view {
          min-height: ${props.isResponsiveMinHeight}px;
        }
      `}
  }
`;
const FooterDiv = styled.div<Props>`
  ${(props) =>
    props.isInlineToolbar &&
    css`
      padding: 0 16px;
    `}
`;
const Second = styled.div<Props>`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg path {
    fill: var(--text-secondary);
  }
  &:hover svg path {
    fill: var(--text-primary);
  }
  ${(props) =>
    props.isActive &&
    css`
      svg path {
        fill: var(--text-primary);
      }
    `}
`;

const Record = styled.div<Props>`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 0.5px solid transparent;
  path.fill-color {
    fill: var(--text-secondary);
  }
  path.stroke-color {
    stroke: var(--text-secondary);
  }
  &:hover {
    background: var(--neutral-secondary);
    border-radius: 50%;
    .svg-icon {
      path.stroke-color {
        stroke: var(--brand-primary);
      }
      path.fill-color {
        fill: var(--brand-primary);
      }
    }
  }
`;
const Stop = styled.div<Props>`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 0.5px solid transparent;
  .stop-icon {
    path.stroke-color {
      stroke: var(--accent-error);
    }
    path.both-color {
      stroke: var(--accent-error);
      fill: var(--accent-error);
    }
  }
  :hover {
    background: var(--neutral-secondary);
    border-radius: 50%;
  }
`;

const DummyDiv = styled.div`
  height: 75px;
`;

const FileIconDiv = styled.div`
  position: relative;
  display: flex;
  .file-text {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 50%;
    font-weight: 700;
    color: var(--text-secondary);
  }
`;

const UploadDiv = styled.div<Props>`
  color: var(--text-primary);
  ${(props) =>
    props.iscommentModelPadding &&
    css`
      padding-left: 16px;
    `}
`;

const ButtonComponent = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
`;

const ButtonLeft = styled.div`
  width: 100%;
  display: flex;
  /* justify-content: flex-end; */
  gap: 12px;
  align-items: center;
`;

const ButtonRight = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
`;

const ReplyMessageDiv = styled.div`
  /* css for file card ui in threaded message */
  .filediv {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 5px;
    flex-wrap: wrap;
    align-items: center;
  }
  .imagediv {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
  }

  .filetext {
    color: var(--text-primary);
    ${Typography.body_md_medium}
    margin-top: 2px;
  }
  .textblock img {
    width: 100%;
  }
`;

export {
  MentionTable,
  MentionList,
  FileBox,
  FileBoxContainer,
  CloseButton,
  TextMainDiv,
  FooterIcon,
  Icon,
  FooterIcons,
  Top,
  First,
  MainDiv,
  Main,
  FooterDiv,
  Second,
  DummyDiv,
  Record,
  Stop,
  FileIconDiv,
  UploadDiv,
  SvgDiv,
  ButtonComponent,
  ButtonLeft,
  ButtonRight,
  ReplyMessageDiv
};
