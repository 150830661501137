import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { GifItem, Input, ModalBox } from './styles';
import EmptyState from '../../emptyState';
import gifdarkempty from '../../../assets/images/emptystates/empty-gifdark.svg';
import gifempty from '../../../assets/images/emptystates/empty-gif.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getSearchedGIF } from '../../../services/chatMessageService';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import { isEmpty } from '../../../helpers/common';

function InsertGifModal({
  setSelectedGif,
  closeModal
}: {
  setSelectedGif: (url: string) => void;
  closeModal: () => void;
}) {
  // Ref variable
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  //states
  const [searchValue, setSearchValue] = useState<string>('');

  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { app: appSelector, settings: settingsSelector } = stateSelector || {};
  const { searchedGif } = appSelector || {};
  const { themeMode } = settingsSelector;

  //other variables
  const dispatch = useDispatch();
  const getImageSource = () => (themeMode?.theme === 'dark' ? gifdarkempty : gifempty);

  const loadData = useCallback(async () => {
    await dispatch(getSearchedGIF(''));
  }, [dispatch]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSearchInput = useCallback(
    (value: string) => {
      setSearchValue(value);

      // Clear the previous timeout if it exists
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      // Set a new timeout
      timeoutRef.current = setTimeout(() => {
        dispatch(getSearchedGIF(value));
      }, 1000);
    },
    [dispatch]
  );

  const handleSelectGif = useCallback(
    (url: string) => {
      setSelectedGif(url);
      closeModal();
    },
    [closeModal, setSelectedGif]
  );

  const renderGifItem = useMemo(() => {
    return (
      <GifItem>
        {searchedGif &&
          searchedGif?.length > 0 &&
          searchedGif?.map((gif) => (
            <div key={gif} className='gif-container' onClick={() => handleSelectGif(gif)}>
              <img src={gif} alt='GIF' />
            </div>
          ))}
      </GifItem>
    );
  }, [handleSelectGif, searchedGif]);

  return (
    <ModalBox>
      <Input
        placeholder='Search for a gif'
        value={searchValue}
        onChange={(e) => handleSearchInput(e.target.value)}></Input>
      {renderGifItem}
      {isEmpty(searchedGif) && (
        <EmptyState
          hideButton
          header='No results found'
          title='Try changing your search term.'
          image={getImageSource()}
        />
      )}
    </ModalBox>
  );
}

export default InsertGifModal;
