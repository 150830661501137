import React from 'react';
import { useSelector } from 'react-redux';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';

function AppLogo(props: { height: string; width?: string }) {
  const { height } = props;
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { app: appSelector } = stateSelector || {};
  const { customBranding } = appSelector;
  //Other variables
  const defaultLogo =
    'https://images.ctfassets.net/6v3c95narsix/4meyMh26aF5gp7IGa3WUmp/028b802e6183801e3b809eca10644fdb/Union.svg';

  return customBranding?.logo?.url ? (
    <img src={customBranding?.logo?.url} alt='logo' height={height} />
  ) : (
    <img src={defaultLogo} alt='teamcamp-logo' height='60' width='60' />
  );
}

export default AppLogo;
