import React, { useCallback, useEffect, useState } from 'react';
import AppLayout from '../../../component/appLayout';
import {
  Bottom,
  Box,
  Buttons,
  Content,
  Form,
  Group,
  Header,
  Icon,
  Inputvalue,
  Label,
  Personaltext,
  Socialicon,
  Total
} from './styles';
import Button from '../../../component/button';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import Referbox from '../../../component/referbox';
import { useDispatch, useSelector } from 'react-redux';
import { getReferralDetails, getUserReferCode } from '../../../services/settingServices';
import copy from 'clipboard-copy';
import { setSuccessMessage } from '../../../actions/messageActions';
import { captureException } from '../../../services/logService';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import { PersonalHeader } from '../apps/styles';
import { ResponsiveHeader } from '../SettingHeaderResponsive';
import { useMobileDevice } from '../../../global/useMobile';

const Referearn: React.FC = () => {
  // States
  const [referralLink, setReferralLink] = useState('');
  //use selector state variables
  const settingsSelector = useSelector((state: RootReducerInterface) => state.settings);
  const { referralDetails } = settingsSelector;
  // Other variables
  const dispatch = useDispatch();
  const isMobile = useMobileDevice();

  const loadData = useCallback(async () => {
    let refCode;
    refCode = await dispatch(getUserReferCode());
    await dispatch(getReferralDetails());
    setReferralLink(`https://www.teamcamp.app/?ref=${refCode || ''}`);
  }, [dispatch]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onCopyLink = useCallback(
    async (url: string) => {
      try {
        await copy(url);
        dispatch(setSuccessMessage('Referral Link copied successfully!'));
      } catch (error) {
        captureException(error);
        console.log('Failed to copy url: ', error);
      }
    },
    [dispatch]
  );

  return (
    <div>
      <AppLayout>
        <Group>
          <Header>
            <PersonalHeader>
              {isMobile && <ResponsiveHeader />}
              <Personaltext>Refer & Earn</Personaltext>
            </PersonalHeader>
            <Button
              title='Learn More'
              secondary={true}
              className='button'
              onClick={() => window.open('https://www.teamcamp.app/affiliate', '_blank', 'noopener,noreferrer')}
              hasNotBoxshadow
              modelbtn
            />
          </Header>
          <Bottom>
            <Box>
              <Content>
                <Form>
                  <Label>Copy & Share your Referral Link</Label>
                  <Inputvalue type='text' value={referralLink} />
                </Form>
                <Buttons>
                  <Button title='Copy Link' onClick={() => onCopyLink(referralLink)} modelbtn />
                  <Socialicon>
                    <Icon
                      onClick={() =>
                        window.open(
                          `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(referralLink)}`,
                          '_blank'
                        )
                      }>
                      <SVGIcon
                        name='social-facebook-icon'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        className='social-icon facebook-icon'
                      />
                    </Icon>
                    <Icon
                      onClick={() =>
                        window.open(
                          `mailto:?subject=${encodeURIComponent('Teamcamp')}&body=${encodeURIComponent(referralLink)}`,
                          '_blank'
                        )
                      }>
                      <SVGIcon
                        name='social-email-icon'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        className='social-icon email-icon'
                      />
                    </Icon>
                    <Icon
                      onClick={() =>
                        window.open(
                          `https://twitter.com/intent/tweet?url=${encodeURIComponent(
                            referralLink
                          )}&text=${encodeURIComponent('Teamcamp')}`,
                          '_blank'
                        )
                      }>
                      <SVGIcon
                        name='social-twitter-icon'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        className='social-icon twitter-icon'
                      />
                    </Icon>
                  </Socialicon>
                </Buttons>
              </Content>
            </Box>
            <Total>
              <Referbox title={'Referred Signed up'} price={referralDetails?.totalReferrals} />
              <Referbox title={'Referred Customers'} price={0} />
              <Referbox title={'Total Earned Credits'} price={'$0'} />
            </Total>
          </Bottom>
        </Group>
      </AppLayout>
    </div>
  );
};

export default Referearn;
