import styled from 'styled-components';
import { Typography } from '../../../global/theme';

const UserProfileModal = styled.div``;
const ProfileSection = styled.div`
  padding: 12px 12px 0 16px;
  display: flex;
  justify-content: space-between;
`;
const Profile = styled.div``;
const NameSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;
const Name = styled.p`
  ${Typography.body_lg_medium};
  color: var(--text-primary);
  margin: 0;
`;
const CopyIcon = styled.div`
  display: none;
  cursor: pointer;
  .copy-icon {
    stroke: var(--text-secondary);
  }
  :hover {
    .copy-icon {
      stroke: var(--text-primary);
    }
  }
`;
const EmailSection = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  :hover ${CopyIcon} {
    display: flex;
  }
`;
const CloseIcon = styled.div`
  padding: 3px;
  width: 14px;
  height: 14px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    path {
      stroke: var(--text-secondary);
    }
  }
  :hover {
    background-color: var(--neutral-secondary);
    svg {
      path {
        stroke: var(--text-primary);
      }
    }
  }
`;
const RedirectSection = styled.div`
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  .button {
    width: 100%;
  }
`;
const ButtonSection = styled.div``;
const TimeAndStatusSection = styled.div`
  border-top: 0.5px solid var(--border-primary);
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Status = styled.p`
  ${Typography.body_sm_medium};
  color: var(--accent-success);
  margin: 0;
`;
const BulletIcon = styled.div`
  background-color: var(--text-tertiary);
  border-radius: 10px;
  width: 4px;
  height: 4px;
  margin: 0 8px;
`;
const Time = styled.p`
  ${Typography.body_sm};
  color: var(--text-secondary);
  margin: 0;
`;
const UserInfo = styled.div`
  display: flex;
  gap: 6px;
  margin-top: 4px;
`;
const Email = styled.p`
  ${Typography.body_md};
  color: var(--text-secondary);
  margin: 0;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export {
  UserProfileModal,
  ProfileSection,
  BulletIcon,
  ButtonSection,
  CloseIcon,
  CopyIcon,
  EmailSection,
  Name,
  NameSection,
  Profile,
  RedirectSection,
  Status,
  Time,
  TimeAndStatusSection,
  UserInfo,
  Email
};
