import React, { useCallback, useState } from 'react';
import {
  ButtonDiv,
  Description,
  Form,
  Icon,
  InputArea,
  MainModal,
  ModalBottom,
  ModalHead,
  ModalTitle,
  VerificationBox,
  VerificationCode
} from './style';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import Button from '../../button';
import { useSelector, useDispatch } from 'react-redux';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import { deleteWorkspace, sendDeleteRequest } from '../../../services/workspaceService';
import { setErrorMessage } from '../../../actions/messageActions';
import { captureException } from '../../../services/logService';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../../routes/urls';
import { setCurrentWorkspace } from '../../../actions/workspaceActions';
import { setWorksapceIdInAxios } from '../../../helpers/authTokenHelpers';
import { workspaceSetAnalytics } from '../../../services/analyticsService';
import { isDev } from '../../../helpers/common';
import { updateUserPreference } from '../../../helpers/firebaseHelper';
import { clearPreviousWorkspaceData } from '../../../services/baseServices';
import { appInit } from '../../../services/appService';
import { rootStore } from '../../../mobx/rootStore';

interface Props {
  onClose: () => void;
}

export const VerificationModal = ({ onClose }: Props) => {
  //States
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [loading, setLoading] = useState(false);
  //use selector state variables
  const workspaceSelector = useSelector((state: RootReducerInterface) => state.workspace);
  const { workspace, list } = workspaceSelector;
  const { id: workspace_id } = workspace;
  //Other variable
  const dispatch = useDispatch();
  const history = useHistory();

  const handleDeleteWorkspace = useCallback(
    async (e: React.SyntheticEvent) => {
      e.preventDefault();
      try {
        setLoading(true);
        if (!verificationCode) {
          dispatch(setErrorMessage('Please enter a verification code'));
          return;
        }
        const result = await dispatch(deleteWorkspace(workspace_id, verificationCode));
        if (result) {
          history.push(ROUTES.SELECT_WORKSPACE);
          dispatch(setCurrentWorkspace(list[0]));
          rootStore.workspaceStore.setCurrentWorkspace(list[0]);
          setWorksapceIdInAxios(list[0]?.id);
          workspaceSetAnalytics(list[0]?.id);
          if (!isDev()) await updateUserPreference({ current_workspace_id: list[0]?.id });
          await dispatch(clearPreviousWorkspaceData());
          await dispatch(appInit());
        }
      } catch (e) {
        captureException(e);
        console.log('ERROR', e);
        dispatch(setErrorMessage('An error occurred while deleting the workspace. Please try again.'));
      } finally {
        setLoading(false);
      }
    },
    [verificationCode, dispatch, workspace_id, list, history]
  );

  const handleResendCode = useCallback(async () => {
    try {
      await dispatch(sendDeleteRequest(true));
    } catch (e) {
      captureException(e);
      console.log('ERROR', e);
      dispatch(setErrorMessage('Failed to resend verification code. Please try again.'));
    }
  }, [dispatch]);

  return (
    <>
      <MainModal>
        <ModalHead>
          <ModalTitle>Verification</ModalTitle>
          <Icon onClick={onClose}>
            <SVGIcon
              name='modal-cross-sign-icon'
              width='18'
              height='18'
              viewBox='0 0 18 18'
              fill='none'
              className='fillColor'
            />
          </Icon>
        </ModalHead>
        <ModalBottom>
          <Form>
            <Description>
              A verification code is sent to your Email Address. You need to enter it and get verified.
            </Description>
            <VerificationBox>
              <InputArea
                placeholder='Enter Verification Code'
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
              />
              <VerificationCode onClick={handleResendCode}>Resend code</VerificationCode>{' '}
            </VerificationBox>
          </Form>
          <ButtonDiv>
            <Button title='Cancel' secondary modelbtn onClick={onClose} isLoading={false} />
            <Button
              title='Delete workspace'
              type='submit'
              isDelete
              modelbtn
              isLoading={loading}
              onClick={handleDeleteWorkspace}
            />
          </ButtonDiv>
        </ModalBottom>
      </MainModal>
    </>
  );
};
