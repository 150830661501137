import styled from 'styled-components';

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export { Icon };
