import React, { useCallback, useMemo } from 'react';
import { COMMENT_TYPE, priorityList, TASK_PRIORITY } from '../../../global/constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  setOtherTaskList,
  updatSubTaskList,
  updateCreateTaskInput,
  updateFilteredTasksList,
  updateMemberTaskField,
  updateMyTaskDetailsData,
  updateMyTaskField,
  updateTaskField
} from '../../../actions/taskActions';
import {
  createNewComment,
  getMyTaskDetailsData,
  updateTaskLocalData,
  updateRecurringTaskDetails,
  updateTaskDetails,
  loadMyTaskData
} from '../../../services/taskServices';
import { ProjectDetailInterface } from '../../../interfaces/ProjectInterface';
import { ProjectTemplateDetailsInterface } from '../../../interfaces/SettingsInterface';
import { updateProjectTemplateDetails, updateTaskTemplateData } from '../../../actions/settingActions';
import { trackAnalyticActivity } from '../../../services/analyticsService';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import { TASK_ANALYTICS } from '../../../global/analyticsConstants';
import {
  FilteredCompletedTasksListInterface,
  FilteredTasksListInterface,
  TaskDetailsInterface,
  UpdateCommentReducer
} from '../../../interfaces/TaskInterface';
import Dropdown from '../../Dropdown/MainDropdown';
import DropdownButton from '../../Dropdown/DropdownButton';
import DropdownItem from '../../Dropdown/DropdownItem';
import { ButtonIcon } from './styles';

interface Props {
  taskData: any;
  icon?: boolean;
  myTask?: boolean;
  isfont?: boolean;
  currentProject?: ProjectDetailInterface | ProjectTemplateDetailsInterface;
  loadData?: () => void;
  isTaskItem?: boolean;
  isFeature?: boolean;
  isBorder?: boolean;
  isTemplateProject?: boolean;
  isSubTask?: boolean;
  isFromMultiSelect?: boolean;
  isRecurringTask?: boolean;
  isCalendarviewCard?: boolean;
  isCalendarviewtasklist?: boolean;
  isTaskTemplate?: boolean;
  updateSortedComment?: (value: UpdateCommentReducer) => void;
  taskGroup?: FilteredTasksListInterface | FilteredCompletedTasksListInterface;
  isTaskCheckBox?: boolean;
  isCreateTask?: boolean;
  isHoverIcon?: boolean;
  isMember?: boolean;
}

const Prioritydropdown: React.FC<Props> = (props) => {
  const { taskData, isRecurringTask, updateSortedComment, isTaskCheckBox, isCreateTask, isHoverIcon, isMember } = props;

  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { settings: settingsSelector, task: taskSelector } = stateSelector || {};
  const { projectTemplateDetails } = settingsSelector;
  const { otherTask } = taskSelector;
  //Other variable
  const dispatch = useDispatch();

  // get priority object
  const getPriorityData = useCallback((key: number) => {
    return priorityList.find((item) => item.key === key);
  }, []);

  // modify project template info
  const updateTemplateProjectData = useCallback(
    (priority: number) => {
      const updatedTasks = projectTemplateDetails?.tasks?.map((x) => {
        if (x?.id === taskData?.id) {
          return { ...x, priority };
        } else {
          return x;
        }
      });
      dispatch(updateProjectTemplateDetails({ propsName: 'tasks', value: updatedTasks }));
    },
    [dispatch, projectTemplateDetails?.tasks, taskData?.id]
  );

  // update task priority function
  const updateTaskPriority = useCallback(
    async (key: number) => {
      if (props?.isTemplateProject) {
        // Update priority for a template project
        updateTemplateProjectData(Number(key));
      } else if (props?.isCreateTask) {
        // Update priority during task creation
        if (props?.isTaskTemplate) {
          // For task templates
          dispatch(updateTaskTemplateData({ propsName: 'priority', value: Number(key) }));
        } else {
          // For standard tasks
          dispatch(updateCreateTaskInput({ propsName: 'priority', value: Number(key) }));
        }
        return;
      } else if (Number(key) !== props?.taskData?.priority && !isRecurringTask) {
        // Update priority for non-recurring tasks
        if (!props.icon) {
          dispatch(updateMyTaskDetailsData({ propsName: 'priority', value: key }));
        }

        if (props.icon && props?.taskData?.parentTaskId) {
          // Update subtask priority
          const taskDetailsClone = JSON.parse(JSON.stringify(props?.taskData));
          taskDetailsClone.priority = Number(key);
          dispatch(updatSubTaskList(taskDetailsClone));
        }

        const updatedTaskData = {
          taskId: props?.taskData?.id,
          key: 'priority',
          value: Number(key)
        };

        // Dispatch updates for tasks and subtasks
        props.icon && !props?.isSubTask && dispatch(updateTaskField(updatedTaskData));
        if (props?.myTask && !props?.isRecurringTask) {
          dispatch(updateMyTaskField(updatedTaskData));
          const updatedData = { ...taskData, priority: Number(key) };
          dispatch(updateTaskLocalData(updatedData));
          await dispatch(loadMyTaskData(false));
        }
        if (isMember) {
          dispatch(updateMemberTaskField(updatedTaskData));
        }

        // Send API request to update task details and add a comment
        const payloadTask = { priority: Number(key), projectId: props?.taskData?.projectId };
        const result = await dispatch(updateTaskDetails(props?.taskData?.id, payloadTask));
        const commentResult = await dispatch(
          createNewComment(props?.taskData?.id, {
            Type: COMMENT_TYPE.CHANGE_PRIORITY,
            PreviousPriority: props?.taskData?.priority,
            Priority: Number(key)
          })
        );

        if (result && !props.myTask && commentResult) {
          // Track priority change and update UI
          trackAnalyticActivity(TASK_ANALYTICS.PRIORITY_CHANGED);
          if (props?.isSubTask && !props?.isTaskItem) {
            const taskDetailsClone = JSON.parse(JSON.stringify(props?.taskData));
            taskDetailsClone.priority = Number(key);
            dispatch(updatSubTaskList(taskDetailsClone));
          }
          if (props?.isSubTask && props?.isTaskItem) {
            const taskDetailsClone = JSON.parse(JSON.stringify(props?.taskData));
            taskDetailsClone.priority = Number(key);
            if (props?.taskGroup) {
              let groupData = JSON.parse(JSON.stringify(props?.taskGroup || {}));
              const parentTask = groupData?.tasks?.map((obj: TaskDetailsInterface) => {
                if (obj.id === taskDetailsClone.parentTaskId) {
                  // Update the subtask list in the parent task
                  obj.subTasks = obj.subTasks?.map((subTask: TaskDetailsInterface) =>
                    subTask.id === taskDetailsClone.id ? taskDetailsClone : subTask
                  );
                }
                return obj;
              });
              groupData.tasks = parentTask;
              dispatch(updateFilteredTasksList(groupData));
            } else {
              const updatedOtherTask = {
                ...(otherTask || {}),
                tasks: otherTask?.tasks?.map((obj) => (obj?.id === taskDetailsClone?.id ? taskDetailsClone : obj))
              };
              dispatch(setOtherTaskList(updatedOtherTask));
            }
          }
          if (updateSortedComment) {
            const newComment = {
              type: COMMENT_TYPE.CHANGE_PRIORITY,
              previousPriority: props?.taskData?.priority,
              priority: Number(key)
            };
            const updatedComment = updateSortedComment(newComment);
            dispatch(
              updateMyTaskDetailsData({
                propsName: 'updatedComments',
                value: updatedComment
              })
            );
          }

          // Reload data if applicable
          !props?.isFeature &&
            !props.icon &&
            !props?.isSubTask &&
            (await dispatch(getMyTaskDetailsData(props?.taskData?.id)));
          !props?.isFeature && props.icon && props?.loadData && props.loadData();
        }
      } else if (isRecurringTask) {
        // Update priority for recurring tasks
        const payload = { priority: Number(key), projectId: props?.taskData?.projectId };
        const response = await dispatch(updateRecurringTaskDetails(props?.taskData.id, payload));
        if (response) {
          trackAnalyticActivity(TASK_ANALYTICS.PRIORITY_CHANGED);
        }
        // Reload data if applicable
        !props?.isFeature && props.icon && props?.loadData && props.loadData();
      }
    },
    [dispatch, isMember, isRecurringTask, otherTask, props, taskData, updateSortedComment, updateTemplateProjectData]
  );

  // render priority data
  const renderPriorityData = useMemo(() => {
    return priorityList.map((priorityData) => (
      <DropdownItem
        label={priorityData.name}
        key={priorityData.key}
        iconName={priorityData.iconName}
        onClick={() => updateTaskPriority(priorityData.key)}
        isSelected={props.taskData?.priority === priorityData.key} // Check if the priority is selected
      />
    ));
  }, [updateTaskPriority, props.taskData?.priority]); // Add `props.taskData?.priority` as a dependency

  // render priority variant
  const renderPriorityVariant = useMemo(() => {
    if (isCreateTask) return 'border';
    if (isTaskCheckBox) return 'no-border';
    return 'hover-border';
  }, [isCreateTask, isTaskCheckBox]);

  return (
    <>
      <Dropdown
        content={
          <ButtonIcon isHoverIcon={isHoverIcon}>
            <DropdownButton
              iconName={getPriorityData(props?.taskData?.priority || TASK_PRIORITY.NO_PRIORITY)?.iconName}
              title={!isTaskCheckBox ? getPriorityData(props?.taskData?.priority)?.name : ''}
              variant={renderPriorityVariant}
              isActive={getPriorityData(props?.taskData?.priority)?.name ? true : false}
              size={isCreateTask ? 'small' : 'medium'}
            />
          </ButtonIcon>
        }
        trigger='click'
        modalTitle='Priority'>
        {renderPriorityData}
      </Dropdown>
    </>
  );
};

export default Prioritydropdown;
