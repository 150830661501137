export const ROUTES = {
  LOGIN: '/membership/login',
  SIGNUP: '/membership/signup',
  FORGOT_PASSWORD: '/membership/forgotpassword',
  DELETE_USER: '/membership/deleteUser',
  WORKSPACE: '/onboarding/workspace',
  MOBILEONBORDING: '/onboarding/mobileonboarding',
  ONBOARDING_STEP2: '/onboarding/step2',
  WELCOME: '/welcome',
  SELECT_WORKSPACE: '/select-workspace',
  MEMBERSHIP_INVITATION: '/membership/invitation/:id',
  RESET_PASSWORD: '/membership/reset-password',
  // With Navbar
  WORKSPACE_REDIRECT: '/:workspace_id',

  MYTASK: '/my-tasks',
  NAVBARMESSAGE: '/messages',
  NAVBARMESSAGE_DETAILS_MOBILE: '/messages/details',
  SNAP: '/snap',
  INVOICES: '/invoices',
  ADD_NEW_INVOICES: '/invoices/create-tracked-invoice',
  ADD_NEW_INVOICES_2: '/invoices/create-blank-invoice',
  EDIT_INVOICES: '/invoices/update/:invoice_id',
  ALL_PROJECT: '/projects',
  FAVOURITE_PROJECT: '/projects/favourite',
  PERSONAL_SETTING: '/settings/personal',
  PASSWORD_SETTING: '/settings/password',
  EMAIL_SETTING: '/settings/email',
  NOTIFICATION_SETTING: '/settings/notification',
  SECURITY_ACCESS_SETTING: '/settings/security-access',
  GENERAL_SETTING: '/settings/general',
  TEMPLATES_SETTING: '/settings/templates',
  TEMPLATES_SETTING_DETAILS: '/settings/templates/:id',
  PAYMENT_SETTING: '/settings/payment',
  PAYMENT_STRIPE_SETTING: '/settings/payment/stripe',
  FIGMA_CONNECT_REDIRECT: '/integration/figma/fallback',
  SUBSCRIPTION_SETTING: '/settings/subscription',
  APPS_SETTING: '/settings/apps',
  API_SETTING: '/settings/apikey',
  INTEGRATIONS_SETTING: '/settings/integrations',
  TEAM_SETTING: '/settings/team',
  INVOICE_SETTING: '/settings/invoice',
  TIME_SETTING: '/settings/time',
  REFEREARN_SETTING: '/settings/referEarn',
  BRANDDOMAIN_SETTING: '/settings/custom-domain',
  USAGE_SETTING: '/settings/usage',
  INBOX: '/',
  CUSTOMER: '/customer',
  CUSTOMERDETAIL: '/customer/customerDetail',
  TIMETRACKING: '/timeTracking',
  REPORT: '/report',
  REPORT_PAYMENTS: '/report/payments',
  REPORT_UNINVOICED: '/report/uninvoiced',
  REPORT_INVOICE: '/report/report_invoice',
  REPORT_PROJECTS: '/report/projects',
  REPORT_TASK: '/report/task',
  REPORT_TEAM_TIMELINE: '/report/team_timeline',
  REPORT_PROJECTS_TIMELINE: '/report/project_timeline',
  ARCHIVE_PROJECT: '/projects/archived',
  REPORT_TIMES: '/report/times',
  RECURRINGTASK: '/recurring-tasks',
  MY_TIMELOG: '/timeTracking/myTimelog',
  SEARCH: '/search',
  SETTING: '/setting',
  HOME: '/home',
  TASK_TEMPLATES_SETTING_DETAILS: '/settings/template/taskTemplateDetails/:id',
  INTEGRATIONS_DETAILS: '/settings/integration/github',
  GITHUB_CALLBACK: '/settings/integration/github/callback',
  SENTRY_CALLBACK: '/settings/integration/sentry/callback'
};
