import React, { useCallback, useRef, useState } from 'react';
import { Icon, Main, Table, First, Moreicon, FileIconDiv, ResponsiveDiv, DocumtentTitle, DocCreated } from './styles';
import SVGIcon from '../../assets/images/svg/SVGIcon';
import { calculateDay, extensionName } from '../../helpers/common';
import FileSVGIcon from '../../assets/images/svg/filesIconSvg';
import { FILE_EXTENTIONS, FILE_TYPES, FILES_MENU_OPTIONS } from '../../global/constants';
import { ProjectFileData, ProjectFilesInterface } from '../../interfaces/ProjectInterface';
import { useMobileDevice } from '../../global/useMobile';
import CustomDropdown from '../dropdowns/customDropdown';

interface Props {
  filesList: ProjectFilesInterface[];
  onClickFile: (item: ProjectFilesInterface) => void;
  onClickRename: () => void;
  OpenModal: (item: ProjectFilesInterface) => void;
  Opendeletemodel: (item: ProjectFilesInterface) => void;
  setSelectedItem: (item: ProjectFileData) => void;
  selectedItem: ProjectFileData | null;
}

const FileListView: React.FC<Props> = (props) => {
  const { filesList, onClickFile, onClickRename, OpenModal, Opendeletemodel, selectedItem, setSelectedItem } = props;
  //Refs
  const buttonRef = useRef<React.RefObject<HTMLDivElement>[]>([]);
  //States
  const [openMilestoneMenu, setOpenMilestoneMenu] = useState(false);
  const [openDropdownId, setOpenDropdownId] = useState<string | null>(null);
  //Other variable
  const isMobile = useMobileDevice();
  const menuItemsWithDownload = [
    { key: FILES_MENU_OPTIONS.VIEW, label: 'View' },
    {
      key: FILES_MENU_OPTIONS.DOWNLOAD,
      label: ' Download'
    },
    { key: FILES_MENU_OPTIONS.RENAME, label: 'Rename' },
    { key: FILES_MENU_OPTIONS.MOVETOFOLDER, label: 'Move to folder' },
    { key: FILES_MENU_OPTIONS.DELETE, label: 'Delete', className: 'red-text' }
  ];
  const menuItems = [
    { key: FILES_MENU_OPTIONS.VIEW, label: 'View' },
    { key: FILES_MENU_OPTIONS.RENAME, label: 'Rename' },
    { key: FILES_MENU_OPTIONS.MOVETOFOLDER, label: 'Move to folder' },
    { key: FILES_MENU_OPTIONS.DELETE, label: 'Delete', className: 'red-text' }
  ];

  const openMilestoneDropdown = useCallback(
    (e: React.SyntheticEvent, item: ProjectFilesInterface) => {
      e.stopPropagation();
      const { id, name, uploadFileType, fileType } = item;
      setSelectedItem({ id, name, uploadFileType, fileType });
      if (openDropdownId === item?.id) {
        setOpenMilestoneMenu(false);
        setOpenDropdownId(null);
      } else {
        setOpenMilestoneMenu(true);
        setOpenDropdownId(item?.id);
      }
    },
    [openDropdownId, setSelectedItem]
  );

  const handleMenuClick = (key: number | string, item: ProjectFilesInterface) => {
    key = Number(key);
    if (key === FILES_MENU_OPTIONS.VIEW) {
      onClickFile(item);
    } else if (key === FILES_MENU_OPTIONS.RENAME) {
      if (onClickRename) onClickRename();
    } else if (key === FILES_MENU_OPTIONS.MOVETOFOLDER) {
      if (OpenModal) OpenModal(item);
    } else if (key === FILES_MENU_OPTIONS.DELETE) {
      if (Opendeletemodel) Opendeletemodel(item);
    } else if (key === FILES_MENU_OPTIONS.DOWNLOAD) {
      if (item?.url) {
        const link = document.createElement('a');
        link.href = item?.url;
        link.download = item?.name;
        link.target = '_blank';
        link.rel = 'noopener noreferrer';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  return (
    <div>
      <Main>
        <Table>
          {!isMobile && (
            <tr>
              <th>Name</th>
              <th>Uploaded on</th>
              <th>Uploaded by</th>
              <th className='last'></th>
            </tr>
          )}

          {filesList?.map((item, index) => {
            if (!buttonRef?.current[index]) {
              buttonRef.current[index] = React.createRef<HTMLDivElement>();
            }
            const extension = extensionName(item).toLowerCase();
            return (
              <tr key={item?.id} onClick={() => onClickFile(item)}>
                <td>
                  <First>
                    <Icon>
                      {(!item?.uploadFileType || item?.uploadFileType === FILE_TYPES.UPLOAD) &&
                      !FILE_EXTENTIONS.includes(extension) ? (
                        <>
                          <FileIconDiv>
                            <FileSVGIcon name={'blank'} height='28' width='28' viewBox='0 0 60 60' />
                            <div
                              style={{
                                fontSize: extension?.length === 5 ? 5 : 6
                              }}
                              className='file-text'>
                              {extension.split('.')?.pop()?.toUpperCase()}
                            </div>
                          </FileIconDiv>
                        </>
                      ) : (
                        <FileSVGIcon
                          name={
                            !item?.uploadFileType || item?.uploadFileType === FILE_TYPES.UPLOAD
                              ? extension
                              : `icon_${item?.uploadFileType}`
                          }
                          height='28'
                          width='28'
                          viewBox='0 0 60 60'
                        />
                      )}
                    </Icon>
                    {isMobile ? (
                      <ResponsiveDiv>
                        <DocumtentTitle>{item.name}</DocumtentTitle>
                        <DocCreated>
                          {calculateDay(item.createdOn)} <span>by</span> {item?.user?.name}
                        </DocCreated>
                      </ResponsiveDiv>
                    ) : (
                      <DocumtentTitle>{item.name}</DocumtentTitle>
                    )}
                  </First>
                </td>
                {!isMobile && (
                  <>
                    <td className='second-column'>{calculateDay(item.createdOn)}</td>
                    <td className='third-column'>{item?.user?.name}</td>
                  </>
                )}
                <td className='forth-column'>
                  <Moreicon
                    className='moreicon'
                    isActive={openMilestoneMenu && item?.id === selectedItem?.id}
                    onClick={(e) => openMilestoneDropdown(e, item)}
                    ref={buttonRef?.current[index]}>
                    <SVGIcon name='more-icon' width='18' height='18' viewBox='0 0 18 18' className='fillColor' />
                    {openMilestoneMenu && openDropdownId === item.id && (
                      <CustomDropdown
                        menuItems={item.uploadFileType === FILE_TYPES.UPLOAD ? menuItemsWithDownload : menuItems}
                        handleClick={(key) => handleMenuClick(key, item)}
                        buttonref={buttonRef?.current[index]}
                        isOpen={openMilestoneMenu}
                        setIsOpen={setOpenMilestoneMenu}
                        width={120}
                        setOpenDropdownId={setOpenDropdownId}
                      />
                    )}
                  </Moreicon>
                </td>
              </tr>
            );
          })}
        </Table>
      </Main>
    </div>
  );
};

export default FileListView;
