import styled, { css } from 'styled-components';
import { Typography } from '../../global/theme';

const Main = styled.div`
  margin-right: -18px;
  padding-bottom: 8px;
  overflow: auto;
  height: calc(100vh - 127px);
  ::-webkit-scrollbar {
    display: none;
  }
`;
const Table = styled.table`
  border-bottom: 0.5px solid var(--border-primary);
  border-collapse: collapse;
  width: 100%;
  thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: var(--background-secondary);
    box-shadow: inset 0px -1px var(--border-primary), inset 0px 1px var(--border-primary);
  }
`;
const Row = styled.tr`
  border-bottom: 0.5px solid var(--border-primary);
  :last-child {
    border-bottom: none;
  }
`;

const Tableheader = styled.th`
  ${Typography.body_md}
  color: var(--text-secondary);
  text-transform: uppercase;
  padding: 8px;
  min-width: 200px;
  text-align: left;
  :last-child {
    border-right: 0.5px solid var(--border-primary);
  }
`;
const Tabledata = styled.td`
  border-right: 0.5px solid var(--border-primary);
  padding: 16px 13px 8px 8px;
  height: 201px;
  min-width: 200px;
  cursor: pointer;
  vertical-align: baseline;
  :hover {
    background-color: var(--neutral-secondary);
    .dates {
      color: var(--brand-primary);
    }
  }
`;
const Bottom = styled.tbody``;
const Data = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;
const Dates = styled.div`
  ${Typography.body_md_medium}
  color: var(--text-primary);
  padding-left: 8px;
  ${(props: { disabled?: boolean }) =>
    props.disabled &&
    css`
      color: var(--text-secondary);
    `}
`;
const Card = styled.div`
  min-width: 180px;
  border: 0.5px solid var(--border-primary);
  border-radius: 8px;
  background-color: var(--background-primary);
  padding: 7px;
`;

const Label = styled.div`
  ${Typography.body_md}
  color: var(--text-primary);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  width: 100%;
  max-width: 184px;
  min-width: 180px;
`;
const Tasks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;
const Button = styled.button`
  background-color: transparent;
  border: none;
  color: var(--text-secondary);
  cursor: pointer;
  ${Typography.body_md}
  padding: 0;
  display: inline-flex;
  :hover {
    color: var(--text-secondary);
  }
`;
export { Main, Table, Row, Tableheader, Tabledata, Bottom, Data, Dates, Card, Label, Tasks, Button };
