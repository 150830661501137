/* eslint-disable max-len */
import styled, { css } from 'styled-components';
import { Typography } from '../../../global/theme';

interface Props {
  newGroup?: boolean;
  isActiveComment?: boolean;
  isShowHover?: boolean;
  isActive?: boolean;
  showGap?: boolean;
  isreply?: boolean;
  isFileCard?: boolean;
  isProjectMessageDetail?: boolean;
  figmaRefreshLoading?: boolean;
  isOverflowHidden?: boolean;
  isDisabled?: boolean;
  isShowUserImage?: boolean;
  hasUserImage?: boolean;
  isFirstUserMessage?: boolean;
  isLastUserMessage?: boolean;
}

const Group = styled.div`
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  height: 100%;
  ${(props: Props) =>
    props.isProjectMessageDetail &&
    css`
      margin-top: -24px;
    `}
`;

const Leftdiv = styled.div<Props>`
  /* max-width: 715px; */
  width: 100%;
  /* height: calc(100dvh - 57px); */
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  margin: 0 auto;
  overflow: auto;
  .infinite-scroll-component {
    overflow: visible !important;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  ${(props: Props) =>
    props.isProjectMessageDetail &&
    css`
      height: calc(100dvh - 51px);
    `}

  ${(props: Props) =>
    props.isOverflowHidden &&
    css`
      overflow: hidden;
    `}
  @media (max-width: 449px) {
    height: ${({ newGroup }) => (newGroup ? 'calc(100dvh - 113px)' : 'calc(100dvh - 57px)')};
    ${(props: Props) =>
      props.isProjectMessageDetail &&
      css`
        height: calc(100dvh - 112px);
      `}
  }
`;

const Discussdiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  align-items: center;
`;

const Topdiv = styled.div`
  display: block;
  max-width: 715px;
  width: 100%;
`;

const Discussiontop = styled.div`
  width: 100%;
  display: block;
  border-bottom: 0.5px solid var(--border-primary);
  text-align: center;
  margin-bottom: 24px;
  @media (max-width: 449px) {
    margin: 0px 16px 20px 16px;
    width: auto;
    ${(props: Props) =>
      props.isProjectMessageDetail &&
      css`
        margin: 0px 0px 20px;
      `}
  }
`;

const DesignDate = styled.h2`
  text-align: center;
  position: relative;
  top: 10px;
  margin: 0px;
  display: inline-block;
  color: var(--text-secondary);
  ${Typography.body_md}
  span {
    background-color: var(--background-secondary);
    position: relative;
    padding: 0 10px;
  }
  @media (max-width: 449px) {
    span {
      padding: 0 16px;
    }
  }
`;

const Comments = styled.div<Props>`
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding-top: 8px;
  position: relative;
  padding-top: ${({ isFirstUserMessage }) => (isFirstUserMessage ? '8px' : '4px')};

  @media (max-width: 449px) {
    gap: 8px;
    width: calc(100% - 32px);
    margin: 0 auto;
    padding-top: ${({ isFirstUserMessage }) => (isFirstUserMessage ? '12px' : '4px')};
    ${(props: Props) =>
      props.isProjectMessageDetail &&
      css`
        width: calc(100% - 24px);
      `}
  }
  @media (max-width: 449px) {
    .moreicon {
      visibility: visible;
    }
  }
  /* ${(props) =>
    props.isActiveComment &&
    css`
      background-color: var(--neutral-secondary);
      border-radius: 12px;
      .rightsection {
        display: block;
      }
    `} */
  /* ${(props) =>
    props.isShowHover &&
    css`
      background-color: var(--neutral-secondary);
      border-radius: 12px;
    `}; */
`;
const Leftcomment = styled.div`
  margin-left: ${({ hasUserImage }) => (hasUserImage ? '0px' : '36px')};
  display: flex;
  flex-direction: column-reverse;
  ${(props: Props) =>
    props.isProjectMessageDetail &&
    css`
      margin-top: -2px;
    `}
  @media (max-width: 449px) {
    margin-left: ${({ hasUserImage }) => (hasUserImage ? '0px' : '32px')};
    .avtar {
      width: 32px;
      height: 32px;
      span {
        font-size: 12px;
      }
    }
  }
`;

const Rightcomment = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  :hover {
    .rightsection {
      display: block;
    }
  }
  ${(props: Props) =>
    props.isShowUserImage &&
    css`
      margin-left: 36px;
    `}
  ${(props: Props) =>
    props.isActive &&
    css`
      .rightsection {
        display: block;
      }
    `}
  @media (max-width: 449px) {
    gap: 6px;
  }
`;

const Nametime = styled.div`
  display: flex;
  flex-direction: row;
  gap: 9px;
  @media (max-width: 449px) {
    gap: 6px;
  }
  @media (max-width: 320px) {
    flex-direction: column;
    gap: 2px;
  }
`;

const Name = styled.p`
  ${Typography.body_md_semibold}
  color: var(--text-primary);
  margin: 0px;
`;
const Time = styled.p`
  ${Typography.body_md}
  color: var(--text-secondary);
  margin: 0px;
`;

const RightSection = styled.div`
  display: none;

  .dropdown-content {
    position: absolute;
    z-index: 9999;
    top: 30px;
    right: 45px;
    @media (max-width: 449px) {
      right: 0;
    }
  }
`;

const RightIcons = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  left: auto;
  top: -24px;
  z-index: 111;
  background: var(--background-primary);
  padding: 4px;
  gap: 4px;
  border: 0.5px solid var(--border-primary);
  box-shadow: var(--shadow-modal);
  border-radius: 50px;
  .ant-dropdown {
    z-index: 1;
  }
  .active {
    border-radius: 50px;
    background-color: var(--background-muted);
  }
`;

const ReactionDiv = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  padding: 4px;
  position: relative;
  cursor: pointer;
  .svgicon {
    path {
      stroke: var(--text-secondary);
    }
  }
  &:hover {
    background-color: var(--background-muted);
    .svgicon {
      path {
        stroke: var(--text-primary);
      }
    }
  }
  @media (max-width: 449px) {
    padding: 2px;
    .svgicon {
      width: 16px;
      height: 16px;
    }
  }
`;

const Replyicon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  padding: 4px;
  cursor: pointer;
  &:hover {
    background-color: var(--background-muted);
    .svgicon {
      stroke: var(--text-primary);
    }
  }
  .svgicon {
    stroke: var(--text-secondary);
  }
  @media (max-width: 449px) {
    padding: 2px;
    .svgicon {
      width: 16px;
      height: 16px;
    }
    ${(props: Props) =>
      props.isProjectMessageDetail &&
      css`
        padding: 3px;
      `}
  }
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  border-radius: 50px;
  padding: 4px;
  cursor: pointer;
  .fillColor {
    fill: var(--text-secondary);
    transform: rotate(90deg);
  }
  :hover {
    background-color: var(--background-muted);
    .fillColor {
      fill: var(--text-primary);
    }
  }
  @media (max-width: 449px) {
    &.moreicon {
      padding: 2px !important;
    }
    .fillColor {
      width: 16px;
      height: 16px;
    }
  }
`;

const Tagdesc = styled.div<Props>`
  padding: 8px 12px;
  background-color: var(--background-muted);
  border-radius: 4px 12px 12px 4px;
  max-width: 524px;
  /* width: 100%; */
  @media (max-width: 449px) {
    padding: 8px;
    gap: 4px;
  }

  &.tiptap :first-child {
    /* margin-top: unset !important; */
  }
  &.tiptap .description {
    margin-top: 0 !important;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    background-color: transparent !important;
    color: var(--text-primary) !important;
  }
  .prosemirror-mention-node {
    color: var(--brand-primary) !important;
  }
  p {
    color: var(--text-primary) !important;
    background-color: transparent !important;
  }
  ul,
  ol {
    color: var(--text-primary) !important;
    background-color: transparent !important;
    li {
      ::marker {
        color: var(--text-primary) !important;
        background-color: transparent !important;
      }
    }
  }
  p > span,
  strong {
    color: var(--text-primary) !important;
  }
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  &.tiptap img {
    @media (max-width: 449px) {
      max-width: 300px;
    }
  }
  ${(props) =>
    props.showGap &&
    css`
      gap: 8px;
      flex-direction: column;
    `}
  ${(props) =>
    props.isreply &&
    css`
      gap: 8px;
      flex-direction: column;
    `}
    ${(props) =>
    props.isFirstUserMessage &&
    css`
      border-top-left-radius: 12px;
    `}
    ${(props) =>
    props.isLastUserMessage &&
    css`
      border-bottom-left-radius: 12px;
    `}
  img {
    .img {
      max-width: 50%;
      max-height: 50%;
    }
  }
  .threaddiv {
    cursor: pointer;
    padding: 8px;
    border-radius: 4px;
    border: 0.5px solid var(--border-primary);
    background: var(--background-tertiary);
    margin-top: 4px;
    max-width: fit-content;
  }
  .content {
    display: flex;
    flex-direction: row;
    gap: 9px;
  }
  .line {
    width: 2px;
    background-color: var(--text-placeholder);
    height: auto;
    border-radius: 3px;
  }
  .rightdiv {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  /* figma design css */
  .figma-main-div {
    position: relative;
    z-index: 99;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background-color: var(--background-secondary);
    border: 0.5px solid var(--border-primary);
    width: fit-content;
    overflow: hidden;
    margin-top: 12px;
    .ant-image {
      margin: 0;
    }
    .ant-image .ant-image-img {
      border-radius: 0 !important;
      /* border-right: 0.5px solid var(--border-primary);
      border-left: 0.5px solid var(--border-primary); */
      max-width: 100%;
      width: auto;
      max-height: 419px;
      height: auto;
    }
  }
  .figma-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 6px;
    padding: 8px;
    border-bottom: 0.5px solid var(--border-primary);

    @media (max-width: 449px) {
      flex-direction: column;
      align-items: baseline;
      gap: 6px;
    }
  }
  .figma-desc-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    @media (max-width: 449px) {
      align-items: flex-start;
      max-width: calc(100% - 24px);
    }
  }
  .figma-icon {
    width: 24px;
    height: 24px;
    border: unset !important;
    border-radius: unset !important;
  }
  .figma-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    @media (max-width: 449px) {
      flex-direction: column;
      gap: 6px;
      align-items: flex-start;
    }
  }
  .figma-description {
    color: var(--text-primary);
    ${Typography.body_sm_medium}
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .figma-filename {
    color: var(--text-secondary);
    ${Typography.body_sm_medium}
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 75px;
  }
  .figma-right-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
  .figma-animation {
    height: 24px;
    display: flex;
    .refresh-icon-spin {
      animation-name: spin;
      animation-duration: 1000ms;
      animation-iteration-count: infinite;

      -webkit-animation-name: spin;
      -webkit-animation-duration: 1000ms;
      -webkit-animation-iteration-count: infinite;
    }
    @-ms-keyframes spin {
      from {
        -ms-transform: rotate(0deg);
      }
      to {
        -ms-transform: rotate(360deg);
      }
    }
    @-moz-keyframes spin {
      from {
        -moz-transform: rotate(0deg);
      }
      to {
        -moz-transform: rotate(360deg);
      }
    }
    @-webkit-keyframes spin {
      from {
        -webkit-transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(360deg);
      }
    }
    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
    @media (max-width: 449px) {
      position: absolute;
      right: 42px;
      top: 8px;
      width: 24px;
      height: 24px !important;
      .ant-image .ant-image-img {
        width: 100%;
      }
    }
  }
  .refresh-icon {
    width: 18px;
    height: 18px;
    max-width: 100%;
    max-height: 18px;
    cursor: pointer;
    padding: 3px;
    border: unset !important;
    border-radius: unset !important;
    ${(props) =>
      props.figmaRefreshLoading &&
      css`
        pointer-events: none;
      `};
  }
  .figma-link-div {
    padding: 5px 16px;
    background-color: var(--background-primary);
    color: var(--text-primary);
    border: 1px solid var(--border-primary);
    cursor: pointer;
    text-decoration: none;
    border-radius: 36px;
    ${Typography.body_sm_medium}
    &:hover {
      background-color: var(--background-secondary);
      border-radius: 36px;
    }
    @media (max-width: 449px) {
      flex-direction: column;
      margin-left: 30px;
    }
  }
  @media (max-width: 449px) {
    .figma-main-div .ant-image .ant-image-img {
      border-right: unset;
      border-left: unset;
    }
  }
  .figma-preview-image {
    /* border-radius: 4px; */
    /* border: 1px solid var(--border-primary); */
    max-width: 100%;
    width: auto;
    max-height: 419px !important;
    height: auto;
    margin: 0 auto;
    display: flex;
  }
  .ant-image {
    margin-bottom: 0 !important;
  }

  .ant-image-img {
    border-radius: 6px !important;
    .ant-image .ant-image-img {
      border-radius: 0px !important;
    }
  }
  .header {
    display: flex;
    flex-direction: row;
    gap: 50px;
    justify-content: space-between;
  }
  .leftheader {
    display: flex;
    flex-direction: row;
    gap: 9px;
    align-items: center;
  }
  .name {
    color: var(--text-primary);
    ${Typography.body_md_semibold}
  }
  .time {
    color: var(--text-secondary);
    ${Typography.body_md}
  }
  .text {
    width: 100%;
    max-width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--text-primary);
    ${Typography.body_md_medium}
    word-break: break-word;
    p {
      color: var(--text-primary);
      ${Typography.body_md_medium}
      margin: 0;
      a {
        color: var(--text-link);
        word-break: break-word;
      }
    }
    img {
      max-height: 100%;
      height: auto;
      margin: 0;
      /* border-radius: 8px; */
      @media (max-width: 449px) {
        max-width: 100%;
      }
    }
    span {
      margin: 0;
      ${Typography.body_md_medium}
      color: var(--text-primary);
    }
  }
  /* css for file card ui in threaded message */
  .filediv {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 5px;
    flex-wrap: wrap;
    align-items: center;
  }
  .imagediv {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
  }
  .filetext {
    color: var(--text-primary);
    ${Typography.body_md_medium}
    margin-top: 2px;
  }
  /* screen recorder design css */
  .screen-recording-custom-main-div {
    -webkit-user-modify: read-only;
    border: 0.5px solid var(--border-primary);
    border-radius: 8px;
    max-width: 354px;
    display: flex;
    flex-direction: column;
    background-color: var(--background-primary);
    overflow: hidden;
  }
  .screen-recording-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    border-bottom: 0.5px solid var(--border-primary);
  }
  .e-rte-video .e-video-inline {
    width: 355px !important;
  }
  .screen-recording-name {
    color: var(--text-primary);
    ${Typography.body_sm_medium}
    max-width: 300px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
  .screen-recording-right-div {
    display: flex;
    gap: 12px;
  }
  .open-video-icon {
    max-width: 100%;
    max-height: 100%;
    border: unset !important;
    border-radius: unset !important;
    cursor: pointer;
    :hover {
      background-color: var(--neutral-secondary);
      border-radius: 4px;
    }
  }
  .video-more-icon {
    max-width: 100%;
    max-height: 100%;
    border: unset !important;
    cursor: pointer;
    :hover {
      background-color: var(--neutral-secondary);
      border-radius: 4px;
    }
  }
  .inbox-video {
    max-width: 353px;
    width: 100%;
    height: auto;
  }
  .screen-recording-animation {
    width: 16px;
    height: 16px;
  }
  .screen-recording-more {
    width: 16px;
    height: 16px;
  }
  .inbox-image {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .video-icon {
    position: absolute;
    top: 45%;
    left: 45%;
    border: unset !important;
    border-radius: unset !important;
    cursor: pointer;
    width: 30px !important;
    height: 30px !important;
  }
  .video-preview {
    max-width: 353px;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--background-tertiary) !important;
    overflow: hidden;
  }
  /* youtube design css */
  .youtube-preview-main-div {
    -webkit-user-modify: read-only;
    border: 0.5px solid var(--border-primary);
    border-radius: 8px;
    max-width: 354px;
    display: flex;
    flex-direction: column;
    background-color: var(--background-primary);
    overflow: hidden;
    margin-top: 12px;
    .youtube-preview-image {
      width: 100%;
      height: auto;
      border-radius: 0px;
      max-width: unset;
    }
    .e-rte-image.e-imginline {
      vertical-align: baseline;
    }
    .screen-recording-desc-div {
      display: flex;
      align-items: center;
      gap: 6px;
    }
    .youtube-icon {
      width: 20px;
      height: 20px;
      border: unset !important;
      border-radius: unset !important;
    }
  }
  /* prent screen css */
  .prnt-desc-div {
    display: flex;
    gap: 4px;
    align-items: center;
  }
  .prnt-icon {
    margin: 0 !important;
    border: unset !important;
    border-radius: unset !important;
  }
  .prnt-url-link {
    cursor: pointer;
    ${Typography.body_sm_medium}
    color: var(--text-link);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    word-break: break-all;
    max-width: 300px;
  }
  .prnt-text {
  }
  .prnt-preview-main-div {
    -webkit-user-modify: read-only;
    display: flex;
    flex-direction: column;
    width: fit-content;
    border-radius: 8px;
    border: 0.5px solid var(--border-primary);
    background-color: var(--background-primary);
    position: relative;
    z-index: 1;
  }
  .prnt-preview-header {
    padding: 8px;
    border-bottom: 0.5px solid var(--border-primary);
  }
  .prnt-inbox-image {
    border-radius: 8px;
  }
  /* .inbox-image {
    .figma-preview-image {
    }
  } */
  @media (max-width: 449px) {
    .threaddiv {
      margin-top: 0;
      padding: 6px 8px;
    }
    .text {
      p {
        word-break: break-all;
      }
    }
    .filetext {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
  }
`;

const Desc = styled.div<{ figmaRefreshLoading: boolean }>`
  ${Typography.body_md_medium}
  color: var(--text-primary);
  margin: 0px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  word-break: break-word;
  a {
    color: var(--text-link);
    word-break: break-word;
    text-decoration: underline;
    :hover {
      text-decoration: none;
    }
  }
  .geist-ellipsis {
    white-space: normal !important;
  }
  .file-box {
    max-width: max-content;
    display: inline-flex;
    padding: 10px 16px;
    background-color: var(--background-primary);
    border: 1px solid var(--border-primary);
    border-radius: 8px;
    box-shadow: var(--shadow-card);
    align-items: center;
    gap: 16px;
  }
  .textarea {
    width: 130px;
    white-space: unset !important;
  }
  .h4 {
    ${Typography.body_md_medium}
    color: var(--text-primary);
    margin: 0 0 4px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    white-space: inherit;
  }
  p {
    ${Typography.body_md}
    color: var(--text-primary);
  }
  .svg {
    width: 32px;
    height: 32px;
  }
  .figma-main-div {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background-color: var(--background-secondary);
    border: 0.5px solid var(--border-primary);
    width: fit-content;
    z-index: 99;
    overflow: hidden;
    margin-top: 12px;
    .ant-image {
      margin: 0;
    }
    .ant-image .ant-image-img {
      border-radius: 0 !important;
      max-width: 100%;
      width: auto;
      max-height: 419px;
      height: auto;
      border-top: unset;
      border-bottom: unset;
    }
  }
  .figma-desc-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }
  .figma-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 6px;
    padding: 8px;
    border-bottom: 0.5px solid var(--border-primary);
  }
  .figma-link-div {
    padding: 5px 16px;
    background-color: var(--background-primary);
    color: var(--text-primary);
    border: 1px solid var(--border-primary);
    cursor: pointer;
    text-decoration: none;
    border-radius: 36px;
    ${Typography.body_sm_medium}
    &:hover {
      background-color: var(--background-secondary);
      border-radius: 36px;
    }
  }
  .figma-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }
  .figma-description {
    color: var(--text-primary);
    ${Typography.body_sm_medium}
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .figma-filename {
    color: var(--text-secondary);
    ${Typography.body_sm_medium}
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 75px;
  }
  .figma-icon {
    width: 24px;
    height: 24px;
  }
  .figma-preview-image {
    border-radius: 0px !important;
    max-width: 100%;
    width: auto;
    max-height: 419px;
    height: auto;
    margin: 0 auto;
    display: flex;
  }
  .figma-right-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
  .figma-animation {
    height: 24px;
    display: flex;
    .refresh-icon-spin {
      animation-name: spin;
      animation-duration: 1000ms;
      animation-iteration-count: infinite;

      -webkit-animation-name: spin;
      -webkit-animation-duration: 1000ms;
      -webkit-animation-iteration-count: infinite;
    }
    @-ms-keyframes spin {
      from {
        -ms-transform: rotate(0deg);
      }
      to {
        -ms-transform: rotate(360deg);
      }
    }
    @-moz-keyframes spin {
      from {
        -moz-transform: rotate(0deg);
      }
      to {
        -moz-transform: rotate(360deg);
      }
    }
    @-webkit-keyframes spin {
      from {
        -webkit-transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(360deg);
      }
    }
    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
  .refresh-icon {
    width: 18px;
    height: 18px;
    max-width: 100%;
    max-height: 18px;
    cursor: pointer;
    padding: 3px;
    ${(props) =>
      props.figmaRefreshLoading &&
      css`
        pointer-events: none;
      `}
  }
  p + .figma-main-div {
    margin-top: 12px;
  }
  .e-rte-table td,
  .e-rte-table th {
    border: 1px solid var(--border-primary);
  }
  @media (max-width: 449px) {
    p + .figma-main-div {
      margin-top: 8px;
    }
    .figma-main-div {
      .ant-image .ant-image-img {
        width: 100%;
      }
    }
    .figma-icon {
      width: 24px;
      height: 24px;
      margin: 0;
    }
    .figma-header {
      flex-direction: column;
      align-items: baseline;
      gap: 6px;
    }
    .figma-text {
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
    }
    .figma-desc-div {
      align-items: flex-start;
    }
    .figma-link-div {
      margin-left: 30px;
    }

    .figma-main-div .ant-image .ant-image-img {
      border-right: unset !important;
      border-left: unset !important;
    }
    .refresh-icon {
      width: 16px;
      height: 16px;
      padding: 5px;
    }
  }
`;

const Undomessage = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: 0px !important;
  background-color: var(--background-muted);
  border-radius: 12px 12px 12px 4px;

  p {
    color: var(--text-secondary);
    ${Typography.body_md}
    font-style: italic;
    margin: 0;
  }
  a {
    color: var(--text-link);
    ${Typography.body_md}
    text-decoration-line: underline;
    cursor: pointer;
  }
`;

const Reactiondiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  width: max-content;
  margin: -4px 0px 0px 8px;
  z-index: 1;
  @media (max-width: 449px) {
    margin: -10px 0px 0px 8px;
  }
`;

const ReactionShow = styled.div`
  border: 1px solid var(--background-secondary);
  background-color: var(--background-muted);
  border-radius: 50px;
  padding: 2px 8px;
  display: flex;
  align-items: center;
  gap: 4px;
  width: fit-content;
  :hover {
    cursor: pointer;
  }
  .reaction-icon {
    font-size: 13px;
    line-height: 16px;
  }
  p {
    ${Typography.body_md};
    color: var(--text-primary);
    margin: 0;
  }
  @media (max-width: 449px) {
    gap: 5px;
    ${(props: Props) =>
      props.isProjectMessageDetail &&
      css`
        gap: 0;
      `}
  }
  ${(props) =>
    props.isDisabled &&
    `
      pointer-events: none;
    `}
`;

const ScrollRef = styled.div``;

const Footer = styled.div<Props>`
  position: sticky;
  bottom: 0;
  padding-bottom: 24px;
  z-index: 99;
  max-width: 715px;
  width: 100%;
  margin: 0 auto;
  background: var(--background-secondary);
  @media (max-width: 449px) {
    padding-top: 6px;
    padding-bottom: 16px;
    width: 100%;
    ${(props) =>
      props.isProjectMessageDetail &&
      css`
        width: calc(100vw - 32px);
      `}
  }
`;
const Message = styled.div`
  position: relative;
  bottom: 0;
`;

const FolderRight = styled.div`
  padding: 0 24px;
  width: 302px;
  background: var(--background-secondary);
  height: calc(100dvh - 54px);
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: auto;
  border-left: 1px solid var(--border-primary);

  ::-webkit-scrollbar {
    display: none;
  }
  ${(props: Props) =>
    props.isProjectMessageDetail &&
    css`
      padding: 0 0 0 24px;
      height: calc(100dvh - 51px);
    `}
`;

const MessagesDivMain = styled.div`
  display: flex;
  flex-direction: column;
`;

const FileBox = styled.div`
  /* max-width: 500px; */
  width: 100%;
  /* display: flex; */
  display: grid;
  grid-template-columns: auto auto;
  flex-wrap: wrap;
  gap: 8px;
  cursor: pointer;
  margin: 4px 0 !important;
  @media (max-width: 449px) {
    grid-template-columns: auto;
  }
`;
const FileDiv = styled.div`
  width: 240px;
  @media (max-width: 449px) {
    width: 100%;
  }
`;
const FileType = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
  ${Typography.body_sm_semibold}
  transform: translate(-46%, -30%);
  color: var(--text-primary);
`;

const FileIconDiv = styled.div`
  position: relative;
  .file-text {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 50%;
    font-weight: 700;
    color: var(--text-secondary);
  }
`;
const EmojiDiv = styled.div`
  display: flex;
  font-size: 14px;
  width: 16px;
  height: 16px;

  padding: 4px;
  align-items: center;
  justify-content: center;
  :hover {
    cursor: pointer;
    background-color: var(--background-muted);
    border-radius: 50px;
  }
`;

const OverLay = styled.div`
  position: absolute;
  border-radius: 50%;
  background-color: var(--background-overlay);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: none;
`;
const AvatarWrap = styled.div`
  cursor: pointer;
  position: relative;
  &:hover {
    ${OverLay} {
      display: block;
    }
  }
`;

export {
  Comments,
  Desc,
  DesignDate,
  Discussdiv,
  Discussiontop,
  FolderRight,
  Footer,
  Group,
  Icon,
  Leftcomment,
  Leftdiv,
  Message,
  Name,
  Nametime,
  ReactionDiv,
  ReactionShow,
  Reactiondiv,
  Replyicon,  
  RightIcons,
  RightSection,
  Rightcomment,
  ScrollRef,
  Tagdesc,
  Time,
  Topdiv,
  Undomessage,
  MessagesDivMain,
  FileBox,
  FileType,
  FileIconDiv,
  EmojiDiv,
  FileDiv,
  AvatarWrap,
  OverLay
};
