/* eslint-disable max-len */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Buttonblock,
  Buttonblocks,
  Container,
  Content,
  Div,
  DropText,
  Filter,
  ResponsiveNavbarIcon,
  Selecttext,
  TaskDropdown,
  Taskbutton,
  Taskicons,
  Title
} from './styles';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'antd';
import { MY_TASKS_GROUP_FILTER_TYPE, MY_TASKS_GROUP_FILTER_TYPE_LABELS } from '../../../../../global/constants';
import SVGIcon from '../../../../../assets/images/svg/SVGIcon';
import ModalCustom from '../../../../../component/models/modal';
import CreateTaskModal from '../../../../../component/models/createTaskModal';
import Button from '../../../../../component/button';
import { clearCreateTaskInput, updateCreateTaskInput } from '../../../../../actions/taskActions';
import { TaskInterfaceDetails } from '../../../../../interfaces/TaskInterface';
import { getUserPreferenceFieldData, updateUserPreference } from '../../../../../helpers/firebaseHelper';
import { RootReducerInterface } from '../../../../../interfaces/RootReducerInterface';
import { UserInterface } from '../../../../../interfaces/ProjectInterface';
import Resbutton from '../../../../../component/resbutton/resbutton';
import Responsivnavbar from '../../../../../component/navbar/responsivenavbar';

interface Props {
  membersTask?: TaskInterfaceDetails[];
  loadData: () => void;
}

const MembersNavbar: React.FC<Props> = (props) => {
  // States
  const [openTaskModel, setOpenTaskModel] = useState(false);
  const [filteredKey, setFilteredKey] = useState<number>();

  //use selector state variables
  const workspaceSelector = useSelector((state: RootReducerInterface) => state.workspace);
  const { workspace } = workspaceSelector;

  // Other variables
  const history = useHistory();
  const params: { id: string } = useParams();
  const location: { pathname: string } = useLocation();
  const dispatch = useDispatch();
  const memberDetail = workspace?.users?.find((x: UserInterface) => x?.id === params?.id);

  // load initial filter data
  const loadFilterTypeData = useCallback(async () => {
    const userFilter = await getUserPreferenceFieldData('my_task_filter');
    setFilteredKey(userFilter?.group_by ?? MY_TASKS_GROUP_FILTER_TYPE.DUE_DATE);
  }, []);

  useEffect(() => {
    loadFilterTypeData();
  }, [loadFilterTypeData]);

  // handle overview button active or not
  const isOverview = useMemo(() => {
    return location?.pathname === `/members/${params?.id}/overview`;
  }, [params?.id, location?.pathname]);

  // handle task button active or not
  const isTask = useMemo(() => {
    return location?.pathname === `/members/${params?.id}/tasks`;
  }, [params?.id, location?.pathname]);

  // for create task
  const createTaskModel = useCallback(async () => {
    const users = [memberDetail];
    dispatch(updateCreateTaskInput({ propsName: 'users', value: users }));
    setOpenTaskModel(true);
  }, [memberDetail, dispatch]);

  // for close task model
  const closeTaskModel = useCallback(() => {
    setOpenTaskModel(false);
    dispatch(clearCreateTaskInput());
  }, [dispatch]);

  // handle filter based on group
  const onChangeGroupByFilter = useCallback(
    async (key: number) => {
      key = Number(key);
      setFilteredKey(key);
      await updateUserPreference({ my_task_filter: { group_by: key } });
      props?.loadData && props?.loadData();
    },
    [props]
  );

  return (
    <>
      <Container>
        <Div>
          <Content>
            <ResponsiveNavbarIcon>
              <Responsivnavbar />
            </ResponsiveNavbarIcon>
            <Title>{memberDetail?.name}</Title>
            <Buttonblock>
              <Taskbutton isActive={isOverview} onClick={() => history.push(`/members/${params?.id}/overview`)}>
                Overview
              </Taskbutton>
              <Taskbutton isActive={isTask} onClick={() => history.push(`/members/${params?.id}/tasks`)}>
                Tasks
              </Taskbutton>
            </Buttonblock>
          </Content>
          <Taskicons>
            <Dropdown
              trigger={['click']}
              placement='bottomRight'
              dropdownRender={() => (
                <>
                  <TaskDropdown>
                    <DropText onClick={() => onChangeGroupByFilter(MY_TASKS_GROUP_FILTER_TYPE.NONE)}>None</DropText>
                    <DropText onClick={() => onChangeGroupByFilter(MY_TASKS_GROUP_FILTER_TYPE.GROUP)}>Group</DropText>
                    <DropText onClick={() => onChangeGroupByFilter(MY_TASKS_GROUP_FILTER_TYPE.DUE_DATE)}>
                      Due Date
                    </DropText>
                    <DropText onClick={() => onChangeGroupByFilter(MY_TASKS_GROUP_FILTER_TYPE.PROJECT)}>
                      Project
                    </DropText>
                    <DropText onClick={() => onChangeGroupByFilter(MY_TASKS_GROUP_FILTER_TYPE.PRIORITY)}>
                      Priority
                    </DropText>
                  </TaskDropdown>
                </>
              )}>
              <Filter isHide={filteredKey === MY_TASKS_GROUP_FILTER_TYPE.NONE}>
                <SVGIcon
                  name='small-filters-icon'
                  width='16'
                  height='16'
                  viewBox='0 0 14 14'
                  className='filters-icon-color'
                />
                <Selecttext isHide={filteredKey === MY_TASKS_GROUP_FILTER_TYPE.NONE}>
                  {filteredKey === MY_TASKS_GROUP_FILTER_TYPE.NONE
                    ? ''
                    : filteredKey !== undefined
                    ? MY_TASKS_GROUP_FILTER_TYPE_LABELS[filteredKey]
                    : ''}
                </Selecttext>
              </Filter>
            </Dropdown>
            {/* className 'hidebutton' is used to hide button in responsive screen and shows only responsive button icon  */}
            <div className='hidebutton'>
              {props?.membersTask && props?.membersTask[0]?.tasks?.length > 0 && props?.membersTask?.length > 0 && (
                <Button
                  title='New Task'
                  iconName='plus-icon'
                  type='button'
                  iconSize={14}
                  iconViewBox='0 0 18 18'
                  iconColor='#fff'
                  onClick={createTaskModel}
                  isMemberTask={true}
                />
              )}
            </div>
            {props?.membersTask && props?.membersTask[0]?.tasks?.length > 0 && props?.membersTask?.length > 0 && (
              <Resbutton onClick={createTaskModel} className={'responsive-button'} />
            )}
          </Taskicons>
        </Div>
        <Buttonblocks>
          <Taskbutton isActive={isOverview} onClick={() => history.push(`/members/${params?.id}/overview`)}>
            Overview
          </Taskbutton>
          <Taskbutton isActive={isTask} onClick={() => history.push(`/members/${params?.id}/tasks`)}>
            Tasks
          </Taskbutton>
        </Buttonblocks>
      </Container>
      <ModalCustom open={openTaskModel} onClose={closeTaskModel} isCreateTask={true}>
        <CreateTaskModal loadData={props?.loadData} onCancel={closeTaskModel} />
      </ModalCustom>
    </>
  );
};
export default MembersNavbar;
