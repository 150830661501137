import React from 'react';
import {
  Group,
  Container,
  Projectcontent,
  Projectitem,
  Projectlist,
  Document,
  Icon,
  Title,
  Text,
  Point,
  AssignContent,
  Assigngroup,
  Plus,
  ProgressDiv,
  Box,
  Bottom
} from './styles';
import { useSelector } from 'react-redux';
import { PROJECT_STATUS, PROJECT_STATUS_COLOR } from '../../../global/constants';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import Avatar from '../../../component/avatar/Avatar';
import { Progress } from 'antd';
import EmptyState from '../../../component/emptyState';
import projectempty from '../../../assets/images/emptystates/projectlightempty.svg';
import { isEmpty } from '../../../helpers/common';
import projectdarkempty from '../../../assets/images/emptystates/projectdarkempty.svg';
import Projectloading from '../../../component/loading/projectloading';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import { rootStore } from '../../../mobx/rootStore';
import { ProjectModel } from '../../../mobx/models/project';

interface Props {
  loading?: boolean;
  onClickFavorite: (e: React.SyntheticEvent, project: ProjectModel, value: boolean) => void;
  onClickProjectList: (project: ProjectModel) => void;
}

const Favourite: React.FC<Props> = (props) => {
  const { loading, onClickFavorite, onClickProjectList } = props;
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { settings: settingsSelector } = stateSelector || {};
  const { themeMode } = settingsSelector;
  //Mobx store variable
  const { projectStore } = rootStore;
  const { favoriteProjects } = projectStore;

  const getImageSource = () => (themeMode?.theme === 'dark' ? projectdarkempty : projectempty);

  return (
    <>
      <Group>
        <Container>
          {loading && <Projectloading />}
          {!loading && favoriteProjects?.length === 0 && (
            <EmptyState
              header={'You have no favourite Projects'}
              title={'You can make favourite by clicking from the project list'}
              image={getImageSource()}
              hideButton={true}
            />
          )}
          {!loading &&
            favoriteProjects?.length > 0 &&
            favoriteProjects?.map((item) => {
              return (
                <Projectcontent key={item?.id} onClick={() => onClickProjectList(item)}>
                  <Box>
                    <Projectitem>
                      <Projectlist>
                        <Document>
                          <Title>{item?.name}</Title>
                          {!isEmpty(item?.customer?.companyName) && <Text>for {item?.customer?.companyName}</Text>}
                        </Document>
                        <Icon
                          onClick={(e) => {
                            onClickFavorite(e, item, false);
                          }}>
                          <SVGIcon
                            name='favorite-icon'
                            width='16'
                            height='16'
                            viewBox='0 0 17 16'
                            className='star-icon'
                          />
                        </Icon>
                      </Projectlist>
                    </Projectitem>
                    <Bottom>
                      <Point color={PROJECT_STATUS_COLOR[item?.progressData.status || 1]}>
                        <span>
                          <small></small>
                          {`${item?.progressData.statusText || 'Not Started yet'}`}
                          {item?.progressData.status !== PROJECT_STATUS.NOT_STARTED &&
                            ` (${item?.progressData.progress || 0}%)`}
                        </span>
                      </Point>
                      <ProgressDiv>
                        <Progress
                          percent={item?.progressData.progress}
                          showInfo={false}
                          strokeColor={PROJECT_STATUS_COLOR[item?.progressData.status || 1]}
                          style={{ marginBottom: 0, marginInlineEnd: 0, height: 5 }}
                          className='prograss '
                        />
                      </ProgressDiv>
                      <AssignContent>
                        <Assigngroup>
                          <ul>
                            {item?.users?.map((item, index) => {
                              if (index > 3) return null;
                              return (
                                <li key={item?.id}>
                                  <Avatar
                                    imgSrc={item?.profile_photo || ''}
                                    name={item?.name ? item.name : 'U N'}
                                    size={20}
                                    isMilestoneBorder
                                  />
                                </li>
                              );
                            })}
                            {item?.users?.length > 4 && (
                              <li className='plus-icon'>
                                <Plus>+{item?.users?.length - 4}</Plus>
                              </li>
                            )}
                          </ul>
                        </Assigngroup>
                      </AssignContent>
                    </Bottom>
                  </Box>
                </Projectcontent>
              );
            })}
        </Container>
      </Group>
    </>
  );
};
export default Favourite;
