import { ActionCreatorWithPayload, ActionCreatorWithOptionalPayload, createAction, Dispatch } from '@reduxjs/toolkit';
import * as Actions from './types';
import {
  CustomerInvoiceDataInterface,
  CustomerInvoiceTimeframesInterface,
  InvoiceActivityDetailsInterface,
  InvoiceDetailInterface
} from '../interfaces/InvoiceInterface';
import { ReducerInterface } from '../interfaces/ReducerInterface';

/**
 * @desc Set Invoice loader
 */
export const setInvoiceLoader: ActionCreatorWithPayload<boolean, string> = createAction(Actions.SET_INVOICE_LOADER);

/**
 * @desc Set Invoice List
 */
export const setInvoiceList: ActionCreatorWithPayload<InvoiceDetailInterface[], string> = createAction(
  Actions.SET_INVOICE_LIST
);

/**
 * @desc Set filtered Invoice List
 */
export const setFilteredInvoiceList: ActionCreatorWithPayload<InvoiceDetailInterface[], string> = createAction(
  Actions.SET_FILTERED_INVOICE_LIST
);

/**
 * @desc Set Invoice List filtered wise
 */
export const setInvoiceListFilteredWise: ActionCreatorWithPayload<InvoiceDetailInterface[], string> = createAction(
  Actions.SET_INVOICE_LIST_FILTERED_WISE
);

/**
 * @desc Set Invoice View Data
 */
export const setInvoiceViewData: ActionCreatorWithPayload<InvoiceDetailInterface, string> = createAction(
  Actions.SET_INVOICE_VIEW_DATA
);

/**
 * @desc Set Invoice Activity Detail
 */
export const setInvoiceActivityDetail: ActionCreatorWithPayload<InvoiceActivityDetailsInterface[], string> =
  createAction(Actions.SET_INVOICE_ACTIVITY_DETAIL);

/**

 * @desc Set Customer Invoice Data
 */
export const setCustomerInvoiceData: ActionCreatorWithPayload<CustomerInvoiceDataInterface[], string> = createAction(
  Actions.SET_CUSTOMER_INVOICE_DATA
);

/**
 * @desc Set Customer Invoice Timeframes
 */
export const setCustomerInvoiceTimeframes: ActionCreatorWithPayload<CustomerInvoiceTimeframesInterface[], string> =
  createAction(Actions.SET_CUTOMER_INVOICE_TIMEFRAMES);

/**
 * @desc Set Invoice View Data
 */
export const setInvoiceDetail: ActionCreatorWithPayload<InvoiceDetailInterface, string> = createAction(
  Actions.SET_INVOICE_DETAIL
);

/**
 * @desc Set Invoice Update Detail
 */
export const updateInvoiceDetail: ActionCreatorWithPayload<ReducerInterface, string> = createAction(
  Actions.UPDATE_INVOICE_DETAIL
);

/**
 * @desc  Clear Invoice Detail
 */
export const clearInvoiceDetail: ActionCreatorWithOptionalPayload<undefined, string> = createAction(
  Actions.CLEAR_INVOICE_DETAIL
);

/**
 * @desc update  Invoice  Bill Details
 */
export const updateInvoiceBillableHoursDetail: ActionCreatorWithPayload<ReducerInterface, string> = createAction(
  Actions.UPDATE_INVOICE_BILLABLE_HOURS_DETAIL
);

/**
 * @desc update  Invoice  Bill Details
 */
export const updateBillableHoursTimeEntryDetail: ActionCreatorWithPayload<ReducerInterface, string> = createAction(
  Actions.UPDATE_BILLABLE_HOURS_TIME_ENTRY_DETAIL
);

/**
 * @desc update  Invoice  Bill Details
 */
export const clearBillableHoursTimeEntryDetail: ActionCreatorWithOptionalPayload<any, string> = createAction(
  Actions.CLEAR_INVOICE_BILLABLE_HOURS_DETAIL
);

/**
 * @desc Clear Web Form Data
 */
export const clearInvoiceData = () => (dispatch: Dispatch) => {
  dispatch(setInvoiceLoader(false));
  dispatch(setInvoiceList([]));
  dispatch(clearInvoiceDetail());
  dispatch(setFilteredInvoiceList([]));
  dispatch(setInvoiceListFilteredWise([]));
  dispatch(
    setInvoiceViewData({
      companyId: '',
      currency: '',
      customer: {
        phone: '',
        lastName: '',
        id: '',
        firstName: '',
        companyName: '',
        email: '',
        companyId: '',
        address: '',
        status: undefined,
        projectId: ''
      },
      date: '',
      discount: 0,
      dueAmount: 0,
      dueDate: '',
      dueDays: 0,
      id: '',
      items: [],
      number: '',
      status: 0,
      statusText: '',
      tax: [],
      totalAmount: 0,
      totalDiscount: 0,
      totalTax: 0,
      totalTax2: 0,
      subTotal: 0
    })
  );
  dispatch(setInvoiceActivityDetail([]));
  dispatch(setCustomerInvoiceData([]));
  dispatch(setCustomerInvoiceTimeframes([]));
};
