import { useCallback } from 'react';
import { ArrowSection, Icon } from './styles';
import SVGIcon from '../../assets/images/svg/SVGIcon';
import { useHistory } from 'react-router-dom';

export default function BackForwardIcon() {
  const history = useHistory();

  const onClickBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const onClickForward = useCallback(() => {
    history.goForward();
  }, [history]);

  return (
    <>
      <ArrowSection>
        <Icon onClick={onClickBack}>
          <SVGIcon name='back-arrow' width='16' height='16' viewBox='16' />
        </Icon>
        <Icon onClick={onClickForward}>
          <SVGIcon name='back-arrow' width='16' height='16' viewBox='16' className='forward-icon' />
        </Icon>
      </ArrowSection>
    </>
  );
}
