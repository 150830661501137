import moment from 'moment';
import { Calender } from './styles';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';
import { useSelector } from 'react-redux';
interface ActionProps {
  date: Date;
  textBlack?: boolean;
}
const Actions = (props: ActionProps) => {
  const { date, textBlack = false } = props;
  //use selector state variables
  const userDetailsSelector = useSelector((state: RootReducerInterface) => state.auth);
  const { userDetails } = userDetailsSelector;
  return (
    <Calender textBlack={textBlack}>
      <p className='text-center'>{date ? moment(date).format(`ddd, ${userDetails?.dateFormat}`) : 'No Due Date'}</p>
    </Calender>
  );
};

export default Actions;
