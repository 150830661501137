import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getUrlParam, isEmpty } from '../../helpers/common';
import { useDispatch, useSelector } from 'react-redux';
import { stripeRedirect } from '../../services/settingServices';
import { ROUTES } from '../../routes/urls';
import AppLayout from '../../component/appLayout';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';

function StripePayment() {
  //use selector state variables
  const workspaceSelector = useSelector((state: RootReducerInterface) => state.workspace);
  const { workspace } = workspaceSelector;
  const { id: workspace_id } = workspace;
  // Other variables
  const history = useHistory();
  const dispatch = useDispatch();
  const user_id = getUrlParam(history.location.search, 'user_id');
  const account_id = getUrlParam(history.location.search, 'account_id');
  const install_signature = getUrlParam(history.location.search, 'install_signature');
  const stripe_user_id = getUrlParam(history.location.search, 'stripe_user_id');
  const state = getUrlParam(history.location.search, 'state');
  const livemode = getUrlParam(history.location.search, 'livemode');

  const loadData = useCallback(async () => {
    if (!isEmpty(workspace_id)) {
      const paramsObj = {
        user_id,
        account_id,
        install_signature,
        stripe_user_id,
        state: isEmpty(state) ? undefined : state,
        livemode: livemode === 'false' ? false : true
      };
      await dispatch(stripeRedirect(paramsObj, workspace_id));
      history.push(ROUTES.PAYMENT_SETTING);
    }
  }, [account_id, dispatch, history, install_signature, livemode, state, stripe_user_id, user_id, workspace_id]);

  useEffect(() => {
    loadData();
  }, [loadData]);
  return <AppLayout>Loading ...</AppLayout>;
}

export default StripePayment;
