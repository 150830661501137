import { combineReducers } from 'redux';
import { createReducer } from '../helpers/reduxHelpers';
import * as Actions from '../actions/types';
import { current, PayloadAction, createReducer as createReducerOrig } from '@reduxjs/toolkit';
import { LogDataInterface } from '../interfaces/TimeTrackingInterface';

const setTimeTrackingLoaderReducer = createReducer({
  initialState: false,
  actionType: Actions.SET_TIME_TRACKING_LOADER
});

const setTimeTrackingDataReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_TIME_LOG_DATA
});

const initialState = {
  active: true,
  date: '',
  day: '',
  formated_date: '',
  hours: 0.0,
  index: 0,
  logData: []
};
const setCurrentTimeTrackingReducer = createReducerOrig(initialState, (builder) => {
  builder
    .addCase(Actions.SET_CURRENT_TIME_TRACKING, (state = initialState, action: PayloadAction<any, never>) => {
      return { ...(action.payload || {}) };
    })
    .addCase(Actions.UPDATE_TASK_DETAILS, (state, action: PayloadAction<any, never>) => {
      let logDataClone = JSON.parse(JSON.stringify(current(state.logData)));
      const index = logDataClone?.findIndex((item: LogDataInterface) => item?.id === action?.payload?.id);
      logDataClone[index] = action?.payload;
      return { ...(state || {}), logData: logDataClone };
    })
    .addCase(Actions.CLEAR_CURRENT_TIME_TRACKING, () => {
      const project = JSON.parse(JSON.stringify(initialState));
      return project;
    });
});

const initialInputState = {
  companyId: '',
  date: '',
  formated_date: '',
  hours: '00:00',
  isBillable: true,
  notes: '',
  projectId: '',
  // taskGroupId: '',
  taskId: ''
  // startTime: null,
  // endTime: null,
  // status: "Pending"
};
const setTimeEntryInputReducer = createReducerOrig(initialInputState, (builder) => {
  builder
    .addCase(Actions.SET_TIME_ENTRY_INPUT, (state = initialInputState, action: PayloadAction<any, never>) => {
      return { ...(action.payload || {}) };
    })
    .addCase(Actions.UPDATE_TIME_ENTRY_INPUT, (state, action: PayloadAction<any, never>) => {
      const workspace = JSON.parse(JSON.stringify(current(state)));
      workspace[action.payload.propsName] = action.payload.value;
      return workspace;
    })
    .addCase(Actions.CLEAR_TIME_ENTRY_INPUT, () => {
      const input = JSON.parse(JSON.stringify(initialInputState));
      return input;
    });
});

const initialListState = {
  projectList: [],
  // taskGroupList: [],
  taskList: []
};
const listDropdownReducer = createReducerOrig(initialListState, (builder) => {
  builder
    .addCase(Actions.SET_LIST_DROPDOWN, (state = initialListState, action: PayloadAction<any, never>) => {
      return { ...(action.payload || {}) };
    })
    .addCase(Actions.UPDATE_LIST_DROPDOWN, (state, action: PayloadAction<any, never>) => {
      const workspace = JSON.parse(JSON.stringify(current(state)));
      workspace[action.payload.propsName] = action.payload.value;
      return workspace;
    })
    .addCase(Actions.CLEAR_LIST_DROPDOWN, () => {
      const input = JSON.parse(JSON.stringify(initialListState));
      return input;
    });
});

const setTimeTrackingActivityReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_TIME_TRACKING_ACTIVITY
});

const timeTrackingReportDataReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_TIMRE_TRACKING_REPORT_DATA
});

const initialReportFilter = {
  selectedDataType: 1,
  timeViewType: 1,
  chartType: 1
};
const reportFilterReducer = createReducerOrig(initialReportFilter, (builder) => {
  builder
    .addCase(Actions.SET_REPORT_FILTER, (state = initialReportFilter, action: PayloadAction<any, never>) => {
      return { ...(action.payload || {}) };
    })
    .addCase(Actions.UPDATE_REPORT_FILTER, (state, action: PayloadAction<any, never>) => {
      const workspace = JSON.parse(JSON.stringify(current(state)));
      workspace[action.payload.propsName] = action.payload.value;
      return workspace;
    })
    .addCase(Actions.CLEAR_REPORT_FILTER, () => {
      const input = JSON.parse(JSON.stringify(initialReportFilter));
      return input;
    });
});

const currentStartTimeReducer = createReducer({
  initialState: { task_id: undefined, time: 0 },
  actionType: Actions.SET_CURRENT_TRACKING_TIME
});

const setMyTimeLogDataReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_MY_TIME_LOG_DATA
});

const setMyTimeLogFilterReducer = createReducer({
  initialState: {},
  actionType: Actions.SET_MY_TIME_LOG_FILTER
});

const timeTrackingReducer = combineReducers({
  loading: setTimeTrackingLoaderReducer,
  timeTackingData: setTimeTrackingDataReducer,
  currentTimeTracking: setCurrentTimeTrackingReducer,
  timeEntryInput: setTimeEntryInputReducer,
  trackingActivity: setTimeTrackingActivityReducer,
  listDropdown: listDropdownReducer,
  timeTrackingReportData: timeTrackingReportDataReducer,
  reportFilter: reportFilterReducer,
  currentStartTime: currentStartTimeReducer,
  myTimeLog: setMyTimeLogDataReducer,
  myTimeLogFilter: setMyTimeLogFilterReducer
});

export default timeTrackingReducer;
