import { useCallback, useEffect, useState } from 'react';
import AppLayout from '../../component/appLayout';
import Navbarmessagedata from '../../component/navbarmessagedata';
import { getAllMessages, getChatGroupList, setLastReadForDiscussion } from '../../services/chatMessageService';
import { useDispatch, useSelector } from 'react-redux';
import { ChatGroupInterface } from '../../interfaces/chatMessageInterface';
import {
  setChatGroups,
  setCurrentChatGroup,
  setPinChatGroups,
  setUnpinChatGroups
} from '../../actions/chatMessageActions';
import { getUrlParam, isEmpty } from '../../helpers/common';
import { CHAT_GROUP_TYPE } from '../../global/constants';
import { useHistory } from 'react-router-dom';
import { getProjectFiles } from '../../services/projectServices';
import { captureException } from '../../services/logService';
import MessageGroupCacheService from '../../services/messageGroupCacheService';
import { app, database } from '../../utils/firebase';
import { collection, deleteDoc, doc, getFirestore, onSnapshot } from 'firebase/firestore';
import UserPreferenceSingleton from '../../helpers/userPreferenceSingleton';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';

export default function Navbarmessages() {
  // States
  const [loading, setLoading] = useState(false);

  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { workspace: workspaceSelector } = stateSelector || {};
  const { workspace } = workspaceSelector || {};

  // Other variables
  const dispatch = useDispatch();
  const history = useHistory();

  // update current group
  const changeCurrentGroup = useCallback(
    async (sortedData: ChatGroupInterface[]) => {
      const group = getUrlParam(history.location.search, 'chatGroup');
      if (sortedData?.length > 0 && isEmpty(group)) {
        if (sortedData[0]?.type === CHAT_GROUP_TYPE.PROJECT) {
          await dispatch(getProjectFiles(sortedData[0]?.id));
        }
        const lastOpenGroupId = UserPreferenceSingleton.getInstance().getLastMessageGroup();
        const lastOpenGroup = sortedData?.find((x) => x?.id === lastOpenGroupId);
        if (lastOpenGroup) {
          dispatch(setCurrentChatGroup(lastOpenGroup));
        } else {
          dispatch(setCurrentChatGroup(sortedData[0]));
        }
      } else if (sortedData?.length > 0) {
        const currentGroup = sortedData?.find((x) => x?.id === group) || { id: '', name: '', type: 0 };
        if (currentGroup?.type === CHAT_GROUP_TYPE.PROJECT) {
          await dispatch(getProjectFiles(currentGroup?.id));
        }
        dispatch(setCurrentChatGroup(currentGroup));
      }
    },
    [dispatch, history.location.search]
  );

  // Filter pinned groups
  const filterPinnedGroups = useCallback((groups: ChatGroupInterface[]) => {
    return groups
      .filter((group) => group?.order !== undefined && group?.order !== null) // Filter pinned groups
      .sort((a, b) => (a?.order ?? 0) - (b?.order ?? 0));
  }, []);

  // Filter unpinned groups
  const filterUnpinnedGroups = useCallback(
    (groups: ChatGroupInterface[]) => groups.filter((group) => group?.order === undefined || group?.order === null),
    []
  );

  // load initial data
  const loadData = useCallback(
    async (isSetInitially?: boolean) => {
      try {
        if (!isEmpty(workspace?.id)) {
          setLoading(true);
          if (isSetInitially) {
            const localGroups = await MessageGroupCacheService.getInstance().getMessageGroups();
            if (localGroups?.length > 0) {
              dispatch(setChatGroups(localGroups));
              dispatch(setPinChatGroups(filterPinnedGroups(localGroups)));
              dispatch(setUnpinChatGroups(filterUnpinnedGroups(localGroups)));
              changeCurrentGroup(localGroups);
            }
          }
          const response = await Promise.all([dispatch(getChatGroupList()), dispatch(getAllMessages())]);
          if (response) {
            const updatedData = await MessageGroupCacheService.getInstance().getMessageGroups();
            dispatch(setChatGroups(updatedData));
            dispatch(setPinChatGroups(filterPinnedGroups(updatedData)));
            dispatch(setUnpinChatGroups(filterUnpinnedGroups(updatedData)));
            changeCurrentGroup(updatedData);
          }
        }
      } catch (e) {
        captureException(e);
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, workspace?.id]
  );

  useEffect(() => {
    loadData(true);
  }, [loadData]);

  // remove firebase data field
  const removeFirebaseDataField = useCallback(async (uniqueId: string) => {
    const docRef = doc(database, 'messages', uniqueId);
    await deleteDoc(docRef);
  }, []);

  useEffect(() => {
    const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
    if (userDetails?.id && workspace?.id) {
      const firestore = getFirestore(app);
      const unsubscribe = onSnapshot(collection(firestore, `messages`), (snapshot) => {
        const newData = snapshot?.docs?.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }));
        const uniqueId = `${workspace?.id}_${userDetails?.id}`;
        const isDataUpdated = newData?.find((x) => x?.id === uniqueId);
        if (isDataUpdated) {
          loadData();
          removeFirebaseDataField(uniqueId);
        }
      });

      return () => {
        unsubscribe();
      };
    }
  }, [loadData, removeFirebaseDataField, workspace?.id]);

  const onLeaveMessages = useCallback(
    async (isCallFromReturn: boolean) => {
      if (isCallFromReturn) {
        await dispatch(setLastReadForDiscussion());
        dispatch(setCurrentChatGroup({}));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    return () => {
      onLeaveMessages(true);
    };
  }, [onLeaveMessages]);

  return (
    <AppLayout isMessageBox={true}>
      <Navbarmessagedata loadData={() => loadData(false)} loading={loading} />
    </AppLayout>
  );
}
