import { combineReducers } from 'redux';
import { createReducer } from '../helpers/reduxHelpers';
import * as Actions from '../actions/types';
import { createReducer as createReducerOrig, PayloadAction } from '@reduxjs/toolkit';
import { ProjectReportInterface } from '../interfaces/ReportInterface';

const setReportLoaderReducer = createReducer({
  initialState: false,
  actionType: Actions.SET_REPORT_LOADER
});

const setReportTasksReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_REPORT_TASK_DETAILS
});

const setFilteredReportTaskReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_FILTERED_REPORT_TASK_DETAILS
});

const paymentReportDataReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_PAYMENT_REPORT_DATA
});

const filteredPaymentReportDataReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_FILTERED_PAYMENT_REPORT_DATA
});

const filteredInvoiceReportDataReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_FILTERED_INVOICE_REPORT_DATA
});

const InvoiceReportDataReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_INVOICE_REPORT_DATA
});

const projectReportDataReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_PROJECT_REPORT_DATA
});

const initialState: Array<any> = [];
const filteredProjectReportDataReducer = createReducerOrig(initialState, (builder) => {
  builder
    .addCase(
      Actions.SET_FILTERED_PROJECT_REPORT_DATA,
      (state = initialState, action: PayloadAction<ProjectReportInterface[], never>) => {
        return [...(action.payload || [])];
      }
    )
    .addCase(
      Actions.UPDATE_FILTERED_PROJECT_REPORT_DATA,
      (state, action: PayloadAction<ProjectReportInterface, never>) => {
        const updatedItem = action.payload;
        const updatedState = state.map((item) => (item.id === updatedItem.id ? updatedItem : item));
        return updatedState;
      }
    );
});

const setTeamMemberDataReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_TEAM_MEMBER_REPORT_DATA
});

const setFilterTeamMemberDataReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_FILTERED_TEAM_MEMBER_REPORT_DATA
});

const reportReducer = combineReducers({
  loading: setReportLoaderReducer,
  reportTasks: setReportTasksReducer,
  filteredReportTask: setFilteredReportTaskReducer,
  paymentReportData: paymentReportDataReducer,
  filteredPaymentReportData: filteredPaymentReportDataReducer,
  filteredInvoiceReportData: filteredInvoiceReportDataReducer,
  invoiceReportData: InvoiceReportDataReducer,
  projectReportData: projectReportDataReducer,
  filteredProjectReportData: filteredProjectReportDataReducer,
  teamMemberData: setTeamMemberDataReducer,
  filterTeamMemberData: setFilterTeamMemberDataReducer
});

export default reportReducer;
