import styled, { css } from 'styled-components';
import { Typography } from '../../../global/theme';
interface Props {
  isborderredius?: boolean;
  ispayment?: boolean;
  isproject?: boolean;
  isreportinvoice?: boolean;
  istasks?: boolean;
  isteamtimeline?: boolean;
  isLoading?: boolean;
  isProjectDropdown?: boolean;
}

const Table = styled.table<Props>`
  border-collapse: collapse;
  width: 100%;
  border-style: hidden;
  border-radius: 8px;
  box-shadow: 0 0 0 0.5px var(--border-primary);
  background: var(--background-primary);
  td {
    border: 0.5px solid var(--border-primary);
    text-align: left;
    padding: 10px 12px;
    ${Typography.body_md_medium}
    color: var(--text-primary);
    ${(props) =>
      props.istasks &&
      css`
        white-space: nowrap;
      `}
    ${(props) =>
      props.isreportinvoice &&
      css`
        white-space: nowrap;
      `}
  }
  th {
    border: 0.5px solid var(--border-primary);
    text-align: left;
    padding: 10px 12px;
    ${Typography.body_md_medium}
    text-transform: uppercase;
    color: var(--text-secondary);
    background-color: var(--background-tertiary);
    border-radius: 0;
    :last-child {
      border-radius: 0 8px 0 0;
    }
    :first-child {
      border-radius: 8px 0 0 0;
    }
  }
  tr td:first-child {
    ${(props) =>
      props.ispayment &&
      css`
        width: 100px;
      `}
    ${(props) =>
      props.isproject &&
      css`
        width: 146px;
        cursor: pointer;
      `}
    ${(props) =>
      props.isreportinvoice &&
      css`
        width: 76px;
      `}
  }
  tr td:last-child {
    ${(props) =>
      (props.ispayment || props.isreportinvoice) &&
      css`
        text-align: right;
        width: 146px;
      `}
  }
  tr th:last-child {
    ${(props) =>
      (props.ispayment || props.isreportinvoice) &&
      css`
        text-align: right;
      `}
  }
  ${(props) =>
    props.isreportinvoice &&
    css`
      th:nth-last-child(2),
      td:nth-last-child(2) {
        text-align: right;
      }
    `}
  tr {
    .paid {
      color: var(--accent-success);
    }
    .overDue {
      color: var(--accent-error);
    }
    .pending {
      color: var(--accent-caution);
    }
    .draft {
      color: var(--text-secondary);
    }
    .partiallyPaid {
      color: var(--accent-secondary);
    }
  }
  .task-name {
    white-space: normal;
  }
  .sub-row {
    color: var(--text-secondary);
  }
  .leave-link {
    ${Typography.body_md_medium}
    color: var(--text-secondary);
  }
  .join-link {
    ${Typography.body_md_medium}
    color: var(--brand-primary);
  }
  .first-column {
    width: 388px;
  }
  .second-column {
    width: 170px;
    @media (max-width: 1024px) {
      width: 190px;
    }
  }
  .third-column {
    width: 170px;
    @media (max-width: 1024px) {
      width: 150px;
    }
  }
  .forth-column {
    width: 74px;
  }
  @media (max-width: 449px) {
    ${(props) =>
      props.ispayment &&
      css`
        white-space: nowrap;
      `}
    tr td:last-child {
      ${(props) =>
        props.ispayment &&
        css`
          text-align: left;
          width: 100%;
        `}
    }
  }
`;

const Dropheader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 12px;
  border-bottom: 0.5px solid var(--border-primary);
`;

const Inputarea = styled.input`
  max-width: 100%;
  width: 130px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  ${Typography.body_md}
  color: var(--text-primary);
  margin: 0;
  padding: 0;
  border: none;
  background-color: var(--background-primary);
  :focus {
    outline: none;
  }
  .placeholder {
    color: var(--text-secondary);
  }
`;

const Bottomdata = styled.div<Props>`
  height: 100%;
  max-height: 192px;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  ${(props: Props) =>
    props.isLoading &&
    css`
      opacity: 0.8;
      cursor: default;
      pointer-events: none;
    `}
`;

const Createitem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 4px 10px;
  :hover {
    background: var(--neutral-secondary);
    cursor: pointer;
  }
  p {
    ${Typography.body_md}
    color: var(--text-primary);
    margin: 0;
  }
`;

const Check = styled.div`
  display: flex;
  gap: 10px;
  .checkbox-round {
    width: 7px;
    height: 7px;
    border-radius: 2px;
    vertical-align: middle;
    border: 1px solid var(--border-secondary);
    appearance: none;
    outline: none;
    cursor: pointer;
    margin: 0;
    padding: 7px;
  }
  input[type='checkbox'] {
    position: relative;
    cursor: pointer;
  }
  input[type='checkbox']:checked {
    background-color: var(--brand-primary);
    border: 1px solid var(--brand-primary);
  }
  input[type='checkbox']:checked::before {
    content: '';
    position: absolute;
    right: 50%;
    top: 50%;
    width: 3px;
    height: 8px;
    border: solid var(--text-white);
    border-width: 0 2px 2px 0;
    margin: -0.5px -1px 0 -1px;
    transform: rotate(45deg) translate(-50%, -50%) scale(0.7);
    z-index: 2;
  }
`;

const Dropbox = styled.div<Props>`
  position: absolute;
  width: 100%;
  max-width: 154px;
  background: var(--background-primary);
  border-radius: 8px;
  border: 0.5px solid var(--border-primary);
  padding: 0;
  margin-top: 5px;
  z-index: 99;
  ${(props) =>
    (props.isreportinvoice || props.isteamtimeline) &&
    css`
      max-width: 152px;
    `}
  ${(props: Props) =>
    props.isLoading &&
    css`
      opacity: 0.8;
      cursor: default;
      pointer-events: none;
    `}
    @media (max-width: 449px) {
    max-width: 180px;
  }
  ${(props) =>
    props.isProjectDropdown &&
    css`
      @media (max-width: 449px) {
        max-width: calc(100vw - 32px) !important;
      }
    `}
  ${(props) =>
    props.isreportinvoice &&
    css`
      @media (max-width: 449px) {
        max-width: calc(50% - 8px);
      }
    `}
`;

const TaskPending = styled.div`
  color: var(--accent-error);
  width: 146px;
  ${Typography.body_md_medium}
`;

const TaskDone = styled.p`
  color: var(--accent-success);
  width: 146px;
  ${Typography.body_md_medium}
  margin: 0;
`;

const CutstomItemDiv = styled.div`
  max-height: 296px;
  overflow: auto;
  scrollbar-width: none;
`;
export { Dropbox, Table, Dropheader, Inputarea, Bottomdata, Createitem, Check, TaskDone, TaskPending, CutstomItemDiv };
