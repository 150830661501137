/* eslint-disable max-len */
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import SVGIcon from '../../assets/images/svg/SVGIcon';
import { ButtonBlock } from '../../pages/project/details/tasks/taskDetail/styles';
import {
  CloseButton,
  DummyDiv,
  FileBoxContainer,
  First,
  FooterIcon,
  FooterIcons,
  FooterDiv,
  Icon,
  Main,
  TextMainDiv,
  Top,
  Second,
  FileIconDiv,
  UploadDiv,
  SvgDiv,
  ButtonComponent,
  ButtonRight,
  FileBox,
  ButtonLeft,
  MainDiv
} from './styles';
import { extensionName, getUrlParam, handleFileUpload, isMediaRecordingSupported } from '../../helpers/common';
import { useDispatch, useSelector } from 'react-redux';
import { setErrorMessage } from '../../actions/messageActions';
import Button from '../button';
import CommentDropdown from '../dropdowns/commentDropdown/commentdropdown';
import { EmojiClickData } from 'emoji-picker-react';
import FileSVGIcon from '../../assets/images/svg/filesIconSvg';
import { FILE_EXTENTIONS, FILE_TYPES } from '../../global/constants';
import { ReplyMessageDataInterface } from '../../interfaces/MessageInterface';
import { captureException } from '../../services/logService';
import RecordingComment from './recordingComment';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';
import { useHistory } from 'react-router-dom';
import { CommentFilesInterface } from '../../interfaces/TaskInterface';
import { UserInterface } from '../../interfaces/chatMessageInterface';
import TiptapEditor from '../tiptapEditor';
import Emoji from '../emojiPicker/emojiPicker';
import ModalCustom from '../models/modal';
import InsertGifModal from '../models/insertGifModal';
import { Dropdown } from '../Dropdown';

interface Props {
  placeholder: string;
  isApplyMaxHeight: number;
  isApplyMinHeight: number;
  uploadedFiles: CommentFilesInterface[];
  setUploadedFiles: any;
  fieldValue: string;
  setValueContent: (value: string) => void;
  propsLoading?: boolean;
  setPropsLoading?: (value: boolean) => void;
  iscommentModel?: boolean;
  isCommentModelContent?: boolean;
  ishideicon?: boolean;
  sendComment?: (files: CommentFilesInterface[]) => void;
  users?: UserInterface[];
  isInlineToolbar?: boolean;
  isApplyMargin?: number;
  iscommentModelPadding?: true;
  onClickSelectFile?: () => void;
  setAttachment?: (value: boolean) => void;
  isTaskModel?: boolean;
  disable?: boolean;
  replyMessage?: any;
  setReplyMessage?: (value: ReplyMessageDataInterface) => void;
  handleOpenModal?: () => void;
  iscreateTask?: boolean;
  isMessageModel?: boolean;
  isApplyMsgModel?: boolean;
  isplaceholder?: boolean;
  isMessageMentionModal?: boolean;
  openModal?: () => void;
  isPlaceholdercolor?: boolean;
  isPaddingright?: boolean;
  isResponsiveMinHeight?: number;
  isTaskDetailComment?: boolean;
  isFileCard?: boolean;
  isSelectFileEnable?: boolean;
  isScrollbarnone?: boolean;
  isEveryoneMentionEnable?: boolean;
  onClickSendFeedback?: (e: React.SyntheticEvent) => void;
  contactUsButtonLoading?: boolean;
  onCancelSendFeedback?: () => void;
  isContactUsModal?: boolean;
  isScrollbarDesign?: boolean;
}

const Inline = forwardRef((props: Props, ref) => {
  const {
    placeholder,
    isApplyMaxHeight,
    isApplyMinHeight,
    uploadedFiles,
    setUploadedFiles,
    fieldValue,
    setValueContent,
    propsLoading = false,
    setPropsLoading,
    iscommentModel = false,
    isCommentModelContent,
    ishideicon,
    sendComment,
    users,
    isInlineToolbar = false,
    isApplyMargin,
    iscommentModelPadding,
    onClickSelectFile,
    setAttachment,
    isTaskModel,
    disable = false,
    replyMessage,
    setReplyMessage,
    isSelectFileEnable = true,
    isScrollbarnone = false,
    handleOpenModal,
    isEveryoneMentionEnable = false,
    iscreateTask,
    isMessageModel,
    isApplyMsgModel,
    isplaceholder,
    openModal,
    isPlaceholdercolor,
    isPaddingright,
    isResponsiveMinHeight,
    isTaskDetailComment,
    isFileCard,
    onClickSendFeedback,
    contactUsButtonLoading,
    onCancelSendFeedback,
    isContactUsModal = false,
    isScrollbarDesign
  } = props;
  //Refs
  const editorRef = useRef<any>(null);
  const emojiPickerRef = useRef<HTMLDivElement | null>(null);
  const history = useHistory();
  const group = getUrlParam(history.location.search, 'chatGroup');
  const previousGroupId = useRef(group);
  const commentAttachFileRef = useRef<HTMLDivElement | null>(null);
  const emojiButtonRef = useRef<HTMLDivElement | null>(null);
  //States
  const [isCommentAttachment, setIsCommentAttachment] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [buttonDisable, setButtonDisable] = useState<boolean>(false);
  const [isEmojiDropdownOpen, setIsEmojiDropdownOpen] = useState(false);
  const [openGifModal, setIsOpenGifModal] = useState(false);
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { project: projectSelector, auth: authSelector } = stateSelector || {};
  const { uploadFilePercent } = projectSelector;
  const { userDetails } = authSelector;
  //Other variable
  const dispatch = useDispatch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const usersWithAll = [
    { id: 'everyone', name: 'Everyone', profile_photo: '', value: 'everyone', email: 'everyone' },
    ...(users?.filter((user) => user?.id !== userDetails?.id) || [])
  ];

  useEffect(() => {
    if (group && previousGroupId.current !== group) {
      setUploadedFiles([]);
      editorRef?.current?.clearValue();
      setAttachment && setAttachment(false);
    }
    previousGroupId.current = group;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group]);

  useImperativeHandle(ref, () => ({
    addRecordingCard(fileUrl: string, fileName: string) {
      editorRef.current.addRecordingCard(fileUrl, fileName);
    },
    addImage(fileUrl: string) {
      editorRef.current.addImage(fileUrl);
    },
    clearContent() {
      editorRef?.current?.clearValue();
    }
  }));

  const openEmojiDropdown = useCallback((value: boolean) => {
    setIsEmojiDropdownOpen(value);
  }, []);

  useEffect(() => {
    if (isEmojiDropdownOpen) {
      setTimeout(() => {
        if (emojiPickerRef.current) {
          const searchInput = emojiPickerRef.current.querySelector('input');
          if (searchInput) {
            searchInput.focus();
          }
        }
      }, 0);
    }
  }, [isEmojiDropdownOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        isEmojiDropdownOpen &&
        emojiPickerRef.current &&
        event.target instanceof Node &&
        !emojiPickerRef.current.contains(event.target as Node)
      ) {
        setIsEmojiDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEmojiDropdownOpen]);

  const onSendComment = useCallback(() => {
    setUploadedFiles([]);
    setValueContent('');
    if (sendComment) sendComment(uploadedFiles);
  }, [sendComment, setUploadedFiles, setValueContent, uploadedFiles]);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onFileUpload = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target;
      if (!files || files?.length === 0) {
        dispatch(setErrorMessage('No files selected.'));
        return;
      }

      try {
        setLoading(true);
        const fileArray = Array.from(files); // Convert FileList to Array

        //call common function to upload file in firebase
        const updatedFiles = await handleFileUpload(uploadedFiles || [], fileArray, dispatch, editorRef);
        const value = [...(uploadedFiles || []), ...(updatedFiles || [])];
        setUploadedFiles(value);
      } catch (error) {
        captureException(error);
        dispatch(setErrorMessage('Error occurred while uploading files.'));
      } finally {
        setLoading(false);
      }
    },
    [dispatch, uploadedFiles, editorRef, setUploadedFiles]
  );

  const onClickClose = useCallback(
    (index: number) => {
      const filesClone = JSON.parse(JSON.stringify(uploadedFiles));
      filesClone.splice(index, 1);
      setUploadedFiles(filesClone);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    },
    [uploadedFiles, setUploadedFiles]
  );

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    // Add styles to indicate that the area can accept drops
    e.dataTransfer.dropEffect = 'copy';
  };

  const handleEmojiSelected = (emojiData: EmojiClickData) => {
    editorRef.current.addContentExistingLine(emojiData.emoji);
  };

  const uploadSnapEmbed = useCallback((firebaseUrl: string, fileName: string, videoId: string) => {
    editorRef?.current?.addRecordingCard(firebaseUrl, fileName, videoId);
  }, []);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
      event.preventDefault(); // Prevent default behavior if needed
      onSendComment();
    }
  };

  const addDroppedFiles = useCallback(
    (files: CommentFilesInterface[]) => {
      setUploadedFiles((prevFiles: CommentFilesInterface[]) => [...(prevFiles || []), ...(files || [])]);
    },
    [setUploadedFiles]
  );

  const handleOpenGifModal = useCallback(() => {
    setIsOpenGifModal(true);
  }, []);

  const handleCloseGifModal = useCallback(() => {
    setIsOpenGifModal(false);
  }, []);

  const getUsersProp = useCallback(() => {
    if (isTaskModel) {
      return [];
    } else if (isEveryoneMentionEnable) {
      return usersWithAll;
    } else {
      return users?.filter((user) => user?.id !== userDetails?.id);
    }
  }, [isEveryoneMentionEnable, isTaskModel, userDetails?.id, users, usersWithAll]);

  const renderFooterContent = useCallback(() => {
    if (isMessageModel) {
      return (
        <Icon disable={loading || propsLoading} onClick={onSendComment.bind(this)}>
          <SVGIcon name='send-discuss-icon' width='32' height='32' viewBox='0 0 32 32' className='fillColor' />
        </Icon>
      );
    }
    if (isContactUsModal) {
      return (
        <ButtonComponent>
          <ButtonLeft>
            <SvgDiv isContactUsModal onClick={handleFileInput}>
              <SVGIcon
                name='message-attachment-icon'
                width='32'
                height='32'
                viewBox='0 0 32 32'
                className='attachment-fill-Color'
              />
            </SvgDiv>
            {loading && <UploadDiv>Uploading ({Math.floor(uploadFilePercent)}%)</UploadDiv>}
          </ButtonLeft>
          <ButtonRight>
            <Button title='Cancel' secondary modelbtn hasNotBoxshadow onClick={onCancelSendFeedback} />
            <Button title='Send message' modelbtn onClick={onClickSendFeedback} isLoading={contactUsButtonLoading} />
          </ButtonRight>
        </ButtonComponent>
      );
    }
    return (
      <Button
        title='Send'
        smallbutton={true}
        onClick={onSendComment.bind(this)}
        disabled={loading || propsLoading || buttonDisable}
        commentbtn={true}
      />
    );
  }, [
    buttonDisable,
    contactUsButtonLoading,
    isContactUsModal,
    isMessageModel,
    loading,
    onCancelSendFeedback,
    onClickSendFeedback,
    onSendComment,
    propsLoading,
    uploadFilePercent
  ]);

  return (
    <>
      <MainDiv
        isApplyMsgModel={isApplyMsgModel}
        onDragOver={handleDragOver}
        isContactUsModal={uploadedFiles?.length > 0 ? isContactUsModal : false}>
        <Main
          isApplyMinHeight={String(isApplyMinHeight)}
          isResponsiveMinHeight={String(isResponsiveMinHeight)}
          isApplyMaxHeight={String(isApplyMaxHeight)}
          isApplyMsgModel={isApplyMsgModel}
          isApplyMargin={String(isApplyMargin)}
          isInlineToolbar={!isInlineToolbar}
          isplaceholder={isplaceholder}
          isScrollbarnone={isScrollbarnone}
          isPaddingright={isPaddingright}>
          <TextMainDiv
            isApplyCss={isTaskModel}
            isApplyMsgModel={isApplyMsgModel}
            isPlaceholdercolor={isPlaceholdercolor}
            onKeyDown={handleKeyDown}
            isScrollbarDesign={isScrollbarDesign}
            isTaskDetailComment={isTaskDetailComment}>
            {!disable && (
              <>
                <TiptapEditor
                  ref={editorRef}
                  valueContent={fieldValue}
                  setValueContent={(value: string) => setValueContent(value)}
                  placeHolder={placeholder}
                  setLoading={(value: boolean) => {
                    if (setPropsLoading) setPropsLoading(value);
                    else setLoading(value);
                  }}
                  setStateLoading={(value: boolean) => setLoading(value)}
                  addUploadedFiles={addDroppedFiles}
                  replyMessage={replyMessage}
                  setReplyMessage={setReplyMessage}
                  users={getUsersProp()}
                  handleFileInput={handleFileInput}
                  handleGifModal={handleOpenGifModal}
                />
              </>
            )}
            {disable && <DummyDiv></DummyDiv>}
            {iscommentModel && (
              <Top onClick={openModal} ishideicon={ishideicon}>
                <SVGIcon
                  name='comment-top-icon'
                  width='14'
                  height='14'
                  viewBox='0 0 14 14'
                  stroke='var(--text-secondary)'
                />
              </Top>
            )}
          </TextMainDiv>
        </Main>

        <input type='file' ref={fileInputRef} style={{ display: 'none' }} onChange={onFileUpload} multiple />
        {!disable && uploadedFiles?.length > 0 && (
          <FileBox
            isApplyMargin={uploadedFiles?.length > 0 && isMessageModel ? '32px' : '0'}
            padding={iscommentModelPadding ? '16px' : '0'}
            isContactUsModal={isContactUsModal}>
            {uploadedFiles?.length > 0 &&
              uploadedFiles?.map((file: any, index: number) => {
                const extension = extensionName(file).toLowerCase();
                return (
                  <FileBoxContainer key={file?.name} isFileCard={isFileCard} isContactUsModal={isContactUsModal}>
                    <div className='file-box'>
                      <div className='svg'>
                        {(!file?.uploadFileType || file?.uploadFileType === FILE_TYPES.UPLOAD) &&
                        !FILE_EXTENTIONS.includes(extension) ? (
                          <FileIconDiv>
                            <FileSVGIcon name={'blank'} height='32' width='32' viewBox='0 0 60 60' />
                            <div
                              style={{
                                fontSize: extension?.length === 5 ? 6 : 7
                              }}
                              className='file-text'>
                              {extension.split('.')?.pop()?.toUpperCase()}
                            </div>
                          </FileIconDiv>
                        ) : (
                          <FileSVGIcon
                            name={
                              !file?.uploadFileType || file?.uploadFileType === FILE_TYPES.UPLOAD
                                ? extension
                                : `icon_${file?.uploadFileType}`
                            }
                            height='32'
                            width='32'
                            viewBox='0 0 60 60'
                          />
                        )}
                      </div>
                      <div className='textarea'>
                        <h4 className='h4'>{file.name}</h4>
                        {parseFloat(file?.size) > 0 && <p className='p'>{file?.size}</p>}
                      </div>
                      <CloseButton
                        onClick={() => {
                          onClickClose(index);
                        }}>
                        ×
                      </CloseButton>
                    </div>
                  </FileBoxContainer>
                );
              })}
          </FileBox>
        )}
        {loading && !isContactUsModal && (
          <UploadDiv iscommentModelPadding={iscommentModelPadding}>
            Uploading ({Math.floor(uploadFilePercent)}%)
          </UploadDiv>
        )}
      </MainDiv>
      <FooterDiv isInlineToolbar={isInlineToolbar}>
        <ButtonBlock isCommentModelContent={isCommentModelContent} contactusModal={isContactUsModal}>
          {isMessageModel && (
            <>
              <FooterIcon
                ref={emojiPickerRef}
                style={{ position: 'relative' }}
                onClick={() => openEmojiDropdown(!isEmojiDropdownOpen)}
                isActive={isEmojiDropdownOpen}>
                <SVGIcon name='message-emoji-icon' width='32' height='32' viewBox='0 0 32 32' className='fillColor' />
                {isEmojiDropdownOpen && (
                  <Emoji
                    isOpenEmoji={isEmojiDropdownOpen}
                    setIsOpenEmoji={setIsEmojiDropdownOpen}
                    onEmojiClick={handleEmojiSelected}
                    emojiButtonref={emojiPickerRef}
                  />
                )}
              </FooterIcon>
              <Dropdown
                content={
                  <FooterIcon
                    style={{ position: 'relative' }}
                    loading={loading}
                    isModel={isTaskModel}
                  >
                    <SVGIcon
                      name='message-attachment-icon'
                      width='32'
                      height='32'
                      viewBox='0 0 32 32'
                      className='attachment-fill-Color'
                    />
                  </FooterIcon>
                }
                trigger='click'>
                <CommentDropdown
                  onClickUploadFile={handleFileInput}
                  onClickSelectFile={onClickSelectFile}
                  isSelectFileEnable={isSelectFileEnable}
                />
              </Dropdown>
            </>
          )}
          {isTaskModel && (
            <FooterIcon onClick={handleFileInput} loading={loading} isModel={isTaskModel} iscreateTask={iscreateTask}>
              <SvgDiv>
                <SVGIcon name='attachment-icon' width='18' height='18' viewBox='0 0 18 18' className='svg' />
              </SvgDiv>
            </FooterIcon>
          )}
          {iscommentModel && (
            <FooterIcons>
              <First
                style={{ position: 'relative' }}
                ref={commentAttachFileRef}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsCommentAttachment(!isCommentAttachment);
                }}
                isContactUsModal={isContactUsModal}>
                <SVGIcon
                  name='comment-attachment-icon'
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  className='svg-icon'
                />
                {isCommentAttachment && (
                  <CommentDropdown onClickUploadFile={handleFileInput} onClickSelectFile={onClickSelectFile} />
                )}
              </First>

              {isMediaRecordingSupported() && (
                <RecordingComment
                  setLoading={(value: boolean) => setLoading(value)}
                  fieldValue={fieldValue}
                  setValueContent={(value: string) => setValueContent(value)}
                  iscommentModel={iscommentModel}
                  setButtonDisable={(value: boolean) => setButtonDisable(value)}
                  handleOpenModal={handleOpenModal!}
                  uploadSnapEmbed={uploadSnapEmbed}
                />
              )}
              <div
                ref={emojiButtonRef}
                style={{ position: 'relative' }}
                onClick={() => openEmojiDropdown(!isEmojiDropdownOpen)}>
                <Second isActive={isEmojiDropdownOpen}>
                  <SVGIcon name='comment-emoji-icon' width='16' height='16' viewBox='0 0 16 16' className='svg-icon' />
                </Second>
                {isEmojiDropdownOpen && (
                  <Emoji
                    isOpenEmoji={isEmojiDropdownOpen}
                    emojiButtonref={emojiButtonRef}
                    onEmojiClick={handleEmojiSelected}
                    setIsOpenEmoji={setIsEmojiDropdownOpen}
                  />
                )}
              </div>
            </FooterIcons>
          )}

          {!isTaskModel && (
            <FooterIcon id='rteSubmit' isContactUsModal={isContactUsModal}>
              {renderFooterContent()}
            </FooterIcon>
          )}
        </ButtonBlock>
      </FooterDiv>
      <ModalCustom
        open={openGifModal}
        onClose={handleCloseGifModal}
        width={686}
        hasNotBoxShadow={true}
        isGifModal={true}>
        <InsertGifModal
          setSelectedGif={(url: string) => {
            editorRef.current.addImage(url);
          }}
          closeModal={handleCloseGifModal}
        />
      </ModalCustom>
    </>
  );
});
export default Inline;
