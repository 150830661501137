import styled from 'styled-components';
import { Typography } from '../../../global/theme';

const Main = styled.div``;

const ModalBlock = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid var(--border-primary);
  padding: 12px 16px;
  gap: 20px;
`;

const ModalHeader = styled.div`
  display: flex;
  gap: 10px;
`;

const ModalTitle = styled.h2`
  ${Typography.heading_md}
  color: var(--text-primary);
  margin: 0;
`;
const Icon = styled.div`
  cursor: pointer;
  display: flex;
  gap: 15px;
  :hover .fillColor {
    background-color: var(--neutral-secondary);
    border-radius: 4px;
  }
  .fillColor {
    stroke: var(--text-primary);
  }
`;
const ModalContent = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;
`;

const ModalForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ModalInput = styled.input`
  padding: 8px 12px;
  background-color: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 6px;
  ${Typography.body_lg}
  color: var(--text-primary);
  outline: none;
  :focus {
    border: 0.5px solid var(--brand-primary);
  }
  ::placeholder {
    color: var(--text-placeholder);
  }
  @media (max-width: 449px) {
    border-radius: 6px;
  }
`;
const Label = styled.div`
  ${Typography.body_md_medium}
  color: var(--text-secondary);
`;

const ModalButton = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
`;

const Dropmenu = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  p {
    margin: 0;
    color: var(--text-primary);
    ${Typography.body_sm_medium}
  }
  .teamcampicon {
    rect {
      fill: var(--background-tertiary);
      stroke: var(--border-primary);
    }
    path {
      fill: var(--text-primary);
    }
  }
`;

const Selects = styled.div`
  width: 100%;
  .angledown-icon {
    path {
      stroke: var(--text-primary);
      width: 18px;
      height: 18px;
    }
  }
  .close-icon {
    cursor: pointer;
    path {
      stroke: var(--text-secondary);
    }
    :hover {
      background-color: var(--background-muted);
      border-radius: 4px;
    }
  }
  .ant-select {
    color: var(--text-primary);
    ${Typography.body_lg}
  }
  .ant-select .ant-select-selection-placeholder {
    color: var(--text-placeholder);
    ${Typography.body_lg}
  }
  .ant-select-multiple .ant-select-selector {
    padding: 3px 7px;
    border: 0.5px solid var(--border-primary);
    background: none;
    :focus {
      border: none;
      box-shadow: none;
    }
    .ant-select-selector {
      box-shadow: none;
      border: 1px solid var(--border-primary);
    }
  }
  .ant-select-focused.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
      .ant-pagination-size-changer
    )
    .ant-select-selector {
    box-shadow: none;
  }
  .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
    color: var(--text-primary);
    ${Typography.body_lg}
  }

  .ant-select-multiple .ant-select-selection-search {
    margin-inline-start: -1px;
  }
  .ant-select-selection-item {
    padding: 3px;
    margin: 0;
    height: 32px;
    border: 1px solid var(--border-primary);
    border-radius: 20px;
  }
  .ant-tag .ant-tag-close-icon {
    width: 16px;
    height: 16px;
    margin-inline-start: 6px;
  }

  .ant-select-selection-overflow {
    gap: 8px;
  }
  .ant-tag {
    border: 0.5px solid var(--border-primary);
    background: var(--neutral-secondary);
    ${Typography.body_md}
    color: var(--text-primary);
  }
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover
    .ant-select-selector {
    border-color: var(--brand-primary);
  }
  @media (max-width: 449px) {
    .ant-select-multiple .ant-select-selector {
      padding: 2px 3px;
    }
    .ant-select .ant-select-selection-placeholder {
      margin-left: -7px;
    }
  }
`;

export {
  Main,
  ModalBlock,
  ModalHeader,
  ModalTitle,
  Icon,
  ModalContent,
  ModalForm,
  ModalInput,
  Label,
  ModalButton,
  Dropmenu,
  Selects
};
