import styled, { css } from 'styled-components';
import { Typography } from '../../../../../global/theme';

interface Props {
  isOverdue?: boolean;
  isHide?: boolean;
}

const Container = styled.div`
  display: flex;
`;

const Maincontent = styled.div`
  padding-left: 16px;
  overflow-x: auto;
  margin-right: -20px;
  height: calc(100vh - 75px);
  ::-webkit-scrollbar {
    height: 6px;
    background-color: var(--background-secondary);
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: var(--text-placeholder);
  }
  @media (max-width: 449px) {
    box-sizing: border-box;
    margin: 0 -16px;
    padding: 0 0 0 24px;
    height: calc(100dvh - 125px);
  }
`;
const Boxmodel = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 346px;
  @media (max-width: 449px) {
    :last-child {
      padding-right: 24px;
    }
  }
`;

const Header = styled.div`
  width: 328px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  ${(props: Props) =>
    props.isHide &&
    css`
      display: none;
    `}
`;

const Title = styled.h1`
  ${Typography.heading_md}
  color: var(--text-primary);
  margin: 0;
`;

const Blockcontent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: auto;
  padding: 0 8px;
  max-height: calc(100vh - 134px);
  height: 100vh;
  :last-child {
    padding-bottom: 12px;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 449px) {
    max-height: calc(100vh - 184px);
  }
`;
const Showhide = styled.a`
  ${Typography.body_md}
  color: var(--text-secondary);
  cursor: pointer;
  max-width: 146px;
  width: 100%;
  padding-top: 4px;
  :hover {
    color: var(--text-primary);
  }
  @media (max-width: 449px) {
    max-width: max-content;
  }
`;
const ShowTask = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;
export { Container, Boxmodel, Header, Title, Blockcontent, Maincontent, Div, Showhide, ShowTask };
