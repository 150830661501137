import React, { useCallback, useEffect, useRef, useState } from 'react';
import AppLayout from '../../component/appLayout';
import {
  Group,
  Personaltext,
  PersonalHeader,
  Profileinfo,
  Text,
  Icon,
  Uploadtext,
  Title2,
  Userprofile,
  Formblock,
  Form,
  Label,
  Inputvalue,
  Header,
  InputDiv,
  ItemDiv,
  IconDiv,
  SelectedText
} from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileSettingData, updateProfileSetting } from '../../services/settingServices';
import { updateProfileSettingsData } from '../../actions/settingActions';
import Button from '../../component/button';
import { setErrorMessage } from '../../actions/messageActions';
import { getFirebaseUrlFromFile, isEmpty } from '../../helpers/common';
import { captureException } from '../../services/logService';
import emptyprofile from '../../assets/images/emptystates/empty-profile.svg';
import darkemptyprofile from '../../assets/images/emptystates/darkemptyprofile.svg';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';
import SVGIcon from '../../assets/images/svg/SVGIcon';
import moment from 'moment-timezone';
import { dateFormats, DEFAULT_DATE_FORMAT } from '../../global/constants';
import { ResponsiveHeader } from './SettingHeaderResponsive';
import { useMobileDevice } from '../../global/useMobile';
import { Dropdown, DropdownItem } from '../../component/Dropdown';

const Personal: React.FC = () => {
  // Refs
  const inputRef = useRef<HTMLInputElement | null>(null);
  //use selector state variables
  const settingsSelector = useSelector((state: RootReducerInterface) => state.settings);
  const { themeMode, profileData } = settingsSelector;
  // States
  const [image, setImage]: any = useState();
  const [loading, setLoading] = useState(false);
  const [timeSearchValue, setTimeSearchValue] = useState('');
  const [dateSearchValue, setDateSearchValue] = useState('');
  // Other variables
  const dispatch = useDispatch();
  const isMobile = useMobileDevice();
  const timeZones = moment.tz.names().map((zone) => {
    const offset = moment.tz(zone).utcOffset();
    const hours = Math.floor(offset / 60);
    const minutes = Math.abs(offset % 60);
    const sign = offset >= 0 ? '+' : '-';
    const label = `UTC${sign}${String(Math.abs(hours)).padStart(2, '0')}:${String(Math.abs(minutes)).padStart(
      2,
      '0'
    )} - ${zone}`;
    return { label };
  });

  const loadData = useCallback(async () => {
    await dispatch(getProfileSettingData());
  }, [dispatch]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onChangeInputField = useCallback(
    (propsName: string, value: string) => {
      dispatch(updateProfileSettingsData({ propsName, value }));
    },
    [dispatch]
  );

  const handleUploadClick = () => {
    inputRef.current?.click();
  };

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        const item = {
          profile: fileReader.result,
          file: file
        };
        setImage(item);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileRead = async (event: any) => {
    try {
      const file = event.target.files[0];
      let fileData = file.name.split('.');
      if (file.size > 500000) {
        dispatch(setErrorMessage('Please upload less than 500kb photo size.'));
        return false;
      }
      if (fileData[1] === 'jpg' || fileData[1] === 'jpeg' || fileData[1] === 'png') {
        await convertBase64(file);
      } else {
        dispatch(setErrorMessage('Please upload a valid type photo.'));
        return false;
      }
    } catch (e) {
      captureException(e);
      console.log('error', e);
    }
  };

  const onSubmit = useCallback(
    async (e: React.SyntheticEvent) => {
      e.preventDefault();
      try {
        setLoading(true);
        let profile;
        if (image) {
          profile = await getFirebaseUrlFromFile(image, 'UserProfiles/', dispatch);
        }
        const payload = {
          name: profileData?.name,
          phoneNumber: profileData?.phone,
          timeZone: profileData?.timeZone || '',
          dateFormat: profileData?.dateFormat || DEFAULT_DATE_FORMAT,
          profilePhoto: !isEmpty(image?.profile)
            ? profile
            : !isEmpty(profileData?.profile_photo)
            ? profileData?.profile_photo
            : null
        };
        const result = await dispatch(updateProfileSetting(payload));
        if (result) {
          setImage();
          loadData();
        }
      } catch (error) {
        captureException(error);
        setLoading(false);
        console.log('error', error);
      } finally {
        setLoading(false);
      }
    },
    [
      dispatch,
      image,
      loadData,
      profileData?.dateFormat,
      profileData?.name,
      profileData?.phone,
      profileData?.profile_photo,
      profileData?.timeZone
    ]
  );

  return (
    <>
      <AppLayout>
        <Group onSubmit={onSubmit}>
          <PersonalHeader>
            <Header>
              {isMobile && <ResponsiveHeader />}
              <Personaltext>My Profile</Personaltext>
            </Header>
          </PersonalHeader>
          <Profileinfo>
            <Userprofile>
              <input type='file' onChange={handleFileRead} ref={inputRef} style={{ display: 'none' }} />
              {!isEmpty(image?.profile) || !isEmpty(profileData?.profile_photo) ? (
                <Icon>
                  <img
                    src={!isEmpty(image?.profile) ? image?.profile : profileData?.profile_photo}
                    alt='googlelogo'
                    width={60}
                    height={60}
                    className='profile-image'
                  />
                </Icon>
              ) : (
                <Icon>
                  {themeMode?.theme === 'dark' ? (
                    <img src={darkemptyprofile} alt='emptyprofile' width={60} height={60} className='profile-image' />
                  ) : (
                    <img src={emptyprofile} alt='emptyprofile' width={60} height={60} className='profile-image' />
                  )}
                </Icon>
              )}
              <Uploadtext>
                <Title2 onClick={handleUploadClick}> Upload new image </Title2>
                <Text> Maximum file size allowed in 500 KB in png, jpeg, jpg format. </Text>
              </Uploadtext>
            </Userprofile>
            <Formblock>
              <Form>
                <Label>Full Name</Label>
                <Inputvalue
                  type='text'
                  value={profileData?.name}
                  onChange={(e) => onChangeInputField('name', e.target.value)}
                />
              </Form>
              <Form>
                <Label>Mobile Number</Label>
                <Inputvalue
                  type='text'
                  value={profileData?.phone}
                  onChange={(e) => onChangeInputField('phone', e.target.value)}
                />
              </Form>
            </Formblock>
            <Formblock>
              <Form>
                <Label>Current Timezone</Label>

                <Dropdown
                  isMinWidth={258}
                  content={
                    <InputDiv>
                      <Inputvalue
                        onChange={(event) => setTimeSearchValue(event.target.value)}
                        value={timeSearchValue}
                      />
                      {isEmpty(timeSearchValue) && profileData?.timeZone && (
                        <SelectedText>{profileData?.timeZone}</SelectedText>
                      )}
                      <IconDiv>
                        <SVGIcon
                          name='angledown-icon'
                          width='18'
                          height='18'
                          viewBox='0 0 18 18'
                          className='angledown-icon'
                        />
                      </IconDiv>
                    </InputDiv>
                  }
                  trigger='click'>
                  <ItemDiv>
                    {timeZones
                      ?.filter((timeZone) =>
                        timeSearchValue ? timeZone.label.toLowerCase().includes(timeSearchValue.toLowerCase()) : true
                      )
                      .map((item) => (
                        <DropdownItem
                          key={item?.label}
                          label={item?.label}
                          onClick={() => {
                            setTimeSearchValue('');
                            onChangeInputField('timeZone', item.label); // Call the function to update the form
                          }}
                        />
                      ))}
                  </ItemDiv>
                </Dropdown>
              </Form>
              <Form>
                <Label>Date Format</Label>
                <Dropdown
                  isMinWidth={258}
                  content={
                    <InputDiv>
                      <Inputvalue
                        value={dateSearchValue}
                        onChange={(event) => setDateSearchValue(event.target.value)}
                      />
                      {isEmpty(dateSearchValue) && profileData?.dateFormat && (
                        <SelectedText>{profileData?.dateFormat}</SelectedText>
                      )}
                      <IconDiv>
                        <SVGIcon
                          name='angledown-icon'
                          width='18'
                          height='18'
                          viewBox='0 0 18 18'
                          className='angledown-icon'
                        />
                      </IconDiv>
                    </InputDiv>
                  }
                  trigger='click'>
                  <ItemDiv>
                    {dateFormats
                      ?.filter((dateFormat) =>
                        dateSearchValue ? dateFormat.toLowerCase().includes(dateSearchValue.toLowerCase()) : true
                      )
                      ?.map((option) => (
                        <DropdownItem
                          key={option}
                          label={option}
                          onClick={() => {
                            setDateSearchValue('');
                            onChangeInputField('dateFormat', option);
                          }}
                        />
                      ))}
                  </ItemDiv>
                </Dropdown>
              </Form>
            </Formblock>
            <Button title='Update' type='submit' isLoading={loading} disabled={loading} modelbtn />
          </Profileinfo>
        </Group>
      </AppLayout>
    </>
  );
};
export default Personal;
