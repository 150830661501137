import React, { useCallback, useState } from 'react';
import {
  Assigngroup,
  AvatarImage,
  ButtonIcon,
  DeadBlock,
  Deadline,
  Doticon,
  Dropblock,
  Droplabel,
  Form,
  IconDueDate,
  Iconhidden,
  Inputvalue,
  Label,
  Multiplelabel,
  Taskcontent,
  Taskcount,
  Taskitem,
  Tasktitle,
  Text,
  TooltipContent
} from './styles';
import SVGIcon from '../../../../assets/images/svg/SVGIcon';
import {
  File,
  LabelDetailsInterface,
  ProjectDetailInterface,
  StatusListInterface
} from '../../../../interfaces/ProjectInterface';
import Linkify from 'react-linkify';
import { isEmpty } from '../../../../helpers/common';
import Avatar from '../../../../component/avatar/Avatar';
import moment from 'moment';
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { useSelector, useDispatch } from 'react-redux';
import { RootReducerInterface } from '../../../../interfaces/RootReducerInterface';
import {
  FilteredCompletedTasksListInterface,
  FilteredTasksListInterface,
  TaskDetailsInterface,
  TaskGroupInterface
} from '../../../../interfaces/TaskInterface';
import { ProjectTemplateDetailsInterface } from '../../../../interfaces/SettingsInterface';
import { useMobileDevice } from '../../../../global/useMobile';
import { LABEL_COLOUR_HASH } from '../../../../global/constants';
import MyCustomContextMenu from '../../../../component/customContextMenu';
import { trackAnalyticActivity } from '../../../../services/analyticsService';
import {
  clearSubTaskList,
  setCreateTaskInput,
  setMyTaskDetailsData,
  setProjectTaskGroupsList,
  updateCreateTaskInput
} from '../../../../actions/taskActions';
import { useHistory } from 'react-router-dom';
import { getMyTaskDetailsData, getTaskGroupList } from '../../../../services/taskServices';
import TaskStatusDropdown from '../../../../component/dropdowns/taskStatusDropdown';
import Prioritydropdown from '../../../../component/dropdowns/priorityDropdown';
import AssignBox from '../../../../component/dropdowns/assignPopup';
import { TASK_ANALYTICS } from '../../../../global/analyticsConstants';
import Tooltip from '../../../../component/Tooltip';

// eslint-disable-next-line @typescript-eslint/no-redeclare
declare type DraggableProvided = typeof import('react-beautiful-dnd');
// eslint-disable-next-line @typescript-eslint/no-redeclare
declare type DraggableStateSnapshot = typeof import('react-beautiful-dnd');

interface Props {
  onMouseEnter?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLDivElement>) => void;
  isOverdue?: boolean;
  provided?: DraggableProvided;
  snapshot?: DraggableStateSnapshot;
  onClickCheckbox: (event: React.ChangeEvent<HTMLInputElement>) => void;
  task: TaskDetailsInterface;
  currentProject: ProjectDetailInterface | ProjectTemplateDetailsInterface;
  statusDetails: StatusListInterface | undefined;
  isTemplateProject?: boolean;
  loadData: () => void;
  isSelected?: boolean;
  taskContentKey: string;
  onUpdateStatus: (status: StatusListInterface, previousStatus: StatusListInterface) => void;
  isCompletedTask?: boolean;
  onClick: () => void;
  isOtherTask?: boolean;
  taskGroups: TaskGroupInterface[];
  dueResult?: any;
  setOpen: (value: boolean) => void;
  setDeleteModelopen: (value: boolean) => void;
  setSelectedItem?: (value: TaskDetailsInterface) => void;
  currentAssignBox: string;
  setCurrentAssignBox: (value: string) => void;
  isAssignDropdownOpen: boolean;
  setIsAssignDropdownOpen: (value: boolean) => void;
  isSubTask?: boolean;
  handleSubtaskOpen?: (id: string) => void;
  isActiveSubTask?: boolean;
  group?: FilteredTasksListInterface | FilteredCompletedTasksListInterface;
}

export const TaskItem = ({
  onMouseEnter,
  onMouseLeave,
  isOverdue,
  provided,
  snapshot,
  onClickCheckbox,
  task,
  currentProject,
  statusDetails,
  isTemplateProject,
  isSelected,
  loadData,
  taskContentKey,
  onUpdateStatus,
  isCompletedTask,
  onClick,
  isOtherTask,
  taskGroups,
  dueResult,
  setOpen,
  setDeleteModelopen,
  setSelectedItem,
  setCurrentAssignBox,
  currentAssignBox,
  isAssignDropdownOpen,
  setIsAssignDropdownOpen,
  isSubTask,
  handleSubtaskOpen,
  isActiveSubTask,
  group
}: Props) => {
  // States
  const [currentActiveTask, setCurrentActiveTask] = useState<string>('');
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { auth: authSelector } = stateSelector || {};
  const { userDetails } = authSelector;
  // Other variables
  const mobile = useMobileDevice();
  const dispatch = useDispatch();
  const history = useHistory();

  const onClickTaskDetails = useCallback(
    (task: TaskDetailsInterface) => {
      if (isTemplateProject) return;
      const eventProperties = {
        source: 'task list',
        title: task?.name || ''
      };
      trackAnalyticActivity(TASK_ANALYTICS.VIEW, eventProperties);
      dispatch(clearSubTaskList());
      dispatch(setMyTaskDetailsData(task));
      history.push(`/projects/details/${task?.projectId}/tasks?task=${task?.id}`);
    },
    [isTemplateProject, dispatch, history]
  );

  const onClickClone = useCallback(
    async (task: TaskDetailsInterface) => {
      let taskData;
      if (isTemplateProject) taskData = task;
      else {
        taskData = await dispatch(getMyTaskDetailsData(task?.id));
      }
      const taskFiles = taskData?.Files?.map((item: File) => {
        const { __typename, ...other } = item;
        return other;
      });
      const cloneTaskdetails = {
        Files: taskFiles,
        description: `${taskData?.description || ''}<p></p>`,
        name: taskData?.name,
        groupId: taskData?.groupId,
        projectId: taskData?.projectId,
        priority: taskData?.priority,
        users: taskData?.users,
        labels: taskData?.labels,
        estimate: taskData?.estimate,
        milestoneId: taskData?.milestoneId,
        statusId: taskData?.statusId,
        parentTaskId: !isEmpty(taskData?.parentTaskId) ? taskData?.parentTaskId : null
      };
      dispatch(setCreateTaskInput(cloneTaskdetails));
      if (!isEmpty(task?.groupId)) {
        const groupId = taskGroups?.find((group) => group.id === task?.groupId);
        dispatch(updateCreateTaskInput({ propsName: 'groupId', value: groupId }));
      }
      dispatch(updateCreateTaskInput({ propsName: 'projectId', value: currentProject }));
      if (isTemplateProject) {
        dispatch(setProjectTaskGroupsList(taskGroups));
        const updatedLabels = taskData?.labels
          ?.map((x: string) => {
            const labelDetails = currentProject?.labelsList?.find((y: { id: string }) => y?.id === x);
            return labelDetails || {};
          })
          ?.filter((x: LabelDetailsInterface) => x?.id);
        dispatch(updateCreateTaskInput({ propsName: 'labels', value: updatedLabels }));
      } else await dispatch(getTaskGroupList(currentProject?.id, true));
      const createTaskValue = () => {
        if (taskData?.priority !== undefined) return taskData.priority;
        if (currentProject?.priority?.default !== undefined) return currentProject.priority.default;
        return 0;
      };
      dispatch(
        updateCreateTaskInput({
          propsName: 'priority',
          value: createTaskValue()
        })
      );
      setOpen(true);
    },
    [isTemplateProject, dispatch, currentProject, setOpen, taskGroups]
  );

  const onClickEdit = useCallback(
    async (task: TaskDetailsInterface) => {
      let taskData;
      if (isTemplateProject) taskData = task;
      else {
        taskData = await dispatch(getMyTaskDetailsData(task?.id));
      }

      dispatch(setCreateTaskInput({ ...(taskData || {}), description: `${taskData?.description || ''}<p></p>` }));
      if (!isEmpty(task?.groupId)) {
        const groupId = taskGroups?.find((group) => group.id === task?.groupId);
        dispatch(updateCreateTaskInput({ propsName: 'groupId', value: groupId }));
      }
      dispatch(updateCreateTaskInput({ propsName: 'projectId', value: currentProject }));
      if (isTemplateProject) {
        dispatch(setProjectTaskGroupsList(taskGroups));
        const updatedLabels = taskData?.labels
          ?.map((x: string) => {
            const labelDetails = currentProject?.labelsList?.find((y: { id: string }) => y?.id === x);
            return labelDetails || {};
          })
          ?.filter((x: LabelDetailsInterface) => x?.id);
        dispatch(updateCreateTaskInput({ propsName: 'labels', value: updatedLabels }));
      } else await dispatch(getTaskGroupList(currentProject?.id, true));

      dispatch(
        updateCreateTaskInput({
          propsName: 'priority',
          value:
            taskData?.priority !== undefined
              ? taskData.priority
              : currentProject?.priority?.default !== undefined
              ? currentProject.priority.default
              : 0
        })
      );
      setOpen(true);
    },
    [isTemplateProject, dispatch, currentProject, setOpen, taskGroups]
  );

  const Opendeletemodel = (task?: TaskDetailsInterface) => {
    if (task && setSelectedItem) setSelectedItem(task);
    setDeleteModelopen(true);
  };

  const onClickUsers = useCallback(
    (taskId: string) => {
      if (mobile) {
        setIsAssignDropdownOpen(true);
        setCurrentAssignBox(taskId);
      }
    },
    [mobile, setCurrentAssignBox, setIsAssignDropdownOpen]
  );

  //reder task title name
  const renderTaskTitle = useCallback(
    (task: TaskDetailsInterface, isCompletedTask: boolean | undefined, isOtherTask: boolean | undefined) => {
      if (isCompletedTask || isOtherTask) return <Label>{task?.name}</Label>;
      if (isEmpty(task?.parentTaskId)) return <Label>{task?.name}</Label>;
      return (
        <Tasktitle>
          <Label>{task?.name}</Label>
        </Tasktitle>
      );
    },
    []
  );

  return (
    <>
      <MyCustomContextMenu
        targetId={task?.id}
        taskData={task}
        loadData={loadData}
        currentProject={currentProject}
        currentActiveTask={currentActiveTask}
        setCurrentActiveTask={(taskId: string) => setCurrentActiveTask(taskId)}
        onClickView={() => onClickTaskDetails(task)}
        onClickClone={() => onClickClone(task)}
        onClickEdit={() => onClickEdit(task)}
        onClickDelete={() => Opendeletemodel(task)}
        statusList={currentProject?.statusData || []}
        isTemplateProject={isTemplateProject}
        onUpdateStatus={onUpdateStatus}
        isSubTask={!!task?.parentTaskId}>
        <Taskcontent
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          isSelected={isSelected}
          isActive={task?.id === currentActiveTask}
          id={`${task?.id}`}
          className={isSelected ? 'selectedtask' : ''}
          showSubTask={isSubTask}>
          <Iconhidden
            onClick={(e) => e.stopPropagation()}
            {...(!isOtherTask && provided.dragHandleProps)}
            showSubTask={isSubTask}>
            <SVGIcon
              className='svgicon'
              name='three-dots-vertical-icon'
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
            />
          </Iconhidden>
          <Taskitem>
            <Form isTaskItem={true}>
              <div style={{ display: 'flex', gap: 2 }}>
                {statusDetails ? (
                  <TaskStatusDropdown
                    defaultStatus={currentProject?.defaultStatus}
                    statusList={currentProject?.statusData}
                    currentStatusId={task?.statusId}
                    isTaskCheckBox={true}
                    isResponsiveStatusIcon={true}
                    onUpdateStatus={onUpdateStatus}
                    isHoverIcon={true}
                  />
                ) : (
                  <ButtonIcon>
                    <Inputvalue
                      onClick={(e) => e.stopPropagation()}
                      type='checkbox'
                      className='checkbox-round'
                      onChange={onClickCheckbox}
                      checked={task?.status}
                    />
                  </ButtonIcon>
                )}
                {isOtherTask ? (
                  <Prioritydropdown
                    isTemplateProject={isTemplateProject}
                    taskData={task}
                    icon={true}
                    isTaskCheckBox={true}
                    currentProject={currentProject}
                    loadData={loadData}
                    isTaskItem={true}
                    isSubTask={isSubTask}
                    isHoverIcon={true}
                  />
                ) : (
                  currentProject?.priority?.enabled && (
                    <Prioritydropdown
                      isTemplateProject={isTemplateProject}
                      taskData={task}
                      icon={true}
                      isTaskCheckBox={true}
                      currentProject={currentProject}
                      loadData={loadData}
                      isTaskItem={true}
                      isSubTask={isSubTask}
                      taskGroup={group}
                      isHoverIcon={true}
                    />
                  )
                )}
              </div>
              <Linkify
                componentDecorator={(decoratedHref: string, decoratedText: string, key: number) => (
                  <a target='blank' href={decoratedHref} key={key}>
                    {decoratedText}
                  </a>
                )}>
                {renderTaskTitle(task, isCompletedTask, isOtherTask)}
              </Linkify>
              {task?.subTasks && task?.subTasks?.length > 0 && (
                <Taskcount
                  isActiveSubTask={isActiveSubTask}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSubtaskOpen?.(task?.id);
                  }}>
                  {task?.subTasks?.filter((x) => x?.status)?.length}/{task?.subTasks?.length}
                </Taskcount>
              )}
            </Form>
            <Assigngroup onClick={(e) => e.stopPropagation()} className={'assign'}>
              <ul onClick={() => onClickUsers(task?.id)}>
                {task?.users
                  ?.filter((item) => !isEmpty(item?.id))
                  ?.map((user) => {
                    return (
                      <li key={user?.id}>
                        <AvatarImage>
                          <Avatar imgSrc={user?.profile_photo} name={user?.name ? user.name : 'U N'} size={20} />
                        </AvatarImage>
                      </li>
                    );
                  })}
                <AssignBox
                  setIsDropdownOpen={(value: boolean) => setIsAssignDropdownOpen(value)}
                  isDropdownOpen={isAssignDropdownOpen && currentAssignBox === task?.id}
                  projectUsers={currentProject?.users}
                  currentTask={task}
                  loadData={loadData}
                  setCurrentAssignBox={(id) => setCurrentAssignBox(id)}
                  currentAssignBox={currentAssignBox}
                  isTemplateProject={isTemplateProject}
                  isAssignModal={mobile}
                  isMultiAssignee={currentProject?.multiAssignee}
                  isSubtask={isSubTask}
                  group={group}
                />
              </ul>
            </Assigngroup>
          </Taskitem>
          <Deadline hasGap={isEmpty(task?.dueDate)}>
            {currentProject?.labelsEnabled && task?.labels?.length > 0 && (
              <Multiplelabel>
                {task.labels.map((label, index) => {
                  return (
                    <Dropblock key={label?.id} className={index === 0 ? 'left-border' : ''}>
                      <Doticon color={`${LABEL_COLOUR_HASH[label?.color]}`} />
                      <Droplabel>{label?.name}</Droplabel>
                    </Dropblock>
                  );
                })}
              </Multiplelabel>
            )}
            {!isTemplateProject && !isEmpty(task?.dueDate) && (
              <DeadBlock>
                <Tooltip
                  title={
                    <>
                      <div>Due {dueResult?.dueParam}</div>
                      <div>Due on {moment(task?.dueDate).format(userDetails?.dateFormat)}</div>
                    </>
                  }
                  placement='bottom'
                  maxWidth={'max-content'}>
                  <TooltipContent>
                    <Text isOverdue={isOverdue}>{task.dueDate && dueResult?.dueTime}</Text>

                    <IconDueDate onClick={(e) => e.stopPropagation()}>
                      {dueResult?.overDue && !task?.status ? (
                        <SVGIcon
                          name='tasklist-calendar-duedate-icon'
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          className='calendar-due-fill-color'
                        />
                      ) : (
                        <SVGIcon
                          name='tasklist-calendar-icon'
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          className='calendar-fill-color'
                        />
                      )}
                    </IconDueDate>
                  </TooltipContent>
                </Tooltip>
              </DeadBlock>
            )}
          </Deadline>
        </Taskcontent>
      </MyCustomContextMenu>
    </>
  );
};
