import styled, { css } from 'styled-components';
import { Typography } from '../../../global/theme';

const Group = styled.div`
  width: 100%;
  height: 100vh;
  background-color: var(--background-secondary);
  margin: 0 auto;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.form`
  width: 100%;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--background-secondary);
  gap: 20px;
  @media (max-width: 449px) {
    gap: 30px;
    max-width: 100%;
    padding: 30px 24px;
  }
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  @media (max-width: 449px) {
    height: 40px;

    .svgicon {
      width: 40px;
      height: 40px;
    }
  }
`;

const Title = styled.h1`
  ${Typography.heading_lg}
  color: var(--text-primary);
  margin: 0;
  @media (max-width: 449px) {
    text-align: center;
  }
`;

const Formblock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 8px;
  border: 0.5px solid var(--border-primary);
  background: var(--text-white);
  box-shadow: var(--shadow-card-hover);
  padding: 20px;
  @media (max-width: 449px) {
    padding: 18px;
    gap: 30px;
  }
`;

const Text = styled.p`
  ${Typography.body_md}
  color: var(--text-secondary);
  margin: 0;

  @media (max-width: 449px) {
    text-align: center;
  }
`;

const Subblock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (max-width: 449px) {
    gap: 20px;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  input[type='password']:not(:placeholder-shown) {
    font-size: 24px;
    color: var(--text-secondary);
  }
  .password:not(:placeholder-shown) {
    height: 18px;
  }
`;

const Label = styled.label`
  ${Typography.body_md_medium}
  color: var(--text-secondary);
`;

const Inputvalue = styled.input`
  ${Typography.body_lg_medium}
  background-color: var(--text-white);
  color: var(--text-primary);
  border: 0.5px solid var(--border-primary);
  padding: 8px 12px;
  border-radius: 6px;
  outline: none;

  ${(props: { disabled?: boolean }) =>
    props.disabled &&
    css`
      background-color: var(--background-secondary);
      border: 0.5px solid var(--border-primary);
      color: var(--text-muted);
    `}

  :focus {
    border: 0.5px solid var(--brand-primary);
  }
  ::placeholder {
    ${Typography.body_lg_medium}
    color: var(--text-secondary);
  }

  @media (max-width: 449px) {
    padding: 12px;
  }
`;

const FormContent = styled.div`
  width: 100%;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .joinbutton {
    margin-left: auto;
  }
  @media (max-width: 449px) {
    gap: 30px;
    .joinbutton {
      margin: 0;
      display: contents;
    }
  }
`;

const ProfileView = styled.div`
  padding-top: 16px;
  text-align: center;
`;
const Box = styled.div`
  max-width: 338px;
  width: 100%;
  border-radius: 12px;
  border: 0.5px solid var(--border-primary);
  background-color: var(--text-white);
  padding: 19px 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  box-shadow: var(--shadow-card);
`;
const Linkicon = styled.div`
  width: 56px;
  height: 56px;
  background-color: var(--neutral-secondary);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Linkcontent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  p {
    margin: 0;
    ${Typography.body_md}
    color: var(--text-secondary);
    text-align: center;
  }
  h2 {
    margin: 0;
    ${Typography.heading_md}
    color: var(--text-primary);
  }
`;
export {
  Content,
  FormContent,
  Formblock,
  Group,
  Icon,
  Inputvalue,
  Item,
  Label,
  ProfileView,
  Subblock,
  Text,
  Title,
  Box,
  Linkicon,
  Linkcontent
};
