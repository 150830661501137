import { useCallback, useEffect, useState } from 'react';
import AppLayout from '../../../component/appLayout';
import { Content, Header, Integrationgroup, Main } from './styles';
import Integrationbox from '../../../component/integrationbox';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from '../../../helpers/common';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import { PersonalHeader } from '../apps/styles';
import { ResponsiveHeader } from '../SettingHeaderResponsive';
import { useMobileDevice } from '../../../global/useMobile';
import { getCompanySettingIntegration } from '../../../services/settingServices';

const integrationData = [
  {
    IntegrationsLogoname: 'stripe-logo',
    title: 'Stripe',
    description: 'Allows your clients to pay your invoices with their credit card.',
    isConnected: false
  },
  {
    IntegrationsLogoname: 'figma-logo',
    title: 'Figma',
    description: 'Figma is a collaborative web application for interface design.',
    isConnect: false
  },
  {
    IntegrationsLogoname: 'github-logo',
    title: 'Github',
    description: 'Link to GitHub for collaborative development within projects.',
    isConnect: true
    // isCommingsoontag: true
  },
  {
    IntegrationsLogoname: 'sentry-logo',
    title: 'Sentry',
    description: 'Connect Sentry to seamlessly create tasks from sentry dashboard.',
    isConnect: true
    // isCommingsoontag: true
  },
  {
    IntegrationsLogoname: 'teams-logo',
    title: 'Teams',
    description:
      'Connect to Microsoft Teams for streamlined collaboration and unified communication within your organization.',
    isCommingsoontag: true
  },
  {
    IntegrationsLogoname: 'slack-logo',
    title: 'Slack',
    description: 'Messaging that means business. Slack is built for work.',
    isCommingsoontag: true
  },
  {
    IntegrationsLogoname: 'make-logo',
    title: 'Make',
    description:
      // eslint-disable-next-line max-len
      'Make helps you to build and automate anything in one powerful visual platform, from tasks and workflows to apps and systems.',
    isCommingsoontag: true
  },
  {
    IntegrationsLogoname: 'zapier-logo',
    title: 'Zapier',
    description:
      'Zapier automates routine tasks, granting time to focus on critical work by eliminating manual effort.',
    isLearnmorebtn: true
  },
  {
    IntegrationsLogoname: 'api-logo',
    title: 'Api',
    description: 'APIs enable applications to communicate, share data, and enhance functionalities seamlessly.',
    isLearnmorebtn: true
  }
];

interface DataProps {
  title: string;
  description: string;
  isConnected?: boolean;
  isConnect?: boolean;
  isCommingsoontag?: boolean;
  isLearnmorebtn?: boolean;
  IntegrationsLogoname?: string;
}

export default function Integrations() {
  // States
  const [data, setData] = useState<DataProps[]>(integrationData);
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { settings: settingsSelector, workspace: workspaceSelector } = stateSelector || {};
  const { themeMode } = settingsSelector;
  const { workspace } = workspaceSelector;
  // Other variables
  const dispatch = useDispatch();
  const isMobile = useMobileDevice();

  const loadData = useCallback(async () => {
    if (!isEmpty(workspace?.id)) {
      const result = await dispatch(getCompanySettingIntegration(workspace?.id));
      const updatedData = data?.map((x) => {
        if (x?.title === 'Stripe') {
          return { ...x, isConnected: result?.stripe_connected, isLearnmorebtn: !result?.stripe_connected };
        } else if (x?.title === 'Figma') {
          return { ...x, isConnected: result?.figma_connected, isConnect: !result?.figma_connected };
        } else if (x?.title === 'Sentry') {
          return { ...x, isConnected: result?.sentry_connected, isConnect: !result?.sentry_connected };
        } else if (x?.title === 'Github') {
          return { ...x, IntegrationsLogoname: themeMode?.theme === 'dark' ? 'github-dark-logo' : 'github-logo' };
        } else if (x?.title === 'Api') {
          return { ...x, IntegrationsLogoname: themeMode?.theme === 'dark' ? 'api-dark-logo' : 'api-logo' };
        } else return x;
      });
      setData(updatedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, themeMode?.theme, workspace?.id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div>
      <AppLayout>
        <Integrationgroup>
          <>
            <Main>
              <PersonalHeader>
                {isMobile && <ResponsiveHeader />}
                <Header>Integrations</Header>
              </PersonalHeader>
            </Main>
            <Content>
              {data?.map((integration, index) => (
                <Integrationbox
                  key={integration?.title}
                  IntegrationsLogoname={integration.IntegrationsLogoname}
                  title={integration.title}
                  description={integration.description}
                  isConnected={integration.isConnected}
                  isConnect={integration.isConnect}
                  isCommingsoontag={integration.isCommingsoontag}
                  isLearnmorebtn={integration.isLearnmorebtn}
                  isLast={index === data.length - 1}
                  loadData={loadData}
                />
              ))}
            </Content>
          </>
        </Integrationgroup>
      </AppLayout>
    </div>
  );
}
