import styled from 'styled-components';

const Main = styled.div`
  display: flex;
  gap: 31px;
  .loading-animation {
    overflow: hidden;
  }
  .loading-animation::before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    animation: loading 1s infinite;
    background: linear-gradient(to right, transparent, var(--background-tertiary), transparent);
  }

  @keyframes loading {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;
const First = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 320px;
  .second {
    width: 47px;
  }
  .third {
    width: 81px;
  }
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Left = styled.div`
  width: 96px;
  height: 12px;
  border-radius: 6px;
  background: var(--background-muted);
`;
const Right = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 5px;
  background-color: var(--background-muted);
`;

const Card = styled.div`
  border-radius: 8px;
  border: 0.5px solid var(--border-primary);
  background: var(--background-primary);
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Row1 = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const Row2 = styled.div`
  display: flex;
  padding-left: 32px;
`;

const Row3 = styled.div`
  padding-left: 26px;
`;

const Leftrow1 = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 6px;
  background-color: var(--background-muted);
`;
const Rightrow1 = styled.div`
  width: 272px;
  height: 12px;
  border-radius: 33px;
  background-color: var(--background-muted);
`;
const Circle = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 3px solid var(--background-primary);
  background: var(--background-muted);
  margin-left: -12px;
`;
export { Main, First, Top, Bottom, Left, Right, Card, Row1, Row2, Row3, Leftrow1, Rightrow1, Circle };
