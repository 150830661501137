import { ChangeEvent, RefObject, useRef } from 'react';
import {
  Action,
  Bottom,
  Closeicon,
  Div,
  Header,
  Headerdetail,
  Headertitle,
  Icon,
  Leftdiv,
  Leftside,
  Modalbox,
  NotionAction
} from './styles';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { REACT_APP_BLOCKSUITE_URL } from '../../../global/environment';

interface Props {
  onClose: () => void;
  editorIframe: RefObject<HTMLIFrameElement>;
}
export default function Importmodal(props: Props) {
  const { onClose } = props;
  //Refs
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileInput = (type: 'html' | 'markdown') => {
    if (fileInputRef.current) {
      if (type === 'html') fileInputRef.current.accept = '.html';
      if (type === 'markdown') fileInputRef.current.accept = '.md';
      fileInputRef.current.click();
    }
  };

  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (!file) return;
    const accept = fileInputRef.current?.accept as '.html' | '.md';
    const fileType = accept === '.html' ? 'html' : 'markdown';
    const text = (await file.text()).replace('<!doctype html>', '');
    props.editorIframe.current?.contentWindow?.postMessage(
      { type: 'import', importType: fileType, data: text },
      `${REACT_APP_BLOCKSUITE_URL}`
    );
    onClose();
  };

  return (
    <Modalbox>
      <Header>
        <Leftdiv>
          <Headertitle>Import</Headertitle>
          <Headerdetail>Teamcamp will gradually support more file formats for import.</Headerdetail>
        </Leftdiv>
        <Closeicon onClick={onClose}>
          <SVGIcon name='close-icon' width='18' height='18' viewBox='0 0 18 18' className='close-icon' />
        </Closeicon>
      </Header>
      <Bottom>
        <input
          type='file'
          ref={fileInputRef}
          style={{ display: 'none' }}
          multiple={false}
          onChange={handleFileUpload}
        />
        <Action onClick={() => handleFileInput('markdown')}>
          <Icon>
            <SVGIcon name='markdown-icon' width='18' height='18' viewBox='0 0 18 18' className='markdown-icon' />
          </Icon>
          <p>Markdown</p>
        </Action>
        <Action onClick={() => handleFileInput('html')}>
          <Icon>
            <SVGIcon name='html-code-icon' width='18' height='18' viewBox='0 0 18 18' className='html' />
          </Icon>
          <p>HTML</p>
        </Action>
        <NotionAction style={{ display: 'none' }}>
          <Leftside>
            <Icon>
              <SVGIcon name='notion-icon' width='18' height='18' viewBox='0 0 18 18' className='notion-icon' />
            </Icon>
            <p>Notion</p>
          </Leftside>
          <Icon>
            <SVGIcon name='notion-info-icon' width='18' height='18' viewBox='0 0 18 18' className='svg-icon' />
          </Icon>
        </NotionAction>
        <Action>
          <Icon>
            <SVGIcon name='coming-soon-icon' width='18' height='18' viewBox='0 0 18 18' className='coming-soon-icon' />
          </Icon>
          <Div>Coming soon</Div>
        </Action>
      </Bottom>
    </Modalbox>
  );
}
