import { USER_PREFERENCES } from '../global/constants';
import { captureException } from '../services/logService';

let singleton: any;
const singletonEnforcer = Symbol();
const parseDataFile = (defaults: any) => {
  try {
    const settings = localStorage.getItem(USER_PREFERENCES);
    if (settings) return JSON.parse(settings);
    return {};
  } catch (error) {
    captureException(error);
    return defaults;
  }
};

const containsKey = (obj: any, key: any) => ({}.hasOwnProperty.call(obj || {}, key));

class UserPreferences {
  defaults: any;
  data: any;
  constructor(opts: any) {
    this.defaults = opts.defaults;
    this.data = parseDataFile(opts.defaults);
  }

  get(key: any, defaultValue: any) {
    if (containsKey(this.data, key)) {
      return this.data[key];
    }
    return defaultValue;
  }

  save(settings: any) {
    localStorage.setItem(USER_PREFERENCES, JSON.stringify(settings));
  }

  set(key: any, value: any) {
    this.data = parseDataFile(this.defaults);
    this.data[key] = value;
    this.save(this.data);
  }

  remove(key: any) {
    delete this.data[key];
    this.save(this.data);
  }

  parseDataFile() {
    this.data = parseDataFile(this.defaults);
  }

  contains(key: any) {
    return Object.prototype.hasOwnProperty.call(this.data, key);
  }
}
export default class UserPreferenceSingleton {
  userPreferences: UserPreferences;
  static get CURRENT_USER() {
    return 'current_user';
  }
  static get API_TOKEN_EXPIRE_TIME() {
    return 'api_token_expire_time';
  }
  static get WORKSPACE() {
    return 'workspace';
  }
  static get ONE_SIGNAL() {
    return 'onesignal';
  }

  static get OVERDUE_RESHEDULE_TIME() {
    return 'overdueTask_reshedule_time';
  }

  static get NOTIFICATION_RESHEDULE_TIME() {
    return 'notification_reshedule_time';
  }

  static get DESKTOP_NOTIFICATION_RESHEDULE_TIME() {
    return 'desktop_notification_reshedule_time';
  }

  static get FIREBASE_CUSTOM_TOKEN() {
    return 'firebase_custom_token';
  }

  static get FIREBASE_TOKEN() {
    return 'firebase_token';
  }

  static get CUSTOMER_PAGINATION() {
    return 'customer_pagination';
  }
  static get INVOICE_PAGINATION() {
    return 'invoice_pagination';
  }
  static get LAST_MESSAGE_GROUP() {
    return 'last_message_group';
  }
  static get MENTION_USERS() {
    return 'mention_users';
  }

  constructor(enforcer: any) {
    if (enforcer !== singletonEnforcer) throw new Error('Cannot construct singleton');

    this.userPreferences = new UserPreferences({
      configName: 'user-preferences',
      defaults: {
        windowBounds: { width: 800, height: 600 }
      }
    });
  }

  static getInstance() {
    if (!singleton) {
      singleton = new UserPreferenceSingleton(singletonEnforcer);
    }
    return singleton;
  }

  static removeInstance() {
    singleton = undefined;
  }

  getWorkspace() {
    return this.userPreferences.get(UserPreferenceSingleton.WORKSPACE, undefined);
  }

  setWorkspace(value: any) {
    return this.userPreferences.set(UserPreferenceSingleton.WORKSPACE, value);
  }

  setTokenExpireTime(value: any) {
    return this.userPreferences.set(UserPreferenceSingleton.API_TOKEN_EXPIRE_TIME, value);
  }

  getTokenExpireTime() {
    return this.userPreferences.get(UserPreferenceSingleton.API_TOKEN_EXPIRE_TIME, undefined);
  }

  setCurrentUser(value: any) {
    return this.userPreferences.set(UserPreferenceSingleton.CURRENT_USER, value);
  }

  getCurrentUser() {
    return this.userPreferences.get(UserPreferenceSingleton.CURRENT_USER, undefined);
  }

  setNotificationReshedule(value: any) {
    return this.userPreferences.set(UserPreferenceSingleton.NOTIFICATION_RESHEDULE_TIME, value);
  }

  getNotificationReshedule() {
    return this.userPreferences.get(UserPreferenceSingleton.NOTIFICATION_RESHEDULE_TIME, undefined);
  }

  setDesktopNotificationReshedule(value: any) {
    return this.userPreferences.set(UserPreferenceSingleton.DESKTOP_NOTIFICATION_RESHEDULE_TIME, value);
  }

  getDesktopNotificationReshedule() {
    return this.userPreferences.get(UserPreferenceSingleton.DESKTOP_NOTIFICATION_RESHEDULE_TIME, undefined);
  }

  setFirebaseToken(value: any) {
    return this.userPreferences.set(UserPreferenceSingleton.FIREBASE_TOKEN, value);
  }

  getFirebaseToken() {
    return this.userPreferences.get(UserPreferenceSingleton.FIREBASE_TOKEN, undefined);
  }

  setFirebaseCustomToken(value: string) {
    return this.userPreferences.set(UserPreferenceSingleton.FIREBASE_CUSTOM_TOKEN, value);
  }

  getFirebaseCustomToken() {
    return this.userPreferences.get(UserPreferenceSingleton.FIREBASE_CUSTOM_TOKEN, undefined);
  }

  getOneSignalInit() {
    return this.userPreferences.get(UserPreferenceSingleton.ONE_SIGNAL, undefined);
  }

  setOneSignalInit(value: any) {
    return this.userPreferences.set(UserPreferenceSingleton.ONE_SIGNAL, value);
  }

  getOverDueResheduleTime() {
    return this.userPreferences.get(UserPreferenceSingleton.OVERDUE_RESHEDULE_TIME, undefined);
  }

  setOverDueResheduleTime(value: number) {
    return this.userPreferences.set(UserPreferenceSingleton.OVERDUE_RESHEDULE_TIME, value);
  }

  get(key: any, defaultValue = null) {
    return this.userPreferences.get(key, defaultValue);
  }

  set(key: any, value: any) {
    this.userPreferences.set(key, value);
  }

  setCustomerPagination(value: any) {
    return this.userPreferences.set(UserPreferenceSingleton.CUSTOMER_PAGINATION, value);
  }

  getCustomerPagination() {
    return this.userPreferences.get(UserPreferenceSingleton.CUSTOMER_PAGINATION, undefined);
  }

  setInvoicePagination(value: any) {
    return this.userPreferences.set(UserPreferenceSingleton.INVOICE_PAGINATION, value);
  }

  getInvoicePagination() {
    return this.userPreferences.get(UserPreferenceSingleton.INVOICE_PAGINATION, undefined);
  }

  setLastMessageGroup(value: string) {
    return this.userPreferences.set(UserPreferenceSingleton.LAST_MESSAGE_GROUP, value);
  }
  getLastMessageGroup() {
    return this.userPreferences.get(UserPreferenceSingleton.LAST_MESSAGE_GROUP, undefined);
  }

  setMentionUsers(value: any) {
    return this.userPreferences.set(UserPreferenceSingleton.MENTION_USERS, value);
  }

  getMentionUsers() {
    return this.userPreferences.get(UserPreferenceSingleton.MENTION_USERS, undefined);
  }

  clearStoredUserData() {
    localStorage.removeItem(USER_PREFERENCES);
  }
}
