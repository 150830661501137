import React, { useCallback, useState } from 'react';
import {
  Dropmenu,
  Icon,
  Label,
  Main,
  ModalBlock,
  ModalButton,
  ModalContent,
  ModalForm,
  ModalHeader,
  ModalInput,
  ModalTitle,
  Selects
} from './styles';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import Button from '../../button';
import { useSelector } from 'react-redux';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import { UsersWorkspaceInterface } from '../../../interfaces/WorkspaceInterface';
import { UserInterface } from '../../../interfaces/chatMessageInterface';
import { ConfigProvider, Select, SelectProps, Tag } from 'antd';
import Avatar from '../../avatar/Avatar';

interface Props {
  onClose?: () => void;
  heading?: string;
  label?: string;
  iseditgroupmodal?: boolean;
  usersList?: UsersWorkspaceInterface[];
  onChange?: (propsName: string, value: string) => void;
  groupUserList?: UserInterface[];
  onAddMembers?: (e: React.SyntheticEvent, users: string[]) => void;
  loading?: boolean;
  onEditGroupName?: (e: React.SyntheticEvent) => void;
}

const { Option } = Select;
type TagRender = SelectProps['tagRender'];

const Addgroupmembermodal: React.FC<Props> = (props) => {
  //States
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { chatMessage: chatMessageSelector } = stateSelector || {};
  const { inputChatGroup } = chatMessageSelector || {};
  //Other variable
  const existUsersIds = props?.groupUserList?.map((user) => user?.id);
  const filteredUsersList = props?.usersList?.filter((item) => !existUsersIds?.includes(item?.id));

  const handleSelectChangeUsers = useCallback((selectedUserIds: string[]) => {
    setSelectedUsers(selectedUserIds);
  }, []);

  const onRemoveMember = useCallback((e: React.SyntheticEvent) => {
    e.stopPropagation();
  }, []);

  const tagRender: TagRender = (props: any) => {
    const { closable, onClose, label } = props;
    const name = label?.props?.children?.[1]?.props?.children || '';
    return (
      <Tag
        closable={closable}
        onClose={onClose}
        closeIcon={
          <div onClick={(e: React.SyntheticEvent) => onRemoveMember(e)}>
            <SVGIcon
              name='tag-close-icon'
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              className='close-icon'
            />
          </div>
        }
        style={{ padding: 4, borderRadius: 4, display: 'flex', alignItems: 'center', marginRight: 0 }}>
        {name}
      </Tag>
    );
  };

  return (
    <div>
      <Main>
        <ModalBlock>
          <ModalHeader>
            <ModalTitle>{props?.heading}</ModalTitle>
          </ModalHeader>
          <Icon onClick={props?.onClose}>
            <SVGIcon name='modal-cross-sign-icon' width='18' height='18' viewBox='0 0 18 18' className='fillColor' />
          </Icon>
        </ModalBlock>
        <ModalContent
          onSubmit={(e) =>
            !props?.iseditgroupmodal && props?.onAddMembers
              ? props?.onAddMembers(e, selectedUsers)
              : props?.onEditGroupName && props?.onEditGroupName(e)
          }>
          <ModalForm>
            <Label>{props?.label}</Label>
            {props?.iseditgroupmodal ? (
              <ModalInput
                type='text'
                placeholder='Enter group name'
                value={inputChatGroup?.name}
                onChange={(e) => props?.onChange && props.onChange('name', e.target.value)}></ModalInput>
            ) : (
              <Selects>
                <ConfigProvider
                  theme={{
                    token: {
                      colorText: 'var(--text-primary)',
                      colorPrimaryBg: 'none',
                      controlItemBgHover: 'var(--neutral-secondary)'
                    }
                  }}>
                  <Select
                    suffixIcon={
                      <SVGIcon
                        name='angledown-icon'
                        width='18'
                        height='18'
                        viewBox='0 0 18 18'
                        className='angledown-icon'
                      />
                    }
                    mode='multiple'
                    autoFocus={true}
                    showSearch
                    style={{ width: '100%' }}
                    popupClassName='selectpopup'
                    listItemHeight={10}
                    listHeight={192}
                    placeholder='Enter member name'
                    dropdownStyle={{
                      border: '0.5px solid var(--border-primary)',
                      backgroundColor: 'var(--background-primary)',
                      boxShadow: 'none',
                      position: 'absolute',
                      zIndex: 9999,
                      padding: '8px',
                      height: 204
                    }}
                    menuItemSelectedIcon=''
                    value={selectedUsers}
                    onChange={handleSelectChangeUsers}
                    filterOption={(input, option: any) =>
                      option?.children?.props?.children[1]?.props?.children
                        ?.toLowerCase()
                        ?.indexOf(input.toLowerCase()) >= 0
                    }
                    tagRender={(e) => tagRender(e)}>
                    {filteredUsersList &&
                      filteredUsersList?.length > 0 &&
                      filteredUsersList?.map((user) => (
                        <Option key={user?.id} value={user?.id}>
                          <Dropmenu>
                            <Avatar
                              imgSrc={user?.profile_photo}
                              name={user?.name ? user?.name : 'U N'}
                              size={28}
                              isNotBorder
                            />
                            <p>{user?.name}</p>
                          </Dropmenu>
                        </Option>
                      ))}
                  </Select>
                </ConfigProvider>
              </Selects>
            )}
          </ModalForm>
          <ModalButton>
            <Button
              modelbtn={true}
              title='Cancel'
              secondary={true}
              onClick={props?.onClose}
              isCancelbtn={true}
              hasNotBoxshadow
            />
            {props.iseditgroupmodal ? (
              <Button type={'submit'} modelbtn={true} title='Save' isLoading={props?.loading} />
            ) : (
              <Button type={'submit'} modelbtn={true} title='Add' isLoading={props?.loading} />
            )}
          </ModalButton>
        </ModalContent>
      </Main>
    </div>
  );
};
export default Addgroupmembermodal;
