import styled, { css } from 'styled-components';
import { Typography } from '../../../global/theme';

interface Props {
  isActive: boolean;
}

const NavbarMain = styled.div`
  width: 100%;
  max-width: 259px;
  background: var(--background-primary);
  position: relative;
  display: flex;
  box-orient: vertical;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 449px) {
    max-width: 100%;
    height: 100dvh;
  }
`;

const Backbutton = styled.div`
  position: sticky;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 20px;
  gap: 8px;
  width: 219px;
  /* background-color: var(--background-primary);  */
  border-bottom: 0.5px solid var(--border-primary);
  cursor: pointer;
  z-index: 999;
  p {
    ${Typography.heading_lg}
    margin: 0px;
    color: var(--text-primary);
  }
  .arrow-icon {
    path {
      stroke: var(--text-primary);
    }
  }
  @media (max-width: 449px) {
    display: none;
  }
`;
const Menu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
`;
const Content = styled.div``;
const Maindiv = styled.div`
  /* margin-top: 57px; */
`;
const Title = styled.div`
  padding: 7px 16px;
  background: var(--background-tertiary);
  border-top: 0.5px solid var(--border-primary);
  max-width: 228px;
  width: 100%;
  &.first-item {
    border-top: none;
  }

  p {
    ${Typography.body_md}
    color: var(--text-secondary);
    margin: 0px;
  }
  @media (max-width: 449px) {
    max-width: -webkit-fill-available;
  }
`;
const Submenu = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 5px;
  border-top: 0.5px solid var(--border-primary);
  max-width: 229px;
  width: 100%;
  h2 {
    ${Typography.body_md_medium}
    color: var(--text-primary);
    margin: 0px;
  }
  p {
    ${Typography.body_sm}
    color: var(--text-secondary);
    margin: 0px;
  }
  :hover {
    background: var(--neutral-secondary);
    padding: 16px;
    cursor: pointer;
    z-index: 0;
  }

  ${(props: Props) =>
    props.isActive &&
    css`
      background: var(--neutral-secondary);
      border-left: 2px solid var(--brand-primary);
      padding: 16px 16px 16px 14px;
      pointer-events: none;
      @media (max-width: 449px) {
        padding: 10px 16px;
      }
    `}
  @media (max-width: 449px) {
    max-width: -webkit-fill-available;
    padding: 10px 16px;
    gap: 4px;
    margin-bottom: 0;
    :hover {
      padding: 10px 16px;
    }
    &.submenu {
      border-bottom: 0.5px solid var(--border-primary);
    }
  }
`;
const ResponsiveHeader = styled.div`
  display: none;
  @media (max-width: 449px) {
    width: -webkit-fill-available;
    padding: 16px;
    gap: 24px;
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--background-primary);
    border-bottom: 0.5px solid var(--border-primary);
    cursor: pointer;
    z-index: 999;
    p {
      ${Typography.heading_lg}
      margin: 0px;
      color: var(--text-primary);
    }
  }
`;
export { NavbarMain, Backbutton, Menu, Title, Submenu, Content, Maindiv, ResponsiveHeader };
