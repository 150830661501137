import styled, { css } from 'styled-components';

interface Props {
  isMargin?: boolean;
  isImage?: boolean;
}

const Main = styled.div<Props>`
  display: flex;
  .ant-image .ant-image-mask:hover {
    background-color: var(--background-thumb-overlay);
  }
  .ant-image .ant-image-img {
    max-width: 300px;
    max-height: 300px;
    width: auto;
    height: auto;
  }

  .custom-mask-content {
    margin-top: unset !important;
    svg {
      margin-top: unset !important;
    }
  }
  @media (max-width: 449px) {
    .ant-image .ant-image-img {
      width: auto;
      max-width: 100%;
    }
  }
  ${(props) =>
    props.isMargin &&
    css`
      margin-top: 6px !important;
    `}
  ${(props) =>
    props.isImage &&
    css`
      margin-top: 12px;
      .ant-image-mask {
        border-radius: 6px;
      }
    `}
`;

const SvgDiv = styled.div`
  display: flex;
  padding: 12px;
  background: var(--background-overlay);
  border-radius: 50%;
  :hover {
    background: var(--background-thumb-overlay);
  }
  svg {
    fill: none;
  }
`;

const CustomPreviewIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

const LeftPreview = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const CenterLine = styled.div`
  height: 32px;
  border-left: 1px solid var(--border-secondary);
`;

export { CenterLine, CustomPreviewIcon, LeftPreview, Main, SvgDiv };
