import styled from 'styled-components';
import { Typography } from '../../../global/theme';

interface Props {
  maxHeight?: number;
}

const MainDiv = styled.div`
  max-width: 500px;
  height: 470px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--background-primary);
  gap: 0px;
  border-radius: 12px;
  box-shadow: var(--shadow-modal);
  @media (max-width: 449px) {
    height: auto;
  }
`;

const Header = styled.div`
  max-width: 500px;
  padding: 12px 16px 12px 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-bottom: 0.5px solid var(--border-primary);
`;

const Title = styled.div`
  width: 100%;
  ${Typography.heading_md}
  color: var(--text-primary);
`;

const Description = styled.div`
  width: 100%;
  ${Typography.body_lg}
  color: var(--text-secondary);
`;

const Form = styled.div<Props>`
  padding: 16px 16px 20px;
  display: flex;
  flex-direction: column;
  border-radius: 0;
  .ProseMirror img {
    width: 100%;
    max-width: calc(100% - 10px);
  }
  .tiptap {
    min-height: ${(props) => props.maxHeight}px;
    max-height: ${(props) => props.maxHeight}px;
    height: 100%;
    overflow: auto;
    scrollbar-width: none;
  }
`;

const ModalBox = styled.div`
  padding: 16px;
  background-color: var(--background-primary);
  border-radius: 12px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  justify-content: center;
  max-width: 500px;
  height: 436px;
  width: calc(100% - 32px);
  box-shadow: var(--shadow-modal);
`;
const TitleDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  max-width: 380px;
  width: 100%;
`;

const TitleScuccess = styled.div`
  ${Typography.heading_md}
  color: var(--text-primary);
`;

const DescriptionSuccess = styled.div`
  ${Typography.body_md}
  color: var(--text-secondary);
`;
const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const BottomBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;
const IconDiv = styled.div`
  cursor: pointer;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 16px;
  right: 16px;
  .fillColor {
    stroke: var(--text-primary);
    :hover {
      background-color: var(--neutral-secondary);
      border-radius: 4px;
    }
  }
`;

// File Upload Design CSS
const FileBox = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
  max-height: 159px;
  overflow: auto;
  scrollbar-width: none;
`;

const FileBoxContainer = styled.div`
  cursor: pointer;
  .file-box {
    max-width: max-content;
    display: inline-flex;
    padding: 5px;
    background-color: var(--background-primary);
    border: 0.5px solid var(--border-primary);
    border-radius: 8px;
    box-shadow: var(--shadow-card);
    align-items: center;
    gap: 4px;
  }
  .textarea {
    width: 130px;
  }
  .h4 {
    ${Typography.body_md_medium}
    color:var(--text-primary);
    margin: 0 0 4px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    white-space: inherit;
    word-break: break-all;
  }
  .p {
    ${Typography.body_xs}
    color: var(--text-secondary);
    margin: 0;
  }
  .svg {
    width: 32px;
    height: 32px;
  }
  .image-div {
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  @media (max-width: 449px) {
    .p {
      padding-top: 0;
    }
  }
`;

const FileIconDiv = styled.div`
  position: relative;
  .file-text {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 35%;
    left: 25%;
    width: 50%;
    font-weight: 700;
    color: var(--text-secondary);
  }
`;

const CloseButton = styled.span`
  color: var(--text-secondary);
  cursor: pointer;
  padding-left: 12px;
`;

// Bottom Button Design CSS
const ButtonComponent = styled.div`
  width: 468px;
  display: flex;
  gap: 12px;
  padding: 0 16px 16px;
  position: absolute;
  bottom: 0;
  @media (max-width: 449px) {
    width: calc(100vw - 64px);
  }
`;
const ButtonLeft = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
  align-items: center;
`;

const ButtonRight = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
`;
const SvgDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  svg {
    path {
      fill: var(--text-secondary);
    }
  }
  &:hover {
    background-color: var(--neutral-secondary);
    border-radius: 50%;
    svg path {
      fill: var(--text-primary);
    }
  }
`;
const Uploaddiv = styled.div`
  color: var(--text-primary);
`;

const InputDiv = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
`;

export {
  MainDiv,
  Header,
  Title,
  Description,
  Form,
  ModalBox,
  TitleScuccess,
  Bottom,
  TitleDiv,
  DescriptionSuccess,
  BottomBox,
  IconDiv,
  FileBox,
  FileBoxContainer,
  FileIconDiv,
  CloseButton,
  ButtonComponent,
  ButtonLeft,
  ButtonRight,
  SvgDiv,
  Uploaddiv,
  InputDiv
};
