import styled, { css } from 'styled-components';
import { Typography } from '../../../global/theme';

interface Props {
  isArchive?: boolean;
}
const ModalMain = styled.div`
  padding: 0px;
  background-color: var(--background-primary);
  border-radius: 12px;
  @media (max-width: 449px) {
    width: 100%;
  }
`;

const Modalhead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 0.5px solid var(--border-primary);
`;

const Headline = styled.h6<Props>`
  ${Typography.body_lg_medium}
  color: var(--text-primary);
  margin: 0;

  ${(props: Props) =>
    props.isArchive &&
    css`
      ${Typography.body_lg_medium}
    `}
`;

const Deletewarning = styled.h6`
  ${Typography.body_md}
  color: var(--text-primary);
  margin: 0;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  gap: 12px;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;
`;
const Icon = styled.div`
  display: inline-flex;
  cursor: pointer;
  .fillColor {
    stroke: var(--text-primary);
  }
  :hover {
    background-color: var(--neutral-secondary);
    border-radius: 4px;
  }
`;

export { ModalMain, Modalhead, Headline, Deletewarning, Buttons, Icon, ModalContent };
