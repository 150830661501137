import { combineReducers } from 'redux';
import { createReducer } from '../helpers/reduxHelpers';
import { createReducer as createReducerOrig, PayloadAction, current } from '@reduxjs/toolkit';
import * as Actions from '../actions/types';
import { isEmpty } from 'lodash';
import { MilestoneListInterface, ProjectStatusInterface } from '../interfaces/ProjectInterface';
import { ReducerInterface } from '../interfaces/ReducerInterface';
import { FILES_LIST_TYPE } from '../global/constants';

const setProjectLoaderReducer = createReducer({
  initialState: false,
  actionType: Actions.SET_PROJECT_LOADER
});

const setProjectListReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_PROJECT_LIST
});

const setFavouriteProjectsReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_FAVOURITE_PROJECT_LIST
});

const setProjectFilesReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_PROJECT_FILES
});

const setMoveProjectFilesReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_MOVE_PROJECT_FILES
});

const setProjectAllFilesReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_PROJECT_ALL_FILES
});

const setDiscussionData: any = createReducer({
  initialState: [],
  actionType: Actions.SET_DISCUSSION_DATA
});

const fileViewDataReducer: any = createReducer({
  initialState: {},
  actionType: Actions.SET_FILE_VIEW_DATA
});

const setProjectActivityReducer: any = createReducer({
  initialState: [],
  actionType: Actions.SET_PROJECT_ACTIVITY
});

const setProjectArchiveDataReducer: any = createReducer({
  initialState: [],
  actionType: Actions.SET_PROJECT_ARCHIVE_DATA
});

const setProjectListUserNotInReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_PROJECT_LIST_USER_NOT_IN
});

const initialState = {
  name: '',
  customer: undefined,
  startDate: new Date().toISOString(),
  dueDate: ''
};
const createProjectReducer = createReducerOrig(initialState, (builder) => {
  builder
    .addCase(Actions.SET_PROJECT_DETAIL, (state = initialState, action: PayloadAction<any, never>) => {
      return { ...(action.payload || {}) };
    })
    .addCase(Actions.UPDATE_PROJECT_DETAIL, (state, action: PayloadAction<ReducerInterface, never>) => {
      const project = JSON.parse(JSON.stringify(current(state)));
      project[action.payload.propsName] = action.payload.value;
      return project;
    })
    .addCase(Actions.CLEAR_PROJECT_DETAIL, () => {
      const initialData = JSON.parse(JSON.stringify(initialState));
      return initialData;
    });
});

const initialDocState = {
  autoSaved: false,
  content: '',
  name: 'New Document',
  projectId: '',
  isPublic: false
};

const setDocDataReducer = createReducerOrig(initialDocState, (builder) => {
  builder
    .addCase(Actions.SET_DOC_DATA, (state = initialDocState, action: PayloadAction<any, never>) => {
      return { ...(action.payload || {}) };
    })
    .addCase(Actions.UPDATE_DOC_DATA, (state, action: PayloadAction<any, never>) => {
      const doc = JSON.parse(JSON.stringify(current(state)));
      doc[action.payload.propsName] = action.payload.value;
      return doc;
    })
    .addCase(Actions.CLEAR_DOC_DATA, () => {
      const initialData = JSON.parse(JSON.stringify(initialDocState));
      return initialData;
    });
});

const initialProjectStatus = {};
const setProjectStatusReducer = createReducerOrig(initialProjectStatus, (builder) => {
  builder
    .addCase(
      Actions.SET_PROJECT_STATUS,
      (state = initialProjectStatus, action: PayloadAction<ProjectStatusInterface, never>) => {
        return { ...(action.payload || {}) };
      }
    )
    .addCase(Actions.CLEAR_PROJECT_STATUS, () => {
      const initialData = JSON.parse(JSON.stringify(initialProjectStatus));
      return initialData;
    });
});

const initialCurrentProjectState = {};
const setCurrentProjectReducer = createReducerOrig(initialCurrentProjectState, (builder) => {
  builder
    .addCase(Actions.SET_CURRENT_PROJECT, (state = initialCurrentProjectState, action: PayloadAction<any, never>) => {
      return { ...(action.payload || {}) };
    })
    .addCase(Actions.UPDATE_CURRENT_PROJECT, (state, action: PayloadAction<any, never>) => {
      const input = JSON.parse(JSON.stringify(current(state)));
      input[action.payload.propsName] = action.payload.value;
      return input;
    })
    .addCase(Actions.CLEAR_CURRENT_PROJECT, () => {
      const input = JSON.parse(JSON.stringify(initialCurrentProjectState));
      return input;
    });
});

const initialGroupInput = {
  createdOn: '',
  id: '',
  name: '',
  projectId: '',
  tasks: []
};

const setInputGrouptDetailReducer = createReducerOrig(initialGroupInput, (builder) => {
  builder
    .addCase(Actions.SET_GROUP_INPUT, (state = initialGroupInput, action: PayloadAction<any, never>) => {
      return { ...(action.payload || {}) };
    })
    .addCase(Actions.UPDATE_GROUP_INPUT, (state, action: PayloadAction<any, never>) => {
      const input = JSON.parse(JSON.stringify(current(state)));
      input[action.payload.propsName] = action.payload.value;
      return input;
    })
    .addCase(Actions.CLEAR_GROUP_INPUT, () => {
      const input = JSON.parse(JSON.stringify(initialGroupInput));
      return input;
    });
});

const initialLabelInput = {
  name: '',
  color: ''
};

const setInputLabelReducer = createReducerOrig(initialLabelInput, (builder) => {
  builder
    .addCase(Actions.SET_LABEL_INPUT, (state = initialLabelInput, action: PayloadAction<any, never>) => {
      return { ...(action.payload || {}) };
    })
    .addCase(Actions.UPDATE_LABEL_INPUT, (state, action: PayloadAction<any, never>) => {
      const input = JSON.parse(JSON.stringify(current(state)));
      input[action.payload.propsName] = action.payload.value;
      return input;
    })
    .addCase(Actions.CLEAR_LABEL_INPUT, () => {
      const input = JSON.parse(JSON.stringify(initialLabelInput));
      return input;
    });
});

const initialmilestoneInput = {
  milestoneName: ''
};

const milestoneInputReducer = createReducerOrig(initialmilestoneInput, (builder) => {
  builder
    .addCase(
      Actions.SET_MILESTONE_INPUT,
      (state = initialmilestoneInput, action: PayloadAction<MilestoneListInterface, never>) => {
        return { ...(action.payload || {}) };
      }
    )
    .addCase(Actions.UPDATE_MILESTONE_INPUT, (state, action: PayloadAction<ReducerInterface, never>) => {
      const input = JSON.parse(JSON.stringify(current(state)));
      input[action.payload.propsName] = action.payload.value;
      return input;
    })
    .addCase(Actions.CLEAR_MILESTONE_INPUT, () => {
      const input = JSON.parse(JSON.stringify(initialmilestoneInput));
      return input;
    });
});

const setUserActivityReducer: any = createReducer({
  initialState: [],
  actionType: Actions.SET_USER_ACTIVITY
});

const initialMilestoneState: Array<any> = [];
const setMilestoneListReducer = createReducerOrig(initialMilestoneState, (builder) => {
  builder
    .addCase(Actions.SET_MILESTONE_LIST, (state = initialMilestoneState, action: PayloadAction<any, never>) => {
      return [...(action.payload || [])];
    })
    .addCase(Actions.UPDATE_MILESTONE_LIST, (state, action: PayloadAction<any, never>) => {
      let task = JSON.parse(JSON.stringify(current(state)));
      task = task?.map((item: any) => {
        if (!isEmpty(item?.id)) {
          return item?.id === action?.payload?.id ? action?.payload : item;
        } else {
          const id = item?.tasks?.map((ele: any) => ele?.groupId);
          return id === action?.payload?.id ? action?.payload : item;
        }
      });
      return task;
    });
});

const archiveMilestoneState: Array<any> = [];
const archiveMilestoneListReducer = createReducerOrig(archiveMilestoneState, (builder) => {
  builder
    .addCase(Actions.SET_ARCHIVE_MILESTONE_LIST, (state = archiveMilestoneState, action: PayloadAction<any, never>) => {
      return [...(action.payload || [])];
    })
    .addCase(Actions.UPDATE_ARCHIVE_MILESTONE_LIST, (state, action: PayloadAction<any, never>) => {
      let task = JSON.parse(JSON.stringify(current(state)));
      task = task?.map((item: any) => {
        if (!isEmpty(item?.id)) {
          return item?.id === action?.payload?.id ? action?.payload : item;
        } else {
          const id = item?.tasks?.map((ele: any) => ele?.groupId);
          return id === action?.payload?.id ? action?.payload : item;
        }
      });
      return task;
    });
});

const setProjectTaskMilestoneListReducer: any = createReducer({
  initialState: [],
  actionType: Actions.SET_PROJECT_TASK_MILESTONE_LIST
});

const setUploadFilePercent = createReducer({
  initialState: 0,
  actionType: Actions.SET_UPLOAD_FILE_PERCENTAGE
});

const setFilesListTypeReducer = createReducer({
  initialState: FILES_LIST_TYPE.GRID,
  actionType: Actions.SET_FILES_LIST_TYPE
});

const initialStatusInput = {
  name: '',
  projectId: '',
  type: ''
};

const setInputStatusDetailReducer = createReducerOrig(initialStatusInput, (builder) => {
  builder
    .addCase(Actions.SET_STATUS_INPUT, (state = initialStatusInput, action: PayloadAction<any, never>) => {
      return { ...(action.payload || {}) };
    })
    .addCase(Actions.UPDATE_STATUS_INPUT, (state, action: PayloadAction<any, never>) => {
      const input = JSON.parse(JSON.stringify(current(state)));
      input[action.payload.propsName] = action.payload.value;
      return input;
    })
    .addCase(Actions.CLEAR_STATUS_INPUT, () => {
      const input = JSON.parse(JSON.stringify(initialGroupInput));
      return input;
    });
});

const projectReducer = combineReducers({
  loading: setProjectLoaderReducer,
  newProjectItem: createProjectReducer,
  list: setProjectListReducer,
  favouriteProjects: setFavouriteProjectsReducer,
  projectFiles: setProjectFilesReducer,
  moveProjectFiles: setMoveProjectFilesReducer,
  projectAllFiles: setProjectAllFilesReducer,
  currentProject: setCurrentProjectReducer,
  projectStatus: setProjectStatusReducer,
  discussionData: setDiscussionData,
  docData: setDocDataReducer,
  fileViewData: fileViewDataReducer,
  projectActivity: setProjectActivityReducer,
  projectArchiveData: setProjectArchiveDataReducer,
  createGroupInput: setInputGrouptDetailReducer,
  userActivity: setUserActivityReducer,
  milestoneList: setMilestoneListReducer,
  archiveMilestoneList: archiveMilestoneListReducer,
  milestoneInput: milestoneInputReducer,
  projectTaskMilestoneList: setProjectTaskMilestoneListReducer,
  uploadFilePercent: setUploadFilePercent,
  filesViewListType: setFilesListTypeReducer,
  labelInput: setInputLabelReducer,
  statusInput: setInputStatusDetailReducer,
  projectListUserNotIn: setProjectListUserNotInReducer
});

export default projectReducer;
