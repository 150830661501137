import { Dispatch } from 'redux';
import { clearCustomerData } from '../actions/customerActions';
import { clearInboxData } from '../actions/inboxActions';
import { clearResponseMessage } from '../actions/messageActions';
import { clearProjectData } from '../actions/projectActions';
import { clearSettingData } from '../actions/settingActions';
import { clearProjectTasksData } from '../actions/taskActions';
import { clearReportsData } from '../actions/reportActions';
import { clearTimeTrackingData } from '../actions/timeTrackingActions';
import { clearChatMessageData } from '../actions/chatMessageActions';
import { captureException } from './logService';

/**
 * @desc load required data
 * @param {*}
 */
export const clearPreviousWorkspaceData: any = () => async (dispatch: Dispatch<any>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(clearInboxData());
    dispatch(clearSettingData());
    dispatch(clearProjectData());
    dispatch(clearCustomerData());
    dispatch(clearChatMessageData());
    dispatch(clearProjectTasksData());
    dispatch(clearReportsData());
    dispatch(clearTimeTrackingData());
    return true;
  } catch (e) {
    captureException(e);
    console.log('error', e);
    return false;
  }
};
