import React from 'react';
import { Circle, Line, Loadingheader } from './styles';

export default function MessageHeaderLoading() {
  return (
    <>
      <Loadingheader>
        <Circle className='loading-animation'></Circle>
        <Line className='loading-animation'></Line>
      </Loadingheader>
    </>
  );
}
