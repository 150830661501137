/* eslint-disable max-len */
import React, { useCallback, useMemo, useState } from 'react';
import { Container, Boxmodel, Header, Title, Blockcontent, Maincontent, Div, ShowTask, Showhide } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  File,
  MilestoneListInterface,
  ProjectDetailInterface,
  StatusListInterface
} from '../../../../../interfaces/ProjectInterface';
import { getFileContent, isEmpty } from '../../../../../helpers/common';
import Collapsedopdown from '../../../../../component/dropdowns/collapseDropdown';
import Deletemodal from '../../../../../component/models/deleteModel';
import CreateTaskModal from '../../../../../component/models/createTaskModal';
import {
  clearCreateTaskInput,
  setCreateTaskInput,
  setFilteredTasksList,
  setMyTaskDetailsData,
  setOtherTaskList,
  updateCreateTaskInput,
  updateFilteredTasksList,
  updatSubTaskList
} from '../../../../../actions/taskActions';
import { useHotkeys } from 'react-hotkeys-hook';
import {
  COMMENT_TYPE,
  SHOW_TASK_BY_ORDER,
  SHOW_TASK_GROUPING_TYPE,
  STATUS_TYPE,
  TASK_PRIORITY
} from '../../../../../global/constants';
import { nanoid } from 'nanoid';
import UserPreferenceSingleton from '../../../../../helpers/userPreferenceSingleton';
import ModalCustom from '../../../../../component/models/modal';
import {
  createNewComment,
  getMyTaskDetailsData,
  getTaskGroupList,
  onChangeComment,
  updateTaskDetails
} from '../../../../../services/taskServices';
import Taskgridloading from '../../../../../component/loading/taskgridloading';
import { trackAnalyticActivity } from '../../../../../services/analyticsService';
import { Droppable, Draggable, DragDropContext, DropResult } from 'react-beautiful-dnd';
import {
  DataUpdatedCommentsInterface,
  FilteredTasksListInterface,
  TaskDetailsInterface,
  TaskGroupInterface
} from '../../../../../interfaces/TaskInterface';
import { captureException } from '../../../../../services/logService';
import Calendarviewcard from '../../../../../component/calendarviewCard';
import { RootReducerInterface } from '../../../../../interfaces/RootReducerInterface';
import MyCustomContextMenu from '../../../../../component/customContextMenu';
import ConfirmationModal from '../../../../../component/models/confirmationModal';
import { TASK_ANALYTICS } from '../../../../../global/analyticsConstants';
// eslint-disable-next-line @typescript-eslint/no-redeclare
declare type DropResult = typeof import('react-beautiful-dnd');
// eslint-disable-next-line @typescript-eslint/no-redeclare
declare type DraggableProvided = typeof import('react-beautiful-dnd');
interface Props {
  loadData: () => void;
  setModalOpen?: boolean;
  propsLoading: boolean;
  groupingStatus: number;
  taskGroups: TaskGroupInterface[];
  currentProject: ProjectDetailInterface;
}

const TaskGrid: React.FC<Props> = (props) => {
  const { loadData, setModalOpen, propsLoading, groupingStatus, taskGroups, currentProject } = props;
  // States
  const [currentActiveTask, setCurrentActiveTask] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [isOpenFromShortcut, setIsOpenFromShortcut] = useState(false);
  const [selectedItem, setSelectedItem] = useState<TaskDetailsInterface>();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showcomplatedtask, setShowcomplatedtask] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [isConfirmationModelOpen, setIsConfirmationModelOpen] = useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);
  const [currentTask, setCurrentTask] = useState<TaskDetailsInterface>();
  const [currentGroup, setCurrentGroup] = useState<FilteredTasksListInterface>();
  const [cancelLoading, setCancelLoading] = useState(false);
  const [status, setStatus] = useState<StatusListInterface>();
  const [previousStatus, setPreviousStatus] = useState<StatusListInterface>();
  const [isCheckBox, setIsCheckBox] = useState(false);
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { task: tasksSelector, project: projectSelector, workspace: workspaceSelector } = stateSelector || {};
  const { loading: reducerLoading, milestoneList } = projectSelector;
  const { loading: taskLoading, taskFilter, otherTask, filteredTasksList } = tasksSelector;
  const { workspace } = workspaceSelector;
  const { id: workspace_id } = workspace;
  const loading = reducerLoading || propsLoading || taskLoading;
  // Other variables
  const dispatch = useDispatch();
  const history = useHistory();

  //toggle completed task
  const togglecomplatedtask = useCallback((value: boolean, id: string) => {
    if (!value) {
      setSelectedId('');
    }
    setSelectedId(id);
    setShowcomplatedtask(value);
  }, []);

  //open delete modal
  const OpenDeleteModal = useCallback((item: TaskDetailsInterface) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  }, []);

  //close delete modal
  const closeDeleteModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  //open confirmation modal
  const handleOpenConfirmationModal = useCallback((isCheckBox: boolean) => {
    setIsCheckBox(isCheckBox);
    setIsConfirmationModelOpen(true);
  }, []);

  //close confirmation modal
  const closeConfirmationModal = useCallback(() => {
    setIsConfirmationModelOpen(false);
  }, []);

  //handle edit task
  const onClickEdit = useCallback(
    async (task: TaskDetailsInterface) => {
      const taskData = await dispatch(getMyTaskDetailsData(task?.id));
      const contentData = getFileContent(taskData?.description);
      const taskClone = JSON.parse(JSON.stringify(taskData));
      taskClone.description = `${taskData?.description || ''}<p></p>`;
      taskClone.uploadedFiles = contentData?.uploadedFiles;
      dispatch(setCreateTaskInput(taskClone));
      await dispatch(getTaskGroupList(currentProject?.id, true));
      if (!isEmpty(task?.groupId)) {
        const groupId = taskGroups?.find((group) => group.id === task?.groupId);
        dispatch(updateCreateTaskInput({ propsName: 'groupId', value: groupId }));
      }
      dispatch(updateCreateTaskInput({ propsName: 'projectId', value: currentProject }));
      dispatch(
        updateCreateTaskInput({
          propsName: 'priority',
          value:
            taskData?.priority !== undefined
              ? taskData.priority
              : currentProject?.priority?.default !== undefined
              ? currentProject.priority.default
              : 0
        })
      );
      setOpen(true);
    },
    [currentProject, dispatch, taskGroups]
  );

  //handle clone task
  const onClickClone = useCallback(
    async (task: TaskDetailsInterface) => {
      const taskData = await dispatch(getMyTaskDetailsData(task?.id));
      const taskFiles = taskData?.Files?.map((item: File) => {
        const { __typename, ...other } = item;
        return other;
      });
      const cloneTaskdetails = {
        Files: taskFiles,
        description: `${taskData?.description || ''}<p></p>`,
        name: taskData?.name,
        groupId: taskData?.groupId,
        projectId: taskData?.projectId,
        priority: taskData?.priority,
        users: taskData?.users,
        labels: taskData?.labels,
        estimate: taskData?.estimate,
        milestoneId: taskData?.milestoneId,
        statusId: taskData?.statusId,
        parentTaskId: !isEmpty(taskData?.parentTaskId) ? taskData?.parentTaskId : null
      };
      dispatch(setCreateTaskInput(cloneTaskdetails));
      if (!isEmpty(task?.groupId)) {
        const groupId = taskGroups?.find((group) => group.id === task?.groupId);
        dispatch(updateCreateTaskInput({ propsName: 'groupId', value: groupId }));
      }
      dispatch(updateCreateTaskInput({ propsName: 'projectId', value: currentProject }));
      await dispatch(getTaskGroupList(currentProject?.id, true));
      dispatch(
        updateCreateTaskInput({
          propsName: 'priority',
          value:
            taskData?.priority !== undefined
              ? taskData.priority
              : currentProject?.priority?.default !== undefined
              ? currentProject.priority.default
              : 0
        })
      );
      setOpen(true);
    },
    [dispatch, currentProject, taskGroups]
  );

  //create task close
  const close = useCallback(() => {
    setIsOpenFromShortcut(false);
    setOpen(false);
    dispatch(clearCreateTaskInput());
  }, [dispatch]);

  //handle click task details
  const onClickTaskDetails = useCallback(
    (task: TaskDetailsInterface) => {
      const eventProperties = {
        source: 'task list',
        title: task?.name || ''
      };
      trackAnalyticActivity(TASK_ANALYTICS.VIEW, eventProperties);
      dispatch(setMyTaskDetailsData(task));
      history.push(`/projects/details/${task?.projectId}/tasks?&task=${task?.id}`);
    },
    [dispatch, history]
  );

  //handle click task checkbox
  const onClickTaskCheckbox = useCallback(
    async (e: React.SyntheticEvent, item: TaskDetailsInterface, group: FilteredTasksListInterface, isOpen: boolean) => {
      e.stopPropagation();
      if (item?.subTasks) {
        let allSubTasksStatus = true;
        if (item?.subTasks && item?.subTasks?.length > 0) {
          for (const subTask of item.subTasks) {
            if (subTask?.status === false) {
              allSubTasksStatus = false;
              break;
            }
          }
        }
        if (allSubTasksStatus === false && !item?.status && isEmpty(item?.parentTaskId)) {
          setCurrentTask(item);
          setCurrentGroup(group);
          handleOpenConfirmationModal(true);
          return;
        }
      }
      let taskData = JSON.parse(JSON.stringify(item));
      let groupData = JSON.parse(JSON.stringify(group || {}));
      const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
      const newComment: DataUpdatedCommentsInterface = {
        id: nanoid(),
        createdOn: new Date().toISOString(),
        updatedBy: userDetails?.id,
        updatedTime: new Date().toISOString(),
        type: COMMENT_TYPE.STATUS_CHANGE,
        status: !item?.status,
        isArchived: false,
        user: { ...userDetails, name: userDetails?.given_name }
      };
      const allComments = item?.updatedComments;
      const comments = [...(allComments || []), newComment];
      taskData.status = !item?.status;
      taskData.companyId = workspace_id;
      taskData.updatedComments = comments;

      if (isOpen) {
        let tasksList = groupData?.tasks?.map((obj: TaskDetailsInterface) => {
          return obj.id === taskData.id ? taskData : obj;
        });
        taskData.status = !item?.status;
        groupData.tasks = tasksList;
        dispatch(updateFilteredTasksList(groupData));
      } else {
        let completedTasksList = groupData?.completedTasks?.filter(
          (task: FilteredTasksListInterface) => task.id !== taskData.id
        );
        groupData.completedTasks = completedTasksList;
        groupData.tasks.splice(0, 0, taskData);
        dispatch(updateFilteredTasksList(groupData));
      }
      const payloadTask = { status: !item?.status, projectId: item?.projectId };
      const result = await dispatch(updateTaskDetails(item?.id, payloadTask));

      const commentResult = await dispatch(
        createNewComment(item?.id, {
          Type: COMMENT_TYPE.STATUS_CHANGE,
          Status: !item?.status
        })
      );
      if (result && commentResult) {
        if (!item?.status) trackAnalyticActivity(TASK_ANALYTICS.MARKED_DONE);
        else {
          trackAnalyticActivity(TASK_ANALYTICS.REOPENED);
          loadData();
        }
      }
    },
    [dispatch, handleOpenConfirmationModal, loadData, workspace_id]
  );

  //handle click other task checkbox
  const onClickOtherTaskCheckbox = useCallback(
    async (e: React.SyntheticEvent, item: TaskDetailsInterface, isOpen: boolean) => {
      e.stopPropagation();
      let taskData = JSON.parse(JSON.stringify(item));
      const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
      const newComment: DataUpdatedCommentsInterface = {
        id: nanoid(),
        createdOn: new Date().toISOString(),
        updatedBy: userDetails?.id,
        updatedTime: new Date().toISOString(),
        type: COMMENT_TYPE.STATUS_CHANGE,
        status: !item?.status,
        isArchived: false,
        user: { ...userDetails, name: userDetails?.given_name }
      };

      const allComments = item?.updatedComments;
      const comments = [...(allComments || []), newComment];
      taskData.companyId = workspace_id;
      taskData.updatedComments = comments;
      taskData.status = !item?.status;
      if (isOpen) {
        let tasksList = otherTask?.tasks?.map((obj) => {
          return obj?.id === taskData?.id ? taskData : obj;
        });
        otherTask.tasks = tasksList;
        dispatch(setOtherTaskList(otherTask));
      } else {
        let taskToRemove = otherTask?.completedTasks?.find((obj) => obj.id === taskData.id);
        taskToRemove = JSON.parse(JSON.stringify(taskToRemove));
        if (taskToRemove) {
          let updatedCompletedTasks = otherTask?.completedTasks?.filter((obj) => obj.id !== taskData.id);
          otherTask.completedTasks = updatedCompletedTasks;
          taskToRemove.status = !item?.status;
          let updatedTasks = [...(otherTask?.tasks || []), taskToRemove];
          otherTask.tasks = updatedTasks;

          dispatch(setOtherTaskList(otherTask));
        }
      }
      const payloadTask = { status: !item?.status, projectId: item?.projectId };
      const result = await dispatch(updateTaskDetails(item?.id, payloadTask));
      const commentResult = await dispatch(
        createNewComment(item?.id, {
          Type: COMMENT_TYPE.STATUS_CHANGE,
          Status: !item?.status
        })
      );
      if (result && commentResult) {
        if (!item?.status) trackAnalyticActivity(TASK_ANALYTICS.MARKED_DONE);
        else trackAnalyticActivity(TASK_ANALYTICS.REOPENED);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, otherTask]
  );

  //handle delete task
  const deleteTask = useCallback(
    async (task: TaskDetailsInterface) => {
      try {
        setDeleteLoading(true);
        const payloadTask = { isArchived: true, projectId: task?.projectId };
        const response = await dispatch(updateTaskDetails(task?.id, payloadTask));
        setIsModalOpen(false);
        setSelectedItem(undefined);
        if (response) {
          loadData();
        }
      } catch (error) {
        captureException(error);
        console.log('error', error);
      } finally {
        setDeleteLoading(false);
      }
    },
    [dispatch, loadData]
  );

  useHotkeys('c', async () => {
    setTimeout(async () => {
      setIsOpenFromShortcut(true);
      setOpen(true);
      dispatch(updateCreateTaskInput({ propsName: 'projectId', value: currentProject }));
      dispatch(updateCreateTaskInput({ propsName: 'priority', value: currentProject?.priority?.default || 0 }));
      if (taskGroups?.length > 0) {
        dispatch(updateCreateTaskInput({ propsName: 'groupId', value: taskGroups[0] }));
      }
    }, 100);
  });

  //handle drag end
  const onDragEnd = useCallback(
    async (result: DropResult) => {
      if (
        !result.destination ||
        groupingStatus === SHOW_TASK_GROUPING_TYPE.NO_GROUPING ||
        groupingStatus === SHOW_TASK_GROUPING_TYPE.GROUPING_BY_USER
      ) {
        return filteredTasksList;
      }
      const sourceGroupId = result.source.droppableId;
      const destinationGroupId = result.destination.droppableId;

      if (
        sourceGroupId === destinationGroupId &&
        taskFilter?.orderByStatus === SHOW_TASK_BY_ORDER.CUSTOM &&
        result?.destination?.index !== result.source.index
      ) {
        const filteredlistClone: FilteredTasksListInterface[] = JSON.parse(JSON.stringify(filteredTasksList || []));
        const groupIndex = filteredlistClone.findIndex((group) => group.id === sourceGroupId);
        if (groupIndex !== -1) {
          const currentGroup = filteredlistClone?.[groupIndex];
          const draggedTaskIndex = currentGroup?.tasks?.findIndex(
            (task: { id: string }) => task.id === result.draggableId
          );
          const draggedTaskCompletedIndex = currentGroup?.completedTasks?.findIndex(
            (task: { id: string }) => task.id === result.draggableId
          );
          const draggedTask = currentGroup?.tasks?.find((task: { id: string }) => task.id === result.draggableId);
          if (draggedTaskIndex !== -1 || draggedTaskCompletedIndex !== -1) {
            const groupTasks = JSON.parse(
              JSON.stringify(draggedTaskIndex !== -1 ? currentGroup?.tasks || [] : currentGroup?.completedTasks || [])
            );
            const newIndex = result?.destination?.index;
            let updatedTaskIndex: number;
            const updatedTaskList = JSON.parse(JSON.stringify(filteredTasksList || []));
            if (newIndex === 0) {
              const nextTaskIndex = groupTasks?.[0]?.index;
              updatedTaskIndex = nextTaskIndex ? nextTaskIndex - 50 : new Date(draggedTask?.createdOn || '').getTime();
            } else if (newIndex === groupTasks?.length - 1) {
              const previousTaskIndex = groupTasks?.[groupTasks?.length - 1]?.index;
              updatedTaskIndex = previousTaskIndex
                ? previousTaskIndex + 50
                : new Date(draggedTask?.createdOn || '').getTime();
            } else {
              if (result?.destination?.index > result.source.index) {
                const previousTaskIndex: number = groupTasks?.[result?.destination?.index]?.index || 0;
                const nextTaskIndex: number = groupTasks?.[result?.destination?.index + 1]?.index || 0;
                updatedTaskIndex = (previousTaskIndex + nextTaskIndex) / 2;
              } else {
                const previousTaskIndex: number = groupTasks?.[result?.destination?.index - 1]?.index || 0;
                const nextTaskIndex: number = groupTasks?.[result?.destination?.index]?.index || 0;
                updatedTaskIndex = (previousTaskIndex + nextTaskIndex) / 2;
              }
            }
            const elementToMove = groupTasks.splice(result.source.index, 1)[0];
            groupTasks.splice(result.destination.index, 0, { ...(elementToMove || []), index: updatedTaskIndex });
            if (draggedTaskIndex !== -1) updatedTaskList[groupIndex].tasks = groupTasks;
            else updatedTaskList[groupIndex].completedTasks = groupTasks;
            dispatch(setFilteredTasksList(updatedTaskList));
            const payloadTask = { index: Number(updatedTaskIndex), projectId: currentProject?.id };
            await dispatch(updateTaskDetails(result?.draggableId, payloadTask));
          }
        }
        return;
      } else if (sourceGroupId === destinationGroupId) {
        return filteredTasksList;
      }

      const newFilteredlist = JSON.parse(JSON.stringify(filteredTasksList));

      const updatedTaskList = [...newFilteredlist];

      const sourceGroupIndex = updatedTaskList.findIndex((group) => group.id === sourceGroupId);
      const destinationGroupIndex = updatedTaskList.findIndex((group) => group.id === destinationGroupId);

      if (sourceGroupIndex !== -1 && destinationGroupIndex !== -1) {
        const draggedTaskIndex = updatedTaskList[sourceGroupIndex]?.tasks?.findIndex(
          (task: { id: string }) => task?.id === result?.draggableId
        );
        const draggedCompletedTaskIndex = updatedTaskList[sourceGroupIndex]?.completedTasks?.findIndex(
          (task: { id: string }) => task?.id === result?.draggableId
        );

        if (draggedTaskIndex !== -1 || draggedCompletedTaskIndex !== -1) {
          const newIndex = result?.destination?.index;
          const taskListKey = draggedTaskIndex !== -1 ? 'tasks' : 'completedTasks';
          const updatedSourceTasks = JSON.parse(JSON.stringify(updatedTaskList[sourceGroupIndex]?.[taskListKey] || []));
          const taskToMove: TaskDetailsInterface = JSON.parse(
            JSON.stringify(
              updatedSourceTasks[draggedTaskIndex !== -1 ? draggedTaskIndex : draggedCompletedTaskIndex] || {}
            )
          );
          updatedSourceTasks.splice(draggedTaskIndex !== -1 ? draggedTaskIndex : draggedCompletedTaskIndex, 1);

          let payload: {
            index?: number;
            groupId?: string;
            statusId?: string;
            milestoneId?: string;
            priority?: number;
          } = {};

          if (
            groupingStatus === SHOW_TASK_GROUPING_TYPE.GROUPING_BY_GROUP ||
            (groupingStatus === SHOW_TASK_GROUPING_TYPE.DEFAULT && currentProject?.isGroupEnabled)
          ) {
            taskToMove.groupId = destinationGroupId;
            const previousGroup = taskGroups?.find((x) => x?.id === sourceGroupId);
            const updatedGroup = taskGroups?.find((x) => x?.id === destinationGroupId);
            dispatch(
              createNewComment(result?.draggableId, {
                Type: COMMENT_TYPE.CHANGE_GROUP,
                PreviousGroup: previousGroup?.name,
                Group: updatedGroup?.name
              })
            );
            payload = {
              groupId: destinationGroupId
            };
          } else if (groupingStatus === SHOW_TASK_GROUPING_TYPE.GROUPING_BY_STATUS) {
            taskToMove.statusId = destinationGroupId;
            const currentStatus = currentProject?.statusData?.find((x) => x['_id'] === destinationGroupId);
            const previousStatus = currentProject?.statusData?.find((x) => x['_id'] === sourceGroupId);
            dispatch(
              createNewComment(result?.draggableId, {
                Type: COMMENT_TYPE.STATUS_ID_CHANGE,
                PreviousGroup: previousStatus?.Name,
                Group: currentStatus?.Name,
                Priority: currentStatus?.Type
              })
            );
            payload = {
              statusId: destinationGroupId
            };
          } else if (groupingStatus === SHOW_TASK_GROUPING_TYPE.GROUPING_BY_MILESTONE) {
            taskToMove.milestoneId = destinationGroupId;
            const milestone = milestoneList?.find((x) => x?.id === destinationGroupId);
            dispatch(
              createNewComment(result?.draggableId, {
                Type: COMMENT_TYPE.SET_MILESTONE,
                Group: milestone?.milestoneName
              })
            );
            payload = {
              milestoneId: destinationGroupId
            };
          } else if (groupingStatus === SHOW_TASK_GROUPING_TYPE.GROUPING_BY_PRIORITY) {
            taskToMove.priority = destinationGroupId;
            dispatch(
              createNewComment(result?.draggableId, {
                Type: COMMENT_TYPE.CHANGE_PRIORITY,
                PreviousPriority: Number(sourceGroupId) === 5 ? TASK_PRIORITY.NO_PRIORITY : Number(sourceGroupId),
                Priority: Number(destinationGroupId) === 5 ? TASK_PRIORITY.NO_PRIORITY : Number(destinationGroupId)
              })
            );
            if (taskToMove.priority === 5) {
              payload = {
                priority: TASK_PRIORITY.NO_PRIORITY
              };
            } else {
              payload = {
                priority: destinationGroupId
              };
            }
          }

          const destinationTasks = updatedTaskList[destinationGroupIndex]?.[taskListKey] || [];
          let newTaskIndex = 0;
          if (taskFilter?.orderByStatus === SHOW_TASK_BY_ORDER.CUSTOM) {
            if (destinationTasks.length === 0 || newIndex >= destinationTasks.length) {
              newTaskIndex =
                destinationTasks.length === 0
                  ? new Date(taskToMove.createdOn || '').getTime()
                  : destinationTasks[destinationTasks.length - 1].index + 50;
            } else if (newIndex === 0) {
              const nextTaskIndex = destinationTasks[0].index;
              newTaskIndex = nextTaskIndex ? nextTaskIndex - 50 : new Date(taskToMove.createdOn || '').getTime();
            } else {
              const previousTaskIndex = destinationTasks[newIndex - 1].index || 0;
              const nextTaskIndex = destinationTasks[newIndex].index || 0;
              newTaskIndex = (previousTaskIndex + nextTaskIndex) / 2;
            }

            taskToMove.index = newTaskIndex;
            destinationTasks.splice(newIndex, 0, taskToMove);
          } else {
            destinationTasks.push(taskToMove);
          }
          updatedTaskList[destinationGroupIndex][taskListKey] = destinationTasks;
          updatedTaskList[sourceGroupIndex][taskListKey].splice(
            draggedTaskIndex !== -1 ? draggedTaskIndex : draggedCompletedTaskIndex,
            1
          );
          dispatch(setFilteredTasksList(updatedTaskList));
          if (taskFilter?.orderByStatus === SHOW_TASK_BY_ORDER.CUSTOM) {
            payload.index = newTaskIndex;
          }
          const payloadTask = { ...(payload || {}), projectId: currentProject?.id };
          const resultUpdate = await dispatch(updateTaskDetails(result?.draggableId, payloadTask));
          if (resultUpdate) {
            trackAnalyticActivity(TASK_ANALYTICS.GROUP_CHANGED);
          }
        }
      }
    },
    [
      groupingStatus,
      taskFilter?.orderByStatus,
      filteredTasksList,
      dispatch,
      currentProject?.id,
      currentProject?.isGroupEnabled,
      currentProject?.statusData,
      taskGroups,
      milestoneList
    ]
  );

  //handle task milestone
  const updateTaskMilestone = useCallback(
    async (currentTask: TaskDetailsInterface, milestone: MilestoneListInterface) => {
      let taskData = JSON.parse(JSON.stringify(currentTask));
      const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
      const newComment: DataUpdatedCommentsInterface = {
        id: nanoid(),
        createdOn: new Date().toISOString(),
        updatedBy: userDetails?.id,
        updatedTime: new Date().toISOString(),
        type: COMMENT_TYPE.SET_MILESTONE,
        group: milestone?.milestoneName,
        user: { ...userDetails, name: userDetails?.given_name },
        isArchived: false
      };
      const allComments = currentTask?.updatedComments;
      const comments = [...(allComments || []), newComment];
      taskData.milestoneId = milestone?.id;
      taskData.companyId = workspace_id;
      taskData.updatedComments = comments;
      const projectTaskClone: FilteredTasksListInterface[] = JSON.parse(JSON.stringify(filteredTasksList));
      const group = projectTaskClone?.find((group) =>
        group?.tasks?.some((task) => task?.groupId === currentTask?.groupId)
      );
      const tasksList = group?.tasks?.map((obj) => {
        return obj.id === taskData.id ? taskData : obj;
      });
      if (group) group.tasks = tasksList;

      dispatch(updateFilteredTasksList(group));
      const payloadTask = { milestoneId: milestone?.id, projectId: currentTask?.projectId };
      const result = await dispatch(updateTaskDetails(currentTask?.id, payloadTask));
      const commentResult = await dispatch(
        createNewComment(currentTask?.id, {
          Type: COMMENT_TYPE.SET_MILESTONE,
          Group: milestone?.milestoneName
        })
      );
      if (result && commentResult) {
        loadData();
      }
    },
    [workspace_id, filteredTasksList, dispatch, loadData]
  );

  //handle remove milestone
  const onRemoveMilestone = useCallback(
    async (task: TaskDetailsInterface) => {
      if (!isEmpty(task?.milestoneId)) {
        const payloadTask = { milestoneIdUnset: true, projectId: task?.projectId };
        const result = await dispatch(updateTaskDetails(task?.id, payloadTask));
        if (result) {
          loadData();
        }
      }
    },
    [dispatch, loadData]
  );

  //handle complete task
  const onCompleteProjectTask = useCallback(async () => {
    if (currentTask) {
      const commentData = onChangeComment(currentTask);
      let taskData = JSON.parse(JSON.stringify(currentTask || {}));
      let groupData = JSON.parse(JSON.stringify(currentGroup || {}));
      taskData.status = !currentTask?.status;
      taskData.companyId = workspace_id;
      taskData.updatedComments = commentData?.updatedComments;
      let tasksList = groupData?.tasks?.map((obj: TaskDetailsInterface) => {
        return obj.id === taskData.id ? taskData : obj;
      });
      taskData.status = !currentTask?.status;
      groupData.tasks = tasksList;
      dispatch(updateFilteredTasksList(groupData));
      const payloadTask = { status: !currentTask?.status, projectId: currentTask?.projectId };
      const result = await dispatch(updateTaskDetails(currentTask?.id, payloadTask));
      const commentResult = await dispatch(
        createNewComment(currentTask?.id, {
          Type: COMMENT_TYPE.STATUS_CHANGE,
          Status: !currentTask?.status
        })
      );
      if (result && commentResult) {
        trackAnalyticActivity(TASK_ANALYTICS.MARKED_DONE);
      }
    }
  }, [currentGroup, currentTask, dispatch, workspace_id]);

  //hadnle complete task confirmation
  const onClickNo = useCallback(async () => {
    setCancelLoading(true);
    try {
      onCompleteProjectTask();
    } catch (error) {
      console.log('error', error);
    } finally {
      setCancelLoading(false);
      setIsConfirmationModelOpen(false);
    }
  }, [onCompleteProjectTask]);

  //handle complete task confirmation
  const onClickConfirm = useCallback(async () => {
    setConfirmationLoading(true);
    try {
      if (currentTask?.subTasks && currentTask?.subTasks?.length > 0) {
        for (const subTask of currentTask.subTasks) {
          if (subTask?.status === false) {
            const taskDetailsClone = JSON.parse(JSON.stringify(subTask));
            taskDetailsClone.status = true;
            dispatch(updatSubTaskList(taskDetailsClone));
            const payloadTask = { status: true, projectId: subTask?.projectId };
            const result = dispatch(updateTaskDetails(subTask?.id, payloadTask));
            const commentResult = dispatch(
              createNewComment(subTask?.id, {
                Type: COMMENT_TYPE.STATUS_CHANGE,
                Status: true
              })
            );
            if (result && commentResult) {
              trackAnalyticActivity(TASK_ANALYTICS.MARKED_DONE);
            }
          }
        }
        onCompleteProjectTask();
      }
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setConfirmationLoading(false);
      setIsConfirmationModelOpen(false);
    }
  }, [currentTask?.subTasks, dispatch, onCompleteProjectTask]);

  //handle change status
  const onChangeStatus = useCallback(
    async (
      status: StatusListInterface,
      previousStatus: StatusListInterface,
      task: TaskDetailsInterface,
      group?: FilteredTasksListInterface
    ) => {
      const payloadTask = { statusId: status['_id'], projectId: task?.projectId };
      let taskData = JSON.parse(JSON.stringify(task));
      const commentData = onChangeComment(task);
      if (group) {
        let groupData = JSON.parse(JSON.stringify(group || {}));
        taskData.statusId = status['_id'];
        taskData.companyId = workspace_id;
        taskData.updatedComments = commentData?.updatedComments;
        let tasksList = groupData?.tasks?.map((obj: TaskDetailsInterface) => {
          return obj.id === taskData.id ? taskData : obj;
        });
        groupData.tasks = tasksList;
        dispatch(updateFilteredTasksList(groupData));
      } else {
        taskData.statusId = status['_id'];
        taskData.companyId = workspace_id;
        taskData.updatedComments = commentData?.updatedComments;
        let tasksList = otherTask?.tasks?.map((obj) => {
          return obj?.id === taskData?.id ? taskData : obj;
        });
        otherTask.tasks = tasksList;
        dispatch(setOtherTaskList(otherTask));
      }
      const result = await dispatch(updateTaskDetails(task?.id, payloadTask));
      if (result) {
        await dispatch(
          createNewComment(task?.id, {
            Type: COMMENT_TYPE.STATUS_ID_CHANGE,
            Group: status?.Name,
            PreviousGroup: previousStatus?.Name,
            Priority: status?.Type
          })
        );
        trackAnalyticActivity(TASK_ANALYTICS.STATUS_CHANGED);
      }
      if (task?.status) loadData();
    },
    [dispatch, loadData, otherTask, workspace_id]
  );

  //handle update status
  const onUpdateStatus = useCallback(
    async (
      status: StatusListInterface,
      previousStatus: StatusListInterface,
      task: TaskDetailsInterface,
      group?: FilteredTasksListInterface
    ) => {
      if (status['_id'] && status['_id'] !== previousStatus['_id']) {
        let allSubTasksStatus = true;
        if (task?.subTasks && task?.subTasks?.length > 0) {
          for (const subTask of task.subTasks) {
            if (subTask?.status === false) {
              allSubTasksStatus = false;
              break;
            }
          }
        }
        if (status.Type === STATUS_TYPE.COMPLETED && !allSubTasksStatus) {
          handleOpenConfirmationModal(false);
          setStatus(status);
          setPreviousStatus(previousStatus);
          setCurrentTask(task);
          setCurrentGroup(group);
          return;
        }
        onChangeStatus(status, previousStatus, task, group);
      }
    },
    [handleOpenConfirmationModal, onChangeStatus]
  );

  const processSubTask = useCallback(
    async (subTask: TaskDetailsInterface) => {
      if (subTask?.status === false && status) {
        const payloadTask = { statusId: status['_id'], projectId: currentTask?.projectId };
        const result = await dispatch(updateTaskDetails(subTask?.id, payloadTask));
        if (result) {
          await dispatch(
            createNewComment(subTask?.id, {
              Type: COMMENT_TYPE.STATUS_ID_CHANGE,
              Group: status?.Name,
              PreviousGroup: previousStatus?.Name,
              Priority: status?.Type
            })
          );
          const taskDetailsClone = JSON.parse(JSON.stringify(subTask));
          taskDetailsClone.statusId = status['_id'];
          dispatch(updatSubTaskList(taskDetailsClone));
          trackAnalyticActivity(TASK_ANALYTICS.STATUS_CHANGED);
        }
      }
    },
    [currentTask?.projectId, dispatch, previousStatus?.Name, status]
  );

  //handle complete task confirmation
  const onClickConfirmStatus = useCallback(async () => {
    setConfirmationLoading(true);
    try {
      if (status) {
        if (currentTask?.subTasks && currentTask?.subTasks?.length > 0 && status?.Type === STATUS_TYPE?.COMPLETED) {
          for (const subTask of currentTask.subTasks) {
            await processSubTask(subTask);
          }
        }
      }
      if (status && previousStatus && currentTask && currentGroup) {
        onChangeStatus(status, previousStatus, currentTask, currentGroup);
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setConfirmationLoading(false);
      closeConfirmationModal();
    }
  }, [closeConfirmationModal, currentGroup, currentTask, onChangeStatus, previousStatus, processSubTask, status]);

  // render completed task data
  const renderDisplayCompletedTaskData = useCallback(
    (item: FilteredTasksListInterface, comparedValue: string) => {
      return (
        selectedId === comparedValue &&
        showcomplatedtask &&
        item?.completedTasks &&
        item?.completedTasks?.length > 0 && (
          <>
            {item?.completedTasks?.map((task, taskIndex: number) => {
              if (item?.isCollapse) return <></>;
              return (
                <Draggable draggableId={task.id} index={taskIndex} key={task.id}>
                  {(provided: DraggableProvided) => (
                    <>
                      <MyCustomContextMenu
                        targetId={task?.id}
                        taskData={task}
                        loadData={loadData}
                        currentProject={currentProject}
                        setCurrentActiveTask={(taskId: string) => setCurrentActiveTask(taskId)}
                        currentActiveTask={currentActiveTask}
                        onClickView={() => onClickTaskDetails(task)}
                        onClickClone={() => onClickClone(task)}
                        onClickEdit={() => onClickEdit(task)}
                        statusList={currentProject?.statusData || []}
                        onClickDelete={() => OpenDeleteModal(task)}
                        onUpdateStatus={(status: StatusListInterface, previousStatus: StatusListInterface) =>
                          onUpdateStatus(status, previousStatus, task, item)
                        }>
                        <Blockcontent
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          id={task?.id}
                          key={task?.id}>
                          <Calendarviewcard
                            taskDetails={task}
                            isTaskGrid={true}
                            loadData={loadData}
                            onClickCurrentTaskCheckbox={(e) => onClickTaskCheckbox(e, task, item, false)}
                            onUpdateStatus={(status: StatusListInterface, previousStatus: StatusListInterface) =>
                              onUpdateStatus(status, previousStatus, task, item)
                            }
                            onClickDelete={() => OpenDeleteModal(task)}
                            onClickClone={() => onClickClone(task)}
                            onClickEdit={() => onClickEdit(task)}
                            onClickView={() => onClickTaskDetails(task)}
                            onUpdateMilestone={(milestone) => updateTaskMilestone(task, milestone)}
                          />
                        </Blockcontent>
                      </MyCustomContextMenu>
                    </>
                  )}
                </Draggable>
              );
            })}
          </>
        )
      );
    },
    [
      OpenDeleteModal,
      currentActiveTask,
      currentProject,
      loadData,
      onClickClone,
      onClickEdit,
      onClickTaskCheckbox,
      onClickTaskDetails,
      onUpdateStatus,
      selectedId,
      showcomplatedtask,
      updateTaskMilestone
    ]
  );

  // render completed task data
  const renderCompletedTaskData = useCallback(
    (item: FilteredTasksListInterface, comparedValue: string) => {
      return (
        item?.completedTasks &&
        item?.completedTasks?.length > 0 && (
          <ShowTask>
            {selectedId === comparedValue && showcomplatedtask ? (
              <Showhide onClick={() => togglecomplatedtask(false, '')}>Hide Completed Tasks</Showhide>
            ) : (
              <Showhide
                onClick={() =>
                  togglecomplatedtask(
                    true,
                    groupingStatus === SHOW_TASK_GROUPING_TYPE.GROUPING_BY_USER ||
                      groupingStatus === SHOW_TASK_GROUPING_TYPE.GROUPING_BY_PRIORITY
                      ? item?.name || ''
                      : item?.id || ''
                  )
                }>
                Show Completed Tasks
              </Showhide>
            )}
            {renderDisplayCompletedTaskData(item, comparedValue)}
          </ShowTask>
        )
      );
    },
    [groupingStatus, renderDisplayCompletedTaskData, selectedId, showcomplatedtask, togglecomplatedtask]
  );

  // get compared value
  const getComparedValue = useCallback(
    (item: FilteredTasksListInterface) => {
      let comparedValue;
      if (
        groupingStatus === SHOW_TASK_GROUPING_TYPE.GROUPING_BY_USER ||
        groupingStatus === SHOW_TASK_GROUPING_TYPE.GROUPING_BY_PRIORITY
      ) {
        comparedValue = item?.name;
      } else {
        comparedValue = item?.id || '';
      }
      return comparedValue;
    },
    [groupingStatus]
  );

  // render filter task list
  const renderFilterTaskList = useMemo(() => {
    return filteredTasksList?.map((item) => {
      let comparedValue = getComparedValue(item);
      return (
        <Droppable droppableId={item.id} key={item.id}>
          {(provided: DraggableProvided) => (
            <Boxmodel key={item?.id} ref={provided.innerRef} {...provided.droppableProps}>
              {!isEmpty(item?.name) && (
                <Header isHide={groupingStatus === SHOW_TASK_GROUPING_TYPE.NO_GROUPING}>
                  <Title>{item?.name}</Title>
                  <Collapsedopdown
                    groupDetail={item}
                    loadData={loadData}
                    setModalOpen={setModalOpen}
                    isWidth={true}
                    workspaceDetail={workspace}
                  />
                </Header>
              )}
              <Div>
                {item?.tasks?.map((task, taskIndex: number) => {
                  if (item?.isCollapse) return <></>;
                  return (
                    <Draggable draggableId={task.id} index={taskIndex} key={task.id}>
                      {(provided: DraggableProvided) => (
                        <>
                          <MyCustomContextMenu
                            targetId={task?.id}
                            taskData={task}
                            loadData={loadData}
                            currentProject={currentProject}
                            setCurrentActiveTask={(taskId: string) => setCurrentActiveTask(taskId)}
                            currentActiveTask={currentActiveTask}
                            onClickView={() => onClickTaskDetails(task)}
                            onClickClone={() => onClickClone(task)}
                            onClickEdit={() => onClickEdit(task)}
                            statusList={currentProject?.statusData || []}
                            onClickDelete={() => OpenDeleteModal(task)}
                            onUpdateStatus={(status: StatusListInterface, previousStatus: StatusListInterface) =>
                              onUpdateStatus(status, previousStatus, task, item)
                            }>
                            <Blockcontent
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              id={task?.id}
                              key={task?.id}>
                              <Calendarviewcard
                                taskDetails={task}
                                isTaskGrid={true}
                                loadData={loadData}
                                onClickCurrentTaskCheckbox={(e) => onClickTaskCheckbox(e, task, item, true)}
                                onUpdateStatus={(status: StatusListInterface, previousStatus: StatusListInterface) =>
                                  onUpdateStatus(status, previousStatus, task, item)
                                }
                                onClickDelete={() => OpenDeleteModal(task)}
                                onClickClone={() => onClickClone(task)}
                                onClickEdit={() => onClickEdit(task)}
                                onClickView={() => onClickTaskDetails(task)}
                                onUpdateMilestone={(milestone) => updateTaskMilestone(task, milestone)}
                                onRemoveMilestone={() => onRemoveMilestone(task)}
                              />
                            </Blockcontent>
                          </MyCustomContextMenu>
                        </>
                      )}
                    </Draggable>
                  );
                })}
                {provided?.placeholder}
                {renderCompletedTaskData(item, comparedValue)}
              </Div>
            </Boxmodel>
          )}
        </Droppable>
      );
    });
  }, [
    OpenDeleteModal,
    currentActiveTask,
    currentProject,
    filteredTasksList,
    getComparedValue,
    groupingStatus,
    loadData,
    onClickClone,
    onClickEdit,
    onClickTaskCheckbox,
    onClickTaskDetails,
    onRemoveMilestone,
    onUpdateStatus,
    renderCompletedTaskData,
    setModalOpen,
    updateTaskMilestone,
    workspace
  ]);

  // render other task data
  const renderOtherTask = useMemo(() => {
    return otherTask?.tasks?.map((item) => {
      return (
        <>
          <MyCustomContextMenu
            targetId={item?.id}
            taskData={item}
            loadData={loadData}
            currentProject={currentProject}
            setCurrentActiveTask={(taskId: string) => setCurrentActiveTask(taskId)}
            currentActiveTask={currentActiveTask}
            onClickView={() => onClickTaskDetails(item)}
            onClickClone={() => onClickClone(item)}
            onClickEdit={() => onClickEdit(item)}
            statusList={currentProject?.statusData || []}
            onClickDelete={() => OpenDeleteModal(item)}
            onUpdateStatus={(status: StatusListInterface, previousStatus: StatusListInterface) =>
              onUpdateStatus(status, previousStatus, item)
            }>
            <Blockcontent key={item?.id}>
              <Calendarviewcard
                taskDetails={item}
                isTaskGrid={true}
                loadData={loadData}
                onClickCurrentTaskCheckbox={(e) => onClickOtherTaskCheckbox(e, item, true)}
                onClickDelete={() => OpenDeleteModal(item)}
                onClickClone={() => onClickClone(item)}
                onClickEdit={() => onClickEdit(item)}
                onClickView={() => onClickTaskDetails(item)}
                onUpdateMilestone={(milestone) => updateTaskMilestone(item, milestone)}
              />
            </Blockcontent>
          </MyCustomContextMenu>
        </>
      );
    });
  }, [
    OpenDeleteModal,
    currentActiveTask,
    currentProject,
    loadData,
    onClickClone,
    onClickEdit,
    onClickOtherTaskCheckbox,
    onClickTaskDetails,
    onUpdateStatus,
    otherTask?.tasks,
    updateTaskMilestone
  ]);

  // render other completed task
  const renderOtherCompletedTask = useMemo(() => {
    return (
      otherTask?.completedTasks &&
      otherTask?.completedTasks?.length > 0 && (
        <ShowTask>
          {selectedId === 'otherTasks' && showcomplatedtask ? (
            <Showhide onClick={() => togglecomplatedtask(false, '')}>Hide Completed Tasks</Showhide>
          ) : (
            <Showhide onClick={() => togglecomplatedtask(true, 'otherTasks')}>Show Completed Tasks</Showhide>
          )}
          {selectedId === 'otherTasks' && showcomplatedtask && otherTask?.completedTasks?.length > 0 && (
            <>
              {otherTask?.completedTasks?.map((item) => {
                return (
                  <Blockcontent key={item?.id}>
                    <Calendarviewcard
                      taskDetails={item}
                      isTaskGrid={true}
                      loadData={loadData}
                      onClickCurrentTaskCheckbox={(e) => onClickOtherTaskCheckbox(e, item, false)}
                      onClickDelete={() => OpenDeleteModal(item)}
                      onClickClone={() => onClickClone(item)}
                      onClickEdit={() => onClickEdit(item)}
                      onClickView={() => onClickTaskDetails(item)}
                      onUpdateMilestone={(milestone) => updateTaskMilestone(item, milestone)}
                    />
                  </Blockcontent>
                );
              })}
            </>
          )}
        </ShowTask>
      )
    );
  }, [
    OpenDeleteModal,
    loadData,
    onClickClone,
    onClickEdit,
    onClickOtherTaskCheckbox,
    onClickTaskDetails,
    otherTask?.completedTasks,
    selectedId,
    showcomplatedtask,
    togglecomplatedtask,
    updateTaskMilestone
  ]);

  //render all task data
  const renderTaskData = useMemo(() => {
    if (
      filteredTasksList?.length > 0 ||
      otherTask?.tasks?.length > 0 ||
      (otherTask?.completedTasks && otherTask?.completedTasks?.length > 0)
    ) {
      return (
        <>
          {renderFilterTaskList}
          {(otherTask?.tasks?.length > 0 || (otherTask?.completedTasks && otherTask?.completedTasks?.length > 0)) && (
            <Boxmodel>
              <Header isHide={groupingStatus === SHOW_TASK_GROUPING_TYPE.NO_GROUPING}>
                <Title>Other Tasks</Title>
              </Header>
              <Div>
                {renderOtherTask}
                {renderOtherCompletedTask}
              </Div>
            </Boxmodel>
          )}
        </>
      );
    }
  }, [
    filteredTasksList?.length,
    otherTask?.tasks?.length,
    otherTask?.completedTasks,
    renderFilterTaskList,
    groupingStatus,
    renderOtherTask,
    renderOtherCompletedTask
  ]);

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Maincontent>
          {loading && filteredTasksList?.length === 0 && <Taskgridloading />}
          <Container>{renderTaskData}</Container>
          <ModalCustom open={isModalOpen} onClose={closeDeleteModal} width={334}>
            <Deletemodal
              onClose={closeDeleteModal}
              onOk={() => selectedItem && deleteTask(selectedItem)}
              loading={deleteLoading}
              modaltitle='Delete Task?'
              description='Are you sure you want to delete this task?'
            />
          </ModalCustom>
          <ModalCustom open={open} onClose={close} isCreateTask={true}>
            <CreateTaskModal loadData={loadData} onCancel={close} isFromShortcut={isOpenFromShortcut} />
          </ModalCustom>
          <ModalCustom open={isConfirmationModelOpen} onClose={closeConfirmationModal} width={334}>
            <ConfirmationModal
              loading={confirmationLoading}
              cancelLoading={cancelLoading}
              onOk={isCheckBox ? onClickConfirm : onClickConfirmStatus}
              onNo={onClickNo}
              onClose={closeConfirmationModal}
              modaltitle='Uncompleted Subtasks'
              description='Do you want to complete all these subtasks?'
            />
          </ModalCustom>
        </Maincontent>
      </DragDropContext>
    </>
  );
};

export default TaskGrid;
