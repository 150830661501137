import { TASK_PRIORITY } from '../global/constants';

export const menuItems = [
  {
    key: TASK_PRIORITY.NO_PRIORITY,
    label: 'No Priority',
    icon: 'Priority-No-priority-icon'
  },
  {
    key: TASK_PRIORITY.URGENT,
    label: 'Urgent',
    icon: 'Priority-Urgent-icon'
  },
  {
    key: TASK_PRIORITY.HIGH,
    label: 'High',
    icon: 'Priority-High-icon'
  },
  {
    key: TASK_PRIORITY.MEDIUM,
    label: 'Medium',
    icon: 'Priority-Medium-icon'
  },
  {
    key: TASK_PRIORITY.LOW,
    label: 'Low',
    icon: 'Priority-low-icon'
  }
];
