import UserPreferenceSingleton from '../helpers/userPreferenceSingleton';
import LocalDbService, { MY_TIME_LOG_NAME, UPDATE_ON_ID } from './localDbService';

let singleton: any;
let organizationId: any;

export default class MyTimeLogCacheService extends LocalDbService {
  constructor(props: any) {
    const db_name = `${props.organizationId}`;
    props.dbName = db_name;
    props.tableName = MY_TIME_LOG_NAME;
    super(props);
  }

  static getInstance() {
    const company = UserPreferenceSingleton.getInstance().getWorkspace();
    if (company) {
      if (!singleton || organizationId !== company?.id) {
        organizationId = company?.id;
        singleton = new MyTimeLogCacheService({ organizationId: company?.id });
      }
    }
    return singleton;
  }

  static removeInstance() {
    if (singleton) singleton.closeDb();
    organizationId = undefined;
    singleton = undefined;
  }

  async getMyTimeLogByWorkspace() {
    const rows = await this.getDb()
      .filter((x: any) => x)
      .sortBy('date');
    const result = rows?.map((x: any) => x.value);
    return result || [];
  }

  async setMyTimeLogData(value: any, companyId: string) {
    if (!value || companyId !== organizationId) return false;
    const result = await this.addBulk(value);
    return result;
  }

  async updateTimeLogList(data: any, archived: any, workspace_id: string) {
    if (workspace_id !== organizationId) return false;
    await this.syncList(data, archived);
  }

  async getLastUpdatedTimeMonthWise(workspace_id?: string) {
    const result = await super.getLastUpdatedTime(`MY_TIMELOG_MONTH_WISE_${workspace_id}`);
    return result;
  }

  async setLastUpdatedTimeMonthWise(value: any, companyId: string) {
    if (companyId !== organizationId) return false;
    const result = await super.setLastUpdatedTime(`MY_TIMELOG_MONTH_WISE_${companyId}`, value);
    return result;
  }

  async getLastUpdatedTime(workspace_id?: string) {
    const result = await super.getLastUpdatedTime(`${UPDATE_ON_ID.MY_TIME_LOG}_${workspace_id}`);
    return result;
  }

  async setLastUpdatedTime(value: any, companyId: string) {
    if (companyId !== organizationId) return false;
    const result = await super.setLastUpdatedTime(`${UPDATE_ON_ID.MY_TIME_LOG}_${companyId}`, value);
    return result;
  }
}
