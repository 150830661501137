import styled from 'styled-components';

const Main = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
  grid-template-rows: auto;
  grid-row-gap: 23px;
  grid-column-gap: 23px;
  @media (max-width: 449px) {
    grid-row-gap: 15px;
    grid-column-gap: 15px;
    grid-template-columns: repeat(auto-fill, minmax(128px, 1fr));
  }
  .loading-animation {
    overflow: hidden;
  }
  .loading-animation::before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    animation: loading 1s infinite;
    background: linear-gradient(to right, transparent, var(--background-tertiary), transparent);
  }

  @keyframes loading {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;

const First = styled.div`
  border-radius: 12px;
  border: 0.5px solid var(--border-primary);
  background: var(--background-primary);
  box-shadow: var(--shadow-card);
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 186px;
`;
const Top = styled.div`
  padding: 28px 0;
  display: flex;
  justify-content: center;
  .fill-color {
    fill: var(--background-muted);
  }
`;
const Line = styled.div`
  height: 1px;
  background-color: var(--background-muted);
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  gap: 8px;
`;
const FirstLine = styled.div`
  width: 70px;
  height: 12px;
  background-color: var(--background-muted);
  border-radius: 8px;
`;
const SecondLine = styled.div`
  background-color: var(--background-muted);
  width: 120px;
  height: 8px;
  border-radius: 8px;
`;
export { Main, First, Top, Bottom, Line, FirstLine, SecondLine };
