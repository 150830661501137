import React, { useCallback, useState } from 'react';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { Main, Top, Left, Right, Bottom, Label, Inputvalue, Formbutton, Form, Field, Text } from './styles';
import Button from '../../button';
import { getCurrentProjectDetails } from '../../../services/projectServices';
import { getWorkspaceDetails } from '../../../services/workspaceService';
import { useDispatch } from 'react-redux';
import { isEmail, isEmpty } from '../../../helpers/common';
import { inviteNewUser } from '../../../services/settingServices';
import { setErrorMessage } from '../../../actions/messageActions';
import { trackAnalyticActivity } from '../../../services/analyticsService';
import { captureException } from '../../../services/logService';
import { WORKSPACE_ANALYTICS } from '../../../global/analyticsConstants';

interface Props {
  onCancel: (value?: string) => void;
  project_id?: string;
  isTextVisible?: boolean;
  projectName?: string;
  workspace_id: string;
}

export default function Inviteteamsmodal(props: Props) {
  const { onCancel, project_id, projectName, isTextVisible, workspace_id } = props;
  //States
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  //Other variable
  const dispatch = useDispatch();

  const onClickInvite = useCallback(
    async (e: React.SyntheticEvent) => {
      e.preventDefault();
      if (isEmpty(email)) {
        dispatch(setErrorMessage('Please enter email!'));
        return;
      } else if (!isEmail(email)) {
        dispatch(setErrorMessage('Please enter valid email!'));
        return;
      }
      try {
        setLoading(true);
        const payload = {
          companyId: workspace_id,
          email: email,
          name: name,
          projectId: !isEmpty(project_id) ? project_id : undefined
        };
        const result = await dispatch(inviteNewUser(payload));
        if (result) {
          trackAnalyticActivity(WORKSPACE_ANALYTICS.USER_INVITED);
          onCancel(result?.redirectUri);
          setName('');
          setEmail('');
          await dispatch(getWorkspaceDetails(workspace_id));
          if (!isEmpty(project_id)) await dispatch(getCurrentProjectDetails(workspace_id, project_id));
        }
      } catch (e) {
        captureException(e);
        console.log('ERROR', e);
      } finally {
        setLoading(false);
      }
    },
    [email, dispatch, name, project_id, onCancel, workspace_id]
  );

  return (
    <div>
      <Main onSubmit={(e) => onClickInvite(e)}>
        <Top>
          <Left>Invite Member</Left>
          <Right onClick={() => onCancel()}>
            <SVGIcon name='close-icon' width='18' height='18' viewBox='0 0 18 18' className='close-icon' />
          </Right>
        </Top>
        <Bottom>
          <Form>
            <Text isTextVisible={!isTextVisible}>Invite member to {projectName} project.</Text>
            <Field>
              <Label>Name</Label>
              <Inputvalue
                type='text'
                placeholder='Enter member’s name'
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </Field>
            <Field>
              <Label>Email</Label>
              <Inputvalue
                type='text'
                placeholder='email@example.com'
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </Field>
          </Form>
          <Formbutton>
            <Button
              title='Cancel'
              secondary={true}
              type='button'
              onClick={onCancel}
              isCancelbtn={true}
              hasNotBoxshadow
            />
            <Button title='Invite' type='submit' isLoading={loading} disabled={loading} modelbtn={true} />
          </Formbutton>
        </Bottom>
      </Main>
    </div>
  );
}
