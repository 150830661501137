import styled from 'styled-components';

export const DropdownWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

export const Trigger = styled.div`
  cursor: pointer;
  display: inline-block;
`;

export const DropdownContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  gap: 12px;
  background: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  box-shadow: var(--shadow-modal);
  border-radius: 12px;
  position: absolute;
  top: 100%;
  right: 0;
`;
