import styled, { css } from 'styled-components';
import { Typography } from '../../global/theme';

interface Props {
  isBlackText?: boolean;
  isListheader?: boolean;
}

const Group = styled.div`
  width: 100%;
  max-width: 1022px;
  height: 100vh;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const Container = styled.div`
  background-color: var(--background-secondary);
`;

const Headerblock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 14px 0 24px;
  @media (max-width: 449px) {
    .icon-button {
      display: none;
    }
    margin: 0 -16px 16px;
    padding: 12px 16px;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: var(--background-primary);
    border-bottom: 0.5px solid var(--border-primary);
  }
`;

const PlusIcon = styled.div`
  display: none;
  @media (max-width: 449px) {
    display: flex;
    .circle {
      fill: var(--brand-primary);
      cursor: pointer;
    }
  }
`;
const Heading = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const Icon = styled.div`
  display: inline-flex;
  .close-icon {
    cursor: pointer;
    path {
      stroke: var(--text-primary);
    }
    :hover {
      background-color: var(--neutral-secondary);
      border-radius: 4px;
    }
  }
  .more-icon {
    cursor: pointer;
    fill: var(--text-secondary);
    stroke: var(--text-secondary);
    :hover {
      background-color: var(--background-muted);
      border-radius: 4px;
    }
  }
  .customer-icon {
    width: 28px;
    height: 28px;
    path {
      fill: var(--brand-primary);
    }
  }
  @media (max-width: 449px) {
    .customer-icon {
      width: 24px;
      height: 24px;
    }
  }
`;

const Title = styled.h1`
  ${Typography.heading_lg}
  color: var(--text-primary);
  margin: 0;
`;

const Content = styled.div`
  background-color: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 8px;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const Titlewrap = styled.h2`
  width: 100%;
  max-width: 510px;
  ${Typography.body_md_medium}
  text-transform: uppercase;
  color: var(--text-primary);
  margin: 0;
`;

const OptionMenu = styled.div<Props>`
  position: relative;
  @media (max-width: 449px) {
    position: sticky;
    right: 0;
    padding: 18px 12px;
    /* height: 58px; */
    background-color: var(--background-primary);
    display: flex;
    align-items: center;
    box-shadow: -1px 0px 8px 0px rgba(0, 0, 0, 0.04);

    ${(props: Props) =>
      props.isListheader &&
      css`
        height: 45px;
        padding: 0 12px;
      `}
  }
`;

const Tabledata = styled.div<Props>`
  display: flex;
  align-items: center;
  gap: 18px;
  padding: 12px 12px 11px;
  background-color: var(--background-primary);
  border-top: 0.5px solid var(--border-primary);
  .active {
    background-color: var(--background-muted);
    border-radius: 4px;
  }
  cursor: pointer;
  :hover {
    background-color: var(--neutral-secondary);
  }
  ${(props: Props) =>
    props.isListheader &&
    css`
      border-top: none;
      background-color: var(--background-tertiary);
      :hover {
        background-color: var(--background-tertiary);
      }
    `}
  @media (max-width: 449px) {
    width: max-content;
    padding: 0;
    ${(props: Props) =>
      props.isListheader &&
      css`
        /* gap: 12px; */
      `}
  }
`;

const Userdetails = styled.div`
  width: 100%;
  max-width: 535px;
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const Username = styled.h5<Props>`
  ${Typography.body_md_medium}
  color: var(--text-primary);
  margin: 0;
  ${(props: Props) =>
    props.isBlackText &&
    css`
      color: var(--text-primary);
    `}
`;

const Userdescription = styled.p`
  ${Typography.body_md}
  color: var(--text-secondary);
  margin: 0;
`;

const Usermail = styled.p<Props>`
  width: 100%;
  max-width: 262px;
  ${Typography.body_md_medium}
  color: var(--text-primary);
  margin: 0;
  min-width: 150px;
  margin-right: 12px;
  ${(props: Props) =>
    props.isListheader &&
    css`
      padding-left: 12px;
      margin-right: 0;
      border-left: 0.5px solid var(--border-primary);
    `}
  @media (max-width: 449px) {
    border-left: 1px solid transparent;
    padding-left: 12px;
    margin-right: 13px;
    min-width: 262px;
    ${(props: Props) =>
      props.isListheader &&
      css`
        padding-left: 12px;
        margin-right: 0;
        border-left: 0.5px solid var(--border-primary);
      `};
  }
`;

const Usernumber = styled.p<Props>`
  width: 100%;
  max-width: 106px;
  ${Typography.body_md_medium}
  color: var(--text-primary);
  margin: 0;
  white-space: nowrap;
  min-width: 100px;
  ${(props: Props) =>
    props.isListheader &&
    css`
      border-left: 0.5px solid var(--border-primary);
      padding-left: 12px;
    `}
`;

const Pagination = styled.div`
  position: sticky;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding: 40px 0;
  z-index: 999;
  .pagination {
    .ant-select-single {
      .ant-select-selector {
        border-radius: 24px;
        padding: 0 12px;
      }
    }

    .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover {
      .ant-select-selector {
        border-color: var(--border-primary);
      }
    }
    .ant-select-focused.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
        .ant-pagination-size-changer
      )
      .ant-select-selector {
      box-shadow: none;
      border-color: var(--border-primary);
    }
    .ant-pagination,
    .ant-pagination-item {
      border-radius: 8px;
      :hover {
        transition: all 0.2s;
        background-color: var(--neutral-secondary);
      }
    }
    .ant-pagination,
    .ant-pagination-prev:hover .ant-pagination-item-link {
      background-color: rgba(0, 0, 0, 0);
    }
  }
  .ant-pagination .ant-pagination-item-active a {
    color: var(--brand-primary);
  }
  .ant-pagination .ant-pagination-item-active {
    background-color: var(--background-tertiary);
    border: 1px solid var(--brand-primary);
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: var(--background-tertiary);
    border: 0.5px solid var(--border-primary);
  }
  .ant-select-single .ant-select-selector {
    color: var(--text-primary);
  }
  .ant-select .ant-select-arrow .anticon > svg {
    fill: var(--text-secondary);
    vertical-align: middle;
  }
  .anticon svg {
    fill: var(--text-secondary);
  }
  .ant-pagination .ant-pagination-item a {
    color: var(--text-secondary);
  }
`;

const Modalhead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 0.5px solid var(--border-primary);
`;

const Headline = styled.h6`
  ${Typography.body_lg_semibold}
  color: var(--text-primary);
  margin: 0;
`;

const Empty = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: calc(50% + 110px);
  transform: translate(-50%, -50%);
  @media (max-width: 449px) {
    left: 50%;
  }
`;

const Profileicon = styled.div`
  padding: 0 4px 0 3px;
  height: 21px;
  .customers-icon {
    path {
      fill: var(--text-primary);
    }
  }
`;
const Userprofile = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  width: 100%;
  min-width: 280px;
  @media (max-width: 449px) {
    padding: 8px 12px;
  }
`;
const Profile = styled.div`
  width: 28px;
  height: 28px;
  img {
    border-radius: 50%;
    object-fit: cover;
  }
`;

const ResponsiveNavbarIcon = styled.div`
  display: none;
  @media (max-width: 449px) {
    display: flex;
  }
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;
const Paginations = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
  background-color: var(--background-secondary);
  @media (max-width: 449px) {
    padding: 30px 0;
  }
  @media (max-width: 320px) {
    flex-direction: column;
  }
`;
const Leftdiv = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const Rightdiv = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
const Text = styled.div`
  ${Typography.body_md_medium}
  color: var(--text-secondary);
`;
const Arrowbtn = styled.div`
  width: 66px;
  background: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 20px;
  display: flex;
`;
const Left = styled.div`
  cursor: pointer;
  padding: 8px;
  border-right: 0.5px solid var(--border-primary);
  display: flex;
  .stroke-color {
    path {
      stroke: var(--text-secondary);
    }
  }
`;

const Right = styled.div`
  cursor: pointer;
  padding: 8px;
  display: flex;
  .stroke-color {
    transform: rotate(180deg);
    path {
      stroke: var(--text-secondary);
    }
  }
`;
const ShowRow = styled.div`
  border-radius: 24px;
  background-color: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  padding: 7px 7px 7px 11px;
  display: flex;
  gap: 18px;
  align-items: center;
  cursor: pointer;
`;
const Rowtext = styled.div`
  ${Typography.body_lg_medium}
  color: var(--text-primary);
`;
const Downarrow = styled.div`
  display: flex;
  .stroke-color {
    transform: rotate(270deg);
    path {
      stroke: var(--text-secondary);
    }
  }
  .arrow-open {
    transform: rotate(90deg);
  }
`;
export {
  Group,
  Container,
  Headerblock,
  Heading,
  Icon,
  Title,
  Content,
  Titlewrap,
  Tabledata,
  Userdetails,
  Username,
  Userdescription,
  Usermail,
  Usernumber,
  Pagination,
  Modalhead,
  Headline,
  Empty,
  Profileicon,
  Userprofile,
  Profile,
  OptionMenu,
  ResponsiveNavbarIcon,
  HeaderLeft,
  PlusIcon,
  Paginations,
  Rightdiv,
  Leftdiv,
  Text,
  Arrowbtn,
  Left,
  Right,
  ShowRow,
  Rowtext,
  Downarrow
};
