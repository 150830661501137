import { ActionCreatorWithOptionalPayload, ActionCreatorWithPayload, createAction, Dispatch } from '@reduxjs/toolkit';
import * as Actions from './types';
import { PropsNameWithValueInterface, ReducerInterface } from '../interfaces/ReducerInterface';
import { WorkspaceInterfase } from '../interfaces/WorkspaceInterface';
import { rootStore } from '../mobx/rootStore';

/**
 * @desc Set workspace loader
 */
export const setWorkspaceLoader: ActionCreatorWithPayload<boolean, string> = createAction(Actions.SET_WORKSPACE_LOADER);

/**
 * @desc Set workspace list
 */
export const setWorkspaceList: ActionCreatorWithPayload<WorkspaceInterfase[], string> = createAction(
  Actions.SET_WORKSPACE_LIST
);

/**
 * @desc Set current workspace
 */
export const setCurrentWorkspace: ActionCreatorWithPayload<any, string> = createAction(Actions.SET_CURRENT_WORKSPACE);

/**
 * @desc Set workspace detail
 */
export const setWorkspaceDetail: ActionCreatorWithPayload<WorkspaceInterfase | null, string> = createAction(
  Actions.SET_WORKSPACE_DETAIL
);

/**
 * @desc Set workspace detail
 */
export const updateWorkspaceDetail: ActionCreatorWithPayload<PropsNameWithValueInterface, string> = createAction(
  Actions.UPDATE_WORKSPACE_DETAIL
);

/**
 * @desc update workspace Input detail
 */
export const updateWorkspaceInputDetail: ActionCreatorWithPayload<ReducerInterface, string> = createAction(
  Actions.UPDATE_WORKSPACE_INPUT_DETAIL
);

/**
 * @desc Clear workspace Input detail
 */
export const clearWorkspaceInputDetail: ActionCreatorWithOptionalPayload<undefined, string> = createAction(
  Actions.CLEAR_WORKSPACE_INPUT_DETAIL
);

/**
 * @desc Add user item
 */
export const addUserItem: ActionCreatorWithPayload<any, string> = createAction(Actions.ADD_USER_ITEM);

/**
 * @desc Update user item
 */
export const updateUserItem: ActionCreatorWithPayload<{ item: any; index: number }, string> = createAction(
  Actions.UPDATE_USER_ITEM
);

/**
 * @desc Delete user item
 */
export const deleteUserItem: ActionCreatorWithPayload<{ index: number }, string> = createAction(
  Actions.DELETE_USER_ITEM
);

/**
 * @desc Clear workspace detail item
 */
export const clearWorkspaceDetailItem: ActionCreatorWithOptionalPayload<undefined, string> = createAction(
  Actions.CLEAR_WORKSPACE_DETAIL_ITEM
);

/**
 * @desc Set workspace usage details
 */
export const setWorkspaceUsage: ActionCreatorWithPayload<any, string> = createAction(Actions.SET_WORKSPACE_USAGE);

/**
 * @desc Set workspace total task
 */
export const setWorkspaceTotalTask: ActionCreatorWithPayload<number, string> = createAction(
  Actions.SET_WORKSPACE_TOTAL_TASKS
);

/**
 * @desc Clear Web Form Data
 */
export const clearOrganizationData = () => (dispatch: Dispatch) => {
  dispatch(setWorkspaceLoader(false));
  dispatch(setWorkspaceList([]));
  dispatch(setCurrentWorkspace(null));
  rootStore.workspaceStore.setCurrentWorkspace(null);
};
