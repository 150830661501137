import React, { RefObject, useCallback, useState } from 'react';
import {
  Bottom,
  Div,
  Expiryoption,
  Firstoption,
  Heading,
  Icon,
  Leftdiv,
  Leftoptiondiv,
  Modalbox,
  Modalheader,
  Name,
  Option,
  Options,
  Rightdiv,
  Switch,
  Title
} from './styles';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import Button from '../../button';
import { useDispatch, useSelector } from 'react-redux';
import copy from 'clipboard-copy';
import { setSuccessMessage } from '../../../actions/messageActions';
import { captureException } from '../../../services/logService';
import CalendarPopUp from '../../calendarPopUp';
import { updateDocumentData } from '../../../services/projectServices';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import { updateDocData } from '../../../actions/projectActions';
import { REACT_APP_BLOCKSUITE_URL } from '../../../global/environment';
import { Dropdown, DropdownItem } from '../../Dropdown';

interface Props {
  onClickCancel: () => void;
  editorIframe: RefObject<HTMLIFrameElement>;
  docTitle: string;
  docId: string;
  isEdge: boolean;
}
export default function SharedocModal(props: Props) {
  const { onClickCancel, isEdge } = props;
  //use selector state variables
  const projectSelector = useSelector((state: RootReducerInterface) => state.project);
  const { docData } = projectSelector;
  //Other variable
  const [isSwitchOn, setIsSwitchOn] = useState(docData?.isPublic);
  const dispatch = useDispatch();

  const handleSwitchChange = async () => {
    try {
      setIsSwitchOn(!isSwitchOn);
      const payload = {
        IsPublic: !isSwitchOn
      };
      await dispatch(updateDocumentData(docData?.id, payload));
      dispatch(updateDocData({ propsName: 'isPublic', value: !isSwitchOn }));
    } catch (error) {
      captureException(error);
      console.log('Failed to toggle switch: ', error);
    }
  };

  const exportToPdf = () => {
    props.editorIframe.current?.contentWindow?.postMessage(
      { type: 'export', payload: 'pdf' },
      `${REACT_APP_BLOCKSUITE_URL}`
    );
  };
  const exportToHtml = () => {
    props.editorIframe.current?.contentWindow?.postMessage(
      { type: 'export', payload: 'html' },
      `${REACT_APP_BLOCKSUITE_URL}`
    );
  };
  const exportToMarkdown = () => {
    props.editorIframe.current?.contentWindow?.postMessage(
      { type: 'export', payload: 'markdown' },
      `${REACT_APP_BLOCKSUITE_URL}`
    );
  };
  const exportToPng = () => {
    props.editorIframe.current?.contentWindow?.postMessage(
      { type: 'export', payload: 'png' },
      `${REACT_APP_BLOCKSUITE_URL}`
    );
  };

  const onClickPrivateCopy = useCallback(async () => {
    try {
      const url = window.location.href;
      await copy(url);
      dispatch(setSuccessMessage('Client invite url copied successfully!'));
    } catch (error) {
      captureException(error);
      console.log('Failed to copy url: ', error);
    }
  }, [dispatch]);

  const onClickPublicCopy = useCallback(async () => {
    try {
      const url = `https://share.teamcamp.app/docs/${props.docId}`;
      await copy(url);
      dispatch(setSuccessMessage('Client invite url copied successfully!'));
    } catch (error) {
      captureException(error);
      console.log('Failed to copy url: ', error);
    }
  }, [dispatch, props.docId]);

  return (
    <>
      <Modalbox>
        <Div>
          <Modalheader>
            <Leftdiv>
              <Title>Share this doc</Title>
              <Heading>Sharing “{props.docTitle}”</Heading>
            </Leftdiv>
            <Rightdiv onClick={onClickCancel}>
              <SVGIcon name='close-icon' width='18' height='18' viewBox='0 0 18 18' className='close-icon' />
            </Rightdiv>
          </Modalheader>
          <Bottom>
            <Firstoption className={isSwitchOn ? 'active' : ''}>
              <Options>
                <Leftoptiondiv>
                  <Icon>
                    <SVGIcon name='global-icon' width='18' height='18' viewBox='0 0 18 18' className='global-icon' />
                  </Icon>
                  <Name>Share link with anyone</Name>
                </Leftoptiondiv>
                <Switch>
                  <label className='switch'>
                    <input type='checkbox' onChange={handleSwitchChange} checked={isSwitchOn} />
                    <span className='slider round'></span>
                  </label>
                </Switch>
              </Options>
              {isSwitchOn && (
                <>
                  <Button title='Copy Public Link' modelbtn={true} className='btn' onClick={onClickPublicCopy} />
                  <Expiryoption>
                    <Name>Expire link</Name>
                    <CalendarPopUp isShareDocModel={true} />
                  </Expiryoption>
                  <Expiryoption style={{ display: 'none' }}>
                    <Name>Public duplication</Name>
                    <Switch>
                      <label className='switch'>
                        <input type='checkbox' />
                        <span className='slider round'></span>
                      </label>
                    </Switch>
                  </Expiryoption>
                </>
              )}
            </Firstoption>
            <Option>
              <Leftoptiondiv>
                <Icon>
                  <SVGIcon name='copy-link-icon' width='18' height='18' viewBox='0 0 18 18' className='copy-icon' />
                </Icon>
                <Name>Private link</Name>
              </Leftoptiondiv>
              <Button
                title='Copy link'
                secondary={true}
                size={'small'}
                smallbutton={true}
                onClick={onClickPrivateCopy}
              />
            </Option>
            {!isEdge && (
              <Option>
                <Leftoptiondiv>
                  <Icon>
                    <SVGIcon name='export-icon' width='16' height='16' viewBox='0 0 16 16' className='export-icon' />
                  </Icon>
                  <Name>Export doc</Name>
                </Leftoptiondiv>
                <Dropdown
                  content={<Button title='Export' secondary={true} size={'small'} smallbutton={true} />}
                  trigger='click'>
                  <DropdownItem key={1} label='PDF' onClick={exportToPdf} />
                  <DropdownItem key={2} label='HTML' onClick={exportToHtml} />
                  <DropdownItem key={3} label='Markdown' onClick={exportToMarkdown} />
                  <DropdownItem key={4} label='PNG' onClick={exportToPng} />
                </Dropdown>
              </Option>
            )}
          </Bottom>
        </Div>
      </Modalbox>
    </>
  );
}
