/* eslint-disable max-len */
import {
  Messagediv,
  Header,
  Selects,
  Text,
  Dropmenu,
  Droptag,
  Closeicon,
  Searchheader,
  Leftheader,
  Rightheader,
  Icons,
  Doticon,
  ResponsiveBackIcon,
  Newheader,
  AssignContent,
  Assigngroup,
  Plus,
  ItemDiv
} from './styles';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { ConfigProvider, Select, Tag } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import type { SelectProps } from 'antd';
import UserPreferenceSingleton from '../../../helpers/userPreferenceSingleton';
import Avatar from '../../avatar/Avatar';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isEmpty } from '../../../helpers/common';
import { CHAT_GROUP_TYPE } from '../../../global/constants';
import { getProjectFiles } from '../../../services/projectServices';
import { useHistory } from 'react-router-dom';
import {
  addMembersInMessageGroup,
  createMessageGroup,
  deleteGroupMessage,
  pinMessageGroup,
  removeMemberFromMessageGroup,
  unPinMessageGroup,
  updateMessageGroup
} from '../../../services/chatMessageService';
import {
  setChatGroups,
  setCurrentChatGroup,
  setCurrentChatGroupName,
  setPinChatGroups,
  setUnpinChatGroups,
  updateCurrentChatGroupName
} from '../../../actions/chatMessageActions';
import { setErrorMessage } from '../../../actions/messageActions';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import ModalCustom from '../../models/modal';
import Addgroupmembermodal from '../../models/addGroupMemberModel';
import Deletemodal from '../../models/deleteModel';
import EmptyState from '../../emptyState';
import darkemptymessage from '../../../assets/images/emptystates/darkemptymessage.svg';
import lightemptymessage from '../../../assets/images/emptystates/lightmessageempty.svg';
import ConfirmationModal from '../../models/confirmationModal';
import { useMobileDevice } from '../../../global/useMobile';
import MessageHeaderLoading from '../../loading/messageHeaderLoading';
import { Messagesfeed } from '../messagefeed';
import ChatBoxAvatar from '../../avatar/chatboxAvatar/ChatBoxAvatar';
import { Dropdown, DropdownDivider, DropdownItem } from '../../Dropdown';
import { ChatGroupInterface } from '../../../interfaces/chatMessageInterface';

const { Option } = Select;
type TagRender = SelectProps['tagRender'];

interface Props {
  newGroup: boolean;
  loadData: () => void;
  onCloseNewGroup: () => void;
  loading: boolean;
  onClickNewChat: () => void;
  attachment?: boolean;
  setAttachment?: (value: boolean) => void;
}

export default function Messagedetail({
  newGroup,
  loadData,
  onCloseNewGroup,
  loading,
  onClickNewChat,
  attachment,
  setAttachment
}: Props) {
  // Refs
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const memberIconRef = useRef<HTMLDivElement | null>(null);
  // States
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [isMemberModalOpen, setIsMemberModalOpen] = useState(false);
  const [isEditGroupOpen, setIsEditGroupOpen] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [alertModalOpen, setAlertModalOpen] = useState(false);
  const [removeUserId, setRemoveUserId] = useState('');
  // const [path, setpath] = useState<ProjectFilesInterface[]>([]);
  const [clearMessageModalOpen, setClearMessageModalOpen] = useState(false);
  const [clearMessageLoading, setClearMessageLoading] = useState(false);
  const [pageScrolled, setPageScrolled] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [selectFile, setSelectFile] = useState<boolean>(false);
  const [showMember, setShowMember] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const {
    settings: settingsSelector,
    project: projectSelector,
    chatMessage: chatMessageSelector,
    workspace: workspaceSelector,
    auth: authSelector
  } = stateSelector || {};
  const { currentChatGroup, chatGroups, inputChatGroup, pinChatGroups, unpinChatGroups } = chatMessageSelector || {};
  const { messages } = currentChatGroup || {};
  const { themeMode } = settingsSelector;
  const { projectAllFiles } = projectSelector;
  const { workspace: workspaceDetails } = workspaceSelector;
  const { userDetails } = authSelector;
  // Other Variables
  const getImageSource = () => (themeMode?.theme === 'dark' ? darkemptymessage : lightemptymessage);
  const dispatch = useDispatch();
  const history = useHistory();
  const mobile = useMobileDevice();
  const baseStyle: React.CSSProperties = {
    boxShadow: 'none',
    borderStyle: 'none solid solid solid',
    borderRadius: '0 0 8px 8px',
    position: 'absolute',
    zIndex: 99,
    padding: 8
  };
  const responsiveStyles: React.CSSProperties =
    windowWidth < 449
      ? {
          maxHeight: 192
        }
      : {
          maxHeight: 205
        };
  const dropdownStyle: React.CSSProperties = {
    ...baseStyle,
    border: '0.5px solid var(--border-primary)',
    backgroundColor: ' var(--background-primary)',
    ...responsiveStyles
  };

  const scrollToBottom = () => {
    setPageScrolled(true);
    messagesEndRef.current?.scrollIntoView({
      inline: 'nearest',
      block: 'nearest'
    });
  };

  useEffect(() => {
    if (!pageScrolled && messages?.length > 0) scrollToBottom();
  }, [messages, pageScrolled]);

  useEffect(() => {
    setPageScrolled(false);
  }, [currentChatGroup?.id]);

  const tagRender: TagRender = (props: any) => {
    const { closable, onClose, label } = props;
    const onPreventMouseDown = (event: React.SyntheticEvent) => {
      event.preventDefault();
      event.stopPropagation();
    };
    const name = label?.props?.children?.[1]?.props?.children || '';
    const profileImage = label?.props?.children?.[0]?.props?.imgSrc || '';
    const handleClose = (event: React.SyntheticEvent) => {
      event.preventDefault();
      event.stopPropagation();
      onClose(label.props.value);
    };
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ padding: 3, borderRadius: 20, display: 'flex', alignItems: 'center', marginRight: 0 }}>
        <Droptag>
          <Avatar imgSrc={profileImage} name={name} size={20} isNotBorder={true} />
          <p>{name}</p>
          <Closeicon onClick={(e) => handleClose(e)}>
            <SVGIcon name='close-icon' width='20' height='20' viewBox='0 0 18 18' className='fillColor' />
          </Closeicon>
        </Droptag>
      </Tag>
    );
  };

  const openClearMessageConfiramation = useCallback(() => {
    setClearMessageModalOpen(true);
  }, []);

  const onCloseClearMessageConfirmation = useCallback(() => {
    setClearMessageModalOpen(false);
  }, []);

  const onDeleteGroupMessage = useCallback(async () => {
    setClearMessageLoading(true);
    try {
      const result = await dispatch(deleteGroupMessage(currentChatGroup?.type, currentChatGroup?.id));
      if (result) {
        onCloseClearMessageConfirmation();
        loadData();
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setClearMessageLoading(false);
    }
  }, [currentChatGroup?.id, currentChatGroup?.type, dispatch, loadData, onCloseClearMessageConfirmation]);

  const onClickExistingGroup = useCallback(
    (groupId: string) => {
      const currentGroup = chatGroups?.find((x) => x?.id === groupId);
      if (currentGroup) {
        if (currentGroup?.type === CHAT_GROUP_TYPE.PROJECT) {
          dispatch(getProjectFiles(currentGroup?.id));
        }
        dispatch(setCurrentChatGroup(currentGroup));
        setSelectedUsers([]);
        onCloseNewGroup();
        if (mobile) history.push(`/messages/details?chatGroup=${currentGroup?.id}`);
        else history.push(`/messages?chatGroup=${currentGroup?.id}`);
        UserPreferenceSingleton.getInstance().setLastMessageGroup(currentGroup?.id);
      }
    },
    [chatGroups, dispatch, history, mobile, onCloseNewGroup]
  );

  const handleSelectChangeUsers = useCallback(
    (selectedUserIds: string[], ref: any) => {
      if (ref[ref?.length - 1]?.key?.startsWith('GROUP')) {
        onClickExistingGroup(selectedUserIds[selectedUserIds?.length - 1]);
      } else setSelectedUsers(selectedUserIds);
    },
    [onClickExistingGroup]
  );

  const checkExistingGroup = useCallback(() => {
    return chatGroups?.find((group) => {
      if (group?.users?.length === selectedUsers?.length + 1 && group?.type === CHAT_GROUP_TYPE.PERSONAL) {
        const users = group?.users?.map((x) => x?.id);
        const notExist = selectedUsers.some((id) => !users.includes(id));
        return !notExist;
      }
      return false;
    });
  }, [chatGroups, selectedUsers]);

  const handleExistingGroup = useCallback(
    (existingGroup: ChatGroupInterface) => {
      setSelectedUsers([]);
      onCloseNewGroup();
      if (existingGroup?.type === CHAT_GROUP_TYPE.PROJECT) {
        dispatch(getProjectFiles(existingGroup?.id));
      }
      dispatch(setCurrentChatGroup(existingGroup));
      UserPreferenceSingleton.getInstance().setLastMessageGroup(existingGroup?.id);
      const path = mobile
        ? `/messages/details?chatGroup=${existingGroup?.id}`
        : `/messages?chatGroup=${existingGroup?.id}`;
      history.push(path);
    },
    [dispatch, history, mobile, onCloseNewGroup]
  );

  const createNewGroup = useCallback(async () => {
    const payload = {
      userId: userDetails?.id,
      name: '',
      companyId: workspaceDetails?.id,
      users: selectedUsers
    };
    const result = await dispatch(createMessageGroup(payload));
    if (result) {
      setSelectedUsers([]);
      onCloseNewGroup();
      dispatch(setCurrentChatGroup(result));
      UserPreferenceSingleton.getInstance().setLastMessageGroup(result?.id);
      const path = mobile ? `/messages/details?chatGroup=${result?.id}` : `/messages?chatGroup=${result?.id}`;
      history.push(path);
      loadData();
    }
  }, [dispatch, history, loadData, mobile, onCloseNewGroup, selectedUsers, userDetails?.id, workspaceDetails?.id]);

  const onCreateGroup = useCallback(async () => {
    if (!selectedUsers?.length) return;
    const existingGroup = checkExistingGroup();
    if (existingGroup && !isEmpty(existingGroup?.id)) {
      handleExistingGroup(existingGroup);
    } else {
      await createNewGroup();
    }
  }, [selectedUsers?.length, checkExistingGroup, handleExistingGroup, createNewGroup]);

  const onOpenAddMemberModal = () => {
    setIsMemberModalOpen(true);
    if (mobile) setShowMember(false);
  };
  const onCloseAddMemberModal = () => {
    setIsMemberModalOpen(false);
  };

  const onOpenEditGroupModal = () => {
    dispatch(setCurrentChatGroupName(currentChatGroup));
    setIsEditGroupOpen(true);
  };

  const onCloseEditGroupModal = () => {
    setIsEditGroupOpen(false);
  };

  const updateChatGroups = useCallback(
    (groupId: string, updates: { pinned: boolean; order: number | undefined }) => {
      return chatGroups?.map((group) => {
        if (group.id === groupId) {
          return {
            ...group,
            ...updates
          };
        }
        return group;
      });
    },
    [chatGroups]
  );

  const pinChatGroup = useCallback(async () => {
    const updatedCurrentGroup = {
      ...(currentChatGroup || {}),
      pinned: true,
      order: new Date().getTime()
    };
    const response = await dispatch(pinMessageGroup(currentChatGroup?.id));
    if (response) {
      dispatch(setPinChatGroups([...(pinChatGroups || []), updatedCurrentGroup]));
      const updatedChatGroups = updateChatGroups(currentChatGroup?.id, {
        pinned: true,
        order: updatedCurrentGroup.order
      });
      dispatch(setChatGroups(updatedChatGroups));
      dispatch(setCurrentChatGroup(updatedCurrentGroup));
      dispatch(setUnpinChatGroups(unpinChatGroups?.filter((group) => group.id !== currentChatGroup.id)));
    }
  }, [currentChatGroup, dispatch, pinChatGroups, unpinChatGroups, updateChatGroups]);

  const unPinChatGroup = useCallback(async () => {
    const updatedCurrentGroup = {
      ...(currentChatGroup || {}),
      pinned: false,
      order: undefined
    };
    const response = await dispatch(unPinMessageGroup(currentChatGroup?.id));
    if (response) {
      const updatedChatGroups = updateChatGroups(currentChatGroup?.id, {
        pinned: false,
        order: undefined
      });
      const unpinnedGroups = updatedChatGroups?.filter((group) => group?.order === undefined || group?.order === null);
      dispatch(setUnpinChatGroups(unpinnedGroups));
      dispatch(setPinChatGroups(pinChatGroups?.filter((group) => group.id !== currentChatGroup.id)));
      dispatch(setChatGroups(updatedChatGroups));
      dispatch(setCurrentChatGroup(updatedCurrentGroup));
    }
  }, [currentChatGroup, dispatch, pinChatGroups, updateChatGroups]);

  const handlePinChatGroup = useCallback(async () => {
    if (currentChatGroup?.pinned) {
      unPinChatGroup();
    } else {
      pinChatGroup();
    }
  }, [currentChatGroup?.pinned, pinChatGroup, unPinChatGroup]);

  const onChangeGroupName = useCallback(
    (propsName: string, value: string) => {
      dispatch(updateCurrentChatGroupName({ propsName, value }));
    },
    [dispatch]
  );

  const onClickSave = useCallback(
    async (e: React.SyntheticEvent) => {
      e.preventDefault();
      try {
        setIsLoading(true);
        const payload = {
          name: inputChatGroup?.name
        };
        const result = await dispatch(updateMessageGroup(currentChatGroup?.id, payload));
        if (result) {
          setIsEditGroupOpen(false);
          loadData();
        }
      } catch (e) {
        console.log('error', e);
      } finally {
        setIsLoading(false);
      }
    },
    [currentChatGroup?.id, dispatch, inputChatGroup?.name, loadData]
  );

  const onAddMembers = useCallback(
    async (e: React.SyntheticEvent, usersList: string[]) => {
      try {
        e.preventDefault();
        setSubmitLoading(true);
        if (usersList?.length > 0) {
          const payload = {
            users: usersList
          };
          const result = await dispatch(addMembersInMessageGroup(currentChatGroup?.id, payload));
          if (result) {
            onCloseAddMemberModal();
            loadData();
          }
        } else {
          dispatch(setErrorMessage('Please select user'));
        }
      } catch (error) {
        console.log('error', error);
        setSubmitLoading(false);
      } finally {
        setSubmitLoading(false);
      }
    },
    [currentChatGroup?.id, dispatch, loadData]
  );

  const openAlertModal = useCallback((userId: string) => {
    setRemoveUserId(userId);
    setAlertModalOpen(true);
  }, []);

  const closeAlertModal = useCallback(() => {
    setRemoveUserId('');
    setAlertModalOpen(false);
  }, []);

  const onClickRemoveUser = useCallback(async () => {
    try {
      setSubmitLoading(true);
      const currentUser = UserPreferenceSingleton.getInstance().getCurrentUser();
      const result = await dispatch(removeMemberFromMessageGroup(currentChatGroup?.id, currentUser?.id, removeUserId));
      if (result) {
        closeAlertModal();
        if (currentUser?.id === removeUserId) {
          loadData();
          history.push('/messages');
        } else loadData();
      }
    } catch (error) {
      console.log('error', error);
      setSubmitLoading(false);
    } finally {
      setSubmitLoading(false);
    }
  }, [closeAlertModal, currentChatGroup?.id, dispatch, history, loadData, removeUserId]);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleMenuItemClick = useCallback(
    (key: number | string) => {
      if (key === 1) {
        handlePinChatGroup();
      } else if (key === 2) {
        openClearMessageConfiramation();
      }
    },
    [handlePinChatGroup, openClearMessageConfiramation]
  );

  // function to handle mouse enter and leave on member hover
  const handleMouseEnter = () => {
    if (!mobile) setShowMember(true);
  };

  const handleShowMemberDropdown = (value: boolean) => {
    setShowMember(value);
  };

  const dropdownItems = useMemo(() => {
    const MenuItems = [
      { key: 1, label: currentChatGroup?.pinned ? 'Unpin chat' : 'Pin chat' },
      { key: 2, label: 'Clear chat' }
    ];
    const chatMenuItem = [{ key: 1, label: currentChatGroup?.pinned ? 'Unpin chat' : 'Pin chat' }];
    const items = workspaceDetails?.isAdmin || workspaceDetails?.isOwner ? MenuItems : chatMenuItem;

    return items.map((item) => (
      <DropdownItem key={item.key} label={item.label} onClick={() => handleMenuItemClick(item.key)} />
    ));
  }, [currentChatGroup?.pinned, workspaceDetails?.isAdmin, workspaceDetails?.isOwner, handleMenuItemClick]);

  return (
    <>
      <Messagediv>
        {newGroup && (
          <>
            <Newheader>
              <ResponsiveBackIcon onClick={() => history.goBack()}>
                <SVGIcon
                  name='report-left-arrow-icon'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  className='left-arrow-icon'
                />
              </ResponsiveBackIcon>
              <p>New Chat</p>
            </Newheader>
            <Searchheader className='searchbar' tagsSelected={selectedUsers?.length > 0}>
              <Text>To:</Text>
              <Selects>
                <ConfigProvider
                  theme={{
                    token: {
                      colorText: 'var(--text-primary)',
                      colorPrimaryBg: 'none',
                      controlItemBgHover: 'var(--neutral-secondary)',
                      colorTextDisabled: 'var(--text-primary)'
                    }
                  }}>
                  <Select
                    mode='multiple'
                    autoFocus={newGroup}
                    placement='bottomLeft'
                    showSearch
                    style={{ width: '100%' }}
                    popupClassName='selectpopup'
                    listHeight={175}
                    placeholder='Enter name or Project name'
                    dropdownStyle={dropdownStyle}
                    menuItemSelectedIcon=''
                    value={selectedUsers}
                    onChange={handleSelectChangeUsers}
                    filterOption={(input, option: any) =>
                      option?.children?.props?.children[1]?.props?.children
                        ?.toLowerCase()
                        ?.indexOf(input.toLowerCase()) >= 0
                    }
                    tagRender={(e) => tagRender(e)}
                    onBlur={onCreateGroup}>
                    {workspaceDetails?.users?.length > 0 &&
                      workspaceDetails?.users?.map((user) =>
                        user?.id !== userDetails?.id ? (
                          <Option key={user?.id} value={user?.id}>
                            <Dropmenu>
                              <Avatar
                                imgSrc={user?.profile_photo}
                                name={user?.name ? user?.name : 'U N'}
                                size={28}
                                isNotBorder={true}
                              />
                              <p>{user?.name}</p>
                            </Dropmenu>
                          </Option>
                        ) : (
                          <></>
                        )
                      )}
                    {chatGroups?.length > 0 &&
                      chatGroups?.map((group) =>
                        group?.users?.length !== 2 ? (
                          <Option key={`GROUP_${group?.id}`} value={group?.id}>
                            <Dropmenu>
                              {group?.type === CHAT_GROUP_TYPE.PROJECT ? (
                                <SVGIcon
                                  name='teamcamp-mobile-icon'
                                  width='28'
                                  height='28'
                                  viewBox='0 0 30 30'
                                  className='teamcampicon'
                                />
                              ) : (
                                <ChatBoxAvatar
                                  imgSrc={group?.profilePic || ''}
                                  name={group?.name || ''}
                                  size={28}
                                  users={group?.users}
                                />
                              )}
                              <p>{group?.name}</p>
                            </Dropmenu>
                          </Option>
                        ) : (
                          <></>
                        )
                      )}
                  </Select>
                </ConfigProvider>
              </Selects>
            </Searchheader>
          </>
        )}
        {!newGroup && !isEmpty(currentChatGroup) && (
          <>
            {loading && (!messages || messages?.length === 0) ? (
              <MessageHeaderLoading />
            ) : selectFile ? (
              <></>
            ) : (
              <Header className={currentChatGroup?.type === CHAT_GROUP_TYPE.PROJECT ? 'project' : ''}>
                <Leftheader>
                  <ResponsiveBackIcon onClick={() => history.goBack()}>
                    <SVGIcon
                      name='report-left-arrow-icon'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      className='left-arrow-icon'
                    />
                  </ResponsiveBackIcon>
                  {currentChatGroup?.type === CHAT_GROUP_TYPE.PROJECT && (
                    <SVGIcon
                      name='teamcamp-mobile-icon'
                      width='30'
                      height='30'
                      viewBox='0 0 30 30'
                      className='teamcampicon'
                    />
                  )}
                  {currentChatGroup?.users && currentChatGroup?.type !== CHAT_GROUP_TYPE.PROJECT && (
                    <ChatBoxAvatar
                      imgSrc={currentChatGroup?.profilePic || ''}
                      name={currentChatGroup?.name}
                      size={30}
                      users={currentChatGroup?.users}
                    />
                  )}
                  <p>{currentChatGroup?.name}</p>
                </Leftheader>
                <Icons>
                  {currentChatGroup?.type === CHAT_GROUP_TYPE.PROJECT && (
                    <>
                      <Rightheader onClick={() => history.push(`/projects/details/${currentChatGroup?.id}/messages`)}>
                        <SVGIcon
                          name='expand-chat-icon'
                          width='18'
                          height='18'
                          viewBox='0 0 18 18'
                          className='plusicon'
                        />
                      </Rightheader>
                      <Dropdown
                        content={
                          <Doticon>
                            <SVGIcon
                              className='doticon'
                              name='three-dots-horizontal-icon'
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                            />
                          </Doticon>
                        }
                        trigger='click'
                        isMinWidth={128}>
                        {dropdownItems}
                      </Dropdown>
                    </>
                  )}
                  {currentChatGroup?.type !== CHAT_GROUP_TYPE.PROJECT && (
                    <React.Fragment>
                      {currentChatGroup?.users && currentChatGroup?.users?.length > 1 && (
                        <Dropdown
                          isMinWidth={186}
                          content={
                            <AssignContent
                              ref={memberIconRef}
                              onClick={() => handleShowMemberDropdown(!showMember)}
                              onMouseEnter={handleMouseEnter}>
                              <Assigngroup>
                                <ul>
                                  {currentChatGroup?.users?.map((item, index) => {
                                    if (index > (mobile ? 2 : 4)) return null;
                                    return (
                                      <li key={item?.id}>
                                        <Avatar
                                          imgSrc={item?.profile_photo || ''}
                                          name={item?.name ? item.name : 'U N'}
                                          size={20}
                                          isMilestoneBorder
                                        />
                                      </li>
                                    );
                                  })}
                                  {currentChatGroup?.users?.length > (mobile ? 3 : 5) && (
                                    <li className='plus-icon'>
                                      <Plus>+{currentChatGroup?.users?.length - (mobile ? 3 : 5)}</Plus>
                                    </li>
                                  )}
                                </ul>
                              </Assigngroup>
                            </AssignContent>
                          }
                          trigger='hover'>
                          <>
                            <ItemDiv>
                              {currentChatGroup?.users.map((item) => {
                                const isShowIcon =
                                  item?.id !== userDetails?.id &&
                                  currentChatGroup?.users &&
                                  currentChatGroup?.users.length > 2 &&
                                  (currentChatGroup?.createdBy === userDetails?.id ||
                                    workspaceDetails?.isAdmin ||
                                    workspaceDetails?.isOwner);
                                return (
                                  <DropdownItem
                                    key={item?.id}
                                    label={item.name}
                                    isAvatar
                                    avtarSrc={item.profile_photo}
                                    isSuffixCross={isShowIcon}
                                    onSuffixClick={() => openAlertModal(item?.id)}
                                  />
                                );
                              })}
                            </ItemDiv>
                            <DropdownDivider />
                            <DropdownItem
                              label='Add Member'
                              iconName='member-plus-icon'
                              iconSize={20}
                              onClick={onOpenAddMemberModal}
                            />
                          </>
                        </Dropdown>
                      )}

                      <Dropdown
                        content={
                          <Doticon>
                            <SVGIcon
                              className='doticon'
                              name='three-dots-horizontal-icon'
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                            />
                          </Doticon>
                        }
                        isMinWidth={128}>
                        <DropdownItem
                          label={currentChatGroup?.pinned ? 'Unpin chat' : 'Pin chat'}
                          onClick={handlePinChatGroup}
                        />
                        {currentChatGroup?.users && currentChatGroup?.users?.length > 2 && (
                          <DropdownItem label='Edit Group name' onClick={onOpenEditGroupModal} />
                        )}
                        {((currentChatGroup?.users && currentChatGroup?.users?.length <= 2) ||
                          workspaceDetails?.isAdmin ||
                          workspaceDetails?.isOwner ||
                          currentChatGroup?.createdBy === userDetails?.id) && (
                          <DropdownItem label='Clear chat' onClick={openClearMessageConfiramation} />
                        )}
                        {currentChatGroup?.users && currentChatGroup?.users?.length > 2 && (
                          <DropdownItem
                            label='Leave'
                            onClick={() => openAlertModal(userDetails?.id || '')}
                            tone='critical'
                          />
                        )}
                      </Dropdown>
                    </React.Fragment>
                  )}
                </Icons>
              </Header>
            )}
          </>
        )}
        {chatGroups?.length === 0 && isEmpty(currentChatGroup) && !loading && (
          <EmptyState
            header='No messages yet'
            title='You have no messages at the moment'
            name='New message'
            image={getImageSource()}
            isMessageEmpty={true}
            onButtonClick={onClickNewChat}
            ismaxwidth={true}
          />
        )}
        {!isEmpty(currentChatGroup) && (
          <Messagesfeed
            loading={loading}
            messages={messages}
            loadData={loadData}
            currentProject={currentChatGroup}
            projectAllFiles={projectAllFiles}
            isNavbarMessage={true}
            isMessageLoading
            setSelectFile={(value: boolean) => setSelectFile(value)}
            selectFile={selectFile}
            isMessage
            newGroup={newGroup}
            attachment={attachment}
            setAttachment={(value) => setAttachment && setAttachment(value)}
          />
        )}
      </Messagediv>
      <ModalCustom width={400} open={isMemberModalOpen} onClose={onCloseAddMemberModal}>
        <Addgroupmembermodal
          onClose={onCloseAddMemberModal}
          heading={'Add member'}
          label={'Member Name'}
          usersList={workspaceDetails?.users}
          groupUserList={currentChatGroup?.users}
          onAddMembers={(e, users) => onAddMembers(e, users)}
          loading={submitLoading}
        />
      </ModalCustom>
      <ModalCustom width={400} open={isEditGroupOpen} onClose={onCloseEditGroupModal}>
        <Addgroupmembermodal
          onClose={onCloseEditGroupModal}
          heading={'Edit Group name'}
          label={'Group Name'}
          iseditgroupmodal={true}
          loading={isLoading}
          onChange={(propsName: string, value: string) => onChangeGroupName(propsName, value)}
          onEditGroupName={(e) => onClickSave(e)}
        />
      </ModalCustom>
      <ModalCustom open={alertModalOpen} onClose={closeAlertModal} width={334}>
        <Deletemodal
          isYes={true}
          onClose={closeAlertModal}
          onOk={onClickRemoveUser}
          modaltitle={userDetails?.id === removeUserId ? 'Leave Group?' : 'Remove Member?'}
          description={
            userDetails?.id === removeUserId
              ? 'Are you sure you want to leave this Group?'
              : 'Are you sure you want to remove this Member?'
          }
          loading={submitLoading}
        />
      </ModalCustom>
      <ModalCustom open={clearMessageModalOpen} onClose={closeAlertModal} width={334}>
        <ConfirmationModal
          loading={clearMessageLoading}
          onOk={onDeleteGroupMessage}
          onClose={onCloseClearMessageConfirmation}
          modaltitle='Delete group message'
          description='Are you sure you want to clear group message?'
        />
      </ModalCustom>
    </>
  );
}
