import styled from 'styled-components';
import { Typography } from '../../../global/theme';

const DropHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 6px 14px 6px 10px;
  position: relative;
  @media (max-width: 449px) {
    background-color: var(--background-secondary);
    margin: 4px 10px;
    border: 0.5px solid var(--neutral-primary);
    border-radius: 8px;
    padding-left: 38px;
  }
`;
const InputArea = styled.input`
  max-width: 100%;
  width: 116px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  ${Typography.body_md}
  color: var(--text-primary);
  background-color: var(--background-primary);
  margin: 0;
  padding: 0;
  border: none;

  :focus {
    outline: none;
  }
  .placeholder {
    color: var(--text-secondary);
  }
  @media (max-width: 449px) {
    max-width: 297px;
    width: 100%;
    background-color: var(--background-secondary);
  }
`;
export { DropHeader, InputArea };
