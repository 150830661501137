import { useHistory } from 'react-router-dom';
import AppLayout from '../../../../../component/appLayout';
import { getUrlParam, isEmpty } from '../../../../../helpers/common';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ROUTES } from '../../../../../routes/urls';
import { RootReducerInterface } from '../../../../../interfaces/RootReducerInterface';
import { githubAccountConnect } from '../../../../../services/githubServices';

function GithubCallback() {
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { workspace: workspaceSelector } = stateSelector;
  const { workspace } = workspaceSelector;
  // Other variables
  const history = useHistory();
  const dispatch = useDispatch();
  const installationId = getUrlParam(history.location.search, 'installation_id');

  const loadData = useCallback(async () => {
    if (!isEmpty(workspace?.id)) {
      // call service to add installation id
      if (installationId) {
        const response = await dispatch(githubAccountConnect(installationId));
        if (response) {
          history.push(ROUTES.INTEGRATIONS_DETAILS);
        }
      }
    }
  }, [dispatch, history, installationId, workspace?.id]);

  useEffect(() => {
    loadData();
  }, [loadData]);
  return <AppLayout>Loading ...</AppLayout>;
}

export default GithubCallback;
