import React, { useCallback, useEffect, useState } from 'react';
import {
  // ArrowIcon,
  Box,
  Leftrow,
  // ProjectName,
  Row,
  Subtitle,
  Tag,
  Title
} from '../style';
import ModalCustom from '../../models/modal';
import SearchRepositoryModel from '../../models/searchRepositoryModel/searchRepositoryModel';
import { updateProjectData } from '../../../services/projectServices';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import { updateCurrentProject } from '../../../actions/projectActions';
import { captureException } from '../../../services/logService';
import { GithubRepositoryList } from '../../../interfaces/SettingsInterface';
import { getRepositoryList } from '../../../services/githubServices';
import { setFilteredGithubRepositoryList } from '../../../actions/settingActions';

export default function GithubSettingCard() {
  //State variables
  const [loading, setLoading] = useState(true);
  const [isRepositoryModelOpen, setIsRepositoryModelOpen] = useState(false);
  const [selectedRepository, setSelectedRepository] = useState<GithubRepositoryList>();
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { project: projectSelector, workspace: workspaceSelector } = stateSelector || {};
  const { currentProject } = projectSelector || {};
  const { workspace } = workspaceSelector || {};
  // other variables
  const dispatch = useDispatch();

  // Load selected repo name
  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      if (workspace?.id) {
        const repoList: GithubRepositoryList[] = await dispatch(getRepositoryList());
        const selectedRepo = repoList?.find(
          (repo: GithubRepositoryList) => repo?.id === currentProject?.githubRepository
        );
        setSelectedRepository(selectedRepo);
        dispatch(setFilteredGithubRepositoryList(repoList));
      }
    } catch (err) {
      console.log('error: ', err);
    } finally {
      if (workspace?.id) setLoading(false);
    }
  }, [currentProject?.githubRepository, dispatch, workspace?.id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  // function to set repositories
  const handleSelectRepository = useCallback(
    async (repo: GithubRepositoryList) => {
      try {
        setLoading(true);
        const payload = { GithubRepository: repo?.id };
        const result = await dispatch(updateProjectData(currentProject?.id, payload));
        if (result) {
          dispatch(updateCurrentProject({ propsName: 'githubRepository', value: repo?.id }));
          setSelectedRepository(repo);
          setIsRepositoryModelOpen(false);
        }
      } catch (e) {
        captureException(e);
        console.log('ERROR', e);
      } finally {
        setLoading(false);
      }
    },
    [currentProject.id, dispatch]
  );

  // select repo model open
  const handleSelectProject = useCallback(() => {
    setIsRepositoryModelOpen(true);
  }, []);

  // select repo model close
  const handleCloseModel = useCallback(() => {
    setIsRepositoryModelOpen(false);
  }, []);
  return (
    <>
      <Box>
        <Row>
          <Leftrow>
            <Title>Github Repository</Title>
            {/* show project name */}
            <Subtitle>{selectedRepository?.fullName || ''}</Subtitle>
          </Leftrow>

          {/* repository is selected */}
          <Tag onClick={handleSelectProject}>Change</Tag>
        </Row>
      </Box>
      <ModalCustom open={isRepositoryModelOpen} width={460} onClose={handleCloseModel}>
        <SearchRepositoryModel onClose={handleCloseModel} handleSelect={handleSelectRepository} loading={loading} />
      </ModalCustom>
    </>
  );
}
