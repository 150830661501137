import React, { useCallback, useEffect, useState } from 'react';
import AppLayout from '../../../../component/appLayout';
import { Group, TaskFormation } from './styles';
import Accounting from '../../../../component/accounting';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import TaskDetail from './taskDetail';
import TasksPage from './taskPage';
import {
  DEFAULT_PROJECT_TASK_FILTER,
  SHOW_COMPLETED_TASK_BY_DATE,
  SHOW_TASK_BY_ORDER,
  SHOW_TASK_GROUPING_TYPE,
  STATUS_TYPE,
  TASK_LIST_TYPE
} from '../../../../global/constants';
import TaskGrid from './taskGrid';
import { getTaskGroupList, getTasksList } from '../../../../services/taskServices';
import {
  clearFilteredTasksList,
  setFilteredTasksList,
  setOtherTaskList,
  setTaskFilter,
  setTaskGroupsList,
  setTasksList,
  updateTaskFilter
} from '../../../../actions/taskActions';
import { getCurrentProjectDetails, getMilestoneList } from '../../../../services/projectServices';
import {
  MonthTasksDataInterface,
  OneWeekTaskDataInterface,
  TaskDetailsInterface,
  TaskGroupInterface
} from '../../../../interfaces/TaskInterface';
import { ProjectDetailInterface } from '../../../../interfaces/ProjectInterface';
import { captureException } from '../../../../services/logService';
import CalendarView from './calendarView';
import moment from 'moment';
import TasksCacheService from '../../../../services/tasksCatchServices';
import { eachDayOfInterval, endOfMonth, startOfMonth, startOfWeek } from 'date-fns';
import { getUserPreferenceFieldData, updateUserPreference } from '../../../../helpers/firebaseHelper';
import { RootReducerInterface } from '../../../../interfaces/RootReducerInterface';
import UserPreferenceSingleton from '../../../../helpers/userPreferenceSingleton';
import {
  getTaskListByGroup,
  getTaskListByMilestone,
  getTasksByPriority,
  getTasksListByNoGrouping,
  getTasksListByStatus,
  getTasksListByUser,
  taskSorter
} from '../../../../services/taskFilterService';

const Tasks: React.FC = () => {
  // States
  const [loading, setLoading] = useState(false);
  const [weekTasks, setWeekTasks] = useState<TaskDetailsInterface[]>([]);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [monthData, setMonthData] = useState<MonthTasksDataInterface[]>([]);
  const [totalCompletedTask, setTotalComletedTask] = useState<number>(0);
  const [countAfterHiddenTask, setCountAfterHiddenTask] = useState<number>(0);
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { project: projectSelector, workspace: workspaceSelector } = stateSelector || {};
  const tasksSelector = useSelector((state: RootReducerInterface) => state.task);
  const { taskGroups, taskFilter, otherTask, filteredTasksList } = tasksSelector;
  const { currentProject, milestoneList } = projectSelector;
  const { workspace } = workspaceSelector;
  // Other variables
  const params: { id: string } = useParams();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const taskId = new URLSearchParams(location?.search).get('task');

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const loadTaskData = useCallback(
    async (
      tasksList: TaskDetailsInterface[],
      taskGroups: TaskGroupInterface[],
      currentProjectData?: ProjectDetailInterface
    ) => {
      setLoading(true);

      //Fetch filter data from firebase and store it in reducer
      const filterOptionLocal = (await getUserPreferenceFieldData('tasks_filter_type')) || {};
      let currentProjectFilter = filterOptionLocal[params?.id];

      if (!currentProjectFilter) {
        const cloneFilterDetails = JSON.parse(JSON.stringify(filterOptionLocal));
        currentProjectFilter = DEFAULT_PROJECT_TASK_FILTER;
        cloneFilterDetails[params?.id] = currentProjectFilter;
        dispatch(
          setTaskFilter({
            showSubTask: DEFAULT_PROJECT_TASK_FILTER.showSubTask,
            showCompletedTaskByFilter: DEFAULT_PROJECT_TASK_FILTER.showCompletedTaskByFilter,
            showRecentlyCompletedTask: DEFAULT_PROJECT_TASK_FILTER.showRecentlyCompletedTask,
            showEmptyGroup: DEFAULT_PROJECT_TASK_FILTER.showEmptyGroup,
            taskViewType: DEFAULT_PROJECT_TASK_FILTER.viewType,
            groupingStatus: DEFAULT_PROJECT_TASK_FILTER.grouping,
            groupName: currentProject?.statusEnable ? 'Status' : currentProject?.statusEnable ? 'Group' : 'No Grouping',
            orderByName: currentProject?.statusEnable || currentProject?.isGroupEnabled ? 'Custom' : 'Created Date',
            orderByStatus: DEFAULT_PROJECT_TASK_FILTER.order
          })
        );
        updateUserPreference({ tasks_filter_type: cloneFilterDetails });
      }

      dispatch(updateTaskFilter({ propsName: 'showSubTask', value: currentProjectFilter?.showSubTask || false }));
      dispatch(updateTaskFilter({ propsName: 'showEmptyGroup', value: currentProjectFilter?.showEmptyGroup ?? true }));
      dispatch(
        updateTaskFilter({
          propsName: 'showRecentlyCompletedTask',
          value: currentProjectFilter?.showRecentlyCompletedTask || false
        })
      );

      dispatch(
        updateTaskFilter({
          propsName: 'showCompletedTaskByFilter',
          value: currentProjectFilter?.showCompletedTask || SHOW_COMPLETED_TASK_BY_DATE.LAST_WEEK
        })
      );

      //Initialize Grouping and ordering status
      const groupingStatus =
        currentProjectFilter?.grouping >= 0
          ? currentProjectFilter?.grouping
          : SHOW_TASK_GROUPING_TYPE.GROUPING_BY_GROUP;
      const orderStatus = currentProjectFilter?.order >= 0 ? currentProjectFilter?.order : SHOW_TASK_BY_ORDER.DUE_DATE;

      //Initialize Task and Other task variables
      let otherRef: any;
      let tasksRef: any = [];
      let result: any;

      // Handle Task Grouping
      if (!currentProjectData?.id) return;

      switch (groupingStatus) {
        case SHOW_TASK_GROUPING_TYPE.NO_GROUPING:
          result = getTasksListByNoGrouping(tasksList, currentProjectData.id);
          break;

        case SHOW_TASK_GROUPING_TYPE.DEFAULT:
          if (currentProject?.statusEnable) {
            result = getTasksListByStatus(
              tasksList,
              currentProjectData,
              currentProjectFilter?.showRecentlyCompletedTask || false
            );
          } else if (currentProjectData?.isGroupEnabled) {
            result = await getTaskListByGroup(tasksList, taskGroups);
          } else {
            result = getTasksListByNoGrouping(tasksList, currentProjectData.id);
          }
          break;

        case SHOW_TASK_GROUPING_TYPE.GROUPING_BY_PRIORITY:
          result = getTasksByPriority(tasksList);
          break;

        case SHOW_TASK_GROUPING_TYPE.GROUPING_BY_USER:
          result = getTasksListByUser(tasksList, currentProjectData);
          break;

        case SHOW_TASK_GROUPING_TYPE.GROUPING_BY_MILESTONE:
          let milestoneListClone = JSON.parse(JSON.stringify(milestoneList));
          if (
            !milestoneListClone ||
            milestoneListClone.length === 0 ||
            milestoneList[0]?.projectId !== currentProjectData?.id
          ) {
            milestoneListClone = await dispatch(getMilestoneList(currentProjectData?.id, false, false));
          }
          result = getTaskListByMilestone(tasksList, milestoneListClone);
          break;

        case SHOW_TASK_GROUPING_TYPE.GROUPING_BY_STATUS:
          result = getTasksListByStatus(
            tasksList,
            currentProjectData,
            currentProjectFilter?.showRecentlyCompletedTask || false
          );
          break;

        default:
          result = await getTaskListByGroup(tasksList, taskGroups);
          break;
      }

      if (result) {
        tasksRef = result.tasksRef;
        otherRef = result.otherRef;
      }

      //Handle task ordering
      tasksRef?.forEach((groupItem: any) => {
        const result = taskSorter(orderStatus, groupItem.tasks || [], groupItem.completedTasks || []);
        groupItem.tasks = result.tasks;
        groupItem.completedTasks = result.completedTasks;
      });

      result = taskSorter(orderStatus, otherRef?.tasks, otherRef?.completedTasks);
      otherRef = { tasks: result.tasks, completedTasks: result.completedTasks };

      //Bellow code is for sorting completed task according to filter
      if (groupingStatus === SHOW_TASK_GROUPING_TYPE.GROUPING_BY_STATUS) {
        let originalTaskCount = 0;
        let newTaskCount = 0;

        if (currentProjectFilter?.showCompletedTask === SHOW_COMPLETED_TASK_BY_DATE.ALL) {
          originalTaskCount = tasksRef?.reduce(
            (acc: any, item: any) => (item.status === STATUS_TYPE.COMPLETED ? acc + item.tasks.length : acc),
            0
          );
          newTaskCount = originalTaskCount;
          setTotalComletedTask(originalTaskCount);
          setCountAfterHiddenTask(newTaskCount);
        } else {
          const today = new Date();
          let endDate: Date;
          if (currentProjectFilter?.showCompletedTask === SHOW_COMPLETED_TASK_BY_DATE.YESTERDAY) {
            endDate = new Date(today);
            endDate.setDate(today.getDate() - 1);
            endDate.setHours(0, 0, 0, 0);
          } else if (
            currentProjectFilter?.showCompletedTask === SHOW_COMPLETED_TASK_BY_DATE.LAST_WEEK ||
            !currentProjectFilter?.showCompletedTask
          ) {
            endDate = new Date(today);
            endDate.setDate(today.getDate() - today.getDay() - 7);
            endDate.setHours(0, 0, 0, 0);
          } else if (currentProjectFilter?.showCompletedTask === SHOW_COMPLETED_TASK_BY_DATE.LAST_MONTH) {
            endDate = new Date(today.getFullYear(), today.getMonth() - 1, new Date().getDate());
            endDate.setHours(0, 0, 0, 0);
          } else if (currentProjectFilter?.showCompletedTask === SHOW_COMPLETED_TASK_BY_DATE.NONE) {
            originalTaskCount = tasksRef?.reduce(
              (acc: any, item: any) => (item.status === STATUS_TYPE.COMPLETED ? acc + item.tasks.length : acc),
              0
            );
            setTotalComletedTask(originalTaskCount);
            setCountAfterHiddenTask(0);
            tasksRef = tasksRef?.map((item: any) => {
              if (item.status === STATUS_TYPE.COMPLETED) {
                return { ...item, tasks: [] };
              }
              return item;
            });
          }

          originalTaskCount = tasksRef?.reduce(
            (acc: any, item: any) => (item.status === STATUS_TYPE.COMPLETED ? acc + item.tasks.length : acc),
            0
          );
          if (currentProjectFilter?.showCompletedTask !== SHOW_COMPLETED_TASK_BY_DATE.NONE) {
            tasksRef = tasksRef?.map((item: any) => {
              if (item.status === STATUS_TYPE.COMPLETED) {
                let filteredCompletedTasks = item.tasks.filter((task: TaskDetailsInterface) => {
                  const taskDate = new Date(task?.updatedTime || '');
                  return taskDate >= endDate;
                });

                if (currentProjectFilter?.showRecentlyCompletedTask) {
                  filteredCompletedTasks = filteredCompletedTasks?.toSorted(
                    (a: TaskDetailsInterface, b: TaskDetailsInterface) =>
                      new Date(b.updatedTime || '').getTime() - new Date(a.updatedTime || '').getTime()
                  );
                }

                newTaskCount += filteredCompletedTasks?.length;
                return { ...item, tasks: filteredCompletedTasks };
              }
              return item;
            });
            setTotalComletedTask(originalTaskCount);
            setCountAfterHiddenTask(newTaskCount);
          }
        }
      }
      dispatch(setFilteredTasksList(tasksRef));
      dispatch(setOtherTaskList(otherRef));
      setLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, params?.id]
  );

  const loadWeekWiseTasks = useCallback(
    async (formatedStartDate: string) => {
      const startDateForTask = moment(formatedStartDate)?.add(-1, 'days').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
      const endDateForTask = moment(formatedStartDate)?.add(7, 'days').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
      const weekWiseTasks = await TasksCacheService.getInstance()?.getTasksByDueDate(
        params?.id,
        startDateForTask,
        endDateForTask,
        taskFilter?.showSubTask
      );
      setWeekTasks(weekWiseTasks);
    },
    [params?.id, taskFilter?.showSubTask]
  );

  const loadCurrentMonthTasks = useCallback(async () => {
    const startDate = startOfMonth(currentMonth);
    const endDate = endOfMonth(currentMonth);
    const monthDates = eachDayOfInterval({ start: startDate, end: endDate });
    const formatedStartDate = moment(startDate).format('YYYY-MM-DD');
    const startDateForTask = moment(formatedStartDate)?.add(-1, 'days').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    const endDateForTask = moment(moment(endDate).format('YYYY-MM-DD'))
      .add(1, 'days')
      .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    const monthTasks: TaskDetailsInterface[] = await TasksCacheService.getInstance()?.getTasksByDueDate(
      currentProject?.id,
      startDateForTask,
      endDateForTask
    );
    const weeks: MonthTasksDataInterface[] = [];
    let week: OneWeekTaskDataInterface[] = [];
    monthDates?.forEach((date, index) => {
      const loopDate = moment(date).format('DD-MM-YYYY');
      const thisDayTasks = monthTasks?.filter((x) => moment(x.dueDate).format('DD-MM-YYYY') === loopDate && !x?.status);
      week?.push({ date: date.toISOString(), tasks: thisDayTasks });
      if (week?.length > 0 && (date.getDay() === 0 || index === monthDates?.length - 1)) {
        if (weeks?.length === 0 && week?.length !== 7) {
          const startRef = startOfWeek(date, { weekStartsOn: 1 });
          let weekRef = [];
          for (let i = 0; i < 7 - week?.length; i++) {
            weekRef.push({
              date: moment(startRef)?.add(i, 'days').toISOString(),
              tasks: []
            });
          }
          week = [...(weekRef || []), ...(week || [])];
        } else if (index === monthDates?.length - 1 && week?.length !== 7) {
          let weekRef = [];
          for (let i = 0; i < 7 - week?.length; i++) {
            weekRef?.push({
              date: moment(date)
                .add(i + 1, 'days')
                .toISOString(),
              tasks: []
            });
          }
          week = [...(week || []), ...(weekRef || [])];
        }
        weeks.push(week);
        week = [];
      }
      setMonthData(weeks);
    });
  }, [currentMonth, currentProject?.id]);

  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      if (!taskId) {
        if (workspace.id) {
          const filterOptionLocal = (await getUserPreferenceFieldData('tasks_filter_type')) || {};
          const currentProjectFilter = filterOptionLocal[params?.id];
          const result = await Promise.all([
            dispatch(getCurrentProjectDetails(workspace?.id, params?.id)),
            dispatch(getTaskGroupList(params?.id)),
            dispatch(getTasksList(params?.id, true, false))
          ]);
          const projectDetails: ProjectDetailInterface = result[0];
          if (projectDetails?.isMilestone) {
            dispatch(getMilestoneList(params?.id, false, false));
          }
          const currentUser = UserPreferenceSingleton.getInstance().getCurrentUser();
          const validUser = projectDetails?.users?.find((user) => user?.id === currentUser?.id);
          if (!validUser) {
            history.push('/');
            return;
          }
          if (currentProjectFilter?.viewType === TASK_LIST_TYPE.CALANDAR_VIEW) {
            loadCurrentMonthTasks();
            loadWeekWiseTasks(moment(startOfWeek(currentMonth, { weekStartsOn: 1 })).format('YYYY-MM-DD'));
          } else {
            await loadTaskData(result[2], result[1], result[0]);
          }
        }
      }
      setLoading(false);
    } catch (e) {
      captureException(e);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId, workspace.id, params?.id, dispatch, history, currentMonth, loadTaskData]);

  const onChangeFilterType = useCallback(async () => {
    if (workspace?.id && params?.id) {
      const result = await Promise.all([
        dispatch(getTaskGroupList(params?.id, false, false)),
        dispatch(getTasksList(params?.id, true, false)),
        dispatch(getCurrentProjectDetails(workspace?.id, params?.id))
      ]);
      loadTaskData(result[1], result[0], result[2]);
    }
  }, [dispatch, loadTaskData, params?.id, workspace?.id]);

  useEffect(() => {
    loadData();
    return () => {
      dispatch(clearFilteredTasksList());
      dispatch(setTaskGroupsList([]));
      dispatch(setTasksList([]));
      dispatch(setOtherTaskList({ tasks: [], completedTasks: [] }));
    };
  }, [loadData, dispatch]);

  const getTaskView = useCallback(
    (taskViewType: number) => {
      if (taskViewType === TASK_LIST_TYPE.TASK_GRID) {
        return (
          <TaskGrid
            propsLoading={loading}
            loadData={loadData}
            groupingStatus={taskFilter?.groupingStatus}
            taskGroups={taskGroups}
            currentProject={currentProject}
          />
        );
      }
      if (taskViewType === TASK_LIST_TYPE.CALANDAR_VIEW) {
        return (
          <CalendarView
            propsLoading={loading}
            loadData={loadData}
            weekTasks={weekTasks}
            loadWeekWiseTasks={loadWeekWiseTasks}
            currentMonth={currentMonth}
            setCurrentMonth={setCurrentMonth}
            monthData={monthData}
            setWeekTasks={(tasks) => setWeekTasks(tasks)}
            taskGroups={taskGroups}
            currentProject={currentProject}
            orderByStatus={taskFilter?.orderByStatus}
            workspaceDetails={workspace}
          />
        );
      }
      return (
        <TasksPage
          propsLoading={loading}
          loadData={loadData}
          taskGroups={taskGroups}
          groupingStatus={taskFilter?.groupingStatus}
          currentProject={currentProject}
          completedTask={totalCompletedTask}
          hiddenTask={countAfterHiddenTask}
          otherTask={otherTask}
          setOtherTask={(task) => dispatch(setOtherTaskList(task))}
          filteredTasksList={filteredTasksList}
        />
      );
    },
    [
      loading,
      loadData,
      taskGroups,
      taskFilter?.groupingStatus,
      taskFilter?.orderByStatus,
      currentProject,
      totalCompletedTask,
      countAfterHiddenTask,
      otherTask,
      filteredTasksList,
      weekTasks,
      loadWeekWiseTasks,
      currentMonth,
      monthData,
      workspace,
      dispatch
    ]
  );

  return (
    <>
      <AppLayout isPadding={true}>
        <Group>
          {taskId ? (
            <TaskDetail />
          ) : (
            <>
              <TaskFormation>
                <Accounting otherTaskList={() => onChangeFilterType()} loadTaskList={loadData} isLeftPadding={true} />
                {getTaskView(taskFilter?.taskViewType)}
              </TaskFormation>
            </>
          )}
        </Group>
      </AppLayout>
    </>
  );
};

export default Tasks;
