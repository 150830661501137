import styled from 'styled-components';

const NavigationWrapper = styled.div`
  display: none;
  @media (max-width: 449px) {
    display: flex;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--background-secondary);
    position: fixed;
    bottom: 0;
    z-index: 11;
    width: -webkit-fill-available;
    border-top: 0.5px solid var(--border-primary);

    &.apply-zindex {
      z-index: 1111;
    }
  }
`;

const NavigationItem = styled.div`
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Icon = styled.div`
  width: 24px;
  height: 24px;
  .plus-icon {
    path {
      fill: var(--text-tertiary);
    }
  }
  .active {
    path {
      fill: var(--text-primary);
    }
  }
  .active-stroke {
    path {
      fill: var(--text-primary);
    }
  }
`;
export { NavigationWrapper, NavigationItem, Icon };
