import TaskDetail from '../../../pages/project/details/tasks/taskDetail';

interface Props {
  taskId: string;
  projectId: string;
  handleCloseTaskDetail: () => void;
}
export default function TaskDetailPreview(props: Props) {
  const { taskId, projectId, handleCloseTaskDetail } = props;

  return (
    <TaskDetail
      taskId={taskId}
      projectId={projectId}
      isTaskDetailPreview={true}
      onClosePreviewModal={handleCloseTaskDetail}
    />
  );
}
