import React, { useCallback, useEffect, useState } from 'react';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { useSelector, useDispatch } from 'react-redux';
import { clearListDropdown, updateListDropdown, updateTimeEntryInput } from '../../../actions/timeTrackingActions';
import { getDateObjecttoDDMMM, getFloatTimeValue, getUTCDate, isEmpty } from '../../../helpers/common';
import dayjs from 'dayjs';
import { ProjectIdInterface, TaskListInterface, TasksInterface } from '../../../interfaces/TimeTrackingInterface';
import { getTasksList } from '../../../services/taskServices';
import Button from '../../button';
import { nanoid } from 'nanoid';
import UserPreferenceSingleton from '../../../helpers/userPreferenceSingleton';
import moment from 'moment';
import { setErrorMessage } from '../../../actions/messageActions';
import TimePickerModal from '../../timePicker';
import { captureException } from '../../../services/logService';
import {
  addTimeLogDetails,
  startTracker,
  stopTracker,
  updateTimeLogDetails
} from '../../../services/timeTrackingServices';
import {
  Billingtitle,
  Check,
  Form,
  Formation,
  Formblock,
  Formbutton,
  Headline,
  Icon,
  IconDiv,
  Input,
  InputDiv,
  Inputvalue,
  ItemDiv,
  Label,
  Modalblock,
  ModalBox,
  Modalhead,
  SelectedText,
  Taskarea
} from './styles';
import { Dropdown, DropdownItem } from '../../Dropdown';
import { useMobileDevice } from '../../../global/useMobile';
import { selectTimeTrackingState } from '../../../store/selectors';

interface Props {
  onCloseModal: () => void;
  loadData: () => void;
  isMyTimeLogModal?: boolean;
}

const AddNewTimeMoadal: React.FC<Props> = ({ onCloseModal, loadData, isMyTimeLogModal = false }) => {
  //use selector state variables with memoized selectors
  const { currentTimeTracking, timeEntryInput, listDropdown, workspace } = useSelector(selectTimeTrackingState);
  const { id: workspace_id } = workspace;
  const { projectList, taskList } = listDropdown;
  const isMobile = useMobileDevice();
  const isMinWidth = isMobile ? Math.max(window.innerWidth - 65) : 598;
  //State
  const [loading, setLoading] = useState(false);
  const [time, setTime] = useState('');
  const [isProjectDropdownOpen, setIsProjectDropdownOpen] = useState(false);
  const [isTaskDropdownOpen, setIsTaskDropdownOpen] = useState(false);
  const [filteredProjectList, setFilteredProjectList] = useState(projectList);
  const [projectSearch, setProjectSearch] = useState('');
  const [filteredTaskList, setFilteredTaskList] = useState(taskList);
  const [taskSearch, setTaskSearch] = useState('');

  //Other variable
  const dispatch = useDispatch();

  useEffect(() => {
    setTime(timeEntryInput?.hours);
  }, [timeEntryInput?.hours]);

  const onUpdateProjectValue = useCallback(
    async (project: ProjectIdInterface) => {
      if (project?.id) {
        const result = await dispatch(getTasksList(project?.id, true, true));
        const tasks = result?.map((item: TasksInterface) => {
          return { ...item, value: item?.name, key: item?.id };
        });
        setFilteredTaskList(tasks);
        dispatch(updateListDropdown({ propsName: 'taskList', value: tasks }));
      }
      dispatch(updateTimeEntryInput({ propsName: 'projectId', value: project }));
      dispatch(updateTimeEntryInput({ propsName: 'taskId', value: '' }));
    },
    [dispatch]
  );

  const onUpdateTaskValue = useCallback(
    (value: TaskListInterface) => {
      dispatch(updateTimeEntryInput({ propsName: 'taskId', value }));
    },
    [dispatch]
  );

  const onChangeInputField = useCallback(
    (propsName: string, value: any) => {
      dispatch(updateTimeEntryInput({ propsName, value }));
    },
    [dispatch]
  );

  const onCancelClick = useCallback(() => {
    dispatch(clearListDropdown());
    onCloseModal();
  }, [dispatch, onCloseModal]);

  const onSubmit = useCallback(
    async (e: React.SyntheticEvent) => {
      try {
        e.preventDefault();
        setLoading(true);
        const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
        let result;
        if (isEmpty(timeEntryInput?.projectId) || isEmpty(timeEntryInput?.projectId?.id)) {
          dispatch(setErrorMessage('Please select Project.'));
          return;
        }
        if (isEmpty(timeEntryInput?.taskId) || isEmpty(timeEntryInput?.taskId?.id)) {
          dispatch(setErrorMessage('Please select task.'));
          return;
        }
        let currentTimeTrackingDate = currentTimeTracking?.date;
        if (isMyTimeLogModal) {
          currentTimeTrackingDate = timeEntryInput?.date;
        }
        if (timeEntryInput?.id) {
          let payload;
          if (time !== timeEntryInput?.hours) {
            const hours = getFloatTimeValue(timeEntryInput?.hours);
            payload = {
              ProjectId: timeEntryInput?.projectId?.id,
              TaskId: timeEntryInput?.taskId?.id,
              Notes: timeEntryInput?.notes,
              Date: getUTCDate(currentTimeTrackingDate).toISOString(),
              IsBillable: timeEntryInput?.isBillable,
              IsManual: true,
              Hours: hours,
              History: [
                {
                  _id: nanoid(),
                  CreatedBy: userDetails?.id,
                  CreatedTime: new Date().toISOString(),
                  UpdatedBy: userDetails?.id,
                  UpdatedTime: new Date().toISOString(),
                  TimerStartedAt: timeEntryInput?.createdOn,
                  TimerEndedAt: moment(timeEntryInput?.createdOn).add(hours, 'hours').toISOString(),
                  Hours: hours
                }
              ]
            };
          } else {
            payload = {
              ProjectId: timeEntryInput?.projectId?.id,
              TaskId: timeEntryInput?.taskId?.id,
              Notes: timeEntryInput?.notes,
              Date: getUTCDate(currentTimeTrackingDate).toISOString(),
              IsBillable: timeEntryInput?.isBillable
            };
          }
          result = await dispatch(updateTimeLogDetails(timeEntryInput?.id, payload));
          dispatch(stopTracker());
        } else {
          const hours = getFloatTimeValue(timeEntryInput?.hours);
          const newPayload = {
            project_id: timeEntryInput?.projectId?.id,
            task_id: timeEntryInput?.taskId?.id,
            date: getUTCDate(currentTimeTrackingDate).toISOString(),
            hours: hours,
            isManual: true,
            notes: timeEntryInput?.notes,
            isBillable: timeEntryInput?.isBillable
          };
          result = await dispatch(addTimeLogDetails(newPayload));
          dispatch(startTracker(timeEntryInput?.taskId?.id));
        }
        if (result) {
          onCancelClick();
          loadData();
        }
      } catch (e) {
        captureException(e);
        console.log('ERROR', e);
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, currentTimeTracking, timeEntryInput, workspace_id]
  );

  const handleToggleProjectDropdown = useCallback(() => {
    setIsProjectDropdownOpen((prev) => !prev);
  }, []);

  const handleToggleTaskDropdown = () => {
    if (isEmpty(timeEntryInput?.projectId) || isEmpty(timeEntryInput?.projectId?.id)) {
      dispatch(setErrorMessage('Please select Project firstly.'));
      return;
    }
    setIsTaskDropdownOpen((prev) => !prev);
  };

  // Function to handle task selection and close dropdown
  const handleTaskSelection = useCallback(
    (task: TaskListInterface) => {
      onUpdateTaskValue(task);
      setTaskSearch('');
      setIsTaskDropdownOpen(false); // Close dropdown after selection
    },
    [onUpdateTaskValue]
  );

  const handleProjectSearch = useCallback(
    (searchValue: string) => {
      setProjectSearch(searchValue);
      const filteredList = projectList.filter((project) =>
        (project.label || '').toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredProjectList(filteredList);
    },
    [projectList]
  );

  // Function to handle project selection and close dropdown
  const handleProjectSelection = useCallback(
    (project: ProjectIdInterface) => {
      handleProjectSearch('');
      onUpdateProjectValue(project);
      setIsProjectDropdownOpen(false); // Close dropdown after selection
    },
    [handleProjectSearch, onUpdateProjectValue]
  );

  const handleTaskSearch = useCallback(
    (searchValue: string) => {
      setTaskSearch(searchValue);
      const filteredTask = taskList.filter((task) => task.value.toLowerCase().includes(searchValue.toLowerCase()));
      setFilteredTaskList(filteredTask);
    },
    [taskList]
  );

  return (
    <>
      <ModalBox>
        <Modalblock onSubmit={onSubmit}>
          <Modalhead>
            <Headline>
              {timeEntryInput?.id ? 'Update' : 'New'} Time Entry for{' '}
              {isMyTimeLogModal ? timeEntryInput?.day : currentTimeTracking?.day},{' '}
              {getDateObjecttoDDMMM(isMyTimeLogModal ? timeEntryInput?.date : currentTimeTracking?.date)}
            </Headline>
            <Icon onClick={onCancelClick}>
              <SVGIcon
                name='close-icon'
                width='18'
                height='18'
                viewBox='0 0 18 18'
                fill='none'
                className='close-icon'
              />
            </Icon>
          </Modalhead>
          <Formation>
            <Form>
              <Label>Project</Label>

              <Dropdown
                isMinWidth={isMinWidth}
                content={
                  <InputDiv onClick={handleToggleProjectDropdown}>
                    <Input
                      placeholder={isEmpty(timeEntryInput?.projectId?.label) ? 'Select your Project' : ''}
                      value={projectSearch}
                      onChange={(e) => handleProjectSearch(e.target.value)}
                    />
                    {isEmpty(projectSearch) && <SelectedText>{timeEntryInput?.projectId?.label}</SelectedText>}
                    <IconDiv>
                      <SVGIcon
                        name='angledown-icon'
                        width='18'
                        height='18'
                        viewBox='0 0 18 18'
                        className={`angledown-icon ${isProjectDropdownOpen ? 'rotate' : ''}`}
                      />
                    </IconDiv>
                  </InputDiv>
                }
                trigger='click'>
                <ItemDiv>
                  {filteredProjectList.map((project) => (
                    <DropdownItem
                      key={project.id}
                      label={project.label}
                      onClick={() => handleProjectSelection(project)}
                    />
                  ))}
                </ItemDiv>
              </Dropdown>
            </Form>
            <Form>
              <Label>Task</Label>
              <Dropdown
                isMinWidth={isMinWidth}
                content={
                  <InputDiv onClick={handleToggleTaskDropdown}>
                    <Input
                      placeholder={isEmpty(timeEntryInput?.taskId?.value) ? 'Select your Task' : ''}
                      value={taskSearch}
                      onChange={(e) => handleTaskSearch(e.target.value)}
                    />

                    {isEmpty(taskSearch) && <SelectedText>{timeEntryInput?.taskId?.value}</SelectedText>}

                    <IconDiv>
                      <SVGIcon
                        name='angledown-icon'
                        width='18'
                        height='18'
                        viewBox='0 0 18 18'
                        className={`angledown-icon ${isTaskDropdownOpen ? 'rotate' : ''}`}
                      />
                    </IconDiv>
                  </InputDiv>
                }
                trigger='click'>
                <ItemDiv>
                  {filteredTaskList.map((task) => (
                    <DropdownItem key={task.id} label={task.value} onClick={() => handleTaskSelection(task)} />
                  ))}
                </ItemDiv>
              </Dropdown>
            </Form>
            <Form>
              <Label>Activity Description (Optional)</Label>
              <Taskarea
                placeholder='Add Description Here'
                value={timeEntryInput?.notes || ''}
                onChange={(e) => onChangeInputField('notes', e.target.value)}
              />
            </Form>

            <Formblock>
              <Form>
                <Label>Duration, HH:mm (Up to 24h) </Label>
                <TimePickerModal
                  value={dayjs(timeEntryInput?.hours, 'HH:mm')}
                  onSelect={(value) => onChangeInputField('hours', value.format('HH:mm'))}
                  placement='topLeft'
                />
              </Form>

              <Form>
                <Check>
                  <Inputvalue
                    type='checkbox'
                    className='checkbox-round'
                    checked={timeEntryInput?.isBillable}
                    onChange={() => onChangeInputField('isBillable', !timeEntryInput?.isBillable)}
                  />
                  <Billingtitle>Billable</Billingtitle>
                </Check>
              </Form>
            </Formblock>
          </Formation>
          <Formbutton>
            <Button
              title='Cancel'
              onClick={onCancelClick}
              secondary={true}
              type='button'
              modelbtn={true}
              hasNotBoxshadow={true}
            />
            <Button
              type={'submit'}
              title={timeEntryInput?.id ? 'Update' : 'Save'}
              isLoading={loading}
              modelbtn={true}
            />
          </Formbutton>
        </Modalblock>
      </ModalBox>
    </>
  );
};

export default AddNewTimeMoadal;
