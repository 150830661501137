import React, { useCallback, useState } from 'react';
import { Bottom, Main, Top, Title, Time, Overlay, Moreicon } from './styles';
import SVGIcon from '../../assets/images/svg/SVGIcon';
import { setSuccessMessage } from '../../actions/messageActions';
import { useDispatch } from 'react-redux';
import { captureException } from '../../services/logService';
import copy from 'clipboard-copy';
import { deleteSnap } from '../../services/snapService';
import { deleteFile } from '../../helpers/common';
import ModalCustom from '../models/modal';
import Deletemodal from '../models/deleteModel';
import SnapRenameModal from '../models/snapRenameModal';
import { SnapListInterface } from '../../interfaces/snapInterface';
import { Dropdown, DropdownItem } from '../Dropdown';

interface Props {
  time: string;
  loadData: () => void;
  snapData: SnapListInterface;
  selectedItem: string | null;
  setSelectedItem: (value: string | null) => void;
}
const SnapcardView: React.FC<Props> = (props) => {
  const { snapData, selectedItem, setSelectedItem } = props;
  //State
  const [isDeleteModelOpen, setIsDeleteModelOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  //Other variable
  const dispatch = useDispatch();

  const onCopyLink = useCallback(
    async (shareId: string) => {
      try {
        setSelectedItem(null);
        await copy(`https://share.teamcamp.app/${shareId}`);
        dispatch(setSuccessMessage('Screen recording url copied successfully!'));
      } catch (error) {
        captureException(error);
        console.log('Failed to copy url: ', error);
      }
    },
    [dispatch, setSelectedItem]
  );

  /**
   * Handles the click event for downloading a file.
   * @param {string} fileUrl - The URL of the file to be downloaded.
   * @param {string} fileName - The name of the file to be downloaded.
   */
  const handleDownloadClick = useCallback(
    (fileUrl: string, fileName: string) => {
      setSelectedItem(null);
      fetch(fileUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const blobUrl = window.URL.createObjectURL(blob);

          const link = document.createElement('a');
          link.href = blobUrl;
          link.setAttribute('download', fileName);

          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
          window.URL.revokeObjectURL(blobUrl);
        })
        .catch((error) => {
          console.error('Error downloading file:', error);
        });
    },
    [setSelectedItem]
  );

  const openDeleteModel = useCallback(() => {
    setSelectedItem(null);
    setIsDeleteModelOpen(true);
  }, [setSelectedItem]);

  const closeDeleteModel = useCallback(() => {
    setIsDeleteModelOpen(false);
  }, []);

  const onClickRename = useCallback(() => {
    setSelectedItem(null);
    setIsOpen(true);
  }, [setSelectedItem]);

  const cancel = () => {
    setIsOpen(false);
  };

  const onSnapDelete = useCallback(
    async (snapId: string, ref: string) => {
      setLoading(true);
      try {
        const url = new URL(ref);
        const decodedFilename = decodeURIComponent(url.pathname);
        const parts = decodedFilename.split('/');
        const firebasePath = parts.slice(-4);
        const filePath = firebasePath.join('/');
        await dispatch(deleteSnap(snapId));
        await deleteFile(filePath);
        dispatch(setSuccessMessage('Screen recording deleted successfully!'));
      } catch (error) {
        console.log('error in deleting snap', error);
      } finally {
        setLoading(false);
        setIsDeleteModelOpen(false);
        props.loadData();
      }
    },
    [dispatch, props]
  );

  const onClickCard = useCallback((shareId: string) => {
    const newTab = window.open(`https://share.teamcamp.app/${shareId}`, '_blank');
    if (newTab) {
      newTab.focus();
    } else {
      alert('Please allow pop-ups for this site to open in a new tab.');
    }
  }, []);

  return (
    <>
      {/* <Div> */}
      <Main isActive={snapData['_id'] === selectedItem} onClick={() => onClickCard(snapData?.['_id'])}>
        <Top>
          <video>
            <source type='video/mp4' src={snapData?.Ref} />
          </video>
          <Overlay className='overlay'></Overlay>
          <Moreicon className='moreicon'>
            <Dropdown
              activeClassName='active'
              content={
                <div onClick={() => setSelectedItem(snapData['_id'])}>
                  <SVGIcon name='more-icon' width='18' height='18' viewBox='0 0 18 18' className='fillColor' />
                </div>
              }
              trigger='click'
              onOutsideClick={() => setSelectedItem(null)}>
              <DropdownItem key={1} label='Copy link' onClick={() => onCopyLink(snapData?.['_id'])} />
              <DropdownItem key={2} label='Rename' onClick={onClickRename} />
              <DropdownItem
                key={3}
                label='Download'
                onClick={() => handleDownloadClick(snapData?.Ref, snapData?.Name)}
              />
              <DropdownItem key={4} label='Delete' onClick={openDeleteModel} tone='critical' />
            </Dropdown>
          </Moreicon>
        </Top>
        <Bottom>
          <Title>{snapData?.Name}</Title>
          <Time>{props?.time}</Time>
        </Bottom>
      </Main>
      <ModalCustom open={isDeleteModelOpen} onClose={closeDeleteModel} width={334} isZindex={true}>
        <Deletemodal
          onClose={closeDeleteModel}
          loading={loading}
          onOk={() => onSnapDelete(snapData['_id'], snapData?.Ref)}
          modaltitle='Delete Snap?'
          description='Are you sure you want to delete this snap?'
        />
      </ModalCustom>
      <ModalCustom open={isOpen} onClose={cancel} width={334} isZindex={true}>
        <SnapRenameModal onClose={cancel} loadData={props.loadData} snapData={props?.snapData} />
      </ModalCustom>
      {/* </Div> */}
    </>
  );
};
export default SnapcardView;
