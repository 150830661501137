import React, { useCallback, useState } from 'react';
import AppLayout from '../../component/appLayout';
import { VercelCMDK } from '../../component/vercel/vercel';
import { useDispatch } from 'react-redux';
import { useMobileDevice } from '../../global/useMobile';
import { setVercelModalOpen } from '../../actions/appActions';
import { CloseIcon, Header, Heading, SearchArea, SearchBlock, SearchIcon } from './style';
import SVGIcon from '../../assets/images/svg/SVGIcon';
import ModalCustom from '../../component/models/modal';
import { Headline, Icon, Modalhead } from '../../component/appLayout/styles';
import CreateNewProjectModal from '../../component/models/createNewProjectModal';
import CreateTaskModal from '../../component/models/createTaskModal';
import { ContactUsModal } from '../../component/models/contactUsmodal';
import { SuccessfullModal } from '../../component/models/contactUsmodal/successfullmessagemodal';
import { isMediaRecordingSupported } from '../../helpers/common';
import SnapRecordModal from '../../component/models/snapRecordModal';
import { BottomNavigation } from '../../component/bottomNavigation';
import UserModal from '../../component/models/userModal';

const ResponsiveSearch: React.FC = () => {
  // States
  const [isOpenSnapModal, setIsOpenSnapModal] = useState(false);
  const [isOpenTaskModal, setIsOpenTaskModal] = useState(false);
  const [openContactUsModal, setOpenContactUsModal] = useState(false);
  const [isOpenProjectModal, setIsOpenProjectModal] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [isSuccessfullModalOpen, setIsSuccessfullModalOpen] = useState(false);
  const [isOpenUserModal, setIsOpenUserModal] = useState(false);

  // Other variables
  const dispatch = useDispatch();
  const isMobile = useMobileDevice();

  const openSnapModal = useCallback(() => {
    setIsOpenSnapModal(true);
  }, []);

  const closeCmdPopup = useCallback(() => {
    if (isMobile) {
      dispatch(setVercelModalOpen(false));
    }
  }, [dispatch, isMobile]);

  const onClickCreateProject = useCallback(() => {
    setIsOpenProjectModal(true);
  }, []);

  const onClickCreateTask = useCallback(() => {
    setIsOpenTaskModal(true);
  }, []);

  const clearSearch = () => {
    setInputValue('');
  };

  const closeProjectModal = useCallback(() => {
    setIsOpenProjectModal(false);
  }, []);

  const closeTaskModal = useCallback(() => {
    setIsOpenTaskModal(false);
  }, []);

  const onCloseContactUsModal = useCallback(() => {
    setOpenContactUsModal(false);
  }, []);

  const onCloseSuccessfullModal = useCallback(() => {
    setIsSuccessfullModalOpen(false);
  }, []);

  const closeSnapModal = useCallback(() => {
    setIsOpenSnapModal(false);
  }, []);

  const onClickUserPopup = useCallback(() => {
    setIsOpenUserModal(true);
  }, []);

  const onCloseUserModal = useCallback(() => {
    setIsOpenUserModal(false);
  }, []);
  return (
    <>
      <AppLayout>
        <Header>
          <SearchBlock>
            <Heading>Search</Heading>
          </SearchBlock>

          <SearchArea>
            <SearchIcon>
              <SVGIcon
                name='project-search-icon'
                width='16'
                height='16'
                viewBox='0 0 16 16'
                className={inputValue ? '' : 'search-icon'}
              />
            </SearchIcon>
            <input
              type='text'
              placeholder='Search for projects, people...'
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
            {inputValue && (
              <CloseIcon onClick={clearSearch}>
                <SVGIcon
                  name='close-icon'
                  width='18'
                  height='18'
                  viewBox='0 0 18 18'
                  fill='none'
                  className='close-icon'
                />
              </CloseIcon>
            )}
          </SearchArea>
        </Header>
        <VercelCMDK
          openSnapModal={openSnapModal}
          closePopup={closeCmdPopup}
          openProjectModal={onClickCreateProject}
          openTaskModal={onClickCreateTask}
          setOpenContactUsModal={() => setOpenContactUsModal(!openContactUsModal)}
          isSearchScreen
          searchValue={inputValue}
          openUserModal={onClickUserPopup}
        />
        <ModalCustom open={isOpenProjectModal} onClose={closeProjectModal} width={632}>
          <Modalhead>
            <Headline>Create New Project</Headline>
            <Icon onClick={closeProjectModal}>
              <SVGIcon name='close-icon' width='18' height='18' viewBox='0 0 18 18' fill='none' className='svgicon' />
            </Icon>
          </Modalhead>
          <CreateNewProjectModal handleCloseModal={closeProjectModal} onClickCancel={closeProjectModal} />
        </ModalCustom>
        <ModalCustom open={isOpenTaskModal} onClose={closeTaskModal} isCreateTask={true}>
          <CreateTaskModal onCancel={closeTaskModal} isFromShortcut={true} />
        </ModalCustom>
        <ModalCustom open={openContactUsModal} onClose={onCloseContactUsModal} width={500}>
          <ContactUsModal
            openSuccessfullModal={() => setIsSuccessfullModalOpen(true)}
            closeContactUsModal={() => setOpenContactUsModal(false)}
          />
        </ModalCustom>
        <ModalCustom open={isSuccessfullModalOpen} onClose={onCloseSuccessfullModal} width={500}>
          <SuccessfullModal onClose={onCloseSuccessfullModal} />
        </ModalCustom>
        {isMediaRecordingSupported() && (
          <ModalCustom open={isOpenSnapModal} onClose={closeSnapModal} width={258} isZindex={true}>
            <SnapRecordModal handleCloseModal={closeSnapModal} />
          </ModalCustom>
        )}
        <ModalCustom open={isOpenUserModal} onClose={onCloseUserModal} width={280}>
          <UserModal onClose={onCloseUserModal} />
        </ModalCustom>
      </AppLayout>
      {/* bottom navigation bar */}
      <BottomNavigation />
    </>
  );
};

export default ResponsiveSearch;
