import styled from 'styled-components';
import { Typography } from '../../../global/theme';

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 0 12px;
  width: 100%;
  max-width: 662px;
  margin: 0 auto;
  align-items: center;
`;
const Subscriptiongroup = styled.div`
  width: 100%;
  max-width: 662px;
  margin: 0 auto;
  margin-bottom: 24px;
  .ant-modal .ant-modal-content {
    padding: 0;
  }
`;
const Personaltext = styled.div`
  ${Typography.heading_lg}
  color: var(--text-primary);
`;
const Content = styled.div`
  border-radius: 6px;
  border: 0.5px solid var(--border-primary);
  background: var(--background-primary);
`;

const First = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 12px 16px;
  border-bottom: 0.5px solid var(--border-primary);
  :last-child {
    border-bottom: none;
    padding: 14px 16px;
  }
`;
const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const Icons = styled.div`
  display: flex;
  gap: 12px;
  .active {
    border-radius: 4px;
    background: var(--neutral-secondary);
  }
`;
const Heading = styled.div`
  ${Typography.heading_md}
  color: var(--text-primary);
`;

const Description = styled.div`
  ${Typography.body_md_medium}
  color: var(--text-secondary);
`;

const Icon1 = styled.div`
  height: 24px;
  .copy-icon {
    padding: 3px;

    cursor: pointer;
    stroke: var(--text-secondary);
    path {
      stroke: var(--text-secondary);
    }
    :hover {
      padding: 3px;
      border-radius: 4px;
      background: var(--neutral-secondary);
    }
  }
`;
const Icon2 = styled.div`
  display: flex;
  height: 24px;
  position: relative;
  .more-icon {
    padding: 3px;
    cursor: pointer;
    fill: var(--text-secondary);
    stroke: var(--text-secondary);
    :hover {
      padding: 3px;
      border-radius: 4px;
      background: var(--neutral-secondary);
    }
  }
`;

const Emptystateapi = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Apilink = styled.div`
  color: var(--text-primary);
  padding-top: 13px;
  ${Typography.body_md}
  a {
    ${Typography.body_md}
    color: var(--brand-primary);
    cursor: pointer;
    padding-left: 5px;
    text-decoration: none;
  }
  &.empty-state {
    text-align: center;
    padding-top: 12px;
  }
`;
export {
  Header,
  Subscriptiongroup,
  Personaltext,
  Content,
  First,
  Details,
  Icons,
  Heading,
  Description,
  Icon1,
  Icon2,
  Emptystateapi,
  Apilink
};
