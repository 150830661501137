import { Component } from 'react';
import { randomColorsArray } from '../../global/row';
import { isEmpty } from '../../helpers/common';
import { TextWrapper, UserLogo, UserLogoWrapper } from './styles';

interface Props {
  imgSrc: string;
  size?: number;
  name: string;
  email?: string;
  isShowBorder?: boolean;
  isThinBorder?: boolean;
  isMediumBorder?: boolean;
  isNotBorder?: boolean;
  isMargin?: boolean;
  isMilestoneBorder?: boolean;
  classname?: string;
  isZindex?: boolean;
}

class Avatar extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  getAvtarName = (name: string) => {
    if (isEmpty(name)) {
      return '#';
    }
    let splitedName = name.trim().split(' ');
    if (splitedName.length === 1) {
      return this.getChar(name);
    } else {
      if (splitedName[1].trim() === '') {
        return this.getChar(name);
      } else {
        return this.getChar(splitedName[0]) + this.getChar(splitedName[1]);
      }
    }
  };

  getChar = (value: string) => {
    return value.charAt(0).toUpperCase();
  };

  getAsciiChar = (name: string) => {
    return this.getChar(name).charCodeAt(0);
  };

  getColorIndex = (name: string) => {
    const asciiValue = this.getAsciiChar(name);
    const colorIndex = (asciiValue - 64) % (randomColorsArray.length - 1);
    return colorIndex;
  };

  render() {
    const props: Props = this.props;
    const {
      imgSrc,
      size,
      name,
      isShowBorder = false,
      isThinBorder = false,
      isMediumBorder = false,
      isNotBorder = false,
      isMargin = false,
      isMilestoneBorder = false,
      isZindex = false,
      classname
    } = props;
    const avtarName = this.getAvtarName(name);
    const colorIndex = this.getColorIndex(avtarName);
    const randColor = randomColorsArray[colorIndex];
    return (
      <UserLogoWrapper>
        {imgSrc ? (
          <UserLogo
            size={size}
            src={imgSrc}
            isShowBorder={isShowBorder}
            isThinBorder={isThinBorder}
            isMediumBorder={isMediumBorder}
            isNotBorder={isNotBorder}
            isMargin={isMargin}
            isMilestoneBorder={isMilestoneBorder}
            className={classname}
            isZindex={isZindex}
          />
        ) : (
          <TextWrapper
            size={size}
            randColor={randColor}
            isShowBorder={isShowBorder}
            isThinBorder={isThinBorder}
            isMediumBorder={isMediumBorder}
            isNotBorder={isNotBorder}
            isMargin={isMargin}
            isMilestoneBorder={isMilestoneBorder}
            className={classname}
            isZindex={isZindex}>
            <span>{avtarName}</span>
          </TextWrapper>
        )}
      </UserLogoWrapper>
    );
  }
}

export default Avatar;
