import { useCallback, useEffect, useState } from 'react';
import AppLayout from '../../component/appLayout';
import {
  Empty,
  Emptyblock,
  HeaderTitle,
  Heading,
  Icon,
  Leftside,
  Main,
  ResponsivenavbarIcon,
  Rightside,
  Snapheader,
  Snaps
} from './styles';
import SVGIcon from '../../assets/images/svg/SVGIcon';
import Button from '../../component/button';
import EmptyState from '../../component/emptyState';
import snaprecordempty from '../../../src/assets/images/emptystates/snaprecordempty.svg';
import { useSelector, useDispatch } from 'react-redux';
import { RootReducerInterface } from '../../interfaces/RootReducerInterface';
import darksnaprecordempty from '../../../src/assets/images/emptystates/darksnaprecordempty.svg';
import SnapRecordModal from '../../component/models/snapRecordModal';
import SnapcardView from '../../component/snapCardView';
import ModalCustom from '../../component/models/modal';
import { getSnapListByUser } from '../../services/snapService';
import UserPreferenceSingleton from '../../helpers/userPreferenceSingleton';
import { calculateDueTime, isMediaRecordingSupported } from '../../helpers/common';
import Responsivnavbar from '../../component/navbar/responsivenavbar';

export default function Snap() {
  // States
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { settings: settingsSelector, snap: snapSelector } = stateSelector || {};
  const { themeMode } = settingsSelector;
  const { snapList } = snapSelector;
  // Other variables
  const dispatch = useDispatch();
  const currentUser = UserPreferenceSingleton.getInstance().getCurrentUser();

  const getImageSource = () => (themeMode?.theme === 'dark' ? darksnaprecordempty : snaprecordempty);

  const loadData = useCallback(async () => {
    await dispatch(getSnapListByUser());
  }, [dispatch]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <AppLayout>
        <Main>
          <Snapheader>
            <HeaderTitle>
              <ResponsivenavbarIcon>
                <Responsivnavbar />
              </ResponsivenavbarIcon>
              <Leftside>
                <Icon>
                  <SVGIcon name='snap-large-icon' width='28' height='28' viewBox='0 0 28 28' />
                </Icon>
                <Heading>Snap</Heading>
              </Leftside>
            </HeaderTitle>
            {snapList?.length !== 0 && (
              <Rightside>
                <Button
                  title='New Snap'
                  iconName='plus-icon'
                  type='button'
                  iconSize={18}
                  iconViewBox='0 0 18 18'
                  iconColor='#fff'
                  onClick={handleOpenModal}
                />
              </Rightside>
            )}
          </Snapheader>

          {snapList?.length <= 0 && (
            <Emptyblock>
              <Empty>
                <EmptyState
                  image={getImageSource()}
                  header='You have no snap'
                  title='You can Create New snap by clicking the button below'
                  name='New Snap'
                  onButtonClick={handleOpenModal}
                />
              </Empty>
            </Emptyblock>
          )}

          <Snaps>
            {snapList?.map((item) => {
              let dueResult: { dueTime?: string; dueParam?: string } | undefined;
              if (item?.CreatedTime) {
                dueResult = calculateDueTime(item?.CreatedTime);
              }
              return (
                <SnapcardView
                  key={item['_id']}
                  time={`${dueResult?.dueTime} by ${currentUser?.given_name}`}
                  loadData={loadData}
                  snapData={item}
                  selectedItem={selectedItem}
                  setSelectedItem={setSelectedItem}
                />
              );
            })}
          </Snaps>
        </Main>
        {isMediaRecordingSupported() && (
          <ModalCustom open={isModalOpen} onClose={handleCloseModal} width={258} isZindex={true}>
            <SnapRecordModal handleCloseModal={handleCloseModal} />
          </ModalCustom>
        )}
      </AppLayout>
    </>
  );
}
