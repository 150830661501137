import { ActionCreatorWithOptionalPayload, ActionCreatorWithPayload, createAction, Dispatch } from '@reduxjs/toolkit';
import * as Actions from './types';
import { ReducerInterface } from '../interfaces/ReducerInterface';
import {
  SetCurrentUserInterface,
  InviteInputInterface,
  PasswordDetailInterface,
  UserProfileDataInterface
} from '../interfaces/AuthInterface';

/**
 * @desc Set Auth Loader
 */
export const setAuthLoader: ActionCreatorWithPayload<boolean, string> = createAction(Actions.SET_AUTH_LOADER);
/**
 * @desc Set Current User
 */
export const setCurrentUser: ActionCreatorWithPayload<SetCurrentUserInterface | null, string> = createAction(
  Actions.SET_CURRENT_USER
);
/**
 * @desc Set Auth Validation Error
 */
export const setAuthValidationError: ActionCreatorWithPayload<string | null, string> = createAction(
  Actions.SET_AUTH_VALIDATION_ERRORS
);

/**
 * @desc Set Password Detail
 */
export const setPasswordDetail: ActionCreatorWithPayload<PasswordDetailInterface, string> = createAction(
  Actions.SET_PASSWORD_DETAIL
);

/**
 * @desc Update Password Detail
 */
export const updatePasswordDetail: ActionCreatorWithPayload<ReducerInterface, string> = createAction(
  Actions.UPDATE_PASSWORD_DETAIL
);

/**
 * @desc Clear Password Detail
 */
export const clearPasswordDetail: ActionCreatorWithOptionalPayload<undefined, string> = createAction(
  Actions.CLEAR_PASSWORD_DETAIL
);

/**
 * @desc Set Invite Input
 */
export const setInviteInput: ActionCreatorWithPayload<InviteInputInterface, string> = createAction(
  Actions.SET_INVITE_INPUT
);

/**
 * @desc Update Invite Input
 */
export const updateInviteInput: ActionCreatorWithPayload<ReducerInterface, string> = createAction(
  Actions.UPDATE_INVITE_INPUT
);

/**
 * @desc Clear Invite Input
 */
export const clearInviteInput: ActionCreatorWithOptionalPayload<undefined, string> = createAction(
  Actions.CLEAR_INVITE_INPUT
);

/**
 * @desc Set User details
 */
export const setUserProfileData: ActionCreatorWithPayload<UserProfileDataInterface, string> = createAction(
  Actions.SET_USER_PROFILE_DATA
);

/**
 * @desc Clear Auth Data
 */
export const clearAuthData = () => (dispatch: Dispatch) => {
  dispatch(setCurrentUser(null));
  dispatch(setAuthLoader(false));
  dispatch(setAuthValidationError(null));
  dispatch(clearInviteInput());
};
