import styled, { css } from 'styled-components';
import { Typography } from '../../../global/theme';
interface Props {
  isActive: boolean;
}

const NavbarMain = styled.div`
  max-width: 210px;
  width: 100%;
  position: relative;
  overflow: auto;
  /* height: 100vh; */
  background-color: var(--background-primary);
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 449px) {
    max-width: 100%;
    height: 100dvh;
  }
`;

const Backbutton = styled.div`
  display: inline-flex;
  align-items: center;
  margin-top: 20px;
  padding: 7px 16px;
  cursor: pointer;
  p {
    ${Typography.body_lg_medium}
    color: var(--text-primary);
    margin: 0px 0px 0px 8px;
  }
  .fill-color {
    stroke: var(--text-primary);
    margin-bottom: 1px;
  }
`;
const MenuSection = styled.div`
  margin-top: 18px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 449px) {
    margin-top: 8px;
    overflow: auto;
  }
`;
const Menu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const Title = styled.div`
  display: flex;
  align-items: center;
  ${Typography.body_md_semibold}
  padding: 0 20px;
  padding-bottom: 8px;
  p {
    margin: 0 10px 0 0;
    color: var(--text-secondary);
  }
  .stop-color {
    stop {
      stop-color: var(--neutral-primary);
    }
  }
`;
const Submenu = styled.div<Props>`
  padding: 7px 20px;
  ${Typography.body_md_medium}
  color: var(--text-primary);
  :hover {
    background: var(--neutral-secondary);
    cursor: pointer;
  }
  ${(props: Props) =>
    props.isActive &&
    css`
      color: var(--brand-primary);
    `}
  .pointer {
    visibility: hidden;
  }
  ${(props: Props) =>
    props.isActive &&
    css`
      .pointer {
        visibility: visible;
      }
    `}
`;

const IconView = styled.div`
  position: absolute;
  left: -2px;
  @media (max-width: 449px) {
    left: 0;
  }
`;
const Div = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  &.bottom-div {
    margin-bottom: 24px;
    @media (max-width: 449px) {
      margin-bottom: 64px;
    }
  }
`;

const ResponsiveHeading = styled.div`
  display: none;
  @media (max-width: 449px) {
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    gap: 12px;
    background-color: var(--background-primary);
    padding: 16px;
    p {
      ${Typography.heading_lg};
      color: var(--text-primary);
      margin: 0;
    }
    .stroke-color {
      path {
        stroke: var(--text-primary);
      }
    }
  }
`;

const TitleBorder = styled.div`
  width: 100%;
  border-bottom: 0.5px solid var(--neutral-primary);
`;

export { NavbarMain, Backbutton, Menu, Title, Submenu, IconView, Div, ResponsiveHeading, TitleBorder, MenuSection };
