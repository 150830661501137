import styled, { css } from 'styled-components';
import { Typography } from '../../../global/theme';

interface Props {
  color?: string;
  isActive?: boolean;
}

const Icon = styled.div`
  display: inline-flex;
  cursor: pointer;
  .svgicon {
    stroke: var(--text-primary);
    fill: var(--text-primary);
  }
  :hover {
    background-color: var(--neutral-secondary);
    border-radius: 4px;
  }
`;

const Detail = styled.div`
  cursor: pointer;
`;
const Modalhead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px 16px 16px;
  @media (max-width: 449px) {
    padding: 12px 16px 16px 11px;
    gap: 20px;
  }
`;

const Headline = styled.h6`
  ${Typography.body_md}
  margin: 0 0 0 -5px;
  padding: 1px 5px;
  text-align: left;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  word-break: break-all;
  overflow: hidden;
  border-radius: 4px;
  color: var(--text-primary);
  :hover {
    cursor: pointer;
    background: var(--neutral-secondary);
  }
  @media (max-width: 449px) {
    line-height: 16px;
  }
`;
const Modalheadleft = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  .dot-icon {
    fill: var(--text-placeholder);
  }
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 0 16px;
  @media (max-width: 449px) {
    gap: 16px;
  }
`;
const Textfied = styled.div`
  width: 100%;
  .taskarea {
    ${Typography.heading_lg}
    color: var(--text-primary);
    margin: 0px;
    border: none;
    background-color: var(--background-primary);
    border-radius: 4px;
    padding: 0;
    width: 648px;
    max-width: 100%;
    ::-webkit-scrollbar {
      display: none;
    }
    ::placeholder {
      ${Typography.heading_lg}
      color: var(--text-primary);
    }
    :focus {
      outline: none;
      z-index: 9999;
      box-shadow: 0 0 0 2px rgba(0, 0, 0, 00);
      border-inline-end-width: 1px;
      outline: 0;
    }
    a {
      color: var(--text-secondary);
      text-decoration: underline;
    }
  }
  @media (max-width: 449px) {
    .taskarea {
      width: 100%;
    }
  }
`;

const Textarea = styled.div`
  width: 100%;
  position: relative;
  .taskarea {
    ${Typography.body_md}
    color: var(--text-primary);
    border: none;
    background-color: var(--text-white);
    border-radius: 4px;
    padding: 2px 0;
    font-family: unset;
    width: 100%;
    min-width: 648px;
    max-width: 100%;
    ::-webkit-scrollbar {
      display: none;
    }
    ::placeholder {
      ${Typography.body_md}
      color: var(--text-secondary);
    }
    :focus {
      outline: none;
      z-index: 9999;
      box-shadow: 0 0 0 2px rgba(0, 0, 0, 00);
      border-inline-end-width: 1px;
      outline: 0;
    }
    a {
      color: var(--text-link);
      text-decoration: underline;
    }
  }
  img {
    max-width: 100%;
    max-height: 50%;
  }
`;
const Btns = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 24px 16px 16px;
  gap: 32px;
  @media (max-width: 449px) {
    gap: 12px;
    flex-direction: column;
    padding: 12px 16px 16px;
  }
`;

const ButtonDiv = styled.div<Props>`
  width: max-content;
  display: flex;
  gap: 4px;
  @media (max-width: 449px) {
    width: 100%;
    justify-content: space-between;
  }
  .ant-dropdown .ant-dropdown-menu {
    background-color: var(--background-primary);
    border: 0.5px solid var(--border-primary);
    box-shadow: var(--shadow-modal);
    width: 130px;
    padding: 4px 0 !important;
    background-clip: unset;
  }
  .active {
    background: var(--neutral-secondary);
    border-radius: 50%;
    .svg {
      path {
        fill: var(--text-primary);
      }
    }
  }
`;

const Uploaddiv = styled.div<Props>`
  padding: 0 16px;
  color: var(--text-primary);
`;

const AttachFile = styled.div<Props>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  position: relative;
  border: 0.5px solid transparent;
  .svg {
    path {
      fill: var(--text-secondary);
    }
  }
  :hover {
    background: var(--neutral-secondary);
    border-radius: 50%;
    .svg {
      path {
        fill: var(--text-primary);
      }
    }
  }
  ${(props) =>
    props.isActive &&
    css`
      background: var(--neutral-secondary);
      border-radius: 50%;
      .svg {
        path {
          fill: var(--text-primary);
        }
      }
    `}
`;

const DocumentIcon = styled.div<Props>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  position: relative;
  border: 0.5px solid transparent;
  .template-doc-icon {
    path {
      stroke: var(--text-secondary);
    }
  }
  :hover {
    background: var(--neutral-secondary);
    border-radius: 50%;
    .template-doc-icon {
      path {
        stroke: var(--text-primary);
      }
    }
  }
  ${(props) =>
    props.isActive &&
    css`
      background: var(--neutral-secondary);
      border-radius: 50%;
      .template-doc-icon {
        path {
          stroke: var(--text-primary);
        }
      }
    `}
`;

const Icons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: 449px) {
    max-width: 100%;
    width: 100%;
    padding-bottom: 12px;
    border-bottom: 0.5px solid var(--border-primary);
  }
`;

const DescriptionSection = styled.div``;

const OverLayDiv = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const Modalheader = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
  position: relative;
  .dot-icon {
    fill: var(--text-placeholder);
  }
`;
const Headtitle = styled.div`
  ${Typography.body_md}
  padding: 1px 5px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  word-break: break-all;
  overflow: hidden;
  border-radius: 4px;
  color: var(--text-primary);
  position: relative;
  :hover {
    cursor: pointer;
    background: var(--neutral-secondary);
  }
`;

const Dropheader = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  padding: 6px 14px 6px 10px;
`;

const Inputarea = styled.input`
  max-width: 100%;
  width: 100px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  ${Typography.body_md}
  color: var(--text-primary);
  background-color: var(--background-primary);
  margin: 0;
  padding: 0;
  border: none;

  :focus {
    outline: none;
  }
  ::placeholder {
    color: var(--text-secondary);
  }
`;

const MenuItem = styled.div`
  max-height: 146px;
  overflow: auto;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  gap: 1px;
`;

export {
  Btns,
  Headline,
  Icon,
  Icons,
  Modalhead,
  Modalheadleft,
  Textarea,
  Textfied,
  Inputs,
  Detail,
  DescriptionSection,
  OverLayDiv,
  Modalheader,
  Headtitle,
  ButtonDiv,
  Uploaddiv,
  AttachFile,
  DocumentIcon,
  Dropheader,
  Inputarea,
  MenuItem
};
