import { RawNotification } from '../interfaces/notification';
import { makeAutoObservable } from 'mobx';
import { rootStore } from '../rootStore';

export class NotificationModel {
  // Common
  id: string;
  title: string;
  description: string;
  type: number;
  date: Date;
  isRead: boolean;
  createdTime: Date;

  // Relational
  projectId?: string;
  companyId?: string;
  taskId?: string;

  userId: string;
  senderId: string;

  constructor(rawNotification: RawNotification) {
    makeAutoObservable(this);

    this.id = rawNotification.id;
    this.description = rawNotification.description;
    this.date = new Date(rawNotification.date);
    this.isRead = rawNotification.isRead;
    this.projectId = rawNotification.referenceId;
    this.companyId = rawNotification.referenceId2;
    this.taskId = rawNotification.referenceId3;
    this.title = rawNotification.title;
    this.type = rawNotification.type;
    this.userId = rawNotification.userId;
    this.senderId = rawNotification.createdBy;
    this.createdTime = new Date(rawNotification.createdTime);
  }

  get user() {
    return rootStore.workspaceStore.currentWorkspace?.users.find((user) => user?.id === this.senderId);
  }

  markAsRead() {
    this.isRead = true;
  }
}

export const createObservableNotification = (rawNotification: RawNotification) => {
  return new NotificationModel(rawNotification);
};
