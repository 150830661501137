import styled, { keyframes } from 'styled-components';
import { Typography } from '../../../global/theme';

const ModelBox = styled.div`
  max-width: 460px;
  width: 100%;
  border-radius: 12px;
  background-color: var(--background-primary);
  display: flex;
  flex-direction: column;
`;
const ModelHeader = styled.div`
  padding: 16px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border-bottom: 0.5px solid var(--border-primary);
`;
const Icon = styled.div`
  width: 18px;
  height: 18px;
  cursor: pointer;
  border-radius: 4px;
  &:hover {
    background-color: var(--neutral-secondary);
  }
  .close-icon {
    stroke: var(--text-primary);
  }
`;
const SearchInput = styled.input`
  border: none;
  background-color: var(--background-primary);
  color: var(--text-primary);
  padding: 0;
  ${Typography.body_lg};
  font-family: 'Inter';
  width: 100%;
  ::placeholder {
    color: var(--text-secondary);
  }
  :focus {
    outline: none;
  }
`;
const ProjectList = styled.div`
  padding: 12px 12px 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const Title = styled.div`
  padding-left: 8px;
  color: var(--text-secondary);
  ${Typography.body_sm_medium}
`;
const Items = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: auto;
  max-height: 256px;
  scrollbar-width: none;
`;
const ListItem = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-radius: 8px;
  &:hover {
    background-color: var(--neutral-secondary);
  }
  p {
    margin: 0;
    color: var(--text-primary);
    ${Typography.body_md}
  }
`;
const LockIcon = styled.div`
  width: 16px;
  height: 16px;
  .lock-icon {
    fill: var(--text-primary);
  }
`;
const Box = styled.div`
  min-height: 280px;
  margin: auto;
  display: flex;
`;
const Text = styled.div`
  color: var(--text-tertiary);
  ${Typography.body_lg_medium};
`;
const LoadingDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const ball = keyframes`
  from {
    -webkit-transform: translateY(0) scaleY(0.8);
    transform: translateY(0) scaleY(0.8);
  }
  to {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
`;
const Loading = styled.div``;
const LoadingWrap = styled.div`
  position: relative;
  display: inline-block;
  width: 36px;
  height: 36px;
  &::before {
    position: absolute;
    top: 50%;
    left: calc(50% - 2px);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: var(--brand-primary);
    z-index: 2;
    margin-top: 4px;
    -webkit-animation: 0.45s cubic-bezier(0, 0, 0.15, 1) infinite alternate ${ball};
    animation: 0.45s cubic-bezier(0, 0, 0.15, 1) infinite alternate ${ball};
    -webkit-animation-delay: 0.15s;
    animation-delay: 0.15s;
  }
  ${Loading} {
    cursor: wait;
    color: transparent;
    &:hover {
      cursor: wait;
    }
    &::before {
      position: absolute;
      top: 50%;
      left: calc(50% - 2px);
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: var(--brand-primary);
      z-index: 2;
      margin-top: 4px;
      -webkit-animation: 0.45s cubic-bezier(0, 0, 0.15, 1) infinite alternate ${ball};
      animation: 0.45s cubic-bezier(0, 0, 0.15, 1) infinite alternate ${ball};
      margin-left: -12px;
    }
    &::after {
      position: absolute;
      top: 50%;
      left: calc(50% - 2px);
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: var(--brand-primary);
      z-index: 2;
      margin-top: 4px;
      -webkit-animation: 0.45s cubic-bezier(0, 0, 0.15, 1) infinite alternate ${ball};
      animation: 0.45s cubic-bezier(0, 0, 0.15, 1) infinite alternate ${ball};
      margin-left: 12px;
      -webkit-animation-delay: 0.3s;
      animation-delay: 0.3s;
    }
  }
`;

export {
  ModelBox,
  ModelHeader,
  Icon,
  SearchInput,
  ProjectList,
  Title,
  Items,
  ListItem,
  LockIcon,
  Box,
  Text,
  LoadingDiv,
  LoadingWrap,
  ball,
  Loading
};
