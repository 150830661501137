import { CHAT_GROUP_TYPE } from '../global/constants';
import { htmlToPlainText, isEmpty } from '../helpers/common';
import UserPreferenceSingleton from '../helpers/userPreferenceSingleton';
import { ChatGroupInterface, MessageDetailsInterface } from '../interfaces/chatMessageInterface';
import ChatMessageCacheService from './chatMessageCacheService';
import LocalDbService, { MESSAGE_GROUP_TABLE_NAME, UPDATE_ON_ID } from './localDbService';

let singleton: any;
let organizationId: any;

export default class MessageGroupCacheService extends LocalDbService {
  constructor(props: any) {
    const db_name = `${props.organizationId}`;
    props.dbName = db_name;
    props.tableName = MESSAGE_GROUP_TABLE_NAME;
    super(props);
  }

  static getInstance() {
    const company = UserPreferenceSingleton.getInstance().getWorkspace();
    if (company) {
      if (!singleton || organizationId !== company?.id) {
        organizationId = company?.id;
        singleton = new MessageGroupCacheService({ organizationId: company?.id });
      }
    }
    return singleton;
  }

  static removeInstance() {
    if (singleton) singleton.closeDb();
    organizationId = undefined;
    singleton = undefined;
  }

  async getMessageGroups() {
    if (this.db) {
      const rows = await this.getDb().toArray();
      const result: ChatGroupInterface[] = rows.map((x: any) => x.value).filter((x: any) => x);
      const currentUser = UserPreferenceSingleton.getInstance().getCurrentUser();
      let updatedGroupsPromises = result?.map(async (group) => {
        if (isEmpty(group?.name)) {
          const groupName = group?.users
            ?.filter((user) => user && user?.id !== currentUser?.id)
            ?.map((user) => user?.name)
            ?.join(', ');
          group.name = groupName || '';
        }
        let messages: MessageDetailsInterface[];
        if (group?.type === CHAT_GROUP_TYPE.PROJECT)
          messages = await ChatMessageCacheService.getInstance()?.getProjectMessages(group?.id);
        else messages = await ChatMessageCacheService.getInstance()?.getGroupMessages(group?.id);
        const lastMessage = messages?.length > 0 ? messages[messages?.length - 1] : undefined;
        let lastMessageText;
        if (currentUser?.id === lastMessage?.sender?.id && lastMessage) {
          lastMessageText = `You: ${
            lastMessage?.uploadedFile?.length > 0 ? 'sent a file' : htmlToPlainText(lastMessage?.message)
          }`;
        } else if (lastMessage) {
          lastMessageText = `${lastMessage?.sender?.name}: ${
            lastMessage?.uploadedFile?.length > 0 ? 'sent a file' : htmlToPlainText(lastMessage?.message)
          }`;
        }
        return {
          ...group,
          messages,
          lastMessageTime: lastMessage?.createdOn,
          lastMessageText,
          hasUnreadMessages:
            lastMessage?.createdOn && lastMessage?.sender?.id !== currentUser?.id
              ? new Date(group?.lastSeen?.[currentUser?.id] || '2025-01-13T11:50:23.196Z') <
                new Date(lastMessage?.createdOn)
              : false
        };
      });
      let updatedGroups = await Promise.all(updatedGroupsPromises);
      const ref = updatedGroups.toSorted((a, b) => {
        if (!a.lastMessageTime && !b.lastMessageTime) return 0;
        if (!a.lastMessageTime) return 1;
        if (!b.lastMessageTime) return -1;
        const dateA = new Date(a.lastMessageTime);
        const dateB = new Date(b.lastMessageTime);
        if (dateA < dateB) return 1;
        if (dateA > dateB) return -1;
        return 0;
      });
      return ref;
    }
    return [];
  }

  async setMessageGroups(value: any) {
    if (!value) return false;
    await this.getDb().clear();
    const result = await this.addBulk(value);
    return result;
  }

  async updateMessageGroups(data: any, archived: any, workspace_id: string) {
    if (this.db) {
      if (data && data.length > 0) {
        await this.addBulk(data);
      }
      if (archived && archived.length > 0) {
        await this.removeBulk(archived);
      }
    }
  }

  async getLastUpdatedTime(workspace_id?: string) {
    const result = await super.getLastUpdatedTime(`${UPDATE_ON_ID.MESSAGE_GROUP}_${workspace_id}`);
    return result;
  }

  async setLastUpdatedTime(value: any, companyId: string) {
    if (companyId !== organizationId) return false;
    const result = await super.setLastUpdatedTime(`${UPDATE_ON_ID.MESSAGE_GROUP}_${companyId}`, value);
    return result;
  }
}
