import styled from 'styled-components';

const DropdownMenuDiv = styled.div`

  .ant-menu-vertical .ant-menu-item {
    height: unset;
    margin: 0;
    width: 100%;
    border-radius: 0;
  }
  .ant-menu-light.ant-menu-root.ant-menu-vertical {
    padding: 5px 0;
    max-height: 206px;
    height: 100%;
    overflow: auto;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  .ant-menu-light.ant-menu-root.ant-menu-vertical {
    border-inline-end: none;
  }
`;

export { DropdownMenuDiv };
