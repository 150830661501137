import React, { useEffect, useMemo } from 'react';
import { Bottom, Buttondiv, Header, Main, Text, Top } from './styles';
import Button from '../../button';
import { useMediaRecorder } from '../../useMediaRecorder/useMediaRecorder';
import { setScreenRecordingUrl } from '../../../actions/appActions';
import { useDispatch } from 'react-redux';
import { isEmpty } from '../../../helpers/common';
import { Dropdown, DropdownItem } from '../../Dropdown';
import SVGIcon from '../../../assets/images/svg/SVGIcon';

interface SnapRecordModalProps {
  handleCloseModal: () => void;
}

const SnapRecordModal: React.FC<SnapRecordModalProps> = ({ handleCloseModal }) => {
  //Other variable
  const { startRecording, audioInputDevices, setSelectedAudioDeviceId, selectedAudioDeviceLabel, updateDevices } =
    useMediaRecorder();
  const dispatch = useDispatch();

  const requestMicrophonePermission = async () => {
    try {
      const permissionStatus = await navigator.permissions.query({ name: 'microphone' as PermissionName });
      if (permissionStatus.state === 'prompt') {
        await navigator.mediaDevices.getUserMedia({ audio: true });
        updateDevices();
      }
    } catch (error) {
      console.error('Error requesting microphone permission:', error);
    }
  };

  useEffect(() => {
    requestMicrophonePermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnClick = () => {
    startRecording();
    handleCloseModal();
    dispatch(setScreenRecordingUrl(''));
  };

  const renderSelectMicrophone = useMemo(() => {
    const handleMenuClick = (deviceId: string) => {
      setSelectedAudioDeviceId(deviceId);
    };
    return (
      <>
        <DropdownItem label='No Microphone' onClick={() => handleMenuClick('none')} />
        {audioInputDevices.length > 0 &&
          audioInputDevices?.map((device) => (
            <DropdownItem
              key={device.deviceId}
              onClick={() => handleMenuClick(device?.deviceId)}
              label={!isEmpty(device.label) ? device.label : `Microphone ${device.deviceId}`}
            />
          ))}
      </>
    );
  }, [audioInputDevices, setSelectedAudioDeviceId]);

  return (
    <>
      <Main>
        <Header>Select Microphone</Header>
        <Bottom>
          <Dropdown
            activeClassName='active'
            isMinWidth={250}
            content={
              <Top>
                <SVGIcon
                  name='microphone-icon'
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  className='mic-stroke-color'
                />
                <Text>{selectedAudioDeviceLabel}</Text>
              </Top>
            }
            trigger='click'>
            <>{renderSelectMicrophone}</>
          </Dropdown>

          <Buttondiv>
            {/* <Button title='Start Recording' isMicmodalbtn /> */}
            <Button title='Start Recording' isMicmodalbtn onClick={handleOnClick} modelbtn={true} />
          </Buttondiv>
        </Bottom>
      </Main>
    </>
  );
};
export default SnapRecordModal;
