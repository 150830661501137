import React from 'react';
import {
  Bottom,
  Div,
  H1,
  H2,
  H3,
  H4,
  Last,
  Middle,
  Nameloading,
  Numberloading,
  Table,
  TableDetail,
  TableHeader,
  Tabledata,
  Top,
  UserName
} from './styles';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
const InvoiceLoading: React.FC = () => {
  return (
    <>
      <Table>
        <TableHeader>
          <UserName>
            <H1 className='loading-animation' />
          </UserName>
          <Tabledata>
            <Div className='first-column'>
              <H2 className='loading-animation' />
            </Div>
            <Div className='second-column'>
              <H2 className='loading-animation' />
            </Div>
            <Div className='third-column'>
              <H3 className='loading-animation' />
            </Div>
            <Div className='fourth-column'>
              <H4 className='loading-animation' />
            </Div>
            <Div className='last-column'/>
          </Tabledata>
        </TableHeader>
        <TableDetail>
          <Nameloading>
            <Top className='loading-animation'></Top>
            <Bottom className='loading-animation'></Bottom>
          </Nameloading>
          <Numberloading>
            <Middle className='loading-animation'></Middle>
          </Numberloading>
          <Nameloading className='third'>
            <Top className='loading-animation third-top'></Top>
            <Bottom className='loading-animation  third-bottom'></Bottom>
          </Nameloading>
          <Nameloading className='forth'>
            <Top className='loading-animation forth-top'></Top>
            <Bottom className='loading-animation forth-bottom'></Bottom>
          </Nameloading>
          <Numberloading className='number-last'>
            <Middle className='loading-animation last'></Middle>
          </Numberloading>
          <Last>
            <SVGIcon name='more-icon' width='18' height='18' viewBox='0 0 18 18' fill='none' className='more-icon' />
          </Last>
        </TableDetail>
        <TableDetail>
          <Nameloading>
            <Top className='loading-animation'></Top>
            <Bottom className='loading-animation'></Bottom>
          </Nameloading>
          <Numberloading>
            <Middle className='loading-animation'></Middle>
          </Numberloading>
          <Nameloading className='third'>
            <Top className='loading-animation third-top'></Top>
            <Bottom className='loading-animation third-bottom'></Bottom>
          </Nameloading>
          <Nameloading className='forth'>
            <Top className='loading-animation forth-top'></Top>
            <Bottom className='loading-animation forth-bottom'></Bottom>
          </Nameloading>
          <Numberloading className='number-last'>
            <Middle className='loading-animation last'></Middle>
          </Numberloading>
          <Last>
            <SVGIcon name='more-icon' width='18' height='18' viewBox='0 0 18 18' fill='none' className='more-icon' />
          </Last>
        </TableDetail>
        <TableDetail>
          <Nameloading>
            <Top className='loading-animation'></Top>
            <Bottom className='loading-animation'></Bottom>
          </Nameloading>
          <Numberloading>
            <Middle className='loading-animation'></Middle>
          </Numberloading>
          <Nameloading className='third'>
            <Top className='loading-animation third-top'></Top>
            <Bottom className='loading-animation third-bottom'></Bottom>
          </Nameloading>
          <Nameloading className='forth'>
            <Top className='loading-animation forth-top'></Top>
            <Bottom className='loading-animation  forth-bottom'></Bottom>
          </Nameloading>
          <Numberloading className='number-last'>
            <Middle className='loading-animation last'></Middle>
          </Numberloading>
          <Last>
            <SVGIcon name='more-icon' width='18' height='18' viewBox='0 0 18 18' fill='none' className='more-icon' />
          </Last>
        </TableDetail>
        <TableDetail>
          <Nameloading>
            <Top className='loading-animation'></Top>
            <Bottom className='loading-animation'></Bottom>
          </Nameloading>
          <Numberloading>
            <Middle className='loading-animation'></Middle>
          </Numberloading>
          <Nameloading className='third'>
            <Top className='loading-animation third-top'></Top>
            <Bottom className='loading-animation third-bottom'></Bottom>
          </Nameloading>
          <Nameloading className='forth'>
            <Top className='loading-animation forth-top'></Top>
            <Bottom className='loading-animation forth-bottom'></Bottom>
          </Nameloading>
          <Numberloading className='number-last'>
            <Middle className='loading-animation last'></Middle>
          </Numberloading>
          <Last>
            <SVGIcon name='more-icon' width='18' height='18' viewBox='0 0 18 18' fill='none' className='more-icon' />
          </Last>
        </TableDetail>
        <TableDetail>
          <Nameloading>
            <Top className='loading-animation'></Top>
            <Bottom className='loading-animation'></Bottom>
          </Nameloading>
          <Numberloading>
            <Middle className='loading-animation'></Middle>
          </Numberloading>
          <Nameloading className='third'>
            <Top className='loading-animation third-top'></Top>
            <Bottom className='loading-animation third-bottom'></Bottom>
          </Nameloading>
          <Nameloading className='forth'>
            <Top className='loading-animation forth-top'></Top>
            <Bottom className='loading-animation forth-bottom'></Bottom>
          </Nameloading>
          <Numberloading className='number-last'>
            <Middle className='loading-animation last'></Middle>
          </Numberloading>
          <Last>
            <SVGIcon name='more-icon' width='18' height='18' viewBox='0 0 18 18' fill='none' className='more-icon' />
          </Last>
        </TableDetail>
      </Table>
    </>
  );
};

export default InvoiceLoading;
