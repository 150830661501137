import React, { useCallback, useMemo, useState } from 'react';
import { LabelDetailsInterface, ProjectDetailInterface } from '../../../interfaces/ProjectInterface';
import { COMMENT_TYPE, LABEL_COLOUR_HASH } from '../../../global/constants';
import Dropdown from '../../Dropdown/MainDropdown';
import DropdownItem from '../../Dropdown/DropdownItem';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import { useSelector, useDispatch } from 'react-redux';
import { updateCreateTaskInput, updateMyTaskDetailsData, updateTaskField } from '../../../actions/taskActions';
import UserPreferenceSingleton from '../../../helpers/userPreferenceSingleton';
import { trackAnalyticActivity } from '../../../services/analyticsService';
import { TASK_ANALYTICS } from '../../../global/analyticsConstants';
import { nanoid } from 'nanoid';
import { createNewComment, updateTaskDetails } from '../../../services/taskServices';
import {
  BlankText,
  CheckBox,
  Coloricon,
  ContentDiv,
  Icondiv,
  IconMainDiv,
  LabelIcon,
  RightText,
  SvgIconDiv
} from './styles';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import { CreateTaskInputInterface, TaskDetailsInterface } from '../../../interfaces/TaskInterface';
import { ItemDiv } from '../../customContextMenu/styles';
import { isEmpty } from '../../../helpers/common';
interface Props {
  isCreateTask?: boolean;
  taskData: CreateTaskInputInterface | TaskDetailsInterface;
  currentProject: ProjectDetailInterface;
  isFromTaskDetails?: boolean;
  isContextMenu?: boolean;
}

export const LabelDropdown: React.FC<Props> = (props) => {
  const { taskData, isCreateTask, isContextMenu, isFromTaskDetails, currentProject } = props;

  //use selector state variables
  const taskSelector = useSelector((state: RootReducerInterface) => state.task);
  const { taskDetails } = taskSelector;
  const dispatch = useDispatch();
  const [searchResult, setSearchResult] = useState<any[]>(currentProject.labelsList || []); // Default to labelsList

  // logic for manage label click
  const onClickLabel = useCallback(
    async (item: LabelDetailsInterface) => {
      // Check if the label is being updated from a general task view or specific task details view
      if (isCreateTask) {
        let labelsClone = JSON.parse(JSON.stringify(taskData.labels || []));
        const labelIndex = taskData?.labels?.findIndex((x) => x?.id === item?.id);

        if (labelIndex === -1) {
          labelsClone = [...(labelsClone || []), item];
        } else {
          labelsClone?.splice(labelIndex, 1);
        }
        // Update the labels in the task input using a Redux action
        dispatch(updateCreateTaskInput({ propsName: 'labels', value: labelsClone }));
        return;
      } else {
        // Logic for when the label is being updated from the task details view
        let updatedLabelsId;
        // Get the current user's details
        const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
        let currentTask;
        if (isContextMenu) {
          currentTask = taskData;
        } else {
          currentTask = taskDetails;
        }
        // Find the index of the label in the current task's labels
        const labelIndex = currentTask?.labels?.findIndex((x) => x?.id === item?.id);
        if (labelIndex !== -1) {
          // If the label exists, remove it from the list
          const ref = JSON.parse(JSON.stringify(currentTask?.labels || []));
          ref?.splice(labelIndex, 1);
          updatedLabelsId = ref;
        } else {
          // If the label doesn't exist, add it and track the analytics event
          trackAnalyticActivity(TASK_ANALYTICS.LABELS_ADDED);
          updatedLabelsId = [...(currentTask?.labels || []), item];
        }

        // Add a comment indicating the label change (add or remove)
        const updatedComments = [
          ...('updatedComments' in currentTask ? currentTask.updatedComments : []),
          {
            id: nanoid(),
            type: labelIndex !== -1 ? COMMENT_TYPE.REMOVE_LABEL : COMMENT_TYPE.ADD_LABEL,
            updatedBy: userDetails?.id,
            updatedTime: new Date().toISOString(),
            createdOn: new Date().toISOString(),
            user: { ...userDetails, name: userDetails?.given_name },
            group: item?.name
          }
        ];

        // Dispatch actions to update comments and labels in the task details state
        dispatch(updateMyTaskDetailsData({ propsName: 'updatedComments', value: updatedComments }));
        dispatch(updateMyTaskDetailsData({ propsName: 'labels', value: updatedLabelsId }));

        // Create a list of label IDs to update the backend
        const labelIdList = updatedLabelsId?.map((item: { id: string }) => {
          return item?.id;
        });
        const payloadTask = { labels: labelIdList, projectId: currentTask?.projectId };

        // Dispatch actions to update the task field and synchronize with the backend
        dispatch(
          updateTaskField({
            taskId: currentTask?.id!,
            key: 'labels',
            value: updatedLabelsId
          })
        );

        // Update the task details and add a new comment via asynchronous actions
        await dispatch(updateTaskDetails(currentTask?.id, payloadTask));
        await dispatch(
          createNewComment(currentTask?.id, {
            Type: labelIndex !== -1 ? COMMENT_TYPE.REMOVE_LABEL : COMMENT_TYPE.ADD_LABEL,
            Group: item?.name
          })
        );
      }
    },
    [isCreateTask, taskData, dispatch, isContextMenu, taskDetails]
  );

  // render label list
  const renderLabelList = useMemo(() => {
    return searchResult.map((labelData) => {
      const isChecked = taskData?.labels?.some((label: { id: string }) => label.id === labelData.id);

      return (
        <DropdownItem
          key={labelData?.name}
          label={labelData.name}
          isSelected={isChecked}
          onClick={(e) => {
            e?.stopPropagation();
            onClickLabel(labelData);
          }}>
          <CheckBox
            type='checkbox'
            checked={isChecked}
            onClick={(e) => {
              e.stopPropagation();
              onClickLabel(labelData);
            }}
          />
          <LabelIcon color={`${LABEL_COLOUR_HASH[labelData?.color]}`} />
        </DropdownItem>
      );
    });
  }, [searchResult, taskData?.labels, onClickLabel]);

  // display label information
  const renderSelectedLabel = useMemo(() => {
    return taskData?.labels?.map((item) => (
      <Coloricon key={item?.id}>
        <IconMainDiv>
          <LabelIcon color={`${LABEL_COLOUR_HASH[item?.color]}`} />
        </IconMainDiv>
        <RightText>{item?.name}</RightText>
      </Coloricon>
    ));
  }, [taskData?.labels]);

  const onChangeSearch = useCallback(
    (value: string) => {
      const result =
        currentProject.labelsList?.filter((item: any) => item?.name?.toLowerCase().includes(value?.toLowerCase())) ||
        [];
      if (isEmpty(value)) {
        setSearchResult(currentProject.labelsList); // Show all labels if search is empty
      } else {
        setSearchResult(result); // Filtered results
      }
    },
    [currentProject.labelsList]
  );

  return (
    <Dropdown
      isMinWidth={148}
      onChangeSearch={onChangeSearch}
      content={
        isContextMenu ? (
          <ItemDiv>
            <DropdownItem label='Label' iconName='label-icon' iconSize={16} iconTone='stroke' />
          </ItemDiv>
        ) : taskData?.labels?.length > 0 ? (
          <Icondiv isCreateTask={isCreateTask} isTaskDetail={isFromTaskDetails}>
            <SVGIcon name='label-icon' width='16' height='16' viewBox='0 0 16 16' stroke='var(--text-secondary)' />
            {renderSelectedLabel}
          </Icondiv>
        ) : (
          <SvgIconDiv isCreateTask={isCreateTask}>
            <SVGIcon name='label-icon' width='16' height='16' viewBox='0 0 16 16' />
            <BlankText isCreateTask={isCreateTask}>Add Label</BlankText>
          </SvgIconDiv>
        )
      }
      modalTitle='Labels'
      trigger={isContextMenu ? 'hover' : 'click'}>
      <ContentDiv>{renderLabelList}</ContentDiv>
    </Dropdown>
  );
};
