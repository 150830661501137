import { doc, getDoc, setDoc } from 'firebase/firestore';
import UserPreferenceSingleton from './userPreferenceSingleton';
import { database } from '../utils/firebase';
import { captureException } from '../services/logService';

export const updateUserPreference = async (data: any) => {
  const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
  const userPreferencesRef = doc(database, 'user_preferences', userDetails?.id);
  try {
    await setDoc(userPreferencesRef, data, { merge: true });
  } catch (error) {
    captureException(error);
  }
};

export const getUserPreferenceFieldData = async (field: any) => {
  const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
  if (!userDetails?.id) return undefined;

  const userPreferencesRef = doc(database, 'user_preferences', userDetails?.id);
  try {
    const docSnapshot = await getDoc(userPreferencesRef);
    if (docSnapshot.exists()) {
      const data = docSnapshot.data();
      const fieldValue = data[field];
      return fieldValue;
    } else {
      return undefined;
    }
  } catch (error) {
    captureException(error);
  }
};
