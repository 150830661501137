export const PROJECT_ANALYTICS = {
  DELETED: 'project: deleted',
  UPDATED: 'project: updated',
  CREATED: 'project: created',
  RESTORED: 'project: restore',
  TEMPLATE_UPDATED: 'project template: updated',
  TEMPLATE_DATA_UPDATED: 'project template: data updated',
  TEMPLATE_CREATED: 'project template: created',
  TEMPLATE_CREATE_CLICKED: 'project template: create clicked',
  MARKED_FAVORITE: 'project: marked favorite',
  MEMBER_ADDED: 'project: member added',
  MEMBER_REMOVED: 'project: member removed',
  GROUP_ADDED: 'project: group added',
  VIEW_SETTING: 'project: view settings',
  PRIORITY_ENABLED: 'project: priority enabled',
  PRIORITY_DISABLED: 'project: priority disabled',
  STATUS_ENABLED: 'project: status enabled',
  STATUS_DISABLED: 'project: status disabled',
  LABEL_ENABLED: 'project: labels enabled',
  LABEL_DISABLED: 'project: labels disabled',
  ESTIMATION_ENABLED: 'project: estimation enabled',
  ESTIMATION_DISABLED: 'project: estimation disabled',
  MILESTONE_ENABLED: 'project: mileStone enabled',
  MILESTONE_DISABLED: 'project: mileStone disabled',
  MULTI_ASSIGNEE_ENABLED: 'project: multiAssignee enabled',
  MULTI_ASSIGNEE_DISABLED: 'project: multiAssignee disabled',
  GROUP_ENABLED: 'project: group enabled',
  GROUP_DISABLED: 'project: group disabled'
};

export const TASK_ANALYTICS = {
  RESCHEDULED: 'task: re-scheduled',
  SCHEDULED: 'task: scheduled',
  VIEW: 'task: view',
  CREATED: 'task: created',
  UPDATED: 'task: updated',
  MARKED_DONE: 'task: marked done',
  REOPENED: 'task: re-opened',
  COMMENT_ADDED: 'task: comment added',
  COMMENT_DELETED: 'task: comment deleted',
  COMMENT_REACTED: 'task: reaction on comment',
  GROUP_CHANGED: 'task: group changed',
  PRIORITY_CHANGED: 'task: priority changed',
  STATUS_CHANGED: 'task: status changed',
  LABELS_ADDED: 'task: labels added',
  ESTIMATION_SET: 'task: estimation set',
  MILESTONE_SELECTED: 'task: milestone selected',
  MULTI_ASSIGNEE_UPDATED: 'task: multi assignee updated',
  REASSIGNED: 'task: re-assigned'
};

export const INVOICE_ANALYTICS = {
  VIEW_TIMELOG: 'invoice: view time-log configuration',
  SENT: 'invoice: sent',
  VIEW_LIST: 'invoice: view list',
  NEW_INVOICE_CLICKED: 'invoice: new invoice clicked',
  VIEW_DRAFT: 'invoice: view draft form',
  DELETED: 'invoice: deleted',
  CREATED: 'invoice: created',
  EDITED: 'invoice: edited',
  VIEW_DETAIL: 'invoice: view detail',
  PAYMENT_RECORDED: 'invoice: payment recorded',
  PUBLIC_BUTTON_CLICKED: 'invoice: public button clicked',
  PDF_BUTTON_CLICKED: 'invoice: pdf button clicked',
  PRINT_BUTTON_CLICKED: 'invoice: print button clicked '
};

export const MESSAGE_ANALYTICS = {
  SENT: 'message: sent',
  REMOVED: 'message: removed',
  MESSAGE_REACTED: 'message: reaction on message'
};

export const WORKSPACE_ANALYTICS = {
  USER_INVITED: 'workspace: user invited',
  UPDATED: 'workspace: updated',
  USER_REMOVED: 'workspace: user removed',
  CREATED: 'workspace: created'
};

export const VIEW_ANALYTICS = {
  LOGIN_PAGE: 'view: login page',
  SIGN_UP_PAGE: 'view: sign up page',
  ONBOARDING_STEP_1: 'view: onboarding step 1',
  ONBOARDING_STEP_2: 'view: onboarding step 2',
  ONBOARDING_WELCOME: 'view: onboarding welcome',
  SETTING_TEMPLATE: 'view: settings template view',
  MILESTONE_TASK: 'milestone task: view'
};

export const ACCOUNT_ANALYTICS = {
  SIGNED_IN: 'account: signed in',
  SIGNED_OUT: 'account: signed out',
  CREATED: 'account: created',
  SALES_UPGRADE_POPUP_DISPLAYED: 'sales: upgrade popup displayed'
};

export const APP_ANALYTICS = {
  APP_LOADED: 'app loaded'
};
