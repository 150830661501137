import styled from 'styled-components';

const Group = styled.div`
  display: flex;
  overflow: hidden;
  margin: 0 -24px;
  @media (max-width: 449px) {
    margin: 0 -16px;
  }
`;

const Rightdiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
`;
export { Group, Rightdiv };
