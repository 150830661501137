/* eslint-disable max-len */
import { Node, mergeAttributes } from '@tiptap/core';

export interface YouTubeEmbedOptions {
  HTMLAttributes?: Record<string, any>;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    youTubeEmbed: {
      setYouTubeEmbed: (options: { url: string; videoId: string; themeMode?: string }) => ReturnType;
    };
  }
}

export const YouTubeEmbed = Node.create<YouTubeEmbedOptions>({
  name: 'youTubeEmbed',

  group: 'block',
  atom: true,
  selectable: true,
  draggable: false,

  addAttributes() {
    return {
      url: { default: '' },
      videoId: { default: '' },
      previewImageUrl: {
        default: (attrs: any) => `https://img.youtube.com/vi/${attrs?.videoId}/maxresdefault.jpg`
      },
      themeMode: { default: 'light' }
    };
  },

  parseHTML() {
    return [
      {
        tag: 'div.youtube-preview-main-div',
        getAttrs: (dom: HTMLElement) => ({
          url: dom.getAttribute('url'),
          videoId: dom.getAttribute('name'),
          previewImageUrl: `https://img.youtube.com/vi/${dom.getAttribute('name')}/maxresdefault.jpg`
        })
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const { themeMode, previewImageUrl } = HTMLAttributes;

    return [
      'div',
      mergeAttributes(HTMLAttributes, {
        class: 'youtube-preview-main-div',
        url: HTMLAttributes.url,
        name: HTMLAttributes?.videoId
      }),
      [
        'div',
        { class: 'screen-recording-header' },
        [
          'div',
          { class: 'screen-recording-desc-div' },
          [
            'img',
            {
              src: 'https://firebasestorage.googleapis.com/v0/b/teamcamp-app.appspot.com/o/MediaIcons%2FYouTube_icon.png?alt=media&token=ef342c7b-16fc-4a2c-b245-8e9eb87ff785',
              alt: 'YouTube',
              width: '20',
              height: '20',
              class: 'youtube-icon'
            }
          ],
          ['div', { class: 'screen-recording-text' }, ['div', { class: 'screen-recording-name' }, 'YouTube Video']]
        ],
        [
          'div',
          { class: 'screen-recording-right-div' },
          [
            'img',
            {
              src:
                themeMode === 'dark'
                  ? 'https://firebasestorage.googleapis.com/v0/b/teamcamp-app.appspot.com/o/MediaIcons%2FIcon_dark_mode_full_screen.svg?alt=media&token=063f8f8a-8ae7-4874-8a46-a08ca596a8ac'
                  : 'https://firebasestorage.googleapis.com/v0/b/teamcamp-app.appspot.com/o/MediaIcons%2FIcon_light_mode_full_screen.svg?alt=media&token=bcad4f9e-dcd0-4444-bc79-521026f18d80',
              alt: 'Open video',
              class: 'open-video-icon'
            }
          ],
          [
            'img',
            {
              src:
                themeMode === 'dark'
                  ? 'https://firebasestorage.googleapis.com/v0/b/teamcamp-app.appspot.com/o/MediaIcons%2FIcon_more.svg?alt=media&token=4bfff6bb-8d93-496e-b97c-d4b6d9eb0aa2'
                  : 'https://firebasestorage.googleapis.com/v0/b/teamcamp-app.appspot.com/o/MediaIcons%2Fmore_updated_light.svg?alt=media&token=2d54af7d-8675-4621-ba69-e0a1247f5114',
              alt: 'More options',
              class: 'video-more-icon'
            }
          ]
        ]
      ],
      [
        'div',
        { class: 'inbox-image' },
        [
          'img',
          {
            src: previewImageUrl,
            alt: 'YouTube Preview',
            class: 'youtube-preview-image',
            width: '200',
            height: '100'
          }
        ],
        [
          'img',
          {
            src: 'https://firebasestorage.googleapis.com/v0/b/teamcamp-app.appspot.com/o/MediaIcons%2Fvideoplay.png?alt=media&token=ef342c7b-16fc-4a2c-b245-8e9eb87ff785',
            alt: 'video-icon',
            class: 'video-icon',
            width: '30',
            height: '30'
          }
        ]
      ]
    ];
  },

  addCommands() {
    return {
      setYouTubeEmbed:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options
          });
        }
    };
  }
});
