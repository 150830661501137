/* eslint-disable jsx-a11y/img-redundant-alt */
import parse from 'html-react-parser';
import { ScreenRecordMain, Printscreen, Figma, SvgDiv } from './styles';
import SVGIcon from '../../assets/images/svg/SVGIcon';
import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { RenderImagePreview } from '../renderImagePreview';
import { isElectronApp } from '../../helpers/common';

function RenderInboxHtml({ htmlstring }: { htmlstring: string }) {
  //Refs
  const videoRef: any = useRef(null);
  const playerRef: any = useRef<HTMLDivElement>(null);
  //States
  const [isPlaying, setIsPlaying] = useState(false);
  const [oncePlay, setOncePlay] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const toggleFullscreen = (isYoutubeVideo: boolean, videoUrl: string) => {
    if (!isYoutubeVideo) {
      if (videoRef.current.requestFullscreen) {
        if (isElectronApp()) {
          window.open(videoUrl, '_blank');
        } else {
          videoRef.current.requestFullscreen();
        }
      } else if (videoRef.current.mozRequestFullScreen) {
        videoRef.current.mozRequestFullScreen();
      } else if (videoRef.current.webkitRequestFullscreen) {
        videoRef.current.webkitRequestFullscreen();
      } else if (videoRef.current.msRequestFullscreen) {
        videoRef.current.msRequestFullscreen();
      }
    } else {
      if (playerRef.current) {
        const wrapper = playerRef.current;
        if (wrapper.requestFullscreen) {
          if (isElectronApp()) {
            window.open(videoUrl, '_blank');
          } else {
            wrapper.requestFullscreen();
          }
        } else if (wrapper.mozRequestFullScreen) {
          wrapper.mozRequestFullScreen();
        } else if (wrapper.webkitRequestFullscreen) {
          wrapper.webkitRequestFullscreen();
        } else if (wrapper.msRequestFullscreen) {
          wrapper.msRequestFullscreen();
        }
      }
    }
  };

  const handleFullscreenChange = () => {
    const fullscreenElement =
      document.fullscreenElement ||
      playerRef?.current?.webkitFullscreenElement ||
      playerRef?.current?.mozFullScreenElement ||
      playerRef?.current?.msFullscreenElement;

    if (fullscreenElement === playerRef.current) {
      setIsFullscreen(true);
    } else {
      setIsFullscreen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange); // Safari
    document.addEventListener('mozfullscreenchange', handleFullscreenChange); // Firefox

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
    };
  }, []);

  const renderYoutubeVideo = (e: React.SyntheticEvent, videoUrl: string) => {
    e.stopPropagation();
    setOncePlay(true);
    setIsPlaying(true);
    toggleFullscreen(true, videoUrl);
  };

  const renderCleanshotVideo = (e: React.SyntheticEvent, videoUrl: string) => {
    e.stopPropagation();
    toggleFullscreen(false, videoUrl);
  };

  const handleViewImage = (e: React.SyntheticEvent, imageUrl: string) => {
    e.stopPropagation();
    window.open(imageUrl, '_blank');
  };
  return (
    <>
      {parse(`<div>${htmlstring}</div>`, {
        replace: (domNode: any) => {
          if (
            domNode.type === 'tag' &&
            domNode.name === 'img' &&
            domNode?.attribs?.class?.includes('figma-preview-image')
          ) {
            return <RenderImagePreview imageUrl={domNode.attribs.src} altText={domNode.attribs.alt} isInbox={true} />;
          } else if (
            domNode.type === 'tag' &&
            domNode.name === 'img' &&
            !domNode?.attribs?.class?.includes('figma-icon') &&
            !domNode?.attribs?.class?.includes('refresh-icon') &&
            !domNode?.attribs?.class?.includes('figma-preview-image')
          ) {
            return (
              <RenderImagePreview
                imageUrl={domNode.attribs.src}
                altText={domNode.attribs.alt}
                isInbox={true}
                isMargin={true}
              />
            );
          } else if (domNode.type === 'tag' && domNode?.attribs?.class?.includes('screen-recording-custom-main-div')) {
            return (
              <ScreenRecordMain>
                <div className='video-preview'>
                  <div className='screen-recording-header'>
                    <div className='screen-recording-desc-div'>
                      <div className='screen-recording-text'>
                        <div className='header'>
                          <SvgDiv>
                            <SVGIcon
                              name='video-play-icon'
                              width='20px'
                              height='20px'
                              viewBox='0 0 20 20'
                              fill='none'
                            />
                          </SvgDiv>
                          <div className='screen-recording-name'>{domNode?.attribs?.name}</div>
                        </div>
                        <div
                          className='button'
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleFullscreen(false, domNode?.attribs?.url);
                          }}>
                          Play Video
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='inbox-image-container'>
                    <video ref={videoRef} controls={false} className='inbox-video'>
                      <source type='video/mp4' src={domNode?.attribs?.url} />
                    </video>
                  </div>
                </div>
              </ScreenRecordMain>
            );
          } else if (domNode.type === 'tag' && domNode?.attribs?.class?.includes('cleanshot-video-main-div')) {
            return (
              <ScreenRecordMain>
                <div className='video-preview'>
                  <div className='screen-recording-header'>
                    <div className='screen-recording-desc-div'>
                      <div className='screen-recording-text'>
                        <div className='header'>
                          <SvgDiv>
                            <img
                              src={
                                // eslint-disable-next-line max-len
                                'https://firebasestorage.googleapis.com/v0/b/teamcamp-app.appspot.com/o/MediaIcons%2Fcleanshot_logo_2x.png?alt=media&token=6bf18f68-de54-4687-a010-e8f1c058cab5'
                              }
                              alt='cleanshot Image'
                              width='20px'
                              height='20px'
                              className='img-cleanshot'
                            />
                          </SvgDiv>
                          <div className='screen-recording-name'>{domNode?.attribs?.name}</div>
                        </div>
                        <div className='button' onClick={(e) => renderCleanshotVideo(e, domNode?.attribs?.url)}>
                          Play Video
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='inbox-image-container' onClick={(e) => e.stopPropagation()}>
                    <video ref={videoRef} controls={false} className='inbox-video'>
                      <source type='video/mp4' src={domNode?.attribs?.url} />
                    </video>
                  </div>
                </div>
              </ScreenRecordMain>
            );
          } else if (domNode.type === 'tag' && domNode?.attribs?.class?.includes('figma-link-div')) {
            return (
              <>
                <Figma onClick={(e) => handleViewImage(e, domNode?.attribs?.href)}>Open In Figma</Figma>
              </>
            );
          } else if (domNode.type === 'tag' && domNode?.attribs?.class?.includes('prnt-preview-main-div')) {
            return (
              <Printscreen>
                <div className='prnt-screen-box'>
                  <div>
                    <div className='prnt-screen-header'>
                      <div className='prnt-screen-text'>
                        <div className='header'>
                          <img
                            src={
                              // eslint-disable-next-line max-len
                              'https://firebasestorage.googleapis.com/v0/b/teamcamp-app.appspot.com/o/MediaIcons%2FLightShot.png?alt=media&token=05979875-3f7c-4836-8242-7b10a8a12cf1'
                            }
                            alt='Figma Image'
                            width='20px'
                            height='20px'
                            className='logo'
                          />
                          <div className='prnt-screen-title'>{domNode?.attribs?.url}</div>
                        </div>
                        <div className='buttons'>
                          <a
                            href={domNode?.attribs?.url}
                            target='_blank'
                            rel='noreferrer'
                            className='button'
                            onClick={(e) => e.stopPropagation()}>
                            Open Link
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='figma-image-container' onClick={(e) => e.stopPropagation()}>
                    <RenderImagePreview imageUrl={domNode.attribs.name} altText={'prnt image'} isInbox={true} />
                  </div>
                </div>
              </Printscreen>
            );
          } else if (domNode.type === 'tag' && domNode?.attribs?.class?.includes('cleanshot-image-main-div')) {
            return (
              <Printscreen>
                <div className='prnt-screen-box'>
                  <div>
                    <div className='prnt-screen-header'>
                      <div className='prnt-screen-text'>
                        <div className='header'>
                          <img
                            src={
                              // eslint-disable-next-line max-len
                              'https://firebasestorage.googleapis.com/v0/b/teamcamp-app.appspot.com/o/MediaIcons%2Fcleanshot_logo_2x.png?alt=media&token=6bf18f68-de54-4687-a010-e8f1c058cab5'
                            }
                            alt='cleanshot Image'
                            width='20px'
                            height='20px'
                            className='logo'
                          />
                          <div className='prnt-screen-title'>{domNode?.attribs?.name}</div>
                        </div>
                        <div className='buttons'>
                          <a
                            href={domNode?.attribs?.name}
                            target='_blank'
                            rel='noreferrer'
                            className='button'
                            onClick={(e) => e.stopPropagation()}>
                            Open Link
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='figma-image-container' onClick={(e) => e.stopPropagation()}>
                    <RenderImagePreview imageUrl={domNode.attribs.name} altText={'prnt image'} isInbox={true} />
                  </div>
                </div>
              </Printscreen>
            );
          } else if (domNode.type === 'tag' && domNode.attribs?.class?.includes('youtube-preview-main-div')) {
            return (
              <>
                <ScreenRecordMain>
                  <div className='video-preview'>
                    <div className='screen-recording-header'>
                      <div className='screen-recording-desc-div'>
                        <div className='screen-recording-text'>
                          <div className='header'>
                            <SvgDiv>
                              <SVGIcon
                                name='video-play-icon'
                                width='20px'
                                height='20px'
                                viewBox='0 0 20 20'
                                fill='none'
                              />
                            </SvgDiv>
                            <div className='screen-recording-name'>{`YouTube_video_${domNode?.attribs?.name}`}</div>
                          </div>
                          <div
                            className='button'
                            onClick={(e) =>
                              renderYoutubeVideo(e, `https://www.youtube.com/watch?v=${domNode?.attribs?.name}`)
                            }>
                            Play Video
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='inbox-image-container' onClick={(e) => e.stopPropagation()}>
                      <img
                        src={`https://img.youtube.com/vi/${domNode?.attribs?.name}/maxresdefault.jpg`}
                        alt='YouTube Preview'
                        className='youtube-preview-image'
                        width='200'
                        height='100'
                      />
                    </div>
                  </div>
                </ScreenRecordMain>
                <div ref={playerRef}>
                  {isFullscreen && oncePlay && (
                    <ReactPlayer
                      url={`https://www.youtube.com/watch?v=${domNode?.attribs?.name}`}
                      playing={isPlaying}
                      controls={true}
                      height='100%'
                      width='100%'
                      config={{
                        youtube: {
                          playerVars: {
                            modestbranding: 1, // Minimal branding
                            showinfo: 0, // Hide video info
                            controls: 1, // Show YouTube controls
                            disablekb: 0, // Enable keyboard controls for seeking
                            iv_load_policy: 3, // Hide annotations
                            fs: 1, // Allow full-screen
                            rel: 0 // Don't show related videos
                          }
                        }
                      }}
                      onPause={() => setIsPlaying(false)}
                      onPlay={() => setIsPlaying(true)}
                      onEnded={() => setIsPlaying(false)}
                    />
                  )}
                </div>
              </>
            );
          }
          return domNode;
        }
      })}
    </>
  );
}
export default RenderInboxHtml;
