import axios from 'axios';
import { AnyAction, Dispatch } from 'redux';
import { REACT_APP_BLOCKSUITE_WS_URL } from '../global/environment';
import { clearResponseMessage, setErrorMessage } from '../actions/messageActions';
import { setDocLoader, setDocVersionList } from '../actions/docAction';
import { captureException } from './logService';
import { getAPIErrorReason } from '../helpers/common';
import { DocVersion, DocVersionList } from '../interfaces/ProjectInterface';

/**
 * @desc Doc - get doc versions list
 * @param {*}
 */
export const getDocVersionList: any = (docId: string) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setDocLoader(true));
    const response = await axios.get(`${REACT_APP_BLOCKSUITE_WS_URL}/versions?docId=${docId}`);
    const { data } = response;
    if (data) {
      dispatch(setDocVersionList(data));
      return data as DocVersionList;
    }
    return undefined;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to get doc version list please try again';
    captureException(error);
    dispatchCustomerError(error, dispatch);
    return undefined;
  } finally {
    dispatch(setDocLoader(false));
  }
};

/**
 * @desc Doc - get doc version
 * @param {*}
 */
export const getDocVersion: any = (versionId: string | number) => async (dispatch: Dispatch<AnyAction>) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setDocLoader(true));
    const response = await axios.get(`${REACT_APP_BLOCKSUITE_WS_URL}/versions/${versionId}`);
    const { data } = response;
    if (data) {
      dispatch(setDocVersionList(data));
      return data as DocVersion;
    }
    return undefined;
  } catch (e) {
    const error = getAPIErrorReason(e) || 'Unable to get doc version please try again';
    captureException(error);
    dispatchCustomerError(error, dispatch);
    return undefined;
  } finally {
    dispatch(setDocLoader(false));
  }
};

function dispatchCustomerError(msg: string, dispatch: Dispatch<AnyAction>) {
  dispatch(setErrorMessage(msg));
}
