import EmojiPicker, { Theme } from 'emoji-picker-react';
import { Emojis } from './style';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';

interface Props {
  onEmojiClick: any;
  emojiButtonref?: React.RefObject<HTMLDivElement | null>;
  isOpenEmoji: boolean;
  setIsOpenEmoji: (open: boolean) => void;
  setOpenDropdownId?: (id: string | null) => void;
  isMessage?: boolean;
  isEmojiLoading?: boolean;
}

const Emoji = ({
  onEmojiClick,
  emojiButtonref,
  isOpenEmoji,
  setIsOpenEmoji,
  setOpenDropdownId,
  isMessage,
  isEmojiLoading = false
}: Props) => {
  const emojiDropdownRef = useRef<HTMLDivElement | null>(null);
  const [coordinates, setCoordinates] = useState({ top: 0, left: 0 });

  // Prevent wheel/touch events from reaching the document
  useEffect(() => {
    if (!isOpenEmoji) return;

    const preventDefault = (e: Event) => {
      if (!emojiDropdownRef.current?.contains(e.target as Node)) {
        e.preventDefault();
      }
    };

    document.addEventListener('wheel', preventDefault, { passive: false });
    document.addEventListener('touchmove', preventDefault, { passive: false });

    return () => {
      document.removeEventListener('wheel', preventDefault);
      document.removeEventListener('touchmove', preventDefault);
    };
  }, [isOpenEmoji]);

  useLayoutEffect(() => {
    if (isOpenEmoji && emojiButtonref?.current && emojiDropdownRef?.current) {
      const buttonRect = emojiButtonref.current.getBoundingClientRect();
      const dropdownRect = emojiDropdownRef.current.getBoundingClientRect();

      const VIEWPORT_PADDING = 16;
      const MESSAGE_BOTTOM_OFFSET = isMessage ? 85 : 0;

      // Calculate available space
      const spaceBelow = window.innerHeight - buttonRect.bottom - MESSAGE_BOTTOM_OFFSET;
      const spaceAbove = buttonRect.top;

      // Determine vertical position
      let top = 0;
      if (spaceBelow >= dropdownRect.height + VIEWPORT_PADDING) {
        top = buttonRect.bottom + 5;
      } else if (spaceAbove >= dropdownRect.height + VIEWPORT_PADDING) {
        top = buttonRect.top - dropdownRect.height - 5;
      } else {
        top = spaceBelow > spaceAbove ? buttonRect.bottom + 5 : buttonRect.top - dropdownRect.height - 5;
      }

      // Determine horizontal position
      let left = buttonRect.left;
      if (buttonRect.left + dropdownRect.width > window.innerWidth - VIEWPORT_PADDING) {
        left = window.innerWidth - dropdownRect.width - VIEWPORT_PADDING;
      }

      // Ensure within viewport bounds
      top = Math.max(VIEWPORT_PADDING, Math.min(top, window.innerHeight - dropdownRect.height - VIEWPORT_PADDING));
      left = Math.max(VIEWPORT_PADDING, left);

      setCoordinates({ top, left });
    }
  }, [isOpenEmoji, emojiButtonref, isMessage]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        !emojiDropdownRef.current?.contains(event.target as Node) &&
        !emojiButtonref?.current?.contains(event.target as Node)
      ) {
        setIsOpenEmoji(false);
        setOpenDropdownId?.(null);
      }
    };

    if (isOpenEmoji) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpenEmoji, emojiButtonref, setIsOpenEmoji, setOpenDropdownId]);

  return (
    <Emojis
      ref={emojiDropdownRef}
      style={{
        position: 'fixed',
        top: `${coordinates.top}px`,
        left: `${coordinates.left}px`,
        overflow: 'hidden'
      }}
      onClick={(e) => e.stopPropagation()}
      isEmojiLoading={isEmojiLoading}>
      <EmojiPicker
        searchPlaceholder='Search Emoji'
        theme={Theme.AUTO}
        width={258}
        height={240}
        onEmojiClick={onEmojiClick}
        lazyLoadEmojis={true}
      />
    </Emojis>
  );
};

export default Emoji;
