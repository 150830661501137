import { combineReducers } from 'redux';
import { createReducer } from '../helpers/reduxHelpers';
import * as Actions from '../actions/types';
import { PayloadAction, createReducer as createReducerOrig, current } from '@reduxjs/toolkit';
import { ReducerInterface } from '../interfaces/ReducerInterface';

const setChatMessageLoaderReducer = createReducer({
  initialState: false,
  actionType: Actions.SET_CHAT_MESSAGE_LOADER
});

const chatGroupsReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_CHAT_GROUPS
});

const pinChatGroupsReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_PIN_CHAT_GROUPS
});
const unpinChatGroupsReducer = createReducer({
  initialState: [],
  actionType: Actions.SET_UNPIN_CHAT_GROUPS
});

const currentChatGroupReducer = createReducer({
  initialState: {},
  actionType: Actions.SET_CURRENT_CHAT_GROUP
});

const initialState = {};

const inputChatGroupReducer = createReducerOrig(initialState, (builder) => {
  builder
    .addCase(Actions.SET_CURRENT_CHAT_GROUP_NAME, (state = initialState, action: PayloadAction<any, never>) => {
      return { ...(action.payload || {}) };
    })
    .addCase(Actions.UPDATE_CURRENT_CHAT_GROUP_NAME, (state, action: PayloadAction<ReducerInterface, never>) => {
      const customer = JSON.parse(JSON.stringify(current(state)));
      customer[action.payload.propsName] = action.payload.value;
      return customer;
    })
    .addCase(Actions.CLEAR_CURRENT_CHAT_GROUP_NAME, () => {
      const initialData = JSON.parse(JSON.stringify(initialState));
      return initialData;
    });
});

const chatMessageReducer = combineReducers({
  loading: setChatMessageLoaderReducer,
  chatGroups: chatGroupsReducer,
  pinChatGroups: pinChatGroupsReducer,
  unpinChatGroups: unpinChatGroupsReducer,
  currentChatGroup: currentChatGroupReducer,
  inputChatGroup: inputChatGroupReducer
});

export default chatMessageReducer;
