import { useSelector } from 'react-redux';
import SVGIcon from '../../../assets/images/svg/SVGIcon';
import Avatar from '../../avatar/Avatar';
import Button from '../../button';
import {
  // BulletIcon,
  ButtonSection,
  CloseIcon,
  CopyIcon,
  Email,
  EmailSection,
  Name,
  NameSection,
  Profile,
  ProfileSection,
  RedirectSection,
  // Status,
  Time,
  TimeAndStatusSection,
  UserInfo,
  UserProfileModal
} from './styles';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import copy from 'clipboard-copy';
import { setSuccessMessage } from '../../../actions/messageActions';
import { captureException } from '@sentry/react';
import MessageGroupCacheService from '../../../services/messageGroupCacheService';
import { ChatGroupInterface } from '../../../interfaces/chatMessageInterface';
import { useHistory } from 'react-router-dom';
import { useMobileDevice } from '../../../global/useMobile';
import UserPreferenceSingleton from '../../../helpers/userPreferenceSingleton';
import { setCurrentChatGroup } from '../../../actions/chatMessageActions';
import { createMessageGroup, setLastReadForDiscussion } from '../../../services/chatMessageService';
import moment from 'moment';
import { isEmpty } from '../../../helpers/common';

interface Props {
  onClose: () => void;
}

export default function UserModal({ onClose }: Props) {
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { app: appSelector, workspace: workspaceSelector } = stateSelector || {};
  const { userModalDetail } = appSelector;
  const { workspace } = workspaceSelector;

  // other variables
  const history = useHistory();
  const dispatch = useDispatch();
  const mobile = useMobileDevice();
  const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();

  // for copy email
  const onCopyLink = useCallback(
    async (url: string) => {
      try {
        await copy(url);
        dispatch(setSuccessMessage('Email copied successfully!'));
      } catch (error) {
        captureException(error);
        console.log('Failed to copy email: ', error);
      }
    },
    [dispatch]
  );
  const createNewGroup = useCallback(async () => {
    const payload = {
      userId: userDetails?.id,
      name: '',
      companyId: workspace?.id,
      users: [userModalDetail?.id]
    };
    const result = await dispatch(createMessageGroup(payload));
    if (result) {
      dispatch(setCurrentChatGroup(result));
      UserPreferenceSingleton.getInstance().setLastMessageGroup(result?.id);
      const path = mobile ? `/messages/details?chatGroup=${result?.id}` : `/messages?chatGroup=${result?.id}`;
      history.push(path);
    }
  }, [dispatch, history, mobile, userDetails?.id, userModalDetail?.id, workspace?.id]);

  const handleMessageClick = useCallback(async () => {
    try {
      const messageGroups = await MessageGroupCacheService.getInstance().getMessageGroups();
      const personalGroups = messageGroups?.filter((item: ChatGroupInterface) => item?.users?.length === 2);
      const currentUserGroup = personalGroups?.find((group: ChatGroupInterface) =>
        group?.users?.some((user) => user.id === userModalDetail?.id)
      );
      if (currentUserGroup) {
        if (mobile) history.push(`/messages/details?chatGroup=${currentUserGroup?.id}`);
        else history.push(`/messages?chatGroup=${currentUserGroup?.id}`);
        UserPreferenceSingleton.getInstance().setLastMessageGroup(currentUserGroup?.id);
        dispatch(setLastReadForDiscussion());
        dispatch(setCurrentChatGroup(currentUserGroup));
      } else {
        createNewGroup();
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      onClose();
    }
  }, [createNewGroup, dispatch, history, mobile, onClose, userModalDetail?.id]);

  // for display assigned task page
  const handleAssignedTask = useCallback(() => {
    history.push(`/members/${userModalDetail?.id}/tasks`);
    onClose();
  }, [history, onClose, userModalDetail?.id]);

  // get current time based on timezone
  const getCurrentTime = (timezoneStr = 'UTC+05:30 - Asia/Kolkata') => {
    try {
      // Extracting IANA Timezone
      const match = timezoneStr.match(/-\s*([\w/-]+)$/);
      const ianaZone = match ? match[1] : 'Asia/Kolkata';

      let formattedTime;
      if (moment.tz.names().includes(ianaZone)) {
        formattedTime = moment()
          .tz(ianaZone)
          .format(userDetails.dateFormat + ' , hh:mm A');
      } else {
        formattedTime = moment()
          .tz('Asia/Kolkata')
          .format(userDetails.dateFormat + ' , hh:mm A');
      }

      return formattedTime;
    } catch (error) {
      console.error(`Invalid timezone format (${timezoneStr}):`, error);
      return 'Invalid Timezone';
    }
  };

  return (
    <UserProfileModal>
      <ProfileSection>
        <UserInfo>
          <Profile>
            <Avatar imgSrc={userModalDetail.profile_photo} name={userModalDetail.name} size={42} isNotBorder />
          </Profile>
          <NameSection>
            <Name>{userModalDetail.name}</Name>
            <EmailSection>
              <Email>{userModalDetail.email}</Email>
              <CopyIcon onClick={() => onCopyLink(userModalDetail.email)}>
                <SVGIcon name='copy-icon-16x16' width='16' height='16' viewBox='0 0 16 16' className='copy-icon' />
              </CopyIcon>
            </EmailSection>
          </NameSection>
        </UserInfo>
        <CloseIcon onClick={onClose}>
          <SVGIcon name='app-close-icon' width='14' height='14' viewBox='0 0 16 16' />
        </CloseIcon>
      </ProfileSection>
      <RedirectSection>
        {userDetails?.id !== userModalDetail?.id && (
          <ButtonSection>
            <Button
              title='Message'
              iconName='navbar-Messages-icon'
              secondary
              iconColor='var(--text-primary)'
              iconSize={18}
              iconViewBox='0 0 18 18'
              className='button'
              onClick={handleMessageClick}
            />
          </ButtonSection>
        )}
        <Button
          onClick={handleAssignedTask}
          title='Assigned tasks'
          iconName='navbar-MyTask-icon'
          secondary
          iconColor='var(--text-primary)'
          iconSize={18}
          iconViewBox='0 0 18 18'
          className='button'
        />
      </RedirectSection>
      <TimeAndStatusSection>
        {/* <Status>Active now</Status>
        <BulletIcon /> */}
        <Time>{getCurrentTime(!isEmpty(userModalDetail?.timezone) ? userModalDetail.timezone : '')}</Time>
      </TimeAndStatusSection>
    </UserProfileModal>
  );
}
