import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { forgotPassword } from '../../../services/authServices';
import Button from '../../../component/button';
import { isDev } from '../../../helpers/common';
import AppLogo from '../../../component/appLogo';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import {
  Content,
  Form,
  Formblock,
  Formtext,
  Formtext1,
  Formtextarea,
  FormView,
  Group,
  Icon,
  Inputvalue,
  Title,
  Viewer,
  ResetView
} from '../styles';

const Forgetpassword: React.FC = () => {
  //States
  const [email, setEmail] = useState('');
  const [isSent, setIsSent] = useState(false);
  //use selector state variables
  const auth = useSelector((state: RootReducerInterface) => state.auth);
  const { loading } = auth;
  //Other variable
  const history = useHistory();
  const dispatch = useDispatch();

  // for email change
  const onChangeEmail = useCallback((value: any) => {
    setEmail(value);
  }, []);

  // for sending mail
  const onSendMail = useCallback(
    async (e: React.SyntheticEvent) => {
      e.preventDefault();
      const payload = {
        email,
        isDev: isDev()
      };
      const result = await dispatch(forgotPassword(payload));
      if (result) {
        setEmail('');
        setIsSent(true);
      }
    },
    [email, dispatch]
  );

  return (
    <>
      <Group isForgotPasswordPage className='windows-onboarding-flow'>
        <Content isSent={isSent}>
          <Icon>
            <AppLogo height='60' />
          </Icon>

          {isSent ? <></> : <Title> Forgot Password</Title>}
          {isSent ? (
            <>
              <ResetView>
                <Title> Forgot Password Confirmation</Title>
                <Formtext isForgotPasswordSent={isSent}>
                  We have sent you an email with instructions on how to reset your password.
                </Formtext>
              </ResetView>
              <Button
                title='Go Back to Log in'
                onClick={() => {
                  history.push('/membership/login');
                }}
                resWidth={100}
              />
            </>
          ) : (
            <FormView>
              <Form onSubmit={onSendMail}>
                <Formblock>
                  <Inputvalue
                    typeof='email'
                    placeholder='Email Address'
                    value={email}
                    onChange={(e) => {
                      onChangeEmail(e.target.value);
                    }}
                  />
                </Formblock>
                <Button title='Send to mail' type='submit' resWidth={100} isLoading={loading} />
              </Form>
              <Formtextarea>
                <Formtext isForgotPasswordSent={false}>Didn't receive the email?</Formtext>
                <Formtext1>
                  <Viewer href='/membership/login'>Back to Login</Viewer>
                </Formtext1>
              </Formtextarea>
            </FormView>
          )}
        </Content>
      </Group>
    </>
  );
};

export default Forgetpassword;
