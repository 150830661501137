import SVGIcon from '../../../assets/images/svg/SVGIcon';
import {
  Main,
  Top,
  Left,
  Right,
  Bottom,
  Label,
  Inputvalue,
  Formbutton,
  Form,
  Field,
  Icon,
  Droptab,
  Titles,
  Buttons,
  Description
} from './styles';
import Button from '../../button';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { clearMemberInput, updateMemberInput } from '../../../actions/customerActions';
import { addCustomerMember, getCustomerMembersList, updateCustomerMember } from '../../../services/customerServices';
import { isEmpty } from '../../../helpers/common';
import { RootReducerInterface } from '../../../interfaces/RootReducerInterface';
import { CUSTOMER_MEMBER_ROLE, roleOptions } from '../../../global/constants';
import { getWorkspaceDetails } from '../../../services/workspaceService';
import { UsersWorkspaceInterface } from '../../../interfaces/WorkspaceInterface';
import { Dropdown, DropdownItem } from '../../Dropdown';

interface Props {
  closeModal: (item?: string) => void;
  customer_id: string;
}

export default function Addmembermodal(props: Props) {
  const { closeModal, customer_id } = props;
  //States
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  //use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const { customer: customerSelector, workspace: workspaceSelector } = stateSelector || {};
  const { memberInput, loading } = customerSelector;
  const { name, role, title, email } = memberInput || {};
  const { workspace } = workspaceSelector;
  //Other variable
  const dispatch = useDispatch();

  const checkUserAccess = useCallback(() => {
    if (memberInput?.id) {
      if (
        memberInput?.role === CUSTOMER_MEMBER_ROLE?.FULL_ACCESS ||
        memberInput?.role === CUSTOMER_MEMBER_ROLE?.PROJECT_ONLY
      ) {
        const userInWorkspace = workspace?.users?.find((user: UsersWorkspaceInterface) => user?.id === memberInput?.id);
        if (userInWorkspace) setIsDisabled(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberInput?.id, workspace?.users]);

  useEffect(() => {
    checkUserAccess();
  }, [checkUserAccess]);

  useEffect(() => {
    return () => {
      dispatch(clearMemberInput());
    };
  }, [dispatch]);

  const handleChange = useCallback(
    (propsName: string, value: string | number) => {
      dispatch(updateMemberInput({ propsName, value }));
    },
    [dispatch]
  );

  const getCurrentSelectedRoleIcon = useMemo(() => {
    switch (role) {
      case 1:
        return <SVGIcon name='no-icon' width='16' height='16' viewBox='0 0 16 16' className='fill-stroke' />;
      case 2:
        return (
          <Icon>
            <SVGIcon name='project-only-icon' width='16' height='16' viewBox='0 0 16 16' className='fill-colors' />
          </Icon>
        );
      case 3:
        return (
          <Icon>
            <SVGIcon name='access-icon' width='16' height='16' viewBox='0 0 16 16' className='fill-colors' />
          </Icon>
        );
      default:
        return (
          <Icon>
            <SVGIcon name='lock-icn' width='16' height='16' viewBox='0 0 16 16' className='fill-color' />
          </Icon>
        );
    }
  }, [role]);

  const getRoleText = useCallback(() => {
    switch (role) {
      case 1:
        return (
          <Description>
            <h2>No Access</h2>
          </Description>
        );
      case 2:
        return (
          <Description>
            <h2>Project Only</h2>
          </Description>
        );
      case 3:
        return (
          <Description>
            <h2>Full Access</h2>
          </Description>
        );
      default:
        return 'Access Control';
    }
  }, [role]);

  const onSubmit = useCallback(
    async (e: React.SyntheticEvent) => {
      e.preventDefault();
      let response;
      if (!isEmpty(memberInput?.id)) {
        response = await dispatch(updateCustomerMember(memberInput, customer_id));
      } else {
        response = await dispatch(addCustomerMember(memberInput, customer_id));
      }
      if (response) {
        if (memberInput?.role !== CUSTOMER_MEMBER_ROLE.NO_ACCESS) {
          await Promise.all([
            dispatch(getWorkspaceDetails(workspace?.id)),
            dispatch(getCustomerMembersList(customer_id))
          ]);
        } else await dispatch(getCustomerMembersList(customer_id));
        closeModal(memberInput?.role !== CUSTOMER_MEMBER_ROLE.NO_ACCESS ? response?.RedirectUri : undefined);
      }
    },
    [closeModal, customer_id, dispatch, memberInput, workspace?.id]
  );

  const renderRoleOptions = useMemo(
    () =>
      roleOptions.map((item) => (
        <DropdownItem
          key={item.key}
          iconName={item.iconName}
          iconSize={16}
          label={item.label}
          description={item.description}
          onClick={() => handleChange('role', item.roleValue)}
          iconTone={item.iconName === 'no-icon' ? 'stroke' : 'fill'}
          align='flex-start'
        />
      )),
    [handleChange]
  );
  return (
    <div>
      <Main onSubmit={onSubmit}>
        <Top>
          <Left>Add new member</Left>
          <Right onClick={() => closeModal()}>
            <SVGIcon name='close-icon' width='18' height='18' viewBox='0 0 18 18' className='close-icon' />
          </Right>
        </Top>
        <Bottom>
          <Form>
            <Field>
              <Label>Name</Label>
              <Inputvalue
                type='text'
                placeholder='Enter your name'
                value={name}
                onChange={(e) => handleChange('name', e.target.value)}
                disabled={isDisabled}
              />
            </Field>
            <Field>
              <Label>Title</Label>
              <Inputvalue
                type='text'
                placeholder='Title'
                value={title}
                onChange={(e) => handleChange('title', e.target.value)}
              />
            </Field>
            <Field>
              <Label>Email</Label>
              <Inputvalue
                type='text'
                placeholder='contact@email.com'
                value={email}
                onChange={(e) => handleChange('email', e.target.value)}
                disabled={isDisabled}
              />
            </Field>
          </Form>
          <Formbutton>
            <Dropdown
              isMinWidth={298}
              content={
                <Droptab>
                  {getCurrentSelectedRoleIcon}
                  <Titles>{getRoleText()}</Titles>
                </Droptab>
              }
              trigger='click'>
              {renderRoleOptions}
            </Dropdown>
            <Buttons>
              <Button
                title={isEmpty(memberInput?.id) ? 'Add Member' : 'Update'}
                type='submit'
                isLoading={loading}
                modelbtn={true}
              />
            </Buttons>
          </Formbutton>
        </Bottom>
      </Main>
    </div>
  );
}
